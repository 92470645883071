<template>
  <div :class="[$style.main, $style.orderBill_container]" ref="order">
    <condition-search
      :options="options"
      @submit="handleSearchSubmit"
      @reset="handleReset"
    />
    <a-table
      row-key="pkId"
      :columns="columns"
      :data-source="data"
      :pagination="invoiceParams.query"
      :scroll="{ x: 1300 }"
      :custom-row="
        record => ({
          on: {
            click: () => showDetailDrawer(record),
          },
        })
      "
      @change="changePage"
    />
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import { createDrawer, createFormModal } from '@triascloud/x-components';
import {
  InvoiceTypeText,
  BillStatusText,
  InvoiceStatus,
} from '@/enum/order-bill.js';
import { getList } from '@/services/transaction/invoice.js';
// import { options } from './index';
import ConditionSearch from '../components/condition-search';
import BillDetail from './bill-detail';
import ApplyInvoice from './apply-invoice';
import { getDetail } from '@/services/transaction/invoice.js';

@Component({ components: { ConditionSearch } })
export default class OrderBill extends Vue {
  invoiceParams = {
    companyName: '',
    endTime: '',
    invoiceId: '',
    invoiceStatusEnum: '',
    startTime: '',
    query: {
      current: 1,
      size: '10',
      total: 0,
    },
  };
  data = [];

  options = [
    {
      label: this.$t('finance.order.BillingOrderNo'),
      model: 'invoiceId',
      type: '1',
    },
    {
      label: this.$t('website.homePage.companyName'),
      model: 'companyName',
      type: '1',
    },
    {
      label: this.$t('finance.order.SelectTime'),
      model: ['startTime', 'endTime'],
      type: 4,
    },
    {
      label: this.$t('finance.order.BillingStatus'),
      type: 3,
      model: 'invoiceStatusEnum',
      items: [
        {
          label: this.$t('common.label.all'),
          value: '',
        },
        {
          label: this.$t('finance.order.ToBeInvoiced'),
          value: 3,
        },
        {
          label: this.$t('finance.order.Invoiced'),
          value: 2,
        },
      ],
    },
  ];

  get columns() {
    return [
      {
        title: this.$t('common.label.index'),
        key: 'serial number',
        width: 80,
        align: 'center',
        customRender: (_, row, index) => (
          <span>
            {~~this.invoiceParams.query.size *
              (this.invoiceParams.query.current - 1) +
              index +
              1}
          </span>
        ),
      },
      {
        title: this.$t('finance.order.BillingOrderNo'),
        dataIndex: 'invoiceCode',
        ellipsis: true,
      },
      {
        title: this.$t('finance.order.InvoiceType'),
        key: 'invoiceType',
        ellipsis: true,
        customRender: record => (
          <span>{InvoiceTypeText[record.invoiceType]}</span>
        ),
      },
      {
        title: this.$t('website.homePage.companyName'),
        dataIndex: 'companyName',
        ellipsis: true,
      },
      {
        title: this.$t('finance.order.InvoiceAmount'),
        key: 'invoiceAmount',
        align: 'right',
        width: 200,
        customRender: record => (
          <span>{`￥${(record.invoiceAmount / 100).toFixed(2)}`}</span>
        ),
      },
      {
        title: this.$t('finance.order.BillingOrder'),
        dataIndex: 'tradeCount',
        align: 'center',
        width: 120,
      },
      {
        title: this.$t('finance.order.SubmissionTime'),
        key: 'createDate',
        width: 180,
        align: 'center',
        customRender: record => (
          <span>
            {this.$moment(record.createDate).format('YYYY.MM.DD HH:mm:ss')}
          </span>
        ),
      },
      {
        title: this.$t('finance.order.BillingStatus'),
        key: 'invoiceStatus',
        width: 120,

        customRender: record => (
          <span>{BillStatusText[record.invoiceStatus]}</span>
        ),
      },
      {
        title: this.$t('common.action.operating'),
        key: 'operation',
        fixed: 'right',
        width: 150,
        align: 'center',
        customCell: () => ({
          on: {
            click: e => e.stopPropagation(),
          },
        }),
        customRender: record => (
          <a-button type="link" onClick={() => this.handleAction(record)}>
            {record.invoiceStatus === InvoiceStatus['TO_BE_INVOICED']
              ? this.$t('solution.orderBilling')
              : this.$t('finance.order.ViewDetails')}
          </a-button>
        ),
      },
    ];
  }

  async mounted() {
    await this.getInvoiceList();
  }

  async getInvoiceList() {
    const data = await getList(this.invoiceParams);
    this.invoiceParams.query.total = data.total;
    this.data = data.records;
  }

  showDetailDrawer(record) {
    const value = createDrawer(
      () => (
        <BillDetail
          pkId={record.pkId}
          companyId={record.companyId}
          onClose={isFresh => {
            value.visible = false;
            if (isFresh) {
              this.getInvoiceList();
            }
          }}
        />
      ),
      {
        title: this.$t('finance.order.BillingRequisitionDetails'),
        width: 900,
        wrapClassName: 'bill-drawer',
      },
    );
  }

  async handleAction(record) {
    if (record.invoiceStatus === InvoiceStatus['TO_BE_INVOICED']) {
      const detailData = await getDetail(record.pkId);
      createFormModal(
        () => (
          <ApplyInvoice
            companyId={record.companyId}
            data={detailData}
            pkId={record.pkId}
          />
        ),
        {
          title: this.$t('solution.orderBilling'),
          width: 700,
        },
      );
    } else {
      this.showDetailDrawer(record);
    }
  }

  changePage(page) {
    this.invoiceParams.query.current = page.current;
    this.getInvoiceList();
  }

  handleSearchSubmit(data) {
    for (let key in data) {
      this.invoiceParams[key] = data[key] ? data[key] : '';
    }
    this.invoiceParams.query.current = 1;
    this.getInvoiceList();
  }

  handleReset() {
    for (let key in this.invoiceParams) {
      if (key !== 'query') {
        this.invoiceParams[key] = '';
      }
      this.invoiceParams.query.current = 1;
    }
    this.getInvoiceList();
  }
}
</script>
<style lang="less" module>
.orderBill_container {
  padding: 20px;
}
</style>
