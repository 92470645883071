<template>
  <div>
    <draggable
      v-model="list"
      :animation="300"
      :handle="`.${$style.dragHandle}`"
      :scroll="true"
      @end="sort"
    >
      <transition-group>
        <div v-for="(item, index) in list" :key="item.id" :class="$style.cell">
          <div :class="$style.cell__name">{{ item.name }}</div>
          <div>
            <x-icon
              :class="$style.cell__icon"
              type="tc-icon-delete"
              @click="remove(item.id, index)"
            />
            <span v-if="!all" :class="[$style.dragHandle, $style.cell__icon]"
              ><x-icon type="tc-icon-drag-handler"
            /></span>
          </div>
        </div>
      </transition-group>
    </draggable>
    <empty-content v-if="!loading && !list.length" />
  </div>
</template>
<script>
import { sortTemplate } from '@/services/template-manager/tpClassify';
import { globalVueI18n } from '@triascloud/i18n';
import {
  getTemplateList,
  removeTemplate,
} from '@/services/template-manager/template';
import { createFormModal } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { TemplateType } from '@/enum/template';
import EmptyContent from '@/components/empty-content';

function createTemplateManageModal(props) {
  return createFormModal(h => h(TemplateManageModal, { props }), {
    title: globalVueI18n.translate('market.template.manage'),
    width: 440,
  });
}
@Component({
  components: { Draggable, EmptyContent },
})
export default class TemplateManageModal extends Vue {
  @Prop({ type: String, required: true }) classifyId;
  @Prop({ type: Boolean, default: false }) all;
  static createModal = createTemplateManageModal;
  list = [];
  loading = true;
  async created() {
    await this.getList();
    this.loading = false;
  }
  async getList() {
    this.list = await getTemplateList({ classifyId: this.classifyId });
  }
  /**
   * @name 拖动排序更新sort字段
   */
  sort({ newIndex, oldIndex }) {
    if (!this.list.length || newIndex === oldIndex) return;
    const sortItems = [];
    this.list.forEach((item, index) => {
      const sort = index;
      if (item.sort === sort) return;
      item.sort = sort;
      sortItems.push({
        sort,
        templateId: item.id,
        templateClassifyId: this.classifyId,
        templateType: TemplateType.Application,
      });
    });
    sortItems.length && sortTemplate(sortItems);
    this.$message.success(this.$t('common.tips.sortSuccessful'));
  }

  async remove(id, index) {
    await this.$confirm(this.$t('market.template.confirmDelete'));
    this.list.splice(index, 1);
    try {
      await removeTemplate({
        templateType: TemplateType.Application,
        templateId: id,
      });
      this.$message.success(this.$t('common.tips.delete'));
    } catch (error) {
      this.getTemplateList();
    }
  }
}
</script>
<style lang="less" module>
.title {
  margin-bottom: 10px;
  color: var(--font);
  font-weight: bold;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  line-height: 36px;
  .cell__left {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .cell__input {
      width: 160px;
    }
  }
  .cell__icon {
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
    &.cell__icon--checked {
      color: var(--success);
    }
    &.dragHandle {
      cursor: move;
    }
  }
}
</style>
