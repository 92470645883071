// @ts-check
import moment from 'moment';
import { DataFormTool } from './BaseTool';

/**
 * @typedef DateAddCutToolConfig
 * @property { 'DATE_ADD_CUT' } type
 * @property { string } formId
 * @property { { field: string, value: number } } amount
 * @property { { field: string, value: number } } date
 * @property { string } echoField
 * @property { import('moment').DurationInputArg2 } unit
 */

/**
 * @extends { DataFormTool<DateAddCutToolConfig> }
 */
export default class DataAddCutTool extends DataFormTool {
  /** @readonly */
  static type = 'DATE_ADD_CUT';

  /** @type { import('./BaseTool').InitField } */
  initField = (field, model) => {
    if (!this.config) return;
    const { echoField, amount, date, unit } = this.config;
    // 如果返回的字段id与当前传入的字段id不相等则return
    if (!echoField || field.pkId !== echoField) return;
    /** @param { { value: number, field: string } } source */
    const getValue = source => {
      if (!source) return null;
      if (source?.value) return source.value;
      if (source?.field) return model.getValue(source.field) || null;
    };
    /** @type {(currentDate: number, currentAmount: number) => void} */
    const setFieldValue = (currentDate, currentAmount) => {
      const fieldValue = currentDate
        ? moment(currentDate).add(Math.floor(Number(currentAmount)), unit)
        : null;
      model.setValue(echoField, fieldValue?.valueOf() || null);
    };
    setFieldValue(getValue(date), getValue(amount));
    // if (date?.field && date?.field !== ControlTypeEnum.CreateDate) {
    //   model.watch([date.field], () =>
    //     setFieldValue(getValue(date), getValue(amount)),
    //   );
    // }
    // if (amount?.field) {
    //   model.watch([amount.field], () =>
    //     setFieldValue(getValue(date), getValue(amount)),
    //   );
    // }
  };
}
