<template>
  <div :class="$style.wrap">
    <div :class="$style.menu">
      <div
        :class="[$style.menuItem, item.pkId === currentId ? $style.active : '']"
        v-for="item in typeList"
        :key="item.pkId"
        @click="changeType(item)"
      >
        {{ item.name }}
      </div>
    </div>

    <div :class="$style.action">
      <x-input
        :placeholder="$t('common.action.search')"
        :class="$style.search"
        v-model="keyword"
        @blur="getList"
        @pressEnter="getList"
      >
        <a-icon slot="prefix" type="search" />
      </x-input>
      <div :class="$style.add">
        <a-button type="primary" @click="add" v-if="$p.action('CREATE')">
          <a-icon type="plus-circle" />
          {{ $t('solution.newSolution') }}
        </a-button>
        <a-button @click="recycle">
          <x-icon type="tc-icon-delete" />
          {{ $t('gallery.actions.recycling') }}
        </a-button>
      </div>
    </div>
    <a-spin :spinning="loading" v-if="list.length">
      <draggable
        v-model="list"
        :animation="300"
        @end="sort"
        :class="$style.list"
        :handle="`.${$style.card__dragHandle}`"
      >
        <transition-group>
          <card-item
            @statusRecord="statusRecord"
            v-for="item in list"
            :key="item.pkId"
            :data="item"
            :class="$style.card"
          >
            <template>
              <a-tooltip
                :title="$t('solution.edit')"
                v-if="$p.action('UPDATE') && item.canEdit"
              >
                <x-icon type="edit" @click="edit(item)" />
              </a-tooltip>
              <a-tooltip :title="$t('common.action.preview')">
                <x-icon type="eye" @click="goDetail(item)" />
              </a-tooltip>
              <a-tooltip
                :title="$t('market.template.submitReview')"
                v-if="$p.action('UPDATE') && item.canEdit"
              >
                <x-icon type="tc-icon-push" @click.stop="shelves(item)" />
              </a-tooltip>
              <a-tooltip
                :title="$t('common.action.down')"
                v-if="item.state === 1 && $p.action('UPDATE') && item.canEdit"
              >
                <x-icon type="tc-icon-off-shelf" @click.stop="download(item)" />
              </a-tooltip>
              <a-tooltip
                :title="$t('common.action.delete')"
                v-if="$p.action('DELETE')"
              >
                <x-icon type="tc-icon-delete" @click.stop="delItem(item)" />
              </a-tooltip>

              <!-- <a-tooltip
                :title="$t('common.action.up')"
                v-if="item.state !== 1 && $p.action('UPDATE') && item.canEdit"
              >
                <x-icon type="tc-icon-shelves" @click.stop="shelves(item)" />
              </a-tooltip> -->
              <a-tooltip :title="$t('record.addRecords')">
                <x-icon type="tc-icon-time" @click="viewUpdateRecord(item)" />
              </a-tooltip>
              <a-tooltip
                :title="$t('common.action.renew')"
                v-if="$p.action('UPDATE') && item.canEdit"
              >
                <x-icon type="sync" @click="update(item)" />
              </a-tooltip>
              <a-tooltip
                :title="$t('common.action.sort')"
                v-if="$p.action('UPDATE')"
              >
                <x-icon type="drag" :class="[$style.card__dragHandle]" />
              </a-tooltip>
            </template>
          </card-item>
        </transition-group>
      </draggable>
    </a-spin>

    <!-- </div> -->
    <EmptyContent v-else type="list" />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import CardItem from './components/card-item.vue';
import { createDrawer, createModal } from '@triascloud/x-components';
import SolutionTemplate from './components/solution-template.vue';
import RecycleBin from './components/recycle-bin.vue';
// import InstallDetails from './components/install-details.vue';
import EmptyContent from '@/components/empty-content';
import { sort, standUpAndDown } from '@/services/template-manager/solution';
import { createModuleUrl } from '@/utils';
// import createSolutionApprovalModal from './components/solution-approval-modal.vue';
import SolutionShelvesModal from './components/solution-shelves-modal.vue';

// import SolutionInstallModal from './components/solution-install-modal.vue';
// import SolutionEditRecord from './components/solution-edit-record.vue';
import SolutionUpdateRecordModal from './components/solution-update-record.vue';
// import SolutionAbnormalNotifyModal from './components/solution-abnormal-notify-modal.vue';
// import { TemplateManageSocket } from '@/enum/socket';
// import { globalSocket } from '@triascloud/message-hub';
import {
  getAuthorSolution,
  getSolutionType,
  removeSolution,
} from '@/services/template-manager/solution';
@Component({
  components: {
    CardItem,
    EmptyContent,
    SolutionUpdateRecordModal,
    SolutionTemplate,
  },
})
export default class Index extends Vue {
  activeTab = 'header';
  typeList = [];
  currentId = null;
  list = [];
  keyword = null;
  loading = false;
  goDetail(data) {
    window.open(createModuleUrl('www') + '/solution/' + data.pkId);
  }

  viewUpdateRecord(item) {
    SolutionUpdateRecordModal.createModal({ pkId: item.pkId, edit: false });
  }

  async update(item) {
    await SolutionUpdateRecordModal.createModal({
      pkId: item.pkId,
      edit: true,
    });
    // await standUpAndDown({ pkId: item.pkId, state: 1 });
  }

  get tabs() {
    return [
      {
        name: this.$t('solution.industrySolutions'),
        key: 'header',
      },
      {
        name: this.$t('solution.universalSolution'),
        key: 'footer',
      },
    ];
  }

  async created() {
    await this.getTypeList();
    await this.getList();
  }

  changeType(item) {
    this.currentId = item.pkId;
    this.getList();
  }

  async shelves(item) {
    // await createModal(h => h(createSolutionApprovalModal, {}), {
    //   title: '解决方案审批',
    //   width: '680px',
    //   bodyStyle: {
    //     padding: 0,
    //   },
    // });
    await this.$confirm(this.$t('market.template.applyTip'));
    await standUpAndDown({ pkId: item.pkId, state: 1 });
    this.$message.success(this.$t('common.tips.operationSuccess'));
    this.getList();
  }

  async download(item) {
    await this.$confirm(this.$t('market.template.removeSolution'));
    await standUpAndDown({ pkId: item.pkId, state: 2 });
    this.$message.success(this.$t('common.tips.operationSuccess'));
    this.getList();
  }

  async getList() {
    const data = await getAuthorSolution({
      classify: this.currentId,
      keyword: this.keyword,
    });
    this.list = data;
  }

  async getTypeList() {
    this.typeList = await getSolutionType();
    this.currentId = this.typeList.length ? this.typeList[0].pkId : null;
  }

  async statusRecord() {
    await createModal(h => h(SolutionShelvesModal, {}), {
      title: this.$t('market.template.solutionRecord'),
      width: '470px',
    });
  }

  async add() {
    const data = { classify: this.currentId, pkId: '' };
    // const template = SolutionTemplate.createModal(
    //   { data },
    //   {
    //     wrapClassName: this.$style.solutionModal,
    //   },

    //   ()=>{
    //     refresh: () => {
    //       this.getList();
    //     },
    //     close: () => {
    //       template.visible = false;
    //     },
    //   },
    // );
    const template = await createModal(
      h =>
        h(SolutionTemplate, {
          props: { data },
          on: {
            refresh: () => {
              this.getList();
              template.visible = false;
            },
            close: () => {
              template.visible = false;
            },
          },
        }),
      {
        title: this.$t('solution.solutionTlt'),
        width: '100vw',
        wrapClassName: this.$style.solutionModal,
        cancelText: this.$t('common.action.cancel'),
        okText: this.$t('common.action.confirm'),
      },
    );
  }

  /**
   * @name 拖动排序更新sort字段
   */
  async sort({ newIndex, oldIndex }) {
    if (!this.list.length || newIndex === oldIndex) return;
    this.loading = true;
    const sortItems = [];
    this.list.forEach((item, index) => {
      const sort = index;
      item.sort = sort;
      sortItems.push({
        sort,
        pkId: item.pkId,
      });
    });
    await sort(sortItems);
    this.loading = false;
  }

  //   async showInstallDesc(data) {
  //     await InstallDetails.createModal(data);
  //   }

  async delItem(data) {
    await this.$confirm(this.$t('record.delTip'));
    await removeSolution(data.pkId);
    this.$message.success(this.$t('common.tips.delete'));
    this.getList();
  }

  async edit(item) {
    const data = { pkId: item.pkId, classify: this.currentId };
    const template = await createModal(
      h =>
        h(SolutionTemplate, {
          props: { data },
          on: {
            refresh: () => {
              this.getList();
              template.visible = false;
            },
            close: () => {
              template.visible = false;
            },
          },
        }),
      {
        title: item.name,
        width: '100%',
        wrapClassName: this.$style.solutionModal,
        cancelText: this.$t('common.action.cancel'),
        okText: this.$t('common.action.confirm'),
      },
    );
  }

  async recycle() {
    await createDrawer(
      h =>
        h(RecycleBin, {
          on: {
            refresh: () => {
              this.getList();
            },
          },
        }),

      {
        title: this.$t('solution.solutionTlt'),
        width: 844,
        wrapClassName: this.$style.RecycleBin,
        bodyStyle: { padding: 0 },
        cancelText: this.$t('common.action.confirm'),
        okText: this.$t('common.action.preview'),
      },
    );
  }
}
</script>
<style lang="less" module>
.tabs {
  margin-left: 20px;
  margin-top: 8px;
}

.wrap {
  position: relative;
  height: 100%;
}
.action {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  .search {
    width: 110px;
  }
  :global(.ant-btn) {
    height: 32px;
  }
  :global(.ant-input) {
    height: 32px;
  }
}

.card {
  margin: 0px 30px 30px 0;
  display: inline-block;
  .card__dragHandle {
    cursor: move;
  }
}

.menu {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 10px;
}

.menuItem {
  padding: 10px 0;
  cursor: pointer;
  &:last-child {
    margin-left: 50px;
  }
  border-bottom: 2px solid transparent;
}
.active {
  border-bottom: 2px solid var(--primary);
  color: var(--primary);
}

.add {
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
}

.list {
  margin-left: 20px;
}
// .list {
//   width: 100%;
//   display: grid;
//   grid-template-columns: repeat(auto-fill, 244px);
//   gap: 20px 35px;
//   margin: 20px;
// }

.solutionModal {
  min-width: 1200px;
  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-content {
      max-width: 100%;
      max-height: 100vh;
    }
    .ant-modal-header {
      padding: 10px 24px;
      background: var(--primary);
      border-radius: 0;
      .ant-modal-title {
        color: #fff;
        text-align: left;
        margin-left: 20px;
        font-weight: normal;
      }
    }
    .ant-modal-footer button {
      margin-right: 20px;
    }
    .ant-modal-footer button + button {
      margin-left: 0;
    }
    .ant-modal-close-x {
      height: 40px;
      line-height: 40px;
    }
    .x-modal-action {
      flex-direction: row-reverse;
    }
  }
  :global(.ant-modal-close) {
    left: 0;
    color: #fff;
  }
}
</style>
