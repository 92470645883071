<script>
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { PARAM_TYPE } from './utils';
@Component()
export default class RenderStruct extends Vue {
  @PropSync('item') syncItem;
  @PropSync('store') syncStore;
  @Prop() tooltip;
  @Prop() param;
  @Prop() schemeByType;
  @Prop() checkDefaultValue;
  @Prop({ type: String, default: 'id' }) useKey;

  render() {
    const item = this.syncItem;
    if (!item.children) return '';
    return (
      <a-form-model-item>
        <label slot="label" class={this.$style.label}>
          <span>{item.name}</span>
          {this.tooltip(item)}
          {this.param(item)}
        </label>
        <a-input
          value={item.identifier}
          disabled
          style={{ marginBottom: '15px' }}
        />
        <div class={this.$style.ChildrenStructBox}>
          {item.children.map((v, idx) => {
            if (this.syncItem[PARAM_TYPE]) {
              Vue.set(v, PARAM_TYPE, this.syncItem[PARAM_TYPE]);
            }
            return (
              <div key={'rStruct' + idx + v.id}>
                {this.schemeByType(v, this.syncStore)}
              </div>
            );
          })}
        </div>
      </a-form-model-item>
    );
  }
}
</script>
<style lang="less" module>
.label {
  display: flex;
  align-items: center;
}
.ChildrenStructBox {
  margin-left: 20px;
}
</style>
