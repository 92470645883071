<template>
  <div :class="$style.classifyDiv">
    <a-spin :spinning="spinning">
      <a-form-model layout="vertical" :model="form" ref="form" :rules="rules">
        <a-form-model-item
          :label="$t('connector.dpManage.cProductCategory')"
          prop="production"
        >
          <selectProduct :platformId="platformId" v-model="form.production" />
        </a-form-model-item>
      </a-form-model>
      <a-form-model-item :class="$style.centerRight">
        <a-button :class="$style.mr10" @click="handleCancel">
          {{ $t('common.action.cancel') }}
        </a-button>
        <a-button type="primary" @click="handleSure" :class="$style.mr10">
          {{ $t('connector.dpManage.next') }}
        </a-button>
      </a-form-model-item>
    </a-spin>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content';
import { uuid } from '@triascloud/utils';
import SelectProduct from '@/views/connector/platform-manage/develop/device-manage/device-form/select-product.vue';
@Component({
  components: {
    EmptyContent,
    SelectProduct,
  },
})
export default class ClassifyForm extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  form = {
    production: undefined,
  };
  showAddInput = false;
  spinning = false;
  editMode = false;
  rules = {
    production: [
      {
        required: true,
        message: this.$t('connector.dpManage.chooseProductCategory'),
      },
    ],
  };
  @Watch('editMode')
  handleModeChange(val) {
    let title = val
      ? this.$t('connector.dpManage.productCateManage')
      : this.$t('connector.dpManage.chooseProductCategory');
    this.$emit('changeTitle', title);
  }
  mounted() {
    this.form.idxPlatformId = this.platformId;
  }
  async addClassify() {
    this.$refs.form.validate(async valid => {
      if (valid) {
        if (!this.form.name || this.form.name.length > 14) return;
        this.spinning = true;
        this.form.idempotent = uuid();
        this.form.name = '';
        this.showAddInput = false;
        this.spinning = false;
      }
    });
  }

  async getValue() {
    return true;
  }

  handleCancel() {
    if (this.editMode) {
      this.editMode = false;
    } else {
      this.$emit('close');
    }
  }

  // @Emit('close')
  // @Emit('message')
  async handleSure() {
    await this.$refs.form.validate();
    this.$emit('confirm', this.form.production);
    this.$emit('close');
  }
}
</script>

<style module lang="less">
.classifyDiv {
  .mr10 {
    margin-right: 10px;
  }
  .centerRight {
    margin: 20px 0 0 0;
    text-align: right;
  }
  .listWarp {
    display: flex;
    flex-direction: row;
    margin: 8px 0;
    flex-wrap: wrap;
    .listItem {
      position: relative;
      margin: 10px;
      cursor: pointer;
      border-radius: 5px;
      color: var(--font-sub);
      background-color: var(--body-bg);
    }
    // .listItem:last-child {
    //   border: none;
    // }
    .productText {
      padding: 5px;
      line-height: 32px;
    }
    .activeItem {
      color: var(--block-bg);
      background-color: var(--primary);
      .delBtn {
        color: var(--block-bg);
      }
    }
    .delBtn {
      padding: 5px;
      color: var(--font-info);
      // position: absolute;
      // top: -16px;
      // right: -13px;
    }
    .configBtn {
      color: var(--primary);
    }
  }
  :global {
    .x-empty-content--image {
      height: 100px;
    }
  }
}
</style>
