<template>
  <div>
    <a-table
      :columns="columns"
      :pagination="false"
      :data-source="list"
      :class="$style.table"
      :scroll="{
        x: '100%',
        y: '100%',
      }"
    >
    </a-table>
    <div slot="footer" :class="$style.action">
      <div :class="$style.desc">
        {{ $t('inviteCode.companiesTlt') }}:
        <span>{{ list.length }}{{ $t('common.label.piece') }}</span>
      </div>
      <div :class="$style.closeBtn">
        <a-button type="primary" @click="$emit('handleClosed')">
          {{ $t('common.action.confirm') }}
        </a-button>
        <a-button @click="$emit('handleClosed')">
          {{ $t('common.action.cancel') }}
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';
import { count } from '@/services/tenant-center/tenant-invite-code';
import moment from 'moment';

function createCompanyDetailModal(props) {
  const detail = createModal(
    h =>
      h(CompanyDetail, {
        props,
        on: {
          handleClosed: () => {
            detail.handleClose();
          },
        },
      }),
    {
      title: globalVueI18n.translate('inviteCode.invitedList'),
      width: 700,
    },
  );
  return detail;
}
@Component()
export default class CompanyDetail extends Vue {
  @Prop({ type: String, default: '' }) tenantId;
  static create = createCompanyDetailModal;
  list = [];

  get columns() {
    return [
      {
        dataIndex: 'code',
        title: this.$t('inviteCode.codes'),
      },
      {
        dataIndex: 'tenantName',
        title: this.$t('inviteCode.companyName'),
      },
      {
        dataIndex: 'createTime',
        title: this.$t('inviteCode.registrationTime'),
        customRender: copyTime =>
          copyTime ? moment(copyTime).format('Y-MM-DD HH:mm') : '',
      },
    ].map(item => ({
      ...item,
      sorter: false,
      filter: false,
      align: 'center',
      ellipsis: true,
    }));
  }
  async count() {
    this.list = await count({ tenantId: this.tenantId });
  }

  created() {
    this.count();
  }
}
</script>
<style lang="less" module>
.table {
}
.action {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.closeBtn {
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
}

.desc {
  color: var(--font-info);
  span {
    color: var(--font);
  }
}
</style>
