<template>
  <a-form-model
    :class="$style.apiSettings"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-form-model-item>
      <span slot="label">
        绑定接口
        <a-tooltip
          title="当前创建的API需要关联系统提供的对应接口后方可正常使用，若关联错误将无法使用，如需帮助请联系售后人员！"
          placement="right"
          :overlayStyle="{ maxWidth: '100%' }"
        >
          <a-icon type="info-circle" />
        </a-tooltip>
      </span>

      <a-tree-select
        v-model="form.associatedInterface"
        style="width:300px;"
        show-search
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="bindApiList"
        placeholder="请选择"
        treeNodeFilterProp="title"
        :allowClear="true"
        :getPopupContainer="getPopupContainer"
      >
      </a-tree-select>
    </a-form-model-item>
    <a-form-model-item>
      <span slot="label">
        日志存储
        <a-tooltip placement="right" :overlayStyle="{ maxWidth: '100%' }">
          <a-icon type="info-circle" />
          <span slot="title">
            <p>设置当前接口请求日志保存时长，最长为30天！</p>
          </span>
        </a-tooltip>
      </span>
      <a-radio-group v-model="form.storageTime">
        <a-radio :value="1">
          1天
        </a-radio>
        <a-radio :value="7">7天 </a-radio>
        <a-radio :value="30">30天 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item>
      <span slot="label">
        出参规则
        <a-tooltip placement="right" :overlayStyle="{ maxWidth: '100%' }">
          <a-icon type="info-circle" />
          <span slot="title">
            <p>
              对API类型为“客户”且为“手动触发”的接口，可设置自定义调用提示和API联动：
            </p>
            <p>调用提示：当前API调用后可根据指定条件的出参结果提示调用情况；</p>
            <p>
              API联动：当前API调用后的出参结果符合指定条件后可自动唤醒其它API并将结果填充到被唤醒API的入参中。
            </p>
            <p>
              提示：一条规则可添加多个条件，但只允许添加1个提示、1个联动API、1个提示和1个联动API。
            </p>
          </span>
        </a-tooltip>
      </span>
      <div :class="$style.outParams">
        <div v-for="(rule, index) of form.outgoingRules" :key="'rules' + index">
          <div v-if="rule.conditions.length" :class="$style.formWrap">
            <div :class="$style.paramLogic">
              <x-select
                style="width: 100%"
                size="small"
                v-model="rule.logic"
                :data="logicList"
              ></x-select>
            </div>
            <div :class="$style.paramForm">
              <div
                :class="$style.formLabel"
                v-for="(condition, idx) of rule.conditions"
                :key="'conditions' + idx"
              >
                <a-form-model-item>
                  <div :ref="'Box' + idx">
                    <SettingsDropdown
                      v-model="condition.fieldId"
                      width="250"
                      :tree-data="paramList"
                      :treeDefaultExpandAll="true"
                      @change="
                        (value, label, extra) => {
                          if (condition.fieldType !== ControlTypeEnum.Switch) {
                            condition.value = '';
                          }
                          handleFieldChange(value, label, extra, condition);
                        }
                      "
                    >
                    </SettingsDropdown>
                  </div>
                </a-form-model-item>
                <a-form-model-item>
                  <a-select
                    v-model="condition.algorithm"
                    placeholder="请选择"
                    style="width: 150px"
                    @change="handleAlgorithmChange($event, condition)"
                  >
                    <a-select-option
                      v-for="opt of conditionMap[condition.fieldType]"
                      :key="opt"
                      :value="CompareMap[opt]"
                    >
                      {{ OperationEnum[opt] }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item style="padding-bottom: 15px;">
                  <a-date-picker
                    v-if="condition.fieldType === ControlTypeEnum.Date"
                    v-model="condition.value"
                    style="width: 200px"
                    format="YYYY-MM-DD HH:mm:ss"
                    :disabled="
                      condition.algorithm === 'NULL' ||
                        condition.algorithm === 'NOTNULL'
                    "
                  ></a-date-picker>
                  <a-select
                    v-else-if="condition.fieldType === ControlTypeEnum.Enum"
                    v-model="condition.value"
                    style="width: 200px"
                    :disabled="
                      condition.algorithm === 'NULL' ||
                        condition.algorithm === 'NOTNULL'
                    "
                  >
                    <a-select-option
                      v-for="item in findParamByFieldId(condition.fieldId)"
                      :key="item.text"
                      :value="item.value"
                    >
                      {{ item.text }}
                    </a-select-option>
                  </a-select>
                  <a-switch
                    v-else-if="condition.fieldType === ControlTypeEnum.Switch"
                    v-model="condition.value"
                    :disabled="
                      condition.algorithm === 'NULL' ||
                        condition.algorithm === 'NOTNULL'
                    "
                  ></a-switch>
                  <a-input-number
                    v-else-if="
                      condition.fieldType === ControlTypeEnum.Int ||
                        condition.fieldType === ControlTypeEnum.Float ||
                        condition.fieldType === ControlTypeEnum.Double ||
                        condition.fieldType === DATATYPE.int.toLocaleLowerCase()
                    "
                    :step="
                      condition.fieldType === ControlTypeEnum.Float ||
                      condition.fieldType === ControlTypeEnum.Double
                        ? 0.1
                        : 1
                    "
                    v-model="condition.value"
                    style="width: 200px"
                    :disabled="
                      condition.algorithm === 'NULL' ||
                        condition.algorithm === 'NOTNULL'
                    "
                  ></a-input-number>
                  <a-input
                    v-else
                    v-model="condition.value"
                    style="width: 200px"
                    :disabled="
                      condition.algorithm === 'NULL' ||
                        condition.algorithm === 'NOTNULL'
                    "
                  ></a-input>
                </a-form-model-item>
                <a-form-model-item>
                  <x-icon
                    type="tc-icon-delete"
                    @click="
                      () => {
                        rule.conditions.splice(idx, 1);
                        checkRuleDelete(rule, index);
                      }
                    "
                  />
                </a-form-model-item>
              </div>
            </div>
          </div>
          <div v-if="rule.prompt !== undefined" :class="$style.outParamsItem">
            <a-form-model-item :class="$style.paramLabel"
              >提示</a-form-model-item
            >
            <a-form-model-item style="width:630px;">
              <a-input v-model="rule.prompt"></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <x-icon
                type="tc-icon-delete"
                @click="
                  () => {
                    rule.prompt = undefined;
                    checkRuleDelete(rule, index);
                  }
                "
              />
            </a-form-model-item>
          </div>
          <div v-if="rule.linkageApiId !== ''" :class="$style.outParamsItem">
            <a-form-model-item :class="$style.paramLabel"
              >联动
              <a-icon
                @click="
                  () => {
                    rule.hideParams = !rule.hideParams;
                  }
                "
                type="caret-right"
                :rotate="rule.hideParams ? 0 : 90"
              />
            </a-form-model-item>
            <a-form-model-item style="width:630px;">
              <a-select
                v-model="rule.linkageApiId"
                placeholder="请选择"
                @change="
                  val => {
                    linkAgeChanged(val, rule);
                  }
                "
                style="width: 630px"
              >
                <a-select-option
                  v-for="opt of linkApiList"
                  :key="opt.pkId"
                  :value="opt.pkId"
                >
                  {{ opt.apiName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <x-icon
                type="tc-icon-delete"
                @click="
                  () => {
                    rule.linkageApiId = '';
                    rule.passParameters = [];
                    checkRuleDelete(rule, index);
                  }
                "
              />
            </a-form-model-item>
          </div>
          <div
            :class="[
              $style.linkParams,
              rule.hideParams ? $style.hideParams : $style.showParams,
            ]"
            v-if="rule.linkageApiId !== ''"
          >
            <div
              v-for="(param, idx) of rule.passParameters"
              :key="'parameters' + idx"
              :class="[$style.outParamsItem]"
            >
              <a-form-model-item :class="$style.paramLabel">
              </a-form-model-item>
              <a-form-model-item
                :class="$style.paramLabel"
                style="margin-left:0"
              >
                传参{{ idx + 1 }}</a-form-model-item
              >
              <a-form-model-item>
                <SettingsDropdown
                  v-model="param.sourceFieldId"
                  width="258"
                  :tree-data="paramList"
                  :treeDefaultExpandAll="true"
                >
                </SettingsDropdown>
              </a-form-model-item>
              <a-form-model-item> 到</a-form-model-item>
              <a-form-model-item>
                <SettingsDropdown
                  v-model="param.targetFieldId"
                  width="258"
                  :tree-data="rule.targetFields"
                  :treeDefaultExpandAll="false"
                  @change="
                    (value, label, extra) => {
                      handleTargetChange(value, label, extra, rule, param);
                    }
                  "
                >
                </SettingsDropdown>
              </a-form-model-item>
              <a-form-model-item>
                <x-icon
                  type="tc-icon-delete"
                  @click="
                    () => {
                      rule.passParameters.splice(idx, 1);
                      checkRuleDelete(rule, index);
                    }
                  "
                />
              </a-form-model-item>
            </div>
          </div>
          <div>
            <a-button
              icon="plus"
              type="link"
              size="small"
              :class="$style.mt10"
              @click="handleAddCondition(index)"
            >
              添加条件
            </a-button>
            <!-- <a-button
              v-if="rule.linkageApiId === ''"
              icon="plus"
              type="link"
              size="small"
              :class="$style.mt10"
              @click="rule.linkageApiId = undefined"
            >
              联动API
            </a-button> -->
            <a-button
              v-if="rule.linkageApiId !== ''"
              icon="plus"
              type="link"
              size="small"
              :class="$style.mt10"
              @click="
                () => {
                  if (rule.passParameters.length >= 20) {
                    $message.error('单条规则最多接收20个出参！');
                    return;
                  }
                  rule.passParameters.push({
                    sourceFieldId: undefined,
                    targetFieldId: undefined,
                    inputType: undefined,
                  });
                }
              "
            >
              添加传参
            </a-button>
            <a-button
              v-if="rule.prompt === undefined"
              icon="plus"
              type="link"
              size="small"
              :class="$style.mt10"
              @click="rule.prompt = ''"
            >
              添加提示
            </a-button>
          </div>
          <a-divider />
        </div>
        <div>
          <a-button
            icon="plus"
            type="primary"
            size="small"
            :class="$style.mt10"
            @click="handleAddRule()"
            >添加规则
          </a-button>
        </div>
      </div>
    </a-form-model-item>
    <a-form-model-item>
      <div :class="$style.btns">
        <a-button @click="$emit('close')">取消</a-button>
        <a-button
          type="primary"
          style="margin-left: 10px"
          @click="saveApiConfig()"
          >保存</a-button
        >
      </div>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import {
  CompareMap,
  OperationEnum,
  conditionMap,
} from './components/compare-enum';
import {
  getApiList,
  getApiBasicSetting,
  getApiSettings,
  setApiSettings,
  getBindApiList,
} from '@/services/iot-platform/platformInterface';
import { deepClone } from '@triascloud/utils';
import SettingsDropdown from './components/settings-dropdown.vue';
import { ControlTypeEnum } from '../data-template/template-design/form/controls';
import { DATATYPE } from '../utils';
@Component({
  components: {
    SettingsDropdown,
  },
})
export default class ApiSettings extends Vue {
  @Prop({ type: String, default: '' }) apiId;
  @Prop({ type: String, default: '' }) platformId;

  ControlTypeEnum = ControlTypeEnum;
  DATATYPE = DATATYPE;

  findParamByFieldId(fieldId) {
    const fieldCheck = field => {
      if (field.type === ControlTypeEnum.Enum) {
        if (field.optionList) {
          return field.optionList;
        } else if (field.details) {
          return field.details.map(i => {
            return {
              value: i.value,
              text: i.key,
            };
          });
        }
      }
      return [];
    };
    let array = [];
    for (let field of this.paramList) {
      if (field.id === fieldId) {
        array = fieldCheck(field);
        break;
      }
      if (field.children) {
        for (let child of field.children) {
          if (child.id === fieldId) {
            array = fieldCheck(child);
            break;
          }
        }
      }
      if (array.length) {
        break;
      }
    }
    return array;
  }

  get logicList() {
    return [
      { text: '且', value: 'AND' },
      { text: '或', value: 'OR' },
    ];
  }

  linkApiList = [];
  form = {
    associatedInterface: '',
    outgoingRules: [
      {
        prompt: undefined,
        linkageApiId: '',
        logic: 'AND',
        conditions: [
          {
            algorithm: undefined,
            fieldId: undefined,
            value: '',
            fieldType: '',
            identifier: '',
          },
        ],
        passParameters: [],
        targetFields: [],
      },
    ],
    pkId: '',
    storageTime: 0,
  };
  rules = {};
  OperationEnum = OperationEnum;
  CompareMap = CompareMap;
  conditionMap = conditionMap;
  async created() {
    this.form.pkId = this.apiId;
    this.paramList = await this.getBaseSettings(this.apiId, 'out');
    this.getApiConfig();
    this.getCustomApi();
    this.getBindApis();
  }
  bindApiList = [];
  async getBindApis() {
    this.bindApiList = [];
    const apiData = await getBindApiList(this.platformId);
    for (const key in apiData) {
      const childs = [];
      for (const child of apiData[key]) {
        childs.push({
          title: child.interfaceName,
          value: child.id,
          key: child.id,
          disabled: !child.state,
        });
      }
      this.bindApiList.push({
        title: key,
        value: key,
        key,
        selectable: false,
        children: childs,
      });
    }
  }
  getPopupContainer() {
    return this.$el;
  }
  async linkAgeChanged(apiId, rule) {
    rule.passParameters.map(param => {
      param.targetFieldId = undefined;
      param.inputType = undefined;
    });
    rule.targetFields = await this.getBaseSettings(apiId, 'in');
  }
  checkRuleDelete(rule, index) {
    if (
      rule.conditions.length === 0 &&
      rule.passParameters.length === 0 &&
      rule.prompt === undefined &&
      rule.linkageApiId === ''
    ) {
      this.form.outgoingRules.splice(index, 1);
    }
  }
  async getApiConfig() {
    const res = await getApiSettings(this.apiId);
    for (let key in this.form) {
      if ('outgoingRules' === key && res.outgoingRules.length) {
        this.form.outgoingRules = [];
        for (const rule of res.outgoingRules) {
          rule.targetFields = [];
          rule.hideParams = false;
          if (rule.linkageApiId) {
            rule.targetFields = await this.getBaseSettings(
              rule.linkageApiId,
              'in',
            );
          }
          this.form[key].push(rule);
        }
      } else {
        this.form[key] = res[key];
      }
    }
    if (!this.form.associatedInterface) {
      this.form.associatedInterface = undefined;
    }
  }
  handleTargetChange(value, label, extra, rule, param) {
    let path = extra.triggerNode.pos.split('-');
    param.inputType = rule.targetFields[path[1]]?.name.toUpperCase();
  }
  handleFieldChange(value, label, extra, condition) {
    let path = extra.triggerNode.pos.split('-');
    path.splice(0, 1);
    let node = this.getNodeItem(path, {
      children: this.paramList,
    });
    if (this.conditionMap[node.type]) {
      condition.fieldType = node.type;
    }
    condition.identifier = node.identifier;
  }
  getNodeItem(path, parent) {
    if (path.length > 1) {
      let index = path[0];
      path.splice(0, 1);
      return this.getNodeItem(path, parent.children[index]);
    } else {
      return parent.children[path[0]];
    }
  }
  handleAddCondition(index) {
    if (this.form.outgoingRules[index].conditions.length >= 10) {
      this.$message.error('单条规则最多创建10个条件！');
      return;
    }
    this.form.outgoingRules[index].conditions.push({
      algorithm: undefined,
      fieldId: undefined,
      identifier: '',
      fieldType: '',
      value: '',
    });
  }
  handleAddRule() {
    this.form.outgoingRules.push({
      prompt: undefined,
      linkageApiId: '',
      logic: 'AND',
      conditions: [
        {
          algorithm: undefined,
          fieldId: undefined,
          identifier: '',
          value: '',
          fieldType: '',
        },
      ],
      passParameters: [],
      targetFields: [],
      hideParams: false,
    });
  }
  paramList = [];
  async getCustomApi() {
    const params = {
      current: 1,
      size: 1000,
      platformId: this.platformId,
    };
    const { records } = await getApiList(params);
    for (let i = 0; i < records.length; i++) {
      if (records[i].pkId === this.apiId) {
        records.splice(i, 1);
        break;
      }
    }
    this.linkApiList = records;
  }
  getRequestParams(res, key) {
    let nameMap = {
      header: 'requestHeaderParam',
      body: 'requestBodyParam',
      query: 'requestUrlParam',
    };
    if (!res[nameMap[key]]) {
      return null;
    }
    let list = [];
    if (key === 'query') {
      list = JSON.parse(res[nameMap[key]]).filter(item => {
        return !item.isChildren;
      });
    } else if (key === 'header') {
      JSON.parse(res[nameMap[key]]).map(item => {
        list.push({
          id: item.id,
          name: item.identifier,
          type: 'text',
          open: true,
          isChildren: true,
          selectable: true,
        });
      });
    } else {
      list = JSON.parse(res[nameMap[key]]).data.filter(item => {
        return !item.isChildren;
      });
    }
    return {
      id: key,
      name: key,
      open: true,
      isChildren: false,
      selectable: false,
      children: list,
    };
  }
  async getBaseSettings(apiId, reqType) {
    try {
      const res = await getApiBasicSetting(apiId);
      let list = [];
      if (reqType === 'out') {
        list = JSON.parse(res.returnParam).filter(item => {
          return !item.isChildren;
        });
        return list;
      } else {
        let paramList = [];
        let headers = this.getRequestParams(res, 'header');
        let bodys = this.getRequestParams(res, 'body');
        let querys = this.getRequestParams(res, 'query');
        querys && paramList.push(querys);
        bodys && paramList.push(bodys);
        headers && paramList.push(headers);
        return paramList;
      }
    } catch {
      return [];
    }
  }
  @Debounce(200)
  async saveApiConfig() {
    let formData = deepClone(this.form);
    formData.outgoingRules.map(item => {
      delete item.targetFields;
      delete item.hideParams;
    });
    if (!formData.associatedInterface) {
      formData.associatedInterface = '';
    }
    await setApiSettings(formData);
    this.$message.success('保存成功');
    this.$emit('refresh');
  }

  handleAlgorithmChange(value, item) {
    if (value === CompareMap.notNull || value === CompareMap.null) {
      item.value = '';
    }
  }
}
</script>
<style lang="less" module>
.apiSettings {
  :global(.ant-select-tree-treenode-disabled) {
    background-color: var(--form-disabled);
  }
  .outParams {
    .formWrap {
      display: flex;
      .paramLogic {
        width: 36px;
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 10px;
        :global {
          .ant-select-selection {
            background-color: var(--block-bg);
          }
          .ant-select-selection__rendered {
            margin: 0 4px;
          }
          .ant-select-arrow {
            right: 2px;
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 12px;
          border: 1px solid var(--btn-border);
          border-right: none;
          pointer-events: none;
        }
      }
    }
    .paramForm {
      margin-bottom: -15px;
      .formLabel {
        display: flex;
        margin-bottom: -15px;
        :global(.ant-form-item) {
          margin-right: 15px;
        }
      }
    }
    .outParamsItem {
      display: flex;
      margin-top: 15px;
      :global(.ant-form-item) {
        margin-right: 15px;
      }
      .paramLabel {
        width: 70px;
        margin-left: 15px;
        margin-right: 0;
      }
    }
    .linkParams {
      transition: max-height 1s;
      overflow: hidden;
    }
    .hideParams {
      max-height: 0;
    }
    .showParams {
      max-height: 950px;
    }
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
