<template>
  <div
    :class="[$style.item, checked ? $style.checked : '']"
    @click="handleSelectIcon"
  >
    <Icon :icon="data" :class="$style.icon" />
    <EditPopover
      :value="data.iconName"
      @click.stop="() => false"
      @change="handleChangeName"
    />
  </div>
</template>
<script>
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import Icon from '../icon';
import EditPopover from '../edit-popover';
import { updateIcon } from '@/services/resource/icon';
import { IconLibrarySymbol } from '../../context';

@Component({
  components: { Icon, EditPopover },
})
export default class IconItem extends Vue {
  @Prop() data;
  @Prop({ type: Boolean, default: false }) checked;
  @Inject(IconLibrarySymbol) IconLibrary;

  async handleChangeName(value) {
    if (this.data.iconName !== value) {
      await updateIcon(value, this.data.pkId);
    }
  }

  handleSelectIcon() {
    if (this.IconLibrary.limit) {
      this.$emit('replace', this.data.pkId);
      return;
    }
    this.$emit('change', this.data.pkId);
  }
}
</script>
<style lang="less" module>
:global {
  .tint {
    --block-hover-bg: #e9e9e9;
  }
  .dark {
    --block-hover-bg: #343434;
  }
}
.item {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 5px;
  border-radius: 4px;

  .icon {
    font-size: 38px;
    line-height: 1em;
    margin-bottom: 10px;
  }

  &:hover {
    background: var(--block-hover-bg);
    border-radius: 4px;
  }

  &.checked {
    border: 1px solid var(--primary);
  }
}
</style>
