// @ts-check
import { BaseControl } from '../BaseControl';
import DesignComponent from './design.vue';
import ConfigComponent from './config.vue';
import DataSetComponent from './dataSet.vue';

/** @name 新增数据组 */
class AddDataSetControl extends BaseControl {
  /** @readonly */
  type = 'addDataSet';

  label = 'controls.addDataSet.label';

  icon = 'tc-icon-form-add';

  actionOpen = true;

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    defaultValue: {
      type: 'custom',
      custom: '',
    },
    // hideTitle: true,
    paramType: undefined, // 类型（入参/出参）
    disabled: false,
    paramArr: [],
  });
  FormComponent = DataSetComponent;

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;
}

export default new AddDataSetControl();
