<template>
  <div :class="[$style.index, 'wrapBox']">
    <Download :moreUrl="`${appUrl}/download`" more-target="_self" />
    <span class="text">{{ $t('common.downloadList') }}</span>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { Download } from '@triascloud/x-blocks';
import { createModuleUrl } from '@/utils';
@Component({
  components: {
    Download,
  },
})
export default class DownloadWrap extends Vue {
  appUrl = createModuleUrl('app');
}
</script>
<style lang="less" module>
.index {
  position: relative;
  margin-right: 30px;
  :global {
    .x-download {
      display: flex;
      align-items: center;
      height: 40px;
      width: 32px;
      color: var(--font);
      margin: 0;
      padding: 0 10px;
      z-index: 1;
      .x-download-icon {
        font-size: 20px;
      }
      .ant-badge-dot {
        top: 11px;
        left: 22px;
      }
      .x-download-icon--downloading {
        font-size: 14px;
        min-width: 20px;
      }
    }
    .text {
      position: absolute;
      top: 0px;
      left: 38px;
    }
  }
  &:hover {
    :global {
      .text,
      .x-download {
        color: var(--primary);
      }
    }
  }
}
</style>
