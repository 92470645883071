<template>
  <div :class="$style.template">
    <div :class="$style.headerLeft">
      <span
        >{{ $t('template.review.pushTenantName') }}：{{
          data.pushTenantName
        }}</span
      >
      <span
        >{{ $t('dictionary.pushTime') }}：{{
          momentFormat(data.pushTime)
        }}</span
      >
      <span
        >{{ $t('template.review.updateTime') }}：{{
          momentFormat(data.updateTime)
        }}</span
      >
    </div>
    <div :class="$style.headerRight">
      <template v-if="review">
        <template v-if="isChecked">
          <a-button @click="checkResult(1)">{{
            $t('common.action.pass')
          }}</a-button>
          <a-button @click="checkResult(0)">{{
            $t('common.action.fail')
          }}</a-button></template
        >
      </template>
      <template v-else>
        <a-button @click="$developing">{{ $t('template.edit') }}</a-button>
        <a-button @click="$developing">{{ $t('template.update') }}</a-button>
      </template>
    </div>
    <iframe
      :class="$style.iframe"
      :src="applicationUrl"
      frameborder="0"
    ></iframe>
  </div>
</template>
<script>
import { createModuleUrl } from '@/utils';
import { createDrawer } from '@triascloud/x-components';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
function createApplicationPreviewDrawer(props, listener) {
  return createDrawer(
    h => h(ApplicationPreviewDrawer, { props, on: listener }),
    {
      title: props.data.name || props.data.pushName,
      width: 1700,
      bodyStyle: {
        padding: 0,
      },
      wrapClassName: 'application-preview-drawer--wrapper',
    },
  );
}
@Component()
export default class ApplicationPreviewDrawer extends Vue {
  @Prop() data;
  @Prop({ type: Boolean, default: false }) review;
  static createModal = createApplicationPreviewDrawer;

  get applicationUrl() {
    return (
      createModuleUrl('app') + `/application/preview/${this.data.templateId}`
    );
  }

  momentFormat(time) {
    return moment(time).format('Y-MM-DD H:mm');
  }
  checkResult(type) {
    this.$emit('check', this.data, type);
  }
  isChecked = false;
  created() {
    this.isChecked = this.review && this.data.isChecked === 0;
  }
}
</script>
<style lang="less" module>
:global {
  .application-preview-drawer--wrapper .ant-drawer-content-wrapper {
    max-width: 100vw;
  }
  .ant-drawer-header {
    padding: 10px 190px 10px 50px;
  }
  .ant-drawer-wrapper-body {
    position: relative;
  }
  .ant-drawer-title {
    width: 100%;
    display: flex;
    font-size: 16px;
    color: #ffffff;
    .x-ellipsis {
      width: 200px;
    }
  }
}
.template {
  width: 100%;
  height: 100%;
}

.headerLeft {
  position: absolute;
  top: 0;
  left: 250px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  margin-right: 10px;

  span + span {
    margin-left: 10px;
  }
}

.headerRight {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 80px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #ffffff;
    margin-right: 10px;
    padding: 0;
    text-align: center;

    &:hover {
      background: #ffffff;
      color: var(--primary);
    }
  }
}

.iframe {
  width: 100%;
  height: 100%;
}
</style>
