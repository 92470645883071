<template>
  <x-data-table
    ref="table"
    :class="$style.table"
    :columns="columns"
    base-path="/resource/productFeedback/report"
    :service="service"
    :custom-row="
      record => ({
        on: {
          click: () => openDetail(record.pkId),
        },
        style: {
          cursor: 'pointer',
        },
      })
    "
    :selected-row-keys.sync="selections"
  >
    <template v-slot:action>
      <a-button v-if="$p.action('DELETE')" type="link" @click="remove">
        <x-icon type="tc-icon-delete" />
        {{ $t('common.action.delete') }}
      </a-button>
    </template>
  </x-data-table>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import moment from 'moment';
import { DataSourceService } from '@triascloud/services';
import {
  FeedbackState,
  FeedbackStateText,
  // FeedBackTypeText,
} from '@/enum/feedback';
import InlineList from '@/components/inline-list';
import FeedbackDetailDrawer from './components/feedback-detail-drawer.vue';
import { createDrawer, createFormModal } from '@triascloud/x-components';
import {
  batchDeleteFeedback,
  getFeedbackList,
} from '@/services/resource/productFeedback';
import {
  updateState,
  feedbackReply,
} from '@/services/resource/productFeedbackReply';
import FeedbackReplyModal from './components/feedback-reply-modal.vue';
@Component({
  components: { LayoutContent },
})
export default class Index extends Vue {
  get columns() {
    return [
      {
        title: this.$t('feedback.title'),
        dataIndex: 'title',
        width: 150,
        ellipsis: true,
        customCell: () => ({
          style: {
            color: 'var(--font-active)',
          },
        }),
      },
      {
        title: this.$t('feedback.relatedFeatures'),
        dataIndex: 'relatedFunction',
        // customRender: feedbackType =>
        //   this.$t(FeedBackTypeText[feedbackType]) || this.$t('feedback.other'),
        // generateFilterOptions: list =>
        //   [...new Set(list.map(item => item.feedbackType))].map(
        //     feedbackType => ({
        //       text: this.$t(FeedBackTypeText[feedbackType]),
        //       value: feedbackType,
        //     }),
        //   ),
        width: 130,
      },
      {
        title: this.$t('feedback.annex'),
        dataIndex: 'accessory',
        filter: false,
        sorter: false,
        customRender: accessory => {
          try {
            accessory = JSON.parse(accessory);
          } catch (error) {
            accessory = [];
          }
          return <InlineList list={accessory} />;
        },
        width: 130,
      },
      {
        title: this.$t('feedback.createTime'),
        dataIndex: 'createDate',
        customRender: createDate => moment(createDate).format('Y-MM-DD HH:mm'),
        generateFilterOptions: list =>
          [...new Set(list.map(item => item.createDate))].map(createDate => ({
            text: moment(createDate).format('Y-MM-DD H:mm'),
            value: createDate,
          })),
        width: 130,
        type: 'date',
      },
      {
        title: this.$t('feedback.status'),
        dataIndex: 'state',
        customRender: state => this.$t(FeedbackStateText[state]),
        generateFilterOptions: list =>
          [...new Set(list.map(item => item.state))].map(state => ({
            text: this.$t(FeedbackStateText[state]),
            value: state,
          })),
        width: 130,
      },
      {
        title: this.$t('feedback.processingTime'),
        dataIndex: 'replyTime',
        width: 130,
        type: 'date',
        generateFilterOptions: list =>
          [...new Set(list.map(item => item.replyTime))].map(replyTime => ({
            text: moment(replyTime).format('Y-MM-DD H:mm'),
            value: replyTime,
          })),
      },
      {
        title: this.$t('feedback.enterprise'),
        width: 130,
        dataIndex: 'tenantName',
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('feedback.processor'),
        dataIndex: 'replyName',
        width: 130,
        filter: false,
        sorter: false,
      },
      {
        width: 180,
        title: this.$t('common.action.operating'),
        key: 'action',
        filter: false,
        sorter: false,
        customCell: () => ({
          on: {
            click: e => {
              e.stopPropagation();
            },
          },
        }),
        customRender: row => {
          const process = (
            <a
              v-show={this.$p.action('VERIFY')}
              class={this.$style.action}
              onClick={() =>
                this.handleFeedback(
                  row,
                  FeedbackState.Processed,
                  this.$t('feedback.state.processed'),
                )
              }
            >
              {this.$t('feedback.state.processed')}
            </a>
          );
          const reply = (
            <a
              v-show={this.$p.action('REPLY')}
              class={this.$style.action}
              onClick={() => this.handleReply(row)}
            >
              {this.$t('feedback.addReply')}
            </a>
          );
          const adopt = (
            <a
              v-show={this.$p.action('VERIFY')}
              class={this.$style.action}
              onClick={() =>
                this.handleFeedback(
                  row,
                  FeedbackState.Adopted,
                  this.$t('feedback.adopt'),
                )
              }
            >
              {this.$t('feedback.adopt')}
            </a>
          );
          const reject = (
            <a
              v-show={this.$p.action('VERIFY')}
              class={[this.$style.action, this.$style.danger]}
              onClick={() =>
                this.handleFeedback(
                  row,
                  FeedbackState.Rejected,
                  this.$t('feedback.reject'),
                )
              }
            >
              {this.$t('feedback.reject')}
            </a>
          );

          if (row.state === FeedbackState.Waiting) {
            return [adopt, reject, reply];
          } else if (row.state === FeedbackState.Adopted) {
            return [reply, process];
          } else {
            return [reply];
          }
        },
      },
    ];
  }

  get service() {
    const service = new DataSourceService('/resource/productFeedback/report');
    service.getList = report =>
      getFeedbackList({
        ...report,
        sorters: [{ field: 'createDate', order: 'DESC' }, ...report.sorters],
      });
    return service;
  }

  openDetail(pkId) {
    if (!pkId) return;
    const drawer = createDrawer(
      h =>
        h(FeedbackDetailDrawer, {
          props: { pkId },
          on: {
            refresh: () => {
              this.$refs.table.loadData();
            },
            remove: () => {
              this.$refs.table.loadData();
              drawer.visible = false;
            },
          },
        }),
      {
        title: this.$t('feedback.detail'),
        width: 690,
        bodyStyle: { padding: 0 },
      },
    );
  }
  selections = [];
  async remove() {
    if (!this.selections.length) {
      return this.$message.warning(this.$t('feedback.unselected'));
    }
    await this.$confirm(this.$t('feedback.deleteConfirm'));
    await batchDeleteFeedback(this.selections);
    this.$refs.table.loadData();
    this.$message.success(this.$t('common.tips.delete'));
  }
  /**
   * @name 审批
   * @param row 反馈数据
   * @param type 回复类型
   */
  async handleFeedback(row, state, operate) {
    await this.$confirm(this.$t('feedback.confirmTip', { value: operate }));
    await updateState({
      pkId: row.pkId,
      state,
    });
    this.$refs.table.loadData();
    this.$message.success(this.$t('common.tips.operationSuccess'));
  }
  /**
   * @name 回复
   */
  async handleReply(row) {
    const content = await createFormModal(() => <FeedbackReplyModal />, {
      title: this.$t('feedback.reply'),
      width: '442px',
    });
    await feedbackReply({
      content,
      idxFeedbackId: row.pkId,
    });
    this.$refs.table.loadData();
    this.$message.success(this.$t('feedback.replySuccess'));
  }
}
</script>
<style lang="less" module>
.table {
  padding: 20px;
}
.content {
  padding: 16px;
  height: 100%;
}
.action {
  margin-left: 10px;
}
.danger {
  color: var(--danger);
  &:hover {
    color: var(--danger);
  }
}
</style>
