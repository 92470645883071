<template>
  <a-form-model ref="form" :model="formData" :rules="rules">
    <template v-if="!readonly">
      <div :class="$style.title">{{ $t('template.review.classifyTip') }}</div>
      <a-form-model-item
        v-for="item in classify"
        :key="item.pkId"
        :label="item.name"
        prop="classify"
      >
        <a-select
          v-model="item.selected"
          allow-clear
          @change="$refs.form.clearValidate('classify')"
        >
          <a-select-option
            v-for="child in item.childList"
            :key="child.pkId"
            :value="child.pkId"
            >{{ child.name }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </template>
    <a-form-model-item :label="$t('dictionary.auditOpinion')" prop="advice">
      <a-textarea
        v-model="formData.advice"
        :rows="4"
        :max-length="200"
        :placeholder="
          $t('common.placeholder.input') + $t('gallery.label.remark')
        "
      ></a-textarea>
    </a-form-model-item>
    <div
      :class="[$style.template, { [$style['template-readonly']]: readonly }]"
    >
      <application-setting-modal
        v-if="templateType === TemplateType.Application"
        :readonly="readonly"
        ref="template"
        :template-id="data.requestId"
        :service="getReviewDetail"
      />
      <form-setting-modal
        v-else-if="templateType === TemplateType.Form"
        :readonly="readonly"
        ref="template"
        :template-id="data.requestId"
        :service="getFormReviewDetail"
      />
      <print-setting-modal
        v-else-if="templateType === TemplateType.Print"
        :readonly="readonly"
        ref="template"
        :template-id="data.requestId"
        :service="getFormReviewDetail"
      />
    </div>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import {
  review,
  getReviewDetail,
} from '@/services/template-manager/templateChecker';
import { getClassifyTree } from '@/services/template-manager/tpClassify';
import { TemplateType, TemplateLabelFrom, CheckResult } from '@/enum/template';
import ApplicationSettingModal from '@/views/market/components/application-setting-modal.vue';
import FormSettingModal from '@/views/market/components/form-setting-modal.vue';
import PrintSettingModal from '@/views/market/components/print-setting-modal.vue';

import { generateMap } from '@triascloud/utils';
// import { globalVueI18n } from '@triascloud/i18n';
function createReviewFormModal(props, { title, width }) {
  return createFormModal(h => h(ReviewFormModal, { props }), {
    title,
    width: width || 500,
  });
}
@Component({
  components: { ApplicationSettingModal, FormSettingModal, PrintSettingModal },
})
export default class ReviewFormModal extends Vue {
  static createModal = createReviewFormModal;
  @Prop({ type: Object }) data;
  @Prop({ type: String, default: TemplateType.Application }) templateType;
  TemplateType = TemplateType;
  CheckResult = CheckResult;
  formData = {
    advice: '',
    checkResult: 0,
    classify: [],
    requestId: '',
  };
  classify = [];
  getReviewDetail = getReviewDetail;
  created() {
    this.formData = {
      ...this.formData,
      ...this.data,
    };
    this.getClassify();
  }
  userLabelMap = {};
  getFormReviewDetail(templateId) {
    return getReviewDetail(templateId).then(data => {
      this.userLabelMap = generateMap(data.labelIds, 'pkId', () => true);
      return data;
    });
  }
  get rules() {
    return {
      classify: [
        {
          validator: (_, _value, callback) => {
            if (!this.readonly && !this.classify.some(item => item.selected)) {
              callback(new Error(this.$t('template.review.leastOne')));
            } else {
              callback();
            }
          },
        },
      ],
      advice: [
        {
          required: true,
          message: this.$t('validate.required', {
            value: this.$t('dictionary.auditOpinion'),
          }),
        },
        {
          min: 2,
          max: 200,
          message: this.$t('template.review.validateOpinion'),
        },
      ],
    };
  }
  get readonly() {
    return this.formData.checkResult === CheckResult.Fail;
  }
  async getClassify() {
    this.classify = (await getClassifyTree(this.templateType)).map(item => ({
      ...item,
      selected: undefined,
    }));
  }
  async getValue() {
    await Promise.all([
      this.$refs.form.validate(),
      this.readonly
        ? Promise.resolve()
        : this.$refs.template.$refs.form.validate(),
    ]);
    const templateData = this.$refs.template.formatFormData;
    await review({
      ...this.formData,
      app:
        !this.readonly && this.templateType === TemplateType.Application
          ? templateData
          : null,
      form:
        !this.readonly && this.templateType === TemplateType.Form
          ? {
              ...templateData,
              labelList: templateData.labelIds.map(pkId => ({
                labelFrom: this.userLabelMap[pkId]
                  ? TemplateLabelFrom.User
                  : TemplateLabelFrom.System,
                pkId,
              })),
            }
          : null,
      print:
        !this.readonly && this.templateType === TemplateType.Print
          ? {
              ...templateData,
              labelList: templateData.labelIds.map(pkId => ({
                labelFrom: this.userLabelMap[pkId]
                  ? TemplateLabelFrom.User
                  : TemplateLabelFrom.System,
                pkId,
              })),
            }
          : null,
      classify: this.classify
        .filter(item => item.selected)
        .map(item => item.selected),
    });
    this.$message.success(this.$t('common.tips.auditSuccessful'));
  }
}
</script>
<style lang="less" module>
.title {
  margin-bottom: 20px;
}
.template {
  margin-bottom: 20px;
}
.template-readonly {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    z-index: 2;
  }
}
</style>
