import request from '../request';

const basePath = '/resource/icon';

/**
 * @name 图标列表（分页）
 * @param {{iconQuery: {current: number, size: number}, keyword: string, updateIconName: boolean}} params
 */
export const getIconList = params =>
  request(`${basePath}/listAll`, {
    method: 'POST',
    body: params,
  });

/**
 * @name 图标编辑
 * @param {string} iconName 图标名称
 * @param {string} pkId 主键ID
 */
export const updateIcon = (iconName, pkId) =>
  request(`${basePath}/update`, {
    method: 'POST',
    body: { iconName, pkId },
  });

/**
 * @name 图标引用详情
 * @param {string} middleId 查询中间ID
 * @param {number} skip 分页-当前页
 */
export const getQuoteDetail = (middleId, skip) =>
  request(`${basePath}/quoteDetail`, {
    method: 'POST',
    body: { middleId, skip, size: 20 },
  });

export const importIcon = params => {
  return request(`${basePath}/import`, {
    method: 'POST',
    body: params,
  });
};
