import Vue from 'vue';
import { globalVueI18n, ensureLocale } from '@triascloud/i18n';
import {
  crossStorage,
  CrossStorageEventType,
  CrossStorageWeb,
} from '@triascloud/cross-storage';
import { SSOClient, ssoClient, SSOClientWeb } from '@triascloud/sso-client';
import { globalSocket } from '@triascloud/message-hub';
import '@triascloud/ant-design-theme/dist/index.css';
import '@triascloud/x-components/dist/index.css';
import '@triascloud/x-blocks/dist/index.css';
import './global.less';

import './hooks';
import { createModuleUrl } from './utils';
// import TranslatePath from '@triascloud/x-blocks';
import install from './install.js';
import { ExpiredLogin, PathHelper } from '@triascloud/x-blocks';
import store from './store';
import router from './router';
import App from './App.vue';
import { checkHost } from '@/utils/custom-host.js';
import { CrossStorageMemory } from '@/utils/CrossStorageMemory.js';
import { getCurrentInfoByToken } from '@/services/template-manager/website';

async function bootstrap() {
  // 自定义域名
  const isCustomHost = await checkHost('co');
  Vue.use(install);
  // 初始化单点登录环境
  const ssoOrigin = createModuleUrl('account');
  // const ssoOrigin = 'http://localhost:8000/';
  ssoClient.init(
    new SSOClientWeb({
      server: ssoOrigin,
      requestExpiredLogin: () =>
        ExpiredLogin.create(
          isCustomHost
            ? `${ssoOrigin}?${
                SSOClient.TOKEN_KEY
              }=${ssoClient.getTokenSync()}&to=${encodeURIComponent(
                location.href,
              )}`
            : ssoOrigin,
        ),
    }),
  );

  // 初始化跨域储存环境
  const data = await crossStorage.init(
    isCustomHost?.useLocalCrossStorage
      ? new CrossStorageMemory({
          loadExtraStorage: token => getCurrentInfoByToken(token),
        })
      : new CrossStorageWeb(`${ssoOrigin}/storage.html`),
  );
  // 初始化国际化
  Vue.use(globalVueI18n, {
    defaultLocale: ensureLocale(data.locale),
    defaultModule: 'co',
    // renderEmpty: key => key,
  });
  Vue.use(PathHelper);

  // 要求登录后才能访问
  await ssoClient.login();

  const token = await ssoClient.getToken();
  // websocket链接
  globalSocket.init(
    `${createModuleUrl('api', 'https:')}?X-Access-Token=${token}`,
  );
  // 监听token变化重连
  crossStorage.serverEmitter.on(
    CrossStorageEventType.Update,
    ({ key, value }) => {
      if (key !== SSOClient.TOKEN_KEY) return;
      globalSocket.close();
      if (!value) return;
      globalSocket.init(
        `${createModuleUrl('api', 'https:')}?X-Access-Token=${value}`,
      );
    },
  );

  // 初始化Vue
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
}

bootstrap();
