<template>
  <div ref="PluginCharts" />
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { crossStorageModule } from '@/enum/store';

import ResizeObserver from 'resize-observer-polyfill';
import { globalVueI18n } from '@triascloud/i18n';

@Component({
  components: {},
})
export default class Charts extends Vue {
  @Prop({ type: Object }) data;
  @crossStorageModule.State skin;

  /** @type { ResizeObserver } */
  _ob = null;
  myChart = null;
  mounted() {
    this._ob = new ResizeObserver(this.chartResize);
    this._ob.observe(this.$refs.PluginCharts);
    this.initChart();
  }
  chartResize() {
    this.myChart.resize();
  }
  get option() {
    const res = this.data;
    return {
      grid: {
        left: '1%',
        right: '0',
        bottom: '0%',
        containLabel: true,
      },
      legend: {
        data: [globalVueI18n.translate('plugin.overview.chartsTit')],
        top: 5,
        left: 5,
        itemWidth: 8,
        itemHeight: 8,
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },

      xAxis: [
        {
          type: 'category',
          data: res.tenantInstallVOS.length
            ? res.tenantInstallVOS.map(item => item.installTime)
            : [],
          inside: true,
          showMaxLabel: true,
          axisLabel: {
            width: 170,
            rotate: 45,
            formatter: function(value) {
              return value;
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: globalVueI18n.translate('plugin.overview.chartsTit'),
          type: 'bar',
          barGap: 0,
          barWidth: 20,
          color: [res.color],
          data: res.tenantInstallVOS.length
            ? res.tenantInstallVOS.map(item => ({
                value: item.count,
              }))
            : [],
        },
      ],
    };
  }
  @Watch('option')
  @Watch('skin', { deep: true })
  initChart() {
    const chart = this.$refs.PluginCharts;
    this.myChart = echarts.init(chart);
    this.myChart.setOption(this.option);
  }
}
</script>
<style lang="less" module></style>
