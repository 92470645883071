import request from '../request';

const serviceName = '/transaction/backendInvoice';

export function getList(data) {
  return request(`${serviceName}/listInvoice`, {
    method: 'POST',
    body: data,
  });
}

export function getDetail(pkId) {
  return request(`${serviceName}/getInvoiceDetail?pkId=${pkId}`, {
    method: 'GET',
  });
}

/** @name 处理开票申请 */
export function createProcessing(data) {
  return request(`${serviceName}/createProcessing`, {
    method: 'POST',
    body: data,
  });
}

/** @name 处理开票申请 */
export function getInvoiceDynamics(pkId) {
  return request(`${serviceName}/getInvoiceDynamics?pkId=${pkId}`, {
    method: 'GET',
  });
}
