<template>
  <router-view />
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';

@Component()
export default class Index extends Vue {}
</script>
<style lang="less" module></style>
