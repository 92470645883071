<template>
  <div :class="$style.solutionSet">
    <div :class="$style.solutionTlt">
      <span>1</span> {{ $t('market.template.base') }}
    </div>
    <a-form-model
      ref="form"
      :model="formData"
      :rules="rules"
      :class="$style.form"
    >
      <a-form-model-item :label="$t('solution.solutionName')" prop="name">
        <a-input
          v-model="formData.name"
          :class="$style.solutionName"
          :maxLength="30"
        />
      </a-form-model-item>
      <a-form-model-item>
        <div slot="label">
          {{ $t('market.template.upgradePlan') }}
          <a-tooltip>
            <template slot="title">{{
              $t('market.template.upgradeTip')
            }}</template>
            <x-icon type="tc-icon-warning" :class="$style.updateTip" />
          </a-tooltip>
        </div>
        <a-radio-group
          v-model="formData.removeUpgrade"
          :disabled="data.pkId !== ''"
        >
          <a-radio :value="1">
            {{ $t('template.review.checked') }}
          </a-radio>
          <a-radio :value="0">
            {{ $t('template.review.unChecked') }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('solution.tip10')">
        <a-textarea
          v-model="formData.description"
          :maxLength="100"
          :class="[$style.solutionDesc, $style.descW]"
        />
      </a-form-model-item>
      <a-form-model-item
        prop="price"
        :label="$t('market.template.priceSetting')"
        :class="$style.price"
      >
        <a-radio-group v-model="isFree">
          <a-radio :value="true">{{ $t('market.template.free') }}</a-radio>
          <a-radio :value="false">
            {{ $t('market.template.price') }}
          </a-radio>
        </a-radio-group>
        <a-input-number
          v-show="!isFree"
          v-model="price"
          :min="0"
          :precision="2"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('solution.tip11')">
        <x-upload-button
          :maxFileSize="5"
          @success="
            data => {
              formData.banner = data.ossData.ossPath;
            }
          "
        >
          <div :class="$style.upload">
            <div
              :class="[$style.addIcon, formData.banner ? $style.changePic : '']"
            >
              <x-icon type="tc-icon-image-download"></x-icon>
              {{ $t('solution.addPictures') }}
            </div>
            <x-oss-image
              :oss-path="formData.banner"
              v-if="formData.banner"
              :class="$style.image__img"
            />
          </div>
        </x-upload-button>
        <a-button
          @click="formData.banner = ''"
          :class="$style.addApp"
          icon="redo"
        >
          {{ $t('activity.reset') }}
        </a-button>
      </a-form-model-item>
      <a-form-model-item :label="$t('market.template.banner.bgColor')">
        <custom-color-panel
          v-model="formData.bgColor"
          :colors="colors"
          :identifier="identifier"
        />
      </a-form-model-item>
      <div :class="$style.solutionTlt">
        <span>2</span> {{ $t('solution.promotingBg') }}
      </div>
      <a-form-model-item
        :label="$t('common.label.title')"
        prop="bgDescribe.title"
      >
        <a-input
          v-model="formData.bgDescribe.title"
          :maxLength="30"
          :class="$style.solutionName"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('market.template.instruction')">
        <a-input
          v-model="formData.bgDescribe.description"
          :maxLength="100"
          :class="[$style.solutionName, $style.descW]"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('solution.projectBg')"
        required
        prop="bgDescribe.detailList"
      >
        <ImgList
          v-model="formData.bgDescribe.detailList"
          @input="$refs.form.validateField('bgDescribe.detailList')"
          formItemProp="bgDescribe.detailList"
          :type="'bgDescribe'"
          ref="bgDescribe"
        />
      </a-form-model-item>
      <div :class="$style.solutionTlt">
        <span>3</span> {{ $t('solution.solutionArchitecture') }}
      </div>
      <a-form-model-item
        :label="$t('common.label.title')"
        prop="architecture.title"
      >
        <a-input
          v-model="formData.architecture.title"
          :class="$style.solutionName"
          :maxLength="30"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('market.template.instruction')">
        <a-textarea
          v-model="formData.architecture.description"
          :class="[$style.solutionName, $style.descW]"
          :maxLength="100"
        />
      </a-form-model-item>

      <a-form-model-item :label="$t('solution.architectureDiagram')">
        <ImgList
          v-model="formData.architecture.image"
          formItemProp="architecture.image"
          :type="'architecture'"
          ref="architecture"
        />
      </a-form-model-item>
      <div :class="$style.solutionTlt">
        <span>4</span> {{ $t('solution.advantage') }}
      </div>
      <a-form-model-item
        :label="$t('common.label.title')"
        prop="advantage.title"
      >
        <a-input
          v-model="formData.advantage.title"
          :class="$style.solutionName"
          :maxLength="30"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('market.template.instruction')">
        <a-textarea
          v-model="formData.advantage.description"
          :class="[$style.solutionName, $style.descW]"
          :maxLength="100"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('solution.advantagesDetails')">
        <ImgList
          v-model="formData.advantage.detailList"
          formItemProp="advantage.detailList"
          :type="'advantage'"
          ref="advantage"
        />
      </a-form-model-item>
      <div :class="$style.solutionTlt">
        <span>5</span> {{ $t('solution.tip13') }}
      </div>
      <a-form-model-item :label="$t('common.label.title')" prop="app.title">
        <a-input
          v-model="formData.app.title"
          :class="$style.solutionName"
          :maxLength="30"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('market.template.instruction')">
        <a-textarea
          v-model="formData.app.description"
          :class="[$style.solutionName, $style.descW]"
          :maxLength="100"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('solution.applicationList')"
        prop="app.appDetail"
      >
        <div :class="[$style.solutionBg, $style.solutionGrip]">
          <div
            :class="$style.appList"
            v-for="(item, index) in formData.app.appDetail"
            :key="index"
          >
            <div :class="$style.appDesc">
              <div :class="$style.icon">
                <x-custom-icon :type="item.appIcon" />
              </div>
              <div :class="$style.appTextDesc">
                {{ item.appName }}
              </div>
            </div>
            <div :class="$style.delete">
              <x-icon type="delete" @click="delApp(item)" />
            </div>
          </div>
        </div>
      </a-form-model-item>

      <a-form-model-item :class="$style.addBtnWarp">
        <a-button
          icon="plus"
          type="link"
          :class="$style.addApp"
          @click="addApp"
          >{{ $t('solution.selectApplication') }}</a-button
        >
      </a-form-model-item>
      <div :class="$style.solutionTlt">
        <div><span>6</span>{{ $t('solution.customerCase') }}</div>
      </div>
      <a-form-model-item
        :label="$t('common.label.title')"
        prop="customer.title"
      >
        <a-input
          v-model="formData.customer.title"
          :class="$style.solutionName"
          :maxLength="30"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('market.template.instruction')">
        <a-textarea
          v-model="formData.customer.description"
          :class="[$style.solutionName, $style.descW]"
          :maxLength="100"
        />
      </a-form-model-item>

      <div :class="$style.customerWrap">
        <div :class="$style.showTip">
          <a-checkbox v-model="formData.customer.ifPleaseWait">{{
            $t('solution.forward')
          }}</a-checkbox>
        </div>
        <a-form-model-item :label="$t('solution.customer')">
          <ImgList
            v-model="formData.customer.detailList"
            formItemProp="customer.detailList"
            :type="'customer'"
            ref="customer"
          />
        </a-form-model-item>
      </div>
    </a-form-model>

    <div :class="$style.footer" slot="footer">
      <async-button :click="submit" type="primary">{{
        $t('common.action.confirm')
      }}</async-button>
      <async-button :click="stashRes" type="primary" v-if="!data.pkId">{{
        $t('solution.temporary storage')
      }}</async-button>
      <a-button @click="clearStash" ghost v-if="isClear">
        {{ $t('solution.clearStaging') }}
      </a-button>
      <a-button @click="$emit('close')" ghost>
        {{ $t('common.action.cancel') }}
      </a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Provide } from 'vue-property-decorator';
import { crossStorageModule } from '@/enum/store';
import { CustomColorPanel } from '@/components/custom-color-picker';
import { UploadPathSymbol, ModalSelect } from '@triascloud/x-components';
import AsyncButton from '@/components/async-button';
import { createModuleUrl } from '@/utils';
import SolutionUpdateRecordModal from './solution-update-record.vue';
import SolutionInstallModal from './solution-install-modal.vue';
import {
  addSolution,
  solutionDetail,
  editSolution,
} from '@/services/template-manager/solution';
import { getAppData } from '@/services/custom-application/application';
import ImgList from './img-list.vue';
import { HabitService } from '@triascloud/services';
import AppList from './app-list.vue';
import { deepClone } from '@triascloud/utils';

@Component({
  components: {
    CustomColorPanel,
    AsyncButton,
    SolutionUpdateRecordModal,
    ImgList,
  },
})
export default class SolutionTemplate extends Vue {
  @Prop({ type: String, default: 'co.solution.color.picker' }) identifier;
  @Prop({ type: Object }) data;
  @Provide(UploadPathSymbol) uploadPath = 'common/screenshot/market/';
  @crossStorageModule.State tenant;

  idData = {
    formIdData: [],
  };
  isDisabled = false;
  goDetail() {
    window.open(createModuleUrl('www') + '/solution/' + this.data.pkId);
  }

  colors = [
    '',
    'rgb(186,104,200)',
    'rgb(147,117,205)',
    'rgb(121,135,203)',
    'rgb(115,131,228)',
    'rgb(100,181,245)',
    'rgb(79,196,247)',
    'rgb(77,209,226)',
    'rgb(38,165,154)',
    'rgb(103,187,106)',
    'rgb(175,213,130)',
  ];
  solutionId = null;
  isFree = true;
  price = '';
  isClear = false;

  defaultStash = null;

  formData = {
    name: '',
    removeUpgrade: 0,
    description: '',
    banner: '',
    bgColor: '',
    app: {
      description: '',
      title: '',
      pkId: '',
      appDetail: [],
      idxAppId: '',
      appIcon: '',
    },
    classify: '1',
    bgDescribe: {
      description: '',
      title: '',
      pkId: '',
      detailList: [
        {
          description: '',
          image: '',
          pkId: '',
          title: '',
        },
        {
          description: '',
          image: '',
          pkId: '',
          title: '',
        },
        {
          description: '',
          image: '',
          pkId: '',
          title: '',
        },
      ],
    },
    architecture: {
      description: '',
      image: [],
      pkId: '',
      title: '',
    },
    advantage: {
      title: '',
      description: '',
      pkId: '',
      detailList: [
        {
          description: '',
          image: '',
          imageUp: '',
          pkId: '',
          title: '',
        },
        {
          description: '',
          image: '',
          imageUp: '',
          pkId: '',
          title: '',
        },
        {
          description: '',
          image: '',
          imageUp: '',
          pkId: '',
          title: '',
        },
      ],
    },
    customer: {
      description: '',
      ifPleaseWait: false,
      detailList: [
        {
          description: '',
          image: '',
          pkId: '',
          title: '',
        },
      ],
      title: '',
      idxSolution: null,
      pkId: '',
    },
    version: '',
    versionName: '',
    title: '',
    price: 0,
  };

  get reminderType() {
    return [
      {
        label: this.$t('solution.applicationList'),
        Component: AppList,
        props: {
          data: this.treeData.map(item => {
            return {
              text: item.title,
              value: item.appId,
              type: 'user',
            };
          }),
        },
      },
    ];
  }

  get habitService() {
    return new HabitService('/configuration/memberHabit');
  }

  get rules() {
    return {
      'name': [
        { required: true, message: this.$t('solution.rules1') },
        {
          min: 1,
          max: 30,
          message: this.$t('solution.tip2'),
          trigger: 'blur',
          whitespace: true,
        },
      ],
      'app.appDetail': [
        {
          required: true,
          message: this.$t('solution.rules2'),
        },
      ],
      'architecture.title': [
        {
          required: true,
          message: this.$t('solution.tip1'),
          whitespace: true,
        },
        {
          min: 1,
          max: 30,
          message: this.$t('solution.tip2'),
          trigger: 'blur',
          whitespace: true,
        },
      ],
      'customer.title': [
        {
          required: true,
          message: this.$t('solution.tip1'),
          whitespace: true,
        },
        {
          min: 2,
          max: 30,
          message: this.$t('solution.tip2'),
          trigger: 'blur',
          whitespace: true,
        },
      ],
      'app.title': [
        {
          required: true,
          message: this.$t('solution.tip1'),
          whitespace: true,
        },
        {
          min: 1,
          max: 30,
          message: this.$t('solution.tip2'),
          trigger: 'blur',
        },
      ],
      'advantage.title': [
        {
          required: true,
          message: this.$t('solution.tip1'),
          whitespace: true,
        },
        {
          min: 1,
          max: 30,
          message: this.$t('solution.tip2'),
          trigger: 'blur',
        },
      ],
      'bgDescribe.title': [
        {
          required: true,
          message: this.$t('solution.tip1'),
          whitespace: true,
        },
        {
          min: 1,
          max: 30,
          message: this.$t('solution.tip2'),
          trigger: 'blur',
        },
      ],
      'price': {
        pattern: /(^[0-9]\d*$)/,
        message: this.$t('market.template.priceValidateTip'),
      },
      'bgDescribe.detailList': {
        required: true,
        message: this.$t('solution.addScheme'),
      },
      'customer.detailList.title': [],
    };
  }

  handleApp(data) {
    this.isDisabled = data.length >= 20 ? true : false;
    this.formData.app.appDetail = this.treeData
      .filter(item => data.map(item => item.value).includes(item.appId))
      .map(item => {
        return {
          appExplain: '',
          appIcon: item.icon,
          appName: item.title,
          idxAppId: item.appId,
          pkId: null,
          color: item.color,
        };
      });
    this.$refs.form.validateField('app.appDetail');
    this.idData.formIdData = this.formData.app.appDetail.map(item => {
      return { value: item.idxAppId, text: item.appName, type: 'dynamic' };
    });
  }

  async addApp() {
    const data = await ModalSelect.createModal({
      value: this.idData.formIdData,
      type: this.reminderType,
    });
    this.handleApp(data);
  }

  delApp(data) {
    this.formData.app.appDetail = this.formData.app.appDetail.filter(
      item => item.idxAppId !== data.idxAppId,
    );
    this.idData.formIdData = this.formData.app.appDetail.map(item => {
      return { value: item.idxAppId, text: item.appName, type: 'dynamic' };
    });
  }

  treeData = [];

  async getTableData() {
    // 默认使用表单数据
    const treeData = await getAppData();
    this.treeData = (treeData.records || [])
      .filter(item => item.appType != 2)
      .map(item => ({
        key: item.pkId,
        title: item.appName,
        appId: item.pkId,
        isGroup: true,
        type: 'APPLICATION',
        url: `application/${item.pkId}`,
        icon: item.appIcon,
        color: item.color,
      }));
  }

  async created() {
    //暂存
    this.defaultStash = deepClone(this.formData);
    if (!this.data.pkId) {
      const list = await this.habitService.getItem(
        `co.solution.add.${this.tenant.pkId}`,
      );
      if (list && list !== '{}') {
        this.isClear = true;
      }
      this.formData = list && list !== '{}' ? JSON.parse(list) : this.formData;
      this.formData.architecture.image =
        list && list !== '{}'
          ? JSON.parse(this.formData.architecture.image)
          : [];
      this.formData.advantage.detailList.map(item => {
        item.image = item.imageUp ? item.imageUp : '';
      });
    }
    if (this.data.pkId) {
      await this.solutionDetailMethod(this.data.pkId);
    }

    this.getTableData();
  }

  async solutionDetailMethod(pkId) {
    this.formData = await solutionDetail(pkId);
    //旧数据处理
    this.formData.advantage.detailList.map(item => {
      item.image = item.imageUp;
    });

    this.formData.architecture.image = JSON.parse(
      this.formData.architecture.image,
    );
    this.formData.customer.ifPleaseWait = this.formData.customer.ifPleaseWait
      ? true
      : false;

    this.idData.formIdData = this.formData.app.appDetail.map(item => {
      return { value: item.idxAppId, text: item.appName, type: 'dynamic' };
    });
    this.formData.price = this.formData.price === -1 ? 0 : this.formData.price;
    this.price =
      this.formData.price && this.formData.price !== -1
        ? this.formData.price / 100
        : '';
    this.isFree = !this.price;
  }

  async stashRes() {
    let data = deepClone({
      ...this.formData,
      ...this.data,
      price: !this.isFree && this.price ? this.price * 100 : 0,
    });
    this.isClear = true;
    data.architecture.image = JSON.stringify(data.architecture.image);
    this.habitService.setItem(
      `co.solution.add.${this.tenant.pkId}`,
      JSON.stringify(data),
    );
    this.$message.success(this.$t('common.tips.temporarySuccess'));
  }

  clearStash() {
    this.isClear = false;
    this.habitService.setItem(`co.solution.add.${this.tenant.pkId}`, '{}');
    this.formData = deepClone(this.defaultStash);
    this.$message.success(this.$t('common.tips.delete'));
  }

  async submit() {
    try {
      await this.$refs['form'].validate();
    } catch {
      let errorDom = document.getElementsByClassName('has-error');
      errorDom[0].querySelector('input').focus();
      return false;
    }
    let data = deepClone({
      ...this.formData,
      ...this.data,
      price: !this.isFree && this.price ? this.price * 100 : 0,
    });
    data.customer.ifPleaseWait = data.customer.ifPleaseWait ? 1 : 0;
    data.architecture.image = JSON.stringify(data.architecture.image);
    //旧数据处理
    data.advantage.detailList.map(item => {
      item.imageUp = item.image;
    });
    if (!this.data.pkId) {
      this.solutionId = await addSolution(data);
      await SolutionInstallModal.createModal({
        upgrade: false,
        solutionId: this.solutionId,
      });
      this.$emit('refresh');
      this.habitService.setItem(`co.solution.add.${this.tenant.pkId}`, '{}');
    } else {
      await editSolution(data);
      const res = await SolutionUpdateRecordModal.createModal({
        pkId: this.data.pkId,
        edit: true,
      });
      if (res) {
        this.$emit('refresh');
      }
    }
  }
}
</script>
<style lang="less" module>
.solutionName {
  width: 622px;
}
.architectureImg {
  :global(.delIconBox) {
    left: 1080px;
  }
}

.solutionSet {
  padding-top: 20px;
}

.updateTip {
  margin-left: 16px;
}

.solutionTlt {
  color: var(--primary);

  &:first-child {
    margin-left: 20px;
  }
  margin-bottom: 17px;
  font-size: 22px;
  span {
    font-size: 36px;
    font-weight: 400;
    margin-right: 6px;
  }
}

.solutionBg {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.solutionGrip {
  display: grid;
  gap: 20px 30px;
  grid-template-columns: repeat(auto-fill, 330px);
}

.addBtnWarp {
  position: relative;
}
.addApp {
  padding: 0;
}
.appForm {
  opacity: 0;
}

.customerWrap {
  position: relative;
  .showTip {
    position: absolute;
    top: 2px;
    right: 0;
    z-index: 1;
  }
}

.delAction {
  button {
    margin-right: 20px;
    height: 32px;
  }
}

.addIcon {
  display: flex;
  flex-direction: column;
  color: var(--font-color-secondary);
  cursor: pointer;
  i {
    font-size: 30px;
    margin-bottom: 5px;
  }
}

.changePic {
  background-color: #000;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  border-radius: 6px;
  button i {
    font-size: 40px;
    color: #ccc;
  }
  button {
    color: var(--block-bg);
  }
}
.appList {
  border: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 20px 20px;
  margin-top: 20px;
  width: 332px;
  margin-right: 30px;
  border-radius: 4px;
  &:last-child {
    margin-right: 0;
  }
}

.appDesc {
  display: flex;
  justify-content: space-between;
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background: #df9d46;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
    flex-shrink: 0;
  }
}

.appTextDesc {
  margin-left: 10px;
  display: flex;
  line-height: 20px;
  .tlt {
    line-height: 12px;
  }
  .desc {
    font-size: 12px;
    margin-top: 16px;
    line-height: 12px;
  }
}

.solutionDesc {
  height: 88px;
  border: 1px solid var(--border);
}

.descW {
  width: 1140px;
}

.footer {
  border-top: 1px solid var(--border);
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: var(--modal-bg);
  z-index: 10;
  :global .ant-btn {
    margin-right: 10px;
  }
}

.upload {
  width: 1140px;
  height: 296px;
  display: flex;
  border: 1px solid var(--border);
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  border-radius: 6px;
  &:hover {
    .changePic {
      display: flex;
    }
  }
  .image {
    width: 1140px;
    height: 296px;
  }
  .image__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.form {
  padding: 0 30px 60px 30px;
}
.price {
  :global(.ant-form-item-children) {
    display: flex;
    align-items: center;
    height: 32px;
  }
}
:global(.img-item-modal-wrapper) :global {
  .solutionBg {
    display: flex;
  }
  .solutionBgDetail {
    width: 650px;
  }
}
.appListModal {
  :global(.x-modal-select-base-tab .ant-tabs-bar) {
    border-bottom: none;
  }
}
</style>
