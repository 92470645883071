<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="list"
      :pagination="pagination"
      :row-key="record => record.id"
      :loading="tableLoading"
      @change="handlePagination"
    >
      <template slot="operate" slot-scope="record">
        <span :class="$style.buttonGroups">
          <a :class="[$style.button]" @click.stop="openForm(record)">{{
            $t('solution.edit')
          }}</a>
          <a :class="[$style.button]" @click.stop="handleDel(record)">{{
            $t('common.action.delete')
          }}</a>
        </span>
      </template>
    </a-table>
    <a-modal
      v-model="showClassify"
      :width="500"
      :title="classifyTitle"
      :footer="null"
    >
      <classify-form
        v-if="showClassify"
        :platformId="id"
        @confirm="handleConfirm"
        @close="handleClose"
        @changeTitle="changeTitle"
      />
    </a-modal>
  </div>
</template>
<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import { createFormModal } from '@triascloud/x-components'; //createModal
import ClassifyForm from './classify-form';
import DpForm from './form';
import UploadForm from './upload-form';
import SyncForm from './sync-form';
import {
  exportDataPoint,
  importDataPoint,
  deleteDataPoint,
  getDataPointList,
  // insertDataPoint,
  // getClassification,
  // getDataPointDetail,
} from '@/services/iot-platform/dpManager';
import {
  Emitter,
  EventType,
} from '@/views/connector/platform-manage/develop/utils';
import { templateDesignModule } from '@/enum/store';
import DownloadDpform from '@/views/connector/platform-manage/develop/dp-manage/download-dpform.vue';
@Component({
  components: {
    LayoutContent,
    DpForm,
    UploadForm,
    SyncForm,
    ClassifyForm,
  },
})
export default class DpManage extends Vue {
  @templateDesignModule.Action getTypes;
  @templateDesignModule.State types;
  @InjectReactive() id;
  classifyTitle = this.$t('connector.dpManage.chooseProductCategory');
  showClassify = false;
  mounted() {
    Emitter.$on(EventType.ADD_DP_DATA, this.handleAddData);
    Emitter.$on(EventType.DOWNLOAD_DP_DATA, this.handleDownload);
    Emitter.$on(EventType.SYNC_DP_DATA, this.handleSyncData);
    Emitter.$on(EventType.IMPORT_DP_DATA, this.handleImport);
    Emitter.$on(EventType.CHOOSE_PRODUCT_DP_DATA, this.handleChoose);
    Emitter.$on(EventType.SEARCH_DP_DATA, this.handleSearch);
  }
  async created() {
    await this.getTypes(this.id);
    this.getList();
  }

  beforeDestroy() {
    Emitter.$off(EventType.ADD_DP_DATA, this.handleAddData);
    Emitter.$off(EventType.DOWNLOAD_DP_DATA, this.handleDownload);
    Emitter.$off(EventType.SYNC_DP_DATA, this.handleSyncData);
    Emitter.$off(EventType.IMPORT_DP_DATA, this.handleImport);
    Emitter.$off(EventType.CHOOSE_PRODUCT_DP_DATA, this.handleChoose);
    Emitter.$off(EventType.SEARCH_DP_DATA, this.handleSearch);
  }

  productId = '';
  handleChoose(val) {
    this.productId = val;
    this.pagination.current = 1;
    this.getList();
  }
  input = '';
  handleSearch(val) {
    this.input = val;
    this.pagination.current = 1;
    this.getList();
  }

  async handleSyncData() {
    try {
      // let result =
      await createFormModal(() => <SyncForm />, {
        width: 500,
        title: this.$t('connector.dpManage.syncDp'),
      });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  async handleDel(record) {
    this.$confirm({
      title: this.$t('connector.accessPlatform.operationTips'),
      content: this.$t('connector.dpManage.deleteTips'),
      okText: this.$t('common.action.confirm'),
      cancelText: this.$t('common.action.cancel'),
      onOk: async () => {
        await deleteDataPoint(record.dataPointId);
        await this.getList();
        this.$message.success(this.$t('common.tips.delete'));
      },
    });
  }
  async handleImport() {
    try {
      // let result =
      let formData = await createFormModal(() => <UploadForm />, {
        width: 360,
        title: this.$t('connector.dpManage.importDataPoints'),
      });
      if (formData) {
        await importDataPoint(this.id, formData);
        this.$message.success(this.$t('connector.dpManage.importSucceed'));
        this.getList();
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  async handleDownload() {
    try {
      const result = await createFormModal(
        () => <DownloadDpform platformId={this.id} />,
        {
          width: 550,
          title: this.$t('connector.dpManage.exportDataPoint'),
        },
      );
      if (result) {
        const params = {
          productId: result,
        };
        await exportDataPoint(this.id, params);
      } else {
        await exportDataPoint(this.id);
      }
    } catch {
      return false;
    }
  }
  async openForm(record) {
    try {
      // const classList = await getClassification(this.id);
      // console.log(record);
      await createFormModal(
        () => <DpForm platformId={this.id} record={record} />,
        {
          width: 500,
          title: record.dataPointId
            ? this.$t('connector.dpManage.editDataPoint')
            : this.$t('connector.dpManage.createDatePoint'),
        },
      );
      this.getList();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  changeTitle(title) {
    this.classifyTitle = title;
  }
  handleAddData() {
    this.showClassify = true;
  }
  handleClose() {
    this.showClassify = false;
  }
  handleConfirm(classificationId) {
    this.openForm({ idxPlatformProductId: classificationId });
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => {
          return index + 1;
        },
        title: this.$t('common.label.index'),
        align: 'center',
        width: 80,
      },
      {
        dataIndex: 'classificationName',
        title: this.$t('connector.dpManage.TSL_Type'),
      },
      {
        dataIndex: 'functionName',
        title: this.$t('controls.fnName.label'),
      },
      {
        dataIndex: 'functionIdentifier',
        title: this.$t('connector.dpManage.identifier'),
      },
      {
        dataIndex: 'dataType',
        title: this.$t('controls.currentDataSet.dataType'),
      },
      {
        dataIndex: 'updateName',
        title: this.$t('connector.dataType.table.lastEditor'),
      },
      {
        width: 200,
        dataIndex: 'updateTime',
        title: this.$t('connector.dataType.table.updateTime'),
        customRender: val => {
          return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
        },
      },
      {
        width: 200,
        dataIndex: 'productName',
        title: this.$t('connector.dpManage.productCategory'),
      },
      {
        width: 200,
        dataIndex: '',
        title: this.$t('common.action.operating'),
        scopedSlots: { customRender: 'operate' },
      },
    ];
  }
  list = [];
  selectItem = {};
  tableLoading = false;
  confirmVisible = false;
  confirmLoading = false;
  detailsVisible = false;
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total}` + this.$t('connector.dataTemplate.itmes'),
  };
  async getList() {
    try {
      const params = {
        current: this.pagination.current,
        size: this.pagination.pageSize,
        platformId: this.id,
      };
      if (this.productId) {
        params['productId'] = this.productId;
      }
      if (this.input) {
        params['input'] = this.input;
      }
      this.tableLoading = true;
      const { records, current, size, total } = await getDataPointList(params);
      for (let record of records) {
        record.dataType = '-';
        let jsonData = JSON.parse(record.dataSpecs);
        for (let item of jsonData.fields) {
          if (item.type === 'currentDataSet' && item.value) {
            record.dataType = item.value.thingIdentifier || '-';
          } else if (item.type === 'addDataSet' && item.value?.length === 1) {
            record.dataType = item.value[0]?.thingIdentifier || '-';
          }
        }
      }
      this.list = records;
      this.tableLoading = false;
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
    } catch {
      this.tableLoading = false;
      return false;
    }
  }
  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getList();
  }
  showDetails() {
    this.detailsVisible = true;
  }
  stopService() {
    this.confirmVisible = false;
    this.selectItem.status = 'stoped';
  }
}
</script>
<style lang="less" module>
.primaryColor {
  color: var(--primary);
}
.btns {
  color: var(--primary);
  cursor: pointer;
}
.buttonGroups {
  display: flex;
  justify-content: flex-start;
  .red {
    color: red;
  }
  .button {
    width: 50px;
    position: relative;
    .messageCount {
      position: absolute;
      font-size: 12px;
      top: 1px;
      right: 5px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      border-radius: 50%;
      background: var(--font-active);
      color: var(--table-row);
      text-align: center;
    }
  }
}
</style>
