// @ts-check
import {
  BaseControl,
  ControlValueTypeEnum,
  ControlValueTypeOperatorMap,
} from '../BaseControl';
// @ts-ignore
import ConfigComponent from '../Int/config.vue';
// @ts-ignore
import DesignComponent from '../Input/design.vue';
// @ts-ignore
import { textFormat } from '../utils';
// import { generateRangeRule, generateUniqueRule } from '../validate';

/** @name 单行文本 */
class DoubleControl extends BaseControl {
  /** @readonly */
  type = 'double';

  label = 'controls.double.label';

  icon = 'tc-icon-number';

  trigger = 'blur';

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    defaultValue: {
      // 单行文本默认值类型
      type: 'custom',
      // 默认值
      custom: '',
    },
    identifier: 'DOUBLE',
    inParams: ['DEVELOPMENT'],
    outParams: [],
    min: null,
    max: null,
    defaultVal: '',
    precision: 6,
    unitSelect: undefined,
    unitInput: '',
  });

  // @ts-ignore
  props = field => ({
    maxLength: field.widget.max || undefined,
  });

  // @ts-ignore
  // rules = (field, root) => [
  //   generateRangeRule(field),
  //   generateUniqueRule(field, root),
  // ];

  operation = ControlValueTypeOperatorMap.Text;

  compare = () => ({
    value: null,
  });

  /**
   * @name 字段特征定义
   * @type { object | ((field: import('../BaseControl').Field) => object) }
   * @returns  { object }
   */
  feature = field => ({
    WRITABLE: !field?.widget?.view,
    REQUIRED: !field?.widget?.view,
    PRINTABLE: true,
    BRIEFING: true,
    sorter: 'order',
  });

  FormComponent = DesignComponent;

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;

  format = textFormat;

  printable = true;
  printToQrCode = true;

  valueType = ControlValueTypeEnum.Text;
}

export default new DoubleControl();
