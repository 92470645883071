/** @name 操作类型 */
export const optionType = {
  ListingApplication: 1,
  UpdateApplication: 2,
  TakeDown: -3,
  TakeShelf: 3,
};

/** @name 结果类型 */
export const resultType = {
  Pending: -1,
  Fail: 0,
  Pass: 1,
};

export const optionTypeText = {
  [optionType.ListingApplication]: 'solution.listingApplication',
  [optionType.UpdateApplication]: 'solution.updateApplication',
  [optionType.TakeDown]: 'common.action.down',
  [optionType.TakeShelf]: 'common.action.up',
};

export const resultTypeText = {
  [resultType.ListingApplication]: 'solution.pending',
  [resultType.Fail]: 'common.action.fail',
  [resultType.Pass]: 'common.action.pass',
};
