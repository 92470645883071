<template>
  <div>
    <div :class="$style['tag-wrapper']">
      <a-tag
        v-for="item in labelList"
        :key="item.pkId"
        :class="$style.tag"
        closable
        @close="removeLabel(item.pkId)"
        >{{ item.labelName }}</a-tag
      >
    </div>
    <a-popover
      v-model="labelInfos.showInput"
      placement="bottom"
      trigger="click"
    >
      <div slot="content" :class="$style['label-content']">
        <a-input
          v-model="labelInfos.labelName"
          :max-length="10"
          auto-focus
          @pressEnter="addLabel"
        >
          <span slot="suffix">{{ labelInfos.labelName.length }}/10</span>
        </a-input>
        <a-button
          type="primary"
          :class="$style['label-content-button']"
          @click="addLabel"
          >{{ $t('common.action.confirm') }}</a-button
        >
      </div>
      <a-button type="link" icon="plus" :class="$style['label-button']">{{
        $t('website.addLabel')
      }}</a-button>
    </a-popover>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  addLabel,
  deleteLabel,
} from '@/services/configuration/labelManagement';
import { labelType } from '@/enum/labelType';

@Component()
export default class EditCase extends Vue {
  @Prop({ type: Array }) labelList;
  @Prop({ type: String }) type;

  labelInfos = {
    showInput: false,
    labelName: '',
  };

  async addLabel() {
    if (!this.labelInfos.labelName.trim()) return;
    for (const { labelName } of this.labelList) {
      if (labelName === this.labelInfos.labelName) {
        this.$message.error(this.$t('website.sameLabelTip'));
        return;
      }
    }

    let useFor = '';
    ['case', 'news', 'download'].some(item => {
      if (this.type === item) {
        useFor = labelType[item];
        return true;
      }
    });
    const requestParams = {
      labelName: this.labelInfos.labelName,
      useFor,
    };
    const pkId = await addLabel(requestParams);
    const obj = {
      pkId,
      labelName: this.labelInfos.labelName,
    };
    Object.assign(this.labelInfos, { showInput: false, labelName: '' });
    this.$emit('add-label', obj);
  }

  async removeLabel(pkId) {
    await deleteLabel(pkId);
    this.$emit('remove-label', pkId);
  }
}
</script>

<style lang="less" module>
// 标签
.tag-wrapper {
  border: 1px solid var(--form-border);
  padding: 6px;
  border-radius: 4px;
  height: 94px;
  overflow-y: auto;
  .tag {
    height: 28px;
    color: var(--primary);
    cursor: pointer;
    margin: 5px 10px 5px 0;
    border: 1px solid var(--primary);
    padding: 6px 12px;
    line-height: 16px;
    min-width: 70px;
    text-align: center;
    background-color: var(--home-bg);
    &:last-child {
      margin-right: 0;
    }
  }
}

.label-content {
  display: flex;
  .label-content-button {
    height: 30px;
    margin-left: 10px;
  }
}
.label-button {
  position: absolute;
  right: 0;
  padding: 0;
}
</style>
