<template>
  <div>
    <a-form-model layout="vertical" :model="formData" ref="form" :rules="rules">
      <div :class="$style.item">
        <div :class="$style.tlt">
          {{ $t('website.setting.basicInformation') }}
        </div>
        <div :class="$style.itemRow">
          <a-form-model-item
            :class="$style.itemCol"
            :label="$t('inviteCode.companyName')"
            prop="tenantList"
            v-if="(data && !data.defaultConfig) || !data"
          >
            <x-select
              :service="initData"
              v-model="formData.tenantList"
              text-key="label"
              :filterOption="false"
              value-key="key"
              multiple
              :data.sync="groups"
              labelInValue
            >
            </x-select>
          </a-form-model-item>
          <a-form-model-item
            :class="$style.itemCol"
            :label="$t('website.setting.styleName')"
            prop="styleName"
            :disabled="view"
            v-if="(data && !data.defaultConfig) || !data"
          >
            <a-input
              v-model="formData.styleName"
              :maxLength="20"
              :disabled="view"
            />
          </a-form-model-item>
        </div>
        <a-form-model-item :label="$t('website.setting.styleSelection')">
          <a-radio-group v-model="formData.style" :class="$style.radioWrap">
            <div
              :class="{
                [$style.itemRadio]: true,
              }"
            >
              <label>
                <div
                  :class="{
                    [$style.itemBorder]: true,
                    [$style.active]: formData.style === 'part',
                  }"
                >
                  <img :src="styleBlock" />
                </div>
                <a-radio
                  :disabled="view"
                  value="part"
                  :class="$style.radioDom"
                  >{{ $t('website.setting.partial') }}</a-radio
                >
              </label>
            </div>
            <div
              :class="{
                [$style.itemRadio]: true,
                [$style.itemMidRadio]: true,
              }"
            >
              <label>
                <div
                  :class="{
                    [$style.itemBorder]: true,
                    [$style.active]: formData.style === 'modal',
                  }"
                >
                  <img :src="styleModal" />
                </div>
                <a-radio
                  :disabled="view"
                  value="modal"
                  :class="$style.radioDom"
                  >{{ $t('website.setting.popUps') }}</a-radio
                >
              </label>
            </div>

            <div
              :class="{
                [$style.itemRadio]: true,
              }"
            >
              <label>
                <div
                  :class="{
                    [$style.itemBorder]: true,
                    [$style.active]: formData.style === 'full',
                  }"
                >
                  <img :src="styleAll" />
                </div>
                <a-radio
                  :disabled="view"
                  value="full"
                  :class="$style.radioDom"
                  >{{ $t('website.setting.fullScreen') }}</a-radio
                >
              </label>
            </div>
          </a-radio-group>
        </a-form-model-item>
      </div>
      <div :class="[$style.item, $style.itemSet]">
        <div :class="$style.tlt">{{ $t('website.setting.configInfo') }}</div>
        <div :class="$style.itemRow">
          <a-form-model-item
            :class="$style.itemCol"
            :label="$t('common.label.title')"
            :placeholder="$t('website.setting.example')"
            prop="config.setTlt"
          >
            <a-input
              v-model="formData.config.setTlt"
              :disabled="view"
              :maxLength="50"
            />
          </a-form-model-item>
          <a-form-model-item
            :class="$style.itemCol"
            :label="$t('website.setting.content')"
            prop="config.setContent"
          >
            <a-input
              v-model="formData.config.setContent"
              :disabled="view"
              :maxLength="50"
            />
          </a-form-model-item>
        </div>
      </div>
      <div :class="$style.item">
        <div :class="$style.tlt">
          {{ $t('website.setting.configurationNavigation') }}
          <x-icon
            type="plus"
            :class="$style.plusIcon"
            @click="addSet"
            v-if="!view"
            v-show="formData.config.data.length <= 8"
          />
        </div>
        <navigate-item
          :data="formData.config.data"
          :view="view"
          @remove="removeGuide"
          @dragGuide="data => (formData.config.data = data)"
        />
      </div>
    </a-form-model>
    <div slot="footer" :class="$style.footer" v-if="!view">
      <a-button @click="$emit('cancel')" ghost>
        {{ $t('common.action.cancel') }}
      </a-button>
      <async-button :click="stash" type="primary" v-if="!data || copy">{{
        $t('solution.temporary storage')
      }}</async-button>
      <a-button @click="clearStash" ghost v-if="isClear">
        {{ $t('solution.clearStaging') }}
      </a-button>
      <async-button :click="submit" type="primary">{{
        $t('common.action.save')
      }}</async-button>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import Draggable from 'vuedraggable';
import NavigateItem from './navigate-item.vue';
import { deepClone, uuid } from '@triascloud/utils';
// import { globalModule } from '@/enum/store';
import {
  addGuide,
  guideDetail,
  editGuide,
  companyListService,
} from '@/services/configuration/operating';
import { HabitService } from '@triascloud/services';
import { crossStorageModule } from '@/enum/store';
import AsyncButton from '@/components/async-button';
import { globalVueI18n } from '@triascloud/i18n';

function createAddGuideModal(props, listener) {
  return createModal(h => h(GuideTemplate, { props, on: listener }), {
    title: props.title
      ? props.title
      : globalVueI18n.translate('website.setting.newGuidelines'),
    width: 860,
  });
}
@Component({
  components: { Draggable, NavigateItem, AsyncButton },
})
export default class GuideTemplate extends Vue {
  @Prop() data;
  @Prop({ type: Boolean, default: false }) view;
  @Prop({ type: Boolean, default: false }) copy;

  static createModal = createAddGuideModal;
  //   @globalModule.State companyList;
  @crossStorageModule.State tenant;

  defaultActiveKey = '1';
  defaultStash = '';
  cacheTenantList = [];
  groups = [];
  get styleModal() {
    return require('@/assets/img/styleModal.png');
  }

  get styleBlock() {
    return require('@/assets/img/styleBlock.png');
  }
  get styleAll() {
    return require('@/assets/img/styleAll.png');
  }

  list = {};
  isClear = false;
  get habitService() {
    return new HabitService('/configuration/memberHabit');
  }

  rules = {
    'styleName': [
      {
        min: 2,
        max: 30,
        message: this.$t('record.groupNameTip'),
        trigger: 'blur',
      },
      {
        required: true,
        trigger: 'blur',
        message: this.$t('website.setting.enterStyleName'),
      },
    ],
    'tenantList': [
      {
        required: true,
        trigger: 'blur',
        message: this.$t('website.setting.selectCompany'),
      },
    ],
    'config.setTlt': [
      {
        min: 2,
        max: 8,
        message: this.$t('record.wordLimit', { value: 8 }),
        trigger: 'blur',
      },
      {
        required: true,
        trigger: 'blur',
        message: this.$t('solution.tip1'),
      },
    ],
    'config.setContent': [
      {
        min: 2,
        max: 20,
        message: this.$t('record.wordLimit', { value: 20 }),
        trigger: 'blur',
      },
      {
        required: true,
        trigger: 'blur',
        message: this.$t('website.setting.enterContent'),
      },
    ],
  };

  removeGuide(data) {
    this.deleteCard(this.formData.config.data, data.id);
  }

  deleteCard(arr, id) {
    arr.forEach((item, index) => {
      if (item.id === id) {
        arr.splice(index, 1);
        return;
      }
      if (item.children && item.children.length > 0) {
        return this.deleteCard(item.children, id);
      }
    });
    return arr;
  }

  async initData(page, keyword) {
    const detail = await companyListService(page, 10, keyword);
    return detail.records.map(item => {
      return {
        text: item.enterpriseName,
        label: item.enterpriseName,
        key: item.pkId,
        disabled: this.cacheTenantList.includes(item.pkId)
          ? false
          : item.checkRelevance,
      };
    });
  }

  companyList = [];
  async created() {
    //暂存
    this.defaultStash = deepClone(this.formData);
    if (!this.data) {
      const list = await this.habitService.getItem(
        `co.guide.add.${this.tenant.pkId}`,
      );
      if (list && list !== '{}') {
        this.isClear = true;
      }
      this.formData = list && list !== '{}' ? JSON.parse(list) : this.formData;
    }
    if (this.data && this.data.pkId) {
      await this.detailList(this.data.pkId);
    }
  }

  async detailList(pkId) {
    const data = await guideDetail(pkId).then(res => {
      res.tenantList = res.tenantList.map(item => {
        return {
          text: item.tenantName,
          label: item.tenantName,
          key: item.tenantId,
        };
      });
      return res;
    });
    this.cacheTenantList = this.copy
      ? []
      : data.tenantList.map(item => item.key);
    this.formData = this.copy
      ? {
          ...data,
          tenantList: [],
          tenantName: '',
          config: data.config ? JSON.parse(data.config) : this.formData.config,
        }
      : {
          ...data,
          config: data.config ? JSON.parse(data.config) : this.formData.config,
        };
  }

  handlePlatformChange(data) {
    this.formData.tenantName = this.companyList.filter(
      item => item.pkId === data,
    )[0].enterpriseName;
  }

  addSet() {
    this.formData.config.data.push({
      title: '',
      icon: '',
      bgDark: '',
      bg: '',
      id: uuid(),
      children: [
        {
          bg: '',
          bgDark: '',
          buttonText: '',
          desc: '',
          icon: '',
          id: uuid(),
          media: [],
          route: '',
          showButton: 'YES',
          title: '',
        },
      ],
    });
  }

  formData = {
    tenantList: '',
    styleName: '',
    style: 'part',
    tenantName: '',
    config: {
      setTlt: '',
      setContent: '',
      data: [
        {
          title: '',
          icon: '',
          bg: '',
          bgDark: '',
          id: 1,
          children: [
            {
              title: '',
              icon: '',
              desc: '',
              bgDark: '',
              id: 1,
              showButton: 'YES',
              bg: '',
              poster: '',
              buttonText: '',
              route: '',
              media: null,
            },
          ],
        },
      ],
    },
  };

  async stash() {
    const tenantNameList = Array.isArray(this.formData.tenantList[0].label)
      ? this.formData.tenantList[0].label[0].context.$el.innerText.split('\n')
      : null;

    this.formData.data = this.data;
    this.habitService.setItem(
      `co.guide.add.${this.tenant.pkId}`,
      JSON.stringify({
        ...this.formData,
        tenantList: this.formData.tenantList.map((item, index) => {
          return {
            key: item.key,
            label: tenantNameList ? tenantNameList[index] : item.label,
          };
        }),
      }),
    );
    this.isClear = true;
    this.$message.success(this.$t('common.tips.temporarySuccess'));
  }

  clearStash() {
    this.isClear = false;
    this.habitService.setItem(`co.guide.add.${this.tenant.pkId}`, '{}');
    this.formData = deepClone(this.defaultStash);
    this.$message.success(this.$t('common.tips.delete'));
  }

  async submit() {
    // await this.$refs.form.validate();
    const tenantNameList =
      this.formData.tenantList.length &&
      Array.isArray(this.formData.tenantList[0].label)
        ? this.formData.tenantList[0].label[0].context.$el.innerText.split('\n')
        : null;

    if (this.data && this.data.pkId && !this.copy) {
      await editGuide({
        ...this.formData,
        tenant: this.formData.tenantList.map((item, index) => {
          return {
            tenantId: item.key,
            tenantName: Array.isArray(item.label)
              ? tenantNameList[index]
              : item.label,
          };
        }),
        tenantList: [],
        config: JSON.stringify(this.formData.config),
      });
    } else {
      await addGuide({
        ...this.formData,
        tenant: this.formData.tenantList.map((item, index) => {
          return {
            tenantId: item.key,
            tenantName: Array.isArray(item.label)
              ? tenantNameList[index]
              : item.label,
          };
        }),
        tenantList: [],

        config: JSON.stringify(this.formData.config),
      });
      this.habitService.setItem(`co.guide.add.${this.tenant.pkId}`, '{}');
      this.$message.success(this.$t('common.tips.operationSuccess'));
    }
    this.$emit('cancel');
    this.$emit('refresh');
  }
}
</script>
<style lang="less" module>
.item {
  .tlt {
    margin-bottom: 10px;
    position: relative;
    padding-left: 8px;
    font-size: 16px;
    &::before {
      content: '';
      height: 15px;
      width: 2px;
      background: #ff5a20;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }

  &:last-child .tlt::after {
    content: '';
    height: 16px;
    width: 1px;
    background: var(--border);
    position: absolute;
    top: 3px;
    left: 80px;
  }
}

.itemSet {
  margin-top: 40px;
}
.itemRow {
  display: flex;
  justify-content: space-between;
  flex: 1;
  .itemCol {
    width: calc(50% - 10px);
  }
}
.radioWrap {
  display: flex;
  img {
    width: 100%;
    height: 100%;
  }
}
.itemRadio {
  label {
    cursor: pointer;
  }
  .itemBorder {
    width: 260px;
    border: 1px solid var(--border);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
  }
  .active {
    border: 1px solid var(--primary);
  }
  &:first-child {
    img {
      width: 132px;
      height: 96px;
    }
  }
  &:nth-child(2) {
    img {
      width: 220px;
      height: 120px;
    }
  }
  &:last-child {
    img {
      width: 252px;
      height: 152px;
    }
  }
}

.itemMidRadio {
  margin: 0 20px;
}
.radioDom {
  margin-left: 100px;
  margin-top: 10px;
}
.plusIcon {
  margin-left: 20px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: right;
  height: 60px;
  position: absolute;
  bottom: 0px;
  background-color: var(--modal-bg);
  z-index: 10;
  border-radius: 0 0 10px 0;
  width: calc(100% - 20px);
  button {
    margin-right: 20px;
  }
}
</style>
