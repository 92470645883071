<template>
  <div :class="$style.wrap">
    <div v-if="$p.action('VERIFY') && data" :class="$style.toolbar">
      <template v-if="data.state === FeedbackState.Waiting">
        <span
          :class="$style.action"
          @click="
            handleFeedback(
              FeedbackReplyType.Adopt,
              FeedbackState.Adopted,
              $t('feedback.adopt'),
            )
          "
        >
          <x-icon :class="$style.action__icon" type="tc-icon-pass" />
          {{ $t('feedback.adopt') }}
        </span>
        <span
          :class="$style.action"
          @click="
            handleFeedback(
              FeedbackReplyType.Reject,
              FeedbackState.Rejected,
              $t('feedback.reject'),
            )
          "
        >
          <x-icon :class="$style.action__icon" type="tc-icon-reject" />
          {{ $t('feedback.reject') }}
        </span>
      </template>
      <span
        v-else-if="data.state === FeedbackState.Adopted"
        :class="$style.action"
        @click="
          handleFeedback(
            FeedbackReplyType.Adopted,
            FeedbackState.Processed,
            $t('feedback.state.processed'),
          )
        "
      >
        <x-icon :class="$style.action__icon" type="check-circle" />
        {{ $t('feedback.state.processed') }}
      </span>
      <span
        v-if="$p.action('DELETE')"
        :class="$style.action"
        @click="handleRemove"
      >
        <x-icon :class="$style.action__icon" type="tc-icon-delete" />
        {{ $t('common.action.delete') }}
      </span>
    </div>
    <div v-if="data" :class="$style.content">
      <div :class="$style.form">
        <div :class="$style['content-item']">
          <div :class="$style['content-item__label']">
            {{ $t('feedback.title') }}
          </div>
          <div>{{ data.title }}</div>
        </div>
        <div :class="$style['content-item']">
          <div :class="$style['content-item__label']">
            {{ $t('feedback.type') }}
          </div>
          <div>{{ $t(FeedBackTypeText[data.feedbackType]) }}</div>
        </div>
        <div :class="$style['content-item']">
          <div :class="$style['content-item__label']">
            {{ $t('feedback.relatedFeatures') }}
          </div>
          <div>{{ data.relatedFunction }}</div>
        </div>
        <div :class="$style['content-item']">
          <div :class="$style['content-item__label']">
            {{ $t('feedback.content') }}
          </div>
          <div>{{ data.content }}</div>
        </div>
        <div :class="$style['content-item']">
          <div :class="$style['content-item__label']">
            {{ $t('feedback.annex') }}
          </div>
          <div>
            <x-upload :value="data.accessory || []" view></x-upload>
          </div>
        </div>
        <div :class="$style['content-item']">
          <div :class="$style['content-item__label']">
            {{ $t('feedback.person') }}
          </div>
          <div>{{ data.createName }}</div>
        </div>
        <div :class="$style['content-item']">
          <div :class="$style['content-item__label']">
            {{ $t('feedback.enterprise') }}
          </div>
          <div>{{ data.tenantName }}</div>
        </div>
        <div :class="$style['content-item']">
          <div :class="$style['content-item__label']">
            {{ $t('feedback.createTime') }}
          </div>
          <div>{{ $moment(data.createDate).format('Y-MM-DD HH:mm:ss') }}</div>
        </div>
      </div>
      <div :class="$style['reply-list']">
        <div
          v-for="item in data.replyList"
          :key="item.pkId"
          :class="$style.reply"
        >
          <x-oss-image
            :oss-path="item.createAvatar"
            :class="$style.reply__avatar"
          >
            <span slot="error">{{ item.createName.substr(0, 1) }}</span>
          </x-oss-image>
          <div :class="$style.reply__right">
            <div :class="$style.reply__nickname">
              <div>{{ item.createName }}</div>
              <div :class="$style.reply__time">
                {{ $moment(item.createTime).format('Y-MM-DD HH:mm:ss') }}
              </div>
            </div>
            <div :class="$style.reply__content">{{ item.content }}</div>
          </div>
        </div>
        <a-empty
          v-if="!data.replyList.length"
          :description="$t('feedback.replyEmpty')"
        />
      </div>
    </div>
    <div v-if="$p.action('REPLY')" :class="$style.form">
      <div :class="$style['form-input-box']">
        <a-textarea
          v-model="content"
          :class="$style['form-input']"
          :max="500"
          :placeholder="$t('feedback.replyPlaceholder')"
        />
        <span :class="$style['form-input__count']"
          >{{ content.length }}/500</span
        >
      </div>
      <div :class="$style['form-footer']">
        <async-button
          type="primary"
          :class="$style['form-submit']"
          :click="handleReply"
          >{{ $t('common.action.submit') }}</async-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  FeedbackReplyType,
  FeedbackState,
  FeedBackTypeText,
} from '@/enum/feedback';
import AsyncButton from '@/components/async-button';
import {
  feedbackReply,
  updateState,
} from '@/services/resource/productFeedbackReply';
import {
  getFeedbackDetail,
  deleteFeedback,
} from '@/services/resource/productFeedback';

@Component({
  components: { AsyncButton },
})
export default class FeedbackDetailDrawer extends Vue {
  @Prop({ required: true, type: String }) pkId;
  FeedbackState = FeedbackState;
  FeedbackReplyType = FeedbackReplyType;
  FeedBackTypeText = FeedBackTypeText;
  /**
   * @name 回复
   * @param row 反馈数据
   * @param type 回复类型
   */
  async handleFeedback(_, state, operate) {
    await this.$confirm(this.$t('feedback.confirmTip', { value: operate }));
    await updateState({
      state,
      pkId: this.data.pkId,
    });
    this.$message.success(this.$t('common.tips.operationSuccess'));
    this.$emit('refresh');
    this.data.state = state;
  }
  content = '';
  /**
   * @name 回复
   */
  async handleReply() {
    if (!this.content || !this.content.trim()) {
      return this.$message.warning(
        this.$t('common.placeholder.input') + this.$t('feedback.replyContent'),
      );
    }
    await feedbackReply({
      content: this.content,
      idxFeedbackId: this.pkId,
    });
    this.loadData();
    this.$message.success(this.$t('feedback.replySuccess'));
    this.content = '';
  }
  async handleRemove() {
    await this.$confirm(this.$t('feedback.deleteTip'));
    await deleteFeedback([this.data.pkId]);
    this.$message.success(this.$t('common.tips.delete'));
    this.$emit('remove');
  }
  created() {
    this.loadData();
  }
  loading = true;
  data = null;
  async loadData() {
    this.loading = true;
    if (!this.pkId) return;
    this.data = await getFeedbackDetail(this.pkId);
    try {
      this.data.accessory = JSON.parse(this.data.accessory);
    } catch (error) {
      this.data.accessory = [];
    }
    this.data.replyList.sort((a, b) => b.createDate - a.createDate);
    this.loading = false;
  }
}
</script>
<style lang="less" module>
.wrap {
  height: 100%;
  .toolbar {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border);
    padding: 7px 30px;
    .action {
      cursor: pointer;
      margin-right: 30px;
      &:hover {
        color: var(--font-active);
      }
      .action__icon {
        margin-right: 5px;
      }
    }
    .toolbar__button {
      margin-left: 20px;
    }
  }
  .content {
    padding-top: 30px;
    height: calc(100% - 200px);
    overflow-y: auto;
    .content-item {
      margin-bottom: 25px;
      .content-item__label {
        color: var(--font-info);
        margin-bottom: 8px;
      }
    }
    .form {
      padding: 0 20px;
    }
    .reply-list {
      border-top: 1px solid var(--form-border);
      padding: 20px;
    }
  }
  .form {
    padding: 10px 30px;
    .form-title {
      color: var(--font-info);
    }
    .form-input-box {
      position: relative;
      margin: 10px 0;
      .form-input__count {
        position: absolute;
        bottom: 6px;
        right: 6px;
        color: var(--font-info);
        font-size: 12px;
      }
    }
    .form-input {
      height: 80px;
    }
    .form-footer {
      text-align: right;
    }
  }
  .reply {
    display: flex;
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    .reply__avatar {
      width: 36px;
      height: 36px;
      line-height: 36px;
      border-radius: 50%;
      color: #fff;
      background-color: var(--font-active);
      text-align: center;
      font-size: 18px;
    }
    .reply__right {
      flex: 1;
      padding-left: 10px;
    }
    .reply__nickname {
      min-height: 28px;
      font-size: 16px;
      .reply__time {
        color: var(--font-info);
        font-size: 12px;
      }
    }
    .reply__content {
      color: var(--font-sub);
    }
  }
}
</style>
