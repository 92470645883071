<template>
  <common-table
    :add-button-text="addButtonText"
    :edit-button-text="editButtonText"
    :request-list="requestList"
  />
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import CommonTable from './components/common-table.vue';
import {
  deleteMedia,
  getMediaDetail,
  getMediaList,
  basePath,
} from '@/services/configuration/fire/news';
import { globalVueI18n } from '@triascloud/i18n';

@Component({ components: { CommonTable } })
export default class News extends Vue {
  requestList = {
    getTableList: basePath + '/get/config',
    deleteMedia: deleteMedia,
    getMediaDetail: getMediaDetail,
    getMediaList: getMediaList,
  };
  get addButtonText() {
    return globalVueI18n.translate('website.news.add');
  }
  get editButtonText() {
    return globalVueI18n.translate('website.news.edit');
  }
}
</script>
