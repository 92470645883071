<template>
  <div :class="$style.wrap">
    <ExcelIn
      v-show="$store.state.hat.excelState == 1"
      :handleClose="$listeners.handleClose"
    />
    <ExcelLoading
      v-show="$store.state.hat.excelState == 2"
      :handleClose="$listeners.handleClose"
    />
    <ExcelSuccess
      v-show="$store.state.hat.excelState == 3"
      :handleClose="$listeners.handleClose"
    />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import ExcelIn from './excel-in.vue';
import ExcelLoading from './excel-loading.vue';
import ExcelSuccess from './excel-success.vue';
@Component({
  components: {
    ExcelIn,
    ExcelLoading,
    ExcelSuccess,
  },
})
export default class Excel extends Vue {
  @Prop({ type: Object, default: () => {} }) record;
  state = 1;
  toggle(state) {
    this.state = state;
  }
}
</script>
<style lang="less" module>
.wrap {
}
</style>
