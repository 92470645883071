<template>
  <div :class="$style.createImageGroupModal">
    <a-form-model ref="form" :model="formData" :rules="rules" layout="vertical">
      <a-form-model-item
        prop="groupName"
        :label="$t('gallery.label.groupName') + '：'"
      >
        <a-input v-model="formData.groupName" />
      </a-form-model-item>
      <a-form-model-item
        prop="remark"
        :label="$t('gallery.label.remark') + '：'"
      >
        <a-input
          v-model="formData.remark"
          type="textarea"
          :auto-size="{ minRows: 2, maxRows: 6 }"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createFormModal, createModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';
import {
  createImgGroup,
  removeImgGroup,
  updateImgGroup,
} from '@/services/resource/cloud-img-group';

@Component({})
export default class CreateImageGroupModal extends Vue {
  @Prop({ type: String, default: '' }) pkId;
  @Prop({ type: String, default: '' }) groupName;
  @Prop({ type: String, default: '' }) remark;

  created() {
    if (this.pkId) {
      this.formData.groupName = this.groupName;
      this.formData.remark = this.remark;
    }
  }

  formData = {
    groupName: '',
    remark: '',
  };
  rules = {
    groupName: [
      {
        required: true,
        message: this.$t('validate.required', {
          value: this.$t('gallery.label.groupName'),
        }),
        trigger: 'blur',
      },
    ],
  };

  static createAddModal = createAddImageGroupModal;
  static createEditModal = createEditImageGroupModal;
}

function createAddImageGroupModal() {
  return createFormModal(render => render(CreateImageGroupModal), {
    title: globalVueI18n.translate('gallery.actions.addGroup'),
    width: 440,
  });
}

function createEditImageGroupModal({ pkId, groupName, remark }) {
  return new Promise(resolve => {
    const modal = { ref: null };
    modal.ref = createModal(
      render => {
        return render(CreateImageGroupModal, {
          props: {
            pkId,
            groupName,
            remark,
          },
        });
      },
      {
        width: 440,
        title: globalVueI18n.translate('gallery.actions.edit'),
        footer: h =>
          h(ImageGroupModalFooter, {
            props: {
              modal,
              resolve,
            },
          }),
      },
    );
  });
}

/**
 * footer 组件
 */
@Component({})
class ImageGroupModalFooter extends Vue {
  @Prop({ type: Object, default: () => null }) modal;
  @Prop({ type: Function }) resolve;

  handleClose() {
    this.modal.ref.handleClose();
  }

  handleCancel() {
    this.handleClose();
  }

  async handleConfirm() {
    // 约定好这个footer组件是为了上面的表单modal组件服务的
    // 所以直接进行表单校验
    const bodyInstance = this.modal.ref.getInstance();
    await bodyInstance.$refs.form.validate();
    let data = { ...bodyInstance.$refs.form.model };
    data.pkId = bodyInstance.pkId;
    bodyInstance.pkId ? await updateImgGroup(data) : await createImgGroup(data);
    this.handleClose();
    this.resolve('updated');
  }

  async handleDelete() {
    const bodyInstance = this.modal.ref.getInstance();
    if (!bodyInstance?.pkId) {
      return;
    }
    await removeImgGroup([bodyInstance.pkId]);
    this.handleClose();
    this.resolve('deleted');
  }

  render() {
    return (
      <div>
        <a-button on-click={() => this.handleCancel()}>
          {globalVueI18n.translate('common.action.cancel')}
        </a-button>
        <a-button on-click={() => this.handleDelete()} type="danger">
          {globalVueI18n.translate('common.action.delete')}
        </a-button>
        <a-button on-click={() => this.handleConfirm()} type="primary">
          {globalVueI18n.translate('common.action.confirm')}
        </a-button>
      </div>
    );
  }
}
</script>

<style lang="less" module>
.createImageGroupModal {
}
</style>
