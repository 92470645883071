<!-- 解决文件和图片下载跨域问题的<a>标签组件 -->
<template>
  <a :download="download" :href="href" @click.prevent="handleDownload">
    <slot />
  </a>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class XDownloadAnchor extends Vue {
  @Prop({ type: String }) href;
  @Prop({ type: String, default: '' }) download;
  handleDownload() {
    XDownloadAnchor.download(this.href, this.download);
  }

  static download = download;
}

/**
 * @param { string } url
 * @param { string } name
 */
function download(url, name) {
  if (!url) return Promise.resolve();
  return 'ActiveXObject' in window
    ? IEDownload(url, name)
    : HTML5Download(url, name);
}

/** @returns { Promise<void> } */
function IEDownload(url, name) {
  return new Promise(resolve => {
    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.addEventListener('load', () => {
      iframe.contentDocument.title = name;
      iframe.contentDocument.execCommand('SaveAs');
      resolve();
      iframe.parentElement.removeChild(iframe);
    });
    document.body.appendChild(iframe);
  });
}
async function HTML5Download(url, name = '') {
  const res = await window.fetch(url);
  const blob = await res.blob();
  const blobUrl = window.URL.createObjectURL(blob);
  const dom = document.createElement('a');
  dom.href = blobUrl;
  dom.download = name;
  document.body.appendChild(dom);
  dom.click();
  setTimeout(() => {
    dom.parentElement.removeChild(dom);
    window.URL.revokeObjectURL(blobUrl);
  });
}
</script>
