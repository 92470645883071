<template>
  <div>
    <div :class="$style.summary">
      <div :class="$style['summary-item']">
        <span>{{ name }}:</span>{{ data.name }}
      </div>
      <div :class="$style['summary-item']">
        <span>{{ $t('market.template.installScore') }}:</span
        >{{ data.integrateCount > 0 ? data.integrateCount : 0 }}
      </div>
      <div :class="$style['summary-item']">
        <span>{{ $t('market.template.sellingPrice') }}:</span>{{ formatPrice }}
      </div>
      <div :class="$style['summary-item']">
        <span>{{ $t('template.review.pushTenantName') }}:</span
        >{{ data.pushTenantName }}
      </div>
      <div :class="$style['summary-item']">
        <span>{{ $t('market.template.totalMoney') }}:</span>￥{{
          data.totalMoney > 0 ? data.totalMoney : 0
        }}
      </div>
      <div :class="$style['summary-item']">
        <span>{{ $t('dictionary.pushTime') }}:</span
        >{{ $moment(data.pushTime).format('Y-MM-DD') }}
      </div>
    </div>
    <div :class="$style.table">
      <div :class="$style.header">
        <div>{{ $t('market.template.installTenant') }}</div>
        <div>{{ $t('market.template.installTime') }}</div>
        <div>{{ $t('market.template.awardScore') }}</div>
        <div>{{ $t('template.review.earnPoints') }}</div>
      </div>
      <x-scroll-list
        v-model="list"
        :service="service"
        :class="$style['table-body']"
      >
        <div slot-scope="{ data }" :class="$style.item">
          <div :title="data.installTenant">{{ data.installTenant }}</div>
          <div>{{ $moment(data.installTime).format('Y-MM-DD HH:mm') }}</div>
          <div>￥{{ data.awardMoney }}</div>
          <div>{{ data.awardScore }}</div>
        </div>
        <empty-content slot="empty" />
      </x-scroll-list>
    </div>
  </div>
</template>
<script>
import { createModal } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { globalVueI18n } from '@triascloud/i18n';
import EmptyContent from '@/components/empty-content';
import {
  formInstallDetail,
  applicationInstallDetail,
} from '@/services/template-manager/template';
import { TemplateType } from '@/enum/template';

function createInstallHistoryModal(props) {
  return createModal(h => h(InstallHistory, { props }), {
    title: globalVueI18n.translate('market.template.installDetail'),
    width: 600,
    bodyStyle: { padding: 0 },
  });
}
@Component({
  components: { EmptyContent },
})
export default class InstallHistory extends Vue {
  static createModal = createInstallHistoryModal;
  @Prop({ type: Object, required: true }) data;
  @Prop({ type: String }) templateType;
  list = [];
  TemplateType = TemplateType;
  service(current) {
    if (this.templateType === TemplateType.Application) {
      return applicationInstallDetail({
        templateId: this.data.id,
        current,
        size: 10,
      }).then(res => res.records);
    } else if (this.templateType === TemplateType.Form) {
      return formInstallDetail({
        templateId: this.data.id,
        current,
        size: 10,
      }).then(res => res.records);
    }
  }
  get name() {
    return this.templateType === TemplateType.Application
      ? this.$t('market.template.appName')
      : this.$t('market.template.formName');
  }
  get formatPrice() {
    if (this.data.price > 0) {
      return `￥${parseFloat(this.data.price / 100) || 0}`;
    }
    return this.$t('market.template.free');
  }
}
</script>
<style lang="less" module>
.summary {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  padding-bottom: 0;
  border-bottom: 1px solid var(--form-border);
  .summary-item {
    width: 33.33%;
    padding-right: 10px;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      color: var(--font-info);
      margin-right: 6px;
    }
  }
}
.item,
.header {
  display: flex;
  color: var(--font);
  & > div {
    height: 47px;
    line-height: 47px;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 25%;
  }
}
.header {
  background: var(--table-header-bg);
}
.item {
  font-size: 12px;
  color: var(--font-sub);
  height: 44px;
  line-height: 44px;
  &:nth-child(2n) {
    background: var(--table-row-stripe);
  }
}
.table {
  padding: 20px;
  .table-body {
    height: 360px;
    overflow-y: auto;
  }
}
</style>
