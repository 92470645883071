import { canJump } from '@/services/flow/people';
export default async function(message) {
  switch (message.msgEvent) {
    case 'triascloud-workflow':
      if (
        message.detail &&
        message.detail.activityId &&
        message.detail.dataId &&
        message.detail.formId
      ) {
        const {
          activityId,
          dataId,
          formId,
          subFormId,
          subDataId,
          executionId,
          noticeEven,
          targetAppId,
          targetFormId,
          appId,
          activityType,
        } = message.detail;
        const notApprove = [
          'INTELLIGENCE_SUCCESS',
          'INTELLIGENCE_FAIL',
          'PUSH_FAIL',
        ];
        if (!notApprove.includes(noticeEven))
          await canJump({ executionId, taskId: activityId, noticeEven });
        switch (noticeEven) {
          case 'SUB_PROCESS':
            return `/todo/my?activityId=${activityId}&formId=${subFormId}&activityType=${activityType}`;
          case 'AUTO_SUB_PROCESS':
            return `/application/${appId}/form/${subFormId}?dataId=${subDataId}`;
          case 'INTELLIGENCE_SUCCESS':
            return `/application/${targetAppId}/form/${targetFormId}`;
          case 'INTELLIGENCE_FAIL':
            return `/application/${appId}/form/${formId}`;
          case 'PUSH_FAIL':
            return `/application/${appId}/form/${formId}?dataId=${dataId}`;
          case 'CC':
            return `/todo/cc?activityId=${activityId}&dataId=${dataId}&formId=${formId}`;
          default:
            return `/todo/my?activityId=${activityId}&dataId=${dataId}&formId=${formId}`;
        }
      }
      break;
    case 'applyForMember':
      return '/company/book';
    case 'addFeedbackMsg':
      return `/company/feedback-processing?pkId=${message.redirectId}`;
    case 'addFeedbackReplyMsg':
      return `/feedback?pkId=${message.redirectId}`;
    case 'repository-comment-event':
    case 'repository-remind-event':
    case 'repository-subscription-event': {
      const { repositoryId, documentId } = message.detail;
      return `/knowledge/${repositoryId}/${documentId}`;
    }
    case 'form-comment-event':
    case 'table-data-event': {
      const { formId, appId, dataId } = message.detail || {};
      if (!formId || !appId || !dataId) return '';
      return `/application/${appId}/form/${formId}?dataId=${dataId}`;
    }
    case 'SHARE_FAVORITE_EVENT': {
      const { appId, parentId, pkId, type } = message.detail || {};
      switch (type) {
        case 'REPOSITORY':
          return `/knowledge/${parentId}/${pkId}/`;
        case 'FORM':
          return `/application/${appId}/form/${parentId}?dataId=${pkId}`;
        case 'DICTIONARY':
          return `/dictionary/${pkId}`;
        default:
          return `/dictionary/${pkId}`;
      }
    }
    case 'triascloud-custom-application':
      if (message.detail && message.detail.appId && message.detail.formId) {
        const { appId, formId } = message.detail;
        return `/application/${appId}/form/${formId}`;
      }
      break;
    case 'COMBO_TIME_OVER':
      return '/combo';
    case 'CAPACITY_NOT_ENOUGH':
      return '/company/statistics';
    case 'triascloud-schedule':
      if (message.detail && message.detail.taskId) {
        return `/task/all?ident=${message.detail.taskId}`;
      }
      break;
    default:
      return '';
  }
}
