<template>
  <div class="x-tenant-change">
    <div class="x-tenant-change--logo" />
    <div class="x-tenant-change--tips">
      <h3>{{ $t('tenantChange.title') }}</h3>
      <p>
        <span>{{ $t('tenantChange.tips') }}</span>
        <span class="x-tenant-change--name">{{ tenant.enterpriseName }}</span>
      </p>
      <a-button type="primary" @click="handleRedirect">
        {{ $t('tenantChange.action') }}
      </a-button>
    </div>
  </div>
</template>
<script>
import { createModuleUrl, safeParseJSON } from '@/utils';
import { crossStorage, CrossStorageEventType } from '@triascloud/cross-storage';
import { promiseLock } from '@triascloud/utils';
import { createModal } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';

function createTenantChangeModal(tenant) {
  return new Promise(resolve => {
    const modal = createModal(
      h =>
        h(TenantChange, {
          props: { tenant },
          on: {
            close: () => {
              modal.handleClose();
              resolve();
            },
          },
        }),
      {
        className: 'x-tenant-change--modal',
        closable: false,
        maskClosable: false,
        width: 660,
      },
    );
  });
}

@Component()
export default class TenantChange extends Vue {
  static create = promiseLock(createTenantChangeModal, () => 'tenantChange');

  @Prop() tenant;

  handleRedirect() {
    crossStorage.setItem('tenant', crossStorage.getItem('tenant'));
    // 更新权限
    this.$router.permission.init(true);
    // this.$router.push('/');
    this.$emit('close');
    location.href = createModuleUrl('app');
  }

  handleStorageUpdate({ key, value }) {
    if (key !== 'tenant') return;
    const newTenant = safeParseJSON(value, null);
    if (!newTenant || newTenant.pkId === this.tenant.pkId) {
      this.$emit('close');
    }
  }

  created() {
    crossStorage.serverEmitter.on(
      CrossStorageEventType.Update,
      this.handleStorageUpdate,
    );
  }

  beforeDestroy() {
    crossStorage.serverEmitter.off(
      CrossStorageEventType.Update,
      this.handleStorageUpdate,
    );
  }
}
</script>
<style lang="less">
@tenant-change-prefix: x-tenant-change;

.@{tenant-change-prefix} {
  padding: 80px 20px 45px;
  display: flex;
}

.@{tenant-change-prefix}--modal {
  .ant-modal-mask {
    z-index: 9999;
  }
  .ant-modal-wrap {
    z-index: 10000;
  }
}

.@{tenant-change-prefix}--logo {
  width: 256px;
  height: 207px;
  margin-right: 15px;
  background-image: url('../../assets/img/expired.png');
}

.dark .@{tenant-change-prefix}--logo {
  background-image: url('../../assets/img/expired-dark.png');
}

.@{tenant-change-prefix}--tips {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  h3 {
    font-size: 22px;
    font-weight: bold;
    color: var(--font);
  }
  p {
    font-size: 12px;
    color: var(--font-sub);
  }
  .@{tenant-change-prefix}--name {
    color: var(--primary);
  }

  .ant-btn {
    width: 124px;
    height: 34px;
    font-size: 16px;
    margin-top: 60px;
  }
}
</style>
