<template>
  <div :class="$style.dragItem">
    <x-custom-icon :type="data" :class="$style.icon" />
    <span :class="$style.name">
      {{ iconName }}
    </span>
    <span :class="$style.action">
      <x-icon-library @change="handleChangeIcon">
        <x-icon slot="block" type="tc-icon-exchange" />
      </x-icon-library>
      <slot name="drag" />
    </span>
  </div>
</template>
<script>
import { addDefaultIcon } from '@/services/resource/icon-library';
import { crossStorage } from '@triascloud/cross-storage';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Icon from '../icon';

@Component({
  components: { Icon },
})
export default class DragItem extends Vue {
  @Prop() data;
  @Prop({ type: String, default: '' }) libraryId;
  @Prop() list;

  async handleChangeIcon(value) {
    if (value) {
      let result = this.list;
      result.splice(this.list.indexOf(this.data), 1, value);

      await addDefaultIcon(this.libraryId, result);

      this.$emit('change');
    }
  }

  tryParseJson(json, catchFn) {
    if (!json) return catchFn();
    try {
      return JSON.parse(json);
    } catch (e) {
      return catchFn();
    }
  }

  get iconName() {
    const icon = this.tryParseJson(
      crossStorage.getItem(`services.iconLibrary.${this.data}`),
      () => null,
    );
    if (!icon) return null;
    return icon.iconName;
  }
}
</script>
<style lang="less" module>
:global {
  .tint {
    --block-hover-bg: #e9e9e9;
  }
  .dark {
    --block-hover-bg: #343434;
  }
}
.dragItem {
  width: 100px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  padding-top: 5px;

  .icon {
    font-size: 40px;
    line-height: 1em;
    margin-bottom: 10px;
  }
  .name {
    font-size: 12px;
    color: var(--font);
    margin-bottom: 10px;
  }
  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45px;
    display: none;

    i {
      font-size: 14px;
      &:hover {
        color: var(--primary);
        cursor: pointer;
      }
    }
  }

  &:hover {
    background: var(--block-hover-bg);
    border-radius: 4px;

    .action {
      display: flex;
    }
  }
}
</style>
