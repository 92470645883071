<template>
  <div :class="$style.ctrl">
    <a-form-model layout="inline">
      <a-form-model-item :label="$t('plugin.overview.statistical')">
        <x-select
          :class="$style.selectWrap"
          v-model="type"
          :data="typeOptions"
          :showSearch="false"
          @input="handleSelect"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component()
export default class SwitchTab extends Vue {
  type = '3';
  typeOptions = [
    { text: this.$t('plugin.overview.apicpt'), value: '3' },
    { text: this.$t('plugin.overview.Database'), value: '2' },
    { text: this.$t('plugin.snms.name'), value: '1' },
  ];

  links = {
    '3': '/plugin/api',
    '2': '/plugin/data',
    '1': '/plugin/snms',
  };

  handleSelect() {
    this.$router.push(this.links[this.type]);
  }

  created() {
    this.handleRouteChange();
  }

  @Watch('$route')
  handleRouteChange() {
    this.type = Object.entries(this.links).find(
      item => item[1] === this.$route.path,
    )[0];
  }
}
</script>
<style lang="less" module>
.ctrl {
  padding: 20px;
  :global(.ant-form-item-label) {
    label::after {
      content: ':';
    }
  }
}
.selectWrap {
  width: 150px;
}
.primaryColor {
  color: var(--primary);
}
</style>
