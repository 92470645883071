<template>
  <a-form-model ref="form" :model="model" :rules="rules">
    <a-form-model-item
      prop="accessTenantSet"
      :key="$t('iconLibrary.library.company')"
    >
      <a-radio
        @click="model.accessTenantSet = null"
        :checked="!model.accessTenantSet"
      >
        {{ $t('common.label.all') }}
      </a-radio>
      <a-radio
        @click="model.accessTenantSet = model.accessTenantSet || []"
        :checked="!!model.accessTenantSet"
      >
        {{ $t('hat.table.selectCompany') }}
      </a-radio>
      <a @click="select">{{ $t('common.label.click') }}</a>
    </a-form-model-item>
    <div v-if="model.accessTenantSet">
      {{
        $t('iconLibrary.library.selectedCompany', {
          value: model.accessTenantSet.length,
        })
      }}
    </div>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { updateLibrary } from '@/services/resource/icon-library';
import CompanySelect from '@/components/company-select';
import { globalVueI18n } from '@triascloud/i18n';

function createLibraryCompanySetting(props) {
  return createFormModal(h => h(LibraryCompanySetting, { props }), {
    title: globalVueI18n.translate('iconLibrary.library.company'),
    width: 600,
  });
}

@Component
export default class LibraryCompanySetting extends Vue {
  @Prop({ type: String }) pkId;
  @Prop() accessTenant;
  static create = createLibraryCompanySetting;
  model = {
    accessTenantSet: null,
  };
  get rules() {
    return {
      accessTenantSet: {
        validator: (_, value, callback) => {
          if (value && !value.length) {
            callback(this.$t('ds.device.selectEnterprise'));
          } else {
            callback();
          }
        },
      },
    };
  }
  async getValue() {
    await this.$refs.form.validate();
    await updateLibrary({
      pkId: this.pkId,
      accessTenantSet: this.model.accessTenantSet,
    });
    this.$message.success(this.$t('common.tips.save'));
    return this.model;
  }
  async select() {
    const value = await CompanySelect.create(this.model.accessTenantSet, true);
    this.model.accessTenantSet = value;
    this.$refs.form.validateField('accessTenantSet');
  }
  async created() {
    this.model.accessTenantSet = this.accessTenant?.length
      ? this.accessTenant
      : null;
  }
}
</script>
