<template>
  <div v-if="data" :style="$style.detail">
    <div :class="$style.header">
      <div :class="$style.header__left">
        <span :class="$style.header__title" :title="data.dictionaryName">{{
          data.dictionaryName
        }}</span>
        <x-icon
          type="link"
          :class="[$style.header__icon, $style['header__icon--link']]"
        />
        <span>{{ data.quoteCount }}</span>
        <!-- <x-icon type="eye" :class="$style.header__icon" /> -->
      </div>
      <div :class="$style.header__right">
        <x-oss-image
          :oss-path="data.createAvatar"
          :class="$style.header__avatar"
        />
        <span :class="$style.header__nickname">{{ data.createNickName }}</span>
        <span :class="$style.header__time">{{
          $moment(data.createDate).format('Y-MM-DD')
        }}</span>
      </div>
    </div>
    <div :class="$style.table">
      <div :class="$style.table__row">
        <div :class="$style.table__cell">{{ $t('common.label.index') }}</div>
        <div :class="$style.table__cell">{{ $t('dictionary.subsetName') }}</div>
      </div>
      <div
        v-for="(item, index) in data.children"
        :key="item.pkId"
        :class="$style.table__row"
      >
        <div :class="$style.table__cell">{{ index + 1 }}</div>
        <div :class="$style.table__cell" :title="item.dictionaryName">
          {{ item.dictionaryName }}
        </div>
        <x-icon
          v-if="!view && !review"
          type="tc-icon-delete"
          :class="$style.table__icon"
          @click="removeChild(item, index)"
        />
      </div>
    </div>
    <template v-if="!review">
      <div :class="$style.summary">
        <span :class="$style.summary__label"
          >{{ $t('dictionary.reviewer') }}：</span
        >
        <span :class="$style.summary__value">{{ data.checkName }}</span>
        <span :class="$style.summary__time">{{
          $moment(data.checkDate).format('Y-MM-DD HH:mm')
        }}</span>
        <template v-if="data.deleteName">
          <span :class="$style.summary__label"
            >{{ $t('dictionary.deletePerson') }}：</span
          >
          <span :class="$style.summary__value">{{ data.deleteName }}</span>
          <span :class="$style.summary__time">{{
            $moment(data.deleteDate).format('Y-MM-DD HH:mm')
          }}</span>
        </template>
      </div>
      <div :class="$style.summary">
        <template v-if="data.regainBy">
          <span :class="$style.summary__label"
            >{{ $t('dictionary.recoveryPerson') }}：</span
          >
          <span :class="$style.summary__value">{{ data.regainName }}</span>
          <span :class="$style.summary__time">{{
            $moment(data.regainDate).format('Y-MM-DD HH:mm')
          }}</span>
        </template>
        <span :class="$style.summary__label"
          >{{ $t('dictionary.company') }}：</span
        >
        <span :class="$style.summary__value">{{
          data.shareEnterpriseName
        }}</span>
      </div>
    </template>
    <div :class="$style['form-item']">
      <div :class="$style['form-item-label']">
        <span>{{ $t('dictionary.category') }}</span>
      </div>
      <x-select
        v-if="!view"
        v-model="formData.idxBusinessId"
        :placeholder="$t('dictionary.selectCategory')"
        :class="$style['form-item-select']"
      >
        <a-select-option
          v-for="item in business"
          :key="item.pkId"
          :value="item.pkId"
          >{{ item.businessName }}</a-select-option
        >
      </x-select>
      <span v-else>{{ businessName }}</span>
    </div>
    <div :class="$style['form-item']">
      <div :class="$style['form-item-label']">
        <span>{{ $t('dictionary.tag') }}</span>
        <span v-if="!view">{{ $t('dictionary.tagMax') }}</span>
      </div>
      <div
        :class="[
          $style['form-item-tag'],
          { [$style['form-item-tag--view']]: view },
        ]"
      >
        <a-tag
          v-for="(item, index) in formData.labels"
          :key="item.pkId"
          :closable="!view"
          :class="$style.tag"
          @close="formData.labels.splice(index, 1)"
          >{{ item.labelName }}</a-tag
        >
      </div>
    </div>
    <div v-if="!view" :class="$style['form-item']">
      <div :class="$style['form-item-label']">
        <span>{{ $t('dictionary.selectTag') }}</span>
      </div>
      <div
        :class="[
          $style['form-item-tag'],
          { [$style['form-item-tag--view']]: view },
        ]"
      >
        <a-popover
          v-if="!view"
          v-model="addVisible"
          placement="bottom"
          trigger="click"
        >
          <div slot="content" :class="$style['form-popoverInput']">
            <a-input
              v-model="labelName"
              :max-length="10"
              :class="$style['form-popoverInput__input']"
              auto-focus
              @pressEnter="addLabel"
            >
              <span slot="suffix">{{ labelName.length }}/10</span>
            </a-input>
            <a-button
              type="primary"
              :class="$style['form-popoverInput__button']"
              @click="addLabel"
              >{{ $t('common.action.confirm') }}</a-button
            >
          </div>
          <a-button type="primary" icon="plus" :class="$style['tag-add']">{{
            $t('dictionary.addTag')
          }}</a-button>
        </a-popover>
        <a-tag
          v-for="item in labels"
          :key="item.pkId"
          :class="$style.tag"
          @click="handelCheckLabel(item)"
          >{{ item.labelName }}</a-tag
        >
      </div>
    </div>
    <div v-if="review" :class="$style['form-item']">
      <div :class="$style['form-item-label']">
        <span>{{ $t('dictionary.auditOpinion') }}</span>
      </div>
      <div v-if="!view" :class="$style['form-item-textarea']">
        <a-textarea v-model="formData.checkOpinion" :max-length="200" />
        <span :class="$style['form-item-textarea__count']"
          >{{ formData.checkOpinion.length }}/200</span
        >
      </div>
      <div v-else>{{ formData.checkOpinion }}</div>
    </div>
    <div
      v-if="review && data.checkStatus !== CheckStatus.Unchecked"
      :class="[$style.summary, $style['summary--footer']]"
    >
      <span :class="$style.summary__label"
        >{{ $t('dictionary.originalReviewer') }}：</span
      >
      <span :class="$style.summary__value">{{ data.checkName }}</span>
      <span :class="$style.summary__time">{{
        data.checkDate && $moment(data.checkDate).format('Y-MM-DD HH:mm')
      }}</span>
      <span :class="$style.summary__label"
        >{{ $t('dictionary.auditOpinion') }}：</span
      >
      <span :class="$style.summary__value">{{ data.checkOpinion }}</span>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getAllBusiness } from '@/services/tenant-center/business';
import { CheckStatus } from '@/enum/dictionary';
import {
  getAllDictionaryLabel,
  addDictionaryLabel,
} from '@/services/resource/cloud-dictionary-label';
import {
  getDictionaryDetail,
  getCheckDictionaryDetail,
} from '@/services/resource/cloud-dictionary';
@Component()
export default class DictionaryDetail extends Vue {
  @Prop({ type: String, required: true }) pkId;
  @Prop({ default: false, type: Boolean }) view;
  @Prop({ default: false, type: Boolean }) review;
  CheckStatus = CheckStatus;
  value;
  formData = {
    children: [],
    labels: [],
    pkId: '',
    idxBusinessId: '',
    removeChildrenIds: [],
    checkOpinion: '',
  };
  data = null;
  get businessName() {
    const business = this.business.find(
      item => item.pkId === this.formData.idxBusinessId,
    );
    return business ? business.businessName : this.formData.idxBusinessId;
  }
  created() {
    this.getData();
    this.getBusiness();
    this.getAllDictionaryLabel();
  }
  async getData() {
    this.data = this.review
      ? await getCheckDictionaryDetail(this.pkId)
      : await getDictionaryDetail(this.pkId);
    this.formData = {
      idxBusinessId: this.data.idxBusinessId,
      labels: this.data.labels.map(item => ({ ...item })),
      pkId: this.pkId,
      removeChildrenIds: [],
      checkOpinion: this.data.checkOpinion || '',
    };
  }
  business = [];
  async getBusiness() {
    this.business = await getAllBusiness();
  }
  labels = [];
  async getAllDictionaryLabel() {
    this.labels = await getAllDictionaryLabel();
  }
  addVisible = false;
  labelName = '';
  async addLabel() {
    if (!this.labelName.trim()) return;
    await addDictionaryLabel({ labelName: this.labelName });
    this.addVisible = false;
    this.labelName = '';
    this.getAllDictionaryLabel();
  }
  removeChild(child, index) {
    this.formData.removeChildrenIds.push(child.pkId);
    this.data.children.splice(index, 1);
  }
  handelCheckLabel(label) {
    if (
      this.view ||
      this.formData.labels.some(item => item.pkId === label.pkId) ||
      this.formData.labels.length >= 5
    )
      return;
    this.formData.labels.push(label);
  }
}
</script>
<style lang="less" module>
.detail {
  padding: 30px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header__left {
    font-size: 12px;
    display: flex;
    align-items: center;
    overflow: hidden;
    color: var(--font-info);
    .header__title {
      display: block;
      font-size: 16px;
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--font);
    }
    .header__icon {
      margin-left: 10px;
      cursor: pointer;
      &.header__icon--link {
        color: var(--font-active);
        margin-right: 10px;
        cursor: default;
      }
    }
  }
  .header__right {
    font-size: 12px;
    color: var(--font-info);
    display: flex;
    align-items: center;
    .header__nickname {
      margin: 0 10px 0 8px;
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
    .header__avatar {
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
}
.table {
  margin: 20px 0;
  .table__row {
    display: flex;
    position: relative;
    line-height: 40px;
    align-items: center;
    &:first-child {
      color: var(--font);
      .table__cell {
        border-top: 1px solid var(--table-border);
      }
    }
    .table__cell {
      border-color: var(--table-border);
      border-style: solid;
      border-width: 0 1px 1px 0;
      &:first-child {
        text-align: center;
        width: 66px;
        border-left: 1px solid var(--table-border);
      }
      &:nth-child(2) {
        flex: 1;
        padding: 0 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .table__icon {
      position: absolute;
      padding: 6px;
      display: none;
      color: var(--danger);
      cursor: pointer;
      right: -26px;
      top: 6px;
    }
    &:nth-child(2n) .table__cell {
      background-color: var(--list-hover);
    }
    &:hover {
      .table__cell {
        background-color: var(--list-active);
      }
      .table__icon {
        display: block;
      }
    }
  }
}
.summary {
  font-size: 12px;
  color: var(--font-info);
  line-height: 28px;
  .summary__value {
    color: var(--font-sub);
  }
  .summary__label {
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
  }
  .summary__time {
    margin-left: 15px;
  }
  &.summary--footer {
    margin-top: 20px;
  }
}
.form-item {
  margin-top: 20px;
  .form-item-label {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: var(--font-info);
    line-height: 28px;
  }
  .form-item-select {
    width: 100%;
  }
  .form-item-tag {
    border: 1px solid var(--form-border);
    padding: 6px;
    border-radius: 4px;
    min-height: 80px;
    max-height: 160px;
    overflow-y: auto;
    &.form-item-tag--view {
      border: none;
      max-height: none;
      min-height: auto;
    }
  }
  .form-item-textarea {
    position: relative;
    height: 80px;
    :global .ant-input {
      height: 100%;
    }
    .form-item-textarea__count {
      position: absolute;
      bottom: 5px;
      right: 5px;
    }
  }
}
.tag-add {
  height: 30px;
  margin-right: 10px;
}
.tag {
  height: 30px;
  border: 1px solid var(--font-active);
  color: var(--font-active);
  cursor: pointer;
  margin: 5px 10px 5px 0;
  padding: 6px 12px;
  line-height: 16px;
  &:last-child {
    margin-right: 0;
  }
}
.form-popoverInput {
  display: flex;
  .form-popoverInput__input {
    flex: 1;
  }
  .form-popoverInput__button {
    height: 30px;
    margin-left: 10px;
  }
}
</style>
