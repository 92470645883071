<template>
  <div class="x-design-action">
    <a-button
      v-if="clone"
      class="x-design-action--clone"
      @click.stop="$emit('clone')"
      shape="circle"
    >
      <x-icon type="tc-icon-copy" />
    </a-button>
    <a-button
      class="x-design-action--delete"
      @click.stop="$emit('delete')"
      shape="circle"
    >
      <x-icon type="tc-icon-delete" />
    </a-button>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class DesignAction extends Vue {
  @Prop({ default: false, type: Boolean }) clone;
}
</script>
<style lang="less">
.tint {
  .x-design-action {
    --action-delete-color: var(--delete);
  }
}
.dark {
  .x-design-action {
    --action-delete-hover-color: #cccccc;
  }
}
.x-design-action {
  position: absolute;
  display: flex;
  z-index: 101;
  bottom: -16px;
  right: 10px;
  .ant-btn {
    min-width: 32px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: var(--main-bg);
    border: none;
    box-shadow: 0 3px 7px 0 var(--shadow);
    &:hover,
    &:focus,
    &:active {
      background-color: none;
      &.x-design-action--clone {
        color: var(--primary-60);
      }
      &.x-design-action--delete {
        color: rgba(254, 89, 89, 0.8);
      }
    }
  }
}
.x-design-action--clone {
  color: var(--primary);
  margin-right: 24px;
}
.x-design-action--delete {
  color: var(--action-delete-color);
}
</style>
