<template>
  <a-spin :spinning="loading">
    <div :class="$style.header">
      <span :class="$style.header__label"
        >{{ $t('help.sharedDirectory') }}:</span
      >{{ $t('dictionary.total')
      }}<span :class="$style.header__count">{{ total }}</span
      >{{ $t('common.label.piece') }}，{{ $t('help.shared')
      }}<span :class="$style.header__count">{{ checkedKeys.length }}</span
      >{{ $t('common.label.piece') }}
    </div>
    <x-tree
      :tree-data="treeData"
      :replace-fields="{ key: 'pkId' }"
      :class="$style.tree"
      check-strictly
      :checked-keys="checkedKeys"
      checkable
      disabled
      @check="handleNodeCurrentChange"
    />
  </a-spin>
</template>
<script>
import { getIndexSetting } from '@/services/resource/repository';
import { globalVueI18n } from '@triascloud/i18n';
import { eachTree } from '@triascloud/utils';
import { createModal } from '@triascloud/x-components';
import { Component, Vue, Prop } from 'vue-property-decorator';

function createPublicRepositoryIndexModal(props) {
  return createModal(h => h(PublicRepositoryIndexModal, { props }), {
    title: globalVueI18n.translate('help.sharedDirectory'),
    width: 500,
    bodyStyle: { height: '450px' },
  });
}

@Component()
export default class PublicRepositoryIndexModal extends Vue {
  @Prop({ required: true, type: String }) pkId;
  static createModal = createPublicRepositoryIndexModal;
  treeData = [];
  loading = true;
  get checkedKeys() {
    const keys = [];
    eachTree(this.treeData, item => {
      item.open && keys.push(item.pkId);
    });
    return keys;
  }
  total = 0;
  async created() {
    this.treeData = await getIndexSetting(this.pkId);
    let total = 0;
    eachTree(this.treeData, () => {
      total++;
    });
    this.total = total;
    this.loading = false;
  }
  handleNodeCurrentChange(_, { node: { checked, dataRef } }) {
    eachTree(dataRef.children, item => {
      item.open = !checked;
    });
    dataRef.open = !checked;
  }
  async getValue() {
    const keys = [];
    eachTree(this.treeData, ({ open, pkId }) => {
      keys.push({
        open,
        pkId,
      });
    });
  }
}
</script>
<style lang="less" module>
.header {
  .header__label {
    color: var(--font-info);
  }
  .header__count {
    color: var(--font-active);
  }
}
.tree {
  margin-top: 4px;
  height: 410px;
  overflow-y: auto;
  :global {
    li span.ant-tree-checkbox + .ant-tree-node-content-wrapper {
      width: calc(100% - 60px);
    }
    li ul {
      width: 100%;
      order: 3;
    }
    .ant-tree-checkbox {
      order: 2;
    }
    li:last-child,
    li:last-child {
      padding: 0;
    }
    li .ant-tree-node-content-wrapper,
    .ant-tree-node-content-wrapper::before {
      height: 30px;
      line-height: 30px;
    }
  }
}
</style>
