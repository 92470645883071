<template>
  <div>
    <x-data-table
      base-path="/tenant-center/enterprise/approval/authenticationList"
      :columns="columns"
      :service="service"
      ref="table"
    >
      <div slot="headerLeft">
        <div :class="$style.headerSearch">
          <a-row :gutter="20">
            <a-col :span="4">
              <a-input-search
                v-model="search.keyword"
                :placeholder="$t('common.placeholder.input')"
              ></a-input-search>
            </a-col>
            <a-col :span="12" :class="$style.searchPicker">
              <span>{{ searchText }}</span>
              <span>
                <x-range-picker
                  v-model="search.time"
                  :format="searchFormat"
                ></x-range-picker>
              </span>
            </a-col>
          </a-row>
        </div>
      </div>
      <!-- <div slot="headerRight"></div> -->
    </x-data-table>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DataSourceService } from '@triascloud/services';
import moment from 'moment';
import { fetchList, fetchDel } from '@/services/attest/index';
// import AttestDetail from './attest-detail.vue';
import ReadDetail from './read-detail.vue';

@Component({})
export default class AttestTable extends Vue {
  @Prop({ type: String, default: '' }) tabsActive;

  search = {
    keyword: null,
    time: [],
  };
  searchFormat = 'YYYY-MM-DD';
  get searchText() {
    switch (this.tabsActive) {
      case '1':
        return this.$t('enterprise.CertificationTime');
      case '2':
        return this.$t('enterprise.ApplicationTime');
      default:
        return this.$t('enterprise.rejectionTime');
    }
  }

  get service() {
    const service = new DataSourceService(
      `/tenant-center/enterprise/approval/authenticationList`,
    );
    service.getList = ({ skip, size }) => {
      return fetchList({
        keyword: this.search.keyword,
        enStatus: this.tabsActive,
        ...this.filterSearchTime(),
        size,
        page: skip / size + 1,
      }).then(res => {
        this.total = res.total;
        return res
          ? {
              total: res.total,
              data: res?.recordList,
            }
          : { total: 0, data: [] };
      });
    };
    return service;
  }

  @Watch('search', { deep: true, immediate: true })
  onSearchChange() {
    this.refreshData();
  }
  refreshData() {
    this.$refs.table?.loadData({
      keyword: this.search.keyword,
      ...this.filterSearchTime(),
    });
  }

  filterSearchTime() {
    switch (this.tabsActive) {
      case '2':
      case '3':
        return {
          approvalTimeEnd: '',
          approvalTimeStart: '',
          applicationTimeEnd: this.search.time[1]
            ? moment(this.search.time[1]).format(this.searchFormat)
            : '',
          applicationTimeStart: this.search.time[0]
            ? moment(this.search.time[0]).format(this.searchFormat)
            : '',
        };
      case '1':
        return {
          applicationTimeEnd: '',
          applicationTimeStart: '',
          approvalTimeEnd: this.search.time[1]
            ? moment(this.search.time[1]).format(this.searchFormat)
            : '',
          approvalTimeStart: this.search.time[0]
            ? moment(this.search.time[0]).format(this.searchFormat)
            : '',
        };
    }
  }

  async handleRead(record) {
    // await AttestDetail.createAttestDetail({
    //   modalTitle: this.$t('enterprise.attest'),
    //   record: record,
    //   attestStatus: this.tabsActive,
    // });
    await ReadDetail.createReadDetail({
      modalTitle: this.$t('enterprise.attest'),
      record: record,
      attestStatus: this.tabsActive,
    });
    this.refreshData();
  }
  handleDel(row) {
    this.$confirm({
      title: this.$t('common.action.delete'),
      content: () => <div>{this.$t('enterprise.deltip')}</div>,
      onOk: () => {
        fetchDel(row.pkId).then(() => {
          this.refreshData();
        });
      },
      onCancel() {},
      class: 'test',
    });
  }

  getApplicationTypeText(type) {
    switch (type) {
      case '1':
        return this.$t('enterprise.enterpriseText');
      case '2':
        return this.$t('enterprise.GovernmentPublicInstitutions');
      case '3':
        return this.$t('enterprise.other');
      default:
        return '-';
    }
  }

  get columns() {
    const base = [
      {
        dataIndex: 'creator',
        title: this.$t('solution.applicant'),
        key: 'creator',
        filter: false,
        sorter: false,
        customRender: record => <span slot="creator">{record}</span>,
      },
      {
        dataIndex: 'applicationType',
        title: this.$t('enterprise.ApplicationType'),
        key: 'applicationType',
        filter: false,
        sorter: false,
        customRender: record => (
          <span slot="applicationType">
            {this.getApplicationTypeText(record)}
          </span>
        ),
      },
      {
        dataIndex: 'creatorTenantName',
        title: this.$t('solution.applyBusiness'),
        key: 'creatorTenantName',
        filter: false,
        sorter: false,
        customRender: record => <span slot="creatorTenantName">{record}</span>,
      },
      {
        dataIndex: 'enName',
        title: this.$t('enterprise.fullName'),
        key: 'enName',
        filter: false,
        sorter: false,
        customRender: record => <span slot="enName">{record}</span>,
      },
      {
        dataIndex: 'enOrganizationCode',
        title: this.$t('enterprise.OrganizationCode'),
        key: 'enOrganizationCode',
        filter: false,
        sorter: false,
        customRender: record => <span slot="enOrganizationCode">{record}</span>,
      },
      {
        dataIndex: 'enLicenseCode',
        title: this.$t('enterprise.license '),
        key: 'enLicenseCode',
        filter: false,
        sorter: false,
        customRender: record => <span slot="enLicenseCode">{record}</span>,
      },
      {
        dataIndex: 'enLawPerson',
        title: this.$t('enterprise.LegalRepresentative'),
        key: 'enLawPerson',
        filter: false,
        sorter: false,
        customRender: record => <span slot="enLawPerson">{record}</span>,
      },
      {
        dataIndex: 'enBusinessLicense',
        title: this.$t('enterprise.BusinessLicense'),
        key: 'enBusinessLicense',
        filter: false,
        sorter: false,
        customRender: record => (
          <span slot="enBusinessLicense">
            <x-oss-image
              ossPath={record}
              size={40}
              onClick={() =>
                this.$preview([
                  { url: record, name: this.$t('enterprise.BusinessLicense') },
                ])
              }
            />
          </span>
        ),
      },
    ];
    let temp = [];

    if (this.tabsActive === '2') {
      temp = [
        {
          dataIndex: 'applicationTime',
          title: this.$t('enterprise.ApplicationTime'),
          key: 'applicationTime',
          customRender: record => {
            return (
              <div slot="applicationTime">
                {record && moment(record).format('YYYY-MM-DD')}
              </div>
            );
          },
        },
        {
          key: 'action',
          title: this.$t('common.action.operating'),
          filter: false,
          sorter: false,
          width: 100,
          customRender: record => (
            <span
              onClick={() => {
                this.handleRead(record);
              }}
              class={this.$style.action}
            >
              {this.$t('common.approval')}
            </span>
          ),
        },
      ];
    } else if (this.tabsActive === '1') {
      temp = [
        {
          dataIndex: 'approvalTime',
          title: this.$t('enterprise.CertificationTime'),
          key: 'approvalTime',
          customRender: record => {
            return (
              <div slot="approvalTime">
                {record && moment(record).format('YYYY-MM-DD')}
              </div>
            );
          },
        },
        {
          key: 'action',
          title: this.$t('common.action.operating'),
          filter: false,
          sorter: false,
          width: 100,
          customRender: record => (
            <span
              onClick={ev => ev.stopPropagation()}
              class={this.$style.action}
            >
              <a
                type="link"
                onClick={() => {
                  this.handleRead(record);
                }}
              >
                {this.$t('activity.Check')}
              </a>
              <a
                type="link"
                onClick={() => {
                  this.handleDel(record);
                }}
              >
                {this.$t('common.action.delete')}
              </a>
            </span>
          ),
        },
      ];
    } else {
      temp = [
        {
          dataIndex: 'rejectTime',
          title: this.$t('enterprise.rejectionTime'),
          key: 'rejectTime',
          customRender: record => {
            return (
              <div slot="rejectTime">
                {record && moment(record).format('YYYY-MM-DD')}
              </div>
            );
          },
        },
        {
          key: 'action',
          title: this.$t('common.action.operating'),
          filter: false,
          sorter: false,
          width: 100,
          customRender: record => (
            <span
              onClick={ev => ev.stopPropagation()}
              class={this.$style.action}
            >
              <a
                type="link"
                onClick={() => {
                  this.handleRead(record);
                }}
              >
                {this.$t('activity.Check')}
              </a>
              <a
                type="link"
                onClick={() => {
                  this.handleDel(record);
                }}
              >
                {this.$t('common.action.delete')}
              </a>
            </span>
          ),
        },
      ];
    }
    return [...base, ...temp];
  }
}
</script>

<style lang="less" module>
.headerSearch {
  margin: 15px 0;
  .searchPicker {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      &:nth-child(1) {
        color: var(--font);
      }
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }
}
.action {
  cursor: pointer;
  a {
    color: var(--font);
    margin-right: 15px;
  }
}
</style>
