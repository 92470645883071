<template>
  <div :class="$style['bulk-editing']">
    <div :class="$style.title">{{ $t('record.column') }}</div>
    <a-textarea v-model="textarea"></a-textarea>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';

async function createBulkEditing(props) {
  const value = await createFormModal(h => h(BatchEditModal, { props }), {
    title: globalVueI18n.translate('record.batch'),
    width: 600,
  });
  return value;
}

@Component()
export default class BatchEditModal extends Vue {
  @Prop() data;
  textarea = '';
  mounted() {
    this.textarea = this.data.map(item => item.text).join('\r\n');
  }

  static createModal = createBulkEditing;

  getValue() {
    return this.textarea.split('\n').filter(item => !!item);
  }
}
</script>
<style lang="less" module>
.bulk-editing {
  border: 1px solid var(--form-border);
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;

  .title {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid var(--form-border);
    width: 100%;
    padding-left: 20px;
    color: var(--font-sub);
  }

  :global {
    .ant-input {
      flex: 1;
      overflow-y: auto;
      border: none;
      resize: none;
      line-height: 30px;
      padding: 5px 20px;
      color: var(--font);

      &:focus,
      &:hover,
      &:active {
        border: none;
        box-shadow: none;
      }
    }
  }
}
</style>
