<template>
  <div :class="$style.innerModel">
    <a-table
      :columns="clumns"
      :data-source="tableData"
      :pagination="pagination"
      :scroll="scrollObj"
      :row-key="record => record.pkId"
      @change="handlePagination"
    />
    <div :class="$style.btn">
      <a-button type="primary" @click="close">
        确定
      </a-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { deepClone, download } from '@triascloud/utils';
import { ControlTypeEnum } from '@/views/connector/platform-manage/develop/data-template/template-design/form/controls/enum.js';

@Component
export default class InnerModel extends Vue {
  @Prop({ type: Array, default: () => [] }) modelData;

  @Watch('modelData', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal) {
      this.modelData = newVal;
      this.getList();
    }
  }

  get clumns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => {
          return index + 1;
        },
        title: '序号',
        align: 'center',
        width: 80,
      },
    ];
  }

  scrollObj = {};
  tableData = [];

  getList() {
    const data = deepClone(this.modelData);
    this.pagination.total = data.length;
    const size = this.pagination.pageSize;
    const start = this.pagination.current;
    const end = start * size;
    const arr = data.splice((start - 1) * size, end);
    for (let i = 0; i < arr.length; i++) {
      const o = {};
      const len = arr[i].length;
      for (let j = 0; j < arr[i].length; j++) {
        const type = arr[i][j].dataType.toLowerCase();
        if (type === ControlTypeEnum.Date) {
          let value = '-';
          if (typeof arr[i][j].value === 'number') {
            value = this.$moment(arr[i][j].value).format(
              arr[i][j].unit || 'YYYY-MM-DD HH:mm:ss',
            );
          }
          o[arr[i][j].identifier] = `${value}`;
        } else if (type === ControlTypeEnum.Switch) {
          if (
            arr[i][j].value !== null &&
            arr[i][j].value !== '-' &&
            arr[i][j].value !== ''
          ) {
            const other = JSON.parse(arr[i][j].other);
            o[arr[i][j].identifier] = `${other[arr[i][j].value]}${
              arr[i][j].unit
            }`;
          } else {
            o[arr[i][j].identifier] = '-';
          }
        } else if (type === ControlTypeEnum.Enum) {
          const other = JSON.parse(arr[i][j].other);
          const value = arr[i][j].value + '';
          if (value !== null && value !== '-' && value !== '') {
            const idx = other.findIndex(e => String(e.value) === value);
            if (idx !== -1) {
              o[arr[i][j].identifier] = `${other[idx].key}${arr[i][j].unit}`;
            } else {
              o[arr[i][j].identifier] = '-';
            }
          } else {
            o[arr[i][j].identifier] = '-';
          }
        } else {
          o[arr[i][j].identifier] = `${arr[i][j].value}${arr[i][j].unit}`;
        }
        if (i === 0) {
          let c = {
            title: arr[i][j].name,
            dataIndex: arr[i][j].identifier,
            ellipsis: true,
            width: 0,
          };
          if (arr[i][j].dataType.toLowerCase() === ControlTypeEnum.Image) {
            c['customRender'] = text => (
              <span>
                <x-oss-image
                  class="x-file-icon"
                  size="32"
                  oss-path={text}
                  onClick={() => {
                    this.$preview(text, 'image');
                  }}
                />
              </span>
            );
          } else if (
            arr[i][j].dataType.toLowerCase() === ControlTypeEnum.File
          ) {
            c['customRender'] = text => (
              <span title={text} class={this.$style.fileWrap}>
                <x-icon
                  class={this.$style.icon}
                  onClick={() => {
                    download(text, arr[i][j].name);
                  }}
                  type="tc-icon-file-unknown"
                />
              </span>
            );
          }
          if (arr[i][j].name.length > 4) {
            c.width = arr[i][j].name.length * 30;
          } else {
            c.width = arr[i][j].name.length * 40;
          }
          const idx = this.clumns.findIndex(
            x => x.dataIndex === arr[i][j].identifier,
          );
          if (idx < 0) {
            this.clumns.push(c);
          }
          this.scrollObj['x'] = 1000 * Math.floor(len / 7);
        }
      }
      this.tableData.push(o);
    }
  }

  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };

  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getList();
  }

  close() {
    this.$emit('close');
  }
}
</script>

<style lang="less" module>
.innerModel {
  .btn {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global {
    .ant-table-thead > tr > th .ant-table-header-column {
      padding: 0;
    }
  }
}
.fileWrap {
  display: flex;
  .icon {
    font-size: 32px;
  }
}
</style>
