<template>
  <div :class="$style.command">
    <div :class="$style.card">
      <div>
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          style="margin-bottom: 10px"
          @change="val => onCheckAllChange(val.target.checked)"
        >
          全选（{{ checkAllNumber }}）
        </a-checkbox>
        <div
          v-for="(item, idx) in selectData"
          :key="item.pkId"
          :class="[$style.itemDiv, item.checked ? $style.checked : '']"
          @click.stop="() => changeLeftItem(!item.checked, idx)"
        >
          <div :class="[$style.item]">
            <a-tag :color="colorObj[item.triggerMethod].color">
              {{ colorObj[item.triggerMethod].text }}
            </a-tag>
            <div style="width: 380px;">
              <div :class="$style.textOverflow">{{ item.apiName }}</div>
            </div>
          </div>
          <a-checkbox
            style="margin-left: 10px"
            :checked="item.checked"
            @change.stop="val => changeLeftItem(val.target.checked, idx)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getApiListDevice } from '@/services/iot-platform/dataManage';
import { deepClone } from '@triascloud/utils';

@Component()
export default class CommandSetting extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: String, default: '' }) productionId;
  @Prop({ type: Array, default: () => [] }) defaultSelect;

  mounted() {
    this.getApiList();
  }
  selectData = [];

  async getApiList() {
    try {
      const params = {
        apiTypes: ['BUSINESS'],
        idxProductId: this.productionId,
        platformId: this.platformId,
        status: 'PUBLISHED',
        triggerMethods: ['MANUAL', 'TIMING'],
      };
      const res = await getApiListDevice(params);
      if (res) {
        this.selectData = res.map(v => {
          return {
            ...v,
            checked: false,
          };
        });
        if (this.defaultSelect.length > 0) {
          const arr = deepClone(this.defaultSelect);
          arr.forEach(v => {
            const idx = this.selectData.findIndex(s => s.pkId === v.pkId);
            if (idx > -1) {
              this.selectData[idx].checked = true;
            }
          });
          this.checkAllStatus();
        }
      }
    } catch {
      return false;
    }
  }

  indeterminate = false;
  checkAll = false;

  get checkAllNumber() {
    const checked = this.selectData.filter(v => v.checked).length;
    const len = this.selectData.length;
    return `${checked}/${len}`;
  }

  colorObj = {
    MANUAL: {
      text: '手动触发',
      color: '#30cfca',
    },
    TIMING: {
      text: '定时触发',
      color: '#2db7f5',
    },
  };

  onCheckAllChange(val) {
    this.selectData.forEach(v => (v.checked = val));
    this.immediate = false;
    this.checkAll = val;
  }

  checkAllStatus() {
    const arr = [];
    this.selectData.forEach(v => {
      if (v.checked) {
        arr.push(1);
      }
    });
    this.indeterminate = !!arr.length && arr.length < this.selectData.length;
    this.checkAll = arr.length === this.selectData.length;
  }

  changeLeftItem(val, idx) {
    this.selectData[idx].checked = val;
    this.checkAllStatus();
  }

  getValue() {
    return this.selectData;
  }
}
</script>

<style lang="less" module>
.command {
  .card {
    border: 1px solid var(--border);
    border-radius: 5px;
    height: 300px;
    overflow-y: auto;
    padding: 10px;
  }

  .textOverflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .itemDiv {
    display: flex;
    border: 1px solid var(--border);
    height: 40px;
    align-items: center;
    border-radius: 4px;
    padding: 0 5px;
    margin-bottom: 10px;
    cursor: pointer;

    .item {
      width: 450px;
      display: flex;
      align-items: center;
    }

    &:hover {
      border: 1px solid var(--primary-40);
    }
  }

  .checked {
    border: 1px solid var(--primary);

    &:hover {
      border: 1px solid var(--primary);
    }
  }

  .activeTab {
    border: 1px solid var(--primary-60);
  }
}
</style>
