// @ts-check
import DataAddCutTool from './DataAddCutTool';
import LoadLastDataTool from './LoadLastDataTool';

export * from './BaseTool';

export const DataFormToolMap = {
  [DataAddCutTool.type]: DataAddCutTool,
  [LoadLastDataTool.type]: LoadLastDataTool,
};

/** @typedef { keyof DataFormToolMap } DataFormToolType */

/**
 * @typedef DataFormToolConfig
 * @property { DataFormToolType } type
 * @property { string } formId
 */

/**
 *
 * @param { string } formId
 * @param { DataFormToolConfig[] } configs
 * @returns { Promise<import('./BaseTool').DataFormTool<any>[]> }
 */
export async function generateTools(formId, configs) {
  const tools = configs
    .filter(config => !!DataFormToolMap[config.type])
    // @ts-ignore
    .map(config => new DataFormToolMap[config.type]({ ...config, formId }));
  await Promise.all(
    tools.map(
      tool =>
        tool.initTool && tool.initTool().catch(() => (tool.initError = true)),
    ),
  );
  return tools.filter(tool => tool.initError === false);
}
