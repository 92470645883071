<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <p :class="$style.title">确定给以下设备激活语音识别包？</p>
    <p :class="$style.desc">激活后将扣减讯飞语音包安装额度，且无法取消！</p>
    <a-form-model-item
      :label="$t('hat.deviceManagement.form.selectDevice')"
      prop="devices"
    >
      <a-select
        mode="multiple"
        option-filter-prop="children"
        :filter-option="false"
        v-model="form.devices"
        :class="$style.selectList"
      >
        <a-select-option v-for="item in devices" :key="item.id">
          {{
            item.name
              ? `${item.deviceCode}(${item.name})`
              : `${item.deviceCode}`
          }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <p :class="$style.notes">注意：已过滤非plus(升级版）且不在线的设备</p>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component()
export default class VoiceRecognition extends Vue {
  @Prop() devices;

  mounted() {
    if (this.devices && this.devices.length > 0) {
      this.form.devices = this.devices.map(v => v.id);
    }
  }
  form = {
    devices: [],
  };
  rules = {
    devices: [
      {
        required: false,
        message: this.$t('hat.deviceManagement.form.requiredDevice'),
      },
    ],
  };
  async getValue() {
    await this.$refs.form.validate();
    return this.form.devices;
  }
}
</script>
<style lang="less" module>
.selectList {
  :global {
    .ant-select-selection--multiple {
      height: 140px;
      overflow-y: auto;
    }
  }
}
.title {
  font-size: 16px;
  color: var(--font);
}
.desc {
  font-size: 14px;
  color: var(--delete);
  padding-bottom: 6px;
}
.notes {
  font-size: 12px;
  color: var(--font-info);
}
</style>
