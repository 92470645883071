<template>
  <div :class="$style.detail">
    <div :class="$style.header">
      <div :class="$style.search">
        <a-input-search
          v-model="keyword"
          @search="refreshScrollList"
        ></a-input-search>
        <span :class="$style.count">
          {{ $t('dictionary.total') }}<i :class="$style.primary">{{ total }}</i>
          {{ $t('iconLibrary.icon.size') }}
        </span>
        <div :class="$style.company" @click="companySetting">
          {{ $t('iconLibrary.library.company') }}：{{
            library.accessTenant && library.accessTenant.length
              ? $t('iconLibrary.library.selectedCompany', {
                  value: library.accessTenant.length,
                })
              : $t('common.label.all')
          }}<x-icon type="swap" />
        </div>
      </div>
      <a-button
        v-if="$p.action('ADD_ICON')"
        :class="$style.button"
        icon="plus-circle"
        type="primary"
        ghost
        @click="handleAddIcon"
        >{{ $t('iconLibrary.icon.add') }}</a-button
      >
    </div>
    <div :class="$style.body">
      <a-spin v-if="spinning" />
      <x-scroll-list
        v-model="iconList"
        :service="initData"
        :class="$style['scroll-view']"
        :autoFill="true"
        ref="scrollList"
      >
        <IconItem
          slot-scope="{ data }"
          :data="data"
          :library="library"
          @change="refreshScrollList"
          @refreshAll="refreshAllList"
        />
      </x-scroll-list>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  addIcon,
  getDetail,
  removeIcon,
} from '@/services/resource/icon-library';
import { createDrawer } from '@triascloud/x-components';
import IconItem from './icon-item';
import IconSelectList from '../icon-select-list/index';
import LibraryCompanySetting from './library-company-setting.vue';

function createDetailDrawer(props, listener, title) {
  return createDrawer(
    h =>
      h(Detail, {
        props,
        on: {
          refreshLibraryList:
            listener && listener.refreshLibraryList
              ? listener.refreshLibraryList
              : () => null,
        },
      }),
    {
      title,
      width: 880,
      className: 'x-icon-manage-detail-drawer',
    },
  );
}

@Component({
  components: {
    IconItem,
  },
})
export default class Detail extends Vue {
  @Prop({ type: Object, default: () => ({}) }) library;

  static createDetailDrawer = createDetailDrawer;

  keyword = '';
  total = null;

  iconList = [];
  spinning = false;

  async initData(page) {
    this.spinning = true;

    const params = {
      iconQuery: {
        current: page,
        size: 56,
      },
      keyword: this.keyword,
      libraryId: this.library.pkId,
      updateIconName: false,
    };
    const detail = await getDetail(params);
    this.total = detail.total;
    this.spinning = false;

    return detail.records;
  }

  refreshScrollList() {
    this.$refs.scrollList.refresh();
  }

  refreshAllList() {
    this.refreshScrollList();
    this.$emit('refreshLibraryList');
  }

  async handleAddIcon() {
    const { addList, removeList } = await IconSelectList.createIconModal(
      {
        libraryId: this.library.pkId,
      },
      { title: this.$t('iconLibrary.icon.public') },
    );
    if (addList.length > 0 || removeList.length > 0) {
      if (addList.length > 0) await addIcon(addList, this.library.pkId);
      if (removeList.length > 0)
        await removeIcon(removeList, this.library.pkId);
      await this.refreshScrollList();
      this.$emit('refreshLibraryList');
    }
  }
  async companySetting() {
    const model = await LibraryCompanySetting.create({
      pkId: this.library.pkId,
      accessTenant: this.library.accessTenant,
    });
    this.library.accessTenant = model.accessTenantSet;
  }
}
</script>
<style lang="less" module>
:global {
  .x-icon-manage-detail-drawer {
    .ant-drawer-body {
      padding: 0;
    }
  }
}
.detail {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    flex-shrink: 0;
    width: 100%;
    padding: 0 30px;
    border-bottom: 1px solid var(--border);
    .search {
      display: flex;
      align-items: center;
      :global(.ant-input-search) {
        width: 110px;
        margin-right: 30px;
      }
      .count {
        font-size: 12px;
        color: var(--font-sub);

        .primary {
          color: var(--primary);
          font-size: 14px;
          font-style: normal;
        }
      }
      .company {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-radius: 4px;
        height: 32px;
        background-color: var(--primary-10);
        color: var(--primary);
        margin-left: 10px;
      }
    }
    .button {
      height: 32px;
    }
  }
  .body {
    flex: 1;
    width: 100%;
    .scroll-view {
      padding: 20px 20px;
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, 100px);
      gap: 25px 10px;
      justify-content: space-between;
      max-height: calc(100vh - 94px);

      :global {
        .x-scroll-list-footer {
          display: none;
        }
      }
    }
  }
}
</style>
