import baseRequest from '@triascloud/request';
import { ssoClient } from '@triascloud/sso-client';
import message from '@/components/closeable-message';
import { createModuleUrl } from '@/utils';
import { ErrorCode } from '@/enum/code';
let customHeaders = {};
/**
 * @name 批量设置header
 * @param { Record<string, string> } headers
 */
export const setHeaders = headers => {
  customHeaders = {
    ...customHeaders,
    ...headers,
  };
};
/** @type { typeof baseRequest } */
export default async function request(relativeUrl, options = {}) {
  const absoluteUrl = relativeUrl.startsWith('/')
    ? createModuleUrl('api', 'https:') + relativeUrl
    : relativeUrl;
  const headers = { ...customHeaders };
  if (options.auth !== false) {
    headers['X-Access-Token'] =
      ssoClient.getTokenSync() || (await ssoClient.getToken());
  }
  return baseRequest(absoluteUrl, {
    ...options,
    headers: {
      ...headers,
      ...options.headers,
    },
  }).catch(async error => {
    if (error.code === ErrorCode.NOT_LOGIN) {
      await ssoClient.logout(true);
      return request(relativeUrl, options);
    }
    if (
      options.displayError !== false &&
      !(error instanceof DOMException && error.code === DOMException.ABORT_ERR) // 取消请求
    ) {
      const errorMessage =
        error && (error.message || error.errmsg || '未知错误');
      message.error(errorMessage);
    }
    throw error;
  });
}
