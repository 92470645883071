<template>
  <div class="box">
    <a-form-model :model="form" :rules="rules" ref="form">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-model-item :label="`设备码`" prop="deviceName">
            <a-input disabled :value="form.deviceName" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item :label="`验证码`" prop="deviceSecretIntercept">
            <a-input disabled :value="form.deviceSecretIntercept" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item :label="`出厂日期`" prop="factoryTime">
            <a-input
              disabled
              :value="$moment(form.factoryTime).format('YYYY-MM-DD HH:mm:ss')"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item :label="`ICCID`" prop="iccId">
            <a-input v-model="form.iccId" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item :label="`所属企业`" prop="tenantId">
            <compony-select
              v-model="form.tenantId"
              :disabled="form.disabled"
              :initialValue="{
                value: record.tenantId,
                label: record.tenantName,
              }"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item :label="`安全帽颜色`" prop="color">
            <a-select v-model="form.color">
              <a-select-option
                v-for="(item, index) in colors"
                :key="index"
                :value="item"
              >
                {{
                  {
                    YELLOW: '黄',
                    BLUE: '蓝',
                    RED: '红',
                    WHITE: '白',
                    ORANGE: '橙',
                  }[item]
                }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item :label="`设备信息`" prop="detailUrl">
            <a-input v-model="form.detailUrl" />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { reqChangeDevice } from '@/services/hat';
import ComponySelect from '@/views/digital-site/components/compony-select.vue';

@Component({ components: { ComponySelect } })
export default class Edit extends Vue {
  @Prop({ type: Object, default: () => {} }) record;
  expand = false;
  form = {
    deviceName: '',
    deviceSecretIntercept: '',
    expireDate: '',
    iccId: 0,
    tenantName: '',
    color: '白',
    detailUrl: '',
  };
  colors = ['WHITE', 'RED', 'YELLOW', 'ORANGE', 'BLUE'];
  rules = {
    deviceName: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    deviceSecretIntercept: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    factoryTime: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    iccId: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    tenantName: [
      {
        validator: this.validateGroup,
        trigger: 'submit',
      },
    ],
    detailUrl: [
      {
        validator: this.validateGroup1,
        trigger: 'submit',
      },
    ],
  };
  validateGroup(rule, value, callback) {
    if (value.length === 0) {
      callback();
      // callback(new Error('请选择编组！')); //TODO 对接下拉框接口后取消注释
    } else {
      callback();
    }
  }
  validateGroup1(rule, value, callback) {
    if (this.form.detailUrl) {
      let t = /^http:\/\/|https:\/\//g.test(this.form.detailUrl);
      !t && callback(new Error(`设备信息必须是以http://或者https://开头`));
    }
    callback();
  }
  async mounted() {
    //处理已分配企业和未分配企业
    if (this.record.tenantId) {
      this.form = { ...this.record, disabled: true };
    } else {
      var obj = { ...this.record };
      delete obj.tenantId;
      this.form = { ...obj, disabled: false };
    }
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.changeDecvice();
            resolve({ name: '张三' });
          } catch (error) {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
  async changeDecvice() {
    const { color, detailUrl, deviceId, iccId } = this.form;
    const idxTenantId = this.form.disabled
      ? this.record.tenantId
      : this.form.tenantId;
    const body = {
      color,
      detailUrl,
      deviceId,
      iccId,
      idxTenantId,
    };
    reqChangeDevice(body)
      .then(() => {
        this.$message.success('编辑设备成功!');
      })
      .catch(() => {});
  }
}
</script>
<style lang="less"></style>
