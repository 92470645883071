<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item label="设备码" prop="deviceCode">
      <a-input
        v-model="form.deviceCode"
        :disabled="editing"
        placeholder="请输入设备码"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item label="设备ID" prop="deviceID">
      <a-input v-model="form.deviceID" placeholder="请输入设备ID"></a-input>
    </a-form-model-item>
    <a-form-model-item label="原始密钥" prop="deviceKey">
      <a-input v-model="form.deviceKey" placeholder="请输入原始密钥"></a-input>
    </a-form-model-item>
    <a-form-model-item prop="logoUrl">
      <span slot="label">
        设备头图
        <a-tooltip
          title="若为空则统一使用当前设备所属产品的第一张头图"
          placement="right"
          :overlayStyle="{ maxWidth: '100%' }"
        >
          <a-icon type="info-circle" />
        </a-tooltip>
      </span>
      <images-upload v-model="form.image" style="height: 120px" />
    </a-form-model-item>
    <a-form-model-item label="设备有效期" prop="expireType" v-if="showExpire">
      <a-radio-group
        v-model="form.expireType"
        default-value="long"
        @change="changeExpireType"
      >
        <a-radio value="long">
          长期
        </a-radio>
        <a-radio value="custom">
          自定义
        </a-radio>
      </a-radio-group>
      <a-form-model-item
        v-if="form.expireType === 'custom'"
        prop="expireTime"
        style="display: inline-block;"
      >
        <a-date-picker
          v-model="form.expireTime"
          :disabled-date="disabledDate"
          placeholder="YYYY/MM/DD"
          format="YYYY/MM/DD"
        />
      </a-form-model-item>
    </a-form-model-item>
    <a-form-model-item label="设备类型" prop="deviceType">
      <a-select
        placeholder="请选择设备类型"
        v-model="form.deviceType"
        :filter-option="false"
      >
        <a-select-option value="DIRECT_CONNECTION">直连设备</a-select-option>
        <a-select-option value="GATEWAY">网关设备</a-select-option>
        <a-select-option value="SUB_GATEWAY">网关子设备</a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      v-if="form.deviceType === 'SUB_GATEWAY'"
      label="所属网关"
      prop="gateway"
    >
      <a-select
        placeholder="请选择网关"
        v-model="form.gateway"
        :filter-option="false"
      >
        <a-select-option
          v-for="item in gatewayList"
          :key="item.pkId"
          :value="item.deviceCode"
          >{{ item.deviceCode }}</a-select-option
        >
        <!-- <a-select-option value="192.168.1.1">192.168.1.1</a-select-option> -->
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="备注说明" prop="remark">
      <a-input v-model="form.remark" placeholder="请输入备注说明"></a-input>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { gatewayByProduct } from '@/services/iot-platform/platformDevice';
import dayjs from 'moment';
import { UploadService } from '@triascloud/services';
import ImagesUpload from '../../../components/images-upload.vue';

@Component({
  components: {
    ImagesUpload,
  },
})
export default class ProductSetting extends Vue {
  @Prop({ type: String }) productId;
  @Prop({ type: Object }) detail;

  disabledDate(current) {
    const cur = current && dayjs(current).add(1, 'day');
    return cur < dayjs().endOf('day');
  }

  changeExpireType(event) {
    if (event.target.value === 'long') {
      this.form.expireTime = '';
    }
  }

  get showExpire() {
    return this.detail && this.detail.deviceStatus !== 'NOT_ALLOCATED';
  }

  get editing() {
    return !!(this.detail && this.detail.deviceStatus);
  }

  @Watch('detail', { immediate: true })
  async onDetailChange(val) {
    if (val && val.deviceCode) {
      this.form.deviceCode = val.deviceCode;
      this.form.deviceID = val.deviceId;
      this.form.deviceType = val.deviceType;
      this.form.deviceKey = val.rawKey;
      this.form.gateway = val.idxDeviceCode;
      this.form.remark = val.remark;
      this.form.expireTime = val.expireTime
        ? dayjs(val.expireTime).format('YYYY/MM/DD')
        : '';
      if (this.form.expireTime) {
        this.form.expireType = 'custom';
      }

      const basePath = '/oss/oss';
      const customService = new UploadService(basePath);
      if (val.headFigure) {
        const abPath = await customService.getAuth(val.headFigure);

        this.form.image = [
          {
            uid: 1,
            name: 'picture',
            status: 'done',
            url: abPath,
            ossPath: val.headFigure,
          },
        ];
      }
    }
  }

  form = {
    deviceCode: '',
    deviceID: '',
    deviceType: undefined,
    deviceKey: '',
    gateway: undefined,
    remark: '',
    expireTime: '',
    expireType: 'long',
    image: [],
  };

  rules = {
    deviceCode: [
      {
        required: true,
        message: '请输入设备码',
      },
    ],
    deviceType: [
      {
        required: true,
        message: '请选择设备类型',
      },
    ],
    gateway: [
      {
        required: true,
        message: '请选择网关',
      },
    ],
  };

  gatewayList = [];
  async mounted() {
    this.gatewayList = await gatewayByProduct(this.productId);
  }

  async getValue() {
    await this.$refs.form.validate();
    return this.form;
  }
}
</script>
<style lang="less" module></style>
