/** @typedef { import('./BaseControl').Field } Field */
/** @typedef { string | Field[] | import('./utils').FieldMapOrGetFieldFn } FieldsRef */

import { getControlMeta } from './utils';

export function generateWidgetRulesFromField(field, getField) {
  const widgetRules = getControlMeta(field.type, 'widgetRules');
  if (!widgetRules) return null;
  return Object.values(widgetRules(field)).map(fn => ({
    asyncValidator: () => Promise.resolve(fn(getField)),
  }));
}
