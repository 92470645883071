import request from '../request';
const serviceName = '/iot-platform/apiLog';

/** @description 添加日志 */
export function addLog(data) {
  return request(`${serviceName}/add`, {
    method: 'POST',
    body: data,
  });
}
