<template>
  <div>
    <div :class="$style.title">{{ $t('website.setting.guideModule') }}</div>
    <div :class="$style.transfer">
      <div :class="$style.unCheckData">
        <div :class="$style.checkTlt">
          <span>{{ $t('website.setting.unselectedCompany') }}</span>
          <span>{{ checkedList.length }}/{{ companyList.length }}</span>
        </div>
        <div :class="$style.checkContent">
          <div :class="$style.search">
            <a-input v-model="keyword" @change="refreshList">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>

          <x-scroll-list
            v-model="companyList"
            :service="initData"
            ref="scrollList"
            :class="$style.list"
          >
            <template slot-scope="{ data, $index }">
              <label :class="$style.DataRow">
                <a-checkbox
                  @click="
                    checkedList.some(item => item.pkId === data.pkId)
                      ? checkedList.splice($index, 1)
                      : checkedList.push(data)
                  "
                  :checked="checkedList.some(item => item.pkId === data.pkId)"
                >
                </a-checkbox>
                <x-ellipsis>{{ data.enterpriseName }}</x-ellipsis>
              </label>
            </template>
          </x-scroll-list>
        </div>
      </div>
      <div :class="$style.CheckData">
        <div :class="$style.checkTlt">
          <span>{{ $t('website.setting.selectedCompany') }}</span>
          <span @click="checkedList = []">{{
            $t('website.setting.removeAll')
          }}</span>
        </div>
        <div :class="$style.checkContent">
          <div :class="$style.list">
            <div
              :class="$style.DataRow"
              v-for="(item, index) in checkedList"
              :key="item.pkId"
            >
              <x-ellipsis style="flex:1">{{ item.enterpriseName }}</x-ellipsis>
              <x-icon
                type="tc-icon-delete"
                @click="checkedList.splice(index, 1)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import Draggable from 'vuedraggable';
import { globalVueI18n } from '@triascloud/i18n';

import {
  shieldTenant,
  companyListService,
} from '@/services/configuration/operating';
// import { globalModule } from '@/enum/store';

function createGuideSettingModal(props, listener) {
  return createFormModal(h => h(GuideSetting, { props, on: listener }), {
    title: globalVueI18n.translate('website.setting.guidanceSettings'),
    width: 484,
    bodyStyle: { padding: '10px 20px 25px 20px' },
  });
}
@Component({
  components: { Draggable },
})
export default class GuideSetting extends Vue {
  static createModal = createGuideSettingModal;
  //   @globalModule.State companyList;

  companyList = [];
  indeterminate = false;
  keyword = '';
  loading = false;
  checkedList = [];
  checkAll = false;
  async created() {
    this.checkedList = await shieldTenant().then(res => {
      res = res.map(item => {
        return {
          pkId: item.tenantId,
          enterpriseName: item.tenantName,
        };
      });
      return res;
    });
  }
  async initData(page) {
    const detail = await companyListService(page, 10, this.keyword);
    return detail.records;
  }

  refreshList() {
    this.$refs.scrollList.refresh();
  }
  getValue() {
    return this.checkedList;
  }
}
</script>
<style lang="less" module>
.title {
  font-size: 14px;
  color: var(--font-info);
  margin-bottom: 10px;
}
.transfer {
  border: 1px solid var(--border);
  width: 100%;
  height: 350px;
  display: flex;
  position: relative;
  flex: 1;
  &::before {
    content: '';
    height: 350px;
    width: 1px;
    background: var(--border);
    position: absolute;
    left: 50%;
    top: 0;
  }
}
.unCheckData {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.CheckData {
  width: 50%;
}
.checkTlt {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  border-bottom: 1px solid var(--border);
  height: 40px;
  align-items: center;
  span {
    &:last-child {
      cursor: pointer;
    }
  }
}
.checkContent {
  height: calc(100% - 40px);
  overflow-y: auto;
  padding-top: 10px;
}
.search {
  margin: 0 10px;
}
.DataRow {
  display: flex;
  width: 100%;
  padding: 8px 10px;
  border-radius: 4px 4px 0px 0px;
  label {
    margin-right: 10px;
  }
}
.list {
  flex: 1;
  height: calc(100% - 40px);
  overflow-y: auto;
}
</style>
