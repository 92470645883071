<template>
  <a-tabs :class="$style.tab">
    <a-tab-pane
      v-for="item in classify"
      :key="item.pkId"
      :tab="item.name"
      :class="$style.content"
    >
      <x-tree
        :tree-data="item.childList"
        :replace-fields="{ key: 'pkId', title: 'name', children: 'childList' }"
        :load-data="loadData"
        @select="handleSelect"
      >
        <div slot="title" slot-scope="{ data, node }" :class="$style.cell">
          <x-ellipsis :class="$style.cell__title">{{ data.name }}</x-ellipsis>
          <a-radio
            v-if="node.isLeaf"
            :checked="selectedTemplate && data.pkId === selectedTemplate.pkId"
          />
        </div>
      </x-tree>
      <empty-content v-if="!item.childList.length" />
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';
import { Component, Vue } from 'vue-property-decorator';
import { getClassifyTree } from '@/services/template-manager/tpClassify';
import { getTemplateList } from '@/services/template-manager/template';
import EmptyContent from '@/components/empty-content';
function createSelectTemplateModal(props, modalProps = {}) {
  return createFormModal(h => h(SelectTemplateModal, { props }), {
    title: globalVueI18n.translate('market.template.addRecommend'),
    width: 442,
    bodyStyle: { padding: 0 },
    ...modalProps,
  });
}
@Component({
  components: { EmptyContent },
})
export default class SelectTemplateModal extends Vue {
  static createModal = createSelectTemplateModal;
  selectedTemplate = null;
  created() {
    this.getClassify();
  }
  classify = [];
  async getClassify() {
    this.classify = await getClassifyTree();
  }
  getValue() {
    if (!this.selectedTemplate) {
      this.$message.warning(this.$t('market.template.selectedTip'));
      return Promise.reject(new Error('empty'));
    } else {
      return Promise.resolve(this.selectedTemplate);
    }
  }
  async loadData(node) {
    const templates = await getTemplateList({ classifyId: node.dataRef.pkId });
    node.dataRef.childList = templates
      .map(item => ({
        pkId: item.id,
        name: item.name,
        isLeaf: true,
      }))
      .sort((a, b) =>
        a.sort === b.sort ? b.checkTime - a.checkTime : a.sort - b.sort,
      );
  }
  handleSelect(_, { node }) {
    if (node.isLeaf) {
      this.selectedTemplate = {
        name: node.dataRef.name,
        pkId: node.dataRef.pkId,
      };
    }
  }
}
</script>
<style lang="less" module>
.tab {
  :global .ant-tabs-bar {
    margin-bottom: 10px;
  }
}
.content {
  height: 300px;
  overflow-y: auto;
  padding: 0 10px;
}
.cell {
  display: flex;
  justify-content: space-between;
  .cell__title {
    flex: 1;
    overflow: hidden;
  }
}
</style>
