import { message } from 'ant-design-vue';
import CloseableMessage from './closeable-message';
const closeableMessage = {
  ...message,
  open: ({ content, ...options }) => {
    const close = message.open({
      ...options,
      content: h => h(CloseableMessage, { on: { close }, props: { content } }),
    });
    return close;
  },
};

['success', 'info', 'warning', 'error'].forEach(type => {
  closeableMessage[type] = (content, duration, onClose) => {
    if (typeof content === 'string') {
      if (typeof duration === 'function') {
        onClose = duration;
        duration = undefined;
      }
      return closeableMessage.open({
        content,
        duration,
        type,
        onClose,
      });
    } else {
      return closeableMessage.open({
        ...content,
        type,
      });
    }
  };
});

export default closeableMessage;
