<template>
  <a-form-model layout="vertical" :model="form" ref="form" :rules="rules">
    <a-form-model-item
      :label="$t('connector.dataTemplate.uploadJson')"
      prop="files"
    >
      <a-upload
        name="file"
        :file-list="form.files"
        :customRequest="handleUploadChange"
        :remove="handleRemove"
        accept=".json"
      >
        <a-button v-if="form.files.length < 1">
          <a-icon type="upload" />
          {{ $t('connector.dataType.uploadFile') }}
        </a-button>
      </a-upload>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { importTemplate } from '@/services/iot-platform/dataTemplate';
@Component({})
export default class uploadPath extends Vue {
  @Prop() platFormId;

  form = {
    files: [],
  };
  rules = {
    files: [
      {
        required: true,
        message: this.$t('connector.dataTemplate.selectUploadFile'),
      },
    ],
  };
  handleRemove() {
    this.form.files = [];
  }
  async handleUploadChange(obj) {
    obj.file.status = 'done';
    this.form.files.push(obj.file);
    const formData = new FormData();
    formData.append('file', obj.file);
    formData.append('platformId', this.platFormId);
    try {
      const res = await importTemplate(formData);
      if (res) {
        const str = this.$t('connector.dataTemplate.uploadSuccess');
        this.$message.success(str);
      }
    } catch {
      this.handleRemove();
      return false;
    }
  }
  async getValue() {
    await this.$refs.form.validate();
    return true;
  }
}
</script>

<style scoped></style>
