/** @name 订单状态 */
export const OrderStatus = {
  /** @name 未支付 */
  UNPAID: 0,
  /** @name 已完成 */
  COMPLETED: 1,
  /** @name 已失效 */
  EXPIRED: -1,
  /** @name 已关闭 */
  CLOSED: -2,
  /** @name 申请退款 */
  REFUNDING: -3,
  /** @name 已退款 */
  REFUNDED: -4,
};

export const OrderStatusText = {
  [OrderStatus.UNPAID]: '未支付',
  [OrderStatus.COMPLETED]: '已完成',
  [OrderStatus.EXPIRED]: '已失效',
  [OrderStatus.CLOSED]: '已关闭',
};
