// @ts-check
import { times } from 'lodash';
import { uuid } from '@triascloud/utils';
import { globalVueI18n } from '@triascloud/i18n';
import { BaseControl } from '../BaseControl';
// @ts-ignore
import Tab from './tab.vue';
// @ts-ignore
import DesignComponent from './design.vue';
// @ts-ignore
import ConfigComponent from './config.vue';
import { createField } from '../utils';
import { ControlTypeEnum } from '../enum';

/** @name 功能分页卡 */
class TabsControl extends BaseControl {
  /** @readonly */
  type = 'tabs';

  label = 'controls.tabs.label';

  icon = 'file';

  actionOpen = true;

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    tabs: times(3, index => ({
      id: uuid(),
      title: `${globalVueI18n.translate('controls.label.sort')}${index + 1}`,
      identifier: `type${index + 1}`,
      fields: [
        // @ts-ignore
        createField(ControlTypeEnum.FnName),
        // @ts-ignore
        createField(ControlTypeEnum.FnIdentifier),
      ],
    })),
    count: 3,
    current: '', // 当前选中的【分类项】
    hideTitle: true,
  });

  /**
   * @name 字段特征定义
   * @type { object | ((field: import('../BaseControl').Field) => object) }
   * @returns  { object }
   */
  feature = {
    PRINTABLE: true,
  };

  LayoutComponent = Tab;

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;
}

export default new TabsControl();
