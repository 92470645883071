<template>
  <div :class="$style.defaultIcon">
    <a-tabs
      default-active-key="APPLICATION"
      tab-position="left"
      @change="handleChangeTabs"
    >
      <a-tab-pane v-for="item in tabsList" :key="item.value" :tab="item.name">
        <div :class="$style.header">
          <span :class="$style.title">
            {{ item.name }}{{ $t('iconLibrary.library.default.name') }}
          </span>
        </div>
        <div :class="$style.body">
          <a-spin v-if="spinning" />
          <Draggable
            v-else
            :value="iconMap[item.value]"
            :class="$style.dragList"
            draggable=".item"
            handle=".draggle-handle"
            @end="handleDraggableList"
          >
            <drag-item
              v-for="icon in iconMap[item.value]"
              :data="icon"
              :libraryId="activeType"
              :list="iconMap[item.value]"
              :key="icon.pkId"
              class="item"
              @change="refreshIconList"
            >
              <x-icon type="drag" class="draggle-handle" slot="drag" />
            </drag-item>
          </Draggable>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { DefaultIconTypeEnum } from '@/enum/icon';
import { createDrawer } from '@triascloud/x-components';
import { Component, Vue } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { getDefaultIcon, sortIcon } from '@/services/resource/icon-library';
import { has } from 'lodash';
import { deepClone } from '@triascloud/utils';
import DragItem from './drag-item';
import { globalVueI18n } from '@triascloud/i18n';

function createDefaultIconDrawer() {
  return createDrawer(h => h(DefaultIcon), {
    title: globalVueI18n.translate('iconLibrary.library.default.name'),
    width: 930,
    className: 'x-default-icon-drawer',
  });
}
@Component({
  components: { Draggable, DragItem },
})
export default class DefaultIcon extends Vue {
  static createDefaultIconDrawer = createDefaultIconDrawer;
  activeType = DefaultIconTypeEnum.APPLICATION;

  get tabsList() {
    return [
      {
        name: this.$t('iconLibrary.library.default.application'),
        value: DefaultIconTypeEnum.APPLICATION,
      },
      {
        name: this.$t('iconLibrary.library.default.group'),
        value: DefaultIconTypeEnum.GROUP,
      },
      {
        name: this.$t('iconLibrary.library.default.form'),
        value: DefaultIconTypeEnum.FORM,
      },
      {
        name: this.$t('iconLibrary.library.default.knowledge'),
        value: DefaultIconTypeEnum.KNOWLEDGE,
      },
      {
        name: '默认分组图标',
        value: DefaultIconTypeEnum.APPLICATION_DEFAULT_GROUP,
      },
    ];
  }

  iconMap = {};

  spinning = false;

  async initIcon(refresh) {
    if (!refresh) {
      if (has(this.iconMap, this.activeType)) return;
    }
    this.spinning = true;
    this.iconMap[this.activeType] = await getDefaultIcon(this.activeType);
    this.spinning = false;
  }

  refreshIconList() {
    this.initIcon(true);
  }

  handleChangeTabs(key) {
    this.activeType = key;
    this.initIcon(false);
  }

  created() {
    this.initIcon();
  }

  async handleDraggableList({ oldIndex, newIndex }) {
    const result = this.swapArray(
      this.iconMap[this.activeType],
      oldIndex,
      newIndex,
    );
    await sortIcon(result, this.activeType);
    this.refreshIconList();
  }

  swapArray(arr, from, to) {
    const result = deepClone(arr);
    if (to > from) {
      result.splice(to + 1, 0, arr[from]);
      result.splice(from, 1);
    } else {
      result.splice(to, 0, arr[from]);
      result.splice(from + 1, 1);
    }
    return result;
  }
}
</script>
<style lang="less" module>
:global {
  .x-default-icon-drawer {
    .ant-drawer-body {
      padding: 0;
    }
  }
}
.defaultIcon {
  height: 100%;
  display: flex;
  align-items: stretch;

  :global {
    .ant-tabs {
      flex: 1;

      .ant-tabs-bar {
        width: 170px;

        .ant-tabs-tab {
          height: 56px;
          margin: 0;
          padding: 0 16px;
          display: flex;
          justify-items: flex-start;
          align-items: center;
          &.ant-tabs-tab-active {
            background: var(--body-bg);
          }
        }

        .ant-tabs-ink-bar {
          height: 56px !important;
          left: 0;
        }
      }

      .ant-tabs-left-content {
        padding: 0;
      }
    }
  }

  .header {
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid var(--border);
    .title {
      font-size: 14px;
      color: var(--font);
    }
    .button {
      button + button {
        margin-left: 20px;
      }
    }
  }

  .body {
    padding: 30px;
    .dragList {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
</style>
