<template>
  <a-form-model
    ref="form"
    :model="formData"
    :rules="readonly ? null : rules"
    :class="$style.form"
  >
    <div :class="$style.formData">
      <a-form-model-item
        prop="appName"
        :label="$t('market.statistics.templateName')"
      >
        <a-input v-model="formData.appName" :max-length="30">
          <template v-slot:suffix>{{ formData.appName.length }}/30</template>
        </a-input>
      </a-form-model-item>
      <a-form-model-item
        prop="instruction"
        :label="name + $t('market.template.instruction')"
      >
        <a-input v-model="formData.instruction" :max-length="30">
          <template v-slot:suffix
            >{{ formData.instruction.length }}/30</template
          >
        </a-input>
      </a-form-model-item>
      <!-- <a-form-model-item
      prop="mobileScreenshotList"
      :label="'上传封面（图片尺寸比例1：1.4）'"
    >
      <x-upload
        v-model="formData.mobileScreenshotList"
        :max="6"
        :max-file-size="5"
      ></x-upload>
    </a-form-model-item> -->
      <!-- <a-form-model-item
        prop="price"
        :label="$t('market.template.priceSetting')"
        :class="$style.price"
      >
        <a-radio-group v-model="isFree">
          <a-radio :value="true">{{ $t('market.template.free') }}</a-radio>
          <a-radio :value="false">
            {{ $t('market.template.price') }}
            <a-input-number
              v-show="!isFree"
              v-model="price"
              :min="0"
              :precision="2"
              type="number"
            />
          </a-radio>
        </a-radio-group>
      </a-form-model-item> -->
      <a-form-model-item
        prop="price"
        :label="$t('market.template.priceSetting')"
        :class="$style.price"
      >
        <a-radio-group v-model="isFree">
          <a-radio :value="true">{{ $t('market.template.free') }}</a-radio>
          <a-radio :value="false">
            {{ $t('market.template.price') }}
            <a-input-number
              v-show="!isFree"
              v-model="price"
              :min="0"
              :precision="2"
              type="number"
            />
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        prop="mobileScreenshotList"
        :label="$t('market.template.coverSize')"
      >
        <input :class="$style.solutionInput" />

        <x-upload-button
          :maxFileSize="5"
          uploadPath="common/temp/"
          @success="
            res => {
              this.formData.pcScreenshots = [res.ossData.ossPath];
            }
          "
        >
          <div :class="[$style.solutionBgDetail]">
            <x-oss-image
              v-if="formData.pcScreenshots.length && formData.pcScreenshots[0]"
              :oss-path="formData.pcScreenshots[0]"
              :class="$style.image__img"
            />
            <div :class="$style.addIcon" v-else>
              <x-icon type="tc-icon-image-download"></x-icon>
              {{ $t('solution.addPictures') }}
            </div>
          </div>
        </x-upload-button>
      </a-form-model-item>
      <a-form-model-item
        prop="installScore"
        :label="$t('market.template.getPoints')"
      >
        <a-input v-model="formData.installScore" />
      </a-form-model-item>
      <a-form-model-item :label="$t('market.template.virtualInstalls')">
        <a-input-number v-model="formData.virtualInstallCount" />
      </a-form-model-item>
      <label-select v-model="formData.labelIds" />
    </div>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { globalVueI18n } from '@triascloud/i18n';
import { createFormModal } from '@triascloud/x-components';
import {
  editPrintTemplate,
  getPrintTemplateDetail,
} from '@/services/template-manager/template';
import LabelSelect from './label-select';
function createPrintSettingModal(props) {
  return createFormModal(h => h(PrintSettingModal, { props }), {
    title:
      globalVueI18n.translate('market.template.template') +
      globalVueI18n.translate('market.template.setting'),
    width: 690,
  });
}
@Component({
  components: { LabelSelect },
})
export default class PrintSettingModal extends Vue {
  static createModal = createPrintSettingModal;
  @Prop({ type: String }) templateId;
  @Prop({ type: Function }) service;
  @Prop({ type: Boolean, default: false }) readonly;
  formData = {
    id: '',
    appName: '',
    instruction: '',
    installScore: '',
    labelIds: [],
    mobileScreenshotList: '',
    pcScreenshots: '',
    virtualInstallCount: 0,
  };
  isFree = true;
  price = '';
  get name() {
    return this.$t('market.template.template');
  }
  get rules() {
    return {
      appName: [
        {
          required: true,
          message:
            this.$t('common.placeholder.input') +
            this.$t('market.template.formName'),
        },
        {
          min: 2,
          max: 30,
          message: this.$t('common.validate.length', { value: '2~30' }),
        },
      ],
      instruction: {
        max: 30,
        message: this.$t('market.template.introductionValidateTip'),
      },
      installScore: {
        pattern: /(^[0-9]\d*$)/,
        message: this.$t('market.template.integralValidateTip'),
      },
    };
  }
  get computedService() {
    return this.service || getPrintTemplateDetail;
  }
  get formatFormData() {
    return {
      installScore: this.formData.installScore,
      instruction: this.formData.instruction,
      labelIds: this.formData.labelIds.map(item => item.pkId),
      isRechargeable: this.isFree ? 1 : 0,
      tpName: this.formData.appName,
      price: !this.isFree && this.price ? this.price * 100 : 0,
      pcScreenshots: [this.formData.pcScreenshots[0]],
      id: this.formData.pkId,
      virtualInstallCount: this.formData.virtualInstallCount,
    };
  }
  async created() {
    const data = await this.computedService(this.templateId);
    this.formData = {
      ...data,
      installScore: data.installScore >= 0 ? data.installScore : '',
      price: data.price === -1 ? 0 : data.price,
      virtualInstallCount:
        data.virtualInstallCount === -1 ? 0 : data.virtualInstallCount,
    };
    this.price = this.formData.price ? this.formData.price / 100 : '';
    this.isFree = !this.price;
  }
  async getValue() {
    await this.$refs.form.validate();
    await editPrintTemplate(this.formatFormData);
    this.$message.success(this.$t('common.tips.save'));
  }
}
</script>
<style lang="less" module>
.form {
}
.form :global .ant-form-item {
  margin-bottom: 10px;
  .x-upload--fileList-oneFile-name,
  .x-upload--fileList-oneFile-icon-edit {
    display: none;
  }
}
.price {
  :global(.ant-form-item-children) {
    display: flex;
    align-items: center;
    height: 32px;
  }
}
.title {
  font-size: 16px;
  color: var(--font-sub);
  margin-bottom: 10px;
}
.textarea {
  position: relative;
  .textarea__count {
    position: absolute;
    bottom: 4px;
    right: 10px;
    color: var(--font-info);
  }
  :global .ant-input {
    height: 86px;
  }
}
.solutionInput {
  opacity: 0;
  height: 0;
  position: absolute;
  top: 0;
  width: 0;
}
.solutionBgDetail {
  height: 320px;
  width: 230px;
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .addIcon {
    display: flex;
    flex-direction: column;
    color: var(--font-color-secondary);
    cursor: pointer;
    i {
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
  &:hover {
    .changePic {
      display: flex;
    }
  }
  .image {
    width: 415px;
    height: 100%;
  }
  .image__img {
    height: 100%;
    width: 100%;
  }
  .addBtn {
    color: var(--font-color-secondary);
    display: flex;
    flex-direction: column;
  }
}
</style>
