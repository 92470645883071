<template>
  <div :class="$style.event">
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-form-model-item label="名称" prop="eventName">
        <a-input
          v-model="form.eventName"
          :maxLength="8"
          placeholder="请输入事件分类名称，限8字符长度"
          @change="$emit('change-name', $event.target.value)"
        />
      </a-form-model-item>
      <a-form-model-item label="订阅计划" prop="source">
        <a-select
          v-model="form.source"
          allowClear
          @change="changePlan"
          :getPopupContainer="triggerNode => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in planList"
            :value="item.pkId"
            :key="item.pkId"
          >
            <div :class="$style.flex">
              <div :title="item.name" style="width: 345px;">
                {{ item.name }}
              </div>
              <a-tag
                :color="colorObj[item.dataSources].color"
                :class="$style.textOverflow"
                style="height: 24px"
              >
                {{ colorObj[item.dataSources].text }}
              </a-tag>
            </div>
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.source && activeFlag === 'PASSIVE'"
        label="订阅主题"
        prop="topic"
      >
        <a-select
          v-model="form.topic"
          allowClear
          @change="changeTheme"
          :dropdownMenuStyle="{ maxHeight: '130px' }"
        >
          <a-select-option
            v-for="item in themeObj[form.source]"
            :value="item.pkId"
            :key="item.pkId"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="字段" prop="panelFields">
        <a-select
          placeholder="请选择"
          v-model="form.panelFields"
          mode="multiple"
          :maxTagCount="12"
          allowClear
          :dropdownMenuStyle="{ maxHeight: '130px' }"
          :getPopupContainer="triggerNode => triggerNode.parentNode"
          @change="changeFileds"
        >
          <a-select-option
            v-for="t in panelFieldsObj[form.topic]"
            :value="t.dataIndex"
            :key="t.dataIndex"
          >
            <div :class="$style.flex">
              <div
                :title="t.name"
                :class="$style.textOverflow"
                style="max-width: 230px;"
              >
                {{ t.name }}
              </div>
              <a-tag
                v-if="formateType(t.dataType, 'color')"
                :color="formateType(t.dataType, 'color')"
                style="height: 24px;margin: 0 5px"
              >
                {{ formateType(t.dataType, 'type') }}
              </a-tag>
            </div>
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="filterField">
        <span slot="label">
          <span>筛选字段</span>
          <a-tooltip>
            <template slot="title">
              限“字段来源”中已选择的枚举类型字段
            </template>
            <a-icon style="margin-left: 10px" type="question-circle" />
          </a-tooltip>
        </span>
        <a-select
          placeholder="请选择"
          v-model="form.filterField"
          allowClear
          :dropdownMenuStyle="{ maxHeight: '130px' }"
        >
          <a-select-option
            v-for="item in filterFieldFileds"
            :value="item.dataIndex"
            :key="item.dataIndex"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="时间筛选" prop="filterTime">
        <a-switch
          v-model="form.filterTime"
          checked-children="开启"
          un-checked-children="关闭"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getPlanThemeList } from '@/services/iot-platform/dataManage';
import { ControlTypeEnum } from '@/views/connector/platform-manage/develop/data-template/template-design/form/controls/enum.js';

/** @description 获取订阅计划和主题 */
export const fetchPlan = res => {
  const planList = [];
  const themeObj = {};
  const panelFieldsObj = {};
  const structObj = {};
  res.forEach(v => {
    const o = {
      name: v.name,
      pkId: v.pkId,
      dataSources: v.dataSources,
      connector: v.connector,
    };
    planList.push(o);
    const arr = [];
    v.theme.forEach(t => {
      const to = {
        pkId: t.id,
        name: t.name,
      };
      arr.push(to);
      const srr = [];
      const structArr = {};
      t.structList.forEach(s => {
        structArr[s.identifier] = {
          name: s.name,
          type: s.dataType.toLowerCase(),
        };
        if (s.sonVO.length) {
          structArr[s.identifier]['sonVO'] = s.sonVO;
          structArr[s.identifier]['hasVO'] = true;
        } else {
          structArr[s.identifier]['unit'] = s.unit;
          structArr[s.identifier]['hasVO'] = false;
        }
        if (s.sonVO.length > 0) {
          s.sonVO.forEach(son => {
            const so = {
              dataIndex: `${s.identifier}.${son.identifier}`,
              name: `${s.name}.${son.name}`,
              other: son.other,
              unit: son.unit,
              dataType: son.dataType.toLowerCase(),
            };
            if (son.sonVO.length > 0) {
              so['hasVO'] = true;
            }
            srr.push(so);
          });
        } else {
          const so = {
            dataIndex: s.identifier,
            name: s.name,
            other: s.other,
            unit: s.unit,
            dataType: s.dataType.toLowerCase(),
          };
          srr.push(so);
        }
      });
      panelFieldsObj[t.id] = srr;
      structObj[t.id] = structArr;
    });
    themeObj[v.pkId] = arr;
  });
  return {
    planList,
    themeObj,
    panelFieldsObj,
    structObj,
  };
};

@Component()
export default class EventSetting extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  @Prop({
    type: Object,
    default: () => {},
  })
  defaultEvent;

  form = {
    eventName: '',
    source: undefined,
    dataSources: '',
    connector: '',
    topic: undefined,
    panelFields: undefined,
    filterTime: true,
    filterField: undefined,
  };
  rules = {
    eventName: [
      {
        required: true,
        message: '事件分类名称不允许为空',
      },
    ],
    source: [
      {
        required: true,
        message: '订阅计划不允许为空',
      },
    ],
    topic: [
      {
        required: true,
        message: '主题不允许为空',
      },
    ],
    panelFields: [
      {
        required: true,
        message: '字段不允许为空',
      },
    ],
  };

  formateType(t, flag) {
    const obj = {
      [ControlTypeEnum.Int]: {
        lable: '整数',
        color: '#FF3535',
      },
      [ControlTypeEnum.Float]: {
        lable: '浮点',
        color: '#FFC12B',
      },
      [ControlTypeEnum.Double]: {
        lable: '双精度',
        color: '#339DEE',
      },
      [ControlTypeEnum.Input]: {
        lable: '文本',
        color: '#848BE2',
      },
      [ControlTypeEnum.Date]: {
        lable: '日期',
        color: '#9375CD',
      },
      [ControlTypeEnum.Switch]: {
        lable: '布尔',
        color: '#7987CB',
      },
      [ControlTypeEnum.Struct]: {
        lable: '结构体',
        color: '#4FC4F7',
      },
      [ControlTypeEnum.Array]: {
        lable: '数组',
        color: '#4DD1E2',
      },
      [ControlTypeEnum.Enum]: {
        lable: '枚举',
        color: '#EE9A9B',
      },
      [ControlTypeEnum.Image]: {
        lable: '图片',
        color: '#E57375',
      },
      [ControlTypeEnum.File]: {
        lable: '文件',
        color: '#FEB74B',
      },
      [ControlTypeEnum.DataPoint]: {
        lable: 'DP',
        color: '#EEE42A',
      },
    };
    // DP的子项没有type
    if (!t) {
      return flag === 'type' ? 'DP' : '#EAEAEA';
    }
    // DP的数据标识的格式
    if (!obj[t]) {
      return flag === 'type' ? t : '#EAEAEA';
    }
    return flag === 'type' ? obj[t].lable : obj[t].color;
  }

  mounted() {
    this.getList();
  }

  colorObj = {
    PASSIVE: {
      text: '被动触发',
      color: '#30cfca',
    },
    TIMING: {
      text: '定时触发',
      color: '#2db7f5',
    },
  };
  activeFlag = 'PASSIVE';

  changePlan(val) {
    this.form.source = val;
    const idx = this.planList.findIndex(v => v.pkId === val);
    const row = this.planList[idx];
    this.form.connector = row.connector;
    if (idx > -1) {
      this.activeFlag = row.dataSources;
      if (row.dataSources !== 'PASSIVE') {
        this.form.topic = this.themeObj[val][0].pkId;
      }
    } else {
      this.activeFlag = 'PASSIVE';
    }
  }

  changeTheme(val) {
    this.form.topic = val;
    this.form.panelFields = undefined;
  }

  filterFieldFileds = [];

  changeFileds(val) {
    this.filterFieldFileds = [];
    this.form.panelFields = val;
    if (val && val.length) {
      this.panelFieldsObj[this.form.topic].forEach(v => {
        if (
          val.indexOf(v.dataIndex) > -1 &&
          v.dataType === ControlTypeEnum.Enum
        ) {
          this.filterFieldFileds.push(v);
        }
      });
    }
  }

  planList = [];
  themeObj = {};
  panelFieldsObj = {};
  structObj = {};

  async getList() {
    const params = {
      platformId: this.platformId,
    };
    try {
      const res = await getPlanThemeList(params);
      if (res.length > 0) {
        this.planList = [];
        this.themeObj = {};
        this.panelFieldsObj = {};
        this.structObj = {};
        const { planList, themeObj, panelFieldsObj, structObj } = fetchPlan(
          res,
        );
        this.planList = planList;
        this.themeObj = themeObj;
        this.panelFieldsObj = panelFieldsObj;
        this.structObj = structObj;
        const len = Object.keys(this.defaultEvent).length;
        if (len > 0) {
          this.form = this.defaultEvent;
          this.activeFlag = this.form.dataSources;
          this.changeFileds(this.form.panelFields);
        }
      }
    } catch {
      return false;
    }
  }

  // async getValue() {
  //   await this.$refs.form.validate();
  //   this.form.dataSources = this.activeFlag;
  //   return {
  //     structObj: this.structObj[this.form.topic],
  //     form: this.form,
  //   };
  // }
}
</script>

<style lang="less" module>
.event {
  :global {
    .ant-select-selection--multiple .ant-select-selection__choice.zoom-leave {
      display: none;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .textOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
