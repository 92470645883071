<template>
  <div :class="$style.contentPadding">
    <FlexColContent>
      <div :class="$style.flexItem">
        <top-data :data-list="topData" />
      </div>
      <template slot="footer">
        <a-tabs
          style="height: 100%;"
          default-active-key="1"
          @change="changeTab"
        >
          <a-tab-pane key="1" tab="开发者">
            <Dev></Dev>
          </a-tab-pane>
          <a-tab-pane key="2" tab="业务日志">
            <Log></Log>
          </a-tab-pane>
        </a-tabs>
      </template>
    </FlexColContent>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import TopData from '../components/top-data.vue';
import Dev from './dev.vue';
import Log from './log.vue';
import FlexColContent from '@/views/digital-site/components/flex-col-content';
import { developerStatistics } from '@/services/digital-site/developer';

@Component({
  components: {
    TopData,
    Dev,
    Log,
    FlexColContent,
  },
})
export default class Developer extends Vue {
  get topData() {
    const {
      developmenterAllCount,
      developmenterGovermentCount,
      todayLogCount,
      monthLogCount,
      developmenterSupplierCount,
    } = this.statisticsData;
    return [
      {
        color: '#297FFF',
        name: '开发者数',
        num: developmenterAllCount ?? 0,
        icon: '1381795643223031810.20655614',
      },
      {
        color: '#FAB21A',
        name: '厂商企业开发者/政府开发者',
        num: `${developmenterSupplierCount ??
          0}/${developmenterGovermentCount ?? 0}`,
        icon: '1381795643223031810.21524818',
      },
      {
        color: '#F85448',
        name: '今日接收日志',
        num: todayLogCount ?? 0,
        icon: '1381795643223031810.21524747',
      },
      {
        color: '#7ED305',
        name: '本月接收日志',
        num: monthLogCount ?? 0,
        icon: '1381795643223031810.23435687',
      },
    ];
  }
  activeIndex = '1';
  changeTab(index) {
    this.activeIndex = index;
  }

  statisticsData = {
    developmenterAllCount: 0,
    developmenterGovermentCount: 0,
    todayLogCount: 0,
    monthLogCount: 0,
    developmenterSupplierCount: 0,
  };
  async initStatistics() {
    const res = await developerStatistics();
    this.statisticsData = res;
  }

  mounted() {
    this.initStatistics();
  }
}
</script>
<style lang="less" module>
.contentPadding {
  padding: 20px;
  height: 100%;
  :global(.ant-tabs-content) {
    height: calc(100% - 60px);
  }
}
.developer {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  .tabContent {
    // height: calc(100% - 195px);
    flex: 1;
    overflow: hidden;
    .tableWrap {
      // height: 100%;
      .operation {
        display: flex;
        justify-content: space-between;
        margin: 0px 0 12px;
        .searchBox {
          .searchInput {
            margin-right: 10px;
            width: 200px;
          }
          .searchSelect {
            width: 180px;
          }
        }
      }
      .buttonGroups {
        .button {
          cursor: pointer;
          color: var(--primary);
          margin-right: 20px;
        }
        .button:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
