<template>
  <div>
    <div v-if="edit && !recordList" :class="$style.title">
      {{ $t('solution.tip5') }}
    </div>
    <div
      v-if="(editStatus && !recordList) || (edit && !recordList)"
      :class="$style.wrapTlt"
    >
      {{ $t('solution.updateContent') }}
    </div>
    <x-scroll-list
      v-model="list"
      :service="service"
      :disabled="load"
      :class="$style.wrap"
      v-show="recordList"
    >
      <div :class="$style.item" slot-scope="{ data }">
        <div :class="$style.item__title">
          {{ $t('record.updateTime') }}：{{
            data.createTime
              ? $moment(data.createTime).format('Y-MM-DD HH:mm:ss')
              : ''
          }}
        </div>
        <div :class="$style.item__desc">
          <span>{{ $t('solution.updateContent') }}</span>
          <!-- <a-button
            type="link"
            v-if="!recordList && !editStatus"
            @click="editStatus = true"
            >编辑</a-button
          > -->
        </div>
        <pre :class="$style.item__desc">{{ data.upgradeDescribe }}</pre>
      </div>
    </x-scroll-list>

    <div
      :class="[$style.item, $style.currentItem]"
      v-show="!recordList && !editStatus && !edit && currentData.pkId"
    >
      <div :class="$style.item__title">
        <span
          >{{ $t('record.updateTime') }}：{{
            currentData.createTime
              ? $moment(currentData.createTime).format('Y-MM-DD HH:mm:ss')
              : ''
          }}</span
        >
        <!-- <a-button type="link" @click="editStatus = true" icon="edit"></a-button> -->
        <x-icon
          type="edit"
          @click="editStatus = true"
          v-if="currentData.pkId && $p.action('UPDATE')"
        />
      </div>
      <div :class="$style.item__desc">
        <span>{{ $t('solution.updateContent') }}</span>
      </div>
      <pre :class="$style.item__desc">{{ currentData.upgradeDescribe }}</pre>
    </div>
    <a-form-model
      ref="form"
      :model="currentData"
      :rules="rules"
      :class="$style.form"
    >
      <a-form-model-item
        prop="upgradeDescribe"
        v-if="editStatus && !recordList && !edit && currentData.pkId"
      >
        <a-textarea
          v-model="currentData.upgradeDescribe"
          :class="$style.desc"
          :maxLength="1000"
        >
        </a-textarea>
      </a-form-model-item>
      <!-- </a-form-model> -->
      <a-form-model-item prop="desc" v-if="edit && !recordList">
        <a-textarea
          v-model="currentData.desc"
          :class="$style.desc"
          :maxLength="1000"
        >
        </a-textarea>
      </a-form-model-item>
    </a-form-model>
    <empty-content type="list" v-if="!edit && !currentData.pkId" />

    <div :class="$style.footer">
      <a-button
        type="link"
        v-if="!editStatus && !recordList && currentData.pkId"
        @click="
          () => {
            recordList = true;
            editStatus = true;
          }
        "
        >{{ $t('solution.historicalUpdate') }}</a-button
      >
      <a-button
        type="link"
        v-if="editStatus && recordList && currentData.pkId"
        @click="
          () => {
            recordList = false;
            editStatus = false;
          }
        "
        >{{ $t('solution.updateContent') }}</a-button
      >
      <div :class="$style.action">
        <a-button @click="$emit('cancel')">{{
          $t('common.action.cancel')
        }}</a-button>
        <a-button @click="confirm" type="primary" :class="$style.confirm">{{
          $t('common.action.confirm')
        }}</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { createModal } from '@triascloud/x-components';
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  currentRecord,
  historyRecord,
  editCurrentRecord,
} from '@/services/template-manager/solution';
import { addSolutionRecord } from '@/services/template-manager/solution';
import SolutionInstallModal from './solution-install-modal.vue';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import { globalVueI18n } from '@triascloud/i18n';

function createSolutionUpdateRecordModal(props) {
  return new Promise(resolve => {
    const modal = createModal(
      h =>
        h(SolutionUpdateRecordModal, {
          on: {
            cancel: () => {
              modal.visible = false;
              resolve(false);
            },
            confirm: () => {
              modal.visible = false;
              resolve(true);
            },
          },
          props,
        }),
      {
        title: globalVueI18n.translate('solution.tip14'),
        width: 470,
        bodyStyle: { padding: 0, marginBottom: 0 },
      },
    );
  });
}
@Component({
  components: {
    EmptyContent,
  },
})
export default class SolutionUpdateRecordModal extends Vue {
  static createModal = createSolutionUpdateRecordModal;
  @Prop({ type: String, default: '' }) pkId;
  @Prop({ type: Boolean, default: false }) edit;

  list = [];
  recordList = false;
  editStatus = false;
  load = false;
  //   desc = null;
  rules = {
    upgradeDescribe: [
      {
        required: true,
        message: '请输入更新内容',
        trigger: 'blur',
      },
    ],
    desc: [
      {
        required: true,
        message: '请输入更新内容',
        trigger: 'blur',
      },
    ],
  };
  currentData = {
    upgradeDescribe: '',
    createTime: '',
    pkId: null,
    desc: '',
  };

  mounted() {
    this.currentDataMethod();
  }
  async service() {
    const data = await historyRecord(this.pkId);
    this.load = true;
    return data;
  }

  async currentDataMethod() {
    const data = await currentRecord(this.pkId);
    this.currentData = data ? data : this.currentData;
  }

  async confirm() {
    if (this.recordList) {
      this.$emit('cancel');
      return false;
    }
    await this.$refs['form'].validate();
    if (this.editStatus && !this.edit) {
      await editCurrentRecord({
        pkId: this.currentData.pkId,
        upgradeDescribe: this.currentData.upgradeDescribe,
      });
    }
    if (this.edit && !this.editStatus) {
      const data = await addSolutionRecord({
        idxSolution: this.pkId,
        remark: '',
        upgradeDescribe: this.currentData.desc,
        upgradeSolutionVersion: 0,
      });
      await SolutionInstallModal.createModal({
        upgrade: true,
        solutionId: data,
      });
    }
    this.$emit('confirm');
  }
}
</script>
<style lang="less" module>
.wrap {
  min-height: 186px;
  max-height: 600px;
  padding: 16px 20px;
  overflow-y: auto;
}

.title {
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
  padding-bottom: 0;
}

.wrapTlt {
  font-size: 16px;
  padding: 20px;
  padding-bottom: 0;
}

textarea.desc {
  margin: 20px;
  margin-left: 0;
  width: calc(100% - 21px);
  height: 230px !important;
}
.item {
  font-size: 16px;
  margin-bottom: 20px;
  .item__title {
    color: var(--font);
    margin-bottom: 4px;
  }
  .item__desc {
    color: var(--font-info);
    line-height: 1.8;
  }
}
.currentItem {
  .item__title {
    display: flex;
    justify-content: space-between;
  }
}
.form {
  margin-left: 20px;
}

.currentItem {
  padding: 20px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;
  border-top: 1px solid var(--form-border);
  padding: 0 20px;
  margin-top: 20px;
  padding-left: 0;
  .confirm {
    margin-left: 20px;
  }
  .action {
    margin-left: auto;
  }
}
</style>
