<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import emptyContent from '@/components/empty-content';
import { ControlTypeEnum } from '../data-template/template-design/form/controls';
import { cloneDeep } from 'lodash';
import { eachDelParent } from './utils';

/**
 * @description 选中的项
 * @param {*} list
 * @param {*} target 目标id
 * @param {*} parent 父节点
 * @param {*} cb 父节点不在树形组件选中的id
 */
const eachData = (list, target, parent, cb) => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return false;
  }
  let valueId = '';
  for (let child of list) {
    // 记录子级的父级
    if (parent) {
      child.parent = parent;
    }
    if (child.id === target) {
      valueId = child.id;
      child.selectable = true;
      if (parent) {
        // 需要用链表往上遍历最顶层的parent
        let parentCp = parent;
        while (parentCp) {
          parentCp.selectable = true;
          // 父节点不在树形组件选中的id
          if (!cb(parentCp.id)) {
            // 树形组件中，不是【选中】状态，是【含有】的状态
            Vue.set(parentCp, 'isContain', true);
          }
          parentCp = parentCp.parent;
        }
      }
      break;
    }
    if (child.children && child.children.length > 0) {
      eachData(child.children, target, child, cb);
    }
  }
  if (valueId) {
    return true;
  }
};

/**
 * @description 选中的列表
 * @param {*} list
 */
const eachOut = list => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return [];
  }
  let result = [];
  list.forEach(child => {
    if (child.selectable) {
      result.push(child);
    }
    if (child.children && child.children.length > 0) {
      child.children = eachOut(child.children);
    }
  });
  return result;
};

/**
 * @name 从列表中获取【树形组件】的选中的【keys】
 * @description 树的选中列表
 * @param {*} list
 */
const eachSelectList = list => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return [];
  }
  let result = [];
  list.forEach(child => {
    // 树形组件中，是【选中】状态，不是【含有】的状态
    if (child.selectable && !child.isContain) {
      result.push(child.id);
    }
    if (child.children && child.children.length > 0) {
      result = result.concat(eachSelectList(child.children));
    }
  });
  return result;
};

/** @name 设置参数列表的selectable和isContain状态 */
const eachAddSelect = (list, cb) => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return [];
  }
  list.forEach(child => {
    if (child.children && child.children.length > 0) {
      eachAddSelect(child.children, cb);
    }
    if (cb(child.id)) {
      child.selectable = true;
      if (child.children && child.children.length > 0) {
        let count = 0;
        for (let item of child.children) {
          if (item.selectable) {
            ++count;
          }
        }
        if (count !== child.children.length) {
          // 树形组件中，不是【选中】状态，是【含有】的状态
          Vue.set(child, 'isContain', true);
        }
      }
    }
  });
};

/** @name 更改选中状态为false */
const eachSelectedFalse = list => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return [];
  }
  list.forEach(child => {
    child.selectable = false;
    if (child.children && child.children.length > 0) {
      eachSelectedFalse(child.children);
    }
  });
};

/** @name 组件类型的文本和颜色 */
const formateType = (t, flag) => {
  const obj = {
    [ControlTypeEnum.Int]: {
      label: '整数',
      color: '#FF3535',
    },
    [ControlTypeEnum.Float]: {
      label: '浮点',
      color: '#FFC12B',
    },
    [ControlTypeEnum.Double]: {
      label: '双精度',
      color: '#339DEE',
    },
    [ControlTypeEnum.Input]: {
      label: '文本',
      color: '#848BE2',
    },
    [ControlTypeEnum.Date]: {
      label: '日期',
      color: '#9375CD',
    },
    [ControlTypeEnum.Switch]: {
      label: '布尔',
      color: '#7987CB',
    },
    [ControlTypeEnum.Struct]: {
      label: '结构体',
      color: '#4FC4F7',
    },
    [ControlTypeEnum.Array]: {
      label: '数组',
      color: '#4DD1E2',
    },
    [ControlTypeEnum.Enum]: {
      label: '枚举',
      color: '#EE9A9B',
    },
    [ControlTypeEnum.Image]: {
      label: '图片',
      color: '#E57375',
    },
    [ControlTypeEnum.File]: {
      label: '文件',
      color: '#FEB74B',
    },
    [ControlTypeEnum.DataPoint]: {
      label: 'DP',
      color: '#EEE42A',
    },
  };
  // DP的子项没有type
  if (!t) {
    return flag === 'type' ? 'DP' : '#EAEAEA';
  }
  // DP的数据标识的格式
  if (!obj[t]) {
    return flag === 'type' ? t : '#EAEAEA';
  }
  return flag === 'type' ? obj[t].label : obj[t].color;
};

@Component({
  components: {
    emptyContent,
  },
})
export default class ApiParamsCopy extends Vue {
  @Prop({ type: String, default: 'req' }) dataType;
  @Prop({ type: Array, default: () => [] }) paramsData;
  /** @description 选中的数据 */
  @Prop({ type: Array, default: () => [] }) selectList;
  @Prop() targetDom;

  @Watch('selectList', { immediate: true, deep: true })
  changeSelectList(newVal) {
    const keys = eachSelectList(newVal);
    eachSelectedFalse(this.paramsData);
    const list = cloneDeep(this.paramsData);
    eachAddSelect(list, id => keys.includes(id));
    const checkedKeys = eachSelectList(list);
    this.checkedKeys = checkedKeys;
    // 先赋值给数据格式
    this.getOutList(this.checkedKeys);
  }

  outList = [];
  onCheck(keys) {
    this.getOutList(keys);
  }
  getOutList(keys) {
    const list = cloneDeep(this.paramsData);
    keys.forEach(item => {
      eachData(list, item, '', id => {
        return keys.find(key => key === id);
      });
    });
    eachDelParent(list);
    this.outList = list;
  }

  checkedKeys = [];
  async getValue() {
    const list = eachOut(this.outList);
    return {
      type: this.dataType,
      list,
    };
  }

  childDom(list) {
    return list.map(item => {
      item.selectable = false;
      return (
        <a-tree-node key={item.id}>
          <div slot={'title'} class={this.$style.params}>
            <div class={[this.$style.text, this.$style.truncate]}>
              {`${item.name}（${item.identifier}）`}
            </div>
            <div style="width:50px">
              <a-tag color={formateType(item.type, 'color')}>
                {formateType(item.type, 'type')}
              </a-tag>
            </div>
          </div>
          {item.children && item.children.length > 0
            ? this.renderChild(item.children)
            : ''}
        </a-tree-node>
      );
    });
  }
  renderChild(list) {
    if (!(Array.isArray(list) && list.length > 0)) {
      return '';
    }
    return this.childDom(list);
  }
  render() {
    return (
      <div class={this.$style.container}>
        {this.paramsData && this.paramsData.length === 0 ? (
          <emptyContent />
        ) : (
          <a-tree
            onCheck={this.onCheck}
            v-model={this.checkedKeys}
            checkable={true}
            defaultExpandedKeys={['-1']}
            replaceFields={{ title: 'name' }}
          >
            <a-tree-node key={'-1'} title={'全选'}>
              {this.renderChild(this.paramsData)}
            </a-tree-node>
          </a-tree>
        )}
      </div>
    );
  }
}
</script>

<style lang="less" module>
.container {
  height: 430px;
  overflow-y: auto;
  :global {
    .ant-tree li .ant-tree-node-content-wrapper {
      flex: 1;
    }
  }
}
.link {
  color: var(--primary);
  cursor: pointer;
  float: right;
  margin-right: 5px;
}
.params {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropClass {
  left: 630px !important;
}
.text {
  max-width: 300px;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
