<template>
  <a-form-model ref="form">
    <a-form-model-item>
      <a-input :value="record.appSecret" disabled>
        <template #suffix>
          <a-icon style="margin-right: 10px;" v-show="loading" type="loading" />
          <span :class="$style.linkButton" @click="handleReset">重置</span>
        </template>
      </a-input>
    </a-form-model-item>
    <p :class="$style.tips">
      提示：<br />
      1. secret重置后旧secret将失效不再使用，请谨慎思考<br />
      2. 重置后的secret请自行保存，关闭后为了安全考虑不再存储secret
    </p>
  </a-form-model>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ResetSecretForm extends Vue {
  @Prop({ type: Object, default: () => {} }) record;

  loading = false;

  async getValue() {
    return true;
  }

  handleReset() {
    this.$emit('reset');
  }
}
</script>

<style lang="less" module>
.linkButton {
  cursor: pointer;
  color: var(--primary);
  user-select: none;
}
.tips {
  margin-top: 20px;
  color: var(--font-info);
}
</style>
