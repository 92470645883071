<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item label="标题" prop="title">
      <a-input :maxLength="16" v-model="form.title" placeholder="请输入标题" />
    </a-form-model-item>
    <a-form-model-item label="内容" prop="content">
      <a-textarea
        :maxLength="50"
        v-model="form.content"
        placeholder="请输入内容"
        :autoSize="{ minRows: 3, maxRows: 3 }"
      />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class AddField extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  editData;

  @Watch('editData', { immediate: true })
  changeData(newVal) {
    if (newVal && newVal.title) {
      this.form.pkId = newVal.pkId;
      this.form.title = newVal.title;
      this.form.content = newVal.content;
    }
  }

  form = {
    pkId: '',
    title: '',
    content: '',
  };
  rules = {
    title: [
      {
        required: true,
        message: '标题不允许为空',
      },
    ],
    content: [
      {
        required: true,
        message: '内容不允许为空',
      },
    ],
  };

  async getValue() {
    await this.$refs.form.validate();
    return this.form;
  }
}
</script>

<style scoped></style>
