<template>
  <div :class="$style.main">
    <a-descriptions :column="1">
      <a-descriptions-item label="订单编号">{{
        info.tradeCode
      }}</a-descriptions-item>
      <a-descriptions-item label="提交时间">
        {{
          info.createDate
            ? $moment(info.createDate).format('YYYY-MM-DD HH:MM:ss')
            : ''
        }}
      </a-descriptions-item>
      <a-descriptions-item label="购买企业">
        {{ info.tenantName }}
      </a-descriptions-item>
      <a-descriptions-item label="支付金额">
        {{
          info.amountActually
            ? `￥${(info.amountActually / 100).toFixed(2)}`
            : '0.00'
        }}
      </a-descriptions-item>
      <a-descriptions-item label="支付时间">
        {{
          info.paymentDate
            ? $moment(info.paymentDate).format('YYYY-MM-DD HH:MM:ss')
            : ''
        }}
      </a-descriptions-item>
      <a-descriptions-item label="支付方式">{{ payWay }}</a-descriptions-item>
      <a-descriptions-item label="订单状态">{{
        tradeStatus
      }}</a-descriptions-item>
      <a-descriptions-item label="开票状态">{{
        invoiceStatus
      }}</a-descriptions-item>
      <a-descriptions-item label="创建人">{{
        info.creatorName
      }}</a-descriptions-item>
    </a-descriptions>
    <div :class="$style.table">
      <div :class="$style.head">商品信息</div>
      <a-table
        rowKey="idxProductId"
        :columns="shopColumns"
        :data-source="info.productList"
        :pagination="false"
      />
    </div>
    <!-- <div :class="$style.table">
      <div :class="$style.head">费用信息</div>
      <a-table
        :columns="billColumns"
        :data-source="billData"
        :rowKey="(record, index) => index"
        :pagination="false"
      />
    </div> -->
    <div :class="$style.table" v-if="info.invoiceInfo">
      <div :class="$style.head">开票信息</div>
      <a-table
        rowKey="invoiceId"
        :scroll="{ x: 1000 }"
        :columns="invoiceColumns"
        :data-source="[info.invoiceInfo]"
        :pagination="false"
      />
    </div>

    <div :class="$style.table" v-if="info.invoiceInfo">
      <div :class="$style.head">分成比例</div>
      <a-table
        rowKey="invoiceId"
        :scroll="{ x: 1000 }"
        :columns="invoiceColumns"
        :data-source="[info.invoiceInfo]"
        :pagination="false"
      />
    </div>
    <!-- <div :class="$style.table">
      <div :class="$style.head">备注信息</div>
      <a-table
        :columns="remarkColumns"
        :data-source="remarkData"
        :rowKey="row => row.time"
        :pagination="false"
      />
    </div> -->
  </div>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  OrderStatusText,
  InvoiceStatusText,
  PayWayText,
  InvoiceTypeText,
} from '@/enum/order-bill.js';
import { getOrderDetail } from '@/services/transaction/trade.js';

@Component()
export default class CompanyOrder extends Vue {
  @Prop({ type: String, default: '' }) pkId;
  info = {};
  shopColumns = [
    {
      title: '商品ID',
      dataIndex: 'idxProductId',
      ellipsis: true,
    },
    {
      title: '商品名称',
      dataIndex: 'productName',
      ellipsis: true,
    },
    {
      title: '商品价格',
      key: 'productPrice',
      width: 100,
      align: 'center',
      customRender: record => `￥${(record.productPrice / 100).toFixed(2)}`,
    },
    {
      title: '数量',
      dataIndex: 'buyCount',
      width: 80,
      align: 'center',
    },
    {
      title: '小计',
      key: 'productSubtotal',
      width: 100,
      align: 'center',
      customRender: record => `￥${(record.productSubtotal / 100).toFixed(2)}`,
    },
  ];
  // billColumns = [
  //   {
  //     title: '商品合计',
  //     dataIndex: 'total',
  //     ellipsis: true,
  //   },
  //   {
  //     title: '优惠券',
  //     dataIndex: 'coupon',
  //     ellipsis: true,
  //   },
  //   {
  //     title: '积分抵扣',
  //     dataIndex: 'integral',
  //     ellipsis: true,
  //   },
  //   {
  //     title: '订单总金额',
  //     dataIndex: 'amount',
  //     ellipsis: true,
  //   },
  //   {
  //     title: '应付金额',
  //     dataIndex: 'bePay',
  //     ellipsis: true,
  //   },
  //   {
  //     title: '实付金额',
  //     dataIndex: 'actualPay',
  //     ellipsis: true,
  //   },
  // ];

  get shareRatioColumns() {
    return [
      {
        title: '关联分成企业',
        dataIndex: 'invoiceId1',
        ellipsis: true,
      },
      {
        title: '分成比例',
        dataIndex: 'invoiceId2',
        ellipsis: true,
      },
      {
        title: '分成金额',
        dataIndex: 'invoiceId3',
        ellipsis: true,
      },
    ];
  }
  invoiceColumns = [
    {
      title: '开票订单编号',
      dataIndex: 'invoiceId',
      ellipsis: true,
    },
    {
      title: '发票类型',
      key: 'invoiceType',
      ellipsis: true,
      customRender: record => InvoiceTypeText[record.invoiceType],
    },
    {
      title: '公司名称',
      dataIndex: 'companyName',
      ellipsis: true,
    },
    {
      title: '开户行',
      dataIndex: 'bankName',
      ellipsis: true,
    },
    {
      title: '税号',
      dataIndex: 'taxNumber',
      ellipsis: true,
    },
    {
      title: '税率',
      key: 'taxPoint',
      width: 80,
      align: 'center',
      customRender: record => `${record.taxPoint}%`,
    },
    {
      title: '开票金额',
      key: 'invoiceAmount',
      width: 100,
      customRender: record => `￥${(record.invoiceAmount / 100).toFixed(2)}`,
    },
  ];
  remarkColumns = [
    {
      title: '操作人',
      dataIndex: 'operator',
      ellipsis: true,
    },
    {
      title: '操作时间',
      dataIndex: 'time',
      ellipsis: true,
    },
    {
      title: '备注信息',
      dataIndex: 'remark',
      ellipsis: true,
    },
  ];
  billData = [
    {
      total: '240.00',
      coupon: '20.00',
      integral: '0.00',
      amount: '240.00',
      bePay: '220.00',
      actualPay: '220.00',
    },
  ];
  remarkData = [
    {
      operator: '小黑豆',
      time: '2020.09.04 17:21:22',
      remark: '备注信息',
    },
  ];

  get tradeStatus() {
    return OrderStatusText[this.info.tradeStatus];
  }

  get invoiceStatus() {
    return InvoiceStatusText[this.info.invoicingStatus];
  }

  get payWay() {
    return PayWayText[this.info.paymentWay];
  }

  mounted() {
    this.getInfo();
  }

  async getInfo() {
    this.info = await getOrderDetail(this.pkId);
  }
}
</script>
<style lang="less" module>
.main {
  :global {
    .ant-modal-body {
      height: 600px;
      overflow-y: auto;
    }
    .ant-descriptions-item-label {
      width: 100px;
    }
  }
  .table {
    margin-bottom: 20px;
  }
  .head {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
:global .company-order-drawer {
  .ant-modal-body {
    height: 600px;
    overflow-y: auto;
  }
}
</style>
