<template>
  <div>
    <a-form-model ref="form" :model="formData" layout="vertical">
      <a-form-model-item :label="$t('gallery.label.newGroupName') + '：'">
        <a-input v-model="imgGroupName" disabled />
      </a-form-model-item>
      <a-form-model-item
        prop="idxImgGroup"
        :label="$t('gallery.label.newGroupName') + '：'"
      >
        <a-select v-model="formData.idxImgGroup" :default-value="idxImgGroup">
          <a-select-option
            v-for="item in imgGroupOptions"
            :key="item.pkId"
            :value="item.pkId"
            >{{
              ['HEAD', 'LOGO', 'FILE'].includes(item.pkId)
                ? $t(item.groupName)
                : item.groupName
            }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';

@Component()
export default class RegroupImageModal extends Vue {
  @Prop({ type: String, default: '' }) idxImgGroup; // 原来的图片分组id
  @Prop({ type: String, default: '' }) imgGroupName; // 原来的图片分组名称
  @Prop({ type: Array, default: () => [] }) imgGroupOptions; // 候选图片分组选项
  static createModal = createModal;

  formData = {
    idxImgGroup: '',
  };

  created() {
    this.formData.idxImgGroup = this.idxImgGroup;
  }
}

function createModal({ idxImgGroup, imgGroupName, imgGroupOptions }) {
  return createFormModal(
    render =>
      render(RegroupImageModal, {
        props: { idxImgGroup, imgGroupName, imgGroupOptions },
      }),
    {
      title: globalVueI18n.translate('gallery.label.moveGroup'),
      width: 440,
    },
  );
}
</script>

<style scoped></style>
