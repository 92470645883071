<template>
  <div>
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-form-model-item prop="platformName">
        <span slot="label">
          {{ $t('connector.accessPlatform.platformName') }}
          <span style="color: red;">
            (*{{ $t('connector.deletePlatform') }}！)
          </span>
        </span>
        <a-input
          :placeholder="$t('connector.accessPlatform.inputPlatformName')"
          :maxLength="32"
          v-model="form.platformName"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class DeleteForm extends Vue {
  @Prop({ type: String, default: '' }) platformName;
  form = {
    platformName: '',
  };
  rules = {
    platformName: [
      {
        required: true,
        message: this.$t('connector.accessPlatform.platformEmpty'),
      },
    ],
  };
  async getValue() {
    await this.$refs.form.validate();
    return new Promise((resolve, reject) => {
      if (this.form.platformName === this.platformName) {
        resolve(true);
      } else {
        this.$message.error(this.$t('connector.accessPlatform.nameError'));
        reject(false);
      }
    });
  }
}
</script>

<style scoped></style>
