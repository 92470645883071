<template>
  <div :class="$style.container">
    <BasicFormModal
      ref="basicFormModal"
      :form-model-data="computedFormModalData"
      :formData="formData"
      @changeValue="changeValue"
    />
    <!-- 底部按钮 -->
    <CommonFooter
      slot="footer"
      type="news"
      :initParams="initParams"
      :mode="mode"
      :habitService="habitService"
      :formData="formData"
      :footerRequests="footerRequests"
      @close="$emit('close')"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { crossStorageModule } from '@/enum/store';
import { deepClone } from '@triascloud/utils';
import { HabitService } from '@triascloud/services';
import BasicFormModal from './basic-form-model.vue';
import CommonFooter from './common-footer.vue';
import { getClassifyList } from '@/services/configuration/classifyManagement';
import { addMedia, editMedia } from '@/services/configuration/newsConfig';
import { classifyType } from '@/enum/classify';

@Component({
  components: {
    BasicFormModal,
    CommonFooter,
  },
})
export default class EditCase extends Vue {
  @Prop({ type: Object }) initParams;
  @Prop({ type: String }) mode;
  @crossStorageModule.State tenant;

  formData = {
    title: '',
    classifyId: '',
    img: '',
    type: 0,
    otherUrl: '',
    signature: '',
    pushTime: this.$moment(new Date()).format('YYYY/MM/DD HH:mm:ss'),
    label: [],
    summarize: '',
  };
  classifyList = [];
  footerRequests = [addMedia, editMedia];

  get computedFormModalData() {
    const formModalData = [
      {
        id: 1,
        name: 'title',
        label: 'common.title',
        type: 'textarea',
        rules: [
          {
            required: true,
            trigger: 'blur',
            preMsg: 'common.placeholder.input',
          },
        ],
      },
      {
        id: 2,
        name: 'classifyId',
        label: 'controls.label.sort',
        type: 'radio',
        radioList: this.classifyList,
        rules: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
      },
      {
        id: 3,
        name: 'img',
        label: 'common.img',
        type: 'image',
        rules: [
          {
            required: true,
            trigger: 'blur',
            preMsg: 'common.placeholder.add',
          },
        ],
      },
      {
        id: 4,
        name: 'type',
        label: 'common.type',
        type: 'radio',
        radioList: [
          { pkId: 0, classifyName: this.$t('inviteCode.add') },
          { pkId: 1, classifyName: this.$t('website.news.otherUrl') },
        ],
        rules: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
        child: {
          id: 41,
          name: 'otherUrl',
          label: 'website.news.otherUrl',
          type: 'input',
          condition: {
            name: 'type',
            value: 1,
          },
          layout: 'singleLine',
          rules: [
            {
              required: true,
              trigger: 'blur',
              preMsg: 'common.placeholder.input',
            },
            {
              trigger: 'blur',
              pattern:
                // eslint-disable-next-line no-useless-escape
                /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/,
              message: this.$t('website.news.otherUrlConfirm'),
            },
          ],
        },
      },
      {
        id: 5,
        name: 'signature',
        label: 'website.author',
        type: 'textarea',
        rules: [
          {
            required: true,
            trigger: 'blur',
            preMsg: 'common.placeholder.input',
          },
        ],
      },
      {
        id: 6,
        name: 'pushTime',
        label: 'help.createDate',
        type: 'time',
        rules: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
      },
      {
        id: 7,
        name: 'label',
        label: 'common.label.name',
        type: 'label',
        labelType: 'news',
        marginBottom: '30px',
        rules: [
          {
            required: true,
            trigger: 'blur',
            preMsg: 'common.placeholder.select',
          },
        ],
      },
      {
        id: 8,
        name: 'summarize',
        label: 'website.summarize',
        type: 'editor',
        layout: 'singleLine',
        rules: [
          {
            required: true,
            trigger: 'change',
            preMsg: 'common.placeholder.input',
          },
        ],
      },
    ];
    if (this.formData.type === 1) {
      // 类型是新增和三方链接的时候字段校验规则不一致
      formModalData.forEach(data => {
        if (
          data.name === 'signature' ||
          data.name === 'label' ||
          data.name === 'summarize'
        ) {
          data.rules = [];
        }
      });
    }
    return formModalData;
  }

  get habitService() {
    return new HabitService('/configuration/memberHabit');
  }

  @Watch('formData.type')
  handleTypeChange() {
    this.$refs.basicFormModal.$refs.form.clearValidate();
  }

  // 编辑和暂存处理
  async created() {
    await this.getClassifyList();
    if (this.initParams) {
      let initParams = deepClone(this.initParams);
      if (initParams.classify && initParams.classify.length) {
        initParams.classifyId = initParams.classify[0].pkId;
        delete initParams.classify;
      }
      this.formData = initParams;
      if (this.formData.pushTime) {
        this.formData.pushTime = this.$moment(this.formData.pushTime).format(
          'YYYY/MM/DD HH:mm:ss',
        );
      }
    } else {
      const list = await this.habitService.getItem(
        `co.website.news.add.${this.tenant.pkId}`,
      );
      if (list && list !== '{}' && list !== 'null') {
        // 1. 保存的时候变成'{}'； 2. 后端默认返回的是'null'
        this.formData = JSON.parse(list);
        if (!this.formData.pushTime)
          // 处理空数据
          this.formData.pushTime = this.$moment(new Date()).format(
            'YYYY/MM/DD HH:mm:ss',
          );
      }
    }
  }

  //    分类相关
  async getClassifyList() {
    this.classifyList = await getClassifyList(classifyType.news);
    if (this.classifyList && this.classifyList.length) {
      this.formData.classifyId = this.classifyList[0].pkId || '';
    }
  }

  // 修改 formData 的属性值
  changeValue([name, value, type = 'change']) {
    if (type === 'change') {
      this.formData[name] = value;
    } else if (type === 'add') {
      this.formData[name].push(value);
    } else if (type === 'remove') {
      this.formData[name] = this.formData[name].filter(
        item => item.pkId !== value,
      );
    }
  }
}
</script>
<style lang="less" module>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}
</style>
