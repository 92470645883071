<script>
import { Component, Vue, ProvideReactive, Prop } from 'vue-property-decorator';
import { fmt2json } from './fmt2json';
import RenderImage from '../api-config/debug-api/render-image.vue';
import RenderNumber from '../api-config/debug-api/render-number.vue';
import RenderFile from '../api-config/debug-api/render-file.vue';
import RenderDataPoint from '../api-config/debug-api/render-datapoint.vue';
import RenderArray from '../api-config/debug-api/render-array.vue';
import RenderBoolean from '../api-config/debug-api/render-boolean.vue';
import RenderEnum from '../api-config/debug-api/render-enum.vue';
import RenderDate from '../api-config/debug-api/render-date.vue';
import RenderStruct from '../api-config/debug-api/render-struct.vue';
import { ControlTypeEnum } from '../data-template/template-design/form/controls';
import {
  checkDefaultValue,
  PARAM_TYPE,
  PARAM_STATUS,
  flatFields,
  ReactiveStore,
  eachResList,
  checkArrayType,
} from '../api-config/debug-api/utils';
import { delay, generateMap } from '@triascloud/utils';
@Component()
export default class Debug extends Vue {
  @Prop({ type: Array }) themes;
  @Prop({ type: Function }) fnFields;
  @ProvideReactive('variables') variables = [];

  theme = '';
  mounted() {
    this.theme = this.themes.length && this.themes[0].id;
  }

  txt = '';

  PARAM_STATUS = PARAM_STATUS;

  handleFormat() {
    const value = fmt2json(this.txt);
    this.txt = value;
  }

  handleClear() {
    this.txt = '';
  }

  renderScheme(list = [], type, store) {
    return list.map((v, idx) => {
      Vue.set(v, PARAM_TYPE, type);
      return <div key={'scheme' + idx}>{this.schemeByType(v, store)}</div>;
    });
  }

  schemeByType(v, store) {
    const mapComponent = {
      [ControlTypeEnum.Input]: RenderNumber,
      [ControlTypeEnum.Double]: RenderNumber,
      [ControlTypeEnum.Float]: RenderNumber,
      [ControlTypeEnum.Int]: RenderNumber,
      [ControlTypeEnum.Switch]: RenderBoolean,
      [ControlTypeEnum.Enum]: RenderEnum,
      [ControlTypeEnum.Date]: RenderDate,
      [ControlTypeEnum.File]: RenderFile,
      [ControlTypeEnum.Image]: RenderImage,
      [ControlTypeEnum.Array]: RenderArray,
      [ControlTypeEnum.Struct]: RenderStruct,
      [ControlTypeEnum.DataPoint]: RenderDataPoint,
    };
    const DefaultComponent = mapComponent[v.type];
    return (
      <DefaultComponent
        item={v}
        store={store}
        tooltip={() => {}}
        param={() => {}}
        checkDefaultValue={checkDefaultValue}
        schemeByType={this.schemeByType}
        useKey={'id'}
      />
    );
  }

  hasReady = false;
  createList() {
    const resList = this.fnFields(this.theme);
    let list = checkArrayType(resList, '', () => {
      this.hasReady = true;
    });
    const array = list.filter(v => v.pkId === v.id);
    return array;
  }

  resCpList = [];
  outStore = {};
  async handleParse(result) {
    this.resCpList = this.createList();
    await eachResList(this.filterResList, -1, '', result);
    let data = generateMap(flatFields(this.resCpList), 'id', item => {
      checkDefaultValue(item);
      return item.val;
    });
    this.outStore = new ReactiveStore(data);
  }

  async handleChange() {
    await delay(600);
    if (this.txt) {
      this.handleParse(JSON.parse(this.txt));
    }
  }

  render() {
    return (
      <div class={this.$style.wrap}>
        <section class={this.$style.left}>
          <section class={this.$style.topWrap}>
            <h3>调试数据</h3>
            <span>
              <a-button
                class={this.$style.btn}
                type="primary"
                onClick={this.handleFormat}
              >
                格式化
              </a-button>
              <a-button class={this.$style.btn} onClick={this.handleClear}>
                清空
              </a-button>
              <a-button
                class={this.$style.btn}
                type="primary"
                onClick={() => this.handleParse(JSON.parse(this.txt))}
              >
                解析
              </a-button>
            </span>
          </section>
          <a-textarea
            v-model={this.txt}
            autoSize={{ minRows: 30, maxRows: 30 }}
          />
        </section>
        <section class={this.$style.right}>
          <section class={this.$style.rightTop}>
            <h3>调试结果</h3>
            <a-select v-model={this.theme} onChange={this.handleChange}>
              {this.themes.map(item => (
                <a-select-option key={item.id} value={item.id}>
                  {item.name}
                </a-select-option>
              ))}
            </a-select>
          </section>
          <section>
            {this.renderScheme(this.resCpList, PARAM_STATUS.OUT, this.outStore)}
          </section>
        </section>
      </div>
    );
  }
}
</script>
<style lang="less" module>
.wrap {
  display: flex;

  .left {
    flex: 1;
    margin-right: 15px;

    .topWrap {
      display: flex;
      justify-content: space-between;
      h3 {
        font-weight: bolder;
      }
      .btn {
        height: 24px;
        padding: 0 7px;
        min-width: 66px;
        font-size: 14px;
        border-radius: 4px;
      }
      .btn + .btn {
        margin-left: 4px;
      }
    }
  }

  .right {
    flex: 1;
    margin-left: 15px;
    .rightTop {
      display: flex;
      justify-content: space-between;
      h3 {
        font-weight: bolder;
      }
    }
  }
}
</style>
