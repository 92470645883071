import { TemplateType } from '@/enum/template';
import request from '../request';
export const basePath = '/template-manager/tpClassify';

/**
 * @name 获取分类树
 * @returns Promise<{ any[] }>
 */
export function getClassifyTree(templateType = TemplateType.Application) {
  return request(`${basePath}/tree`, { body: { templateType } });
}

/**
 * @name 获取分类树（不包含“其他”分类）
 * @returns Promise<{ any[] }>
 */
export function getClassifyTreeExcludeOther(
  templateType = TemplateType.Application,
) {
  return request(`${basePath}/treeNoOther`, { body: { templateType } });
}

/**
 * @name 获取子分类
 * @returns Promise<{ any[] }>
 */
export function getClassifyChildren(parentId) {
  return request(`${basePath}/listByClassType?parentId=${parentId}`);
}

export const addClassify = data =>
  request(`${basePath}/add`, {
    method: 'POST',
    body: data,
  });

export const editClassify = data =>
  request(`${basePath}/edit`, {
    method: 'POST',
    body: data,
  });

export const sortClassify = data =>
  request(`${basePath}/order`, {
    method: 'POST',
    body: data,
  });

export const removeClassify = pkId =>
  request(`${basePath}/remove?pkId=${pkId}`, {
    method: 'DELETE',
  });

/**
 * @name 分类下模板排序
 * @param {Array<{ sort: number; templateClassifyId: string; templateId: string; templateType: string}>} data
 */
export const sortTemplate = data =>
  request(`${basePath}/templateOrderInClass`, {
    method: 'POST',
    body: data,
  });

/**
 * @name 模板移动分类
 * @param {*} param0
 * @returns
 */
export const moveTemplate = ({ tempId, tempType, newClassId }) =>
  request(`${basePath}/templateEditClass`, {
    method: 'POST',
    body: { tempId, tempType, newClassId },
  });

/**
 * @name 获取模板分类
 * @param {*} param0
 * @returns
 */
export const getTemplateClassify = templateId =>
  request(`${basePath}/classifyByTp`, {
    body: { templateId },
  });
