import { ensureArray } from '@triascloud/utils';
import { isEqual } from 'lodash';
import {
  Component,
  Inject,
  Prop,
  Provide,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { DataFormContext } from './context';

@Component()
export default class DataFormModelContext extends Vue {
  /** @type { string[] } */
  @Prop({ type: Array, default: () => [] }) path;
  /** @type { Record<string, import('../controls').Field> } */
  @Prop({ type: Object, default: () => ({}) }) fieldMap;
  /** @name 上下文数据 */
  @Prop() contextData;

  /** @type { import('./data-form.vue').default } */
  @Inject({ from: DataFormContext.Root, default: null }) root;
  /** @type { import('./reactive-store').default }  */
  get store() {
    return this.root && this.root.store;
  }

  /** @type { string[] } */
  @Inject({ from: DataFormContext.Path, default: () => [] }) injectPath;

  @Provide(DataFormContext.Path)
  get providePath() {
    return this.injectPath.concat(this.path);
  }

  @Inject({ from: DataFormContext.Model, default: null }) injectModel;

  @Provide(DataFormContext.Model)
  get model() {
    return this;
  }

  getContextPath(path) {
    if (typeof path !== 'string') return path;
    if (path in this.fieldMap) return this.providePath.concat(path);
    return path;
  }

  getValue(fieldId) {
    const contextValue = this.contextData?.[fieldId];
    if (contextValue !== undefined) return contextValue;
    return this.store.getArray(this.getContextPath(fieldId));
  }

  setValue(fieldId, value, trigger) {
    return this.store.set(this.getContextPath(fieldId), value, trigger);
  }

  /**
   * @param { string } fieldId
   * @returns { import('../controls').Field | null }
   */
  getField(fieldId) {
    return (
      this.fieldMap[fieldId] ||
      (this.injectModel && this.injectModel.getField(fieldId)) ||
      null
    );
  }

  /**
   * @name 将配置的路径转换为正确的监听的地址
   * @param { string } path
   */
  generateWatcherPath(path) {
    // 非子表单字段 '字段id' => '字段id'
    // 子表单外的字段访问子表单内的字段 '子表单id.子字段id' => '子表单id.*.子字段id'
    // 子表单内的字段访问同一行的字段 '子表单id.子字段id' => '子字段id'
    const [parentField, subField] = (path || '').split('.');
    if (!subField) return parentField;
    if (this.getField(subField) || this.path.includes(parentField))
      return subField;
    return path.replace(/\./g, '.*.');
  }

  clearWatchers() {
    const watchers = this.modelWatchers.map(([unwatch, paths, callback]) => {
      unwatch && unwatch();
      return [paths, callback];
    });
    this.modelWatchers = [];
    return watchers;
  }

  @Watch('root.view')
  @Watch('path')
  resetWatchers(newVal, oldVal) {
    if (isEqual(newVal, oldVal)) return;
    this.clearWatchers().forEach(([paths, callback]) =>
      this.watch(paths, callback),
    );
  }

  modelWatchers = [];

  watch(paths, callback) {
    const unwatch = this.root.view
      ? null
      : this.store.watch(
          paths.map(path => ensureArray(this.getContextPath(path)).join('.')),
          callback,
        );
    this.modelWatchers.push([unwatch, paths, callback]);
  }

  beforeDestroy() {
    this.clearWatchers();
  }

  trigger(fieldId) {
    return this.store.trigger(this.providePath.concat(fieldId));
  }

  render() {
    if (!this.$slots.default) return null;
    if (this.$slots.default.length === 1) return this.$slots.default[0];
    return this.$createElement('div', this.$slots.default);
  }
}
