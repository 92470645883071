<template>
  <div :class="$style.wrap">
    <div :class="$style.tableWrap" id="tableWrap">
      <a-form-model :model="form" :rules="rules" ref="form" labelAlign="left">
        <a-form-model-item
          label="升级方式"
          prop="upgradeType"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :class="$style.item"
        >
          <a-radio-group
            name="radioGroup"
            :default-value="1"
            style="margin-left: 20px;"
            v-model="form.upgradeType"
            @change="updatedDetails"
          >
            <a-radio value="STATIC">
              静态升级
            </a-radio>
            <a-radio value="DYNAMIC">
              动态升级
            </a-radio>
          </a-radio-group>
          <a-tooltip
            placement="rightBottom"
            :class="$style.detail"
            :getPopupContainer="parentNode"
          >
            <template slot="title">
              <div>
                <div>静态升级：本次选中的设备进行升级操作；</div>
                <div>动态升级：对所有设备以及后续添加/激活的设备持续升级；</div>
              </div>
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
          <div :class="$style.tip">
            <span v-show="form.upgradeType == 'DYNAMIC'">
              将对现有全部设备以及后续添加/激活的设备持续升级操作，请谨慎操作!</span
            >
          </div>
        </a-form-model-item>
        <a-form-model-item
          :class="$style.item"
          label="待升级版本号"
          prop="srcVersions"
          v-if="
            !(
              $store.state.hat.OTAversion.type === 'FULL_PACKAGE' &&
              form.upgradeType == 'STATIC'
            )
          "
        >
          <a-select
            mode="multiple"
            v-model="form.srcVersions"
            placeholder="请选择版本号"
            style="width:fit-content;min-width: 400px;"
            :disabled="versionDisable"
            @change="updatedDetails"
          >
            <a-select-option
              v-for="(item, index) in versions"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          :class="$style.item"
          label="升级范围"
          prop="isForceUpgrade"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <div :class="$style.range">
            <span style="margin-right: 10px;">{{ conflictInfo }}</span>
            <a @click="copy">复制</a>
          </div>
          <div :class="$style.range">
            <a-switch
              checked-children="开"
              un-checked-children="关"
              v-model="form.isForceUpgrade"
            />
            <span style="margin-left: 10px;">强制覆盖设备已有升级任务</span>
          </div>
        </a-form-model-item>
        <a-form-model-item
          label="升级时间"
          prop="upgradeTime"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :class="$style.item"
        >
          <a-radio-group
            name="radioGroup"
            :default-value="1"
            style="margin-left: 20px;"
            v-model="form.upgradeTime"
          >
            <a-radio :value="1">
              立即升级
            </a-radio>
            <a-radio :value="2" v-show="form.upgradeType == 'STATIC'">
              定时升级
            </a-radio>
          </a-radio-group>
          <!-- <a-range-picker
            v-show="form.upgradeTime == 2 && form.upgradeType == 'STATIC'"
            v-model="form.timeRange"
            style="width: 320px;text-align: left;"
            format="YYYY-MM-DD HH:mm:ss"
            :placeholder="['开始日期', '结束日期']"
            @change="handleSearchTime"
            @ok="handleSearchTimeOK"
            :allow-clear="false"
          /> -->
          <a-range-picker
            v-show="form.upgradeTime == 2 && form.upgradeType == 'STATIC'"
            v-model="form.timeRange"
            style="width: 370px;text-align: left;"
            @calendarChange="onOpenChange"
            :disabled-date="disabledDate"
            @change="handleSearchTime"
            @OK="handleSearchTimeOK"
            :show-time="{
              hideDisabledOptions: true,
              defaultValue: [
                $moment('00:00:00', 'HH:mm:ss'),
                $moment('11:59:59', 'HH:mm:ss'),
              ],
            }"
            format="YYYY-MM-DD HH:mm:ss"
          />
          <a-tooltip
            placement="rightBottom"
            :class="$style.detaildd"
            :getPopupContainer="parentNode"
          >
            <template slot="title">
              <div>
                <div>定时开始时间：必填，需大于当前时间10分钟，最多7天</div>
                <div>
                  定时结束时间：可选，距开始时间最少 1 小时，最多为 30 天
                </div>
              </div>
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </a-form-model-item>
        <a-form-model-item
          :class="$style.item"
          label="客户确认"
          prop="isClientConfirm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-switch
            checked-children="开"
            un-checked-children="关"
            v-model="form.isClientConfirm"
          />
          <div id="tooltip3" :class="$style.tooltip3">
            <a-tooltip
              placement="rightBottom"
              :class="$style.detail1"
              :overlayStyle="{ maxWidth: 600, width: 600 }"
              :getPopupContainer="parentNode3"
            >
              <template slot="title">
                <div>
                  <div>静开启后只有用户确认才执行升级操作；</div>
                  <div>若设置定时升级，客户确认时间必须在定时时段内；</div>
                </div>
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </div>
        </a-form-model-item>
        <a-form-model-item
          :class="$style.item"
          label="升级说明"
          prop="mark"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-input
            v-model="form.mark"
            type="textarea"
            style="width: 500px;"
            :max-length="256"
            placeholder='限140字符长度,若开启"客户确认",此处填写内容将作为升级提示内容'
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { reqAllFirmwareVersion, reqUpgradeDetail } from '@/services/hat/ota';
import { clipboardWrite } from '@triascloud/utils';
// import moment from 'moment';
@Component({})
export default class setStrategy extends Vue {
  @Prop({ type: Object, default: () => {} }) record;
  form = {
    upgradeType: 'STATIC',
    srcVersions: undefined,
    isForceUpgrade: false,
    upgradeTime: 1,
    isClientConfirm: false,
    mark: ' ',
    timeRange: [],
  };
  all = '';
  canUpgraded = '';
  conflict = '';
  unUpgrade = '';
  labelCol = { span: 2 };
  wrapperCol = { span: 20 };
  rules = {
    upgradeType: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    srcVersions: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    isForceUpgrade: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    upgradeTime: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    mark: [
      {
        validator: this.validateGroup,
        trigger: 'submit',
      },
    ],
  };
  conflictUnUpgrade = {
    all: '',
    canUpgraded: '',
    conflict: {
      number: '',
    },
    unUpgrade: {
      number: '',
    },
  };
  timeString = [];
  offsetDays = 7 * 24 * 60 * 60 * 1000;
  offsetDays22 = 30 * 24 * 60 * 60 * 1000;
  versions = [];
  selectPriceDate = '';
  mounted() {
    this.setPackage();
    this.setTime();
  }
  onOpenChange(status) {
    this.selectPriceDate = status[0];
  }
  handleSearchTime(time, timeString) {
    this.timeString = timeString;
  }

  disabledDate(current) {
    let nowDate = Date.now();
    const moment = this.$moment;
    if (this.selectPriceDate) {
      let selectV = moment(this.selectPriceDate, 'YYYY-MM-DD').valueOf();

      return (
        current >
          moment(
            this.formatDate(
              new Date(selectV + this.offsetDays22).getTime(),
              'Y-m-d',
            ),
          ) || current < nowDate
      );
    } else {
      return current < nowDate || current > nowDate + this.offsetDays;
    }

    // let nowDate = Date.now();
    // if (this.timeString[0]) {
    //   return current < nowDate || current > nowDate + this.offsetDays22;
    // } else {
    //   return current < nowDate || current > nowDate + this.offsetDays;
    // }

    // if (this.timeString[0]) {
    //     let selectV = moment(this.timeString[0], 'YYYY-MM-DD').valueOf();
    //     console.log(selectV,this.timeString[0]);
    //   //禁用选定时间前后6天和今天之后的日期
    //   return (
    //     current >
    //       moment(
    //         this.formatDate(
    //           new Date(selectV + this.offsetDays).getTime(),
    //           'Y-m-d',
    //         ),
    //       ) ||
    //     current <
    //       moment(
    //         this.formatDate(
    //           new Date(selectV - this.offsetDays).getTime(),
    //           'Y-m-d',
    //         ),
    //       ) ||
    //     current > moment().endOf('day')
    //   );
    // } else {
    //   // return current > moment().endOf('day'); //禁用今天之后的日期
    // }
  }
  formatDate(timestamp, formatLayout = 'Y-m-d H:i:s') {
    let formatDate = '';
    formatLayout = formatLayout.toUpperCase();
    timestamp = (timestamp + '').length > 11 ? timestamp : timestamp * 1000;
    let time = new Date(timestamp);
    for (let i in formatLayout) {
      if (['Y', 'M', 'D', 'W', 'H', 'I', 'S'].indexOf(formatLayout[i]) >= 0) {
        switch (formatLayout[i]) {
          case 'Y':
            formatDate += time.getFullYear();
            break;
          case 'M':
            formatDate +=
              time.getMonth() >= 9
                ? time.getMonth() + 1
                : '0' + (time.getMonth() + 1);
            break;
          case 'D':
            formatDate +=
              time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
            break;
          case 'W':
            formatDate += time.getDay() == 0 ? 7 : time.getDay();
            break;
          case 'H':
            formatDate +=
              time.getHours() > 9 ? time.getHours() : '0' + time.getHours();
            break;
          case 'I':
            formatDate +=
              time.getMinutes() > 9
                ? time.getMinutes()
                : '0' + time.getMinutes();
            break;
          case 'S':
            formatDate +=
              time.getSeconds() > 9
                ? time.getSeconds()
                : '0' + time.getSeconds();
            break;
        }
      } else {
        formatDate += formatLayout[i];
      }
    }
    return formatDate;
  }
  disabledRangeTime(_, type) {
    if (type === 'start') {
      return {
        disabledHours: () => this.range(0, 60).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    }
    return {
      disabledHours: () => this.range(0, 60).splice(20, 4),
      disabledMinutes: () => this.range(0, 31),
      disabledSeconds: () => [55, 56],
    };
  }
  range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  get conflictInfo() {
    const { all, canUpgraded, unUpgrade, conflict } = this.conflictUnUpgrade;
    return `已选${all}台,预计${canUpgraded}台可升级(${unUpgrade.number}台已有升级任务,${conflict.number}台版本固件冲突)`;
  }

  //   setTime() {
  //     if (this.form.upgradeTime == 2 && !this.form.timeRange.length) {
  //       const startDate = Date.now() + 10 * 60 * 1000;
  //       const endDate = startDate + 60 * 60 * 1000;
  //       this.form.timeRange = [this.myMoment(startDate), this.myMoment(endDate)];
  //     }
  //   }
  setTime() {
    const startDate = Date.now() + 10 * 60 * 1000;
    const endDate = startDate + 60 * 60 * 1000;
    this.form.timeRange = [this.myMoment(startDate), this.myMoment(endDate)];
  }
  myMoment(time) {
    return this.$moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  validateGroup(rule, value, callback) {
    if (value.length > 140) {
      callback(new Error(`输入限140字符长度!`));
    }
    callback();
  }
  //toolTip添加到的父节点,不设置就会默认加给body
  parentNode() {
    return document.getElementById('tableWrap');
  }
  parentNode3() {
    return document.getElementById('tooltip3');
  }
  get versionDisable() {
    const { type } = this.$store.state.hat.OTAversion;
    if (type == 'PART_PACKAGE') {
      return true;
    } else {
      return this.form.upgradeType === 'STATIC';
    }
  }
  async setPackage() {
    const { srcVersion, type } = this.$store.state.hat.OTAversion;
    if (type == 'PART_PACKAGE') {
      this.form.srcVersions = srcVersion;
      this.versions = [srcVersion];
    } else {
      const result = await reqAllFirmwareVersion();
      this.versions = result;
    }
    this.updatedDetails();
  }
  handleSearchTimeOK() {
    this.selectPriceDate = '';
  }
  async updatedDetails() {
    let { firmwareId } = this.$store.state.hat.OTAversion;
    let { deviceIds } = this.$store.state.hat;
    let { upgradeType, srcVersions } = this.form;
    if (!srcVersions || srcVersions.length === 0) {
      srcVersions = this.versions;
    } else {
      srcVersions = [srcVersions];
    }
    deviceIds = deviceIds.join(',');
    const result = await reqUpgradeDetail({
      upgradeType,
      firmwareId,
      deviceIds,
      srcVersions,
    });
    this.conflictUnUpgrade = result;
  }
  copy() {
    let conflictArr = this.conflictUnUpgrade.conflict.deviceNames.map(item => {
      return item;
    });
    let unUpgradeArr = this.conflictUnUpgrade.unUpgrade.deviceNames.map(
      item => {
        return item;
      },
    );
    let conflict = '固件版本冲突:  ' + conflictArr.join(', ');
    let unUpgrade = '已有升级任务:  ' + unUpgradeArr.join(', ');
    let str = conflict + '\n' + unUpgrade;
    clipboardWrite(str).then(() => {
      this.$message.success('设备信息复制成功!');
    });
  }
  validate() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          resolve();
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>
<style lang="less" module>
.wrap {
  .tableWrap {
    height: fit-content;
    margin-top: 20px;
    .item {
      margin-bottom: 10px;
      position: relative;

      .detail {
        position: absolute;
        top: 3px;
        left: -24px;
        color: #000;
      }
      .detaildd {
        position: absolute;
        top: 3px;
        left: -24px;
        color: #000;
      }
      .detail1 {
        position: absolute;
        top: 3px;
        left: -37px;
        color: #000;
      }
      .tip {
        height: 20px;
        color: red;
      }
      .range {
        display: flex;
        align-items: center;
      }
    }
  }
  :global(.ant-form-item-label) {
    label {
      color: #000 !important;
    }
  }
  :global(.ant-tooltip-inner) {
    width: 400px;
  }
  .tooltip3 {
    :global(.ant-tooltip-inner) {
      width: 340px;
    }
  }
}
</style>
