<template>
  <div>
    <field-config-config-desc :desc="defaultDesc" />
    <field-config-title v-bind="fieldProps" />
    <field-config-required v-if="!isRes" v-bind="fieldProps" />
    <parameter-object v-if="!isRes" v-bind="fieldProps" isInOrOut="in" />
    <parameter-object v-if="isRes" v-bind="fieldProps" isInOrOut="out" />
    <a-form-model-item label="参数范围">
      <div :class="$style.range">
        <a-input
          type="number"
          :value="field.widget.min"
          @input="
            updateField({ key: 'widget.min', value: $event.target.value })
          "
          @blur="validateMin"
          allowClear
        />
        <span style="margin: 0 10px">-</span>
        <a-input
          type="number"
          :value="field.widget.max"
          @input="
            updateField({ key: 'widget.max', value: $event.target.value })
          "
          @blur="validateMax"
          allowClear
        />
      </div>
    </a-form-model-item>
    <a-form-model-item
      v-if="!isRes"
      :class="$style.title"
      prop="defaultVal"
      label="默认值"
    >
      <div :class="$style.range">
        <a-input
          style="width: 100%"
          type="number"
          :value="field.widget.defaultValue.custom"
          @input="
            updateField({
              key: 'widget.defaultValue.custom',
              value: $event.target.value,
            })
          "
          @blur="validateDefault"
          allowClear
        />
      </div>
    </a-form-model-item>
    <a-form-model-item v-if="field.type !== 'int'" label="参数精度">
      <a-select :value="field.widget.precision" @change="changePrecision">
        <a-select-option v-for="item in getPrecision" :key="item" :value="item">
          {{ item + '位' }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="单位">
      <a-radio-group
        :value="field.widget.unitType"
        :options="unitOptions"
        @change="changeUnit"
      />
      <a-select
        v-if="field.widget.unitType === 'system'"
        :value="field.widget.unitSelect"
        @change="changeUnitSelect"
      >
        <a-select-option
          v-for="item in unitArr"
          :key="item.key"
          :value="item.key"
        >
          {{ item.value }}
        </a-select-option>
      </a-select>
      <a-input
        v-else
        :value="field.widget.unitInput"
        @input="
          updateField({
            key: 'widget.unitInput',
            value: $event.target.value,
          })
        "
      />
    </a-form-model-item>
    <field-config-description v-bind="fieldProps" />
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getControl } from '../utils';
import { unitArray } from '@/views/connector/platform-manage/develop/utils';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';
import FieldConfigTitle from '../components/field-config/title.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
import FieldConfigRequired from '../components/field-config/required.vue';
import ParameterObject from '../components/field-config/parameter-object.vue';
import { templateDesignModule } from '@/enum/store';
import { ParamType } from '../enum';

@Component({
  components: {
    FieldConfigConfigDesc,
    FieldConfigTitle,
    FieldConfigRequired,
    FieldConfigDescription,
    ParameterObject,
  },
})
export default class InputConfig extends Vue {
  @Prop() field;
  @Prop() updateField;
  @templateDesignModule.Getter paramType;

  get defaultDesc() {
    const type = this.field.type;
    if (type === 'int') {
      return '整数：允许自定义输入-2147483648-2147483648区间的数值';
    } else if (type === 'float') {
      return '浮点：适用于精度要求一般的数据场景，建议不超过小数点后7位';
    } else {
      return '双精度浮点：适用于精度较高的数据场景，建议精度不超过15位';
    }
  }

  /** @name 出参 */
  get isRes() {
    return this.paramType === ParamType.RES_PARAM;
  }

  get getPrecision() {
    return this.field.type === 'float' ? 7 : 15;
  }
  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
    };
  }
  handleInput(key, value) {
    this.updateField({ key, value });
  }
  unitOptions = [
    { label: '系统推荐', value: 'system' },
    { label: '用户自定义输入', value: 'custom' },
  ];
  unitSelect = undefined;
  get unitArr() {
    return unitArray;
  }
  changeUnit(e) {
    this.handleInput('widget.unitSelect', undefined);
    this.handleInput('widget.unitInput', '');
    this.handleInput('widget.unitType', e.target.value);
  }
  changePrecision(e) {
    this.handleInput('widget.precision', e);
  }
  changeUnitSelect(e) {
    this.handleInput('widget.unitSelect', e);
  }
  get controlLabel() {
    return this.$t(getControl(this.field.type).label);
  }
  validateMin() {
    let min = this.field.widget.min ? Number(this.field.widget.min) : null;
    const max = this.field.widget.max ? Number(this.field.widget.max) : null;
    if (max !== null && min > max) {
      min = max;
    }
    if (this.field.type === 'int') {
      if (min < -2147483648) {
        min = -2147483648;
      }
    } else if (this.field.type === 'float') {
      if (min < Number(-3.4e38)) {
        min = Number(-3.4e38);
      }
    } else {
      if (min < Number(-1.79e308)) {
        min = Number(-1.79e308);
      }
    }
    this.updateField({
      key: 'widget.min',
      value: min || null,
    });
  }
  validateMax() {
    const min = this.field.widget.min ? Number(this.field.widget.min) : null;
    let max = this.field.widget.max ? Number(this.field.widget.max) : null;
    if (min !== null && min > max) {
      max = min;
    }
    if (this.field.type === 'int') {
      if (max > 2147483648) {
        max = 2147483648;
      }
    } else if (this.field.type === 'float') {
      if (max > Number(3.4e38)) {
        max = Number(3.4e38);
      }
    } else {
      if (max > Number(1.79e308)) {
        max = Number(1.79e308);
      }
    }
    this.updateField({
      key: 'widget.max',
      value: max || null,
    });
  }
  validateDefault() {
    const min = this.field.widget.min ? Number(this.field.widget.min) : null;
    const max = this.field.widget.max ? Number(this.field.widget.max) : null;
    let defaultVal = this.field.widget.defaultVal
      ? Number(this.field.widget.defaultVal)
      : null;
    if (min && defaultVal < min) {
      defaultVal = min;
    }
    if (max && defaultVal > max) {
      defaultVal = max;
    }
    this.updateField({
      key: 'widget.defaultVal',
      value: defaultVal || null,
    });
  }
}
</script>

<style lang="less" module>
.range {
  :global {
    .ant-input-affix-wrapper {
      width: 132px;
      &:last-child {
        margin-right: 0;
      }
      .ant-input {
        padding-right: 11px;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
      .anticon-close-circle {
        display: none;
      }

      &:hover .ant-input {
        padding-right: 30px;
      }
      &:hover .anticon-close-circle {
        display: inline-block;
      }
    }
  }
}
</style>
