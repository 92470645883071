<template>
  <div>
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-form-model-item
        :label="$t('connector.accessPlatform.platformName')"
        prop="platformName"
      >
        <a-input
          :placeholder="$t('connector.accessPlatform.inputPlatformName')"
          :maxLength="32"
          v-model="form.platformName"
        />
      </a-form-model-item>
      <a-form-model-item prop="identifier">
        <span slot="label">
          {{ $t('connector.accessPlatform.platformIdentity') }}
          <a-tooltip
            :title="$t('connector.accessPlatform.signTips')"
            placement="right"
            :overlayStyle="{ maxWidth: '100%' }"
          >
            <a-icon type="info-circle" />
          </a-tooltip>
        </span>
        <a-input
          :placeholder="$t('connector.accessPlatform.inputSign')"
          :maxLength="16"
          :disabled="operationFlag !== 'add'"
          v-model="form.identifier"
        />
      </a-form-model-item>
      <a-form-model-item prop="logoUrl">
        <span slot="label">
          {{ $t('connector.accessPlatform.headPicture') }}
          <a-tooltip
            :title="$t('connector.accessPlatform.imageTips')"
            placement="right"
            :overlayStyle="{ maxWidth: '100%' }"
          >
            <a-icon type="info-circle" />
          </a-tooltip>
        </span>
        <images-upload v-model="form.logoUrl" style="height: 120px" />
      </a-form-model-item>
      <a-form-model-item prop="introduction">
        <span slot="label">
          {{ $t('connector.accessPlatform.IntroductionOfPlatform') }}
          <span
            style="margin-left: 10px"
            :class="$style.link"
            @click="viewLink"
          >
            {{
              linkDivShow
                ? $t('connector.accessPlatform.back')
                : $t('common.action.preview')
            }}
          </span>
          <span :class="$style.link" @click="superLink">
            {{ $t('connector.accessPlatform.hyperlink') }}
          </span>
        </span>
        <div v-show="linkDivShow" ref="linkDiv" :class="$style.linkDiv"></div>
        <a-input
          v-model="form.introduction"
          v-show="!linkDivShow"
          ref="platformAbout1"
          :placeholder="$t('connector.accessPlatform.platformAbout')"
          type="textarea"
          :autoSize="{ minRows: 4, maxRows: 6 }"
        />
        <!--        <div id="年薪百万"></div>-->
      </a-form-model-item>
      <a-form-model-item prop="cloudServiceMode">
        <span slot="label">
          {{ $t('connector.accessPlatform.platformModel') }}
          <span style="color: var(--font-info)">
            （{{ $t('connector.accessPlatform.modelTips') }}）
          </span>
        </span>
        <a-radio-group
          v-model="form.cloudServiceMode"
          :options="modeOptions"
          @change="modeChange"
        />
      </a-form-model-item>
    </a-form-model>
    <div :class="$style.btnGroup">
      <a-button
        v-if="getAuth"
        :class="[$style.btn, $style.btnThrottle]"
        type="primary"
        @click="submit"
      >
        {{ $t('connector.accessPlatform.save') }}
      </a-button>
      <a-button
        v-if="$p.action('UPDATE')"
        type="primary"
        :class="$style.btnThrottle"
        style="background-color:var(--primary-80);border-color:var(--primary-80)"
        @click="toDevPage"
      >
        {{ $t('connector.accessPlatform.enterDev') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ImagesUpload from './images-upload.vue';
import LinkForm from './link-form';
import {
  addPlatform,
  checkIdentifier,
  editPlatform,
} from '@/services/iot-platform/platform';
import { UploadService } from '@triascloud/services';
import { deepClone } from '@triascloud/utils';
import { createFormModal } from '@triascloud/x-components';

/** @name 为超链接添加http */
const hasLink = (link = '') => {
  // egg: //www.baidu.com
  // egg: www.baidu.com
  if (link.indexOf('http') === -1) {
    let result = link.match(
      /((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/,
    );
    return `http://${result[0]}`;
  } else {
    return link;
  }
};

/** @name 超链接markdown语法转换 */
export const markedHref = (str = '') =>
  str.replace(/\[[^[]*?]\(.[^(]*?\)/g, s => {
    let arr = s.split('](');
    let href = arr[1].substring(0, arr[1].length - 1);
    href = hasLink(href);
    return `<a href="${href}" target="_blank">${arr[0].substring(1)}</a>`;
  });

@Component({
  components: {
    ImagesUpload,
    LinkForm,
  },
})
export default class PlatformForm extends Vue {
  @Prop({ type: Function, default: () => {} }) setDevelopFlag;
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: 'add' }) operationFlag;
  form = {
    platformName: '',
    identifier: '',
    introduction: '',
    cloudServiceMode: 'PUBLIC',
    logoUrl: [],
  };
  modeOptions = [
    { label: this.$t('connector.accessPlatform.publicCloud'), value: 'PUBLIC' },
    {
      label: this.$t('connector.accessPlatform.privateCloud'),
      value: 'PRIVATE',
      disabled: true,
    },
    {
      label: this.$t('connector.accessPlatform.mixedCloud'),
      value: 'MIX',
      disabled: true,
    },
  ];
  modeChange(e) {
    this.form.cloudServiceMode = e.target.value;
  }
  mounted() {
    this.init();
  }
  get getAuth() {
    return this.operationFlag === 'add'
      ? this.$p.action('CREATE')
      : this.$p.action('UPDATE');
  }
  async init() {
    const editData = this.editData;
    if (this.operationFlag === 'edit') {
      this.platFormId = editData.platFormId;
      this.form.platformName = editData.platformName;
      this.form.identifier = editData.identifier;
      this.form.introduction = editData.introduction;
      this.form.cloudServiceMode = editData.cloudServiceMode;
      const basePath = '/oss/oss';
      const customService = new UploadService(basePath);
      const abPath = await customService.getAuth(editData.logoUrl);
      this.form.logoUrl = [
        {
          uid: 1,
          name: 'picture',
          status: 'done',
          url: abPath,
          ossPath: editData.logoUrl,
        },
      ];
    }
  }
  @Watch('editData', { immediate: true, deep: true })
  changeData(newVal, oldVal) {
    this.editData = newVal;
    if (newVal !== oldVal) {
      this.modeOptions[0].disabled =
        (newVal.operatingStatus && newVal.operatingStatus !== 'DEVELOP') ||
        false;
    }
  }
  rules = {
    platformName: [
      {
        required: true,
        message: this.$t('connector.accessPlatform.platformEmpty'),
      },
    ],
    identifier: [
      {
        type: 'string',
        required: true,
        validator: this.checkSign,
        trigger: 'blur',
      },
    ],
    logoUrl: [
      {
        required: true,
        message: this.$t('connector.accessPlatform.imageEmpty'),
      },
    ],
    introduction: [
      {
        required: true,
        message: this.$t('connector.accessPlatform.abortEmpty'),
        trigger: ['change', 'blur'],
      },
    ],
  };
  async checkSign(rule, value, callback) {
    const reg = /^[a-zA-Z]+$/;
    if (value === '') {
      const str = this.$t('connector.accessPlatform.signEmpty');
      callback(new Error(str));
    } else if (!reg.test(value)) {
      const str = this.$t('connector.accessPlatform.chartsSupport');
      callback(new Error(str));
    } else {
      const params = {
        identifier: value,
      };
      if (!this.firstClick) {
        params.platFormId = this.platFormId;
      } else {
        params.platFormId =
          this.operationFlag === 'edit' ? this.editData.platFormId : undefined;
      }
      const res = await checkIdentifier(params);
      if (res) {
        callback();
      } else {
        const str = this.$t('connector.accessPlatform.identifyHas');
        callback(new Error(str));
      }
    }
  }
  linkDivShow = false;
  viewLink() {
    if (this.linkDivShow || this.form.introduction === '') {
      this.linkDivShow = false;
      return;
    }
    this.linkDivShow = true;
    this.$nextTick(() => {
      const div = this.$refs.linkDiv;
      const str = this.form.introduction;
      div.innerHTML = markedHref(str);
    });
  }
  async superLink() {
    try {
      const model = await createFormModal(() => <LinkForm />, {
        width: '450px',
        title: this.$t('connector.accessPlatform.addHyperlink'),
      });
      if (model) {
        const area = this.$refs.platformAbout1.$el;
        const start = area.selectionStart;
        const showText = model.showText;
        const url = model.url;
        // const end = area.selectionEnd;
        const val = this.form.introduction;
        const valArr = val.split('');
        // const bracketArr = ['[', ']', '(', ')'];
        // let flag = true;
        // 下标开始的位置是开头或者结尾，直接插入
        if (!(start === 0 || start === valArr.length)) {
          const left = valArr.slice(0, start);
          const right = valArr.slice(start, valArr.length);
          const rl = this.hasBracket(left, 'left');
          const rr = this.hasBracket(right, 'right');
          // )
          if (rl.cr) {
            // ( [  或者没有括号
            if (rr.cl || rr.sl || (!rr.cl && !rr.cr && !rr.sl && !rr.sr)) {
              this.form.introduction =
                left.join('') + `[${showText}](${url})` + right.join('');
            }
          } else if (!rl.cl && !rl.cr && !rl.sl && !rl.sr) {
            // 左侧没有 括号
            this.form.introduction =
              left.join('') + `[${showText}](${url})` + right.join('');
          } else if ((rl.sr && rl.sri !== 0) || (rr.cl && rr.cli !== 0)) {
            // [] 这里的情况 ()
            this.form.introduction =
              left.join('') + `[${showText}](${url})` + right.join('');
          } else {
            const str = this.$t('connector.accessPlatform.notHyperlink');
            this.$message.error(str);
          }
        } else {
          // 直接插入
          if (start === 0) {
            this.form.introduction =
              `[${showText}](${url})` + this.form.introduction;
          } else if (start === valArr.length) {
            this.form.introduction =
              this.form.introduction + `[${showText}](${url})`;
          }
        }
      }
    } catch {
      return false;
    }
  }

  /**
   * 判断是否有括号
   * @param arr 需要判断的数组
   * @param flag 左侧还是右侧
   * @returns {boolean} 是否有括号
   */
  hasBracket(arr, flag) {
    const bracketArr = ['[', ']', '(', ')'];
    let a = deepClone(arr);
    if (flag === 'left') {
      a = a.reverse();
    }
    let result = {
      cl: false, //（
      cli: 0, // （ 在遍历的一侧字符串的第几个
      cr: false, // )
      cri: 0,
      sl: false, // [
      sli: 0,
      sr: false, // ]
      sri: 0,
    };
    for (let i = 0; i < arr.length; i++) {
      if (bracketArr.indexOf(a[i]) > -1) {
        if (a[i] === '(') {
          result.cl = true;
          result.cli = i;
          break;
        } else if (a[i] === ')') {
          result.cr = true;
          result.cri = i;
          break;
        } else if (a[i] === '[') {
          result.sl = true;
          result.sli = i;
          break;
        } else {
          result.sr = true;
          result.sri = i;
          break;
        }
      }
    }
    return result;
  }
  cancleModal() {
    this.$emit('close');
  }
  platFormId = '';
  firstClick = true;

  async submit(n = 0) {
    await this.$refs.form.validate();
    try {
      const params = {
        platformName: this.form.platformName,
        identifier: this.form.identifier,
        introduction: this.form.introduction,
        logoUrl: this.form.logoUrl[0].ossPath,
        cloudServiceMode: this.form.cloudServiceMode,
      };
      if (!this.firstClick) {
        params.platFormId = this.platFormId;
      } else {
        params.platFormId =
          this.operationFlag === 'edit' ? this.editData.platFormId : undefined;
      }
      if (this.operationFlag === 'add') {
        this.platFormId = await addPlatform(params);
        this.firstClick = false;
        this.$message.success(this.$t('common.tips.add'));
      } else {
        await editPlatform(params);
        if (n === 0) {
          this.$message.success(this.$t('common.tips.save'));
        }
      }
      this.cancleModal();
    } catch {
      return false;
    }
  }
  async toDevPage() {
    await this.submit(1);
    this.$emit('toDevPage', this.platFormId);
  }
}
</script>

<style lang="less" module>
@keyframes throttle-button {
  from {
    pointer-events: none;
  }
  to {
    pointer-events: all;
  }
}
.btnThrottle {
  animation: throttle-button 2s step-end forwards;
  &:active {
    animation: none;
  }
}
.link {
  color: var(--primary);
  cursor: pointer;
  float: right;
}
.linkDiv {
  height: 94px;
  overflow: auto;
  border: 1px solid var(--form-border);
  max-height: 136px;
  border-radius: 4px;
  padding: 4px 11px;
  margin-bottom: 4px;
}
.btnGroup {
  margin-top: 20px;
  text-align: end;
  .btn {
    margin-right: 15px;
  }
}
.linkAbout {
  color: var(--primary);
  cursor: pointer;
}
:global {
  .dark {
    .w-e-bar,
    .w-e-bar-item .disabled:hover,
    .w-e-bar-item button:hover {
      background-color: var(--body-bg);
      color: var(--font);
    }
    .w-e-bar-item button {
      color: var(--font);
    }
  }
}
</style>
