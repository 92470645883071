<template>
  <div :class="$style.index">
    <x-data-table
      ref="table"
      base-path="/template-manager/secondary/node/statistics/enterprise/details/of/secondary/nodes"
      :columns="columns"
      :service="service"
      :class="$style.table"
    >
      <template slot="action"></template>
      <div slot="headerLeft" :class="$style.title">
        {{ $t('plugin.snms.secondaryDetails') }}
      </div>
      <div slot="footer"></div>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { nodeDetail } from '@/services/template-manager/secondary';
import { DataSourceService } from '@triascloud/services';
import { InstalledPluginStatus } from '@/enum/plugin';

@Component
export default class NodeDetailsTable extends Vue {
  @Prop({ type: String, default: '' }) classId;
  get columns() {
    return [
      {
        title: this.$t('plugin.snms.enterprisePrefix'),
        dataIndex: 'prefix',
        key: 'prefix',
      },
      {
        key: 'prefixStatus',
        title: this.$t('plugin.snms.prefixStatus'),
        dataIndex: 'prefixStatus',
      },
      {
        title: this.$t('inviteCode.companyName'),
        dataIndex: 'enterpriseName',
        key: 'enterpriseName',
      },
      {
        title: this.$t('plugin.snms.numberForms'),
        dataIndex: 'formCount',
        key: 'formCount',
        width: 140,
      },
      {
        title: this.$t('plugin.snms.secondaryNode'),
        dataIndex: 'enterpriseStatusOfSecondaryNodes',
        customRender: status =>
          status === InstalledPluginStatus.Open
            ? this.$t('website.homePage.open')
            : this.$t('finance.order.close'),
        width: 130,
      },
      {
        title: this.$t('market.statistics.numberTemplates'),
        dataIndex: 'templateCount',
        key: 'templateCount',
      },
      {
        title: this.$t('plugin.snms.IdNumber'),
        dataIndex: 'identificationNumber',
      },
      {
        title: this.$t('plugin.snms.parsingQuantity'),
        dataIndex: 'analyticNumber',
      },
      {
        title: this.$t('plugin.snms.validityPeriod'),
        dataIndex: 'periodOfValidity',
      },
      // {
      //   title: '操作',
      //   dataIndex: 'action',
      //   customRender: () => {
      //     return (
      //       <span
      //         onClick={e => {
      //           e.stopPropagation();
      //           this.stopOff();
      //         }}
      //       >
      //         停用
      //       </span>
      //     );
      //   },
      // },
    ].map(item => ({
      ...item,
      width: item.width || 130,
      sorter: false,
      filter: false,
      align: 'left',
      ellipsis: false,
    }));
  }

  stopOff() {}

  total = 0;
  get service() {
    const service = new DataSourceService(
      '/template-manager/secondary/node/statistics/enterprise/details/of/secondary/nodes',
    );
    service.getList = () =>
      nodeDetail().then(res => {
        return {
          data: res,
        };
      });
    return service;
  }
}
</script>
<style lang="less" module>
.index {
  padding: 20px;
  width: 100%;
  height: 640px;
  box-shadow: 0px 0px 8px 0px var(--shadow);
}
.action {
  cursor: pointer;
  color: var(--font-active);
  &.action--delete {
    color: var(--delete);
    margin-left: 20px;
  }
}
.title {
  color: var(--font);
  font-weight: bold;
}
</style>
