var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',{class:_vm.$style.collapse,attrs:{"tag":"ul","value":_vm.data,"handle":".drag-item","animation":300,"group":{ name: ("tab-" + _vm.configsKey) },"move":_vm.onMove},on:{"input":_vm.handleInput}},_vm._l((_vm.data),function(item,index){
var _obj, _obj$1;
return _c('a-collapse',{key:index,attrs:{"bordered":_vm.bordered,"accordion":""}},[_c('a-collapse-panel',{class:_vm.$style.collapse,style:(_vm.customStyle),attrs:{"header":item.title}},[(!_vm.view)?_c('template',{slot:"extra"},[(_vm.data.length > 1)?_c('x-icon',{attrs:{"type":"tc-icon-delete","tooltip":_vm.$t('website.setting.removeNavigation')},on:{"click":function($event){$event.stopPropagation();return _vm.removeGuide(item)}}}):_vm._e(),_c('x-icon',{staticClass:"drag-item",staticStyle:{"margin-left":"10px","cursor":"move"},attrs:{"type":"tc-icon-drag-handler"}})],1):_vm._e(),_c('a-form-model-item',{class:_vm.$style.itemCol,attrs:{"label":_vm.$t('common.title'),"prop":!_vm.bordered
            ? ("config.data." + index + ".title")
            : ("config.data." + _vm.i + ".children." + index + ".title"),"rules":[
          {
            min: 2,
            max: 10,
            message: _vm.$t('website.setting.wordTip'),
            trigger: 'blur',
          },
          {
            required: true,
            trigger: 'blur',
            message: _vm.$t('solution.tip1'),
          } ]}},[_c('a-input',{staticStyle:{"width":"380px"},attrs:{"disabled":_vm.view},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1),(!_vm.bordered)?_c('a-form-model-item',{class:_vm.$style.itemCol,attrs:{"label":_vm.$t('website.setting.navigationIcon'),"prop":("config.data." + index + ".title"),"rules":[
          {
            required: true,
            trigger: 'blur',
            message: _vm.$t('iconLibrary.icon.tips.selectIcon'),
          } ]}},[_c('x-icon-library',{class:_vm.$style.libraryIcon,attrs:{"disabled":_vm.view},model:{value:(item.icon),callback:function ($$v) {_vm.$set(item, "icon", $$v)},expression:"item.icon"}},[_c('div',{attrs:{"slot":"block"},slot:"block"},[(item.icon)?_c('x-custom-icon',{class:_vm.$style.guideIcon,attrs:{"type":item.icon}}):_c('div',{class:_vm.$style.chooseIcon},[_c('x-icon',{attrs:{"type":"plus-circle"}})],1)],1)])],1):_vm._e(),_vm._t("content",null,{"data":Object.assign({}, item, {i: index})}),_c('a-form-model-item',{ref:"imageForm",refInFor:true,class:( _obj = {}, _obj[_vm.$style.itemCol] = true, _obj[_vm.$style.itemColMg] = !_vm.bordered, _obj ),attrs:{"label":_vm.$t('website.setting.navigationMenu'),"prop":_vm.bordered ? undefined : ("config.data." + index + ".bg"),"rules":_vm.bordered
            ? undefined
            : [
                {
                  message: _vm.$t('solution.selectPicture'),
                  required: true,
                } ]}},[_c('x-upload-button',{class:_vm.$style.uploadBox,attrs:{"uploadPath":"/oss/backend","maxFileSize":5,"disabled":_vm.view},on:{"success":function (res) {
              //   $refs.imageForm.FormContext.clearValidate(
              //     `config.data.${scope.data.i}.children.${scope.data.i}.bg`,
              //   );
              item.bg = res.ossData.ossPath;
              _vm.$emit('input', Object.assign({}, item,
                {bg: res.ossData.ossPath}));
            }}},[_c('div',{class:_vm.$style.guideBg},[(!item.bg)?_c('div',{class:_vm.$style.addIcon},[_c('x-icon',{attrs:{"type":"tc-icon-image-download"}}),_vm._v(" "+_vm._s(_vm.$t('solution.addPictures'))+" ")],1):_c('x-oss-image',{class:_vm.$style.image__img,attrs:{"oss-path":item.bg,"size":100}})],1)])],1),_c('a-form-model-item',{ref:"imageForm",refInFor:true,class:( _obj$1 = {}, _obj$1[_vm.$style.itemCol] = true, _obj$1[_vm.$style.itemColMg] = !_vm.bordered, _obj$1 ),attrs:{"label":_vm.$t('website.setting.navigationMenuDark'),"prop":_vm.bordered ? undefined : ("config.data." + index + ".bgDark"),"rules":_vm.bordered
            ? undefined
            : [
                {
                  message: _vm.$t('solution.selectPicture'),
                  required: true,
                } ]}},[_c('x-upload-button',{class:_vm.$style.uploadBox,attrs:{"uploadPath":"/oss/backend","maxFileSize":5,"disabled":_vm.view},on:{"success":function (res) {
              //   $refs.imageForm.FormContext.clearValidate(
              //     `config.data.${scope.data.i}.children.${scope.data.i}.bg`,
              //   );
              item.bgDark = res.ossData.ossPath;
              _vm.$emit('input', Object.assign({}, item,
                {bgDark: res.ossData.ossPath}));
            }}},[_c('div',{class:_vm.$style.guideBg},[(!item.bgDark)?_c('div',{class:_vm.$style.addIcon},[_c('x-icon',{attrs:{"type":"tc-icon-image-download"}}),_vm._v(" "+_vm._s(_vm.$t('solution.addPictures'))+" ")],1):_c('x-oss-image',{class:_vm.$style.image__img,attrs:{"oss-path":item.bgDark,"size":100}})],1)])],1),_vm._t("default",null,{"data":Object.assign({}, item, {i: index})}),_c('navigate-item-children',{attrs:{"data":item.children,"bordered":true,"view":_vm.view,"i":index},on:{"remove":_vm.removeGuide,"dragGuide":function (data) { return (item.children = data); }},scopedSlots:_vm._u([{key:"content",fn:function(scope){return [_c('a-form-model-item',{class:_vm.$style.itemCol,attrs:{"label":_vm.$t('website.setting.content'),"prop":("config.data." + index + ".children." + (scope.data.i) + ".desc"),"rules":[
              {
                min: 2,
                max: 150,
                message: _vm.$t('record.wordLimit', { value: 150 }),
                trigger: 'blur',
              },
              {
                required: true,
                trigger: 'blur',
                message: _vm.$t('website.setting.enterContent'),
              } ]}},[_c('a-input',{staticStyle:{"width":"380px"},attrs:{"disabled":_vm.view},model:{value:(item.children[scope.data.i].desc),callback:function ($$v) {_vm.$set(item.children[scope.data.i], "desc", $$v)},expression:"item.children[scope.data.i].desc"}})],1)]}},{key:"default",fn:function(scope){return [_c('a-form-model-item',{ref:"imageForm",refInFor:true,class:_vm.$style.itemCol,attrs:{"label":_vm.$t('website.setting.submenuVideos'),"prop":("config.data." + index + ".children." + (scope.data.i) + ".media"),"rules":[
              {
                required: true,
                trigger: 'blur',
                message: _vm.$t('website.setting.uploadVideo'),
              } ]}},[_c('x-upload',{key:'media',attrs:{"max":1,"uploadPath":_vm.UploadPath,"mode":"summary","type":"file","accept":"image/*,video/*","maxFileSize":200},model:{value:(item.children[scope.data.i].media),callback:function ($$v) {_vm.$set(item.children[scope.data.i], "media", $$v)},expression:"item.children[scope.data.i].media"}})],1),_c('a-form-model-item',{class:_vm.$style.itemCol,attrs:{"label":_vm.$t('website.setting.submenu')}},[_c('a-radio-group',{attrs:{"disabled":_vm.view},model:{value:(item.children[scope.data.i].showButton),callback:function ($$v) {_vm.$set(item.children[scope.data.i], "showButton", $$v)},expression:"item.children[scope.data.i].showButton"}},[_c('a-radio',{attrs:{"value":"NO"}},[_vm._v(" "+_vm._s(_vm.$t('help.nothing'))+" ")]),_c('a-radio',{attrs:{"value":"YES"}},[_vm._v(" "+_vm._s(_vm.$t('help.have'))+" ")])],1)],1),(item.children[scope.data.i].showButton === 'YES')?_c('div',{class:_vm.$style.itemRow},[_c('a-form-model-item',{class:_vm.$style.itemCol,attrs:{"label":_vm.$t('website.setting.buttonName'),"prop":("config.data." + index + ".children." + (scope.data.i) + ".buttonText"),"rules":[
                {
                  min: 2,
                  max: 30,
                  message: _vm.$t('common.tips.wordTip'),
                  trigger: 'blur',
                },
                {
                  required: true,
                  trigger: 'blur',
                  message: _vm.$t('website.setting.enterName'),
                } ]}},[_c('a-input',{attrs:{"disabled":_vm.view},model:{value:(item.children[scope.data.i].buttonText),callback:function ($$v) {_vm.$set(item.children[scope.data.i], "buttonText", $$v)},expression:"item.children[scope.data.i].buttonText"}})],1),_c('a-form-model-item',{class:_vm.$style.itemCol,attrs:{"label":"URL","prop":("config.data." + index + ".children." + (scope.data.i) + ".route"),"rules":[
                {
                  required: true,
                  trigger: 'blur',
                  message: _vm.$t('website.setting.enterUrl'),
                } ]}},[_c('a-input',{attrs:{"disabled":_vm.view},model:{value:(item.children[scope.data.i].route),callback:function ($$v) {_vm.$set(item.children[scope.data.i], "route", $$v)},expression:"item.children[scope.data.i].route"}})],1)],1):_vm._e()]}}],null,true)}),(!_vm.bordered && item.children.length <= 8 && !_vm.view)?_c('a-button',{attrs:{"icon":"plus-circle"},on:{"click":function($event){return _vm.addSubGuide(item)}}},[_vm._v(_vm._s(_vm.$t('website.setting.addSubnavigation')))]):_vm._e()],2)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }