<template>
  <div :class="$style.overViewCtrl">
    <div :class="$style.overView" v-for="(item, idx) in data" :key="idx">
      <div :class="$style.title">{{ item.title }}</div>
      <div :class="$style.links" @click="routeTo(item.link)">
        {{ $t('business.seeMore') }}
      </div>
      <div v-if="item.rankVOS.length">
        <charts
          :data="item"
          :class="$style.chartsCtrl"
          :ref="'overViewCharts' + idx"
        ></charts>
        <div :class="$style.list">
          <div>
            {{ $t('plugin.overview.listTit') }}
          </div>
          <div :class="$style.listCtrl">
            <div
              :class="$style.item"
              v-for="(iitem, iidx) in item.rankVOS"
              :key="iidx"
            >
              <span :class="$style.count">{{ iidx + 1 }}</span>
              <span :class="$style.title">
                {{ iitem.tenantName }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.chartsCtrl" v-else>
        <empty-content></empty-content>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import Charts from './charts.vue';
import EmptyContent from '@/components/empty-content/empty-content.vue';
@Component({
  components: { EmptyContent, Charts },
})
export default class ChartsView extends Vue {
  @Prop({ type: Array, default: () => [] }) data;

  routeTo(link) {
    this.$router.push(link);
  }
}
</script>
<style lang="less" module>
.overViewCtrl {
  .overView {
    width: 100%;
    position: relative;
    box-shadow: 0px 0px 8px 0px #e1e1e1;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }

  .title {
    font-weight: 500;
  }

  .links {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .chartsCtrl {
    display: inline-block;
    height: 400px;
    max-width: 1400px;
    width: 100%;
  }
  .list {
    display: inline-block;
    vertical-align: top;
    width: 170px;
    padding-left: 40px;
    padding-top: 20px;

    .listCtrl {
      padding-top: 20px;
    }

    .item {
      line-height: 20px;
      font-size: 14px;
      padding-bottom: 15px;
      .count {
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        padding-right: 10px;
      }
      .title {
        display: inline-block;
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 16px;
        vertical-align: middle;
      }
    }
  }
}
</style>
