<template>
  <div
    :class="$style.enterpriseItem"
    :style="{ border: isShowTitle ? '1px solid var(--border)' : 'none' }"
  >
    <div :class="$style.header" v-if="isShowTitle">
      {{ $t('enterprise.enterpriseText') }}{{ `${this.current}` }}
    </div>

    <div :class="$style.content">
      <div :class="$style.infoLeft">
        <ul :class="$style.infoItem">
          <li>
            <!-- 姓名 -->
            <span :class="$style.label">{{ $t('activity.names') }}: </span>
            <span :class="$style.items">{{ itemInfo.memberName || '-' }}</span>
          </li>
          <li>
            <!-- 职位 -->
            <span :class="$style.label">{{ $t('business.position') }}: </span>
            <span :class="$style.items">{{ itemInfo.position || '-' }}</span>
          </li>
          <li>
            <!-- 注册时间 -->
            <span :class="$style.label">
              {{ $t('inviteCode.registrationTime') }}:
            </span>
            <span :class="$style.items">
              {{
                itemInfo.registerTime !== ''
                  ? $moment(itemInfo.registerTime).format('YYYY-MM-DD')
                  : '-'
              }}
            </span>
          </li>
          <li>
            <!-- 是否认证企业 -->
            <span :class="$style.label">
              {{ $t('business.isCertifiedEnterprise') }}:
            </span>
            <span :class="$style.items">
              {{ certifiedEnterpriseText(itemInfo.isCertifiedEnterprise) }}
            </span>
          </li>
        </ul>
      </div>
      <div :class="$style.infoRight">
        <ul :class="$style.infoItem">
          <li>
            <!-- 公司名称 -->
            <span :class="$style.label">
              {{ $t('website.homePage.companyName') }}:
            </span>
            <span :class="$style.items">{{ itemInfo.tenantName || '-' }}</span>
          </li>
          <li>
            <!-- 手机号 -->
            <span :class="$style.label">
              {{ $t('activity.phoneNumber') }}:
            </span>
            <span :class="$style.items">{{ itemInfo.phone || '-' }}</span>
          </li>
          <li>
            <!-- 认证企业时间 -->
            <span :class="$style.label">
              {{ $t('business.enterpriseCertificationTime') }}:
            </span>
            <span :class="$style.items">
              {{
                itemInfo.certifiedTime !== ''
                  ? $moment(itemInfo.certifiedTime).format('YYYY-MM-DD')
                  : '-'
              }}
            </span>
          </li>
          <li>
            <!-- 企业成员数 -->
            <span :class="$style.label">
              {{ $t('business.enterpriseMembers') }}:
            </span>
            <span :class="$style.items">{{ itemInfo.memberCount || '-' }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/** 更多企业列表元素组件 */

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class EnterpriseItem extends Vue {
  @Prop({ type: Boolean, default: true }) isShowTitle;
  /** 每条记录的信息 */
  @Prop({ type: Object, default: () => null }) itemInfo;
  @Prop({ type: Number, default: () => 1 }) current;

  certifiedEnterpriseText(status) {
    switch (status) {
      case 1:
        return this.$t('template.review.checked');
      case 0:
        return this.$t('template.review.unChecked');
      default:
        return this.$t('common.label.all');
    }
  }
}
</script>

<style lang="less" module>
.enterpriseItem {
  width: 100%;
  border-radius: 4px;
  .header {
    border-bottom: 1px solid var(--border);
    color: var(--font);
    height: 34px;
    line-height: 34px;
    width: 100%;
    padding: 0 10px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    padding-bottom: 10px;
    .infoLeft {
      width: 49%;
      .infoItem {
        width: 100%;
        li {
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          .label {
            color: var(--font-info);
            min-width: 112px;
            text-align: right;
          }
          .items {
            color: var(--font);
            margin-left: 10px;
          }
        }
      }
    }
    .infoRight {
      width: 49%;
      .infoItem {
        width: 100%;
        li {
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          .label {
            color: var(--font-info);
            min-width: 112px;
            text-align: right;
          }
          .items {
            color: var(--font);
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
