<template>
  <div :class="$style.wrap">
    <a-tabs
      :activeKey="activeTab"
      @change="handleTabChange"
      :animated="false"
      :class="$style.tab"
    >
      <a-tab-pane v-for="item in classify" :key="item.pkId" :tab="item.name">
        <div :class="$style.classify">
          <div :class="$style.classifyBlock">
            <div
              :class="[
                $style['classify-item'],
                { [$style.active]: activeClassify === item.pkId },
              ]"
              @click="activeClassify = item.pkId"
            >
              {{ $t('common.label.all') }}
            </div>
          </div>
          <div
            :class="$style.classifyBlock"
            v-for="child in item.childList"
            :key="child.pkId"
          >
            <div
              :class="[
                $style['classify-item'],
                { [$style.active]: activeClassify === child.pkId },
              ]"
              @click="activeClassify = child.pkId"
            >
              <x-ellipsis :row="1">
                {{ child.name }}
              </x-ellipsis>
            </div>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div :class="$style.right">
      <div v-if="activeTab" :class="$style.toolbar">
        <a-input
          :placeholder="$t('common.action.search')"
          :class="$style['toolbar-search']"
          @keyup.enter="searchHandler"
          @blur="searchHandler"
        >
          <x-icon slot="prefix" type="tc-icon-search" />
        </a-input>
        <div :class="$style.actions">
          <a-button
            type="primary"
            :class="$style['toolbar-button']"
            @click="handleHotRecommendDrawer"
          >
            <x-icon type="setting" />
            {{ $t('market.template.hotRecommend') }}
          </a-button>
          <a-button
            type="primary"
            :class="$style['toolbar-button']"
            @click="handleTemplateBannerDrawer"
          >
            <x-icon type="setting" />
            {{ $t('market.template.banner.index') }}
          </a-button>
          <a-button
            v-if="$p.action('UPDATE')"
            type="primary"
            :class="$style['toolbar-button']"
            @click="installManage"
          >
            <x-icon type="setting" />
            {{ $t('market.template.virtualManagement') }}
          </a-button>
          <a-button
            type="primary"
            :class="$style['toolbar-button']"
            @click="manageTemplate"
          >
            <x-icon type="setting" />
            {{ $t('market.template.appManagement') }}
          </a-button>
          <a-button
            v-if="$p.action('RECYCLE-BIN')"
            :class="$style['toolbar-button']"
            @click="recycle"
          >
            <x-icon type="tc-icon-delete" />
            {{ $t('gallery.actions.recycling') }}
          </a-button>
          <div v-if="activeTab" :class="$style.controllers">
            <template v-if="$p.action('CLASSIFY')">
              <a-tooltip :title="$t('market.template.classify.manage')"
                ><x-icon
                  :class="$style.controllers__control"
                  type="setting"
                  @click="manageClassify"
                  hover
              /></a-tooltip>
              <a-tooltip :title="$t('market.template.classify.add')">
                <x-icon
                  :class="$style.controllers__control"
                  type="plus-circle"
                  @click="addClassify(false)"
                  hover
                />
              </a-tooltip>
            </template>
          </div>
        </div>
      </div>
      <div :class="$style.content">
        <a-spin :spinning="loading">
          <application-card
            v-for="(item, index) in list"
            :key="item.id"
            :data="item"
            :class="$style.card"
          >
            <a-tooltip
              v-if="$p.action('UPDATE')"
              placement="bottom"
              :title="$t('common.action.edit')"
            >
              <x-icon
                type="edit"
                :class="$style.card__icon"
                @click="edit(item.id)"
              />
            </a-tooltip>
            <a-tooltip
              placement="bottom"
              :title="$t('common.action.preview')"
              @click="preview(item)"
            >
              <x-icon type="tc-icon-search-square" :class="$style.card__icon" />
            </a-tooltip>
            <a-tooltip
              v-if="$p.action('DELETE')"
              placement="bottom"
              :title="$t('common.action.delete')"
            >
              <x-icon
                type="tc-icon-delete"
                :class="[$style.card__icon, $style['card__icon--delete']]"
                @click="remove(item.id, index)"
              />
            </a-tooltip>
            <a-tooltip
              v-if="$p.action('SETUP_DOWN')"
              placement="bottom"
              :title="
                item.state === 1
                  ? $t('common.action.down')
                  : $t('common.action.up')
              "
            >
              <x-icon
                :type="
                  item.state === 1 ? 'tc-icon-off-shelf' : 'tc-icon-shelves'
                "
                :class="$style.card__icon"
                @click="setStatus(item)"
              />
            </a-tooltip>
            <!-- <x-icon
              v-if="$p.action('UPDATE') && activeClassify !== activeTab"
              type="fullscreen"
              :class="[
                $style.card__icon,
                $style.card__dragHandle,
                { [$style['card__dragHandle--disabled']]: !!keyword },
              ]"
            /> -->
          </application-card>
        </a-spin>
        <empty-content v-if="!loading && !list.length" :class="$style.empty" />
      </div>
    </div>
    <a-pagination
      v-if="total"
      v-model="current"
      show-quick-jumper
      show-size-changer
      :page-size-options="pageSizeOptions"
      :class="$style.pagination"
      :total="total"
      @change="handlePagination"
      @showSizeChange="handleShowSizeChange"
    />
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import ApplicationCard from './components/card';
import EmptyContent from '@/components/empty-content';
import AddClassifyModal from './components/add-classify-modal.vue';
import {
  getClassifyTree,
  moveTemplate,
  sortTemplate,
  getTemplateClassify,
} from '@/services/template-manager/tpClassify';
import {
  getTemplateList,
  removeTemplate,
  setStatus,
} from '@/services/template-manager/template';
import ClassifyManageModal from './components/classify-manage-modal.vue';
import { TemplateState, TemplateType } from '@/enum/template';
import ApplicationSettingModal from './components/application-setting-modal.vue';
import TemplateManageModal from './components/template-manage-modal.vue';
import TemplateRecycleDrawer from './components/template-recycle-drawer.vue';
import ApplicationPreviewDrawer from './components/application-preview-drawer.vue';
import { findTree } from '@triascloud/utils';
import ApplicationBanner from './components/banner';
import SelectClassifyModal from './components/select-classify-modal.vue';
import InstallTimeSetting from './components/install-time-setting.vue';
import HotRecommendDrawer from './components/hot-recommend-drawer.vue';
import TemplateBannerDrawer from './components/template-banner-drawer.vue';
const HOT_CLASSIFY_TYPE = 'HOT';
@Component({
  components: { ApplicationCard, EmptyContent, ApplicationBanner },
})
export default class Index extends Vue {
  classify = [];
  activeTab = '';
  activeClassify = '';
  keyword = '';
  pageSizeOptions = ['10', '20', '30', '40', '50'];
  async created() {
    this.classify = await getClassifyTree();
    this.activeTab =
      this.classify && this.classify.length
        ? this.classify[0].pkId
        : HOT_CLASSIFY_TYPE;
    this.initActiveClassify();
  }
  initActiveClassify() {
    this.activeClassify = this.activeTab;
    // if (this.classify && this.classify.length) {
    //   const classify = this.classify.find(item => item.pkId === this.activeTab);
    //   if (classify && classify.childList && classify.childList[0]) {
    //     this.activeClassify = classify.childList[0].pkId;
    //   } else {
    //     this.activeClassify = '';
    //   }
    // } else {
    //   this.activeClassify = '';
    // }
  }
  list = [];
  loading = false;
  current = 1;
  total = 0;
  size = 10;
  @Watch('activeClassify')
  handleUpdateList() {
    this.current = 1;
    this.getTemplateList();
  }
  async getTemplateList() {
    this.loading = true;
    const { data, total } = await getTemplateList({
      classifyId: this.activeClassify,
      keyword: this.keyword || null,
      current: this.current,
      size: this.size,
      pageOpen: true,
    });
    this.list = this.sortTemplate(data || []);
    this.total = total;
    await this.$nextTick();
    this.loading = false;
  }
  sortTemplate(list) {
    if (this.activeClassify === this.activeTab) {
      return list.sort((a, b) => b.checkTime - a.checkTime);
    } else {
      return list.sort((a, b) =>
        a.sort === b.sort ? b.checkTime - a.checkTime : a.sort - b.sort,
      );
    }
  }
  /**
   * @name 拖动排序更新sort字段
   */
  async sort({ newIndex, oldIndex }) {
    if (!this.list.length || newIndex === oldIndex) return;
    this.loading = true;
    const sortItems = [];
    this.list.forEach((item, index) => {
      const sort = index;
      // if (index === item.sort) return;
      item.sort = sort;

      sortItems.push({
        sort,
        templateId: item.id,
        templateType: TemplateType.Application,
        templateClassifyId: this.activeClassify,
      });
    });
    if (sortItems.length) {
      await sortTemplate(sortItems);
    }
    this.loading = false;
  }
  searchHandler(event) {
    const keyword = event.target.value;
    if (keyword !== this.keyword) {
      this.keyword = keyword;
      this.current = 1;
      this.getTemplateList();
    }
  }
  async manageClassify() {
    try {
      await ClassifyManageModal.createModal({
        pkId: this.activeTab,
        name: this.classify.find(item => item.pkId === this.activeTab).name,
      });
    } finally {
      this.classify = await getClassifyTree();
      if (
        !findTree(this.classify, item => item.pkId === this.activeClassify, {
          children: 'childList',
        })
      ) {
        this.initActiveClassify();
      }
    }
  }
  async addClassify() {
    await AddClassifyModal.createModal({ parentId: this.activeTab });
    this.classify = await getClassifyTree();
  }
  handleTabChange(activeKey) {
    this.keyword = '';
    this.activeTab = activeKey;
    this.current = 1;
    this.initActiveClassify();
  }
  async edit(id) {
    await ApplicationSettingModal.createModal({
      templateId: id,
    });
    this.getTemplateList();
  }
  async preview(item) {
    await ApplicationPreviewDrawer.createModal({
      data: {
        ...item,
        templateId: item.id,
      },
    });
  }
  async setStatus(item) {
    const state =
      item.state === TemplateState.Open
        ? TemplateState.Close
        : TemplateState.Open;
    await this.$confirm(
      state === TemplateState.Open
        ? this.$t('market.template.openConfirm', {
            value: this.$t('market.template.application'),
          })
        : this.$t('market.template.closeConfirm', {
            value: this.$t('market.template.application'),
          }),
    );
    await setStatus({
      state,
      templateId: item.id,
      templateType: TemplateType.Application,
    });
    this.$message.success(
      state === TemplateState.Open
        ? this.$t('market.template.openSuccessful')
        : this.$t('market.template.closeSuccessful'),
    );
    item.state = state;
  }
  async remove(id, index) {
    await this.$confirm(this.$t('market.template.confirmDelete'));
    await removeTemplate({
      templateType: TemplateType.Application,
      templateId: id,
    });
    this.list.splice(index, 1);
    this.$message.success(this.$t('common.tips.delete'));
  }
  async manageTemplate() {
    try {
      await TemplateManageModal.createModal({
        classifyId: this.activeClassify,
        all: this.activeClassify === this.activeTab,
      });
    } finally {
      this.current = 1;
      this.getTemplateList();
    }
  }

  async installManage() {
    await InstallTimeSetting.createModal({ classifyId: this.activeClassify });
  }
  recycle() {
    TemplateRecycleDrawer.createDrawer(
      { classifyId: this.activeClassify },
      { freshList: this.handleFreshList },
    );
  }
  handleFreshList() {
    this.current = 1;
    this.getTemplateList();
  }
  async changeClassify(templateId) {
    const originClassify = (await getTemplateClassify(templateId)).map(
      item => ({
        value: item.pkId,
        text: item.name,
      }),
    );
    const value = await SelectClassifyModal.createModal({
      value: originClassify,
      templateType: TemplateType.Application,
    });
    await moveTemplate({
      tempId: templateId,
      tempType: TemplateType.Application,
      newClassId: value.map(item => item.value),
    });
    this.$message.success(this.$t('common.tips.edit'));
    this.getTemplateList();
  }
  async handleHotRecommendDrawer() {
    await HotRecommendDrawer.createModal({ title: '热门推荐' });
  }
  async handleTemplateBannerDrawer() {
    await TemplateBannerDrawer.createModal({ title: '模版banner' });
  }
  handlePagination(current) {
    this.current = current;
    this.getTemplateList();
  }
  handleShowSizeChange(current, pageSize) {
    this.size = pageSize;
    this.current = 1;
    this.getTemplateList();
  }
}
</script>
<style lang="less" module>
.wrap {
  padding: 10px 20px 20px;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}
.tab {
  width: 243px;
  border-right: 1px solid var(--border);
}
.right {
  flex: 1;
  padding: 0 20px;
}
.actions {
  display: flex;
}
.empty {
  flex: 1;
  display: flex;
}
.classify {
  display: flex;
  flex-wrap: wrap;
  .classifyBlock {
    text-align: center;
    width: 46%;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .classify-item {
    padding: 6px 17px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    &.active,
    &:hover {
      background-color: var(--primary);
      color: #fff;
    }
  }
}
.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  .toolbar-search {
    width: 110px;
    & input:focus {
      width: 200px;
    }
  }
  .toolbar-button {
    margin-right: 20px;
  }
}
.controllers {
  min-width: 60px;
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  height: 100%;
  padding-top: 10px;
  .controllers__control {
    cursor: pointer;
  }
}
.card {
  margin: 30px 25px 0 0;
  .card__icon {
    cursor: pointer;
    &:hover {
      color: var(--font-active);
    }
  }
  .card__dragHandle {
    cursor: move;
    &.card__dragHandle--hot {
      margin-left: 25px;
    }
    &.card__dragHandle--disabled {
      cursor: not-allowed;
    }
  }
  .card__icon--delete {
    &:hover {
      color: var(--delete);
    }
  }
}
.pagination {
  position: absolute;
  bottom: 20px;
  right: 30px;
}
.content {
  height: calc(100vh - 250px);
  overflow-y: auto;
  padding-left: 5px;
}
</style>
