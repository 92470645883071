<template>
  <a-row>
    <a-row :gutter="[0, 10]">
      <a-form-model-item label="头图" prop="baseInfoForm.headFigure">
        <image-upload
          v-model="infoForm.headFigure"
          :maxCount="5"
          ossFilePath="productInfo"
          :showCutImg="true"
          :cutImgSize="500"
          :isDisable="disabledFlag"
          style="height: 120px"
        />
      </a-form-model-item>
    </a-row>
    <a-row :gutter="[20, 10]">
      <a-col :span="8">
        <a-form-model-item label="设备名称" prop="baseInfoForm.deviceName">
          <a-input
            :maxLength="100"
            v-model="infoForm.deviceName"
            placeholder="请输入设备名称"
            :disabled="disabledFlag"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="品牌" prop="baseInfoForm.brand">
          <a-input
            :maxLength="32"
            v-model="infoForm.brand"
            :disabled="disabledFlag"
            placeholder="请输入品牌"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="设备价格" prop="baseInfoForm.devicePrice">
          <a-input-number
            style="width: 40%"
            :min="0.0"
            :max="99999999.0"
            :precision="2"
            :disabled="disabledFlag"
            v-model="infoForm.devicePrice"
            placeholder="请输入设备价格"
          />
          <span :class="$style.ml10">元</span>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="[20, 10]">
      <a-col :span="8">
        <a-form-model-item label="单位" prop="baseInfoForm.unit">
          <a-input
            v-model="infoForm.unit"
            :maxLength="4"
            :disabled="disabledFlag"
            placeholder="请输入单位"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="产品型号" prop="baseInfoForm.productType">
          <a-input
            v-model="infoForm.productType"
            :maxLength="200"
            :disabled="disabledFlag"
            placeholder="请输入产品型号"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="库存" prop="baseInfoForm.inventory">
          <a-input-number
            style="width: 40%"
            :min="0"
            :max="99999"
            :disabled="disabledFlag"
            v-model="infoForm.inventory"
            placeholder="请输入库存"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="[20, 10]">
      <a-col :span="8">
        <a-form-model-item label="产品一级分类" prop="baseInfoForm.mainSort">
          <a-select
            placeholder="请选择主分类"
            v-model="infoForm.mainSort"
            allowClear
            :disabled="disabledFlag"
            @change="changeFirst"
          >
            <a-select-option
              v-for="item in fatherData"
              :value="item.pkId"
              :key="item.pkId"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8" v-if="infoForm.mainSort">
        <a-form-model-item label="产品二级分类" prop="baseInfoForm.subSort">
          <a-select
            placeholder="请选择主分类"
            v-model="infoForm.subSort"
            allowClear
            :disabled="disabledFlag"
          >
            <a-select-option
              v-for="item in childrenData"
              :value="item.pkId"
              :key="item.pkId"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-row>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ImageUpload from '@/views/connector/platform-manage/components/images-upload.vue';
import { gatCategoryList } from '@/services/iot-platform/platformDevice';

@Component({
  components: {
    ImageUpload,
  },
})
export default class ProductionInfo extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  infoForm;
  @Prop({ type: Boolean, default: false }) disabledFlag;

  mounted() {
    // this.getCategoryLists();
  }
  @Watch('mainSort', { immediate: true, deep: true })
  changeSort(newVal) {
    if (newVal) {
      this.getCategoryLists(newVal);
    } else {
      this.getCategoryLists(0);
    }
  }

  get mainSort() {
    return this.infoForm.mainSort;
  }

  fatherData = [];
  childrenData = [];

  changeFirst(val) {
    // this.infoForm.mainSort = val;
    this.infoForm.subSort = undefined;
    this.childrenData = [];
    if (val) {
      this.getCategoryLists(val);
    } else {
      this.getCategoryLists(0);
    }
  }

  async getCategoryLists(id = 0) {
    const params = {
      parentId: id === 0 ? id : id,
    };
    try {
      const res = await gatCategoryList(params);
      if (id === 0) {
        this.fatherData = res;
      } else {
        this.childrenData = res;
      }
    } catch {
      return false;
    }
  }
}
</script>
<style lang="less" module>
.btnGroup {
  text-align: right;
  padding-top: 24px;
}
.ml10 {
  margin-left: 10px;
}
</style>
