<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item label="面板名称" prop="name">
      <a-input v-model="form.name" :maxLength="8" />
    </a-form-model-item>
    <a-form-model-item label="面板类型" prop="type">
      <a-select
        v-model="form.type"
        placeholder="请选择"
        :dropdownMenuStyle="{ maxHeight: '130px' }"
        allowClear
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
        <a-select-option value="DEVICE_POSITION">设备定位</a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="计划" prop="source">
      <a-row :gutter="10">
        <a-col :span="12">
          <a-select
            v-model="form.source"
            placeholder="请选择"
            :dropdownMenuStyle="{ maxHeight: '130px' }"
            allowClear
            @change="changePlan"
            :getPopupContainer="triggerNode => triggerNode.parentNode"
          >
            <a-select-option
              v-for="item in planList"
              :value="item.pkId"
              :key="item.pkId"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col :span="12">
          <a-select
            :disabled="!(form.source && activeFlag === 'PASSIVE')"
            v-model="form.topic"
            placeholder="请选择"
            :dropdownMenuStyle="{ maxHeight: '130px' }"
            allowClear
            @change="changeTheme"
            :getPopupContainer="triggerNode => triggerNode.parentNode"
          >
            <a-select-option
              v-for="item in themeObj[form.source]"
              :value="item.pkId"
              :key="item.pkId"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </a-form-model-item>
    <a-form-model-item label="经度" prop="lon">
      <a-select
        v-model="form.lon"
        placeholder="请选择"
        :dropdownMenuStyle="{ maxHeight: '130px' }"
        allowClear
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
        <a-select-option
          v-for="t in panelFieldsObj[form.topic]"
          :value="t.dataIndex"
          :key="t.dataIndex"
          >{{ t.name }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="纬度" prop="lat">
      <a-select
        v-model="form.lat"
        placeholder="请选择"
        :dropdownMenuStyle="{ maxHeight: '130px' }"
        allowClear
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
        <a-select-option
          v-for="t in panelFieldsObj[form.topic]"
          :value="t.dataIndex"
          :key="t.dataIndex"
          >{{ t.name }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="经纬度坐标系" prop="coordinate">
      <a-radio-group v-model="form.coordinate">
        <a-radio value="WGS84">
          WGS84
        </a-radio>
        <a-radio value="GCJ02">
          GCJ02
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item label="轨迹展示" prop="isPreviewLocus">
      <a-switch v-model="form.isPreviewLocus" />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getPlanThemeList } from '@/services/iot-platform/dataManage';
import { fetchPlan } from './event-setting.vue';
@Component()
export default class DeviceLocationForm extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: Object }) detail;

  async mounted() {
    await this.getPlans();
  }

  form = {
    isPreviewLocus: false,
    coordinate: 'GCJ02',
    type: 'DEVICE_POSITION',
    lat: '',
    lon: '',
    source: '',
    name: '',
    connector: undefined,
    topic: undefined,
    panelFields: undefined,
  };
  rules = {
    name: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    type: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    source: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    topic: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    lon: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    lat: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    coordinate: [
      {
        required: true,
        message: '不能为空',
      },
    ],
    isPreviewLocus: [
      {
        required: true,
        message: '不能为空',
      },
    ],
  };

  planList = [];
  themeObj = {};
  panelFieldsObj = {};
  structObj = {};
  async getPlans() {
    const res = await getPlanThemeList({
      platformId: this.platformId,
    });
    const { planList, themeObj, panelFieldsObj, structObj } = fetchPlan(res);
    this.planList = planList;
    this.themeObj = themeObj;
    this.panelFieldsObj = panelFieldsObj;
    this.structObj = structObj;

    const len = this.detail && Object.keys(this.detail).length;
    if (len > 0) {
      this.form = this.detail;
    }
  }

  activeFlag = 'PASSIVE';

  changePlan(val) {
    this.form.source = val;
    const idx = this.planList.findIndex(v => v.pkId === val);
    this.form.connector = this.planList[idx].connector;
    if (idx > -1) {
      this.activeFlag = this.planList[idx].dataSources;
    } else {
      this.activeFlag = 'PASSIVE';
    }
  }

  changeTheme(val) {
    this.form.topic = val;
    this.form.panelFields = undefined;
  }

  async getValue() {
    await this.$refs.form.validate();

    return {
      form: this.form,
      body: {
        coordinate: this.form.coordinate,
        isPreviewLocus: this.form.isPreviewLocus,
        name: this.form.name,
        type: this.form.type,
        subscriptionPlanConfigList: [
          {
            connector: this.form.connector,
            dataSources: this.activeFlag,
            panelFields: [this.form.lon, this.form.lat],
            source: this.form.source,
            topic: this.form.topic,
          },
        ],
      },
    };
  }
}
</script>
<style lang="less" module></style>
