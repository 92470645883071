<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <!-- <p>当前平台设备容量10/500</p> -->
    <a-form-model-item label="产品分类名称" prop="productName">
      <a-input
        placeholder="分类名称"
        v-model="form.productName"
        :maxLength="16"
      />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class EditName extends Vue {
  @Prop({ type: String, default: '' }) name;

  form = {
    productName: '',
  };

  mounted() {
    if (this.name) {
      this.form.productName = this.name;
    }
  }

  rules = {
    productName: [
      {
        required: true,
        message: '请输入产品分类名称',
      },
    ],
  };

  async getValue() {
    await this.$refs.form.validate();
    return this.form;
  }
}
</script>

<style scoped></style>
