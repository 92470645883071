<template>
  <div :class="$style.wrap">
    <a-tabs
      :activeKey="activeTab"
      @change="handleTabChange"
      :animated="false"
      :class="$style.side"
      :tab-bar-gutter="40"
    >
      <a-tab-pane v-for="item in classify" :key="item.pkId" :tab="item.name">
        <div :class="$style.classify">
          <div
            :class="[
              $style['classify-item'],
              { [$style.active]: activeClassify === item.pkId },
            ]"
            @click="activeClassify = item.pkId"
          >
            {{ $t('common.label.all') }}
          </div>
          <x-ellipsis
            v-for="child in item.childList"
            :key="child.pkId"
            :class="[
              $style['classify-item'],
              { [$style.active]: activeClassify === child.pkId },
            ]"
            @click.native="activeClassify = child.pkId"
          >
            {{ child.name }}
          </x-ellipsis>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div :class="$style.content">
      <x-data-table
        v-if="activeClassify"
        ref="table"
        base-path="/template-manager/template/printTemplateList"
        :columns="columns"
        :service="service"
        :class="$style.table"
        row-key="pkId"
        :selected-row-keys.sync="selectedRowKeys"
      >
        <template #action>
          <a-button
            v-if="$p.action('UPDATE', '/market')"
            :class="$style.action"
            type="primary"
            @click="modify"
          >
            <x-icon type="tc-icon-setting" />
            {{ $t('market.template.virtualManagement') }}
          </a-button>
          <a-button
            v-if="$p.action('RECYCLE-BIN', '/market')"
            :class="$style.action"
            @click="recycle"
          >
            <x-icon type="tc-icon-delete" />
            {{ $t('gallery.actions.recycling') }}
          </a-button>
          <template v-if="$p.action('CLASSIFY', '/market')">
            <a-tooltip :title="$t('market.template.classify.manage')">
              <x-icon
                :class="$style.action"
                type="setting"
                @click="manageClassify"
                hover
              />
            </a-tooltip>
            <a-tooltip :title="$t('market.template.classify.add')">
              <x-icon
                :class="$style.action"
                type="plus-circle"
                @click="addClassify(false)"
                hover
              />
            </a-tooltip>
          </template>
        </template>
      </x-data-table>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import AddClassifyModal from './components/add-classify-modal.vue';
import {
  getClassifyTree,
  getTemplateClassify,
  moveTemplate,
} from '@/services/template-manager/tpClassify';
import {
  getPrintTemplateList,
  removeTemplate,
  setStatus,
} from '@/services/template-manager/template';
import { globalSocket } from '@triascloud/message-hub';
import ClassifyManageModal from './components/classify-manage-modal.vue';
import { TemplateState, TemplateType } from '@/enum/template';
import TemplateManageModal from './components/template-manage-modal.vue';
import TemplateRecycleDrawer from './components/template-recycle-drawer.vue';
import { DataSourceService } from '@triascloud/services';
// import FormSettingModal from './components/form-setting-modal.vue';
import PrintSettingModal from './components/print-setting-modal.vue';
import { previewPdf } from '@/services/oss/pdf';
import PreviewDrawer from './components/preview.vue';
import { OssSocket } from '@/enum/socket';
import { getPrintDetails } from '@/services/template-manager/printTemplate';
import Print from './pdf';

// import PrintPreviewDrawer from './components/print-preview-drawer.vue';
import InstallHistory from './components/install-history-modal.vue';
import { findTree } from '@triascloud/utils';
import SelectClassifyModal from './components/select-classify-modal.vue';
import LabelManageModal from './components/label-manage-modal.vue';
import InstallSettingModal from './components/install-setting-modal.vue';

@Component
export default class PrintTemplate extends Vue {
  classify = [];
  activeTab = '';
  activeClassify = '';
  async modify() {
    if (this.selectedRowKeys.length <= 0) {
      this.$message.warning(this.$t('market.template.selectedTip'));
      return;
    }
    await InstallSettingModal.create({
      total: this.selectedRowKeys.length,
      id: this.selectedRowKeys,
      templateType: TemplateType.Print,
    });
    this.$message.success(this.$t('common.tips.operationSuccess'));

    this.$refs.table.loadData();
  }

  selectedRowKeys = [];
  get columns() {
    return [
      {
        dataIndex: 'name',
        title: this.$t('market.template.printName'),
      },
      {
        dataIndex: 'state',
        title: this.$t('help.status'),
        customRender: state =>
          state === TemplateState.Open ? (
            <span class={[this.$style.state, this.$style['state--open']]}>
              {this.$t('template.review.state.open')}
            </span>
          ) : state === TemplateState.Init ? (
            <span class={[this.$style.state, this.$style['state--init']]}>
              {this.$t('template.review.state.notListed')}
            </span>
          ) : (
            <span class={[this.$style.state, this.$style['state--close']]}>
              {this.$t('template.review.state.offShelf')}
            </span>
          ),
      },
      {
        dataIndex: 'checker',
        title: this.$t('dictionary.reviewer'),
      },
      {
        dataIndex: 'instruction',
        title: this.$t('market.template.description'),
        width: 200,
      },
      {
        key: 'installCount',
        title: this.$t('market.template.Installs'),
        // customRender: record => (
        //   <span>
        //     {record.installCount}
        //     <x-icon
        //       class={this.$style.table__icon}
        //       type="eye"
        //       onClick={() => this.installDetail(record)}
        //     />
        //   </span>
        // ),
      },
      {
        dataIndex: 'virtualInstallCount',
        title: this.$t('market.template.virtualInstalls'),
        width: 200,
      },
      {
        dataIndex: 'pushTenantName',
        title: this.$t('template.review.pushTenantName'),
      },
      {
        dataIndex: 'pushTime',
        title: this.$t('dictionary.pushTime'),
        customRender: time => this.$moment(time).format('YYYY-MM-DD HH:mm'),
      },
      {
        dataIndex: 'checkTime',
        title: this.$t('dictionary.reviewTime'),
        customRender: time => this.$moment(time).format('YYYY-MM-DD HH:mm'),
      },
      {
        dataIndex: 'integrateCount',
        title: this.$t('template.review.earnPoints'),
      },
      {
        key: 'action',
        title: this.$t('common.action.operating'),
        customRender: record => {
          return [
            this.$p.action('UPDATE', '/market') ? (
              <a-tooltip
                placement="bottom"
                title={this.$t('common.action.edit')}
              >
                <x-icon
                  type="edit"
                  class={this.$style.table__icon}
                  onClick={e => {
                    e.stopPropagation();
                    this.edit(record.pkId);
                  }}
                />
              </a-tooltip>
            ) : null,
            <a-tooltip
              placement="bottom"
              title={this.$t('common.action.preview')}
            >
              <x-icon
                type="tc-icon-search-square"
                class={this.$style.table__icon}
                onClick={e => {
                  e.stopPropagation();
                  this.preview(record);
                }}
              />
            </a-tooltip>,
            this.$p.action('MOVE', '/market') ? (
              <a-tooltip
                placement="bottom"
                title={this.$t('market.template.classify.move')}
              >
                <x-icon
                  type="tc-icon-change"
                  class={this.$style.table__icon}
                  onClick={e => {
                    e.stopPropagation();
                    this.changeClassify(record.pkId);
                  }}
                />
              </a-tooltip>
            ) : null,
            this.$p.action('DELETE', '/market') ? (
              <a-tooltip
                placement="bottom"
                title={this.$t('common.action.delete')}
              >
                <x-icon
                  type="tc-icon-delete"
                  class={[
                    this.$style.table__icon,
                    this.$style['table__icon--delete'],
                  ]}
                  onClick={e => {
                    e.stopPropagation();
                    this.remove(record.pkId);
                  }}
                />
              </a-tooltip>
            ) : null,
            this.$p.action('SETUP_DOWN', '/market') ? (
              <a-tooltip
                placement="bottom"
                title={
                  record.state === 1
                    ? this.$t('common.action.down')
                    : this.$t('common.action.up')
                }
              >
                <x-icon
                  type={
                    record.state === 1 ? 'tc-icon-off-shelf' : 'tc-icon-shelves'
                  }
                  class={[
                    this.$style.table__icon,
                    this.$style['table__icon--delete'],
                  ]}
                  onClick={e => {
                    e.stopPropagation();
                    this.setStatus(record);
                  }}
                />
              </a-tooltip>
            ) : null,
          ];
        },
        width: 220,
        fixed: 'right',
      },
    ].map(item => ({
      ...item,
      width: item.width || 150,
      sorter: false,
      filter: false,
      align: 'center',
      ellipsis: true,
    }));
  }
  get service() {
    const service = new DataSourceService(
      '/template-manager/template/printTemplateList',
    );
    service.getList = ({ skip, size, keyword }) =>
      getPrintTemplateList({
        classifyId: this.activeClassify,
        keyword,
        current: skip / size + 1,
        size,
      }).then(res => ({ data: res.records, total: res?.total }));
    return service;
  }
  async created() {
    this.classify = await getClassifyTree(TemplateType.Print);
    this.activeTab = this.classify[0]?.pkId;
    this.initActiveClassify();
  }
  initActiveClassify() {
    this.activeClassify = this.activeTab;
  }
  @Watch('activeClassify')
  async getTemplateList() {
    this.$refs.table?.loadData();
  }
  async manageClassify() {
    try {
      await ClassifyManageModal.createModal({
        pkId: this.activeTab,
        name: this.classify.find(item => item.pkId === this.activeTab).name,
      });
    } finally {
      this.classify = await getClassifyTree(TemplateType.Print);
      if (
        !findTree(this.classify, item => item.pkId === this.activeClassify, {
          children: 'childList',
        })
      ) {
        this.initActiveClassify();
      }
    }
  }
  async addClassify() {
    await AddClassifyModal.createModal({
      parentId: this.activeTab,
      templateType: TemplateType.Print,
    });
    this.classify = await getClassifyTree(TemplateType.Print);
  }
  handleTabChange(activeKey) {
    this.keyword = '';
    this.activeTab = activeKey;
    this.initActiveClassify();
  }
  async edit(id) {
    await PrintSettingModal.createModal({
      templateId: id,
    });
    this.getTemplateList();
  }
  async preview(item) {
    const data = await getPrintDetails(item.pkId);
    PreviewDrawer.createDrawer({
      file: async () => {
        const pages = await new Print([data.template]).export();
        return new Promise((resolve, reject) => {
          previewPdf({ fileName: data.name, pages });
          globalSocket.once(OssSocket.PdfPreview, ({ data }) => {
            if (!data.abort) {
              resolve(data.previewUrl);
            } else {
              reject(new Error());
            }
          });
        });
      },
    });
  }
  async setStatus(item) {
    const state =
      item.state === TemplateState.Open
        ? TemplateState.Close
        : TemplateState.Open;
    await this.$confirm(
      state === TemplateState.Open
        ? this.$t('market.template.openConfirm', {
            value: this.$t('market.template.print'),
          })
        : this.$t('market.template.closeConfirm', {
            value: this.$t('market.template.print'),
          }),
    );
    await setStatus({
      state,
      templateId: item.pkId,
      templateType: TemplateType.Print,
    });
    this.$message.success(
      state === TemplateState.Open
        ? this.$t('market.template.openSuccessful')
        : this.$t('market.template.closeSuccessful'),
    );
    item.state = state;
  }
  async remove(id) {
    await this.$confirm(this.$t('market.template.confirmDelete'));
    await removeTemplate({
      templateType: TemplateType.Print,
      templateId: id,
    });
    this.$refs.table.loadData();
    this.$message.success(this.$t('common.tips.delete'));
  }
  async manageTemplate() {
    try {
      await TemplateManageModal.createModal({
        classifyId: this.activeClassify,
      });
    } finally {
      this.getTemplateList();
    }
  }
  recycle() {
    TemplateRecycleDrawer.createDrawer(
      { classifyId: this.activeClassify, templateType: TemplateType.Print },
      {
        onClose: () => {
          this.getTemplateList();
        },
      },
    );
  }
  labelManage() {
    LabelManageModal.createModal();
  }
  async changeClassify(templateId) {
    const originClassify = (await getTemplateClassify(templateId)).map(
      item => ({
        value: item.pkId,
        text: item.name,
      }),
    );
    const value = await SelectClassifyModal.createModal({
      value: originClassify,
      templateType: TemplateType.Print,
    });
    await moveTemplate({
      tempId: templateId,
      tempType: TemplateType.Print,
      newClassId: value.map(item => item.value),
    });
    this.$message.success(this.$t('common.tips.edit'));
    this.getTemplateList();
  }
  installDetail(record) {
    InstallHistory.createModal({
      data: record,
      templateType: TemplateType.Print,
    });
  }
}
</script>
<style lang="less" module>
.wrap {
  height: 100%;
  display: flex;
  .side {
    width: 243px;
    border-right: 1px solid var(--form-border);
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    :global {
      .ant-tabs-nav {
        padding: 0 40px;
        .ant-tabs-tab {
          padding: 10px 0;
          font-size: 16px;
        }
      }
      .ant-tabs-content {
        flex: 1;
        overflow-y: auto;
      }
    }
  }
  .content {
    flex: 1;
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
}
.empty {
  flex: 1;
  display: flex;
}
.classify {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 30px;
  .classify-item {
    width: 80px;
    padding: 6px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    &:hover {
      color: var(--primary);
    }
    &.active {
      background-color: var(--primary);
      color: #fff;
    }
  }
}
.action {
  margin-left: 20px;
}
.table {
  flex: 1;
  overflow: hidden;
  :global(.x-data-table--action .ant-btn.ant-btn-primary) {
    color: #fff;
  }
  .table__icon {
    cursor: pointer;
    font-size: 16px;
    margin: 0 10px;
  }
}
.state {
  padding: 4px 15px;
  border-radius: 50px;
  color: #fff;
  &.state--open {
    background-color: var(--success);
  }
  &.state--close {
    background-color: var(--font-info);
  }
  &.state--init {
    background-color: #18c4ec;
  }
}
</style>
