<script>
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';
import { crossStorageModule } from '@/enum/store';

const customService = new UploadService('/oss/oss');

@Component()
export default class RenderImage extends Vue {
  @PropSync('item') syncItem;
  @PropSync('store') syncStore;
  @Prop() tooltip;
  @Prop() param;
  @Prop() schemeByType;
  @Prop() checkDefaultValue;
  @Prop({ type: String, default: 'id' }) useKey;

  @crossStorageModule.State('tenant') tenant;

  previewVisible = false;
  previewImage = '';
  async handleImagePreview(file) {
    if (!file.url && !file.preview) {
      const getBase64 = file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      };
      file.preview = await getBase64(file.originFileObj);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  }
  handleImageCancel() {
    this.previewVisible = false;
  }

  imageList = [];
  uploadOptions = {};
  handleImageRemove(file) {
    const index = this.imageList.indexOf(file);
    const newFileList = this.imageList.slice();
    newFileList.splice(index, 1);
    this.imageList = newFileList;
    this.syncStore.set(this.syncItem[this.useKey], this.imageList);
  }
  async uploadImage(file) {
    const files = file.file;
    const pkId = this.tenant.pkId;
    const uploadPath = `${pkId}/connector/dp-manage/file`;
    const res = await customService.upload(
      files,
      uploadPath,
      e => {
        e.addEventListener('responseprogress', p => {
          this.uploadOptions.onProgress(
            { percent: +Math.round((p.loaded / p.total) * 100).toFixed(2) },
            files,
          );
        });
      },
      false,
    );
    this.uploadOptions.onSuccess(res, files);
    return res;
  }
  async customImageUpload(options, item) {
    const isDisabled = count => {
      return this.imageList.length >= count;
    };
    if (isDisabled(item.maxCount)) {
      this.$message.warn(`最多上传${item.maxCount}张图片！`);
      return;
    }
    if (options.file.size > item.maxSize * 1024 * 1024) {
      this.$message.warn(`文件${options.file.name}大小超过${item.maxSize}M！`);
      return;
    }
    this.uploadOptions = options;
    let ossPath = await this.uploadImage(options);
    const abPath = await customService.getAuth(ossPath);
    this.imageList.push({
      uid: options.file.uid,
      name: options.file.name,
      status: 'done',
      url: abPath,
      ossPath,
      file: options.file,
    });
    this.syncStore.set(this.syncItem[this.useKey], this.imageList);
  }

  render() {
    const item = this.syncItem;
    if (Array.isArray(item.val) && item.val.length) {
      this.imageList = item.val;
    }
    return (
      <div>
        <a-form-model-item
          class={this.$style.formItem}
          rules={[
            { required: item.required, message: '不能为空', trigger: 'blur' },
          ]}
          prop={item[this.useKey]}
        >
          <label slot="label" class={this.$style.label}>
            <span>{item.name}</span>
            {this.tooltip(item)}
            {this.param(item)}
          </label>
          <a-upload
            accept={'image/*'}
            list-type={'picture-card'}
            remove={this.handleImageRemove}
            customRequest={options => this.customImageUpload(options, item)}
            file-list={this.imageList}
            multiple={item.maxCount > 1}
            onPreview={this.handleImagePreview}
          >
            {this.imageList.length < 1 ? <a-icon type="plus" /> : ''}
          </a-upload>
        </a-form-model-item>
        <a-modal
          visible={this.previewVisible}
          footer={null}
          onCancel={this.handleImageCancel}
        >
          <img
            alt={'example'}
            style={{ width: '100%' }}
            src={this.previewImage}
          />
        </a-modal>
      </div>
    );
  }
}
</script>
<style lang="less" module>
.formItem {
  :global {
    .ant-form-item-required {
      display: flex;
      align-items: center;
    }
    .ant-form-item-control-wrapper {
      height: 138px;
    }
  }
}
.label {
  display: flex;
  align-items: center;
}
</style>
