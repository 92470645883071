import request from '../request';
const basePath = '/smart-site/ssc/developmenterData';

/**
 * @name 开发者管理分页列表
 * @returns { Promise }
 */
export const getDeveloperList = params =>
  request(`${basePath}/pageData`, {
    method: 'POST',
    body: params,
  });

/**
 * @name 添加开发者
 * @param { object } body
 * @param { string } body.developmenterName
 * @param { string } body.remark
 * @returns { Promise }
 */
export const addDeveloper = params =>
  request(`${basePath}/add`, {
    method: 'POST',
    body: params,
  });

/**
 * @name 重置AppSecret
 * @param {number} id
 */
export const resetAppSecret = id =>
  request(`${basePath}/reSetAppSecret/${id}`, {
    method: 'PUT',
  });

/**
 * @name 删除开发者
 * @param {string} id
 * @returns { Promise }
 */
export const deleteDeveloper = id =>
  request(`${basePath}/delete/${id}`, {
    method: 'DELETE',
  });

/**
 * @name 开发者管理看板数据
 * @returns { Promise }
 */
export const developerStatistics = () =>
  request(`${basePath}/statistics`, {
    method: 'GET',
  });

/**
 * @name 编辑开发者
 * @param { object } body
 * @param { number | string } body.pkId
 * @param { string } body.developmenterName
 * @param { string } body.remark
 * @returns { Promise }
 */
export const updateDeveloper = body =>
  request(`${basePath}/update`, {
    method: 'POST',
    body,
  });

/**
 * @name 权限编辑
 * @param {object} body
 * @param {1 | 2} body.developmenterType
 * @param {number} body.permissionId
 * @param {number} body.pkId
 * @returns {Promise}
 */
export const updatePermissionForDeveloper = body =>
  request(`${basePath}/updatePermission`, {
    method: 'POST',
    body,
  });

/**
 * @name 业务日志
 * @param {object} body
 * @param {string} body.developmenter 开发者
 * @param {string} body.endTime
 * @param {string} body.startTime
 * @param {string} body.deviceGroup 设备类型
 * @returns {Promise}
 */
export const logList = body =>
  request(`${basePath}/log`, {
    method: 'POST',
    body,
  });
