<template>
  <a-form-model-item
    :label="$t('controls.config.desc')"
    prop="widget.description"
  >
    <x-editor
      :class="$style.descriptionEditor"
      :value="field.widget.description"
      :options="editorOption"
      @input="updateField({ key: 'widget.description', value: $event })"
    />
  </a-form-model-item>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class Description extends Vue {
  @Prop() field;
  @Prop() updateField;

  get editorOption() {
    return {
      modules: {
        toolbar: {
          container: [['link']],
        },
      },
    };
  }
}
</script>
<style lang="less" module>
.descriptionEditor:global(.x-editor) :global {
  padding: 0;
  .ql-editor {
    min-height: 150px;
  }
  .x-editor .ql-snow.ql-toolbar .ql-picker-options,
  .x-editor-toolbar.ql-snow.ql-toolbar .ql-picker-options {
    max-height: 150px;
  }
  .ql-snow.ql-toolbar {
    padding: 4px 0;
  }
}
</style>
