<template>
  <div>
    <div v-if="noTemplateTips">{{ $t('connector.dpManage.noDataModel') }}</div>
    <data-form
      v-if="!noTemplateTips && hasAlreay"
      :fields="fields"
      :data="formData"
      ref="formRef"
    />
  </div>
</template>
<script>
import { Component, Vue, Prop, Provide } from 'vue-property-decorator';
import DataForm from '../data-template/template-design/form/data-form/data-form';
import {
  getClassification,
  insertDataPoint,
  updateDataPoint,
} from '@/services/iot-platform/dpManager';
import { ControlTypeEnum } from '../data-template/template-design/form/controls';
import { uuid } from '@triascloud/utils';
import { delay } from 'lodash-decorators';
@Component({ components: { DataForm } })
export default class DpForm extends Vue {
  @Prop({ type: Object }) record;
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: String, default: '' }) idxPlatformProductId;
  fields = [];

  created() {
    this.generateFormData();
    this.getFields();
  }

  /** @name 修改某tab标识符 */
  @Provide('typeId')
  get typeId() {
    return this.record.classificationId || '';
  }
  noTemplateTips = false;
  /** @name 数据和字段是否准备好 */
  hasAlreay = false;
  async getFields() {
    const array = await getClassification(this.platformId);
    if (!array.length) this.noTemplateTips = true;
    if (this.typeId && this.record.dataPointId) {
      try {
        array
          .find(v => v.identifier === this.typeId)
          .fields.forEach(v => {
            if (v.type === ControlTypeEnum.FnIdentifier) {
              // 展示对应的tab面板
              v.widget.view = true;
            }
          });
      } catch (error) {
        return false;
      }
    }
    const tabs = {
      type: ControlTypeEnum.Tabs,
      widget: {
        tabs: array,
      },
      current: '', // 当前选中的【分类项】
    };
    this.fields = [tabs];
    await delay(50);
    this.hasAlreay = true;
  }

  formData = {};
  generateFormData() {
    if (this.record.dataPointId) {
      let data = JSON.parse(this.record.dataSpecs);
      for (const item of data.fields) {
        this.formData[item.pkId] = item.value;
      }
    }
  }

  get Ref() {
    return this.$refs.formRef && this.$refs.formRef;
  }

  async getValue() {
    let form = await this.Ref.validate();
    form.idempotent = uuid();
    let res = null;
    try {
      //编辑
      if (this.record.dataPointId) {
        form.dataPointId = this.record.dataPointId;
        delete form.classificationId;
        delete form.functionIdentifier;
        res = await updateDataPoint(form);
      } else {
        form.idxPlatformId = this.platformId;
        form.idxPlatformProductId = this.record.idxPlatformProductId;
        res = await insertDataPoint(form);
      }
      this.$message.success(this.$t('common.tips.operationSuccess') + '！');
    } catch (error) {
      return false;
    }
    return res;
  }
}
</script>
<style lang="less" module>
.mr10 {
  margin-left: 10px;
}
.centerRight {
  text-align: right;
}
.listWarp {
  display: flex;
  flex-direction: row;
  .activeItem {
    color: var(--primary);
    border-bottom: 2px solid var(--primary);
  }
  .listItem {
    margin-right: 10px;
    cursor: pointer;
  }
}
</style>
