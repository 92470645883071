/**
 * 设备类型
 * @readonly
 * @enum
 */
export const deviceType = {
  FG: '闸机',
  DUST: '扬尘',
  TCSF: '塔吊',
  ED: '升降机',
  HBD: '吊篮',
  WB: '智能地磅',
  WM: '智能水表',
  EM: '智能电表',
  NG: '有害气体监测',
  VC: '车辆清洗监测',
  VL: '车辆进出监控',
  UP: '卸料平台',
  HF: '高支模',
  DFP: '深基坑',
  SMOKE: '智能烟感',
  AIV: 'AI盒子识别',
  CT: '混凝土测温',
  PN: '临边防护',
  SD: '水质监测',
  SP: '雾炮喷淋',
  SCREW: '螺母监测',
  SCR: '标养室监测',
};

/**
 * 开发人员类型
 * @readonly
 * @enum
 */
export const developerType = {
  1: '厂商',
  2: '政府',
};

/**
 * 所有导航栏菜单
 * @readonly
 * @enum
 */
export const navMenu = {
  GONG_SI_SHOU_YE: '首页',
  XIANG_MU_SHOU_YE: '首页',
  LV_SE_SHI_GONG: '绿色施工',
  LAO_WU_GUAN_LI: '劳务管理',
  SHI_PING_JIAN_KONG: '视频监控',
  CHE_LIANG_GUAN_LI: '车辆管理',
  XIAN_CHANG_JIAN_CHA: '现场检查',
  SHENG_JIANG_JI: '升降机',
  XIE_LIAO_PING_TAI: '卸料平台',
  TA_DIAO: '塔吊',
  LIN_BIAN_FANG_HU: '临边防护',
  SHEN_JI_KENG: '深基坑',
  GAO_ZHI_MO: '高支模',
  ZHI_NENG_DI_BANG: '智能地磅',
  SHUI_DIAN_JIAN_CE: '水电监测',
  AI_SHI_BIE: 'AI识别',
  JIN_DU_GUAN_LI: '进度管理',
};

/**
 * 项目导航栏菜单
 * @readonly
 * @enum
 */
export const projectNavMenu = {
  XIANG_MU_SHOU_YE: '首页',
  LV_SE_SHI_GONG: '绿色施工',
  LAO_WU_GUAN_LI: '劳务管理',
  SHI_PING_JIAN_KONG: '视频监控',
  CHE_LIANG_GUAN_LI: '车辆管理',
  XIAN_CHANG_JIAN_CHA: '现场检查',
  SHENG_JIANG_JI: '升降机',
  XIE_LIAO_PING_TAI: '卸料平台',
  TA_DIAO: '塔吊',
  LIN_BIAN_FANG_HU: '临边防护',
  SHEN_JI_KENG: '深基坑',
  GAO_ZHI_MO: '高支模',
  ZHI_NENG_DI_BANG: '智能地磅',
  SHUI_DIAN_JIAN_CE: '水电监测',
  AI_SHI_BIE: 'AI识别',
  JIN_DU_GUAN_LI: '进度管理',
};

/**
 * 公司导航栏菜单
 * @readonly
 * @enum
 */
export const componyNavMenu = {
  GONG_SI_SHOU_YE: '首页',
};

/**
 * 项目BI导航栏模板
 * @readonly
 * @enum
 */
export const projectNavTemplates = {
  XIANG_MU_SHOU_YE: [
    {
      label: '江苏省模板',
      value: 'XIANG_MU_SHOU_YE_JIANG_SU',
    },
  ],
  LV_SE_SHI_GONG: [
    {
      label: '江苏省模板',
      value: 'LV_SE_SHI_GONG_JIANG_SU',
    },
  ],
  LAO_WU_GUAN_LI: [
    {
      label: '江苏省模板',
      value: 'LAO_WU_GUAN_LI_JIANG_SU',
    },
  ],
  SHI_PING_JIAN_KONG: [
    {
      label: '江苏省模板',
      value: 'SHI_PING_JIAN_KONG_JIANG_SU',
    },
  ],
  CHE_LIANG_GUAN_LI: [
    {
      label: '江苏省模板',
      value: 'CHE_LIANG_GUAN_LI_JIANG_SU',
    },
  ],
  XIAN_CHANG_JIAN_CHA: [
    {
      label: '江苏省模板',
      value: 'XIAN_CHANG_JIAN_CHA_JIANG_SU',
    },
  ],
  SHENG_JIANG_JI: [
    {
      label: '江苏省模板',
      value: 'SHENG_JIANG_JI_JIANG_SU',
    },
  ],
  XIE_LIAO_PING_TAI: [
    {
      label: '江苏省模板',
      value: 'XIE_LIAO_PING_TAI_JIANG_SU',
    },
  ],
  TA_DIAO: [
    {
      label: '江苏省模板',
      value: 'TA_DIAO_JIANG_SU',
    },
  ],
  LIN_BIAN_FANG_HU: [
    {
      label: '江苏省模板',
      value: 'LIN_BIAN_FANG_HU_JIANG_SU',
    },
  ],
  SHEN_JI_KENG: [
    {
      label: '江苏省模板',
      value: 'SHEN_JI_KENG_JIANG_SU',
    },
  ],
  GAO_ZHI_MO: [
    {
      label: '江苏省模板',
      value: 'GAO_ZHI_MO_JIANG_SU',
    },
  ],
  ZHI_NENG_DI_BANG: [
    {
      label: '江苏省模板',
      value: 'ZHI_NENG_DI_BANG_JIANG_SU',
    },
  ],
  SHUI_DIAN_JIAN_CE: [
    {
      label: '江苏省模板',
      value: 'SHUI_DIAN_JIAN_CE_JIANG_SU',
    },
  ],
  AI_SHI_BIE: [
    {
      label: '江苏省模板',
      value: 'AI_SHI_BIE_JIANG_SU',
    },
  ],
  JIN_DU_GUAN_LI: [
    {
      label: '江苏省模板',
      value: 'JIN_DU_GUAN_LI_JIANG_SU',
    },
  ],
};

/**
 * 公司BI导航栏模板
 * @readonly
 * @enum
 */
export const componyNavTemplates = {
  GONG_SI_SHOU_YE: [
    {
      label: '标准',
      value: 'GONG_SI_SHOU_YE_STANDARD',
    },
  ],
};
