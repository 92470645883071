<template>
  <a-form-model :model="form" :rules="rules" ref="form">
    <a-form-model-item label="选择产品" prop="production">
      <SelectProduct
        :disabled="actionCheck"
        :platformId="id"
        v-model="form.production"
      />
    </a-form-model-item>
    <a-form-model-item
      style="height: 180px"
      prop="desc"
      label="发布声明"
      v-if="hasSelfCheck"
      ref="desc"
    >
      <a-textarea
        :maxLength="128"
        v-model="form.desc"
        placeholder="填写发布声明"
        :auto-size="{ minRows: 6 }"
      />
    </a-form-model-item>
    <a-form-model-item v-else style="height: 180px">
      <a-progress v-if="actionCheck" :percent="percent" />
      <section v-if="actionCheck" :class="$style.pWrap">
        <a-icon v-if="!config.flag" :class="$style.tIcon" type="loading" />
        <x-custom-icon
          v-if="config.flag && config.pass"
          :class="$style.xIcon"
          type="1356536898861703169.3092122"
        />
        <a-icon
          :class="$style.tIcon"
          v-if="config.flag && !config.pass"
          type="exclamation-circle"
        />
        <span :class="$style.title">连接器配置</span>
        <span :class="$style.txt">{{ config.txt }}</span>
      </section>
      <section v-if="config.flag" :class="$style.pWrap">
        <a-icon v-if="!subscri.flag" :class="$style.tIcon" type="loading" />
        <x-custom-icon
          v-if="subscri.flag && subscri.pass"
          :class="$style.xIcon"
          type="1356536898861703169.3092122"
        />
        <a-icon
          v-if="subscri.flag && !subscri.pass"
          :class="$style.tIcon"
          type="exclamation-circle"
        />
        <span :class="$style.title">订阅计划</span>
        <span :class="$style.txt">{{ subscri.txt }}</span>
      </section>
      <section v-if="subscri.flag" :class="$style.pWrap">
        <a-icon v-if="!deviceAdd.flag" :class="$style.tIcon" type="loading" />
        <x-custom-icon
          v-if="deviceAdd.flag && deviceAdd.pass"
          :class="$style.xIcon"
          type="1356536898861703169.3092122"
        />
        <a-icon
          v-if="deviceAdd.flag && !deviceAdd.pass"
          :class="$style.tIcon"
          type="exclamation-circle"
        />
        <span :class="$style.title">设备添加</span>
        <span :class="$style.txt">{{ deviceAdd.txt }}</span>
      </section>
      <section v-if="deviceAdd.flag" :class="$style.pWrap">
        <a-icon v-if="!panel.flag" :class="$style.tIcon" type="loading" />
        <x-custom-icon
          v-if="panel.flag && panel.pass"
          :class="$style.xIcon"
          type="1356536898861703169.3092122"
        />
        <a-icon
          v-if="panel.flag && !panel.pass"
          :class="$style.tIcon"
          type="exclamation-circle"
        />
        <span :class="$style.title">设备面板</span>
        <span :class="$style.txt">{{ panel.txt }}</span>
      </section>
      <section v-if="panel.flag" :class="[$style.pWrap, $style.active]">
        <a-icon v-if="!productDes.flag" :class="$style.tIcon" type="loading" />
        <x-custom-icon
          v-if="productDes.flag && productDes.pass"
          :class="$style.xIcon"
          type="1356536898861703169.3092122"
        />
        <a-icon
          v-if="productDes.flag && !productDes.pass"
          type="exclamation-circle"
          :class="$style.tIcon"
        />
        <span :class="$style.title">产品介绍</span>
        <span :class="$style.txt">{{ productDes.txt }}</span>
      </section>
    </a-form-model-item>
    <a-form-model-item :class="$style.btnBottom">
      <a-button @click="close">取消</a-button>
      <a-button
        v-if="hasSelfCheck"
        :class="$style.margin"
        type="primary"
        @click="handleRelease"
        >发布</a-button
      >
      <a-button
        v-else
        :class="$style.margin"
        type="primary"
        @click="handleCheck"
        >{{ btnSelfCheck }}</a-button
      >
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { globalSocket } from '@triascloud/message-hub';
import SelectProduct from '@/views/connector/platform-manage/develop/device-manage/device-form/select-product.vue';
import { releaseRecord } from '@/services/iot-platform/releaseRecord';
import { IotSocket } from '@/enum/socket';
import { delay } from '@triascloud/utils';

/** @name 下一个自检步骤 */
const EMIT_KEY = 'emit-next';
const delay_time = 100;

@Component({
  components: {
    SelectProduct,
  },
})
export default class ReleaseProduct extends Vue {
  @Prop() id;
  @Prop({ type: Function }) close;
  @Prop({ type: Function }) success;

  mounted() {
    this.initEmit();
    this.initAction();
  }

  async nextForEach(obj, nextType) {
    for (const txt of obj.txtList) {
      await this.$nextTick();
      await delay(delay_time);
      obj.txt = txt;
    }
    this.percent = obj.percent;
    obj.flag = true;
    this.$emit(EMIT_KEY, nextType);
  }

  /** @name 是否自检了呢 */
  hasSelfCheck = false;
  btnSelfCheck = '产品自检';
  allSelfChecking = false;
  initEmit() {
    this.$on(EMIT_KEY, async type => {
      if (type === IotSocket.subscriptionPlan) {
        await this.nextForEach(this.subscri, IotSocket.deviceAddition);
      } else if (type === IotSocket.deviceAddition) {
        await this.nextForEach(this.deviceAdd, IotSocket.devicePanel);
      } else if (type === IotSocket.devicePanel) {
        await this.nextForEach(this.panel, IotSocket.productDescription);
      } else if (type === IotSocket.productDescription) {
        await this.nextForEach(this.productDes, IotSocket.ProductInspection);
      } else if (type === IotSocket.ProductInspection) {
        this.productCheck.flag = true;
        this.percent = this.productCheck.percent;
        await delay(1000);
        this.allSelfChecking = false;
        if (this.hasPass === 6) {
          /** @name 自检通过 */
          this.hasSelfCheck = true;
        } else {
          this.btnSelfCheck = '继续发布';
        }
      }
    });
  }

  form = {
    production: '',
    desc: '',
  };
  rules = {
    production: [{ required: true, message: '产品不能为空！' }],
    desc: [{ required: true, message: '发布说明不能为空！' }],
  };

  async handleRelease() {
    this.$refs.form.addField(this.$refs.desc);
    await this.$refs.form.validate();
    const res = await releaseRecord({
      directions: this.form.desc,
      idxPlatformId: this.id,
      idxReleaseId: this.form.production,
      type: 'PRODUCT',
    });
    if (res) {
      this.$message.success('发布成功！');
      this.success();
    }
  }

  actionCheck = false;
  async handleCheck() {
    if (this.btnSelfCheck === '产品自检') {
      if (!this.allSelfChecking) {
        await this.$refs.form.validate();
        this.allSelfChecking = true;
        this.actionCheck = true;
        globalSocket.send(IotSocket.ProductInspection, this.form.production);
      }
    } else {
      this.$message.warning({
        content:
          '不符合发布要求！建议完成上述自检项后再发布，继续发布可能被驳回！',
        onClose: () => {
          this.hasSelfCheck = true;
        },
        duration: 1.5,
      });
    }
  }
  initAction() {
    globalSocket.on(IotSocket.connectorConfiguration, this.handleConfig);
    globalSocket.on(IotSocket.subscriptionPlan, this.handleSubscription);
    globalSocket.on(IotSocket.deviceAddition, this.handleDeviceAddition);
    globalSocket.on(IotSocket.devicePanel, this.handleDevicePanel);
    globalSocket.on(IotSocket.productDescription, this.handleProductDesc);
    globalSocket.on(IotSocket.ProductInspection, this.handleProductCheck);
  }
  percent = 0;
  hasPass = 0;
  config = {
    txtList: [],
    txt: '',
    flag: false,
    pass: false,
    percent: 0,
  };
  async handleConfig(data) {
    const txtList = [];
    let opt = {};
    opt =
      data.data.checkResultList &&
      data.data.checkResultList.length &&
      data.data.checkResultList[0];
    opt?.names?.forEach(v => {
      txtList.push(`已发布连接器：${v}`);
    });
    txtList.push(`当前产品已发布连接器${opt?.total || 0}个`);
    this.config = {
      txtList,
      txt: '',
      flag: false,
      pass: data.data.isPassed,
      percent: parseInt(data.data.percentage),
    };
    if (data.data.isPassed) {
      this.hasPass++;
    }
    await this.nextForEach(this.config, IotSocket.subscriptionPlan);
  }
  subscri = {
    txtList: [],
    txt: '',
    flag: false,
    pass: false,
    percent: 0,
  };
  handleSubscription(data) {
    const txtList = [];
    let opt = {};
    opt =
      data.data.checkResultList &&
      data.data.checkResultList.length &&
      data.data.checkResultList[0];
    opt?.names?.forEach(v => {
      txtList.push(`已发布订阅：${v}`);
    });
    txtList.push(`当前产品运行的订阅计划共${opt?.total || 0}个`);
    this.subscri = {
      txtList,
      txt: '',
      flag: false,
      pass: data.data.isPassed,
      percent: parseInt(data.data.percentage),
    };
    if (data.data.isPassed) {
      this.hasPass++;
    }
  }
  deviceAdd = {
    txtList: [],
    txt: '',
    flag: false,
    pass: false,
    percent: 0,
  };
  handleDeviceAddition(data) {
    const txtList = [];
    let opt = {};
    opt =
      data.data.checkResultList &&
      data.data.checkResultList.length &&
      data.data.checkResultList[0];
    opt?.names?.forEach(v => {
      txtList.push(`已添加设备：${v}`);
    });
    txtList.push(`当前产品累计添加设备${opt?.total || 0}个`);
    this.deviceAdd = {
      txtList,
      txt: '',
      flag: false,
      pass: data.data.isPassed,
      percent: parseInt(data.data.percentage),
    };
    if (data.data.isPassed) {
      this.hasPass++;
    }
  }
  panel = {
    txtList: [],
    txt: '',
    flag: false,
    pass: false,
    percent: 0,
  };
  handleDevicePanel(data) {
    const panels = data.data.checkResultList || [];
    let txt = '';
    panels.forEach(v => {
      txt += `${v.name}${v.total}个，`;
    });

    let txtList = [];
    panels.forEach(v => {
      if (v.name === '基础字段') {
        v.names.forEach(i => {
          txtList.push(`已添加基础字段：${i}`);
        });
      } else {
        v.names.slice(0, 10).forEach(i => {
          txtList.push(`已添加${v.name}：${i}`);
        });
      }
    });
    if (txt) {
      txtList.push(txt.slice(0, -1));
    }
    this.panel = {
      txtList,
      txt: '',
      flag: false,
      pass: data.data.isPassed,
      percent: parseInt(data.data.percentage),
    };
    if (data.data.isPassed) {
      this.hasPass++;
    } else {
      txtList.push('暂无数据');
    }
  }
  productDes = {
    txtList: [],
    txt: '',
    flag: false,
    pass: false,
    percent: 0,
  };
  handleProductDesc(data) {
    let txtList = [];
    if (data.data.isPassed) {
      this.hasPass++;
      txtList = [
        '产品介绍模块：产品基础信息',
        '产品介绍模块：增值服务',
        '产品介绍模块：售后支持',
        '产品介绍模块：设备能力',
        '产品介绍模块：设备规格和介绍',
      ];
      txtList.push('产品介绍已提交');
    } else {
      txtList.push('产品介绍还未完成');
    }
    this.productDes = {
      txtList,
      txt: '',
      flag: false,
      pass: data.data.isPassed,
      percent: parseInt(data.data.percentage),
    };
  }
  productCheck = {
    flag: false,
    pass: false,
    percent: 0,
  };
  handleProductCheck(data) {
    this.productCheck = {
      flag: false,
      pass: data.data.isPassed,
      percent: parseInt(data.data.percentage),
    };
    if (data.data.isPassed) {
      this.hasPass++;
    }
  }

  beforeDestroy() {
    globalSocket.off(IotSocket.connectorConfiguration, this.handleConfig);
    globalSocket.off(IotSocket.subscriptionPlan, this.handleSubscription);
    globalSocket.off(IotSocket.deviceAddition, this.handleDeviceAddition);
    globalSocket.off(IotSocket.devicePanel, this.handleDevicePanel);
    globalSocket.off(IotSocket.productDescription, this.handleProductDesc);
    globalSocket.off(IotSocket.ProductInspection, this.handleProductCheck);
  }
}
</script>
<style lang="less" module>
.btnBottom {
  text-align: right;
  .margin {
    margin-left: 20px;
  }
}
.pWrap {
  display: flex;
  align-items: center;
  .tIcon {
    font-size: 20px;
  }
  .xIcon {
    font-size: 20px;
    color: #69ea32;
  }
  .title {
    margin-left: 10px;
    font-weight: 900;
    font-size: 16px;
    color: var(--font-active);
  }
  .txt {
    margin-left: 10px;
    color: var(--font-active);
  }
}
</style>
