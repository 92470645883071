<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { templateDesignModule } from '@/enum/store';
import { DATATYPE } from '@/views/connector/platform-manage/develop/utils';

@Component()
export default class CurrentDataSetDesign extends Vue {
  @Prop({ required: true }) field;
  @Prop() updateField;
  @templateDesignModule.State types;

  get DataType() {
    const type = this.types.find(v => v.pkId === this.field.widget.datatype);
    if (type) {
      return type.datatype;
    }
    return '';
  }

  onChangeType(value) {
    this.updateField({
      key: 'widget.datatype',
      value: value,
    });
  }

  renderNumber() {
    return (
      <div>
        <a-form-model-item label={this.$t('controls.currentDataSet.ranges')}>
          <a-row>
            <a-col span={11}>
              <a-input-number
                placeholder={this.$t('controls.currentDataSet.minimum')}
                disabled
              />
            </a-col>
            <a-col span={11} offset={2}>
              <a-input-number
                placeholder={this.$t('controls.currentDataSet.maximum')}
                disabled
              />
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label={this.$t('controls.currentDataSet.stepSize')}>
          <a-input
            placeholder={this.$t('controls.currentDataSet.stepSizePlaceholder')}
            readOnly
          />
        </a-form-model-item>
        <a-form-model-item label={this.$t('controls.currentDataSet.unit')}>
          <a-input
            placeholder={this.$t('controls.currentDataSet.unitPlaceholder')}
            readOnly
          />
        </a-form-model-item>
      </div>
    );
  }

  renderText() {
    return (
      <a-form-model-item label={this.$t('controls.currentDataSet.maxLen')}>
        <a-row>
          <a-col span={22}>
            <a-input-number value={10240} readOnly />
          </a-col>
          <a-col span={2} class={this.$style.right}>
            {this.$t('connector.dataType.byte')}
          </a-col>
        </a-row>
      </a-form-model-item>
    );
  }

  renderDate() {
    return (
      <a-form-model-item label={this.$t('controls.currentDataSet.timeFormat')}>
        <a-input
          value={this.$t('controls.currentDataSet.timeLimit')}
          readOnly
        />
      </a-form-model-item>
    );
  }

  renderEnum() {
    return (
      <div>
        <a-form-model-item>
          <label slot={'label'} class={'ant-form-item-required'}>
            {this.$t('controls.currentDataSet.enumItem')}
          </label>
          <a-row>
            <a-col span={11}>
              {this.$t('controls.currentDataSet.paramValue')}
              <a-tooltip placement={'right'} arrowPointAtCenter={true}>
                <span slot={'title'}>
                  {this.$t('controls.currentDataSet.rangeDesc')}
                </span>
                <a-icon
                  type={'info-circle'}
                  class={[this.$style.ml5, this.$style.mr5, this.$style.zI101]}
                />
              </a-tooltip>
            </a-col>
            <a-col span={11} offset={2}>
              {this.$t('controls.currentDataSet.paramDesc')}
              <a-tooltip placement={'right'} arrowPointAtCenter={true}>
                <span slot={'title'}>
                  {this.$t('controls.currentDataSet.rangeDesc')}
                </span>
                <a-icon
                  type={'info-circle'}
                  class={[this.$style.ml5, this.$style.mr5, this.$style.zI101]}
                />
              </a-tooltip>
            </a-col>
            <a-col span={11}>
              <a-input
                placeholder={this.$t('controls.currentDataSet.close')}
                readOnly
              />
            </a-col>
            <a-col span={11} offset={2}>
              <a-input
                placeholder={this.$t('controls.currentDataSet.open')}
                readOnly
              />
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-button
          icon={'plus'}
          type={'primary'}
          size={'small'}
          class={this.$style.mt10}
        >
          {this.$t('controls.currentDataSet.addEnum')}
        </a-button>
      </div>
    );
  }

  renderBoolean() {
    return (
      <a-form-model-item label={this.$t('controls.currentDataSet.ranges')}>
        <a-row>
          <a-col span={11}>
            <div class={this.$style.labelBox}>
              <span class={this.$style.span}>0-</span>
              <a-input
                placeholder={this.$t('controls.currentDataSet.close')}
                readOnly
              />
            </div>
          </a-col>
          <a-col span={11} offset={2}>
            <div class={this.$style.labelBox}>
              <span class={this.$style.span}>1-</span>
              <a-input
                placeholder={this.$t('controls.currentDataSet.open')}
                readOnly
              />
            </div>
          </a-col>
        </a-row>
      </a-form-model-item>
    );
  }

  renderStruct() {
    return (
      <a-form-model-item>
        <label slot={'label'} class={'ant-form-item-required'}>
          {this.$t('controls.currentDataSet.struct')}
        </label>
        <a-button
          icon={'plus'}
          type={'primary'}
          size={'small'}
          class={this.$style.mt10}
        >
          {this.$t('controls.currentDataSet.newObject')}
        </a-button>
      </a-form-model-item>
    );
  }

  canUseTypeArray = [
    DATATYPE.int,
    DATATYPE.float,
    DATATYPE.double,
    DATATYPE.text,
    DATATYPE.struct,
  ];
  renderArray() {
    const array = this.canUseTypeArray.map(v => v.toLocaleLowerCase());
    return (
      <div>
        <a-form-model-item>
          <label slot={'label'} class={'ant-form-item-required'}>
            {this.$t('controls.currentDataSet.elementType')}
          </label>
          <a-checkbox-group disabled options={array} />
        </a-form-model-item>
        <a-form-model-item>
          <label slot={'label'} class={'ant-form-item-required'}>
            {this.$t('controls.currentDataSet.eleCount')}
          </label>
          <a-input value={10} readOnly />
        </a-form-model-item>
      </div>
    );
  }

  get renderScheme() {
    switch (this.DataType) {
      case DATATYPE.int:
      case DATATYPE.float:
      case DATATYPE.double:
        return this.renderNumber();
      case DATATYPE.date:
        return this.renderDate();
      case DATATYPE.enum:
        return this.renderEnum();
      case DATATYPE.text:
        return this.renderText();
      case DATATYPE.boolean:
        return this.renderBoolean();
      case DATATYPE.struct:
        return this.renderStruct();
      case DATATYPE.array:
        return this.renderArray();
      default:
        return '';
    }
  }

  render() {
    return (
      <div class={this.$style.box}>
        <a-form-model-item>
          <label slot={'label'} class={'ant-form-item-required'}>
            {this.$t('controls.currentDataSet.dataType')}
          </label>
          <a-select
            onChange={this.onChangeType}
            value={this.field.widget.datatype}
          >
            {this.types.map(item => (
              <a-select-option value={item.pkId} key={item.pkId}>
                {item.customizeName}
              </a-select-option>
            ))}
          </a-select>
        </a-form-model-item>
        {this.renderScheme}
      </div>
    );
  }
}
</script>
<style lang="less" module>
.box {
  :global {
    .ant-form-item-control-wrapper {
      max-width: 400px;
    }
    .ant-input-number {
      width: 100%;
    }
    .ant-btn-sm {
      height: 24px;
    }
  }
}
.ml5 {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}
.mt10 {
  margin-top: 10px;
  font-size: 12px;
}
.zI101 {
  z-index: 101;
  position: relative;
}
.labelBox {
  display: flex;
  .span {
    width: 30px;
  }
}
.right {
  text-align: right;
}
</style>
