<template>
  <div :class="$style.SolutionManagement">
    <!-- 头部显示 -->
    <ManageHeader :data="data.totalAmountVO" v-if="data" />
    <div :class="$style.echart">
      <!-- 数量统计 -->
      <div :class="$style.chart_row">
        <ManageChartsWrap
          v-for="item in dataList"
          :key="item.key"
          :title="item.title"
          :isEmpty="item.data.length"
          :loading="loading"
          :class="$style.chart_wrap"
        >
          <template v-slot="{ selectTime }">
            <Component
              :class="$style.chart_item"
              :is="item.component"
              :selectTime="selectTime"
              :data="item.data[selectTime]"
              :key="selectTime"
              v-if="item.hasData(item.data[selectTime])"
            />
            <EmptyContent
              type="list"
              :class="$style.chart_item"
              v-if="!item.hasData(item.data[selectTime]) && !loading"
            />
          </template>
        </ManageChartsWrap>
      </div>
    </div>
    <!-- <ManageDetailsTable /> -->
    <ManageTradeTable />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { solutionStatistics } from '@/services/template-manager/solution';
import ManageHeader from './components/manage-header.vue';
import ManageCountEchart from './components/manage-count-echart.vue';
import ManageInstallEchart from './components/manage-install-echart.vue';
import ManageIncomeEchart from './components/manage-income-echart.vue';
import ManageDesignEchart from './components/manage-design-echart.vue';
import ManageRankEchart from './components/manage-rank-echart.vue';
import ManageConversionEchart from './components/manage-conversion-echart.vue';
// import ManageDetailsTable from './components/manage-details-table.vue';
import ManageTradeTable from './components/manage-trade-table.vue';
import ManageChartsWrap from './components/manage-charts-wrap.vue';
import EmptyContent from '@/components/empty-content';

import { generateMap } from '@triascloud/utils';

@Component({
  components: {
    ManageHeader,
    ManageCountEchart,
    ManageInstallEchart,
    ManageIncomeEchart,
    ManageDesignEchart,
    ManageRankEchart,
    ManageConversionEchart,
    // ManageDetailsTable,
    ManageTradeTable,
    ManageChartsWrap,
    EmptyContent,
  },
})
export default class SolutionStatistics extends Vue {
  data = {
    solutionCountVOList: null,
    solutionInstallRankVOS: [],
    tenantInComeCOS: [],
    remoteSolutionCountVOList: [],
    solutionConversionRateStatisticsVOS: [],
    solutionRevenueRankingCOS: [],
  };
  loading = false;

  get dataList() {
    return [
      {
        key: 'solutionCountVOList',
        component: ManageCountEchart,
        title: this.$t('solution.numberStatistics'),
        hasData: data =>
          data &&
          data.solutionCountDetailList &&
          data.solutionCountDetailList.length,
        data: this.initData(this.data.solutionCountVOList),
      },
      {
        key: 'solutionInstallRankVOS',
        component: ManageInstallEchart,
        title: this.$t('solution.solutionInstallationRanking'),
        dataKey: 'solutionInstallRankDetailList',
        hasData: data =>
          data &&
          data.solutionInstallRankDetailList &&
          data.solutionInstallRankDetailList.length,
        data: this.initData(this.data.solutionInstallRankVOS),
      },
      {
        key: 'tenantInComeCOS',
        component: ManageIncomeEchart,
        title: this.$t('solution.revenueShare'),
        dataKey: 'dataList',
        hasData: data => data && data.dataList && data.dataList.length,
        data: this.initData(this.data.tenantInComeCOS),
      },
      {
        key: 'remoteSolutionCountVOList',
        component: ManageDesignEchart,
        title: '专业方案设计统计',
        hasData: data =>
          data &&
          data.solutionCountDetailList &&
          data.solutionCountDetailList.length,

        data: this.initData(this.data.remoteSolutionCountVOList),
      },
      {
        key: 'solutionConversionRateStatisticsVOS',
        component: ManageRankEchart,
        title: this.$t('solution.statisticsRate'),
        hasData: data => data && data.solutionConversionRateStatisticsDetailVOS,
        data: this.initData(this.data.solutionConversionRateStatisticsVOS),
      },
      {
        key: 'solutionRevenueRankingCOS',
        component: ManageConversionEchart,
        title: this.$t('solution.SolutionRevenue'),
        hasData: data => data && data.data.length,
        data: this.initData(this.data.solutionRevenueRankingCOS),
      },
    ];
  }
  created() {
    this.getData();
  }

  initData(arr) {
    return arr && arr.length ? generateMap(arr, 'timeType', item => item) : [];
  }

  async getData() {
    this.loading = true;
    this.data = await solutionStatistics();
    this.loading = false;
  }
}
</script>
<style lang="less" module>
.SolutionManagement {
  padding-bottom: 20px;
  width: 100%;
  min-width: 1500px;
}
.chart_row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}
.echart {
  width: 100%;
}

.chart_item {
  height: 390px;
}
.chart_wrap {
  margin-bottom: 20px;
  border-radius: 1px;
  width: calc(33.33% - 13.3px);
  &:nth-child(3n-1) {
    margin: 0 20px;
  }
}
</style>
