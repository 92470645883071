<template>
  <div>
    <a-form-model ref="form" :model="formData" :rules="rules" layout="vertical">
      <a-form-model-item
        prop="imgName"
        :label="$t('common.label.imageName') + '：'"
      >
        <a-input v-model="formData.imgName" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';

@Component()
export default class RenameImageModal extends Vue {
  @Prop({ type: String, default: '' }) imgName;

  formData = {
    imgName: '',
  };
  rules = {
    imgName: [
      {
        required: true,
        message: this.$t('validate.required', {
          value: this.$t('common.label.imageName'),
        }),
        trigger: 'blur',
      },
    ],
  };
  created() {
    this.formData.imgName = this.imgName;
  }

  static createModal = createModal;
}

function createModal({ imgName }) {
  return createFormModal(
    render =>
      render(RenameImageModal, {
        props: { imgName },
      }),
    {
      title:
        globalVueI18n.translate('common.action.edit') +
        globalVueI18n.translate('common.label.imageName'),
      width: 440,
    },
  );
}
</script>

<style scoped></style>
