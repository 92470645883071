<template>
  <div :class="$style.mb15">
    <a-form-model-item
      v-if="name"
      prop="name"
      :label="$t('controls.config.name')"
    >
      <x-input
        :max-length="16"
        :value="field.name"
        @blur="validateName"
        @input="value => handleInput('name', value)"
      />
    </a-form-model-item>
    <a-form-model-item
      v-if="identifier"
      prop="widget.identifier"
      :label="$t('controls.config.identifier')"
      :rules="[
        {
          pattern: '^[a-zA-Z\\d_]+$',
          message: '只支持数字、字母、下划线',
        },
      ]"
    >
      <x-input
        :value="field.widget.identifier"
        :max-length="50"
        @input="value => handleInput('widget.identifier', value)"
      />
    </a-form-model-item>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { trim } from 'lodash';
import { getControl } from '../../utils';

@Component()
export default class Title extends Vue {
  @Prop() field;
  @Prop() updateField;
  @Prop({ type: Boolean, default: true }) identifier;
  @Prop({ type: Boolean, default: true }) name;

  handleInput(key, value) {
    this.updateField({ key: key, value: value });
  }

  get controlLabel() {
    return this.$t(getControl(this.field.type).label);
  }
  validateName() {
    if (!trim(this.field.name)) {
      this.handleInput('name', this.controlLabel);
    }
  }
}
</script>
<style lang="less" module>
.mb15 {
  margin-bottom: 15px;
}
</style>
