<template>
  <div>
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-form-model-item
        :label="$t('market.statistics.templateName')"
        prop="templateName"
      >
        <a-input
          :placeholder="$t('connector.dataTemplate.inputDpName')"
          :maxLength="50"
          v-model="form.templateName"
        />
      </a-form-model-item>
      <a-form-model-item
        v-if="!editName"
        :label="$t('market.statistics.templateType')"
        prop="templateType"
      >
        <a-radio-group
          v-model="form.templateType"
          :options="typeOptions"
          @change="typeChange"
        />
        <div :class="$style.info">
          {{ $t('gallery.label.remark') }}：{{ typeTips[form.templateType] }}
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { hasThingTemplate } from '@/services/iot-platform/dataTemplate';
import { TemplateType } from '@/views/connector/platform-manage/develop/data-template/template-design/form/controls';
@Component({})
export default class AddTemplate extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop() id;

  @Watch('editData', { immediate: true })
  changeData(newVal, oldVal) {
    if (newVal && newVal.pkId && newVal !== oldVal) {
      this.form.templateName = newVal.templateName;
      this.form.templateType = newVal.templateType;
    }
  }
  form = {
    templateName: '',
    templateType: 'MODEL_TEMPLATE',
  };
  rules = {
    templateName: [
      {
        type: 'string',
        required: true,
        validator: this.checkName,
        trigger: ['change', 'blur'],
      },
    ],
  };
  checkName(rule, value, callback) {
    const reg = /^[a-zA-Z\d_\u4e00-\u9fa5]+$/;
    if (value === '') {
      const str = this.$t('connector.dataTemplate.templateNameEmpty');
      callback(new Error(str));
    } else if (!reg.test(value)) {
      const str = this.$t('connector.dataTemplate.templateNameRule');
      callback(new Error(str));
    } else {
      callback();
    }
  }
  mounted() {
    this.getTemplateDisabled();
  }
  editName = false;
  async getTemplateDisabled() {
    try {
      if (this.editData.pkId) {
        this.editName = true;
      } else {
        const res = await hasThingTemplate(this.id);
        this.typeOptions[0]['disabled'] = res;
        if (res) {
          this.form.templateType = 'CUSTOM_TEMPLATE';
        }
      }
    } catch {
      return false;
    }
  }
  typeOptions = [
    {
      label: this.$t('connector.dataTemplate.modelTemplate'),
      value: TemplateType.MODEL_TEMPLATE,
    },
    {
      label: this.$t('connector.dataTemplate.customTemplate'),
      value: TemplateType.CUSTOM_TEMPLATE,
      disabled: false,
    },
  ];
  typeTips = {
    MODEL_TEMPLATE: this.$t('connector.dataTemplate.modelTemplateTips'),
    CUSTOM_TEMPLATE: this.$t('connector.dataTemplate.customTemolateTips'),
  };
  typeChange(e) {
    this.form.templateType = e.target.value;
  }
  async getValue() {
    await this.$refs.form.validate();
    return this.form;
  }
}
</script>
<style lang="less" module>
.info {
  color: var(--font-info);
}
</style>
