<template>
  <div :class="$style.desc">
    {{ desc }}
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class ConfigDesc extends Vue {
  @Prop() desc;
}
</script>
<style lang="less" module>
.desc {
  color: var(--font-info);
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 16px;
}
</style>
