var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-table',{ref:"table",class:_vm.$style.table,attrs:{"columns":_vm.columns,"base-path":"/resource/productFeedback/report","service":_vm.service,"custom-row":function (record) { return ({
      on: {
        click: function () { return _vm.openDetail(record.pkId); },
      },
      style: {
        cursor: 'pointer',
      },
    }); },"selected-row-keys":_vm.selections},on:{"update:selectedRowKeys":function($event){_vm.selections=$event},"update:selected-row-keys":function($event){_vm.selections=$event}},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.$p.action('DELETE'))?_c('a-button',{attrs:{"type":"link"},on:{"click":_vm.remove}},[_c('x-icon',{attrs:{"type":"tc-icon-delete"}}),_vm._v(" "+_vm._s(_vm.$t('common.action.delete'))+" ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }