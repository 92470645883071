var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('x-data-table',{ref:"table",class:_vm.$style.table,attrs:{"base-path":"/template-manager/secondary/node/statistics/identifyQuantity/detail","columns":_vm.columns,"service":_vm.service,"custom-row":function (record) { return ({
        on: {
          click: function () { return _vm.details(record); },
        },
        style: {
          cursor: 'pointer',
        },
      }); }},scopedSlots:_vm._u([{key:"header",fn:function(){return undefined},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }