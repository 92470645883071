<template>
  <div>
    <field-config-config-desc :desc="$t('controls.desc.array')" />
    <field-config-title v-bind="fieldProps" />
    <field-config-required
      v-if="!isRes && field.widget.subType !== ControlTypeEnum.struct"
      v-bind="fieldProps"
    />
    <a-form-model-item :label="$t('connector.dpManage.subParameterType')">
      <a-select
        :value="field.widget.subType"
        @change="v => handleInput('widget.subType', v)"
      >
        <a-select-option
          :value="item.key"
          v-for="item in Types"
          :key="item.key"
        >
          {{ item.value }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      prop="widget.subCount"
      :label="$t('connector.dpManage.subParametersCount')"
    >
      <template slot="label">
        <label>{{ $t('connector.dpManage.subParametersCount') }}</label>
        <a-tooltip :arrowPointAtCenter="true" placement="right">
          <div slot="title">{{ $t('connector.dpManage.inputTips4') }}</div>
          <a-icon type="info-circle" :class="$style.tooltipIcon" />
        </a-tooltip>
      </template>
      <section :class="this.$style.section">
        <a-input-number
          :value="field.widget.subCount"
          :max="10240"
          :min="1"
          :class="$style.item"
          @change="v => handleInput('widget.subCount', +v)"
        />
        <span :class="[this.$style.ml10, this.$style.label1]">
          个
        </span>
      </section>
    </a-form-model-item>
    <div v-if="field.widget.subType === ControlTypeEnum.struct">
      <a-form-model-item :label="$t('connector.dataType.rule.subIdentifier')">
        <x-input
          :value="field.widget.subName"
          @input="
            updateField({
              key: `widget.subName`,
              value: $event,
            })
          "
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('connector.dataType.rule.dataIdentifier')">
        <x-input
          :value="field.widget.subIdentifier"
          @input="
            updateField({
              key: `widget.subIdentifier`,
              value: $event,
            })
          "
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('connector.dataType.rule.groupDataIdentifier')"
      >
        <x-input
          :value="field.widget.subGroup"
          @input="
            updateField({
              key: `widget.subGroup`,
              value: $event,
            })
          "
        />
      </a-form-model-item>
    </div>
    <div v-else>
      <parameter-object v-if="!isRes" v-bind="fieldProps" isInOrOut="in" />
      <parameter-object v-if="isRes" v-bind="fieldProps" isInOrOut="out" />
      <a-form-model-item
        v-if="!isRes"
        prop="widget.defaultValue.custom"
        :label="$t('connector.dpManage.defaultValue')"
      >
        <a-input
          :value="field.widget.defaultValue.custom"
          :class="$style.item"
          @change="
            v => handleInput('widget.defaultValue.custom', v.target.value)
          "
        />
      </a-form-model-item>
    </div>
    <field-config-description v-bind="fieldProps" />
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import FieldConfigTitle from '../components/field-config/title.vue';
import FieldConfigRequired from '../components/field-config/required.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';
import ParameterObject from '../components/field-config/parameter-object.vue';
import { ParamType } from '../index';
import { templateDesignModule } from '@/enum/store';
import { ControlTypeEnum } from '../enum';

@Component({
  components: {
    FieldConfigTitle,
    FieldConfigRequired,
    FieldConfigDescription,
    FieldConfigConfigDesc,
    ParameterObject,
  },
})
export default class ArrayConfig extends Vue {
  @Prop() field;
  @Prop() updateField;
  @templateDesignModule.Getter paramType;

  ControlTypeEnum = ControlTypeEnum;

  get Types() {
    return [
      {
        key: ControlTypeEnum.Int,
        value: this.$t('controls.int.label'),
      },
      {
        key: ControlTypeEnum.Float,
        value: this.$t('controls.float.label'),
      },
      {
        key: ControlTypeEnum.Double,
        value: this.$t('controls.double.label'),
      },
      {
        key: ControlTypeEnum.Input,
        value: this.$t('controls.input.label'),
      },
      {
        key: ControlTypeEnum.Struct,
        value: this.$t('controls.struct.label'),
      },
    ];
  }

  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
    };
  }
  /** @name 出参 */
  get isRes() {
    return this.paramType === ParamType.RES_PARAM;
  }
  handleInput(key, value) {
    this.updateField({ key: key, value: value });
  }
}
</script>

<style lang="less" module>
.ml10 {
  margin-left: 10px;
}
.section {
  display: flex;
  width: 100%;
  .item {
    flex: 1;
  }
  .label2 {
    width: 32px;
  }
  .label1 {
    width: 15px;
  }
}
.tooltipIcon {
  margin: 0 5px;
  z-index: 101;
  position: relative;
}
</style>
