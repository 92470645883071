<script>
import { isNumber } from 'lodash';
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
@Component()
export default class RenderEnum extends Vue {
  @PropSync('item') syncItem;
  @PropSync('store') syncStore;
  @Prop() tooltip;
  @Prop() param;
  @Prop() schemeByType;
  @Prop() checkDefaultValue;
  @Prop({ type: String, default: 'id' }) useKey;

  render() {
    const item = this.syncItem;
    if (isNumber(this.syncStore.data[item[this.useKey]])) {
      this.syncStore.set(
        item[this.useKey],
        `${this.syncStore.data[item[this.useKey]]}`,
      );
    }
    return (
      <a-form-model-item
        class={this.$style.formItem}
        rules={[
          { required: item.required, message: '不能为空', trigger: 'blur' },
        ]}
        prop={item[this.useKey]}
      >
        <label slot="label" class={this.$style.label}>
          <span>{item.name}</span>
          {this.tooltip(item)}
          {this.param(item)}
        </label>
        <a-select
          mode={item.isSingle ? 'default' : 'multiple'}
          v-model={this.syncStore.data[item[this.useKey]]}
          placeholder={'请选择'}
        >
          {item.optionList &&
            item.optionList.map(v => {
              return (
                <a-select-option key={v.id} value={`${v.value}`}>
                  {v.text}
                </a-select-option>
              );
            })}
        </a-select>
      </a-form-model-item>
    );
  }
}
</script>
<style lang="less" module>
.formItem {
  :global {
    .ant-form-item-required {
      display: flex;
      align-items: center;
    }
    .ant-select {
      width: 100%;
    }
  }
}
.label {
  display: flex;
  align-items: center;
}
</style>
