<template>
  <div :class="$style.attestContainer">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" :tab="$t('enterprise.notCertified')">
        <AttestTable :tabsActive="'2'" />
      </a-tab-pane>
      <a-tab-pane key="2" :tab="$t('enterprise.Certified')">
        <AttestTable :tabsActive="'1'" />
      </a-tab-pane>
      <a-tab-pane key="3" :tab="$t('feedback.state.rejected')">
        <AttestTable :tabsActive="'3'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import AttestTable from './components/table';

@Component({
  components: { AttestTable },
})
export default class AttestView extends Vue {}
</script>

<style lang="less" module>
.attestContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
}
</style>
