// @ts-check
import { BaseControl } from '../BaseControl';
// @ts-ignore
import ConfigComponent from './config.vue';
// @ts-ignore
import DesignComponent from '../Input/design.vue';
/** @param { string } switchText */
function getSwitchText(switchText) {
  if (!switchText || typeof switchText !== 'string') return null;
  if (switchText.indexOf('/') !== -1) {
    const match = /^([^/]*?)\/?([^/]*?)$/.exec(switchText);
    if (!match) return null;
    return {
      checkedChildren: match[1],
      unCheckedChildren: match[2],
    };
  } else {
    return { checkedChildren: switchText };
  }
}

/** @name 是否 */
class SwitchControl extends BaseControl {
  /** @readonly */
  type = 'bool';

  label = 'controls.boolean.label';

  icon = 'tc-icon-switch';

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    switchText: '',
    defaultValue: {
      type: 'custom',
      custom: false,
    },
    identifier: 'BOOL',
    inParams: ['DEVELOPMENT'],
    outParams: [],
    is: '是',
    not: '否',
  });

  // @ts-ignore
  props = ({ widget: { switchText } }) => ({
    ...getSwitchText(switchText),
  });

  operation = ['eq', 'notEq'];

  compare = () => ({
    value: null,
  });

  /**
   * @name 字段特征定义
   * @type { object | ((field: import('../BaseControl').Field) => object) }
   * @returns  { object }
   */
  feature = {
    WRITABLE: true,
    PRINTABLE: true,
    BRIEFING: true,
    sorter: 'order',
  };

  FormComponent = DesignComponent;

  TableComponent = 'x-switch';

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;

  /**
   * @param {any} value
   * @param {{ widget: any; }} field
   */
  format = (value, field) => {
    const switchText = getSwitchText(field.widget.switchText);
    if (value) {
      return (switchText && switchText.checkedChildren) || '是';
    } else {
      return (switchText && switchText.unCheckedChildren) || '否';
    }
  };

  printable = true;
}

export default new SwitchControl();
