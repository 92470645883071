<template>
  <div :class="$style.statisticsWrap">
    <div :class="$style.statisticsList">
      <div id="main" :class="$style.viewBlock"></div>
      <div id="code" :class="$style.viewBlock"></div>
      <div id="price" :class="$style.viewBlock"></div>
    </div>
    <div :class="$style.invitationDetail">
      <x-data-table
        ref="table"
        :class="$style.table"
        :columns="columns"
        basePath="/tenant-center/tenant-invite-code/invite/detail"
        :service="service"
      >
        <div slot="headerLeft" :class="$style.headerL">
          <div :class="$style.title">
            {{ this.$t('inviteCode.invitation') }}
          </div>

          <div :class="$style.subTitle">
            <span>
              {{ $t('inviteCode.companiesNum', { value: allCount.company }) }}
            </span>
            <span>
              {{ $t('inviteCode.invitedUserNum', { value: allCount.user }) }}
            </span>
          </div>
        </div>
        <div slot="headerRight">
          <async-button
            type="primary"
            :click="exportData"
            v-if="$p.action('EXPORT')"
            >{{ $t('inviteCode.export') }}</async-button
          >
        </div>
      </x-data-table>
    </div>
    <div :class="$style.invitationCode">
      <x-data-table
        ref="companyTable"
        :class="$style.companyTable"
        :columns="companyColumns"
        base-path="/tenant-center-code/all/tenant/code/list"
        :service="companyService"
      >
        <div slot="headerLeft" :class="$style.headerL">
          <div :class="$style.title">
            {{ $t('inviteCode.invitationDetails') }}
          </div>
          <div :class="$style.subTitle">
            <span>
              {{ $t('inviteCode.corporate', { value: countData.allCount }) }}
            </span>
            <span>
              {{
                $t('inviteCode.Remaining', {
                  value: countData.remainingAvailable,
                })
              }}
            </span>
          </div>
        </div>
        <div slot="headerRight"></div>
      </x-data-table>
      <x-data-table
        ref="userTable"
        :class="$style.userTable"
        :columns="userColumns"
        base-path="/tenant-invite-code/all/member/code/list"
        :service="usersService"
      >
        <div slot="headerRight"></div>
        <div slot="headerLeft" :class="$style.headerL">
          <div :class="$style.title">
            {{ this.$t('inviteCode.invitationDetail') }}
          </div>
          <div :class="$style.subTitle">
            <span>
              {{ $t('inviteCode.corporate', { value: userCount.allCount }) }}
            </span>
            <span>
              {{
                $t('inviteCode.Remaining', {
                  value: userCount.remainingAvailable,
                })
              }}
            </span>
          </div>
        </div>
      </x-data-table>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import echarts from '../../components/echarts';
import { crossStorageModule } from '@/enum/store';
import { DataSourceService } from '@triascloud/services';
import {
  detail,
  rankData,
  statisticsData,
  amount,
  tenantCodeList,
  tenantCodeCount,
  exportDetail,
  inviteList,
  inviteCodeCount,
} from '@/services/tenant-center/tenant-invite-code';

import CompanyDetail from './components/company-detail.vue';
import PayDetail from './components/pay-detail.vue';
import AsyncButton from '@/components/async-button';
import { globalVueI18n } from '@triascloud/i18n';
import moment from 'moment';

@Component({
  components: { AsyncButton },
})
export default class InvitationView extends Vue {
  @crossStorageModule.State skin;
  get service() {
    const vm = this;
    class InactiveService extends DataSourceService {
      async getList({ skip, size }) {
        const res = await detail({
          orderBy: 'INVITE_TENANT_COUNT',
          size: size,
          skip: skip,
        });
        vm.allCount.company = res.inviteTenant;
        vm.allCount.user = res.inviteMember;
        return res;
      }
    }
    return new InactiveService(
      '/tenant-center/tenant-invite-code/invite/detail',
    );
  }
  data = {
    current: 1,
    endTime: null,
    startTime: null,
    size: 10,
  };
  countData = {
    allCount: 0,
    remainingAvailable: 0,
  };
  userCount = {
    allCount: 0,
    remainingAvailable: 0,
  };
  allCount = {
    company: 0,
    user: 0,
  };
  get columns() {
    return [
      {
        dataIndex: 'tenantName',
        title: this.$t('inviteCode.enterpriseName'),
      },
      {
        dataIndex: 'tenantCodeCount',
        title: this.$t('inviteCode.codesCorporate'),
      },
      {
        dataIndex: 'inviteTenantCount',
        title: this.$t('inviteCode.companies'),
        customRender: (num, res) => {
          return (
            <span
              style="color:var(--primary);cursor: pointer;"
              onClick={() => this.detail(res)}
            >
              {num}
            </span>
          );
        },
      },
      {
        dataIndex: 'inviteTenantSort',
        title: this.$t('inviteCode.invitSort'),
      },
      {
        dataIndex: 'inviteTenantPayCount',
        title: this.$t('inviteCode.payNum'),
        customRender: (num, res) => {
          return (
            <span
              style="color:var(--success);cursor: pointer;"
              onClick={() => this.payDetailModal(res)}
            >
              {num}
            </span>
          );
        },
      },
      {
        dataIndex: 'tenantPayAmount',
        title: this.$t('inviteCode.amountPay'),
        customRender: price => {
          return <span>{price / 100}</span>;
        },
      },
      {
        dataIndex: 'inviteTenantPaySort',
        title: this.$t('inviteCode.companiesSort'),
      },
      {
        dataIndex: 'memberCodeCount',
        title: this.$t('inviteCode.invitationCode'),
      },
      {
        dataIndex: 'inviteMemberCount',
        title: this.$t('inviteCode.usersNum'),
      },
      {
        dataIndex: 'inviteMemberSort',
        title: this.$t('inviteCode.invitedUser'),
      },
      {
        dataIndex: 'allSort',
        title: this.$t('inviteCode.allSort'),
      },
    ].map(item => ({
      ...item,
      width: item.width || 150,
      sorter: false,
      filter: false,
      align: 'center',
      ellipsis: false,
    }));
  }

  get companyColumns() {
    return [
      {
        dataIndex: 'code',
        title: this.$t('inviteCode.enterpriseCode'),
        customRender: code =>
          code
            ? code.replace(code.charAt(2), '*').replace(code.charAt(3), '*')
            : '',
      },
      {
        dataIndex: 'creatorTenant',
        title: this.$t('inviteCode.business'),
      },
      {
        dataIndex: 'useMember',
        title: this.$t('inviteCode.user'),
      },
      {
        dataIndex: 'useTenant',
        title: this.$t('inviteCode.useEnterprise'),
      },
      {
        dataIndex: 'useTime',
        title: this.$t('inviteCode.usageTime'),
        customRender: useTime =>
          useTime ? moment(useTime).format('Y-MM-DD HH:mm') : '',
      },
    ].map(item => ({
      ...item,
      sorter: false,
      filter: false,
      align: 'center',
      ellipsis: false,
    }));
  }

  get userColumns() {
    return [
      {
        dataIndex: 'code',
        title: this.$t('inviteCode.userCodeSet'),
        customRender: code =>
          code
            ? code.replace(code.charAt(2), '*').replace(code.charAt(3), '*')
            : '',
      },
      {
        dataIndex: 'creatorTenant',
        title: this.$t('inviteCode.business'),
      },
      {
        dataIndex: 'useMember',
        title: this.$t('inviteCode.user'),
      },
      {
        dataIndex: 'useTenant',
        title: this.$t('inviteCode.enterprise'),
      },
      {
        dataIndex: 'useTime',
        title: this.$t('inviteCode.usageTime'),
        customRender: useTime =>
          useTime ? moment(useTime).format('Y-MM-DD HH:mm') : '',
      },
    ].map(item => ({
      ...item,
      sorter: false,
      filter: false,
      align: 'center',
      ellipsis: false,
    }));
  }
  rankData = [];
  statisticsData = [];
  amountData = [];
  async getRankData() {
    this.rankData = await rankData();
  }
  async count() {
    this.countData = await tenantCodeCount();
    this.userCount = await inviteCodeCount();
  }

  async detail(res) {
    await CompanyDetail.create({ tenantId: res.tenantId });
  }

  async payDetailModal(res) {
    await PayDetail.create({ tenantId: res.tenantId });
  }

  async exportData() {
    await exportDetail();
  }

  get companyService() {
    const service = new DataSourceService(
      '/tenant-center/all/tenant/code/list',
    );
    service.getList = ({ skip, size, keyword }) =>
      tenantCodeList({
        ifUsed: 'ALL',
        endTime: null,
        startTime: null,
        current: Math.ceil(skip / size) + 1,
        keyword,
        size,
      }).then(res => {
        return {
          ...res,
          data: res.records,
        };
      });
    return service;
  }
  get usersService() {
    const service = new DataSourceService(
      '/tenant-invite-code/all/member/code/list',
    );
    service.getList = ({ skip, size, keyword }) =>
      inviteList({
        ifUsed: 'ALL',
        endTime: null,
        startTime: null,
        current: Math.ceil(skip / size) + 1,
        keyword,
        size,
      }).then(res => {
        return {
          ...res,
          data: res.records,
        };
      });
    return service;
  }

  async getAmount() {
    const data = await amount();
    // data.sort(this.compare('payTheAmount'));
    let other = {
      payTenantCount: 0,
      payTheAmount: 0,
      tenantCount: 0,
      tenantName: this.$t('inviteCode.other'),
    };
    if (data && data.length > 9) {
      data.splice(9, data.length - 1).map(item => {
        other.payTenantCount += item.payTenantCount;
        other.payTheAmount += item.payTheAmount;
        other.tenantCount += item.tenantCount;
      });
      this.amountData = [...data.splice(0, 9), other];
    } else {
      this.amountData = data;
    }
  }

  async getStatisticsData() {
    const data = await statisticsData();
    // data.sort(this.compare('tenantCount'));
    let other = {
      payTenantCount: 0,
      payTheAmount: 0,
      tenantCount: 0,
      tenantName: this.$t('inviteCode.other'),
    };
    if (data && data.length > 9) {
      data.splice(9, data.length - 1).map(item => {
        other.payTenantCount += item.payTenantCount;
        other.payTheAmount += item.payTheAmount;
        other.tenantCount += item.tenantCount;
      });
      this.statisticsData = [...data.splice(0, 9), other];
    } else {
      this.statisticsData = data;
    }
  }

  //   compare(p) {
  //     return function(m, n) {
  //       var a = m[p];
  //       var b = n[p];
  //       return b - a; //升序
  //     };
  //   }

  async mounted() {
    await this.getRankData();
    await this.getStatisticsData();
    await this.getAmount();
    await this.count();
    // 基于准备好的dom，初始化echarts实例
    // const echarts = installer();
    const myChart = echarts.init(document.getElementById('main'), this.skin);
    const codeChart = echarts.init(document.getElementById('code'), this.skin);
    const priceChart = echarts.init(
      document.getElementById('price'),
      this.skin,
    );

    // 绘制图表
    myChart.setOption({
      title: {
        text: this.$t('inviteCode.ranking'),
        textStyle: {
          fontSize: 14,
        },
        left: 40,
        top: 20,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        top: 20,
        right: 20,
        itemWidth: 8,
        itemHeight: 8,
        itemStyle: {
          borderColor: 'red',
        },
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },
      grid: {
        left: '5%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        // data:['0%', 100, 200,]
      },
      yAxis: {
        type: 'category',
        axisTick: {
          show: false,
          inside: true,
        },
        axisLine: {
          show: false,
        },
        data:
          this.rankData.length >= 10
            ? this.rankData
                .map(item => item.tenantName)
                .splice(0, 9)
                .reverse()
            : this.rankData.map(item => item.tenantName).reverse(),
        axisLabel: {
          width: 20,
          formatter: function(value) {
            if (value.length >= 9) {
              let v = value.slice(0, 9) + '...';
              return v;
            } else {
              return value;
            }
          },
        },
      },
      series: [
        {
          name: this.$t('inviteCode.InviteUsers'),
          type: 'bar',
          data:
            this.rankData.length >= 10
              ? this.rankData
                  .map(item => item.inviteMember)
                  .splice(0, 9)
                  .reverse()
              : this.rankData.map(item => item.inviteMember).reverse(),
          color: ['#68b4ff'],
        },
        {
          name: this.$t('inviteCode.invitingCompanies'),
          type: 'bar',
          data:
            this.rankData.length >= 10
              ? this.rankData
                  .map(item => item.inviteTenant)
                  .splice(0, 9)
                  .reverse()
              : this.rankData.map(item => item.inviteTenant).reverse(),
          color: ['#25b97b'],
        },
      ],
    });

    codeChart.setOption({
      title: [
        {
          text: this.$t('inviteCode.invitationStatistics'),
          textStyle: {
            fontSize: 14,
          },
          left: 40,
          top: 20,
        },
        {
          text: this.$t('inviteCode.invitationsTip'),
          textStyle: {
            fontSize: 12,
            color: getComputedStyle(document.documentElement).getPropertyValue(
              '--font-info',
            ),
            fontWeight: 'normal',
          },
          left: 140,
          top: 22,
        },
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        top: 20,
        right: 20,
        itemWidth: 8,
        itemHeight: 8,
        data: [
          this.$t('inviteCode.invitationsCount'),
          this.$t('inviteCode.purchases'),
        ],
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },
      grid: {
        left: '5%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      yAxis: {
        // data:['0%', 100, 200,]
      },
      xAxis: {
        data: this.statisticsData.map(item => item.tenantName),
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          rotate: 45,
          formatter: function(value) {
            // 超出8个字符后面就用省略号代替
            if (value.length >= 7) {
              let v = value.slice(0, 7) + '...';
              return v;
            } else {
              return value;
            }
          },
        },
      },
      series: [
        {
          name: this.$t('inviteCode.invitationsCount'),
          type: 'bar',
          data: this.statisticsData.map(item => item.tenantCount),
          color: ['#68b4ff'],
          stack: 'one',
          barWidth: 30,
        },
        {
          name: this.$t('inviteCode.purchases'),
          type: 'bar',
          data: this.statisticsData.map(item => item.payTenantCount),
          color: ['#25b97b'],
          stack: 'one',
          barWidth: 30,
        },
      ],
    });
    priceChart.setOption({
      tooltip: {
        trigger: 'item',
        formatter: function(params) {
          return [
            params.data.name + '<br/>',
            globalVueI18n.translate('inviteCode.companiesPay') +
              ': ' +
              (params.data.value / 100 >= 10000
                ? params.data.value / 1000000 +
                  globalVueI18n.translate('inviteCode.unit')
                : params.data.value / 100 +
                  globalVueI18n.translate('inviteCode.Yuan')) +
              '<br/>',
            globalVueI18n.translate('inviteCode.companiesPaid') +
              ': ' +
              params.percent +
              '<br/>',
            globalVueI18n.translate('inviteCode.invitedPay') +
              ': ' +
              params.data.payTenantCount +
              '<br/>',
          ].join('');
        },
      },
      title: [
        {
          text: this.$t('inviteCode.paymentAmount'),
          textStyle: {
            fontSize: 14,
          },
          left: 20,
          top: 20,
        },
        {
          text: this.$t('inviteCode.enterpriseAmount'),
          textStyle: {
            fontSize: 16,
            color: '#999',
          },
          left: '40%',
          top: 140,
        },
        {
          //   text:
          //     this.amountData.reduce((total, currentValue) => {
          //       return total + currentValue.payTheAmount;
          //     }, 0) / 100,
          text: [
            `{a|${
              this.amountData && this.amountData.length
                ? this.amountData.reduce((total, currentValue) => {
                    return total + currentValue.payTheAmount;
                  }, 0) /
                    100 >
                  10000
                  ? this.amountData.reduce((total, currentValue) => {
                      return total + currentValue.payTheAmount;
                    }, 0) / 1000000
                  : this.amountData.reduce((total, currentValue) => {
                      return total + currentValue.payTheAmount;
                    }, 0) / 100
                : 0
            }}`,
            `{b|${
              this.amountData.reduce((total, currentValue) => {
                return total + currentValue.payTheAmount;
              }, 0) /
                100 >=
              10000
                ? this.$t('inviteCode.unit')
                : this.$t('inviteCode.Yuan')
            }}`,
          ].join(''),
          textStyle: {
            fontSize: 36,
            color: '#333',
            rich: {
              a: {
                fontSize: 36,
                color: '#333',
              },
              b: {
                fontSize: 16,
                color: '#333',
                verticalAlign: 'bottom',
                lineHeight: 40,
                align: 'center',
                padding: [0, 0, 6, 4],
              },
            },
          },
          //   formatter: ,
          left: '40%',
          top: 170,
        },
      ],
      legend: {
        top: 50,
        right: '3%',
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
        formatter: function(value) {
          // 超出8个字符后面就用省略号代替
          if (value.length >= 6) {
            let v = value.slice(0, 6) + '...';
            return v;
          } else {
            return value;
          }
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          //   itemStyle: {
          //     borderRadius: 2,
          //     borderColor: '#fff',
          //     borderWidth: 2,
          //   },
          label: {
            show: true,
            formatter: '{d}%',
            position: 'inside',
            color: '#fff',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '32',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: true,
          },
          data: this.amountData.map(item => {
            return {
              value: item.payTheAmount,
              name: item.tenantName,
              payTenantCount: item.payTenantCount,
            };
          }),
        },
      ],
    });
  }
}
</script>
<style lang="less" module>
.viewBlock {
  height: 360px;
  flex: 1;
  min-width: 536px;
  box-shadow: 0px 0px 8px 0px var(--shadow);
  border-radius: 4px;
  position: relative;
  &:nth-child(2) {
    margin: 0px 20px;
  }
}
.statisticsList {
  display: flex;
  flex-direction: row;
  margin: 20px;
}
.statisticsWrap {
  min-width: 1690px;
}
.invitationDetail {
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 0px 8px 0px var(--shadow);
  border-radius: 4px;
}
.invitationCode {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.companyTable {
  box-shadow: 0px 0px 8px 0px var(--shadow);
  border-radius: 4px;
  padding: 20px;
  height: 620px;
}
.userTable {
  margin-left: 20px;
  box-shadow: 0px 0px 8px 0px var(--shadow);
  border-radius: 4px;
  padding: 20px;
  height: 620px;
}
.table {
  height: 620px;
}

.headerL {
  display: flex;
  flex-direction: row;
  align-items: center;
  .title {
    color: var(--font);
    font-size: 14px;
  }
  .subTitle {
    color: var(--font-info);
    font-size: 12px;
    margin-left: 20px;
    span:last-child {
      margin-left: 8px;
    }
  }
}
</style>
