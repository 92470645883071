<template>
  <a-select
    show-search
    v-model="form.tenantId"
    placeholder="请选择"
    v-bind="$attrs"
    option-filter-prop="children"
    @change="handleChange"
    :filter-option="false"
    @search="v => selectSearch(v)"
    @dropdownVisibleChange="onVisibleChange"
  >
    <a-select-option
      :value="item.id"
      v-for="item in companyList"
      :key="item.id"
      :disabled="item.tenantStatus === 'DISABLE'"
    >
      {{
        item.tenantStatus === 'DISABLE' ? `${item.value}(已停用)` : item.value
      }}
    </a-select-option>
  </a-select>
</template>
<script>
import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { reqGetCompany } from '@/services/hat';
import { Debounce } from 'lodash-decorators';

@Component()
export default class ComponySelect extends Vue {
  @Model('bindTenantId', { type: String }) tenantId;
  @Prop({ type: Object }) initialValue;

  mounted() {
    this.getCompanyList();
    this.insertGroup();
  }
  form = {
    tenantId: undefined,
  };
  insertGroup() {
    this.form.tenantId = this.initialValue && this.initialValue.value;
  }
  handleChange(v) {
    this.$emit('bindTenantId', v);
    const idx = this.companyList.findIndex(item => v === item.id);
    this.$emit('change', this.companyList[idx]);
  }
  companyList = [];
  async getCompanyList(keyword = undefined) {
    const { data } = await reqGetCompany({ current: 1, size: 50, keyword });
    this.companyList = data.map(item => ({
      id: item.pkId,
      value: item.enterpriseName,
      tenantStatus: item.tenantStatus,
    }));
    if (this.initialValue?.label) {
      this.companyList.unshift({
        id: this.initialValue.value,
        value: this.initialValue.label,
      });
    }
  }
  onVisibleChange() {
    if (this.isSearch) {
      this.getCompanyList();
      this.isSearch = false;
    }
  }
  isSearch = false;
  @Debounce(300)
  selectSearch(keyword) {
    this.isSearch = true;
    this.getCompanyList(keyword);
  }
}
</script>
