import { uuid } from '@triascloud/utils';
import { ControlTypeEnum } from '@/views/connector/platform-manage/develop/data-template/template-design/form/controls/enum.js';

// * arr 是返回数据
// * obj 是主题结构
// * hasVO 区分数组是不是详情
export function formatData(obj, arr, initKeys = [], moment) {
  if (!arr.length)
    return {
      res: [],
      data: [],
    };
  const res = [];
  const data = [];
  try {
    arr.forEach(v => {
      const o = {};
      initKeys.forEach(ik => {
        if (ik === 'triascloud_create_time') {
          o[ik] = moment(v[ik]).format('YYYY-MM-DD HH:mm:ss');
        } else {
          o[ik] = v[ik] || '';
        }
      });
      for (let key in v) {
        if (obj[key] && initKeys.indexOf(key) === -1) {
          if (obj[key].type === ControlTypeEnum.Array) {
            if (isValue(v[key])) {
              o[key] = changeArray(v, obj, key);
            } else if (v[key] === '-' || !v[key]) {
              if (!v[key]) {
                v[key] = '-';
              }
              o[key] = changeArray(v, obj, key);
            }
          } else if (obj[key].type === ControlTypeEnum.Struct) {
            const arr1 = [];
            for (const key1 in v[key]) {
              const index = obj[key].sonVO.findIndex(
                son => son.identifier === key1,
              );
              const s = obj[key].sonVO[index];
              const o1 = {
                name: `${s.name}`,
                key: `${key}_${s.identifier}`,
                value: '',
                isArray: false,
              };
              const type = s.dataType.toLowerCase();
              if (type === ControlTypeEnum.Date) {
                const value = changeDateValue(
                  v[key][s.identifier],
                  moment,
                  s.unit,
                );
                o1.value = `${value}`;
              } else if (type === ControlTypeEnum.Switch) {
                const value = changeBoolValue(v[key][s.identifier], s.other);
                o1.value = `${value}${s.unit}`;
              } else if (type === ControlTypeEnum.Enum) {
                const value = changeEnumValue(v[key][s.identifier], s.other);
                o1.value = `${value}${s.unit}`;
              } else if (type === ControlTypeEnum.Array) {
                const val = v[key][s.identifier];
                const obj1 = changeStruct([s]);
                if (isValue(val)) {
                  if (val.length > 0) {
                    if (s.sonVO.length > 0) {
                      o1.isArray = true;
                      o1['sonVO'] = changeArray(v[key], obj1, key1);
                    } else {
                      o1.value = JSON.stringify(val);
                    }
                  } else {
                    o1.isArray = true;
                    const test = changeTest(v[key], obj1, key1);
                    o1['sonVO'] = changeArray(test, obj1, key1);
                  }
                } else {
                  o1.value = '-';
                }
              } else {
                if (isValue(v[key][s.identifier])) {
                  o1.value = `${v[key][s.identifier]}${s.unit}`;
                } else {
                  o1.value = `-${s.unit}`;
                }
              }
              arr1.push(o1);
            }
            o[key] = {
              hasVO: false,
              sonVO: arr1,
              type: obj[key].type,
            };
          } else if (obj[key].type === ControlTypeEnum.Date) {
            const value = changeDateValue(v[key], moment, obj[key].unit);
            o[key] = {
              ...obj[key],
              pkId: uuid(),
              value: `${value}`,
            };
          } else if (obj[key].type === ControlTypeEnum.Switch) {
            const value = changeBoolValue(v[key], obj[key].other);
            o[key] = {
              ...obj[key],
              pkId: uuid(),
              value: `${value}${obj[key].unit}`,
            };
          } else if (obj[key].type === ControlTypeEnum.Enum) {
            const value = changeEnumValue(v[key], obj[key].other);
            o[key] = {
              ...obj[key],
              pkId: uuid(),
              value: `${value}${obj[key].unit}`,
            };
          } else {
            o[key] = {
              ...obj[key],
              pkId: uuid(),
              value: isValue(v[key])
                ? `${v[key]}${obj[key].unit}`
                : `${obj[key].unit}`,
            };
          }
        }
      }
      res.push(o);
    });
    res.forEach(v => {
      const o = {
        pkId: uuid(),
        triascloud_device_code: v.triascloud_device_code,
        triascloud_create_time: moment(v.triascloud_create_time).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
        triascloud_source_data: v.triascloud_source_data,
      };
      for (const key in v) {
        if (typeof v[key] === 'object') {
          if (v[key].hasVO) {
            if (v[key].sonVO.length > 0) {
              o[key] = v[key].sonVO;
            }
          } else {
            if (v[key].sonVO) {
              v[key].sonVO.forEach(son => {
                if (son.isArray) {
                  o[son.key] = son.sonVO.sonVO;
                } else {
                  o[son.key] = son.value;
                }
              });
            } else {
              o[key] = v[key].value;
            }
          }
        }
      }
      data.push(o);
    });
  } catch {
    // console.log('🚀~ e', e);
    return false;
  }
  // console.log('🚀~ res', res);
  // console.log('🚀~ data', data);
  return {
    res,
    data,
  };
}

// * 生成结构obj
function changeStruct(struct) {
  const structArr = {};
  struct.forEach(v => {
    structArr[v.identifier] = {
      name: v.name,
      type: v.dataType.toLowerCase(),
      other: v.other,
      unit: v.unit,
    };
    if (v.sonVO.length) {
      structArr[v.identifier]['sonVO'] = v.sonVO;
      structArr[v.identifier]['hasVO'] = true;
    } else {
      structArr[v.identifier]['hasVO'] = false;
    }
  });
  return structArr;
}

// * 生成结构Test
function changeTest(v, obj, key) {
  const o = {};
  obj[key].sonVO.forEach(v => {
    o[v.identifier] = null;
  });
  return {
    [key]: [o],
  };
}

// * 更改Array数据显示
function changeArray(v, obj, key) {
  let o = {};
  if (typeof v[key][0] === 'object') {
    o = {
      pkId: uuid(),
      name: obj[key].name,
      type: obj[key].type,
      hasVO: true,
      sonVO: [],
    };
    v[key].forEach(val => {
      const arr1 = [];
      for (const key1 in val) {
        const index = obj[key].sonVO.findIndex(son => son.identifier === key1);
        const s = obj[key].sonVO[index];
        const ao = {
          ...s,
          pkId: uuid(),
          value: '-',
        };
        if (val[s.identifier] !== null) {
          ao.value = val[s.identifier];
        }
        arr1.push(ao);
      }
      o.sonVO.push(arr1);
    });
  } else {
    o = {
      ...obj[key],
      hasVO: false,
      pkId: uuid(),
      value: JSON.stringify(v[key]),
    };
  }
  return o;
}

// * 更改EMUM数据显示
function changeEnumValue(val, other) {
  if (!isValue(val)) return '-';
  const oth = JSON.parse(other);
  const idx = oth.findIndex(v => String(v.value) === String(val));
  return oth[idx].key;
}

// * 更改BOOL数据显示
function changeBoolValue(val, other) {
  if (!isValue(val)) return '-';
  let b = val;
  if (typeof val === 'boolean') {
    b = val ? 0 : 1;
  }
  const oth = JSON.parse(other);
  return oth[b];
}

// * 更改Date数据显示
function changeDateValue(val, moment, format) {
  if (isValue(val)) {
    return moment(val).format(format || 'YYYY-MM-DD HH:mm:ss');
  } else {
    return '-';
  }
}

function isValue(val) {
  return val !== '' && val !== undefined && val !== null && val !== '-';
}

export function formateType(t, flag) {
  const obj = {
    [ControlTypeEnum.Int]: {
      lable: '整数',
      color: '#FF3535',
    },
    [ControlTypeEnum.Float]: {
      lable: '浮点',
      color: '#FFC12B',
    },
    [ControlTypeEnum.Double]: {
      lable: '双精度',
      color: '#339DEE',
    },
    [ControlTypeEnum.Input]: {
      lable: '文本',
      color: '#848BE2',
    },
    [ControlTypeEnum.Date]: {
      lable: '日期',
      color: '#9375CD',
    },
    [ControlTypeEnum.Switch]: {
      lable: '布尔',
      color: '#7987CB',
    },
    [ControlTypeEnum.Struct]: {
      lable: '结构体',
      color: '#4FC4F7',
    },
    [ControlTypeEnum.Array]: {
      lable: '数组',
      color: '#4DD1E2',
    },
    [ControlTypeEnum.Enum]: {
      lable: '枚举',
      color: '#EE9A9B',
    },
    [ControlTypeEnum.Image]: {
      lable: '图片',
      color: '#E57375',
    },
    [ControlTypeEnum.File]: {
      lable: '文件',
      color: '#FEB74B',
    },
    [ControlTypeEnum.DataPoint]: {
      lable: 'DP',
      color: '#EEE42A',
    },
  };
  // DP的子项没有type
  if (!t) {
    return flag === 'type' ? 'DP' : '#EAEAEA';
  }
  // DP的数据标识的格式
  if (!obj[t]) {
    return flag === 'type' ? t : '#EAEAEA';
  }
  return flag === 'type' ? obj[t].lable : obj[t].color;
}
