<template>
  <component
    :is="groupComponent"
    :class="$style.group"
    :value="defaultValue"
    @change="handleSetDefaultValue"
  >
    <draggable
      :value="options"
      @input="updateField({ key: 'widget.optionList', value: $event })"
      :handle="`.${$style.handler}`"
      :animation="300"
    >
      <div
        :class="$style.item"
        v-for="(option, index) in options"
        :key="option.id"
      >
        <a-row>
          <a-col :span="2" style="line-height:60px;">
            <component
              :is="optionComponent"
              :value="option.id"
              @click="handleOptionClick"
            />
          </a-col>
          <a-col :span="18">
            <a-input
              type="text"
              :value="option.text"
              :style="option.bold ? 'font-weight: bold' : ''"
              @input="
                updateField({
                  key: `widget.optionList.${index}.text`,
                  value: $event.target.value,
                })
              "
              @blur="handleUpdateOptionValue(index, 'text')"
            />
            <a-input
              type="text"
              :value="option.value"
              @input="
                updateField({
                  key: `widget.optionList.${index}.value`,
                  value: $event.target.value,
                })
              "
              @blur="handleUpdateOptionValue(index, 'value')"
            />
          </a-col>
          <a-col :span="2" style="line-height:60px;">
            <x-icon type="tc-icon-drag-handler" :class="$style.handler" />
          </a-col>
          <a-col :span="2" style="line-height:60px;">
            <x-icon type="tc-icon-delete" @click="handleDeleteOption(index)" />
          </a-col>
        </a-row>
      </div>
    </draggable>
    <div :class="$style.action">
      <a-button type="link" @click="handleAddOption">
        {{ $t('controls.label.addOption') }}
      </a-button>
    </div>
  </component>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ensureArray, uuid } from '@triascloud/utils';
// import { unwrapValue } from '../../../utils';
import { max } from 'lodash';

@Component({
  components: {},
})
export default class FieldConfigOptionsCustom extends Vue {
  /** @type { import('../../../BaseControl').Field } */
  @Prop() field;
  @Prop() updateField;

  /** @name 将原本对象类型的默认值转换成group组件的值 */
  get defaultValue() {
    const { custom } = this.field.widget.defaultValue;
    if (!custom) return custom;
    // const unwrapped = unwrapValue(custom);
    return custom;
  }

  /** @name 自定义选项数组 */
  get options() {
    return ensureArray(this.field.widget.optionList);
  }

  get multiple() {
    return !this.field.widget.isSingle;
  }

  get groupComponent() {
    return this.field.widget.isSingle ? 'a-radio-group' : 'a-checkbox-group';
  }
  get optionComponent() {
    return this.field.widget.isSingle ? 'a-radio' : 'a-checkbox';
  }

  /**
   * @name 输入框blur时更新选项的value，同时更新默认值
   * @todo 选项去重
   */
  handleUpdateOptionValue(index, key) {
    this.updateField(field => {
      const { optionList } = field.widget;

      // 当前操作的选项
      const item = optionList[index];

      if (!item) return;
      // 判断选项是否为空或者为空格
      if (!item[key].replace(/\s+/g, '')) {
        this.$message.warning('输入内容不能为空');
        return field;
      }

      // 判断值是否重复
      const valSet = new Set();
      for (let item of optionList) {
        valSet.add(item[key]);
      }
      if (valSet.size < optionList.length) {
        this.$message.warning('选项已存在');
        return field;
      }

      return field;
    });
  }

  /** @name 设置字段默认值 */
  handleSetDefaultValue(ev) {
    const value = this.multiple ? ev : ev.target.value;
    this.updateField({
      key: 'widget.defaultValue.custom',
      value,
    });
  }

  /** @name 点击已选选项时清空单选的默认值 */
  handleOptionClick(ev) {
    if (this.multiple || this.defaultValue !== ev.target.value) return;
    ev.stopPropagation();
    this.updateField({
      key: 'widget.defaultValue.custom',
      value: undefined,
    });
  }

  /** @name 添加选项 */
  handleAddOption() {
    const { optionList } = this.field.widget;
    const label = '选项';
    const index =
      ~~max(optionList.map(item => ~~parseInt(item.text.replace(label, '')))) +
      1;
    this.updateField({
      key: 'widget.optionList',
      value: [
        ...optionList,
        {
          id: uuid(),
          value: `option${index}`,
          text: `${label}${index}`,
        },
      ],
    });
  }

  /** @name 删除选项同时删除默认值 */
  handleDeleteOption(index) {
    this.updateField(field => {
      const defaultValue = this.field.widget.defaultValue.custom;
      const deleteItem = field.widget.optionList.splice(index, 1);
      if (this.multiple) {
        let idx = defaultValue.findIndex(id => id === deleteItem[0].id);
        if (idx >= 0) {
          this.field.widget.defaultValue.custom.splice(idx, 1);
        }
      } else if (defaultValue === deleteItem.id) {
        this.field.widget.defaultValue.custom = undefined;
      }
    });
  }
}
</script>
<style lang="less" module>
.group {
  display: block;
}
.item {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  :global {
    .ant-checkbox-wrapper,
    .ant-radio-wrapper {
      line-height: 1;
      margin-right: 8px;
    }
    input.ant-input {
      height: 32px;
      font-size: 12px;
      margin-bottom: 5px;
      flex: 1;
    }
    .x-icon {
      font-size: 16px;
      padding-left: 5px;
    }
  }
}
.handler {
  cursor: grab;
}
.setting {
  cursor: pointer;
}
.action {
  margin-top: 4px;
  padding-left: 8px;
  display: flex;
  height: 32px;
  :global(.ant-btn.ant-btn-link) {
    font-size: 12px;
    padding: 0 16px;
    position: relative;
    &:not(:last-child)::after {
      content: '';
      height: 12px;
      border-left: 1px solid var(--primary);
      position: absolute;
      top: 10px;
      right: -1px;
      display: block;
    }
  }
}
.color-picker {
  margin-right: 5px;
}
.option-image {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  margin-right: 5px;
}
.option-setting {
  height: 36px;
  padding: 0 10px;
  background: var(--dropdown-bg);
  border-radius: 4px;
  color: var(--font);

  :global(.ant-dropdown-content) {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;

    i {
      cursor: pointer;
      &:hover {
        color: var(--primary);
      }
    }

    i + i {
      margin-left: 15px;
    }
  }
}
</style>
