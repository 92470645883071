// @ts-check
/** @typedef { import('vue').VueConstructor | import('vue').FunctionalComponentOptions | string } VueConstructor */

/** @typedef { import('./FormatContext').CacheRequest } CacheRequest */

/** @typedef { import('./FormatContext').FormatContext } FormatContext */

/** @typedef { keyof typeof import('../form-compare').Operator } CompareOperator */

export class BaseControl {
  /**
   * @name 控件类型
   * @type { import('./utils').ControlType }
   */
  type = 'tabs';

  /** @name 是否静态控件（静态控件的type与其在data中的key一致） */
  static = false;

  /** @name 控件展示名称（国际化key） */
  label = '';

  /** @name 控件图标 */
  icon = '';

  /** @name 触发表单变更事件 */
  trigger = 'change';

  /** @name 在表格中展示时是否根据内容自动计算列宽  */
  autoColumnWidth = false;

  /** @name 在表单设计器中是否允许被点击内部 */
  actionOpen = false;

  /**
   * @name 组件通用属性
   * @returns { Widget & Record<string, any> }
   */
  widget() {
    return {
      defaultValue: {
        type: 'custom',
        custom: null,
      },
      identifier: '',
      placeholder: '',
      required: false,
      invalidMessage: '',
      view: false,
      description: '',
      hideTitle: false,
      visible: true,
      alone: false, // 独立字段，不允许操作
      parentId: null, // 父级id
    };
  }

  /**
   * @name 控件props
   * @param { Field } _field
   * @returns { object }
   */
  // eslint-disable-next-line no-unused-vars
  props(_field) {
    return {};
  }

  /**
   * @name 控件验证规则
   * @param { Field } _field
   * @returns { import('async-validator').RuleItem[] }
   */
  // eslint-disable-next-line no-unused-vars
  rules(_field) {
    return [];
  }

  /**
   * @name 操作集合
   * @type { CompareOperator[] | ((field: Field) => CompareOperator[] | null | undefined) | null | undefined }
   */
  operation = null;

  /** @name 对比属性 */
  compare = () => ({});

  /**
   * @name 表单组件
   * @type { VueConstructor | null }
   */
  FormComponent = null;

  /**
   * @name 布局组件
   * @type { VueConstructor | null }
   */
  LayoutComponent = null;

  /**
   * @name 属性配置组件
   * @type { VueConstructor | null }
   */
  ConfigComponent = null;

  /**
   * @name 表单设计组件
   * @type { VueConstructor | null }
   */
  DesignComponent = null;

  /**
   * @name 对比组件
   * @type { VueConstructor | null }
   */
  CompareComponent = null;

  /**
   * @name 表格渲染组件
   * @type { VueConstructor | null }
   */

  TableComponent = null;

  isControl = true;

  /**
   * @name 数据格式化处理函数
   * @type { ControlFormat | null | undefined }
   */
  format = null;

  /**
   * @name 是否可打印
   */
  printable = false;

  /**
   * @name 基础校验规则（默认值数据关联校验）
   * @param { Field } field
   * @name 校验字段配置
   * @type { ((field: Field, getField: import('./utils').FieldMapOrGetFieldFn) => Record<string, Function>) | null }
   */
  widgetRules = null;

  /** @type { string | ((field: Field) => string) | null } */
  valueType = null;

  /**
   * @name 字段特征定义
   * @type { object | ((field: Field) => object) }
   * @returns  { object }
   */
  feature = {};
}

/** @name 控件值类型枚举，用于控件赋值判断类型以及公式编辑 */
export const ControlValueTypeEnum = {
  Text: 'Text',
  Number: 'Number',
  Array: 'Array',
  Date: 'Date',
  Member: 'Member',
  MemberArray: 'MemberArray',
  Department: 'Department',
  DepartmentArray: 'DepartmentArray',
  Address: 'Address',
};

/** @type { Partial<Record<keyof typeof ControlValueTypeEnum, CompareOperator[]>> }  */
export const ControlValueTypeOperatorMap = {
  [ControlValueTypeEnum.Text]: [
    'eq',
    'notEq',
    'eqOne',
    'uneqOne',
    'contains',
    'notContains',
    'null',
    'notNull',
  ],
  [ControlValueTypeEnum.Number]: [
    'eq',
    'notEq',
    'gt',
    'ge',
    'lt',
    'le',
    'range',
    'null',
    'notNull',
  ],
  [ControlValueTypeEnum.Date]: [
    'eq',
    'notEq',
    'gt',
    'ge',
    'lt',
    'le',
    'range',
    'filter',
    'null',
    'notNull',
  ],
  [ControlValueTypeEnum.Array]: ['some', 'alsoInclude', 'null', 'notNull'],
  [ControlValueTypeEnum.Address]: ['belong', 'notBelong', 'null', 'notNull'],
  [ControlValueTypeEnum.Member]: [
    'eq',
    'notEq',
    'eqOne',
    'uneqOne',
    'null',
    'notNull',
  ],
};

/** @typedef { (vm?: Vue) => Widget & Record<string, any> } WidgetGenerator */

/** @typedef { (value: any, field: Field, cacheRequest: CacheRequest, currentFormat: (value: any, field: Field) => (string | Promise<string>) ) => (string | Promise<string>) } ControlFormat */

/**
 * @typedef { object } Widget
 * @property { WidgetDefaultValue= } defaultValue 默认值
 * @property { boolean= } required 必填
 * @property { string= } identifier 数据标识符
 * @property { string= } invalidMessage 错误提示消息
 * @property { boolean } view 只读
 * @property { string= } description 提示信息
 * @property { string= } placeholder 占位符
 * @property { boolean } hideTitle 是否隐藏标题
 * @property { WidgetOptions= } options 选项来源，部分组件可用
 * @property { { inject: WidgetDataFill[]?, provide: WidgetDataFill[] }?= } dataFill 数据填值规则，部分组件可用
 * @property { boolean= } multiple 是否多选，部分组件可用
 * @property { boolean= } param 是否参数字段
 * @property { string= } format 日期格式
 * @property { number= } paramType 参数类型
 * @property { boolean= } disabled 是否显示
 */

/**
 * @name 字段默认值
 * @typedef WidgetDefaultValue
 * @property { 'custom' | 'linkage' | 'formula' | 'current' | 'rowCount' } type 默认值类型
 * @property { any } custom 自定义
 * @property { WidgetDefaultValueLinkage= } linkage 数据联动
 * @property { string= } formula 公式编辑
 * @property { number | string=} rowCount 子表单默认行数，数字为固定行数，字符串为公式计算行数
 */

/**
 * @name 数据联动规则
 * @typedef WidgetDefaultValueLinkage
 * @property { string } targetForm 关联目标表单id
 * @property { string } targetField 要回显的字段id
 * @property { import('../form-compare').FormCompareFilter | null } filter 数据联动过滤器
 * @property { WidgetDataFill[]= } subformDataFill 子表单字段映射
 */

/**
 * @name 数据联动子表单字段映射
 * @typedef WidgetDefaultValueLinkageSubformField
 * @property { string } from 目标子表单字段id
 * @property { string } to 当前子表单字段id
 */

/**
 * @name 字段选项来源（自定义、数据字典、关联其他表单数据）
 * @typedef WidgetOptions
 * @property { 'custom' | 'dictionary' | 'fieldData' } type 选项来源类型
 * @property { string?= } dictionary 数据字典id
 * @property { boolean?= } latest 数据字典是否使用最新数据
 * @property { WidgetOptionsFieldData?= } fieldData 关联其他表单数据
 * @property { import('@triascloud/services').DataSourceSorter<any> | null?= } sorter 排序方式
 * @property { import('../form-compare').FormCompareFilter | null } filter 过滤条件
 */

/**
 * @name 关联其他表单数据
 * @typedef WidgetOptionsFieldData
 * @property { string } formId
 * @property { string } fieldId
 * @property { string } assistFieldId
 */

/**
 * @name 表单字段结构
 * @typedef Field
 * @property { string } name 字段名
 * @property { string } pkId 字段pkId
 * @property { string | null } parentId 父字段id，禁止在业务逻辑中依赖此字段
 * @property { import('./utils').ControlType } type 字段类型
 * @property { Widget } widget 字段配置
 */

/**
 * @name 数据填充规则
 * @typedef WidgetDataFill
 * @property { string } from
 * @property { string } to
 * @property { WidgetDataFill[]= } children
 */

/**
 * @typedef { object } fieldPermission
 * @property { boolean | null } visible
 * @property { boolean | null } writable
 * @property { boolean | null } required
 * @property { boolean | null } printable
 * @property { boolean | null } briefing
 * */
