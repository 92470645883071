<template>
  <div :class="$style.wrap">
    <div :class="$style.iconWrap">
      <x-icon :class="$style.icon" type="tc-icon-warning" />
    </div>
    <div :class="$style.txt">
      <slot name="txt"></slot>
    </div>
    <div :class="$style.subTxt">
      <slot name="subTxt"></slot>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';

@Component()
export default class TipTxt extends Vue {
  getValue() {
    return true;
  }
}
</script>
<style lang="less" module>
.wrap {
  .iconWrap {
    padding-bottom: 15px;
    text-align: center;
  }

  .icon {
    font-size: 42px;
    color: #fe5959;
  }
  .txt {
    font-size: 16px;
    color: var(--font);
    line-height: 24px;
    text-align: center;
  }
  .subTxt {
    padding-top: 10px;
    font-size: 14px;
    color: var(--font-info);
    line-height: 18px;
    text-align: center;
  }
}
</style>
