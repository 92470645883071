import request from '../request';

const basePath = '/tenant-center/invite-code';

/** @name 用户邀请码范围 */
export const inviteList = ({
  current,
  endTime,
  ifUsed,
  keyword,
  startTime,
  size,
}) => {
  return request(`${basePath}/list`, {
    method: 'POST',
    body: {
      current,
      endTime,
      ifUsed,
      keyword,
      startTime,
      size,
    },
  });
};

/** @name 添加邀请码 */
export const addInviteCode = count =>
  request(`${basePath}/generate?count=${count}`, {
    method: 'GET',
  });

/** @name 邀请码统计 */
export const inviteCodeCount = () =>
  request(`${basePath}/count`, {
    method: 'GET',
  });

/** @name 邀请码复制 */
export const copyCode = pkId =>
  request(`${basePath}/copyCode?pkId=${pkId}`, {
    method: 'GET',
  });

/** @name 使用记录 */
export const useHistory = pkId => {
  return request(`${basePath}/useHistory?pkId=${pkId}`, {
    method: 'GET',
  });
};
