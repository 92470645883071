<template>
  <div ref="LogoRegistrationCharts" />
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import ResizeObserver from 'resize-observer-polyfill';
import moment from 'moment';
import { globalVueI18n } from '@triascloud/i18n';
import { crossStorageModule } from '@/enum/store';

@Component({
  components: {},
})
export default class LogoRegistrationCharts extends Vue {
  @Prop({ type: Object }) data;
  /** @type { ResizeObserver } */
  _ob = null;
  myChart = null;
  @crossStorageModule.State skin;

  get option() {
    const res = this.data && this.data.data ? this.data.data : [];
    return {
      legend: {
        show: true,
        data: [globalVueI18n.translate('plugin.snms.registrations')],
        top: 30,
        right: 10,
        itemWidth: 8,
        itemHeight: 8,
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },

      yAxis: {
        max: 'dataMax',
        // data:['0%', 100, 200,]
      },
      xAxis: {
        data: res.map(item => moment(item.time).format('MM/DD')),
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      series: [
        {
          name: globalVueI18n.translate('plugin.snms.registrations'),
          type: 'line',
          data: res.map(item => item.count),
          color: ['#68b4ff'],
          stack: 'one',
          barWidth: 30,
        },
      ],
    };
  }
  mounted() {
    this._ob = new ResizeObserver(this.chartResize);
    this._ob.observe(this.$refs.LogoRegistrationCharts);
    this.initChart();
  }

  chartResize() {
    this.myChart.resize();
  }

  @Watch('option')
  @Watch('skin', { deep: true })
  initChart() {
    const chart = this.$refs.LogoRegistrationCharts;
    this.myChart = echarts.init(chart);

    this.myChart.setOption(this.option);
  }
}
</script>
<style lang="less" module></style>
