<template>
  <div :class="$style.index">
    <x-data-table
      ref="table"
      base-path="/template-manager/solution/statistics/enterpriseSolutionsTransactionDetails"
      :columns="columns"
      :service="service"
      :class="$style.table"
      :custom-row="
        record => ({
          on: {
            click: () => openDetail(record),
          },
          style: {
            cursor: 'pointer',
          },
        })
      "
      :scroll="{
        x: '100%',
        y: '100%',
      }"
    >
      <template slot="action">
        <a-input
          :class="$style.toolbar__input"
          v-model="searchData.keyword"
          @pressEnter="handleSearch($event, $table)"
          @blur="handleSearch($event, $table)"
        >
          <x-icon type="tc-icon-search" slot="prefix" />
        </a-input>
        <a-range-picker
          :class="$style.toolbar__picker"
          :placeholder="[
            `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
              'common.label.day',
            )}`,
            `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
              'common.label.day',
            )}`,
          ]"
          :separator="$t('common.label.to')"
          @change="handlerDatePickerChange"
        />
        <a-select
          :placeholder="$t('common.label.all')"
          :class="$style.toolbar__select"
          v-model="searchData.solutionClassify"
          @change="handleSearch"
        >
          <a-select-option
            v-for="item in templateTypes"
            :key="item.value"
            :value="item.value"
            >{{ item.text }}</a-select-option
          >
        </a-select>
      </template>
      <div slot="headerLeft" :class="$style.toolbar">
        <b>{{ $t('solution.tableTlt') }}</b>
      </div>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { solutionTrade } from '@/services/template-manager/solution';
import { DataSourceService } from '@triascloud/services';
import { clipboardWrite } from '@triascloud/utils';
import { OrderStatusText } from '@/enum/order';

@Component
export default class ManageTradeTable extends Vue {
  @Prop({ type: String, default: '' }) classId;
  searchData = {
    keyword: '',
    timeStart: '',
    timeEnd: '',
    solutionClassify: '',
  };
  templateTypes = [
    {
      text: this.$t('common.label.all'),
      value: '',
    },
    {
      text: this.$t('solution.industrySolutions'),
      value: 1,
    },
    {
      text: this.$t('solution.universalSolution'),
      value: 2,
    },
  ];
  datePicker = [];
  handleMenuClick() {}
  handlerDatePickerChange(range) {
    if (range.length) {
      this.searchData.timeStart = range[0].startOf('day').valueOf();
      this.searchData.timeEnd = range[1].endOf('day').valueOf();
    } else {
      this.searchData.timeStart = null;
      this.searchData.timeEnd = null;
    }
    this.$refs.table.loadData();
  }
  get columns() {
    return [
      {
        title: this.$t('solution.serialNumber'),
        dataIndex: 'serialNumber',
        key: 'serialNumber',
      },
      {
        dataIndex: 'solutionName',
        key: 'solutionName',
        title: this.$t('solution.solutionName'),
      },
      {
        key: 'classIfy',
        title: this.$t('common.type'),
        dataIndex: 'classIfy',
        customRender: status =>
          status === '1'
            ? this.$t('solution.industrySolutions')
            : this.$t('solution.universalSolution'),
      },
      {
        title: this.$t('solution.paymentStatus'),
        key: 'payStatus',
        dataIndex: 'payStatus',
        customRender: status => (
          <span
            class={
              status === 1
                ? this.$style.primarySpan
                : status === 0
                ? this.$style.warningSpan
                : status === -2 || status === -1
                ? this.$style.dangerSpan
                : ''
            }
          >
            {OrderStatusText[status]}
          </span>
        ),

        width: 130,
      },
      {
        title: this.$t('solution.AmountTransaction'),
        dataIndex: 'amount',
        key: 'amount',
        customRender: price => `¥${(price / 100).toFixed(2)}`,
      },
      {
        title: this.$t('solution.buyBusiness'),
        dataIndex: 'buyTenantName',
        key: 'buyTenantName',
      },
      {
        title: this.$t('solution.Purchaser'),
        dataIndex: 'buyMemberName',
        key: 'buyMemberName',
      },
      {
        title: this.$t('solution.purchaseTime'),
        dataIndex: 'buyTime',
        customRender: checkDate =>
          checkDate ? moment(checkDate).format('Y-MM-DD HH:mm') : '-',
      },
      {
        title: this.$t('solution.solutionOwns'),
        dataIndex: 'createTenantName',
      },
    ].map(item => ({
      ...item,
      width: item.width || 130,
      sorter: false,
      filter: false,
      align: 'left',
      ellipsis: false,
    }));
  }

  handleSearch() {
    this.$refs.table.loadData();
  }

  async handleCopy(data) {
    await clipboardWrite(data.inviteCode);
    this.$message.success(this.$t('common.tips.copy'));
  }

  total = 0;
  get service() {
    const service = new DataSourceService(
      '/template-manager/solution/statistics/enterpriseSolutionsTransactionDetails',
    );
    service.getList = report =>
      solutionTrade({
        current: report.skip / report.size + 1,
        endTime: this.searchData.timeEnd,
        keyword: this.searchData.keyword,
        solutionClassify: this.searchData.solutionClassify,
        startTime: this.searchData.timeStart,
        size: report.size,
      }).then(res => {
        return {
          ...res,
          current: 1,
          hitCount: false,
          pages: 1,
          searchCount: true,
          size: 10,
          data: res.data,
          total: res ? res.total : 0,
        };
      });
    return service;
  }
}
</script>
<style lang="less" module>
.table {
  padding: 20px;
  overflow: auto;
  :global(.x-data-table--action .ant-btn-primary) {
    color: #fff;
    margin-left: 10px;
  }
}

.index {
  height: 640px;
  margin: 0 20px;
  margin-top: 20px;
  box-shadow: 0px 0px 8px 0px var(--shadow);
}
.action {
  cursor: pointer;
  color: var(--font-active);
  &.action--delete {
    color: var(--delete);
    margin-left: 20px;
  }
}
.toolbar__input {
  width: 120px;
  margin-right: 10px;
}
.toolbar__picker {
  width: 220px;
  margin-right: 10px;

  :global {
    .ant-calendar-picker-icon {
      display: none;
    }
    .ant-calendar-picker-input.ant-input {
      padding-left: 10px;
      text-align: center;
    }
  }
}
.toolbar__select {
  min-width: 124px;
  margin-right: 10px;
}

.primarySpan {
  color: var(--success);
}
.warningSpan {
  color: var(--warning);
}
.dangerSpan {
  color: var(--danger);
}
</style>
