import request from '../request';

export const basePath = '/tenant-center/business/opportunities';

export const fetchList = body => {
  return request(`${basePath}/list`, {
    method: 'POST',
    body,
  });
};

export const fetchMoreEnterprise = memberId => {
  return request(`${basePath}/tenants/list?memberId=${memberId}`, {
    method: 'POST',
  });
};

export const fetchWriteFollowup = body => {
  return request(`${basePath}/add/record`, {
    method: 'POST',
    body,
  });
};

export const fetchMoreFollowup = (memberId, moreFollowUpRecord) => {
  return request(
    `${basePath}/moreFollowUpRecord?memberId=${memberId}&moreFollowUpRecord=${moreFollowUpRecord}`,
    {
      method: 'GET',
    },
  );
};
