<template>
  <div>
    <div :class="$style.toolbar">
      <a-input
        v-model="sendData.keyword"
        :class="$style.toolbar__input"
        :placeholder="$t('common.action.search')"
        @blur="getList"
        @keyup.enter="getList"
      >
        <x-icon slot="prefix" type="tc-icon-search" />
      </a-input>
      <div :class="$style.toolbar__label">
        {{ $t('common.label.deleteDate') }}
      </div>
      <a-range-picker
        v-model="sendData.timeRange"
        :class="$style.toolbar__picker"
      />
      <div :class="$style.toolbar__label">
        {{ $t('dictionary.total') }}：<span
          :class="$style['toolbar__label--light']"
          >{{ list.length }}</span
        >
        {{ $t('common.label.piece') }}
        {{ name }}
      </div>
    </div>
    <a-spin :spinning="loading">
      <div :class="$style.list">
        <application-card
          v-for="item in list"
          :key="item.id"
          :data="item"
          :class="$style.card"
          :templateType="templateType"
          recycle
        >
          <a-tooltip
            v-if="$p.action('UPDATE')"
            placement="bottom"
            :title="$t('common.action.edit')"
          >
            <x-icon
              type="edit"
              :class="$style.card__icon"
              @click="edit(item.id)"
            />
          </a-tooltip>
          <a-tooltip
            placement="bottom"
            :title="$t('common.action.preview')"
            @click="preview(item)"
          >
            <x-icon type="tc-icon-search-square" :class="$style.card__icon" />
          </a-tooltip>
          <a-tooltip placement="bottom" :title="$t('iconLibrary.library.undo')">
            <x-icon
              type="tc-icon-recovery"
              :class="$style.card__icon"
              @click="recovery(item.id)"
            />
          </a-tooltip>
          <a-tooltip placement="bottom" :title="$t('common.action.delete')">
            <x-icon
              type="tc-icon-delete"
              :class="[$style.card__icon, $style['card__icon--delete']]"
              @click="remove(item.id)"
            />
          </a-tooltip>
        </application-card>
      </div>
    </a-spin>
    <empty-content v-if="!loading && !list.length" />
  </div>
</template>
<script>
import { createDrawer } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ApplicationCard from './card';
import ApplicationSettingModal from './application-setting-modal.vue';
import {
  getFormTemplateRecycleList,
  getPrintTemplateRecycleList,
  getTemplateRecycleList,
  recoverTemplate,
  removeCompletelyTemplate,
} from '@/services/template-manager/template';
import { TemplateType } from '@/enum/template';
import EmptyContent from '@/components/empty-content';
import FormSettingModal from './form-setting-modal.vue';
import PrintSettingModal from './print-setting-modal.vue';
import PrintPreviewDrawer from './print-preview-drawer.vue';
import ApplicationPreviewDrawer from './application-preview-drawer.vue';
import FormPreviewDrawer from './form-preview-drawer.vue';

function createTemplateRecycleDrawer(props, on, drawerOption = {}) {
  return createDrawer(h => h(TemplateRecycleDrawer, { props, on }), {
    title: globalVueI18n.translate('market.template.recycleBin'),
    width: 846,
    bodyStyle: { padding: 0 },
    ...drawerOption,
  });
}
@Component({
  components: { ApplicationCard, EmptyContent },
})
export default class TemplateRecycleDrawer extends Vue {
  @Prop({ type: String, default: TemplateType.Application }) templateType;
  static createDrawer = createTemplateRecycleDrawer;
  list = [];
  sendData = {
    timeRange: [],
    keyword: '',
  };
  get name() {
    return (this.templateType === TemplateType.Application
      ? this.$t('market.template.application')
      : this.templateType === TemplateType.Print
      ? this.$t('market.template.print')
      : this.$t('market.template.form')
    ).toLocaleLowerCase();
  }
  loading = true;
  @Watch('sendData.timeRange', { deep: true, immediate: true })
  async getList() {
    this.loading = true;
    const getListMeth =
      this.templateType === TemplateType.Application
        ? getTemplateRecycleList
        : this.templateType === TemplateType.Print
        ? getPrintTemplateRecycleList
        : getFormTemplateRecycleList;
    this.list = await getListMeth({
      keyword: this.sendData.keyword || undefined,
      beginTime: this.sendData.timeRange[0]
        ? this.sendData.timeRange[0].startOf('day').valueOf()
        : undefined,
      endTime: this.sendData.timeRange[1]
        ? this.sendData.timeRange[1].endOf('day').valueOf()
        : undefined,
    });
    this.loading = false;
  }
  async edit(id) {
    if (this.templateType === TemplateType.Application) {
      await ApplicationSettingModal.createModal({
        templateId: id,
      });
    } else if (this.templateType === TemplateType.Form) {
      await FormSettingModal.createModal({
        templateId: id,
      });
    } else {
      //打印模板
      await PrintSettingModal.createModal({
        templateId: id,
      });
    }
    this.getList();
  }
  async recovery(templateId) {
    await this.$confirm(this.$t('market.template.restoreConfirm'));
    await recoverTemplate({
      templateId,
      templateType: this.templateType,
    });
    this.$message.success(this.$t('common.tips.recoverySuccess'));
    this.getList();
    this.$emit('freshList');
  }
  async remove(templateId) {
    await this.$confirm(this.$t('market.template.removeCompletely'));
    await removeCompletelyTemplate({
      templateType: this.templateType,
      templateId,
    });
    this.$message.success(this.$t('common.tips.delete'));
    this.getList();
  }
  async preview(data) {
    let openMethod = null;
    if (this.templateType === TemplateType.Application) {
      openMethod = ApplicationPreviewDrawer.createModal;
    } else if (this.templateType === TemplateType.Print) {
      //打印
      await PrintPreviewDrawer.createModal({
        data: {
          ...data,
          templateId: data.id,
          pcScreenshots: [data.img['1'][0].imgPath],
        },
        review: true,
      });
    } else {
      openMethod = FormPreviewDrawer.createModal;
    }
    openMethod({
      data: {
        ...data,
        templateId: data.id,
      },
      review: false,
    });
  }
}
</script>
<style lang="less" module>
.toolbar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--form-border);
  padding: 20px;
  .toolbar__label {
    color: var(--font-info);
    .toolbar__label--light {
      color: var(--font-active);
    }
  }
  .toolbar__input {
    width: auto;
    :global .ant-input {
      width: 110px;
      margin-right: 40px;
      &:focus {
        width: 220px;
      }
    }
  }
  .toolbar__picker {
    margin: 0 20px 0 10px;
    width: 250px;
  }
}
.list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.card {
  margin-right: 30px;
  margin-bottom: 30px;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .card__icon {
    cursor: pointer;
    &:hover {
      color: var(--font-active);
    }
  }
  .card__icon--delete {
    &:hover {
      color: var(--delete);
    }
  }
}
</style>
