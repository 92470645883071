var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.tabConfig},[_c('field-config-config-desc',{attrs:{"desc":_vm.$t('controls.desc.tabs')}}),_c('a-form-model-item',{ref:"tabs",attrs:{"label":_vm.$t('controls.tabs.tabsLabel'),"prop":"widget.tabs","rules":_vm.rules}},[_c('draggable',{attrs:{"tag":"ul","value":_vm.tabs,"group":{ name: 'tab-config' },"handle":("." + (_vm.$style.dragHandler)),"animation":300},on:{"input":_vm.handleInput}},_vm._l((_vm.tabs),function(tab,tabIndex){return _c('li',{key:tab.id,class:_vm.$style.flexItem},[_c('span',{class:_vm.$style.label},[_c('x-input',{attrs:{"value":tab.title},on:{"input":function($event){return _vm.updateField({
                key: ("widget.tabs." + tabIndex + ".title"),
                value: $event,
              })},"blur":function () {
                _vm.$refs.tabs.onFieldBlur();
              },"change":function () {
                _vm.$refs.tabs.onFieldChange();
              }}}),_c('x-input',{attrs:{"value":tab.identifier,"disabled":_vm.operationFlag === 'edit' ? true : false},on:{"input":function($event){return _vm.updateField({
                key: ("widget.tabs." + tabIndex + ".identifier"),
                value: $event,
              })},"blur":function () {
                _vm.$refs.tabs.onFieldBlur();
              },"change":function () {
                _vm.$refs.tabs.onFieldChange();
              }}})],1),_c('span',{class:_vm.$style.labelAction},[_c('x-icon',{class:_vm.$style.dragHandler,attrs:{"type":"tc-icon-drag-handler"}}),(_vm.operationFlag === 'add')?_c('x-icon',{class:_vm.$style.delete,attrs:{"type":"tc-icon-delete"},on:{"click":function($event){return _vm.handleDeleteTab(tab)}}}):_vm._e()],1)])}),0),(_vm.operationFlag === 'add')?_c('a',{on:{"click":_vm.handleAddTab}},[_vm._v(" "+_vm._s(_vm.$t('controls.label.addOption'))+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }