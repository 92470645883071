<template>
  <div>
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-form-model-item label="姓名" prop="appName">
        <a-input v-model="authData.appName" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="authData.remark" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item prop="apiId">
        <span slot="label">
          <span>授权连接器</span>
          <span style="color:var(--font-info)"
            >（限请求设置为"三方推送"的连接器）</span
          >
        </span>
        <a-select v-model="form.apiId" placeholder="请选择连接器">
          <a-select-option
            v-for="item in apiList"
            :key="item.pkId"
            :value="item.pkId"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import ImagesUpload from '@/views/connector/platform-manage/components/images-upload.vue';
import {
  addAuth,
  authApiList,
} from '@/services/iot-platform/platformInterface';

@Component({
  components: { ImagesUpload },
})
export default class AuthDeveloper extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  authData;

  form = {
    apiId: undefined,
  };

  rules = {
    apiId: [
      {
        required: true,
        message: '请选择授权连接器',
      },
    ],
  };

  apiList = [];

  mounted() {
    this.getApilList();
  }

  triggerMethodEnum = {
    MANUAL: '手动',
    TIMING: '定时',
    PASSIVE: '被动',
  };

  async getApilList() {
    const params = {
      appKey: this.authData.appKey,
      platformId: this.authData.platformId,
    };
    try {
      const res = await authApiList(params);
      this.apiList = res.map(v => {
        return {
          pkId: v.pkId,
          name: `${v.name}（${this.triggerMethodEnum[v.triggerMethod]}）`,
        };
      });
    } catch {
      return false;
    }
  }

  async getValue() {
    await this.$refs.form.validate();
    try {
      const params = {
        apiId: this.form.apiId,
        appKey: this.authData.appKey,
        platformId: this.authData.platformId,
      };
      await addAuth(params);
      this.$message.success('授权成功');
      return true;
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module></style>
