<template>
  <div :class="$style.wrap">
    <div :class="$style.content">
      <div :class="$style.imgWrap">
        <a-icon type="check-circle" :class="$style.icon" />
      </div>
      <p>OTA升级配置成功！</p>
      <span>可通过刷新设备列表并筛选固件留意设备是否升级成功！</span>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class setDone extends Vue {
  @Prop({ type: Object, default: () => {} }) record;
}
</script>
<style lang="less" module>
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .content {
    width: fit-content;
    p {
      font-size: 26px;
      color: #000;
      font-weight: 600;
      margin: 20px 0 20px 0;
      text-align: center;
    }

    .imgWrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 80px;
        color: #52c41a;
      }
      //   img {
      //     width: 80px;
      //     height: 80px;
      //   }
    }
  }
}
</style>
