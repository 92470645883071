<template>
  <div :class="$style.introduce">
    <a-form-model-item
      label="自定义字段"
      prop="guigeForm.hardwareSpecification"
    >
      <FieldsList
        v-model="infoForm.hardwareSpecification"
        :disabledFlag="disabledFlag"
      />
    </a-form-model-item>
    <a-form-model-item prop="guigeForm.graphicIntroduction">
      <span slot="label">
        <span>设备介绍图片</span>
        <span>（推荐宽1200px的图片，支持jpg、bmp、png等主流图片格式）</span>
      </span>
      <image-upload
        v-model="infoForm.graphicIntroduction"
        :maxCount="50"
        :is-disable="disabledFlag"
        ossFilePath="productInfo"
        :showCutImg="false"
        style="height: 120px"
      />
    </a-form-model-item>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ImageUpload from '@/views/connector/platform-manage/components/images-upload.vue';
import FieldsList from '@/views/connector/platform-manage/develop/device-manage/introduce/fields-list.vue';
@Component({
  components: {
    ImageUpload,
    FieldsList,
  },
})
export default class RichIntroduce extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  infoForm;
  @Prop({ type: Boolean, default: false }) disabledFlag;
}
</script>
<style lang="less" module>
.introduce {
  .ml10 {
    margin-left: 10px;
  }

  .add {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    margin: 10px 0;
  }

  .themeColor {
    color: var(--primary);
  }
}
</style>
