<template>
  <div :class="$style.ManageSwitchDate">
    <div>
      <x-select
        :class="$style.selectWarp"
        v-model="val"
        :data="timeOptions"
        @change="changeValue"
      />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
  name: 'ManageSwitchDate',
})
export default class ManageSwitchDate extends Vue {
  @Prop({ type: String }) value;
  @Prop({ type: Array }) timeOptions;
  val = '';
  @Watch('val', { immediate: true })
  handleCValeChange() {
    this.val = this.value;
  }

  @Watch('$route')
  handleRouteChange() {
    this.val = 'WEEK';
    this.changeValue();
  }
  changeValue() {
    this.$emit('input', this.val);
  }
}
</script>

<style lang="less" module>
.ManageSwitchDate {
  z-index: 1;
  font-size: 14px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  min-width: 232px;
  right: 0px;
  top: 0px;
  background: var(--main-bg);
  :global(.ant-radio-button-wrapper) {
    border: none;
    &:not(:first-child)::before {
      opacity: 0;
    }
  }
  :global(.ant-radio-button-wrapper-checked) {
    &:not(.ant-radio-button-wrapper-disabled) {
      box-shadow: inherit;
    }
    &:not(.ant-radio-button-wrapper-disabled) {
      outline: none;
    }
  }
  .text {
    font-weight: 500;
  }
  .icon {
    margin-left: 10px;
    cursor: pointer;
  }
  .selectWarp {
    width: 120px;
  }
}
</style>
