<template>
  <a-form-model ref="form" :model="form" layout="vertical" :class="$style.main">
    <a-form-model-item :rules="rules" label="" prop="content">
      <a-textarea
        :class="$style.content"
        v-model="form.content"
        type="text"
        :placeholder="$t('feedback.replyPlaceholder')"
        :max-length="500"
        :rows="5"
      ></a-textarea>
      <div :class="$style['corner-num']">{{ form.content.length }} / 500</div>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
@Component()
export default class FeedbackReplyModal extends Vue {
  form = {
    content: '',
  };
  get rules() {
    return {
      message: this.$t('common.validate.max', {
        name: this.$t('feedback.replyContent'),
        value: '500',
      }),
      max: 500,
    };
  }
  async getValue() {
    await this.$refs.form.validate();
    return this.form.content;
  }
}
</script>

<style lang="less" module>
.main {
  position: relative;
}
.content {
  width: 100%;
  padding-bottom: 20px;
  min-height: 139px;
  background: var(--block-bg);
  resize: none;
  &:focus {
    outline: none !important;
  }
}
.tips {
  margin-bottom: 10px;
}
.corner-num {
  position: absolute;
  right: 10px;
  bottom: 0px;
  color: var(--font-sub);
  font-weight: 400;
}
</style>
