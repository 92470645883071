<template>
  <div :class="$style.card">
    <div :class="$style.card__header">
      <div
        :class="[$style.card__icon__bg]"
        :style="{ color: data.bgColor || 'var(--primary)' }"
      >
        <x-custom-icon :type="data.icon" :class="$style.card__icon" />
      </div>
      <div :class="$style.card__header__right">
        <div :class="$style.card__name">{{ data.name }}</div>
        <a-rate
          :class="$style.card__rate"
          :value="(data.recommendStart > 0 ? data.recommendStart : 0) / 10"
          allow-half
          disabled
        />
        <div :class="$style.card__desc">{{ data.instruction }}</div>
      </div>
    </div>
    <div :class="[$style.card__item, $style['card-item--install']]">
      {{ $t('market.template.price') }}：{{ formatPrice }}
      <span :class="$style.card__install">
        {{ $t('market.template.Installs') }}：
        <span
          :title="data.installCount > 0 ? data.installCount : 0"
          :class="$style.card__install__number"
          >{{ data.installCount > 0 ? data.installCount : 0 }}</span
        >
        <x-icon
          :class="$style.card__item__icon"
          type="eye"
          @click="installDetail"
        />
      </span>
    </div>
    <div :class="$style.card__item" :title="data.pushTenantName">
      {{ $t('template.review.pushTenantName') }}：{{ data.pushTenantName }}
    </div>
    <div :class="$style.card__item">
      {{ $t('dictionary.pushTime') }}：{{
        data.pushTime && $moment(data.pushTime).format('YYYY-MM-DD HH:mm')
      }}
    </div>
    <div :class="$style.card__item">
      {{ $t('dictionary.reviewTime') }}：{{
        data.pushTime && $moment(data.checkTime).format('YYYY-MM-DD HH:mm')
      }}
    </div>
    <div :class="$style.card__item">
      {{ $t('dictionary.reviewer') }}：{{ data.checker }}
    </div>
    <div :class="$style.card__item">
      {{ $t('market.template.accumulatePoints') }}：{{
        data.integrateCount > 0 ? data.integrateCount : 0
      }}
    </div>
    <template v-if="recycle">
      <div :class="$style.card__item">
        {{ $t('common.label.deleteDate') }}：{{
          $moment(data.deleteTime).format('YYYY-MM-DD HH:mm')
        }}
      </div>
      <div :class="$style.card__item">
        {{ $t('dictionary.deletePerson') }}：{{ data.deleterName || '--' }}
      </div>
    </template>
    <div :class="$style.controllers">
      <slot></slot>
    </div>
    <div
      :class="[
        $style.card__status,
        { [$style['card__status--open']]: data.state === TemplateState.Open },
        { [$style['card__status--init']]: data.state === TemplateState.Init },
      ]"
    >
      <span>{{ stateText }}</span>
    </div>
  </div>
</template>
<script>
import { TemplateState, TemplateType } from '@/enum/template';
import { Component, Prop, Vue } from 'vue-property-decorator';
import InstallHistory from './install-history-modal.vue';
@Component()
export default class Card extends Vue {
  @Prop({ type: Boolean, default: false }) recycle;
  @Prop({ type: Object, required: true }) data;
  @Prop({ type: String, default: TemplateType.Application }) templateType;
  TemplateState = TemplateState;
  get formatPrice() {
    if (this.data.price > 0) {
      return `￥${parseFloat(this.data.price / 100) || 0}`;
    }
    return this.$t('market.template.free');
  }
  installDetail() {
    InstallHistory.createModal({
      data: this.data,
      templateType: this.templateType,
    });
  }
  get stateText() {
    switch (this.data.state) {
      case TemplateState.Open:
        return this.$t('template.review.state.open');
      case TemplateState.Close:
        return this.$t('template.review.state.offShelf');
      case TemplateState.Init:
        return this.$t('template.review.state.notListed');
      default:
        return '';
    }
  }
}
</script>
<style lang="less" module>
.card {
  position: relative;
  display: inline-block;
  min-height: 278px;
  width: 246px;
  color: var(--main-bg);
  box-shadow: 0px 0px 12px 0px var(--shadow);
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  color: var(--font-sub);
  .card__header {
    display: flex;
    color: var(--font);
    .card__header__right {
      flex: 1;
      overflow: hidden;
    }
    .card__name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .card__rate {
      font-size: 14px;
    }
    .card__icon__bg {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--primary-fade-10);
      border-radius: 6px;
      height: 60px;
      margin-right: 10px;
      width: 60px;
      background-color: currentColor;
      .card__icon {
        color: #fff;
        font-size: 30px;
      }
    }
    .card__desc {
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .card__item {
    margin-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &.card-item--install {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .card__install {
      display: flex;
      align-items: center;
    }
    .card__install__number {
      display: inline-block;
      max-width: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .card__item__icon {
      margin-left: 10px;
    }
  }
  .controllers {
    width: 100%;
    height: 42px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    justify-content: space-between;
    display: none;
    align-items: center;
    border-radius: 0 0 5px 5px;
    background: var(--block-bg);
    font-size: 16px;
    box-shadow: 0px 0px 5px 0px var(--shadow);
    padding: 0 10px;
  }
  .card__status {
    position: absolute;
    width: 60px;
    color: #fff;
    right: 0;
    top: 0;
    background: var(--font-info);
    text-align: center;
    transform: skewX(10deg);
    border-radius: 0 0 0 10px;
    font-size: 12px;
    padding: 2px 0;
    span {
      display: block;
      transform: skewX(-10deg);
    }
    &.card__status--open {
      background-color: var(--success);
    }
    &.card__status--init {
      background-color: #18c4ec;
    }
  }
  &:hover {
    box-shadow: 0px 0px 14px 0px var(--primary);
    .controllers {
      display: flex;
    }
  }
}
</style>
