var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-table',{ref:"table",class:_vm.$style.table,attrs:{"base-path":"/resource/cloud-dictionary/list","columns":_vm.columns,"service":_vm.service,"custom-row":function (record) { return ({
      on: {
        click: function () { return _vm.openDetail(record); },
      },
      style: {
        cursor: 'pointer',
      },
    }); }}},[_c('div',{class:_vm.$style.toolbar,attrs:{"slot":"headerLeft"},slot:"headerLeft"},[_c('a-input',{class:_vm.$style.toolbar__input,on:{"pressEnter":function($event){return _vm.handleTableKeywordChange($event)}}},[_c('x-icon',{attrs:{"slot":"prefix","type":"tc-icon-search"},slot:"prefix"})],1),_c('span',[_vm._v(_vm._s(_vm.$t('dictionary.shareTime')))]),_c('a-range-picker',{class:_vm.$style.toolbar__picker,attrs:{"placeholder":[
        ((_vm.$t('common.label.year')) + "-" + (_vm.$t('common.label.month')) + "-" + (_vm.$t(
          'common.label.day'
        ))),
        ((_vm.$t('common.label.year')) + "-" + (_vm.$t('common.label.month')) + "-" + (_vm.$t(
          'common.label.day'
        ))) ],"separator":_vm.$t('common.label.to')},on:{"change":_vm.handlerDatePickerChange}}),_c('span',[_vm._v(_vm._s(_vm.$t('dictionary.total'))+"："),_c('a',[_vm._v(_vm._s(_vm.total))]),_vm._v(_vm._s(_vm.$t('dictionary.aggregation'))+"："),_c('a',[_vm._v(_vm._s(_vm.childrenTotal)+" ")]),_vm._v(_vm._s(_vm.$t('dictionary.subset')))])],1),(_vm.$p.action('RECYCLE-BIN'))?_c('a-button',{attrs:{"slot":"action"},on:{"click":_vm.openRecycle},slot:"action"},[_c('x-icon',{attrs:{"type":"tc-icon-delete"}}),_vm._v(" "+_vm._s(_vm.$t('gallery.actions.recycling'))+" ")],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }