<template>
  <div>
    <div>
      {{ $t('connector.dataType.desc') }}
    </div>
    <div :class="$style.content">
      <a-button icon="plus" type="primary" @click.stop="handleAdd">{{
        $t('connector.extra.insertDataType')
      }}</a-button>
      <a-button type="primary" :class="$style.mr10" @click.stop="handleImport">
        {{ $t('common.tips.import') }}
      </a-button>
      <a-button :class="$style.mr10" @click.stop="handleDownload">{{
        $t('connector.extra.export')
      }}</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :row-key="record => record.pkId"
      :loading="loading"
    >
      <div slot="action" slot-scope="text, record">
        <span :class="$style.buttonGroups">
          <a
            v-if="$p.action('UPDATE')"
            :class="$style.button"
            @click="handleUpdate(record)"
            >{{ $t('common.action.edit') }}</a
          >
          <a
            v-if="$p.action('DELETE')"
            :class="[$style.button, $style.red]"
            @click="handleDelete(record)"
            >{{ $t('common.action.delete') }}</a
          >
        </span>
      </div>
    </a-table>
  </div>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// import { Emitter, EventType } from '../utils';
import { createFormModal, createModal } from '@triascloud/x-components';
import UploadForm from './upload-form';
import AddForm from './add-form';
import {
  getList,
  insertData,
  deleteData,
  updateData,
  getData,
  exportData,
  importData,
} from '@/services/iot-platform/dataIdentifier';
@Component({
  components: {
    AddForm,
    UploadForm,
  },
})
export default class DataType extends Vue {
  @Prop() id;
  mounted() {
    // Emitter.$on(EventType.ADD_DATA_TYPE, this.handleAdd);
    // Emitter.$on(EventType.DOWNLOAD_DATA_TYPE, this.handleDownload);
    // Emitter.$on(EventType.IMPORT_DATA_TYPE, this.handleImport);
  }

  @Watch('id', { immediate: true })
  onDevelopIdChange(val) {
    if (val) {
      this.getTableList();
    }
  }

  beforeDestroy() {
    // Emitter.$off(EventType.ADD_DATA_TYPE, this.handleAdd);
    // Emitter.$off(EventType.DOWNLOAD_DATA_TYPE, this.handleDownload);
    // Emitter.$off(EventType.IMPORT_DATA_TYPE, this.handleImport);
  }
  handleImport() {
    this.addTypeModal = createModal(
      () => (
        <UploadForm
          tableData={this.tableData}
          onClose={this.typeModelClose}
          onMessage={this.handleAddArray}
          onUpload={this.handleUpload}
        />
      ),
      {
        width: 400,
        title: '导入数据',
      },
    );
  }
  uploadArray = [];
  uploadDisMsg = '';
  handleUpload(result) {
    const { array, errMsg, data } = result;
    this.uploadDisMsg = errMsg;
    this.uploadArray = array;
    /** @name 本次导入的数据，都要添加 */
    if (array.length === data.length) {
      this.typeModelClose();
      this.handleAddArray();
    }
  }

  addTypeModal;
  typeModelClose() {
    this.addTypeModal.handleClose();
  }

  async handleAddArray() {
    try {
      let result = await createFormModal(
        () => <AddForm list={this.uploadArray} />,
        {
          width: 470,
          title: this.$t('connector.dataType.tip.modelTypeTitle'),
        },
      );
      if (result) {
        await importData(this.id, result);
        await this.getTableList();
        let msg = '';
        if (this.uploadDisMsg.length) {
          msg = this.uploadDisMsg + this.$t('connector.dataType.errMsg');
        } else {
          msg = this.$t('common.tips.add');
        }
        this.$message.success(msg);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  async handleAdd() {
    try {
      let result = await createFormModal(() => <AddForm />, {
        width: 470,
        title: this.$t('connector.dataType.tip.modelTypeTitle'),
      });
      if (result) {
        result = {
          ...result,
          idxPlatformId: this.id,
        };
        await insertData(result);
        await this.getTableList();
        this.$message.success(this.$t('common.tips.add'));
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  handleDelete(record) {
    this.$confirm({
      title: this.$t('connector.dataType.delete.title'),
      content: this.$t('connector.dataType.delete.content'),
      okText: this.$t('common.action.confirm'),
      cancelText: this.$t('common.action.cancel'),
      onOk: async () => {
        await deleteData(record.pkId);
        await this.getTableList();
        this.$message.success(this.$t('common.tips.delete'));
      },
    });
  }
  async handleUpdate(record) {
    try {
      const detail = await getData(record.pkId);
      let result = await createFormModal(() => <AddForm detail={detail} />, {
        width: 470,
        title: this.$t('connector.dataType.tip.modelTypeTitle'),
      });
      if (result) {
        result = {
          ...result,
          id: record.pkId,
        };
        await updateData(this.id, result);
        await this.getTableList();
        this.$message.success(this.$t('common.tips.edit'));
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  async handleDownload() {
    await exportData(this.id);
  }

  loading = false;
  columns = [
    {
      dataIndex: 'index',
      customRender: (text, record, index) => index + 1,
      title: this.$t('common.label.index'),
      align: 'center',
      fixed: 'left',
      width: 80,
    },
    {
      title: this.$t('connector.dataType.rule.customName'),
      dataIndex: 'customizeName',
    },
    {
      title: this.$t('connector.dataType.table.objectModelType'),
      dataIndex: 'thingIdentifier',
    },
    {
      title: this.$t('connector.dataType.rule.type'),
      dataIndex: 'datatype',
    },
    {
      title: this.$t('connector.dataType.table.lastEditor'),
      dataIndex: 'memberName',
    },
    {
      title: this.$t('connector.dataType.table.updateTime'),
      dataIndex: 'updateTime',
      customRender: val => {
        return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
      },
    },
    {
      width: 240,
      title: this.$t('common.action.operating'),
      key: 'operation',
      scopedSlots: { customRender: 'action' },
    },
  ];
  tableData = [];
  async getTableList() {
    this.loading = true;
    this.tableData = await getList(this.id);
    this.loading = false;
  }
}
</script>
<style lang="less" module>
.buttonGroups {
  display: flex;
  justify-content: flex-start;
  .red {
    color: red;
  }
  .button {
    width: 50px;
    position: relative;
  }
}
.content {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: right;
  .mr10 {
    margin-left: 10px;
  }
}
</style>
