<template>
  <div>
    <a-upload
      :class="$style.wrap"
      list-type="picture-card"
      :file-list="images"
      @preview="handlePreview"
      @change="handleImgsChange"
      :beforeUpload="beforeUpload"
      :customRequest="customUpload"
      accept="image/*"
      :disabled="isDisable"
    >
      <div v-if="images.length < maxCount && !isDisable">
        <a-icon type="plus" />
      </div>
    </a-upload>
    <a-modal
      :visible="previewVisible"
      @cancel="handlePreviewCancel"
      @ok="handlePreviewOk"
      title="预览图片"
      wrapClassName="viewModel"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal
      :visible="imageEditVisible"
      :maskClosable="false"
      @ok="handleImgResize"
      @cancel="() => handleCancel('cancel')"
      title="裁剪图片"
      wrapClassName="viewModel"
    >
      <div style="min-height: 300px">
        <div
          style="margin-bottom: 5px; color: var(--primary)"
          v-if="showCutImg"
        >
          裁剪尺寸：{{ cutImgSize }} * {{ cutImgSize }}
        </div>
        <cropper
          ref="cropper"
          :src="previewImage"
          :stencil-size="{
            width: cutImgSize,
            height: cutImgSize,
          }"
          :stencil-props="{
            handlers: {},
            movable: true,
            resizable: false,
            aspectRatio: 1,
          }"
          image-restriction="stencil"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
import { Component, Prop, Vue, Model, Watch } from 'vue-property-decorator';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { UploadService } from '@triascloud/services';
import { crossStorageModule } from '@/enum/store';

@Component({ components: { Cropper } })
export default class ImagesUpload extends Vue {
  @crossStorageModule.State('tenant') tenant;

  @Model('change.value') value;
  @Prop({ type: Array }) value;
  @Prop({ type: String, default: 'logoImages' }) ossFilePath;
  @Prop({ type: Number, default: 1 }) maxCount;
  @Prop({ type: Boolean, default: false }) isDisable;
  @Prop({ type: Boolean, default: true }) showCutImg;
  @Prop({ type: Number, default: 300 }) cutImgSize;

  images = [];

  @Watch('value', { deep: true, immediate: true })
  onValue(value) {
    this.images = value || [];
  }

  imageEditVisible = false;

  previewImage = '';
  previewVisible = false;

  getBase64(img) {
    const reader = new FileReader();
    return new Promise(resolve => {
      reader.addEventListener('load', () => resolve(reader.result));
      reader.readAsDataURL(img);
    });
  }
  async handlePreview(file, type) {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }
    this.previewImage = file.preview || file.url;
    if (type === 'edit') {
      this.imageEditVisible = true;
    } else {
      this.previewVisible = true;
    }
  }

  uploadOptions = null;
  async customUpload(options) {
    this.uploadOptions = options;
  }
  async uploadImg(file) {
    const uploadOptions = this.uploadOptions;
    const files = file.originFileObj;
    const pkId = this.tenant.pkId;
    const uploadPath = `${pkId}/connector/${this.ossFilePath}`;
    const basePath = '/oss/oss';
    const customService = new UploadService(basePath);
    const res = await customService.upload(
      files,
      uploadPath,
      e => {
        e.addEventListener('responseprogress', p => {
          uploadOptions.onProgress(
            { percent: +Math.round((p.loaded / p.total) * 100).toFixed(2) },
            files,
          );
        });
      },
      false,
    );
    uploadOptions.onSuccess(res, files);
    return res;
  }

  beforeUpload(file) {
    if (file.size > 10 * 1024 * 1024) {
      this.$message.error('请上传小于10M的图片');
      return false;
    } else {
      return true;
    }
  }

  async handleImgsChange({ fileList }) {
    if (fileList.length > this.images.length) {
      let file = fileList.pop();
      fileList.push(file);
      this.images = fileList;
      if (this.showCutImg) {
        await this.handlePreview(file, 'edit');
      } else {
        await this.handlePreview(file, 'view');
      }
    } else {
      this.images = fileList;
      this.images.forEach((i, index) => {
        const flag = fileList.findIndex(v => v.uid === i.uid);
        if (flag === -1) {
          this.images.splice(index, 1);
        }
      });
    }
    this.$emit('change.value', this.images);
    this.$emit('change', this.images);
    this.$emit('input', this.images);
  }

  handlePreviewCancel() {
    this.previewVisible = false;
  }

  async handlePreviewOk() {
    this.handlePreviewCancel();
    const file = this.images.pop();
    this.images.push(file);
    if (!this.showCutImg) {
      file.ossPath = await this.uploadImg(file);
    } else {
      await this.handlePreview(file, 'edit');
    }
  }

  handleCancel(flag) {
    this.imageEditVisible = false;
    if (flag === 'cancel') {
      this.images.pop();
    }
  }

  async handleImgResize() {
    const { canvas } = this.$refs.cropper.getResult();
    let previewImage = canvas.toDataURL();
    let file = this.images.pop();
    file.preview = previewImage;
    file.originFileObj = this.dataToBlob(previewImage, file.name);
    delete file.url;
    delete file.thumbUrl;
    // file.status = 'uploading';
    this.images.push(file);
    this.imageEditVisible = false;
    file.ossPath = await this.uploadImg(file);
    // file.status = 'done';
    // this.images.push(file);
  }

  dataToBlob(data, fileName) {
    let fileType = data.split(',')[0].match(/:(.*?);/)[1];
    data = data.split(',')[1];
    data = window.atob(data);
    const ia = new Uint8Array(data.length);
    for (let i = 0; i < data.length; i++) {
      ia[i] = data.charCodeAt(i);
    }
    // canvas.toDataURL 返回的默认格式就是 image/png
    let newBlob = new Blob([ia], {
      type: fileType,
    });
    newBlob.lastModifiedDate = new Date();
    newBlob.name = fileName;
    return newBlob;
  }
}
</script>

<style lang="less" module>
.wrap {
  :global {
    .ant-upload-select {
      background: var(--block-bg);
    }
  }
}

:global {
  .viewModel {
    .ant-modal {
      width: auto !important;
      max-width: 1200px;
    }
  }
}
</style>
