import request from '../request';

const serviceName = '/flow/people';

/**
 * @name 消息是否可跳转
 * @param {*} param0
 */
export const canJump = ({ executionId, taskId, noticeEven }) =>
  request(`${serviceName}/canJump/${executionId}/${taskId}/${noticeEven}`, {});
