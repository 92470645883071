<script>
import { Component, Vue, Watch, Inject, Prop } from 'vue-property-decorator';
import { templateDesignModule } from '@/enum/store';
import {
  DATATYPE,
  unitArray,
} from '@/views/connector/platform-manage/develop/utils';
import { DataFormContext } from '../../data-form/context';
import { deepClone } from '@triascloud/utils';
import secondForm from './secondForm.vue';
import { validateInput } from './validate';
import { uuid } from '@triascloud/utils';

@Component({ components: { secondForm } })
export default class currentDataSet extends Vue {
  @Prop({ required: true }) field;
  @Inject({ from: DataFormContext.Root, default: null }) root;
  @Inject({ from: DataFormContext.Model, default: null }) model;
  @templateDesignModule.State types;
  form = {
    id: '',
    dataType: undefined,
    dataTypeText: '',
    thingIdentifier: '',
    dataSpecs: {
      number: {
        max: '',
        min: '',
        step: '',
        unit: '',
      },
      text: {
        maxLength: '',
      },
      date: {
        format: 'timeStamp',
      },
      boolean: {
        0: '',
        1: '',
      },
      enum: [{ key: '', value: '', type: '' }],
      struct: [],
      array: {
        type: '',
        count: 10,
        list: [],
      },
    },
  };

  created() {
    this.form.id = uuid();
    const dataValue = this.model.getValue(this.field.pkId);
    this.form.dataSpecs.array.type = DATATYPE.int.toLocaleLowerCase();
    if (dataValue) {
      this.form = deepClone(dataValue);
    }
  }
  @Watch('form', { deep: true })
  handleFormChange() {
    this.form.thingIdentifier = this.thingIdentifier;
    this.$emit('input', this.form);
  }
  @Watch('form.dataType')
  handleTypeChange() {
    this.form.dataTypeText = this.DataType;
    this.refreshRender();
  }
  get thingIdentifier() {
    const type = this.types.find(v => v.pkId === this.form.dataType);
    if (type) {
      return type.thingIdentifier;
    }
    return '';
  }
  get DataType() {
    const type = this.types.find(v => v.pkId === this.form.dataType);
    if (type) {
      return type.datatype;
    }
    return '';
  }
  async refreshRender() {
    this.tickFlag = false;
    await this.$nextTick();
    this.tickFlag = true;
  }

  renderNumber() {
    const valueScopeMap = {
      [DATATYPE.int]: { max: 2147483647, min: -2147483648 },
      [DATATYPE.float]: { max: 3.4e38, min: -3.4e38 },
      [DATATYPE.double]: { max: Number.MAX_VALUE, min: -Number.MAX_VALUE },
    };
    const scope = valueScopeMap[this.DataType];
    const type = this.types.find(v => v.pkId === this.form.dataType);
    const jsonSpecs = JSON.parse(type.dataSpecs);
    return (
      <div>
        <a-form-model-item
          label={this.$t('controls.currentDataSet.ranges')}
          prop={'dataSpecs-number'}
          rules={{
            validator: validateInput.bind(this),
          }}
        >
          <a-row>
            <a-col span={11}>
              <a-input-number
                v-model={this.form.dataSpecs.number.min}
                placeholder={this.$t('controls.currentDataSet.minimum')}
                max={scope.max}
                min={scope.min}
              />
            </a-col>
            <a-col span={11} offset={2}>
              <a-input-number
                v-model={this.form.dataSpecs.number.max}
                placeholder={this.$t('controls.currentDataSet.maximum')}
                max={scope.max}
                min={scope.min}
              />
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label={this.$t('controls.currentDataSet.stepSize')}>
          <a-input-number
            placeholder={this.$t('controls.currentDataSet.stepSizePlaceholder')}
            v-model={this.form.dataSpecs.number.step}
            max={scope.max}
            min={scope.min}
          />
        </a-form-model-item>
        <a-form-model-item label={this.$t('controls.currentDataSet.unit')}>
          {jsonSpecs.unit.source === 'SYSTEM' ? (
            <a-select v-model={this.form.dataSpecs.number.unit}>
              {unitArray.map(item => (
                <a-select-option value={item.key} key={item.key}>
                  {item.value}
                </a-select-option>
              ))}
            </a-select>
          ) : (
            <a-input
              placeholder={this.$t('controls.currentDataSet.unitPlaceholder')}
              v-model={this.form.dataSpecs.number.unit}
            />
          )}
        </a-form-model-item>
      </div>
    );
  }

  renderText() {
    return (
      <a-form-model-item label={this.$t('controls.currentDataSet.maxLen')}>
        <a-row>
          <a-col span={22}>
            <a-input-number
              v-model={this.form.dataSpecs.text.maxLength}
              max={10240}
              min={1}
            />
          </a-col>
          <a-col span={2} class={this.$style.right}>
            {this.$t('connector.dataType.byte')}
          </a-col>
        </a-row>
      </a-form-model-item>
    );
  }

  renderDate() {
    return (
      <a-form-model-item label={this.$t('controls.currentDataSet.timeFormat')}>
        <a-input
          value={this.$t('controls.currentDataSet.timeLimit')}
          readOnly
        />
      </a-form-model-item>
    );
  }

  addEnumItem() {
    const type = this.types.find(v => v.pkId === this.form.dataType);
    const jsonSpecs = JSON.parse(type.dataSpecs);
    this.form.dataSpecs.enum.push({
      key: '',
      value: '',
      type: jsonSpecs.enumValueType || 'TEXT',
    });
  }
  delEnumItem(index) {
    this.form.dataSpecs.enum.splice(index, 1);
  }

  renderEnum() {
    if (this.form.dataSpecs.enum.length > 0) {
      if (!this.form.dataSpecs.enum[0].type) {
        const type = this.types.find(v => v.pkId === this.form.dataType);
        const jsonSpecs = JSON.parse(type.dataSpecs);
        this.form.dataSpecs.enum[0].type = jsonSpecs.enumValueType || 'TEXT';
      }
    }
    return (
      <div>
        <a-form-model-item style={'margin-bottom:0;'}>
          <label slot={'label'} class={'ant-form-item-required'}>
            {this.$t('controls.currentDataSet.enumItem')}
          </label>
          <a-row>
            <a-col span={11}>key</a-col>
            <a-col span={11}>value</a-col>
            {this.form.dataSpecs.enum.map((item, index) => (
              <a-row style="margin:15px 0;" key={index}>
                <a-col span={10}>
                  <a-form-model-item
                    prop={'dataSpecs-enum-key-' + index}
                    rules={{
                      validator: validateInput.bind(this),
                      trigger: 'change',
                    }}
                  >
                    <a-input placeholder="请输入描述" v-model={item.key} />
                  </a-form-model-item>
                </a-col>
                <a-col span={10} offset={1}>
                  <a-form-model-item
                    prop={'dataSpecs-enum-value-' + index}
                    rules={{
                      validator: validateInput.bind(this),
                      trigger: 'change',
                    }}
                  >
                    <a-input placeholder="请输入参数值" v-model={item.value} />
                    {/* <a-input-number
                      placeholder="请输入数值，如0、1、2..."
                      v-model={item.value}
                      max={2147483647}
                      min={-2147483648}
                    /> */}
                  </a-form-model-item>
                </a-col>
                <a-col span={1} offset={1}>
                  <a-icon
                    type="minus-circle"
                    onClick={() => {
                      this.delEnumItem(index);
                    }}
                  />
                </a-col>
              </a-row>
            ))}
          </a-row>
        </a-form-model-item>
        <a-form-model-item
          prop={'dataSpecs-enum-length'}
          rules={{
            validator: validateInput.bind(this),
          }}
        >
          <a-button
            icon={'plus'}
            type={'primary'}
            size={'small'}
            onClick={() => {
              this.addEnumItem();
            }}
          >
            {this.$t('controls.currentDataSet.addEnum')}
          </a-button>
        </a-form-model-item>
      </div>
    );
  }

  renderBoolean() {
    return (
      <a-form-model-item label={'数值描述'} required>
        <a-row>
          <a-col span={11}>
            <div class={this.$style.labelBox}>
              <span class={this.$style.span}>0-</span>
              <a-form-model-item
                prop="dataSpecs-boolean-0"
                rules={{
                  validator: validateInput.bind(this),
                  trigger: 'change',
                }}
              >
                <a-input
                  placeholder={this.$t('controls.currentDataSet.close')}
                  v-model={this.form.dataSpecs.boolean[0]}
                  maxLength={10}
                />
              </a-form-model-item>
            </div>
          </a-col>
          <a-col span={11} offset={2}>
            <div class={this.$style.labelBox}>
              <span class={this.$style.span}>1-</span>
              <a-form-model-item
                prop="dataSpecs-boolean-1"
                rules={{
                  validator: validateInput.bind(this),
                  trigger: 'change',
                }}
              >
                <a-input
                  placeholder={this.$t('controls.currentDataSet.open')}
                  v-model={this.form.dataSpecs.boolean[1]}
                  maxLength={10}
                />
              </a-form-model-item>
            </div>
          </a-col>
        </a-row>
      </a-form-model-item>
    );
  }

  renderStruct() {
    return (
      <a-form-model-item
        prop="dataSpecs-struct"
        rules={{
          validator: validateInput.bind(this),
          trigger: 'change',
        }}
      >
        <label slot={'label'} class={'ant-form-item-required'}>
          {this.$t('controls.currentDataSet.struct')}
        </label>
        {this.form.dataSpecs.struct.map((item, index) => (
          <a-row style="margin:10px 0;" key={item.paramTag}>
            <a-col span="20"> 参数名称：{item.paramName} </a-col>
            <a-col span="2">
              <a-icon
                type="edit"
                onClick={() => {
                  this.editParamItem(item, index);
                }}
              />
            </a-col>
            <a-col span="2">
              <a-icon
                type="minus-circle"
                onClick={() => {
                  this.form.dataSpecs.struct.splice(index, 1);
                }}
              />
            </a-col>
          </a-row>
        ))}
        <a-button
          icon={'plus'}
          type={'primary'}
          size={'small'}
          class={this.$style.mt10}
          onClick={() => {
            this.handleAddClick();
          }}
        >
          {this.$t('controls.currentDataSet.newObject')}
        </a-button>
      </a-form-model-item>
    );
  }

  editParamItem(item, index) {
    this.setExistedTags(index);
    this.paramDetail = item;
    this.editIndex = index;
    this.secondFormVisible = true;
  }

  handleAddClick() {
    this.setExistedTags();
    this.secondFormVisible = true;
    this.paramDetail = {};
  }

  setExistedTags(index) {
    const pType = this.DataType.toLocaleLowerCase();
    const list = this.form.dataSpecs[pType].list
      ? this.form.dataSpecs[pType].list
      : this.form.dataSpecs[pType];
    this.existedTags = [];
    list.map((item, idx) => {
      if (index !== idx) this.existedTags.push(item.paramTag);
    });
  }

  canUseTypeArray = [
    DATATYPE.int,
    DATATYPE.float,
    DATATYPE.double,
    DATATYPE.text,
    DATATYPE.struct,
  ];

  renderArray() {
    const array = this.canUseTypeArray.map(v => v.toLocaleLowerCase());
    return (
      <div>
        <a-form-model-item>
          <label slot={'label'} class={'ant-form-item-required'}>
            {this.$t('controls.currentDataSet.elementType')}
          </label>
          <a-radio-group
            v-model={this.form.dataSpecs.array.type}
            options={array}
          />
        </a-form-model-item>
        <a-form-model-item
          prop="dataSpecs-array-count"
          rules={{
            validator: validateInput.bind(this),
            trigger: 'change',
          }}
        >
          <label slot={'label'} class={'ant-form-item-required'}>
            {this.$t('controls.currentDataSet.eleCount')}
          </label>
          <a-input-number
            v-model={this.form.dataSpecs.array.count}
            min={1}
            max={512}
          />
        </a-form-model-item>
        {this.form.dataSpecs.array.type ===
          DATATYPE.struct.toLocaleLowerCase() && (
          <a-form-model-item
            prop="dataSpecs-array-list"
            rules={{
              validator: validateInput.bind(this),
              trigger: 'change',
            }}
          >
            <label slot={'label'} class={'ant-form-item-required'}>
              {'JSON对象'}
            </label>
            {this.form.dataSpecs.array.list.map((item, index) => (
              <a-row style="margin:10px 0;" key={item.paramTag}>
                <a-col span="20"> 参数名称：{item.paramName} </a-col>
                <a-col span="2">
                  <a-icon
                    type="edit"
                    onClick={() => {
                      this.editParamItem(item, index);
                    }}
                  />
                </a-col>
                <a-col span="2">
                  <a-icon
                    type="minus-circle"
                    onClick={() => {
                      this.form.dataSpecs.array.list.splice(index, 1);
                    }}
                  />
                </a-col>
              </a-row>
            ))}
            <a-button
              icon={'plus'}
              type={'primary'}
              size={'small'}
              class={this.$style.mt10}
              onClick={() => {
                this.handleAddClick();
              }}
            >
              {this.$t('controls.currentDataSet.newObject')}
            </a-button>
          </a-form-model-item>
        )}
      </div>
    );
  }

  get renderScheme() {
    switch (this.DataType) {
      case DATATYPE.int:
      case DATATYPE.float:
      case DATATYPE.double:
        return this.renderNumber();
      case DATATYPE.date:
        return this.renderDate();
      case DATATYPE.enum:
        return this.renderEnum();
      case DATATYPE.text:
        return this.renderText();
      case DATATYPE.boolean:
        return this.renderBoolean();
      case DATATYPE.struct:
        return this.renderStruct();
      case DATATYPE.array:
        return this.renderArray();
      default:
        return '';
    }
  }
  paramDetail = {};
  secondFormVisible = false;
  handleOk() {
    this.$refs.ruleForm2.validate(valid => {
      if (valid) {
        let formData = this.$refs.secondForm.form;
        if (formData.dataSpecs.enum.length) {
          formData.dataSpecs.enum = formData.dataSpecs.enum.map(v => {
            return {
              key: v.key,
              value: v.value + '',
              type: v.type,
            };
          });
        }
        if (Object.keys(this.paramDetail).length) {
          if (this.DataType === DATATYPE.struct) {
            this.form.dataSpecs.struct.splice(this.editIndex, 1, formData);
          }
          if (this.DataType === DATATYPE.array) {
            this.form.dataSpecs.array.list.splice(this.editIndex, 1, formData);
          }
        } else {
          if (this.DataType === DATATYPE.struct) {
            this.form.dataSpecs.struct.push(formData);
          }
          if (this.DataType === DATATYPE.array) {
            this.form.dataSpecs.array.list.push(formData);
          }
        }
        this.secondFormVisible = false;
      }
    });
  }

  tickFlag = true;
  existedTags = [];
  render() {
    return (
      <div class={this.$style.box}>
        <a-form-model-item
          prop="dataType"
          rules={{
            validator: validateInput.bind(this),
            trigger: 'change',
          }}
        >
          <label slot={'label'} class={'ant-form-item-required'}>
            数据类型
          </label>
          <a-select
            v-model={this.form.dataType}
            placeholder="请选择"
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            {this.types.map(item => (
              <a-select-option value={item.pkId} key={item.pkId}>
                {item.customizeName}
              </a-select-option>
            ))}
          </a-select>
        </a-form-model-item>
        {this.tickFlag && this.renderScheme}
        {this.secondFormVisible && (
          <a-modal
            v-model={this.secondFormVisible}
            title="添加参数"
            onOk={this.handleOk}
            width={500}
          >
            <a-form-model
              ref="ruleForm2"
              rules={{}}
              props={{
                model: this.paramDetail,
              }}
            >
              <secondForm
                ref="secondForm"
                paramDetail={this.paramDetail}
                existedTags={this.existedTags}
              />
            </a-form-model>
          </a-modal>
        )}
      </div>
    );
  }
}
</script>
<style lang="less" module>
.box {
  :global {
    .ant-form-item-control-wrapper {
      max-width: 400px;
    }
    .ant-input-number {
      width: 100%;
    }
    .ant-btn-sm {
      height: 24px;
    }
  }
}
.ml5 {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}
.mt10 {
  margin-top: 10px;
  font-size: 12px;
}
.zI101 {
  z-index: 101;
  position: relative;
}
.labelBox {
  display: flex;
  .span {
    width: 30px;
  }
}
.right {
  text-align: right;
}
</style>
