<template>
  <config-provider :locale="currentLocale" :renderEmpty="renderEmpty">
    <router-view :class="$style.root" />
  </config-provider>
</template>
<script>
import { Vue, Component, Watch } from 'vue-property-decorator';
import { ConfigProvider } from 'ant-design-vue';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import zh_TW from 'ant-design-vue/lib/locale-provider/zh_TW';
import en_US from 'ant-design-vue/lib/locale-provider/en_US';
import { ThemeSelect } from '@triascloud/x-blocks';
import { crossStorageModule, globalModule } from '@/enum/store';
import { createModal, Icon as XIcon } from '@triascloud/x-components';
import EmptyContent from '@/components/empty-content';
import { ssoClient } from '@triascloud/sso-client';
import { globalSocket } from '@triascloud/message-hub';
import { TenantCenterSocket } from '@/enum/socket';
import { Button } from 'ant-design-vue';

@Component({
  components: { ConfigProvider },
})
export default class App extends Vue {
  @crossStorageModule.State user;
  @crossStorageModule.State skin;
  @crossStorageModule.State locale;
  @globalModule.Action refreshUser;
  @globalModule.Action refreshCompanyList;

  get userId() {
    return this.user && this.user.pkId;
  }

  @Watch('userId')
  handleUserChange() {
    this.destroy();
    this.init();
  }

  get localeMap() {
    return {
      zh_CN,
      zh_TW,
      en_US,
    };
  }

  get currentLocale() {
    return this.localeMap[this.locale] || zh_CN;
  }

  renderEmpty() {
    return <EmptyContent type="list" />;
  }

  created() {
    this.init();
  }

  beforeDestroy() {
    this.destroy();
  }

  init() {
    ThemeSelect.setTheme(document.documentElement, this.skin);
    globalSocket.on(TenantCenterSocket.MemberUpdate, this.handleMemberUpdate);
    globalSocket.on(TenantCenterSocket.MemberDisable, this.handleMemberDisable);
    globalSocket.on(TenantCenterSocket.MemberResign, this.handleMemberResign);
    globalSocket.on(TenantCenterSocket.TenantDisband, this.handleTenantDisband);
    globalSocket.on(TenantCenterSocket.TenantUpdate, this.refreshCompanyList);
    // globalSocket.on(
    //   TemplateManageSocket.SolutionUpdate,
    //   this.handleMemberUpdate,
    // );
    globalSocket.on(
      TenantCenterSocket.PasswordUpdate,
      this.handlePasswordUpdate,
    );
    this.refreshCompanyList();
  }

  destroy() {
    globalSocket.off(TenantCenterSocket.MemberUpdate, this.handleMemberUpdate);
    globalSocket.off(
      TenantCenterSocket.MemberDisable,
      this.handleMemberDisable,
    );
    globalSocket.off(TenantCenterSocket.MemberResign, this.handleMemberResign);
    globalSocket.off(
      TenantCenterSocket.TenantDisband,
      this.handleTenantDisband,
    );
    globalSocket.off(TenantCenterSocket.TenantUpdate, this.refreshCompanyList);
    globalSocket.off(
      TenantCenterSocket.PasswordUpdate,
      this.handlePasswordUpdate,
    );
  }

  @Watch('locale', { immediate: true })
  setLocaleAttr() {
    document.documentElement.lang = this.locale.replace('_', '-');
  }

  handleMemberUpdate() {
    this.refreshUser();
  }

  handleMemberDisable() {
    this.stopAuth('您当前所在的企业账号已经被管理员停用，请重新登录');
  }

  handleMemberResign() {
    this.stopAuth('您当前所在的企业账号已经被管理员设为离职，请重新登录');
  }

  handleTenantDisband() {
    this.stopAuth('您当前所在的企业已被企业主解散，请重新登录');
  }

  handleTenantUpdate() {
    this.refreshCompanyList();
  }

  handlePasswordUpdate() {
    ssoClient.logout(true);
  }

  stopAuth(tips) {
    createModal(
      () => (
        <div class={this.$style.tip}>
          <XIcon type="tc-icon-warning" class={this.$style.tipIcon} />
          <p>{tips}</p>
        </div>
      ),
      {
        title: this.$t('common.tips.prompt'),
        width: 440,
        className: this.$style['logout-modal'],
        closable: false,
        maskClosable: false,
        footer: () => (
          <Button type="primary" onClick={() => ssoClient.logout()}>
            {this.$t('common.action.confirm')}
          </Button>
        ),
      },
    );
  }

  get documentTitle() {
    return this.$path(this.$route.path);
  }

  get siteName() {
    return this.$t('enterprise.triascloud');
  }

  @Watch('documentTitle', { immediate: true })
  handleDocumentTitleChange(title) {
    document.title = title ? `${title} - ${this.siteName}` : this.siteName;
  }
}
</script>
<style lang="less" module>
.root {
  height: 100%;
  min-width: 760px;
}
.logout-modal {
  text-align: center;
  .tipIcon {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 30px;
    color: #fe5959;
  }
  p {
    font-size: 16px;
    color: var(--font);
  }
}
:global .tint .ant-modal-content {
  box-shadow: none;
}
</style>
