import request from '../request';
const serviceNameApi = '/iot-platform/platformInterface';

/**@name 添加API */
export function addApi(data) {
  return request(`${serviceNameApi}`, {
    method: 'POST',
    body: data,
  });
}
/**@name 获取API基础信息 */
export function getApiBaseInfo(platformInterfaceId) {
  return request(`${serviceNameApi}/baseInfo/${platformInterfaceId}`, {
    method: 'GET',
  });
}

/**@name 修改API基础信息 */
export function updateApiBaseInfo(data) {
  return request(`${serviceNameApi}/baseInfo`, {
    method: 'PUT',
    body: data,
  });
}

/**@name 获取平台API列表 */
export function getApiList(data) {
  return request(`${serviceNameApi}/list`, {
    method: 'GET',
    body: data,
  });
}
/**@name 获取oss */
export function getOssHost() {
  return request('/oss/oss/host', {
    method: 'GET',
  });
}

/**@name 获取平台API基础设置 */
export function getApiBasicSetting(apiId) {
  return request(`${serviceNameApi}/basic/${apiId}`, {
    method: 'GET',
  });
}

/**@name 修改平台API基础设置 */
export function updateApiBasicSetting(data) {
  return request(`${serviceNameApi}/baseSetting`, {
    method: 'PUT',
    body: data,
  });
}

/**@name 删除平台API基础设置 */
export function deleteConfigs(id) {
  return request(`${serviceNameApi}/${id}`, {
    method: 'DELETE',
  });
}
/**@name 校验api标识是否可用 */
export function checkApiIdentifier(data) {
  return request(`${serviceNameApi}/checkIdentifierCanUse`, {
    method: 'POST',
    body: data,
  });
}

/**@name 获取平台API触发设置 */
export function getTriggerSettings(id) {
  return request(`${serviceNameApi}/triggerSettings/${id}`, {
    method: 'GET',
  });
}

/**@name 修改平台API触发设置 */
export function updateTriggerSettings(data) {
  return request(`${serviceNameApi}/triggerSettings`, {
    method: 'PUT',
    body: data,
  });
}

/**@name HTTP调试 */
export function debug(headers, data) {
  return request(`${serviceNameApi}/debug`, {
    method: 'POST',
    body: data,
    headers: headers,
  });
}

/** @name 获取前置信息的API */
export function preInterface(id) {
  return request(`${serviceNameApi}/before/${id}`, {
    method: 'GET',
  });
}

/** @name 前置接口API */
export function prefixApi(url, data) {
  return request(url, {
    method: 'POST',
    body: data,
  });
}

/**@name 修改平台API设置 */
export function setApiSettings(data) {
  return request(`${serviceNameApi}/apiSettings`, {
    method: 'PUT',
    body: data,
  });
}
/**@name 获取平台API设置 */
export function getApiSettings(id) {
  return request(`${serviceNameApi}/apiSettings/${id}`, {
    method: 'GET',
  });
}

/**@name 获取当前企业可绑定接口 */
export function getBindApiList(id) {
  return request(`/iot-platform/interface/permissionList/${id}`, {
    method: 'GET',
  });
}

/**@name 获取api获取公共-鉴权连接器列表*/
export function getAuthList(platformId) {
  return request(`${serviceNameApi}/publicAuthenticationApi/${platformId}`, {
    method: 'GET',
  });
}

/**@name 修改平台API发布状态*/
export function updateStatus(data) {
  return request(`${serviceNameApi}/status`, {
    method: 'PUT',
    body: data,
  });
}

// * 三方推送列表
export function threePushList(data) {
  return request('/iot-platform/platformInterface/develop/list', {
    method: 'POST',
    body: data,
  });
}

// * 添加授权
export function addAuth(data) {
  return request('/iot-platform/platformInterface/develop', {
    method: 'PUT',
    body: data,
  });
}

// * 三方推送列表
export function authApiList(data) {
  return request('/iot-platform/platformInterface/develop/thirdParty', {
    method: 'GET',
    body: data,
  });
}
