import request from '../request';
const basePath = '/resource/helpCenter';

/**
 * @name 获取帮助中心列表
 * @param {{ current: number; size: number }} param0
 */
export const getList = ({ current, size }) =>
  request(`${basePath}/pageHelpCenter`, {
    body: {
      current,
      size,
    },
  });

/**
 * @name 删除帮助中心文档
 * @param {string} pkCenterId
 */
export const remove = pkCenterId =>
  request(`${basePath}/remove/${pkCenterId}`, {
    method: 'POST',
  });

/**
 * @name 批量修改排序
 * @param {Array<{id: string;sort:number}>} arr
 */
export const sort = arr =>
  request(`${basePath}/reorder`, { method: 'POST', body: arr });

/**
 * @name 添加帮助中心文档
 * @param {{ centerName: string;idxRepositoryId:string;}} param
 */
export const add = ({ centerName, idxRepositoryId }) =>
  request(`${basePath}/add`, {
    method: 'POST',
    body: { centerName, idxRepositoryId },
  });

/**
 * @name 更新帮助中心文档
 * @param {{ centerName: string;idxRepositoryId:string;pkCenterId:string;}} param
 */
export const update = ({ centerName, idxRepositoryId, pkCenterId }) =>
  request(`${basePath}/update`, {
    method: 'POST',
    body: { centerName, idxRepositoryId, pkCenterId },
  });

/**
 * @name 帮助中心文档树
 *
 */
export const documentTree = () =>
  request(`${basePath}/tree`, {
    method: 'GET',
  });
