<template>
  <div :class="$style.wrap">
    <div :class="$style.actionWrap">
      <a-input
        :class="[$style.input, focus ? $style.focus : '']"
        v-model="keyword"
        @focus="focus = true"
        @blur="focus = false"
        @pressEnter="getRecords"
      >
        <x-icon type="search" slot="prefix" />
      </a-input>
      <div :class="$style.action">
        <a-button
          type="primary"
          ghost
          @click="AddGuideMethod()"
          v-if="$p.action('CREATE')"
          >{{ $t('website.setting.newConfiguration') }}</a-button
        >
        <a-button type="primary" @click="guideSet" v-if="$p.action('shield')">{{
          $t('website.setting.guideDisplaySettings')
        }}</a-button>
      </div>
    </div>

    <x-data-table
      ref="table"
      :columns="columns"
      :service="service"
      :scroll="{
        x: '100%',
        y: '100%',
      }"
      :pagination="false"
      :class="$style.table"
    >
      <div slot="headerLeft"></div>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import GuideSetting from './guide-setting.vue';
import guideTemplate from './guide-template.vue';
import { guideList } from '@/services/configuration/operating';
import { DataSourceService } from '@triascloud/services';
import { delGuide, disabledList } from '@/services/configuration/operating';

@Component()
export default class Index extends Vue {
  focus = false;
  keyword = null;
  getRecords() {
    this.$refs.table.loadData();
  }

  disabledList = [];

  async guideSet() {
    const data = await GuideSetting.createModal(
      { disabledList: this.disabledList },
      {
        closed: () => {},
      },
    );
    await disabledList(
      data.map(item => {
        return {
          tenantName: item.enterpriseName
            ? item.enterpriseName
            : item.tenantName,
          tenantId: item.pkId,
        };
      }),
    );
  }

  async AddGuideMethod(res, view = false, copy = false) {
    const modal = await guideTemplate.createModal(
      {
        data: res,
        view: view,
        copy: copy,
        title: res ? res.title : this.$t('website.setting.newGuidelines'),
      },
      {
        cancel: () => {
          modal.visible = false;
        },
        refresh: () => {
          this.getRecords();
        },
      },
    );
  }

  get columns() {
    return [
      {
        dataIndex: 'styleName',
        title: this.$t('website.setting.styleName'),
      },
      {
        dataIndex: 'tenantName',
        title: this.$t('enterprise.enterpriseText'),
        ellipsis: true,
      },
      {
        title: this.$t('common.action.operating'),
        key: 'action',
        customRender: record => {
          const view = (
            <AButton
              type="link"
              v-show={this.$p.action('READ')}
              onClick={() => {
                this.AddGuideMethod(
                  {
                    ...record,
                    title: this.$t('website.setting.viewGuidelines'),
                  },
                  true,
                );
              }}
            >
              {this.$t('activity.Check')}
            </AButton>
          );
          const remove = (
            <AButton
              type="link"
              v-show={!record.defaultConfig && this.$p.action('DELETE')}
              onClick={() => {
                this.removeGUide(record);
              }}
            >
              {this.$t('common.action.delete')}
            </AButton>
          );
          const edit = (
            <AButton
              type="link"
              v-show={this.$p.action('UPDATE')}
              onClick={() => {
                this.AddGuideMethod(
                  {
                    ...record,
                    title: this.$t('website.setting.editorialGuidelines'),
                  },
                  false,
                );
              }}
            >
              {this.$t('solution.edit')}
            </AButton>
          );
          const copy = (
            <AButton
              type="link"
              v-show={this.$p.action('copy')}
              onClick={() => {
                this.copyGuide(record);
              }}
            >
              {this.$t('inviteCode.copy')}
            </AButton>
          );
          return [view, edit, copy, remove];
        },
      },
    ].map(item => ({
      ...item,
      width: item.width || 130,
      sorter: false,
      filter: false,
      align: 'left',
      ellipsis: false,
    }));
  }
  copyGuide(record) {
    this.AddGuideMethod({ ...record, defaultConfig: false }, false, true);
  }
  async removeGUide(res) {
    await this.$confirm(this.$t('record.delTip'));

    await delGuide(res.pkId);
    this.$refs.table.loadData();
  }
  get service() {
    const service = new DataSourceService(
      'configuration/operating/instruction/management/list',
    );
    service.getList = () =>
      guideList({ keyword: this.keyword }).then(res => {
        return {
          ...res,
          data: res.records,
          current: 1,
          hitCount: false,
          pages: 1,
          searchCount: true,
          size: 10,
          total: res.total,
        };
      });

    return service;
  }
}
</script>
<style lang="less" module>
.wrap {
  padding: 20px;
}

.actionWrap {
  display: flex;
  justify-content: space-between;
}
.action {
  button {
    margin-right: 20px;
  }
}
.input {
  width: 110px;
  transition: width 0.5s linear;
  &.focus {
    width: 200px;
  }
}
</style>
