import request from '../request';

const serviceName = '/resource/repository';
// 获取公开的企业知识库
export function getKnowledgeList() {
  return request(`${serviceName}/setting/knowledge/enterprise/list`);
}

/**
 * @name 知识库公开目录设置
 * @param {string} repositoryId
 */
export function getIndexSetting(repositoryId) {
  return request(`${serviceName}/setting/document/get/${repositoryId}`);
}
