<script>
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { ControlTypeEnum } from '../../data-template/template-design/form/controls';
import { DATATYPE } from '../../utils';
import { PARAM_STATUS, PARAM_TYPE } from './utils';
@Component()
export default class RenderArray extends Vue {
  @PropSync('item') syncItem;
  @PropSync('store') syncStore;
  @Prop() tooltip;
  @Prop() param;
  @Prop() schemeByType;
  @Prop() checkDefaultValue;
  @Prop({ type: String, default: 'id' }) useKey;

  count = 0;
  handleChangeNext() {
    this.count++;
    if (this.count > this.syncItem.fields.length - 1) {
      this.count = 0;
    }
  }
  handleChangePrev() {
    this.count--;
    if (this.count < 0) {
      this.count = this.syncItem.fields.length - 1;
    }
  }

  get current() {
    return this.syncItem.fields ? this.syncItem.fields[this.count] : [];
  }

  renderItem() {
    return (
      <div class={this.$style.ChildWrap}>
        <div class={this.$style.ChildrenBox}>
          <div class={this.$style.arrowBox}>
            <a-icon
              class={this.$style.arrow}
              type="left-circle"
              onClick={() => this.handleChangePrev()}
            />
            <span>
              {this.count + 1}&nbsp;/&nbsp;{this.syncItem.fields.length}
            </span>
            <a-icon
              class={this.$style.arrow}
              type="right-circle"
              onClick={() => this.handleChangeNext()}
            />
          </div>
          {this.current.map((v, idx) => {
            if (this.syncItem[PARAM_TYPE]) {
              Vue.set(v, PARAM_TYPE, this.syncItem[PARAM_TYPE]);
            }
            return (
              <div key={'struct' + idx + v.id}>
                {this.schemeByType(v, this.syncStore)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  fetchRules(item) {
    if (!item.required) return [];
    if (
      (item.type === ControlTypeEnum.Array &&
        item.subType === ControlTypeEnum.Struct) ||
      item.type === DATATYPE.array.toLocaleLowerCase()
    ) {
      let validate = (rule, value, callback) => {
        let fields = [].concat(...item.fields);
        let length = fields.length;
        let count = 0;
        let array = [];
        fields.forEach((child, idx) => {
          if (
            this.syncStore.get(child[this.useKey]) ||
            child.type === DATATYPE.boolean.toLocaleLowerCase() ||
            child.type === ControlTypeEnum.Switch
          ) {
            count++;
          } else {
            // 第几列表无数据校验
            array.push(Math.ceil((idx + 1) / item.fields[0].length));
          }
        });
        if (length !== 0 && count !== length) {
          this.count = array[0] - 1;
          callback(new Error(`第${array[0]}列表不能为空`));
        } else {
          callback();
        }
      };
      return [
        { required: item.required, validator: validate, trigger: 'change' },
      ];
    } else {
      return [
        { required: item.required, message: '不能为空', trigger: 'change' },
      ];
    }
  }

  render() {
    const item = this.syncItem;
    let str;
    if (!item.fields) {
      let value = this.syncStore.get(item[this.useKey]) || [];
      let disabled = false;
      if (item[PARAM_TYPE] === PARAM_STATUS.OUT) {
        disabled = true;
      }
      str = (
        <a-select
          disabled={disabled}
          mode="tags"
          style="width: 100%"
          token-separators={[',']}
          value={this.syncStore.get(item[this.useKey]) || []}
          onChange={val => {
            this.syncStore.set(item[this.useKey], val);
          }}
        >
          {Array.isArray(value) &&
            value.map((v, idx) => {
              <a-select-option key={'sep' + idx}>{v}</a-select-option>;
            })}
        </a-select>
      );
    }

    const rules = this.fetchRules(item);
    return (
      <a-form-model-item
        rules={rules}
        prop={item[this.useKey]}
        class={this.$style.formItem}
      >
        {item[PARAM_TYPE] !== PARAM_STATUS.OUT ? (
          <label slot="label" class={this.$style.label}>
            <span>{item.name}</span>
            {this.tooltip(item)}
            {this.param(item)}
          </label>
        ) : (
          ''
        )}
        {item[PARAM_TYPE] !== PARAM_STATUS.OUT && item.fields ? (
          <a-input
            disabled
            value={item.identifier ? item.identifier : item.name}
          />
        ) : (
          str
        )}
        {item.fields ? this.renderItem() : ''}
      </a-form-model-item>
    );
  }
}
</script>
<style lang="less" module>
.formItem {
  :global {
    .ant-form-item-required {
      display: flex;
      align-items: center;
    }
  }
}

.label {
  display: flex;
  align-items: center;
}

.ChildWrap {
  width: 100%;
  box-sizing: border-box;
  padding: 4px 4px 4px 10px;
}

.ChildrenBox {
  border-radius: 4px;
  box-shadow: 0 0 1px 3px var(--shadow);
  box-sizing: content-box;
  width: 100%;
  padding: 4px;
  width: calc(100% - 8px);
}

.arrowBox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .arrow {
    font-size: 20px;
  }
}
</style>
