<template>
  <a-form-model class="api-wrapper" :model="form" :rules="rules" ref="form">
    <a-form-model-item label="变量类型" prop="variableType">
      <a-select
        placeholder="请选择"
        v-model="form.variableType"
        :disabled="form.apiVariableId"
      >
        <a-select-option
          v-for="(item, idx) in variableTypes"
          :key="idx"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="变量标识" prop="identifier">
      <a-input
        v-model="form.identifier"
        placeholder="请输入"
        :disabled="form.apiVariableId"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item label="备注名" prop="remarkName">
      <a-input v-model="form.remarkName" placeholder="请输入"></a-input>
    </a-form-model-item>
    <a-form-model-item label="默认值" prop="defaultValue">
      <a-input v-model="form.defaultValue" placeholder="请输入"></a-input>
    </a-form-model-item>
    <a-form-model-item prop="remark">
      <span slot="label">
        <span style="margin-right: 300px">备注说明</span>
        <span style="margin-left: 10px" :class="$style.link" @click="viewLink">
          {{
            linkDivShow
              ? $t('connector.accessPlatform.back')
              : $t('common.action.preview')
          }}
        </span>
        <span :class="$style.link" @click="superLink">
          {{ $t('connector.accessPlatform.hyperlink') }}
        </span>
      </span>
      <div v-show="linkDivShow" ref="linkDiv" :class="$style.linkDiv"></div>
      <a-input
        v-model="form.remark"
        v-show="!linkDivShow"
        ref="platformAbout1"
        placeholder="请输入备注说明"
        type="textarea"
        :autoSize="{ minRows: 4, maxRows: 6 }"
      />
    </a-form-model-item>
    <!-- <a-form-model-item label="备注说明" prop="remark">
      <a-textarea
        v-model="form.remark"
        placeholder="请输入"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-form-model-item> -->
  </a-form-model>
</template>
<script>
/** @name 为超链接添加http */
const hasLink = (link = '') => {
  // egg: //www.baidu.com
  // egg: www.baidu.com
  if (link.indexOf('http') === -1) {
    let result = link.match(
      /((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/,
    );
    return `http://${result[0]}`;
  } else {
    return link;
  }
};
/** @name 超链接markdown语法转换 */
export const markedHref = (str = '') =>
  str.replace(/\[[^[]*?]\(.[^(]*?\)/g, s => {
    let arr = s.split('](');
    let href = arr[1].substring(0, arr[1].length - 1);
    href = hasLink(href);
    return `<a href="${href}" target="_blank">${arr[0].substring(1)}</a>`;
  });

import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  insertVariable,
  updateVariable,
} from '@/services/iot-platform/variable.js';
import { deepClone } from '@triascloud/utils';
import LinkForm from '../../components/link-form.vue';
import { createFormModal } from '@triascloud/x-components';
@Component({})
export default class ServiceForm extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) platformId;
  activeKey = 1;
  form = {
    defaultValue: '',
    identifier: '',
    platformId: '',
    remark: '',
    remarkName: '',
    variableType: undefined,
  };
  variableTypes = [
    {
      label: '开发变量',
      value: 'DEVELOP',
    },
    {
      label: '平台变量',
      value: 'PLATFORM',
    },
    {
      label: '订阅变量',
      value: 'SUBSCRIBE',
    },
    {
      label: '数据变量',
      value: 'DATA',
    },
  ];
  loading = false;
  rules = {
    variableType: [
      {
        required: true,
        message: '请选择变量类型',
      },
    ],
    identifier: [
      {
        max: 50,
        required: true,
        message: '请输入不超过50个字符的变量标识',
      },
    ],
    remarkName: [
      {
        max: 16,
        message: '请输入不超过16个字符的名称',
        trigger: 'change',
      },
    ],
    defaultValue: [
      {
        max: 256,
        message: '请输入不超过256个字符的备注',
        trigger: 'change',
      },
    ],
    remark: [
      {
        max: 256,
        message: '请输入小于256个字符的备注',
        trigger: 'change',
      },
    ],
  };

  created() {
    if (this.editData.apiVariableId) {
      this.form = deepClone(this.editData);
      this.variableTypes.push({
        label: '系统变量',
        value: 'SYSTEM',
      });
    } else {
      this.form.platformId = this.platformId;
    }
  }
  async superLink() {
    try {
      const model = await createFormModal(() => <LinkForm />, {
        width: '450px',
        title: this.$t('connector.accessPlatform.addHyperlink'),
      });
      if (model) {
        const area = this.$refs.platformAbout1.$el;
        const start = area.selectionStart;
        const showText = model.showText;
        const url = model.url;
        // const end = area.selectionEnd;
        const val = this.form.remark;
        const valArr = val.split('');
        // const bracketArr = ['[', ']', '(', ')'];
        // let flag = true;
        // 下标开始的位置是开头或者结尾，直接插入
        if (!(start === 0 || start === valArr.length)) {
          const left = valArr.slice(0, start);
          const right = valArr.slice(start, valArr.length);
          const rl = this.hasBracket(left, 'left');
          const rr = this.hasBracket(right, 'right');
          // )
          if (rl.cr) {
            // ( [  或者没有括号
            if (rr.cl || rr.sl || (!rr.cl && !rr.cr && !rr.sl && !rr.sr)) {
              this.form.remark =
                left.join('') + `[${showText}](${url})` + right.join('');
            }
          } else if (!rl.cl && !rl.cr && !rl.sl && !rl.sr) {
            // 左侧没有 括号
            this.form.remark =
              left.join('') + `[${showText}](${url})` + right.join('');
          } else if ((rl.sr && rl.sri !== 0) || (rr.cl && rr.cli !== 0)) {
            // [] 这里的情况 ()
            this.form.remark =
              left.join('') + `[${showText}](${url})` + right.join('');
          } else {
            const str = this.$t('connector.accessPlatform.notHyperlink');
            this.$message.error(str);
          }
        } else {
          // 直接插入
          if (start === 0) {
            this.form.remark = `[${showText}](${url})` + this.form.remark;
          } else if (start === valArr.length) {
            this.form.remark = this.form.remark + `[${showText}](${url})`;
          }
        }
      }
    } catch {
      return false;
    }
  }

  linkDivShow = false;
  viewLink() {
    if (this.linkDivShow || this.form.remark === '') {
      this.linkDivShow = false;
      return;
    }
    this.linkDivShow = true;
    this.$nextTick(() => {
      const div = this.$refs.linkDiv;
      const str = this.form.remark;
      div.innerHTML = markedHref(str);
    });
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            if (this.form.apiVariableId) {
              await updateVariable(this.form);
            } else {
              await insertVariable(this.form);
            }
            this.$emit('refreshList');
            resolve(true);
          } catch (error) {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }

  mounted() {}
  removeExtraItem(index) {
    this.extraList.splice(index, 1);
  }
  addExtraData() {
    this.extraList.push({
      name: '',
      stock: '',
      price: '',
      isNew: true,
    });
  }
}
</script>

<style lang="less" module>
.link {
  color: var(--primary);
  cursor: pointer;
  float: right;
}
.linkDiv {
  height: 94px;
  overflow: auto;
  border: 1px solid var(--form-border);
  max-height: 136px;
  border-radius: 4px;
  padding: 4px 11px;
  margin-bottom: 4px;
}
.btnGroup {
  margin-top: 20px;
  text-align: end;
  .btn {
    margin-right: 15px;
  }
}
.linkAbout {
  color: var(--primary);
  cursor: pointer;
}
:global {
  .dark {
    .w-e-bar,
    .w-e-bar-item .disabled:hover,
    .w-e-bar-item button:hover {
      background-color: var(--body-bg);
      color: var(--font);
    }
    .w-e-bar-item button {
      color: var(--font);
    }
  }
}
</style>
