<template>
  <div :class="$style.topData">
    <div :class="$style.card" v-for="item in dataList" :key="item.name">
      <x-custom-icon
        :class="$style.card_icon"
        :style="{
          background: item.color,
        }"
        :type="item.icon"
      />
      <div :class="$style.content">
        <div :class="$style.name">
          <span>{{ item.name }}</span>
          <span style="color: var(--font-info);">{{ item.status }}</span>
        </div>
        <span
          :class="$style.num"
          :style="{
            color: item.color,
          }"
          >{{ item.num || '0' }}</span
        >
      </div>
      <div
        :class="$style.cardBg"
        :style="{
          background: item.color,
        }"
      ></div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component()
export default class TopData extends Vue {
  @Prop({ type: Array, default: () => [], required: true }) dataList;
}
</script>
<style lang="less" module>
.topData {
  display: flex;
  justify-content: space-between;
  .card {
    width: 24%;
    height: 116px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-left: 20px;
    background: var(--block-bg);
    box-shadow: 0px 0px 8px 0px var(--shadow);
    border-radius: 8px;
    .card_icon {
      width: 66px;
      height: 66px;
      border-radius: 10px;
      color: #fff;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
    }
    .content {
      margin-left: 20px;
      .name {
        & > span {
          font-size: 14px;
        }
      }
      .num {
        font-size: 30px;
      }
    }
    .cardBg {
      width: 200px;
      height: 120%;
      border-radius: 19px;
      transform: rotateZ(30deg);
      position: absolute;
      right: 0;
      top: -39%;
      opacity: 0.05;
    }
  }
}
</style>
