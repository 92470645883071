<template>
  <a-form-model ref="form" :model="{ data }" :rules="rules">
    <ImgItem
      v-model="data"
      :status="false"
      formItemProp="data"
      @deleteItem="
        () => {
          $emit(
            'input',
            data.filter((_, i) => index !== i),
          );
        }
      "
      :type="type"
      :ref="type"
    />
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ImgItem from './img-item.vue';

@Component({
  components: {
    ImgItem,
  },
})
export default class AddModal extends Vue {
  @Prop({ type: String }) type;

  data = {
    description: '',
    image: '',
    pkId: '',
    title: '',
  };
  get rules() {
    return {
      'data.title': [],
      'data.image': [],
      'data.description': [],
    };
  }
  async getValue() {
    await this.$refs.form.validate();
    return this.data;
  }
}
</script>
<style lang="less" module></style>
