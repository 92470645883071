import request from '../request';

const basePath = '/tenant-center/tenant/member';
import defaultAvatar from '@/assets/img/default-avatar.png';
import defaultBoy from '@/assets/img/default-boy.png';
import defaultGirl from '@/assets/img/default-girl.png';

export const getCurrentUser = () => request(basePath);

/**
 * 获取用户头像
 */
export function getUserAvatar(user) {
  if (!user) return fixPath(defaultAvatar);
  if (user.avatar) return user.avatar;
  switch (user.sex) {
    case 'MALE':
      return fixPath(defaultBoy);
    case 'FEMALE':
      return fixPath(defaultGirl);
    default:
      return fixPath(defaultAvatar);
  }
}
function fixPath(path) {
  return /^\//.test(path) ? location.origin + path : path;
}
