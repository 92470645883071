import request from '../request';
export const basePath = '/template-manager/template';
export const serviceName = '/template-manager';

/**
 * @name 获取模板列表
 * @returns Promise<{ any[] }>
 */
export function getTemplateList({
  classifyId,
  keyword,
  current = 1,
  size = 10,
  pageOpen = false,
}) {
  return request(`${basePath}/applicationTemplateList`, {
    body: {
      classifyId,
      keyword,
    },
  }).then(result => {
    if (!pageOpen) return result;
    let startIndex = (current - 1) * size;
    let endIndex = current * size;
    if (result.length - 1 < endIndex) endIndex = result.length;

    return { data: result.slice(startIndex, endIndex), total: result.length };
  });
}

/**
 * @name 获取模板详情
 */
export function getTemplateDetail(appId) {
  return request(`${basePath}/templateApplicationDetails/${appId}`);
}

/**
 * @name 修改模板
 * @param {{"appBgColor": "","appIcon": "","appName": "","description": "","id": "","instruction": "","mobileScreenshotList": [],"pcScreenshotList": [],"recommendStart": 0}} data
 * @returns Promise<boolean>
 */
export const editTemplate = data =>
  request(`${basePath}/editApplicationTemplate`, {
    method: 'POST',
    body: data,
  });

export const removeTemplate = ({ templateId, templateType }) =>
  request(
    `${basePath}/delete?templateId=${templateId}&templateType=${templateType}`,
    {
      method: 'DELETE',
    },
  );

/**
 * @name 上下架
 */
export const setStatus = ({ state, templateId, templateType }) =>
  request(`${basePath}/standUpAndDown`, {
    method: 'POST',
    body: { state, templateId, templateType },
  });

/**
 * @name 获取模板回收站列表
 * @returns Promise<{ any[] }>
 */
export function getTemplateRecycleList(sendData) {
  return request(`${basePath}/applicationTemplateRecycleList`, {
    body: sendData,
  });
}

/** @name 彻底删除 */
export const removeCompletelyTemplate = ({ templateType, templateId }) =>
  request(
    `${basePath}/completelyCancel?templateType=${templateType}&templateId=${templateId}`,
    {
      method: 'DELETE',
    },
  );

/** @name 回收站恢复 */
export const recoverTemplate = ({ templateType, templateId }) =>
  request(
    `${basePath}/recover?templateType=${templateType}&templateId=${templateId}`,
    {
      method: 'DELETE',
    },
  );

/**
 * @name 获取表单模板列表
 * @returns Promise<{ any[] }>
 */
export function getFormTemplateList({
  classifyId,
  keyword,
  label,
  orderingRuleEnum,
  size,
  current,
}) {
  return request(`${basePath}/formTemplateList`, {
    body: {
      classifyId,
      keyword,
      label,
      orderingRuleEnum,
      size,
      current,
    },
  });
}

/**
 * @name 获取表单模板详情
 */
export function getFormTemplateDetail(pkId) {
  return request(`${basePath}/formInformation`, { body: { pkId } });
}

/**
 * @name 获取表单模板回收站列表
 * @returns Promise<{ any[] }>
 */
export function getFormTemplateRecycleList(sendData) {
  return request(`${basePath}/formTemplateRecycleList`, {
    body: sendData,
  });
}

/**
 * @name 修改模板
 * @param {{"appName": "","pkId": "","instruction": "","installScore: ""}} data
 * @returns Promise<boolean>
 */
export const editFormTemplate = data =>
  request(`${basePath}/formInformation`, {
    method: 'POST',
    body: data,
  });
/**
 * @name 应用模板安装详情
 * @param {*} param0
 * @returns
 */
export const applicationInstallDetail = ({ templateId, current, size }) =>
  request(
    `${basePath}/applicationInstallDetail?templateId=${templateId}&current=${current}&size=${size}`,
    {},
  );
/**
 * @name 表单模板安装详情
 * @param {*} param0
 * @returns
 */
export const formInstallDetail = ({ templateId, current, size }) =>
  request(
    `${basePath}/formInstallDetail?templateId=${templateId}&current=${current}&size=${size}`,
    {},
  );

/**
 * @name 获取模板综合统计
 * @returns Promise<{ any[] }>
 */
export function getTemplateData() {
  return request(`${basePath}/statistics/integratedStatisticalInterface`);
}

export function templateUseDetail(report) {
  return request(`${basePath}/statistics/templateUseDetail`, {
    method: 'GET',
    body: report,
  });
}

/**
 * @name 获取打印模板列表
 * @returns Promise<{ any[] }>
 */
export function getPrintTemplateList({
  classifyId,
  keyword,
  orderingRuleEnum,
  current,
  size,
}) {
  return request(`${basePath}/printTemplateList`, {
    body: {
      classifyId,
      keyword,
      orderingRuleEnum,
      current,
      size,
    },
  });
}

/**
 * @name 获取打印模板回收站列表
 * @returns Promise<{ any[] }>
 */
export function getPrintTemplateRecycleList(sendData) {
  return request(`${basePath}/printTemplateRecycleList`, {
    body: sendData,
  }).then(res => {
    return res.map(item => ({ ...item, id: item.pkId }));
  });
}

/**
 * @name 打印模板安装详情
 * @param {*} param0
 * @returns
 */
export const printInstallDetail = ({ templateId, current, size }) =>
  request(
    `${basePath}/printInstallDetail?templateId=${templateId}&current=${current}&size=${size}`,
    {},
  );

/**
 * @name 获取打印模板详情
 */
export function getPrintTemplateDetail(pkId) {
  return request(`${basePath}/printInformation`, { body: { pkId } });
}

/**
 * @name 修改打印模板
 * @param {{"appName": "","pkId": "","instruction": "","installScore: ""}} data
 * @returns Promise<boolean>
 */
export const editPrintTemplate = data =>
  request(`${basePath}/editPrintInformation`, {
    method: 'POST',
    body: data,
  });

/**
 * @name 编辑虚拟安装量
 * @param {{
  "count": "",
  "templateId": [],
  "templateType": ""
}} data
 * @returns Promise<boolean>
 */
export const editInstallTimes = data =>
  request(`${basePath}/edit/virtualInstallCount`, {
    method: 'POST',
    body: data,
  });
