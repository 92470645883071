<template>
  <div>
    <field-config-config-desc :desc="$t('controls.desc.addDataSet')" />
    <field-config-title v-bind="fieldProps" />
    <a-form-model-item>
      <template slot="label">
        <span>{{ $t('controls.addDataSet.type') }}</span>
        <a-tooltip :arrowPointAtCenter="true" placement="right">
          <div slot="title">{{ $t('controls.addDataSet.desc') }}</div>
          <a-icon type="info-circle" :class="$style.tooltipIcon" />
        </a-tooltip>
      </template>
      <a-radio-group
        :disabled="field.widget.disabled"
        :value="field.widget.paramType"
        @change="onChange"
      >
        <a-radio :value="1">
          {{ $t('controls.addDataSet.reqParam') }}
        </a-radio>
        <a-radio :value="2">
          {{ $t('controls.addDataSet.resParam') }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <field-config-description v-bind="fieldProps" />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import FieldConfigTitle from '../components/field-config/title.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';

@Component({
  components: {
    FieldConfigTitle,
    FieldConfigDescription,
    FieldConfigConfigDesc,
  },
})
export default class AddDataSetConfig extends Vue {
  @Prop() field;
  @Prop() updateField;

  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
    };
  }

  onChange(val) {
    this.updateField({
      key: 'widget.paramType',
      value: val.target.value,
    });
    let str = '';
    if (val.target.value === 1) {
      str = 'inputData';
    } else {
      str = 'outputData';
    }
    this.updateField({
      key: 'widget.identifier',
      value: str,
    });
  }
}
</script>
<style lang="less" module>
.tooltipIcon {
  margin: 0 5px;
  z-index: 101;
  position: relative;
  vertical-align: middle !important;
}
</style>
