<template>
  <div :class="$style.addCode">
    <a-form-model ref="form" :model="formData" :rules="rules">
      <a-form-model-item :label="$t('record.setTime') + '：'" prop="pushTime">
        <a-select
          v-model="formData.pushTime"
          :placeholder="$t('common.placeholder.select')"
        >
          <a-select-option
            v-for="item in timeList"
            :key="item.value"
            :value="item.value"
            >{{ item.text }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <!-- <div :class="$style.footer">
      <a-button @click="$emit('handleClosed')">取消</a-button>
      <a-button type="primary">确定</a-button>
    </div> -->
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { pushRecord } from '@/services/configuration/recordConfig';
import { deepClone } from '@triascloud/utils';
import { globalVueI18n } from '@triascloud/i18n';

function createModalPushTime(props, listener) {
  const PushTimeModal = createFormModal(
    h =>
      h(PushTime, {
        props,
        on: {
          ...listener,
          handleClosed: () => {
            PushTimeModal.handleClose();
          },
        },
      }),
    {
      title: globalVueI18n.translate('record.confirmRelease'),
      width: 420,
      bodyStyle: {
        padding: 0,
      },
      wrapClassName: 'form-preview-drawer--wrapper',
    },
  );
  return PushTimeModal;
}
@Component()
export default class PushTime extends Vue {
  @Prop({ type: Object, default: {} }) data;
  static createFormModal = createModalPushTime;
  formData = {
    pushTime: null,
  };
  rules = {
    pushTime: [
      {
        required: true,
        trigger: 'blur',
        message: this.$t('record.releaseTime'),
      },
    ],
  };
  get timeList() {
    const time = [
      { value: 0, text: this.$t('record.publish') },
      { value: 30, text: this.$t('record.halfHour') },
    ];
    for (let i = 1; i <= 24; i++) {
      time.push({ value: i, text: `${i}${this.$t('record.hourLater')}` });
    }
    return time;
  }

  created() {
    this.formData = deepClone(this.data);
    this.formData.pushTime = !isNaN(parseInt(this.formData.pushTime))
      ? parseInt(this.formData.pushTime)
      : 0;
  }

  async getValue() {
    await this.$refs.form.validate();
    await this.pushVersion();
    return this.formData.pushTime;
  }

  async pushVersion() {
    let data = {
      ...this.formData,
      pushTime: this.formData.pushTime,
      pushTimeUnit:
        this.formData.pushTime === 30 || this.formData.pushTime === 0
          ? 'MINUTE'
          : 'HOUR',
      synopsis: this.formData.synopsis
        ? JSON.parse(this.formData.synopsis)
        : [],
      msgType: this.formData.msgType ? JSON.parse(this.formData.msgType) : [],
    };
    await pushRecord(data);
  }
}
</script>
<style lang="less" module>
.addCode {
  padding: 20px;
  padding-bottom: 0;
  :global(.ant-radio-wrapper) {
    margin: 0 32px 0 0;
  }
}
.title {
  color: var(--font-info);
  margin-bottom: 10px;
}
.footer {
  text-align: right;
  margin-top: 20px;
  button {
    margin-right: 20px;
  }
}
</style>
