<template>
  <div ref="OrderPayConversion" />
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import ResizeObserver from 'resize-observer-polyfill';
import { crossStorageModule } from '@/enum/store';

@Component({
  components: {},
})
export default class ManageCountEchart extends Vue {
  @Prop({ type: Object }) data;
  /** @type { ResizeObserver } */
  _ob = null;
  myChart = null;
  @crossStorageModule.State skin;

  mounted() {
    this._ob = new ResizeObserver(this.chartResize);
    this._ob.observe(this.$refs.OrderPayConversion);
    this.initChart();
  }
  chartResize() {
    this.myChart.resize();
  }
  get timeOptions() {
    return [
      {
        text: this.$t('common.week'),
        value: 'WEEK',
      },
      {
        text: this.$t('common.month'),
        value: 'MONTH',
      },
      {
        text: this.$t('common.year'),
        value: 'YEAR',
      },
      {
        text: this.$t('common.label.all'),
        value: 'ALL',
      },
    ];
  }

  get option() {
    const res =
      this.data && this.data.solutionCountDetailList
        ? this.data.solutionCountDetailList
        : [];
    return {
      title: {
        subtext: this.$t('solution.SolutionSubTlt'),
        top: 10,
        left: -5,
      },
      legend: {
        show: true,
        data: [
          this.$t('solution.numberPlans'),
          this.$t('solution.paidQuantity'),
        ],
        top: 25,
        right: 10,
        itemWidth: 8,
        itemHeight: 8,
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },

      yAxis: {
        max: 'dataMax',
        // data:['0%', 100, 200,]
      },
      xAxis: {
        data: res.map(item => item.tenantName),
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          rotate: 45,
          formatter: function(value) {
            if (value.length >= 4) {
              let v = value.slice(0, 4) + '...';
              return v;
            } else {
              return value;
            }
          },
        },
      },
      series: [
        {
          name: this.$t('solution.numberPlans'),
          type: 'bar',
          data: res.map(item => item.solutionCount),
          color: ['#68b4ff'],
          stack: 'one',
          barWidth: 30,
        },
        {
          name: this.$t('solution.paidQuantity'),
          type: 'bar',
          data: res.map(item => item.buyCount),
          color: ['#25b97b'],
          stack: 'one',
          barWidth: 30,
        },
      ],
    };
  }
  @Watch('option')
  @Watch('skin', { deep: true })
  initChart() {
    const chart = this.$refs.OrderPayConversion;
    this.myChart = echarts.init(chart);
    this.myChart.setOption(this.option);
  }
}
</script>
<style lang="less" module></style>
