<template>
  <div :class="$style.container">
    <BasicFormModal
      ref="basicFormModal"
      :form-model-data="computedFormModalData"
      :formData="formData"
      @changeValue="changeValue"
    />
    <!-- 底部按钮 -->
    <CommonFooter
      slot="footer"
      type="download"
      :initParams="initParams"
      :mode="mode"
      :habitService="habitService"
      :formData="formData"
      :footerRequests="footerRequests"
      @close="$emit('close')"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { crossStorageModule } from '@/enum/store';
import { deepClone } from '@triascloud/utils';
import { HabitService } from '@triascloud/services';
import BasicFormModal from './basic-form-model.vue';
import CommonFooter from './common-footer.vue';
import { getClassifyList } from '@/services/configuration/classifyManagement';
import { addMedia, editMedia } from '@/services/configuration/downloadConfig';
import { classifyType } from '@/enum/classify';

@Component({
  components: {
    BasicFormModal,
    CommonFooter,
  },
})
export default class EditCase extends Vue {
  @Prop({ type: Object }) initParams;
  @Prop({ type: String }) mode;
  @crossStorageModule.State tenant;

  formData = {
    title: '',
    authority: '1',
    classifyId: '',
    img: '',
    briefIntroduction: '',
    fileUrl: null,
    signature: '',
    pushTime: this.$moment(new Date()).format('YYYY/MM/DD HH:mm:ss'),
    label: [],
    summarize: '',
  };
  classifyList = [];
  footerRequests = [addMedia, editMedia];

  get computedFormModalData() {
    return [
      {
        id: 1,
        name: 'title',
        label: 'common.title',
        type: 'textarea',
        rules: [
          {
            required: true,
            trigger: 'blur',
            preMsg: 'common.placeholder.input',
          },
        ],
      },
      {
        id: 2,
        name: 'authority',
        label: 'website.download.permission',
        type: 'radio',
        radioList: [
          {
            pkId: '1',
            classifyName: this.$t('website.download.publicDownload'),
          },
          {
            pkId: '2',
            disabled: true,
            classifyName: this.$t('website.download.loginDownload'),
          },
        ],
        rules: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
      },
      {
        id: 3,
        name: 'classifyId',
        label: 'controls.label.sort',
        type: 'radio',
        radioList: this.classifyList,
        rules: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
      },
      {
        id: 4,
        name: 'img',
        label: 'common.img',
        type: 'image',
        rules: [
          {
            required: true,
            trigger: 'change',
            preMsg: 'common.placeholder.add',
          },
        ],
      },
      {
        id: 5,
        name: 'briefIntroduction',
        label: 'connector.accessPlatform.briefIntroduction ',
        type: 'textarea',
        rules: [
          {
            required: true,
            trigger: 'blur',
            preMsg: 'common.placeholder.input',
          },
        ],
      },

      {
        id: 6,
        name: 'fileUrl',
        label: 'feedback.annex',
        type: 'upload',
        rules: [
          {
            required: true,
            trigger: 'change',
            preMsg: 'common.placeholder.upload',
          },
        ],
      },
      {
        id: 7,
        name: 'signature',
        label: 'website.author',
        type: 'textarea',
        rules: [
          {
            required: true,
            trigger: 'blur',
            preMsg: 'common.placeholder.input',
          },
        ],
      },
      {
        id: 8,
        name: 'pushTime',
        label: 'help.createDate',
        type: 'time',
        rules: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
      },
      {
        id: 9,
        name: 'label',
        label: 'common.label.name',
        type: 'label',
        labelType: 'download',
        marginBottom: '30px',
        rules: [
          {
            required: true,
            trigger: 'blur',
            preMsg: 'common.placeholder.select',
          },
        ],
      },
      {
        id: 10,
        name: 'summarize',
        label: 'website.summarize',
        type: 'editor',
        layout: 'singleLine',
        rules: [
          {
            required: true,
            trigger: 'change',
            preMsg: 'common.placeholder.input',
          },
        ],
      },
    ];
  }

  get habitService() {
    return new HabitService('/configuration/memberHabit');
  }

  // 编辑和暂存处理
  async created() {
    await this.getClassifyList();
    if (this.initParams) {
      let initParams = deepClone(this.initParams);
      if (initParams.classify && initParams.classify.length) {
        initParams.classifyId = initParams.classify[0].pkId;
        delete initParams.classify;
      }
      this.formData = initParams;
      if (this.formData.pushTime) {
        this.formData.pushTime = this.$moment(this.formData.pushTime).format(
          'YYYY/MM/DD HH:mm:ss',
        );
      }
      if (this.formData.fileUrl) {
        this.formData.fileUrl = JSON.parse(this.formData.fileUrl);
      }
    } else {
      const list = await this.habitService.getItem(
        `co.website.download.add.${this.tenant.pkId}`,
      );
      if (list && list !== '{}' && list !== 'null') {
        // 1. 保存的时候变成'{}'； 2. 后端默认返回的是'null'
        this.formData = JSON.parse(list);
        if (!this.formData.pushTime)
          // 处理空数据
          this.formData.pushTime = this.$moment(new Date()).format(
            'YYYY/MM/DD HH:mm:ss',
          );
      }
    }
  }

  //    分类相关
  async getClassifyList() {
    this.classifyList = await getClassifyList(classifyType.download);
    this.classifyList.sort((a, b) => a.pkId - b.pkId);
    if (this.classifyList && this.classifyList.length) {
      this.formData.classifyId = this.classifyList[0].pkId || '';
    }
  }

  // 修改 formData 的属性值
  changeValue([name, value, type = 'change']) {
    if (type === 'change') {
      this.formData[name] = value;
    } else if (type === 'add') {
      this.formData[name].push(value);
    } else if (type === 'remove') {
      this.formData[name] = this.formData[name].filter(
        item => item.pkId !== value,
      );
    }
  }
}
</script>
<style lang="less" module>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}
</style>
