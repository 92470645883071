<template>
  <div>
    <div :class="$style.contentTop">
      <a-input-search
        placeholder="变量标识/备注名"
        style="width: 200px;margin-right: 10px;"
        @search="handleSearchTag"
      />
      <a-select
        placeholder="请选择"
        @change="handleSearchType"
        style="width: 200px;margin-right: 10px;"
      >
        <a-select-option
          v-for="(item, idx) in variableTypes"
          :key="idx"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
      <a-button icon="plus" type="primary" @click.stop="addApi"
        >添加变量</a-button
      >
      <a-button type="primary" :class="$style.mr10" @click.stop="importVar">
        导入
      </a-button>
      <a-button :class="$style.mr10" @click.stop="downloadVar">{{
        this.$t('connector.extra.export')
      }}</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableList"
      :pagination="pagination"
      :row-key="record => record.id"
      :loading="tableLoading"
      @change="handlePagination"
    >
      <template slot="remark" slot-scope="text">
        <a-tooltip placement="bottomLeft">
          <div :class="$style.textStyle" v-html="markedHref(text)"></div>
          <div slot="title">
            <div v-html="markedHref(text)"></div>
          </div>
        </a-tooltip>
      </template>
      <template slot="defaultValue" slot-scope="text">
        <a-tooltip placement="bottomLeft">
          <div :class="$style.textStyle">
            {{ text }}
          </div>
          <div slot="title">
            <div v-html="markedHref(text)"></div>
          </div>
        </a-tooltip>
      </template>
      <div slot="templateType">
        <span>变量分类</span>
        <a-tooltip placement="right" :overlayStyle="{ maxWidth: '100%' }">
          <a-icon type="info-circle" style="margin-left: 5px" />
          <div slot="title">
            <div>
              系统变量：不允许新增和删除的默认变量类型，建议key、secret、appid、accesskey等信息使用此类型，请求时将对此变量值进行加密处理
            </div>
            <div>
              开发变量：API中“开发”参数推荐变量，对当前平台以及订阅用户统一生效，例如接口请求地址...
            </div>
            <div>
              平台变量：API中“平台方”参数推荐变量，为当前平台和订阅者提供云服务资源的无需加密的参数，例如实例id等
            </div>
            <div>
              订阅变量：API中“订阅方”参数推荐变量，为单个订阅者分配订阅单项服务的参数，例如消费订阅组、设备码、设备密钥…
            </div>
            <div>
              数据变量：通过”前置调用”获取，通过绑定接口返回赋值，例如token、动态签名...
            </div>
            <div style="color:orange">
              特别提醒：发布平台前需补全开发变量和部分平台变量的默认值，否则订阅用户将无法正常使用！
            </div>
          </div>
        </a-tooltip>
      </div>
      <template slot="operate" slot-scope="record">
        <span :class="$style.buttonGroups">
          <a :class="[$style.button]" @click.stop="openForm(record)">编辑</a>
          <a :class="[$style.button]" @click.stop="handleDel(record)">删除</a>
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
/** @name 为超链接添加http */
const hasLink = (link = '') => {
  // egg: //www.baidu.com
  // egg: www.baidu.com
  if (link.indexOf('http') === -1) {
    let result = link.match(
      /((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/,
    );
    return `http://${result[0]}`;
  } else {
    return link;
  }
};

import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import VarForm from './form.vue';
import UploadForm from './upload-form.vue';
import {
  getVariables,
  deleteVariable,
  exportVariables,
  importVariables,
} from '@/services/iot-platform/variable.js';

@Component({})
export default class ApiConfig extends Vue {
  @Prop({ type: String, default: '' }) id;
  mounted() {
    this.getList();
  }

  tableLoading = false;
  columns = [
    {
      dataIndex: 'index',
      customRender: (text, row, index) => {
        return index + 1;
      },
      title: '序号',
      align: 'center',
      width: 80,
    },
    {
      width: 200,
      dataIndex: 'identifier',
      title: '变量标识',
    },
    {
      width: 200,
      dataIndex: 'remarkName',
      title: '备注名',
    },
    {
      width: 200,
      dataIndex: 'variableType',
      slots: { title: 'templateType' },
      customRender: val => {
        const obj = {
          SYSTEM: '系统变量',
          DEVELOP: '开发变量',
          PLATFORM: '平台变量',
          SUBSCRIBE: '订阅变量',
          DATA: '数据变量',
        };
        return obj[val];
      },
    },
    {
      width: 300,
      dataIndex: 'defaultValue',
      title: '默认值',
      scopedSlots: { customRender: 'defaultValue' },
    },
    {
      dataIndex: 'remark',
      title: '备注说明',
      scopedSlots: { customRender: 'remark' },
    },
    {
      width: 150,
      dataIndex: '',
      title: '操作',
      scopedSlots: { customRender: 'operate' },
    },
  ];
  tableList = [];
  async importVar() {
    try {
      // let result =
      let formData = await createFormModal(() => <UploadForm />, {
        width: 360,
        title: '导入API变量',
      });
      if (formData) {
        await importVariables(this.id, formData);
        this.$message.success('导入成功！');
        this.getList();
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  async downloadVar() {
    await exportVariables(this.id);
  }
  handleSearchTag(val) {
    this.identifier = val;
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.getList();
  }
  handleSearchType(val) {
    this.variableType = val;
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.getList();
  }
  /** @name 超链接markdown语法转换 */
  markedHref(str = '') {
    return str.replace(/\[[^[]*?]\(.[^(]*?\)/g, s => {
      let arr = s.split('](');
      let href = arr[1].substring(0, arr[1].length - 1);
      href = hasLink(href);
      return `<a href="${href}" target="_blank">${arr[0].substring(1)}</a>`;
    });
  }
  async addApi() {
    await this.openForm({});
  }
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };
  async getList() {
    const params = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
      platformId: this.id,
    };
    if (this.variableType !== 'all') {
      params['variableType'] = this.variableType;
    }
    if (this.identifier) {
      params['identifier'] = this.identifier;
    }
    const { records, current, size, total } = await getVariables(params);
    this.tableList = records;
    this.pagination.current = current;
    this.pagination.pageSize = size;
    this.pagination.total = total;
  }
  async openForm(record) {
    const model = await createFormModal(
      () => (
        <VarForm
          platformId={this.id}
          editData={record}
          onClose={() => this.closeModel(model)}
          onRefreshList={() => this.refreshList()}
        />
      ),
      {
        title: 'API变量',
        width: 500,
      },
    );
  }
  refreshList() {
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.apiNameOrIdentifier = '';
    this.apiType = 'all';
    this.getList();
  }
  closeModel(m) {
    m.handleClose();
  }
  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getList();
  }

  async handleDel(record) {
    this.$confirm({
      title: '操作提示',
      content: `确定删除变量: ${record.remarkName} ？ 删除后已引用变量将失效，且对应设置可能无法保存！`,
      okText: this.$t('common.action.confirm'),
      cancelText: this.$t('common.action.cancel'),
      onOk: async () => {
        await deleteVariable(record.apiVariableId);
        this.getList();
        this.$message.success(this.$t('common.tips.delete'));
      },
    });
  }
}
</script>

<style lang="less" module>
.textStyle {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.contentTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
  .mr10 {
    margin-left: 10px;
  }
}
.buttonGroups {
  display: flex;
  justify-content: flex-start;
  .red {
    color: red;
  }
  .button {
    width: 50px;
    position: relative;
    .messageCount {
      position: absolute;
      font-size: 12px;
      top: 1px;
      right: 5px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      border-radius: 50%;
      background: var(--font-active);
      color: var(--table-row);
      text-align: center;
    }
  }
}
</style>
