<template>
  <div :class="$style.template">
    <div :class="$style.templateConfig">
      <div :class="$style.title">BI模板配置</div>
      <div :class="$style.line"></div>
      <a-button
        :class="$style.addBtn"
        type="primary"
        @click="addTemplate"
        icon="plus"
        v-if="$p.action('CREATE')"
      >
        添加模板
      </a-button>
      <a-tabs default-active-key="1" @change="changeTab">
        <a-tab-pane key="1" tab="项目BI">
          <div v-if="projectTree.length" :class="$style.treeList">
            <div
              :class="$style.tree"
              v-for="item in projectTree"
              :key="item.key"
            >
              <div
                :class="$style.catalogue"
                @click="item.isOpen = !item.isOpen"
              >
                {{ item.title
                }}<a-icon :type="item.isOpen ? 'down' : 'right'" />
              </div>
              <template v-if="item.isOpen">
                <div
                  :class="$style.item"
                  v-for="i in item.children"
                  :key="i.pkId"
                >
                  <span>{{ i.templateName }}</span>
                  <div :class="$style.operation">
                    <a-icon
                      type="form"
                      @click="editTemplate(i)"
                      v-if="$p.action('UPDATE')"
                    />
                    <a-icon
                      type="delete"
                      @click="deleteTemplate(i)"
                      v-if="$p.action('DELETE')"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <empty-content v-else />
        </a-tab-pane>
        <a-tab-pane key="2" tab="公司BI">
          <div v-if="companyTree.length" :class="$style.treeList">
            <div
              :class="$style.tree"
              v-for="item in companyTree"
              :key="item.key"
            >
              <div
                :class="$style.catalogue"
                @click="item.isOpen = !item.isOpen"
              >
                {{ item.title
                }}<a-icon :type="item.isOpen ? 'down' : 'right'" />
              </div>
              <template v-if="item.isOpen">
                <div
                  :class="$style.item"
                  v-for="i in item.children"
                  :key="i.pkId"
                >
                  <span>{{ i.templateName }}</span>
                  <div :class="$style.operation">
                    <a-icon
                      type="form"
                      @click="editTemplate(i)"
                      v-if="$p.action('UPDATE')"
                    />
                    <a-icon
                      type="delete"
                      @click="deleteTemplate(i)"
                      v-if="$p.action('DELETE')"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <empty-content v-else />
        </a-tab-pane>
      </a-tabs>
      <a-spin :spinning="loading" />
    </div>
    <div :class="$style.enterpriseConfig">
      <div :class="$style.title">物联网企业权限配置</div>
      <div :class="$style.tableWrap">
        <FlexColContent>
          <div :class="$style.operation">
            <div :class="$style.searchBox">
              <a-input
                :class="$style.searchInput"
                v-model="searchParams.keyword"
                @input.stop="() => $refs.tableRef.search()"
                placeholder="搜索企业名称"
              >
                <a-icon slot="prefix" type="search" />
              </a-input>
            </div>
            <a-button
              type="primary"
              @click="addEnterprise()"
              icon="plus"
              v-if="$p.action('CREATE')"
              >添加企业</a-button
            >
          </div>
          <template slot="footer">
            <PaginationTable
              ref="tableRef"
              :columns="columns"
              :api="getEnterpriseConfigList"
              :extraKeys="{ showKey: false }"
              row-key="pkId"
              :scroll="{ y: 'max-content' }"
              :slotKeys="['action', 'deviceCount']"
              :search-params="searchParams"
            >
              <template slot="action" slot-scope="{ record }">
                <div :class="$style.buttonGroups">
                  <span
                    :class="$style.button"
                    @click="editEnterprise(record)"
                    v-if="$p.action('UPDATE')"
                    >{{ $t('solution.edit') }}</span
                  >
                  <span
                    :class="$style.button"
                    @click="deleteEnterprise(record)"
                    v-if="$p.action('DELETE')"
                    >删除</span
                  >
                </div>
              </template>
            </PaginationTable>
          </template>
        </FlexColContent>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import {
  getTemplateList,
  deleteTemplate,
  getEnterpriseConfigList,
  deleteTenantConfig,
} from '@/services/digital-site/template';
import { createFormModal } from '@triascloud/x-components';
import { navMenu, componyNavMenu, projectNavMenu } from './enum';
import TemplateForm from './form-modal/template-form.vue';
import Tip from '@/components/tip';
import PaginationTable from '@/views/digital-site/components/pagination-table';
import FlexColContent from '@/views/digital-site/components/flex-col-content';
import EnterpriseForm from './form-modal/enterprise-form.vue';
import EmptyContent from '@/components/empty-content';

@Component({
  components: {
    PaginationTable,
    FlexColContent,
    EmptyContent,
  },
})
export default class NavTemplate extends Vue {
  mounted() {
    this.getTemplates();
  }
  /**
   * BI模板配置
   */
  loading = false;
  projectTree = [];
  companyTree = [];
  async getTemplates() {
    try {
      const [projectTemp, componyTemp] = await Promise.all([
        getTemplateList(1),
        getTemplateList(2),
      ]);
      this.loading = true;
      const projectList = [];
      const companyList = [];
      Object.keys(projectNavMenu).forEach(key => {
        projectList.push({
          title: navMenu[key],
          key,
          isOpen: projectTemp[key]?.length,
          children: projectTemp[key] || [],
        });
      });
      Object.keys(componyNavMenu).forEach(key => {
        companyList.push({
          title: navMenu[key],
          key,
          isOpen: componyTemp[key]?.filter(item => item.templateType === 2)
            .length,
          children:
            componyTemp[key]?.filter(item => item.templateType === 2) || [],
        });
      });
      this.projectTree = projectList;
      this.companyTree = companyList;
    } finally {
      this.loading = false;
    }
  }
  activeKey = '1';
  changeTab(key) {
    this.activeKey = key;
  }
  addTemplate() {
    this.showTemplateModal({}, 'add');
  }
  editTemplate(data) {
    this.showTemplateModal(data, 'edit');
  }
  async showTemplateModal(data, operationType) {
    const configName = {
      '1': '项目BI',
      '2': '公司BI',
    };
    try {
      const result = await createFormModal(
        () => (
          <TemplateForm
            templateType={this.activeKey}
            editData={data}
            menuList={
              this.activeKey === '1' ? this.projectTree : this.companyTree
            }
            operationType={operationType}
          />
        ),
        {
          width: 500,
          maskClosable: false,
          title:
            operationType === 'add'
              ? `${configName[this.activeKey]}-添加模板`
              : `${configName[this.activeKey]}-编辑模板`,
        },
      );
      if (result) {
        await this.getTemplates();
      }
    } catch {
      return false;
    }
  }
  async deleteTemplate(record) {
    const text = `确定删除模板-${record.templateName}？`;
    const tips = '删除后将无此模板，请谨慎删除';
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: '提示',
          onOk: async () => {
            await deleteTemplate(record.pkId);
            this.$message.success('删除成功!');
            this.getTemplates();
          },
        },
      );
    } catch {
      return false;
    }
  }
  /**
   * 物联网企业权限配置
   */
  get columns() {
    return [
      {
        align: 'left',
        title: '企业名称',
        dataIndex: 'tenantName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '项目权限数',
        dataIndex: 'projectTemplateCount',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '公司权限数',
        dataIndex: 'companyTemplateCount',
        ellipsis: true,
      },
      {
        title: this.$t('common.action.operating'),
        key: 'operation',
        width: 200,
        ellipsis: true,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  searchParams = {
    keyword: '',
  };
  getEnterpriseConfigList = getEnterpriseConfigList;
  projectList = [];
  addEnterprise() {
    this.showEnterpriseModel({}, 'add');
  }
  editEnterprise(row) {
    this.showEnterpriseModel(row, 'edit');
  }
  async showEnterpriseModel(row, operationType) {
    try {
      const result = await createFormModal(
        () => (
          <EnterpriseForm
            projectConfig={this.projectTree}
            componyConfig={this.companyTree}
            editData={row}
            operationType={operationType}
          />
        ),
        {
          width: 800,
          maskClosable: false,
          title: operationType === 'add' ? '添加企业' : '修改企业',
        },
      );
      if (result) {
        this.$refs.tableRef.getTableList();
      }
    } catch {
      return false;
    }
  }
  async deleteEnterprise(record) {
    const text = `确定删除企业-${record.tenantName}？`;
    const tips = '删除后将无此企业权限，请谨慎删除';
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: '提示',
          onOk: async () => {
            await deleteTenantConfig(record.pkId);
            this.$message.success('删除成功!');
            this.$refs.tableRef.getTableList();
          },
        },
      );
    } catch {
      return false;
    }
  }
}
</script>
<style lang="less" module>
.template {
  height: 100%;
  display: flex;
  .templateConfig,
  .enterpriseConfig {
    height: 100%;
    padding: 15px 20px;
    .title {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .templateConfig {
    width: 430px;
    padding-right: 0;
    position: relative;
    border-right: 1px solid var(--border);
    .line {
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 91.5px;
      border-bottom: 1px solid var(--border);
    }
    .addBtn {
      position: absolute;
      right: 20px;
      bottom: 20px;
      z-index: 10;
    }
    .treeList {
      height: 68vh;
      overflow-y: auto;
      padding: 20px 20px 0 0;
    }
    .tree {
      width: 100%;
      margin-bottom: 25px;
      .catalogue {
        user-select: none;
        svg {
          margin-left: 5px;
        }
      }
      .catalogue:hover {
        cursor: pointer;
        color: var(--primary);
      }
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        margin-top: 12px;
        .operation {
          display: flex;
          align-items: center;
          svg {
            margin-left: 10px;
            font-size: 18px;
          }
          svg:hover {
            cursor: pointer;
            color: var(--primary);
          }
        }
      }
    }
    :global {
      .ant-tabs-bar {
        margin-bottom: 0;
      }
    }
  }
  .enterpriseConfig {
    flex: 1;
    height: 100%;
    .tableWrap {
      height: calc(100% - 40px);
      .operation {
        display: flex;
        justify-content: space-between;
        margin: 8px 0 10px;
        .searchBox {
          .searchInput {
            width: 200px;
          }
        }
      }
      .buttonGroups {
        .button {
          cursor: pointer;
          color: var(--primary);
          margin-right: 20px;
        }
        .button:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
