<template>
  <div :class="$style.plist">
    <a-form-model layout="inline" :model="form" :rules="rules" ref="form">
      <!-- <p>当前平台设备容量10/500</p> -->
      <a-form-model-item prop="productName">
        <div style="display: flex;align-items: center">
          <a-input
            placeholder="分类名称"
            v-model="form.productName"
            :maxLength="16"
          />
          <span :class="$style.text" @click="addProduct">
            <a-icon type="plus" />
            <span>添加</span>
          </span>
        </div>
      </a-form-model-item>
    </a-form-model>
    <a-table
      style="margin-top: 10px;"
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.id"
      :loading="tableLoading"
      @change="handlePagination"
    >
      <template slot="operate" slot-scope="record">
        <span :class="$style.buttonGroups">
          <a :class="[$style.button]" @click.stop="handleUpdate(record)"
            >编辑</a
          >
          <a
            v-if="record.status !== 'UNPUBLISHED'"
            :class="[$style.button]"
            @click.stop="handleReviewRecord(record)"
            >发布记录</a
          >
          <a
            :class="[$style.button, $style.red]"
            @click.stop="handleDelete(record)"
            >删除</a
          >
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  delProducts,
  editProduct,
  getProducts,
  insertProduct,
} from '@/services/iot-platform/dpManager';
import { uuid } from '@triascloud/utils';
import { createFormModal, createModal } from '@triascloud/x-components';
import EditName from './edit-name';
import Detail from '@/views/connector/platform-review/review/detail.vue';

@Component
export default class ProductList extends Vue {
  @Prop({ type: String, default: '' }) platformId;

  form = {
    productName: '',
  };
  rules = {
    productName: [
      {
        required: true,
        message: '请输入产品分类名称',
      },
    ],
  };

  async addProduct() {
    await this.$refs.form.validate();
    const params = {
      idempotent: uuid(),
      idxPlatformId: this.platformId,
      name: this.form.productName,
    };
    try {
      await insertProduct(params);
      this.$message.success('添加成功');
      this.$emit('refreshList');
      this.form.productName = '';
      this.pagination.current = 1;
      await this.getProductList();
    } catch {
      return false;
    }
  }

  columns = [
    {
      dataIndex: 'index',
      customRender: (text, row, index) => {
        return index + 1;
      },
      title: '序号',
      align: 'center',
      width: 80,
    },
    {
      title: '产品名称',
      dataIndex: 'name',
      width: 200,
      scopedSlots: { customRender: 'name' },
    },
    {
      title: '当前状态',
      dataIndex: 'status',
      customRender: type => {
        const key = {
          UNPUBLISHED: '未发布',
          PENDING_APPROVAL: '待审批',
          PASSED: '已通过',
          REJECTED: '已驳回',
        };
        return key[type];
      },
    },
    {
      width: 200,
      dataIndex: '',
      title: '操作',
      scopedSlots: { customRender: 'operate' },
    },
  ];
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };

  mounted() {
    this.getProductList();
  }

  tableData = [];
  tableLoading = false;

  async getProductList() {
    this.tableLoading = true;
    const params = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    try {
      const { records, current, size, total } = await getProducts(
        this.platformId,
        params,
      );
      this.tableLoading = false;
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
    } catch {
      this.tableLoading = false;
      return false;
    }
  }

  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getProductList();
  }

  async handleUpdate(row) {
    try {
      const addParams = await createFormModal(
        () => <EditName name={row.name} />,
        {
          title: '修改产品分类名称',
          width: 450,
        },
      );
      if (addParams) {
        const formData = new FormData();
        formData.append('pkId', row.pkId);
        formData.append('name', addParams.productName);
        await editProduct(formData);
        this.$message.success('修改成功');
        this.$emit('refreshList');
        await this.getProductList();
      }
    } catch {
      return false;
    }
  }

  async handleDelete(record) {
    try {
      await delProducts(record.pkId);
      this.$message.success('删除成功');
      this.$emit('refreshList');
      this.pagination.current = 1;
      await this.getProductList();
    } catch {
      return false;
    }
  }

  handleReviewRecord(record) {
    createModal(() => <Detail id={record.pkId} />, {
      width: 900,
      title: '产品发布记录',
    });
  }

  getValue() {
    return true;
  }
}
</script>

<style lang="less" module>
.plist {
  .text {
    cursor: pointer;
    color: var(--primary);
    width: 90px;
    margin-left: 10px;
  }

  .buttonGroups {
    display: flex;
    justify-content: flex-start;

    .red {
      color: red;
    }

    .button {
      width: 60px;
      position: relative;
      text-align: center;

      .messageCount {
        position: absolute;
        font-size: 12px;
        top: 1px;
        right: 5px;
        height: 18px;
        width: 18px;
        line-height: 18px;
        border-radius: 50%;
        background: var(--font-active);
        color: var(--table-row);
        text-align: center;
      }
    }
  }
}
</style>
