<template>
  <div :class="$style.fields">
    <a-row :gutter="[10, 10]">
      <a-col :span="8" v-for="(item, idx) in value" :key="item.pkId">
        <div :class="$style.vCard">
          <div :class="$style.count">
            <div :class="$style.flex">
              <div style="width: 45px">标题：</div>
              <div>{{ item.title }}</div>
            </div>
            <div :class="$style.iconWrap" v-if="!disabledFlag">
              <a-icon
                :class="[$style.countIcon, $style.themeColor]"
                type="edit"
                @click="addUseCount(item, 'edit')"
              />
              <x-icon
                v-if="!item.updated"
                :class="[$style.countIcon, $style.deleteColor]"
                type="tc-icon-delete"
                @click="deleteUseCount(idx)"
              />
            </div>
          </div>
          <div :class="$style.count">
            <div style="width: 45px">内容：</div>
            <div style="width: 325px; line-height: 25px">
              <span :title="item.content">{{ formatStr(item.content) }}</span>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <div
      v-if="value.length < 20 && !disabledFlag"
      :class="[$style.add, $style.themeColor]"
      @click="addUseCount({}, 'add')"
    >
      <a-icon type="plus" />
      <span>添加自定义字段</span>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { uuid } from '@triascloud/utils';
import { createFormModal } from '@triascloud/x-components';
import AddField from '@/views/connector/platform-manage/develop/device-manage/introduce/add-field.vue';

@Component
export default class FieldsList extends Vue {
  @Prop({ type: Array, default: () => [] }) value;
  @Prop({ type: Boolean, default: false }) disabledFlag;

  deleteUseCount(index) {
    this.value.splice(index, 1);
  }

  formatStr(str) {
    if (str.length > 45) {
      return str.substring(0, 45) + '...';
    } else {
      return str;
    }
  }

  async addUseCount(item, flag) {
    const title = flag === 'add' ? '添加自定义字段' : '编辑自定义字段';
    const form = await createFormModal(() => <AddField editData={item} />, {
      title: title,
      width: 500,
    });
    if (form) {
      if (flag === 'add') {
        const o = {
          pkId: uuid(),
          title: form.title,
          content: form.content,
        };
        this.value.push(o);
      } else {
        const idx = this.value.findIndex(v => v.pkId === form.pkId);
        this.value[idx].title = form.title;
        this.value[idx].content = form.content;
      }
      this.$emit('input', this.value);
      this.$emit('change', this.value);
    }
  }
}
</script>

<style lang="less" module>
.fields {
  .vCard {
    //display: flex;
    //flex-direction: column;
    //justify-content: space-between;
    border: 1px solid var(--border);
    border-radius: 4px;
    height: 90px;
    padding: 8px;

    .count {
      display: flex;
      line-height: 30px;

      .iconWrap {
        display: inline-flex;
        align-items: center;
      }

      .countIcon {
        font-size: 16px;
        width: 24px;
        cursor: pointer;
        text-align: right;
      }
    }

    &:hover {
      border-color: var(--primary-40);
    }
  }

  .themeColor {
    color: var(--primary);
  }

  .deleteColor {
    color: var(--delete);
  }

  .successColor {
    color: var(--success);
  }

  .ml10 {
    margin-left: 10px;
  }

  .add {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    margin: 10px 0;
  }

  .flex {
    display: flex;
    align-items: baseline;
    width: 325px;
  }
}
</style>
