<template>
  <a-table
    :columns="columns"
    :data-source="tableData"
    :pagination="pagination"
    :row-key="record => record.id"
    :loading="loading"
    @change="handlePagination"
  >
    <div slot="platformName" slot-scope="text, record">
      <div :class="$style.logodiv">
        <x-oss-image class="x-file-icon" size="32" :oss-path="record.logoUrl" />
        <div :class="$style.logotext">{{ text }}</div>
      </div>
    </div>

    <div slot="action" slot-scope="record">
      <span :class="$style.buttonGroups">
        <a :class="$style.button" @click="aboutClick(record)">
          {{ $t('connector.accessPlatform.briefIntroduction') }}
        </a>
        <a
          v-if="$p.action('UPDATE')"
          :class="$style.button"
          @click.stop="setDevelop(true, record.platFormId, record.identifier)"
        >
          {{ $t('connector.accessPlatform.develop ') }}
        </a>
        <a
          v-if="$p.action('DELETE') && record.operatingStatus === 'DEVELOP'"
          :class="[$style.button, $style.red]"
          @click.stop="deletePlatform(record)"
        >
          {{ $t('common.action.delete') }}
        </a>
        <a
          v-else-if="$p.action('UPDATE') && record.operatingStatus === 'RUN'"
          :class="[$style.button, $style.red]"
          @click.stop="stopAndRun(record, 'DISABLE')"
        >
          {{ $t('connector.accessPlatform.outOfUse') }}
        </a>
        <a
          v-else-if="
            $p.action('UPDATE') && record.operatingStatus === 'DISABLE'
          "
          :class="[$style.button, $style.red]"
          @click.stop="stopAndRun(record, 'RUN')"
        >
          {{ $t('connector.accessPlatform.startUsing') }}
        </a>
      </span>
    </div>
  </a-table>
</template>

<script>
import { Component, Vue, Inject, Watch, Prop } from 'vue-property-decorator';
import {
  getPlatform,
  deletePlatform,
  changePlatformStatus,
} from '@/services/iot-platform/platform';
import DeleteForm from './components/delete-form';
import StopAndRun from './components/stop-run';
import { createFormModal, createModal } from '@triascloud/x-components';
import PlatformForm from './components/platform-form.vue';
import { Emitter } from '@/views/connector/platform-manage/develop/utils';

@Component({
  components: {
    PlatformForm,
    DeleteForm,
  },
})
export default class InPlatform extends Vue {
  @Inject() setDevelop;
  @Prop({ type: String, default: 'all' }) platrormStatus;
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => `${total} ` + this.$t('connector.dataTemplate.itmes'),
  };
  loading = false;
  statusText = {
    DEVELOP: this.$t('connector.accessPlatform.development'),
    RUN: this.$t('connector.accessPlatform.inService'),
    DISABLE: this.$t('connector.accessPlatform.outOfService'),
  };
  modeText = {
    PUBLIC: this.$t('connector.accessPlatform.publicCloud'),
    MIX: this.$t('connector.accessPlatform.mixedCloud'),
    PRIVATE: this.$t('connector.accessPlatform.privateCloud'),
  };
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => index + 1,
        title: this.$t('common.label.index'),
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        title: this.$t('connector.accessPlatform.platformLogoName'),
        dataIndex: 'platformName',
        width: 400,
        scopedSlots: { customRender: 'platformName' },
      },
      {
        title: this.$t('help.status'),
        dataIndex: 'operatingStatus',
        customRender: val => {
          return this.statusText[val];
        },
      },
      {
        title: this.$t('connector.accessPlatform.totalDeviceCount'),
        dataIndex: 'deviceNumber',
      },

      {
        title: this.$t('iot.device.creator'),
        dataIndex: 'creatorName',
      },
      {
        title: this.$t('connector.dataTemplate.lastEditTime'),
        dataIndex: 'updateTime',
        customRender: val => {
          return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
        },
      },
      {
        width: 240,
        title: this.$t('common.action.operating'),
        key: 'operation',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  @Watch('platrormStatus')
  changeStatus(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.pagination.current = 1;
      this.getTableList();
    }
  }

  handlePagination(pagination) {
    this.pagination = pagination;
    this.getTableList();
  }
  mounted() {
    // this.setDevelop(true);
    Emitter.$on('addPlatform', this.handleAddForm);
    this.getTableList();
  }
  beforeDestroy() {
    Emitter.$off('addPlatform', this.handleAddForm);
  }
  inPlayeformModel = null;
  editData = {};
  async handleAddForm(flag) {
    try {
      this.inPlayeformModel = await createModal(
        () => (
          <PlatformForm
            onClose={() => this.closeModel()}
            onToDevPage={this.toDevPage}
            operationFlag={flag}
            editData={this.editData}
          />
        ),
        {
          width: '500px',
          title:
            flag === 'add'
              ? this.$t('connector.accessPlatform.addPlatform')
              : this.$t('connector.accessPlatform.IntroductionOfPlatform'),
        },
      );
    } catch {
      return false;
    }
  }
  closeModel() {
    this.inPlayeformModel.handleClose();
    this.pagination.current = 1;
    this.$emit('update:platrormStatus', 'all');
    this.getTableList();
  }
  toDevPage(val) {
    this.setDevelop(true, val);
    this.inPlayeformModel.handleClose();
  }
  tableData = [];
  async getTableList() {
    const page = this.pagination;
    const params = {
      current: page.current,
      size: page.pageSize,
    };
    if (this.platrormStatus !== 'all') {
      params['operatingStatus'] = this.platrormStatus;
    }
    try {
      this.loading = true;
      const res = await getPlatform(params);
      this.tableData = res.records;
      this.loading = false;
      this.pagination.current = res.current;
      this.pagination.pageSize = res.size;
      this.pagination.total = res.total;
    } catch {
      this.loading = false;
      return false;
    }
  }
  aboutClick(row) {
    this.editData = row;
    this.handleAddForm('edit');
  }

  async deletePlatform(row) {
    const { platFormId, platformName } = row;
    const model = await createFormModal(
      () => (
        <DeleteForm
          platformName={platformName}
          onClose={() => model.handleClose()}
        />
      ),
      {
        width: '500px',
        title: this.$t('connector.accessPlatform.operationTips'),
      },
    );
    if (model) {
      try {
        await deletePlatform(platFormId);
        this.$message.success(this.$t('common.tips.delete'));
        if (this.pagination.current > 1) {
          if (this.tableData.length === 1) {
            this.pagination.current--;
          }
        }
        await this.getTableList();
      } catch {
        return false;
      }
    }
  }
  row = {};
  async stopAndRun(row, flag) {
    const { platFormId, operatingStatus } = row;
    const params = {
      operatingStatus: operatingStatus === 'RUN' ? 'DISABLE' : 'RUN',
      platFormId: platFormId,
    };
    const model = await createFormModal(
      () => <StopAndRun operating={flag} onClose={() => model.handleClose()} />,
      {
        width: '500px',
        title: this.$t('connector.accessPlatform.operationTips'),
      },
    );
    try {
      await changePlatformStatus(params);
      this.$message.success(this.$t('common.tips.edit'));
      await this.getTableList();
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.logodiv {
  display: flex;
  align-items: center;
  .logo {
    width: 50px;
    height: 50px;
  }
  .logotext {
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.buttonGroups {
  display: flex;
  justify-content: flex-start;
  .red {
    color: red;
  }
  .button {
    width: 50px;
    position: relative;
  }
}
</style>
