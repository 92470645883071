<template>
  <div :class="$style.index">
    <x-data-table
      ref="table"
      base-path="/template-manager/plugin/statistics/dbServiceTenantDetail"
      :columns="columns"
      :service="service"
      :class="$style.table"
    >
      <template slot="action"></template>
      <div slot="headerLeft"></div>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DataSourceService } from '@triascloud/services';
import { getDbServiceList } from '@/services/template-manager/plugin';
import { createModal } from '@triascloud/x-components';
import NodeDetails from '@/views/plugin/data/components/node-details.vue';

@Component
export default class NodeDetailsTable extends Vue {
  @Prop({ type: String, default: '' }) classId;
  get columns() {
    return [
      {
        title: this.$t('inviteCode.companyName'),
        dataIndex: 'tenantName',
        key: 'tenantName',
      },
      {
        key: 'configCount',
        title: this.$t('plugin.overview.database'),
        dataIndex: 'configCount',
      },
      {
        title: this.$t('plugin.overview.assoNumber'),
        dataIndex: 'associateCount',
        key: 'associateCount',
      },
      {
        title: this.$t('help.status'),
        dataIndex: 'status',
        key: 'status',
        width: 140,
        customRender: row =>
          row
            ? this.$t('connector.accessPlatform.startUsing')
            : this.$t('plugin.overview.unUse'),
      },
      {
        title: this.$t('common.action.operating'),
        dataIndex: 'settingVOS',
        customRender: row => {
          return (
            <span
              class={this.$style.primarySpan}
              onClick={e => {
                e.stopPropagation();
                this.openDetail(row);
              }}
            >
              详情
            </span>
          );
        },
      },
    ].map(item => ({
      ...item,
      width: item.width || 130,
      sorter: false,
      filter: false,
      align: 'left',
      ellipsis: false,
    }));
  }

  get dbType() {
    return this.$route.path === '/plugin/api' ? '3' : '2' || '3';
  }

  async openDetail(row) {
    await createModal(h => h(NodeDetails, { props: { data: row } }), {
      title: '详情',
      width: 1000,
      bodyStyle: { padding: 0 },
    });
  }

  total = 0;
  get service() {
    const service = new DataSourceService(
      '/template-manager/plugin/statistics/dbServiceTenantDetail',
    );
    service.getList = ({ skip, size }) => {
      return getDbServiceList({
        query: {
          size,
          current: skip / size + 1,
        },
        type: this.dbType,
      }).then(res => {
        this.size = res.size;
        return res
          ? {
              total: res.total,
              data: res?.records,
            }
          : { total: 0, data: [] };
      });
    };
    return service;
  }

  @Watch('$route')
  handleRouteChange() {
    this.$refs.table.loadData();
  }
}
</script>
<style lang="less" module>
.index {
  padding: 20px;
  width: 100%;
  box-shadow: 0px 0px 8px 0px var(--shadow);
}
.action {
  cursor: pointer;
  color: var(--font-active);
  &.action--delete {
    color: var(--delete);
    margin-left: 20px;
  }
}
.title {
  color: var(--font);
  font-weight: bold;
}
.primarySpan {
  color: var(--primary);
  cursor: pointer;
}
</style>
