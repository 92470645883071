<template>
  <x-data-table
    ref="table"
    base-path="template-manager/kitGroup/list"
    :columns="columns"
    :service="service"
    :class="$style.table"
    row-key="pkId"
    :selected-row-keys.sync="selectedRowKeys"
  >
    <template slot="action">
      <a-button
        :class="$style.btn"
        @click="addTypeModal"
        v-if="$p.action('CREATE')"
        >新建分类</a-button
      >
    </template>
  </x-data-table>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { DataSourceService } from '@triascloud/services';
import {
  addKitGroup,
  //   kitGroupList,
  kitGroupList,
  kitGroupEdit,
  removeKitGroup,
} from '@/services/template-manager/kitGroup';

import AddType from './add-type.vue';
@Component()
export default class KitTypeList extends Vue {
  get columns() {
    return [
      {
        dataIndex: 'groupName',
        title: '套件名称',
      },
      {
        dataIndex: 'cover',
        title: '图片',
        customRender: cover => (
          <x-oss-image
            class={this.$style.tableImg}
            size="40"
            ossPath={cover}
            onClick={() => this.$preview([{ url: cover, name: '封面' }])}
          />
        ),
      },
      {
        title: this.$t('common.action.operating'),
        key: 'actions',
        customRender: record => {
          return [
            <span
              class={this.$style.marginSpan}
              v-if={this.$p.action('UPDATE')}
              onClick={e => {
                e.stopPropagation();
                this.editKitType(record);
              }}
            >
              编辑
            </span>,
            <span
              class={this.$style.marginSpan}
              v-if={this.$p.action('DELETE')}
              onClick={e => {
                e.stopPropagation();
                this.deleteKit(record);
              }}
            >
              删除
            </span>,
          ];
        },
      },
    ];
  }

  async editKitType(record) {
    const data = await AddType.create({ data: record });
    await kitGroupEdit(data.pkId, data);
    this.$refs.table.loadData();
  }

  async deleteKit(row) {
    await this.$confirm(this.$t('feedback.deleteConfirm'));
    await removeKitGroup(row.pkId);
    this.$refs.table.loadData();
  }

  selectedRowKeys = [];

  async addTypeModal() {
    const data = await AddType.create();
    await addKitGroup(data);
    this.$message.success(this.$t('common.tips.operationSuccess'));
    this.$refs.table.loadData();
  }

  get service() {
    const service = new DataSourceService('template-manager/kitGroup/list');
    service.getList = report =>
      kitGroupList({
        query: {
          size: report.size,
          current: report.skip / report.size + 1,
        },
        search: report.keyword,
      }).then(res => ({ data: res.records, total: res.total }));
    return service;
  }
}
</script>
<style lang="less" module>
.table {
  height: calc(100% - 60px);
}
.btn {
  color: var(--primary);
}

.marginSpan {
  color: var(--primary);
  margin-left: 5px;
  cursor: pointer;
}
</style>
