// @ts-check
import { BaseControl } from '../BaseControl';
import DesignComponent from './design.vue';
import ConfigComponent from './config.vue';

class SyncInterfaceControl extends BaseControl {
  /** @readonly */
  type = 'syncInterface';

  label = 'controls.syncInterface.label';

  icon = 'tc-icon-time';

  actionOpen = true;

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    hideTitle: true,
  });

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;
}

export default new SyncInterfaceControl();
