<template>
  <a-form-model
    ref="form"
    :model="formData"
    :rules="{
      name: [
        {
          required: true,
          message:
            $t('common.placeholder.input') +
            $t('market.template.classify.name'),
        },
        {
          message: $t('common.validate.length', { value: '2~20' }),
          min: 2,
          max: 20,
        },
      ],
    }"
  >
    <a-form-model-item :label="$t('market.template.classify.name')" prop="name">
      <a-input v-model="formData.name" :max-length="20">
        <template v-slot:suffix>{{ formData.name.length }}/20</template>
      </a-input>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { addClassify } from '@/services/template-manager/tpClassify';
import { TemplateType } from '@/enum/template';
function createAddClassifyModal(props) {
  return createFormModal(h => h(AddClassifyModal, { props }), {
    title: globalVueI18n.translate('market.template.classify.add'),
    width: 442,
  });
}
@Component()
export default class AddClassifyModal extends Vue {
  @Prop({ type: String }) parentId;
  @Prop({ type: String, default: TemplateType.Application }) templateType;
  static createModal = createAddClassifyModal;
  formData = {
    name: '',
  };
  async getValue() {
    await this.$refs.form.validate();
    await addClassify({
      name: this.formData.name,
      parentId: this.parentId,
      templateType: this.templateType,
    });
    this.$message.success(this.$t('common.tips.add'));
  }
}
</script>
<style lang="less" module></style>
