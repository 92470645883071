<template>
  <a-table
    :columns="columns"
    :data-source="tableData"
    :pagination="pagination"
    :row-key="record => record.pkId"
    :loading="loading"
    @change="handlePagination"
  >
    <div slot="templateName" slot-scope="text, record" :class="$style.nameDiv">
      <div :class="$style.name">
        {{ text }}
      </div>
      <a-icon
        :class="$style.editIcon"
        type="edit"
        @click="handleAddTemplate('editName', record)"
      />
    </div>
    <div slot="templateType">
      <span>{{ $t('market.statistics.templateType') }}</span>
      <a-tooltip :title="templateTypeTips" placement="right">
        <a-icon type="info-circle" style="margin-left: 5px" />
      </a-tooltip>
    </div>
    <div slot="templateStatus">
      <span>{{ $t('connector.dataTemplate.templateStatus') }}</span>
      <a-tooltip placement="right" :overlayStyle="{ maxWidth: '100%' }">
        <div slot="title">
          <div>
            {{ $t('connector.dataTemplate.notAssociated') }}：{{
              $t('connector.dataTemplate.notAssociatedTips')
            }};
          </div>
          <div>
            {{ $t('connector.dataTemplate.associated') }}：{{
              $t('connector.dataTemplate.associatedTips')
            }};
          </div>
          <div>
            {{ $t('connector.dataTemplate.published') }}：{{
              $t('connector.dataTemplate.publishedTips')
            }};
          </div>
        </div>
        <a-icon type="info-circle" style="margin-left: 5px" />
      </a-tooltip>
    </div>
    <div slot="action" slot-scope="record">
      <span :class="$style.buttonGroups">
        <a
          v-if="record.templateStatus !== 'PUBLISHED'"
          :class="$style.button"
          @click="getTemplate(record, 'edit')"
        >
          {{ $t('solution.edit') }}
        </a>
        <a
          :class="[$style.button]"
          v-if="record.templateStatus === 'PUBLISHED'"
          @click="getTemplate(record, 'show')"
        >
          <!-- TODO @click="getTemplate(record, 'show')" -->
          {{ $t('connector.accessPlatform.view') }}
        </a>
        <a :class="$style.button" @click="templateExport(record.pkId)">
          {{ $t('connector.extra.export') }}
        </a>
        <a
          :class="[$style.button, $style.red]"
          v-if="deleteButtonStatus(record)"
          @click="deleteTemplate(record)"
        >
          {{ $t('common.action.delete') }}
        </a>
        <a
          v-if="record.templateType === 'MODEL_TEMPLATE'"
          :class="[$style.button, $style.otherBtn]"
          @click="showDataType"
          >{{ $t('controls.currentDataSet.dataType') }}</a
        >
      </span>
    </div>
  </a-table>
</template>

<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import { Emitter, EventType } from '../utils';
import { createFormModal, createModal } from '@triascloud/x-components';
import TemplateDesign from './template-design';
import AddTemplate from './add-template.vue';
import ImportTemplate from './upload-form.vue';
import StopAndRun from '@/views/connector/platform-manage/components/stop-run';
import {
  getTemplateList,
  createTemplate,
  deleteTemplateById,
  getTemplateById,
  editTemplateName,
  editTemplate,
  exportTemplate,
} from '@/services/iot-platform/dataTemplate';
import { templateDesignModule } from '@/enum/store';
import DataType from './data-type';
@Component({
  components: {
    DataType,
  },
})
export default class DataTemplate extends Vue {
  @InjectReactive() id;
  @templateDesignModule.Action updateFields;
  @templateDesignModule.Mutation updateTemplateType;
  @templateDesignModule.Mutation reset;

  loading = false;
  columns = [
    {
      dataIndex: 'index',
      customRender: (text, record, index) => index + 1,
      title: this.$t('common.label.index'),
      align: 'center',
      fixed: 'left',
      width: 80,
    },
    {
      title: this.$t('market.statistics.templateName'),
      dataIndex: 'templateName',
      width: 250,
      scopedSlots: { customRender: 'templateName' },
    },
    {
      dataIndex: 'templateType',
      slots: { title: 'templateType' },
      customRender: val => {
        return val === 'MODEL_TEMPLATE'
          ? this.$t('connector.dataTemplate.modelTemplate')
          : this.$t('connector.dataTemplate.customTemplate');
      },
    },
    {
      dataIndex: 'templateStatus',
      slots: { title: 'templateStatus' },
      customRender: val => {
        return this.statusText[val];
      },
    },
    {
      title: this.$t('connector.dataType.table.lastEditor'),
      dataIndex: 'updateName',
    },
    {
      title: this.$t('connector.dataType.table.updateTime'),
      dataIndex: 'updateTime',
      customRender: val => {
        return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
      },
    },
    {
      width: 240,
      title: this.$t('common.action.operating'),
      key: 'operation',
      scopedSlots: { customRender: 'action' },
    },
  ];
  templateTypeTips = this.$t('connector.dataTemplate.templateTypeTips');
  mounted() {
    Emitter.$on(EventType.ADD_DATA_TEMPLATE, this.handleAddTemplate);
    Emitter.$on(EventType.SEARCH_DATA_TEMPLATE, this.handleSearchTemplate);
    Emitter.$on(EventType.IMPORT_DATA_TEMPLATE, this.handleImportTemplate);

    this.getList();
  }
  beforeDestroy() {
    Emitter.$off(EventType.ADD_DATA_TEMPLATE, this.handleAddTemplate);
    Emitter.$off(EventType.SEARCH_DATA_TEMPLATE, this.handleSearchTemplate);
    Emitter.$off(EventType.IMPORT_DATA_TEMPLATE, this.handleImportTemplate);
  }

  showDataType() {
    createModal(() => <DataType id={this.id} />, {
      width: 1200,
      title: this.$t('connector.dataType.confirmTitle'),
    });
  }

  deleteButtonStatus(record) {
    if (record.templateType === 'CUSTOM_TEMPLATE') {
      if (record.templateStatus === 'ASSOCIATED') {
        return false;
      } else if (record.templateStatus === 'PUBLISHED') {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  tableData = [];
  statusText = {
    PUBLISHED: this.$t('connector.dataTemplate.published'),
    NOT_ASSOCIATED: this.$t('connector.dataTemplate.notAssociated'),
    ASSOCIATED: this.$t('connector.dataTemplate.associated'),
  };
  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => `${total} ` + this.$t('connector.dataTemplate.itmes'),
  };
  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getList();
  }
  async getList() {
    const page = this.pagination;
    const params = {
      current: page.current,
      size: page.pageSize,
      platformId: this.id,
    };
    try {
      this.loading = true;
      const { records, current, size, total } = await getTemplateList(params);
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  nameForm = {};
  async handleAddTemplate(flag = '', row = {}) {
    const editData = {
      pkId: row.pkId,
      templateName: row.templateName,
      templateType: row.templateType,
    };
    const model = await createFormModal(
      () => <AddTemplate editData={editData} id={this.id} />,
      {
        title:
          flag === 'editName'
            ? this.$t('connector.dataTemplate.editDpName')
            : this.$t('connector.dataTemplate.addDp'),
        width: 500,
      },
    );
    if (model) {
      if (flag === 'editName') {
        const params = {
          dataTemplateId: row.pkId,
          templateName: model.templateName,
        };
        try {
          await editTemplateName(params);
          const str = this.$t('common.tips.edit');
          this.$message.success(str);
          await this.getList();
        } catch {
          return false;
        }
      } else {
        this.nameForm = model;
        await this.submitDesign();
      }
    }
  }
  async submitDesign(flag = 'add', tempId = '', type) {
    if (flag === 'add') this.reset();

    const model = this.nameForm;
    flag === 'add'
      ? this.updateTemplateType(model.templateType)
      : this.updateTemplateType(type);

    // TODO 【查看模板】
    if (flag === 'show') {
      flag = 'edit';
      await createModal(
        () => <TemplateDesign id={this.id} operationFlag={flag} />,
        {
          title: (
            <div class={this.$style.Title}>
              <span>{this.$t('connector.dataTemplate.dataTemplateView')}</span>
            </div>
          ),
          width: '1576px',
          wrapClassName: this.$style.DataTemplateModal,
        },
      );
      return false;
    }

    const designModal = await createFormModal(
      () => <TemplateDesign id={this.id} operationFlag={flag} />,
      {
        title: (
          <div class={this.$style.Title}>
            <span>{this.$t('connector.dataTemplate.dataTemplateEdit')}</span>
          </div>
        ),
        width: '1576px',
        wrapClassName: this.$style.DataTemplateModal,
      },
    );
    if (designModal) {
      const items = designModal.items[0];
      const tabs = items.widget.tabs;
      const params = {
        fields: [items],
      };
      if (flag === 'add') {
        params['templateName'] = model.templateName;
        params['templateType'] = model.templateType;
        params['idxPlatformId'] = this.id;
      } else {
        params['idxDataTemplate'] = tempId;
      }
      tabs.forEach(tab => {
        tab.fields.forEach(v => {
          v.widget['isTabs'] = true; // 由于后端需要扁平化功能分页卡中添加的组件，以此做区分
          params.fields.push(v);
          if (v.widget.fields && v.widget.fields.length > 0) {
            v.widget.fields.forEach(s => {
              s.widget['isTabs'] = true; // 由于后端需要扁平化功能分页卡中添加的组件，以此做区分
              params.fields.push(s);
            });
          }
        });
      });
      try {
        if (flag === 'add') {
          await createTemplate(params);
          const str = this.$t('common.tips.add');
          this.$message.success(str);
        } else {
          await editTemplate(params);
          const str = this.$t('connector.dataTemplate.editSuccess');
          this.$message.success(str);
        }
        await this.getList();
      } catch {
        return false;
      }
    }
  }

  query = '';
  async handleSearchTemplate(query) {
    // TODO 列表查询
    this.query = query;
    await this.getList();
  }

  async handleImportTemplate() {
    const t = this.$t('connector.dataTemplate.importDataTemplate');
    const model = await createFormModal(
      () => <ImportTemplate platFormId={this.id} />,
      {
        title: t,
        width: 500,
      },
    );
    if (model) {
      await this.getList();
    }
  }
  async deleteTemplate(row) {
    const tempId = row.pkId;
    const model = await createFormModal(
      () => (
        <StopAndRun
          operating={'customerTemplate'}
          onClose={() => model.handleClose()}
        />
      ),
      {
        width: '500px',
        title: this.$t('connector.accessPlatform.operationTips'),
      },
    );
    if (model) {
      try {
        await deleteTemplateById(tempId);
        const str = this.$t('common.tips.delete');
        this.$message.success(str);
        if (this.pagination.current > 1) {
          if (this.tableData.length === 1) {
            this.pagination.current--;
          }
        }
        await this.getList();
      } catch {
        return false;
      }
    }
  }
  async getTemplate({ pkId, templateType }, flag) {
    this.reset();
    try {
      const res = await getTemplateById(pkId);
      const result = res.map(v => {
        return {
          ...v,
          name: v.fieldName,
          type: v.fieldType,
          widget: JSON.parse(v.widget),
        };
      });
      const showArr = result.filter(v => v.widget?.isTabs !== true);
      this.updateFields(showArr);
      await this.submitDesign(flag, pkId, templateType);
    } catch {
      return false;
    }
  }
  async templateExport(tempId) {
    try {
      this.loading = true;
      await exportTemplate(tempId);
      this.loading = false;
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.nameDiv {
  display: flex;
  align-items: center;
  .name {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .editIcon {
    margin-left: 10px;
    color: var(--primary);
  }
}
.buttonGroups {
  display: flex;
  justify-content: flex-start;
  .red {
    color: red;
  }
  .button {
    width: 50px;
    position: relative;
  }
  .otherBtn {
    width: 80px;
  }
}
.Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}
.DataTemplateModal {
  :global {
    .ant-modal-header {
      padding: 7px 24px;
    }
    .ant-modal-content {
      width: 1576px;
      height: 918px;
    }
    .ant-modal-body {
      padding: 20px;
      margin: 0;
    }
  }
}
</style>
