<template>
  <a-form-model layout="vertical" :model="form" ref="form">
    <a-form-model-item
      :label="$t('connector.dpManage.productCategory')"
      prop="productName"
    >
      <a-select
        :placeholder="$t('connector.dpManage.pleaseSelectProduct')"
        v-model="form.productName"
        :filter-option="false"
      >
        <a-select-option
          :value="item.id"
          v-for="item in productList"
          :key="item.id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('connector.dpManage.syncType')"
      prop="apiType"
    >
      <a-radio-group v-model="form.apiType">
        <a-radio :value="'props'">
          {{ $t('connector.dpManage.byProperty') }}
        </a-radio>
        <a-radio :value="'event'">
          {{ $t('connector.dpManage.byEvents') }}
        </a-radio>
        <a-radio :value="'service'">
          {{ $t('connector.dpManage.byService') }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('connector.dpManage.instanceID')"
      prop="instanceId"
    >
      <a-input v-model="form.instanceId"></a-input>
    </a-form-model-item>
    <a-form-model-item label="ProductKey" prop="productKey">
      <a-input v-model="form.productKey"></a-input>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class SyncForm extends Vue {
  @Prop({ type: Object }) record;
  form = {
    productName: undefined,
    instanceId: '',
    productKey: '',
    apiType: '',
  };
  productList = [
    { id: '123', name: '三叠T1' },
    { id: '113', name: '三叠T2' },
  ];
}
</script>
<style lang="less" module>
.mr10 {
  margin-left: 10px;
}
.centerRight {
  text-align: right;
}
</style>
