<template>
  <div :class="$style.box">
    <a-form-model-item>
      <a-row
        style="margin:10px 0;"
        v-for="(item, index) of paramArr"
        :key="item.paramTag"
      >
        <a-col span="20">
          {{ $t('connector.api.parameterName') }}：{{ item.paramName }}
        </a-col>
        <a-col span="2">
          <a-icon
            type="edit"
            @click="
              () => {
                editParamItem(item, index);
              }
            "
          />
        </a-col>
        <a-col span="2">
          <a-icon
            type="minus-circle"
            @click="
              () => {
                paramArr.splice(index, 1);
              }
            "
          />
        </a-col>
      </a-row>
      <!-- <label slot="label" class="ant-form-item-required">
        新增参数
      </label> -->
      <a-button
        icon="plus"
        type="primary"
        size="small"
        :class="this.$style.mt10"
        @click="showAddModel()"
      >
        {{ $t('connector.api.addParameters') }}
      </a-button>
    </a-form-model-item>
    <a-modal
      v-if="paramFormVisible"
      v-model="paramFormVisible"
      :title="$t('connector.api.addParameters')"
      @ok="handleOk"
      :width="500"
    >
      <a-form-model ref="ruleForm" :model="paramDetail" :rules="{}">
        <param-form
          ref="paramForm"
          :paramDetail="paramDetail"
          :existedTags="existedTags"
        />
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import ParamForm from './paramForm';
import { templateDesignModule } from '@/enum/store';
import { DataFormContext } from '../../data-form/context';

@Component({ components: { ParamForm } })
export default class Dataset extends Vue {
  @Prop({ required: true }) field;
  @Inject({ from: DataFormContext.Root, default: null }) root;
  @Inject({ from: DataFormContext.Model, default: null }) model;
  @templateDesignModule.State types;
  paramFormVisible = false;
  paramArr = [];
  paramDetail = {};
  editIndex = null;
  paramArrRule = {
    validator: this.validator,
    trigger: 'change',
  };
  created() {
    const data = this.model.getValue(this.field.pkId);
    if (data) {
      this.paramArr = data;
    }
  }
  validator(rule, value, callback) {
    if (this.paramArr.length) {
      callback();
    } else {
      callback(new Error(this.$t('connector.api.pleaseAddParam')));
    }
  }
  existedTags = [];
  editParamItem(item, index) {
    this.paramDetail = item;
    this.editIndex = index;
    this.existedTags = [];
    this.paramArr.map((item, idx) => {
      if (idx !== index) {
        this.existedTags.push(item.paramTag);
      }
    });
    this.paramFormVisible = true;
  }
  showAddModel() {
    this.existedTags = [];
    this.paramArr.map(item => {
      this.existedTags.push(item.paramTag);
    });
    this.paramDetail = {};
    this.paramFormVisible = true;
  }
  clearDiscardData(data) {
    let type = this.types.find(v => v.pkId === data.dataType);
    let dataType = type && type.datatype.toLocaleLowerCase();
    const typeMap = { int32: 'number', float: 'number', double: 'number' };
    for (let key in data.dataSpecs) {
      if (typeMap[dataType] && key !== 'number') {
        delete data.dataSpecs[key];
      } else if (key !== dataType) {
        delete data.dataSpecs[key];
      }
    }
  }

  getThingIdentifier(id) {
    const type = this.types.find(v => v.pkId === id);
    if (type) {
      return type.thingIdentifier;
    }
    return '';
  }

  handleOk() {
    this.$refs.ruleForm.validate(valid => {
      if (valid) {
        this.paramFormVisible = false;
        let formData = this.$refs.paramForm.form;
        if (formData.dataSpecs.enum.length) {
          formData.dataSpecs.enum = formData.dataSpecs.enum.map(v => {
            return {
              key: v.key,
              value: v.value + '',
            };
          });
        }
        // this.clearDiscardData(formData);
        formData.thingIdentifier = this.getThingIdentifier(formData.dataType);
        if (Object.keys(this.paramDetail).length) {
          this.paramArr.splice(this.editIndex, 1, formData);
        } else {
          this.paramArr.push(formData);
        }
        this.$emit('input', this.paramArr);
      }
    });
  }
}
</script>
<style lang="less" module>
.box {
  :global {
    .ant-btn-sm {
      height: 24px;
    }
    .ant-form-item,
    .ant-form-item.ant-form-item-with-help {
      margin-bottom: 0;
    }
  }
}
.mt10 {
  margin-top: 10px;
  font-size: 12px;
}
</style>
