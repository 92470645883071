<template>
  <div>
    <div v-if="activeKey === 'data-template'" :class="$style.content">
      <!-- <a-input-search
        placeholder="模型名称"
        style="width: 200px;margin-right: 10px;"
        @search="searchDataTemplate"
      /> -->
      <a-popover>
        <template #content>
          <p>{{ $t('connector.dataTemplate.dataModelTips') }}</p>
        </template>
        <a-button icon="plus" type="primary" @click.stop="addDataTemplate">
          <span>{{ $t('connector.dataTemplate.addModel') }}</span>
        </a-button>
      </a-popover>
      <a-button
        type="primary"
        :class="$style.ml10"
        @click.stop="importDataTemplate"
      >
        {{ $t('common.tips.import') }}
      </a-button>
    </div>
    <div v-else-if="activeKey === 'dp-manage'" :class="$style.content">
      <!-- v-model="form.apiCfgProduct" -->
      <a-select
        placeholder="全部产品"
        @change="changeDpData"
        allowClear
        :class="[$style.w200, $style.mr10]"
      >
        <a-select-option
          v-for="product in products"
          :value="product.pkId"
          :key="product.pkId"
          >{{ product.name }}
        </a-select-option>
        <template slot="notFoundContent">
          <a-empty :image="simpleImage" />
        </template>
      </a-select>
      <a-input
        placeholder="DP点名称/标识符"
        allowClear
        :class="[$style.w200, $style.mr10]"
        @change="
          val => {
            searchDpData(val.target.value);
          }
        "
      />
      <a-popover>
        <template #content>
          <p>{{ $t('connector.dpManage.tips1') }}</p>
        </template>
        <a-button icon="plus" type="primary" @click.stop="addDpData">{{
          $t('inviteCode.add')
        }}</a-button>
      </a-popover>
      <a-button type="primary" :class="$style.ml10" @click.stop="syncDpData">
        {{ $t('connector.dpManage.sync') }}
      </a-button>
      <a-button type="primary" :class="$style.ml10" @click.stop="importDpData">
        {{ $t('common.tips.import') }}
      </a-button>
      <a-button :class="$style.ml10" @click.stop="downloadDpData">{{
        this.$t('connector.extra.export')
      }}</a-button>
    </div>
    <div v-else-if="activeKey === 'data-manage'" :class="$style.content">
      <a-input
        :placeholder="$t('connector.dpManage.planName')"
        v-model="form.search"
        allowClear
        :class="[$style.w200, $style.mr10]"
        @change="
          val => {
            subscribeSearch(val.target.value);
          }
        "
      />
      <a-select
        placeholder="全部状态"
        @change="dataManageSelect"
        v-model="form.dataManageStatus"
        allowClear
        :class="[$style.w200, $style.mr10]"
      >
        <a-select-option value="RUN">运行中</a-select-option>
        <a-select-option value="STOP">已暂停</a-select-option>
        <a-select-option value="ERROR">运行异常</a-select-option>
      </a-select>

      <a-popover placement="topLeft">
        <template #content>
          <p>
            通过已发布的连接器创建数据接收/定时请求任务，并可对接收的数据创建自定义过滤和存储。
          </p>
        </template>
        <a-button icon="plus" type="primary" @click.stop="addSubscribe"
          >订阅计划</a-button
        >
      </a-popover>
    </div>

    <div v-else-if="activeKey === 'api-config'" :class="$style.content">
      <a-input
        allowClear
        placeholder="连接器名称/标识"
        :class="[$style.w200, $style.mr10]"
        @change="
          val => {
            apiCfgSearch(val.target.value);
          }
        "
      />
      <a-select
        placeholder="全部产品"
        @change="apiCfgChange"
        v-model="form.apiCfgProduct"
        allowClear
        :class="[$style.w200, $style.mr10]"
      >
        <a-select-option
          v-for="product in products"
          :value="product.pkId"
          :key="product.pkId"
          >{{ product.name }}
        </a-select-option>
        <template slot="notFoundContent">
          <a-empty :image="simpleImage" />
        </template>
      </a-select>
      <a-popover placement="topLeft">
        <template #content>
          <p>
            创建与服务端或设备端的连接，支持AMQP消息订阅和http的请求（更多连接协议正在规划中）
          </p>
        </template>
        <a-button icon="plus" type="primary" @click.stop="addApiCfg"
          >添加连接器</a-button
        >
      </a-popover>
      <a-popover placement="topLeft">
        <template #content>
          <p>
            连接器中需要多次使用的参数可通过创建变量来提升编辑调试效率
          </p>
        </template>
        <a-button type="primary" @click.stop="variableMgr" :class="$style.ml10"
          >变量管理
        </a-button>
      </a-popover>
      <a-popover placement="topLeft">
        <template #content>
          <p>
            给三方推送的授权可用连接器
          </p>
        </template>
        <a-button type="primary" @click.stop="threePush" :class="$style.ml10"
          >三方推送
        </a-button>
      </a-popover>
    </div>
    <div v-else-if="activeKey === 'device-manage'" :class="$style.content">
      <a-input
        allowClear
        placeholder="设备码/密钥"
        :class="[$style.w200, $style.mr10]"
        @change="
          val => {
            deviceSearch(val.target.value);
          }
        "
      />
      <a-select
        :class="[$style.w200, $style.mr10]"
        v-model="form.deviceStatus"
        placeholder="全部设备状态"
        allow-clear
        @change="handleDeviceStatusChange"
      >
        <a-select-option value="NOT_ALLOCATED">待分配</a-select-option>
        <a-select-option value="ALLOCATED">已分配</a-select-option>
        <a-select-option value="BIND">已绑定</a-select-option>
        <a-select-option value="EXPIRED">已过期</a-select-option>
      </a-select>
      <a-select
        placeholder="全部产品分类"
        @change="deviceChange"
        v-model="form.deviceProduct"
        allowClear
        :class="[$style.w200, $style.mr10]"
      >
        <a-select-option
          v-for="product in products"
          :value="product.pkId"
          :key="product.pkId"
          >{{ product.name }}
        </a-select-option>
        <template slot="notFoundContent">
          <a-empty :image="simpleImage" />
        </template>
      </a-select>
      <a-popover>
        <template #content>
          <p>
            为当前开发平台添加可用的设备，默认限添加500台！
          </p>
        </template>
        <a-button
          icon="plus"
          type="primary"
          @click.stop="deviceAdd"
          :class="$style.mr10"
          >添加设备</a-button
        >
      </a-popover>
      <a-popover placement="topLeft">
        <template #content>
          <p>
            完善待发布产品基础信息，并为产品下的设备配置交互面板。
          </p>
        </template>
        <a-button type="primary" @click.stop="deviceSetting">产品设置</a-button>
      </a-popover>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Emitter, EventType } from './utils';
import { debounce } from 'lodash-decorators';
import { Empty } from 'ant-design-vue';
@Component()
export default class FormExtra extends Vue {
  @Prop({ type: String }) activeKey;
  @Prop({ type: Array, default: () => [] }) products;

  @Watch('activeKey')
  chengeKey() {
    this.form = {
      search: '',
      dataManageStatus: undefined,
    };
  }

  @Watch('products', { immediate: true, deep: true })
  chengeProducts(newVal) {
    this.products = newVal;
  }

  simpleImage;

  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  }

  form = {
    search: '',
    dataManageStatus: undefined,
    apiCfgProduct: undefined,
    deviceProduct: undefined,
    deviceStatus: undefined,
  };

  addApiCfg() {
    Emitter.$emit(EventType.ADD_API_CONFIG);
  }

  variableMgr() {
    Emitter.$emit(EventType.VARIABLE_MANAGE);
  }

  threePush() {
    Emitter.$emit(EventType.THIRD_PARTY);
  }

  apiCfgChange(val) {
    this.form.apiCfgProduct = val;
    Emitter.$emit(EventType.API_CONFIG_CHANGE, val);
  }

  apiCfgSearch(val) {
    Emitter.$emit(EventType.API_CONFIG_SEARCH, val);
  }

  importDataTemplate() {
    Emitter.$emit(EventType.IMPORT_DATA_TEMPLATE);
  }
  addDataTemplate() {
    Emitter.$emit(EventType.ADD_DATA_TEMPLATE);
  }
  searchDataTemplate(val) {
    Emitter.$emit(EventType.SEARCH_DATA_TEMPLATE, val);
  }
  addDpData() {
    Emitter.$emit(EventType.ADD_DP_DATA);
  }
  changeDpData(val) {
    Emitter.$emit(EventType.CHOOSE_PRODUCT_DP_DATA, val);
  }
  searchDpData(val) {
    Emitter.$emit(EventType.SEARCH_DP_DATA, val);
  }
  importDpData() {
    Emitter.$emit(EventType.IMPORT_DP_DATA);
  }
  syncDpData() {
    Emitter.$emit(EventType.SYNC_DP_DATA);
  }
  downloadDpData() {
    Emitter.$emit(EventType.DOWNLOAD_DP_DATA);
  }
  addServerData() {
    Emitter.$emit(EventType.ADD_SERVER_DATA);
  }
  subscribeCfg() {
    Emitter.$emit(EventType.SUBSCRIBE_CFG);
  }
  gotoPlatform() {
    Emitter.$emit(EventType.GOTO_PLATFORM);
  }
  addSubscribe() {
    Emitter.$emit(EventType.DATA_MANAGE_ADD);
  }
  dataManageSelect(val) {
    this.form.dataManageStatus = val;
    Emitter.$emit(EventType.DATA_MANAGE_CHANGE, val);
  }

  @debounce(300)
  subscribeSearch(val) {
    Emitter.$emit(EventType.DATA_MANAGE_SEARCH, val);
  }

  deviceSearch(val) {
    Emitter.$emit(EventType.DEVICE_MANAGE_SEARCH, val);
  }

  deviceChange(val) {
    this.form.deviceProduct = val;
    Emitter.$emit(EventType.DEVICE_MANAGE_CHNAGE, val);
  }

  handleDeviceStatusChange(val) {
    this.form.deviceStatus = val;
    Emitter.$emit(EventType.DEVICE_STATUS_CHNAGE, val);
  }

  deviceSetting() {
    Emitter.$emit(EventType.DEVICE_MANAGE_SETTING);
  }

  deviceAdd() {
    Emitter.$emit(EventType.DEVICE_MANAGE_ADD);
  }
}
</script>
<style lang="less" module>
.content {
  margin-top: 5px;

  .ml10 {
    margin-left: 10px;
  }

  .mr10 {
    margin-right: 10px;
  }

  .w200 {
    width: 200px;
  }
}
</style>
