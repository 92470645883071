<template>
  <a-form-model :model="form" :rules="rules" ref="form">
    <a-row :gutter="24" style="margin-bottom: 15px;">
      <!-- <a-col :span="12">
        <a-form-model-item
          :label="$t('ds.manufacturer.manufacturerCode')"
          prop="supplierCode"
        >
          <a-input
            :placeholder="$t('ds.manufacturer.inputCode')"
            v-model="form.supplierCode"
            :maxLength="24"
            :disabled="operationType === 'edit'"
          />
        </a-form-model-item>
      </a-col> -->
      <a-col :span="12">
        <a-form-model-item
          :label="$t('ds.manufacturer.manufacturerName')"
          prop="supplierName"
        >
          <a-input
            :placeholder="$t('ds.manufacturer.inputName')"
            v-model="form.supplierName"
            :maxLength="56"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item :label="$t('common.label.remark')" prop="remark">
          <a-input
            :placeholder="$t('ds.device.inputRemark')"
            v-model="form.remark"
            :maxLength="104"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="24">
      <a-col :span="12">
        <a-form-model-item
          :label="$t('ds.manufacturer.manufacturerType')"
          prop="supplierType"
        >
          <a-radio-group v-model="form.supplierType">
            <a-radio :value="1">
              {{ $t('ds.manufacturer.standardDocking') }}
            </a-radio>
            <a-radio :value="2">
              {{ $t('ds.manufacturer.customDocking') }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row v-if="form.supplierType === 1">
      <a-col span="3" :class="$style.checkboxItem">
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
        >
          全部
        </a-checkbox>
      </a-col>
      <a-col
        :class="$style.checkboxItem"
        v-for="item in deviceTypes"
        :key="item"
        span="3"
      >
        <a-checkbox
          :value="item"
          @change="onCheckChange"
          :checked="checkedList.includes(item)"
        >
          {{ deviceType[item] }}
        </a-checkbox>
      </a-col>
    </a-row>
    <div v-else>
      <a-row :gutter="24" style="margin-bottom: 10px;">
        <a-col :span="12">
          <a-select v-model="selected" placeholder="请选择">
            <a-select-option value="ysy">
              海康萤石云
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12"
          ><a-form-model-item label="APPkey">
            <a-input
              placeholder="登录厂商开放平台获取，限128字符长度"
              v-model="form.extData.ysyAppKey"
              :maxLength="128"
            /> </a-form-model-item
        ></a-col>
        <a-col :span="12"
          ><a-form-model-item label="secret">
            <a-input
              placeholder="登录厂商开放平台获取，限128字符长度"
              v-model="form.extData.ysySecret"
              :maxLength="128"
            /> </a-form-model-item
        ></a-col>
        <a-col :span="12"
          ><a-form-model-item label="message_secret">
            <a-input
              placeholder="登录厂商开放平台获取，限128字符长度"
              v-model="form.extData.ysyMessageSecret"
              :maxLength="56"
            /> </a-form-model-item
        ></a-col>
      </a-row>
    </div>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { deviceType } from '../enum.js';
import {
  addManufacturer,
  editManufacturer,
} from '@/services/digital-site/manufacturer';

@Component()
export default class ManufacturerForm extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) operationType;

  form = {
    // supplierCode: '',
    supplierName: '',
    supplierType: 1,
    remark: '',
    permissionDevice: '',
    extData: {
      ysyAppKey: '',
      ysyMessageSecret: '',
      ysySecret: '',
    },
  };
  selected = 'ysy';
  rules = {
    // supplierCode: [
    //   {
    //     required: true,
    //     message: this.$t('ds.manufacturer.requiredCode'),
    //   },
    // ],
    supplierName: [
      {
        required: true,
        message: this.$t('ds.manufacturer.requiredCName'),
      },
    ],
    supplierType: [
      {
        required: true,
        message: '厂商类型不能为空',
      },
    ],
  };
  mounted() {
    this.initData();
  }
  async initData() {
    if (this.operationType === 'edit') {
      this.initFormData(this.editData);
    }
  }
  initFormData(data) {
    // this.form.supplierCode = data.supplierCode;
    this.form.supplierName = data.supplierName;
    this.form.supplierType = data.supplierType;
    this.form.remark = data.remark;
    if (data.extData) {
      this.form.extData = data.extData;
    }
    this.checkedList = data.permissionDevice
      ? data.permissionDevice.split(',')
      : [];
  }
  deviceType = deviceType;
  deviceTypes = Object.keys(deviceType);
  checkedList = [];
  get indeterminate() {
    return (
      this.checkedList.length > 0 &&
      this.checkedList.length < this.deviceTypes.length
    );
  }
  get checkAll() {
    return this.checkedList.length === this.deviceTypes.length;
  }
  onCheckAllChange(e) {
    const { checked } = e.target;
    this.checkedList = checked ? this.deviceTypes : [];
  }
  onCheckChange(e) {
    const { checked, value } = e.target;
    this.checkedList = checked
      ? [...this.checkedList, e.target.value]
      : this.checkedList.filter(item => item != value);
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        this.form.permissionDevice =
          this.form.supplierType === 1 ? this.checkedList.join() : '';
        if (this.form.supplierType === 1) {
          this.form.extData = undefined;
        }
        if (!this.form.permissionDevice && this.form.supplierType === 1) {
          this.$message.error('请至少选择一个设备权限');
          valid = false;
        }
        if (valid) {
          try {
            const flag = this.operationType === 'add';
            flag
              ? await addManufacturer(this.form)
              : await editManufacturer({
                  pkId: this.editData.pkId,
                  ...this.form,
                });
            this.$message.success(
              flag ? this.$t('common.tips.add') : this.$t('common.tips.edit'),
            );
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>
<style lang="less" module>
.checkboxItem {
  margin-top: 20px;
}
</style>
