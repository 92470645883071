import request from '../request';
const basePath = '/configuration/triascloud/setting/label';

export const addLabel = config =>
  request(`${basePath}/add`, { method: 'POST', body: config });

export const getLabelList = labelType =>
  request(`${basePath}/list?labelType=${labelType}`, {
    method: 'GET',
  });

export const deleteLabel = pkId =>
  request(`${basePath}/remove?pkId=${pkId}`, {
    method: 'DELETE',
  });
