import { capitalize } from 'lodash';
import { getControl } from '../controls';

/** @type { import("vue").FunctionalComponentOptions } */
const FieldRender = {
  functional: true,
  render(h, context) {
    const {
      listeners,
      props,
      data: { class: className, staticClass },
    } = context;
    const {
      field,
      fields,
      updateFields,
      updateField,
      store,
      type,
      view,
      ...rest
    } = props;
    const Control = getControl(field.type);
    const Component =
      typeof type === 'function'
        ? type(Control)
        : Control[`${capitalize(type)}Component`];
    if (!Component) return null;
    const { style, ...controlProps } = Control.props(field, props);
    return h(Component, {
      props: {
        field,
        fields: controlProps.fields || fields, // 解决controlProps中存在fields字段的问题
        updateFields,
        updateField,
        store,
      },
      attrs: {
        placeholder: field.widget.placeholder,
        view: view || field.widget.view,
        ...rest,
        ...controlProps,
      },
      on: {
        input: () => {},
        ...listeners,
      },
      style,
      class: className,
      staticClass,
    });
  },
};

export { FieldRender };
