<template>
  <a-spin :spinning="!config" :class="$style.wrap">
    <a-tabs v-model="activeTab" type="card">
      <a-tab-pane :tab="$t('website.setting.base')" key="setting">
        <a-form-model>
          <a-row type="flex" justify="end">
            <a-button @click="add" icon="plus" type="link"
              >{{ $t('common.action.add')
              }}{{ $t('website.setting.variable') }}</a-button
            >
          </a-row>
          <a-table
            :columns="columns"
            :data-source="formData.setting"
            :pagination="false"
          />
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane :tab="$t('website.navigationSetting')" key="nav">
        <navigation-setting
          v-if="config"
          :value="config"
          @input="handleConfigChange"
        />
      </a-tab-pane>
    </a-tabs>
  </a-spin>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import {
  getWebConfig,
  saveConfig,
} from '@/services/configuration/websiteConfig';
import NavigationSetting from './components/navigation-setting.vue';
import AsyncButton from '@/components/async-button';
import AddFieldModal from './components/add-field-modal.vue';
import FieldRender from './components/field-render.vue';

@Component({ components: { NavigationSetting, AsyncButton, FieldRender } })
export default class Index extends Vue {
  activeTab = 'setting';
  config = null;
  formData = {
    setting: [],
  };
  get columns() {
    return [
      {
        title: this.$t('website.setting.variableTitle'),
        dataIndex: 'name',
      },
      {
        title: this.$t('website.setting.variableKey'),
        dataIndex: 'key',
      },
      {
        title: this.$t('website.setting.variableValue'),
        key: 'value',
        customRender: item => (
          <FieldRender
            type={item.type}
            value={item.value}
            onInput={value => {
              item.value = value;
              this.submit();
            }}
          />
        ),
      },
      {
        title: this.$t('common.action.operating'),
        key: 'action',
        customRender: (_, __, i) => (
          <a onClick={() => this.remove(i)}>
            {this.$t('common.action.delete')}
          </a>
        ),
        width: 150,
      },
    ];
  }
  created() {
    this.getWebConfig();
  }
  async getWebConfig() {
    const res = await getWebConfig();
    this.config =
      !res || !res.config
        ? {
            header: {
              navigation: [],
            },
            footer: {
              navigation: [],
            },
            setting: [],
          }
        : JSON.parse(res.config);
    this.formData.setting = this.config.setting || [];
  }
  handleConfigChange({ header, footer }) {
    saveConfig({ ...this.config, header, footer });
    this.getWebConfig();
  }
  async submit() {
    await saveConfig({ ...this.config, setting: this.formData.setting });
    await this.getWebConfig();
  }
  async add() {
    const formData = await AddFieldModal.createModal(this.formData.setting);
    this.formData.setting.push(formData);
    await this.submit();
    this.$message.success(this.$t('common.tips.save'));
  }
  async remove(index) {
    await this.$confirm(this.$t('feedback.deleteConfirm'));
    this.formData.setting.splice(index, 1);
    this.submit();
    this.$message.success(this.$t('common.tips.delete'));
  }
}
</script>
<style lang="less" module>
.wrap {
  height: 100%;
  padding: 20px;
  :global(.ant-spin-container) {
    height: 100%;
  }
}
</style>
