<template>
  <div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-model-item
            :label="$t('business.renewalOrNot')"
            prop="renewalOrNot"
          >
            <a-radio-group v-model="form.renewalOrNot">
              <a-radio :value="1">
                {{ $t('template.review.checked') }}
              </a-radio>
              <a-radio :value="0">
                {{ $t('template.review.unChecked') }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            :label="$t('business.whetherThereIsCustomizationRequirement')"
            prop="customizedDemand"
          >
            <a-radio-group v-model="form.customizedDemand">
              <a-radio :value="1">
                {{ $t('template.review.checked') }}
              </a-radio>
              <a-radio :value="0">
                {{ $t('template.review.unChecked') }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item :label="$t('business.otherRecords')">
            <a-textarea
              v-model="form.otherRecord"
              type="textarea"
              :rows="5"
              :placeholder="`${$t('common.placeholder.input')}`"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div :class="$style.footer">
      <a-button :class="$style.btn" @click="handleCancel">
        {{ $t('common.action.cancel') }}
      </a-button>
      <a-button type="primary" :class="$style.btn" @click="handleConfirm">
        {{ $t('common.action.confirm') }}
      </a-button>
    </div>
  </div>
</template>

<script>
/** 填写跟进信息 */

import { Component, Vue, Prop } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import { fetchWriteFollowup } from '@/services/business-opportunity/index';

@Component({})
export default class InputFollowupInfo extends Vue {
  @Prop({ type: Object, default: () => null }) modalInfo;
  @Prop({ type: String, default: () => '' }) memberId;

  static createInputFollowupInfoModal = createInputFollowupInfoModal;

  form = {
    renewalOrNot: 1,
    customizedDemand: 1,
    otherRecord: '',
  };

  handleCancel() {
    this.$emit('close');
  }
  handleConfirm() {
    fetchWriteFollowup({ ...this.form, followUpObjectId: this.memberId }).then(
      res => {
        if (res) {
          this.$emit('close');
          this.$message.success(this.$t('common.tips.add'));
        }
      },
    );
  }

  get rules() {
    return {
      renewalOrNot: [
        {
          required: true,
          message: this.$t('common.placeholder.select'),
        },
      ],
      customizedDemand: [
        {
          required: true,
          message: this.$t('common.placeholder.select'),
        },
      ],
    };
  }
}

function createInputFollowupInfoModal(props) {
  return new Promise(resolve => {
    const modal = createModal(
      h =>
        h(InputFollowupInfo, {
          props,
          on: {
            close: () => {
              modal.handleClose();
              resolve();
            },
            handleCreate: () => {
              modal.handleClose();
              resolve('success');
            },
          },
        }),
      {
        width: 860,
        title: props.modalInfo.title,
      },
    );
  });
}
</script>

<style lang="less" module>
.footer {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  margin-bottom: -10px;
  .btn {
    margin-right: 10px;
    &:nth-child(2) {
      margin-right: 0;
    }
  }
}
</style>
