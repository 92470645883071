<template>
  <div :class="$style.iconSelect">
    <div :class="$style.header">
      <div>
        <a-select
          default-value="null"
          :class="$style.select"
          @change="handleTypeChange"
        >
          <a-select-option value="null">
            {{ $t('common.label.all') }}
          </a-select-option>
          <a-select-option value="true">
            {{ $t('iconLibrary.icon.label.modified') }}
          </a-select-option>
          <a-select-option value="false">
            {{ $t('iconLibrary.icon.label.improved') }}
          </a-select-option>
        </a-select>
        <a-input-search
          v-model="keyword"
          @search="refreshScrollList"
          :class="$style.search"
        ></a-input-search>
        <span :class="$style.title"
          >{{ $t('dictionary.total')
          }}<i :class="$style.primary">{{ total }}</i>
          {{ $t('iconLibrary.icon.size') }}</span
        >
      </div>
      <a-button type="link" @click="handleImport" :class="$style.importBtn">
        <span :class="$style.importIcon"><x-icon type="tc-icon-to-top"/></span>
        {{ $t('common.tips.import') }}
      </a-button>
    </div>
    <div :class="$style.body">
      <a-spin v-if="spinning" />
      <x-scroll-list
        v-model="iconList"
        :service="initData"
        :class="$style['scroll-view']"
        ref="scrollList"
      >
        <IconItem
          slot-scope="{ data }"
          :data="data"
          :checked="selectIconsList && selectIconsList.includes(data.pkId)"
          @change="handleIconListChange"
          @replace="handleIconReplace"
        />
      </x-scroll-list>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import { getIconList } from '@/services/resource/icon';
import { createFormModal } from '@triascloud/x-components';
import IconItem from './icon-item';
import { getAllIdByLibraryId } from '@/services/resource/icon-library';
import { difference } from 'lodash';
import { deepClone, promiseLock } from '@triascloud/utils';
import { IconLibrarySymbol } from '../../context';
import ImportIconModal from './import-icon-modal';

function createIconModal(
  { libraryId, iconId, limit },
  { title, width = '755px' },
) {
  return createFormModal(
    h => h(IconSelectList, { props: { libraryId, iconId, limit } }),
    {
      title,
      width,
      wrapClassName: 'x-icon-select-list',
    },
  );
}

@Component({
  components: { IconItem },
})
export default class IconSelectList extends Vue {
  static createIconModal = createIconModal;
  @Prop({ type: String, default: '' }) libraryId;
  @Prop({ type: String, default: '' }) iconId;
  @Prop({ type: Boolean, default: false }) limit;

  @Provide(IconLibrarySymbol)
  get IconLibrary() {
    return this;
  }

  keyword = '';
  iconType = null;
  total = null;
  selectIconsList = [];
  compareList = [];

  TypeEnum = {
    null: null,
    true: true,
    false: false,
  };

  get disabled() {
    return this.totalPages === this.current || this.totalPages < this.current;
  }

  handleTypeChange(type) {
    this.iconType = this.TypeEnum[type];
    this.refreshScrollList();
  }

  refreshScrollList() {
    this.$refs.scrollList.refresh();
  }
  changeIcon = false;

  handleIconListChange(value) {
    this.changeIcon = true;
    if (this.selectIconsList.includes(value)) {
      this.selectIconsList = this.selectIconsList.filter(
        item => item !== value,
      );
    } else {
      this.selectIconsList.push(value);
    }
  }

  handleIconReplace(value) {
    this.selectIconsList = [value];
  }

  modalInfo = {
    title: this.$t('common.tips.import'),
  };
  async handleImport() {
    await ImportIconModal.createImportIconModal({
      modalInfo: this.modalInfo,
    });
    this.refreshScrollList();
  }

  iconList = [];
  spinning = false;

  async initData(page) {
    this.spinning = true;

    const params = {
      iconQuery: {
        current: page,
        size: 84,
      },
      keyword: this.keyword,
      updateIconName: this.iconType,
    };
    const list = await getIconList(params);
    this.total = list.total;
    this.spinning = false;

    return list.records;
  }

  async created() {
    this.selectIconsList = this.limit
      ? [this.iconId]
      : await this.getLibraryIconList(this.libraryId);
    this.compareList = deepClone(this.selectIconsList);
  }

  @promiseLock({
    forever: true,
    keyGenerator: libraryId => libraryId,
  })
  getLibraryIconList(libraryId) {
    return getAllIdByLibraryId(libraryId);
  }

  getValue() {
    if (this.limit) {
      if (!this.selectIconsList.length) {
        this.$message.warning(this.$t('iconLibrary.icon.tips.selectIcon'));
        throw new Error(this.$t('iconLibrary.icon.tips.selectIcon'));
      }
      return { removeList: this.compareList, addList: this.selectIconsList };
    }
    const removeList = difference(this.compareList, this.selectIconsList);
    const addList = difference(this.selectIconsList, this.compareList);

    return this.changeIcon ? { removeList, addList } : this.changeIcon;
  }
}
</script>
<style lang="less" module>
:global .x-icon-select-list {
  .ant-modal-body {
    padding: 0;
  }
}
.iconSelect {
  .header {
    height: 50px;
    padding: 0 30px;
    border-bottom: 1px solid var(--border);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .select {
      width: 145px;
    }
    .search {
      width: 110px;
      margin-left: 30px;
    }
    .title {
      color: var(--font-sub);
      margin-left: 30px;
      font-size: 12px;
    }
    .primary {
      color: var(--primary);
      font-size: 14px;
      font-style: normal;
    }
  }
  .body {
    min-height: 50vh;
    .scroll-view {
      padding: 20px;
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, 80px);
      gap: 25px 10px;
      justify-content: space-between;
      max-height: 55vh;

      :global {
        .x-scroll-list-footer {
          display: none;
        }
      }
    }
  }
}
.importBtn {
  display: flex;
  align-items: center;
  font-size: 14px;
  .importIcon {
    transform: rotateX(180deg) translateY(2px);
    margin-right: 10px;
  }
}
</style>
