<template>
  <div>
    <div class="formula-editor" :style="{ width: widthVal, height: heightVal }">
      <textarea :ref="textareaRef" :id="textareaRef" />
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import 'codemirror';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material-darker.css';
import CodeMirror from 'codemirror';
import './mode';
import { thenable, uuid } from '@triascloud/utils';
import { variableModule } from '@/enum/store';
import { markedHref } from '@/views/connector/platform-manage/components/platform-form';

const uid = uuid();

@Component()
export default class VariableModel extends Vue {
  @Prop({ type: String, default: '250px' }) widthVal;
  @Prop({ type: String, default: '32px' }) heightVal;
  @Prop({ type: String | Number, default: '' }) variableVal;
  @Prop({ type: String, default: '' }) platformId;
  @variableModule.State variableAllList;

  $cm = null;

  variableObj = {};
  variableType = '';
  defaultVal = '';
  remark = '';
  vID = uid;
  textareaRef = uuid();
  typeObj = {
    SYSTEM: '系统变量',
    DEVELOP: '开发变量',
    PLATFORM: '平台变量',
    SUBSCRIBE: '订阅变量',
    DATA: '数据变量',
  };
  mounted() {
    this.init();
  }
  initValue() {
    let str = '';
    String(this.variableVal)
      .split(/({{.*?}})/g)
      .forEach(word => {
        const match = /^{{(.*?)}}$/.exec(word);
        if (match) {
          str += `_${word}_`;
        } else {
          str += word;
        }
      });
    this.$cm.replaceSelection(str);
    this.$nextTick(() => {
      this.markAllVariable(this.$cm.getValue());
    });
  }
  reset() {
    this.$cm.setValue(''); // 清空数据
    this.initValue();
  }
  init() {
    this.variableAllList.forEach(v => {
      this.variableObj[v.identifier] = {
        variableType: this.typeObj[v.variableType],
        defaultValue: v.defaultValue,
        remark: v.remark,
      };
    });
    const keys = this.variableAllList.map(v => v.identifier);
    if (!this.$refs[this.textareaRef]) return;
    this.$cm = CodeMirror.fromTextArea(this.$refs[this.textareaRef], {
      mode: 'variable',
      lineWrapping: false,
      keywords: keys,
    });
    if (this.variableVal) {
      this.initValue();
    }
    this.$cm.on('blur', () => {
      this.$emit('blur');
    });
    this.$cm.on('change', () => {
      const v1 = this.$cm.getValue();
      const v2 = v1.replace(/[_,\n]/g, '');
      this.$emit('update:variableVal', v2);
    });
    this.$cm.on('cursorActivity', () => {
      this.$cm.showHint({
        completeSingle: false,
      });
      const v1 = this.$cm.getValue();
      const v2 = v1.replace(/[_,\n]/g, '');
      this.$emit('update:variableVal', v2);
    });
    this.$cm.on('startCompletion', () => {
      this.$nextTick(() => {
        const el = document.getElementById('cm-complete-0');
        if (el) {
          this.renderDoc(el);
        }
      });
    });
    this.$cm.on('endCompletion', () => {
      const doc = window.document.getElementById(this.vID);
      if (!doc) return;
      doc.style.display = 'none';
    });
    this.$cm.on('hint-select', fnName => {
      this.text = fnName.text;
      this.variableType = this.variableObj[fnName.text].variableType;
      this.defaultVal = this.variableObj[fnName.text].defaultValue;
      this.remark = this.variableObj[fnName.text].remark;
      this.docInnerHTML();
      fnName.hint = (cm, data) => {
        cm.replaceRange('', data.from, data.to, 'complete');
        this.insert(fnName.text);
      };
    });
    this.$cm.on('inputRead', (cm, data) => {
      let val = cm.getValue();
      let cursor = cm.getCursor();
      const cs = data.text[0];
      const len = val.length;
      if (len > 4) {
        if (cs === '}' && val[data.to.ch - 1] === '}') {
          for (let i = data.to.ch - 2; i >= 0; i--) {
            if (val[i] === '}' && val[i - 1] === '}') {
              break;
            }
            if (val[i] === '{' && val[i - 1] === '{') {
              const a = val.slice(i - 1, data.to.ch + 1);
              val = val.substring(0, i - 1);
              const f = {
                line: cursor.line,
                ch: i - 1,
              };
              const t = {
                line: cursor.line,
                ch: data.to.ch + 1,
              };
              cm.replaceRange('', f, t, 'complete');
              // cm.setValue(val);
              cm.setCursor({
                line: cursor.line,
                ch: i - 1,
              });
              this.insertVariable(a);
              break;
            }
          }
        }
      }
      this.$cm.showHint();
    });
  }
  docElement() {
    let ele = document.getElementById(this.vID);

    if (!ele) {
      const ele = document.createElement('div');
      document.body.appendChild(ele);
      ele.id = this.vID;
      ele.className = 'docCard';
      this.docInnerHTML();
    }
    return ele;
  }
  // 更新innerHTML
  docInnerHTML() {
    let ele = document.getElementById(this.vID);
    let str = this.remark;
    if (this.remark.indexOf('www') > -1 || this.remark.indexOf('http') > -1) {
      str = markedHref(this.remark);
    }
    if (ele) {
      ele.innerHTML = `
        <div>
        <span class="title">变量分类：</span>${this.variableType}</div>
        <div>
        <span class="title">默认值：</span>${this.defaultVal}</div>
        <div>
        <span class="title">备注说明：</span>
          ${str}
        </div>
        `;
    }
  }
  renderDoc(el) {
    const doc = this.docElement();
    if (!doc) return;
    doc.style.display = 'block';
    doc.style.position = 'absolute';
    doc.style.top = el.style.top;
    doc.style.left =
      parseInt(el.style.left.slice(0, -2)) + el.clientWidth + 'px';
  }

  markAllVariable(value = '') {
    let str = '';
    value.split(/(_{{.*?}}_)/g).forEach(word => {
      const match = /^_{{(.*?)}}_$/.exec(word);
      if (match) {
        this.markVariable({
          start: CodeMirror.Pos(0, str.length),
          end: CodeMirror.Pos(0, str.length + word.length),
          variable: `{{${match[1]}}}`,
        });
      }
      str += word;
    });
  }
  insert(e) {
    let val;
    if (e.target) {
      val = e.target.dataset.item;
    } else {
      val = e;
    }
    this.insertVariable(`{{${val}}}`);
  }
  insertVariable(variable) {
    const cursor = this.$cm.getCursor();
    this.$cm.replaceSelection(`_${variable}_`);
    this.markVariable({
      start: cursor,
      end: this.$cm.getCursor(),
      variable,
    });
    this.$cm.setCursor(this.$cm.getCursor());
    this.$cm.focus();
  }
  markVariable(mark) {
    const fieldDom = document.createElement('span');
    /** @type { string | Promise<string> } */
    const label = mark.variable;
    thenable(label).then(label => {
      fieldDom.title = label;
      fieldDom.innerText = label;
      fieldDom.classList.add(
        'formula-fields--item-type',
        'type-text',
        'formula-editor--field',
      );
    });
    fieldDom.dataset.variable = mark.variable;
    this.$cm.markText(mark.start, mark.end, {
      replacedWith: fieldDom,
      // handleMouseEvents: true,
      atomic: true,
    });
  }
}
</script>

<style lang="less">
@prefix: formula-editor;
.@{prefix} {
  display: block;
  padding: 0;
  cursor: text;
  height: 32px;
  border: 1px solid var(--border);
  border-radius: 4px;
  width: 100px;
  textarea {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    color: var(--primary);
    &:focus {
      outline: none;
    }
  }
  .CodeMirror {
    // padding: 4px;
    background-color: transparent;
    height: 100%;
    color: var(--primary);
  }
  .CodeMirror pre.CodeMirror-line,
  .CodeMirror pre.CodeMirror-line-like {
    font-family: Consolas, 'Microsoft YaHei', serif;
  }
  pre.CodeMirror-line,
  pre.CodeMirror-line-like {
    line-height: 24px;
    outline: none;
  }
  .CodeMirror-wrap {
    pre.CodeMirror-line,
    pre.CodeMirror-line-like {
      word-break: break-all;
    }
  }
  pre.CodeMirror-placeholder {
    color: #845deb;
  }
  .CodeMirror-cursor {
    border-color: var(--primary);
  }
  .CodeMirror-selected {
    background-color: #fa9b47;
  }
  .CodeMirror-vscrollbar,
  .CodeMirror-hscrollbar {
    overflow: hidden;
  }
  div.CodeMirror-scroll {
    overflow: hidden !important;
  }
  div.CodeMirror span.CodeMirror-nonmatchingbracket {
    color: #f11b1b;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: -6px;
      left: -20%;
      right: 20%;
      height: 6px;
      border-top: 1px solid #f11b1b;
      border-radius: 50%;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 60%;
      right: -60%;
      height: 6px;
      border-bottom: 1px solid #f11b1b;
      border-radius: 50%;
    }
  }
  div.CodeMirror span.CodeMirror-matchingbracket {
    color: inherit;
    background-color: rgba(132, 93, 235, 0.3);
  }
  .@{prefix}--field {
    display: inline-block;
    margin: 1px;
    font-family: 'Microsoft YaHei', Arial, sans-serif;
  }
  .formula-fields--item-type {
    font-size: 12px;
    padding: 0 6px;
    min-width: 60px;
    height: 22px;
    line-height: 20px;
    text-align: center;
    border-radius: 11px;
    color: #fff;
    border: 1px solid;
    max-width: 260px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: bottom;
    &.type-text {
      border-color: #fab30b;
      background-color: #fab30b;
    }
  }
  .dark .formula-fields--item-type {
    &.type-text {
      color: #fab30b;
      background-color: rgba(250, 179, 11, 0.15);
    }
  }
  .cm-negative {
    color: #845deb;
  }
}
.CodeMirror-hints {
  font-family: Consolas, 'Microsoft YaHei', serif;
}
.CodeMirror-hints {
  z-index: 10000;
  font-size: 14px;
}
.wrap {
  display: flex;
  justify-content: space-between;
  height: 350px;
  position: relative;
}
.leftList {
  overflow-x: hidden;
  overflow-y: auto;
  width: 250px;
  border-radius: 4px;
  border: 1px solid var(--border);
}
.listItem {
  display: flex;
  padding: 6px 20px;
  height: 34px;
  line-height: 22px;
  cursor: pointer;
  &:hover {
    background-color: var(--primary-fade-10);
  }
}
.name {
  flex: 1;
  color: var(--font);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.type {
  font-size: 12px;
  padding: 0 6px;
  min-width: 60px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  border-radius: 11px;
  color: #fff;
  border: 1px solid #f3da10;
  max-width: 260px;
  vertical-align: bottom;
  background-color: #f3da10;
}
.docCard {
  z-index: 99999;
  overflow-x: hidden;
  overflow-y: auto;
  width: 250px;
  border-radius: 4px;
  border: 1px solid #c0c0c0;
  margin-left: 5px;
  padding: 4px;
  background-color: var(--block-bg);
}
.title {
  font-weight: 600;
}
</style>
