import Vue from 'vue';
import Component from 'vue-class-component';

const routerHooks = [
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
];

// 屏蔽掉用户关闭弹窗的报错
Vue.config.errorHandler = error => {
  if (
    error.message === 'user cancel' ||
    error.message === 'form validate error'
  )
    return;
  throw error;
};

// 解决打包后组件名被压缩的问题
if (process.env.NODE_ENV === 'production') {
  Vue.config.warnHandler = (msg, _, trace) => {
    if (
      msg &&
      msg.startsWith(
        'Do not use built-in or reserved HTML elements as component id',
      )
    )
      return;
    // eslint-disable-next-line no-console
    console.error('[Vue warn]: ' + msg + trace);
  };
}

Component.registerHooks(routerHooks);
