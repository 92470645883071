/** @type { import('vuex').Module } */
const home = {
  namespaced: true,
  state: {
    collection: null,
  },
  mutations: {
    updateCollection(state, payload) {
      state.collection = payload;
    },
  },
};

export default home;
