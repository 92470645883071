<script>
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { templateDesignModule } from '@/enum/store';
import { deepClone } from '@triascloud/utils';
import {
  DATATYPE,
  unitArray,
} from '@/views/connector/platform-manage/develop/utils';
import { validateInput } from './validate';
import { uuid } from '@triascloud/utils';

@Component()
export default class ParamForm extends Vue {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  existedTags;
  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  paramDetail;
  @templateDesignModule.State types;
  form = {
    id: '',
    paramName: '',
    paramTag: '',
    dataType: undefined,
    dataTypeText: '',
    dataSpecs: {
      number: {
        max: '',
        min: '',
        step: '',
        unit: '',
      },
      text: {
        maxLength: '',
      },
      date: {
        format: 'timeStamp',
      },
      boolean: {
        0: '',
        1: '',
      },
      enum: [{ key: '', value: '', type: '' }],
    },
  };

  created() {
    this.form.id = uuid();
    this.typeSec = [];
    for (const item of this.types) {
      if (
        item.datatype !== DATATYPE.struct &&
        item.datatype !== DATATYPE.array
      ) {
        this.typeSec.push(item);
      }
    }
    if (Object.keys(this.paramDetail).length) {
      this.form = deepClone(this.paramDetail);
    }
  }
  @Watch('form.dataType')
  handleTypeChange() {
    this.form.dataTypeText = this.DataType;
    this.refreshRender();
  }
  get DataType() {
    const type = this.typeSec.find(v => v.pkId === this.form.dataType);
    if (type) {
      return type.datatype;
    }
    return '';
  }
  async refreshRender() {
    this.tickFlag = false;
    await this.$nextTick();
    this.tickFlag = true;
  }

  renderNumber() {
    const valueScopeMap = {
      [DATATYPE.int]: { max: 2147483647, min: -2147483648 },
      [DATATYPE.float]: { max: 3.4e38, min: -3.4e38 },
      [DATATYPE.double]: { max: Number.MAX_VALUE, min: -Number.MAX_VALUE },
    };
    const scope = valueScopeMap[this.DataType];
    const type = this.types.find(v => v.pkId === this.form.dataType);
    const jsonSpecs = JSON.parse(type.dataSpecs);
    return (
      <div>
        <a-form-model-item
          label={this.$t('controls.currentDataSet.ranges')}
          prop={'dataSpecs-number'}
          rules={{
            validator: validateInput.bind(this),
          }}
        >
          <a-row>
            <a-col span={11}>
              <a-input-number
                v-model={this.form.dataSpecs.number.min}
                placeholder={this.$t('controls.currentDataSet.minimum')}
                max={scope.max}
                min={scope.min}
              />
            </a-col>
            <a-col span={11} offset={2}>
              <a-input-number
                v-model={this.form.dataSpecs.number.max}
                placeholder={this.$t('controls.currentDataSet.maximum')}
                max={scope.max}
                min={scope.min}
              />
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label={this.$t('controls.currentDataSet.stepSize')}>
          <a-input-number
            placeholder={this.$t('controls.currentDataSet.stepSizePlaceholder')}
            v-model={this.form.dataSpecs.number.step}
            max={scope.max}
            min={scope.min}
          />
        </a-form-model-item>
        <a-form-model-item label={this.$t('controls.currentDataSet.unit')}>
          {jsonSpecs.unit.source === 'SYSTEM' ? (
            <a-select v-model={this.form.dataSpecs.number.unit}>
              {unitArray.map(item => (
                <a-select-option value={item.key} key={item.key}>
                  {item.value}
                </a-select-option>
              ))}
            </a-select>
          ) : (
            <a-input
              placeholder={this.$t('controls.currentDataSet.unitPlaceholder')}
              v-model={this.form.dataSpecs.number.unit}
            />
          )}
        </a-form-model-item>
      </div>
    );
  }

  renderText() {
    return (
      <a-form-model-item label={this.$t('controls.currentDataSet.maxLen')}>
        <a-row>
          <a-col span={22}>
            <a-input-number
              v-model={this.form.dataSpecs.text.maxLength}
              max={10240}
              min={1}
            />
          </a-col>
          <a-col span={2} class={this.$style.right}>
            {this.$t('connector.dataType.byte')}
          </a-col>
        </a-row>
      </a-form-model-item>
    );
  }

  renderDate() {
    return (
      <a-form-model-item label={this.$t('controls.currentDataSet.timeFormat')}>
        <a-input
          value={this.$t('controls.currentDataSet.timeLimit')}
          readOnly
        />
      </a-form-model-item>
    );
  }

  addEnumItem() {
    const type = this.types.find(v => v.pkId === this.form.dataType);
    const jsonSpecs = JSON.parse(type.dataSpecs);
    this.form.dataSpecs.enum.push({
      key: '',
      value: '',
      type: jsonSpecs.enumValueType || 'TEXT',
    });
  }
  delEnumItem(index) {
    this.form.dataSpecs.enum.splice(index, 1);
  }

  renderEnum() {
    if (this.form.dataSpecs.enum.length > 0) {
      if (!this.form.dataSpecs.enum[0].type) {
        const type = this.types.find(v => v.pkId === this.form.dataType);
        const jsonSpecs = JSON.parse(type.dataSpecs);
        this.form.dataSpecs.enum[0].type = jsonSpecs.enumValueType || 'TEXT';
      }
    }
    return (
      <div>
        <a-form-model-item style={'margin-bottom:0;'}>
          <label slot={'label'} class={'ant-form-item-required'}>
            {this.$t('controls.currentDataSet.enumItem')}
          </label>
          <a-row>
            <a-col span={11}>key</a-col>
            <a-col span={11}>value</a-col>
            {this.form.dataSpecs.enum.map((item, index) => (
              <a-row style="margin:15px 0;" key={index}>
                <a-col span={10}>
                  <a-form-model-item
                    prop={'dataSpecs-enum-key-' + index}
                    rules={{
                      validator: validateInput.bind(this),
                      trigger: 'change',
                    }}
                  >
                    <a-input placeholder="请输入描述" v-model={item.key} />
                  </a-form-model-item>
                </a-col>
                <a-col span={10} offset={1}>
                  <a-form-model-item
                    prop={'dataSpecs-enum-value-' + index}
                    rules={{
                      validator: validateInput.bind(this),
                      trigger: 'change',
                    }}
                  >
                    <a-input placeholder="请输入参数值" v-model={item.value} />
                    {/* <a-input-number
                      placeholder="请输入数值，如0、1、2..."
                      v-model={item.value}
                      max={2147483647}
                      min={-2147483648}
                    /> */}
                  </a-form-model-item>
                </a-col>
                <a-col span={1} offset={1}>
                  <a-icon
                    type="minus-circle"
                    onClick={() => {
                      this.delEnumItem(index);
                    }}
                  />
                </a-col>
              </a-row>
            ))}
          </a-row>
        </a-form-model-item>
        <a-form-model-item
          prop={'dataSpecs-enum-length'}
          rules={{
            validator: validateInput.bind(this),
          }}
        >
          <a-button
            icon={'plus'}
            type={'primary'}
            size={'small'}
            onClick={() => {
              this.addEnumItem();
            }}
          >
            {this.$t('controls.currentDataSet.addEnum')}
          </a-button>
        </a-form-model-item>
      </div>
    );
  }

  renderBoolean() {
    return (
      <a-form-model-item label={'数值描述'} required>
        <a-row>
          <a-col span={11}>
            <div class={this.$style.labelBox}>
              <span class={this.$style.span}>0-</span>
              <a-form-model-item
                prop="dataSpecs-boolean-0"
                rules={{
                  validator: validateInput.bind(this),
                  trigger: 'change',
                }}
              >
                <a-input
                  placeholder={this.$t('controls.currentDataSet.close')}
                  v-model={this.form.dataSpecs.boolean[0]}
                />
              </a-form-model-item>
            </div>
          </a-col>
          <a-col span={11} offset={2}>
            <div class={this.$style.labelBox}>
              <span class={this.$style.span}>1-</span>
              <a-form-model-item
                prop="dataSpecs-boolean-1"
                rules={{
                  validator: validateInput.bind(this),
                  trigger: 'change',
                }}
              >
                <a-input
                  placeholder={this.$t('controls.currentDataSet.open')}
                  v-model={this.form.dataSpecs.boolean[1]}
                />
              </a-form-model-item>
            </div>
          </a-col>
        </a-row>
      </a-form-model-item>
    );
  }

  get renderScheme() {
    switch (this.DataType) {
      case DATATYPE.int:
      case DATATYPE.float:
      case DATATYPE.double:
        return this.renderNumber();
      case DATATYPE.date:
        return this.renderDate();
      case DATATYPE.enum:
        return this.renderEnum();
      case DATATYPE.text:
        return this.renderText();
      case DATATYPE.boolean:
        return this.renderBoolean();
      default:
        return '';
    }
  }

  tickFlag = true;
  render() {
    return (
      <div class={this.$style.box}>
        <a-form-model-item
          prop="paramName"
          rules={{
            validator: validateInput.bind(this),
            trigger: 'change',
          }}
        >
          <label slot={'label'} class={'ant-form-item-required'}>
            参数名称
          </label>
          <a-input v-model={this.form.paramName} />
        </a-form-model-item>
        <a-form-model-item
          prop="paramTag"
          rules={{
            validator: validateInput.bind(this),
            trigger: 'change',
          }}
        >
          <label slot={'label'} class={'ant-form-item-required'}>
            参数标识符
          </label>
          <a-input v-model={this.form.paramTag} />
        </a-form-model-item>
        <a-form-model-item
          prop="dataType"
          rules={{
            validator: validateInput.bind(this),
            trigger: 'change',
          }}
        >
          <label slot={'label'} class={'ant-form-item-required'}>
            数据类型
          </label>
          <a-select
            v-model={this.form.dataType}
            placeholder="请选择"
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            {this.typeSec.map(item => (
              <a-select-option value={item.pkId} key={item.pkId}>
                {item.customizeName}
              </a-select-option>
            ))}
          </a-select>
        </a-form-model-item>
        {this.tickFlag && this.renderScheme}
      </div>
    );
  }
}
</script>
<style lang="less" module>
.box {
  :global {
    .ant-form-item-control-wrapper {
      max-width: 400px;
    }
    .ant-input-number {
      width: 100%;
    }
    .ant-btn-sm {
      height: 24px;
    }
  }
}
.ml5 {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}
.mt10 {
  margin-top: 10px;
  font-size: 12px;
}
.zI101 {
  z-index: 101;
  position: relative;
}
.labelBox {
  display: flex;
  .span {
    width: 30px;
  }
}
.right {
  text-align: right;
}
</style>
