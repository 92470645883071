var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form-model',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules}},[_c('a-form-model-item',{attrs:{"label":"套件名称"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(_vm.formData.groupName),callback:function ($$v) {_vm.$set(_vm.formData, "groupName", $$v)},expression:"formData.groupName"}})],1),_c('a-form-model-item',{ref:"imageForm",attrs:{"label":"背景图(推荐尺寸：1920*1080)","prop":"popularImg","rules":[
      {
        message: _vm.$t('solution.selectPicture'),
        required: true,
      } ]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.popularImg),expression:"formData.popularImg"}],class:_vm.$style.solutionInput,domProps:{"value":(_vm.formData.popularImg)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "popularImg", $event.target.value)}}}),_c('x-upload-button',{attrs:{"maxFileSize":5,"uploadPath":"common/temp/"},on:{"success":function (res) {
          _vm.$refs.imageForm.FormContext.clearValidate('popularImg');
          this$1.formData.popularImg = res.ossData.ossPath;
        }}},[_c('div',{class:[_vm.$style.solutionBgDetail]},[(!_vm.formData.popularImg)?_c('div',{class:_vm.$style.addIcon},[_c('x-icon',{attrs:{"type":"plus"}}),_c('div',[_vm._v("点击上传图片，不超过5m")])],1):_c('x-oss-image',{class:_vm.$style.image__img,attrs:{"oss-path":_vm.formData.popularImg}}),(_vm.formData.popularImg)?_c('div',{class:_vm.$style.changePic},[_c('div',{class:_vm.$style.coverAction},[_c('div',{class:_vm.$style.delIcon,on:{"click":function($event){$event.stopPropagation();_vm.formData.popularImg = null}}},[_c('x-icon',{attrs:{"type":"tc-icon-delete"}}),_vm._v(" 删除 ")],1),_c('div',{class:_vm.$style.changeIcon},[_c('x-icon',{attrs:{"type":"edit"}}),_vm._v(" 替换 ")],1)])]):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }