<template>
  <section :class="$style.contentWrap">
    <section :class="$style.queryWrap">
      <a-select
        :class="$style.selectWrap"
        v-model="queryTenant"
        @change="handleTenantChange"
      >
        <a-select-option
          v-for="item in tenantList"
          :key="item.tenantId"
          :value="item.tenantId"
          >{{ item.tenantName }}</a-select-option
        >
      </a-select>
      <a-select
        :class="$style.selectWrap"
        v-model="queryType"
        @change="handleTypeChange"
      >
        <a-select-option value="">全部类型</a-select-option>
        <a-select-option value="PRODUCT">产品</a-select-option>
        <a-select-option value="OFFICIAL_WEBSITE">官网</a-select-option>
      </a-select>
      <a-select
        :class="$style.selectWrap"
        v-model="queryStatus"
        @change="handleStatusChange"
      >
        <a-select-option value="">全部审批状态</a-select-option>
        <a-select-option value="PENDING_APPROVAL">待审批</a-select-option>
        <a-select-option value="PASSED">已通过</a-select-option>
        <a-select-option value="REJECTED">已驳回</a-select-option>
      </a-select>
    </section>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
    >
      <div :class="$style.timeWrap" slot="status" slot-scope="txt">
        <a-tag :color="statusColor[txt]">
          {{ STATUS_KEY[txt] }}
        </a-tag>
      </div>
      <div :class="$style.timeWrap" slot="createTime" slot-scope="txt, record">
        <span :class="$style.txt">{{ formatDate(txt) }}</span>
        <span :class="$style.txt">{{
          record.appraiseTime ? `(${formatDate(record.appraiseTime)})` : '(-)'
        }}</span>
      </div>
      <div slot="platformName" slot-scope="txt, record">
        <span :class="$style.nameWrap">
          <x-oss-image
            class="x-file-icon"
            size="32"
            :oss-path="record.platformLogo"
          />
          <span :class="$style.txt">{{ txt }}</span>
        </span>
      </div>
      <div slot="action" slot-scope="record">
        <span :class="$style.buttonGroups">
          <a
            v-if="record.status === 'PENDING_APPROVAL'"
            :class="[$style.button]"
            @click="handleReview(record)"
          >
            审批
          </a>
          <a
            v-if="
              record.status === 'PENDING_APPROVAL' ||
                record.status === 'REJECTED' ||
                record.status === 'PASSED'
            "
            :class="[$style.button]"
            @click="handleIntroduce(record)"
          >
            介绍
          </a>
          <a
            v-if="
              record.status === 'PENDING_APPROVAL' ||
                record.status === 'REJECTED' ||
                record.status === 'PASSED'
            "
            :class="[$style.button]"
            @click="handlePanel(record)"
          >
            面板
          </a>
          <a
            v-if="record.status === 'REJECTED' || record.status === 'PASSED'"
            :class="[$style.button]"
            @click="handleDetail(record)"
          >
            详情
          </a>
          <!-- <a :class="[$style.button]" @click="handlePreview(record)">
            预览
          </a> -->
        </span>
      </div>
    </a-table>
  </section>
</template>
<script>
import { createModal } from '@triascloud/x-components';
import { Component, Vue } from 'vue-property-decorator';
import Detail from './detail.vue';
import ReviewOption from './review-option.vue';
import {
  getReleaseList,
  getTenant,
} from '@/services/iot-platform/releaseRecord';
import dayjs from 'moment';
import Panel from '../../platform-manage/develop/device-manage/panel/index.vue';
import Introduce from '../../platform-manage/develop/device-manage/introduce/index.vue';

@Component()
export default class Review extends Vue {
  STATUS_KEY = {
    UNPUBLISHED: '未发布',
    REJECTED: '已驳回',
    PENDING_APPROVAL: '待审核',
    PASSED: '已通过',
  };
  statusColor = {
    UNPUBLISHED: '#ccc',
    REJECTED: 'var(--delete)',
    PENDING_APPROVAL: 'var(--warning)',
    PASSED: 'var(--success)',
  };

  mounted() {
    this.getReleaseList();
    this.getTenant();
  }

  tenantList = [
    {
      tenantId: '',
      tenantName: '全部企业',
    },
  ];
  async getTenant() {
    const list = await getTenant();
    this.tenantList.push(...list);
  }

  queryStatus = '';
  handleStatusChange(val) {
    this.queryStatus = val;
    this.pagination.current = 1;
    this.getReleaseList();
  }

  queryType = '';
  handleTypeChange(val) {
    this.queryType = val;
    this.pagination.current = 1;
    this.getReleaseList();
  }

  queryTenant = '';
  handleTenantChange(val) {
    this.queryTenant = val;
    this.pagination.current = 1;
    this.getReleaseList();
  }

  formatDate(val, format = 'YYYY-MM-DD HH:mm:ss') {
    if (val) {
      return dayjs(val).format(format);
    }
    return val;
  }

  async getReleaseList() {
    let option = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.queryStatus) {
      option.releaseStatus = this.queryStatus;
    }
    if (this.queryType) {
      option.releaseType = this.queryType;
    }
    if (this.queryTenant) {
      option.tenantId = this.queryTenant;
    }
    const { records, total } = await getReleaseList(option);
    this.tableData = records;
    this.pagination.total = total;
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => index + 1,
        title: this.$t('common.label.index'),
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        title: '发布内容',
        dataIndex: 'content',
      },
      {
        title: '审批状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' },
      },
      {
        title: '发布企业',
        dataIndex: 'tenantName',
      },
      {
        title: '发布平台',
        dataIndex: 'platformName',
        scopedSlots: { customRender: 'platformName' },
      },
      {
        title: '发布说明',
        dataIndex: 'directions',
      },
      {
        title: '发布（审核）时间',
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' },
      },
      {
        title: '审核人',
        dataIndex: 'appraiseBy',
      },
      {
        width: 240,
        title: this.$t('common.action.operating'),
        key: 'operation',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }

  tableData = [];

  approvalForm = {};
  async handleReview(record) {
    const modal = createModal(
      () => (
        <ReviewOption
          record={record}
          close={() => {
            modal.handleClosed();
            this.getReleaseList();
          }}
        />
      ),
      {
        title: '审批操作',
        width: 500,
      },
    );
  }
  handleIntroduce(record) {
    createModal(
      () => (
        <Introduce
          productionId={record.idxReleaseId}
          reviewId={record.pkId}
          disabledFlag={true}
        />
      ),
      {
        title: '产品介绍',
        width: 1250,
      },
    );
  }
  handlePanel(record) {
    createModal(
      () => (
        <Panel
          productionId={record.idxReleaseId}
          platformId={record.platformId}
          reviewId={record.pkId}
          disabledFlag={true}
          preview={true}
        />
      ),
      {
        title: '设备面板',
        width: 800,
        okText: '保存',
      },
    );
  }
  handleDetail(record) {
    createModal(() => <Detail id={record.idxReleaseId} />, {
      width: 900,
      title: '产品发布记录',
    });
  }
  handlePreview() {
    this.$message.warning('即将上线，尽请期待！');
  }

  loading = false;
  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => `${total} ` + this.$t('connector.dataTemplate.itmes'),
  };
  handlePagination(e) {
    this.pagination.current = e.current;
    this.getReleaseList();
  }
}
</script>
<style lang="less" module>
.contentWrap {
  position: relative;
}
.queryWrap {
  position: absolute;
  right: 0;
  top: -45px;
}
.buttonGroups {
  display: flex;
  justify-content: flex-start;

  .button {
    width: 50px;
    position: relative;
  }
}
.selectWrap {
  width: 140px;
}
.selectWrap + .selectWrap {
  margin-left: 20px;
}

.nameWrap {
  margin-left: 20px;
  .txt {
    margin-left: 20px;
  }
}
.timeWrap {
  .txt {
    display: block;
    height: 28px;
    line-height: 28px;
  }
}
</style>
