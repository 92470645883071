<template>
  <x-data-table
    ref="table"
    base-path="/resource/cloud-dictionary/list"
    :columns="columns"
    :service="service"
    :class="$style.table"
    :selected-row-keys.sync="selectedRowKeys"
    :custom-row="
      record => ({
        on: {
          click: () => openDetail(record),
        },
        style: {
          cursor: 'pointer',
        },
      })
    "
  >
    <div slot="headerLeft" :class="$style.toolbar">
      <ul :class="$style.toolbar__tabs">
        <li
          v-for="item in checkStatusList"
          :key="item.value"
          :class="[
            $style.toolbar__tab,
            { [$style['toolbar__tab--active']]: item.value === checkStatus },
          ]"
          @click="checkStatus = item.value"
        >
          {{ item.label }}
        </li>
      </ul>
      <a-input
        :class="$style.toolbar__input"
        @pressEnter="handleTableKeywordChange($event)"
      >
        <x-icon type="tc-icon-search" slot="prefix" />
      </a-input>
      <span>{{ $t('dictionary.shareTime') }}</span>
      <a-range-picker
        :class="$style.toolbar__picker"
        :separator="$t('common.label.to')"
        :placeholder="[
          `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
            'common.label.day',
          )}`,
          `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
            'common.label.day',
          )}`,
        ]"
        @change="handlerDatePickerChange"
      />
      <span
        >{{ $t('dictionary.total') }}：<a>{{ total }}</a
        >{{ $t('dictionary.aggregation') }}：<a>{{ childrenTotal }}&nbsp;</a
        >{{ $t('dictionary.subset') }}</span
      >
    </div>
    <a-button v-if="$p.action('DELETE_BATCH')" slot="action" @click="remove()">
      <x-icon type="tc-icon-delete" />
      {{ $t('common.action.batchDelete') }}
    </a-button>
  </x-data-table>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { Debounce } from 'lodash-decorators';
import { CheckStatus } from '@/enum/dictionary';
import {
  getDictionaryCheckList,
  removeDictionary,
  batchRemoveDictionary,
} from '@/services/resource/cloud-dictionary';
import {
  DataSourceCompareOperator,
  DataSourceLogicOperator,
  DataSourceService,
} from '@triascloud/services';
import DictionaryReviewDrawer from './components/dictionary-review-drawer.vue';
import DictionaryRemove from './components/dictionary-remove-modal.vue';

@Component
export default class Dictionary extends Vue {
  get columns() {
    return [
      {
        title: this.$t('dictionary.name'),
        dataIndex: 'dictionaryName',
        width: 130,
        customCell: () => ({
          style: {
            color: 'var(--font-active)',
          },
        }),
      },
      {
        title: this.$t('dictionary.subsetCount'),
        dataIndex: 'childrenCount',
        width: 130,
      },
      {
        key: 'checkStatus',
        title: this.$t('common.label.approvalStatus'),
        width: 130,
        customRender: record => {
          const data = this.checkStatusList.find(
            item => item.value === record.checkStatus,
          );
          return data ? (
            <span class={this.$style[`checkStatus--${record.checkStatus}`]}>
              {data.label}
            </span>
          ) : (
            this.$t('common.tips.abnormal')
          );
        },
        generateFilterOptions: list =>
          [...new Set(list.map(item => item.checkStatus))].map(checkStatus => {
            const data = this.checkStatusList.find(
              item => item.value === checkStatus,
            );
            return {
              text: data ? data.label : this.$t('common.tips.abnormal'),
              value: checkStatus,
            };
          }),
      },
      {
        title: this.$t('dictionary.sharerName'),
        dataIndex: 'createNickName',
        width: 130,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.sharerPhone'),
        dataIndex: 'createPhone',
        width: 150,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.sharerInstitution'),
        dataIndex: 'shareEnterpriseName',
        width: 130,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.pushTime'),
        dataIndex: 'createDate',
        customRender: createDate => moment(createDate).format('Y-MM-DD HH:mm'),
        generateFilterOptions: list =>
          [...new Set(list.map(item => item.createDate))].map(createDate => ({
            text: moment(createDate).format('Y-MM-DD H:mm'),
            value: createDate,
          })),
        width: 130,
      },
      {
        title: this.$t('dictionary.reviewer'),
        dataIndex: 'checkName',
        width: 130,
      },
      {
        title: this.$t('dictionary.reviewTime'),
        dataIndex: 'checkDate',
        customRender: checkDate =>
          checkDate ? moment(checkDate).format('Y-MM-DD HH:mm') : '',
        generateFilterOptions: list =>
          [...new Set(list.map(item => item.checkDate))].map(checkDate => ({
            text: moment(checkDate).format('Y-MM-DD H:mm'),
            value: checkDate,
          })),
        width: 130,
      },
      {
        title: this.$t('dictionary.auditOpinion'),
        dataIndex: 'checkOpinion',
        width: 130,
      },
      {
        title: this.$t('common.action.operating'),
        key: 'actions',
        width: 130,
        customRender: record => {
          const actionName =
            record.checkStatus === CheckStatus.Unchecked
              ? this.$t('common.action.review')
              : this.$t('dictionary.editReview');
          return (
            <span
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {this.$p.action('DELETE') ? (
                <span
                  class={[this.$style.action, this.$style['action--delete']]}
                  onClick={() => this.remove(record.pkId)}
                >
                  {this.$t('common.action.delete')}
                </span>
              ) : null}
              {this.$p.action('VERIFY') ? (
                <a
                  class={this.$style.action}
                  onClick={() => this.openDetail(record)}
                >
                  {actionName}
                </a>
              ) : null}
            </span>
          );
        },
        filter: false,
        sorter: false,
      },
    ];
  }
  get checkStatusList() {
    return [
      {
        label: this.$t('common.label.all'),
        value: '',
      },
      {
        label: this.$t('dictionary.checkStatus.unchecked'),
        value: CheckStatus.Unchecked,
      },
      {
        label: this.$t('dictionary.checkStatus.passed'),
        value: CheckStatus.Passed,
      },
      {
        label: this.$t('dictionary.checkStatus.fail'),
        value: CheckStatus.Fail,
      },
    ];
  }
  datePicker = [];
  handlerDatePickerChange(range) {
    if (range.length) {
      this.datePicker = [
        range[0].startOf('day').valueOf(),
        range[1].endOf('day').valueOf(),
      ];
    } else {
      this.datePicker = [];
    }
    this.$refs.table.loadData();
  }
  @Debounce(500)
  handleTableKeywordChange(event) {
    this.$refs.table.loadData({ keyword: event.target.value });
  }
  checkStatus = '';
  @Watch('checkStatus')
  handleStatusChange() {
    this.$refs.table.loadData();
  }
  total = 0;
  childrenTotal = 0;
  get service() {
    const service = new DataSourceService('/resource/cloud-dictionary/list');
    service.getList = report =>
      getDictionaryCheckList({
        ...report,
        filterGroup: {
          group: [
            ...report.filterGroup.group,
            ...[
              ...(this.checkStatus
                ? [
                    {
                      group: [
                        {
                          compare: DataSourceCompareOperator.EQ,
                          value: this.checkStatus,
                          field: 'checkStatus',
                        },
                      ],
                      logic: DataSourceLogicOperator.OR,
                    },
                  ]
                : []),
              this.datePicker.length
                ? {
                    logic: DataSourceLogicOperator.AND,
                    group: [
                      {
                        compare: DataSourceCompareOperator.GE,
                        value: this.datePicker[0],
                        field: 'createDate',
                      },
                      {
                        compare: DataSourceCompareOperator.LE,
                        value: this.datePicker[1],
                        field: 'createDate',
                      },
                    ],
                  }
                : null,
            ].filter(item => item),
          ],
          logic: DataSourceLogicOperator.AND,
        },
      }).then(res => {
        this.total = res.total;
        this.childrenTotal = res.childrenTotal;
        return res;
      });
    return service;
  }
  async openDetail(record) {
    const actionName =
      record.checkStatus === CheckStatus.Unchecked
        ? this.$t('common.action.review')
        : this.$t('dictionary.editReview');
    await DictionaryReviewDrawer.createDrawer(record, {
      title: actionName,
    });
    this.$refs.table.loadData();
  }
  selectedRowKeys = [];
  async remove(pkId) {
    if (!pkId && !this.selectedRowKeys.length)
      return this.$message.warning(this.$t('dictionary.Unselected'));
    if (pkId) {
      await DictionaryRemove.createModal(pkId, true);
      await removeDictionary([pkId]);
    } else {
      await this.$confirm(this.$t('dictionary.deleteConfirm'));
      await batchRemoveDictionary(this.selectedRowKeys);
    }
    this.$refs.table.loadData();
    this.$message.success(this.$t('common.tips.delete'));
  }
}
</script>
<style lang="less" module>
.table {
  padding: 20px;
}
.action {
  cursor: pointer;
  color: var(--font-active);
  &.action--delete {
    color: var(--delete);
    margin-right: 20px;
  }
}
.checkStatus--PASSED {
  color: var(--font-active);
}
.checkStatus--FAIL {
  color: var(--delete);
}
.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .toolbar__input {
    width: auto;
    margin-right: 30px;
    :global .ant-input {
      width: 110px;
      transition: width 0.5s;
      &:focus {
        width: 200px;
      }
    }
  }
  .toolbar__picker {
    width: 220px;
    margin: 0 5px;
    :global {
      .ant-calendar-picker-icon {
        display: none;
      }
      .ant-calendar-picker-input.ant-input {
        padding-left: 10px;
        text-align: center;
      }
    }
  }
  .toolbar__tabs {
    display: flex;
  }
  .toolbar__tab {
    cursor: pointer;
    margin-right: 40px;
    &.toolbar__tab--active {
      color: var(--font-active);
    }
    &:hover {
      color: var(--font-hover);
    }
  }
}
</style>
