<script>
import { Component, Vue } from 'vue-property-decorator';
import { Dropdown, Menu } from 'ant-design-vue';
import ResizeObserver from 'resize-observer-polyfill';
import { Icon } from '@triascloud/x-components';

/**
 * @param { Vue.CreateElement } h
 * @param { Vue.VNode } vNode
 */
function cloneVNode(h, vNode) {
  return h(
    vNode.componentOptions.Ctor,
    {
      ...vNode.data,
      props: vNode.componentOptions.propsData,
      on: vNode.componentOptions.listeners,
    },
    vNode.componentOptions.children,
  );
}

const MenuItem = Menu.Item;

@Component()
export default class ActionBar extends Vue {
  sliceIndex = -1;
  /** @type { ResizeObserver } */
  _ob = null;
  mounted() {
    this._ob = new ResizeObserver(this.calc);
    this._ob.observe(this.$refs.scroll);
    this.calc();
  }
  updated() {
    this.calc();
  }
  beforeDestroy() {
    if (!this._ob) return;
    this._ob.disconnect();
    this._ob = null;
  }
  calc() {
    if (this.$refs.scroll.clientWidth === this.$refs.scroll.scrollWidth) {
      this.sliceIndex = -1;
    } else {
      const rect = this.$refs.scroll.getBoundingClientRect();
      this.sliceIndex = (this.$slots.default || []).findIndex(child => {
        if (!child.elm) return false;
        const childRect = child.elm.getBoundingClientRect();
        return rect.right < childRect.right;
      });
    }
  }
  getMoreList() {
    const slots = this.$slots.default || [];
    return this.sliceIndex > -1 ? slots.slice(this.sliceIndex) : [];
  }
  render(h) {
    const moreList = this.getMoreList();
    const dropdown =
      moreList.length > 0 ? (
        <Dropdown
          trigger={['click']}
          placement="bottomRight"
          class={this.$style.dropdown}
        >
          <Icon type="ellipsis" />
          <Menu class={this.$style.dropdownMenu} slot="overlay">
            {moreList.map(vNode => (
              <MenuItem key={vNode.key}>{cloneVNode(h, vNode)}</MenuItem>
            ))}
          </Menu>
        </Dropdown>
      ) : null;
    const slots = this.$slots.default || [];
    return (
      <div class={this.$style.container}>
        <div ref="scroll" class={this.$style.scroll}>
          {slots.map((vNode, index) => (
            <Wrap
              key={vNode.key}
              class={
                this.sliceIndex > -1 && this.sliceIndex <= index
                  ? this.$style.hidden
                  : null
              }
            >
              {vNode}
            </Wrap>
          ))}
        </div>
        {dropdown}
      </div>
    );
  }
}

@Component()
class Wrap extends Vue {
  render() {
    return this.$slots.default[0];
  }
}
</script>
<style lang="less" module>
.container {
  display: flex;
  align-items: center;
}
.scroll {
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.dropdown {
  cursor: pointer;
  padding: 12px;
  font-size: 16px;
}
.dropdownMenu {
  :global(.ant-dropdown-menu-item) {
    padding: 0;
    :global(.ant-btn) {
      display: block;
      width: 100%;
      text-align: left;
      padding: 12px 20px;
      height: auto;
    }
  }
}
.hidden {
  visibility: hidden;
}
</style>
