<template>
  <div :class="$style.TemplateStatistics">
    <header-list :data="data.templateTotalAmountVO"></header-list>
    <div :class="$style.chart">
      <div :class="$style.chart_row">
        <ManageChartsWrap
          v-for="item in dataList"
          :key="item.key"
          :title="item.title"
          :isEmpty="item.data.length"
          :loading="loading"
          :class="$style.chart_wrap"
        >
          <template v-slot="{ selectTime }">
            <Component
              :class="$style.chart_item"
              :is="item.component"
              :selectTime="selectTime"
              :data="item.data[selectTime]"
              :key="selectTime"
              v-if="item.hasData(item.data[selectTime])"
            />
            <EmptyContent
              type="list"
              :class="$style.chart_item"
              v-if="!item.hasData(item.data[selectTime]) && !loading"
            />
          </template>
        </ManageChartsWrap>
        <UseDetailTable />
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import HeaderList from './components/statistics/header-list.vue';
import ManageChartsWrap from '../solution/components/manage-charts-wrap.vue';
import EmptyContent from '@/components/empty-content';
import CountSortCharts from './components/statistics/count-sort-charts.vue';
import { generateMap } from '@triascloud/utils';
import UseDetailTable from './components/statistics/use-detail-table.vue';
import IncomeCharts from './components/statistics/income-charts.vue';
import CountCharts from './components/statistics/count-charts.vue';
import PaySortCharts from './components/statistics/pay-sort-charts.vue';
import InstallCharts from './components/statistics/install-charts.vue';
import IncomeSortCharts from './components/statistics/income-sort-charts.vue';
import { getTemplateData } from '@/services/template-manager/template';
@Component({
  components: {
    HeaderList,
    ManageChartsWrap,
    EmptyContent,
    CountSortCharts,
    UseDetailTable,
    IncomeCharts,
    PaySortCharts,
    CountCharts,
    IncomeSortCharts,
    InstallCharts,
  },
})
export default class TemplateStatistics extends Vue {
  data = {
    enterpriseApplicationTemplateStatistics: [],
    enterpriseContributionPaymentProportion: [],
    paidTemplateLeaderboards: [],
    productRevenueRankingCOS: [],
    rankingOfTemplateContributions: [],
    templateInstallStatisticsVOS: [],
  };
  initData(arr) {
    return arr && arr.length ? generateMap(arr, 'timeType', item => item) : [];
  }
  loading = false;
  created() {
    this.getData();
  }
  async getData() {
    this.loading = true;
    this.data = await getTemplateData();
    this.loading = false;
  }
  get dataList() {
    return [
      {
        key: 'rankingOfTemplateContributions',
        component: CountSortCharts,
        title: this.$t('market.statistics.tlt1'),
        hasData: data =>
          data &&
          data.solutionCountDetailList &&
          data.solutionCountDetailList.length,
        data: this.initData(this.data.rankingOfTemplateContributions),
      },
      {
        key: 'enterpriseContributionPaymentProportion',
        component: IncomeCharts,
        title: this.$t('market.statistics.tlt2'),
        dataKey: 'dataList',
        hasData: data =>
          data && data.tenantPayDetailCOS && data.tenantPayDetailCOS.length,
        data: this.initData(this.data.enterpriseContributionPaymentProportion),
      },
      {
        key: 'enterpriseApplicationTemplateStatistics',
        component: CountCharts,
        title: this.$t('market.statistics.tlt5'),
        hasData: data =>
          data &&
          data.solutionCountDetailList &&
          data.solutionCountDetailList.length,
        data: this.initData(this.data.enterpriseApplicationTemplateStatistics),
      },
      /// 新组件
      {
        key: 'paidTemplateLeaderboards',
        component: PaySortCharts,
        title: this.$t('market.statistics.tlt3'),
        hasData: data =>
          data &&
          data.solutionCountDetailList &&
          data.solutionCountDetailList.length,
        data: this.initData(this.data.paidTemplateLeaderboards),
      },
      {
        key: 'templateInstallStatisticsVOS',
        component: InstallCharts,
        title: this.$t('market.statistics.tlt6'),
        hasData: data =>
          data &&
          data.templateInstallRankDetailVOS &&
          data.templateInstallRankDetailVOS.length,
        data: this.initData(this.data.templateInstallStatisticsVOS),
      },
      {
        key: 'productRevenueRankingCOS',
        component: IncomeSortCharts,
        title: this.$t('market.statistics.tlt4'),
        hasData: data => data && data.data.length,
        data: this.initData(this.data.productRevenueRankingCOS),
      },
    ];
  }
}
</script>
<style lang="less" module>
.TemplateStatistics {
  padding-bottom: 20px;
  width: 100%;
  min-width: 1500px;
}
.chart_row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}
.chart {
  width: 100%;
}

.chart_item {
  height: 390px;
}
.chart_wrap {
  margin-bottom: 20px;
  border-radius: 1px;
  width: calc(33.33% - 13.3px);
  &:nth-child(3n-1) {
    margin: 0 20px;
  }
}
</style>
