<template>
  <div :class="$style.index">
    <x-data-table
      ref="table"
      base-path="/template-manager/solution/admin/management/loadApprovalRequestForm"
      :columns="columns"
      :service="service"
      :class="$style.table"
      :custom-row="
        record => ({
          style: {
            cursor: 'pointer',
          },
        })
      "
      :scroll="{
        x: '100%',
        y: '100%',
      }"
    >
      <template slot="headerLeft">
        <a-input
          :class="$style.toolbar__input"
          v-model="searchData.keyword"
          @pressEnter="handleTableKeywordChange($event, $table)"
          @blur="handleTableKeywordChange($event, $table)"
        >
          <x-icon type="tc-icon-search" slot="prefix" />
        </a-input>
        <a-select
          :placeholder="$t('common.label.all')"
          :class="$style.toolbar__select"
          v-model="searchData.solutionClassify"
          @change="typeSelectChange"
        >
          <a-select-option
            v-for="item in templateTypes"
            :key="item.value"
            :value="item.value"
            >{{ item.text }}</a-select-option
          >
        </a-select>
        <a-range-picker
          :class="$style.toolbar__picker"
          :placeholder="[
            `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
              'common.label.day',
            )}`,
            `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
              'common.label.day',
            )}`,
          ]"
          :separator="$t('common.label.to')"
          @change="handlerDatePickerChange"
        />

        <!-- <a-dropdown
          v-model="visible"
          :trigger="['click']"
          :overlayClassName="$style.filterDropdown"
        >
          <x-icon type="tc-icon-funnel" />
          <template slot="overlay">
            <a-menu>
              <a-menu-item>
                <a-checkbox-group
                  v-model="searchData.type"
                  name="checkboxgroup"
                  @change="selectChange"
                >
                  <a-row>
                    <a-checkbox value="1">{{
                      $t('solution.remoteSolution')
                    }}</a-checkbox></a-row
                  >
                  <a-row
                    ><a-checkbox value="2">{{
                      $t('solution.paidPlan')
                    }}</a-checkbox></a-row
                  >
                </a-checkbox-group>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown> -->
      </template>
      <!-- <div slot="headerLeft" :class="$style.toolbar">
        <b>解决方案审批</b>
      </div> -->
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import solutionApprovalModal from './solution-approval-modal.vue';
import {
  solutionApprovalDetails,
  //   processApprovalRequestForm,
} from '@/services/template-manager/solution';
import { createModuleUrl } from '@/utils';
import { DataSourceService } from '@triascloud/services';
import ManageBuysCount from './manage-buys-count.vue';
import { standUpAndDown } from '@/services/template-manager/solution';
import { createModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';
import SolutionShelvesModal from './solution-shelves-modal.vue';

@Component
export default class SolutionApprovalListTable extends Vue {
  @Prop({ type: String, default: '' }) classId;
  visible = false;
  searchData = {
    keyword: '',
    timeStart: '',
    timeEnd: '',
    type: [],
    solutionClassify: '',
  };
  plainOptions = [
    {
      label: this.$t('solution.remoteSolution'),
      value: 'Apple',
    },
    {
      label: this.$t('solution.paidPlan'),
      value: 'Pear',
    },
  ];
  templateTypes = [
    {
      text: this.$t('common.label.all'),
      value: '',
    },
    {
      text: '未上架',
      value: '0',
    },

    {
      text: '已上架',
      value: '1',
    },
    {
      text: '已下架',
      value: '2',
    },

    // {
    //   text: '申请上架',
    //   value: '1',
    // },
    // {
    //   text: '上架驳回',
    //   value: '2',
    // },
    // {
    //   text: '更新申请',
    //   value: '3',
    // },
    // {
    //   text: '更新驳回',
    //   value: '4',
    // },
  ];
  datePicker = [];
  handleTableKeywordChange() {
    this.$refs.table.loadData();
  }
  handlerDatePickerChange(range) {
    if (range.length) {
      this.searchData.timeStart = range[0].startOf('day').valueOf();
      this.searchData.timeEnd = range[1].endOf('day').valueOf();
    } else {
      this.searchData.timeStart = null;
      this.searchData.timeEnd = null;
    }
    this.$refs.table.loadData();
  }

  async statusRecord() {
    await createModal(h => h(SolutionShelvesModal, {}), {
      title: this.$t('solution.launchRecord'),
      width: '470px',
    });
  }

  selectChange() {
    this.$refs.table.loadData();
  }
  async approve(res) {
    const approvalModal = await createModal(
      () => (
        <solutionApprovalModal
          data={res}
          onClose={() => {
            approvalModal.visible = false;
          }}
          onRefresh={() => {
            this.$refs.table.loadData();
          }}
        />
      ),
      {
        title: this.$t('solution.approval'),
        width: '680px',
        bodyStyle: {
          padding: 0,
        },
      },
    );

    // await processApprovalRequestForm(data);
  }

  typeSelectChange() {
    this.$refs.table.loadData();
  }

  get paidSolution() {
    if (!this.searchData.type.length) {
      return '';
    }

    if (this.searchData.type.length && this.searchData.type.includes('2')) {
      return 1;
    } else {
      return 0;
    }
  }

  get remoteSolution() {
    if (!this.searchData.type.length) {
      return '';
    }
    if (this.searchData.type.length && this.searchData.type.includes('1')) {
      return 1;
    } else {
      return 0;
    }
  }

  get columns() {
    return [
      {
        title: this.$t('solution.solutionName'),
        dataIndex: 'briefDesc.name',
      },

      {
        dataIndex: 'solutionState',
        title: this.$t('solution.programStatus'),
        customRender: status =>
          status === 1 ? (
            <span class={this.$style.primarySpan}>
              {this.$t('solution.addedTo')}
            </span>
          ) : status === 2 ? (
            <span class={this.$style.dangerSpan}>
              {this.$t('template.review.state.offShelf')}
            </span>
          ) : (
            <span class={this.$style.warningSpan}>
              {this.$t('template.review.state.notListed')}
            </span>
          ),
      },
      {
        dataIndex: 'requestType',
        key: 'requestType',
        title: this.$t('common.type'),
        customRender: status =>
          status === 1
            ? this.$t('solution.listingApplication')
            : this.$t('solution.updateApplication'),
      },
      {
        dataIndex: 'checkedResult',
        key: 'checkedResult',
        title: this.$t('solution.auditResults'),
        customRender: status =>
          status === 1
            ? this.$t('solution.approved')
            : status === 0
            ? this.$t('solution.approvalFailed')
            : this.$t('solution.pending'),
      },
      {
        key: 'removeUpgrade',
        title: this.$t('solution.upgradeTip'),
        dataIndex: 'removeUpgrade',
        width: 170,
        customRender: status =>
          status
            ? this.$t('template.review.checked')
            : this.$t('template.review.unChecked'),
      },
      {
        title: this.$t('market.template.sellingPrice'),
        key: 'price',
        dataIndex: 'briefDesc.price',
        width: 130,
        customRender: price => '¥' + (price / 100).toFixed(2),
      },
      {
        title: this.$t('solution.applications'),
        dataIndex: 'briefDesc.appCount',
        key: 'briefDesc.appCount',
      },
      {
        title: this.$t('inviteCode.business'),
        dataIndex: 'proposerTenantName',
        key: 'proposerTenantName',
      },
      {
        title: this.$t('iot.device.creator'),
        dataIndex: 'briefDesc.creatorName',
        key: 'briefDesc.creatorName',
      },
      {
        title: this.$t('common.label.createTime'),
        dataIndex: 'proposerTime',
        customRender: checkDate =>
          checkDate ? moment(checkDate).format('Y-MM-DD HH:mm') : '-',
      },
      {
        title: this.$t('common.action.operating'),
        key: 'actions',
        width: 249,
        customRender: record => {
          return [
            <span
              class={[this.$style.primaryColor, this.$style.marginSpan]}
              onClick={e => {
                e.stopPropagation();
                this.approve(record);
              }}
            >
              {record.checkedResult === 1 || record.checkedResult === 0
                ? this.$t('common.label.detail')
                : this.$t('common.approval')}
            </span>,
            <span
              onClick={e => {
                e.stopPropagation();
                this.goDetail(record);
              }}
            >
              {this.$t('solution.schemePreview')}
            </span>,
          ];
        },
      },
    ].map(item => ({
      ...item,
      width: item.width || 130,
      sorter: false,
      filter: false,
      align: 'left',
      ellipsis: false,
    }));
  }

  //   handleReset() {
  //     this.searchData = {
  //       inviteCode: '',
  //       used: '',
  //       userName: '',
  //       userPhone: '',
  //     };
  //   }
  handleSearch() {
    this.$refs.table.loadData(this.searchData);
  }

  //   async shelves(item) {
  //     await standUpAndDown({ pkId: item.pkId, state: 1 });
  //     this.$message.success(this.$t('common.tips.operationSuccess'));
  //     this.getList();
  //   }

  //   async download(item) {
  //     await standUpAndDown({ pkId: item.pkId, state: 2 });
  //     this.$message.success(this.$t('common.tips.operationSuccess'));
  //     this.getList();
  //   }

  async takeDown(item) {
    await this.$confirm(this.$t('solution.downTip'));
    await standUpAndDown({ pkId: item.pkId, state: 2 });
    this.$refs.table.loadData();
    this.$message.success(this.$t('common.tips.operationSuccess'));
  }

  async shelf(item) {
    await this.$confirm(this.$t('solution.pushTip'));
    await standUpAndDown({ pkId: item.pkId, state: 1 });
    this.$refs.table.loadData();
    this.$message.success(this.$t('common.tips.operationSuccess'));
  }

  goDetail(data) {
    window.open(createModuleUrl('www') + '/solution/' + data.solutionId);
  }

  async installDetail(data) {
    const manageModal = await createModal(
      () => (
        <ManageBuysCount
          data={data.pkId}
          onClose={(manageModal.visible = false)}
        />
      ),
      {
        title: globalVueI18n.translate('solution.Purchases'),
        width: 600,
        bodyStyle: {
          padding: 0,
          overflow: 'hidden',
        },
      },
    );
  }

  total = 0;
  get service() {
    const service = new DataSourceService(
      '/template-manager/solution/admin/management/loadApprovalRequestForm',
    );
    service.getList = report =>
      solutionApprovalDetails({
        ...report,
        current: report.skip / report.size + 1,
        timeEnd: this.searchData.timeEnd,
        keyword: this.searchData.keyword,
        size: report.size,
        checkState: this.searchData.solutionClassify,
        timeStart: this.searchData.timeStart,
      }).then(res => {
        return {
          ...res,
          current: 1,
          hitCount: false,
          pages: 1,
          searchCount: true,
          size: 10,
          data: res ? res.records : [],
          total: res ? res.total : 0,
        };
      });
    return service;
  }
}
</script>
<style lang="less" module>
.table {
  :global(.x-data-table--action .ant-btn-primary) {
    color: #fff;
    margin-left: 10px;
  }
}

.index {
  height: 640px;
  margin: 0 20px;
}
.action {
  cursor: pointer;
  color: var(--font-active);
  &.action--delete {
    color: var(--delete);
    margin-left: 20px;
  }
}
.toolbar__input {
  width: 120px;
  margin-right: 10px;
}
.toolbar__picker {
  width: 220px;
  margin-right: 10px;

  :global {
    .ant-calendar-picker-icon {
      display: none;
    }
    .ant-calendar-picker-input.ant-input {
      padding-left: 10px;
      text-align: center;
    }
  }
}
.toolbar__select {
  min-width: 124px;
  margin-right: 10px;
}
.filterDropdown {
  :global(.ant-dropdown-menu) {
    padding: 0;
  }
}

.primaryColor {
  color: var(--primary);
  margin-left: 5px;
}
.marginSpan {
  margin-right: 20px;
}
.primarySpan {
  color: var(--success);
}
.warningSpan {
  color: var(--warning);
}
.dangerSpan {
  color: var(--danger);
}
</style>
