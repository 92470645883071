import request from '../request';

// /iot-platform/subscribe
const serviceName = '/iot-platform/subscribe';

/**@name 获取数据订阅列表 */
export function getSubscribeList(data) {
  return request(`${serviceName}/list`, {
    method: 'GET',
    body: data,
  });
}

// 获取产品已发布的api列表
export function getApiList(data) {
  return request('/triascloud-iot-platform/platformInterface/release', {
    method: 'GET',
    body: data,
  });
}

// 新增订阅
export function addSubscribe(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}

// 删除订阅列表
export function deleteSubscribe(subscribeId) {
  return request(`/triascloud-iot-platform/subscribe/${subscribeId}`, {
    method: 'DELETE',
  });
}

// 获取系统主题列表
export function getSubscribeTheme() {
  return request(`${serviceName}/amqp/theme`, {
    method: 'GET',
  });
}

// 获取系统主题列表
export function changeState(data) {
  return request(`${serviceName}/state`, {
    method: 'PUT',
    body: data,
  });
}

// 获取编辑数据
export function getEditData(subscribeId) {
  return request(`${serviceName}/${subscribeId}`, {
    method: 'GET',
  });
}

// 获取历史版本
export function getHistoryData(subscribeId) {
  return request(`${serviceName}/version/history/${subscribeId}`, {
    method: 'GET',
  });
}

// 保存并切换版本
export function saveChangeVersion(data) {
  return request(`${serviceName}/version/edit`, {
    method: 'PUT',
    body: data,
  });
}

// 编辑并发布
export function editChangeVersion(data) {
  return request(`${serviceName}`, {
    method: 'PUT',
    body: data,
  });
}

// 获取订阅ID
export function getPreviewList(data) {
  return request(`${serviceName}/preview`, {
    method: 'POST',
    body: data,
  });
}

// 获取主题列表
export function getThemeList(subscribeId) {
  return request(`${serviceName}/theme/${subscribeId}`, {
    method: 'GET',
  });
}

// 获取预览结构
export function getPreviewStruct(data) {
  return request(`${serviceName}/theme/struct`, {
    method: 'GET',
    body: data,
  });
}

// 获取平台订阅计划列表和主题
export function getPlanThemeList(data) {
  return request('/iot-platform/subscribe/all', {
    method: 'GET',
    body: data,
  });
}

// 获取平台连接器列表
export function getApiListDevice(data) {
  return request('/iot-platform/platformInterface/params', {
    method: 'POST',
    body: data,
  });
}
