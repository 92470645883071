<template>
  <a-form-model :model="form" ref="form" :rules="rules">
    <a-form-model-item label="所属功能" prop="navigationTag">
      <a-select
        placeholder="请选择所属功能"
        v-model="form.navigationTag"
        :options="navList"
        :disabled="operationType === 'edit'"
        @change="form.templateCode = undefined"
      >
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="模板" prop="templateCode">
      <a-select
        placeholder="请选择所属模板"
        v-model="form.templateCode"
        :disabled="operationType === 'edit' || !form.navigationTag"
        dropdownClassName="templateForm"
      >
        <a-select-option
          v-for="item in navTemplate[form.navigationTag]"
          :value="item.value"
          :key="item.value"
          :disabled="existedMenus.includes(item.value)"
          >{{ item.label }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Vue, Prop, Component } from 'vue-property-decorator';
// import { addTemplate, editTemplate } from '@/services/digital-site/template';
import { addTemplate } from '@/services/digital-site/template';
import {
  componyNavMenu,
  projectNavMenu,
  projectNavTemplates,
  componyNavTemplates,
} from '../enum';

@Component
export default class DeveloperEditForm extends Vue {
  @Prop({ type: String, default: 'add' }) operationType;
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '1' }) templateType;
  @Prop({ type: Array, default: () => [] }) menuList;

  form = {
    templateCode: undefined,
    templateName: '',
    navigationTag: undefined,
    templateType: '1',
  };
  get navTemplate() {
    return this.templateType === '1'
      ? projectNavTemplates
      : componyNavTemplates;
  }
  get existedMenus() {
    const list = [];
    this.menuList.forEach(item => {
      item.children.forEach(itemChild => {
        list.push(itemChild.templateCode);
      });
    });
    return list;
  }
  template = {};
  rules = {
    templateCode: [{ required: true, message: '请选择模板' }],
    navigationTag: [{ required: true, message: '请选择所属功能' }],
  };
  get navList() {
    const list = [];
    const menus = this.templateType === '1' ? projectNavMenu : componyNavMenu;
    Object.keys(menus).forEach(key => {
      list.push({
        label: menus[key],
        value: key,
      });
    });
    return list;
  }
  mounted() {
    this.initFormData();
  }
  initFormData() {
    if (this.operationType === 'edit') {
      this.form.templateCode = this.editData.templateCode;
      this.form.templateName = this.editData.templateName;
      this.form.navigationTag = this.editData.navigationTag;
    }
  }
  async getValue() {
    const message = {
      add: '添加成功！',
      edit: '编辑成功',
    };
    await this.$refs.form.validate();
    try {
      //   this.operationType === 'edit'
      //     ? await editTemplate({
      //         templateName: this.form.templateName,
      //         pkId: this.editData.pkId,
      //       })
      //     : await addTemplate(this.form);
      if (this.operationType === 'add') {
        this.form.templateType = this.templateType;
        this.form.templateName = this.navTemplate[this.form.navigationTag].find(
          item => item.value === this.form.templateCode,
        ).label;
        await addTemplate(this.form);
      }
      this.$message.success(message[this.operationType]);
      return true;
    } catch (error) {
      return false;
    }
  }
}
</script>

<style lang="less">
.templateForm {
  .ant-select-dropdown-menu-item-disabled {
    background-color: var(--form-disabled);
  }
}
</style>
