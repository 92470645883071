<template>
  <div :class="$style.index">
    <div :class="$style.item" v-for="item in list" :key="item.processTime">
      <div :class="$style.title">
        {{ $t(optionTypeText[item.optType]) }}
        <div :class="$style.optTenantName">
          {{ $t('iot.device.boundingEnterprises') }}:{{ item.optTenantName }}
        </div>
      </div>
      <div :class="$style.item_label">
        <div>{{ $t('solution.applicant') }}：{{ item.optUserName }}</div>
        <div>
          {{ $t('enterprise.ApplicationTime') }}：{{
            item.applyTime
              ? $moment(item.applyTime).format('YYYY-MM-DD HH:mm')
              : '-'
          }}
        </div>
      </div>
      <div :class="$style.item_label">
        <div>
          {{ $t('solution.applicationResult') }}：{{
            $t(resultTypeText[item.processResult])
          }}
        </div>
        <div>
          {{ $t('feedback.processingTime') }}：{{
            item.processTime
              ? $moment(item.processTime).format('YYYY-MM-DD HH:mm')
              : '-'
          }}
        </div>
      </div>
      <div v-if="item.processResultDesc">{{ item.processResultDesc }}</div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { optRecord } from '@/services/template-manager/solution';
import {
  optionType,
  optionTypeText,
  resultType,
  resultTypeText,
} from '@/enum/solution';
// import moment from 'moment';

@Component()
export default class SolutionShelvesModal extends Vue {
  @Prop({ type: String }) solutionId;
  //   async list() {
  //     await optRecord(this.solutionId);
  //   }
  optionType = optionType;
  optionTypeText = optionTypeText;
  resultType = resultType;
  resultTypeText = resultTypeText;
  list = [];
  async mounted() {
    this.list = await optRecord(this.solutionId);
  }
}
</script>
<style lang="less" module>
.title {
  color: var(--font-info);
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  .optTenantName {
    color: var(--font);
    width: 50%;
    text-align: left;
    overflow: hidden;
  }
}
.item {
  margin-bottom: 40px;
}
.item_label {
  display: flex;
  justify-content: space-between;
  color: var(--font);
  margin-bottom: 6px;
  font-size: 16px;
  div {
    width: 50%;
  }
}
</style>
