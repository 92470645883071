<template>
  <section :class="$style.content">
    <draggable handle=".mover" :animation="300" v-model="list">
      <div
        v-for="(item, index) in list"
        :class="$style.wrap"
        :key="item.name + index"
      >
        <images-upload :class="$style.img" v-model="item.images" />
        <a-input
          :value="item.name"
          @blur="handleInputName($event, index)"
          :class="$style.name"
          placeholder="限16字符汉字"
          :maxLength="32"
        />
        <span :class="$style.btnGroup">
          <x-icon
            :class="$style.icon"
            type="tc-icon-delete"
            @click.stop="handleChartDelete(item, index)"
          />
          <x-icon
            :class="[$style.icon, 'mover']"
            type="tc-icon-drag-handler"
          ></x-icon>
        </span>
      </div>
    </draggable>
    <a-button icon="plus" size="small" type="primary" @click="handleAdd"
      >新增二级分类</a-button
    >
  </section>
</template>
<script>
import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator';
import ImagesUpload from '@/views/connector/platform-manage/components/images-upload.vue';
import { deleteCategory } from '@/services/iot-platform/category';
import Draggable from 'vuedraggable';

@Component({
  components: {
    ImagesUpload,
    Draggable,
  },
})
export default class SecondSort extends Vue {
  @Model('change.value') value;
  @Prop({ type: Array }) value;

  @Watch('value', { deep: true, immediate: true })
  onValue(value) {
    this.list = value || [];
  }
  list = [
    {
      images: [],
      name: '',
    },
  ];
  handleAdd() {
    this.list.push({
      images: [],
      name: '',
    });
    this.emitChange();
  }

  handleInputName(e, index) {
    Vue.set(this.list[index], 'name', e.target.value);
  }

  async handleChartDelete(item, index) {
    if (item.pkId) {
      const res = await deleteCategory(item.pkId);
      if (res) {
        this.list.splice(index, 1);
        this.$message.success('操作成功');
      }
    } else {
      this.list.splice(index, 1);
      this.$message.success('操作成功');
    }
    this.emitChange();
  }

  emitChange() {
    this.$emit('change.value', this.list);
    this.$emit('change', this.list);
    this.$emit('input', this.list);
  }
}
</script>
<style lang="less" module>
.wrap {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  .img {
    width: 60px;
    height: 60px;
    display: inline-block;
    :global {
      .ant-upload.ant-upload-select-picture-card {
        margin: 0;
        width: 60px;
        height: 60px;
      }
      .ant-upload-list-picture-card .ant-upload-list-item,
      .ant-upload-list-picture-card-container {
        width: 60px;
        height: 60px;
      }
      .ant-form-item-control {
        line-height: 0;
      }
      .ant-upload-list-item-uploading-text {
        font-size: 12px;
        margin-top: 0px;
      }
    }
  }
  .name {
    width: 300px;
    margin-left: 10px;
  }
  .btnGroup {
    display: flex;
    align-items: center;
    height: 32px;
    .icon {
      color: var(--font-sub);
      margin-left: 10px;
    }
  }
}
.content {
  :global {
    .ant-btn-sm {
      height: 24px;
    }
  }
}
</style>
