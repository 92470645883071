<template>
  <a-form-model
    ref="form"
    :model="formData"
    :class="$style.wrap"
    :rules="rules"
  >
    <div :class="$style.tlt">
      基础信息
    </div>

    <a-row :gutter="20">
      <a-col :span="12">
        <a-form-model-item label="套件名称" prop="kitName">
          <a-input v-model="formData.kitName"></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="分类" prop="idxGroupId">
          <a-select v-model="formData.idxGroupId" :class="$style.select">
            <a-select-option
              v-for="item in templateTypes"
              :key="item.pkId"
              :value="item.pkId"
              >{{ item.groupName }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-row :gutter="20">
      <a-col :span="12">
        <a-form-model-item label="安装次数" prop="installCount">
          <a-input v-model="formData.installCount" type="number"></a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="状态" prop="type">
          <a-input v-model="formData.type" disabled></a-input>
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-form-model-item label="图标" prop="icon">
      <x-icon-select v-model="formData.icon" type="icon" required />
    </a-form-model-item>

    <a-form-model-item label="背景颜色" prop="color">
      <custom-color-panel v-model="formData.color" :identifier="identifier" />
    </a-form-model-item>

    <div :class="$style.tlt">
      套件信息
    </div>
    <a-form-model-item prop="fields">
      <a-button type="primary" ghost @click="kitEdit">套件编辑器</a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';
import { addDefaultIcon } from '@/services/resource/icon-library';
import { CustomColorPanel } from '@/components/custom-color-picker';
import { addKit, kitEdit, kitDetails } from '@/services/template-manager/kit';
import { kitGroupAll } from '@/services/template-manager/kitGroup';
import KitDesignModal from './kit-design-modal.vue';
function createKitTemplateModal(props) {
  return createFormModal(h => h(KitTemplate, { props }), {
    title: props?.id
      ? globalVueI18n.translate('kit.editKit')
      : globalVueI18n.translate('kit.addKit'),
    width: 860,
  });
}

@Component({ components: { CustomColorPanel } })
export default class KitTemplate extends Vue {
  @Prop({ type: String, default: null }) id;

  static createModal = createKitTemplateModal;

  templateTypes = [];
  formData = {
    kitName: '',
    icon: '',
    idxGroupId: '',
    installCount: '',
    color: '',
    fields: [],
  };
  view = '';
  get rules() {
    return {
      kitName: [
        {
          required: true,
          trigger: 'blur',
          message: this.$t('kit.kitName'),
        },
        {
          min: 2,
          max: 30,
          message: this.$t('record.groupNameTip'),
          trigger: 'blur',
        },
      ],
      idxGroupId: [
        {
          required: true,
          trigger: 'blur',
          message: this.$t('kit.typeTip'),
        },
      ],
      icon: [
        {
          required: true,
          trigger: 'blur',
          message: this.$t('iconLibrary.icon.tips.selectIcon'),
        },
      ],
      fields: [
        {
          required: true,
          trigger: 'change',
          message: this.$t('kit.addkitTip'),
        },
      ],
    };
  }

  async kitEdit() {
    const fields = await KitDesignModal.create({
      fields: this.formData.fields,
    });
    this.formData.fields = fields;
  }

  created() {
    if (this.id) {
      this.getData();
    }
    this.groupList();
  }

  async getData() {
    const {
      kitName,
      icon,
      idxGroupId,
      installCount,
      color,
      pluginFormFields,
      pkId,
    } = await kitDetails(this.id);
    this.formData = {
      kitName,
      icon,
      idxGroupId,
      installCount,
      color,
      fields: pluginFormFields,
      pkId,
    };
  }

  get identifier() {
    return 'home.design.color.picker';
  }
  handleEditLabelColor() {}
  handleColorChange() {}
  async groupList() {
    this.templateTypes = await kitGroupAll();
  }
  async getValue() {
    // console.log(this.formData.fields, this.formData.pluginFormFields);

    await this.$refs.form.validate();
    this.$refs.form.validateField('fields');
    await (this.id ? kitEdit : addKit)({
      color: this.formData.color,
      fields: this.formData.fields,
      icon: this.formData.icon,
      idxGroupId: this.formData.idxGroupId,
      installCount: this.formData.installCount,
      kitName: this.formData.kitName,
      pkId: this.formData.pkId,
    });
    this.$message.success(this.$t('common.tips.save'));
  }

  async handleChangeIcon(value) {
    if (value) {
      let result = this.list;
      result.splice(this.list.indexOf(this.data), 1, value);
      await addDefaultIcon(this.libraryId, result);
      this.$emit('change');
    }
  }
}
</script>
<style lang="less" module>
.tlt {
  margin-bottom: 10px;
  position: relative;
  padding-left: 8px;
  font-size: 16px;
  &::before {
    content: '';
    height: 15px;
    width: 2px;
    background: #ff5a20;
    position: absolute;
    left: 0;
    top: 4px;
  }
}

.wrap :global {
  .ant-col {
    margin-bottom: 5px;
  }
}
</style>
