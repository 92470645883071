<template>
  <div :class="$style.index">
    <div :class="$style.label">
      {{ $t('solution.solutionName') }}：{{ data.briefDesc.name }}
    </div>
    <div :class="$style.label">
      {{ $t('solution.solutionType') }}：{{ $t('solution.industrySolutions') }}
    </div>
    <div :class="$style.label">
      {{ $t('market.template.sellingPrice') }}：{{
        data.price ? data.price.toFixed(2) : $t('market.template.free')
      }}
    </div>
    <div :class="$style.label">
      {{ $t('market.template.upgradePlan') }}:{{
        data.briefDesc.remove
          ? $t('template.review.checked')
          : $t('template.review.unChecked')
      }}
    </div>
    <div :class="$style.label">
      {{ $t('solution.applyBusiness') }}：{{
        data.proposerTenantName ? data.proposerTenantName : '-'
      }}
    </div>
    <div :class="$style.label">
      {{ $t('solution.applicant') }}：{{
        data.proposerName ? data.proposerName : '-'
      }}
    </div>
    <div :class="$style.label">
      {{ $t('enterprise.ApplicationTime') }}：{{
        data.proposerTime
          ? $moment(data.proposerTime).format('YYYY-MM-DD HH:mm:ss')
          : '-'
      }}
    </div>
    <div :class="$style.label">
      {{ $t('solution.updateText') }}：{{ data.requestDesc }}
    </div>
    <div :class="$style.label" v-if="data.checkedResult !== -1">
      {{ $t('template.review.comments') }}：{{
        data.checkedResultDesc ? data.checkedResultDesc : '-'
      }}
    </div>

    <div :class="[$style.label, $style.desc]" v-if="data.checkedResult === -1">
      <a-form-model
        ref="form"
        :model="formData"
        :rules="rules"
        :class="$style.form"
        ><a-form-model-item
          :label="$t('template.review.comments')"
          prop="advice"
        >
          <a-textarea
            :class="$style.textarea"
            v-model="formData.advice"
          ></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div :class="$style.footer" slot="footer" v-if="data.checkedResult === -1">
      <a-button @click="$emit('close')">{{
        $t('common.action.cancel')
      }}</a-button>
      <a-button @click="submit(0)" type="primary">{{
        $t('solution.approvalFailed')
      }}</a-button>
      <a-button @click="submit(1)" type="primary">
        {{ $t('solution.approved') }}
      </a-button>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { solutionApproval } from '@/services/template-manager/solution';
@Component({
  components: {},
})
export default class SolutionApprovalModal extends Vue {
  @Prop({ type: Object }) data;

  formData = {
    advice: '',
  };
  get rules() {
    return {
      advice: [
        { required: true, message: this.$t('solution.rules1') },
        {
          min: 1,
          max: 200,
          message: this.$t('solution.tip2'),
          trigger: 'blur',
          whitespace: true,
        },
      ],
    };
  }
  async submit(value) {
    await solutionApproval({
      requestId: this.data.id,
      checkResult: value,
      advice: this.formData.advice,
    });
    this.$message.success(this.$t('common.tips.operationSuccess'));
    this.$emit('close');
    this.$emit('refresh');
  }
}
</script>
<style lang="less" module>
.footer {
  border-top: 1px solid var(--border);
  display: flex;
  place-content: flex-end;
  padding: 0 20px;
  padding-top: 20px;
  button {
    margin-left: 20px;
  }
}
.index {
  padding: 20px 0;
}
.label {
  font-size: 16px;
  padding: 0 20px;
  color: var(--font);
  margin-bottom: 8px;
  .textarea {
    min-height: 200px;
  }
}
.desc {
  margin-bottom: 15px;
}
</style>
