<template>
  <div>
    <div :class="$style['header-wrapper']">
      <div :class="$style.toolbar">
        <a-input
          :class="$style.toolbar__input"
          :placeholder="$t('common.action.search')"
          @pressEnter="handleFilterChange"
          @blur="handleFilterChange"
          v-model="keyword"
        >
          <x-icon type="tc-icon-search" slot="prefix" />
        </a-input>
      </div>
      <a-button
        v-if="$p.action('UPDATE')"
        type="primary"
        icon="plus"
        @click="handleCaseModal(null)"
      >
        {{ addButtonText }}
      </a-button>
    </div>
    <x-data-table
      ref="table"
      :columns="columns"
      :service="service"
      :scroll="{
        x: '100%',
        y: '100%',
      }"
      :class="$style.table"
    >
      <div slot="headerLeft"></div>

      <template #headerRight> </template>
    </x-data-table>
  </div>
</template>

<script>
import { Vue, Prop, Component } from 'vue-property-decorator';

import { DataSourceService } from '@triascloud/services';
import EditNews from './edit-news.vue';
import { createModal } from '@triascloud/x-components';

@Component()
export default class CommonTable extends Vue {
  @Prop({ type: String, default: '' }) addButtonText;
  @Prop({ type: String, default: '' }) editButtonText;
  @Prop({ type: Object }) requestList;

  keyword = '';

  data = {
    current: 1,
    size: 10,
  };

  get columns() {
    let defaultColumns = [
      {
        dataIndex: 'img',
        title: this.$t('common.img'),
        customRender: res =>
          res ? (
            <x-oss-image
              ossPath={res}
              fit={'contain'}
              class={this.$style['table-img']}
              resize={{ l: 270, m: 'lfit' }}
              onClick={this.previewImg}
            />
          ) : (
            ''
          ),
      },
      {
        dataIndex: 'title',
        title: this.$t('common.title'),
      },
      {
        dataIndex: 'label',
        title: this.$t('common.label.name'),
      },
      {
        dataIndex: 'pushTime',
        title: this.$t('common.time'),
        customRender: time =>
          time ? this.$moment(time).format('YYYY-MM-DD HH:mm') : '',
      },
      {
        dataIndex: 'tenant',
        title: this.$t('website.tenant'),
      },
      {
        dataIndex: 'creator',
        title: this.$t('website.creator'),
      },
      {
        title: this.$t('common.action.operating'),
        key: 'action',
        width: 80,
        customRender: (_, res) => {
          return (
            <div class={this.$style.operate}>
              <span onClick={() => this.handleDelete(res.pkId)}>
                {this.$t('common.action.delete')}
              </span>
              <span onClick={() => this.handleCaseModal(res.pkId)}>
                {this.$t('common.action.edit2')}
              </span>
            </div>
          );
        },
      },
    ];
    if (!this.$p.action('UPDATE')) {
      defaultColumns = defaultColumns.filter(item => item.key !== 'action');
    }
    return defaultColumns.map(item => ({
      ...item,
      width: item.width || 150,
      sorter: false,
      filter: false,
      align: 'left',
      ellipsis: false,
    }));
  }

  get service() {
    const service = new DataSourceService(this.requestList.getTableList);
    service.getList = ({ size, skip }) =>
      this.requestList
        .getMediaList({
          current: Math.ceil(skip / size) + 1,
          size,
          keyword: this.keyword,
          // classify: [],
        })
        .then(res => {
          return {
            ...res,
            data: res ? res.records : [],
            total: res ? res.total : 0,
          };
        })
        .catch(() => ({
          data: [],
          total: 0,
        }));
    return service;
  }

  created() {}

  previewImg(url) {
    this.$preview([{ url }]);
  }

  async handleDelete(pkId) {
    await this.$confirm(this.$t('website.news.deleteConfirm'));
    await this.requestList.deleteMedia(pkId);
    this.handleFilterChange();
    this.$message.success(this.$t('common.tips.delete'));
  }

  async handleCaseModal(pkId) {
    let initParams = null,
      promptText = this.addButtonText,
      mode = 'add';
    if (pkId) {
      initParams = await this.requestList.getMediaDetail(pkId);
      promptText = this.editButtonText;
      mode = 'edit';
    }
    const template = await createModal(
      h =>
        h(EditNews, {
          props: { initParams, mode },
          on: {
            refresh: () => {
              this.handleFilterChange();
              this.$message.success(this.$t('common.tips.save'));
              template.visible = false;
            },
            close: () => {
              template.visible = false;
            },
          },
        }),
      {
        title: promptText,
        width: 880,
        wrapClassName: this.$style['solution-modal'],
      },
    );
  }

  async handleFilterChange() {
    await this.$nextTick();
    this.$refs.table.loadData();
  }
}
</script>

<style lang="less" module>
.header-wrapper {
  display: flex;
  justify-content: space-between;
}
.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .toolbar__select {
    width: 140px;
    margin-right: 20px;
  }
  .toolbar__input {
    width: auto;
    :global .ant-input {
      width: 120px;
      transition: width 0.5s;
      &:focus {
        width: 200px;
      }
    }
  }
}
.table {
  height: calc(100% - 36px);
}
.table-img {
  margin: 8px 0;
  height: 44px;
  max-width: 102px;
  max-height: 44px;
}
.operate {
  display: flex;
  justify-content: space-between;
  color: var(--primary);
  padding: 0 5px;
  cursor: pointer;
}
.solution-modal {
  :global .ant-modal-title {
    text-align: left;
  }
  :global .ant-modal-body {
    display: flex;
    overflow: hidden;
    padding: 0;
  }
}
</style>
