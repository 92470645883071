<template>
  <div :class="[$style.index, 'wrapBox']">
    <notice
      ref="notice"
      :more-url="messageUrl"
      :handle-click="handleMessageClick"
      more-target="_self"
    />
    <span class="text" @click.once="handleClick">{{
      $t('common.information')
    }}</span>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { Notice } from '@triascloud/x-blocks';
import createMessageJumpPath from './createMessageJumpPath';
import { createModuleUrl } from '@/utils/createModuleUrl';

@Component({
  components: {
    Notice,
  },
})
export default class NoticeWrap extends Vue {
  messageUrl = `${createModuleUrl('app')}/message`;
  handleClick() {
    this.$refs.notice.visible = true;
  }
  async handleMessageClick(message) {
    const path = await createMessageJumpPath(message);
    // 这里当前页面重复打开的话，刷新下query
    path && this.navigator(path);
  }
  navigator(path) {
    location.href = `${createModuleUrl('app')}${path}`;
  }
}
</script>
<style lang="less" module>
.index {
  position: relative;
  margin-bottom: 10px;
  :global {
    .ant-badge {
      height: 32px;
      margin: 0;
      padding: 0 10px;
      z-index: 1;
      i {
        margin-top: 13px;
        font-size: 19px;
      }
      &:hover {
        color: var(--primary);
        i {
          color: var(--primary);
        }
      }
    }
    .ant-badge .ant-scroll-number-custom-component,
    .ant-badge-count,
    .ant-badge-dot {
      right: inherit;
      left: 10px;
      top: 12px;
    }
    .text {
      position: absolute;
      top: 2px;
      left: 38px;
    }
  }
  &:hover {
    color: var(--primary);
  }
}
</style>
