import request from '../request';
const basePath = '/resource/productFeedbackReply';

export function getFeedbackReplyList() {
  return request(`${basePath}/list`);
}

export function feedbackReply(data) {
  return request(`${basePath}/saveOrUpdate`, {
    method: 'POST',
    body: data,
  });
}

export function updateState({ pkId, state }) {
  return request(`${basePath}/updateStatus`, {
    method: 'POST',
    body: { pkId, state },
  });
}
