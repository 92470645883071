<template>
  <x-tree
    :class="$style.tree"
    :tree-data="classify"
    :replace-fields="{ key: 'pkId', title: 'name', children: 'childList' }"
    @select="handleSelect"
  >
    <div slot="title" slot-scope="{ data }" :class="$style.item">
      <div :class="$style.title">{{ data.name }}</div>
      <a-checkbox
        v-if="data.parentId !== '0'"
        :checked="value.some(item => item.value === data.pkId)"
      ></a-checkbox>
    </div>
  </x-tree>
</template>
<script>
import { TemplateType } from '@/enum/template';
import { getClassifyTree } from '@/services/template-manager/tpClassify';
import { globalVueI18n } from '@triascloud/i18n';
import { ModalSelect } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
function createSelectClassifyModal({ value, ...props }, modalProp = {}) {
  return ModalSelect.createModal(
    {
      type: [
        {
          Component: SelectClassifyModal,
          tab: globalVueI18n.translate('gallery.label.groupName'),
          props,
        },
      ],
      value,
    },
    {
      title: globalVueI18n.translate('gallery.actions.edit'),
      ...modalProp,
    },
  );
}
@Component()
export default class SelectClassifyModal extends Vue {
  @Prop({ type: String, default: TemplateType.Application }) templateType;
  @Prop({ type: Array, default: () => [] }) value;
  static createModal = createSelectClassifyModal;
  classify = [];
  created() {
    this.getClassify();
  }
  handleSelect(_, { node }) {
    if (node.dataRef.parentId === '0') return;
    const pkId = node.dataRef.pkId;
    if (this.value.some(item => item.value === pkId)) {
      this.$emit(
        'input',
        this.value.filter(item => item.value !== pkId),
      );
    } else {
      this.$emit(
        'input',
        this.value.concat({ value: pkId, text: node.dataRef.name }),
      );
    }
  }
  async getClassify() {
    this.classify = await getClassifyTree(this.templateType);
  }
}
</script>
<style lang="less" module>
.tree {
  padding: 10px 20px;
  .item {
    display: flex;
    align-items: center;
    .title {
      flex: 1;
    }
  }
}
</style>
