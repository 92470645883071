<template>
  <div :class="$style.selectProduct">
    <a-select
      :disabled="disabled"
      :placeholder="$t('common.placeholder.select')"
      v-model="form.production"
      allowClear
      @change="changeProduct"
    >
      <a-select-option
        v-for="item in productData"
        :value="item.pkId"
        :key="item.pkId"
      >
        {{ item.name }}
      </a-select-option>
    </a-select>
    <span v-if="showManage" :class="$style.text" @click="chooseProduct">
      {{ $t('connector.dpManage.manage') }}
    </span>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import ProductList from '@/views/connector/platform-manage/develop/device-manage/device-form/product-list.vue';
import { getProductsNoPage } from '@/services/iot-platform/dpManager';

@Component({
  components: {
    ProductList,
  },
})
export default class SelectProduct extends Vue {
  @Prop({ type: String, default: '' }) platformId;

  @Prop({ type: String, default: undefined }) value;
  @Prop({ type: Boolean, default: true }) showManage;
  @Prop({ type: Boolean, default: false }) disabled;

  showProduct = false;
  form = {
    production: undefined,
  };

  async chooseProduct() {
    await createFormModal(
      () => (
        <ProductList
          platformId={this.platformId}
          onRefreshList={() => this.getProductData()}
        />
      ),
      {
        title: this.$t('connector.dpManage.productCategorys'),
        width: 750,
      },
    );
  }

  changeProduct(val) {
    this.$emit('input', val);
    this.$emit('change', val);
  }

  mounted() {
    this.getProductData();
  }

  productData = [];

  async getProductData() {
    this.form.production = undefined;
    this.productData = [];
    try {
      this.productData = await getProductsNoPage(this.platformId);
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.selectProduct {
  display: flex;
  justify-content: space-between;

  .text {
    width: 40px;
    margin-left: 10px;
    color: var(--primary);
    cursor: pointer;
  }
}
</style>
