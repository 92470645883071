<template>
  <div :class="$style.panel">
    <div :class="$style.baseInfo">
      <a-popover placement="bottom" trigger="click">
        <span v-if="!isOverview && !disabledFlag">
          <a-icon type="plus" />设备基础信息</span
        >
        <div slot="content">
          <a-checkbox-group v-model="checkedList" @change="onChange">
            <a-col v-for="item in plainOptions" :key="item.value">
              <a-checkbox :value="item.value" :disabled="item.disabled">
                {{ item.label }}
              </a-checkbox>
            </a-col>
          </a-checkbox-group>
        </div>
      </a-popover>
    </div>
    <div>
      <EmptyContent v-if="checkedList.length === 0" />
      <a-row v-else :gutter="20">
        <a-col
          v-for="item in checkedList"
          :key="item"
          :span="12"
          style="margin-bottom: 10px"
        >
          <a-col :span="5" style="padding: 0;text-align: end">
            {{ baseInfo[item].text }}：
          </a-col>
          <a-col
            :span="19"
            :class="$style.baseValue"
            :title="baseInfo[item].value"
          >
            {{ baseInfo[item].value }}
          </a-col>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-tabs
        :default-active-key="activeKey"
        @change="changeTab"
        @tabClick="handleTabClick"
      >
        <a-tab-pane key="property" tab="属性">
          <a-spin :spinning="spinningProperty">
            <EmptyContent
              v-if="!spinningProperty && propertyList.length === 0"
            />
            <div v-else :class="$style.propertyDiv">
              <a-col style="text-align: end;color: var(--font-info);">
                最近更新：{{ isOverview && !disabledFlag ? chekcedTime : '-' }}
              </a-col>
              <a-col v-for="(item, idx) in propertyList" :key="idx">
                <a-col v-for="(v, key) in item" :key="key">
                  <a-col v-if="item[key].hasVO">
                    <a-col>
                      {{ item[key].name }}
                    </a-col>
                    <a-row>
                      <span
                        :class="[$style.property, $style.primarySpan]"
                        @click="openInnerModel(item[key].sonVO, item[key].name)"
                      >
                        详情
                      </span>
                    </a-row>
                  </a-col>
                  <a-col v-else>
                    <a-col v-if="item[key].sonVO && item[key].sonVO.length">
                      <a-col>
                        {{ item[key].sonVO[0].name.split('.')[0] }}
                      </a-col>
                      <a-row :gutter="10" :class="$style.property">
                        <a-col
                          v-for="son in item[key].sonVO"
                          :key="son.key"
                          :span="8"
                          style="margin: 5px 0"
                          :title="son.value"
                          :class="$style.textOverflow"
                        >
                          {{ son.name.split('.')[1] }}：{{ son.value }}
                        </a-col>
                      </a-row>
                    </a-col>
                    <a-col v-else :title="item[key].value">
                      {{ item[key].name }}：{{ item[key].value }}
                    </a-col>
                  </a-col>
                </a-col>
              </a-col>
            </div>
          </a-spin>
        </a-tab-pane>
        <a-tab-pane key="command" tab="指令">
          <EmptyContent v-if="commandList.length === 0" />
          <div v-else :class="$style.propertyDiv">
            <div
              v-for="item in commandList"
              :key="item.pkId"
              :class="$style.api"
              :style="{
                backgroundColor: apiLeftColor(item).backgroundColor30,
                borderColor: apiLeftColor(item).backgroundColor,
              }"
            >
              <div :class="$style.icon">
                <x-oss-image
                  :oss-path="item.logoUrl"
                  size="45"
                  @click.native="
                    $preview([{ url: item.logoUrl, name: item.apiName }])
                  "
                />
              </div>
              <div :class="$style.open">
                <div>
                  <span>{{ item.apiName }}</span>
                  <a-tooltip>
                    <template slot="title">
                      <span>当前指令支持定时请求</span>
                    </template>
                    <a-icon
                      v-if="item.triggerMethod === 'TIMING'"
                      type="clock-circle"
                      :class="$style.time"
                      :style="{ color: apiLeftColor(item).color }"
                    />
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title">
                      <span>点击查看帮助文档</span>
                    </template>
                    <a-icon
                      type="question-circle"
                      :class="$style.link"
                      :style="{ color: apiLeftColor(item).color }"
                      @click.stop="toHelpDoc(item)"
                    />
                  </a-tooltip>
                </div>
                <div v-if="item.introduction">
                  {{ item.introduction }}
                </div>
              </div>
              <a-button
                type="primary"
                @click="clickApi"
                :style="{
                  backgroundColor: apiLeftColor(item).backgroundColor,
                  borderColor: apiLeftColor(item).borderColor,
                }"
              >
                {{ item.copyWriting }}
              </a-button>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="event" tab="事件">
          <div
            style="display: flex;justify-content: flex-end; margin-bottom: 10px"
          >
            <a-select
              v-if="eventNames.length > 0"
              v-model="eventForm.name"
              placeholder="请选择"
              style="width: 150px"
              @change="selectEventName"
            >
              <a-select-option
                v-for="item in eventNames"
                :value="item"
                :key="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
            <a-select
              v-if="defaultEvent.filterField"
              v-model="eventForm.select"
              allowClear
              placeholder="请选择"
              style="width: 150px;margin-left: 10px;"
              @change="selectEventEnum"
            >
              <a-select-option
                v-for="item in eventEnum"
                :value="item.value"
                :key="item.value"
              >
                {{ item.key }}
              </a-select-option>
            </a-select>
            <a-range-picker
              v-if="defaultEvent.filterTime"
              v-model="eventForm.timeRange"
              show-time
              format="yyyy-MM-DD HH:mm"
              :separator="$t('common.label.to')"
              style="width: 325px;margin-left: 10px;"
              :disabled-date="disabledDate"
              @calendarChange="calendarPriceRangeChange"
              @ok="val => onTimeSearch(val, 'ok')"
              @change="val => onTimeSearch(val, 'change')"
            />
          </div>
          <div>
            <a-table
              :columns="columns"
              :data-source="tableData"
              :pagination="pagination"
              :scroll="scrollObj"
              :row-key="record => record.pkId"
              :loading="tableLoading"
              @change="handlePagination"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="custom" :tab="customTxt" v-if="customTxt">
          <DeviceLocation :detail="location" v-if="hasCustom" />
        </a-tab-pane>
        <div v-if="!isOverview && !disabledFlag" slot="tabBarExtraContent">
          <div
            v-if="activeKey === 'property'"
            @click="openProperty"
            :class="$style.extText"
          >
            管理属性字段
          </div>
          <div
            v-else-if="activeKey === 'command'"
            @click="openCommand"
            :class="$style.extText"
          >
            管理设备调用API
          </div>
          <div v-else-if="activeKey === 'custom'" :class="$style.extText">
            <section :class="$style.btnGroups" v-if="hasCustomEdit">
              <span :class="$style.mr20" @click="handleDelCustom">删除</span>
              <span @click="handleUpdateCustom">编辑</span>
            </section>
          </div>
          <div v-else :class="$style.extText" @click="openEvent">
            管理设备事件
          </div>
        </div>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content';
import { createFormModal, createModal } from '@triascloud/x-components';
import PropertySetting from '@/views/connector/platform-manage/develop/device-manage/panel/property-setting.vue';
import CommandSetting from '@/views/connector/platform-manage/develop/device-manage/panel/command-setting.vue';
import { ControlTypeEnum } from '@/views/connector/platform-manage/develop/data-template/template-design/form/controls/enum.js';
import InnerModel from '@/views/connector/platform-manage/develop/data-manage/inner-model.vue';
import {
  getDevicePanelInfo,
  getPreviewAttribute,
  getPreviewEvent,
} from '@/services/iot-platform/deviceManage';
import { getPlanThemeList } from '@/services/iot-platform/dataManage';
import { formatData } from '@/views/connector/platform-manage/develop/data-manage/common';
import DeviceLocationForm from './device-location-form.vue';
import DeviceLocation from './device-location.vue';
import { deepClone, download } from '@triascloud/utils';
import EventSettingList from './event-setting-list.vue';

@Component({
  components: {
    EmptyContent,
    DeviceLocation,
  },
})
export default class Panel extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: String, default: '' }) productionId;
  @Prop({
    type: Object,
    default: () => {},
  })
  deviceData;
  @Prop({ type: Boolean, default: false }) disabledFlag;
  /** @description 平台审核id */
  @Prop({ type: String, default: '' }) reviewId;
  @Prop({ type: Boolean, default: false }) preview;

  checkedList = [];
  plainOptions = [
    {
      label: '设备码',
      value: 'DEVICE_CODE',
      disabled: false,
    },
    {
      label: '设备ID',
      value: 'DEVICE_ID',
      disabled: false,
    },
    {
      label: '设备密钥',
      value: 'DEVICE_KEY',
      disabled: false,
    },
    {
      label: '设备类型',
      value: 'DEVICE_TYPE',
      disabled: false,
    },
    {
      label: '所属网关',
      value: 'IDX_DEVICE_CODE',
      disabled: false,
    },
    {
      label: '产品名称',
      value: 'PRODUCT_NAME',
      disabled: false,
    },
    {
      label: '绑定时间',
      value: 'BIND_TIME',
      disabled: false,
    },
    {
      label: '在线状态',
      value: 'ON_LINE',
      disabled: false,
    },
  ];
  baseInfo = {
    DEVICE_CODE: {
      text: '设备码',
      key: 'deviceCode',
      value: '12345678（示例）',
    },
    DEVICE_ID: {
      text: '设备ID',
      key: 'deviceId',
      value: '12345678（示例）',
    },
    DEVICE_KEY: {
      text: '设备密钥',
      key: 'rawKey',
      value: '12345678（示例）',
    },
    DEVICE_TYPE: {
      text: '设备类型',
      key: 'deviceType',
      value: '直连/网关/子设备（示例）',
    },
    IDX_DEVICE_CODE: {
      text: '所属网关',
      key: 'idxDeviceCode',
      value: '1.1.1.1（示例）',
    },
    PRODUCT_NAME: {
      text: '产品名称',
      key: 'productName',
      value: '三叠纪元T2电箱（示例）',
    },
    BIND_TIME: {
      text: '绑定时间',
      key: 'deviceTime',
      value: '2023-01-01 10:10:10（示例）',
    },
    ON_LINE: {
      text: '在线状态',
      key: 'deviceOnLine',
      value: '在线（示例）',
    },
  };

  clickApi() {
    this.$message.warning('仅供预览，请选择实际设备调用');
  }

  async mounted() {
    await this.getList();
    await this.initInfo();
  }

  eventForm = {
    select: undefined,
    name: undefined,
    timeRange: [
      this.$moment(new Date().getTime() - 30 * 86400000).format(
        'YYYY-MM-DD HH:mm',
      ),
      this.$moment(new Date().getTime()).format('YYYY-MM-DD HH:mm'),
    ],
  };
  structObj = {};

  async getList() {
    const params = {
      platformId: this.platformId,
    };
    this.spinningProperty = true;
    try {
      const res = await getPlanThemeList(params);
      if (res.length > 0) {
        this.structObj = {};
        res.forEach(v => {
          v.theme.forEach(t => {
            const structArr = {};
            t.structList.forEach(s => {
              structArr[s.identifier] = {
                name: s.name,
                type: s.dataType.toLowerCase(),
                other: s.other,
                unit: s.unit,
              };
              if (s.sonVO.length) {
                structArr[s.identifier]['sonVO'] = s.sonVO;
                structArr[s.identifier]['hasVO'] = true;
              } else {
                structArr[s.identifier]['hasVO'] = false;
              }
              this.structObj[t.id] = structArr;
            });
          });
        });
      }
    } catch {
      return false;
    }
  }

  get isOverview() {
    return this.deviceData?.deviceCode;
  }

  deviceTypeObj = {
    DIRECT_CONNECTION: '直连设备',
    GATEWAY: '网关设备',
    SUB_GATEWAY: '网关子设备',
  };
  location = {};
  spinningProperty = false;
  async initInfo() {
    const params = {
      productId: this.productionId,
    };
    if (this.reviewId) {
      params.recordId = this.reviewId;
    }
    try {
      const res = await getDevicePanelInfo(params);
      // 设备定位
      this.formatDeviceLocation(res, this.preview);
      if (res) {
        if (this.isOverview) {
          const params = {
            idxProductId: this.productionId,
            deviceCode: this.deviceData?.deviceCode,
          };
          this.location = params;
          const obj = await getPreviewAttribute(params);
          if (res.eventConfigList && res.eventConfigList.length > 0) {
            params['id'] = res.eventConfigList[0].id;
          }
          const { records, total } = await getPreviewEvent({
            startTime: this.$moment(
              new Date().getTime() - 30 * 86400000,
            ).valueOf(),
            endTime: this.$moment(new Date().getTime()).valueOf(),
            ...params,
          });
          res.baseInfoField.forEach(v => {
            const row = this.baseInfo[v];
            const key = this.baseInfo[v].key;
            if (v === 'DEVICE_TYPE') {
              row.value = this.deviceTypeObj[this.deviceData[key]];
            } else {
              row.value = this.deviceData[key] || '-';
            }
          });
          this.checkedList = res.baseInfoField;
          this.chekcedTime = obj.lastTime
            ? this.$moment(obj.lastTime).format('YYYY-MM-DD HH:mm:ss')
            : '-';
          // 属性
          let arr1 = [];
          obj.data.forEach(v => {
            const o1 = this.formatData(this.structObj[v.triascloud_theme], [v]);
            arr1 = arr1.concat(o1);
          });
          this.propertyList = arr1;
          this.spinningProperty = false;
          // 指令
          this.commandList = res.platformApiList;
          this.commandListDefault = res.platformApiList;
          // 事件
          if (res.eventConfigList && res.eventConfigList.length > 0) {
            this.defaultEvents = res.eventConfigList;
            this.setEventNames(this.defaultEvents);
            this.eventForm.name = this.defaultEvents[0].eventName;
            this.formatEventData(res.eventConfigList[0]);
            this.$nextTick(() => {
              this.selectEventName(this.eventForm.name);
            });
          }
          this.pagination.total = total;
          this.changeScreen();
          this.formatTableData(
            this.structObj[this.defaultEvent.topic],
            records,
          );
        } else {
          this.deviceEditId = res.pkId;
          this.checkedList = res.baseInfoField;
          this.onChange(res.baseInfoField);
          this.defaultSelect = res.attributeConfig;
          this.chekcedTime = res.attributeTimeDisplay;
          this.changeProperty();
          this.spinningProperty = false;
          this.commandList = res.platformApiList;
          this.commandListDefault = res.platformApiList;
          if (res.eventConfigList && res.eventConfigList.length > 0) {
            this.defaultEvents = res.eventConfigList;
            this.setEventNames(this.defaultEvents);
            this.eventForm.name = this.defaultEvents[0].eventName;
            this.formatEventData(res.eventConfigList[0]);
            this.$nextTick(() => {
              this.selectEventName(this.eventForm.name);
            });
          }
        }
      } else {
        this.spinningProperty = false;
      }
    } catch (error) {
      this.spinningProperty = false;
      return false;
    }
  }
  /** @description 格式化事件数据 */
  formatEventData(eventConfig) {
    this.defaultEvent = {
      source: eventConfig.source,
      topic: eventConfig.topic,
      panelFields: eventConfig.panelFields,
      filterField: eventConfig.filterField
        ? eventConfig.filterField
        : undefined,
      filterTime: eventConfig.filterTime,
      connector: eventConfig.connector,
      dataSources: eventConfig.dataSources,
    };
  }

  /** @description 设备定位 */
  formatDeviceLocation(res, preview = false) {
    if (res && res.customConfigList[0] && res.customConfigList[0].name) {
      this.customConfigList = res.customConfigList;
      this.hasCustom = true;
      if (preview) {
        this.customTxt = this.customConfigList.length
          ? this.customConfigList[0] && this.customConfigList[0].name
          : '';
      } else {
        if (
          this.customConfigList.length &&
          this.customConfigList[0].type === 'DEVICE_POSITION'
        ) {
          this.customTxt = '设备定位';
        }
        const data = this.customConfigList[0];
        const sub = data.subscriptionPlanConfigList[0];
        this.hasCustomEdit = true;
        this.customForm = {
          isPreviewLocus: data.isPreviewLocus,
          coordinate: data.coordinate,
          type: 'DEVICE_POSITION',
          lat: sub.panelFields[1],
          lon: sub.panelFields[0],
          source: sub.source,
          name: data.name,
          connector: sub.connector,
          topic: sub.topic,
          panelFields: sub.panelFields,
        };
      }
    } else {
      if (preview) {
        this.customTxt = '';
      } else {
        this.customTxt = '+自定义';
      }
    }
  }

  deviceEditId = '';
  onChange(checkedList) {
    if (checkedList.length === 6) {
      this.plainOptions.forEach(v => {
        if (checkedList.indexOf(v.value) === -1) {
          v.disabled = true;
        }
      });
    } else {
      this.plainOptions.forEach(v => {
        if (checkedList.indexOf(v.value) === -1) {
          v.disabled = false;
        }
      });
    }
    this.checkedList = checkedList;
  }

  activeKey = 'property';
  async changeTab(activeKey) {
    this.activeKey = activeKey;
    if (activeKey === 'event') {
      if (this.columns.length === 0) {
        this.columns = this.defaultColumns;
      }
      if (!this.isOverview) {
        const res = this.defaultEvent;
        this.changeEvent(res.panelFields, res.topic);
      }
    }
  }

  customTxt = '';
  hasCustom = false;
  hasCustomEdit = false;
  customConfigList = [];
  customForm = {};
  async handleTabClick(activeKey) {
    if (activeKey === 'custom') {
      if (this.hasCustom) return false;
      const res = await createFormModal(
        () => <DeviceLocationForm platformId={this.platformId} />,
        {
          width: 500,
          title: '添加自定义面板',
        },
      );
      if (res) {
        this.hasCustom = true;
        this.hasCustomEdit = true;
        this.customTxt = res.form.name;
        this.customConfigList = [res.body];
        this.customForm =
          res.form.type === 'DEVICE_POSITION' ? '设备定位' : res.form.name;
      }
    }
  }
  handleDelCustom() {
    this.hasCustom = false;
    this.hasCustomEdit = false;
    this.customTxt = '+自定义';
    this.customConfigList = [];
  }
  async handleUpdateCustom() {
    const detail = this.customForm;
    const res = await createFormModal(
      () => <DeviceLocationForm platformId={this.platformId} detail={detail} />,
      {
        width: 500,
        title: `修改${detail.name}面板`,
      },
    );
    if (res) {
      this.hasCustom = true;
      this.customTxt =
        res.form.type === 'DEVICE_POSITION' ? '设备定位' : res.form.name;
      this.customConfigList = [res.body];
    }
  }

  propertyList = [];
  defaultSelect = [];
  chekcedTime = false;
  submit = [];
  async openProperty() {
    const form = await createFormModal(
      () => (
        <PropertySetting
          platformId={this.platformId}
          defaultSelect={this.defaultSelect}
          defaultSelectTime={this.chekcedTime}
        />
      ),
      {
        title: '属性数据设置',
        width: 800,
        okText: '保存',
      },
    );
    if (form) {
      this.chekcedTime = form.createTime;
      this.defaultSelect = deepClone(form.data);
      this.changeProperty();
    }
  }

  changeSubmit(data) {
    if (!data.length) return [];
    const res = [];
    const o = {};
    const a = deepClone(data);
    a.forEach(v => {
      if (!o[v.topic]) {
        o[v.topic] = v;
        res.push(v);
      } else {
        const fileds = v.panelFields;
        o[v.topic].panelFields = o[v.topic].panelFields.concat(fileds);
      }
    });
    return res;
  }

  changeProperty() {
    const structObj = this.structObj;
    let arr1 = [];
    const submit = this.changeSubmit(this.defaultSelect);
    submit.forEach(s => {
      const farr = [];
      const fileds = this.chengeFileds(s.panelFields, structObj[s.topic]);
      farr.push(fileds);
      const res = this.formatData(structObj[s.topic], farr);
      arr1 = arr1.concat(res);
    });
    this.propertyList = arr1;
    this.submit = this.defaultSelect;
  }

  formatData(obj, arr) {
    const row = formatData(obj, arr, [], this.$moment);
    return row.res;
  }

  changeData(res) {
    if (res.length) {
      const item = res[0];
      const columnArr = [];
      for (let key in item) {
        if (typeof item[key] === 'object') {
          if (item[key].hasVO) {
            const o = {
              title: item[key].name,
              dataIndex: key,
            };
            o['customRender'] = 'detail';
            columnArr.push(o);
          } else {
            if (item[key].sonVO) {
              item[key].sonVO.forEach(son => {
                const o = {
                  title: son.name,
                  dataIndex: son.key,
                };
                o['customRender'] = 'value';
                columnArr.push(o);
              });
            } else {
              const o = {
                title: item[key].name,
                dataIndex: key,
              };
              let inVal = item[key]?.value ? item[key]?.value : '';
              if (item[key].type === 'array') {
                inVal = inVal.toString();
                o['customRender'] = `[${inVal}]`;
              } else {
                o['customRender'] = `${inVal}`;
              }
              columnArr.push(o);
            }
          }
        }
      }
      return columnArr;
    }
  }

  commandList = [];
  commandListDefault = [];

  async openCommand() {
    const res = await createFormModal(
      () => (
        <CommandSetting
          platformId={this.platformId}
          productionId={this.productionId}
          defaultSelect={this.commandListDefault}
        />
      ),
      {
        title: '添加指令能力',
        width: 550,
        okText: '保存',
      },
    );
    if (res) {
      this.commandListDefault = res;
      this.commandList = res.filter(v => v.checked === true);
    }
  }

  apiLeftColor(item) {
    if (item.themeColor !== '') {
      return {
        backgroundColor: item.themeColor,
        backgroundColor30: item.themeColor + '30',
        color: item.themeColor,
        borderColor: item.themeColor,
      };
    } else {
      return {
        backgroundColor: 'var(--primary)',
        backgroundColor30: 'var(--primary-30)',
        color: 'var(--primary)',
        borderColor: 'var(--primary)',
      };
    }
  }

  toHelpDoc(item) {
    if (item.helpDocumentationUrl) {
      window.open(item.helpDocumentationUrl, '_blank');
    } else {
      this.$message.warning('当前连接器暂无帮助文档');
    }
  }

  get defaultColumns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => {
          return index + 1;
        },
        title: '序号',
        align: 'center',
        width: 80,
      },
      {
        title: '创建时间',
        dataIndex: 'triascloud_create_time',
        width: 180,
      },
    ];
  }

  tableData = [];
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };
  tableLoading = false;
  scrollObj = {};

  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getEventTableList();
  }

  selectEventEnum(val) {
    this.eventForm.select = val;
    if (this.isOverview) {
      this.getEventTableList();
    } else {
      this.$message.warning('预览设备时生效');
    }
  }

  timeRange = {
    startTime: '',
    endTime: '',
  };

  selectPriceDate = '';
  calendarPriceRangeChange(date) {
    this.selectPriceDate = date[0];
  }
  disabledDate(current) {
    const DAY = 24 * 60 * 60 * 1000;
    const selectDate = this.$moment(this.selectPriceDate).valueOf();
    const offsetDays = 30 * DAY;
    // 禁用选中时间前后的{{offsetDays}}天
    return (
      this.$moment(current).valueOf() > selectDate + offsetDays ||
      this.$moment(current).valueOf() < selectDate - offsetDays
    );
  }

  onTimeSearch(val, flag) {
    if (this.isOverview) {
      if (flag === 'ok') {
        this.eventForm.timeRange = val;
        const s = this.$moment(val[0]).valueOf();
        const e = this.$moment(val[1]).valueOf();
        this.timeRange = {
          startTime: s,
          endTime: e,
        };
        this.getEventTableList();
      } else {
        // if (val.length === 0) {
        //   this.timeRange = {
        //     startTime: '',
        //     endTime: '',
        //   };
        //   this.getEventTableList();
        // }
      }
    } else {
      this.$message.warning('预览设备时生效');
    }
  }

  async getEventTableList() {
    const params = {
      idxProductId: this.productionId,
      deviceCode: this.deviceData?.deviceCode,
      current: this.pagination.current,
      size: 10,
    };
    if (
      this.eventForm.select !== undefined &&
      this.eventForm.select !== null &&
      this.eventForm.select !== ''
    ) {
      params['filterFieldValue'] = this.eventForm.select;
    }
    if (this.timeRange.startTime) {
      params['startTime'] = this.timeRange.startTime;
      params['endTime'] = this.timeRange.endTime;
    }
    try {
      const { records, current, size, total } = await getPreviewEvent(params);
      this.formatTableData(this.structObj[this.defaultEvent.topic], records);
      this.pagination.current = current;
      this.pagination.size = size;
      this.pagination.total = total;
    } catch {
      return false;
    }
  }

  columns = [];
  defaultEvent = {};
  defaultEvents = [];
  eventNames = [];
  selectEventName(name) {
    const item = this.defaultEvents.find(v => v.eventName === name);
    this.defaultEvent = item;
    const { panelFields, topic } = this.defaultEvent;
    this.changeEvent(panelFields, topic);
  }
  setEventNames(res) {
    const names = [];
    res.forEach(v => {
      names.push(v.eventName);
    });
    this.eventNames = names;
  }
  async openEvent() {
    const res = await createFormModal(
      () => (
        <EventSettingList
          platformId={this.platformId}
          defaultEvents={this.defaultEvents}
        />
      ),
      {
        title: '添加事件记录',
        width: 500,
        okText: '保存',
      },
    );
    if (res) {
      this.setEventNames(res);
      this.defaultEvents = res;
      this.defaultEvent = this.defaultEvents[0];
      this.eventForm.name = this.defaultEvent.eventName;
      const { panelFields, topic } = this.defaultEvent;
      this.changeEvent(panelFields, topic);
      // this.defaultEvent = res.form;
      // this.changeEvent(res.form.panelFields, res.form.topic);
    }
  }

  eventEnum = [];

  changeScreen() {
    const row = this.defaultEvent;
    if (row.filterField) {
      const filed = row.filterField.split('.');
      const struct = this.structObj[row.topic];
      const obj = struct[filed[0]];
      if (filed.length > 1) {
        const idx = obj.sonVO.findIndex(v => v.identifier === filed[1]);
        this.eventEnum = JSON.parse(obj.sonVO[idx].other);
      } else {
        this.eventEnum = JSON.parse(obj.other);
      }
    }
  }

  changeEvent(panelFields, id) {
    this.changeScreen();
    const testData = [];
    const structObj = this.structObj[id];
    let o = {
      triascloud_create_time: this.$moment(new Date()).valueOf(),
      triascloud_source_data: '',
    };
    const fileds = this.chengeFileds(panelFields, structObj);
    o = Object.assign(o, fileds);
    testData.push(o);
    this.tableLoading = true;
    this.formatTableData(structObj, testData);
  }

  chengeFileds(arr, obj) {
    const o = {};
    arr.forEach(v => {
      const str = v.split('.');
      const key = str[0];
      const row = obj[key];
      if (row.type === ControlTypeEnum.Array) {
        if (row.hasVO) {
          o[key] = [];
        } else {
          o[key] = '-';
        }
      } else if (row.type === ControlTypeEnum.Struct) {
        o[key] = {};
      } else {
        o[key] = '-';
      }
    });
    arr.forEach(v => {
      const str = v.split('.');
      const key = str[0];
      const row = obj[key];
      if (row.type === ControlTypeEnum.Array) {
        if (row.hasVO) {
          const o1 = {};
          const idx = row.sonVO.findIndex(v => v.identifier === str[1]);
          const type = row.sonVO[idx].dataType.toLowerCase();
          if (type === ControlTypeEnum.Date) {
            o1[str[1]] = this.$moment(new Date()).valueOf();
          } else if (
            type === ControlTypeEnum.Enum ||
            type === ControlTypeEnum.Switch
          ) {
            o1[str[1]] = '';
          } else {
            o1[str[1]] = '-';
          }
          if (o[key].length) {
            const o2 = Object.assign(o[key][0], o1);
            o[key].push(o2);
          } else {
            o[key].push(o1);
          }
        } else {
          o[key] = '-';
        }
      } else if (row.type === ControlTypeEnum.Struct) {
        const idx =
          row.sonVO && row.sonVO.findIndex(v => v.identifier === str[1]);
        const type = row.sonVO && row.sonVO[idx].dataType.toLowerCase();
        if (type === ControlTypeEnum.Date) {
          o[key][str[1]] = this.$moment(new Date()).valueOf();
        } else if (
          type === ControlTypeEnum.Enum ||
          type === ControlTypeEnum.Switch
        ) {
          o[key][str[1]] = '';
        } else {
          o[key][str[1]] = '-';
        }
      } else {
        o[key] = '-';
      }
    });
    return o;
  }

  changeColumn(item) {
    const columnArr = [];
    this.columns = this.defaultColumns;
    for (let key in item) {
      if (typeof item[key] === 'object') {
        if (item[key].hasVO) {
          const o = {
            title: item[key].name,
            dataIndex: key,
          };
          if (o.title.length > 4) {
            o.width = o.title.length * 20;
          } else {
            o.width = o.title.length * 30;
          }
          o['customRender'] = text => (
            <span
              class={this.$style.primarySpan}
              onClick={e => {
                e.stopPropagation();
                this.openInnerModel(text, o.title);
              }}
            >
              详情
            </span>
          );
          columnArr.push(o);
        } else {
          if (item[key].sonVO) {
            item[key].sonVO.forEach(son => {
              const o = {
                title: son.name,
                dataIndex: son.key,
                ellipsis: true,
              };
              if (o.title.length > 4) {
                o.width = o.title.length * 20;
              } else {
                o.width = o.title.length * 30;
              }
              o['customRender'] = text => <span title={text}>{text}</span>;
              columnArr.push(o);
            });
          } else {
            const o = {
              title: item[key].name,
              dataIndex: key,
              ellipsis: true,
            };
            if (o.title.length > 4) {
              o.width = o.title.length * 20;
            } else {
              o.width = o.title.length * 30;
            }
            if (item[key].type === 'array') {
              o['customRender'] = text => (
                <span title={text ? text.toString() : '-'}>
                  [{text ? text.toString() : ''}]
                </span>
              );
            } else {
              if (item[key].type.toLowerCase() === ControlTypeEnum.File) {
                o['customRender'] = text => (
                  <span title={text} class={this.$style.fileWrap}>
                    <x-icon
                      class={this.$style.icon}
                      onClick={() => {
                        download(text, item[key].name);
                      }}
                      type="tc-icon-file-unknown"
                    />
                  </span>
                );
              } else if (
                item[key].type.toLowerCase() === ControlTypeEnum.Image
              ) {
                o['customRender'] = text => (
                  <span>
                    <x-oss-image
                      class="x-file-icon"
                      size="32"
                      oss-path={text}
                      onClick={() => {
                        this.$preview(text, 'image');
                      }}
                    />
                  </span>
                );
              } else {
                o['customRender'] = text => <span title={text}>{text}</span>;
              }
            }
            columnArr.push(o);
          }
        }
      }
    }
    this.columns = this.columns.concat(columnArr);
    const len = columnArr.length;
    this.scrollObj['x'] = 1000 + 1000 * Math.floor(len / 5);
    this.tableLoading = false;
  }

  openInnerModel(key, name) {
    const modal = createModal(
      () => <InnerModel modelData={key} onClose={() => modal.handleClose()} />,
      {
        width: '1000px',
        title: name,
      },
    );
  }

  formatTableData(obj, arr) {
    const row = formatData(
      obj,
      arr,
      ['triascloud_create_time', 'triascloud_source_data'],
      this.$moment,
    );
    const res = row.res;
    this.changeColumn(res[0]);
    this.tableData = Object.freeze(row.data);
  }

  getValue() {
    return new Promise((resolve, reject) => {
      if (this.preview) {
        reject(false);
      }
      const params = {
        idxProductId: this.productionId,
        // idxProductId: '1564091274244186113',
        baseInfoField: this.checkedList,
        attributeConfig: this.submit,
        attributeTimeDisplay: this.chekcedTime,
      };
      if (this.deviceEditId) {
        params['pkId'] = this.deviceEditId;
      }
      if (this.commandList.length) {
        params['platformApiList'] = this.commandListDefault.filter(
          v => v.checked === true,
        );
      }
      if (this.defaultEvent.source) {
        params['eventConfigList'] = this.defaultEvents;
        // params['filterField'] = this.defaultEvent.filterField;
        // params['filterTime'] = this.defaultEvent.filterTime;
        params['customConfigList'] = this.customConfigList;
      }
      resolve(params);
    });
  }
}
</script>

<style lang="less" module>
.panel {
  .baseInfo {
    text-align: end;
    color: var(--primary);
    cursor: pointer;
  }
  .baseValue {
    padding: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .extText {
    cursor: pointer;
    color: var(--primary);
    .btnGroups {
      text-align: right;
    }
    .mr20 {
      margin-right: 20px;
    }
  }
  .api {
    position: relative;
    display: flex;
    align-items: center;
    height: 70px;
    background: var(--body-bg);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    border-left: 5px solid;

    .icon {
      font-size: 45px;
      margin-right: 10px;
    }

    .open {
      width: 590px;

      .time {
        margin-left: 10px;
        cursor: pointer;
        color: var(--primary);
      }

      .link {
        margin-left: 10px;
        cursor: pointer;
        color: var(--primary);
      }
    }
  }

  .propertyDiv {
    max-height: 300px;
    overflow-y: auto;
    padding: 5px;

    .property {
      padding-left: 20px;
    }
  }

  .primarySpan {
    color: var(--primary);
    cursor: pointer;
  }

  .textOverflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.fileWrap {
  display: flex;

  .icon {
    font-size: 32px;
  }
}
</style>
