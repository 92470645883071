<template>
  <a-form-model-item :label="getLabel" :prop="getProp">
    <a-radio-group
      v-if="isInOrOut === 'in'"
      :disabled="field.widget.parentId ? true : false"
      :value="field.widget.inParams && field.widget.inParams[0]"
      :options="inParameterOptions"
      @change="e => handleRadioChange(e)"
    />
    <a-checkbox-group
      v-else
      :value="field.widget.outParams"
      :options="outParameterOptions"
      @change="e => handleInput('widget.outParams', e)"
    />
  </a-form-model-item>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';

@Component()
export default class ParameterObject extends Vue {
  @Prop() field;
  @Prop() updateField;
  // 是入参还是出参
  @Prop({ type: String, default: 'in' }) isInOrOut;

  inParameterOptions = [
    { label: '开发', value: 'DEVELOPMENT' },
    { label: '平台方', value: 'PLATFORM' },
    { label: '订阅方', value: 'SUBSCRIBER' },
  ];
  outParameterOptions = [
    { label: '平台方', value: 'PLATFORM' },
    { label: '订阅方', value: 'SUBSCRIBER' },
  ];

  get getLabel() {
    return this.isInOrOut === 'in' ? '入参对象' : '出参对象';
  }
  get getProp() {
    return this.isInOrOut === 'in' ? 'widget.inParams' : 'widget.outParams';
  }
  handleInput(key, value) {
    if (value.target) {
      this.updateField({ key, value: [value.target?.value] });
    } else {
      this.updateField({ key, value });
    }
  }

  handleRadioChange(e) {
    this.handleInput('widget.inParams', e);

    // 组件为【结构体】和【数组】，将父组件【入参对象】属性同步到子组件的【入参对象】属性
    if (this.field.widget.fields) {
      // const fields = this.field.widget.fields;
      const fields = cloneDeep(this.field.widget.fields);
      fields.forEach(field => {
        field.widget.inParams = this.field.widget.inParams;
      });
      this.handleInput('widget.fields', fields);
    }
  }
}
</script>
<style lang="less" module>
.descriptionEditor:global(.x-editor) :global {
  padding: 0;
  .ql-editor {
    min-height: 150px;
  }
  .x-editor .ql-snow.ql-toolbar .ql-picker-options,
  .x-editor-toolbar.ql-snow.ql-toolbar .ql-picker-options {
    max-height: 150px;
  }
  .ql-snow.ql-toolbar {
    padding: 4px 0;
  }
}
</style>
