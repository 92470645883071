<template>
  <div :class="$style.auditContent">
    <div :class="$style.images">
      <!-- 单图片展示-->
      <template v-if="cloneRecord.length === 1">
        <div :class="$style.img">
          <x-oss-image
            :ossPath="cloneRecord[0].imgUrl"
            size="110"
            :class="$style.picture"
          />
          <div :class="$style.text">
            <p :class="$style.imgName">
              {{ cloneRecord[0].imgName }}
            </p>
            <p :class="$style.sharer">
              {{ $t('gallery.label.sharer') }}:
              {{ cloneRecord[0].createNickName }}
            </p>
          </div>
        </div>
        <p :class="$style.checkStatus" v-if="type === 'delete'">
          {{ $t('common.label.approvalStatus') }}：<span
            :class="$style[cloneRecord[0].checkStatus]"
            >{{ checkStatus }}</span
          >
        </p>
      </template>
      <!--多图片的展示-->
      <div :class="$style.manyImg" v-else>
        <div
          v-for="(item, index) in cloneRecord"
          :key="item.pkId"
          :class="$style.pictureGroup"
        >
          <x-oss-image
            :ossPath="item.imgUrl"
            size="62"
            :class="$style.groupPicture"
          />
          <div :class="$style.manyImgDel">
            <x-icon
              type="tc-icon-delete"
              :class="$style.icon"
              @click="deleteCheckKeys(index)"
            />
          </div>
          <p :class="$style.imgName">{{ item.imgName }}</p>
        </div>
      </div>
      <a-form-model
        ref="form"
        :model="audit"
        layout="vertical"
        v-if="type === 'audit'"
      >
        <a-form-model-item :label="$t('gallery.label.approvalGroup') + '：'">
          <x-select v-model="audit.idxImgGroup" :data="cloudGroup" />
        </a-form-model-item>
        <a-form-model-item :label="$t('dictionary.auditOpinion') + `:`">
          <x-textarea v-model="audit.checkOpinion" :maxLength="200" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getCloudGroup } from '@/services/resource/cloud-img-group';
import { createModal } from '@triascloud/x-components';
import ModalFooter from './ModalFooter';
import { globalVueI18n } from '@triascloud/i18n';

@Component({
  components: { ModalFooter },
})
export default class ImageAudit extends Vue {
  @Prop({ type: Array, default: () => [] }) record;
  @Prop({ type: String, default: 'audit' }) type;
  mounted() {
    this.getCloudGroup();
    this.cloneRecord = JSON.parse(JSON.stringify(this.record));
    if (this.cloneRecord.length > 0 && this.cloneRecord.length === 1) {
      this.audit.checkOpinion = this.cloneRecord[0].checkOpinion;
    }
  }
  cloneRecord = [];
  audit = {
    idxImgGroup: 'HEAD',
    checkOpinion: '',
  };
  cloudGroup = [];
  static creadAuditModal = createAuditModal;

  get checkStatus() {
    const obj = {
      PASSED: this.$t('common.action.pass'),
      FAIL: this.$t('common.action.Failed'),
      UNCHECKED: this.$t('common.label.pendingReview'),
    };
    return obj[this.cloneRecord[0].checkStatus];
  }
  deleteCheckKeys(index) {
    this.cloneRecord.splice(index, 1);
  }
  async getCloudGroup() {
    const data = await getCloudGroup();
    this.cloudGroup = data.map(item => {
      return { text: item.groupName, value: item.pkId };
    });
  }
}
function createAuditModal(record, type) {
  return new Promise(resolve => {
    const modal = { ref: null };
    modal.ref = createModal(
      render => {
        return render(ImageAudit, {
          props: {
            record,
            type,
          },
        });
      },
      {
        width: 482,
        title:
          type === 'audit'
            ? globalVueI18n.translate('common.action.review')
            : globalVueI18n.translate('common.action.delete'),
        footer: h =>
          h(ModalFooter, {
            props: {
              modal,
              resolve,
            },
          }),
      },
    );
  });
}
</script>

<style lang="less" module>
.auditContent {
}
.images {
  /*display: flex;*/
  /*justify-content: center;*/
}
.img {
  text-align: center;
  :global(.x-oss-image) {
    border-radius: 4px;
  }
}
.picture {
  width: 110px;
  height: 110px;
  background: #ccc;
}
.text {
  padding-top: 14px;
  .imgName {
    font-size: var(--font-sm);
    color: var(--font);
    margin-bottom: 5px;
  }
  .sharer {
    font-size: var(--font-xs);
    color: var(--font-sub);
  }
}
.manyImg {
  width: calc(100% + 14px);
  overflow: hidden;
  margin-bottom: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  .pictureGroup {
    width: 62px;
    text-align: center;
    margin-right: 14px;
    margin-bottom: 8px;
    position: relative;
    &:hover {
      .manyImgDel {
        display: block;
      }
    }
  }
  .groupPicture {
    width: 62px;
    height: 62px;
    border-radius: 4px;
    overflow: hidden;
  }
  .manyImgDel {
    position: absolute;
    top: 0;
    left: 0;
    width: 62px;
    height: 62px;
    background: rgba(00, 00, 00, 0.7);
    border-radius: 4px;
    padding-top: 22px;
    box-sizing: border-box;
    display: none;
    .icon {
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      &:hover {
        color: #fe5959;
      }
    }
  }
  .imgName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: var(--font-xs);
  }
}
.checkStatus {
  padding-top: 45px;
  font-size: var(--font-xs);
  color: var(--font-info);
}
.PASSED {
  color: var(--primary);
  font-size: var(--font-sm);
}
.FAIL {
  color: #fe5959;
  font-size: var(--font-sm);
}
.UNCHECKED {
  color: var(--font);
  font-size: var(--font-sm);
}
</style>
