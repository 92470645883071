import request from '../request';

export const basePath = '/tenant-center/enterprise/approval';

export const fetchList = body => {
  return request(`${basePath}/authenticationList`, {
    method: 'POST',
    body,
  });
};

/** @name 认证审批 */
export const fetchApproval = body => {
  return request(`${basePath}/approval`, {
    method: 'POST',
    body,
  });
};
/** @name del */
export const fetchDel = id => {
  return request(`${basePath}/remove/${id}`, {
    method: 'DELETE',
  });
};
