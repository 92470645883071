<template>
  <div :class="$style.index" v-if="data">
    <div :class="$style.item" v-for="item in dataKey" :key="item.key">
      <div :class="$style.itemContent">
        <div :class="$style.count" v-html="item.data"></div>
        <div :class="$style.tlt">{{ item.tlt }}</div>
        <div :class="$style.bot" v-if="item.dayOrWeek.length">
          <span
            >{{ $t('solution.weekon') }}:
            <a-icon
              type="caret-up"
              :style="{ color: 'green' }"
              v-if="data[item.dayOrWeek[0]] > 0"
            />
            <a-icon type="caret-down" :style="{ color: '#ff5a20' }" v-else />
            {{ data[item.dayOrWeek[0]] }}%
          </span>
          <span>
            {{ $t('solution.dayTo') }}:
            <a-icon
              type="caret-up"
              :style="{ color: 'green' }"
              v-if="data[item.dayOrWeek[1]] > 0"
            />
            <a-icon type="caret-down" :style="{ color: '#ff5a20' }" v-else />
            {{ data[item.dayOrWeek[1]] }}%
          </span>
        </div>
        <img
          :class="$style.img"
          :src="item.img"
          v-if="!item.ImgPosition && item.img"
        />
      </div>
      <img
        :class="$style.item_right"
        :src="item.img"
        v-if="item.ImgPosition && item.img"
      />
      <x-custom-icon
        :class="[$style.card__icon, $style.item_right]"
        :type="item.icon"
        v-if="item.icon"
      />
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { globalVueI18n } from '@triascloud/i18n';

@Component({
  components: {},
})
export default class HeaderList extends Vue {
  @Prop({ type: Object, default: () => null }) data;

  get dataKey() {
    return [
      {
        key: 'amount',
        icon: null,
        tlt: this.$t('market.statistics.totalTransaction'),
        img: null,
        dayOrWeek: ['dayCompared', 'weekCompared'],
        data: this.data
          ? this.data.amount / 100 > 10000
            ? (this.data.amount / 1000000).toFixed(2) +
              `<span>${globalVueI18n.translate('inviteCode.unit')}</span>`
            : this.data.amount / 100 +
              `<span>${globalVueI18n.translate('inviteCode.Yuan')}</span>`
          : '',
      },
      {
        key: 'buyCount',
        icon: null,
        tlt: this.$t('market.statistics.transactions'),
        img: require('@/assets/img/trade.png'),
        dayOrWeek: [],
        data: this.data && this.data.buyCount,
        ImgPosition: false,
      },
      {
        key: 'appTemplateCount',
        icon: '1381795643223031810.22724474',
        tlt: this.$t('market.statistics.templatesNumber'),
        img: null,
        dayOrWeek: [],
        data: this.data && this.data.appTemplateCount,
        ImgPosition: null,
      },
      {
        key: 'formTemplateCount',
        icon: '1381795643223031810.21742567',
        tlt: this.$t('market.statistics.formNumber'),
        img: null,
        dayOrWeek: [],
        data: this.data && this.data.formTemplateCount,
        ImgPosition: true,
      },
      {
        key: 'appInstallCount',
        icon: null,
        tlt: this.$t('market.statistics.appInstalls'),
        img: require('@/assets/img/application.png'),
        dayOrWeek: [],
        data: this.data && this.data.appInstallCount,
        ImgPosition: null,
      },
      {
        key: 'formInstallCount',
        icon: null,
        tlt: this.$t('market.statistics.formInstalls'),
        img: require('@/assets/img/install.png'),
        dayOrWeek: [],
        data: this.data && this.data.formInstallCount,
        ImgPosition: null,
      },
      {
        key: 'appPreviewInstallationConversionRates',
        icon: null,
        tlt: this.$t('market.statistics.Appconversion'),
        img: require('@/assets/img/marshalling.png'),
        dayOrWeek: [],
        data:
          this.data &&
          this.data.appPreviewInstallationConversionRates.toFixed(2) + '%',
        ImgPosition: true,
      },
      {
        key: 'formPreviewInstallationConversionRates',
        icon: '1381795643223031810.23899833',
        tlt: this.$t('market.statistics.previewRate'),
        img: null,
        dayOrWeek: [],
        data:
          this.data &&
          this.data.formPreviewInstallationConversionRates.toFixed(2) + '%',
        ImgPosition: true,
      },
      {
        key: 'templateAccess',
        icon: null,
        tlt: this.$t('market.statistics.templateVisits'),
        img: null,
        dayOrWeek: ['templateAccessDayCompared', 'templateAccessWeekCompared'],
        data: this.data && this.data.templateAccess,
        ImgPosition: true,
      },
      {
        key: 'templateContributionEnterprise',
        icon: null,
        tlt: this.$t('market.statistics.contributionEnterprise'),
        img: require('@/assets/img/totalPreviews.png'),
        dayOrWeek: [],
        data: this.data && this.data.templateContributionEnterprise,
        ImgPosition: null,
      },
      {
        key: 'printInstallCount',
        icon: null,
        tlt: this.$t('market.statistics.printInstalls'),
        img: null,
        dayOrWeek: [],
        data: this.data && this.data.printInstallCount,
        ImgPosition: true,
      },
      {
        key: 'printTemplateCount',
        icon: '1381795643223031810.32201581',
        tlt: this.$t('market.statistics.templatesInstalled'),
        img: null,
        dayOrWeek: [],
        data: this.data && this.data.printTemplateCount,
        ImgPosition: true,
      },
      {
        key: 'printPreviewInstallationConversionRates',
        icon: null,
        tlt: this.$t('market.statistics.printRate'),
        img: require('@/assets/img/printMarshalling.png'),
        dayOrWeek: [],
        data:
          this.data &&
          this.data.printPreviewInstallationConversionRates.toFixed(2) + '%',
        ImgPosition: true,
      },
    ];
  }
}
</script>
<style lang="less" module>
.index {
  padding: 30px 20px;
  padding-right: 0;
  display: flex;
  border-radius: 8px;
  padding-bottom: 0px;
  flex-wrap: wrap;
}
.item {
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px 0px var(--shadow);
  min-width: 300px;
  min-height: 116px;
  padding: 0 20px;
  position: relative;
  width: calc(20% - 20px);
  //   margin: 0 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  .itemContent {
    flex: 1;
    overflow: hidden;
  }
  &:first-child {
    margin-left: 0px;
  }
  &:last-child {
    margin-right: 0;
  }

  .count {
    font-size: 32px;
    color: var(--danger);
    line-height: 32px;
    margin-top: 14px;
    span {
      font-size: 18px;
    }
  }
  &:nth-child(2) {
    .count {
      color: #4771ff;
    }
  }

  &:nth-child(3) {
    .count {
      color: #41c400;
    }
    .card__icon {
      background: #41c400;
    }
  }
  &:nth-child(4) {
    .count {
      color: #b73eff;
    }
    .card__icon {
      background: #b73eff;
    }
    button {
      color: #f99604;
    }
  }

  &:nth-child(5) {
    .count {
      color: #f99604;
    }
    button {
      color: #f99604;
    }
  }

  &:nth-child(7) {
    .count {
      color: #ffa64e;
    }
    button {
      color: #ffa64e;
    }
  }
  &:nth-child(8) {
    .count {
      color: #ffc51a;
    }
    button {
      color: #ffc51a;
    }
  }
  &:nth-child(9) {
    .count {
      color: #00c49f;
    }
    button {
      color: #00c49f;
    }
  }
  &:nth-child(10) {
    .count {
      color: #4f7af2;
    }
    button {
      color: #4f7af2;
    }
  }
  &:nth-child(12) {
    .count {
      color: #11c5d9;
    }
    button {
      color: #11c5d9;
    }
    .card__icon {
      background: #11c5d9;
    }
  }
  &:nth-child(13) {
    .count {
      color: #0a36ff;
    }
    button {
      color: #0a36ff;
    }
    .card__icon {
      background: #0a36ff;
    }
  }
  .tlt {
    font-size: 14px;
    line-height: 14px;
    margin-top: 10px;
  }
  .img {
    margin-top: 15px;
    width: 100%;
  }
  .bot {
    font-size: 14px;
    line-height: 14px;
    margin-top: 16px;
    color: var(--font-info);
    display: flex;
    justify-content: space-between;
  }
  button {
    padding: 0;
    text-align: left;
  }
  &:last-child {
    .card__icon {
      background-color: #ff6231;
    }
  }
}
.item_right {
  // position: absolute;
  // right: 20px;
  // top: 25px;
}
.card__icon {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  color: #fff;
  background-color: #f99604;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}
.item_icon {
  background-color: var(--warning);
  i {
    font-size: 36px;
  }
}
</style>
