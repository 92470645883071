var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('field-config-config-desc',{attrs:{"desc":_vm.$t('controls.desc.array')}}),_c('field-config-title',_vm._b({},'field-config-title',_vm.fieldProps,false)),(!_vm.isRes && _vm.field.widget.subType !== _vm.ControlTypeEnum.struct)?_c('field-config-required',_vm._b({},'field-config-required',_vm.fieldProps,false)):_vm._e(),_c('a-form-model-item',{attrs:{"label":_vm.$t('connector.dpManage.subParameterType')}},[_c('a-select',{attrs:{"value":_vm.field.widget.subType},on:{"change":function (v) { return _vm.handleInput('widget.subType', v); }}},_vm._l((_vm.Types),function(item){return _c('a-select-option',{key:item.key,attrs:{"value":item.key}},[_vm._v(" "+_vm._s(item.value)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"prop":"widget.subCount","label":_vm.$t('connector.dpManage.subParametersCount')}},[_c('template',{slot:"label"},[_c('label',[_vm._v(_vm._s(_vm.$t('connector.dpManage.subParametersCount')))]),_c('a-tooltip',{attrs:{"arrowPointAtCenter":true,"placement":"right"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.$t('connector.dpManage.inputTips4')))]),_c('a-icon',{class:_vm.$style.tooltipIcon,attrs:{"type":"info-circle"}})],1)],1),_c('section',{class:this.$style.section},[_c('a-input-number',{class:_vm.$style.item,attrs:{"value":_vm.field.widget.subCount,"max":10240,"min":1},on:{"change":function (v) { return _vm.handleInput('widget.subCount', +v); }}}),_c('span',{class:[this.$style.ml10, this.$style.label1]},[_vm._v(" 个 ")])],1)],2),(_vm.field.widget.subType === _vm.ControlTypeEnum.struct)?_c('div',[_c('a-form-model-item',{attrs:{"label":_vm.$t('connector.dataType.rule.subIdentifier')}},[_c('x-input',{attrs:{"value":_vm.field.widget.subName},on:{"input":function($event){return _vm.updateField({
            key: "widget.subName",
            value: $event,
          })}}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('connector.dataType.rule.dataIdentifier')}},[_c('x-input',{attrs:{"value":_vm.field.widget.subIdentifier},on:{"input":function($event){return _vm.updateField({
            key: "widget.subIdentifier",
            value: $event,
          })}}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('connector.dataType.rule.groupDataIdentifier')}},[_c('x-input',{attrs:{"value":_vm.field.widget.subGroup},on:{"input":function($event){return _vm.updateField({
            key: "widget.subGroup",
            value: $event,
          })}}})],1)],1):_c('div',[(!_vm.isRes)?_c('parameter-object',_vm._b({attrs:{"isInOrOut":"in"}},'parameter-object',_vm.fieldProps,false)):_vm._e(),(_vm.isRes)?_c('parameter-object',_vm._b({attrs:{"isInOrOut":"out"}},'parameter-object',_vm.fieldProps,false)):_vm._e(),(!_vm.isRes)?_c('a-form-model-item',{attrs:{"prop":"widget.defaultValue.custom","label":_vm.$t('connector.dpManage.defaultValue')}},[_c('a-input',{class:_vm.$style.item,attrs:{"value":_vm.field.widget.defaultValue.custom},on:{"change":function (v) { return _vm.handleInput('widget.defaultValue.custom', v.target.value); }}})],1):_vm._e()],1),_c('field-config-description',_vm._b({},'field-config-description',_vm.fieldProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }