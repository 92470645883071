<template>
  <div :class="$style.wrap">
    <template v-if="!result">
      <x-icon :class="$style.icon" type="loading" />
      <div>
        {{ upgrade ? $t('solution.tip6') : $t('solution.tip7') }}
      </div>
    </template>
    <template v-else-if="result === -1">
      <x-icon :class="[$style.icon, $style.error]" type="close-circle" />
      <div>
        {{ upgrade ? $t('solution.upgrade') : $t('inviteCode.add')
        }}{{ $t('common.fail') }}
      </div>
    </template>
    <template v-else>
      <x-icon :class="$style.icon" type="check-circle" />
      <div>
        {{ upgrade ? $t('solution.upgrade') : $t('inviteCode.add')
        }}{{ $t('common.finish') }}
      </div>
    </template>
  </div>
</template>
<script>
import { delay } from '@triascloud/utils';
import { createModal } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
// import { TemplateManageSocket } from '@/enum/socket';
// import { globalSocket } from '@triascloud/message-hub';
import SolutionAbnormalNotifyModal from './solution-abnormal-notify-modal.vue';
import {
  checkResult,
  createdResult,
} from '@/services/template-manager/solution';

function createSolutionInstallModal(props) {
  return new Promise((resolve, reject) => {
    const modal = createModal(
      h =>
        h(SolutionInstallModal, {
          on: {
            cancel: () => {
              modal.visible = false;
              reject(new Error('user cancel'));
            },
            confirm: () => {
              modal.visible = false;
              resolve(modal);
            },
          },
          props,
        }),
      {
        title: null,
        width: 470,
        closable: false,
        bodyStyle: { margin: 0 },
        maskClosable: false,
      },
    );
  });
}
@Component()
export default class SolutionInstallModal extends Vue {
  @Prop({ type: Boolean, default: false }) upgrade;
  @Prop({ type: String }) solutionId;

  static createModal = createSolutionInstallModal;
  result = false;
  timer = null;
  async created() {
    this.getResult();
  }

  getResult() {
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.getList(this.solutionId);
      }, 0);
    }, 3000);
  }
  destroyed() {
    window.clearInterval(this.timer);
  }

  async getList(data) {
    this.result = await createdResult(data);
    await delay(2000);
    if (this.result === 0) {
      return false;
    }
    window.clearInterval(this.timer);
    if (this.result !== -1) {
      this.$emit('confirm');
    } else {
      const list = await checkResult(this.solutionId);
      SolutionAbnormalNotifyModal.createModal({
        list: list && list.length ? list : [],
      });
      await delay(2000);
      this.$emit('cancel');
    }
  }
}
</script>
<style lang="less" module>
.wrap {
  text-align: center;
  font-size: 16px;
  color: var(--font);
  padding: 60px 0 50px;
  .icon {
    font-size: 80px;
    color: var(--primary);
    margin-bottom: 30px;
    &.error {
      color: var(--danger);
    }
  }
}
</style>
