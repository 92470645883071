<template>
  <div ref="ManageDesignEchart" />
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import ResizeObserver from 'resize-observer-polyfill';
import { crossStorageModule } from '@/enum/store';

@Component({
  components: {},
})
export default class ManageDesignEchart extends Vue {
  @Prop({ type: Object }) data;
  /** @type { ResizeObserver } */
  _ob = null;
  myChart = null;
  @crossStorageModule.State skin;

  mounted() {
    this._ob = new ResizeObserver(this.chartResize);
    this._ob.observe(this.$refs.ManageDesignEchart);
    this.initChart();
  }
  chartResize() {
    this.myChart.resize();
  }
  get option() {
    const res =
      this.data && this.data.solutionCountDetailList
        ? this.data.solutionCountDetailList
        : [];
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        show: true,
        data: [
          {
            name: '专业方案数量',
            // 强制设置图形为圆。
            icon: 'roundRect',
            // 设置文本为红色
          },
        ],
        itemWidth: 8,
        itemHeight: 8,
        top: 25,
        right: 10,
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },

      xAxis: [
        {
          type: 'category',
          data: res.map(item => item.tenantName),
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            rotate: 45,
            formatter: function(value) {
              if (value.length >= 4) {
                let v = value.slice(0, 4) + '...';
                return v;
              } else {
                return value;
              }
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: '专业方案数量',
          type: 'bar',
          barWidth: '60%',
          color: ['#78B7FF'],
          data: res.map(item => item.solutionCount),
        },
      ],
    };
  }
  @Watch('option')
  @Watch('skin', { deep: true })
  initChart() {
    const chart = this.$refs.ManageDesignEchart;
    this.myChart = echarts.init(chart);
    this.myChart.setOption(this.option);
  }
}
</script>
<style lang="less" module></style>
