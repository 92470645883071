<template>
  <div :class="$style.box">
    <a-form-model-item>
      <label slot="label" class="ant-form-item-required">
        {{ $t('connector.api.newParameters') }}
      </label>
      <a-button
        icon="plus"
        type="primary"
        size="small"
        :class="this.$style.mt10"
      >
        {{ $t('connector.api.addParameters') }}
      </a-button>
    </a-form-model-item>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';

@Component()
export default class Design extends Vue {}
</script>
<style lang="less" module>
.box {
  :global {
    .ant-btn-sm {
      height: 24px;
    }
    .ant-form-item,
    .ant-form-item.ant-form-item-with-help {
      margin-bottom: 0;
    }
  }
}
.mt10 {
  margin-top: 10px;
  font-size: 12px;
}
</style>
