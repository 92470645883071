<template>
  <div :class="$style.manufacturer">
    <top-data :data-list="topData" />
    <div :class="$style.tableWrap">
      <FlexColContent>
        <div :class="$style.operation">
          <div :class="$style.searchBox">
            <a-input
              :class="$style.searchInput"
              v-model="searchParams.keyword"
              @input.stop="() => $refs.tableRef.search()"
              :placeholder="$t('ds.manufacturer.manufacturerName')"
            >
              <a-icon slot="prefix" type="search" />
            </a-input>
            <a-select
              allowClear
              v-model="searchParams.supplierType"
              :placeholder="$t('ds.manufacturer.manufacturerType')"
              @change="$refs.tableRef.search()"
              :class="$style.searchSelect"
            >
              <a-select-option value="1">标准</a-select-option>
              <a-select-option value="2">定制</a-select-option>
            </a-select>
          </div>
          <a-button
            type="primary"
            v-if="$p.action('CREATE')"
            @click="handleAdd()"
            >+{{ $t('ds.manufacturer.addManufacturer') }}</a-button
          >
        </div>
        <template slot="footer">
          <PaginationTable
            ref="tableRef"
            :columns="columns"
            :api="getManufacturerList"
            :extraKeys="{ showKey: false }"
            row-key="pkId"
            :scroll="{ x: 1600, y: 'max-content' }"
            :slotKeys="['action', 'deviceCount']"
            :search-params="searchParams"
          >
            <template slot="action" slot-scope="{ record }">
              <div :class="$style.buttonGroups">
                <span
                  :class="$style.button"
                  v-if="$p.action('UPDATE')"
                  @click="handleEdit(record)"
                  >{{ $t('solution.edit') }}</span
                >
                <span
                  :class="$style.button"
                  v-if="$p.action('DELETE')"
                  @click="handleDelete(record)"
                  >{{ $t('common.action.delete') }}</span
                >
              </div>
            </template>
            <template slot="deviceCount" slot-scope="{ record, text }">
              <a @click="showDeviceModal(record)">{{ text }}台</a>
            </template>
          </PaginationTable>
        </template>
      </FlexColContent>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import TopData from './components/top-data.vue';
import { createFormModal, createModal } from '@triascloud/x-components';
import ManufacturerForm from './form-modal/manufacturer-form.vue';
import PaginationTable from '@/views/digital-site/components/pagination-table';
import FlexColContent from '@/views/digital-site/components/flex-col-content';
import {
  getManufacturerList,
  getManufacturerStatistics,
  deleteManufacturer,
} from '@/services/digital-site/manufacturer';
import Tip from '@/components/tip';
import DeviceModal from './form-modal/device-modal.vue';

@Component({
  components: {
    TopData,
    PaginationTable,
    FlexColContent,
  },
})
export default class Manufacturer extends Vue {
  get topData() {
    const {
      supplierCount,
      supplierConnectedCount,
      standardSupplierCount,
      customerSupplierCount,
    } = this.statisticsData;
    return [
      {
        color: '#297FFF',
        name: this.$t('ds.manufacturer.manufacturers'),
        num: supplierCount || 0,
        icon: '1381795643223031810.35212371',
      },
      {
        color: '#FAB21A',
        name: this.$t('ds.manufacturer.devices'),
        num: supplierConnectedCount || 0,
        icon: '1381795643223031810.35212374',
      },
      {
        color: '#F85448',
        name: this.$t('ds.manufacturer.standardManufacturer'),
        num: standardSupplierCount || 0,
        icon: '1381795643223031810.35212372',
      },
      {
        color: '#7ED305',
        name: this.$t('ds.manufacturer.customizedManufacturer'),
        num: customerSupplierCount || 0,
        icon: '1381795643223031810.35212373',
      },
    ];
  }
  get columns() {
    return [
      {
        align: 'left',
        title: this.$t('ds.manufacturer.manufacturerCode'),
        dataIndex: 'supplierCode',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('ds.manufacturer.manufacturerName'),
        dataIndex: 'supplierName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('ds.manufacturer.manufacturerType'),
        dataIndex: 'supplierType',
        ellipsis: true,
        customRender: text => <span>{text == '1' ? '标准' : '定制'}</span>,
      },
      {
        align: 'left',
        title: this.$t('ds.manufacturer.dockingEquipment'),
        dataIndex: 'deviceCount',
        ellipsis: true,
        scopedSlots: { customRender: 'deviceCount' },
      },
      {
        align: 'left',
        title: this.$t('ds.manufacturer.manufacturerAuth'),
        dataIndex: 'permissionDeviceCount',
        ellipsis: true,
        customRender: text => <span>{text ? `${text}个` : '无'}</span>,
      },
      {
        align: 'left',
        title: this.$t('common.label.remark'),
        dataIndex: 'remark',
        ellipsis: true,
      },
      {
        title: this.$t('common.action.operating'),
        key: 'operation',
        width: 200,
        ellipsis: true,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  mounted() {
    this.getStatistics();
  }
  searchParams = {
    keyword: '',
    supplierType: undefined,
  };
  getManufacturerList = getManufacturerList;
  statisticsData = {
    supplierCount: 0,
    supplierConnectedCount: 0,
    standardSupplierCount: 0,
    customerSupplierCount: 0,
  };
  async getStatistics() {
    const data = await getManufacturerStatistics();
    this.statisticsData = data;
  }
  projectList = [];
  handleAdd() {
    this.showFormModel({}, 'add');
  }
  handleEdit(row) {
    this.showFormModel(row, 'edit');
  }
  async showFormModel(row, operationType) {
    try {
      const result = await createFormModal(
        () => <ManufacturerForm editData={row} operationType={operationType} />,
        {
          width: 1100,
          maskClosable: false,
          title: operationType === 'add' ? '添加厂商' : '修改厂商',
        },
      );
      if (result) {
        this.$refs.tableRef.getTableList();
      }
    } catch {
      return false;
    }
  }
  showDeviceModal(row) {
    createModal(() => <DeviceModal rowData={row} />, {
      title: '厂商对接设备',
      width: 900,
    });
  }
  async handleDelete(record) {
    const text = '确定删除厂商？';
    const tips = '删除后将清空厂商所有数据，请谨慎删除';
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: '提示',
          onOk: async () => {
            await deleteManufacturer(record.pkId);
            this.$message.success('删除成功!');
            this.$refs.tableRef.getTableList();
          },
        },
      );
    } catch {
      return false;
    }
  }
}
</script>
<style lang="less" module>
.manufacturer {
  height: 100%;
  padding: 20px;
  .tableWrap {
    height: calc(100% - 116px);
    .operation {
      display: flex;
      justify-content: space-between;
      margin: 20px 0 12px;
      .searchBox {
        .searchInput {
          margin-right: 10px;
          width: 200px;
        }
        .searchSelect {
          width: 180px;
        }
      }
    }
    .buttonGroups {
      .button {
        cursor: pointer;
        color: var(--primary);
        margin-right: 20px;
      }
      .button:last-of-type {
        margin-right: 0;
      }
    }
  }
}
</style>
