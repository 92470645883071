<template>
  <div :class="$style.wrap">
    <span>部分设备导入失败，点击</span>
    <span :class="$style.detail" @click="fn">复制详情</span>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { clipboardWrite } from '@triascloud/utils';
@Component({})
export default class ExcelError extends Vue {
  @Prop({ type: Object, default: () => {} }) record;
  fn() {
    let arr = this.record.devices.map(item => {
      var str = `设备码:${item.deviceName}`;
      if (item.deviceNameError) {
        str += ' 设备码冲突';
      }
      if (item.iccIdError) {
        str += ' iccId冲突';
      }
      if (item.expireTimeError) {
        str += ' 生产日期格式错误';
      }
      if (item.colorError) {
        str += ' 颜色非指定类型';
      }
      return str;
    });
    let str = arr.join(';\n');
    clipboardWrite(str).then(() => {
      this.$message.success('复制成功');
    });
  }
}
</script>
<style lang="less" module>
.wrap {
  display: inline-block;
  .detail {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
