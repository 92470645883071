<script>
import { delay } from '@triascloud/utils';
import {
  Component,
  Vue,
  PropSync,
  Prop,
  InjectReactive,
} from 'vue-property-decorator';
import { ControlTypeEnum } from '../../data-template/template-design/form/controls';
import { createRandom, parseVariable } from './utils';

@Component()
export default class RenderNumber extends Vue {
  @PropSync('item') syncItem;
  @PropSync('store') syncStore;
  @Prop() tooltip;
  @Prop() param;
  @Prop() checkDefaultValue;
  @Prop() schemeByType;
  @Prop({ type: String, default: 'id' }) useKey;

  @InjectReactive('variables') variables;

  random;
  async created() {
    this.random = Math.random();
    if (/\{\{(\w+)\}\}/.test(this.syncStore.get(this.syncItem[this.useKey]))) {
      const strValue = parseVariable(
        this.syncStore.get(this.syncItem[this.useKey]),
        this.variables,
      );
      await this.$nextTick();
      await delay(50);
      this.syncStore.set(this.syncItem[this.useKey], strValue);
    }
  }

  renderRandom() {
    const item = this.syncItem;
    this.checkDefaultValue(item);
    let str;
    if (item.type === ControlTypeEnum.Input) {
      let data = createRandom(item, this.random);

      if (data) {
        this.syncStore.set(item[this.useKey], data);
      }
      // eslint-disable-next-line prettier/prettier
      str = (
        <a-input
          v-model={this.syncStore.data[item[this.useKey]]} 
        />
      );
    } else {
      str = (
        <a-input-number
          style={{ width: '100%' }}
          v-model={this.syncStore.data[item[this.useKey]]}
        />
      );
    }
    return str;
  }

  render() {
    const item = this.syncItem;
    this.checkDefaultValue(item);
    return (
      <a-form-model-item
        class={this.$style.formItem}
        rules={[
          { required: item.required, message: '不能为空', trigger: 'blur' },
        ]}
        prop={item[this.useKey]}
      >
        <span slot="label" class={this.$style.label}>
          <span>{item.name}</span>
          {this.tooltip(item)}
          {this.param(item)}
        </span>
        {this.renderRandom()}
      </a-form-model-item>
    );
  }
}
</script>
<style lang="less" module>
.formItem {
  :global {
    .ant-form-item-required {
      display: flex;
      align-items: center;
    }
  }
}
.label {
  display: flex;
  align-items: center;
}
</style>
