<template>
  <div :class="$style.extend">
    <a-row :gutter="[10, 10]">
      <a-col :span="6" v-for="(item, idx) in value" :key="item.pkId">
        <div :class="$style.vCard">
          <div :class="$style.count">
            <div v-if="!item.updated">
              <span :class="$style.countNum">
                {{ changeNumber(item.count) }}
              </span>
              <span v-if="flag !== 'store'" :class="$style.ml10">次调用</span>
              <span v-else :class="$style.ml10">月</span>
            </div>
            <div v-else>
              <a-input-number
                :min="0"
                :max="getNumber"
                :step="100"
                style="width: 120px"
                :formatter="value => changeNumber(value)"
                v-model="item.count"
              />
              <span v-if="flag !== 'store'" :class="$style.ml10">次调用</span>
              <span v-else :class="$style.ml10">月</span>
            </div>
            <div :class="$style.iconWrap" v-if="!disabledFlag">
              <a-icon
                v-if="!item.updated"
                :class="[$style.countIcon, $style.themeColor]"
                type="edit"
                @click="editUseCount(item)"
              />
              <a-icon
                v-else
                type="check-circle"
                :class="[$style.countIcon, $style.successColor]"
                @click="saveUseCount(idx)"
              />
              <x-icon
                v-if="!item.updated"
                :class="[$style.countIcon, $style.deleteColor]"
                type="tc-icon-delete"
                @click="deleteUseCount(idx)"
              />
              <a-icon
                v-else
                type="close-circle"
                :class="[$style.countIcon, $style.deleteColor]"
                @click="editUseCount(item)"
              />
            </div>
          </div>
          <div :class="$style.count">
            <div v-if="!item.updated">
              <span>￥</span>
              <span :class="$style.countNum">
                {{ changeNumber(item.price) }}
              </span>
              <span :class="$style.ml10">元</span>
            </div>
            <div v-else>
              <a-input-number
                :min="0.01"
                :max="999999.0"
                :step="100.0"
                style="width: 120px"
                :formatter="value => changeNumber(value)"
                :precision="2"
                v-model="item.price"
              />
              <span :class="$style.ml10">元</span>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <div
      v-if="value.length < 6 && !disabledFlag"
      :class="[$style.add, $style.themeColor]"
      @click="addUseCount"
    >
      <a-icon type="plus" />
      <span>添加扩展包</span>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { uuid } from '@triascloud/utils';

@Component
export default class ExtendList extends Vue {
  @Prop({ type: Array, default: () => [] }) value;
  @Prop({ type: String, default: 'extend' }) flag;
  @Prop({ type: Boolean, default: false }) disabledFlag;

  changeNumber(val) {
    return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  get getNumber() {
    return this.flag !== 'store' ? 99999999 : 100;
  }

  addUseCount() {
    if (this.checkHasLastValue(this.value)) {
      this.value.forEach(v => (v.updated = false));
      const v = this.flag !== 'store' ? 1000 : 12;
      this.value.push({
        pkId: uuid(),
        count: v,
        price: 100,
        updated: true,
      });
    } else {
      this.$message.warn('请填写扩展包的值');
    }
  }

  // 限制扩展包最多添加6次
  checkHasLastValue(list = []) {
    // 检查上一次添加的【扩展包】是否有更新值
    if (list.length > 0) {
      const len = list.length - 1;
      if (list[len].count && list[len].price) {
        return true;
      }
    }
    return true;
  }

  deleteUseCount(index) {
    this.value.splice(index, 1);
  }

  editUseCount(item) {
    item.updated = !item.updated;
  }

  saveUseCount(idx) {
    if (this.checkHasLastValue(this.value)) {
      this.value[idx].updated = false;
      this.$emit('input', this.value);
      this.$emit('change', this.value);
    } else {
      this.$message.warn('请填写扩展包的值');
    }
  }
}
</script>

<style lang="less" module>
.extend {
  .vCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--border);
    border-radius: 4px;
    height: 90px;
    padding: 8px;

    .count {
      display: flex;
      justify-content: space-between;
      line-height: 30px;

      .countNum {
        font-size: 18px;
        color: var(--primary);
      }

      .iconWrap {
        display: inline-flex;
        align-items: center;
      }

      .countIcon {
        font-size: 16px;
        width: 24px;
        cursor: pointer;
        text-align: right;
      }
    }

    &:hover {
      border-color: var(--primary-40);
    }
  }

  .themeColor {
    color: var(--primary);
  }

  .deleteColor {
    color: var(--delete);
  }

  .successColor {
    color: var(--success);
  }

  .ml10 {
    margin-left: 10px;
  }

  .add {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    margin: 10px 0;
  }
}
</style>
