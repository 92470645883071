<template>
  <div :class="$style.pseting">
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-form-model-item prop="createTime">
        <span slot="label">
          显示创建时间（添加多个订阅计划时取最新的时间）
        </span>
        <a-switch
          v-model="form.createTime"
          checked-children="显示"
          un-checked-children="不显示"
        />
      </a-form-model-item>
    </a-form-model>
    <div :class="$style.select">
      <a-spin :spinning="spinning">
        <div v-if="spinning" style="height: 100px"></div>
        <x-collapse
          :activeKey="defaultActiveKey"
          :defaultActiveKey="defaultActiveKey"
        >
          <x-collapse-panel
            v-for="(item, idx) in propertyList"
            :key="item.pkId"
          >
            <span slot="header" style="display: flex;align-items: center">
              <span>{{ item.name || '定时默认主题' }}</span>
              <a-tag
                :color="
                  colorObj[item.dataSources] && colorObj[item.dataSources].color
                "
                style="margin-left: 10px"
              >
                {{
                  colorObj[item.dataSources] && colorObj[item.dataSources].text
                }}
              </a-tag>
            </span>
            <div style="min-height: 50px">
              <a-form-model
                ref="listForm"
                layout="vertical"
                :model="item.form"
                :rules="listFormRules"
                :key="item.pkId"
              >
                <a-form-model-item prop="propertyData">
                  <PropertyForm
                    ref="propertyForm"
                    v-model="item.form.propertyData"
                    :selectData="selectData"
                    :planId="item.pkId"
                  />
                </a-form-model-item>
              </a-form-model>
            </div>
            <span
              slot="extra"
              :class="$style.delete"
              @click.stop="deleteProperty(item, idx)"
            >
              <x-icon type="tc-icon-delete" />
            </span>
          </x-collapse-panel>
        </x-collapse>
        <a-form-model
          ref="propertyForms"
          layout="vertical"
          :model="propertyForms"
          :rules="propertyRules"
          :key="+new Date()"
        >
          <a-form-model-item label="订阅计划" prop="plan">
            <a-select
              v-model="propertyForms.plan"
              placeholder="请选择"
              style="width: 250px;"
              allowClear
            >
              <a-select-option
                v-for="item in showSelectData"
                :key="item.pkId"
                :value="item.pkId"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <a-button
          type="link"
          icon="plus"
          style="padding: 0"
          @click="addProperty"
        >
          添加属性数据
        </a-button>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getPlanThemeList } from '@/services/iot-platform/dataManage';
import { deepClone, uuid } from '@triascloud/utils';
import PropertyForm from '@/views/connector/platform-manage/develop/device-manage/panel/property-form.vue';

@Component({
  components: {
    PropertyForm,
  },
})
export default class PropertySetting extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: Array, default: () => [] }) defaultSelect;
  @Prop({ type: Boolean, default: false }) defaultSelectTime;

  @Watch('defaultSelect', { immediate: true, deep: true })
  changeSelect(newVal) {
    if (newVal) {
      // pass
    }
  }

  mounted() {
    this.getList();
  }

  form = {
    createTime: false,
  };
  rules = {};
  propertyForms = {
    plan: undefined,
  };
  propertyRules = {
    plan: [
      {
        required: true,
        message: '请选择订阅计划',
      },
    ],
  };
  listFormRules = {
    propertyData: [
      {
        required: true,
        message: '请添加属性字段',
      },
    ],
  };
  propertyList = [];
  showSelectData = [];
  async addProperty() {
    await this.$refs.propertyForms.validate();
    const id = this.propertyForms.plan;
    const idx = this.selectData.planData.findIndex(v => v.pkId === id);
    const o = {
      pkId: id,
      form: {
        propertyData: [],
      },
      ...this.selectData.planData[idx],
    };
    const idx2 = this.showSelectData.findIndex(v => v.pkId === id);
    this.showSelectData.splice(idx2, 1);
    this.propertyList.push(o);
    this.defaultActiveKey = id;
    this.$refs.propertyForms.resetFields();
    this.propertyForms.plan = undefined;
  }

  deleteProperty(row, idx) {
    this.propertyList.splice(idx, 1);
    const idx1 = this.selectData.planData.findIndex(v => v.pkId === row.pkId);
    if (idx1 !== -1) {
      this.showSelectData.push(this.selectData.planData[idx1]);
    }
  }

  colorObj = {
    PASSIVE: {
      text: '被动触发',
      color: '#c763f4',
    },
    TIMING: {
      text: '定时触发',
      color: '#b1a192',
    },
  };

  spinning = false;
  planObj = {};
  filedsObj = {};
  selectData = {};
  defaultActiveKey = '';
  async getList() {
    const params = {
      platformId: this.platformId,
    };
    try {
      this.spinning = true;
      const arr = [];
      const planObj = {};
      const filedsObj = {};
      const sonObj = {};
      const res = await getPlanThemeList(params);
      if (res.length > 0) {
        res.forEach(v => {
          arr.push({
            connector: v.connector,
            dataSources: v.dataSources,
            name: v.name,
            pkId: v.pkId,
          });
          planObj[v.pkId] = [];
          v.theme.forEach(t => {
            planObj[v.pkId].push({
              id: t.id,
              name: t.name,
            });
            filedsObj[t.id] = [];
            t.structList.forEach(s => {
              filedsObj[t.id].push({
                identifier: s.identifier,
                name: s.name,
              });
              sonObj[`${t.id}.${s.identifier}`] = s.sonVO;
            });
          });
        });
        this.selectData = {
          planData: arr,
          planObj,
          filedsObj,
          sonObj,
        };
        this.showSelectData = deepClone(arr);
        this.defaultActiveKey = arr[0].pkId;
        if (this.defaultSelect.length > 0) {
          this.defaultActiveKey = this.defaultSelect[0].source;
          const data = deepClone(this.defaultSelect);
          const arr = [];
          const obj = {};
          data.forEach(v => {
            const id = v.source;
            const idx1 = this.showSelectData.findIndex(
              show => show.pkId === id,
            );
            if (idx1 > -1) {
              this.showSelectData.splice(idx1, 1);
            }
            if (!obj[v.source]) {
              const idx = this.selectData.planData.findIndex(
                v => v.pkId === id,
              );
              const o = {
                pkId: v.source,
                form: {
                  propertyData: [],
                },
                ...this.selectData.planData[idx],
              };
              v.panelFields.forEach(p => {
                const o1 = this.changeFormFileds(v, p);
                o.form.propertyData.push(o1);
              });
              obj[v.source] = o;
              arr.push(o);
            } else {
              const form1 = obj[v.source].form.propertyData;
              v.panelFields.forEach(p => {
                const o1 = this.changeFormFileds(v, p);
                form1.push(o1);
              });
            }
          });
          this.propertyList = arr;
          this.form.createTime = this.defaultSelectTime;
        }
      }
      this.spinning = false;
    } catch {
      this.spinning = false;
      return false;
    }
  }

  changeFormFileds(v, p) {
    const str = p.split('.');
    const form = {
      theme: v.topic,
      pfiled: `${v.topic}.${str[0]}`,
      cfiled: str.length > 1 ? `${v.topic}.${p}` : undefined,
    };
    return {
      id: uuid(),
      showTwo: str.length > 1,
      form,
    };
  }

  checkForm(form) {
    return new Promise(resolve => {
      this.$refs[form]?.forEach(async v => {
        try {
          await v.validate();
          resolve(true);
        } catch {
          resolve(false);
          return false;
        }
      });
    });
  }
  checkFormList() {
    return new Promise(resolve => {
      this.$refs.propertyForm?.forEach(async v => {
        try {
          const a = await v.checkForm();
          resolve(a);
        } catch {
          return false;
        }
      });
    });
  }

  async getValue() {
    await new Promise((resolve, reject) => {
      if (!this.propertyList.length) {
        this.$message.error('至少选择一个主题字段');
        reject(false);
      } else {
        resolve(true);
      }
    });
    const a = await this.checkForm('listForm');
    let b = false;
    if (a) {
      b = await this.checkFormList();
    }
    return new Promise((resolve, reject) => {
      if (a && b) {
        const arr = this.changeList();
        resolve({
          data: arr,
          createTime: this.form.createTime,
        });
      } else {
        reject();
      }
    });
  }

  changeList() {
    if (!this.propertyList.length) {
      return [];
    } else {
      const arr = [];
      this.propertyList.forEach(v => {
        v.form.propertyData.forEach(p => {
          const o = {
            source: v.pkId,
            dataSources: v.dataSources,
            topic: p.form.theme,
            connector: v.connector,
            panelFields: [],
          };
          if (p.form.cfiled) {
            const str = p.form.cfiled.split('.');
            o.panelFields.push(`${str[1]}.${str[2]}`);
          } else {
            const str = p.form.pfiled.split('.');
            o.panelFields.push(str[1]);
          }
          arr.push(o);
        });
      });
      return arr;
    }
  }
}
</script>

<style lang="less" module>
.pseting {
  .select {
    min-height: 100px;
    margin-top: 10px;

    .itemDiv {
      display: flex;
      border: 1px solid var(--border);
      height: 40px;
      align-items: center;
      border-radius: 4px;
      padding: 0 5px;
      margin-bottom: 10px;
      cursor: pointer;
      margin-right: 10px;

      .item {
        width: 180px;
        display: flex;
        align-items: center;
      }

      &:hover {
        border: 1px solid var(--primary-40);
      }
    }

    .checked {
      border: 1px solid var(--primary);

      &:hover {
        border: 1px solid var(--primary);
      }
    }

    .activeTab {
      border: 1px solid var(--primary-60);
    }

    .right {
      .theme {
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;

        .itemTag {
          max-width: 100px;
          min-width: 60px;
          height: 30px;
          border: 1px solid var(--border);
          background: var(--nav-bg);
          line-height: 30px;
          padding: 0 5px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
          margin-bottom: 5px;

          &:hover {
            border: 1px solid var(--primary-40);
          }
        }

        .activeTag {
          color: var(--font-active);
          background-color: var(--inline-menu-active);
          border-color: var(--primary);

          &:hover {
            border: 1px solid var(--primary);
          }
        }
      }

      .fileds {
        .filedItem {
          .itemDiv {
            height: 35px;
            width: 200px;
            margin-right: 5px;

            .item {
              width: 160px;
            }
          }
        }
      }
    }

    .card {
      border: 1px solid var(--border);
      border-radius: 5px;
      height: 300px;
      overflow-y: auto;
      padding: 10px;
    }

    .textOverflow {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .add {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    margin: 10px 0;
  }

  .themeColor {
    color: var(--primary);
  }

  .deleteColor {
    color: var(--delete);
  }

  .delete {
    cursor: pointer;

    &:hover {
      .deleteColor;
    }
  }

  :global {
    .ant-collapse {
      border-color: var(--primary-50);
      background-color: var(--primary);
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color: #fff;
      padding: 5px 5px 5px 40px;
    }

    .ant-select-selection {
      background-color: var(--block-bg);
    }
    .x-collapse-panel {
      overflow: unset;
    }
    .x-collapse-panel--header-left {
      display: flex;
      align-items: center;
    }
  }
}
</style>
