import { getControl, ControlTypeEnum } from './form/controls';

export default [
  {
    // 物模板数据
    title: 'controls.subCategory.layout',
    list: [
      getControl(ControlTypeEnum.Tabs),
      getControl(ControlTypeEnum.CurrentDataSet),
      getControl(ControlTypeEnum.AddDataSet),
      getControl(ControlTypeEnum.SyncInterface),
    ],
  },
  {
    // 通用数据
    title: 'controls.subCategory.base',
    list: [
      getControl(ControlTypeEnum.Int),
      getControl(ControlTypeEnum.Float),
      getControl(ControlTypeEnum.Double),
      getControl(ControlTypeEnum.Input),
      getControl(ControlTypeEnum.Date),
      getControl(ControlTypeEnum.Switch),
      getControl(ControlTypeEnum.Struct),
      getControl(ControlTypeEnum.Array),
      getControl(ControlTypeEnum.Enum),
      getControl(ControlTypeEnum.Image),
      getControl(ControlTypeEnum.File),
      getControl(ControlTypeEnum.DataPoint),
    ],
  },
];
