import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { FormatContext } from '../controls';
import { FormatContextSymbol } from '../controls/symbol';

/**
 * @template T
 * @param { MapOrGetFn<T> } fn
 * @param { string } key
 * @returns { T }
 */
const fnGet = (fn, key) => (typeof fn === 'function' ? fn(key) : fn[key]);

@Component()
export default class DataFormTitleRender extends Vue {
  @Prop() dataTitle;
  @Prop() getField;
  @Prop() getValue;
  @Prop({ default: 'span' }) component;
  @Inject({ from: FormatContextSymbol, default: () => new FormatContext() })
  formatContext;

  get title() {
    return this.generateDataTitleSync(
      this.dataTitle,
      this.innerGetField,
      this.getValue,
      this.formatContext,
    );
  }

  innerGetField(fieldId) {
    return fnGet(this.getField, fieldId);
  }

  generateDataTitleSync(dataTitle, getField, getValue, formatContext) {
    if (!dataTitle) return '';
    if (!/##(.*?)\$\$/.test(dataTitle)) return dataTitle;
    return dataTitle.replace(/##(.*?)\$\$/g, (_, fieldId) => {
      const field = fnGet(getField, fieldId);
      if (!field) return '';
      return formatContext.formatSync(fnGet(getValue, fieldId), field);
    });
  }

  render() {
    return this.$createElement(
      this.component,
      { attrs: { ...this.$attrs, title: this.title } },
      this.title,
    );
  }
}
