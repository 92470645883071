<template>
  <div :class="$style.container">
    <router-view></router-view>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
@Component()
export default class SmartHat extends Vue {}
</script>
<style lang="less" module>
.container {
  height: 100%;
}
</style>
