<template>
  <div ref="CountSortCharts" />
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { globalVueI18n } from '@triascloud/i18n';
import ResizeObserver from 'resize-observer-polyfill';
import { crossStorageModule } from '@/enum/store';

@Component({
  components: {},
})
export default class CountSortCharts extends Vue {
  @Prop({ type: Object }) data;
  /** @type { ResizeObserver } */
  _ob = null;
  myChart = null;
  @crossStorageModule.State skin;

  mounted() {
    this._ob = new ResizeObserver(this.chartResize);
    this._ob.observe(this.$refs.CountSortCharts);
    this.initChart();
  }
  chartResize() {
    this.myChart.resize();
  }
  get option() {
    const res =
      this.data && this.data.solutionCountDetailList
        ? this.data.solutionCountDetailList
        : [];
    return {
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          return [
            params[0].name + '<br/>',
            globalVueI18n.translate('path.market./') +
              ': ' +
              params[0].value +
              '&nbsp' +
              '<br/>',
            globalVueI18n.translate('path.market.form') +
              ': ' +
              params[1].value +
              '<br/>',
            '打印模板' + ': ' + params[2].value + '<br/>',
          ].join('');
        },
      },
      grid: {
        left: '5%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      legend: {
        data: [
          this.$t('path.market./'),
          this.$t('path.market.form'),
          '打印模板',
        ],
        top: 25,
        right: 10,
        itemWidth: 8,
        itemHeight: 8,
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },
      title: {
        subtext: this.$t('market.statistics.contribution'),
        top: 15,
        left: -5,
      },
      xAxis: [
        {
          type: 'category',
          data: res.length ? res.map(item => item.tenantName) : [],
          inside: true,
          showMaxLabel: true,
          axisLabel: {
            width: 170,
            rotate: 45,
            formatter: function(value) {
              if (value.length >= 5) {
                let v = value.slice(0, 5) + '...';
                return v;
              } else {
                return value;
              }
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: globalVueI18n.translate('path.market./'),
          type: 'bar',
          barGap: 0,
          barWidth: 20,
          color: ['#78B7FF'],
          data: res.length
            ? res.map(item => ({
                value: item.appTemplateCount,
              }))
            : [],
        },
        {
          name: globalVueI18n.translate('path.market.form'),
          type: 'bar',
          barGap: 0,
          barWidth: 20,
          color: ['#5BCC75'],
          tooltip: {
            valueFormatter: function(value) {
              return value;
            },
          },
          data: res.length
            ? res.map(item => ({
                value: item.formTemplateCount,
              }))
            : [],
        },
        {
          name: '打印模板',
          type: 'bar',
          barGap: 0,
          barWidth: 20,
          color: ['#f99604'],
          tooltip: {
            valueFormatter: function(value) {
              return value;
            },
          },
          data: res.length
            ? res.map(item => ({
                value: item.printTemplateCount,
              }))
            : [],
        },
      ],
    };
  }
  @Watch('option')
  @Watch('skin', { deep: true })
  initChart() {
    const chart = this.$refs.CountSortCharts;
    this.myChart = echarts.init(chart);
    this.myChart.setOption(this.option);
  }
}
</script>
<style lang="less" module></style>
