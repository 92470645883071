<template>
  <a-tabs v-model="activeTab" :class="$style.tabs">
    <a-tab-pane :tab="$t('path.website./')" key="statistics" force-render>
      <a-form-model ref="statistics" :model="formData.statistics">
        <a-form-model-item
          v-for="(item, index) in formData.statistics.options"
          :key="index"
          :prop="`options.${index}`"
          :class="$style.statistics"
        >
          <a-form-model-item
            :prop="`options.${index}.image`"
            :rules="createRequiredRule($t('website.homePage.icon'))"
          >
            <div
              :class="[$style.image, $style.statistics__image]"
              @click="selectStatisticsImage(index)"
            >
              <x-oss-image
                v-if="item.image"
                :oss-path="item.image"
                size="100"
              />
              <x-icon v-else type="plus" />
            </div>
          </a-form-model-item>
          <a-form-model-item
            :prop="`options.${index}.title`"
            :rules="createRequiredRule($t('common.title'))"
          >
            <a-input v-model="item.title" />
          </a-form-model-item>
          <a-form-model-item
            :prop="`options.${index}.desc`"
            :rules="createRequiredRule($t('solution.quantity'))"
          >
            <a-input v-model="item.desc" />
          </a-form-model-item>
        </a-form-model-item>
        <a-form-item :label="$t('website.homePage.hideOrNot')" prop="on">
          <a-radio
            :checked="formData.statistics.on"
            @click="formData.statistics.on = true"
            >{{ $t('website.homePage.open') }}</a-radio
          >
          <a-radio
            :checked="!formData.statistics.on"
            @click="formData.statistics.on = false"
            >{{ $t('website.homePage.hide') }}</a-radio
          >
        </a-form-item>
      </a-form-model>
    </a-tab-pane>
    <a-tab-pane :tab="$t('solution.solutionTlt')" key="solution" force-render>
      <a-form-model ref="solution" :model="formData.solution">
        <a-form-model-item
          v-for="(item, index) in formData.solution.options"
          :key="index"
          :prop="`options.${index}`"
          :class="$style.solution"
        >
          <div :class="$style.solution__title">
            {{ $t('website.homePage.program') }}{{ index + 1 }}
            <x-icon
              :class="$style.solution__icon"
              type="tc-icon-delete"
              hover
              @click="formData.solution.options.splice(index, 1)"
            />
          </div>
          <a-form-model-item
            :prop="`options.${index}.title`"
            :rules="createRequiredRule($t('website.homePage.name'))"
          >
            <a-input v-model="item.title" />
          </a-form-model-item>
          <a-form-model-item
            :prop="`options.${index}.titleDesc`"
            :rules="createRequiredRule($t('website.homePage.titleDescription'))"
          >
            <a-input v-model="item.titleDesc" />
          </a-form-model-item>
          <a-form-model-item
            :prop="`options.${index}.image`"
            :rules="createRequiredRule($t('website.homePage.cover'))"
          >
            <div
              :class="[$style.image, $style.solution__image]"
              @click="selectSolutionImage(index)"
            >
              <x-oss-image
                v-if="item.image"
                :oss-path="item.image"
                :resize="{ l: 540, m: 'lfit' }"
              />
              <x-icon v-else type="plus" />
            </div>
          </a-form-model-item>
          <div :class="$style.uploadTip">
            {{ $t('website.homePage.uploadTip') }}
          </div>
          <a-form-model-item
            :prop="`options.${index}.subTitle`"
            :rules="createRequiredRule($t('controls.label.title'))"
          >
            <a-input v-model="item.subTitle" />
          </a-form-model-item>
          <a-form-model-item
            :prop="`options.${index}.desc`"
            :rules="createRequiredRule($t('website.homePage.describe'))"
          >
            <a-textarea v-model="item.desc" />
          </a-form-model-item>
          <a-form-model-item :prop="`options.${index}.video`">
            <a-input v-model="item.video" :class="$style.solution__video">
              <x-upload-button
                slot="suffix"
                accept="video/*"
                :maxFileSize="200"
                @success="({ ossData }) => (item.video = ossData.ossPath)"
              >
                <a-button type="link" :class="$style.solution__upload">{{
                  $t('website.homePage.uploadVideo')
                }}</a-button>
              </x-upload-button>
            </a-input>
          </a-form-model-item>
        </a-form-model-item>
        <div>
          <a-button type="link" @click="addSolution">
            <x-icon type="plus" />{{ $t('website.homePage.addPlan') }}
          </a-button>
        </div>
        <a-form-item :label="$t('website.homePage.hideOrNot')" prop="on">
          <a-radio
            :checked="formData.solution.on"
            @click="formData.solution.on = true"
            >{{ $t('website.homePage.open') }}</a-radio
          >
          <a-radio
            :checked="!formData.solution.on"
            @click="formData.solution.on = false"
            >{{ $t('website.homePage.hide') }}</a-radio
          >
        </a-form-item>
      </a-form-model>
    </a-tab-pane>
    <a-tab-pane
      :tab="$t('website.homePage.boutiqueCase')"
      key="case"
      force-render
    >
      <a-form-model ref="case" :model="formData.case">
        <a-form-model-item
          v-for="(item, index) in formData.case.options"
          :key="index"
          :prop="`options.${index}`"
          :class="$style.solution"
        >
          <div :class="$style.solution__title">
            {{ $t('website.homePage.case') }}{{ index + 1 }}
            <x-icon
              :class="$style.solution__icon"
              type="tc-icon-delete"
              hover
              @click="formData.case.options.splice(index, 1)"
            />
          </div>
          <a-form-model-item
            :prop="`options.${index}.title`"
            :rules="createRequiredRule($t('common.title'))"
          >
            <a-input v-model="item.title" />
          </a-form-model-item>
          <a-form-model-item
            :prop="`options.${index}.image`"
            :rules="createRequiredRule($t('website.homePage.cover'))"
          >
            <div
              :class="[$style.image, $style.solution__image]"
              @click="selectCaseImage(index)"
            >
              <x-oss-image
                v-if="item.image"
                :oss-path="item.image"
                :resize="{ l: 540, m: 'lfit' }"
              />
              <x-icon v-else type="plus" />
            </div>
          </a-form-model-item>
          <div :class="$style.uploadTip">
            {{ $t('website.homePage.uploadTip') }}
          </div>
          <a-row type="flex" align="middle">
            <a-form-model-item
              :prop="`options.${index}.avatar`"
              :rules="createRequiredRule($t('website.homePage.icon'))"
            >
              <div
                :class="[$style.image, $style.case__avatar]"
                @click="selectCaseImage(index, 'avatar')"
              >
                <x-oss-image
                  v-if="item.avatar"
                  :oss-path="item.avatar"
                  size="540"
                  @load="$refs.case.validateField(`options.${index}.avatar`)"
                />
                <x-icon v-else type="plus" />
              </div>
            </a-form-model-item>
            <a-form-model-item
              :prop="`options.${index}.company`"
              :rules="createRequiredRule($t('website.homePage.companyName'))"
            >
              <a-input
                v-model="item.company"
                :class="$style.case__company"
                @blur="$refs.case.validateField(`options.${index}.company`)"
              />
            </a-form-model-item>
          </a-row>
        </a-form-model-item>
        <div>
          <a-button type="link" @click="addCase">
            <x-icon type="plus" />{{ $t('website.case.add') }}
          </a-button>
        </div>
        <a-form-item :label="$t('website.homePage.hideOrNot')" prop="on">
          <a-radio
            :checked="formData.case.on"
            @click="formData.case.on = true"
            >{{ $t('website.homePage.open') }}</a-radio
          >
          <a-radio
            :checked="!formData.case.on"
            @click="formData.case.on = false"
            >{{ $t('website.homePage.hide') }}</a-radio
          >
        </a-form-item>
      </a-form-model>
    </a-tab-pane>
    <a-tab-pane
      :tab="$t('website.homePage.partner')"
      key="partner"
      force-render
    >
      <a-form-model ref="partner" :model="formData.partner">
        <a-form-model-item
          v-for="(item, index) in formData.partner.options"
          :key="index"
          :prop="`options.${index}`"
          :class="$style.partner"
        >
          <div
            :class="[$style.image, $style.partner__image]"
            @click="selectPartnerImage(index)"
          >
            <x-oss-image
              :oss-path="item.image"
              :resize="{ l: 540, m: 'lfit' }"
            />
            <x-icon
              type="tc-icon-delete"
              :class="$style.partner__icon"
              hover
              @click.stop="formData.partner.options.splice(index, 1)"
            />
          </div>
        </a-form-model-item>
        <div :class="$style.uploadTip">
          {{ $t('website.homePage.uploadTip') }}
        </div>
        <div>
          <a-button type="link" @click="addPartner">
            <x-icon type="plus" />{{ $t('website.homePage.addPartner') }}
          </a-button>
        </div>
        <a-form-item :label="$t('website.homePage.hideOrNot')" prop="on">
          <a-radio
            :checked="formData.partner.on"
            @click="formData.partner.on = true"
            >{{ $t('website.homePage.open') }}</a-radio
          >
          <a-radio
            :checked="!formData.partner.on"
            @click="formData.partner.on = false"
            >{{ $t('website.homePage.hide') }}</a-radio
          >
        </a-form-item>
      </a-form-model>
    </a-tab-pane>
    <async-button
      v-if="$p.action('UPDATE')"
      type="primary"
      slot="tabBarExtraContent"
      :click="save"
    >
      {{ $t('activity.modify') }}
    </async-button>
  </a-tabs>
</template>
<script>
import { Component, Vue, Provide } from 'vue-property-decorator';
import { getConfig, saveConfig } from '@/services/configuration/homePage';
import AsyncButton from '@/components/async-button';
import { ImageSelect, UploadPathSymbol } from '@triascloud/x-components';
import { createModuleUrl } from '@/utils/createModuleUrl';

@Component({
  components: { AsyncButton },
})
export default class Home extends Vue {
  @Provide(UploadPathSymbol) uploadPath = 'website/setting';
  uploadPath = 'website/setting';
  activeTab = 'statistics';
  formData = {
    statistics: {
      options: Array(4)
        .fill()
        .map(() => ({
          image: '',
          title: '',
          desc: '',
        })),
      on: true,
    },
    solution: {
      options: [],
      on: true,
    },
    case: {
      options: [],
      on: true,
    },
    partner: {
      options: [],
      on: true,
    },
  };
  createRequiredRule(name) {
    return {
      validator: (_, value, callback) => {
        if (!value) {
          callback(name + this.$t('connector.dataType.rule.notempty'));
        } else {
          callback();
        }
      },
    };
  }
  openImageSelect() {
    return new Promise(resolve => {
      const drawer = ImageSelect.createDrawer({
        props: {
          signalUse: false,
          uploadPath: this.uploadPath,
          qrCode: createModuleUrl('m'),
        },
        on: {
          change: data => {
            resolve(data.ossPath);
            drawer.visible = false;
          },
        },
      });
    });
  }
  async selectStatisticsImage(index) {
    const image = await this.openImageSelect();
    this.formData.statistics.options[index].image = image;
    this.$refs.statistics.clearValidate(`options.${index}.image`);
  }
  async selectSolutionImage(index) {
    const image = await this.openImageSelect();
    this.formData.solution.options[index].image = image;
    this.$refs.solution.clearValidate(`options.${index}.image`);
  }
  async selectCaseImage(index, avatar) {
    const image = await this.openImageSelect();
    if (avatar) {
      this.$set(this.formData.case.options[index], 'avatar', image);
    } else {
      this.$set(this.formData.case.options[index], 'image', image);
      this.$refs.case.clearValidate(`options.${index}.image`);
    }
  }
  async selectPartnerImage(index) {
    const image = await this.openImageSelect();
    this.formData.partner.options[index].image = image;
    this.$refs.partner.clearValidate(`options.${index}.image`);
  }
  addSolution() {
    this.formData.solution.options.push({
      title: '',
      titleDesc: '',
      image: '',
      subTitle: '',
      desc: '',
      video: '',
    });
  }
  addCase() {
    this.formData.case.options.push({
      title: '',
      image: '',
      avatar: '',
      company: '',
    });
  }
  async addPartner() {
    const image = await this.openImageSelect();
    this.formData.partner.options.push({ image });
  }
  async save() {
    await this.$refs.statistics.validate().catch(e => {
      this.activeTab = 'statistics';
      return Promise.reject(e);
    });
    await this.$refs.solution.validate().catch(e => {
      this.activeTab = 'solution';
      return Promise.reject(e);
    });
    await this.$refs.case.validate().catch(e => {
      this.activeTab = 'case';
      return Promise.reject(e);
    });
    await saveConfig(this.formData);
    this.$message.success(this.$t('common.tips.save'));
  }
  async created() {
    const config = await getConfig();
    if (config && config.setting) {
      this.formData = JSON.parse(config.setting);
    }
  }
}
</script>
<style lang="less" module>
.tabs {
  padding-right: 10px;
  height: 100%;
  :global(.ant-tabs-bar) {
    border-bottom: 1px solid var(--form-border);
    margin-bottom: 30px;
  }

  :global {
    .ant-tabs-bottom-content,
    .ant-tabs-top-content {
      height: calc(100% - 64px);
      .ant-tabs-tabpane {
        height: 100%;
        overflow: auto;
      }
    }
  }
}
.image {
  width: 100px;
  height: 100px;
  border: 1px solid var(--form-border);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  font-size: 30px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.uploadTip {
  color: var(--font-info);
  line-height: 1.4;
  margin-bottom: 16px;
}
.statistics {
  width: calc(25% - 15px);
  display: inline-block;
  margin-right: 20px;
  max-width: 400px;
  &:nth-child(4) {
    margin-right: 0;
  }
  .statistics__image {
    width: 100px;
    height: 100px;
  }
}
.solution {
  width: calc(33.33% - 20px);
  margin-right: 20px;
  display: inline-block;
  max-width: 537px;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .solution__title {
    color: var(--font-info);
    .solution__icon {
      margin-left: 10px;
    }
  }
  .solution__image {
    width: 100%;
    height: 370px;
  }
  .solution__video :global(.ant-input) {
    padding-right: 80px;
  }
  .solution__upload {
    height: 100%;
    min-width: 60px;
    padding: 0;
  }
}
.partner {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-right: 0;
  }
  .partner__image {
    position: relative;
    width: 218px;
    height: 87px;
    border-radius: 4px;
    border: 1px solid var(--form-border);
    &:hover .partner__icon {
      display: block;
    }
  }
  .partner__icon {
    position: absolute;
    display: none;
    top: 4px;
    right: 4px;
    font-size: 18px;
  }
}
.case__avatar {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  font-size: 20px;
}
.case__company {
  flex: 1;
}
</style>
