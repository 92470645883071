<template>
  <a-form-model-item :label="$t('controls.config.required')">
    <a-switch
      :checked="field.widget.required"
      @change="updateRequired($event)"
    />
  </a-form-model-item>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class Required extends Vue {
  @Prop() field;
  @Prop() updateField;

  updateRequired(required) {
    this.updateField(field => {
      field.widget.view = false;
      field.widget.required = required;
      return field;
    });
  }
}
</script>
