<template>
  <div :class="$style.overViewCtrl">
    <charts-view :data="data"></charts-view>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import ChartsView from '@/views/plugin/overview/components/charts-view.vue';
import { getOverViewList } from '@/services/template-manager/plugin';

@Component({
  components: { ChartsView },
})
export default class Overview extends Vue {
  data = [];

  async created() {
    const {
      dbApiStatistics,
      dbServiceStatistics,
      idcStatistics,
    } = await getOverViewList();
    this.data = [
      {
        ...idcStatistics,
        title: this.$t('plugin.snms.name'),
        link: '/plugin/snms',
        color: '#2887FF',
      },
      {
        ...dbServiceStatistics,
        title: this.$t('plugin.overview.Database'),
        link: '/plugin/data',
        color: '#FF8128',
      },
      {
        ...dbApiStatistics,
        title: this.$t('plugin.overview.apicpt'),
        link: '/plugin/api',
        color: '#FFA91B',
      },
    ].filter(item => this.$p(item.link));
  }
}
</script>
<style lang="less" module>
.overViewCtrl {
  padding: 20px;
  min-width: 1650px;
  width: 100%;
}
</style>
