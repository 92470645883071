import { ControlTypeEnum } from '../data-template/template-design/form/controls';

/**
 * @description 设置 val JSONpath 语法
 * @param {*} list
 * @param {*} identifier 标识符
 */
export const eachJsonPath = (list, identifier) => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return;
  }

  for (let child of list) {
    let str = '';
    if (identifier) {
      str = `${identifier}.${child.identifier}`;
    } else {
      str = `${child.identifier}`;
    }
    if (child.type === ControlTypeEnum.Array) {
      child.val = `$.${str}[*]`;
    } else {
      child.val = `$.${str}`;
    }
    if (child.children && child.children.length > 0) {
      eachJsonPath(child.children, str);
    }
  }
};

/** @name 删除parent属性 */
export const eachDelParent = list => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return false;
  }
  for (let child of list) {
    if (child.parent) {
      delete child.parent;
    }
    if (child.children && child.children.length > 0) {
      eachDelParent(child.children);
    }
  }
};

/** @name 获取数据所有id的val */
export const flatFieldsId = (fields, result) => {
  if (!(Array.isArray(fields) && fields.length > 0)) {
    return {};
  }
  for (let field of fields) {
    result[field.id] = field.val;
    // 1、结构体 2、DP点 3、DP点下面的结构体 4、数组结构体 5、DP点下面的数组结构体
    // 获取他们的children
    if (field.children && field.children.length > 0) {
      flatFieldsId(field.children, result);
    }
  }
  return result;
};

/** @name 设置jsonPath */
export const eachSetJsonPath = (list, store) => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return;
  }
  for (let child of list) {
    if (store.get(child.id) && store.get(child.id).includes('$.')) {
      child.val = store.get(child.id);
    }
    if (child.children && child.children.length > 0) {
      eachSetJsonPath(child.children, store);
    }
  }
};

/** @name 给Store设置jsonPath */
export const eachSetJsonPathForStore = (list, store) => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return;
  }
  for (let child of list) {
    if (child.val && store.get(child.id) && child.val.includes('$.')) {
      store.set(child.id, child.val);
    }
    if (child.children && child.children.length > 0) {
      eachSetJsonPathForStore(child.children, store);
    }
  }
};
