<template>
  <div>
    <div>已选中：{{ awards.length }}</div>
    <a-select v-model="isWin" :class="$style.select">
      <a-select-option
        v-for="item in templateTypes"
        :key="item.value"
        :value="item.value"
        >{{ item.text }}</a-select-option
      >
    </a-select>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AwardTypeText, AwardType } from '@/enum/activity';
import { createFormModal } from '@triascloud/x-components';
import { batchSetLucky } from '@/services/marketing/invite-code';
function createBatchSetAwardModal(props) {
  return createFormModal(h => h(BatchSetAwardModal, { props }), {
    title: '批量设置中奖',
    width: 500,
  });
}
@Component
export default class BatchSetAwardModal extends Vue {
  static createModal = createBatchSetAwardModal;
  @Prop({ required: true, type: Array }) awards;
  isWin = 0;
  get templateTypes() {
    return [
      {
        text: this.$t(AwardTypeText[AwardType.Null_Award]),
        value: AwardType.Null_Award,
      },
      {
        text: this.$t(AwardTypeText[AwardType.Special_Award]),
        value: AwardType.Special_Award,
      },
      {
        text: this.$t(AwardTypeText[AwardType.First_Award]),
        value: AwardType.First_Award,
      },
      {
        text: this.$t(AwardTypeText[AwardType.Second_Award]),
        value: AwardType.Second_Award,
      },
      {
        text: this.$t(AwardTypeText[AwardType.Third_Award]),
        value: AwardType.Third_Award,
      },
      {
        text: this.$t(AwardTypeText[AwardType.Mystery_Award]),
        value: AwardType.Mystery_Award,
      },
    ];
  }
  async getValue() {
    await this.$confirm(
      `确认批量设置选中抽奖码为${this.$t(AwardTypeText[this.isWin])}吗？`,
    );
    await batchSetLucky(this.awards, this.isWin);
    this.$message.success('设置成功');
  }
}
</script>
<style module lang="less">
.select {
  width: 100%;
  margin-top: 20px;
}
</style>
