export function validateInput(rule, value, callback) {
  if (rule.field === 'paramTag') {
    if (this.form.paramTag) {
      if (
        this.existedTags &&
        this.existedTags.length &&
        this.existedTags.indexOf(this.form.paramTag) > -1
      ) {
        callback(new Error('参数标识符已存在'));
      } else {
        callback();
      }
    } else {
      callback(new Error('请输入参数标识符'));
    }
  } else if (rule.field === 'paramName') {
    if (this.form.paramName) {
      callback();
    } else {
      callback(new Error('请输入参数名称'));
    }
  } else if (rule.field === 'dataType') {
    if (this.form.dataType) {
      callback();
    } else {
      callback(new Error('请选择数据类型'));
    }
  } else if (rule.field === 'dataSpecs-number') {
    const min = this.form.dataSpecs.number.min;
    const max = this.form.dataSpecs.number.max;
    if ((min || min === 0) && (max || max === 0) && min > max) {
      callback(new Error('最小值不能大于最大值'));
    } else {
      callback();
    }
  } else if (rule.field === 'dataSpecs-boolean-0') {
    if (this.form.dataSpecs.boolean[0]) {
      callback();
    } else {
      callback(new Error('请输入数值描述'));
    }
  } else if (rule.field === 'dataSpecs-boolean-1') {
    if (this.form.dataSpecs.boolean[1]) {
      callback();
    } else {
      callback(new Error('请输入数值描述'));
    }
  } else if (rule.field === 'dataSpecs-enum-length') {
    if (this.form.dataSpecs.enum.length) {
      callback();
    } else {
      callback(new Error('请添加枚举项'));
    }
  } else if (rule.field.indexOf('dataSpecs-enum-key') > -1) {
    let index = rule.field.split('-').pop();
    if (this.form.dataSpecs.enum[index].key) {
      callback();
    } else {
      callback(new Error('请输入描述值'));
    }
  } else if (rule.field.indexOf('dataSpecs-enum-value') > -1) {
    let index = Number(rule.field.split('-').reverse()[0]);
    let itemVal = this.form.dataSpecs.enum[index].value;
    let sameValIndex = -1;
    if (itemVal || itemVal === 0) {
      this.form.dataSpecs.enum.map((item, idx) => {
        if (idx !== index && item.value === itemVal) sameValIndex = idx;
      });
      if (sameValIndex === -1) {
        callback();
      } else {
        callback(new Error('参数值不能重复'));
      }
    } else {
      callback(new Error('请输入参数值'));
    }
  } else if (rule.field === 'dataSpecs-struct') {
    if (this.form.dataSpecs.struct.length) {
      callback();
    } else {
      callback(new Error('请至少新增一个对象'));
    }
  } else if (rule.field === 'dataSpecs-array-count') {
    if (this.form.dataSpecs.array.count) {
      callback();
    } else {
      callback(new Error('请输入元素个数'));
    }
  } else if (rule.field === 'dataSpecs-array-list') {
    if (this.form.dataSpecs.array.list.length) {
      callback();
    } else {
      callback(new Error('请至少新增一个对象'));
    }
  }
}
