<template>
  <i
    :class="[$style.icon, icon.colorful ? $style.colorful : $style.common]"
    v-html="icon.showSvg"
  ></i>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component()
export default class Icon extends Vue {
  @Prop() icon;
}
</script>
<style lang="less" module>
.icon {
  font-size: 30px;
}
.colorful {
}
.common {
  svg {
    > path {
      fill: var(--font-sub);
    }
  }
}
</style>
