<template>
  <a-form-model ref="form" :model="form" :rules="rules">
    <a-form-model-item :label="$t('website.setting.type')" prop="type">
      <a-radio-group v-model="form.type" @change="clearValue">
        <a-radio
          v-for="item in fileType"
          :key="item.value"
          :value="item.value"
          >{{ item.name }}</a-radio
        >
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item :label="$t('website.setting.variableTitle')" prop="name">
      <a-input v-model="form.name" />
    </a-form-model-item>
    <a-form-model-item :label="$t('website.setting.variableKey')" prop="key">
      <a-input v-model="form.key" />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('website.setting.variableValue')"
      prop="value"
    >
      <field-render v-model="form.value" :type="form.type" />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { globalVueI18n } from '@triascloud/i18n';
import { ensureArray } from '@triascloud/utils';
import { createFormModal } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
import FieldRender, { FieldType } from './field-render.vue';
function createAddFieldModal(setting) {
  return createFormModal(h => h(AddFieldModal, { props: { setting } }), {
    title:
      globalVueI18n.translate('common.action.add') +
      globalVueI18n.translate('website.setting.variable'),
    width: 510,
  });
}

@Component({
  components: { FieldRender },
})
export default class AddFieldModal extends Vue {
  @Prop() setting;
  static createModal = createAddFieldModal;
  form = {
    name: '',
    key: '',
    value: null,
    type: 'text',
  };
  get fileType() {
    return [
      {
        name: this.$t('controls.input.label'),
        value: FieldType.Text,
      },
      {
        name: this.$t('common.img'),
        value: FieldType.Image,
      },
      {
        name: this.$t('feedback.annex'),
        value: FieldType.File,
      },
    ];
  }
  get rules() {
    return {
      key: [
        {
          required: true,
          message:
            this.$t('common.placeholder.input') +
            this.$t('website.setting.variableKey'),
        },
        {
          validator: (_, value, callback) => {
            ensureArray(this.setting).some(item => item.key === value)
              ? callback(
                  this.$t('common.tips.repeat', {
                    value: this.$t('website.setting.variableKey'),
                  }),
                )
              : callback();
          },
        },
      ],
      name: [
        {
          required: true,
          message:
            this.$t('common.placeholder.input') +
            this.$t('website.setting.variableTitle'),
        },
        {
          validator: (_, value, callback) => {
            ensureArray(this.setting).some(item => item.name === value)
              ? callback(
                  this.$t('common.tips.repeat', {
                    value: this.$t('website.setting.variableTitle'),
                  }),
                )
              : callback();
          },
        },
      ],
    };
  }
  clearValue() {
    this.form.value = null;
  }
  async getValue() {
    await this.$refs.form.validate();
    return this.form;
  }
}
</script>
<style lang="less" module></style>
