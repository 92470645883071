<template>
  <div :class="$style.box">
    <a-form-model-item label="绑定接口">
      <a-select disabled>
        <a-select-option key="1" value="1">同步API</a-select-option>
      </a-select>
    </a-form-model-item>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';

@Component()
export default class Design extends Vue {}
</script>
<style lang="less" module>
.box {
  :global {
    .ant-form-item-control-wrapper {
      max-width: 400px;
    }
  }
}
</style>
