<template>
  <div v-if="!supportCurrentWindow" :class="$style.loading">
    <x-icon :class="$style.icon" type="loading" />
    <div>
      生成pdf文件中...
    </div>
  </div>
  <div
    v-else
    :class="[$style.wrap, type === 'modal' ? $style.modalWrap : null]"
  >
    <div :class="$style.header">
      打印设置预览
      <span :class="$style.close" @click="$emit('close')">退出预览</span>
    </div>
    <a-spin :spinning="loading" :class="$style.content" tip="生成pdf文件中...">
      <iframe :src="fileUrl" frameborder="0" :class="$style.iframe"></iframe>
    </a-spin>
  </div>
</template>
<script>
import { createDrawer, createModal } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';

function checkSupportPdfPreviewCurrentWindow() {
  return !/DingTalk/gi.test(navigator.userAgent);
}
const supportCurrentWindow = checkSupportPdfPreviewCurrentWindow();
function createPreviewDrawer(props) {
  if (!supportCurrentWindow) {
    const modal = createModal(
      h =>
        h(PreviewDrawer, {
          on: {
            close: () => (modal.visible = false),
          },
          props,
        }),
      {
        title: null,
        width: 470,
        closable: false,
        bodyStyle: { margin: 0 },
        maskClosable: false,
      },
    );
    return modal;
  } else {
    const drawer = createDrawer(
      h =>
        h(PreviewDrawer, {
          props,
          on: {
            close: () => (drawer.visible = false),
          },
        }),
      {
        headerStyle: { display: 'none' },
        bodyStyle: { padding: 0 },
        placement: 'bottom',
        width: '100vw',
        height: '100vh',
      },
    );
    return drawer;
  }
}
@Component()
export default class PreviewDrawer extends Vue {
  @Prop({ required: true, type: Function }) file;
  @Prop({ type: String }) type;

  supportCurrentWindow = supportCurrentWindow;
  static createDrawer = createPreviewDrawer;
  fileUrl = '';
  loading = true;
  async created() {
    this.fileUrl = await this.file();
    this.loading = false;
    if (!supportCurrentWindow) {
      window.open(this.fileUrl, '_blank');
      this.$emit('close');
    }
    // try {
    //   this.fileUrl = await this.file();
    // } catch (_) {
    //   this.$message.error('打印出错');
    //   this.$emit('close');
    // } finally {
    //   this.loading = false;
    // }
  }
}
</script>
<style lang="less" module>
.loading {
  text-align: center;
  font-size: 16px;
  color: var(--font);
  padding: 60px 0 50px;
  .icon {
    font-size: 80px;
    color: var(--primary);
    margin-bottom: 30px;
  }
}
.wrap {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  z-index: 99;
  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;
    .close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      color: var(--font-color-secondary);
      cursor: pointer;
    }
  }
  .header {
    background-color: #212121;
  }
  .toolbar {
    box-shadow: 0px 6px 13px 0px var(--shadow);
  }
  .content {
    text-align: center;
    flex: 1;
    :global .ant-spin-container {
      width: 100%;
      height: 100%;
    }
  }
  .iframe {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
.modalWrap {
  width: 690px;
  height: calc(100vh - 48px);
  .header {
    display: none;
  }
}
</style>
