<template>
  <div :class="$style.invitationCode">
    <div :class="$style.classList">
      <div :class="$style.addButton" v-if="$p.action('CREATE')">
        <a-button type="primary" @click="addCode" icon="plus">
          {{ $t('inviteCode.addCode') }}
        </a-button>
      </div>

      <div v-if="classData && classData.length">
        <div
          v-for="item in classData"
          :key="item.id"
          :class="[
            $style.item,
            item.classId === currentClass.classId ? $style.active : '',
            $p.action('CREATE') ? '' : $style.classItem,
          ]"
          @click="changeType(item)"
        >
          <x-ellipsis>
            {{ item.className }}
          </x-ellipsis>
        </div>
      </div>
      <empty-content v-else type="list" />
    </div>

    <div :class="$style.rightContent">
      <CodeParameters
        :currentClass="currentClass"
        @addSubmit="addSubmit"
        @editName="editName"
        ref="CodeParameters"
      />
      <invitationList
        ref="invitationTable"
        :class="$style.content"
        :classId="currentClass.classId"
        v-if="currentClass && currentClass.classId"
      />
      <empty-content v-else type="list" />
    </div>
    <a-spin :spinning="loading" />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import {
  getInviteTypeList,
  addInviteCode,
  editClassName,
} from '@/services/marketing/invite-code';
import EmptyContent from '@/components/empty-content';
import invitationList from './components/invitation-list.vue';
import CodeParameters from './components/code-parameters.vue';
import { deepClone } from '@triascloud/utils';

@Component({
  components: {
    invitationList,
    EmptyContent,
    CodeParameters,
  },
})
export default class EventInvitationCode extends Vue {
  classData = [];
  loading = false;
  currentClass = {
    classId: '',
    type: 3,
    className: '',
  };
  async mounted() {
    await this.getClassList();
    this.currentClass = deepClone(this.classData && this.classData[0]);
    if (!this.currentClass) {
      this.currentClass = {
        classId: '',
        type: '',
        codeCount: '',
        className: '',
      };
    }
  }
  async changeType(data) {
    this.$refs.CodeParameters.$refs.form.resetFields();

    this.currentClass = deepClone(data);
    await this.$nextTick();
    this.$refs.invitationTable.refreshData();
  }
  async getClassList() {
    this.classData = await getInviteTypeList();
  }

  addCode() {
    this.currentClass = {
      classId: '',
      className: '',
      type: 3,
      codeCount: 50,
    };
  }

  async editName(data) {
    this.loading = true;

    await editClassName({
      codeClassName: data.className,
      codeClassId: data.classId,
    });
    this.loading = false;

    this.classData.forEach(item => {
      if (item.classId === data.classId) {
        item.className = data.className;
      }
    });
  }

  async addSubmit(data) {
    await addInviteCode({
      className: data.className,
      codeCount: data.codeCount,
      type: data.type,
    });
    await this.getClassList();

    this.currentClass = deepClone(this.classData[this.classData.length - 1]);
    await this.$nextTick();
    this.$refs.invitationTable.refreshData();
  }
}
</script>
<style lang="less" module>
.invitationCode {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 100%;
  position: relative;
}
.rightContent {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
  }
}

.addButton {
  position: fixed;
  padding: 10px;
  width: 299px;
  background: var(--main-bg);
  button {
    width: 100%;
  }
}

.left_item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.left_box {
  padding: 9px 10px;
  border-radius: 6px;
  margin-left: 10px;
}
.left_border {
  border: 1px solid var(--border);
}

.item {
  height: 40px;
  padding: 0px 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:first-child {
    margin-top: 55px;
  }
}

.classItem {
  &:first-child {
    margin-top: 0px;
  }
}

.classList {
  width: 300px;
  border-right: 1px solid #eee;
  height: 100%;
  flex-shrink: 0;
  overflow: auto;
}
.active {
  background-color: var(--inline-menu-active);
  color: var(--font-active);
}
</style>
