<template>
  <a-config-provider :getPopupContainer="computedGetPopupContainer">
    <data-form-model-context
      ref="modelContext"
      :fieldMap="fieldMap"
      :contextData="contextData"
    >
      <a-form-model
        class="x-data-form"
        :class="computedBorderClass"
        :model="root.store.data"
        layout="vertical"
        ref="form"
      >
        <data-form-title-watcher
          :dataTitle="title"
          :defaultField="flattedFields[0]"
          :placeholder="$t('common.label.emptyForm')"
          @change="handleTitleChange"
          ref="title"
        />
        <component
          v-for="field in fields"
          :key="field.pkId"
          :is="getComponent(field)"
          :field="field"
        />
      </a-form-model>
    </data-form-model-context>
  </a-config-provider>
</template>
<script>
import { has, set } from 'lodash';
import { Component, Inject, Prop, Provide, Vue } from 'vue-property-decorator';
import {
  deepClone,
  ensureArray,
  generateMap,
  uuid,
  // delay,
} from '@triascloud/utils';
import { scrollIntoView } from '../../utils';
import {
  flatFields,
  getControl,
  getFieldDefaultValue,
  FormatContext,
  ControlTypeEnum,
} from '../controls';
import { DataFormContext, DataFormFilterType } from './context';
import ReactiveStore from './reactive-store';
import DataFormTitleWatcher from './title-watcher';
import DataFormItem from './data-form-item.vue';
import DataFormModelContext from './data-form-model-context';
import { FormatContextSymbol } from '../controls/symbol';
import { compare } from '../form-compare/compare';

@Component({
  components: { DataFormTitleWatcher, DataFormModelContext },
})
export default class DataForm extends Vue {
  /**
   * @name 字段列表
   * @type { Field[] }
   */
  @Prop({ type: Array, default: () => [] }) fields;
  /** @name 表单数据 */
  @Prop({ type: Object, default: () => ({}) }) data;
  /** @name 上下文数据 */
  @Prop() contextData;
  /** @name 是否查看模式 */
  @Prop({ type: Boolean, default: false }) view;
  /** @name 数据id */
  @Prop({ type: String, default: () => uuid() }) pkId;
  /**
   * @name 智能工具
   * @type { import('./tools').DataFormTool[] }
   */
  @Prop({ type: Array, default: () => [] }) tools;

  /**
   * @description 以下四个prop来自表单属性设置，必须填写
   */
  /** @name 表单提交时的验证规则 */
  @Prop({ type: Array, default: () => [] }) rules;
  /** @name 表单提交时的验证规则 */
  @Prop({ type: Array, default: () => [] }) relations;
  /** @name 数据标题 */
  @Prop({ type: String, default: '' }) title;

  /**
   * @name 字段展示过滤器
   * @description 用于抽屉操作中的字段隐藏功能
   */
  @Prop({ type: [Array, String], default: DataFormFilterType.All }) filter;
  /**
   * @name 字段过滤方法
   * @description 用于部分场景下需要筛选掉某些特定的字段类型
   */
  @Prop({ type: Function, default: () => () => true }) filterMethod;

  @Prop({ type: Object, default: () => null }) borderSetting;

  created() {
    this.initStore();
    this.initValidateContext();
  }

  @Inject({ from: DataFormContext.Root, default: null }) injectRoot;
  /** @name 注入root */
  @Provide(DataFormContext.Root)
  get root() {
    return this.injectRoot || this;
  }

  @Provide(DataFormContext.Model)
  get provideModel() {
    return this.injectRoot ? this.injectRoot.$refs.modelContext : null;
  }

  @Provide(DataFormContext.Path)
  @Prop({ type: Array, default: () => [] })
  rootPath;

  /** @name 注入字段组件 */
  @Provide(DataFormContext.Item)
  Item = DataFormItem;

  @Provide(FormatContextSymbol)
  formatContext = new FormatContext();

  /**
   * @name 数据仓库
   * @type { import('./reactive-store').default }
   * @description 使用下划线开头是为了不被vue加入引用收集
   */
  _store = null;
  get store() {
    return this._store;
  }

  get flattedFields() {
    return flatFields(this.fields, { param: true });
  }

  get fieldMap() {
    return generateMap(this.flattedFields, 'pkId', field => field);
  }

  /** @name 初始化数据仓库 */
  initStore() {
    let data = {
      ...this.data,
      ...generateMap(
        flatFields(this.fields, { parent: true, param: true }),
        'pkId',
        this.getFieldDefaultValue,
      ),
    };
    // 初始化表单数据
    data = ensureArray(this.tools).reduce(
      (data, tool) => (tool.initForm ? tool.initForm(data) : data),
      data,
    );
    this._store = new ReactiveStore(data);
  }

  /** @param { Field } field */
  getFieldDefaultValue(field) {
    if (field.pkId in this.data) return this.data[field.pkId];
    if (this.view) return null;
    return getFieldDefaultValue(field);
  }

  /** @name 获取字段的控件 */
  getComponent(field) {
    return getControl(field.type).LayoutComponent || DataFormItem;
  }

  /** @name 表单验证上下文 */
  validateContext = null;
  /** @name 初始化验证上下文 */
  initValidateContext() {
    this.validateContext = {
      /** @name 是否验证字段必填 */
      required: true,
    };
  }

  /** @name 验证表单提交时的验证规则 */
  validateRules() {
    if (!this.rules.length) return;
    // return Promise.all(
    //   this.rules.map(rule =>
    //     execFormula(rule.formula, this.$refs.modelContext).then(
    //       result => result || Promise.reject(new Error(rule.message)),
    //     ),
    //   ),
    // ).catch(error => {
    //   this.$message.error(error.message);
    //   return Promise.reject(error);
    // });
  }

  extraValidate = new Set();

  afterValidate() {
    return Promise.all(Array.from(this.extraValidate).map(fn => fn()));
  }

  async validate(context) {
    // context合并
    if (context) {
      this.validateContext = {
        ...this.validateContext,
        ...context,
      };
      // 等validateContext更新完毕
      await this.$nextTick();
    }
    try {
      // 先对字段进行验证
      await this.$refs.form.validate();
      // 再对表单进行验证
      await this.validateRules();
      // 校验后处理（流水号字段）
      await this.afterValidate();
      await this.$nextTick();
      // 重置验证上下文
      this.initValidateContext();
      // 触发标题更新
      await this.$refs.title.updateTitle(this.store.data);
      if (this.deletePathList.size) {
        return this.deletePath(this.store.data);
      } else {
        // return this.store.data;
        return this.formatData;
      }
    } catch (e) {
      const dom = this.$el.querySelector('.ant-form-item-control.has-error');
      if (dom) {
        const parent = dom.closest('.ant-tabs-tabpane-inactive');
        // 处理分页卡内的验证错误
        if (parent && this.$el.contains(parent)) {
          const index = Array.from(parent.parentElement.children).indexOf(
            parent,
          );
          const parentTab = parent
            .closest('.ant-tabs')
            .querySelectorAll('.ant-tabs-tab')[index];
          parentTab && parentTab.click();
        }
        // 验证失败后跳转到错误的字段
        scrollIntoView(dom);
      }
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error('form validate error');
    }
  }

  get formatData() {
    // Tabs 组件
    const field = this.fields[0];
    const currentTab = field.widget.tabs.find(v => v.id === field.current);
    const currentTabFields = currentTab.fields;
    // 功能名称组件
    const fnNameField = currentTabFields.find(
      v => v.type === ControlTypeEnum.FnName,
    );
    // 功能标识符组件
    const fnIdentifierField = currentTabFields.find(
      v => v.type === ControlTypeEnum.FnIdentifier,
    );

    const otherFields = currentTabFields
      // .filter(
      //   v =>
      //     v.type !== ControlTypeEnum.FnName &&
      //     v.type !== ControlTypeEnum.FnIdentifier,
      // )
      .map(v => {
        return {
          ...v,
          value: this.store.data[v.pkId],
        };
      });
    let formData = {
      dataSpecs: {
        fields: otherFields,
      },
      classificationId: currentTab.identifier,
      functionName: this.store.data[fnNameField.pkId],
      functionIdentifier: this.store.data[fnIdentifierField.pkId],
    };
    return formData;
  }

  get relationVisibleMap() {
    const controlField = {};
    const relationVisibleMap =
      this.relationsFilter &&
      this.relationsFilter.reduce((fields, relation) => {
        relation.fields.forEach(item => (controlField[item] = false));
        return compare(relation.filterGroup, {
          data: this.store.data,
        })
          ? relation.fields.reduce((map, key) => {
              map[key] = true;
              return map;
            }, fields)
          : fields;
      }, {});
    return {
      ...controlField,
      ...relationVisibleMap,
    };
  }

  mounted() {
    this.setChangedWatcher();

    this.setRelationsFilter();
  }
  relationsFilter = [];
  /** 字段关联显示配置 */
  setRelationsFilter() {
    // this.relationsFilter = ensureArray(this.relations).map(item => ({
    //   filterGroup: generateFormFilterGroup(
    //     item.relation,
    //     this.$refs.modelContext.getField,
    //     this.$refs.modelContext,
    //   ),
    //   ...item,
    // }));
  }
  /** @name 可见字段map */
  // get fieldVisibleMap() {
  //   const filterVisibleMap =
  //     this.filterType === DataFormFilterType.Contains
  //       ? generateMap(
  //           this.filter,
  //           pkId => pkId,
  //           () => true,
  //         )
  //       : {};
  //   return {
  //     ...filterVisibleMap,
  //     ...this.relationVisibleMap,
  //   };
  // }

  /**
   * @name 可见字段过滤类型
   * ALL 全部可见
   * FILTER 根据传入的id过滤
   * WRITABLE 只显示可写字段
   * REQUIRED 只显示必填字段
   */
  get filterType() {
    if (!this.filter) return DataFormFilterType.All;
    return Array.isArray(this.filter)
      ? DataFormFilterType.Contains
      : this.filter;
  }

  /**
   * @name 表单额外数据，用于存放验证码等信息
   */
  extra = {};

  deletePathList = new Set();

  deletePath(data) {
    const cloneData = deepClone(data);
    this.deletePathList.forEach(
      path => has(cloneData, path) && set(cloneData, path, undefined),
    );
    return cloneData;
  }

  /** @name 解决内部浮层的定位问题 */
  @Prop() getPopupContainer;
  computedGetPopupContainer(target) {
    return this.getPopupContainer ? this.getPopupContainer(target) : this.$el;
  }

  handleTitleChange(title) {
    this.$emit('title-change', title);
  }

  /** @name 表单渲染后changed状态的检测延迟，默认为5秒，用于等待接口和公式编辑等执行完成 */
  @Prop({ type: Number, default: 5000 }) changedDelay;
  changed = false;
  async setChangedWatcher() {
    // await delay(this.changedDelay);
    const unwatch = this.store.watch('*', () => {
      this.changed = true;
      unwatch();
    });
  }

  get computedBorderClass() {
    if (
      !this.borderSetting ||
      !this.borderSetting.border ||
      this.borderSetting.border === 'default'
    )
      return '';
    return [
      `border-${this.borderSetting.border}`,
      this.borderSetting.verticalBorder ? 'border-vertical' : '',
    ];
  }
}
/** @typedef { import('@/form/controls/BaseControl').Field } Field */
</script>
<style lang="less">
.x-data-form {
  font-size: 14px;
  padding: 0 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  scroll-behavior: smooth;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.x-data-form-item {
  border-left: 1px solid transparent;
  &.ant-form-item {
    padding: 10px 20px 15px;
    margin: 0;
  }

  // 鼠标划过字段的虚线框
  &.x-data-form-item--view:hover::before {
    position: absolute;
    left: -1px;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: block;
    border: 1px dashed var(--primary);
    border-left: 2px solid var(--primary);
    pointer-events: none;
  }
  &.x-data-form-item--view::after {
    position: absolute;
    left: 0;
    top: -1px;
    bottom: 0;
    right: -1px;
    z-index: 1;
    display: block;
    width: 400%;
    border-bottom: 1px solid transparent;
    content: '';
    pointer-events: none;
  }
}

.x-data-form.border-solid {
  padding: 0 10px;
  position: relative;
  &::before {
    position: absolute;
    left: 10px;
    top: -1px;
    z-index: 0;
    height: 100%;
    display: block;
    border-left: 1px solid #ffffff;
    content: '';
    pointer-events: none;
  }
  .x-data-form-item {
    &.ant-form-item {
      padding: 15px 20px 15px 10px;
    }

    &.x-data-form-item--view::after {
      position: absolute;
      left: 0;
      top: -1px;
      bottom: 0;
      right: -1px;
      z-index: 1;
      display: block;
      border: 1px solid var(--border);
      content: '';
      pointer-events: none;
    }
  }
}

.x-data-form.border-solidColumn {
  .x-data-form-item {
    border-left: 1px solid transparent;
    &.ant-form-item {
      padding: 10px 20px 15px;
      margin: 0;
    }
    &.x-data-form-item--view::after {
      position: absolute;
      left: 0;
      top: -1px;
      bottom: 0;
      right: -1px;
      z-index: 1;
      display: block;
      width: 400%;
      border-bottom: 1px solid var(--border);
      content: '';
      pointer-events: none;
    }
  }
}

.x-data-form.border-vertical {
  .x-data-form-item {
    border-left: 1px solid var(--border);
  }
}

.x-data-form.border-dashedColumn {
  .x-data-form-item {
    border-left: 1px dashed transparent;
    &.ant-form-item {
      padding: 10px 20px 15px;
      margin: 0;
    }
    &.x-data-form-item--view::after {
      position: absolute;
      left: 0;
      top: -1px;
      bottom: 0;
      right: -1px;
      z-index: 1;
      display: block;
      width: 400%;
      border-bottom: 1px dashed var(--border);
      content: '';
      pointer-events: none;
    }
  }
}

.x-data-form.border-dashedColumn.border-vertical {
  .x-data-form-item {
    &.x-data-form-item--view::after {
      border-left: 1px dashed var(--border);
      pointer-events: none;
    }
  }
}
</style>
