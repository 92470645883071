<template>
  <div>
    <field-config-config-desc :desc="$t('controls.desc.input')" />
    <field-config-title v-bind="fieldProps" />
    <field-config-required v-bind="fieldProps" />
    <parameter-object v-if="!isRes" v-bind="fieldProps" isInOrOut="in" />
    <parameter-object v-if="isRes" v-bind="fieldProps" isInOrOut="out" />
    <a-form-model-item prop="widget.isSingle" label="类型">
      <a-radio-group :value="field.widget.isSingle" @change="handleModeChange">
        <a-radio :value="true">
          单选
        </a-radio>
        <a-radio :value="false">
          多选
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item prop="widget.enumValueType" label="枚举value值类型">
      <x-select
        :value="field.widget.enumValueType"
        :data="enumValueList"
        @input="updateField({ key: 'widget.enumValueType', value: $event })"
        :showSearch="false"
      />
    </a-form-model-item>
    <a-form-model-item prop="widget.optionList" label="key/value">
      <options :field="field" :updateField="updateField" />
    </a-form-model-item>
    <field-config-description v-bind="fieldProps" />
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import FieldConfigTitle from '../components/field-config/title.vue';
import FieldConfigRequired from '../components/field-config/required.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';
import ParameterObject from '../components/field-config/parameter-object.vue';
import Options from './options.vue';
import { ParamType } from '@/views/connector/platform-manage/develop/data-template/template-design/form/controls';
import { templateDesignModule } from '@/enum/store';

@Component({
  components: {
    Options,
    FieldConfigTitle,
    FieldConfigRequired,
    FieldConfigDescription,
    FieldConfigConfigDesc,
    ParameterObject,
  },
})
export default class EnumConfig extends Vue {
  @Prop() field;
  @Prop() updateField;
  @templateDesignModule.Getter paramType;
  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
    };
  }
  /** @name 出参 */
  get isRes() {
    return this.paramType === ParamType.RES_PARAM;
  }
  handleInput(key, value) {
    this.updateField({ key: key, value: value });
  }
  enumValueList = [
    { text: 'int', value: 'INT32' },
    { text: 'string', value: 'TEXT' },
  ];
  handleModeChange(e) {
    this.handleInput('widget.isSingle', e.target.value);
    this.handleInput('widget.defaultValue.custom', e.target.value ? '' : []);
  }
}
</script>

<style lang="less" module>
.ml10 {
  margin-left: 10px;
}
.section {
  display: flex;
  width: 100%;
  .item {
    flex: 1;
  }
  .label2 {
    width: 32px;
  }
  .label1 {
    width: 15px;
  }
}
</style>
