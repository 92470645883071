<template>
  <div>
    <div :class="$style.basic">
      <div :class="$style.basicHeader">
        <div :class="$style.label">{{ $t('market.template.base') }}</div>
      </div>
      <div :class="$style.basicMain">
        <EnterpriseItem :isShowTitle="false" :itemInfo="enterpriseInfo" />

        <a @click="handleMoreEnterprise" type="link" :class="$style.moreBtn">
          {{ $t('business.seeMoreEnterprises') }}
        </a>
      </div>

      <div :class="$style.interval" v-if="followupList.length > 0"></div>
    </div>

    <div :class="$style.followInfo">
      <div :class="$style.followInfoHeader" v-if="followupList.length > 0">
        <div :class="$style.label">{{ $t('business.followupRecord') }}</div>
        <a-button @click="handleMoreFollowupInfo" type="link">
          {{ $t('business.seeMore') }}
        </a-button>
      </div>
      <ul :class="$style.followInfoMain">
        <li
          v-for="(item, index) in followupList"
          :key="index"
          :class="$style.item"
        >
          <FollowupInfoItem :itemInfo="item" />
        </li>
      </ul>
    </div>
    <div :class="$style.footer">
      <a-button :class="$style.btn" @click="handleCancel">
        {{ $t('common.action.cancel') }}
      </a-button>
      <a-button :class="$style.btn" type="link" @click="handleFollowup">
        {{ $t('business.FillInTheFollowUpRecord') }}
      </a-button>
      <a-button type="primary" :class="$style.btn" @click="handleCancel">
        {{ $t('common.action.confirm') }}
      </a-button>
    </div>
  </div>
</template>

<script>
/** 查看详情 */

import { Component, Vue, Prop } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import MoreEnterprise from './more-enterprise';
import MoreFollowupInfo from './more-followup-info';
import FollowupInfoItem from './followup-info-item.vue';
import EnterpriseItem from './enterprise-item.vue';
import InputFollowupInfo from './input-followup-info.vue';

import { fetchMoreFollowup } from '@/services/business-opportunity/index';

@Component({
  components: { FollowupInfoItem, EnterpriseItem },
})
export default class ReadDetail extends Vue {
  @Prop({ type: Object, default: () => null }) modalInfo;
  @Prop({ type: Object, default: () => null }) detail;

  static createReadDetailModal = createReadDetailModal;

  followupList = [];

  moreFollowupType = {
    more: 1,
    noMore: 0,
  };

  created() {
    fetchMoreFollowup(this.detail.memberId, this.moreFollowupType.noMore).then(
      res => {
        this.followupList = res;
      },
    );
  }

  async handleMoreEnterprise() {
    await MoreEnterprise.createMoreEnterpriseModal({
      modalInfo: { title: this.$t('business.seeMoreEnterprises') },
      memberId: this.detail.memberId,
    });
  }
  async handleMoreFollowupInfo() {
    await MoreFollowupInfo.createMoreFollowupInfoModal({
      modalInfo: { title: this.$t('business.followupRecord') },
      memberId: this.detail.memberId,
      moreFollowupType: this.moreFollowupType.more,
    });
  }
  handleCancel() {
    this.$emit('close');
  }
  async handleFollowup() {
    await InputFollowupInfo.createInputFollowupInfoModal({
      modalInfo: { title: this.$t('business.fillInFollowUpInformation') },
      memberId: this.detail.memberId,
    });
  }

  get enterpriseInfo() {
    return {
      memberName: this.detail.memberName,
      phone: this.detail.phone,
      position: this.detail.position,
      registerTime: this.detail.registerTime,
      isCertifiedEnterprise: this.detail.isCertifiedEnterprise,
      tenantName: this.detail.tenantName,
      memberCount: this.detail.memberCount,
      certifiedTime: this.detail.certifiedTime,
    };
  }
}

function createReadDetailModal(props) {
  return new Promise(resolve => {
    const modal = createModal(
      h =>
        h(ReadDetail, {
          props,
          on: {
            close: () => {
              modal.handleClose();
              resolve();
            },
            handleCreate: () => {
              modal.handleClose();
              resolve('success');
            },
          },
        }),
      {
        width: 860,
        title: props.modalInfo.title,
      },
    );
  });
}
</script>

<style lang="less" module>
.basic {
  .basicHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    .label {
      color: var(--font);
    }
  }
  .basicMain {
    margin-bottom: 10px;
  }
  .interval {
    width: 100%;
    height: 1px;
    background-color: var(--border);
    margin: 20px 0;
  }
}

.followInfo {
  .followInfoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    .label {
      color: var(--font);
    }
  }
  .followInfoMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    .item {
      width: 49%;
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  margin-bottom: -10px;
  .btn {
    margin-right: 10px;
    &:nth-child(2) {
      border: 1px solid var(--primary);
      color: var(--primary);
    }
    &:nth-child(3) {
      margin-right: 0;
    }
  }
}
</style>
