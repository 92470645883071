<template>
  <a-form-model :class="$style.main" @submit.prevent="handleSubmit">
    <div class="condition-item-wapper">
      <div
        class="condition-item"
        :class="$style.item"
        v-for="option in options"
        :key="option.label"
        :style="{ width: option.width }"
      >
        <a-form-model-item
          v-if="option.type == 1"
          :label="option.label"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-input
            v-model="form[option.model]"
            :type="option.formType"
            :placeholder="`${option.label}`"
            :maxLength="option.maxLength"
          />
        </a-form-model-item>
        <a-form-model-item
          v-if="option.type == 2"
          :label="option.label"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-date-picker
            :placeholder="`${option.label}`"
            :locale="locale"
            v-model="form[option.model]"
            value-format="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item
          v-if="option.type == 3"
          :label="option.label"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-select
            :value="form[option.model]"
            @change="val => (form[option.model] = val)"
          >
            <a-select-option
              v-for="item in option.items"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          v-if="option.type == 4"
          :class="$style['date-range']"
          :label="option.label"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 14 }"
        >
          <a-range-picker v-model="value" :locale="locale" @change="onChange" />
        </a-form-model-item>
        <a-form-model-item
          v-if="option.type == 5"
          :class="$style['date-range']"
          :label="option.label"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-range-picker
            v-model="value"
            :locale="locale"
            @change="onChange"
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm"
          />
        </a-form-model-item>
        <!-- 设备事件 -->
        <div v-if="option.type == 6" :class="$style.customItemBox">
          <a-form-model-item :label="option.label" :class="$style.customItem">
            <a-select
              v-model="form[option.children[0].model]"
              @change="
                val => {
                  form[option.children[0].model] = val;
                  $emit('custom-change', {
                    model: option.children[0].model,
                    value: val,
                  });
                  if (form[option.children[1].model]) {
                    form[option.children[1].model] = '';
                  }
                }
              "
            >
              <a-select-option
                v-for="item in option.children[0].items"
                :key="item.label"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            style="display: inline-block;"
            :class="$style.customItemCp"
          >
            <a-select
              v-model="form[option.children[1].model]"
              @change="val => (form[option.children[1].model] = val)"
            >
              <a-select-option
                v-for="item in option.children[1].items"
                :key="item.label"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
      </div>
    </div>
    <div class="searchBtns" :class="$style.btn">
      <a-button type="primary" html-type="submit">
        {{ $t('activity.Inquire') }}
      </a-button>
      <a-button @click="handleReset">{{ $t('activity.reset') }}</a-button>
    </div>
  </a-form-model>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

@Component()
export default class ConditionSearch extends Vue {
  /**
   * @name 数组
   * @param {
      * @label 左边文字
      * @model v-model绑定的值
      * @type  1-input 2-date 3-select(select结构：{label:显示值 value:值})
    }
   */
  @Prop({ type: Array, default: () => [] }) options;
  form = {};
  value = []; // [开始时间，结束时间]

  get locale() {
    return locale;
  }
  mounted() {
    this.resetForm();
  }
  resetForm() {
    this.options.map(item => {
      if (Array.isArray(item.model)) {
        for (let field of item.model) {
          this.$set(this.form, field, '');
        }
      } else if (item.type === 3) {
        if (item.items.length) {
          let selectedItem = item.items.filter(option => {
            return option.selected === true;
          });
          this.$set(
            this.form,
            item.model,
            (selectedItem[0] && selectedItem[0].value) || '',
          );
        }
      } else if (item.type === 6) {
        if (item.children.length) {
          for (let child of item.children) {
            this.$set(
              this.form,
              child.model,
              (child.items[0] && child.items[0].value) || '',
            );
          }
        }
      } else {
        this.$set(this.form, item.model, '');
      }
    });
  }
  onChange(_, dateStr) {
    this.options.map(item => {
      if (Array.isArray(item.model)) {
        this.form[item.model[0]] = this.$moment(dateStr[0]).valueOf();
        this.form[item.model[1]] = this.$moment(dateStr[1])
          .endOf('d')
          .valueOf();
      }
    });
  }

  handleSubmit() {
    this.$emit('submit', this.form);
  }

  handleReset() {
    this.resetForm();
    this.value = [];
    this.$emit('reset');
  }
}
</script>
<style lang="less" module>
.main {
  display: flex;
  justify-content: space-between;
  .item {
    display: inline-block;
    width: 350px;
  }
  .date-range {
    width: 350px;
  }
  .btn {
    width: 200px;
    margin-top: 4px;
    > button {
      margin: 0 0 10px 10px;
    }
  }
  :global .ant-form-item {
    margin-bottom: 15px;
    .ant-form-item-label {
      text-align: center !important;
    }
  }
  :global .ant-col {
    padding-left: 0 10px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
.customItemBox {
  display: table;
}
.customItem {
  display: inline-flex;
  width: 140px;
  :global {
    .ant-form-item-label {
      width: 65px;
      text-align: center;
    }
    .ant-form-item-control-wrapper {
      width: 70px;
    }
  }
}
.customItemCp {
  display: inline-flex;
  :global {
    .ant-form-item-control-wrapper {
      width: 110px;
    }
  }
}
</style>
