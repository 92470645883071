import request from '../request';
export const basePath = '/template-manager/secondary';

/**
 * @name 获取及节点综合统计
 * @returns Promise<{ any[] }>
 */
export function getNodeData() {
  return request(
    `${basePath}/node/statistics/integrated/statistical/interface`,
  );
}
export function nodeDetail() {
  return request(
    `${basePath}/node/statistics/enterprise/details/of/secondary/nodes`,
  );
}

export function quantityDetail(report) {
  return request(`${basePath}/node/statistics/identifyQuantity/detail`, {
    method: 'GET',
    body: report,
  });
}

export function identifyQuantityDetail(handle) {
  return request(
    `/template-manager/idc/identification/detail?handle=${handle}`,
  );
}
