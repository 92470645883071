<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :form="form"
    layout="vertical"
  >
    <a-form-model-item
      :label="$t('gallery.label.originalGroupName')"
      ref="beforeLibraryType"
      prop="beforeLibraryType"
    >
      <a-input
        disabled
        :value="iconLibraryType[beforeLibraryType]"
        :maxLength="30"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('iconLibrary.library.group.move')"
      ref="libraryType"
      prop="libraryType"
    >
      <a-select v-model="form.libraryType">
        <a-select-option
          v-for="item in libraryTypeList"
          :value="item.value"
          :key="item.name"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { IconLibraryTypeEnum } from '@/enum/icon';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';

function createModal({ ...props }, { title, width = '440px' }) {
  return createFormModal(h => h(MoveLibrary, { props }), {
    title,
    width,
  });
}

@Component()
export default class MoveLibrary extends Vue {
  @Prop({ type: Object, default: () => null }) data;
  form = {
    deleted: 0,
    libraryName: '',
    libraryType: '',
    pkId: '',
  };

  beforeLibraryType = '';

  get libraryTypeList() {
    return [
      {
        name: this.$t('iconLibrary.library.type.official'),
        value: IconLibraryTypeEnum.OFFICIAL,
      },
      {
        name: this.$t('iconLibrary.library.type.monochrome'),
        value: IconLibraryTypeEnum.MONOCHROMATIC,
      },
      {
        name: this.$t('iconLibrary.library.type.multiColor'),
        value: IconLibraryTypeEnum.MULTICOLOR,
      },
    ].filter(item => item.value !== this.beforeLibraryType);
  }

  beforeLibraryType = '';

  get rules() {
    return {
      libraryType: [
        {
          required: true,
          trigger: 'change',
          message: this.$t('iconLibrary.library.tips.belong'),
        },
      ],
    };
  }

  async getValue() {
    await this.$refs.ruleForm.validate();
    return {
      ...this.form,
    };
  }

  created() {
    this.initData();
  }

  get iconLibraryType() {
    return {
      OFFICIAL: this.$t('iconLibrary.library.type.official'),
      MONOCHROMATIC: this.$t('iconLibrary.library.type.monochrome'),
      MULTICOLOR: this.$t('iconLibrary.library.type.multiColor'),
    };
  }

  initData() {
    if (this.data) {
      Object.keys(this.form).forEach(key => {
        if (key === 'libraryType') {
          this.beforeLibraryType = this.data['iconLibraryType'];
          this.form[key] = this.libraryTypeList[0].value;
        } else {
          let keyValue = key === 'libraryName' ? 'iconLibraryName' : key;
          if (
            this.data[keyValue] !== undefined &&
            this.data[keyValue] !== null
          ) {
            this.form[key] = this.data[keyValue];
          }
        }
      });
    }
  }

  static createModal = createModal;
}
</script>
<style lang="less" module></style>
