import { cloneDeep, get, isNumber, set, isObject, isBoolean } from 'lodash';
import dayjs from 'moment';
import qs from 'qs';
import Vue from 'vue';
import { JSONPath } from 'jsonpath-plus';
import { UploadService } from '@triascloud/services';
import { ensureArray, generateMap, uuid } from '@triascloud/utils';
import {
  ControlTypeEnum,
  DateDefault,
  InputFormat,
  RuleType,
  TextDefault,
} from '../../data-template/template-design/form/controls';
import { DATATYPE } from '../../utils';
import {
  PLACEHOLDER_IN,
  PLACEHOLDER_INPUT,
  PLACEHOLDER_OUT,
} from '../components/utils';
import { createModuleUrl } from '@/utils';
import {
  preInterface,
  prefixApi,
} from '@/services/iot-platform/platformInterface';
import { CompareMap } from '../components/compare-enum';

/** @name 参数状态 */
export const PARAM_STATUS = {
  /** @name 入参 */
  IN: 'IN',
  /** @name 出参 */
  OUT: 'OUT',
};
/** @name 入参or出参 */
export const PARAM_TYPE = '__paramType__';

export const flatFields = fields => {
  return ensureArray(fields).reduce((result, field) => {
    // 1、结构体 2、DP点 3、DP点下面的结构体
    // 获取他们的children
    if (
      field.type === ControlTypeEnum.Struct ||
      field.type === ControlTypeEnum.DataPoint ||
      field.type.toLocaleLowerCase() === DATATYPE.struct.toLocaleLowerCase()
    ) {
      return result.concat(flatFields(field.children));
    } else if (
      // 1、数组结构体 2、DP点下面的数组结构体
      (field.type === ControlTypeEnum.Array &&
        field.subType === ControlTypeEnum.Struct) ||
      (field.type.toLocaleLowerCase() === DATATYPE.array.toLocaleLowerCase() &&
        field.details?.subType?.toLocaleLowerCase() ===
          DATATYPE.struct.toLocaleLowerCase())
    ) {
      return result.concat(flatFields([].concat(...field.fields)));
    } else {
      return result.concat(field);
    }
  }, []);
};

export class ReactiveStore {
  /** @param { Record<string, T> } initData */
  constructor(initData) {
    this.data = Vue.observable(initData || {});
  }

  get(path) {
    return get(this.data, path);
  }

  set(path, value) {
    set(this.data, path, value);
  }
}

/** @name 数组组件的children的id需要唯一 */
const parseChildren = children => {
  children.forEach(v => {
    v.id = uuid();
  });
  return children;
};
/** @name 数组组件包含的children多项列表 */
export const parseArrayByFields = (item, totalCount, observable = false) => {
  let count = 0;
  const fields = [];
  while (count < totalCount) {
    fields.push(cloneDeep(parseChildren(item.children)));
    count++;
  }

  // 【数组】组件的【defaultValue】属性，拥有数据
  if (Array.isArray(item.defaultValue) && item.defaultValue.length > 0) {
    const list = item.defaultValue;
    fields.forEach((items, itemsIndex) => {
      items.forEach((field, fieldIndex) => {
        field.val = list[itemsIndex][fieldIndex];
      });
    });
  }

  if (!observable) {
    item['fields'] = fields;
  } else {
    Vue.set(item, 'fields', fields);
  }
};

/** @description 迭代字段列表 */
const eachFieldList = (list, postType, uidKey) => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return;
  }
  list.forEach(item => {
    if (postType || item.postType) {
      item[uidKey] = item.id + (postType || item.postType);
      item.postType = postType || item.postType;
    } else {
      item[uidKey] = item.id;
    }
    if (
      item.type === ControlTypeEnum.Array &&
      item.subType === ControlTypeEnum.Struct
    ) {
      eachFieldList([].concat(...item.fields), item.postType, uidKey);
    } else if (
      item.type.toLocaleLowerCase() === DATATYPE.array.toLocaleLowerCase() &&
      item.details?.subType?.toLocaleLowerCase() ===
        DATATYPE.struct.toLocaleLowerCase()
    ) {
      eachFieldList([].concat(...item.fields), item.postType, uidKey);
    } else if (item.children && item.children.length > 0) {
      eachFieldList(item.children, item.postType, uidKey);
    }
  });
};
/** @description 给字段列表添加【uid】字段 */
export const addUidByFieldList = (array = [], uidKey = 'uid') => {
  eachFieldList(array, '', uidKey);
  return array;
};

/** @name 检查组件的默认值 */
export const checkDefaultValue = param => {
  if (param.val === PLACEHOLDER_INPUT) {
    param.val = '';
  }
  if (param.val === PLACEHOLDER_OUT) {
    param.val = '';
  }
  if (param.val === PLACEHOLDER_IN) {
    param.val = '';
  }
  /** @name 布尔类型 */
  if (
    param.type === ControlTypeEnum.Switch ||
    param.type === DATATYPE.boolean.toLocaleLowerCase()
  ) {
    if (param.val === '') {
      param.val = 0;
    }
  }
  return param;
};

const checkFieldValue = ({ item, key, store, variableCallback, fnArg }) => {
  if (!store) return '';
  checkDefaultValue(item);

  if (item.type === ControlTypeEnum.Input) {
    /** @name 随机数生成 */
    let data = createRandom(item);
    if (data) {
      store.set(item[key], data);
    }
    if (variableCallback) {
      /** @name 变量解析 */
      if (/\{\{(\w+)\}\}/.test(store.get(item[key]))) {
        const strValue = parseVariable(
          store.get(item[key]),
          variableCallback(),
        );
        store.set(item[key], strValue);
      }
    }
  }
  // fnArg用于连接器发布，拦截【文本组件】和【时间组件】，设置后端参数【functionList】
  if (fnArg) {
    if (item.type === ControlTypeEnum.Input) {
      if (item.ruleType === RuleType.INT) {
        return 'RANDOM_INT';
      } else if (item.ruleType === RuleType.FLOAT) {
        return 'RANDOM_DOUBLE';
      } else if (item.ruleType === RuleType.UUID) {
        return 'RANDOM_UUID';
      }
    } else if (item.type === ControlTypeEnum.Date) {
      if (item.defaultType === DateDefault.CURRENT) {
        if (item.format === 'X') {
          return 'TIME_SECONDS';
        } else if (item.format === 'x') {
          return 'TIME_MILLISECOND';
        } else if (item.format === 'YYYY-MM-DDTHH:mm:ssZ') {
          return 'TIME_UTC_FORMAT';
        }
        return `TIME_FORMAT@${item.format}`;
      } else {
        if (item.format === 'X') {
          return 'TIME_SECONDS';
        } else if (item.format === 'x') {
          return 'TIME_MILLISECOND';
        } else if (item.format === 'YYYY-MM-DDTHH:mm:ssZ') {
          return 'TIME_UTC_FORMAT';
        }
        return `TIME_FORMAT@${item.format}`;
      }
    }
  }

  if (
    item.type === ControlTypeEnum.Int ||
    item.type === ControlTypeEnum.Float ||
    item.type === ControlTypeEnum.Double ||
    item.type.toLocaleLowerCase() === DATATYPE.int.toLocaleLowerCase() ||
    item.type.toLocaleLowerCase() === DATATYPE.float.toLocaleLowerCase() ||
    item.type.toLocaleLowerCase() === DATATYPE.double.toLocaleLowerCase()
  ) {
    return isNumber(-store.get(item[key])) ? Number(store.get(item[key])) : '';
  } else if (item.type === ControlTypeEnum.Image) {
    return store.get(item[key]) ? store.get(item[key])[0].ossPath : '';
  } else if (item.type === ControlTypeEnum.File) {
    return store.get(item[key]) ? store.get(item[key])[0].ossPath : '';
  } else if (
    item.type === ControlTypeEnum.Switch ||
    item.type === DATATYPE.boolean.toLocaleLowerCase()
  ) {
    return store.get(item[key]) ? 1 : 0;
  } else if (
    item.type === ControlTypeEnum.Enum ||
    item.type.toLocaleLowerCase() === DATATYPE.enum.toLocaleLowerCase()
  ) {
    return isNumber(store.get(item[key]))
      ? String(store.get(item[key]))
      : String(store.get(item[key])) === 'undefined'
      ? ''
      : String(store.get(item[key]));
  } else if (
    item.type === ControlTypeEnum.Date ||
    item.type.toLocaleLowerCase() === DATATYPE.date.toLocaleLowerCase()
  ) {
    if (item.format) {
      let val = store.get(item[key]) ? store.get(item[key]) : '';
      if (item.defaultType && item.defaultType === DateDefault.CURRENT) {
        if (val === '') {
          val = dayjs(Date.now()).format('YYYY-MM-DD HH:mm:ss');
        }
      }
      // 处理(UTC)时间格式
      // YYYY-MM-DDTHH:mm:ss.SSS[Z] 这个是旧的UTC时间格式，只做兼容显示
      // YYYY-MM-DDTHH:mm:ssZ这个是新的UTC时间格式
      if (
        item.format === 'YYYY-MM-DDTHH:mm:ssZ' ||
        item.format === 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
      ) {
        return val
          ? dayjs(val)
              .utc()
              .format()
          : val;
      } else {
        return val ? dayjs(val).format(item.format) : val;
      }
    } else {
      return store.get(item[key]) ? store.get(item[key]) : '';
    }
  } else {
    return store.get(item[key]) ? store.get(item[key]) : '';
  }
};
const checkFieldFilter = ({
  object,
  item,
  key,
  store,
  variableCallback,
  postType,
  fnArg,
}) => {
  // 只获取对应的postType[query or body]
  if (postType) {
    if (item.postType === postType) {
      object[item.identifier] = checkFieldValue({
        item,
        key,
        store,
        variableCallback,
        fnArg,
      });
    }
    return;
  }
  object[item.identifier] = checkFieldValue({
    item,
    key,
    store,
    variableCallback,
    fnArg,
  });
};

/**
 * @name 根据接口的Method，决定参数的输入方式
 */
export const formatBody = ({
  list = [],
  store,
  key = 'uid',
  postType = '',
  variableCallback,
  fnArg = false, //
} = {}) => {
  let body = {};
  let dpBody = {};
  let array = [];
  const eachBody = (
    list,
    body,
    key,
    store,
    variableCallback,
    postType,
    fnArg,
    len,
  ) => {
    if (!(Array.isArray(list) && list.length > 0)) {
      return;
    }
    list.forEach((item, index) => {
      if (item.type === ControlTypeEnum.DataPoint) {
        // DP选择的某项，即只获取页面显示的组件
        const child =
          item.children && item.children.find(v => v.id === item.val);
        if (child) {
          eachBody(
            child.children,
            dpBody,
            key,
            store,
            variableCallback,
            postType,
            fnArg,
            -1,
          );
        }
      } else if (
        item.type === ControlTypeEnum.Array &&
        item.subType === ControlTypeEnum.Struct
      ) {
        // 【结构数组】
        eachBody(
          [].concat(...item.fields),
          body,
          key,
          store,
          variableCallback,
          postType,
          fnArg,
          item.fields.length,
        );
      } else if (
        item.type === DATATYPE.array.toLocaleLowerCase() &&
        item.details?.subType?.toLocaleLowerCase() ===
          DATATYPE.struct.toLocaleLowerCase()
      ) {
        // 【DP的结构数组】
        eachBody(
          [].concat(...item.fields),
          dpBody,
          key,
          store,
          variableCallback,
          postType,
          fnArg,
          item.fields.length,
        );
      } else if (item.children && item.children.length > 0) {
        // 【DP结构】和【普通结构】
        eachBody(
          item.children,
          dpBody,
          key,
          store,
          variableCallback,
          postType,
          fnArg,
          -1,
        );
      } else {
        let result;
        if (len === -1) {
          result = body;
        } else {
          result = {};
        }
        checkFieldFilter({
          object: result,
          item,
          key,
          store,
          variableCallback,
          postType,
          fnArg,
        });
        if (len !== -1) {
          let arrayLen = list.length / len;
          let rangeIdx = -1;
          rangeIdx = Math.floor(index / arrayLen);
          if (rangeIdx !== -1) {
            array[rangeIdx] = result;
          }
        }
      }
      // 2、DP 处理完成后，需要特殊处理DP的结构
      if (item.type === ControlTypeEnum.DataPoint) {
        //入参格式 不参与入参：0, 对象:1, 键值:2
        if (item.paramInFormat === InputFormat.OBJECT) {
          body = Object.assign(body, {
            [item.identifier]: `${JSON.stringify(dpBody)}`,
          });
        } else if (item.paramInFormat === InputFormat.KEYVALUE) {
          body = Object.assign(body, { ...dpBody });
        }

        // paramInTag: 'identifier', //入参标识
        if (item.paramInTag) {
          const result = item.children.find(i => i.id === item.val);
          let _identifier = '';
          if (result) {
            _identifier = result.identifier;
          }
          body = Object.assign(body, {
            [item.paramInTag]: _identifier,
          });
        }
        dpBody = {};
      } else if (item.type === ControlTypeEnum.Struct) {
        // 【结构】
        body = Object.assign(body, {
          [item.identifier]: dpBody,
        });
        dpBody = {};
      } else if (
        item.type === ControlTypeEnum.Array &&
        item.subType === ControlTypeEnum.Struct
      ) {
        body = Object.assign(body, {
          [item.identifier]: array,
        });
        array = [];
      } else if (
        item.type === DATATYPE.array.toLocaleLowerCase() &&
        item.details?.subType?.toLocaleLowerCase() ===
          DATATYPE.struct.toLocaleLowerCase()
      ) {
        dpBody = Object.assign(dpBody, {
          [item.identifier]: array,
        });
        array = [];
      }
    });
  };
  eachBody(list, body, key, store, variableCallback, postType, fnArg, -1);
  return body;
};

const keyStr = key => {
  return `{{${key}}}`;
};

/** @name 解析变量，list是对象数组 */
export const parseVariable = (value = '', list = []) => {
  const array = list.map(v => {
    return {
      key: keyStr(v.identifier),
      value: v.defaultValue,
    };
  });
  const reg = /\{\{(\w+)\}\}/g;
  const result = value.match(reg) || [];

  result.forEach(v => {
    const item = array.find(i => i.key === v);
    if (item) {
      value = value.replace(`${v}`, item.value);
    }
  });
  return value;
};

const customService = new UploadService('/oss/oss');
/** @name 出参解析图片和文件 */
export const parseImageOrFile = async item => {
  let val = item.val;
  if (typeof val === 'string' && val.indexOf('//') === -1) {
    val = await customService.getAuth(val);
  }
  let uid = uuid();
  item.val = [
    {
      status: 'done',
      uid: uid,
      name: uid,
      url: val,
    },
  ];
};

/**
 * @name 出参解析
 * @param {*} list 结构列表
 * @param {*} len 表示【结构数组】的长度
 * @param {*} parentValue 上一级节点的数据
 * @param {*} result 数据
 */
export const eachResList = async (list, len, parentValue, result) => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return;
  }
  for await (let [index, item] of list.entries()) {
    if (
      item.val &&
      typeof item.val === 'string' &&
      item.val.indexOf('$.') > -1
    ) {
      let value = JSONPath({ path: item.val, json: result });
      if (item.type !== ControlTypeEnum.Array) {
        value = value[0];
      }
      if (value) {
        item.val = value;
      } else {
        if (len === -1) {
          if (isNumber(value)) {
            item.val = value;
          } else if (parentValue) {
            item.val = parentValue[item.identifier];
          }
        } else {
          /**
           * @name 获取数组的子项数据
           */
          const listLen = list.length / len;
          let rangeIdx = -1;
          rangeIdx = Math.floor(index / listLen);
          if (rangeIdx !== -1 && parentValue && rangeIdx < parentValue.length) {
            item.val = parentValue[rangeIdx][item.identifier];
          }
        }
      }
      if (
        item.type === ControlTypeEnum.Image ||
        item.type === ControlTypeEnum.File
      ) {
        await parseImageOrFile(item);
      }
    }
    if (
      item.type === ControlTypeEnum.Array &&
      item.subType === ControlTypeEnum.Struct
    ) {
      // 【结构数组】
      let len = 0;
      if (item.val) {
        len = item.val.length;
      }
      parseArrayByFields(item, len, true);
      if (item.fields) {
        await eachResList(
          [].concat(...item.fields),
          item.fields.length,
          item.val,
          result,
        );
      }
    } else if (
      item.type.toLocaleLowerCase() === DATATYPE.array.toLocaleLowerCase() &&
      item.details?.subType?.toLocaleLowerCase() ===
        DATATYPE.struct.toLocaleLowerCase()
    ) {
      // 【DP的结构数组】
      let len = 0;
      if (item.val) {
        len = item.val.length;
      }
      parseArrayByFields(item, len, true);
      if (item.fields) {
        await eachResList(
          [].concat(...item.fields),
          item.fields.length,
          item.val,
          result,
        );
      }
    } else if (item.children && item.children.length > 0) {
      // 【结构 or DP的结构 or DP】
      await eachResList(item.children, -1, item.val, result);
    }
  }
};

/** @name url自带了path入参 */
export const parseUrlPath = (url = '', body) => {
  if (url.includes('{')) {
    let identifier = '';
    Array.from(url.matchAll(/\{(\w+)\}/g), v => v[1]).forEach(v => {
      identifier += `/${body[v]}`;
    });
    const urlStr = url.split('{')[0].slice(0, -1);
    url = `${urlStr}${identifier}`;
  }
  return url;
};

/** @name url的query入参 */
export const parseUrlQuery = (url = '', method, body) => {
  let urlStr = '';
  Object.keys(body).forEach(v => {
    if (isObject(body[v])) {
      const obj = {};
      obj[v] = body[v];
      urlStr +=
        '&' + encodeURI(qs.stringify(obj, { allowDots: true, encode: false }));
    } else {
      urlStr += `&${v}=${body[v]}`;
    }
  });
  if (url.includes('?')) {
    url = url + urlStr;
  } else {
    let str = urlStr.charAt(0) === '&' ? urlStr.slice(1) : urlStr;
    url = url + (urlStr ? '?' + str : '');
  }
  return url;
};

const IntLen = (num = 0, len = 0) => {
  try {
    let str = num.toString();
    return parseInt(str.slice(0, len));
  } catch (error) {
    return num;
  }
};
/** @name 生成随机长度的整数和小数 */
export const createRandom = (item, random = Math.random()) => {
  let data = undefined;
  if (item.defaultType === TextDefault.RULE) {
    let len = item.ruleLength || 10;
    if (item.ruleType === RuleType.INT) {
      let t = len;
      let i = 0;
      while (i < len) {
        ++i;
        t = t + '0';
      }
      data = IntLen(parseInt(random * +t), len);
    } else if (item.ruleType === RuleType.FLOAT) {
      data = parseFloat(random.toFixed(len));
    } else {
      data = uuid();
    }
  }
  return data;
};

/** @name 用于后端函数解析使用 */
const getMapByRelase = (body, query) => {
  const fnMap = new Map();
  bodyIterator(body, 'BODY', fnMap);
  bodyIterator(query, 'QUERY', fnMap);
  return [...fnMap.values()];
};

const bodyIterator = (body, path, fnMap) => {
  Object.keys(body).forEach(i => {
    if (
      Object.prototype.toString.call(body[i]) === '[object String]' &&
      (body[i].includes('RANDOM_') || body[i].includes('TIME_'))
    ) {
      if (body[i].includes('TIME_FORMAT')) {
        let str = body[i].split('@');
        if (fnMap.has('TIME_FORMAT')) {
          const paths = fnMap.get('TIME_FORMAT');
          paths.functionPath.push({
            extendArgs: [str[1]],
            identifier: i,
            path,
          });
        } else {
          fnMap.set('TIME_FORMAT', {
            httpFunction: 'TIME_FORMAT',
            functionPath: [
              {
                extendArgs: [str[1]],
                identifier: i,
                path,
              },
            ],
          });
        }
        body[i] = str[0];
      } else {
        if (fnMap.has(body[i])) {
          const paths = fnMap.get(body[i]);
          paths.functionPath.push({
            identifier: i,
            path,
          });
        } else {
          fnMap.set(body[i], {
            httpFunction: body[i],
            functionPath: [
              {
                identifier: i,
                path,
              },
            ],
          });
        }
      }
    }
  });
};

/** @name POST参数的Content-Type */
export const BODY_TYPE = {
  /** @name JSON */
  JSON: 'JSON',
  /** @name form-data */
  FORM: 'form-data',
  /** @name x-www-form-urlencoded */
  X_FORM: 'x-www-form-urlencoded',
};
/** @name 入参的来源 */
export const STATUS = {
  /** @name 开发 */
  DEVELOPMENT: 'DEVELOPMENT',
  /** @name 平台方 */
  PLATFORM: 'PLATFORM',
  /** @name 订阅方 */
  SUBSCRIBER: 'SUBSCRIBER',
};

/** @description 递归整个数组 */
const eachList = (array, postType) => {
  if (!(Array.isArray(array) && array.length > 0)) {
    return;
  }
  array.forEach(child => {
    if (postType) {
      child.postType = postType;
    }
    if (
      child.type === ControlTypeEnum.Array &&
      child.subType === ControlTypeEnum.Struct
    ) {
      parseArrayByFields(child, child.subCount);
    }
    if (
      child.type.toLocaleLowerCase() === DATATYPE.array.toLocaleLowerCase() &&
      child.details?.subType?.toLocaleLowerCase() ===
        DATATYPE.struct.toLocaleLowerCase()
    ) {
      parseArrayByFields(child, child.details.count);
    }
    if (child.children && child.children.length > 0) {
      eachList(child.children, postType);
    }
  });
};
/** @name 【入参或出参】数组组件处理 */
export const checkArrayType = (list = [], postType = '', callback) => {
  eachList(list, postType);
  if (callback) {
    setTimeout(() => {
      callback();
    }, 20);
  }
  return list;
};

/** @name 解析Header的变量 */
export const parseHeaderVariable = (headerString = '', variables) => {
  const headers = JSON.parse(headerString);
  headers.forEach(v => {
    v.val = parseVariable(v.val, variables);
  });
  return headers;
};

const mapHeader = (list = []) => {
  const headers = {};
  list.forEach(v => {
    headers[v.identifier] = v.val;
  });
  return headers;
};
export const headerOptions = (list = [], otherHeader = {}) => {
  let headers = mapHeader(list);
  if (otherHeader) {
    headers = Object.assign(otherHeader, headers);
  }
  return Object.keys(headers).length ? JSON.stringify(headers) : '';
};

/** @name 前置接口参数解析 */
export const prefixAPiFormat = (res, variables) => {
  const prefix = {
    queryParam: res.requestUrlParam
      ? checkArrayType(JSON.parse(res.requestUrlParam))
      : [],
    bodyParam: res.requestBodyParam
      ? checkArrayType(JSON.parse(res.requestBodyParam).data)
      : [],
    HeaderParam: res.requestHeaderParam
      ? parseHeaderVariable(res.requestHeaderParam, variables)
      : [],
    url: res.beforeUrl,
  };

  const paramList = () => {
    let list = [];
    if (prefix.bodyParam) {
      list = list.concat(prefix.bodyParam);
    }
    if (prefix.queryParam) {
      list = list.concat(prefix.queryParam);
    }
    return list.filter(v => v.pkId === v.id);
  };

  let data = generateMap(flatFields(paramList()), 'id', item => {
    checkDefaultValue(item);
    return item.val;
  });
  const store = new ReactiveStore(data);

  let body = formatBody({
    list: paramList(),
    store,
    key: 'id',
    variableCallback: () => variables,
  });
  const header = mapHeader(prefix.HeaderParam);
  body = Object.assign(body, header);

  const fnList = (array = []) => {
    let list = [];
    if (array) {
      list = list.concat(array);
    }
    return list.filter(v => v.pkId === v.id);
  };

  const prefixFnQueryData = formatBody({
    list: fnList(prefix.queryParam),
    store,
    key: 'id',
    variableCallback: () => variables,
    fnArg: true,
  });

  const prefixFnBodyData = formatBody({
    list: fnList(prefix.bodyParam),
    store,
    key: 'id',
    variableCallback: () => variables,
    fnArg: true,
  });

  const url = createModuleUrl('api', 'https:') + '/iot-platform' + prefix.url;

  return {
    prefixUrl: url,
    prefixData: body,
    prefixFnQueryData,
    prefixFnBodyData,
  };
};

/** @name 获取Content-Type */
export const GetContentType = (Method, formatType) => {
  let contentType = '';
  if (Method === 'GET') {
    contentType = 'application/x-www-form-urlencoded';
  } else {
    if (formatType === BODY_TYPE.JSON) {
      contentType = 'application/json';
    } else if (formatType === BODY_TYPE.FORM) {
      contentType = 'multipart/form-data';
    } else if (formatType === BODY_TYPE.X_FORM) {
      contentType = 'application/x-www-form-urlencoded';
    }
  }
  return contentType;
};

/** @name 获取发布的http的入参 */
export const fetchReleaseOption = async args => {
  let { detail, list, store, apiId, trigger, variables, apiUrl } = args;
  const Method = detail.requestMethod;
  let fnList = [];
  let apiBody = {};
  const headerFormat = str => {
    if (!str) {
      return {};
    } else {
      return JSON.parse(str);
    }
  };
  const headerParam = detail.requestHeaderParam
    ? parseHeaderVariable(detail.requestHeaderParam, variables)
    : [];
  let apiHead = headerFormat(headerOptions(headerParam));
  if (Method === 'GET') {
    const queryBody = formatBody({
      list: list,
      store: store,
      fnArg: true,
    });
    fnList = getMapByRelase({}, queryBody);
  } else {
    const postBody = formatBody({
      list: list,
      store: store,
      fnArg: true,
      postType: 'body',
    });

    const postQuery = formatBody({
      list: list,
      store: store,
      fnArg: true,
      postType: 'query',
    });
    apiBody = postBody;
    fnList = getMapByRelase(postBody, postQuery);
  }
  if (trigger.citingPublicParameters === 'PUBLIC_AUTHENTICATION') {
    const result = await preInterface(apiId);
    const {
      prefixData,
      prefixUrl,
      prefixFnBodyData,
      prefixFnQueryData,
    } = prefixAPiFormat(result, variables);

    // 预请求（前置接口）
    const body = formatBody({
      list: list,
      store: store,
      fnArg: true,
    });
    const data = Object.assign(body, prefixData);
    const prefixRes = await prefixApi(prefixUrl, {
      apiId,
      data,
    });
    let otherQuery = prefixRes.query;
    let otherBody = prefixRes.body;
    let otherHeader = prefixRes.header;

    if (otherQuery || prefixData) {
      apiUrl = parseUrlQuery(
        apiUrl,
        Method,
        Object.assign(prefixData || {}, otherQuery || {}),
      );
    }

    const prefixFnList = getMapByRelase(prefixFnBodyData, prefixFnQueryData);
    fnList.push(...prefixFnList);

    apiHead = Object.assign(apiHead, otherHeader);

    apiBody = Object.assign(apiBody, otherBody);
  }

  return {
    optBody: apiBody,
    optHead: apiHead,
    optUrl: apiUrl,
    optFnList: fnList,
  };
};

/** @name 获取请求URL */
export const requestURL = (detail, list, store) => {
  let url = detail.requestUrl;
  const method = detail.requestMethod;
  const options = {
    list,
    store,
  };
  if (method === 'POST') {
    options.postType = 'query';
  }
  const body = formatBody(options);
  url = parseUrlPath(url, body);
  url = parseUrlQuery(url, method, body);
  return url;
};

/** @name HTTP请求实体 */
export const fetchBody = (Method, formtType, list, otherBody, store) => {
  if (Method === 'GET') return null;
  const data = formatBody({ list, store, postType: 'body' });
  if (formtType === BODY_TYPE.JSON) {
    return Object.assign(otherBody ? otherBody : {}, data);
  } else if (formtType === BODY_TYPE.FORM) {
    let formData = new FormData();
    if (otherBody) {
      Object.keys(otherBody).forEach(v => {
        formData.append(v, data[v]);
      });
    }
    Object.keys(data).forEach(v => {
      formData.append(v, data[v]);
    });
    return formData;
  }
};

/**
 * @description 更改列表的required属性
 * @param { Array } list 入参列表
 * @param { Boolean } flag required的类型
 * @param { Vue } vueTarget vue
 */
export const updateListRequired = (list = [], flag = false, vueTarget) => {
  const fieldFilter = (field, flag) => {
    field.required && vueTarget.set(field, 'originRequired', field.required);
    if (!flag) {
      field.required = flag;
    } else {
      if (field.originRequired) {
        field.required = flag;
      }
    }
  };
  list.forEach(field => {
    if (
      field.inParams &&
      field.inParams[0] === STATUS.SUBSCRIBER &&
      field.type !== ControlTypeEnum.DataPoint
    ) {
      fieldFilter(field, flag);
    }
    if (field.type === ControlTypeEnum.DataPoint) {
      fieldFilter(field, flag);
    }
  });
};

/** @name 根据identifier查找field【组件】的value */
const findIdentifier = (list, target) => {
  const result = [];
  list.forEach(v => {
    if (
      v.type === ControlTypeEnum.Array &&
      v.subType === ControlTypeEnum.Struct
    ) {
      v.fields.forEach(v => {
        v.forEach(field => {
          if (field.identifier === target.identifier) {
            result.push(field.val);
          }
        });
      });
    }
  });
  return result;
};
/** @name 出参规则提示语校验 */
export const promptValidate = (array, store, list) => {
  const msgList = [];
  array.forEach(v => {
    let resCount = 0;
    v.conditions.forEach(field => {
      const valueList = findIdentifier(list, field);
      if (valueList.length) {
        let count = 0;
        valueList.forEach(value => {
          count += checkOutValue(value, field);
        });
        if (count > 0 && valueList.length === count) {
          resCount++;
        }
      } else {
        // 根据identifier查找
        const value = store.get(field.fieldId);
        let count = checkOutValue(value, field);
        if (count > 0) {
          resCount++;
        }
      }
    });
    if (v.logic === 'AND' && v.conditions.length === resCount) {
      msgList.push(v.prompt);
    }
    if (v.logic === 'OR' && resCount > 0) {
      msgList.push(v.prompt);
    }
  });
  return msgList;
};

/** @name 出参规则校验 */
const checkOutValue = (value, field) => {
  let resCount = 0;
  if (value || isBoolean(value)) {
    if (field.algorithm === CompareMap.eq) {
      if (
        field.fieldType === ControlTypeEnum.Int ||
        field.fieldType === ControlTypeEnum.Float ||
        field.fieldType === ControlTypeEnum.Double ||
        field.fieldType === DATATYPE.int.toLocaleLowerCase()
      ) {
        if (+field.value === +value) {
          resCount++;
        }
      } else if (
        field.fieldType === ControlTypeEnum.Enum ||
        field.fieldType === ControlTypeEnum.Switch
      ) {
        if (field.value === `${value}`) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Input) {
        const eqComplex = type => JSON.stringify(type.replace(/\s/g, ''));
        if (eqComplex(field.value) === eqComplex(value)) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Date) {
        if (dayjs(field.value).format('x') === dayjs(value).format('x')) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Array) {
        if (
          JSON.stringify(field.value) ===
          JSON.stringify(value.replace(/\s/g, ''))
        ) {
          resCount++;
        }
      }
    } else if (field.algorithm === CompareMap.notEq) {
      if (
        field.fieldType === ControlTypeEnum.Int ||
        field.fieldType === ControlTypeEnum.Float ||
        field.fieldType === ControlTypeEnum.Double ||
        field.fieldType === DATATYPE.int.toLocaleLowerCase()
      ) {
        if (+field.value !== +value) {
          resCount++;
        }
      } else if (
        field.fieldType === ControlTypeEnum.Enum ||
        field.fieldType === ControlTypeEnum.Switch
      ) {
        if (field.value !== `${value}`) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Input) {
        const eqComplex = type => JSON.stringify(type.replace(/\s/g, ''));
        if (eqComplex(field.value) !== eqComplex(value)) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Date) {
        if (dayjs(field.value).format('x') !== dayjs(value).format('x')) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Array) {
        if (
          JSON.stringify(field.value) !==
          JSON.stringify(value.replace(/\s/g, ''))
        ) {
          resCount++;
        }
      }
    } else if (field.algorithm === CompareMap.lt) {
      if (
        field.fieldType === ControlTypeEnum.Int ||
        field.fieldType === ControlTypeEnum.Float ||
        field.fieldType === ControlTypeEnum.Double ||
        field.fieldType === DATATYPE.int.toLocaleLowerCase()
      ) {
        if (value < field.value) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Date) {
        if (dayjs(value).format('x') < dayjs(field.value).format('x')) {
          resCount++;
        }
      }
    } else if (field.algorithm === CompareMap.le) {
      if (
        field.fieldType === ControlTypeEnum.Int ||
        field.fieldType === ControlTypeEnum.Float ||
        field.fieldType === ControlTypeEnum.Double ||
        field.fieldType === DATATYPE.int.toLocaleLowerCase()
      ) {
        if (value <= field.value) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Date) {
        if (dayjs(value).format('x') <= dayjs(field.value).format('x')) {
          resCount++;
        }
      }
    } else if (field.algorithm === CompareMap.gt) {
      if (
        field.fieldType === ControlTypeEnum.Int ||
        field.fieldType === ControlTypeEnum.Float ||
        field.fieldType === ControlTypeEnum.Double ||
        field.fieldType === DATATYPE.int.toLocaleLowerCase()
      ) {
        if (value > field.value) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Date) {
        if (dayjs(value).format('x') > dayjs(field.value).format('x')) {
          resCount++;
        }
      }
    } else if (field.algorithm === CompareMap.ge) {
      if (
        field.fieldType === ControlTypeEnum.Int ||
        field.fieldType === ControlTypeEnum.Float ||
        field.fieldType === ControlTypeEnum.Double ||
        field.fieldType === DATATYPE.int.toLocaleLowerCase()
      ) {
        if (value >= field.value) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Date) {
        if (dayjs(value).format('x') >= dayjs(field.value).format('x')) {
          resCount++;
        }
      }
    } else if (field.algorithm === CompareMap.null) {
      if (
        field.fieldType === ControlTypeEnum.Int ||
        field.fieldType === ControlTypeEnum.Float ||
        field.fieldType === ControlTypeEnum.Double ||
        field.fieldType === DATATYPE.int.toLocaleLowerCase() ||
        field.fieldType === ControlTypeEnum.Date ||
        field.fieldType === ControlTypeEnum.Input ||
        field.fieldType === ControlTypeEnum.Image
      ) {
        if (!value) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Array) {
        if (Array.isArray(value).length === 0) {
          resCount++;
        }
      }
    } else if (field.algorithm === CompareMap.notNull) {
      if (
        field.fieldType === ControlTypeEnum.Int ||
        field.fieldType === ControlTypeEnum.Float ||
        field.fieldType === ControlTypeEnum.Double ||
        field.fieldType === DATATYPE.int.toLocaleLowerCase() ||
        field.fieldType === ControlTypeEnum.Date ||
        field.fieldType === ControlTypeEnum.Input ||
        field.fieldType === ControlTypeEnum.Image
      ) {
        if (value) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Array) {
        if (Array.isArray(value).length > 0) {
          resCount++;
        }
      }
    } else if (field.algorithm === CompareMap.contains) {
      if (
        field.fieldType === ControlTypeEnum.Input ||
        field.fieldType === ControlTypeEnum.Enum
      ) {
        if (`${value}`.includes(field.value)) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Array) {
        try {
          const arr = Array.from(JSON.parse(field.value));
          let fCount = 0;
          arr.forEach(i => {
            let fIdx = Array.from(value).find(v => v === i);
            if (fIdx !== -1) {
              fCount++;
            }
          });
          if (fCount === arr.length) {
            resCount++;
          }
        } catch (error) {
          return;
        }
      }
    } else if (field.algorithm === CompareMap.notContains) {
      if (
        field.fieldType === ControlTypeEnum.Input ||
        field.fieldType === ControlTypeEnum.Enum
      ) {
        if (!value.includes(field.value)) {
          resCount++;
        }
      } else if (field.fieldType === ControlTypeEnum.Array) {
        try {
          const arr = Array.from(JSON.parse(field.value));
          let fCount = 0;
          arr.forEach(i => {
            let fIdx = Array.from(value).find(v => v === i);
            if (!fIdx) {
              fCount++;
            }
          });
          if (fCount > 0) {
            resCount++;
          }
        } catch (error) {
          return;
        }
      }
    }
  }
  return resCount;
};
