<template>
  <div>
    <div :class="$style['header-wrapper']">
      <div :class="$style.toolbar">
        <x-select
          v-if="type !== 'case'"
          v-model="classify"
          :data="classifyList"
          :class="$style.toolbar__select"
          @change="handleFilterChange"
        />
        <a-input
          :class="$style.toolbar__input"
          :placeholder="$t('common.action.search')"
          @pressEnter="handleFilterChange"
          @blur="handleFilterChange"
          v-model="keyword"
        >
          <x-icon type="tc-icon-search" slot="prefix" />
        </a-input>
      </div>
      <a-button
        v-if="$p.action('UPDATE')"
        type="primary"
        icon="plus"
        @click="handleCaseModal(null)"
      >
        {{ addButtonText }}
      </a-button>
    </div>
    <x-data-table
      ref="table"
      :columns="columns"
      :service="service"
      :scroll="{
        x: '100%',
        y: '100%',
      }"
      :class="$style.table"
    >
      <div slot="headerLeft"></div>

      <template #headerRight> </template>
    </x-data-table>
  </div>
</template>

<script>
import { Vue, Prop, Component } from 'vue-property-decorator';

import { DataSourceService, UploadService } from '@triascloud/services';
import EditCase from '../components/edit-case.vue';
import EditNews from '../components/edit-news.vue';
import EditDownload from '../components/edit-download.vue';
import { createModal } from '@triascloud/x-components';
import { getClassifyList } from '@/services/configuration/classifyManagement';
import { globalVueI18n } from '@triascloud/i18n';
import { classifyType } from '@/enum/classify';

@Component()
export default class CommonTable extends Vue {
  @Prop({ type: String, default: '' }) addButtonText;
  @Prop({ type: String, default: '' }) editButtonText;
  @Prop({ type: Object }) requestList;
  @Prop({ type: String, default: 'case' }) type;

  classify = '';
  keyword = '';
  classifyList = [];

  data = {
    current: 1,
    size: 10,
  };

  get columns() {
    let defaultColumns = [
      {
        dataIndex: 'img',
        title: this.$t('common.img'),
        customRender: res =>
          res ? (
            <x-oss-image
              ossPath={res}
              fit={'contain'}
              class={this.$style['table-img']}
              resize={{ l: 270, m: 'lfit' }}
              onClick={this.previewImg}
            />
          ) : (
            ''
          ),
      },
      {
        dataIndex: 'title',
        title: this.$t('common.title'),
      },
      {
        dataIndex: 'label',
        title: this.$t('common.label.name'),
      },
      {
        dataIndex: 'classify',
        title: this.$t('website.category'),
      },
      {
        dataIndex: 'pushTime',
        title: this.$t('common.time'),
        customRender: time =>
          time ? this.$moment(time).format('YYYY-MM-DD HH:mm') : '',
      },
      {
        dataIndex: 'tenant',
        title: this.$t('website.tenant'),
      },
      {
        dataIndex: 'creator',
        title: this.$t('website.creator'),
      },
      {
        title: this.$t('common.action.operating'),
        key: 'action',
        width: 200,
        customRender: (_, res) => {
          return (
            <div class={this.$style.operate}>
              {res.isTop ? (
                <span onClick={() => this.multiOperate(res.pkId, 2)}>
                  {this.$t('common.action.cancel') +
                    this.$t('common.action.top')}
                </span>
              ) : (
                <span onClick={() => this.multiOperate(res.pkId, 1)}>
                  {this.$t('common.action.top')}
                </span>
              )}
              <span onClick={() => this.multiOperate(res.pkId, 3)}>
                {this.$t('common.action.delete')}
              </span>
              <span onClick={() => this.handleCaseModal(res.pkId)}>
                {this.$t('common.action.edit2')}
              </span>
              {this.type === 'download' && (
                <span onClick={() => this.downloadFile(res)}>
                  {this.$t('common.download')}
                </span>
              )}
            </div>
          );
        },
      },
    ];
    if (this.type === 'case') {
      defaultColumns.splice(3, 1);
    }
    if (!this.$p.action('UPDATE')) {
      defaultColumns = defaultColumns.filter(item => item.key !== 'action');
    }
    return defaultColumns.map(item => ({
      ...item,
      width: item.width || 150,
      sorter: false,
      filter: false,
      align: 'left',
      ellipsis: false,
    }));
  }

  get service() {
    const service = new DataSourceService(this.requestList.getTableList);
    const classify = this.classify === '' ? [] : [this.classify];
    service.getList = ({ size, skip }) =>
      this.requestList
        .getMediaList({
          current: Math.ceil(skip / size) + 1,
          size,
          keyword: this.keyword,
          classify: this.type !== 'case' ? classify : undefined,
        })
        .then(res => {
          return {
            ...res,
            data: res ? res.records : [],
            total: res ? res.total : 0,
          };
        })
        .catch(() => ({
          data: [],
          total: 0,
        }));
    return service;
  }

  created() {
    if (this.type === 'download') {
      this.getClassifyList(classifyType.download);
    } else if (this.type === 'news') {
      this.getClassifyList(classifyType.news);
    }
  }

  previewImg(url) {
    this.$preview([{ url }]);
  }

  async getClassifyList(params) {
    const data = await getClassifyList(params);
    this.classifyList = data
      .map(item => ({
        text: item.classifyName,
        value: item.pkId,
      }))
      .sort((a, b) => a.value - b.value);
    this.classifyList.unshift({
      value: '',
      text: '全部',
    });
  }

  async multiOperate(pkId, type) {
    let msg = '';
    if (type === 1) {
      await this.requestList.stickTopic(pkId);
      msg = globalVueI18n.translate('common.tips.top');
    } else if (type === 2) {
      await this.requestList.cancelStickTopic(pkId);
      msg = globalVueI18n.translate('common.tips.cancelTop');
    } else if (type === 3) {
      let confirmMsg = '';
      if (this.type === 'case') {
        confirmMsg = 'website.case.deleteConfirm';
      } else if (this.type === 'news') {
        confirmMsg = 'website.news.deleteConfirm';
      } else if (this.type === 'download') {
        confirmMsg = 'website.download.deleteConfirm';
      }
      await this.$confirm(this.$t(confirmMsg));
      await this.requestList.deleteMedia(pkId);
      msg = globalVueI18n.translate('common.tips.delete');
    }
    this.handleFilterChange();
    this.$message.success(msg);
  }

  async handleCaseModal(pkId) {
    let initParams = null,
      component = null,
      promptText = this.addButtonText,
      mode = 'add';
    if (pkId) {
      initParams = await this.requestList.getMediaDetail(pkId);
      promptText = this.editButtonText;
      mode = 'edit';
    }
    if (this.type === 'case') {
      component = EditCase;
    } else if (this.type === 'news') {
      component = EditNews;
    } else if (this.type === 'download') {
      component = EditDownload;
    }

    const template = await createModal(
      h =>
        h(component, {
          props: { initParams, mode },
          on: {
            refresh: () => {
              this.handleFilterChange();
              this.$message.success(this.$t('common.tips.save'));
              template.visible = false;
            },
            close: () => {
              template.visible = false;
            },
          },
        }),
      {
        title: promptText,
        width: 880,
        wrapClassName: this.$style['solution-modal'],
      },
    );
  }

  async downloadFile(res) {
    if (res.fileUrl) {
      const { ossPath, name } = JSON.parse(res.fileUrl)[0];
      const customService = new UploadService('/oss/oss');
      const abPath = await customService.getAuth(ossPath);
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = abPath;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async handleFilterChange() {
    await this.$nextTick();
    this.$refs.table.loadData();
  }
}
</script>

<style lang="less" module>
.header-wrapper {
  display: flex;
  justify-content: space-between;
}
.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .toolbar__select {
    width: 140px;
    margin-right: 20px;
  }
  .toolbar__input {
    width: auto;
    :global .ant-input {
      width: 120px;
      transition: width 0.5s;
      &:focus {
        width: 200px;
      }
    }
  }
}
.table {
  height: calc(100% - 36px);
}
.table-img {
  margin: 8px 0;
  height: 44px;
  max-width: 102px;
  max-height: 44px;
}
.operate {
  display: flex;
  justify-content: space-between;
  color: var(--primary);
  padding: 0 5px;
  cursor: pointer;
}
.solution-modal {
  :global .ant-modal-title {
    text-align: left;
  }
  :global .ant-modal-body {
    display: flex;
    overflow: hidden;
    padding: 0;
  }
}
</style>
