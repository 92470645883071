import request from '../request';
const basePath = '/configuration/operating/instruction';
//新增
export function addGuide(data) {
  return request(`${basePath}/management/add`, {
    method: 'POST',
    body: data,
  });
}
//复制
export function guideCopy(pkId) {
  return request(`${basePath}/management/copy`, {
    method: 'POST',
    body: pkId,
  });
}
//详情
export function guideDetail(pkId) {
  return request(`${basePath}/management/detail?pkId=${pkId}`, {
    method: 'GET',
  });
}

//编辑
export function editGuide(data) {
  return request(`${basePath}/management/edit`, {
    method: 'POST',
    body: data,
  });
}

//列表
export function guideList(data) {
  return request(`${basePath}/management/list`, {
    method: 'GET',
    body: data,
  });
}

//删除
export function delGuide(pkId) {
  return request(`${basePath}/management/remove?pkId=${pkId}`, {
    method: 'DELETE',
  });
}

//查看
export function viewGuide() {
  return request(`${basePath}/public/config/detail`, {
    method: 'GET',
  });
}
//屏蔽企业
export function disabledList(data) {
  return request(`${basePath}/shield/tenant`, {
    method: 'POST',
    body: data,
  });
}

//查看屏蔽企业列表
export function shieldTenant() {
  return request(`${basePath}/shield/tenant/list`, {
    method: 'GET',
  });
}

//查看所有企业列表
export function companyListService(current, size, keyWord = '') {
  return request(
    `${basePath}/tenant/list?current=${current}&size=${size}&keyword=${keyWord}`,
    {
      method: 'GET',
    },
  );
}
