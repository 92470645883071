import request from '../request';
const basePath = '/resource/cloud-img-group';

export const getCollectionList = report =>
  request(`${basePath}/collectionList`, {
    method: 'POST',
    body: {
      fieIds: [],
      filterGroup: {
        group: [],
        logic: '',
      },
      sorters: [],
      ...report,
    },
  });
export const getCloudGroup = () =>
  request(`${basePath}/list`, { method: 'POST' });

export const getImgByGroup = report =>
  request(`${basePath}/listImg`, {
    method: 'POST',
    body: report,
  });

/**
 * 创建图库分组
 * @param data
 * @returns {*}
 */
export const createImgGroup = data => {
  return request(`${basePath}/save`, {
    method: 'POST',
    body: data,
  });
};

/**
 * 移除分组
 * @param data
 * @returns {*}
 */
export const removeImgGroup = data => {
  return request(`${basePath}/remove`, {
    method: 'DELETE',
    body: data,
  });
};

/**
 * 更新图库分组信息
 * @param data
 * @returns {*}
 */
export const updateImgGroup = data => {
  return request(`${basePath}/update`, {
    method: 'POST',
    body: data,
  });
};

/**
 * 获取回收站图库列表
 * @param {*} data
 */
export const getRecycleList = data => {
  return request(`${basePath}/recycleList`, {
    method: 'POST',
    body: data,
  });
};
