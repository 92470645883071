<template>
  <div :class="$style.subscribeContainer">
    <a-spin :spinning="spinning">
      <a-form-model
        layout="vertical"
        :model="form"
        :rules="rules"
        ref="topicForm"
      >
        <div v-if="connectorType !== 'TIMING'">
          <a-row :gutter="10" :class="$style.rowHeight">
            <a-col :span="12" :class="[$style.pr10]">
              <a-form-model-item label="主题名称" prop="name">
                <a-input
                  placeholder="请输入主题名称"
                  :maxLength="8"
                  v-model="form.name"
                  @change="$emit('change-name', $event.target.value)"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="10" :class="$style.rowHeight">
            <a-col :span="4">
              <a-form-model-item label="主题分类" prop="classification">
                <a-select
                  placeholder="请选择"
                  v-model="form.classification"
                  :filter-option="false"
                  :getPopupContainer="triggerNode => triggerNode.parentNode"
                  @change="changeClassification"
                >
                  <a-select-option value="SYSTEM">系统返回</a-select-option>
                  <a-select-option value="RULE">规则匹配</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8" v-if="form.classification === 'SYSTEM'">
              <a-form-model-item prop="systemTopic">
                <div slot="label">&nbsp;</div>
                <a-select
                  placeholder="请选择"
                  v-model="form.systemTopic"
                  :getPopupContainer="triggerNode => triggerNode.parentNode"
                  :filter-option="false"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="item in themeList"
                    :key="item.value"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8" v-if="form.classification === 'RULE'">
              <a-form-model-item label="校验规则" prop="rulePath">
                <a-input
                  v-model="form.rulePath"
                  placeholder="主题分类Jsonpath校验规则"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8" v-if="form.classification === 'RULE'">
              <a-form-model-item label="校验结果" prop="ruleValue">
                <a-input
                  v-model="form.ruleValue"
                  placeholder="主题分类Jsonpath校验结果"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="10" :class="$style.rowHeight">
            <a-col :span="8">
              <a-form-model-item label="设备号" prop="deviceCodePath">
                <a-input
                  placeholder="请输入Jsonpath匹配规则"
                  v-model="form.deviceCodePath"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="设备号原格式">
                <a-select
                  v-model="form.deviceFormate"
                  :getPopupContainer="triggerNode => triggerNode.parentNode"
                >
                  <a-select-option :value="0">文本</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="10" :class="$style.rowHeight">
            <a-col :span="12" style="padding: 0">
              <a-col :span="8">
                <a-form-model-item label="创建时间" prop="timeType">
                  <a-select
                    v-model="form.timeType"
                    :getPopupContainer="triggerNode => triggerNode.parentNode"
                  >
                    <a-select-option value="RULE">规则匹配</a-select-option>
                    <a-select-option value="AUTO">自动生成</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="16" v-if="form.timeType === 'RULE'">
                <a-form-model-item label="时间规则" prop="timePath">
                  <a-input
                    placeholder="请输入Jsonpath匹配规则"
                    v-model="form.timePath"
                  />
                </a-form-model-item>
              </a-col>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="创建时间原格式" prop="timeFormat">
                <a-select
                  v-model="form.timeFormat"
                  :getPopupContainer="triggerNode => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="item in dateList"
                    :key="item.value"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
        <div v-else>
          <a-row :gutter="10" :class="$style.rowHeight">
            <a-col :span="4">
              <a-form-model-item label="订阅频率">
                <a-select
                  placeholder="请选择"
                  v-model="form.dateType"
                  @change="changeDateType"
                >
                  <a-select-option value="HOURLY">每小时</a-select-option>
                  <a-select-option value="DAILY">每天</a-select-option>
                  <a-select-option value="WEEKLY">每周</a-select-option>
                  <a-select-option value="MONTHLY">每月</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col
              :span="8"
              v-if="form.dateType === 'WEEKLY' || form.dateType === 'MONTHLY'"
            >
              <a-form-model-item label="订阅天数" prop="cycle">
                <a-select
                  placeholder="请选择"
                  v-model="form.cycle"
                  mode="multiple"
                  :maxTagCount="3"
                >
                  <a-select-option
                    v-for="(item, idx) in dayOptions"
                    :value="idx"
                    :key="idx"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="订阅时间" prop="subscribeTime">
                <a-time-picker
                  v-model="form.subscribeTime"
                  style="width:100%;"
                  :format="form.dateType === 'HOURLY' ? 'mm:ss' : 'HH:mm:ss'"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="10">
            <a-col :span="18" :class="$style.pl10">
              <a-form-model-item>
                <span slot="label">
                  设备轮询
                  <a-tooltip
                    placement="right"
                    :overlayStyle="{ maxWidth: '100%' }"
                  >
                    <a-icon type="info-circle" />
                    <div slot="title">
                      开启后可将指定设备的部分信息（例如设备码）作为接口入参来完成请求！
                    </div>
                  </a-tooltip>
                </span>
                <a-radio-group
                  v-model="form.polling"
                  :default-value="1"
                  @change="changePolling"
                >
                  <a-radio value="CLOSE">
                    关闭
                  </a-radio>
                  <a-radio value="ALL">
                    全部设备
                  </a-radio>
                  <a-radio value="DISTRIBUTION">
                    已分配设备
                  </a-radio>
                  <a-radio value="CUSTOM">
                    自定义设备
                  </a-radio>
                </a-radio-group>
                <span
                  v-if="form.polling === 'CUSTOM'"
                  :class="$style.textPrimary"
                  @click="addDevice"
                >
                  添加自定义设备
                </span>
                <span
                  v-if="form.polling === 'CUSTOM'"
                  :class="$style.textPrimary"
                  >{{ selectedLen }}</span
                >
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="10" style="margin-top: 10px">
            <a-col
              v-for="(item, idx) in deviceParamsList"
              :key="item.id"
              :class="$style.list"
            >
              <a-col :span="10" style="display: flex;align-items: center">
                <div style="width: 55px">参数{{ idx + 1 }}</div>
                <a-select
                  v-model="item.paramId"
                  placeholder="请选择请求入参"
                  :getPopupContainer="triggerNode => triggerNode.parentNode"
                >
                  <a-select-opt-group v-if="paramsQueryList.length">
                    <span slot="label">Query</span>
                    <a-select-option
                      v-for="t in paramsQueryList"
                      :key="t.id"
                      :value="t.identifier + '_QUERY'"
                    >
                      <div
                        style="display: flex;justify-content: space-between;align-items: center;"
                      >
                        <div
                          :title="t.name"
                          style="width: 230px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                        >
                          {{ t.name }}
                        </div>
                        <a-tag
                          v-if="formateType(t.type, 'color')"
                          :color="formateType(t.type, 'color')"
                          style="height: 24px"
                        >
                          {{ formateType(t.type, 'type') }}
                        </a-tag>
                      </div>
                    </a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group v-if="paramsHeaderList.length">
                    <span slot="label">Header</span>
                    <a-select-option
                      v-for="t in paramsHeaderList"
                      :key="t.id"
                      :value="t.identifier + '_HEADER'"
                    >
                      <div
                        style="display: flex;justify-content: space-between;align-items: center;"
                      >
                        <div
                          :title="t.name"
                          style="width: 230px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                        >
                          {{ t.name }}
                        </div>
                        <a-tag
                          v-if="formateType(t.type, 'color')"
                          :color="formateType(t.type, 'color')"
                          style="height: 24px"
                        >
                          {{ formateType(t.type, 'type') }}
                        </a-tag>
                      </div>
                    </a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group v-if="paramsBodyList.length">
                    <span slot="label">Body</span>
                    <a-select-option
                      v-for="t in paramsBodyList"
                      :key="t.id"
                      :value="t.identifier + '_BODY'"
                    >
                      <div
                        style="display: flex;justify-content: space-between;align-items: center;"
                      >
                        <div
                          :title="t.name"
                          style="width: 230px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                        >
                          {{ t.name }}
                        </div>
                        <a-tag
                          v-if="formateType(t.type, 'color')"
                          :color="formateType(t.type, 'color')"
                          style="height: 24px"
                        >
                          {{ formateType(t.type, 'type') }}
                        </a-tag>
                      </div>
                    </a-select-option>
                  </a-select-opt-group>
                </a-select>
              </a-col>
              <a-col :span="1">
                等于
              </a-col>
              <a-col :span="8">
                <a-select
                  v-model="item.selected"
                  :getPopupContainer="triggerNode => triggerNode.parentNode"
                >
                  <a-select-option
                    v-for="e in dListOptions"
                    :value="e.id"
                    :key="e.id"
                  >
                    {{ e.label }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="2">
                <x-icon
                  type="tc-icon-delete"
                  :class="$style.deleteIcon"
                  @click="deleteDeviceParam(idx)"
                />
              </a-col>
            </a-col>
          </a-row>
          <div
            v-if="form.polling !== 'CLOSE'"
            :class="$style.addIcon"
            @click="addDeviceParam"
          >
            <a-icon type="plus" style="font-size: 20px" />
            <span>关联设备参数</span>
          </div>
          <a-row :gutter="10" :class="$style.rowHeight">
            <a-col :span="8">
              <a-form-model-item label="自动分页" prop="autoDevidePage">
                <a-select
                  v-model="form.autoDevidePage"
                  placeholder="自动关联分页参数"
                  allowClear
                  :getPopupContainer="triggerNode => triggerNode.parentNode"
                >
                  <a-select-opt-group v-if="paramsQueryList.length">
                    <span slot="label">Query</span>
                    <a-select-option
                      v-for="t in paramsQueryList"
                      :key="t.id"
                      :value="t.identifier + '_QUERY'"
                    >
                      <div
                        style="display: flex;justify-content: space-between;align-items: center;"
                      >
                        <div
                          :title="t.name"
                          style="width: 230px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                        >
                          {{ t.name }}
                        </div>
                        <a-tag
                          v-if="formateType(t.type, 'color')"
                          :color="formateType(t.type, 'color')"
                          style="height: 24px"
                        >
                          {{ formateType(t.type, 'type') }}
                        </a-tag>
                      </div>
                    </a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group v-if="paramsHeaderList.length">
                    <span slot="label">Header</span>
                    <a-select-option
                      v-for="t in paramsHeaderList"
                      :key="t.id"
                      :value="t.identifier + '_HEADER'"
                    >
                      <div
                        style="display: flex;justify-content: space-between;align-items: center;"
                      >
                        <div
                          :title="t.name"
                          style="width: 230px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                        >
                          {{ t.name }}
                        </div>
                        <a-tag
                          v-if="formateType(t.type, 'color')"
                          :color="formateType(t.type, 'color')"
                          style="height: 24px"
                        >
                          {{ formateType(t.type, 'type') }}
                        </a-tag>
                      </div>
                    </a-select-option>
                  </a-select-opt-group>
                  <a-select-opt-group v-if="paramsBodyList.length">
                    <span slot="label">Body</span>
                    <a-select-option
                      v-for="t in paramsBodyList"
                      :key="t.id"
                      :value="t.identifier + '_BODY'"
                    >
                      <div
                        style="display: flex;justify-content: space-between;align-items: center;"
                      >
                        <div
                          :title="t.name"
                          style="width: 230px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                        >
                          {{ t.name }}
                        </div>
                        <a-tag
                          v-if="formateType(t.type, 'color')"
                          :color="formateType(t.type, 'color')"
                          style="height: 24px"
                        >
                          {{ formateType(t.type, 'type') }}
                        </a-tag>
                      </div>
                    </a-select-option>
                  </a-select-opt-group>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col v-if="form.autoDevidePage" :span="4">
              <a-form-model-item label="分页间隔">
                <a-input-number
                  v-model="form.pageInterval"
                  :min="10"
                  :max="1000"
                  type="number"
                />
                <span style="margin-left: 10px">ms</span>
              </a-form-model-item>
            </a-col>
            <a-col v-if="form.autoDevidePage" :span="6">
              <a-form-model-item label="分页总数">
                <a-select placeholder="请选择" v-model="form.pageTotalType">
                  <a-select-option value="FIXED">固定数值</a-select-option>
                  <a-select-option value="PATH">JsonPath匹配</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col
              :span="6"
              v-if="form.autoDevidePage && form.pageTotalType === 'FIXED'"
            >
              <a-form-model-item>
                <span slot="label">&nbsp;</span>
                <a-input-number
                  v-model="form.pageTotalNumber"
                  :min="1"
                  :max="512"
                  type="number"
                />
                <span style="margin-left: 10px">条</span>
              </a-form-model-item>
            </a-col>
            <a-col
              :span="6"
              v-if="form.autoDevidePage && form.pageTotalType !== 'FIXED'"
            >
              <a-form-model-item
                label="分页总数规则"
                prop="pageTotalPath"
                key="pageTotalPath"
              >
                <a-input
                  placeholder="请输入JsonPath解析规则"
                  v-model="form.pageTotalPath"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="10" :class="$style.rowHeight">
            <a-col :span="8">
              <a-form-model-item label="设备号" prop="deviceCodePath">
                <a-input
                  placeholder="请输入Jsonpath匹配规则"
                  v-model="form.deviceCodePath"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="设备号原格式">
                <a-select
                  v-model="form.deviceFormate"
                  :getPopupContainer="triggerNode => triggerNode.parentNode"
                >
                  <a-select-option :value="0">文本</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="10" :class="$style.rowHeight">
            <a-col :span="12" style="padding: 0">
              <a-col :span="8">
                <a-form-model-item label="创建时间" prop="timeType">
                  <a-select
                    v-model="form.timeType"
                    :getPopupContainer="triggerNode => triggerNode.parentNode"
                  >
                    <a-select-option value="RULE">规则匹配</a-select-option>
                    <a-select-option value="AUTO">自动生成</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="16" v-if="form.timeType === 'RULE'">
                <a-form-model-item label="时间规则" prop="timePath">
                  <a-input
                    placeholder="请输入Jsonpath匹配规则"
                    v-model="form.timePath"
                  />
                </a-form-model-item>
              </a-col>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="创建时间原格式" prop="timeFormat">
                <a-select
                  v-model="form.timeFormat"
                  :getPopupContainer="triggerNode => triggerNode.parentNode"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="item in dateList"
                    :key="item.value"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
        <a-form-model-item :wrapper-col="{ span: 24 }">
          <span slot="label">
            自定义字段：
            <a-button icon="plus" type="link" @click="chooseParameter('res')">
              添加出参
            </a-button>
          </span>
          <ApiTable
            :table-data="resData"
            table-type="res"
            :platformId="platformId"
            :store="outStore"
          />
        </a-form-model-item>
        <a-row :gutter="10">
          <a-col :span="16">
            <a-form-model-item label="存储规则" prop="storageRules">
              <a-radio-group v-model="form.storageRules">
                <a-radio value="EVERY_TIME">
                  每次存储
                </a-radio>
                <a-radio value="RULE_ALL">
                  规则存储（全部符合）
                </a-radio>
                <a-radio value="RULE_OR">
                  规则存储（任一符合）
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-row :gutter="10" v-if="form.storageRules !== 'EVERY_TIME'">
        <a-col
          v-for="(item, idx) in ruleList"
          :key="item.id"
          :class="$style.list"
        >
          <a-col :span="10" style="display: flex;align-items: center">
            <div style="width: 55px">条件{{ idx + 1 }}</div>
            <a-select
              v-model="item.typeId"
              @change="val => changeCondition(val, idx)"
            >
              <a-select-option
                v-for="t in ruleSelectList"
                :key="t.id"
                :value="t.id"
              >
                <div
                  style="display: flex;justify-content: space-between;align-items: center;"
                >
                  <div
                    :title="t.name"
                    style="width: 230px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                  >
                    {{ t.name }}
                  </div>
                  <a-tag
                    v-if="formateType(t.type, 'color')"
                    :color="formateType(t.type, 'color')"
                    style="height: 24px"
                  >
                    {{ formateType(t.type, 'type') }}
                  </a-tag>
                </div>
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="4">
            <a-select
              v-model="item.rule"
              @change="
                val => {
                  changeRule(val, item);
                }
              "
            >
              <a-select-option
                v-for="t in item.ruleSelectList"
                :key="t.value"
                :value="t.value"
              >
                {{ t.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="8">
            <a-date-picker
              v-if="item.type === 'date'"
              v-model="item.value"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              style="width: 100%;"
              placeholder="选择时间"
              @ok="
                v => {
                  handleTimeChange(v, item, 'ok');
                }
              "
              @change="
                v => {
                  handleTimeChange(v, item, 'change');
                }
              "
            />
            <a-switch
              v-else-if="item.type === 'bool'"
              v-model="item.value"
              checked-children="开"
              un-checked-children="关"
            />
            <a-select
              v-else-if="item.type === 'enum'"
              placeholder="请选择设备"
              v-model="item.value"
              :getPopupContainer="triggerNode => triggerNode.parentNode"
            >
              <a-select-option
                v-for="e in item.selectList"
                :value="e.value"
                :key="e.value"
              >
                {{ e.key }}
              </a-select-option>
            </a-select>
            <a-input
              v-else-if="showInput(item)"
              v-model="item.value"
              allowClear
              placeholder="请输入条件值"
            />
            <a-input
              v-else-if="
                getIntType(item) &&
                  ['EMPTY', 'NOT_EMPTY'].indexOf(item.rule) === -1
              "
              type="number"
              v-model="item.value"
              @blur="validateMin(item)"
              allowClear
            />
          </a-col>
          <a-col :span="2">
            <x-icon
              type="tc-icon-delete"
              :class="$style.deleteIcon"
              @click="deleteRule(idx)"
            />
          </a-col>
        </a-col>
      </a-row>
      <div
        v-if="form.storageRules !== 'EVERY_TIME' && ruleList.length < 100"
        :class="$style.addIcon"
        @click="addRule"
      >
        <a-icon type="plus" style="font-size: 20px" />
        <span>添加条件</span>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ApiTable from '../api-config/api-table.vue';
import ApiParams from '../api-config/api-params.vue';
import { createFormModal } from '@triascloud/x-components';
import { ControlTypeEnum } from '@/views/connector/platform-manage/develop/data-template/template-design/form/controls/enum.js';
import { uuid } from '@triascloud/utils';
import { getSubscribeTheme } from '@/services/iot-platform/dataManage';
import DeviceSelect from '@/views/connector/platform-manage/develop/data-manage/device-select.vue';
import { clone } from 'lodash';
import {
  eachSetJsonPath,
  eachSetJsonPathForStore,
  flatFieldsId,
} from '../api-config/utils';
import { ReactiveStore } from '../api-config/debug-api/utils';
import { PLACEHOLDER_OUT } from '../api-config/components/utils';
@Component({
  components: { ApiTable },
})
export default class DataManageForm extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  topic;
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: String, default: 'add' }) editFlag;
  @Prop({ type: String, default: 'PASSIVE' }) connectorType;
  @Prop({ type: Array, default: () => [] }) paramsOut;
  @Prop({ type: Array, default: () => [] }) paramsIn;
  @Prop({ type: Array, default: () => [] }) paramsBody;
  @Prop({ type: Array, default: () => [] }) paramsHeader;
  @Prop({ type: String, default: '' }) productId;

  mounted() {
    this.getThemeList();
  }

  @Watch('topic', { immediate: true, deep: true })
  changeTopic(newVal) {
    if (this.editFlag === 'edit') {
      if (newVal.customFields) {
        this.initForm();
      } else {
        this.form.classification = 'SYSTEM';
        this.form.systemTopic = 'ALL';
        this.form.timeType = 'RULE';
        this.form.timeFormat = 'TIME_STAMP13';
      }
    }
  }

  outStore = {};
  @Watch('paramsOut', { immediate: true, deep: true })
  changeParamsOut(newVal) {
    let data = flatFieldsId(newVal, {});
    this.outStore = new ReactiveStore(data);
  }

  @Watch('connectorType', { immediate: true })
  changeType(newVal) {
    this.connectorType = newVal;
  }

  @Watch('paramsIn', { immediate: true, deep: true })
  chanegParams(newVal) {
    if (newVal?.length) {
      this.initParamsList();
    }
  }

  async addDevice() {
    if (this.productId === '') {
      this.$message.warning('请先选择所属产品');
      return;
    }
    this.deviceIds = await createFormModal(
      () => (
        <DeviceSelect
          productId={this.productId}
          platformId={this.platformId}
          deviceIds={this.deviceIds}
        />
      ),
      {
        width: '880px',
        title: '选择设备列表',
      },
    );
  }

  deviceIds = [];
  paramsQueryList = [];
  paramsHeaderList = [];
  paramsBodyList = [];

  initParamsList() {
    const query = this.changeSelectRule(this.paramsIn);
    query.forEach(v => {
      if (v.type === ControlTypeEnum.Input || v.type === 'int32') {
        const o = {
          id: v.id,
          name: v.name,
          type: v.type,
          flag: 'query',
          identifier: v.identifier,
        };
        this.paramsQueryList.push(o);
      }
    });
    const body = this.changeSelectRule(this.paramsBody);
    body.forEach(v => {
      if (v.type === ControlTypeEnum.Input || v.type === 'int32') {
        const o = {
          id: v.id,
          name: v.name,
          type: v.type,
          flag: 'body',
          identifier: v.identifier,
        };
        this.paramsBodyList.push(o);
      }
    });
    this.paramsHeaderList = this.paramsHeader.map(v => {
      return {
        id: v.id,
        name: v.identifier,
        type: 'text',
        flag: 'header',
        identifier: v.identifier,
      };
    });
    if (this.topic?.deviceParams) {
      this.changeDeviceP(this.topic.deviceParams);
    }
  }

  form = {
    name: '',
    classification: 'SYSTEM',
    systemTopic: 'ALL',
    rulePath: '',
    ruleValue: '',
    deviceCodePath: '',
    deviceFormate: 0,
    timeType: 'RULE',
    timePath: '',
    timeFormat: 'TIME_STAMP13',
    customFields: [],
    storageRules: 'EVERY_TIME',
    expressions: [],
    polling: 'CLOSE',
    dateType: 'HOURLY',
    cycle: [],
    subscribeTime: '',
    autoDevidePage: undefined,
    pageInterval: 10,
    pageTotalType: 'FIXED',
    pageTotalNumber: 1,
    pageTotalPath: '',
  };
  rules = {
    name: [
      {
        required: true,
        message: '主题名称不能为空',
      },
    ],
    deviceCodePath: [
      {
        required: true,
        message: '设备号不能为空',
      },
    ],
    timePath: [
      {
        required: true,
        message: '时间规则不能为空',
      },
    ],
    rulePath: [
      {
        required: true,
        message: '主题分类Jsonpath校验规则不能为空',
      },
    ],
    ruleValue: [
      {
        required: true,
        message: '主题分类Jsonpath校验结果不能为空',
      },
    ],
    subscribeTime: [
      {
        required: true,
        message: '时间不能为空',
      },
    ],
    pageTotalPath: [
      {
        required: true,
        message: '分页总数规则不能为空',
      },
    ],
    cycle: [
      {
        required: true,
        message: '订阅天数不能为空',
      },
    ],
  };

  dayList = {
    WEEKLY: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
    MONTHLY: [],
  };
  dayOptions = [];

  changeDateType(val, arr = []) {
    if (val === 'WEEKLY') {
      this.dayOptions = this.dayList.WEEKLY;
      this.$nextTick(() => {
        this.form.cycle = arr.length ? arr : 0;
      });
    } else if (val === 'MONTHLY') {
      this.dayList.MONTHLY = [];
      for (let i = 1; i < 32; i++) {
        this.dayList.MONTHLY.push(`${i}号`);
      }
      this.dayOptions = this.dayList.MONTHLY;
      this.$nextTick(() => {
        this.form.cycle = arr.length ? arr : 0;
      });
    }
  }

  deviceParamsList = [];
  paramsSelectList = [];

  deleteDeviceParam(idx) {
    this.deviceParamsList.splice(idx, 1);
  }

  changePolling(val) {
    if (val.target.value === 'CLOSE') {
      this.deviceParamsList = [];
    }
  }

  addDeviceParam() {
    this.deviceParamsList.push({
      id: uuid(),
      paramId: undefined,
      name: '',
      selected: 'CODE',
    });
  }

  dListOptions = [
    {
      id: 'CODE',
      label: '设备码',
    },
    {
      id: 'ID',
      label: '设备ID',
    },
    {
      id: 'KEY',
      label: '原始密钥',
    },
  ];

  changeClassification(val) {
    if (val === 'SYSTEM') {
      this.$refs.topicForm.clearValidate(['rulePath', 'ruleValue']);
    }
  }

  getIntType(item) {
    return (
      item.type === ControlTypeEnum.Int ||
      item.type === 'int32' ||
      item.type === ControlTypeEnum.Float ||
      item.type === ControlTypeEnum.Double
    );
  }

  showInput(item) {
    const a = ['EMPTY', 'NOT_EMPTY'].indexOf(item.rule) === -1;
    const b = this.getIntType(item);
    return a && !b;
  }

  validateMin(item) {
    let value = Number(item.value);
    if (item.type === ControlTypeEnum.Int || item.type === 'int32') {
      const v = value.toString().split('.').length > 1;
      if (v) {
        item.value = +value.toString().split('.')[0];
      }
      if (value > 2147483648) {
        item.value = 2147483648;
      } else if (value < -2147483648) {
        item.value = -2147483648;
      }
    } else if (item.type === ControlTypeEnum.Float) {
      if (value > 3.4e38) {
        item.value = 3.4e38;
      } else if (value < -3.4e38) {
        item.value = -3.4e38;
      }
    } else {
      if (value > 1.79e308) {
        item.value = 1.79e308;
      } else if (value < -1.79e308) {
        item.value = -1.79e308;
      }
    }
  }

  spinning = false;
  initForm() {
    this.spinning = true;
    const row = this.topic;
    const form = this.form;
    form.name = row.name;
    if (this.connectorType !== 'TIMING') {
      form.classification = row.classification;
      if (form.classification === 'SYSTEM') {
        form.systemTopic = row.systemTopic;
      } else {
        form.rulePath = row.rulePath;
        form.ruleValue = row.ruleValue;
      }
    } else {
      form.dateType = row.cycle;
      const time = `2022-12-12 ${row.hour}:${row.minute}:${row.second}`;
      form.subscribeTime = this.$moment(time).format('YYYY-MM-DD HH:mm:ss');
      this.changeDateType(row.cycle, row.updateDate);
      form.polling = row.polling;
      this.deviceIds = row?.deviceIds || [];
      if (row.pageParams) {
        form.pageInterval = row.pageParams.pageInterval;
        form.pageTotalType = row.pageParams.pageType;
        form.autoDevidePage = `${row.pageParams.identifier}_${row.pageParams.paramPath}`;
        if (form.pageTotalType === 'FIXED') {
          form.pageTotalNumber = row.pageParams.count;
        } else {
          form.pageTotalPath = row.pageParams.pageCountPath;
        }
      }
    }
    form.deviceCodePath = row.deviceCodePath;
    form.timeType = row.timeType;
    if (form.timeType === 'RULE') {
      form.timePath = row.timePath;
    }
    form.timeFormat = row.timeFormat;
    form.storageRules = row.storageRules;
    const resDataList = row.customFields[0].echoData.data;
    if (Object.keys(this.outStore).length > 0) {
      eachSetJsonPathForStore(resDataList, this.outStore);
    }
    this.resData = resDataList;
    const s = this.changeSelectRule(this.resData);
    this.ruleSelectList = Object.freeze(s);
    setTimeout(() => {
      if (row.expressions) {
        this.ruleList = row.expressions.map(v => {
          const o = {
            ...v,
            typeId: v.echoData?.typeId,
            type: v.dataType.toLowerCase(),
            rule: v.judgeEnum,
            value: v.value,
            other: v.other,
            ruleSelectList: [],
          };
          if (o.type === ControlTypeEnum.Date) {
            o.value = this.$moment(+v.value);
          }
          if (o.type === ControlTypeEnum.Switch) {
            o.value = v.value === 'true';
          }
          return o;
        });
        this.ruleList.forEach((v, idx) => {
          this.changeCondition(v.typeId, idx, 'edit');
        });
      }
      this.spinning = false;
    }, 0);
  }

  get selectedLen() {
    const len = this.deviceIds.length;
    return `已选择${len}条设备`;
  }

  changeDeviceP(arr) {
    const res = [];
    arr.forEach(v => {
      const o = {
        id: uuid(),
        paramId: `${v.identifier}_${v.paramPath}`,
        selected: v.param,
        type: 'int32',
      };
      res.push(o);
    });
    this.deviceParamsList = res;
  }

  dateList = [
    {
      name: '时间戳（13位）',
      value: 'TIME_STAMP13',
    },
    {
      name: '时间戳（10位）',
      value: 'TIME_STAMP10',
    },
    {
      name: '时：分',
      value: 'HOUR_MINUTE',
    },
    {
      name: '年',
      value: 'YEAR',
    },
    {
      name: '年-月',
      value: 'YEAR_MONTH',
    },
    {
      name: '年-月-日',
      value: 'YEAR_MONTH_DAY',
    },
    {
      name: '年-月-日 时：分',
      value: 'YEAR_MONTH_DAY_HOUR_MINUTE',
    },
    {
      name: '年-月-日 时：分：秒',
      value: 'YEAR_MONTH_DAY_HOUR_MINUTE_SECOND',
    },
    {
      name: "UTC（\"yyyy-MM-dd'T'HH:mm:ss'Z'\"）",
      value: 'UTC',
    },
  ];

  handleTimeChange(v, row, flag) {
    if (flag === 'ok') {
      row.value = v;
    }
  }

  changeRule(val, row) {
    row.rule = val;
  }

  typeList = [];

  ruleList = [];
  /**
   * @name 规则条件列表选择事件
   * @description valId 第几个下拉规则的ID
   * index 第几个规则
   * ruleSelectList 列表的属性 isArray 和 isStruct 是用来获取【规则运算条件】的
   */
  changeCondition(valId, index, flag = 'add') {
    const idx = this.ruleSelectList.findIndex(v => v.id === valId);
    if (idx === -1) {
      this.ruleList[index].typeId = '';
      this.ruleList[index].rule = undefined;
      this.ruleList[index].value = '';
      return;
    }
    const row = this.ruleSelectList[idx];
    const type = row.type;
    const path = row.val;
    const arr = this.conditionArr;
    const a1 = arr.slice(0, arr.length - 2);
    const o = {
      int: a1,
      int32: a1,
      float: a1,
      date: a1,
      double: a1,
      text: [arr[0], arr[1], arr[6], arr[7], arr[8], arr[9]],
      bool: [arr[0], arr[1]],
      enum: [arr[0], arr[1], arr[6], arr[7], arr[8], arr[9]],
      file: [arr[6], arr[7]],
      image: [arr[6], arr[7]],
    };
    // ruleSelectList 列表的属性 isArray 和 isStruct 是用来获取【规则运算条件】的
    if (row.isArray && !row.isStruct) {
      this.ruleList[index].ruleSelectList = [
        arr[0],
        arr[1],
        arr[6],
        arr[7],
        arr[8],
        arr[9],
      ];
    } else {
      this.ruleList[index].ruleSelectList = o[type];
    }
    // 延迟赋值？？？
    this.$nextTick(() => {
      const obj = this.ruleList[index];
      obj.typeId = row.id;
      obj.other = row.other;
      obj.type = type;
      obj.path = path;
      if (flag === 'add') {
        obj.rule = obj.ruleSelectList[0].value;
        obj.value = undefined;
      }
      if (type === ControlTypeEnum.Enum) {
        if (row?.details.length) {
          obj.selectList = row.details;
          obj.value = row.details[0].value;
        }
      }
    });
  }

  /** @name 规则运算条件列表 */
  conditionArr = [
    {
      label: '等于',
      value: 'EQUAL',
    },
    {
      label: '不等于',
      value: 'NOT_EQUAL',
    },
    {
      label: '小于',
      value: 'LESS',
    },
    {
      label: '小于等于',
      value: 'LESS_EQUAL',
    },
    {
      label: '大于',
      value: 'GRATER',
    },
    {
      label: '大于等于',
      value: 'GRATER_EQUAL',
    },
    {
      label: '为空',
      value: 'EMPTY',
    },
    {
      label: '不为空',
      value: 'NOT_EMPTY',
    },
    {
      label: '包含',
      value: 'CONTAIN',
    },
    {
      label: '不包含',
      value: 'NOT_CONTAIN',
    },
  ];
  addRule() {
    this.ruleList.push({
      id: uuid(),
      typeId: '',
      type: '',
      rule: '',
      path: '',
      ruleSelectList: [],
      selectList: [],
      value: '',
      other: '',
    });
  }

  deleteRule(idx) {
    this.ruleList.splice(idx, 1);
  }

  ruleSelectList = [];

  /**
   * @name 设置规则条件列表-ruleSelectList
   * @param {*} ruleData
   * @description
   */
  changeSelectRule(ruleData) {
    const eachRuleList = (list, parent, name) => {
      if (!(Array.isArray(list) && list.length > 0)) {
        return [];
      }
      let result = [];
      list.forEach(child => {
        if (child.children && child.children.length > 0) {
          result = result.concat(
            eachRuleList(child.children, child, child.name),
          );
        } else {
          let parentObj = {};
          if (parent) {
            parentObj = parent;
          }
          let isArray = false;
          if (parent.type === ControlTypeEnum.Array) {
            isArray = true;
          }
          let isObject = false;
          if (
            parent.type === ControlTypeEnum.Struct ||
            parent.subType === ControlTypeEnum.Struct ||
            parent.details?.subType === ControlTypeEnum.Struct
          ) {
            isObject = true;
          }
          let obj = this.changeSelectCommon(
            child,
            parentObj,
            isArray,
            isObject,
          );
          if (
            obj.type === ControlTypeEnum.Array &&
            obj.subType !== ControlTypeEnum.Struct
          ) {
            obj.type = obj.subType;
          }
          let nameStr = '';
          if (name) {
            nameStr = name + '.' + child.name;
          } else {
            nameStr = child.name;
          }
          obj.name = nameStr;
          result.push(obj);
        }
      });
      return result;
    };
    return eachRuleList(ruleData, '', '');
  }

  changeSelectCommon(obj, item, isArray, isStruct) {
    const o = {
      id: obj.id,
      parId: item.id,
      name: '',
      identifier: obj.identifier,
      val: obj.val,
      details: obj.details || '',
      type: obj.type,
      isArray,
      isStruct,
    };
    if (obj.type === ControlTypeEnum.Date) {
      // outFormat 出参格式，给后端使用的
      o['other'] = obj.outFormat || obj.format || 'UTC';
      o['unit'] = obj.format;
    }
    return o;
  }

  themeList = [];

  async getThemeList() {
    try {
      this.themeList = await getSubscribeTheme();
    } catch {
      return false;
    }
  }

  formateType(t, flag) {
    const obj = {
      [ControlTypeEnum.Int]: {
        lable: '整数',
        color: '#FF3535',
      },
      [ControlTypeEnum.Float]: {
        lable: '浮点',
        color: '#FFC12B',
      },
      [ControlTypeEnum.Double]: {
        lable: '双精度',
        color: '#339DEE',
      },
      [ControlTypeEnum.Input]: {
        lable: '文本',
        color: '#848BE2',
      },
      [ControlTypeEnum.Date]: {
        lable: '日期',
        color: '#9375CD',
      },
      [ControlTypeEnum.Switch]: {
        lable: '布尔',
        color: '#7987CB',
      },
      [ControlTypeEnum.Struct]: {
        lable: '结构体',
        color: '#4FC4F7',
      },
      [ControlTypeEnum.Array]: {
        lable: '数组',
        color: '#4DD1E2',
      },
      [ControlTypeEnum.Enum]: {
        lable: '枚举',
        color: '#EE9A9B',
      },
      [ControlTypeEnum.Image]: {
        lable: '图片',
        color: '#E57375',
      },
      [ControlTypeEnum.File]: {
        lable: '文件',
        color: '#FEB74B',
      },
      [ControlTypeEnum.DataPoint]: {
        lable: 'DP',
        color: '#EEE42A',
      },
    };
    // DP的子项没有type
    if (!t) {
      return flag === 'type' ? 'DP' : '#EAEAEA';
    }
    // DP的数据标识的格式
    if (!obj[t]) {
      return flag === 'type' ? t : '#EAEAEA';
    }
    return flag === 'type' ? obj[t].lable : obj[t].color;
  }

  customFields = [];
  async chooseParameter(type = '') {
    const t = type !== '' ? type : 'req';
    let data = type !== '' ? this.paramsOut : this.paramsIn;
    const arrTypeObj = {
      req: 'reqData',
      res: 'resData',
      body: 'bodyData',
    };
    const addParams = await createFormModal(
      () => (
        <ApiParams
          paramsData={data}
          dataType={t}
          selectList={this[arrTypeObj[t]]}
          {...{
            on: {
              'update:paramsData': val => {
                data = val;
              },
            },
          }}
        />
      ),
      {
        title: '选择参数',
        width: 500,
      },
    );
    if (addParams) {
      this.paramsClick(addParams);
      // TODO 这里待修改
      setTimeout(() => {
        const s = this.changeSelectRule(this.resData);
        // console.log(s);
        this.ruleSelectList = Object.freeze(s);
      }, 0);
    }
  }

  // 生成customFields数据
  changeRulesData(ruleData) {
    const eachFilter = (list, parent) => {
      if (!(Array.isArray(list) && list.length > 0)) {
        return;
      }
      let result = [];
      list.forEach(child => {
        const uid = uuid();
        if (child.type !== ControlTypeEnum.DataPoint) {
          if (child.val === PLACEHOLDER_OUT) {
            child.val = '';
          }
          let parentId = '';
          if (parent) {
            parentId = parent;
          }
          const obj = this.changeCommonData(child, uid, parentId);
          if (child.type === ControlTypeEnum.Array) {
            let subType = '';
            if (child.subType) {
              // 普通的【数组】包含【结构】
              subType =
                child.subType === 'int' ? 'INT32' : child.subType.toUpperCase();
            } else if (child.details && child.details.subType) {
              // 【DP】的【数组】包含【结构】
              subType =
                child.details.subType === 'int'
                  ? 'INT32'
                  : child.details.subType.toUpperCase();
            }
            obj['childDataType'] = subType;
          }
          result.push(obj);
        }
        if (child.children && child.children.length > 0) {
          result = result.concat(eachFilter(child.children, uid));
        }
      });
      return result;
    };
    const list = eachFilter(ruleData, '');
    if (list && list.length > 0) {
      list[0]['echoData'] = {
        data: clone(ruleData),
      };
    }
    return list;
  }

  changeCommonData(obj, id, parId) {
    const type =
      obj.type === ControlTypeEnum.Int || obj.type === 'int32'
        ? 'int32'
        : obj.type;
    const t = type === ControlTypeEnum.DataPoint ? 'DP' : type;
    const o = {
      id: id,
      identifier: obj.identifier,
      name: obj.name,
      dataType: t.toUpperCase(),
      path: obj.val,
      unit: obj.details?.unit || '',
    };
    if (o.dataType === '') {
      o.dataType = 'STRUCT';
    }
    if (obj.type === ControlTypeEnum.Date) {
      // outFormat 出参格式，给后端使用的
      o['other'] = obj.outFormat || obj.format || 'UTC';
      o['unit'] = obj.format;
    } else if (obj.type === ControlTypeEnum.Switch) {
      o['other'] = JSON.stringify(obj.details);
    } else if (obj.type === ControlTypeEnum.Image) {
      const o1 = {
        source: obj.source,
        isStore: obj.isStore,
        storeSize: obj.storeSize,
      };
      o['other'] = JSON.stringify(o1);
    } else if (obj.type === ControlTypeEnum.File) {
      const o1 = {
        isStore: obj.isStore,
        storeSize: obj.storeSize,
      };
      o['other'] = JSON.stringify(o1);
    } else if (obj.type === ControlTypeEnum.Enum) {
      if (obj.details) {
        let enumValueType = '';
        if (obj.details.length > 0) {
          enumValueType = obj.details[0].type || 'TEXT';
        }
        o['other'] = JSON.stringify({
          valueType: enumValueType || 'TEXT',
          enumItem: obj.details,
        });
      } else {
        const arr = obj.optionList.map(v => {
          return {
            key: v.text,
            value: v.value,
          };
        });
        o['other'] = JSON.stringify({
          valueType: obj.enumValueType || 'TEXT',
          enumItem: arr,
        });
      }
    }
    if (parId) {
      o['parent'] = parId;
    }
    return o;
  }

  resData = [];

  paramsClick(obj) {
    const { list } = obj;
    if (!(Array.isArray(list) && list.length > 0)) return;
    eachSetJsonPath(list, this.outStore);
    this.resData = list;
  }

  themeData() {
    const form = this.form;
    let params = {};
    if (this.connectorType !== 'TIMING') {
      params = {
        id: this.topic.id,
        name: form.name,
        classification: form.classification,
        deviceCodePath: form.deviceCodePath,
        timeType: form.timeType,
        timeFormat: form.timeFormat,
        storageRules: form.storageRules,
      };
      if (form.classification === 'SYSTEM') {
        params['systemTopic'] = form.systemTopic;
      } else {
        params['rulePath'] = form.rulePath;
        params['ruleValue'] = form.ruleValue;
      }
      if (form.timeType === 'RULE') {
        params['timePath'] = form.timePath;
      }
    } else {
      const time = this.$moment(form.subscribeTime)
        .format('HH:mm:ss')
        .split(':');
      params = {
        id: this.topic.id,
        cycle: form.dateType,
        hour: form.dateType === 'HOURLY' ? 0 : Number(time[0]),
        minute: Number(time[1]),
        second: Number(time[2]),
        updateDate: form.cycle,
        polling: form.polling,
        deviceParams: this.changeDeviceParams(),
        deviceCodePath: form.deviceCodePath,
        timeType: form.timeType,
        timeFormat: form.timeFormat,
        storageRules: form.storageRules,
      };
      if (form.polling === 'CUSTOM') {
        params['deviceIds'] = this.deviceIds;
      }
      if (form.autoDevidePage) {
        params['pageParams'] = {};
        params.pageParams['pageInterval'] = form.pageInterval;
        params.pageParams['pageType'] = form.pageTotalType;
        params.pageParams['identifier'] = form.autoDevidePage.split('_')[0];
        params.pageParams['paramPath'] = form.autoDevidePage.split('_')[1];
        if (form.pageTotalType === 'FIXED') {
          params.pageParams['count'] = form.pageTotalNumber;
        } else {
          params.pageParams['pageCountPath'] = form.pageTotalPath;
        }
      }
      if (form.timeType === 'RULE') {
        params['timePath'] = form.timePath;
      }
    }
    // TODO 清除默认提示语
    this.customFields = this.changeRulesData(this.resData);
    params['customFields'] = this.customFields;
    if (form.storageRules !== 'EVERY_TIME') {
      params['expressions'] = this.ruleList.map(v => {
        const type =
          v.type === ControlTypeEnum.Int || v.type === 'int32'
            ? 'int32'
            : v.type;
        const o = {
          id: v.id,
          other: v.other || 'UTC',
          echoData: {
            typeId: v.typeId,
          },
          path: v.path,
          judgeEnum: v.rule,
          value: v.value,
          dataType: type.toUpperCase(),
        };
        if (type === ControlTypeEnum.Date) {
          o.value = this.$moment(v.value).valueOf();
        }
        return o;
      });
    }
    return params;
  }

  changeDeviceParams() {
    const arr = [];
    this.deviceParamsList.forEach(v => {
      const o = {
        param: v.selected,
        paramPath: v.paramId.split('_')[1],
        identifier: v.paramId.split('_')[0],
      };
      arr.push(o);
    });
    return arr;
  }
}
</script>
<style lang="less" module>
.subscribeContainer {
  .pr10 {
    padding-right: 10px;
  }

  .pl10 {
    padding-left: 10px;
  }

  .list {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .textPrimary {
    cursor: pointer;
    margin-left: 10px;
    color: var(--primary);
  }

  .addIcon {
    width: 150px;
    cursor: pointer;
    color: var(--primary);
    display: flex;
    align-items: center;
  }

  .deleteIcon {
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
    color: var(--delete);
  }

  .rowHeight {
    height: 75px;
  }

  :global {
    .ant-input-affix-wrapper {
      &:last-child {
        margin-right: 0;
      }

      .ant-input {
        padding-right: 11px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }

      .anticon-close-circle {
        display: none;
      }

      &:hover .ant-input {
        padding-right: 30px;
      }

      &:hover .anticon-close-circle {
        display: inline-block;
      }
    }

    .ant-select-selection--multiple .ant-select-selection__choice.zoom-leave {
      display: none;
    }
  }
}
//@import url(); 引入公共css类
</style>
