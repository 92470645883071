<template>
  <div ref="PluginCharts" />
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { crossStorageModule } from '@/enum/store';

import ResizeObserver from 'resize-observer-polyfill';
import { globalVueI18n } from '@triascloud/i18n';

@Component({
  components: {},
})
export default class PluginCharts extends Vue {
  @Prop({ type: Object }) data;
  @crossStorageModule.State skin;

  /** @type { ResizeObserver } */
  _ob = null;
  myChart = null;
  mounted() {
    this._ob = new ResizeObserver(this.chartResize);
    this._ob.observe(this.$refs.PluginCharts);
    this.initChart();
  }
  chartResize() {
    this.myChart.resize();
  }
  get option() {
    const res = this.data && this.data.data ? this.data.data : [];
    return {
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          return [
            params[0].name + '<br/>',
            globalVueI18n.translate('plugin.overview.config'),
            ': ' + params[0].value + '&nbsp' + '<br/>',
            globalVueI18n.translate('plugin.overview.associated'),
            ': ' + params[1].value + '<br/>',
          ].join('');
        },
      },
      grid: {
        left: '5%',
        right: '4%',
        bottom: '18%',
        containLabel: false,
      },
      legend: {
        data: [
          globalVueI18n.translate('plugin.overview.config'),
          globalVueI18n.translate('plugin.overview.associated'),
        ],
        top: 25,
        left: 5,
        itemWidth: 8,
        itemHeight: 8,
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },

      xAxis: [
        {
          type: 'category',
          data: res.length ? res.map(item => item.tenantName) : [],
          axisTick: {
            alignWithLabel: true,
          },
          inside: true,
          showMaxLabel: true,
          axisLabel: {
            width: 170,
            rotate: 45,
            formatter: function(value) {
              if (value.length >= 5) {
                let v = value.slice(0, 5) + '...';
                return v;
              } else {
                return value;
              }
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: globalVueI18n.translate('plugin.overview.config'),
          type: 'bar',
          barGap: 0,
          barWidth: 15,
          color: ['#34C18F'],
          data: res.length
            ? res.map(item => ({
                value: item.configCount,
              }))
            : [],
        },
        {
          name: globalVueI18n.translate('plugin.overview.associated'),
          type: 'bar',
          barGap: 0.5,
          barWidth: 15,
          color: ['#FFB22A'],
          tooltip: {
            valueFormatter: function(value) {
              return value;
            },
          },
          data: res.length
            ? res.map(item => ({
                value: item.associateCount,
              }))
            : [],
        },
      ],
    };
  }
  @Watch('option')
  @Watch('skin', { deep: true })
  initChart() {
    const chart = this.$refs.PluginCharts;
    this.myChart = echarts.init(chart);
    this.myChart.setOption(this.option);
  }

  @Watch('$route')
  handleRouteChange() {
    this.myChart.clear();
    this.initChart();
  }
}
</script>
<style lang="less" module></style>
