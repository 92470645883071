<template>
  <a-table
    :pagination="pagination"
    :row-key="record => record[rowKey]"
    :columns="tableColumns"
    @change="handlePagination"
    :data-source="tableData"
    :loading="loading"
    :scroll="scroll"
    :class="{ 'scroll-y-header': !!scroll.y }"
    :rowSelection="rowSelection"
    :transformCellText="({ text }) => text || cellEmptyText"
    v-bind="$attrs"
  >
    <template
      v-for="key in slotKeys"
      :slot="key"
      slot-scope="text, record, index"
    >
      <slot :name="key" :text="text" :record="record" :index="index"></slot>
    </template>
  </a-table>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import FileIcon from '@/components/file-icon';
import { Debounce } from 'lodash-decorators';
import { emptyValueFilter } from '@/utils';

/** @todo a-table二次封装 */
@Component({ components: { FileIcon } })
export default class PaginationTable extends Vue {
  @Prop({ type: String, default: '-' }) cellEmptyText; // 单元格缺省值
  @Prop({ type: Boolean, default: false }) showSelection; // 是否显示rowSelection
  @Prop({ type: Boolean, default: true }) showIndex; // 是否显示序号
  @Prop({ type: Object, default: () => {} }) paginationConfig; // 用于覆盖默认pagination
  @Prop({ type: Array, default: () => [] }) slotKeys; // 动态生成的插槽name值
  @Prop({ type: Object, default: () => {} }) searchParams; //table查询
  @Prop({ type: Object, default: () => ({ x: '100%', y: false }) }) scroll;
  @Prop({ type: Array, default: () => [] }) columns;
  @Prop({ type: String, required: true }) rowKey;
  @Prop({ type: Function, required: true }) api; // table数据接口
  @Prop({ type: Object, default: () => {} }) extraKeys; // table数组中需要添加的额外key值

  loading = false;
  tableData = [];
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => `${this.$t('hat.table.total')}${total} `,
    ...this.paginationConfig,
  };
  actionWidth = 0;

  get tableColumns() {
    return [
      this.showIndex && {
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('common.label.index'),
        align: 'center',
        width: 90,
        ellipsis: true,
      },
      ...this.columns,
    ]
      .map(column => {
        if (column.key === 'action') {
          column.className = 'action';
          column.width = this.actionWidth;
          column.customHeaderCell = async () => {
            await this.$nextTick();
            const widthArr = [];
            const els = document.querySelectorAll('.action');
            for (let index = 0; index < els.length; index++) {
              const { width } = els[index].children[0].getBoundingClientRect();
              widthArr.push(width);
            }
            const maxW = Math.ceil(Math.max(...widthArr));
            this.actionWidth = maxW + 32 || 0;
          };
        }
        return column;
      })
      .filter(item => item);
  }

  // 选中项的keys
  selectedKeys = [];

  get rowSelection() {
    return this.showSelection
      ? {
          onChange: selectedRowKeys => {
            this.selectedKeys = selectedRowKeys;
          },
        }
      : null;
  }

  handlePagination({ current, pageSize }) {
    this.pagination.current = current;
    this.pagination.pageSize = pageSize;
    this.getTableList();
  }

  async getTableList() {
    this.loading = true;
    const { current, pageSize: size } = this.pagination;
    const data = {
      current,
      size,
      ...emptyValueFilter(this.searchParams),
    };
    try {
      const { records, total } = await this.api(data);

      if (this.extraKeys && Object.keys(this.extraKeys).length > 0) {
        this.tableData = records.map(item => {
          item = { ...item, ...this.extraKeys };
          return item;
        });
      } else {
        this.tableData = records;
      }

      this.pagination.total = total;
    } catch (error) {
      return false;
    } finally {
      this.loading = false;
    }
  }

  @Debounce(500)
  search() {
    this.pagination.current = 1;
    this.getTableList();
  }

  @Debounce(500)
  refresh() {
    this.getTableList();
  }

  mounted() {
    this.getTableList();
  }
}
</script>
<style lang="less">
.scroll-y-header .ant-table-header {
  background: none;
}
.scroll-y-header .ant-table-body-inner {
  overflow-y: auto !important;
}
</style>
