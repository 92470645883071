<template>
  <div :class="$style.index">
    <div :class="$style.title">{{ $t('activity.InvitationInfo') }}</div>
    <div :class="$style.item" v-for="item in columns" :key="item.dataIndex">
      <div :class="$style.label">{{ item.title }}</div>
      <div :class="$style.value" v-if="item.dataIndex === 'status'">
        {{
          data[item.dataIndex] === 1
            ? '未使用'
            : data[item.dataIndex] === 2
            ? '已使用'
            : '停用'
        }}
      </div>
      <div :class="$style.value" v-else-if="item.dataIndex === 'codeType'">
        {{ $t(ActivityTypeText[data[item.dataIndex]]) }}
      </div>
      <!-- <div :class="$style.value" v-else-if="item.dataIndex === 'useTime'">
        {{ $moment(data[item.dataIndex]).format('Y-MM-DD HH:mm') }}
      </div> -->

      <div :class="$style.value" v-else>
        {{ data[item.dataIndex] ? data[item.dataIndex] : '-' }}
      </div>
    </div>
    <div v-if="status != 2 && data.codeType !== 4">
      <div :class="$style.item" v-if="$p.action('UPDATE')">
        {{ $t('common.action.operating') }}
      </div>
      <div v-if="$p.action('UPDATE')">
        <a-select
          :placeholder="$t('common.label.all')"
          v-model="status"
          :class="$style.toolbar__select"
          :disabled="data.status === 2 || data.status === -1"
        >
          <a-select-option
            v-for="item in templateTypes"
            :key="item.value"
            :value="item.value"
            >{{ item.text }}</a-select-option
          >
        </a-select>
        <a-button
          type="link"
          @click="changeType"
          :disabled="data.status === 2 || data.status === -1"
          >{{ $t('activity.adjustment') }}</a-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createDrawer } from '@triascloud/x-components';
// import { globalVueI18n } from '@triascloud/i18n';
import { ActivityTypeText } from '@/enum/activity';
import { banPickCode } from '@/services/marketing/invite-code';
function createInvitationDetailsDrawer(props, listener) {
  return createDrawer(h => h(InvitationCodeDetails, { props, on: listener }), {
    title: `邀请码：${props.data.inviteCode}`,
    width: 518,
    className: 'x-default-icon-drawer',
  });
}

@Component()
export default class InvitationCodeDetails extends Vue {
  @Prop({ type: Object }) data;
  static createDrawer = createInvitationDetailsDrawer;
  ActivityTypeText = ActivityTypeText;
  status = 1;
  templateTypes = [
    {
      text: '未使用',
      value: 1,
    },
    {
      text: '停用',
      value: -1,
    },
  ];
  mounted() {
    this.status = JSON.parse(JSON.stringify(this.data.status));
  }

  async changeType() {
    await banPickCode({ pkId: this.data.inviteCode, status: this.status });
    this.$message.success(this.$t('common.tips.operationSuccess'));
    this.$emit('refresh', this.status);
  }
  get columns() {
    return [
      {
        title: this.$t('inviteCode.codes'),
        dataIndex: 'inviteCode',
      },
      {
        dataIndex: 'status',
        title: this.$t('inviteCode.useNot'),
      },
      {
        title: this.$t('activity.userWeChat'),
        dataIndex: 'useMemberWechatName',
      },
      {
        title: this.$t('activity.userAccount'),
        dataIndex: 'useMemberAccount',
      },
      {
        title: this.$t('activity.Username'),
        dataIndex: 'useMemberName',
      },
      {
        title: this.$t('activity.userMobile'),
        dataIndex: 'useMemberTel',
      },
      {
        title: this.$t('activity.InvitationType'),
        dataIndex: 'codeType',
      },
      //   {
      //     title: '使用时间',
      //     dataIndex: 'useTime',
      //   },
    ];
  }
}
</script>
<style lang="less" module>
.index {
  padding: 0 20px;
}
.title {
  font-size: 14px;
  padding: 20px 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
}
.item {
  margin-bottom: 20px;
}
.label {
  margin-bottom: 10px;
}
.value {
  padding-left: 10px;
  height: 21px;
}
.toolbar__select {
  width: 95px;
}
</style>
