<template>
  <component
    :is="computedComponent"
    :value="value"
    :view="view"
    :field="field"
    :checked-children="field.widget.is && field.widget.is"
    :un-checked-children="field.widget.not && field.widget.not"
    v-on="$listeners"
    v-bind="$attrs"
  />
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ControlTypeEnum } from '../enum';
@Component()
export default class InputDesign extends Vue {
  /** @description 数据 */
  @Prop({
    type: Object,
    required: true,
  })
  field;
  @Prop() value;
  @Prop() view;

  get computedComponent() {
    switch (this.field.type) {
      case ControlTypeEnum.Input:
      case ControlTypeEnum.Int:
      case ControlTypeEnum.Float:
      case ControlTypeEnum.Double:
        return 'x-input';
      case ControlTypeEnum.Date:
        return 'x-date-picker';
      case ControlTypeEnum.Switch:
        return 'x-switch';
      default:
        return 'x-input';
    }
  }
}
</script>
