import request from '../request';

const serviceName = '/iot-smart-helmet/system/device/usageStatistics';

// 获取用量统计
export function getUsageStatistics(data) {
  return request(`${serviceName}`, {
    method: 'GET',
    body: data,
  });
}
