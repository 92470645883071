<template>
  <div :class="$style.box">
    <div :class="$style.editBtn" @click="handleDisabledChange" v-if="tantendId">
      {{ disabled ? '编辑' : '取消' }}
    </div>
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-form-model-item label="开通企业">
        <compony-select
          v-model="form.idxTenantId"
          style="width: 98%;"
          @change="handleIdChange"
        />
      </a-form-model-item>

      <a-form-model-item label="平台功能">
        <a-checkbox-group v-model="plantsSelected" :disabled="disabled">
          <a-row>
            <a-checkbox value="ONLINE_CALL">音/视频通话</a-checkbox>
            <a-checkbox value="GROUP_CALL">群组通话</a-checkbox>
            <a-checkbox value="ON_SITE_PERIOD">在岗时长</a-checkbox>
            <a-checkbox value="ATTENDANCE_PERIOD">班次时长</a-checkbox>
            <a-checkbox value="VOICE_BOARDCAST">广播（录音）</a-checkbox>
          </a-row>
          <a-row>
            <a-checkbox value="IMAGE_ANNOTATOR">图片标注</a-checkbox>
            <a-checkbox value="FILE_MANAGE_TIMELINE"
              >文件管理（时间线）</a-checkbox
            >
            <a-checkbox value="FILE_MANAGE_MAP"
              >文件管理（地图模式）</a-checkbox
            >
          </a-row>
        </a-checkbox-group>
        <!--        <a-radio-group-->
        <!--          name="radioGroup"-->
        <!--          :default-value="1"-->
        <!--          style="margin-left: 20px;"-->
        <!--        >-->
        <!--          <a-radio v-for="(item, idx) in plants" :key="idx" :value="item.value">-->
        <!--            {{ item.label }}-->
        <!--          </a-radio>-->
        <!--        </a-radio-group>-->
      </a-form-model-item>
      <a-form-model-item label="AI模型">
        <a-row>
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="AiChecked"
            @change="checkAllItem"
            :disabled="disabled"
            >行为识别</a-checkbox
          >
        </a-row>
        <a-checkbox-group
          style="width: 98%;"
          v-model="aiSelected"
          @change="aiOptionsChange"
          :disabled="disabled"
        >
          <a-row :gutter="20">
            <a-col offset="1">
              <a-checkbox value="NO_WEARING_HELMET">未带安全帽</a-checkbox>
              <a-checkbox value="REFLECTIVE_VEST">未穿反光衣</a-checkbox>
              <a-checkbox value="SMOKING_BEHAVIOR">吸烟行为</a-checkbox>
              <a-checkbox value="BREAK_IN">非法闯入</a-checkbox>
            </a-col>
          </a-row>

          <a-row>
            <a-checkbox value="FACIAL_RECOGNITION">人脸识别</a-checkbox>
            <div>
              备注：开通后需要在企业通讯录中录入人脸
            </div>
          </a-row>
        </a-checkbox-group>
      </a-form-model-item>

      <a-form-model-item label="语音识别">
        <a-row>
          <a-checkbox
            :indeterminate="re_indeterminate"
            :checked="reChecked"
            @change="checkReAllItem"
            :disabled="disabled"
            >语音识别</a-checkbox
          >
        </a-row>
        <a-checkbox-group
          style="width: 98%;"
          v-model="reSelected"
          @change="reOptionsChange"
          :disabled="disabled"
        >
          <a-row :gutter="20">
            <a-col offset="1">
              <a-checkbox value="ALI_VOICE_RECOGNITION"
                >阿里智能（录音）</a-checkbox
              >
              <a-checkbox value="ALI_VIDEO_RECOGNITION"
                >阿里智能（视频)
              </a-checkbox>
              <a-checkbox value="HOMOPHONE_RECOGNITION">同音识别</a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import ComponySelect from '@/views/digital-site/components/compony-select.vue';
import { getTenantFeatureSetting } from '@/services/hat';
@Component({ components: { ComponySelect } })
export default class ValueAdded extends Vue {
  disabled = true;
  aiSelected = [];
  reSelected = [];
  plantsSelected = [];

  form = {
    deviceName: '',
    factoryTime: '',
    color: '白',
    idxTenantId: undefined,
  };
  id = 1;
  formData = [
    {
      deviceName: '',
      factoryTime: this.$moment().format('YYYY/MM/DD'),
      color: '白',
      id: 1,
    },
  ];
  rules = {
    deviceName: [
      {
        required: true,
        validator: this.validateGroup1,
        trigger: 'submit',
      },
    ],
  };
  AiChecked = false;
  indeterminate = false;
  aiOptionsChange(checkedList) {
    if (checkedList.length) {
      const hasSomeOne = [
        'NO_WEARING_HELMET',
        'REFLECTIVE_VEST',
        'SMOKING_BEHAVIOR',
        'BREAK_IN',
      ].some(item => {
        return checkedList.includes(item);
      });

      const hasEveryOne = [
        'NO_WEARING_HELMET',
        'REFLECTIVE_VEST',
        'SMOKING_BEHAVIOR',
        'BREAK_IN',
      ].every(item => {
        return checkedList.includes(item);
      });
      if (hasEveryOne) {
        this.AiChecked = true;
        this.indeterminate = false;
        return;
      }
      if (hasSomeOne) {
        this.AiChecked = false;
        this.indeterminate = true;
      }
    } else {
      this.indeterminate = false;
      this.AiChecked = false;
    }
  }
  checkAllItem(e) {
    const items = this.aiSelected.filter(
      item =>
        ![
          'NO_WEARING_HELMET',
          'REFLECTIVE_VEST',
          'SMOKING_BEHAVIOR',
          'BREAK_IN',
        ].includes(item),
    );
    Object.assign(this, {
      aiSelected: e.target.checked
        ? items.concat([
            'NO_WEARING_HELMET',
            'REFLECTIVE_VEST',
            'SMOKING_BEHAVIOR',
            'BREAK_IN',
          ])
        : items,
      indeterminate: false,
      AiChecked: e.target.checked,
    });
  }
  tantendId = '';
  reChecked = false;
  re_indeterminate = false;
  reOptionsChange(checkedList) {
    if (checkedList.length) {
      const hasSomeOne = [
        'ALI_VOICE_RECOGNITION',
        'ALI_VIDEO_RECOGNITION',
        'HOMOPHONE_RECOGNITION',
      ].some(item => {
        return checkedList.includes(item);
      });

      const hasEveryOne = [
        'ALI_VOICE_RECOGNITION',
        'ALI_VIDEO_RECOGNITION',
        'HOMOPHONE_RECOGNITION',
      ].every(item => {
        return checkedList.includes(item);
      });
      if (hasEveryOne) {
        this.reChecked = true;
        this.re_indeterminate = false;
        return;
      }
      if (hasSomeOne) {
        this.reChecked = false;
        this.re_indeterminate = true;
      }
    } else {
      this.re_indeterminate = false;
      this.reChecked = false;
    }
  }
  checkReAllItem(e) {
    const items = this.reSelected.filter(
      item =>
        ![
          'ALI_VOICE_RECOGNITION',
          'ALI_VIDEO_RECOGNITION',
          'HOMOPHONE_RECOGNITION',
        ].includes(item),
    );
    Object.assign(this, {
      reSelected: e.target.checked
        ? items.concat([
            'ALI_VOICE_RECOGNITION',
            'ALI_VIDEO_RECOGNITION',
            'HOMOPHONE_RECOGNITION',
          ])
        : [],
      re_indeterminate: false,
      reChecked: e.target.checked,
    });
  }
  handleDisabledChange() {
    if (!this.tantendId) return;
    this.disabled = !this.disabled;
    this.handleIdChange({ id: this.tantendId });
  }
  show() {}
  mounted() {}
  featureSetting = [];
  async handleIdChange(val) {
    this.tantendId = val.id;
    this.featureSetting = await getTenantFeatureSetting(val.id);
    const allSelected = this.featureSetting
      .filter(item => item.enable)
      .map(item => item.featureType);

    this.plantsSelected = allSelected.filter(item => {
      return [
        'ONLINE_CALL',
        'GROUP_CALL',
        'ON_SITE_PERIOD',
        'ATTENDANCE_PERIOD',
        'VOICE_BOARDCAST',
        'IMAGE_ANNOTATOR',
        'FILE_MANAGE_TIMELINE',
        'FILE_MANAGE_MAP',
      ].includes(item);
    });
    this.aiSelected = allSelected.filter(item => {
      return [
        'NO_WEARING_HELMET',
        'REFLECTIVE_VEST',
        'SMOKING_BEHAVIOR',
        'BREAK_IN',
        'FACIAL_RECOGNITION',
      ].includes(item);
    });
    this.reSelected = allSelected.filter(item => {
      return [
        'ALI_VOICE_RECOGNITION',
        'ALI_VIDEO_RECOGNITION',
        'HOMOPHONE_RECOGNITION',
      ].includes(item);
    });
    this.aiOptionsChange(this.aiSelected);
    this.reOptionsChange(this.reSelected);
  }

  getValue() {
    return new Promise(resolve => {
      const allSelected = this.aiSelected.concat(
        this.reSelected,
        this.plantsSelected,
      );
      const _form = this.featureSetting.map(item => {
        item.enable = !!allSelected.includes(item.featureType);
        return item;
      });
      resolve({
        featureSettingDTOList: _form,
        tenantId: this.tantendId,
      });
    });
  }
}
</script>
<style lang="less" module>
.box {
  position: relative;
  .editBtn {
    position: absolute;
    right: 15px;
    color: var(--primary);
    cursor: pointer;
    z-index: 10;
  }
  table {
    border-color: var(--primary);
    width: 98%;
    th {
      width: 33%;
      height: 40px;
      text-align: center;
    }

    td {
      text-align: center;
      .textArea {
        width: 100%;
        resize: none;
        border: none;
        outline: none;
        text-align: center;
        padding-top: 6px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .textArea::-webkit-input-placeholder {
        /* WebKit browsers，webkit内核浏览器 */
        color: #bfbfbf;
      }
      .textArea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #bfbfbf;
      }
      .textArea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #bfbfbf;
      }
      .textArea:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #bfbfbf;
      }
      .select {
        width: 90%;
        text-align: center;
        border: none;
        outline: none;
      }
    }

    .delete {
      width: 30px;
      text-align: center;
      position: absolute;
      top: 6px;
      right: -26px;
    }

    .delete:hover {
      color: var(--primary);
      cursor: pointer;
    }
  }
}
</style>
