<template>
  <a-select
    :mode="getMode"
    :value="getVal"
    @change="emitChange"
    style="max-width: 400px"
    placeholder="请选择"
  >
    <a-select-option
      v-for="item of field.widget.optionList"
      :key="item.id"
      :value="item.value"
    >
      {{ item.text }}
    </a-select-option>
  </a-select>
</template>

<script>
import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import { DataFormContext } from '../../data-form/context';
// import { deepClone } from '@triascloud/utils';
@Component()
export default class EnumDesign extends Vue {
  @Prop({ required: true }) field;
  @Inject({ from: DataFormContext.Root, default: null }) root;
  @Inject({ from: DataFormContext.Model, default: null }) model;
  /** @description 数据 */
  @Prop({
    type: Object,
    required: true,
  })
  field;
  @Prop() value;
  @Prop() view;
  get getMode() {
    return this.field.widget.isSingle ? 'default' : 'multiple';
  }
  get getVal() {
    let dataValue = this.model.getValue(this.field.pkId);
    if (dataValue === this.field.widget.defaultValue.custom) {
      dataValue = [];
      this.field.widget.optionList.map(item => {
        if (this.field.widget.defaultValue.custom.indexOf(item.id) >= 0) {
          dataValue.push(item.value);
        }
      });
      if (this.getMode === 'default') {
        dataValue = dataValue[0];
      }
    }
    return dataValue;
  }
  emitChange(val) {
    this.$emit('input', val);
  }
}
</script>
