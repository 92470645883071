<template>
  <div :class="$style.topData" @click="showAll">
    <div :class="$style.item" v-for="(item, idx) in topDataOptions" :key="idx">
      <div :class="$style.info">
        <x-icon :class="$style.iconStyle" :type="item.icon" />
        <span :class="$style.number" :style="{ color: item.color }">{{
          countObj[item.number]
        }}</span>
        <span :class="$style.text">{{ item.text }}</span>
      </div>
      <div :class="$style.chart">
        <PieChart
          :id="item.number"
          :chartData="item.chartData"
          :colorArray="item.colors"
          :updateTable="$attrs.updateTable"
          v-if="item.chartData.length"
        />
        <EmptyContent type="home" label="" v-else />
      </div>
    </div>
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import PieChart from './pie-chart.vue';
import { reqDevicesCount } from '@/services/hat';
import EmptyContent from '@/components/empty-content';
@Component({
  components: {
    PieChart,
    EmptyContent,
  },
})
export default class ElectronFence extends Vue {
  topDataOptions = [
    {
      number: 'totalNumber',
      text: '设备总数',
      icon: 'tc-icon-equipment',
      color: '#947dfa',
      chartData: [],
      colors: ['#004EC4', '#00BDC4'],
    },
    {
      number: 'alarmNumber',
      text: '今日警报',
      icon: 'tc-icon-alarm',
      color: '#00b893',
      chartData: [],
      colors: ['#00C588', '#F6B649'],
    },
    {
      number: 'remindsNumber',
      text: '今日提醒',
      icon: 'tc-icon-reminder',
      color: '#fe5959',
      chartData: [],
      colors: ['#9F88FA', '#3163ED'],
    },
    {
      number: 'noticeNumber',
      text: '今日通知',
      icon: 'tc-icon-notice',
      color: '#ffa348',
      chartData: [],
      colors: ['#F6B649', '#F60000'],
    },
  ];
  countObj = {
    totalNumber: 0,
    alarmNumber: 0,
    remindsNumber: 0,
    noticeNumber: 0,
  };
  mounted() {
    this.getList();
  }
  showAll() {
    if (this.$store.state.hat.status) {
      this.$store.commit('hat/setStatus', null);
      this.$attrs.updateTable();
    }
  }
  async getList() {
    const result = await reqDevicesCount();
    const { devices, alerts, notice, reminds } = result;
    const arr = [devices, alerts, reminds, notice];
    Object.keys(this.countObj).forEach((item, index) => {
      const { totalNumber, data } = this.getStatistic(arr[index]);
      this.countObj[item] = totalNumber;
      this.topDataOptions[index].chartData = data;
    });
  }

  getStatistic(chartData) {
    const data = chartData.map(item => {
      const { name, number: value } = item;
      return { name, value };
    });
    let totalNumber = chartData.reduce((pre, next) => pre + next.number, 0);
    return {
      data,
      totalNumber,
    };
  }
}
</script>
<style lang="less" module>
.topData {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .item {
    width: 24%;
    height: 150px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--block-bg);
    box-shadow: 0px 0px 5px 0px #e1e1e1;
    border-radius: 8px;
    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .iconStyle {
        font-size: 34px;
      }
      .number {
        font-size: 32px;
      }
      .text {
        color: #a8a7a7;
      }
    }
  }
  .chart {
    display: flex;
    align-items: center;
    width: 60%;
    height: 100%;
  }
}
</style>
