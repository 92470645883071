<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="tableList"
      :pagination="pagination"
      :row-key="record => record.id"
      :loading="tableLoading"
      @change="handlePagination"
    >
      <template slot="apiName" slot-scope="text, record">
        <div :class="$style.tagColumn">
          <span> {{ text }}</span>
          <span>{{ record.identifier }}</span>
        </div>
      </template>
      <template slot="helpDoc" slot-scope="text">
        <a v-if="text" :href="text" target="_blank">查看</a>
        <span v-else>无</span>
      </template>
      <template slot="operate" slot-scope="record">
        <span :class="$style.buttonGroups">
          <a
            v-if="record.status === 'UNPUBLISHED'"
            :class="[$style.button]"
            @click.stop="addApi(record.pkId)"
            >编辑</a
          >
          <a
            v-if="record.status === 'UNPUBLISHED'"
            :class="[$style.button]"
            @click.stop="debugApi(record)"
          >
            调试
          </a>
          <a
            v-if="record.status === 'PUBLISHED'"
            :class="[$style.button]"
            @click.stop="debug(record)"
          >
            调用
          </a>
          <a
            v-if="record.status === 'PUBLISHED'"
            :class="[$style.button]"
            @click.stop="handleStop(record.pkId)"
            >停用</a
          >
          <a
            v-if="record.status === 'UNPUBLISHED'"
            :class="[$style.button, $style.red]"
            @click.stop="handleDel(record)"
            >删除</a
          >
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import { createModal, createFormModal } from '@triascloud/x-components';
import SettingApiModel from './setting-api-model';
import AddApi from './add-api';
import ApiVariable from '../api-variable/index';
import {
  Emitter,
  EventType,
} from '@/views/connector/platform-manage/develop/utils';
import {
  getApiList,
  deleteConfigs,
  updateStatus,
} from '@/services/iot-platform/platformInterface';
import DebugApi from './debug-api';
import { getVariables } from '@/services/iot-platform/variable';
import { variableModule } from '@/enum/store';
import ThreeModel from '@/views/connector/platform-manage/develop/api-config/three-model.vue';

@Component({
  components: {
    ThreeModel,
  },
})
export default class ApiConfig extends Vue {
  @InjectReactive() id;
  @variableModule.Mutation updateVariableAllList;
  @Prop({ type: Array, default: () => [] }) products;
  @Prop({ type: String, default: '' }) platformIdentifier;

  mounted() {
    Emitter.$on(EventType.API_CONFIG_SEARCH, this.handleSearchTag);
    Emitter.$on(EventType.API_CONFIG_CHANGE, this.handleSearchType);
    Emitter.$on(EventType.ADD_API_CONFIG, this.addApi);
    Emitter.$on(EventType.VARIABLE_MANAGE, this.variableMgr);
    Emitter.$on(EventType.THIRD_PARTY, this.threePush);
    this.getList();
    this.getVariableList();
  }

  beforeDestroy() {
    this.updateVariableAllList([]);
    Emitter.$off(EventType.API_CONFIG_SEARCH, this.handleSearchTag);
    Emitter.$off(EventType.API_CONFIG_CHANGE, this.handleSearchType);
    Emitter.$off(EventType.ADD_API_CONFIG, this.addApi);
    Emitter.$off(EventType.VARIABLE_MANAGE, this.variableMgr);
    Emitter.$off(EventType.THIRD_PARTY, this.threePush);
  }
  async getVariableInfo() {
    const params = {
      platformId: this.id,
      size: 20,
      current: 1,
    };
    try {
      const res = await getVariables(params);
      let result = res.records;
      params.current = res.current;
      if (res.total > 20) {
        const n = Math.ceil(res.total / 20);
        for (let i = 1; i < n; i++) {
          params.current = i + 1;
          const { records } = await getVariables(params);
          result = result.concat(records);
        }
      }
      return result;
    } catch {
      return false;
    }
  }
  async getVariableList() {
    try {
      const list = await this.getVariableInfo();
      this.updateVariableAllList(list);
    } catch {
      return false;
    }
  }
  tableLoading = false;
  columns = [
    {
      dataIndex: 'index',
      customRender: (text, row, index) => {
        return index + 1;
      },
      title: '序号',
      align: 'center',
      width: 80,
    },
    {
      width: 200,
      dataIndex: 'apiName',
      title: '连接器名称/标识',
      scopedSlots: { customRender: 'apiName' },
    },
    {
      dataIndex: 'subscriptionType',
      title: '连接器类型',
    },
    {
      width: 200,
      dataIndex: 'idxProductId', //TODO 改成接口返回字段
      title: '所属产品',
      customRender: text => {
        return this.getProductName(text);
      },
    },
    {
      dataIndex: 'status',
      title: '状态',
      customRender: text => {
        const typeMap = {
          UNPUBLISHED: '待发布',
          PUBLISHED: '已发布',
        };
        return typeMap[text];
      },
    },
    {
      dataIndex: 'helpDocumentationUrl',
      title: '帮助文档',
      align: 'center',
      scopedSlots: { customRender: 'helpDoc' },
    },
    {
      dataIndex: 'creatorName',
      title: '创建人',
    },
    {
      width: 200,
      dataIndex: 'updateTime',
      title: '最后编辑时间',
      customRender: val => {
        return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
      },
    },
    {
      width: 200,
      dataIndex: '',
      title: '操作',
      scopedSlots: { customRender: 'operate' },
    },
  ];
  tableList = [];
  apiNameOrIdentifier = '';
  idxProductId = 'all';
  getProductName(productId) {
    let item = this.products.find(item => {
      return item.pkId === productId;
    });
    return item?.name || '';
  }
  handleSearchTag(val) {
    this.apiNameOrIdentifier = val;
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.getList();
  }
  handleSearchType(val) {
    this.idxProductId = val;
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.getList();
  }
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };
  async getList() {
    const params = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
      platformId: this.id,
    };
    if (this.apiNameOrIdentifier !== '') {
      params['apiName'] = this.apiNameOrIdentifier;
    }
    if (this.idxProductId !== 'all') {
      params['idxProductId'] = this.idxProductId;
    }
    const { records, current, size, total } = await getApiList(params);
    this.tableList = records;
    this.pagination.current = current;
    this.pagination.pageSize = size;
    this.pagination.total = total;
  }

  variableMgr() {
    createModal(() => <ApiVariable id={this.id} />, {
      title: '变量管理',
      width: 1500,
    });
  }
  openForm(apiId) {
    const modal = createModal(
      () => (
        <SettingApiModel
          platformId={this.id}
          apiId={apiId}
          platformIdentifier={this.platformIdentifier}
          onClose={() => modal.handleClose()}
          onRefreshList={() => this.refreshList()}
        />
      ),
      {
        title: '连接器配置',
        width: 960,
      },
    );
  }

  threePush() {
    const modal = createModal(
      () => (
        <ThreeModel platformId={this.id} onClose={() => modal.handleClose()} />
      ),
      {
        title: '三方推送管理',
        width: 1200,
      },
    );
  }

  refreshList() {
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.apiNameOrIdentifier = '';
    this.idxProductId = 'all';
    this.getList();
  }

  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getList();
  }
  async addApi(apiId = '') {
    if (apiId === '') {
      const addApi = await createFormModal(() => <AddApi id={this.id} />, {
        title: '添加连接器',
        width: 500,
      });
      if (addApi) {
        await this.getList();
        this.openForm(addApi);
      }
    } else {
      this.openForm(apiId);
    }
  }

  debugApi(item) {
    this.$confirm({
      title: '调试前请确保：',
      content: (
        <div>
          <p>1、API的基础配置完善无误；</p>
          <p>2、API关联了正确的自定义数据模板；</p>
          <p>3、当前API已绑定正确的接口（如果需要）；</p>
        </div>
      ),
      onOk: async () => {
        this.$destroyAll();
        await this.debug(item);
      },
      onCancel() {},
    });
  }

  /** @description 停用【API连接器】 */
  handleStop(apiId) {
    this.$confirm({
      title: '确定停用当前API？',
      content: (
        <div>
          <p style="font-size: 12px">
            *停用后已订阅当前API的用户将无法使用并切换为“待发布”状态，请慎重操作！
          </p>
        </div>
      ),
      onOk: async () => {
        this.$destroyAll();
        try {
          await updateStatus({
            pkId: apiId,
            status: 'UNPUBLISHED', // 【停用】
          });
          this.$message.success('操作成功！');
          await this.getList();
        } catch (error) {
          return;
        }
      },
      onCancel() {},
    });
  }

  debugModal = null;
  async debug(item) {
    try {
      this.debugModal = createModal(
        () => (
          <DebugApi
            platformId={this.id}
            apiItem={item}
            close={flag => {
              this.debugModal.handleClose();
              if (flag) {
                this.getList();
              }
            }}
          />
        ),
        {
          title: 'API调试',
          width: 1000,
        },
      );
    } catch (error) {
      if (error.message === 'user cancel') return;
    }
  }

  async handleDel(record) {
    this.$confirm({
      title: '操作提示',
      content: `确定删除当前API？ 当前API关联的自定义模板不会被删除且状态将会变为 “未关联”！！`,
      okText: this.$t('common.action.confirm'),
      cancelText: this.$t('common.action.cancel'),
      onOk: async () => {
        await deleteConfigs(record.pkId);
        await this.getList();
        record.id;
        this.$message.success(this.$t('common.tips.delete'));
      },
    });
  }
}
</script>

<style lang="less" module>
.tagColumn {
  display: flex;
  flex-direction: column;
  span {
    line-height: 18px;
  }
  span:last-child {
    color: var(--font-info);
  }
}
.buttonGroups {
  display: flex;
  justify-content: flex-start;
  .red {
    color: var(--delete);
  }
  .button {
    width: 50px;
    position: relative;
  }
}
</style>
