<template>
  <div>
    <field-config-config-desc :desc="$t('controls.desc.syncInterface')" />
    <a-form-model-item>
      <a-select>
        <a-select-option key="1" value="1">同步API</a-select-option>
      </a-select>
    </a-form-model-item>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';

@Component({
  components: {
    FieldConfigConfigDesc,
  },
})
export default class SyncInterfaceConfig extends Vue {}
</script>
<style lang="less" module></style>
