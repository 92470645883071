<template>
  <div :class="$style.deviceWrap">
    <div>
      <a-input-search
        v-model="form.input"
        placeholder="请输入设备号查询"
        style="width: 240px;margin-right: 10px"
        @search="getData"
      />
      <a-radio-group
        v-model="form.radio"
        button-style="solid"
        @change="changeRedio"
      >
        <a-radio-button
          v-for="item in screenArr"
          :key="item.value"
          :value="item.value"
        >
          {{ item.label }}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div :class="$style.device">
      <div :class="[$style.left, $style.border]">
        <a-spin :spinning="spinning">
          <EmptyContent v-if="deviceList.length === 0" style="height: 460px;" />
          <div
            v-else
            style="height: 460px; overflow-x: hidden;overflow-y: auto"
          >
            <div
              v-for="item in deviceList"
              :key="item.pkId"
              :class="[$style.leftItem, $style.border]"
              @click="checkedDevice(item)"
            >
              <div :class="$style.code">
                <div :class="$style.text">
                  {{ item.deviceCode }}
                </div>
                <div style="margin-left: 5px">
                  <a-tag :color="colorObj[item.deviceStatus]">
                    {{ statusObj[item.deviceStatus] }}
                  </a-tag>
                  <a-tag :color="colorObj[item.deviceType]">
                    {{ typeObj[item.deviceType] }}
                  </a-tag>
                </div>
              </div>
              <a-checkbox v-model="item.checked" />
            </div>
          </div>
        </a-spin>
        <a-pagination
          size="small"
          :total="pagination.total"
          @change="changePage"
        />
      </div>
      <div :class="[$style.right, $style.border]" style="overflow: auto">
        <div style="overflow: hidden auto; ">
          <EmptyContent
            v-if="selectedList.length === 0"
            style="margin-top: 20%"
          />
          <div v-else style="display: flex; flex-wrap: wrap">
            <div
              v-for="(item, idx) in selectedList"
              :key="item.pkId"
              :class="[$style.leftItem, $style.border]"
              style="width: max-content;height: 40px;margin: 5px"
              @click="item.checked = !item.checked"
            >
              <div
                :class="$style.code"
                style="width: 100%;display: flex;align-items: center"
              >
                <div :class="$style.text">
                  {{ item.deviceCode }}
                </div>
                <a-tag :color="colorObj[item.deviceStatus]">
                  {{ statusObj[item.deviceStatus] }}
                </a-tag>
                <a-tag :color="colorObj[item.deviceType]">
                  {{ typeObj[item.deviceType] }}
                </a-tag>
                <x-icon
                  type="tc-icon-delete"
                  :class="$style.deleteIcon"
                  @click="deleteSelect(idx)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  getDeleteDeviceList,
  getList,
} from '@/services/iot-platform/platformDevice';
import EmptyContent from '@/components/empty-content';

@Component({
  components: {
    EmptyContent,
  },
})
export default class DeviceSelect extends Vue {
  @Prop({ type: String, default: '' }) productId;
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: Array, default: () => [] }) deviceIds;

  mounted() {
    this.getData();
  }

  form = {
    radio: 'ALL',
  };
  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };
  screenArr = [
    { label: '全部', value: 'ALL' },
    { label: '直连设备', value: 'DIRECT_CONNECTION' },
    { label: '网关设备', value: 'GATEWAY' },
    { label: '网关子设备', value: 'SUB_GATEWAY' },
  ];
  deviceList = [];
  colorObj = {
    NOT_ALLOCATED: '#FFC12B',
    ALLOCATED: '#00c361',
    BIND: '#339DEE',
    EXPIRED: '#4DD1E2',
    DIRECT_CONNECTION: '#E57375',
    GATEWAY: '#7987CB',
    SUB_GATEWAY: '#CE93D9',
  };
  statusObj = {
    NOT_ALLOCATED: '未分配',
    ALLOCATED: '已分配',
    BIND: '已绑定',
    EXPIRED: '已过期',
  };
  typeObj = {
    DIRECT_CONNECTION: '直连设备',
    GATEWAY: '网关设备',
    SUB_GATEWAY: '网关子设备',
  };
  spinning = false;

  async getData() {
    const params = {
      // productId: '1564091274244186113',
      productId: this.productId,
      platformId: this.platformId,
      current: this.pagination.current,
      size: 10,
    };
    if (this.form.radio !== 'ALL') {
      params['deviceTypeEnum'] = this.form.radio;
    }
    if (this.form.input) {
      params['keyword'] = this.form.input;
    }
    this.spinning = true;
    try {
      if (this.deviceIds.length > 0) {
        const ids = this.deviceIds.map(v => v.pkId);
        const data = await getDeleteDeviceList(ids);
        if (data.length > 0) {
          this.selectedList = this.deviceIds.filter(
            v => data.indexOf(v.pkId) > -1,
          );
        } else {
          this.selectedList = this.deviceIds;
        }
      }
      const { records, total } = await getList(params);
      const arr = [];
      records.forEach(v => {
        const o = {
          pkId: v.pkId,
          deviceCode: `${v.deviceCode}`,
          deviceStatus: v.deviceStatus,
          deviceType: v.deviceType,
          checked: false,
        };
        const idx1 = this.selectedList.findIndex(s => s.pkId === v.pkId);
        o.checked = idx1 > -1;
        arr.push(o);
      });
      this.deviceList = arr;
      this.spinning = false;
      this.pagination.total = total;
    } catch {
      this.spinning = false;
      return false;
    }
  }

  selectedList = [];

  checkedDevice(item) {
    item.checked = !item.checked;
    if (item.checked) {
      this.selectedList.push(item);
    } else {
      const idx = this.selectedList.findIndex(v => v.pkId === item.pkId);
      this.selectedList.splice(idx, 1);
    }
  }

  changePage(page) {
    this.pagination.current = page;
    this.getData();
  }

  deleteSelect(idx) {
    this.selectedList.splice(idx, 1);
  }

  changeRedio() {
    this.getData();
  }

  getValue() {
    return this.selectedList;
  }
}
</script>

<style lang="less" module>
.deviceWrap {
  .device {
    display: flex;
    justify-content: space-between;
    height: 500px;
    margin-top: 10px;

    .left {
      width: 300px;
      padding: 10px 0 10px 10px;
    }

    .right {
      width: 530px;
    }

    .leftItem {
      display: flex;
      align-items: center;
      height: 60px;
      width: 270px;
      margin-bottom: 5px;

      .code {
        width: 240px;

        .text {
          margin: 0 8px;
          height: 25px;
          font-size: 16px;
        }
      }

      &:hover {
        border-color: var(--primary-30);
      }
    }

    .border {
      border: 1px solid var(--border);
      border-radius: 5px;
    }
  }

  .deleteIcon {
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
    color: var(--delete);
  }
}
</style>
