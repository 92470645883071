<template>
  <a-form-model ref="form" :model="formData" :rules="rules">
    <a-form-model-item :label="$t('help.name')" prop="centerName">
      <a-input
        v-model="formData.centerName"
        :placeholder="$t('help.pleaseDocument')"
        :max-length="10"
      />
    </a-form-model-item>
    <a-form-model-item :label="$t('help.repository')" prop="idxRepositoryId">
      <a-select
        v-model="formData.idxRepositoryId"
        :placeholder="$t('common.placeholder.select')"
      >
        <a-select-option
          v-for="item in repositoryData"
          :key="item.repositoryId"
          :value="item.repositoryId"
          >{{ item.repositoryName }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <div :class="$style.summary">
      <template v-if="currentRepository">
        <div :class="$style.item">
          <div :class="$style.item__left">
            <a-button
              type="primary"
              ghost
              :class="$style.item__button"
              @click="openIndex"
              >{{ $t('help.sharedDirectory') }}</a-button
            >
            <div :class="$style.item__block1">
              <span :class="$style.item__label">{{ $t('help.status') }}：</span
              >{{
                currentRepository.open ? $t('help.open') : $t('help.closed')
              }}
            </div>
          </div>
          <div :class="$style.item__block2">
            <span :class="$style.item__label">{{ $t('help.password') }}：</span
            ><span :class="$style.item__password">{{ password }}</span>
            <x-icon
              v-if="currentRepository.enablePwd"
              type="eye"
              @click="passwordVisible = !passwordVisible"
            />
          </div>
        </div>
        <div :class="$style.item">
          <div :class="$style.item__left">
            <div :class="$style.item__block3">
              <x-oss-image
                :class="$style.item__avatar"
                :oss-path="currentRepository.avatar"
              >
                <span slot="error">{{
                  currentRepository.createBy.substr(0, 1)
                }}</span>
              </x-oss-image>
              {{ currentRepository.createBy }}
            </div>
            <div :class="$style.item__block4">
              <span :class="$style.item__label"
                >{{ $t('help.createDate') }}：</span
              >{{
                $moment(currentRepository.createDate).format('yyyy.MM.DD HH:mm')
              }}
            </div>
          </div>
          <div :class="$style.item__block5">
            <span :class="$style.item__label">{{ $t('help.aging') }}：</span
            >{{
              currentRepository.forever
                ? $t('help.forever')
                : $moment(currentRepository.expireDate).format(
                    'yyyy.MM.DD HH:mm',
                  )
            }}
          </div>
        </div>
      </template>
    </div>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { getKnowledgeList } from '@/services/resource/repository';
import { generateMap } from '@triascloud/utils';
import { add, update } from '@/services/resource/helpCenter';
import PublicRepositoryIndexModal from './public-repository-index-modal.vue';
import { globalVueI18n } from '@triascloud/i18n';
function createHelpSettingModal(props) {
  return createFormModal(h => h(HelpSettingModal, { props }), {
    title: !props
      ? globalVueI18n.translate('help.add')
      : globalVueI18n.translate('help.edit'),
    width: 500,
  });
}
@Component()
export default class HelpSettingModal extends Vue {
  static createModal = createHelpSettingModal;
  @Prop({ type: Object }) data;
  formData = {
    centerName: '',
    idxRepositoryId: undefined,
  };
  get rules() {
    return {
      centerName: [
        {
          required: true,
          message: this.$t('help.pleaseDocument'),
        },
        {
          min: 2,
          max: 10,
          message: this.$t('help.validateName', { min: 2, max: 10 }),
        },
      ],
      idxRepositoryId: {
        required: true,
        message: this.$t('help.selectRepository'),
      },
    };
  }
  async created() {
    if (this.data) {
      this.formData.centerName = this.data.centerName;
      this.formData.idxRepositoryId = this.data.idxRepositoryId;
    }
    await this.getRepositoryData();
    if (this.data && !this.repositoryMap[this.data.idxRepositoryId]) {
      this.repositoryData.unshift({
        ...this.data,
        repositoryId: this.data.idxRepositoryId,
        createBy: this.data.promulgator ? this.data.promulgator.memberName : '',
        avatar: this.data.promulgator && this.data.promulgator.avatar,
      });
    }
  }
  get repositoryMap() {
    return generateMap(this.repositoryData, 'repositoryId', item => item);
  }
  get currentRepository() {
    if (!this.formData.idxRepositoryId) return null;
    return this.repositoryMap[this.formData.idxRepositoryId] || null;
  }
  passwordVisible = false;
  get password() {
    if (!this.currentRepository) return '';
    return this.currentRepository.enablePwd
      ? this.passwordVisible
        ? this.currentRepository.password
        : '******'
      : this.$t('help.nothing');
  }
  repositoryData = [];
  async getRepositoryData() {
    this.repositoryData = await getKnowledgeList();
  }

  openIndex() {
    PublicRepositoryIndexModal.createModal({
      pkId: this.currentRepository.repositoryId,
    });
  }

  async getValue() {
    await this.$refs.form.validate();
    if (!this.data) {
      await add(this.formData);
      this.$message.success(this.$t('common.tips.add'));
    } else {
      await update({ ...this.formData, pkCenterId: this.data.pkCenterId });
      this.$message.success(this.$t('common.tips.save'));
    }
  }
}
</script>
<style lang="less" module>
.summary {
  min-height: 82px;
}
.item {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  .item__left {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .item__block1 {
    margin-left: 56px;
  }
  .item__block1,
  .item__block3 {
    width: 86px;
  }
  .item__block3 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .item__block4 {
    width: 186px;
    margin-left: 10px;
  }
  .item__block5 {
    width: 160px;
  }
  .item__label {
    color: var(--font-info);
  }
  .item__block2 {
    min-width: 160px;
  }
  .item__password {
    display: inline-block;
    min-width: 70px;
    padding-right: 10px;
  }
  .item__button {
    width: 130px;
    height: 32px;
  }
  .item__avatar {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    background-color: #d9dfea;
    background-color: var(--x-modal-select-avatar);
    margin-right: 6px;
  }
}
</style>
