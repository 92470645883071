<template>
  <a-spin :spinning="!config" :class="$style.wrap">
    <navigation-setting
      v-if="config"
      :value="config"
      show-desc
      @input="handleConfigChange"
    />
  </a-spin>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { getConfig, saveConfig } from '@/services/configuration/navigation';
import NavigationSetting from '../components/navigation-setting.vue';

@Component({ components: { NavigationSetting } })
export default class Navigator extends Vue {
  config = null;
  created() {
    this.getConfig();
  }
  async getConfig() {
    const res = await getConfig();
    this.config =
      !res || !res.setting
        ? {
            header: {
              navigation: [],
            },
            footer: {
              navigation: [],
            },
          }
        : JSON.parse(res.setting);
  }
  handleConfigChange(config) {
    saveConfig(config);
  }
}
</script>
<style lang="less" module>
.wrap {
  height: 100%;
  :global(.ant-spin-container) {
    height: 100%;
  }
}
</style>
