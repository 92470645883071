<template>
  <div :class="$style.container">
    <BasicFormModal
      ref="basicFormModal"
      :form-model-data="formModelData"
      :formData="formData"
      @changeValue="changeValue"
    />
    <!-- 底部按钮 -->
    <CommonFooter
      slot="footer"
      type="case"
      :initParams="initParams"
      :mode="mode"
      :habitService="habitService"
      :formData="formData"
      :footerRequests="footerRequests"
      @close="$emit('close')"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { crossStorageModule } from '@/enum/store';
import { deepClone } from '@triascloud/utils';
import { HabitService } from '@triascloud/services';
import BasicFormModal from './basic-form-model.vue';
import CommonFooter from './common-footer.vue';
import { addMedia, editMedia } from '@/services/configuration/caseConfig';

@Component({
  components: {
    BasicFormModal,
    CommonFooter,
  },
})
export default class EditCase extends Vue {
  @Prop({ type: Object }) initParams;
  @Prop({ type: String }) mode;
  @crossStorageModule.State tenant;

  formData = {
    title: '',
    label: [],
    img: '',
    briefIntroduction: '',
    signature: '',
    pushTime: this.$moment(new Date()).format('YYYY/MM/DD HH:mm:ss'),
    summarize: '',
  };
  footerRequests = [addMedia, editMedia];
  formModelData = [
    {
      id: 1,
      name: 'title',
      label: 'common.title',
      type: 'textarea',
      rules: [
        {
          required: true,
          trigger: 'blur',
          preMsg: 'common.placeholder.input',
        },
      ],
    },
    {
      id: 2,
      name: 'label',
      label: 'common.label.name',
      type: 'label',
      labelType: 'case',
      marginBottom: '30px',
      rules: [
        {
          required: true,
          trigger: 'blur',
          preMsg: 'common.placeholder.select',
        },
      ],
    },
    {
      id: 3,
      name: 'img',
      label: 'common.img',
      type: 'image',
      rules: [
        {
          required: true,
          trigger: 'change',
          preMsg: 'common.placeholder.add',
        },
      ],
    },
    {
      id: 4,
      name: 'briefIntroduction',
      label: 'website.case.intro',
      type: 'textarea',
      contentHeight: '130px',
      rules: [
        {
          required: true,
          trigger: 'blur',
          preMsg: 'common.placeholder.input',
        },
      ],
    },
    {
      id: 5,
      name: 'signature',
      label: 'website.author',
      type: 'textarea',
      rules: [
        {
          required: true,
          trigger: 'blur',
          preMsg: 'common.placeholder.input',
        },
      ],
    },
    {
      id: 6,
      name: 'pushTime',
      label: 'help.createDate',
      type: 'time',
      rules: [
        {
          required: true,
          trigger: 'blur',
        },
      ],
    },
    {
      id: 7,
      name: 'summarize',
      label: 'website.summarize',
      type: 'editor',
      layout: 'singleLine',
      rules: [
        {
          required: true,
          trigger: 'change',
          preMsg: 'common.placeholder.input',
        },
      ],
    },
  ];

  get habitService() {
    return new HabitService('/configuration/memberHabit');
  }

  async created() {
    if (this.initParams) {
      this.formData = deepClone(this.initParams);
      if (this.formData.pushTime) {
        this.formData.pushTime = this.$moment(this.formData.pushTime).format(
          'YYYY/MM/DD HH:mm:ss',
        );
      }
    } else {
      const list = await this.habitService.getItem(
        `co.website.case.add.${this.tenant.pkId}`,
      );
      if (list && list !== '{}' && list !== 'null') {
        // 1. 保存的时候变成'{}'； 2. 后端默认返回的是'null'
        this.formData = JSON.parse(list);
        if (!this.formData.pushTime)
          // 处理空数据
          this.formData.pushTime = this.$moment(new Date()).format(
            'YYYY/MM/DD HH:mm:ss',
          );
      }
    }
  }

  // 修改 formData 的属性值
  changeValue([name, value, type = 'change']) {
    if (type === 'change') {
      this.formData[name] = value;
    } else if (type === 'add') {
      this.formData[name].push(value);
    } else if (type === 'remove') {
      this.formData[name] = this.formData[name].filter(
        item => item.pkId !== value,
      );
    }
  }
}
</script>
<style lang="less" module>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}
</style>
