<template>
  <div :class="$style.deleteImageModalWrapper">
    <a-form-model ref="form" :model="formData" layout="vertical">
    </a-form-model>
    <x-icon :class="$style.icon" type="warning" style="color: var(--danger)" />
    <div :class="$style.tips">
      {{ $t('common.tips.confirmDeleteImageSubTips') }}？
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';

@Component()
export default class DeleteImageModal extends Vue {
  @Prop({ type: String, default: '' }) pkId;

  static createModal = createModal;

  formData = {
    pkId: '',
  };

  created() {
    this.formData.pkId = this.pkId;
  }
}

function createModal(pkId) {
  return createFormModal(
    render =>
      render(DeleteImageModal, {
        props: { pkId },
      }),
    {
      title: globalVueI18n.translate('common.tips.confirmDelete'),
      width: 440,
    },
  );
}
</script>

<style lang="less" module>
.deleteImageModalWrapper {
  text-align: center;
  .icon {
    font-size: 40px;
  }

  .tips {
    font-size: var(--font-md);
    color: var(--font);
    padding-top: 20px;
  }
}
</style>
