import { AwardType } from '@/enum/activity';
import request from '../request';
const basePath = '/marketing';

export const getInviteCode = report =>
  request(`${basePath}/inviteCode/list`, {
    method: 'GET',
    body: report,
  });

export const getInviteTypeList = () =>
  request(`${basePath}/inviteCode/codeClass/list`, {
    method: 'GET',
  });

export const addInviteCode = report =>
  request(`${basePath}/inviteCode/add`, {
    method: 'GET',
    body: report,
  });

export const exportInviteCode = () =>
  request(`${basePath}/inviteCode/export`, {
    method: 'GET',
  });

export const editClassName = report =>
  request(`${basePath}/inviteCode/codeClass/edit/name`, {
    method: 'GET',
    body: report,
  });

export const getActivityList = report =>
  request(`${basePath}/marketing/manage/list`, {
    method: 'GET',
    body: report,
  }).then(res => {
    return res.map(item => ({ ...item, type: 'activity' }));
  });

export const winningLotteryList = report =>
  request(`${basePath}/activity/award/winning/code/list`, {
    method: 'GET',
    body: report,
  });

export const exportActivityCode = report =>
  request(`${basePath}/activity/award/winning/code/export`, {
    method: 'GET',
    body: report,
  });

export const closeActivity = report =>
  request(`${basePath}/marketing/manage/close`, {
    method: 'GET',
    body: report,
  });

export const editActivity = report =>
  request(`${basePath}/marketing/manage/edit`, {
    method: 'GET',
    body: report,
  });

export const banPickCode = report =>
  request(`${basePath}/inviteCode/banPickCode`, {
    method: 'GET',
    body: report,
  });

export const winningDetail = report =>
  request(`${basePath}/activity/award/winning/code/detail`, {
    method: 'GET',
    body: report,
  });

export const setAward = report =>
  request(`${basePath}/activity/award/is/win`, {
    method: 'GET',
    body: report,
  });

export const statisticsList = report =>
  request(`${basePath}/invitation/statistics/list/${report.invitationClsId}`, {
    method: 'POST',
    body: report,
  });

export const editStatisticsName = report =>
  request(`${basePath}/invitation/statistics/invitationCls/${report.id}`, {
    method: 'POST',
    body: report,
  });

export const invitationClsList = () =>
  request(`${basePath}/invitation/statistics/invitationClsList`, {
    method: 'GET',
  }).then(res => {
    return res.map(item => ({ ...item, type: 'invitation' }));
  });

export const exportInvitationClsList = report =>
  request(
    `${basePath}/invitation/statistics/export/${report.invitationClsId}`,
    {
      method: 'POST',
      body: report,
    },
  );

/** @name 发消息-废弃 */
export const sendMsg = report =>
  request(`${basePath}/activity/award/msg/${report.awards}`, {
    method: 'POST',
  });

/** @name 根据双色球号码获取最近的抽奖码 */
export const getCodeByDualColoredBall = dualColoredBall =>
  request(
    `${basePath}/activity/award/big/prize?dualColoredBall=${dualColoredBall}`,
  );

/** @name 随机获取493个幸运抽奖码 */
export const getLuckyCode = () =>
  request(`${basePath}/activity/award/lucky/prize`);

/** @name 批量设置幸运奖 */
export const batchSetLucky = (codes, type = AwardType.Mystery_Award) =>
  request(`${basePath}/activity/award/setWinList`, {
    method: 'POST',
    body: {
      winCode: codes,
      winType: type,
    },
  });

/** @name 批量发消息 */
export const batchSendAwardMessage = codes =>
  request(`${basePath}/activity/award/sendMsgList`, {
    method: 'POST',
    body: {
      winCode: codes,
    },
  });

/**
 * @name 获取抽奖码发送消息记录
 */
export const getAwardSendRecord = award =>
  request(`${basePath}/activity/award/msgPushRecord?award=${award}`);

/** @name 更新用户数据 */
export const updateUser = () =>
  request(`${basePath}/activity/award/refreshingUserInformation`, {
    method: 'POST',
  });
