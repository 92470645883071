<template>
  <div :class="$style.imageInfoModalWrapper">
    <div :class="$style.left">
      <x-oss-image
        :class="$style.img"
        :title="imgInfo.imgName"
        size="210"
        :oss-path="imgInfo.imgUrl"
        @click.native="
          $preview([{ url: imgInfo.imgUrl, name: imgInfo.imgName }])
        "
      />
    </div>
    <div :class="$style.infoBlock">
      <ul :class="$style.infoList">
        <li
          :class="$style.infoItem"
          v-for="(item, index) in visibleInfoList"
          :key="index"
        >
          <x-ellipsis :class="$style.content">
            <span :class="$style.label">{{ item.label }}：</span
            >{{ item.value }}
          </x-ellipsis>
          <x-icon
            @click="handleOpenLogModal(item)"
            style="color: var(--font-info);cursor: pointer"
            type="eye"
          />
        </li>
      </ul>
      <ul :class="$style.infoList">
        <li
          :class="$style.infoItem"
          v-for="(item, index) in numsInfoList"
          :key="index"
        >
          <x-ellipsis :class="$style.content">
            <span :class="$style.label">{{ item.label }}：</span
            >{{ item.value }}
          </x-ellipsis>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';
import ImageLogInfoModal from './ImageLogInfoModal';
import {
  getChangeDataDetail,
  getHotDataDetail,
} from '@/services/resource/cloud-img';
import { DataSourceService } from '@triascloud/services';

@Component()
export default class ImageInfoModal extends Vue {
  @Prop({ type: Object, default: () => {} }) imgInfo;
  static createModal = createModal;

  // 可点击展示详情的数据列表
  get visibleInfoList() {
    return [
      {
        label: this.$t('common.label.imageName'),
        dataType: 'changeData',
        type: 'NAME',
        value: this.imgInfo.imgName,
        mark: '图片名称',
        titlePath: this.$t('gallery.label.reneameRecord'),
        columns: [
          {
            title: this.$t('gallery.label.nameWasModified'),
            dataIndex: 'beforeName',
          },
          {
            title: this.$t('gallery.label.modifiedName'),
            dataIndex: 'afterName',
          },
          {
            title: this.$t('common.label.editTime'),
            dataIndex: 'operationTime',
          },
        ],
      },
      {
        label: this.$t('common.label.belongGroupName'),
        dataType: 'changeData',
        type: 'GROUP',
        value: this.imgInfo.imgGroupName,
        mark: '所属分类',
        titlePath: this.$t('gallery.label.groupModifiesRecord'),
        columns: [
          {
            title: this.$t('gallery.label.beforeEditGroup'),
            dataIndex: 'beforeName',
          },
          {
            title: this.$t('gallery.label.afterEditGroup'),
            dataIndex: 'afterName',
          },
          {
            title: this.$t('common.label.editTime'),
            dataIndex: 'operationTime',
          },
        ],
      },
      {
        label: this.$t('common.label.likeNums'),
        dataType: 'hotData',
        type: 'LIKE',
        value: this.imgInfo.likeNum,
        mark: '点赞次数',
        titlePath: this.$t('gallery.label.imageLikeDetails'),
        columns: [
          {
            title: this.$t('gallery.label.likePeople'),
            dataIndex: 'operator',
          },
          {
            title: this.$t('gallery.label.likeTime'),
            dataIndex: 'operationTime',
          },
        ],
      },
      {
        label: this.$t('dictionary.quoteCount'),
        dataType: 'hotData',
        type: 'QUOTE',
        value: this.imgInfo.quoteNum,
        mark: '引用次数',
        titlePath: this.$t('gallery.label.pictureReferenceDetails'),
        columns: [
          {
            title: this.$t('gallery.label.ReferencePerson'),
            dataIndex: 'operator',
          },
          {
            title: this.$t('gallery.label.referenceTime'),
            dataIndex: 'operationTime',
          },
        ],
      },
      {
        label: this.$t('common.label.collectNums'),
        dataType: 'hotData',
        type: 'COLLECT',
        value: this.imgInfo.collectNum,
        mark: '收藏次数',
        titlePath: this.$t('gallery.label.detailsOfPictureCollection'),
        columns: [
          {
            title: this.$t('gallery.label.Collector'),
            dataIndex: 'operator',
          },
          {
            title: this.$t('gallery.label.favoriteTime'),
            dataIndex: 'operationTime',
          },
        ],
      },
    ];
  }

  get numsInfoList() {
    return [
      {
        label: this.$t('gallery.label.sharer'),
        value: `${this.imgInfo.createBy} ${
          this.imgInfo.createDate
            ? this.$moment(this.imgInfo.createDate).format('yyyy/MM/DD HH:mm')
            : '--'
        }`,
        mark: '共享者',
      },
      {
        label: this.$t('dictionary.reviewer'),
        value: `${this.imgInfo.checkBy} ${
          this.imgInfo.checkDate
            ? this.$moment(this.imgInfo.checkDate).format('yyyy/MM/DD HH:mm')
            : '--'
        }`,
        mark: '审核人',
      },
      {
        label: this.$t('dictionary.deletePerson'),
        value: `${this.imgInfo.deleteBy} ${
          this.imgInfo.deleteDate
            ? this.$moment(this.imgInfo.deleteDate).format('yyyy/MM/DD HH:mm')
            : '--'
        }`,
        mark: '删除人',
      },
      {
        label: this.$t('dictionary.recoveryPerson'),
        value: `${this.imgInfo.regainBy} ${
          this.imgInfo.regainDate
            ? this.$moment(this.imgInfo.regainDate).format('yyyy/MM/DD HH:mm')
            : '--'
        }`,
        mark: '恢复人',
      },
    ];
  }

  generateService(params) {
    const middleId = this.imgInfo.pkId;
    const { type } = params;
    const service = new DataSourceService('xx');
    service.getList = dataTableParams => {
      const { size, skip } = dataTableParams;
      return params.dataType === 'hotData'
        ? getHotDataDetail({ middleId, type, size, skip })
        : getChangeDataDetail({ middleId, type, size, skip });
    };
    return service;
  }

  /**
   * 打开数据统计弹窗
   * @returns {Promise<void>}
   */
  async handleOpenLogModal(item) {
    const service = this.generateService(item);
    await ImageLogInfoModal.createModal({
      titlePath: item.titlePath,
      imgInfo: this.imgInfo,
      columns: item.columns,
      service,
    });
  }
}

function createModal({ imgInfo }) {
  return createFormModal(
    render =>
      render(ImageInfoModal, {
        props: {
          imgInfo,
        },
      }),
    {
      title: globalVueI18n.translate('gallery.label.imgInfoTitle'),
      width: 580,
    },
  );
}
</script>

<style lang="less" module>
.imageInfoModalWrapper {
  display: flex;
  .left {
    padding: 20px;
    .img {
      width: 210px;
      height: 210px;
      display: block;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .infoBlock {
    padding: 20px 0 20px 20px;
    flex: 1;

    .infoList {
      margin-bottom: 10px;

      .infoItem {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        .content {
          font-size: var(--font-sm);
          line-height: 1;
          color: var(--font);
          padding-right: 10px;
          .label {
            color: var(--font-info);
          }
        }
      }
    }
  }
}
</style>
