import request from '../request';
import { stringify } from '@triascloud/utils';

const basePath = '/resource/icon-library';
/** @typedef { import('@/enum/icon').DefaultIconTypeEnum } DefaultIconTypeEnum */

/**
 * @name 根据类型获取图标库列表（分页）
 * @param {object} params
 */
export const getIconLibraryList = params =>
  request(`${basePath}/listLibraries`, {
    method: 'POST',
    body: params,
  });

/**
 * @name 获取回收站图标库详情列表（分页，模糊搜索）
 * @param {object} params
 */
export const getRecycleBinList = params =>
  request(`${basePath}/listRecycleBin`, {
    method: 'POST',
    body: params,
  });

/**
 * @name 新增或编辑图标库
 * @param {object} params
 */
// export const saveOrUpdateLibrary = params =>
//   request(`${basePath}/saveOrUpdate`, {
//     method: 'POST',
//     body: params,
//   });

/**
 * @name 图标库详情
 * @param {*} params
 */
export const getDetail = params =>
  request(`${basePath}/detail`, {
    method: 'POST',
    body: params,
  });

/**
 * @name 调整图标顺序
 * @param {string[]} iconIds 图标ID集合
 * @param {string} iconLibraryId 图标库ID
 */
export const sortIcon = (iconIds, iconLibraryId) =>
  request(`${basePath}/sort`, {
    method: 'POST',
    body: { iconIds, iconLibraryId },
  });

/**
 * @name 彻底删除图标库
 * @param {string} iconLibraryId 图标库ID
 */
export const removeLibrary = iconLibraryId =>
  request(`${basePath}/removeLibrary?iconLibraryId=${iconLibraryId}`, {
    method: 'DELETE',
    // body: `iconLibraryId=${iconLibraryId}`,
  });

/**
 * @name 图标库获取所有图标展示基础信息列表
 * @param {string} libraryId 图标库ID
 */
export const getAllIconByLibraryId = libraryId =>
  request(`${basePath}/listIcon/${libraryId}`, {
    method: 'GET',
  });

/**
 * @name 图标库获取所有图标Id
 * @param {string} libraryId 图标库ID
 */
export const getAllIdByLibraryId = libraryId =>
  request(`${basePath}/allIconId/${libraryId}`, {
    method: 'GET',
  });

/**
 * @name 添加图标
 * @param {string[]} iconIds 图标ID集合
 * @param {string} iconLibraryId 图标库ID
 */
export const addIcon = (iconIds, iconLibraryId) =>
  request(`${basePath}/addIcon`, {
    method: 'POST',
    body: { iconIds, iconLibraryId },
  });

/**
 * @name 图标库移除图标
 * @param {string[]} iconIds 图标ID集合
 * @param {string} iconLibraryId 图标库ID
 */
export const removeIcon = (iconIds, iconLibraryId) =>
  request(`${basePath}/removeIconInLibrary`, {
    method: 'DELETE',
    body: { iconIds, iconLibraryId },
  });

/**
 * @name 所有类型下图标库分组
 */
export const treeByType = () =>
  request(`${basePath}/treeByType`, {
    method: 'POST',
  });

/**
 * @name 获取默认图标
 * @param {DefaultIconTypeEnum} iconLibraryId
 */
export const getDefaultIcon = iconLibraryId =>
  request(
    `${basePath}/getDefaultIconInSetting?iconLibraryId=${iconLibraryId}`,
    {
      method: 'GET',
    },
  );

/**
 * @name 添加默认图标
 * @param {DefaultIconTypeEnum} iconLibraryId 图标库ID
 * @param {string[]} iconIds 图标ID集合
 */
export const addDefaultIcon = (iconLibraryId, iconIds) =>
  request(`${basePath}/addDefaultIcon`, {
    method: 'POST',
    body: { iconLibraryId, iconIds },
  });

/**
 * @name 恢复图标库
 * @param {object} params
 */
export const recoverLibrary = params =>
  request(`${basePath}/regain`, {
    method: 'POST',
    body: params,
  });

/**
 * @name 删除图标库
 * @param {object} params
 */
export const deleteLibrary = params =>
  request(`${basePath}/deleted`, {
    method: 'POST',
    body: params,
  });

/**
 * @name 新增图标库
 * @param {object} params
 */
export const saveLibrary = params =>
  request(`${basePath}/save`, {
    method: 'POST',
    body: params,
  });

/**
 * @name 编辑图标库
 * @param {object} params
 */
export const updateLibrary = params =>
  request(`${basePath}/update`, {
    method: 'POST',
    body: params,
  });

/**
 * @name 图标库置顶
 * @param {object} params
 */
export const iconAlignTop = params =>
  request(`${basePath}/top`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: stringify(params),
  });
