// @ts-check
import { BaseControl } from '../BaseControl';
import ConfigComponent from './config.vue';

/** @name 功能名称 */
class FnNameControl extends BaseControl {
  /** @readonly */
  type = 'fnName';

  label = 'controls.fnName.label';

  icon = 'tc-icon-font-line';

  trigger = 'blur';

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    identifier: 'name',
    required: true,
    alone: true, // 独立字段，不允许操作
  });

  FormComponent = 'x-input';

  DesignComponent = 'x-input';

  ConfigComponent = ConfigComponent;
}

export default new FnNameControl();
