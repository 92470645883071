<template>
  <div :class="$style.index">
    <x-data-table
      ref="statisticsTable"
      base-path="/marketing/invitation/statistics/list"
      :columns="columns"
      :service="service"
      :class="$style.table"
      :custom-row="
        () => ({
          style: {
            cursor: 'pointer',
          },
        })
      "
      :scroll="{
        x: '100%',
        y: '100%',
      }"
    >
      <template slot="action">
        <a-button type="link" @click="handleExport" v-if="$p.action('EXPORT')">
          <span>{{ $t('inviteCode.export') }}</span>
        </a-button>
        <a-button @click="handleSearch" type="info">
          <span>{{ $t('activity.Inquire') }}</span>
        </a-button>
        <a-button type="primary" @click="handleReset">
          {{ $t('activity.reset') }}
        </a-button>
      </template>
      <div slot="headerLeft" :class="$style.toolbar">
        <span>
          被邀请的人：
          <a-input
            :class="$style.toolbar__input"
            v-model="searchData.beInviteUserName"
          >
          </a-input>
        </span>
        <span>
          邀请者：
          <a-input
            :class="$style.toolbar__input"
            v-model="searchData.inviteUserName"
          >
          </a-input>
        </span>
        <a-range-picker
          :class="$style.toolbar__picker"
          :placeholder="[
            `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
              'common.label.day',
            )}`,
            `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
              'common.label.day',
            )}`,
          ]"
          :separator="$t('common.label.to')"
          @change="handlerDatePickerChange"
        />
      </div>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import {
  statisticsList,
  exportInvitationClsList,
} from '@/services/marketing/invite-code';
import { DataSourceService } from '@triascloud/services';
// import InvitationCodeDetails from './invitation-code-details.vue';
// import { ActivityTypeText } from '@/enum/activity';
import { clipboardWrite } from '@triascloud/utils';

@Component
export default class StatisticsList extends Vue {
  @Prop({ type: String, default: '' }) activityId;
  searchData = {
    beInviteUserName: '',
    inviteUserName: '',
    timeEnd: '',
    timeStart: '',
  };
  datePicker = [];
  handlerDatePickerChange(range) {
    if (range.length) {
      this.searchData.timeStart = range[0].startOf('day').valueOf();
      this.searchData.timeEnd = range[1].startOf('day').valueOf();
    } else {
      this.searchData.timeStart = null;
      this.searchData.timeEnd = null;
    }
  }
  get columns() {
    return [
      {
        title: '邀请的人',
        dataIndex: 'applyUserName',
        key: 'applyUserName',
      },
      {
        dataIndex: 'applyUserCompanyName',
        key: 'applyUserCompanyName',
        title: '公司',
      },
      {
        key: 'applyUserTitle',
        title: '职务',
        dataIndex: 'applyUserTitle',
      },
      {
        title: '电话',
        key: 'applyUserPhone',
        dataIndex: 'applyUserPhone',
      },
      {
        title: '邮箱',
        dataIndex: 'applyUserEmail',
        key: 'applyUserEmail',
      },
      {
        title: '邀请者',
        dataIndex: 'inviteUserName',
        key: 'inviteUserName',
      },
      {
        title: '邀请者手机',
        dataIndex: 'inviteUserPhone',
        key: 'inviteUserPhone',
      },
      {
        title: '邀请时间',
        dataIndex: 'applyTime',
        key: 'applyTime',

        customRender: applyTime =>
          applyTime ? moment(applyTime).format('Y-MM-DD HH:mm') : '-',
      },
    ].map(item => ({
      ...item,
      width: item.width || 130,
      sorter: false,
      filter: false,
      align: 'left',
      ellipsis: true,
    }));
  }

  async exportInvitationClsList() {
    await exportInvitationClsList();
  }

  async handleExport() {
    await exportInvitationClsList({
      invitationClsId: this.activityId,
      ...this.searchData,
    });
  }
  handleReset() {
    this.searchData = {
      beInviteUserName: '',
      inviteUserName: '',
      timeEnd: '',
      timeStart: '',
    };
  }
  handleSearch() {
    this.$refs.statisticsTable.loadData(this.searchData);
  }

  async handleCopy(data) {
    await clipboardWrite(data.inviteCode);
    this.$message.success(this.$t('common.tips.copy'));
  }

  total = 0;
  get service() {
    const service = new DataSourceService(
      `/marketing/invitation/statistics/list`,
    );
    service.getList = report =>
      statisticsList({
        timeEnd: '',
        timeStart: '',
        beInviteUserName: '',
        inviteUserName: '',
        invitationClsId: this.activityId,
        ...report,
        skip: report.skip / report.size + 1,
      }).then(res => {
        return {
          ...res,
          data: res.records,
          current: 1,
          hitCount: false,
          pages: 1,
          searchCount: true,
          size: 10,
          total: res ? res.total : 0,
        };
      });
    return service;
  }
  refreshData() {
    this.$refs.statisticsTable.loadData();
  }
  //   async openDetail(data) {
  //     await InvitationCodeDetails.createDrawer(
  //       { data: data },
  //       {
  //         refresh: () => {
  //           this.$refs.statisticsTable.loadData();
  //         },
  //       },
  //     );
  //   }
}
</script>
<style lang="less" module>
.table {
  padding: 20px;
  :global(.x-data-table--action .ant-btn-primary) {
    color: #fff;
    margin-left: 10px;
  }
}

.index {
  height: calc(100% - 100px);
}
.action {
  cursor: pointer;
  color: var(--font-active);
  &.action--delete {
    color: var(--delete);
    margin-left: 20px;
  }
}
.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .toolbar__input {
    width: 120px;
    margin-right: 30px;
    :global .ant-input {
      width: 110px;
      transition: width 0.5s;
      &:focus {
        width: 200px;
      }
    }
  }
  .toolbar__picker {
    width: 220px;
    margin: 0 5px;
    :global {
      .ant-calendar-picker-icon {
        display: none;
      }
      .ant-calendar-picker-input.ant-input {
        padding-left: 10px;
        text-align: center;
      }
    }
  }
}
</style>
