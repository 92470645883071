import { Vue } from 'vue-property-decorator';
export const Emitter = new Vue();

export const EventType = {
  /** @name 模型名称搜索 */
  SEARCH_DATA_TEMPLATE: 'search:data-template',
  ADD_DATA_TEMPLATE: 'add:data-template',
  IMPORT_DATA_TEMPLATE: 'import:data-template',
  ADD_DP_DATA: 'add:dp-data',
  DOWNLOAD_DP_DATA: 'download:dp-data',
  SYNC_DP_DATA: 'sync:dp-data',
  IMPORT_DP_DATA: 'import:dp-data',
  GOTO_PLATFORM: 'goto-platform',
  SUBSCRIBE_CFG: 'subscribe-cfg',
  ADD_SERVER_DATA: 'add-server-data',
  API_CONFIG_CHANGE: 'api-config-change',
  API_CONFIG_SEARCH: 'api-config-search',
  ADD_API_CONFIG: 'add-api-config',
  VARIABLE_MANAGE: 'variable-manage',
  THIRD_PARTY: 'THIRD_PARTY',
  DATA_MANAGE_ADD: 'data-manage-add',
  DATA_MANAGE_CHANGE: 'data-manage-change',
  DATA_MANAGE_SEARCH: 'data-manage-search',
  /** @name 设备码/密钥搜索 */
  DEVICE_MANAGE_SEARCH: 'device-manage-search',
  /** @name 全部产品SELECT */
  DEVICE_MANAGE_CHNAGE: 'device-manage-change',
  /** @name 全部产品status */
  DEVICE_STATUS_CHNAGE: 'device-status-change',
  /** @name 更新产品SELECT */
  DEVICE_MANAGE_PRODUCT_UPDATE: 'device-manage-product-update',
  /** @name 产品设置 */
  DEVICE_MANAGE_SETTING: 'device-manage-setting',
  /** @name 添加设备 */
  DEVICE_MANAGE_ADD: 'device-manage-add',
  /** @name 搜索DP */
  SEARCH_DP_DATA: 'SEARCH_DP_DATA',
  /** @name 筛选产品，关于DP */
  CHOOSE_PRODUCT_DP_DATA: 'CHOOSE_PRODUCT_DP_DATA',
};

export const DATATYPE = {
  int: 'INT32',
  float: 'FLOAT',
  double: 'DOUBLE',
  boolean: 'BOOL',
  enum: 'ENUM',
  text: 'TEXT',
  date: 'DATE',
  struct: 'STRUCT',
  array: 'ARRAY',
};
export const identifier = val =>
  val.match(/（[a-zA-Z]{0,}[\u4e00-\u9fa5]{0,}）/)[0].slice(1, -1);
export const dpJsonSchema = {
  classificationName: { type: 'integer' },
  dataPointId: { type: 'string' },
  dataSpecs: { type: 'string' },
  functionIdentifier: { type: 'string' },
  functionName: { type: 'string' },
  idempotent: { type: 'string' },
  idxPlatformId: { type: 'string' },
  productName: { type: 'string' },
  updateBy: { type: 'string' },
  updateName: { type: 'string' },
  updateTime: { type: 'string' },
};
const baseFields = {
  customizeName: { type: 'string' },
  dataType: { type: 'string' },
  thingIdentifier: { type: 'string' },
};
const baseRequired = {
  required: ['customizeName', 'dataType', 'thingIdentifier', 'dataSpecs'],
};
const NumberSchema = {
  type: 'object',
  properties: {
    ...baseFields,
    dataSpecs: {
      type: 'object',
      properties: {
        step: {
          type: 'object',
          properties: {
            identifier: { type: 'string' },
            customizeName: { type: 'string' },
          },
          required: ['identifier', 'customizeName'],
        },
        stepFlag: { type: 'boolean' },
        unit: {
          type: 'object',
          properties: {
            source: { type: 'string' },
            unitName: { type: 'string' },
            identifier: { type: 'string' },
            customizeName: { type: 'string' },
          },
          required: ['source', 'unitName', 'identifier', 'customizeName'],
        },
        unitFlag: { type: 'boolean' },
        maxIdentifier: { type: 'string' },
        minIdentifier: { type: 'string' },
      },
      required: [
        'step',
        'stepFlag',
        'unit',
        'unitFlag',
        'maxIdentifier',
        'minIdentifier',
      ],
    },
  },
  ...baseRequired,
};
const JsonSchema = {
  type: 'object',
  properties: {
    ...baseFields,
    dataSpecs: {
      type: 'object',
      properties: {
        sonParamsName: { type: 'string' },
        sonParamsSpecs: { type: 'string' },
        sonParamsIdentifier: { type: 'string' },
      },
      required: ['sonParamsName', 'sonParamsSpecs', 'sonParamsIdentifier'],
    },
  },
  ...baseRequired,
};
export const types = Vue => [
  {
    key: DATATYPE.int,
    value: 'int32（整数）',
    desc: Vue.$t('connector.dataType.type.int32'),
    schema: NumberSchema,
  },
  {
    key: DATATYPE.float,
    value: 'float（单精度浮点）',
    desc: Vue.$t('connector.dataType.type.float'),
    schema: NumberSchema,
  },
  {
    key: DATATYPE.double,
    value: 'double（双精度浮点）',
    desc: Vue.$t('connector.dataType.type.double'),
    schema: NumberSchema,
  },
  {
    key: DATATYPE.text,
    value: 'text（文本）',
    desc: Vue.$t('connector.dataType.type.text'),
    schema: {
      type: 'object',
      properties: {
        ...baseFields,
        dataSpecs: {
          type: 'object',
          properties: {
            max: { type: 'integer' },
          },
          required: ['max'],
        },
      },
      ...baseRequired,
    },
  },
  {
    key: DATATYPE.date,
    value: 'date（时间戳）',
    desc: Vue.$t('connector.dataType.type.date'),
    schema: {
      type: 'object',
      properties: {
        ...baseFields,
        dataSpecs: {
          type: 'object',
          properties: {
            storage: { type: 'string' },
            precision: { type: 'string' },
          },
          required: ['storage', 'precision'],
        },
      },
      ...baseRequired,
    },
  },
  {
    key: DATATYPE.boolean,
    value: 'bool（布尔）',
    desc: Vue.$t('connector.dataType.type.boolean'),
    schema: {
      type: 'object',
      properties: {
        ...baseFields,
        dataSpecs: {
          type: 'object',
          properties: {
            maxLength: { type: 'integer' },
          },
          required: ['maxLength'],
        },
      },
      ...baseRequired,
    },
  },
  {
    key: DATATYPE.enum,
    value: 'enum（枚举）',
    desc: Vue.$t('connector.dataType.type.enum'),
    schema: {
      type: 'object',
      properties: {
        ...baseFields,
        dataSpecs: {
          type: 'object',
          properties: {
            amount: { type: 'integer' },
            enumValueType: { type: 'string' },
          },
          required: ['amount', 'enumValueType'],
        },
      },
      ...baseRequired,
    },
  },
  {
    key: DATATYPE.struct,
    value: 'struct（JSON对象）',
    desc: Vue.$t('connector.dataType.type.struct'),
    schema: JsonSchema,
  },
  {
    key: DATATYPE.array,
    value: 'array（数组）',
    desc: Vue.$t('connector.dataType.type.array'),
    schema: JsonSchema,
  },
];

/**
 * @name 中文或者字符长度
 * @param txt {string} 文本
 * @param length {number} 限制长度
 */
export const limitTxtLength = (txt, length) => {
  return txt.length <= length;
};

export const domClassList = node => {
  try {
    // let el = this.$refs.boxRef.parentNode;
    let el = node;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (!el) {
        break;
      }
      const array = Array.from(el.classList);
      const result = array.find(v => v === 'ant-form-item-control');
      if (result) {
        break;
      } else {
        el = el.parentNode;
      }
    }
    return el ? Array.from(el.classList) : [];
  } catch (error) {
    return [];
  }
};

export const unitArray = [
  { key: '无', value: '无（无）' },
  { key: 'm', value: '米（m）' },
  { key: 'km', value: '千米（km）' },
  { key: 'dm', value: '分米（dm）' },
  { key: 'cm', value: '厘米（cm）' },
  { key: 'mm', value: '毫米（mm）' },
  { key: 'μm', value: '微米（μm）' },
  { key: 'nm', value: '纳米（nm）' },
  { key: '㎡', value: '平方米（㎡）' },
  { key: 'k㎡', value: '平方千米（k㎡）' },
  { key: 'm㎡', value: '平方毫米（m㎡）' },
  { key: 'c㎡', value: '平方厘米（c㎡）' },
  { key: 'h㎡', value: '公顷（h㎡）' },
  { key: 'm³', value: '立方米（m³）' },
  { key: 'km³', value: '立方千米（km³）' },
  { key: 'cm³', value: '立方厘米（cm³）' },
  { key: 'mm³', value: '立方毫米（mm³）' },
  { key: 'L', value: '升（L）' },
  { key: 'mL', value: '毫升（mL）' },
  { key: 'N', value: '牛（N）' },
  { key: 'kg', value: '千克（kg）' },
  { key: 'g', value: '克（g）' },
  { key: 'mg', value: '毫克（mg）' },
  { key: 'Pa', value: '帕斯卡（Pa）' },
  { key: 'kPa', value: '千帕（kPa）' },
  { key: 'hPa', value: '百帕（hPa）' },
  { key: 'mPa', value: '毫帕（mPa）' },
  { key: '°C', value: '摄氏度（°C）' },
  { key: 't', value: '吨（t）' },
  { key: 'K', value: '开尔文（K）' },
  { key: '℉', value: '华氏度（℉）' },
  { key: 'J', value: '焦耳（J）' },
  { key: 'kJ', value: '千焦（kJ）' },
  { key: 'eV', value: '电子伏（eV）' },
  { key: 'Wh', value: '瓦时（Wh）' },
  { key: 'kW·h', value: '千瓦时（kW·h）' },
  { key: 'cal', value: '卡路里（cal）' },
  { key: 'W', value: '瓦特（W）' },
  { key: 'kW', value: '千瓦特（kW）' },
  { key: 'mW', value: '毫瓦（mW）' },
  { key: 'μW', value: '微瓦（μW）' },
  { key: 'Hz', value: '赫兹（Hz）' },
  { key: 'rad', value: '弧度（rad）' },
  { key: '°', value: '度（°）' },
  { key: '′', value: '分（′）' },
  { key: '″', value: '秒（″）' },
  { key: 'm/s', value: '米每秒（m/s）' },
  { key: 'km/h', value: '千米每小时（km/h）' },
  { key: 'kn', value: '节（kn）' },
  { key: 'year', value: '年（year）' },
  { key: 'month', value: '月（month）' },
  { key: 'week', value: '周（week）' },
  { key: 'day', value: '日（day）' },
  { key: 'h', value: '小时（h）' },
  { key: 'min', value: '分钟（min）' },
  { key: 's', value: '秒（s）' },
  { key: 'ms', value: '毫秒（ms）' },
  { key: 'V', value: '伏特（V）' },
  { key: 'mV', value: '毫伏（mV）' },
  { key: 'A', value: '安培（A）' },
  { key: 'kA', value: '千安（kA）' },
  { key: 'mA', value: '毫安（mA）' },
  { key: 'μA', value: '微安（μA）' },
  { key: 'Ω', value: '欧姆（Ω）' },
  { key: 'F', value: '法拉（F）' },
  { key: 'μF', value: '微法（μF）' },
  { key: 'pF', value: '皮法（pF）' },
  { key: 'nF', value: '纳法（nF）' },
  { key: 'kg/m³', value: '千克每立方米（kg/m³）' },
  { key: 'g/m³', value: '克每立方米（g/m³）' },
  { key: 'mg/m³', value: '毫克每立方米（mg/m³）' },
  { key: 'μg/m³', value: '微克每立方米（μg/m³）' },
  { key: 'mg/L', value: '毫克每升（mg/L）' },
  { key: 'g/L', value: '克每升（g/L）' },
  { key: 'g/mL', value: '克每毫升（g/mL）' },
  { key: 'bit', value: '比特（bit）' },
  { key: 'lm', value: '流明（lm）' },
  { key: '%', value: '百分比（%）' },
  { key: 'dB', value: '分贝（dB）' },
  { key: 'grav', value: '重力加速度（grav）' },
  { key: 'Lux', value: '照度（Lux）' },
  { key: 'pixel', value: '像素（pixel）' },
  { key: 'ppm', value: '百万分率（ppm）' },
  { key: 'μg/(d㎡·d)', value: '微克每平方分米每天（μg/(d㎡·d)）' },
  { key: 'B', value: '字节（B）' },
  { key: 'KB', value: '千字节（KB）' },
  { key: 'MB', value: '兆字节（MB）' },
  { key: 'GB', value: '吉字节（GB）' },
  { key: 'kcal', value: '千卡路里（kcal）' },
  { key: 'μg/L', value: '微克每升（μg/L）' },
  { key: 'kvarh', value: '千乏时（kvarh）' },
  { key: 'm³/h', value: '立方米每小时（m³/h）' },
  { key: 'Mpa', value: '兆帕（Mpa）' },
  { key: 'L/s', value: '升每秒（L/s）' },
  { key: 'KCL/h', value: '千卡每小时（KCL/h）' },
  { key: 't/h', value: '吨每小时（t/h）' },
  { key: 'r/min', value: '转每分钟（r/min）' },
  { key: 'kg/s', value: '公斤每秒（kg/s）' },
  { key: 'm³/s', value: '立方米每秒（m³/s）' },
  { key: '%RH', value: '相对湿度（%RH）' },
  { key: '只', value: '只（只）' },
  { key: 'uw/cm2', value: '微瓦每平方厘米（uw/cm2）' },
  { key: 'kVar', value: '千乏（kVar）' },
  { key: 'kVA', value: '千伏安（kVA）' },
  { key: 'kV', value: '千伏（kV）' },
  { key: 'Nm3/h', value: '标准立方米每小时（Nm3/h）' },
  { key: 'stepCount', value: '步（stepCount）' },
  { key: 'gear', value: '档（gear）' },
  { key: 'count', value: '次（count）' },
  { key: 'turn/m', value: '转每分钟（turn/m）' },
  { key: 'mm/s', value: '毫米每秒（mm/s）' },
  { key: 'mmol/L', value: '血糖（mmol/L）' },
  { key: 'mmHg', value: '血压（mmHg）' },
  { key: 'ml/min', value: '滴速（ml/min）' },
  { key: 'V/m', value: '伏特每米（V/m）' },
  { key: 'N/C', value: '牛顿每库仑（N/C）' },
  { key: 'uS/cm', value: '微西每厘米（uS/cm）' },
  { key: 'ppb', value: '微克每升（ppb）' },
  { key: 'ppt', value: '纳克每升（ppt）' },
  { key: 'bar', value: '巴（bar）' },
  { key: 'cst', value: '厘斯（cst）' },
  { key: 'pcs', value: '个（pcs）' },
  { key: 'aw', value: '饱和度（aw）' },
  { key: 'cP', value: '厘泊（cP）' },
  { key: 'var', value: '乏（var）' },
  { key: 'mm/hour', value: '降雨量（mm/hour）' },
  { key: 'W/㎡', value: '太阳总辐射（W/㎡）' },
  { key: 'dS/m', value: '土壤EC值（dS/m）' },
  { key: 'pH', value: 'PH值（pH）' },
  { key: 'NTU', value: '浊度（NTU）' },
  { key: 'mg/kg', value: '毫克每千克（mg/kg）' },
];

function randomString(length, chars) {
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}
/**
 * @description 随机字符串
 * @param { Number } len 长度
 */
export const randomFn = len =>
  randomString(
    len,
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
  );
