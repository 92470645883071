import CodeMirror from 'codemirror';
import { generateMap } from '@triascloud/utils';

// const REG_BRACKET = /[[\],()]/;
// const REG_OPERATOR = /[+\-*/=<>!&|]/;

const matchString = (stream, quote) => {
  let result = false;
  let current;
  while ((current = stream.next()) != null) {
    if (current === quote && !result) {
      return false;
    }
    result = !result && current === '\\';
  }
  return result;
};

function normalizeKeywords(keywords) {
  return (keywords || []).map(keyword =>
    typeof keyword === 'string'
      ? {
          text: keyword,
          value: keyword,
          cursorOffset: 0,
        }
      : keyword,
  );
}

/** @type { (options: object) => import('codemirror').Mode['token'] } */
const tokenGenerator = options => {
  const keywordMap = generateMap(
    normalizeKeywords(options.keywords),
    'text',
    () => true,
  );
  return stream => {
    if (stream.eatSpace()) return null;
    const str = stream.next();
    if (str === '"' || str === `'`) {
      matchString(stream, str);
      return 'string';
    }
    // if (REG_BRACKET.test(str)) {
    //   return 'bracket';
    // }
    // if (REG_OPERATOR.test(str)) {
    //   return 'operator';
    // }
    // if (/\d/.test(str)) {
    //   stream.eatWhile(/[\d.]/);
    //   return 'number';
    // }
    stream.eatWhile(/[\w]/);
    const current = stream.current();
    if (current === 'true' || current === 'false') {
      return 'atom';
    }
    // console.log(keywordMap[current], current);
    if (keywordMap[current]) {
      return 'keyword';
    }
    return 'negative';
  };
};

/** @name 变量模式 */
export const MODE = 'variable';

CodeMirror.defineMode(MODE, options => ({
  token: tokenGenerator(options),
}));

CodeMirror.registerHelper('hint', MODE, cm => {
  const cursor = cm.getCursor();
  const token = cm.getTokenAt(cursor);
  if (!token.string) return;
  const searchKey = token.string;
  const keywords = normalizeKeywords(cm.options.keywords);
  const list = keywords
    .filter(keyword => keyword.text.startsWith(searchKey))
    .map(keyword => ({
      text: keyword.text,
      hint: (cm, data) => {
        cm.replaceRange(keyword.value, data.from, data.to, 'complete');
        if (!keyword.cursorOffset) return;
        const cursor = cm.getCursor();
        cm.setCursor({
          line: cursor.line,
          ch: cursor.ch + keyword.cursorOffset,
        });
        cm.focus();
      },
    }));
  if (!list.length) return null;
  return {
    _handlers: {
      select: [(...args) => CodeMirror.signal(cm, 'hint-select', ...args)],
    },
    list,
    from: CodeMirror.Pos(cursor.line, token.start),
    to: CodeMirror.Pos(cursor.line, cursor.ch),
  };
});

CodeMirror.defineMIME('text/x-variable', MODE);
