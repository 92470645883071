<template>
  <div :class="$style.detail">
    <a-form-model
      ref="form"
      :class="$style.detailMain"
      :model="formData"
      :rules="rules"
      layout="vertical"
    >
      <a-form-model-item
        :label="$t('record.versionName') + '：'"
        prop="versionName"
      >
        <a-input
          v-model="formData.versionName"
          :disabled="!$p.action('UPDATE')"
          :class="$style.smallInput"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('record.updateInfo')"
        :class="$style.showCol"
      ></a-form-model-item>
      <a-form-model-item
        :label="$t('record.updateTime') + '：'"
        prop="editTime"
      >
        <a-date-picker
          show-time
          v-model="formData.editTime"
          :class="$style.smallInput"
          placeholder=""
          :disabled="!$p.action('UPDATE')"
          :disabled-date="disabledDate"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('record.duration') + '：'"
        prop="scheduledTime"
      >
        <a-input
          style="width:362px;margin-right:10px;"
          v-model="formData.scheduledTime"
          :disabled="!$p.action('UPDATE')"
        />{{ $t('record.hours') }}
      </a-form-model-item>
      <a-form-model-item :label="$t('record.updateStop') + '：'">
        <a-radio-group
          v-decorator="['radio-group']"
          v-model="formData.isStopSystem"
          :disabled="!$p.action('UPDATE')"
        >
          <a-radio :value="true">
            {{ $t('template.review.checked') }}
          </a-radio>
          <a-radio :value="false">
            {{ $t('template.review.unChecked') }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('record.versionNumber') + '：'">
        <a-input
          :class="$style.smallInput"
          v-model="formData.versionCode"
          @keyup="
            formData.versionCode = formData.versionCode.replace(
              /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,
              '',
            )
          "
          :disabled="!$p.action('UPDATE')"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('record.updateDetails') + '：'"
        :class="$style.showCol"
      >
      </a-form-model-item>
      <a-form-model-item :label="$t('record.infoContent') + '：'">
        <!-- <a-input type="textarea" :auto-size="{ minRows: 2, maxRows: 8 }" /> -->
        <x-textarea
          :class="[$style.feedbackTextarea, $style.smallInput]"
          :placeholder="$t('record.updateProfile')"
          v-model="formData.synopsisContent"
          :disabled="!$p.action('UPDATE')"
          :maxLength="500"
          :view="view"
        ></x-textarea>
      </a-form-model-item>
      <a-form-model-item :label="$t('record.updateDetails') + '：'" required>
        <a-form-model-item
          :class="$style.item"
          v-for="(option, index) in formData.synopsis"
          :key="`${option.value}+${index}`"
          :rules="[
            {
              trigger: 'blur',
              message: $t('record.updateProfile'),
              required: true,
            },
            {
              min: 2,
              max: 100,
              message: $t('record.introductionTip'),
              trigger: 'blur',
            },
          ]"
          :prop="`synopsis.${index}.text`"
        >
          <a-input
            type="text"
            v-model="option.text"
            :class="$style.smallInput"
            :disabled="!$p.action('UPDATE')"
            @change="$refs.form.validateField('synopsis')"
          />
          <x-icon
            type="delete"
            :class="$style.delItem"
            v-if="index !== 0 && $p.action('UPDATE')"
            @click="delOption(option)"
          />
        </a-form-model-item>
        <div :class="$style.action" v-if="$p.action('UPDATE')">
          <a-button type="link" @click="handleAddOption">
            <x-icon type="plus" />{{ $t('inviteCode.add') }}
          </a-button>
          <a-button type="link" @click="handleBatchEdit">
            {{ $t('common.action.edit') }}
          </a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('record.updateInfos') + '：'"
        :class="$style.showCol"
      >
      </a-form-model-item>
      <a-form-model-item :label="$t('record.linkAddress') + '：'">
        <a-radio-group
          v-decorator="['radio-group']"
          v-model="formData.type"
          @change="formData.updateDetail = ''"
          :disabled="!$p.action('UPDATE')"
        >
          <a-radio :value="1">
            {{ $t('record.linkAddress') }}
          </a-radio>
          <a-radio :value="2">
            {{ $t('record.publicKnowledge') }}
          </a-radio>
        </a-radio-group>
        <p v-if="formData.type === 1">
          <a-input
            type="input"
            :class="$style.smallInput"
            :disabled="!$p.action('UPDATE')"
            v-model="formData.updateDetail"
          />
        </p>
        <x-tree-select
          v-else
          :class="$style.smallInput"
          :value="formData.updateDetail"
          :data="treeData"
          v-bind="$attrs"
          :disabled="!$p.action('UPDATE')"
          @input="handleInput"
        >
          <template slot="title" slot-scope="{ data }">
            {{ data.treeTitle }}
          </template>
        </x-tree-select>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('record.UpdateTip') + '：'"
        :class="$style.showCol"
      >
      </a-form-model-item>
      <a-form-model-item :label="$t('record.tipToUser') + '：'">
        <a-radio-group
          v-decorator="['radio-group']"
          v-model="formData.sendMsg"
          :disabled="!$p.action('UPDATE') || formData.issue === 3"
        >
          <a-radio :value="true">
            {{ $t('template.review.checked') }}
          </a-radio>
          <a-radio :value="false">
            {{ $t('template.review.unChecked') }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('record.messageRange') + '：'">
        <a-radio-group
          v-decorator="['radio-group']"
          v-model="formData.sendTo"
          :disabled="!$p.action('UPDATE') || formData.issue === 3"
        >
          <a-radio value="0">
            {{ $t('record.allMembers') }}
          </a-radio>
          <a-radio value="1">
            {{ $t('record.allManage') }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('record.reminderType') + '：'">
        <a-checkbox-group
          :class="$style.visibleFields"
          v-model="formData.msgType"
          :disabled="!$p.action('UPDATE') || formData.issue === 3"
          :options="visibleList"
        >
        </a-checkbox-group>
      </a-form-model-item>
    </a-form-model>
    <div :class="$style.footer" v-if="$p.action('UPDATE')">
      <async-button type="primary" :click="submit">{{
        $t('common.action.confirm')
      }}</async-button>
      <a-button type="info" @click="cancel">{{
        $t('common.action.cancel')
      }}</a-button>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createDrawer } from '@triascloud/x-components';
import BatchEditModal from './batch-edit-modal.vue';
import { deepClone, mapTree } from '@triascloud/utils';
import { documentTree } from '@/services/resource/helpCenter';
import { ensureArray } from '@triascloud/utils';
import AsyncButton from '@/components/async-button';
import { saveRecord } from '@/services/configuration/recordConfig';
import { editRecord } from '@/services/configuration/recordConfig';
import { HabitService } from '@triascloud/services';

import moment from 'moment';
import { globalVueI18n } from '@triascloud/i18n';

function createRecordDetail(props, listener) {
  return createDrawer(
    h =>
      h(RecordDetail, {
        props,
        on: listener,
      }),

    {
      title: globalVueI18n.translate('record.addRecords'),
      width: 900,
      bodyStyle: {
        padding: 0,
      },
      wrapClassName: 'form-preview-drawer--wrapper',
    },
  );
}

@Component({
  components: { AsyncButton },
})
export default class RecordDetail extends Vue {
  @Prop({ type: Object }) data;
  static createDrawer = createRecordDetail;
  submitStatus = false;
  formData = {
    versionName: '',
    editTime: moment(new Date()).format('YYYY/MM/DD HH:mm'),
    scheduledTime: '1~2',
    isStopSystem: false,
    synopsisContent: '',
    synopsis: [{ value: '', text: '' }],
    type: 1,
    updateDetail: '',
    sendMsg: false,
    sendTo: '0',
    msgType: ['1'],
    versionCode: '',
  };

  rules = {
    versionName: [
      {
        required: true,
        trigger: 'blur',
        message: this.$t('record.enterVersion'),
      },
      {
        min: 2,
        max: 30,
        message: this.$t('record.groupNameTip'),
        trigger: 'blur',
      },
    ],
    synopsis: [],
    editTime: [
      {
        required: true,
        trigger: 'blur',
        message: this.$t('record.editTimeTip'),
      },
    ],
    scheduledTime: [
      {
        validator: this.checkScheduledTimeCompare,
        trigger: 'blur',
        required: true,
      },
    ],
  };

  view = null;
  treeData = [];
  disabledDate(current) {
    // return current && current < moment().endOf('day');
    return (
      current.valueOf() <
      moment()
        .startOf('day')
        .format('x')
    );
  }

  get visibleList() {
    return [
      { label: this.$t('record.station news'), value: '1' },
      { label: this.$t('record.nailed'), value: '2' },
      { label: this.$t('record.weChat'), value: '3' },
      { label: this.$t('record.flyingBook'), value: '4' },
    ];
  }

  beforeDestroy() {
    if (!this.submitStatus) {
      this.recordStash();
    }
  }

  recordStash() {
    this.habitService.setItem(
      `co.record.addRecord`,
      JSON.stringify(this.formData),
    );
  }

  get habitService() {
    return new HabitService('/configuration/memberHabit');
  }

  async created() {
    if (this.data) {
      this.formData = deepClone(this.data);
      this.submitStatus = true;
      this.formData.synopsis = ensureArray(JSON.parse(this.data.synopsis))
        .length
        ? ensureArray(JSON.parse(this.data.synopsis)).map(it => {
            return { value: it, text: it };
          })
        : [{ value: '', text: '' }];
      this.formData.msgType = ensureArray(JSON.parse(this.data.msgType));
      this.formData.editTime = moment(this.data.editTime).format(
        'YYYY/MM/DD HH:mm',
      );
    } else {
      let recordStash = await this.habitService.getItem('co.record.addRecord');
      this.formData =
        recordStash && recordStash !== '{}'
          ? JSON.parse(recordStash)
          : this.formData;
    }
    this.getTreeData();
  }

  delOption(data) {
    this.formData.synopsis = this.formData.synopsis.filter(
      item => item !== data,
    );
  }

  checkScheduledTimeCompare(rule, value, callback) {
    if (!/^\d+(\.?|(\.\d+)?)(~\d+(\.?|(\.\d+)?))?$/.test(value) && value) {
      return callback(this.$t('inviteCode.inputErrTip'));
    } else {
      if (value.indexOf('.') === value.length - 1) {
        return callback(this.$t('inviteCode.inputErrTip'));
      }

      if (value.indexOf('~') !== -1) {
        const timeRange = value.split('~');
        if (timeRange[0] > timeRange[1]) {
          return callback(this.$t('inviteCode.inputErrTip'));
        } else {
          return callback();
        }
      }
      return callback();
    }
  }

  handleInput(value) {
    this.formData.updateDetail = value;
  }

  async getTreeData() {
    const list = await documentTree();
    this.treeData = ensureArray(list).map(it => {
      return {
        title: it.repositoryName,
        treeTitle: it.repositoryName,
        pkId: it.repositoryId,
        key: it.repositoryId,
        repositoryId: it.repositoryId,
        parentId: '0',
        disabled: true,
        children: mapTree(it.list, item => {
          return {
            pkId: item.idxRepositoryId + '/' + item.pkCenterId,
            key: item.idxRepositoryId + '/' + item.pkCenterId,
            value: item.idxRepositoryId + '/' + item.pkCenterId,
            title: item.centerName,
            repositoryId: item.idxRepositoryId,
            treeTitle: item.centerName,
            children: item.children,
          };
        }),
      };
    });
  }

  async submit() {
    await this.$refs.form.validate();
    if (this.data) {
      await editRecord({
        ...this.formData,
        synopsis: ensureArray(this.formData.synopsis).map(it => it.text),
      });
    } else {
      await saveRecord({
        ...this.formData,
        synopsis: ensureArray(this.formData.synopsis).map(it => it.text),
      });
      this.habitService.setItem(`co.record.addRecord`, '{}');
    }
    this.$message.success(this.$t('common.tips.save'));
    this.$emit('confirm');
  }

  cancel() {
    this.$emit('close');
  }

  handleAddOption() {
    this.formData.synopsis.push({ value: '', text: '' });
  }

  async handleBatchEdit() {
    const value = await BatchEditModal.createModal({
      data: this.formData.synopsis,
    });
    this.formData.synopsis = ensureArray(value).map(item => ({
      ...item,
      text: item,
      value: item,
    }));
  }

  async getValue() {
    return this.formData;
  }
}
</script>
<style lang="less" module>
.detailMain {
  padding: 20px;
}

.smallInput {
  width: 400px;
}

.delItem {
  margin-left: 10px;
  cursor: pointer;
  color: #999;
}

.showCol {
  margin-bottom: 0;
  :global(.has-error > .ant-form-item-children) {
    display: none;
  }
  :global(.ant-form-item-label > label) {
    color: var(--font);
  }
  :global(.ant-form-item-control > .ant-form-item-children) {
    display: none;
  }
}

.feedbackTextarea {
  width: 400px;
}

.item {
  :global(.ant-input) {
    width: 400px;
    margin-bottom: 10px;
  }
}

.action {
  button {
    padding-left: 0;
    text-align: left;
  }
}

.footer {
  border-top: 1px solid var(--border);
  padding: 10px 20px;
  button {
    margin-right: 20px;
  }
}
</style>
