<template>
  <div :class="$style.controlList">
    <div v-for="group in list" :class="$style.group" :key="group.title">
      <div :class="$style.title">{{ $t(group.title) }}</div>
      <draggable
        :class="$style.list"
        :list="group.list"
        :group="{ name: 'control', pull: 'clone', put: false }"
        :ghost-class="$style.ghost"
        :filter="`.${$style.forbid}`"
        :sort="false"
        :clone="createField"
      >
        <div
          v-for="control in group.list"
          :key="control.type"
          :class="[$style.item, $style[displayMode], getControlClass(control)]"
          :title="$t(control.label)"
          @click.stop="handleAddField(control)"
        >
          <x-icon :type="control.icon" />
          <span :class="$style.label">{{ $t(control.label) }}</span>
          <x-icon type="plus" />
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { templateDesignModule } from '@/enum/store';
import { createField, ControlTypeEnum, TemplateType } from './form/controls';

@Component({
  components: {
    Draggable,
  },
})
export default class FieldList extends Vue {
  @templateDesignModule.Getter fields;
  @templateDesignModule.State templateType;
  @templateDesignModule.State selectedField;
  @Prop() list;

  displayMode = 'Introduction';
  handleAddField(control) {
    if (this.switchControl(control)) return;
    this.$emit('add', this.createField(control));
  }

  /** @name 模板中，可使用的组件 */
  switchControl(control) {
    return this.hasModelTemplate(control) || this.hasCustomTemplate(control);
  }

  /** @name 【物模型模板】模式 */
  hasModelTemplate(control) {
    return (
      this.templateType === TemplateType.MODEL_TEMPLATE &&
      (this.hasControl(control) ||
        this.hasTabs(control) ||
        this.hasCurrentDataSet(control) ||
        this.hasSyncInterface(control) ||
        this.hasStruct(control) ||
        this.hasArray(control) ||
        this.hasDP(control) ||
        this.hasTwiceAddDataSet(control) ||
        this.hasDiffAddOrCurrent(control))
    );
  }

  /** @name 【自定义模板】模式 */
  hasCustomTemplate(control) {
    return (
      this.templateType === TemplateType.CUSTOM_TEMPLATE &&
      (control.type === ControlTypeEnum.Tabs ||
        control.type === ControlTypeEnum.CurrentDataSet ||
        control.type === ControlTypeEnum.AddDataSet ||
        control.type === ControlTypeEnum.SyncInterface ||
        this.hasStructForArray(control) ||
        this.hasArrayForArray(control) ||
        this.hasDataPointForArray(control) ||
        this.hasStructForStruct(control) ||
        this.hasArrayForStruct(control) ||
        this.hasDataPointForStruct(control))
    );
  }

  /** @name （结构体）已经有【结构体】组件，当前锚点是【结构体】 */
  hasStructForStruct(control) {
    if (this.fields.length === 0) return;
    return (
      control.type === ControlTypeEnum.Struct &&
      this.selectedField.type === ControlTypeEnum.Struct
    );
  }

  /** @name （数组）已经有【结构体】组件，当前锚点是【结构体】 */
  hasArrayForStruct(control) {
    if (this.fields.length === 0) return;
    return (
      control.type === ControlTypeEnum.Array &&
      this.selectedField.type === ControlTypeEnum.Struct
    );
  }

  /** @name （DP点）已经有【结构体】组件，当前锚点是【结构体】 */
  hasDataPointForStruct(control) {
    if (this.fields.length === 0) return;
    return (
      control.type === ControlTypeEnum.DataPoint &&
      this.selectedField.type === ControlTypeEnum.Struct
    );
  }

  /** @name （结构体）已经有【数组】组件，当前锚点是【数组】，且选择的子类型是【结构体】 */
  hasStructForArray(control) {
    if (this.fields.length === 0) return;
    return (
      control.type === ControlTypeEnum.Struct &&
      this.selectedField.type === ControlTypeEnum.Array &&
      this.selectedField.widget.subType === ControlTypeEnum.Struct
    );
  }

  /** @name （数组）已经有【数组】组件，当前锚点是【数组】，且选择的子类型是【结构体】 */
  hasArrayForArray(control) {
    if (this.fields.length === 0) return;
    return (
      control.type === ControlTypeEnum.Array &&
      this.selectedField.type === ControlTypeEnum.Array &&
      this.selectedField.widget.subType === ControlTypeEnum.Struct
    );
  }

  /** @name （DP点）已经有【数组】组件，当前锚点是【数组】，且选择的子类型是【结构体】 */
  hasDataPointForArray(control) {
    if (this.fields.length === 0) return;
    return (
      control.type === ControlTypeEnum.DataPoint &&
      this.selectedField.type === ControlTypeEnum.Array &&
      this.selectedField.widget.subType === ControlTypeEnum.Struct
    );
  }

  /** @name 还没有组件，只有【功能分页卡】可以使用 */
  hasControl(control) {
    return control.type !== ControlTypeEnum.Tabs && this.fields.length === 0;
  }

  /** @name 有一个Tabs组件了 */
  hasTabs(control) {
    const item = this.fields.find(v => v.type === ControlTypeEnum.Tabs);
    return (
      control.type === ControlTypeEnum.Tabs && item && this.fields.length !== 0
    );
  }

  /** @name 当前【功能分页卡-分类】禁止使用【结构体】 */
  hasStruct(control) {
    if (this.fields.length === 0) return;
    return control.type === ControlTypeEnum.Struct;
  }

  /** @name 当前【功能分页卡-分类】禁止使用【数组】 */
  hasArray(control) {
    if (this.fields.length === 0) return;
    return control.type === ControlTypeEnum.Array;
  }

  /** @name 当前【功能分页卡-分类】禁止使用【DP源】 */
  hasDP(control) {
    if (this.fields.length === 0) return;
    return control.type === ControlTypeEnum.DataPoint;
  }

  /** @name 当前【功能分页卡-分类】中有一个【当前数据组】 */
  hasCurrentDataSet(control) {
    if (this.fields.length === 0) return;
    const tabField = this.fields.find(v => v.type === ControlTypeEnum.Tabs);
    let item = null;
    if (tabField) {
      item =
        this.currentTab &&
        this.currentTab.fields.find(
          v => v.type === ControlTypeEnum.CurrentDataSet,
        );
    }
    return control.type === ControlTypeEnum.CurrentDataSet && item;
  }

  /** @name 当前【功能分页卡-分类】中有一个【同步接口】 */
  hasSyncInterface(control) {
    if (this.fields.length === 0) return;
    const tabField = this.fields.find(v => v.type === ControlTypeEnum.Tabs);
    let item = null;
    if (tabField) {
      item =
        this.currentTab &&
        this.currentTab.fields.find(
          v => v.type === ControlTypeEnum.SyncInterface,
        );
    }
    return control.type === ControlTypeEnum.SyncInterface && item;
  }

  /** @name 当前【功能分页卡-分类】中有两个【新增数据组】 */
  hasTwiceAddDataSet(control) {
    if (this.fields.length === 0) return;
    const tabField = this.fields.find(v => v.type === ControlTypeEnum.Tabs);
    let count = 0;
    if (tabField) {
      count =
        this.currentTab &&
        this.currentTab.fields.filter(
          v => v.type === ControlTypeEnum.AddDataSet,
        ).length;
    }
    return control.type === ControlTypeEnum.AddDataSet && count === 2;
  }

  /** @name 【当前数据组】和【新增数据组】互斥 */
  hasDiffAddOrCurrent(control) {
    if (this.fields.length === 0) return;
    const tabField = this.fields.find(v => v.type === ControlTypeEnum.Tabs);
    let hasCurrentField = null;
    let hasAddField = null;
    if (tabField) {
      hasCurrentField =
        this.currentTab &&
        this.currentTab.fields.find(
          v => v.type === ControlTypeEnum.CurrentDataSet,
        );
      hasAddField =
        this.currentTab &&
        this.currentTab.fields.find(v => v.type === ControlTypeEnum.AddDataSet);
    }
    return (
      (hasCurrentField && control.type === ControlTypeEnum.AddDataSet) ||
      (hasAddField && control.type === ControlTypeEnum.CurrentDataSet)
    );
  }

  get currentTab() {
    const tabField = this.fields.find(v => v.type === ControlTypeEnum.Tabs);
    return (
      tabField &&
      tabField.widget.tabs.find(v => v.id === tabField.widget.current)
    );
  }

  getControlClass(control) {
    return [
      {
        [this.$style.forbid]: this.switchControl(control),
      },
    ];
  }

  createField(control) {
    return createField(control.type);
  }
}
</script>
<style lang="less" module>
.Introduction {
  width: calc(50% - 10px);
  height: 36px;
}
.controlList {
  padding: 20px 20px 0;
  position: relative;
}
.group {
  margin-top: 5px;
  margin-bottom: 25px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  margin-top: 5px;
}
.item {
  color: var(--font);
  background: var(--main-bg);
  border: 1px solid #eaeaea;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 5px;
  box-shadow: 2px 2px 5px 0px #e1e1e1;
  > :global(.ant-icon) {
    color: var(--font-sub);
  }
  > :global(.anticon-plus) {
    display: none;
  }
  &:hover {
    border-style: dashed;
    border-color: var(--primary);
    > :global(.anticon) {
      color: var(--primary);
    }
    > :global(.anticon-plus) {
      display: block;
    }
  }
}
.title {
  color: var(--font);
}
.label {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
}
:global(.dark) .item {
  border-color: #515151;
  box-shadow: 4px 4px 10px 0px #232323;
  &:hover {
    border-style: dashed;
    border-color: var(--primary);
  }
}

.forbid {
  background-color: var(--form-disabled);
  //background-color: rgb(97, 62, 111);
  cursor: default;
}
</style>
