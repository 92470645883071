<template>
  <div ref="IncomeChart" />
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { globalVueI18n } from '@triascloud/i18n';
import ResizeObserver from 'resize-observer-polyfill';
import { crossStorageModule } from '@/enum/store';

@Component({
  components: {},
})
export default class IncomeCharts extends Vue {
  @Prop({ type: Object }) data;
  /** @type { ResizeObserver } */
  _ob = null;
  myChart = null;
  @crossStorageModule.State skin;
  mounted() {
    this._ob = new ResizeObserver(this.chartResize);
    this._ob.observe(this.$refs.IncomeChart);
    this.initChart();
  }
  chartResize() {
    this.myChart.resize();
  }

  get option() {
    const res =
      this.data && this.data.tenantPayDetailCOS
        ? this.data.tenantPayDetailCOS
        : [];
    return {
      tooltip: {
        trigger: 'item',
        formatter: function(params) {
          return [
            params.data.name + '<br/>',
            globalVueI18n.translate('market.statistics.lumpSum'),
            ': ' +
              (params.data.value / 100 >= 10000
                ? (params.data.value / 1000000).toFixed(2) +
                  globalVueI18n.translate('inviteCode.unit')
                : (params.data.value / 100).toFixed(2) +
                  globalVueI18n.translate('inviteCode.Yuan')) +
              '<br/>',
          ].join('');
        },
      },
      title: [
        {
          text: globalVueI18n.translate('market.statistics.totalAmount'),
          textStyle: {
            fontSize: 16,
            color: getComputedStyle(document.documentElement).getPropertyValue(
              '--font-info',
            ),
          },
          left: 'center',
          top: 140,
        },
        {
          //   text:
          //     this.amountData.reduce((total, currentValue) => {
          //       return total + currentValue.payTheAmount;
          //     }, 0) / 100,
          text: [
            `{a|${
              res && res.length
                ? res.reduce((total, currentValue) => {
                    return total + currentValue.amount;
                  }, 0) /
                    100 >=
                  10000
                  ? (
                      res.reduce((total, currentValue) => {
                        return total + currentValue.amount;
                      }, 0) / 1000000
                    ).toFixed(2)
                  : (
                      res.reduce((total, currentValue) => {
                        return total + currentValue.amount;
                      }, 0) / 100
                    ).toFixed(2)
                : 0
            }}`,
            `{b|${
              res.reduce((total, currentValue) => {
                return total + currentValue.amount;
              }, 0) /
                100 >=
              10000
                ? globalVueI18n.translate('inviteCode.unit')
                : globalVueI18n.translate('inviteCode.Yuan')
            }}`,
          ].join(''),
          textStyle: {
            fontSize: 36,
            color: '#333',
            rich: {
              a: {
                fontSize: 36,
                color: getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--font-info'),
              },
              b: {
                fontSize: 16,
                color: getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--font-info'),
                verticalAlign: 'bottom',
                lineHeight: 40,
                align: 'center',
                padding: [0, 0, 6, 4],
              },
            },
          },
          //   formatter: ,
          left: 'center',
          top: 170,
        },
      ],
      legend: {
        top: 25,
        right: '3%',
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        formatter: function(value) {
          // 超出8个字符后面就用省略号代替
          if (value.length >= 6) {
            let v = value.slice(0, 6) + '...';
            return v;
          } else {
            return value;
          }
        },
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['80%', '55%'],
          avoidLabelOverlap: false,
          //   itemStyle: {
          //     borderRadius: 2,
          //     borderColor: '#fff',
          //     borderWidth: 2,
          //   },
          label: {
            show: true,
            formatter: '{d}%',
            position: 'inside',
            color: '#fff',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '32',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: true,
          },
          data: res.map(item => {
            return {
              value: item.amount,
              name: item.tenantName,
            };
          }),
        },
      ],
    };
  }

  @Watch('option')
  @Watch('skin', { deep: true })
  initChart() {
    const chart = this.$refs.IncomeChart;
    this.myChart = echarts.init(chart);
    this.myChart.setOption(this.option);
  }
}
</script>
<style lang="less" module></style>
