// @ts-check

/** @typedef { (data: Record<string, any>) => Record<string, any> } InitForm */

/** @typedef { (field: import('../../controls').Field, model: import('..').DataFormModelContext) => void } InitField */

/**
 * @template { import('.').DataFormToolConfig } T
 * @property { T } config
 */
export class DataFormTool {
  /** @param { T } config */
  constructor(config) {
    this.config = config;
  }
  initError = false;

  /**
   * @name 工具初始化
   * @type { Function | null }
   */
  initTool = null;
  /**
   * @name 表单初始化数据
   * @type { InitForm | null }
   */
  initForm = null;

  /**
   * @name 字段初始化
   * @type { InitField | null }
   */
  initField = null;
}
