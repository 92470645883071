<template>
  <div :class="$style.index">
    <x-data-table
      ref="table"
      base-path="/marketing/inviteCode/list"
      :columns="columns"
      :service="service"
      :class="$style.table"
      :custom-row="
        record => ({
          on: {
            click: () => openDetail(record),
          },
          style: {
            cursor: 'pointer',
          },
        })
      "
      :scroll="{
        x: '100%',
        y: '100%',
      }"
    >
      <template slot="action">
        <a-button type="link" @click="handleExport" v-if="$p.action('EXPORT')">
          <span>{{ $t('inviteCode.export') }}</span>
        </a-button>
        <a-button @click="handleSearch" type="info">
          <span>{{ $t('activity.Inquire') }}</span>
        </a-button>
        <a-button type="primary" @click="handleReset">
          {{ $t('activity.reset') }}
        </a-button>
      </template>
      <div slot="headerLeft" :class="$style.toolbar">
        <span>
          {{ $t('inviteCode.codes') }}：
          <a-input
            :class="$style.toolbar__input"
            v-model="searchData.inviteCode"
          >
          </a-input>
        </span>
        <span>
          {{ $t('inviteCode.useNot') }}：
          <a-radio-group v-model="searchData.used" :class="$style.left_box">
            <a-radio :value="''"> {{ $t('common.label.all') }}</a-radio>
            <a-radio :value="2"> {{ $t('template.review.checked') }} </a-radio>
            <a-radio :value="1"> {{ $t('template.review.unChecked') }}</a-radio>
          </a-radio-group>
        </span>
        <span>
          {{ $t('activity.Username') }}：
          <a-input :class="$style.toolbar__input" v-model="searchData.userName">
          </a-input>
        </span>
        <span>
          {{ $t('activity.userMobile') }}：
          <a-input
            :class="$style.toolbar__input"
            v-model="searchData.userPhone"
          >
          </a-input>
        </span>
      </div>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import {
  getInviteCode,
  exportInviteCode,
} from '@/services/marketing/invite-code';
import { DataSourceService } from '@triascloud/services';
import InvitationCodeDetails from './invitation-code-details.vue';
import { ActivityTypeText } from '@/enum/activity';
import { clipboardWrite } from '@triascloud/utils';

@Component
export default class InvitationList extends Vue {
  @Prop({ type: String, default: '' }) classId;
  searchData = {
    inviteCode: '',
    used: '',
    userName: '',
    userPhone: '',
  };
  get columns() {
    return [
      {
        title: this.$t('inviteCode.codes'),
        dataIndex: 'inviteCode',
      },
      {
        dataIndex: 'status',
        key: 'status',
        title: this.$t('inviteCode.useNot'),
        customRender: status =>
          status === 1 ? '未使用' : status === 2 ? '已使用' : '冻结',
      },
      {
        key: 'useMemberWechatName',
        title: this.$t('activity.userWeChat'),
        dataIndex: 'useMemberWechatName',
      },
      {
        title: this.$t('activity.userAccount'),
        key: 'useMemberAccount',
        dataIndex: 'useMemberAccount',
        width: 130,
      },
      {
        title: this.$t('activity.Username'),
        dataIndex: 'useMemberName',
        key: 'useMemberName',
      },
      {
        title: this.$t('activity.userMobile'),
        dataIndex: 'useMemberTel',
        key: 'useMemberTel',
      },
      {
        title: this.$t('activity.InvitationType'),
        dataIndex: 'codeType',
        key: 'codeType',
        customRender: type => this.$t(ActivityTypeText[type]),
      },
      {
        title: this.$t('inviteCode.usageTime'),
        dataIndex: 'useTime',
        customRender: checkDate =>
          checkDate ? moment(checkDate).format('Y-MM-DD HH:mm') : '-',
      },

      {
        title: this.$t('common.action.operating'),
        key: 'actions',
        width: 130,
        customRender: record => {
          return (
            <span
              onClick={e => {
                e.stopPropagation();
                this.handleCopy(record);
              }}
            >
              {this.$t('inviteCode.copy')}
            </span>
          );
        },
      },
    ].map(item => ({
      ...item,
      width: item.width || 130,
      sorter: false,
      filter: false,
      align: 'left',
      ellipsis: false,
    }));
  }

  async handleExport() {
    await exportInviteCode();
  }
  handleReset() {
    this.searchData = {
      inviteCode: '',
      used: '',
      userName: '',
      userPhone: '',
    };
  }
  handleSearch() {
    this.$refs.table.loadData(this.searchData);
  }

  async handleCopy(data) {
    await clipboardWrite(data.inviteCode);
    this.$message.success(this.$t('common.tips.copy'));
  }

  total = 0;
  get service() {
    const service = new DataSourceService('/marketing/inviteCode/list');
    service.getList = report =>
      getInviteCode({
        codeClass: this.classId,
        inviteCode: '',
        size: 10,
        skip: 0,
        used: '',
        userName: '',
        userPhone: '',
        ...report,
      }).then(res => {
        return {
          ...res,
          current: 1,
          hitCount: false,
          pages: 1,
          searchCount: true,
          size: 10,
          total: res ? res.total : 0,
        };
      });
    return service;
  }
  refreshData() {
    this.$refs.table.loadData({ codeClass: this.classId });
  }
  async openDetail(data) {
    await InvitationCodeDetails.createDrawer(
      { data: data },
      {
        refresh: status => {
          this.$refs.table.loadData();
          data.status = status;
        },
      },
    );
  }
}
</script>
<style lang="less" module>
.table {
  padding: 20px;
  :global(.x-data-table--action .ant-btn-primary) {
    color: #fff;
    margin-left: 10px;
  }
}

.index {
  height: calc(100% - 100px);
}
.action {
  cursor: pointer;
  color: var(--font-active);
  &.action--delete {
    color: var(--delete);
    margin-left: 20px;
  }
}
.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .toolbar__input {
    width: 120px;
    margin-right: 30px;
    :global .ant-input {
      width: 110px;
      transition: width 0.5s;
      &:focus {
        width: 200px;
      }
    }
  }
  .toolbar__picker {
    width: 220px;
    margin: 0 5px;
    :global {
      .ant-calendar-picker-icon {
        display: none;
      }
      .ant-calendar-picker-input.ant-input {
        padding-left: 10px;
        text-align: center;
      }
    }
  }
}
</style>
