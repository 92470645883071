<template>
  <x-data-table
    ref="table"
    base-path="/resource/cloud-dictionary/list"
    :columns="columns"
    :service="service"
    :class="$style.table"
    :custom-row="
      record => ({
        on: {
          click: () => openDetail(record),
        },
        style: {
          cursor: 'pointer',
        },
      })
    "
  >
    <div slot="headerLeft" :class="$style.toolbar">
      <a-input
        :class="$style.toolbar__input"
        @pressEnter="handleTableKeywordChange($event)"
      >
        <x-icon type="tc-icon-search" slot="prefix" />
      </a-input>
      <span>{{ $t('dictionary.shareTime') }}</span>
      <a-range-picker
        :class="$style.toolbar__picker"
        :placeholder="[
          `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
            'common.label.day',
          )}`,
          `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
            'common.label.day',
          )}`,
        ]"
        :separator="$t('common.label.to')"
        @change="handlerDatePickerChange"
      />
      <span
        >{{ $t('dictionary.total') }}：<a>{{ total }}</a
        >{{ $t('dictionary.aggregation') }}：<a>{{ childrenTotal }}&nbsp;</a
        >{{ $t('dictionary.subset') }}</span
      >
    </div>
    <a-button
      v-if="$p.action('RECYCLE-BIN')"
      slot="action"
      @click="openRecycle"
    >
      <x-icon type="tc-icon-delete" />
      {{ $t('gallery.actions.recycling') }}
    </a-button>
  </x-data-table>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { Debounce } from 'lodash-decorators';
import {
  getDictionaryList,
  deleteDictionary,
} from '@/services/resource/cloud-dictionary';
import {
  DataSourceCompareOperator,
  DataSourceLogicOperator,
  DataSourceService,
} from '@triascloud/services';
import DictionaryDetailDrawer from './components/dictionary-detail-drawer.vue';
import DictionaryRecycle from './components/dictionary-recycle-drawer.vue';
import DictionaryTableTag from './components/dictionary-table-tag';
import { ATooltip } from '@triascloud/x-blocks/dist/ant-design-vue';

@Component
export default class Dictionary extends Vue {
  get columns() {
    return [
      {
        title: this.$t('dictionary.name'),
        dataIndex: 'dictionaryName',
        width: 130,
        customCell: () => ({
          style: {
            color: 'var(--font-active)',
          },
        }),
      },
      {
        key: 'idxBusinessId',
        title: this.$t('dictionary.category'),
        width: 130,
        customRender: record => record.businessName,
        generateFilterOptions: list => {
          const obj = {};
          const arr = [];
          list.forEach(item => {
            if (!obj[item.idxBusinessId]) {
              arr.push({
                text: item.businessName,
                value: item.idxBusinessId,
              });
              obj[item.idxBusinessId] = true;
            }
          });
          return arr;
        },
      },
      {
        key: 'label',
        title: this.$t('dictionary.tag'),
        width: 130,
        customRender: record => <DictionaryTableTag data={record.labels} />,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.sharerName'),
        dataIndex: 'createNickName',
        width: 130,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.sharerPhone'),
        dataIndex: 'createPhone',
        width: 150,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.sharerInstitution'),
        dataIndex: 'shareEnterpriseName',
        width: 130,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.pushTime'),
        dataIndex: 'createDate',
        customRender: createDate => moment(createDate).format('Y-MM-DD HH:mm'),
        generateFilterOptions: list =>
          [...new Set(list.map(item => item.createDate))].map(createDate => ({
            text: moment(createDate).format('Y-MM-DD H:mm'),
            value: createDate,
          })),
        width: 130,
      },
      {
        title: this.$t('dictionary.reviewer'),
        dataIndex: 'checkName',
        width: 130,
      },
      {
        title: this.$t('dictionary.reviewTime'),
        dataIndex: 'checkDate',
        customRender: checkDate => moment(checkDate).format('Y-MM-DD HH:mm'),
        generateFilterOptions: list =>
          [...new Set(list.map(item => item.checkDate))].map(checkDate => ({
            text: moment(checkDate).format('Y-MM-DD H:mm'),
            value: checkDate,
          })),
        width: 130,
      },
      {
        title: this.$t('dictionary.auditOpinion'),
        dataIndex: 'checkOpinion',
        width: 130,
      },
      {
        title: this.$t('dictionary.quoteCount'),
        dataIndex: 'quoteCount',
        width: 130,
      },
      {
        title: this.$t('common.action.operating'),
        key: 'actions',
        width: 130,
        customRender: record => {
          return (
            <span
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <ATooltip title={this.$t('common.label.detail')}>
                <XIcon
                  type="eye"
                  class={this.$style.action}
                  onClick={() => this.openDetail(record)}
                />
              </ATooltip>
              {this.$p.action('DELETE') ? (
                <ATooltip title={this.$t('common.action.delete')}>
                  <XIcon
                    type="tc-icon-delete"
                    class={[this.$style.action, this.$style['action--delete']]}
                    onClick={() => this.remove(record.pkId)}
                  />
                </ATooltip>
              ) : null}
            </span>
          );
        },
        filter: false,
        sorter: false,
      },
    ];
  }

  total = 0;
  childrenTotal = 0;
  get service() {
    const service = new DataSourceService('/resource/cloud-dictionary/list');
    service.getList = report =>
      getDictionaryList({
        ...report,
        filterGroup: {
          group: [
            ...report.filterGroup.group,
            {
              logic: DataSourceLogicOperator.OR,
              group: [
                {
                  compare: DataSourceCompareOperator.EQ,
                  value: 0,
                  field: 'deleted',
                },
              ],
            },
            ...(this.datePicker.length
              ? [
                  {
                    logic: DataSourceLogicOperator.AND,
                    group: [
                      {
                        compare: DataSourceCompareOperator.GE,
                        value: this.datePicker[0],
                        field: 'createDate',
                      },
                      {
                        compare: DataSourceCompareOperator.LE,
                        value: this.datePicker[1],
                        field: 'createDate',
                      },
                    ],
                  },
                ]
              : []),
          ],
          logic: DataSourceLogicOperator.AND,
        },
      }).then(res => {
        this.total = res.total;
        this.childrenTotal = res.childrenTotal;
        return res;
      });
    return service;
  }
  datePicker = [];
  handlerDatePickerChange(range) {
    if (range.length) {
      this.datePicker = [
        range[0].startOf('day').valueOf(),
        range[1].endOf('day').valueOf(),
      ];
    } else {
      this.datePicker = [];
    }
    this.$refs.table.loadData();
  }
  @Debounce(500)
  handleTableKeywordChange(event) {
    this.$refs.table.loadData({ keyword: event.target.value });
  }
  openRecycle() {
    DictionaryRecycle.createDrawer({
      recovery: () => this.$refs.table.loadData(),
    });
  }
  async openDetail(record) {
    await DictionaryDetailDrawer.createDrawer(record);
    this.$refs.table.loadData();
  }
  async remove(pkId) {
    await this.$confirm(this.$t('dictionary.trashBinTip'));
    await deleteDictionary({ deleted: true, pkId });
    this.$refs.table.loadData();
    this.$message.success(this.$t('common.tips.operationSuccess'));
  }
}
</script>
<style lang="less" module>
.table {
  padding: 20px;
  padding-bottom: 10px;
}
.action {
  cursor: pointer;
  color: var(--font-active);
  &.action--delete {
    color: var(--delete);
    margin-left: 20px;
  }
}
.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .toolbar__input {
    width: auto;
    margin-right: 30px;
    :global .ant-input {
      width: 110px;
      transition: width 0.5s;
      &:focus {
        width: 200px;
      }
    }
  }
  .toolbar__picker {
    width: 220px;
    margin: 0 5px;
    :global {
      .ant-calendar-picker-icon {
        display: none;
      }
      .ant-calendar-picker-input.ant-input {
        padding-left: 10px;
        text-align: center;
      }
    }
  }
}
</style>
