<template>
  <div :class="$style.box">
    <x-qrcode
      ref="qrcode"
      :class="$style.image"
      intrinsicsize="500 x 500"
      :style="qrStyle"
      :src="codeStr"
      :option="option"
    />
    <div :class="$style.btnGroup">
      <a-button type="primary" @click.stop="download">下载</a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import XQrcode from '@/components/x-qrcode';
import XDownloadAnchor from '@/components/common/x-download-anchor/index.vue';

@Component({
  components: {
    XQrcode,
  },
})
export default class ShowQrcode extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  deviceItem;
  @Prop({
    type: Object,
    default: () => {
      return {
        width: '130px',
        height: '130px',
      };
    },
  })
  qrStyle;
  option = {
    color: {
      background: '#ffffff00',
      foreground: '#000000FF',
    },
    width: 500,
    margin: 0,
  };

  get codeStr() {
    return `SDIOT,${this.deviceItem.idxProductId},${this.deviceItem.deviceCode}`;
  }

  download() {
    XDownloadAnchor.download(
      this.$refs.qrcode.imageSrc,
      `${this.deviceItem.deviceCode}.png`,
    );
  }
}
</script>
<style lang="less" module>
.box {
  text-align: center;
  font-size: 12px;

  .image {
    margin: 0 auto 20px;
    display: inherit;
  }

  .btnGroup {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
