import request from '../request';

const serviceName = '/template-manager';

/**
 * @name 获取api同步配置列表
 */
export function getPrintDetails(id) {
  return request(`${serviceName}/printTemplate/structure/${id}`, {
    method: 'GET',
  });
}
