<template>
  <div :class="$style.tableWrap">
    <FlexColContent>
      <template slot="footer">
        <PaginationTable
          ref="tableRef"
          :columns="columns"
          :api="getDeviceList"
          :extraKeys="{ showKey: false }"
          row-key="pkId"
          :scroll="{ y: 'max-content' }"
          :search-params="searchParams"
        >
        </PaginationTable>
      </template>
    </FlexColContent>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getDeviceList } from '@/services/digital-site/device';
import PaginationTable from '@/views/digital-site/components/pagination-table';
import FlexColContent from '@/views/digital-site/components/flex-col-content';

@Component({
  components: {
    PaginationTable,
    FlexColContent,
  },
})
export default class DeviceModal extends Vue {
  @Prop({ type: Object, default: () => {} }) rowData;

  get columns() {
    return [
      {
        align: 'left',
        title: '设备码',
        dataIndex: 'deviceCode',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '设备类型',
        dataIndex: 'deviceGroupShortName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '绑定企业',
        dataIndex: 'tenantName',
        ellipsis: true,
      },
    ];
  }
  mounted() {}
  get searchParams() {
    return { supplierId: this.rowData.pkId };
  }
  getDeviceList = getDeviceList;
}
</script>
<style lang="less" module>
.tableWrap {
  height: 570px;
}
</style>
