<template>
  <div :class="$style.box">
    <div :class="$style.fileName">
      <x-icon type="tc-icon-file-unknown" style="font-size: 30px" />
      <span>{{ $store.state.hat.fileName }}</span>
    </div>
    <div :class="$style.loading">
      <a-icon type="check-circle" :class="$style.checkIcon" />
      <div :class="$style.tips">导入完成</div>
      <a @click="detail">详情</a>
    </div>
    <div :class="$style.footer">
      <a-button type="primary" style="margin-left: 10px;" @click="continueIn"
        >继续导入</a-button
      >

      <a-button @click="handleClose">取消</a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import ExcelError from './excel-error.vue';
@Component({
  components: {
    ExcelError,
  },
})
export default class Manage extends Vue {
  @Prop({ type: Function, default: () => {} }) handleClose;
  continueIn() {
    this.$store.commit('hat/setExcelState', 1);
  }
  detail() {
    const { type, devices, createNumber } = this.$store.state.hat.devicesInfo;
    if (type) {
      this.$message.warn(<ExcelError record={{ devices }} />, 3);
    } else {
      this.$message.success(`导入${createNumber}条设备成功`);
    }
  }
}
</script>
<style lang="less" module>
.box {
  .fileName {
    width: 100%;
    height: 40px;
    background-color: #f5f7fa;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    padding-left: 10px;

    span {
      margin-left: 15px;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .tips {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
  }
  .checkIcon {
    font-size: 18px;
    color: #4771ff;
  }
}
</style>
