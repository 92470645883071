<script>
import {
  Component,
  Vue,
  Prop,
  Watch,
  ProvideReactive,
} from 'vue-property-decorator';
import dayjs from 'moment';
import { cloneDeep, isObject, isString } from 'lodash';
import { globalSocket } from '@triascloud/message-hub';
import { ControlTypeEnum } from '../../data-template/template-design/form/controls';
import {
  getApiBasicSetting,
  getApiSettings,
  getApiBaseInfo,
  getOssHost,
  getTriggerSettings,
  debug,
  preInterface,
  prefixApi,
  updateStatus,
  updateApiBasicSetting,
} from '@/services/iot-platform/platformInterface';
import { getVariables } from '@/services/iot-platform/variable';
import { changeOssToUrl } from '../components/utils';
import { crossStorageModule } from '@/enum/store';
import { clipboardWrite, delay, generateMap } from '@triascloud/utils';
import RenderImage from './render-image.vue';
import RenderNumber from './render-number.vue';
import RenderFile from './render-file.vue';
import RenderDataPoint from './render-datapoint.vue';
import RenderArray from './render-array.vue';
import RenderBoolean from './render-boolean.vue';
import RenderEnum from './render-enum.vue';
import RenderDate from './render-date.vue';
import RenderStruct from './render-struct.vue';
import {
  ReactiveStore,
  flatFields,
  PARAM_STATUS,
  PARAM_TYPE,
  formatBody,
  checkDefaultValue,
  parseUrlPath,
  parseUrlQuery,
  addUidByFieldList,
  STATUS,
  parseHeaderVariable,
  checkArrayType,
  headerOptions,
  prefixAPiFormat,
  GetContentType,
  fetchReleaseOption,
  promptValidate,
  fetchBody,
  updateListRequired,
  eachResList,
} from './utils';
import EmptyContent from '@/components/empty-content';
import { createFormModal } from '@triascloud/x-components';
import MockForm from './mock-form.vue';
import { IotSocket } from '@/enum/socket';
import { addLog } from '@/services/iot-platform/apiLog';

const API_STATUS = {
  /** @name 【API】发布状态 */
  PUBLISHED: 'PUBLISHED',
};

const MODE = {
  /** @name 参数模式 */
  PARAM: 'PARAM',
  /** @name 日志模式 */
  LOG: 'LOG',
};

const METHOD_TYPE = {
  /** @description 三方推送 */
  THIRD_PARTY: 'THIRD_PARTY',
  /** @description AMQP请求 */
  AMQP: 'AMQP',
  /** @description HTTP请求 */
  HTTP: 'HTTP',
};

@Component({
  components: {
    EmptyContent,
  },
})
export default class DebugApi extends Vue {
  @Prop({ type: Object }) apiItem;
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: Function }) close;
  @crossStorageModule.State('tenant') tenant;

  list = [];

  header = {
    apiName: '',
    apiDesc: '',
    logoUrl: [],
  };
  detail = {};
  async created() {
    await this.init();
  }

  beforeDestroy() {
    this.closeSocket();
    this.closeThirdParty();
  }

  @ProvideReactive('variables') variables = [];
  apiId = '';
  isAMQP = false;
  trigger = undefined;
  basicSetting = undefined;
  apiSetting = undefined;
  /** @name 入参数据准备 */
  hasReady = false;
  async init() {
    // 【API】为【发布状态】，设置【入参对象】属性为【订阅方】
    if (this.apiItem.status === API_STATUS.PUBLISHED) {
      this.status = STATUS.SUBSCRIBER;
    }
    this.apiId = this.apiItem.pkId;
    const { records } = await getVariables({
      platformId: this.platformId,
      size: 500,
    });
    this.variables = records;
    const [
      ossHost,
      basicSetting,
      trigger,
      header,
      apiSetting,
    ] = await Promise.all([
      getOssHost(),
      getApiBasicSetting(this.apiId),
      getTriggerSettings(this.apiId),
      getApiBaseInfo(this.apiId),
      getApiSettings(this.apiId),
    ]);
    // 用于反写【赋值】
    // requestBodyParam: {"postFormat":"JSON","data":[]}
    this.basicSetting = {
      ...basicSetting,
      requestUrlParam: basicSetting.requestUrlParam
        ? JSON.parse(basicSetting.requestUrlParam)
        : [],
      requestBodyParam: basicSetting.requestBodyParam
        ? JSON.parse(basicSetting.requestBodyParam)
        : {},
    };
    this.trigger = trigger;
    this.apiSetting = apiSetting;
    const method = basicSetting.requestMethod;
    this.detail = {
      ...basicSetting,
      requestHeaderParam: basicSetting.requestHeaderParam
        ? parseHeaderVariable(basicSetting.requestHeaderParam, this.variables)
        : [],
      requestUrlParam: basicSetting.requestUrlParam
        ? checkArrayType(
            JSON.parse(basicSetting.requestUrlParam),
            method === 'POST' ? 'query' : '',
            () => {
              this.hasReady = true;
            },
          )
        : [],
      requestBodyParam: basicSetting.requestBodyParam
        ? checkArrayType(
            JSON.parse(basicSetting.requestBodyParam).data,
            method === 'POST' ? 'body' : '',
            () => {
              this.hasReady = true;
            },
          )
        : [],
      returnParam: basicSetting.returnParam
        ? checkArrayType(JSON.parse(basicSetting.returnParam), '', () => {
            this.hasReady = true;
          })
        : [],
      /** @name post方法参数的提交方式 */
      formatType: basicSetting.requestBodyParam
        ? JSON.parse(basicSetting.requestBodyParam).postFormat
        : '',
    };
    if (basicSetting.subscriptionType === 'AMQP') {
      this.isAMQP = true;
    }
    this.header.apiName = header.apiName;
    this.header.apiDesc = header.introduction;
    const defaultUrl = await changeOssToUrl(ossHost, header.logoUrl);
    this.header.logoUrl = defaultUrl[0];
  }

  /** @name 请求参数列表 */
  get filterList() {
    return this.inList(true);
  }
  /** @name 入参列表【全量】副本 */
  get filterListCopy() {
    return this.inList(false);
  }
  /**
   * @description 入参列表
   * @param { Boolean } filterStatus 是否筛选【入参对象】属性【STATUS】
   */
  inList(filterStatus = false) {
    let array = [];
    let list = [];
    if (this.detail.requestUrlParam) {
      list = list.concat(this.detail.requestUrlParam);
    }
    if (this.detail.requestBodyParam) {
      list = list.concat(this.detail.requestBodyParam);
    }
    array = addUidByFieldList(list, 'uid');
    if (filterStatus && this.status !== '') {
      array = array.filter(v => v.inParams && v.inParams.includes(this.status));
    }
    return array;
  }

  inStore = undefined;
  initInStore() {
    let data = generateMap(flatFields(this.filterListCopy), 'uid', item => {
      checkDefaultValue(item);
      return item.val;
    });
    this.inStore = new ReactiveStore(data);
  }

  @Watch('hasReady')
  onChangeReady(val) {
    if (val) {
      this.initInStore();
    }
  }

  async handleRelease() {
    try {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        // 【发布】的时候，需要展示所有组件
        this.changeParamStatus({ key: '' });
        updateListRequired(this.filterList, false, Vue);
        await this.$nextTick();

        const valid = await this.$refs.form.validate();
        if (valid) {
          this.confirmRelease();
        }
      } else {
        this.$message.warn('没有入参信息！');
      }
    } catch (error) {
      this.$message.warn(
        '需填充“开发”和“平台方”必填入参字段后方可发布当前API！',
      );
    }
  }

  async updateModalData() {
    this.formatValue();
    const filter = list => (list.length === 0 ? '' : JSON.stringify(list));
    const bodyParam =
      this.basicSetting.requestBodyParam &&
      this.basicSetting.requestBodyParam.data
        ? JSON.stringify(this.basicSetting.requestBodyParam)
        : '';
    const body = {
      ...this.basicSetting,
      requestUrlParam: filter(this.basicSetting.requestUrlParam),
      requestBodyParam: bodyParam,
    };
    await updateApiBasicSetting(body);
  }

  /** @description 模板赋值 */
  formatValue() {
    try {
      const value = this.inStore.data;
      const storeKeys = Object.keys(value);

      // 赋值
      const fieldAssign = (field, array) => {
        // 1、有入参对象，且入参对象不是【订阅方】
        // 2、不是【DP】组件
        if (
          field.inParams &&
          field.inParams[0] !== STATUS.SUBSCRIBER &&
          field.type &&
          field.type !== ControlTypeEnum.DataPoint
        ) {
          if (
            field.type !== ControlTypeEnum.Struct &&
            field.type !== ControlTypeEnum.Array
          ) {
            const fKey = storeKeys.find(key => key === field.id);
            if (fKey) {
              field.val = value[fKey];
            }
          } else {
            if (field.type === ControlTypeEnum.Struct) {
              // 【结构体】组件
              field.children.forEach(v => {
                const fKey = storeKeys.find(key => key === v.id);
                if (fKey) {
                  v.val = value[fKey];
                }
              });
            } else if (field.type === ControlTypeEnum.Array) {
              // 【数组】组件
              const dataList = [];
              const fField = array.find(item => item.id === field.id);
              if (fField) {
                fField.fields &&
                  fField.fields.forEach(c => {
                    const arr = [];
                    c.forEach(i => {
                      arr.push(value[i.id]);
                    });
                    dataList.push(arr);
                  });
              }
              Vue.set(field, 'defaultValue', dataList);
            }
          }
        }
      };

      this.basicSetting.requestUrlParam.forEach(field => {
        fieldAssign(field, this.filterListCopy);
      });
      if (
        this.basicSetting.requestBodyParam &&
        this.basicSetting.requestBodyParam.data
      ) {
        this.basicSetting.requestBodyParam.data.forEach(field => {
          fieldAssign(field, this.filterListCopy);
        });
      }
    } catch {
      return false;
    }
  }

  confirmRelease() {
    this.$confirm({
      title: '确定发布当前API？',
      content: (
        <div>
          <p style="font-size: 12px">
            1、发布前请正确填写入参对象为“开发”和“平台方”的必填字段；
          </p>
          <p style="font-size: 12px">
            2、发布后当前API关联的自定义模板将不允许编辑；
          </p>
          <p style="font-size: 12px">
            3、发布后模板中的入参对象为订阅方的字段均会被清空，但“开发”和“平台方”的字段会保存；
          </p>
          <p style="font-size: 12px">
            4、订阅者将基于平台提供的“开发”和“平台方”的信息/资源完成请求，请做好对应的维护！
          </p>
        </div>
      ),
      onOk: async () => {
        try {
          this.$destroyAll();
          let option = {
            pkId: this.apiId,
            status: API_STATUS.PUBLISHED, // 【发布】
          };
          if (this.isAMQP) {
            const body = formatBody({
              list: this.filterListCopy,
              store: this.inStore,
            });
            option = {
              ...option,
              ...body,
            };
          } else {
            const Method = this.detail.requestMethod;
            let apiUrl = this.requestURL;
            const {
              optBody,
              optHead,
              optUrl,
              optFnList,
            } = await fetchReleaseOption({
              detail: this.basicSetting,
              list: this.filterListCopy,
              store: this.inStore,
              apiId: this.apiId,
              trigger: this.trigger,
              variables: this.variables,
              apiUrl,
            });
            option = {
              ...option,
              httpRequest: {
                apiBody: optBody,
                apiHead: optHead,
                apiRequestMethod: Method,
                apiUrl: optUrl,
                contentType: GetContentType(Method, this.detail.formatType),
                functionList: optFnList,
              },
            };
          }
          await this.updateModalData();
          await updateStatus(option);
          this.$message.success('发布成功！');
          this.close(true);
        } catch (error) {
          return;
        }
      },
      onCancel() {},
    });
  }

  /** @description 调试接口的类型 */
  get debugMethod() {
    if (this.detail.requestMethod === METHOD_TYPE.THIRD_PARTY) {
      return METHOD_TYPE.THIRD_PARTY;
    } else if (this.isAMQP) {
      return METHOD_TYPE.AMQP;
    } else {
      return METHOD_TYPE.HTTP;
    }
  }

  async handleDebug() {
    try {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        updateListRequired(this.filterList, true, Vue);
        await this.$nextTick();
        const valid = await this.$refs.form.validate();
        if (valid) {
          if (this.debugMethod === METHOD_TYPE.THIRD_PARTY) {
            this.handleThirdParty();
          } else if (this.debugMethod === METHOD_TYPE.AMQP) {
            this.handleWebSocket();
          } else {
            await this.handleRequest();
          }
        }
      } else {
        if (this.debugMethod === METHOD_TYPE.THIRD_PARTY) {
          this.handleThirdParty();
        } else if (this.debugMethod === METHOD_TYPE.AMQP) {
          this.handleWebSocket();
        } else {
          await this.handleRequest();
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  get requestURL() {
    let url = this.detail.requestUrl;
    if (!this.hasReady) return url;
    const method = this.detail.requestMethod;
    const options = {
      list: this.filterListCopy,
      store: this.inStore,
    };
    if (method === 'POST') {
      options.postType = 'query';
    }
    const body = formatBody(options);
    url = parseUrlPath(url, body);
    url = parseUrlQuery(url, method, body);
    return url;
  }

  formatReuestType(method) {
    let type = 'JSON';
    if (method === 'POST') {
      type = this.detail.formatType;
    }
    return type;
  }
  jsonArray = [];
  /** @name 请求响应实体 */
  resResult = {};
  async handleRequest() {
    let otherHeader = undefined,
      otherBody = undefined,
      otherQuery = undefined,
      targetUrl = this.requestURL;
    const Method = this.detail.requestMethod;

    // 是否需要前置调用--》预请求（前置接口）
    if (this.trigger.citingPublicParameters === 'PUBLIC_AUTHENTICATION') {
      const result = await preInterface(this.apiId);
      const { prefixUrl, prefixData } = prefixAPiFormat(result, this.variables);

      const body = formatBody({
        list: this.filterListCopy,
        store: this.inStore,
      });
      const data = Object.assign(body, prefixData);
      const prefixRes = await prefixApi(prefixUrl, {
        apiId: this.apiId,
        data,
      });
      otherHeader = prefixRes.header;
      otherBody = prefixRes.body;
      otherQuery = prefixRes.query;
      if (otherQuery || prefixData) {
        targetUrl = parseUrlQuery(
          targetUrl,
          Method,
          Object.assign(prefixData || {}, otherQuery || {}),
        );
      }
    }

    const formtType = this.formatReuestType(Method);
    const headers = {
      'api-head': headerOptions(this.detail.requestHeaderParam, otherHeader),
      'api-method': Method,
      'api-url': targetUrl,
    };
    const body = fetchBody(
      Method,
      formtType,
      this.filterListCopy,
      Object.assign(otherBody || {}),
      this.inStore,
    );
    const currentTime = Date.now();
    const result = await debug(headers, body);
    this.resResult = result;
    this.jsonArray.unshift({
      time: currentTime,
      format: 'YYYY/MM/DD HH:mm:ss',
      result: JSON.stringify(result),
    });

    await addLog({
      callTime: currentTime,
      connectorId: this.apiId,
      inputParams: JSON.stringify({
        'api-head': headerOptions(this.detail.requestHeaderParam, otherHeader),
        'postParams': formatBody({
          list: this.filterListCopy,
          store: this.inStore,
          postType: 'body',
        }),
        'api-url': targetUrl,
      }),
      outputParams: JSON.stringify(result),
    });

    // 解析结果
    if (this.apiItem.status === API_STATUS.PUBLISHED) {
      this.resMode = MODE.LOG;
      this.handleParse(result);
    }
  }

  initThirdParty() {
    if (this.debugMethod === METHOD_TYPE.THIRD_PARTY) {
      globalSocket.on(IotSocket.thirdPartyEvent, this.handleAmqpDebug);
      globalSocket.on(
        IotSocket.thirdPartyEventError,
        this.thirdPartySocketError,
      );
    }
  }
  destroyThirdParty() {
    if (this.debugMethod === METHOD_TYPE.THIRD_PARTY) {
      globalSocket.off(IotSocket.thirdPartyEvent, this.handleAmqpDebug);
      globalSocket.off(
        IotSocket.thirdPartyEventError,
        this.thirdPartySocketError,
      );
    }
  }
  thirdPartySocketError(error) {
    this.$message.error(error.data);
  }
  /** @name 三方推送是否发送了 */
  ThirdPartyEmitted = false;
  THirdPartyDisconnect = true;
  handleThirdParty() {
    if (!this.ThirdPartyEmitted) {
      this.ThirdPartyEmitted = true;
      const body = {
        apiId: this.apiId,
      };
      globalSocket.send(IotSocket.thirdParty, body);
      this.THirdPartyDisconnect = false;
      this.initThirdParty();
    } else {
      this.closeThirdParty();
    }
  }
  closeThirdParty() {
    this.destroyThirdParty();
    if (this.debugMethod === METHOD_TYPE.THIRD_PARTY) {
      this.ThirdPartyEmitted = false;
      this.THirdPartyDisconnect = true;
      globalSocket.send(IotSocket.thirdPartyClose, {
        apiId: this.apiId,
      });
    }
  }

  initSocket() {
    if (this.isAMQP) {
      globalSocket.on(IotSocket.AmqpEvent, this.handleAmqpDebug);
      globalSocket.on(IotSocket.AmqpEventError, this.socketError);
    }
  }
  destroySocket() {
    if (this.isAMQP) {
      globalSocket.off(IotSocket.AmqpEvent, this.handleAmqpDebug);
      globalSocket.off(IotSocket.AmqpEventError, this.socketError);
    }
  }
  /** @name socket是否发送了 */
  SocketEmitted = false;
  SocketDisconnect = true;
  async handleWebSocket() {
    if (!this.SocketEmitted) {
      this.SocketEmitted = true;
      let body = formatBody({ list: this.filterListCopy, store: this.inStore });
      body = Object.assign(body, {
        apiId: this.apiId,
      });
      globalSocket.send(IotSocket.AmqpDebug, body);
      this.SocketDisconnect = false;
      this.initSocket();
    } else {
      this.closeSocket();
    }
  }
  closeSocket() {
    this.destroySocket();
    if (this.isAMQP) {
      this.SocketEmitted = false;
      this.SocketDisconnect = true;
      globalSocket.send(IotSocket.AmqpDisconnect, {
        apiId: this.apiId,
      });
    }
  }
  handleAmqpDebug({ data }) {
    if (isObject(data)) {
      this.resResult = data;
    } else if (isString(data)) {
      this.resResult = JSON.parse(data);
    }
    /** @name 限制只展示100条 */
    if (this.jsonArray.length > 100) {
      this.jsonArray.pop();
    }
    this.jsonArray.unshift({
      time: Date.now(),
      format: 'YYYY/MM/DD HH:mm:ss.SSS',
      result: isObject(data) ? JSON.stringify(data) : data,
    });
  }

  socketError(error) {
    this.$message.error(error.data);
  }

  resMode = MODE.LOG;
  handChangeResMode() {
    if (this.resMode === MODE.PARAM) {
      this.resMode = MODE.LOG;
    } else {
      this.resMode = MODE.PARAM;
    }
  }

  handleClear() {
    this.jsonArray = [];
    this.resResult = {};
  }

  async mockClick() {
    try {
      const result = await createFormModal(() => <MockForm />, {
        width: 500,
        title: '模拟调试数据',
      });
      if (result) {
        const dataStr = JSON.stringify(JSON.parse(result));
        this.jsonArray.unshift({
          time: Date.now(),
          format: 'YYYY/MM/DD HH:mm:ss.SSS',
          result: dataStr,
        });
      }
    } catch (error) {
      if (error.message !== 'user cancel') {
        this.$message.error('模拟数据转换失败！');
      }
    }
  }

  apiLeftColor(item) {
    if (item.themeColor !== '') {
      return {
        backgroundColor: item.themeColor,
        backgroundColor30: item.themeColor + '30',
        color: item.themeColor,
        borderColor: item.themeColor,
      };
    } else {
      return {
        backgroundColor: 'var(--primary)',
        backgroundColor30: 'var(--primary-30)',
        color: 'var(--primary)',
        borderColor: 'var(--primary)',
      };
    }
  }
  get requestText() {
    if (this.SocketEmitted) {
      return '停止';
    } else {
      return this.trigger.copyWriting ? this.trigger.copyWriting : '调试';
    }
  }
  renderHeader() {
    return (
      <section
        class={this.$style.header}
        style={{
          backgroundColor: this.apiLeftColor(this.detail).backgroundColor30,
          borderColor: this.apiLeftColor(this.detail).backgroundColor,
        }}
      >
        <div class={this.$style.imgBox}>
          <img class={this.$style.img} src={this.header.logoUrl} alt="" />
        </div>
        <div class={this.$style.labelBox}>
          <h3 class={this.$style.title}>{this.header.apiName}</h3>
          <p class={this.$style.desc}>{this.header.apiDesc}</p>
        </div>
        {this.requestURL ? (
          <div class={this.$style.buttonBox}>
            <a-button
              type={'primary'}
              onClick={this.handleDebug}
              style={{
                backgroundColor: this.apiLeftColor(this.detail).backgroundColor,
                borderColor: this.apiLeftColor(this.detail).borderColor,
              }}
            >
              {this.requestText}
            </a-button>
            {this.apiItem.status !== API_STATUS.PUBLISHED ? (
              <a-button
                type="primary"
                onClick={this.handleRelease}
                style={{
                  backgroundColor: this.apiLeftColor(this.detail)
                    .backgroundColor,
                  borderColor: this.apiLeftColor(this.detail).borderColor,
                }}
              >
                发布
              </a-button>
            ) : null}
          </div>
        ) : (
          ''
        )}
      </section>
    );
  }

  hideIcon = 'down';
  hideRequest = false;
  handleRequestHide() {
    if (this.hideIcon === 'down') {
      this.hideIcon = 'up';
      this.hideRequest = true;
    } else {
      this.hideIcon = 'down';
      this.hideRequest = false;
    }
  }
  get hasHeaderParams() {
    return (
      this.detail.requestHeaderParam &&
      this.detail.requestHeaderParam.length !== 0
    );
  }
  renderRequestHeader() {
    return (
      <div>
        <h1 class={this.$style.requestMessage}>
          <span>请求信息</span>
          <span onClick={this.handleRequestHide}>
            <a-icon type={this.hideIcon} />
          </span>
        </h1>
        <div
          class={[
            this.$style.requestBox,
            this.hideRequest ? this.$style.hideActive : '',
          ]}
          style={{
            visibility: this.hideRequest ? 'hidden' : 'visible',
            opacity: this.hideRequest ? 0 : 1,
          }}
        >
          <h3 class={this.$style.requestTitle}>请求类型</h3>
          <p class={this.$style.reqeustMethod}>{this.detail.requestMethod}</p>
          <h3>请求地址</h3>
          <p>{this.requestURL}</p>
          {this.hasHeaderParams ? (
            <h3 class={this.$style.headerBox}>Header</h3>
          ) : (
            ''
          )}
          {this.hasHeaderParams
            ? this.detail.requestHeaderParam.map((v, i) => {
                return (
                  <p key={v.identifier + i} style={{ marginBottom: '4px' }}>
                    {v.identifier}: {v.val}
                  </p>
                );
              })
            : ''}
          {this.detail.requestMethod === 'POST' ? this.renderPostBody() : ''}
        </div>
      </div>
    );
  }
  renderRequest() {
    const header = this.isAMQP ? '' : this.renderRequestHeader();
    return <div class={this.$style.requestWrap}>{header}</div>;
  }
  get PostBody() {
    let array = [];
    const obj = formatBody({
      list: this.filterListCopy,
      store: this.inStore,
      postType: 'body',
    });
    Object.keys(obj).forEach(v => {
      array.push({
        identifier: v,
        val: JSON.stringify(obj[v]),
      });
    });
    return array;
  }
  renderPostBody() {
    return (
      <div>
        <h3 class={this.$style.headerBox}>Body</h3>
        {this.PostBody
          ? this.PostBody.map((v, i) => {
              return (
                <p key={i} style={{ marginBottom: '4px' }}>
                  {v.identifier}: {v.val}
                </p>
              );
            })
          : ''}
      </div>
    );
  }

  /** @name FormItem名称提示语 */
  renderTooltip(item) {
    if (item.description) {
      return (
        <a-tooltip
          overlayClassName={
            item.description.length > 100 ? this.$style.tooltip : ''
          }
          arrowPointAtCenter={true}
        >
          <div
            {...{
              domProps: {
                innerHTML: item.description,
              },
            }}
            class={this.$style.boxHtml}
            slot={'title'}
          ></div>
          <a-icon type={'info-circle'} class={this.$style.tooltipIcon} />
        </a-tooltip>
      );
    } else {
      return '';
    }
  }
  /** @name 入参【订阅方、平台方、开发】 */
  renderParamStatus(item) {
    if (item[PARAM_TYPE] === PARAM_STATUS.OUT) return '';
    const array = item.inParams || [];
    if (array.includes(STATUS.SUBSCRIBER)) {
      return (
        <a-tag style={{ margin: '0 10px' }} color="#f50">
          订阅方
        </a-tag>
      );
    }
    if (array.includes(STATUS.PLATFORM)) {
      return (
        <a-tag style={{ margin: '0 10px' }} color="#f50">
          平台方
        </a-tag>
      );
    }
    if (array.includes(STATUS.DEVELOPMENT)) {
      return (
        <a-tag style={{ margin: '0 10px' }} color="#f50">
          开发
        </a-tag>
      );
    }
  }
  schemeByType(v, store) {
    const mapComponent = {
      [ControlTypeEnum.Input]: RenderNumber,
      [ControlTypeEnum.Double]: RenderNumber,
      [ControlTypeEnum.Float]: RenderNumber,
      [ControlTypeEnum.Int]: RenderNumber,
      [ControlTypeEnum.Switch]: RenderBoolean,
      [ControlTypeEnum.Enum]: RenderEnum,
      [ControlTypeEnum.Date]: RenderDate,
      [ControlTypeEnum.File]: RenderFile,
      [ControlTypeEnum.Image]: RenderImage,
      [ControlTypeEnum.Array]: RenderArray,
      [ControlTypeEnum.Struct]: RenderStruct,
      [ControlTypeEnum.DataPoint]: RenderDataPoint,
    };
    const DefaultComponent = mapComponent[v.type];
    const useKey = v[PARAM_TYPE] === PARAM_STATUS.IN ? 'uid' : 'id';
    return (
      <DefaultComponent
        item={v}
        store={store}
        tooltip={this.renderTooltip}
        param={this.renderParamStatus}
        checkDefaultValue={checkDefaultValue}
        schemeByType={this.schemeByType}
        useKey={useKey}
      />
    );
  }
  renderScheme(list = [], type, store) {
    return list.map((v, idx) => {
      Vue.set(v, PARAM_TYPE, type);
      return <div key={'scheme' + idx}>{this.schemeByType(v, store)}</div>;
    });
  }

  status = '';
  changeParamStatus(e) {
    this.status = e.key;
  }
  renderDropDownStatus() {
    return (
      <a-dropdown>
        <a class="ant-dropdown-link" onClick={e => e.preventDefault()}>
          入参对象 <a-icon type="down" />
        </a>
        <a-menu
          slot="overlay"
          selectedKeys={[this.status]}
          onClick={event => this.changeParamStatus(event)}
        >
          <a-menu-item key={''}>
            <a href="javascript:;">全部</a>
          </a-menu-item>
          <a-menu-item key={STATUS.DEVELOPMENT}>
            <a href="javascript:;">开发</a>
          </a-menu-item>
          <a-menu-item key={STATUS.PLATFORM}>
            <a href="javascript:;">平台方</a>
          </a-menu-item>
          <a-menu-item key={STATUS.SUBSCRIBER}>
            <a href="javascript:;">订阅方</a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    );
  }
  renderParamSelect() {
    return (
      <a-form-model
        class={[this.$style.form, this.$style.boxItem]}
        ref={'form'}
        props={{ model: this.inStore.data }}
      >
        {this.apiItem.status !== API_STATUS.PUBLISHED ? (
          this.renderDropDownStatus()
        ) : (
          <h1 class={this.$style.fontH}>请求参数</h1>
        )}
        {this.inStore && this.hasReady ? (
          this.renderScheme(this.filterList, PARAM_STATUS.IN, this.inStore)
        ) : (
          <empty-content
            type="list"
            style={{ height: 'initial', marginTop: '20px' }}
          />
        )}
      </a-form-model>
    );
  }
  /** @name 出参规则提示语 */
  async promptMessage() {
    try {
      const msgList = promptValidate(
        this.apiSetting.outgoingRules,
        this.outStore,
        this.filterResList,
      );
      for (let msg of msgList) {
        this.$message.warn({
          content: msg,
          duration: 1.5,
        });
        await delay(1500);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  async handleCopy(item) {
    try {
      await clipboardWrite(item);
      this.$message.success('复制成功');
    } catch (error) {
      this.$message.success('复制失败');
    }
  }
  /** @name 响应参数列表 */
  filterResList = [];
  outStore = {};
  /** @name 解析 */
  async handleParse(result) {
    try {
      result = isObject(result) ? result : JSON.parse(result);
      this.filterResList = cloneDeep(
        addUidByFieldList(this.detail.returnParam, 'id'),
      );
      await eachResList(this.filterResList, -1, '', result);
      let data = generateMap(flatFields(this.filterResList), 'id', item => {
        checkDefaultValue(item);
        return item.val;
      });
      this.outStore = new ReactiveStore(data);
      this.handChangeResMode();
      await this.promptMessage();
    } catch (error) {
      this.$message.error('解析失败！');
    }
  }
  renderLogMode() {
    return this.jsonArray.map((v, i) => {
      return (
        <section class={this.$style.jsonBox} key={'jsonLog' + i}>
          <div>
            <span>{dayjs(v.time).format(v.format)}</span>
          </div>
          <div class={this.$style.jsonString}>{v.result}</div>
          <div class={this.$style.jsonBorder}></div>
          <div class={this.$style.copyBox}>
            <span
              class={this.$style.copy}
              onClick={() => this.handleCopy(v.result)}
            >
              复制
            </span>
            <span
              class={this.$style.copy}
              onClick={() => this.handleParse(v.result)}
            >
              解析
            </span>
          </div>
        </section>
      );
    });
  }
  renderLog() {
    return (
      <div class={this.$style.boxWrap}>
        <div class={this.$style.logBox}>
          <h1 onClick={this.mockClick}>调用日志</h1>
          <div>
            {this.resMode === MODE.LOG ? (
              <x-icon
                type="tc-icon-brush"
                class={this.$style.logCursor}
                onClick={this.handleClear}
              />
            ) : (
              ''
            )}
            <span class={this.$style.paramBox}>
              {this.resMode === MODE.LOG ? '参数模式' : '日志模式'}
            </span>
            <a-icon
              type="swap"
              class={this.$style.logCursor}
              onClick={this.handChangeResMode}
            />
          </div>
        </div>
        {this.jsonArray.length ? (
          ''
        ) : (
          <empty-content
            type="list"
            style={{ height: 'initial', marginTop: '20px' }}
          />
        )}
        {this.resMode === MODE.LOG
          ? this.renderLogMode()
          : this.renderScheme(
              this.filterResList,
              PARAM_STATUS.OUT,
              this.outStore,
            )}
      </div>
    );
  }
  render() {
    return (
      <div ref={'boxRef'}>
        {this.renderHeader()}
        <section class={this.$style.box}>
          {this.hasReady && this.inStore ? this.renderParamSelect() : ''}
          <section class={this.$style.boxItem}>
            {this.requestURL ? this.renderRequest() : ''}
            {this.requestURL ? this.renderLog() : ''}
          </section>
        </section>
      </div>
    );
  }
}
</script>
<style lang="less" module>
.tooltipIcon {
  margin: 0 5px;
  z-index: 101;
  position: relative;
}
.tooltip {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  max-width: 250px;
}
.boxHtml {
  img {
    display: none;
  }
}
.header {
  box-sizing: content-box;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  border-left: 5px solid;
  display: flex;

  .imgBox {
    padding: 20px;

    .img {
      width: 80px;
      border-radius: 80px;
    }
  }

  .labelBox {
    flex: 1;
    padding: 20px;
  }
  .buttonBox {
    display: flex;
    align-items: center;
    padding-right: 20px;
    button + button {
      margin-left: 20px;
    }
  }
}
.box {
  display: flex;

  :global {
    .ant-form-item-control-wrapper {
      padding-bottom: 15px;
    }
  }

  .form {
    width: 400px;
    margin-right: 60px;
  }
  .boxItem {
    flex: 1;
    .fontH {
      font-weight: 500;
      font-size: 16px;
    }
  }
}
.boxWrap {
  margin-top: 20px;
  .logBox {
    display: flex;
    justify-content: space-between;
    .logCursor {
      cursor: pointer;
    }
    .paramBox {
      padding: 0 4px;
    }
  }
  .jsonBox {
    margin-bottom: 20px;
    .jsonString {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
    }
    .jsonBorder {
      margin-top: 10px;
      border-top: 1px solid var(--border);
    }
    .copyBox {
      margin-top: -33px;
      text-align: right;
      .copy {
        color: var(--font-active);
        cursor: pointer;
        font-weight: 900;
        display: inline-block;
        padding: 2px 4px;
        background-color: var(--block-bg);
        border-radius: 10px;
      }
    }
    &:last-of-type {
      .jsonBorder {
        border-top: none;
      }
    }
  }
}

.requestWrap {
  position: relative;
  .requestMessage {
    display: flex;
    justify-content: space-between;
  }
  .requestBox {
    border: 1px solid var(--border);
    border-radius: 4px;
    padding: 14px 10px;
    opacity: 1;
    visibility: visible;
    transition: all 0.5s;
    .requestTitle {
      margin: 0;
    }
    .reqeustMethod {
      padding: 10px 0;
    }
    .headerBox {
      margin: 0;
      padding: 10px 0;
    }
  }
  .hideActive {
    position: absolute;
    z-index: 0;
  }
}
</style>
