import request from '../request';
const basePath = '/configuration/editHistory';

export function getRecordsList({ beginTime, endingTime, keyword }) {
  return request(`${basePath}/list`, {
    method: 'POST',
    body: {
      beginTime,
      endingTime,
      keyword,
    },
  });
}

export function editRecord(data) {
  return request(`${basePath}/edit`, {
    method: 'POST',
    body: data,
  });
}

export function pushRecord(data) {
  return request(`${basePath}/push`, {
    method: 'POST',
    body: data,
  });
}

export function getOptimizeCount() {
  return request(`${basePath}/getHistoryCount`, {
    method: 'GET',
  });
}

export function setOptimizeCount(data) {
  return request(`${basePath}/setHistoryCount`, {
    method: 'POST',
    body: data,
  });
}

export function getHistory() {
  return request(`${basePath}/history`, {
    method: 'GET',
  });
}

export function delRecordItem(pkId) {
  return request(`${basePath}/remove?pkId=${pkId}`, {
    method: 'DELETE',
  });
}

export function saveRecord(data) {
  return request(`${basePath}/save`, {
    method: 'POST',
    body: data,
  });
}
