<template>
  <div :class="$style.wrap">
    <header>
      <a-steps :current="showContent">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps>
    </header>
    <section>
      <SetFirmware v-if="showContent == 0" ref="SetFirmware" />
      <SetStrategy v-if="showContent == 1" ref="SetStrategy" />
      <SetDone v-if="showContent == 2" />
    </section>
    <footer>
      <div v-if="showContent == 0">
        <a-button style="margin-right: 60px;" @click="handleClose"
          >取消</a-button
        >
        <a-button type="primary" @click="toSetStrategyPage">下一步</a-button>
      </div>
      <div v-if="showContent == 1">
        <a-button
          type="primary"
          style="margin-right: 60px;"
          @click="showContent = 0"
          >上一步</a-button
        >
        <a-button type="primary" @click="upgradeDevice">下一步</a-button>
      </div>
      <div v-if="showContent == 2">
        <a-button @click="handleClose">关闭</a-button>
      </div>
    </footer>
  </div>
</template>
<script>
import { Component, Vue, Prop, Provide } from 'vue-property-decorator';
import SetDone from './set-done.vue';
import SetFirmware from './set-firmware.vue';
import SetStrategy from './set-strategy.vue';
import { Throttle } from 'lodash-decorators';
import { reqAddUpgrade } from '@/services/hat/ota';
@Component({
  components: {
    SetDone,
    SetFirmware,
    SetStrategy,
  },
})
export default class Upgrade extends Vue {
  @Provide('deviceList')
  @Prop({ type: Object, default: () => {} })
  record;
  showContent = 0;
  steps = [
    {
      title: '选择固件',
    },
    {
      title: '设置升级策略',
    },
    {
      title: '完成',
    },
  ];
  created() {
    this.toSetStrategyPage = this.toSetStrategyPage.bind(this);
    this.saveDeviceIds();
  }
  handleClose() {
    this.$listeners.close();
  }
  saveDeviceIds() {
    this.$store.commit('hat/setDeviceIds', this.record.deviceIds);
  }
  @Throttle(1500)
  toSetStrategyPage() {
    const { selectedRow } = this.$refs.SetFirmware;
    if (!selectedRow.firmwareId) {
      this.$message.warn('请选择一个升级包类型!');
      return;
    }
    this.showContent = 1;
    this.$store.commit('hat/setOTAversion', selectedRow);
  }
  async upgradeDevice() {
    try {
      await this.$refs.SetStrategy.validate();
      await this.upgrading();
      this.showContent = 2;
    } catch (error) {
      error;
    }
  }
  async upgrading() {
    const { deviceIds } = this.record;
    const { firmwareId } = this.$store.state.hat.OTAversion;
    let {
      upgradeType,
      srcVersions,
      mark,
      isForceUpgrade,
      isClientConfirm,
      upgradeTime,
      timeRange,
    } = this.$refs.SetStrategy.form;
    if (!(srcVersions instanceof Array)) {
      srcVersions = [srcVersions];
    }
    const requestObj = {
      deviceIds,
      upgradeType,
      srcVersions,
      mark,
      isForceUpgrade,
      isClientConfirm,
      firmwareId,
    };
    if (upgradeTime == 2) {
      requestObj.startTime = this.$moment(timeRange[0]).valueOf();
      requestObj.endTime = this.$moment(timeRange[1]).valueOf();
    }
    return new Promise((resolve, reject) => {
      reqAddUpgrade(requestObj)
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
</script>
<style lang="less" module>
.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  header {
    height: 50px;
    width: 100%;
  }
  section {
    height: 400px;
    width: 100%;
  }
  footer {
  }
}
</style>
