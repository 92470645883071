<template>
  <x-date-picker
    :value="getVal"
    @input="dateChanged"
    :format="field.widget.format"
  >
  </x-date-picker>
</template>

<script>
import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import { DataFormContext } from '../../data-form/context';
import { DateDefault } from '../enum';
@Component()
export default class DateDesign extends Vue {
  @Prop({ required: true }) field;
  @Inject({ from: DataFormContext.Root, default: null }) root;
  @Inject({ from: DataFormContext.Model, default: null }) model;
  /** @description 数据 */
  @Prop({
    type: Object,
    required: true,
  })
  field;
  @Prop() value;
  @Prop() view;
  get getMode() {
    return this.field.widget.isSingle ? 'default' : 'multiple';
  }
  dateChanged(val) {
    this.$emit('input', val);
  }
  get getVal() {
    let dataValue = '';
    if (this.field.widget.defaultType === DateDefault.MANUAL) {
      dataValue =
        this.model.getValue(this.field.pkId) ||
        this.field.widget.defaultValue.custom;
    }
    return dataValue;
  }
  emitChange(val) {
    this.$emit('input', val);
  }
}
</script>
