import request from '../request';

const serviceName = '/transaction/backendTrade';

export function getOrderList(data) {
  return request(`${serviceName}/listTrade`, {
    method: 'POST',
    body: data,
  });
}

export function getOrderDetail(pkId) {
  return request(`${serviceName}/getTradeDetail?pkId=${pkId}`, {
    method: 'GET',
  });
}
