import request from '../request';
const basePath = '/resource/productFeedback';

export function getFeedbackList(report) {
  return request(`${basePath}/report`, { method: 'POST', body: report });
}

export function getFeedbackDetail(pkId) {
  return request(`${basePath}/detail`, { body: { pkId } });
}

/**
 * @name 删除
 * @param pkId array<string>
 */
export function deleteFeedback(pkId) {
  return request(`${basePath}/remove`, { body: pkId, method: 'DELETE' });
}

/**
 * @name 批量删除
 * @param pkId array<string>
 */
export function batchDeleteFeedback(pkId) {
  return request(`${basePath}/removeAll`, { body: pkId, method: 'DELETE' });
}
