import request from '../request';
const basePath = '/smart-site/ssc/navigationTemplate';

// BI模板配置
export const getTemplateList = templateType =>
  request(`${basePath}/getAllTemplateInfo?templateType=${templateType}`, {
    method: 'GET',
  });
export const addTemplate = params =>
  request(`${basePath}/add`, {
    method: 'POST',
    body: params,
  });

export const editTemplate = params =>
  request(`${basePath}/update`, {
    method: 'POST',
    body: params,
  });

export const deleteTemplate = id =>
  request(`${basePath}/delete/${id}`, {
    method: 'DELETE',
  });

// 物联网企业权限配置
export const getEnterpriseConfigList = params =>
  request(`${basePath}/pageNavigationConfigData`, {
    method: 'POST',
    body: params,
  });

export const getEnterpriseConfigDetail = id =>
  request(`${basePath}/getTenantNavigationConfigById/${id}`, {
    method: 'GET',
  });

export const addTenantConfig = data =>
  request(`${basePath}/addTenantConfig`, {
    method: 'POST',
    body: data,
  });

export const editTenantConfig = data =>
  request(`${basePath}/updateTenantConfig`, {
    method: 'POST',
    body: data,
  });

export const deleteTenantConfig = id =>
  request(`${basePath}/deleteTenantConfig/${id}`, {
    method: 'DELETE',
  });

export const getEnterpriseList = data =>
  request('/tenant-center/tenant/enterprise/page', {
    method: 'GET',
    body: data,
  });
