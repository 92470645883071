<template>
  <div :class="$style.device">
    <top-data :data-list="topData" />
    <div :class="$style.tableWrap">
      <FlexColContent>
        <div :class="$style.operation">
          <div :class="$style.searchBox">
            <a-input
              :class="$style.searchInput"
              v-model="searchParams.deviceCode"
              @input.stop="() => $refs.tableRef.search()"
              :placeholder="this.$t('ds.device.deviceCode')"
            >
              <a-icon slot="prefix" type="search" />
            </a-input>
            <a-select
              show-search
              allowClear
              v-model="selectParams.deviceType"
              :placeholder="$t('ds.device.deviceType')"
              option-filter-prop="children"
              :filter-option="true"
              @change="handleSelect"
              :class="$style.searchSelect"
            >
              <a-select-option
                :value="item.id"
                v-for="item in typeList"
                :key="item.id"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
            <a-select
              show-search
              allowClear
              v-model="searchParams.supplierId"
              :placeholder="$t('ds.device.selectManufacturer')"
              option-filter-prop="children"
              :filter-option="true"
              @change="$refs.tableRef.search()"
              :class="$style.searchSelect"
            >
              <a-select-option
                :value="item.id"
                v-for="item in manufacturers"
                :key="item.id"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </div>
          <a-button
            type="primary"
            @click="handleAdd()"
            v-if="$p.action('CREATE')"
            >+{{ $t('hat.addDevice.addDevice') }}</a-button
          >
        </div>
        <template slot="footer">
          <PaginationTable
            ref="tableRef"
            :columns="columns"
            :api="getDeviceList"
            :extraKeys="{ showKey: false }"
            row-key="pkId"
            :scroll="{ x: 1600, y: 'max-content' }"
            :slotKeys="['action', 'encryptionKey']"
            :search-params="searchParams"
          >
            <template slot="action" slot-scope="{ record }">
              <div :class="$style.buttonGroups">
                <span
                  :class="$style.button"
                  @click="handleEdit(record)"
                  v-if="$p.action('UPDATE')"
                  >{{ $t('common.action.edit2') }}</span
                >
                <span
                  :class="$style.button"
                  @click="handleDelete(record)"
                  v-if="$p.action('DELETE')"
                  >{{ $t('common.action.delete') }}</span
                >
                <span :class="$style.button" @click="handleCopy(record)">{{
                  $t('inviteCode.copy')
                }}</span>
              </div>
            </template>
            <template slot="encryptionKey" slot-scope="{ text, record }">
              <div :class="$style.verificationCode">
                <span>{{ record.showKey ? text : '**********' }}</span>
                <i @click="record.showKey = !record.showKey"
                  ><a-icon :type="record.showKey ? 'eye-invisible' : 'eye'"
                /></i>
              </div>
            </template>
          </PaginationTable>
        </template>
      </FlexColContent>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import TopData from './components/top-data.vue';
import {
  getDeviceList,
  getDeviceGroup,
  getDeviceStatistics,
  deleteDevice,
} from '@/services/digital-site/device';
import { createFormModal } from '@triascloud/x-components';
import DeviceForm from './form-modal/device-form.vue';
import PaginationTable from '@/views/digital-site/components/pagination-table';
import FlexColContent from '@/views/digital-site/components/flex-col-content';
import Tip from '@/components/tip';
import { clipboardWrite } from '@triascloud/utils';
import { getManufacturerList } from '@/services/digital-site/manufacturer';

const statusList = {
  '0': '离线',
  '1': '在线',
  '2': '未激活',
};
@Component({
  components: {
    TopData,
    PaginationTable,
    FlexColContent,
  },
})
export default class Device extends Vue {
  get topData() {
    const {
      deviceNo,
      inactiveNo,
      offlineNo,
      onlineNo,
      videoSurveillanceNo,
      supplierNo,
    } = this.statisticsData;
    return [
      {
        color: '#297FFF',
        name: this.$t('ds.device.devices'),
        num: deviceNo || 0,
        icon: '1381795643223031810.35220242',
      },
      {
        color: '#FAB21A',
        name: this.$t('ds.device.deviceStatus'),
        num: `${inactiveNo || 0}/${offlineNo || 0}/${onlineNo || 0}`,
        status: '（未激活/离线/在线）',
        icon: '1381795643223031810.35220241',
      },
      {
        color: '#F85448',
        name: this.$t('ds.device.cameraNumber'),
        num: videoSurveillanceNo || 0,
        icon: '1381795643223031810.35229088',
      },
      {
        color: '#7ED305',
        name: this.$t('ds.device.deviceManufacturer'),
        num: supplierNo || 0,
        icon: '1381795643223031810.35220243',
      },
    ];
  }
  get columns() {
    return [
      {
        align: 'left',
        title: this.$t('ds.device.deviceCode'),
        dataIndex: 'deviceCode',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('ds.device.deviceType'),
        dataIndex: 'deviceGroupShortName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('ds.device.manufacturer'),
        dataIndex: 'supplierName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('iot.device.boundingEnterprises'),
        dataIndex: 'tenantName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('hat.table.deviceStatus'),
        dataIndex: 'online',
        ellipsis: true,
        customRender: text => statusList[text],
      },
      {
        align: 'left',
        title: this.$t('ds.device.key'),
        dataIndex: 'encryptionKey',
        ellipsis: true,
        scopedSlots: { customRender: 'encryptionKey' },
      },
      {
        align: 'left',
        title: this.$t('ds.device.deviceModel'),
        dataIndex: 'model',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('common.label.remark'),
        dataIndex: 'remark',
        ellipsis: true,
      },
      {
        title: this.$t('common.action.operating'),
        key: 'operation',
        width: 200,
        ellipsis: true,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  mounted() {
    this.getStatistics();
    this.loadSelectList();
  }
  searchParams = {
    deviceCode: '',
    deviceGroup: undefined,
    supplierId: undefined,
  };
  getDeviceList = getDeviceList;
  typeList = [];
  manufacturers = [];
  async loadSelectList() {
    const data = await Promise.all([
      getDeviceGroup(),
      getManufacturerList({ current: 1, size: 100 }),
    ]);
    this.typeList = data[0].map(item => ({
      id: item.groupId,
      value: item.groupShortName,
    }));
    this.manufacturers = data[1].records.map(item => ({
      id: item.pkId,
      value: item.supplierName,
    }));
  }
  statisticsData = {
    deviceNo: 0,
    inactiveNo: 0,
    offlineNo: 0,
    onlineNo: 0,
    videoSurveillanceNo: 0,
    supplierNo: 0,
  };
  async getStatistics() {
    const data = await getDeviceStatistics();
    this.statisticsData = data;
  }
  selectParams = {
    deviceType: undefined,
  };
  handleSelect(v) {
    const array = v?.split('.') || [];
    if (array.includes('VS')) {
      this.searchParams.deviceGroup = array[0];
      this.searchParams.deviceType = array[1];
    } else {
      this.searchParams.deviceGroup = v;
      this.searchParams.deviceType = undefined;
    }
    this.$refs.tableRef.search();
  }
  handleAdd() {
    this.showFormModel({}, 'add');
  }
  handleEdit(row) {
    this.showFormModel(row, 'edit');
  }
  async showFormModel(row, operationType) {
    try {
      const result = await createFormModal(
        () => <DeviceForm editData={row} operationType={operationType} />,
        {
          width: 1000,
          maskClosable: false,
          title: operationType === 'add' ? '添加设备' : '编辑设备',
        },
      );
      if (result) {
        await this.$refs.tableRef.getTableList();
      }
    } catch {
      return false;
    }
  }
  async handleCopy(record) {
    const str = `设备码：${record.deviceCode}\n设备密钥：${record.encryptionKey}`;
    await clipboardWrite(str);
    this.$message.success('复制成功');
  }
  async handleDelete(record) {
    const text = '确认删除设备？';
    const tips = '删除后将清空设备所有数据，请谨慎删除';
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: '提示',
          onOk: async () => {
            await deleteDevice(record.pkId);
            this.$message.success('删除成功!');
            this.$refs.tableRef.getTableList();
          },
        },
      );
    } catch {
      return false;
    }
  }
}
</script>
<style lang="less" module>
.device {
  height: 100%;
  padding: 20px;
  .tableWrap {
    height: calc(100% - 116px);
    .operation {
      display: flex;
      justify-content: space-between;
      margin: 20px 0 12px;
      .searchBox {
        .searchInput {
          margin-right: 10px;
          width: 200px;
        }
        .searchSelect {
          margin-right: 10px;
          width: 180px;
        }
      }
    }
    .buttonGroups {
      .button {
        cursor: pointer;
        color: var(--primary);
        margin-right: 20px;
      }
      .button:last-of-type {
        margin-right: 0;
      }
    }
    .verificationCode {
      & > span {
        margin-right: 12px;
      }

      & > i {
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}
</style>
