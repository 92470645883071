import request from '../request';
const basePath = '/resource/cloud-dictionary-label';

export function getAllDictionaryLabel() {
  return request(`${basePath}/list`);
}

export function addDictionaryLabel({ labelColor, labelName }) {
  return request(`${basePath}/add`, {
    method: 'POST',
    body: {
      labelName,
      labelColor,
    },
  });
}
