<template>
  <a-spin :spinning="loading" :class="$style.index">
    <manage-switch-date v-model="selectTime" :timeOptions="timeOptions" />
    <div :class="$style.OrderTlt">{{ title }}</div>
    <slot :selectTime="selectTime"></slot>
  </a-spin>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import ManageSwitchDate from './manage-switch-date.vue';

@Component({
  components: {
    ManageSwitchDate,
  },
})
export default class ManageEchartsWrap extends Vue {
  @Prop({ type: String }) title;
  @Prop({ type: Boolean }) loading;
  @Prop({ type: Array }) timeOptionsProp;

  selectTime = 'WEEK';
  get timeOptions() {
    if (this.timeOptionsProp) {
      return this.timeOptionsProp;
    }
    return [
      {
        text: this.$t('common.week'),
        value: 'WEEK',
      },
      {
        text: this.$t('common.month'),
        value: 'MONTH',
      },
      {
        text: this.$t('common.year'),
        value: 'YEAR',
      },
      {
        text: this.$t('common.label.all'),
        value: 'ALL',
      },
    ];
  }
}
</script>
<style lang="less" module>
.index {
  padding: 10px;
  box-shadow: 0px 0px 8px 0px var(--shadow);
  position: relative;
  height: 400px;
  .OrderTlt {
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    left: 10px;
    top: 5px;
    z-index: 5;
  }
}
</style>
