<template>
  <a-popover
    v-model="visible"
    trigger="click"
    placement="bottomRight"
    :overlayClassName="$style.editPopover"
  >
    <template slot="content">
      <a-input v-model="content" :maxLength="10"></a-input>
      <a-button type="primary" ghost @click="handleChangeValue">{{
        $t('common.action.confirm')
      }}</a-button>
    </template>
    <span :class="$style.info">
      <span
        :class="[$style.name, visible ? $style.visible : '']"
        :title="value"
        @click.stop="visible = false"
        >{{ value }} </span
      ><x-icon type="edit" @click.stop="visible = true" />
    </span>
  </a-popover>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component()
export default class EditPopover extends Vue {
  @Prop({ type: String, default: '' }) value;
  visible = false;
  content = '';

  created() {
    this.content = this.value;
  }

  handleChangeValue() {
    this.visible = false;
    this.$emit('change', this.content);
  }
}
</script>
<style lang="less" module>
.editPopover {
  padding-top: 0px;
  margin-top: -10px;
  :global(.ant-popover-inner-content) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;

    input {
      height: 30px;
      width: 200px;
      margin-right: 10px;
    }

    button {
      height: 30px;
      min-width: 50px;
      font-size: 12px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  :global(.ant-popover-arrow) {
    display: none;
  }
}
.info {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .name {
    font-size: 12px;
    color: var(--font);
    flex: 1;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.visible {
      color: var(--primary);
    }
  }

  i {
    display: none;
    cursor: pointer;
  }

  &:hover {
    i {
      display: inline;
    }
  }
}
</style>
