<template>
  <div>
    <x-data-table
      ref="table"
      :columns="columns"
      :service="service"
      :class="$style.table"
    >
      <template v-slot:header></template>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataSourceService } from '@triascloud/services';

@Component()
export default class NodeDetails extends Vue {
  @Prop() data;
  get service() {
    const service = new DataSourceService('/plugins/overview');
    service.getList = () => {
      return new Promise(resolve =>
        resolve({
          data: this.data,
          current: 1,
          hitCount: false,
          pages: 1,
          searchCount: true,
          size: 10,
          total: this.data.length,
        }),
      );
    };

    return service;
  }
  get columns() {
    return [
      {
        title: '数据库标题',
        key: 'title',
        dataIndex: 'title',
      },
      {
        key: 'formName',
        title: '关联应用表单',
        dataIndex: 'formName',
        width: 150,
      },
    ].map(item => ({
      ...item,
      width: item.width || 100,
      sorter: false,
      filter: false,
    }));
  }
}
</script>
<style lang="less" module>
.primaryColor {
  color: var(--primary);
}
</style>
