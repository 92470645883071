<template>
  <div>
    <a-form-model
      ref="form"
      :model="form"
      layout="vertical"
      :rules="rules"
      v-if="attestStatus === '2'"
    >
      <a-form-model-item label="" prop="type">
        <a-radio-group
          :options="approvalOptions"
          v-model="form.type"
          @change="handleApprovalChange"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('enterprise.rejectReason')"
        prop="argument"
        v-if="!form.type"
      >
        <a-textarea v-model="form.argument" :maxLength="300" />
      </a-form-model-item>
    </a-form-model>
    <div :class="$style.detailsTitle">
      {{ $t('enterprise.certificationDetails') }}
    </div>
    <div :class="$style.gridThree">
      <div>
        <p><span>*</span>{{ $t('enterprise.TypeOfCertification') }}</p>
        <p>{{ getApplicationTypeText(formData.applicationType) || '-' }}</p>
      </div>
      <div>
        <p><span>*</span>{{ $t('enterprise.fullName') }}</p>
        <p>{{ formData.enName || '-' }}</p>
      </div>
      <div>
        <p>
          <span>*</span>{{ $t('enterprise.UnifiedSocialCreditCode') || '-' }}
        </p>
        <p>{{ formData.enOrganizationCode || '-' }}</p>
      </div>
    </div>
    <div :class="$style.gridThree">
      <div>
        <p><span>*</span>{{ $t('enterprise.license ') }}</p>
        <p>{{ formData.enLicenseCode || '-' }}</p>
      </div>
      <div>
        <p>{{ $t('enterprise.LegalRepresentative') }}</p>
        <p>{{ formData.enLawPerson || '-' }}</p>
      </div>
      <div>
        <p>{{ $t('solution.applicant') }}</p>
        <p>{{ formData.creator || '-' }}</p>
      </div>
    </div>

    <div :class="$style.gridThree">
      <div>
        <p>{{ $t('solution.applyBusiness') }}</p>
        <p>{{ formData.creatorTenantName || '-' }}</p>
      </div>
      <!-- <div>
        <p>{{ $t('enterprise.DocumentType') }}</p>
        <p>{{ getDocumentType(formData.documentType) }}</p>
      </div> -->
    </div>

    <div :class="$style.img">
      <p>{{ $t('controls.image.label') }}</p>
      <p>
        <x-upload
          mode="thumb"
          type="image"
          :uploadPath="getUploadPath"
          size="md"
          :maxFileSize="20"
          :max="1"
          v-model="formData.enBusinessLicense"
          view
        ></x-upload>
      </p>
    </div>
    <div :class="$style.footer" v-if="attestStatus === '2'">
      <a-button :class="$style.footerOperation" @click="handleClose">
        {{ $t('common.action.cancel') }}
      </a-button>
      <a-button
        :class="$style.footerOperation"
        @click="handleSubmit"
        type="primary"
      >
        {{ $t('common.action.submit') }}
      </a-button>
    </div>
    <div :class="$style.footer" v-if="attestStatus === '1'">
      <a-button :class="$style.footerOperation" @click="handleClose">
        {{ $t('common.action.cancel') }}
      </a-button>
    </div>
  </div>
</template>

<script>
/**
 * 企业认证弹窗
 */
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import { crossStorageModule } from '@/enum/store';
import { deepClone } from '@triascloud/utils';
import { fetchApproval } from '@/services/attest/index';

function createReadDetail(props) {
  return new Promise(resolve => {
    const modal = createModal(
      h =>
        h(ReadDetail, {
          props,
          on: {
            close: () => {
              modal.handleClose();
              resolve();
            },
            handleSave: () => {
              modal.handleClose();
              resolve();
            },
          },
        }),
      {
        width: 860,
        title: `${props.modalTitle}`,
      },
    );
  });
}

@Component({
  components: {},
})
export default class ReadDetail extends Vue {
  @crossStorageModule.State('tenant') tenant;

  @Prop({ type: String, default: '' }) modalTitle;
  @Prop({ type: String, default: '0' }) attestStatus;
  @Prop({ type: Object, default: () => {} }) record;

  static createReadDetail = createReadDetail;

  formData = {
    enName: null,
    enOrganizationCode: '',
    enLicenseCode: '',
    enLawPerson: '',
    enBusinessLicense: [],
    pkId: '',
  };
  form = {
    type: true,
    argument: '',
  };
  get rules() {
    return {
      argument: {
        message: this.$t('common.placeholder.input'),
        required: true,
        trigger: 'blur',
      },
    };
  }
  get approvalOptions() {
    return [
      { label: this.$t('enterprise.certified'), value: true },
      { label: this.$t('enterprise.rejectAuth'), value: false },
    ];
  }
  getApplicationTypeText(type) {
    switch (type) {
      case '1':
        return this.$t('enterprise.enterpriseText');
      case '2':
        return this.$t('enterprise.GovernmentPublicInstitutions');
      case '3':
        return this.$t('enterprise.other');
      default:
        return '-';
    }
  }
  getDocumentType(type) {
    switch (type) {
      case '1':
        return '有';
      case '2':
        return '无';
      default:
        return '-';
    }
  }

  // /{tenantId}/license/{UUID}.*
  getUploadPath = null;
  created() {
    this.getUploadPath = `/${this.tenant?.pkId}/license`;

    this.formData = deepClone(this.record);
    const arr = [];
    arr.push({ ossPath: this.record.enBusinessLicense, name: '1' });
    this.formData.enBusinessLicense = arr;
  }

  fullNameOptions = [];

  handleClose() {
    this.$emit('close');
  }

  onSubmit() {
    this.$emit('handleSave');
  }
  handleAgree() {
    fetchApproval({
      approval: true,
      pkId: this.record.pkId,
      argument: '',
    }).then(res => {
      if (res) {
        this.$message.success('success');
        this.$emit('close');
      }
    });
  }
  handleReject() {
    fetchApproval({
      approval: false,
      pkId: this.record.pkId,
      argument: this.form.argument,
    }).then(res => {
      if (res) {
        this.$message.success('success');
        this.$emit('close');
      }
    });
  }
  async handleSubmit() {
    if (this.form.type) this.handleAgree();
    else {
      await this.$refs.form.validate();
      this.handleReject();
    }
  }
  handleApprovalChange() {}
}
</script>

<style lang="less" module>
.footer {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  .footerOperation {
    &:first-child {
      margin-right: 20px;
    }
  }
}
.gridThree {
  display: flex;
  justify-content: flex-start;
  div {
    width: 33%;
    margin-top: 20px;
    p {
      &:nth-child(1) {
        color: var(--font);
        span {
          color: var(--font-color-info);
        }
      }
      &:nth-child(2) {
        margin-top: 10px;
        color: var(--font-info);
      }
    }
  }
}
.img {
  margin-top: 20px;
  p {
    &:nth-child(1) {
      margin-bottom: 10px;
      color: var(--font);
    }
  }
}
.detailsTitle {
  font-size: 16px;
  color: #333333;
  position: relative;
  padding-left: 10px;
  margin-top: 20px;
  &::before {
    content: '';
    height: 20px;
    position: absolute;
    top: 2px;
    left: 0;
    border-left: 3px solid var(--primary);
  }
}
</style>
