// @ts-check
import { BaseControl } from '../BaseControl';
// @ts-ignore
import ConfigComponent from './config.vue';
// @ts-ignore
import DesignComponent from './design.vue';
// @ts-ignore
import FormComponent from './enum.vue';
// import { generateRangeRule, generateUniqueRule } from '../validate';

/** @name 枚举 */
class EnumControl extends BaseControl {
  /** @readonly */
  type = 'enum';

  label = 'controls.enum.label';

  icon = 'tc-icon-text';

  trigger = 'blur';

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    defaultValue: {
      // 单行文本默认值类型
      type: 'custom',
      // 默认值
      custom: [],
    },
    identifier: 'ENUM',
    inParams: ['DEVELOPMENT'],
    outParams: [],
    optionList: [],
    isSingle: true,
    enumValueType: 'TEXT', // 枚举的（value）数值类型
  });

  // @ts-ignore
  // props = field => ({
  //   maxLength: field.widget.max || undefined,
  // });

  // @ts-ignore
  // rules = (field, root) => [
  //   generateRangeRule(field),
  //   generateUniqueRule(field, root),
  // ];

  /**
   * @name 字段特征定义
   * @type { object | ((field: import('../BaseControl').Field) => object) }
   * @returns  { object }
   */
  feature = field => ({
    WRITABLE: !field?.widget?.view,
    REQUIRED: !field?.widget?.view,
    PRINTABLE: true,
    BRIEFING: true,
    sorter: 'order',
  });

  FormComponent = FormComponent;

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;
}

export default new EnumControl();
