/** @name 结果类型 */
export const kitShelvesState = {
  /** @name 未上架 */
  INITIAL: 'INITIAL',
  /** @name 上架中 */
  PUT_AWAY: 'PUT_AWAY',
  /** @name 已下架 */
  SOLD_OUT: 'SOLD_OUT',
};

/** @name 结果类型 */
export const kitState = {
  /** @name 未上架 */
  notShelves: 0,
  /** @name 上架中 */
  shelves: 1,
  /** @name 已下架 */
  removedShelves: 2,
};

/** @name 结果类型 */
export const kitStateText = {
  /** @name 未上架 */
  [kitState.notShelves]: 'template.review.state.notListed',
  /** @name 已上架 */
  [kitState.shelves]: 'solution.addedTo',
  /** @name 下架中 */
  [kitState.removedShelves]: 'template.review.state.offShelf',
};
