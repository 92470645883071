import request from '../request';
const serviceName = '/iot-platform/platform';

//新增
export function insertVariable(data) {
  return request(`${serviceName}/apiVariable`, {
    method: 'POST',
    body: data,
  });
}
//修改
export function updateVariable(data) {
  return request(`${serviceName}/apiVariable`, {
    method: 'PUT',
    body: data,
  });
}
//列表
export function getVariables(data) {
  return request(`${serviceName}/apiVariable/list`, {
    method: 'GET',
    body: data,
  });
}
//变量详情
export function getVariableDetails(apiVariableId) {
  return request(`${serviceName}/apiVariable/${apiVariableId}`, {
    method: 'GET',
  });
}

//导出变量
export function exportVariables(platformId) {
  return request(`${serviceName}/apiVariable/export/${platformId}`, {
    method: 'GET',
  });
}
//导入变量
export function importVariables(platformId, data) {
  return request(`${serviceName}/apiVariable/import?platformId=${platformId}`, {
    method: 'POST',
    body: data,
  });
}
//变量详情
export function deleteVariable(apiVariableId) {
  return request(`${serviceName}/apiVariable/${apiVariableId}`, {
    method: 'DELETE',
  });
}
