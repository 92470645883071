import request from '../request';
const basePath = '/triascloud-iot-smart-helmet/system';

// OTA列表
export const reqOtaList = body => {
  const { current, size, destVersion } = body;
  let reqsutData = `?current=${current}&size=${size}`;
  if (destVersion) {
    reqsutData += `&destVersion=${destVersion}`;
  }
  return request(`${basePath}/device/ota/list${reqsutData}`, {
    method: 'get',
  });
};

// 创建OTA升级任务
export const reqAddUpgrade = body => {
  return request(`${basePath}/device/ota/createUpgrade`, {
    method: 'put',
    body,
  });
};

// 统计设备升级详情
export const reqUpgradeDetail = body => {
  const { firmwareId, upgradeType, deviceIds, srcVersions } = body;
  let reqsutData = `?firmwareId=${firmwareId}&upgradeType=${upgradeType}`;
  if (deviceIds) {
    reqsutData += `&deviceIds=${deviceIds}`;
  }
  if (srcVersions) {
    reqsutData += `&srcVersions=${srcVersions}`;
  }
  return request(`${basePath}/device/ota/countUpgradeDetail${reqsutData}`, {
    method: 'GET',
  });
};

// 获取所有设备固件版本 整包升级
export const reqAllFirmwareVersion = () => {
  return request(`${basePath}/device/ota/allFirmwareVersion`, {
    method: 'get',
  });
};
