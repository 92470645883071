export const TemplateType = {
  Application: '01',
  Form: '02',
  Print: '03',
};

export const CheckResult = {
  Fail: 0,
  Pass: 1,
};

export const TemplateState = {
  Init: 0,
  Open: 1,
  Close: 2,
};

export const TemplateLabelFrom = {
  System: 1,
  User: 2,
};
