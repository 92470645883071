import { tuple } from '@triascloud/utils';

import TabsControl from './Tabs';
import InputControl from './Input';
import CurrentDataSetControl from './CurrentDataSet';
import IntControl from './Int';
import FloatControl from './Float';
import DoubleControl from './Double';
import DateControl from './Date';
import AddDataSetControl from './AddDataSet';
import SyncInterfaceControl from './SyncInterface';
import FnNameControl from './FnName';
import FnIdentifierControl from './FnIdentifier';
import CustomParamControl from './CustomParam';
import SwitchControl from './Boolean';
import StructControl from './Struct';
import ArrayControl from './Array';
import EnumControl from './Enum/index.js';
import ImageControl from './Image';
import FileControl from './File';
import DPSControl from './DataPointSource';
/** @name 控件类型枚举 */
export const ControlTypeEnum = {
  /** @name 分页卡 */
  Tabs: TabsControl.type,
  /** @name 文本框 */
  Input: InputControl.type,
  /** @name 当前数据组 */
  CurrentDataSet: CurrentDataSetControl.type,
  /** @name 整数框 */
  Int: IntControl.type,
  /** @name 浮点框 */
  Float: FloatControl.type,
  /** @name 双精度浮点框 */
  Double: DoubleControl.type,
  /** @name 日期框 */
  Date: DateControl.type,
  /** @name 新增数据组 */
  AddDataSet: AddDataSetControl.type,
  /** @name 同步接口 */
  SyncInterface: SyncInterfaceControl.type,
  /** @name 功能名称 */
  FnName: FnNameControl.type,
  /** @name 功能标识 */
  FnIdentifier: FnIdentifierControl.type,
  /** @name 自定义模板（入参和出参） */
  CustomParam: CustomParamControl.type,
  /** @name 开关（是和否） */
  Switch: SwitchControl.type,
  /** @name 结构体 */
  Struct: StructControl.type,
  /** @name 数组 */
  Array: ArrayControl.type,
  /** @name 枚举*/
  Enum: EnumControl.type,
  /** @name 图片*/
  Image: ImageControl.type,
  /** @name 文件*/
  File: FileControl.type,
  /** @name DP源*/
  DataPoint: DPSControl.type,
};
/** @name 控件映射表 */
export const ControlMap = {
  [TabsControl.type]: TabsControl,
  [InputControl.type]: InputControl,
  [CurrentDataSetControl.type]: CurrentDataSetControl,
  [IntControl.type]: IntControl,
  [FloatControl.type]: FloatControl,
  [DoubleControl.type]: DoubleControl,
  [DateControl.type]: DateControl,
  [AddDataSetControl.type]: AddDataSetControl,
  [SyncInterfaceControl.type]: SyncInterfaceControl,
  [FnNameControl.type]: FnNameControl,
  [FnIdentifierControl.type]: FnIdentifierControl,
  [CustomParamControl.type]: CustomParamControl,
  [SwitchControl.type]: SwitchControl,
  [StructControl.type]: StructControl,
  [ArrayControl.type]: ArrayControl,
  [EnumControl.type]: EnumControl,
  [ImageControl.type]: ImageControl,
  [FileControl.type]: FileControl,
  [DPSControl.type]: DPSControl,
};

/** @name 模板类型 */
export const TemplateType = {
  /** @name 物模型模板 */
  MODEL_TEMPLATE: 'MODEL_TEMPLATE',
  /** @name 自定义模板 */
  CUSTOM_TEMPLATE: 'CUSTOM_TEMPLATE',
};

/** @name 自定义模板的tab类型（入参or出参） */
export const ParamType = {
  /** @name 入参 */
  REQ_PARAM: 'REQ_PARAM',
  /** @name 出参 */
  RES_PARAM: 'RES_PARAM',
};

/** @name 日期的默认值类型 */
export const DateDefault = {
  /** @name 手动输入 */
  MANUAL: 'manual',
  /** @name 此刻（实时计算当前时间） */
  CURRENT: 'current',
};

/** @name 文本的默认值类型 */
export const TextDefault = {
  /** @name 手动输入 */
  MANUAL: 'manual',
  /** @name 规则生成 */
  RULE: 'rule',
  /** @name 指定变量 */
  VARIABLE: 'variable',
};

/** @name 文本的规则生成的数据类型 */
export const RuleType = {
  /** @name 整数 */
  INT: 'int',
  /** @name 浮点数 */
  FLOAT: 'float',
  /** @name UUID */
  UUID: 'uuid',
};

/** @name 入参的格式 */
export const InputFormat = {
  /** @name 不参与入参 0 */
  NEVER: 0,
  /** @name 对象 1*/
  OBJECT: 1,
  /** @name 键值 2*/
  KEYVALUE: 2,
};

export const WrappedValueControls = tuple(
  ControlTypeEnum.Radio,
  ControlTypeEnum.Checkbox,
  ControlTypeEnum.Select,
  ControlTypeEnum.Tags,
);
