<template>
  <section :class="$style.wrap">
    <section :class="$style.queryWrap">
      <a-button icon="plus" type="primary" @click="handleAdd"
        >新增分类</a-button
      >
    </section>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
      @expand="haneleExpand"
    >
      <div slot="firstName" slot-scope="txt, record">
        <span v-if="record.firstName" :class="$style.nameWrap">
          <x-oss-image
            class="x-file-icon"
            size="32"
            :oss-path="record.headImgUrl"
          />
          <span :class="$style.txt">{{ txt }}</span>
        </span>
      </div>
      <div slot="secondName" slot-scope="txt, record">
        <span v-if="record.secondName" :class="$style.nameWrap">
          <x-oss-image
            class="x-file-icon"
            size="32"
            :oss-path="record.headImgUrl"
          />
          <span :class="$style.txt">{{ txt }}</span>
        </span>
      </div>
      <div slot="action" slot-scope="record">
        <span :class="$style.buttonGroups" v-if="record.firstName">
          <a :class="[$style.button]" @click="handleUpdate(record)">
            编辑
          </a>
          <a :class="[$style.button]" @click="handleDelete(record)">
            删除
          </a>
        </span>
      </div>
    </a-table>
  </section>
</template>
<script>
import { createFormModal } from '@triascloud/x-components';
import { Component, Vue } from 'vue-property-decorator';
import Add from './add.vue';
import {
  insertCategory,
  updateCategory,
  deleteCategory,
  getCategoryList,
} from '@/services/iot-platform/category';
@Component()
export default class Category extends Vue {
  async mounted() {
    await this.getCategoryList();
  }

  async handleAdd() {
    const form = await createFormModal(() => <Add />, {
      title: '新增分类',
      width: 500,
    });

    const option = {
      name: form.firstName,
      headImgUrl: form.firstPic[0].ossPath,
      sort: 0,
      children: [],
    };

    let list = [];
    form.secondSort.forEach((v, idx) => {
      list.push({
        name: v.name,
        headImgUrl: v.images[0].ossPath,
        sort: idx,
      });
    });
    option.children = list;
    const bol = await insertCategory(option);
    if (bol) {
      this.$message.success('新增成功！');
      this.getCategoryList();
    }
  }
  async handleUpdate(record) {
    const form = await createFormModal(() => <Add detail={record} />, {
      width: 500,
      title: '编辑分类',
    });

    const option = {
      name: form.firstName,
      headImgUrl: form.firstPic[0].ossPath,
      sort: 0,
      children: [],
      pkId: record.pkId,
    };

    let list = [];
    form.secondSort.forEach((v, idx) => {
      const option = {
        name: v.name,
        headImgUrl: v.images[0].ossPath,
        sort: idx,
      };
      if (v.pkId) {
        option.pkId = v.pkId;
      }
      list.push(option);
    });
    option.children = list;

    const bol = await updateCategory(option);
    if (bol) {
      this.$message.success('更新成功！');
      this.getCategoryList();
    }
  }
  async handleDelete(record) {
    const bol = await deleteCategory(record.pkId);
    if (bol) {
      this.$message.success('操作成功！');
      this.getCategoryList();
    }
  }

  tableData = [];
  async getCategoryList() {
    const { records, total } = await getCategoryList({
      current: this.pagination.current,
      size: this.pagination.pageSize,
    });
    records.forEach(v => {
      v.firstName = v.name;
      v.secondName = '';
      v.children.forEach(i => {
        i.firstName = '';
        i.secondName = i.name;
        if (i['children']) {
          delete i.children;
        }
      });
    });
    this.tableData = records;
    this.pagination.total = total;
  }

  get columns() {
    return [
      {
        title: '一级分类',
        dataIndex: 'firstName',
        scopedSlots: { customRender: 'firstName' },
      },
      {
        title: '二级分类',
        dataIndex: 'secondName',
        scopedSlots: { customRender: 'secondName' },
      },
      {
        title: '企业总数',
        dataIndex: 'totalTenant',
      },
      {
        title: '产品总数（类）',
        dataIndex: 'totalProduct',
      },
      {
        title: '设备总数（台）',
        dataIndex: 'totalDevice',
      },
      {
        width: 240,
        title: this.$t('common.action.operating'),
        key: 'operation',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  loading = false;
  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => `${total} ` + this.$t('connector.dataTemplate.itmes'),
  };
  handlePagination(e) {
    this.pagination.current = e.current;
    this.getCategoryList();
  }
  haneleExpand() {}
}
</script>
<style lang="less" module>
.buttonGroups {
  display: flex;
  justify-content: flex-start;

  .button {
    width: 50px;
    position: relative;
  }
}
.wrap {
  position: relative;
  .queryWrap {
    position: absolute;
    right: 0;
    top: -48px;
  }
  :global {
    .ant-table-row {
      td:first-of-type {
        display: flex;
        align-items: center;
      }
    }
    .x-icon.x-file-icon {
      font-size: 32px;
      vertical-align: middle;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .nameWrap {
    margin-left: 20px;
    .txt {
      margin-left: 20px;
    }
  }
}
</style>
