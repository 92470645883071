// @ts-check
import { BaseControl } from '../BaseControl';
import { InputFormat } from '../enum';
// @ts-ignore
import ConfigComponent from './config.vue';
// @ts-ignore
import DesignComponent from './design.vue';
// import { generateRangeRule, generateUniqueRule } from '../validate';

/** @name DP源 */
class DPControl extends BaseControl {
  /** @readonly */
  type = 'dataPoint';

  label = 'controls.dataPoint.label';

  icon = 'tc-icon-data-source';

  trigger = 'blur';

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    defaultValue: {
      // 单行文本默认值类型
      type: 'custom',
      // 默认值
      custom: [],
    },
    identifier: 'DP_source',
    inParams: ['DEVELOPMENT'],
    outParams: [],
    productId: undefined,
    classificationId: undefined,
    selectedDp: [],
    paramInFormat: InputFormat.OBJECT, //入参格式 不参与入参：0, 对象:1, 键值:2
    paramInTag: 'Identifier', //入参标识
  });

  // @ts-ignore
  // props = field => ({
  //   maxLength: field.widget.max || undefined,
  // });

  // @ts-ignore
  // rules = (field, root) => [
  //   generateRangeRule(field),
  //   generateUniqueRule(field, root),
  // ];

  /**
   * @name 字段特征定义
   * @type { object | ((field: import('../BaseControl').Field) => object) }
   * @returns  { object }
   */
  feature = field => ({
    WRITABLE: !field?.widget?.view,
    REQUIRED: !field?.widget?.view,
    PRINTABLE: true,
    BRIEFING: true,
    sorter: 'order',
  });

  FormComponent = DesignComponent;

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;
}

export default new DPControl();
