<template>
  <div>
    <EnterpriseItem
      :isShowTitle="true"
      v-for="(item, index) in list"
      :key="index"
      :class="$style.mb"
      :itemInfo="item"
      :current="`${index + 1}`"
    />
  </div>
</template>

<script>
/** 查看更多企业 */

import { Component, Vue, Prop } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import EnterpriseItem from './enterprise-item.vue';

import { fetchMoreEnterprise } from '@/services/business-opportunity/index';

@Component({
  components: { EnterpriseItem },
})
export default class MoreEnterprise extends Vue {
  @Prop({ type: Object, default: () => null }) modalInfo;
  @Prop({ type: String, default: () => '' }) memberId;

  static createMoreEnterpriseModal = createMoreEnterpriseModal;

  list = [];

  created() {
    fetchMoreEnterprise(this.memberId).then(res => {
      this.list = res;
    });
  }
}

function createMoreEnterpriseModal(props) {
  return new Promise(resolve => {
    const modal = createModal(
      h =>
        h(MoreEnterprise, {
          props,
          on: {
            close: () => {
              modal.handleClose();
              resolve();
            },
            handleCreate: () => {
              modal.handleClose();
              resolve('success');
            },
          },
        }),
      {
        width: 860,
        title: props.modalInfo.title,
      },
    );
  });
}
</script>

<style lang="less" module>
.mb {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
