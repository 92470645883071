import { UploadService } from '@triascloud/services';

/** @name 默认十六种颜色 */
export const defaultColors = [
  '#FF3535',
  '#FFC12B',
  '#339DEE',
  '#848BE2',
  '#BA68C8',
  '#9375CD',
  '#7987CB',
  '#64B5F5',
  '#4FC4F7',
  '#4DD1E2',
  '#FEB74B',
  '#E57375',
  '#F06292',
  '#EE9A9B',
  '#F48FB1',
  '#CE93D9',
];

export async function changeOssToUrl(host, list) {
  const basePath = '/oss/oss';
  const customService = new UploadService(basePath);
  const result = [];
  if (list.startsWith('common/public')) {
    const url = `${host}/${list}`;
    result.push(url);
  } else {
    const url = await customService.getAuth(list);
    result.push(url);
  }
  return result;
}

/** @name API变量reset */
export const variableReset = (reflect, ref) => {
  Object.keys(reflect)
    .filter(name => name.includes(ref))
    .forEach(key => {
      reflect[key] &&
        reflect[key][0] &&
        reflect[key][0].reset &&
        reflect[key][0].reset();
    });
};

/** @name 入参placeholder */
export const PLACEHOLDER_IN = '根据选择的DP点填写';
/** @name 出参placeholder */
export const PLACEHOLDER_OUT = '支持固定值和JsonPath解析';
/** @name input_placeholder */
export const PLACEHOLDER_INPUT = '调试时填写入参';
