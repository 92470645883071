<template>
  <div :class="$style.wrap">
    <a-tabs
      :default-active-key="activeKey"
      v-model="activeKey"
      :class="$style.wrapTab"
    >
      <a-tab-pane key="1" tab="套件管理">
        <KitList />
      </a-tab-pane>
      <a-tab-pane key="2" tab="套件分类">
        <KitTypeList />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import KitTypeList from './components/kit-type-list.vue';
import KitList from './components/kit-list.vue';

@Component({
  components: { KitTypeList, KitList },
})
export default class Index extends Vue {
  activeKey = '1';
}
</script>
<style lang="less" module>
.wrap {
  padding: 20px;
  height: 100%;
  :global(.ant-tabs-content) {
    height: 100%;
  }
}

.wrapTab {
  height: 100%;
}
</style>
