var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrap},[_c('field-config-config-desc',{attrs:{"desc":_vm.$t('controls.desc.input')}}),_c('field-config-title',_vm._b({},'field-config-title',_vm.fieldProps,false)),_c('field-config-required',_vm._b({},'field-config-required',_vm.fieldProps,false)),(!_vm.isRes)?_c('parameter-object',_vm._b({attrs:{"isInOrOut":"in"}},'parameter-object',_vm.fieldProps,false)):_vm._e(),(_vm.isRes)?_c('parameter-object',_vm._b({attrs:{"isInOrOut":"out"}},'parameter-object',_vm.fieldProps,false)):_vm._e(),(!_vm.isRes)?_c('a-form-model-item',{attrs:{"prop":"widget.defaultValue.custom","label":"默认值"}},[(_vm.isReq)?_c('a-radio-group',{attrs:{"value":_vm.field.widget.defaultType},on:{"change":_vm.onTypeChange}},[_c('a-radio',{attrs:{"value":_vm.TextDefault.MANUAL}},[_vm._v(" 手动输入 ")]),_c('a-radio',{attrs:{"value":_vm.TextDefault.RULE}},[_vm._v(" 规则生成 ")]),_c('a-radio',{attrs:{"value":_vm.TextDefault.VARIABLE}},[_vm._v(" 指定变量 ")])],1):_vm._e(),(_vm.field.widget.defaultType === _vm.TextDefault.MANUAL)?_c('a-input',{class:_vm.$style.item,attrs:{"value":_vm.field.widget.defaultValue.custom,"placeholder":"请输入默认值"},on:{"change":function (v) { return _vm.handleInput('widget.defaultValue.custom', v.target.value); }}}):_vm._e(),(_vm.field.widget.defaultType === _vm.TextDefault.RULE)?_c('a-input-group',{attrs:{"compact":""}},[_c('a-select',{staticStyle:{"width":"35%"},attrs:{"value":_vm.field.widget.ruleType,"placeholder":"请选择"},on:{"change":function (v) {
            _vm.handleInput('widget.ruleType', v);
            if (v === 'uuid') {
              _vm.handleInput('widget.ruleLength', '32');
            } else {
              _vm.handleInput('widget.ruleLength', '');
            }
          }}},[_c('a-select-option',{attrs:{"value":_vm.RuleType.INT}},[_vm._v(" 整数 ")]),_c('a-select-option',{attrs:{"value":_vm.RuleType.FLOAT}},[_vm._v(" 浮点 ")]),_c('a-select-option',{attrs:{"value":_vm.RuleType.UUID}},[_vm._v(" UUID(V4) ")])],1),_c('a-input',{staticStyle:{"width":"65%"},attrs:{"value":_vm.field.widget.ruleLength,"placeholder":"长度","type":"number","max":100,"min":1,"disabled":_vm.field.widget.ruleType === _vm.RuleType.UUID},on:{"change":function (v) { return _vm.handleInput('widget.ruleLength', v.target.value); }}},[_c('span',{attrs:{"slot":"addonAfter"},slot:"addonAfter"},[_vm._v("位")])])],1):_vm._e(),(_vm.field.widget.defaultType === _vm.TextDefault.VARIABLE)?_c('a-select',{attrs:{"value":_vm.field.widget.variableId,"placeholder":"请选择"},on:{"change":_vm.handleVarChange}},_vm._l((_vm.variables),function(item){return _c('a-select-option',{key:item.apiVariableId,attrs:{"value":item.apiVariableId}},[_vm._v(" "+_vm._s(item.remarkName)+" ")])}),1):_vm._e()],1):_vm._e(),_c('a-form-model-item',{attrs:{"prop":"widget.maxLength","label":_vm.$t('controls.input.maxLength')}},[_c('section',{class:this.$style.section},[_c('a-input',{class:_vm.$style.item,attrs:{"value":_vm.field.widget.maxLength,"type":"number","max":10240,"min":1},on:{"change":function (v) { return _vm.handleInput('widget.maxLength', +v); }}},[_c('span',{attrs:{"slot":"addonAfter"},slot:"addonAfter"},[_vm._v(_vm._s(_vm.$t('connector.dataType.byte')))])])],1)]),_c('field-config-description',_vm._b({},'field-config-description',_vm.fieldProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }