<template>
  <div>
    <div
      :class="[
        $style.ImgList,
        type === 'customer' ? $style.customerBg : '',
        type === 'advantage' ? $style.customerBg : '',
        type === 'architecture' ? $style.architecture : '',
        'solutionBg',
      ]"
    >
      <ImgItem
        v-for="(item, index) in value"
        :form-item-prop="`${formItemProp}.${index}`"
        :key="index"
        :value="item"
        :status="true"
        @input="changData($event, index)"
        @deleteItem="
          () => {
            $emit(
              'input',
              value.filter((_, i) => index !== i),
            );
          }
        "
        :type="type"
        :ref="type"
      />
    </div>
    <a-button
      v-show="value && value.length < countMap[type]"
      icon="plus"
      type="link"
      :class="$style.addApp"
      @click="addModal"
      v-if="type !== 'architecture'"
      >{{ addText }}</a-button
    >
    <x-upload-button
      v-else
      v-show="value && value.length < countMap[type]"
      :maxFileSize="5"
      @success="
        res => {
          $emit('input', [
            ...value,
            {
              description: '',
              pkId: '',
              title: '',
              image: res.ossData.ossPath,
            },
          ]);
        }
      "
    >
      <a-button icon="plus" type="link" :class="$style.addApp">
        {{ addText }}
      </a-button>
    </x-upload-button>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import ImgItem from './img-item.vue';
import AddModal from './add-modal.vue';

@Component({
  components: { ImgItem },
})
export default class ImgList extends Vue {
  @Prop({ type: Array }) value;
  @Prop({ type: String }) type;
  @Prop() formItemProp;
  countMap = { bgDescribe: 6, architecture: 3, advantage: 6, customer: 100 };
  status = true;
  get addText() {
    const typeData = {
      bgDescribe: this.$t('solution.addBg'),
      customer: this.$t('solution.newcustomer'),
      advantage: this.$t('solution.tip12'),
      architecture: this.$t('solution.newDiagram'),
    };
    return typeData[this.type];
  }

  changData(data, index) {
    this.$emit(
      'input',
      this.value.map((item, i) => (i === index ? data : item)),
    );
  }
  addImage() {
    this.$emit('input', [
      ...this.value,
      {
        description: '',
        image: '',
        pkId: '',
        title: '',
      },
    ]);
  }

  async addModal() {
    const res = await createFormModal(() => <AddModal type={this.type} />, {
      title: this.addText,
      width: 690,
      className: 'img-item-modal-wrapper',
    });
    this.$emit('input', [...this.value, res]);
  }
}
</script>
<style lang="less" module>
.ImgList {
  display: grid;
  flex-wrap: wrap;
  width: 100%;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 415px);
  gap: 30px;
  justify-content: space-between;
  align-content: flex-start;
  margin-bottom: 10px;
  :global {
    .x-upload-button--uploader {
      line-height: 0px;
    }
  }
}
.customerBg {
  grid-template-columns: repeat(auto-fill, 330px);
}

.architecture {
  display: flex;
  gap: inherit;
}
.architectureImg {
  :global(.delIconBox) {
    left: 1080px;
  }
}
.addApp {
  padding: 0;
}
</style>
