<template>
  <div :class="$style.introduce">
    <div :class="$style.scroll">
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <div :class="$style.title">
          <span>1</span>
          产品基础信息
        </div>
        <ProductionInfo
          :infoForm="form.baseInfoForm"
          :disabledFlag="disabledFlag"
        />
        <div :class="$style.title">
          <span>2</span>
          增值服务
        </div>
        <ValueAdded
          :infoForm="form.valueAddForm"
          :disabledFlag="disabledFlag"
        />
        <div :class="$style.title">
          <span>3</span>
          售后支持
        </div>
        <SalesSupport
          :infoForm="form.supportForm"
          :disabledFlag="disabledFlag"
        />
        <div :class="$style.title">
          <span>4</span>
          设备能力
        </div>
        <DevicePower
          :infoForm="form.powerForm"
          :platformId="platformId"
          :productionId="productionId"
          :disabledFlag="disabledFlag"
        />
        <div :class="$style.title">
          <span>5</span>
          设备规格和介绍
        </div>
        <RichIntroduce
          :infoForm="form.guigeForm"
          :disabledFlag="disabledFlag"
        />
      </a-form-model>
    </div>
    <div :class="$style.btnGroup" v-if="!disabledFlag">
      <a-button @click="close">取消</a-button>
      <a-button
        v-if="deviceInfoId === ''"
        :class="$style.ml10"
        type="primary"
        @click="tStore"
      >
        暂存
      </a-button>
      <a-button :class="$style.ml10" type="primary" @click="save">
        保存
      </a-button>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductionInfo from './production-info.vue';
import HardwareSpec from './hardware-spec.vue';
import DevicePower from './device-power.vue';
import ValueAdded from './value-added.vue';
import SalesSupport from './sales-support.vue';
import RichIntroduce from './rich-introduce.vue';
import {
  addOrEditProductintroduction,
  getProductintroduction,
  storeProductintroduction,
} from '@/services/iot-platform/deviceManage';
import { UploadService } from '@triascloud/services';

@Component({
  components: {
    ProductionInfo,
    HardwareSpec,
    DevicePower,
    ValueAdded,
    SalesSupport,
    RichIntroduce,
  },
})
export default class IntroduceModalIndex extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: String, default: '' }) productionId;
  @Prop({ type: Boolean, default: false }) disabledFlag;
  /** @description 平台审核id */
  @Prop({ type: String, default: '' }) reviewId;

  form = {
    baseInfoForm: {
      headFigure: [],
      deviceName: '',
      brand: '',
      devicePrice: undefined,
      unit: '',
      productType: '',
      inventory: undefined,
      mainSort: undefined,
      subSort: undefined,
    },
    valueAddForm: {
      freeCallCount: 1000,
      freeStorageTime: 1,
      callCountExpands: [],
      storageTimeExpands: [],
    },
    supportForm: {
      contact: '',
      afterSalesTime: '',
      address: '',
      email: '',
      serviceIntroduction: [],
    },
    powerForm: {
      productDocumentation: [],
    },
    guigeForm: {
      hardwareSpecification: [],
      graphicIntroduction: [],
    },
  };

  rules = {
    'baseInfoForm.headFigure': [{ required: true, message: '头图不能为空' }],
    'baseInfoForm.deviceName': [
      { required: true, message: '设备名称不能为空' },
    ],
    'baseInfoForm.brand': [{ required: true, message: '品牌不能为空' }],
    'baseInfoForm.devicePrice': [
      { required: true, message: '设备价格不能为空' },
    ],
    'baseInfoForm.unit': [{ required: true, message: '单位不能为空' }],
    'baseInfoForm.productType': [
      { required: true, message: '产品型号不能为空' },
    ],
    'baseInfoForm.inventory': [{ required: true, message: '库存不能为空' }],
    'baseInfoForm.subSort': [{ required: true, message: '二级分类不能为空' }],
    'valueAddForm.freeCallCount': [
      { required: true, message: '免费调用不能为空' },
    ],
    'valueAddForm.freeStorageTime': [
      { required: true, message: '免费数据存储不能为空' },
    ],
    'supportForm.contact': [
      { required: true, message: '联系电话不能为空' },
      {
        pattern: /^[0-9]+$/,
        message: '请输入数字',
      },
    ],
    'supportForm.email': [
      { required: true, message: '联系邮箱不能为空' },
      {
        pattern: '^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$',
        message: '请输入正确的邮箱地址',
      },
    ],
    'supportForm.serviceIntroduction': [
      { required: true, message: '售后服务介绍图片不能为空' },
    ],
    'guigeForm.graphicIntroduction': [
      { required: true, message: '设备介绍图片不能为空' },
    ],
  };

  mounted() {
    this.initForm();
  }

  deviceInfoId = '';

  async initForm() {
    const baseInfoForm = this.form.baseInfoForm;
    const valueAddForm = this.form.valueAddForm;
    const supportForm = this.form.supportForm;
    const powerForm = this.form.powerForm;
    const guigeForm = this.form.guigeForm;
    try {
      const params = {
        productId: this.productionId,
      };
      if (this.reviewId) {
        params.recordId = this.reviewId;
      }
      const res = await getProductintroduction(params);
      this.deviceInfoId = res.pkId;
      baseInfoForm.headFigure = await this.getImageUrl(res.headFigure);
      baseInfoForm.deviceName = res.deviceName;
      baseInfoForm.brand = res.brand;
      baseInfoForm.devicePrice = res.devicePrice;
      baseInfoForm.unit = res.unit;
      baseInfoForm.productType = res.productType;
      baseInfoForm.inventory = res.inventory;
      baseInfoForm.mainSort = res.categoryTree?.pkId;
      baseInfoForm.subSort = res.category;
      baseInfoForm.inventory = res.inventory;
      valueAddForm.freeCallCount = res.freeCallCount;
      valueAddForm.callCountExpands = res.callCountExpands.map(v => {
        return {
          ...v,
          updated: false,
        };
      });
      valueAddForm.freeStorageTime = res.freeStorageTime;
      valueAddForm.storageTimeExpands = res.storageTimeExpands.map(v => {
        return {
          ...v,
          updated: false,
        };
      });
      supportForm.contact = res.contact;
      supportForm.afterSalesTime = res.afterSalesTime;
      supportForm.email = res.email;
      supportForm.address = res.address;
      supportForm.serviceIntroduction = await this.getImageUrl(
        res.serviceIntroduction,
      );
      powerForm.productDocumentation = res.productDocumentation.map(v => {
        return {
          name: v.fileName,
          ossPath: v.ossPath,
        };
      });
      guigeForm.hardwareSpecification = res.hardwareSpecification;
      guigeForm.graphicIntroduction = await this.getImageUrl(
        res.graphicIntroduction,
      );
    } catch {
      return false;
    }
  }

  async getImageUrl(arr) {
    if (!arr.length) return [];
    const basePath = '/oss/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(arr);
    return abPath.map((v, idx) => {
      return {
        uid: idx + 1,
        name: 'picture' + idx,
        status: 'done',
        url: v,
        ossPath: arr[idx],
      };
    });
  }

  formData() {
    const baseInfoForm = this.form.baseInfoForm;
    const valueAddForm = this.form.valueAddForm;
    const supportForm = this.form.supportForm;
    const powerForm = this.form.powerForm;
    const guigeForm = this.form.guigeForm;
    return {
      idxProductId: this.productionId,
      // idxProductId: '1564091274244186113',
      headFigure: this.getOssPath(baseInfoForm.headFigure),
      deviceName: baseInfoForm.deviceName,
      brand: baseInfoForm.brand,
      devicePrice: baseInfoForm.devicePrice || 0,
      unit: baseInfoForm.unit,
      productType: baseInfoForm.productType,
      inventory: baseInfoForm.inventory || 0,
      freeCallCount: valueAddForm.freeCallCount || 0,
      category: baseInfoForm.subSort,
      callCountExpands: this.changeExtend(
        valueAddForm.callCountExpands,
        'CALL_COUNT',
      ),
      freeStorageTime: valueAddForm.freeStorageTime || 0,
      storageTimeExpands: this.changeExtend(
        valueAddForm.storageTimeExpands,
        'STORAGE_TIME',
      ),
      contact: supportForm.contact,
      afterSalesTime: supportForm.afterSalesTime,
      email: supportForm.email,
      address: supportForm.address,
      serviceIntroduction: this.getOssPath(supportForm.serviceIntroduction),
      productDocumentation: this.getOssPathFile(powerForm.productDocumentation),
      hardwareSpecification: guigeForm.hardwareSpecification.map(v => {
        return {
          title: v.title,
          content: v.content,
        };
      }),
      graphicIntroduction: this.getOssPath(guigeForm.graphicIntroduction),
    };
  }

  async tStore() {
    const params = this.formData();
    if (this.deviceInfoId) {
      params['pkId'] = '';
    }
    try {
      await storeProductintroduction(params);
      this.$message.success('暂存成功');
      this.openNotification('store');
      this.close();
    } catch {
      return false;
    }
  }

  async save() {
    await this.$refs.form.validate();
    const params = this.formData();
    if (this.deviceInfoId) {
      params['pkId'] = this.deviceInfoId;
    }
    try {
      await addOrEditProductintroduction(params);
      this.$message.success('保存成功');
      this.openNotification('save');
      this.close();
    } catch {
      return false;
    }
  }

  openNotification(flag) {
    const key = `open${Date.now()}`;
    const dsc =
      flag === 'save' ? (
        <span>
          数据已提交保存，将在<span class={this.$style.text}>发布</span>且
          <span class={this.$style.text}>审核通过</span>
          后同步更新到官网/企业！
        </span>
      ) : (
        <span>
          距离最后一次暂存超过<span class={this.$style.dec}>3</span>
          天后，临时保存的内容将被自动清空！
        </span>
      );
    const msg = flag === 'save' ? '保存提示' : '暂存提示';
    this.$notification.open({
      message: msg,
      description: dsc,
      duration: 0,
      icon: <a-icon type="exclamation-circle" style="color: var(--warning)" />,
      btn: h => {
        return h(
          'a-button',
          {
            props: {
              type: 'primary',
              size: 'small',
            },
            on: {
              click: () => this.$notification.close(key),
            },
          },
          '确定',
        );
      },
      key,
      onClose: () => this.$notification.close(key),
    });
  }

  close() {
    this.$emit('close');
  }

  getOssPath(arr) {
    if (!arr.length) return [];
    return arr.map(v => v.ossPath);
  }

  getOssPathFile(arr) {
    if (!arr.length) return [];
    return arr.map(v => {
      const ext = v.ossPath.split('.')[1];
      return {
        fileName: v.name,
        fileType: ext,
        ossPath: v.ossPath,
      };
    });
  }

  changeExtend(arr, type) {
    if (!arr.length) return [];
    return arr.map((v, idx) => {
      return {
        idxProductId: this.productionId,
        // idxProductId: '1564091274244186113',
        count: v.count,
        price: v.price,
        sort: idx,
        type,
      };
    });
  }
}
</script>
<style lang="less" module>
.introduce {
  .scroll {
    height: 700px;
    overflow: hidden auto;
    padding: 0 10px;
  }

  .title {
    font-size: 22px;
    margin-bottom: 10px;
    color: var(--primary);

    span {
      font-size: 36px;
    }
  }

  .btnGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .ml10 {
    margin-left: 10px;
  }
}

.dec {
  color: var(--primary);
  font-size: 18px;
  margin: 0 5px;
}

.text {
  color: var(--primary);
}
</style>
