<template>
  <a-form-model
    :class="$style.wrap"
    layout="horizontal"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-row :gutter="[0, 20]">
      <a-col :span="12">
        <a-form-model-item prop="firstPic" label="一级头图">
          <images-upload v-model="form.firstPic" />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item prop="firstName" label="一级名称">
          <a-input
            v-model="form.firstName"
            placeholder="限8字符汉字"
            :maxLength="16"
          /> </a-form-model-item
      ></a-col>
    </a-row>
    <a-form-model-item prop="secondSort" label="二级分类">
      <second-sort v-model="form.secondSort" />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ImagesUpload from '@/views/connector/platform-manage/components/images-upload.vue';
import { UploadService } from '@triascloud/services';
import SecondSort from './second-sort.vue';
@Component({
  components: {
    ImagesUpload,
    SecondSort,
  },
})
export default class Add extends Vue {
  @Prop({ type: Object }) detail;

  mounted() {
    this.initDefault();
  }

  async initDefault() {
    if (this.detail && !this.detail.firstName) {
      return;
    }
    this.form.firstName = this.detail.firstName;
    const picList = [this.detail.headImgUrl];
    const secondSort = [];
    this.detail.children.forEach((item, index) => {
      picList.push(item.headImgUrl);
      secondSort.push({
        name: item.secondName,
        pkId: item.pkId,
        images: [
          {
            uid: index + 'second',
            name: item.secondName,
            status: 'done',
            url: '',
            ossPath: item.headImgUrl,
          },
        ],
      });
    });
    const basePath = '/oss/oss';
    const customService = new UploadService(basePath);
    const picPathList = await customService.getAuth(picList);

    const firstPic = picPathList.shift();

    this.form.firstPic = [
      {
        uid: 1,
        name: this.detail.firstName,
        status: 'done',
        url: firstPic,
        ossPath: this.detail.headImgUrl,
      },
    ];

    picPathList.forEach((v, i) => {
      secondSort[i].images[0].url = v;
    });
    this.form.secondSort = secondSort;
  }

  form = {
    firstPic: [],
    firstName: '',
    secondSort: [],
  };
  rules = {
    firstPic: [
      {
        required: true,
        message: '一级头图不能为空',
      },
    ],
    firstName: [
      {
        required: true,
        message: '一级名称不能为空',
      },
    ],
    secondSort: [
      {
        required: true,
        validator: this.secondSortrule,
        trigger: 'blur',
      },
    ],
  };

  secondSortrule(rule, value, cb) {
    if (!value.length) {
      cb('二级分类不能为空');
    }
    let count = 0;
    value.forEach(v => {
      if (!v.images.length) {
        ++count;
      }
      if (!v.name) {
        ++count;
      }
    });
    if (count > 0) {
      cb('二级分类信息需要填写完善');
    } else {
      cb();
    }
  }

  async getValue() {
    await this.$refs.form.validate();
    return this.form;
  }
}
</script>
<style lang="less" module>
.wrap {
  :global {
    .ant-upload.ant-upload-select-picture-card {
      margin: 0;
      width: 60px;
      height: 60px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload-list-picture-card-container {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
