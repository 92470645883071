<template>
  <div>
    <!-- <span
      v-for="item of selectedItems"
      :key="item.dataPointId"
      :class="$style.mr10"
      >{{ item.functionName }}</span
    > -->
    <a-select
      mode="multiple"
      :value="field.widget.defaultValue.custom"
      style="width: 100%"
      placeholder="请选择"
    >
      <a-select-option
        v-for="item of selectedItems"
        :key="item.dataPointId"
        :value="item.dataPointId"
      >
        {{ item.functionName }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component()
export default class DPSDesign extends Vue {
  /** @description 数据 */
  @Prop({
    type: Object,
    required: true,
  })
  field;
  @Prop() value;
  @Prop() view;
  get selectedItems() {
    return this.field.widget.selectedDp;
  }
}
</script>
<style lang="less" module>
.mr10 {
  margin-right: 10px;
}
</style>
