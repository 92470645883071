<template>
  <div>
    <div v-if="tipsType === 'delete'">
      <a-form-model :model="form" :rules="rules" ref="form">
        <a-form-model-item label="订阅名称" prop="planName">
          <a-input
            placeholder="请输入计划名称"
            :max-length="30"
            v-model="form.planName"
          />
        </a-form-model-item>
      </a-form-model>
      <div style="color: red;margin-top: 20px;">
        提醒：将删除订阅下的所有版本和数据，请谨慎操作！
      </div>
    </div>
    <div v-else-if="tipsType === 'STOP'">
      <p>启动当前订阅？</p>
      <p>启动前请调试确认配置能正常运行！</p>
    </div>
    <div v-else>
      <p>暂停当前订阅？</p>
      <p style="color: var(--warning)">
        说明：暂停后将停止消息消费/定时请求，关联的设备数据将停止更新！
      </p>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  changeState,
  deleteSubscribe,
} from '@/services/iot-platform/dataManage';

@Component
export default class TipsModel extends Vue {
  @Prop({ type: String, default: '' }) editName;
  @Prop({ type: String, default: '' }) pkId;
  @Prop({ type: String, default: 'delete' }) tipsType;

  form = {
    planName: '',
  };
  rules = {
    planName: [
      {
        required: true,
        validator: this.checkPlanName,
        trigger: 'blur',
      },
    ],
  };

  checkPlanName(rule, value, callback) {
    if (!value) {
      callback(new Error('订阅名称不允许为空'));
    } else if (value !== this.editName) {
      callback(new Error('订阅名称错误'));
    } else {
      callback();
    }
  }

  async getValue() {
    if (this.tipsType === 'delete') {
      await this.$refs.form.validate();
      await deleteSubscribe(this.pkId);
      this.$message.success('删除成功');
    } else if (this.tipsType === 'RUN') {
      const params = {
        pkId: this.pkId,
        state: 'STOP',
      };
      await changeState(params);
      this.$message.success('暂停成功');
    } else {
      const params = {
        pkId: this.pkId,
        state: 'RUN',
      };
      await changeState(params);
      this.$message.success('运行成功');
    }
  }
}
</script>

<style scoped></style>
