import request from '../request';
export const basePath = '/template-manager/label';

/**
 * @name 获取标签列表
 * @returns Promise<{ any[] }>
 */
export function getLabelList() {
  return request(`${basePath}/list`);
}

/**
 * @name 新增标签
 * @returns Promise<true>
 */
export function addLabel({ name, color }) {
  return request(`${basePath}/save`, {
    method: 'POST',
    body: {
      name,
      color,
    },
  });
}

/**
 * @name 删除标签
 * @returns Promise<true>
 */
export function removeLabel(pkId) {
  return request(`${basePath}/delete?pkId=${pkId}`, {
    method: 'DELETE',
    body: {
      pkId,
    },
  });
}

/**
 * @name 编辑标签颜色
 * @returns Promise<true>
 */
export function editLabelColor({ color, pkId }) {
  return request(`${basePath}/color`, {
    method: 'POST',
    body: {
      color,
      pkId,
    },
  });
}

/**
 * @name 顶置标签
 * @returns Promise<true>
 */
export function overheadLabel(pkId) {
  return request(`${basePath}/setTop?pkId=${pkId}`, {
    method: 'PUT',
    body: {
      pkId,
    },
  });
}
