<template>
  <div v-if="view" :class="$style['x-color-panel']">
    <div
      :style="{
        background: value,
        color: !!value ? '#FFFFFF' : 'var(--primary-light)',
      }"
      :class="[
        !value ? $style['x-color-panel--mosaic'] : '',
        $style['x-color-panel--block'],
        { [$style['x-color-panel--disabled']]: view },
      ]"
    ></div>
  </div>
  <div v-else :class="$style['x-color-panel']">
    <div
      v-for="color in colors"
      :key="color"
      :style="{
        background: color,
        color: !!color ? '#FFFFFF' : 'var(--primary-light)',
      }"
      :class="[
        !color ? $style['x-color-panel--mosaic'] : '',
        $style['x-color-panel--block'],
      ]"
      @click="handleSelectColor(color)"
    >
      <x-icon
        :class="$style['x-icon']"
        type="tc-icon-check"
        v-if="color === selectedColor"
      />
    </div>
    <div
      :class="$style['x-color-panel--block']"
      :style="`background: ${customColor}`"
      v-if="customizable"
    >
      <a-input
        :class="$style['x-color-panel--input']"
        type="color"
        v-model="customColor"
        @blur="handleColorChange"
        @click.stop="event => event.stopPropagation()"
      />
      <x-icon
        v-if="!customColor"
        :class="$style['x-icon-add']"
        type="plus-circle"
      />
    </div>
    <div
      v-for="color in customColorList"
      :key="color"
      :style="{
        background: color,
        color: !!color ? '#FFFFFF' : 'var(--primary-light)',
      }"
      :class="[
        !color ? $style['x-color-panel--mosaic'] : '',
        $style['x-color-panel--block'],
      ]"
      @click="handleSelectColor(color)"
    >
      <x-icon
        :class="$style['x-icon']"
        type="tc-icon-check"
        v-if="color === selectedColor"
      />
    </div>
  </div>
</template>
<script>
import { Vue, Prop, Component } from 'vue-property-decorator';
import { HabitService } from '@triascloud/services';
import { promiseLock } from '@triascloud/utils';
import { defaultColors } from './utils';

@Component()
export default class CustomColorPanel extends Vue {
  /** @name 用户习惯存储路径 */
  @Prop({ type: String, default: 'form.design.color.picker' }) identifier;
  /** @name 选中色值 */
  @Prop({ type: String, default: '' }) value;
  /** @name 默认颜色列表 */
  @Prop({
    type: Array,
    default: () => [...defaultColors],
  })
  colors;
  /** @name 是否可以自定义颜色 */
  @Prop({ type: Boolean, default: true }) customizable;
  /** @name 自定义颜色数量 */
  @Prop({ type: Number, default: 7 }) customizableLimit;
  /** @name 禁用状态 */
  @Prop({ type: Boolean, default: false }) view;

  get selectedColor() {
    return this.colorList.find(color => color === this.value);
  }

  customColor = null;
  customColorList = [];

  get colorList() {
    return [...this.colors, ...this.customColorList];
  }

  async created() {
    if (this.view) {
      this.customColorList = [this.value];
      return;
    }
    if (!this.identifier) return;
    this.getColorList(this.identifier);
  }

  @promiseLock({ keyGenerator: identifier => identifier })
  async getColorList(identifier) {
    this.customColorList = ((await this.habitService.getItem(identifier)) || '')
      .split(',')
      .filter(item => item);

    if (this.customColorList.length > this.customizableLimit) {
      this.customColorList = this.customColorList.slice(
        0,
        this.customizableLimit,
      );
    }
  }

  handleSelectColor(color) {
    this.$emit('change', color);
    this.$emit('input', color);
  }

  handleColorChange() {
    if (!this.customColor) return;
    if (!this.colorList.includes(this.customColor)) {
      if (this.customColorList.length === this.customizableLimit) {
        this.customColorList.pop();
      }
      this.customColorList.unshift(this.customColor);
      if (this.identifier) {
        this.habitService.setItem(
          this.identifier,
          this.customColorList.join(','),
        );
      }
    }
    this.handleSelectColor(this.customColor);
    this.customColor = null;
  }

  get habitService() {
    return new HabitService('/configuration/memberHabit');
  }
}
</script>
<style lang="less" module>
.x-color-panel {
  width: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 24px);
  gap: 10px 10px;
  justify-content: space-between;
  align-content: flex-start;

  .x-color-panel--block {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    .x-icon {
      z-index: 1;
      font-size: 20px;
    }
    .x-color-panel--input {
      z-index: 2;
      border: none;
      height: 24px;
      width: 24px;
      position: absolute;
      top: 0;
      left: 0;
      caret-color: transparent;
      padding: 0;
      opacity: 0;
      cursor: pointer;
    }
    .x-icon-add {
      font-size: 24px;
      color: #cccccc;
    }
  }

  .x-color-panel--mosaic {
    position: relative;
    overflow: hidden;
    &.active {
      color: var(--primary);
    }
    &::after {
      --lineStart: 4%;
      --lineEnd: 96%;
      --bgStart: 25%;
      --bgEnd: 75%;
      --color: #ddd;
      content: '';
      position: absolute;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      transform: rotate(45deg);
      background-size: 5px 5px;
      background-image:
    /* 前两个渐变是为了防止 rotate 之后的分割线 */ linear-gradient(
          0deg,
          var(--color) var(--lineStart),
          transparent var(--lineStart),
          transparent var(--lineEnd),
          var(--color) var(--lineEnd)
        ),
        linear-gradient(
          -90deg,
          var(--color) var(--lineStart),
          transparent var(--lineStart),
          transparent var(--lineEnd),
          var(--color) var(--lineEnd)
        ),
        linear-gradient(
          45deg,
          var(--color) var(--bgStart),
          transparent var(--bgStart),
          transparent var(--bgEnd),
          var(--color) var(--bgEnd)
        ),
        linear-gradient(
          -45deg,
          var(--color) var(--bgStart),
          transparent var(--bgStart),
          transparent var(--bgEnd),
          var(--color) var(--bgEnd)
        );
    }
  }
  .x-color-panel--disabled {
    cursor: not-allowed;
  }
}

:global(.dark) {
  .x-color-panel .x-color-panel--mosaic::after {
    opacity: 0.5;
  }
}
</style>
