import request from '../request';

const serviceName = '/oss/pdf';

export function exportPdf(params) {
  return request(`${serviceName}/export`, {
    method: 'POST',
    body: params,
  });
}
export function previewPdf({ fileName, pages }) {
  return request(`${serviceName}/preview`, {
    method: 'POST',
    body: {
      fileName,
      pages,
    },
  });
}

export function generatePdf(params) {
  return request(`${serviceName}/generate`, {
    method: 'POST',
    body: params,
  });
}
