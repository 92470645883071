import request from '../request';
export const basePath = '/configuration/download/management';

export const getMediaList = config =>
  request(`${basePath}/get/config`, { method: 'POST', body: config });

export const getMediaDetail = pkId =>
  request(`${basePath}/get/config/detail?pkId=${pkId}`, { method: 'GET' });

export const stickTopic = pkId =>
  request(`${basePath}/top?pkId=${pkId}`, {
    method: 'GET',
  });

export const cancelStickTopic = pkId =>
  request(`${basePath}/cancel/top?pkId=${pkId}`, {
    method: 'GET',
  });

export const deleteMedia = pkId =>
  request(`${basePath}/remove?pkId=${pkId}`, {
    method: 'DELETE',
  });

export const addMedia = config =>
  request(`${basePath}/add/config`, { method: 'POST', body: config });

export const editMedia = config =>
  request(`${basePath}/edit/config`, { method: 'POST', body: config });
