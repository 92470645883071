<template>
  <a-tabs
    @change="handleChangeTab"
    :class="['x-tab', `x-tab--align-left`, `x-tab--item-column-1`]"
    :animated="false"
    v-model="activeKey"
  >
    <a-tab-pane
      v-for="tab in fieldShadow.widget.tabs"
      :tab="tab.title"
      :key="tab.id"
      forceRender
      :disabled="onlyId ? tab.id !== onlyId : false"
    >
      <component
        v-for="tabField in tab.fields"
        :key="tabField.id"
        :is="getComponent(tabField)"
        :field="tabField"
      />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { Vue, Component, PropSync, Inject } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import { DataFormContext } from '../../data-form';
import { getControl } from '../utils';

@Component()
export default class Tab extends Vue {
  @PropSync('field') fieldCp;
  @Inject(DataFormContext.Item) Item;
  getComponent(field) {
    return getControl(field.type).LayoutComponent || this.Item;
  }

  @Inject('typeId') typeId;
  get onlyId() {
    if (this.typeId) {
      return this.fieldCp.widget.tabs.find(v => v.identifier === this.typeId)
        .id;
    } else {
      return '';
    }
  }

  activeKey = '';
  created() {
    if (this.onlyId) {
      this.activeKey = this.onlyId;
    } else {
      this.activeKey = this.fieldCp.widget.tabs[0]?.id;
    }
    this.handleChangeTab(this.activeKey);
  }

  handleChangeTab(tab) {
    this.fieldCp.current = tab;
  }

  /** @name form-item的显示与当前Tab相关 */
  get fieldShadow() {
    const tabs = cloneDeep(this.fieldCp.widget.tabs);
    const array = tabs.map(v => {
      let item = v;
      if (v.id !== this.fieldCp.current) {
        return {
          ...v,
          fields: [],
        };
      } else {
        return item;
      }
    });
    const tab = cloneDeep(this.fieldCp);
    tab.widget.tabs = array;
    return tab;
  }
}
</script>
<style lang="less">
@prefix: x-tab;
.@{prefix} {
  width: calc(100% + 30px);
  margin: 5px -15px 0;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 30px;
    right: 30px;
    z-index: 1;
    border-bottom: 1px dashed var(--border);
  }
  &::before {
    top: 0;
    bottom: auto;
  }
  &::after {
    border-width: 0 0 1px 0;
    top: auto;
    bottom: 0;
  }
  .ant-tabs-bar.ant-tabs-top-bar {
    margin: 10px 0;
    padding: 0 35px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
    height: 28px;
    line-height: 28px;
    margin-right: 40px;
  }
  .ant-tabs-tabpane {
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}
.@{prefix}--align-left .ant-tabs-nav-scroll {
  text-align: left;
}
.@{prefix}--align-center .ant-tabs-nav-scroll {
  text-align: center;
}
.@{prefix}--align-right .ant-tabs-nav-scroll {
  text-align: right;
}
.@{prefix}--card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 30px !important;
}
.@{prefix}--card .ant-tabs-card-bar .ant-tabs-tab {
  height: 26px !important;
  line-height: 26px !important;
  padding: 0 14px !important;
  margin: 0 6px 0 0 !important;
  border: 1px solid var(--border) !important;
  background: var(--drawer-menu-toggle) !important;
  border-bottom: none !important;

  &.ant-tabs-tab-active {
    background: var(--block-bg) !important;
    border-bottom: 1px solid var(--block-bg) !important;
  }
}

.@{prefix}--theme2,
.@{prefix}--theme3,
.@{prefix}--theme5 {
  .ant-tabs-ink-bar {
    visibility: hidden;
  }
}

.@{prefix}--theme2 {
  .ant-tabs-nav-scroll {
    margin-bottom: 2px;
  }
  .ant-tabs-nav {
    border-radius: 15px;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    margin-right: 20px !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.@{prefix}--theme3 {
  .ant-tabs-nav {
    margin: 0 0 6px;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    margin-right: 20px !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.@{prefix}--theme4 {
  .ant-tabs-nav-scroll {
    padding: 0 12px;
    border-bottom: 1px solid var(--border);
    margin-bottom: 2px;
  }
  .ant-tabs-ink-bar {
    bottom: -1px;
    height: 3px;
    border-radius: 11px 11px 0px 0px;
    overflow: hidden;
  }
}

.@{prefix}--theme5 {
  .ant-tabs-nav-scroll {
    border-bottom: 1px solid var(--border);
    margin-bottom: 2px;
  }
}
.@{prefix}--theme6 {
  .ant-tabs-nav-scroll {
    padding: 0 12px;
    border-bottom: 1px solid var(--border);
    margin-bottom: 2px;
  }
  .ant-tabs-tab {
    margin: 0 48px 0 0 !important;
    padding-bottom: 10px !important;
  }
  .ant-tabs-ink-bar {
    bottom: 0;
    height: 1px;
  }
}
</style>
