<template>
  <a-form-model-item
    v-if="checkVisible(field)"
    class="drag-item"
    :label-position="field.widget.labelPosition"
    :class="computedClass"
    :prop="field.pkId"
    :data-type="field.type"
    :rules="rules"
    @click.native.stop="selectField(field)"
  >
    <template slot="label">
      <x-icon v-if="field.widget.param" type="tc-icon-param" />
      <label
        v-if="!field.widget.hideTitle"
        :class="field.widget.required && 'ant-form-item-required'"
        >{{ label }}</label
      >
      <a-tooltip
        :overlayClassName="$style.tooltip"
        v-if="field.widget.description"
        :arrowPointAtCenter="true"
        placement="right"
      >
        <div
          v-html="field.widget.description"
          :class="$style.boxHtml"
          slot="title"
        ></div>
        <a-icon type="info-circle" :class="$style.tooltipIcon" />
      </a-tooltip>
    </template>
    <field-render
      :field="field"
      :updateField="updateSelectedField"
      :value="value"
      type="design"
    />
    <drag-action
      v-if="field.pkId === selectedField.pkId && !selectedField.widget.alone"
      @delete="deleteField(field)"
    />
  </a-form-model-item>
</template>
<script>
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import {
  ControlDesignContext,
  getControl,
  getControlMeta,
  getFieldTagClass,
} from './form/controls';
import DragAction from './form/controls/components/drag-action.vue';
import { FieldRender } from './form/render';
import { templateDesignModule } from '@/enum/store';

@Component({
  components: {
    DragAction,
    FieldRender,
  },
})
export default class FormDesignDragItem extends Vue {
  @Prop() selectedField;
  @Prop() selectField;
  @Prop() deleteField;
  @Prop({ required: true }) field;
  @Prop() value;
  @Prop() fieldTagMap;
  @templateDesignModule.Mutation updateSelectedField;

  @InjectReactive(ControlDesignContext.CheckVisible) checkVisible;

  @InjectReactive(ControlDesignContext.Root) root;

  get computedClass() {
    const { $style, field, fieldTagMap, selectedField } = this;
    return [
      $style.field,
      $style[`field-${field.type}`],
      $style['column-1'],
      {
        [$style.selected]: selectedField.pkId === field.pkId,
        [$style.actionOpen]: getControlMeta(field.type, 'actionOpen'),
        ...getFieldTagClass(field.pkId, fieldTagMap),
      },
    ];
  }

  get Control() {
    return getControl(this.field.type);
  }

  get label() {
    return this.field.name || this.$t(getControlMeta(this.field.type, 'label'));
  }

  get rules() {
    const widgetRules = getControlMeta(this.field.type, 'widgetRules');
    if (!widgetRules) return null;
    return Object.values(widgetRules(this.field)).map(fn => ({
      asyncValidator: () => Promise.resolve(fn(this.root.getField)),
    }));
  }
}
</script>
<style lang="less" module>
.field:global(.ant-form-item) {
  padding: 12px 15px;
  margin: 0;
  position: relative;
  border: 1px solid transparent;
  :global {
    .ant-form-item-label .x-editor--view:not(:only-child) {
      color: var(--font-color-secondary);
    }
  }
  :global(.ant-form-item-control),
  :global(.ant-form-item-control-wrapper),
  :global(.ant-form-item-children) {
    display: block;
    position: static;
  }
  :global(.ant-form-item-children) {
    min-height: 32px;
    white-space: pre-wrap;
  }
  :global(.ant-input),
  :global(.ant-select),
  :global(.ant-input-number),
  :global(.x-date-picker),
  :global(.ant-radio-inner),
  :global(.ant-checkbox-inner),
  :global(.x-modal-select-choosebtn),
  :global(.x-editor .ql-container),
  :global(.x-signature-button),
  :global(.x-cascader) {
    background-color: var(--dropdown-bg);
  }
  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: var(--primary);
  }
  :global(.x-date-picker) {
    display: block;
  }
  :global(.ant-checkbox-wrapper + .ant-checkbox-wrapper) {
    margin-left: 0;
  }
  &.field-input,
  &.field-int,
  &.field-float,
  &.field-double,
  &.field-fnName,
  &.field-fnIdentifier,
  &.field-date {
    > :global(.ant-form-item-control-wrapper) {
      max-width: 400px;
    }
  }
  &.selected {
    background-color: var(--primary-fade-10);
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -1px;
      top: -1px;
      bottom: -1px;
      border-right: 3px solid var(--primary);
    }
  }
  &:hover {
    border: 1px dashed var(--primary);
    cursor: move;
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
  &.actionOpen::after {
    content: none;
  }
  &[label-position='center'] :global(.ant-form-item-label) {
    width: 100%;
    text-align: center;
  }
  &[label-position='right'] :global(.ant-form-item-label) {
    width: 100%;
    text-align: right;
  }
  &.column-1 {
    width: 100%;
  }
}
.tooltipIcon {
  margin: 0 5px;
  z-index: 101;
  position: relative;
}
.tooltip {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  max-width: 250px;
}
.boxHtml {
  img {
    display: none;
  }
}
</style>
