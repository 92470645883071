<template>
  <div :class="$style.main">
    <div>
      <action-bar :class="$style.tab">
        <a-button
          v-for="(btn, index) in imageGroup"
          :key="index"
          :class="[$style.tabBtn, imageType === btn.pkId ? $style.active : '']"
          @click="imageType = btn.pkId"
          type="link"
          >{{
            baseImageGroup.some(item => item.pkId === btn.pkId)
              ? $t(btn.groupName)
              : btn.groupName
          }}</a-button
        >
      </action-bar>
      <div :class="$style.subTab">
        <div :class="$style.left">
          <a-input
            :class="[$style.input, focus ? $style.focus : '']"
            v-model="keyword"
            @focus="focus = true"
            @blur="focus = false"
            @pressEnter="refresh"
          >
            <x-icon type="search" slot="prefix" />
          </a-input>
          <div :class="$style.timeFilter">
            <span>{{ $t('dictionary.shareTime') }}：</span>
            <a-range-picker
              v-model="dateRange"
              @change="handleRangePickerChange"
              :separator="$t('common.label.to')"
              style="width: 260px"
            />
          </div>
          <div :class="$style.total">
            <span>{{ $t('dictionary.total') }}：</span>
            <span :class="$style.nums">{{ total }}</span>
            <span>{{ $t('gallery.label.Apicture') }}</span>
          </div>
        </div>
        <div :class="$style.right">
          <a-button
            v-if="!baseImageGroup.some(item => item.pkId === imageType)"
            icon="form"
            @click="editImageGroup"
            >{{ $t('gallery.actions.edit') }}</a-button
          >
          <a-button
            v-if="$p.action('ADD_IMG_GROUP')"
            icon="plus-circle"
            @click="addImageGroup"
            >{{ $t('gallery.actions.addGroup') }}</a-button
          >
          <a-button
            v-if="$p.action('RECYCLE-BIN')"
            icon="delete"
            @click="handleOpenRecyclingDrawer"
            >{{ $t('gallery.actions.recycling') }}</a-button
          >
        </div>
      </div>
    </div>
    <x-scroll-view
      :class="$style.imgList"
      :disabled="!hasMore"
      @scrolltolower="getData(false)"
      ref="imgList"
    >
      <template v-if="imgList.length > 0">
        <image-box
          v-for="item in imgList"
          :key="item.pkId"
          :class="$style.imageBox"
          :image-data="item"
          :img-group-options="imageGroup"
          @refresh="getData(true)"
        />
      </template>
      <empty-content v-else type="list" />
    </x-scroll-view>
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import ActionBar from '@/components/action-bar';
import ImageBox from './components/ImageBox';
import {
  createImgGroup,
  getCloudGroup,
  getImgByGroup,
} from '@/services/resource/cloud-img-group';
import CreateImageGroupModal from './components/CreateImageGroupModal';
import RecyclingDrawer from './components/RecyclingDrawer';
import EmptyContent from '@/components/empty-content/empty-content.vue';

@Component({
  components: {
    ActionBar,
    ImageBox,
    EmptyContent,
  },
})
export default class Main extends Vue {
  imageType = 'All'; // 图片分组类型
  // 固定存在的图片分组
  baseImageGroup = [
    {
      pkId: 'All',
      groupName: 'common.label.all',
      name: '全部',
    },
    {
      pkId: 'Elite',
      groupName: 'common.label.elite',
      name: '精品图库',
    },
    {
      pkId: 'HEAD',
      groupName: 'common.label.head',
      name: '头像',
    },
    {
      pkId: 'LOGO',
      groupName: 'common.label.logo',
      name: 'Logo',
    },
    {
      pkId: 'FILE',
      groupName: 'common.label.galleryFile',
      name: '文件图库',
    },
  ];
  fetchImageGroup = []; // 获取的图片分组

  baseButtons = [
    {
      icon: 'form',
      text: 'gallery.actions.edit',
      name: '修改分组',
      onClick: () => this.editImageGroup(),
    },
    {
      icon: 'plus-circle',
      text: 'gallery.actions.addGroup',
      name: '新建图库分组',
      onClick: () => this.addImageGroup(),
    },
    {
      icon: 'delete',
      text: 'gallery.actions.recycling',
      name: this.$t('gallery.actions.recycling'),
      onClick: () => this.handleOpenRecyclingDrawer(),
    },
  ];

  get imageGroup() {
    return [...this.baseImageGroup, ...this.fetchImageGroup];
  }

  dateRange = [];
  startTimestamp = '';
  endTimestamp = '';
  imgList = [];
  loading = false;
  scrollLoading = false;
  current = 1;
  keyword = '';
  hasMore = true;
  focus = false;
  total = 0; // 图片总数

  created() {
    this.getImgGroup();
  }

  /**
   * 加载数据
   * @param refresh 是否来自刷新
   * @returns {Promise<void>}
   */
  async getData(refresh = false) {
    if (this.scrollLoading) return;
    this.scrollLoading = true;
    if (refresh) {
      this.loading = true;
      this.current = 1;
    }
    let data = {
      size: 50,
      keyword: this.keyword,
      skip: 50 * (this.current - 1),
      filterGroup: {
        logic: 'AND',
        group:
          this.startTimestamp && this.endTimestamp
            ? [
                {
                  compare: 'GE',
                  field: 'createDate',
                  value: this.startTimestamp,
                },
                {
                  compare: 'LE',
                  field: 'createDate',
                  value: this.endTimestamp,
                },
              ]
            : [],
      },
      sorters: [
        {
          field: 'createDate',
          order: 'DESC',
        },
      ],
    };
    data.filterGroup.group.push(
      ...[
        {
          compare: 'EQ',
          field: 'deleted',
          value: 0,
        },
        {
          compare: 'EQ',
          field: 'checkStatus',
          value: 'PASSED',
        },
      ],
    );

    switch (this.imageType) {
      case 'All':
        break;
      case 'Elite':
        data.filterGroup.group.push({
          compare: 'EQ',
          field: 'elite',
          value: 1,
        });
        break;
      default:
        data.filterGroup.group.push({
          compare: 'EQ',
          field: 'idxImgGroup',
          value: this.imageType,
        });
        break;
    }
    const res = await getImgByGroup(data);
    this.imgList = refresh ? res.data : [...this.imgList, ...res.data];
    this.total = res.total;
    this.scrollLoading = false;
    this.loading = false;
    if (res.data.length) {
      this.current += 1;
    } else {
      this.hasMore = false;
    }
  }

  /**
   * 获取所有分组
   * @returns {Promise<void>}
   */
  async getImgGroup() {
    const res = await getCloudGroup();
    this.fetchImageGroup = res.filter(item => {
      return !['HEAD', 'LOGO', 'FILE'].includes(item.pkId);
    });
  }

  /**
   * 处理日期范围数据变动
   * @param value
   */
  handleRangePickerChange(range) {
    if (range.length) {
      this.startTimestamp = range[0].startOf('day').valueOf();
      this.endTimestamp = range[1].endOf('day').valueOf();
    } else {
      this.startTimestamp = '';
      this.endTimestamp = '';
    }
    this.refresh();
  }

  /**
   * 打开回收站
   */
  handleOpenRecyclingDrawer() {
    RecyclingDrawer.createdDrawer(this.imageType);
  }

  @Watch('imageType')
  refresh() {
    this.current = 1;
    this.hasMore = true;
    this.getData(true);
  }

  /**
   * 新增图库分组
   * @returns {Promise<void>}
   */
  async addImageGroup() {
    const formData = await CreateImageGroupModal.createAddModal();
    await createImgGroup(formData);
    await this.getImgGroup();
    this.$message.success(this.$t('common.tips.add'));
  }

  /**
   * 修改图库分组
   * @returns {Promise<void>}
   */
  async editImageGroup() {
    const imgGroup = this.imageGroup.find(item => item.pkId === this.imageType);
    if (!imgGroup) {
      return;
    }
    const res = await CreateImageGroupModal.createEditModal(imgGroup);
    let msg = this.$t('common.tips.save');
    if (res === 'deleted') {
      this.imageType = 'All';
      msg = this.$t('common.tips.delete');
    }
    this.$message.success(msg);
    await this.getImgGroup();
    await this.refresh(true);
  }

  handleBlur(e) {
    e.target && e.target.blur();
  }
}
</script>
<style lang="less" module>
.tabBtn {
  color: var(--btn-color);
  min-width: unset;

  &.active {
    color: var(--primary);
  }
}

.main {
  .tab {
    padding: 10px 0;
  }

  .subTab {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left,
    .right {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      flex-shrink: 0;
    }
    .right {
      button {
        margin-left: 15px;
      }
    }
    .input {
      width: 110px;
      transition: width 0.5s linear;
      &.focus {
        width: 200px;
      }
    }

    .timeFilter {
      padding: 0 20px;
    }

    .total {
      .nums {
        color: var(--primary);
        padding-right: 0.5em;
      }
    }
  }

  .imgList {
    overflow-y: auto;
    padding: 10px;
    // TODO 需要计算imgList距离到浏览器顶部之间的距离
    height: calc(100vh - 250px);

    .imageBox {
      float: left;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
    }
  }
}
</style>
