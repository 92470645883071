<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item
      :label="`将${deviceIds.length}台设备添加到:`"
      prop="tenantId"
    >
      <compony-select v-model="form.tenantId" />
    </a-form-model-item>
    <p :class="$style.tipText">
      提示：已过滤已绑定企业的设备，绑定前请在三确认！
    </p>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { batchBindTenant } from '@/services/hat';
import ComponySelect from '@/views/digital-site/components/compony-select.vue';

@Component({ components: { ComponySelect } })
export default class BatchAllocation extends Vue {
  @Prop({ type: Array, default: () => [] }) deviceIds;
  mounted() {}
  form = {
    tenantId: undefined,
  };
  rules = {
    tenantId: [
      {
        required: true,
        message: '企业不能为空',
      },
    ],
  };
  async getValue() {
    await this.$refs.form.validate();
    try {
      const data = {
        deviceIds: this.deviceIds,
        tenantId: this.form.tenantId,
      };
      await batchBindTenant(data);
      this.$message.success('操作成功！');
      return true;
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.tipText {
  color: #999999;
  margin-top: 10px;
  font-size: 13px;
}
</style>
