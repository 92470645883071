<template>
  <div :class="$style.three">
    <div :class="$style.search">
      <a-input
        v-model="form.search"
        placeholder="名称/key/Secret"
        style="width: 240px"
        allowClear
        @change="val => changeSearch(val)"
      />
      <span :class="$style.tips">
        注意：如需新增三方开发者或授权新的连接器，请到三叠开放中心完成操作！
      </span>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableList"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="tableLoading"
      @change="handlePagination"
    >
      <template slot="Secret" slot-scope="text, record">
        <div :class="$style.secret">
          <div :class="$style.text" :title="text">
            <span v-if="record.checked">{{ text }}</span>
            <span v-else>***********</span>
          </div>
          <a-icon
            :type="record.checked ? 'eye' : 'eye-invisible'"
            :class="$style.icon"
            @click="changeChecked(record)"
          />
        </div>
      </template>
      <template slot="auth" slot-scope="text, record">
        <div style="color: var(--primary);cursor: pointer">
          <span @click="authList(record)">{{ text }}个</span>
        </div>
      </template>
      <template slot="operate" slot-scope="record">
        <span :class="$style.buttonGroups">
          <a :class="[$style.button]" @click.stop="authListItem(record)"
            >授权</a
          >
          <a :class="[$style.button]" @click.stop="copy(record)">
            复制
          </a>
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { threePushList } from '@/services/iot-platform/platformInterface';
import { createFormModal, createModal } from '@triascloud/x-components';
import AuthDeveloper from '@/views/connector/platform-manage/develop/api-config/auth-developer.vue';
import AuthModal from '@/views/connector/platform-manage/develop/api-config/auth-modal.vue';
import { debounce } from 'lodash-decorators';
import { clipboardWrite } from '@triascloud/utils';

@Component({
  components: {
    AuthDeveloper,
    AuthModal,
  },
})
export default class ThreeModel extends Vue {
  @Prop({ type: String, default: '' }) platformId;

  form = {
    search: '',
  };

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => {
          return index + 1;
        },
        title: '序号',
        align: 'center',
        width: 80,
      },
      {
        width: 200,
        dataIndex: 'name',
        title: '名称',
      },
      {
        width: 200,
        dataIndex: 'appKey',
        ellipsis: true,
        title: 'appKey',
      },
      {
        width: 200,
        dataIndex: 'secret',
        ellipsis: true,
        title: 'Secret',
        scopedSlots: { customRender: 'Secret' },
      },
      {
        dataIndex: 'authorizedCount',
        title: '已授权连接器',
        scopedSlots: { customRender: 'auth' },
      },
      {
        width: 200,
        dataIndex: 'remark',
        title: '备注说明',
      },
      {
        width: 100,
        dataIndex: '',
        title: '操作',
        scopedSlots: { customRender: 'operate' },
      },
    ];
  }

  tableList = [];
  tableLoading = false;
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };

  changeChecked(row) {
    row.checked = !row.checked;
  }

  @debounce(300)
  changeSearch(val) {
    this.form.search = val.target.value;
    this.pagination.current = 1;
    this.getList();
  }

  handlePagination(data) {
    this.pagination = data;
    this.getList();
  }

  mounted() {
    this.getList();
  }

  async getList() {
    const params = {
      platformId: this.platformId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.form.search) {
      params['keyword'] = this.form.search;
    }
    this.tableLoading = true;
    try {
      const { records, total } = await threePushList(params);
      this.tableList = records.map(v => {
        return {
          ...v,
          checked: false,
        };
      });
      this.tableLoading = false;
      this.pagination.total = total;
    } catch {
      this.tableLoading = false;
      return false;
    }
  }

  async authListItem(row) {
    const obj = {
      appKey: row.appKey,
      remark: row.remark,
      appName: row.name,
      platformId: this.platformId,
    };
    const res = await createFormModal(() => <AuthDeveloper authData={obj} />, {
      title: '开发者授权',
      width: 500,
    });
    if (res) {
      this.pagination.current = 1;
      await this.getList();
    }
  }

  async copy(row) {
    const obj = {
      name: row.name,
      appKey: row.appKey,
      secret: row.secret,
    };
    await clipboardWrite(JSON.stringify(obj));
    this.$message.success('复制成功');
  }

  authList(row) {
    const modal = createModal(
      () => (
        <AuthModal
          pkId={row.pkId}
          appKey={row.appKey}
          secret={row.secret}
          dataList={row.apiList}
          onClose={() => modal.handleClose()}
        />
      ),
      {
        title: '已授权列表',
        width: 800,
      },
    );
  }
}
</script>

<style lang="less" module>
.three {
  .search {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .tips {
      color: var(--warning);
      margin-left: 10px;
    }
  }

  .buttonGroups {
    display: flex;
    justify-content: flex-start;

    .red {
      color: var(--delete);
    }

    .button {
      width: 50px;
      position: relative;
    }
  }

  .secret {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      color: var(--primary);
      cursor: pointer;
    }
  }

  :global {
    .ant-table-thead > tr > th .ant-table-header-column {
      padding: 0;
    }
  }
}
</style>
