<script>
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
@Component()
export default class RenderBoolean extends Vue {
  @PropSync('item') syncItem;
  @PropSync('store') syncStore;
  @Prop() tooltip;
  @Prop() param;
  @Prop() checkDefaultValue;
  @Prop() schemeByType;
  @Prop({ type: String, default: 'id' }) useKey;

  render() {
    const item = this.syncItem;
    this.checkDefaultValue(item);
    return (
      <a-form-model-item
        class={this.$style.formItem}
        rules={[
          { required: item.required, message: '不能为空', trigger: 'blur' },
        ]}
        prop={item[this.useKey]}
      >
        <label slot="label" class={this.$style.label}>
          <span>{item.name}</span>
          {this.tooltip(item)}
          {this.param(item)}
        </label>
        <a-switch
          checked={this.syncStore.get(item[this.useKey]) ? true : false}
          onChange={target => {
            this.syncStore.set(item[this.useKey], target ? 1 : 0);
          }}
          checked-children={item.is}
          un-checked-children={item.not}
        />
      </a-form-model-item>
    );
  }
}
</script>
<style lang="less" module>
.formItem {
  :global {
    .ant-form-item-required {
      display: flex;
      align-items: center;
    }
  }
}
.label {
  display: flex;
  align-items: center;
}
</style>
