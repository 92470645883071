import request from '../request';

// /iot-platform/platformPanel
const serviceName = '/iot-platform/platformPanel';

// 新增编辑设备面板
export function addOrEditDevicePanel(data) {
  return request(`${serviceName}/saveOrUpdate`, {
    method: 'POST',
    body: data,
  });
}

// 获取设备面板信息
export function getDevicePanelInfo(data) {
  return request(`${serviceName}`, {
    method: 'GET',
    body: data,
  });
}

// 获取产品下的dp列表
export function getProductDP(data) {
  return request('/iot-platform/platform/datapoint/list', {
    method: 'GET',
    body: data,
  });
}

// 获取产品下介绍
export function getProductintroduction(data) {
  return request('/iot-platform/productDescription', {
    method: 'GET',
    body: data,
  });
}

// add update产品下介绍
export function addOrEditProductintroduction(data) {
  return request('/iot-platform/productDescription/saveOrUpdate', {
    method: 'POST',
    body: data,
  });
}

// 暂存产品介绍
export function storeProductintroduction(data) {
  return request('/iot-platform/productDescription/staging', {
    method: 'POST',
    body: data,
  });
}

// 预览属性
export function getPreviewAttribute(data) {
  return request('/iot-platform/platformDevice/previewAttribute', {
    method: 'GET',
    body: data,
  });
}

// 获取单个设备信息
export function getSingleDeviceInfo(deviceId) {
  return request(`/iot-platform/platformDevice/${deviceId}`, {
    method: 'GET',
  });
}

// 预览事件
export function getPreviewEvent(data) {
  return request('/iot-platform/platformDevice/previewEvent', {
    method: 'POST',
    body: data,
  });
}
