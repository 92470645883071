import request from '../request';

const basePath = '/tenant-center/tenant';

export const isBuild = tenantId =>
  request(`${basePath}/isBuild`, {
    body: { tenantId },
  });

/**
 * @name 获取企业列表
 * @returns Promise<{ any[] }>
 */
export function getCompanyList(current, size, keyWord = '') {
  return request(
    `${basePath}/enterprise/page?current=${current}&size=${size}&keyword=${keyWord}`,
    {},
  );
}
