<template>
  <a-form-model :model="form" :rules="rules" ref="form">
    <a-row :gutter="24" style="margin-bottom: 15px;">
      <a-col :span="12">
        <a-form-model-item
          :label="$t('ds.device.deviceCode')"
          prop="deviceCode"
        >
          <a-input
            :placeholder="$t('ds.device.inputCode')"
            v-model="form.deviceCode"
            :maxLength="56"
            :disabled="operationType === 'edit'"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item
          :label="$t('ds.device.deviceType')"
          prop="deviceGroup"
        >
          <a-select
            show-search
            allowClear
            v-model="form.deviceGroup"
            :placeholder="$t('ds.device.selectType')"
            option-filter-prop="children"
            :filter-option="true"
            :disabled="operationType === 'edit'"
          >
            <a-select-option
              :value="item.id"
              v-for="item in deviceTypes"
              :key="item.id"
            >
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="24" style="margin-bottom: 15px;">
      <a-col :span="12">
        <a-form-model-item
          :label="$t('ds.device.manufacturer')"
          prop="idxSupplierId"
        >
          <a-select
            show-search
            allowClear
            v-model="form.idxSupplierId"
            :placeholder="$t('ds.device.selectManufacturer')"
            option-filter-prop="children"
            :filter-option="true"
            :disabled="operationType === 'edit'"
          >
            <a-select-option
              :value="item.id"
              v-for="item in manufacturers"
              :key="item.id"
            >
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item
          :label="$t('enterprise.enterpriseText')"
          prop="tenantId"
        >
          <a-select
            show-search
            allowClear
            v-model="form.tenantId"
            :placeholder="$t('ds.device.selectEnterprise')"
            option-filter-prop="children"
            :filter-option="false"
            @search="v => selectSearch(v)"
          >
            <a-select-option
              :value="item.id"
              v-for="item in companyList"
              :key="item.id"
              :disabled="item.tenantStatus === 'DISABLE'"
            >
              {{
                item.tenantStatus === 'DISABLE'
                  ? `${item.value}(已停用)`
                  : item.value
              }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="24">
      <a-col :span="12">
        <a-form-model-item :label="$t('ds.device.deviceModel')" prop="type">
          <a-input
            :placeholder="$t('ds.device.inputMode')"
            v-model="form.type"
            :maxLength="56"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item :label="$t('common.label.remark')" prop="remark">
          <a-input
            :placeholder="$t('ds.device.inputRemark')"
            v-model="form.remark"
            :maxLength="104"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { reqGetCompany } from '@/services/hat';
import {
  getDeviceGroup,
  addDevice,
  editDevice,
} from '@/services/digital-site/device';
import { getManufacturerList } from '@/services/digital-site/manufacturer';
import { Debounce } from 'lodash-decorators';

@Component()
export default class DeviceForm extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) operationType;
  form = {
    deviceCode: '',
    deviceGroup: undefined,
    tenantId: undefined,
    idxSupplierId: undefined,
    deviceType: '', // 只有摄像头类型需要传值
    type: '',
    remark: '',
  };
  rules = {
    deviceCode: [
      {
        required: true,
        message: this.$t('ds.device.requiredCode'),
      },
    ],
    deviceGroup: [
      {
        required: true,
        message: this.$t('ds.device.requiredType'),
      },
    ],
    idxSupplierId: [
      {
        required: true,
        message: this.$t('ds.device.requiredManufacturer'),
      },
    ],
  };
  async mounted() {
    await this.initData();
    await this.getSelectList();
  }
  async initData() {
    if (this.operationType === 'edit') {
      this.initFormData(this.editData);
      if (
        !this.companyList.find(item => item.id === this.editData.idxTenantId)
      ) {
        this.companyList.unshift({
          id: this.editData.idxTenantId,
          value: this.editData.tenantName,
        });
      }
    }
  }
  initFormData(data) {
    this.form.deviceCode = data.deviceCode;
    this.form.deviceGroup = data.deviceType
      ? `${data.deviceGroup}.${data.deviceType}`
      : data.deviceGroup;
    this.form.tenantId = data.idxTenantId;
    this.form.idxSupplierId = data.idxSupplierId;
    this.form.type = data.model;
    this.form.remark = data.remark;
  }
  manufacturers = [];
  companyList = [];
  deviceTypes = [];
  async getSelectList() {
    const data = await Promise.all([
      this.getCompanyList(),
      getDeviceGroup(),
      getManufacturerList({ current: 1, size: 100 }),
    ]);
    this.deviceTypes = data[1].map(item => ({
      id: item.groupId,
      value: item.groupShortName,
    }));
    this.manufacturers = data[2].records.map(item => ({
      id: item.pkId,
      value: item.supplierName,
    }));
  }
  async getCompanyList(keyword = undefined) {
    const { data } = await reqGetCompany({ current: 1, size: 50, keyword });
    this.companyList = data.map(item => ({
      id: item.pkId,
      value: item.enterpriseName,
      tenantStatus: item.tenantStatus,
    }));
  }
  @Debounce(300)
  selectSearch(keyword) {
    this.getCompanyList(keyword);
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const flag = this.operationType === 'add';
            const postForm = { ...this.form };
            const array = postForm.deviceGroup.split('.');
            if (array.includes('VS')) {
              postForm.deviceGroup = array[0];
              postForm.deviceType = array[1];
            }
            flag
              ? await addDevice(postForm)
              : await editDevice({
                  deviceId: this.editData.pkId,
                  remark: this.form.remark,
                  tenantId: this.form.tenantId || '',
                  type: this.form.type,
                });
            this.$message.success(
              flag ? this.$t('common.tips.add') : this.$t('common.tips.edit'),
            );
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>
<style lang="less" module></style>
