<template>
  <div :class="$style.box">
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-form-model-item
        label="设备信息"
        prop="deviceName"
        style="margin-bottom: 4px;"
      >
        <table border="1">
          <tr>
            <th>设备码</th>
            <th>出厂日期</th>
            <th>颜色</th>
          </tr>
          <tr v-for="item in formData" :key="item.id">
            <td>
              <textarea
                :class="$style.textArea"
                placeholder="限32字符"
                v-model="item.deviceName"
              />
            </td>
            <td>
              <textarea
                :class="$style.textArea"
                placeholder="yyyy/mm/dd"
                v-model="item.factoryTime"
              />
            </td>
            <td style="position: relative;">
              <select :class="$style.select" v-model="item.color">
                <option value="白">白</option>
                <option value="红">红</option>
                <option value="黄">黄</option>
                <option value="橙">橙</option>
                <option value="蓝">蓝</option>
              </select>
              <div
                :class="$style.delete"
                v-if="item.id != 1"
                @click="deleteRow(item.id)"
              >
                <a-icon type="delete" />
              </div>
            </td>
          </tr>
        </table>
      </a-form-model-item>

      <a-form-model-item style="margin: 2px 0 4px;">
        <a href="javascript:;" style="margin-right: 10px;" @click="addTableList"
          >添加</a
        >
        <a href="javascript:;" @click="ExcelIn">Excel模板导入</a>
      </a-form-model-item>

      <a-form-model-item label="添加企业">
        <compony-select v-model="form.idxTenantId" style="width: 98%;" />
      </a-form-model-item>
      <div :class="$style.tips">
        <h2 style="font-size:14px">注意事项:</h2>
        <ul style="font-size:12px;color:#999999">
          <li v-for="(item, index) in tips" :key="index">
            {{ item.index }}、{{ item.des }}
          </li>
          <li>
            <span @click="show"
              >{{ tips.length + 1 }}、Excel模板单次限200条数据:</span
            >
            <a
              href="https://trias-aiot.oss-cn-shenzhen.aliyuncs.com/common/samrt_helmet_import.xlsx"
              target="tempiframe"
            >
              Excel导入模板</a
            >
            <iframe name="tempiframe" style="display: none"></iframe>
          </li>
        </ul>
      </div>
    </a-form-model>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import ComponySelect from '@/views/digital-site/components/compony-select.vue';

import Excel from './excel';
import _ from 'lodash';
@Component({ components: { ComponySelect } })
export default class deviceAdd extends Vue {
  @Prop({ type: Object, default: () => {} }) record;
  tipsTime = 0;
  form = {
    deviceName: '',
    factoryTime: '',
    color: '白',
    idxTenantId: undefined,
  };
  id = 1;
  formData = [
    {
      deviceName: '',
      factoryTime: this.$moment().format('YYYY/MM/DD'),
      color: '白',
      id: 1,
    },
  ];
  tips = [
    {
      index: 1,
      des: '设备码限32位字符长度;',
    },
    {
      index: 2,
      des: '设备码、出厂日期(yyyy/mm/dd) 导入后不允许修改;',
    },
    {
      index: 3,
      des: '颜色仅支持白/红/黄/橙/蓝 (新增请联系产品安排);',
    },
    {
      index: 4,
      des: '选择企业后会将导入成功的设备全部添加到选中企业。',
    },
    {
      index: 5,
      des: '仅允许选择已开通物联网模块的企业！',
    },
  ];
  rules = {
    deviceName: [
      {
        required: true,
        validator: this.validateGroup1,
        trigger: 'submit',
      },
    ],
  };
  show() {}
  mounted() {}
  validateGroup(rule, value, callback) {
    if (value.length === 0) {
      callback();
      callback(new Error('请选择编组！')); //TODO 对接下拉框接口后取消注释
    } else {
      callback();
      callback(new Error('请选择编组！'));
    }
  }
  validateGroup1(rule, value, callback) {
    this.formData.forEach(element => {
      var valiObj = { ...element };
      var tips = {
        deviceName: '设备码',
        factoryTime: '出厂日期',
        color: '颜色',
      };
      delete valiObj.id;
      for (let i in valiObj) {
        !valiObj[i] && callback(new Error(`${tips[i]}不能为空！`));
        if (i == 'deviceName') {
          valiObj[i].length > 32 &&
            callback(new Error(`${tips[i]}超出长度限制！`));
        }
        if (i == 'factoryTime') {
          let t = /^(\d{4})\/(1[0-2]|0?[1-9])\/(0?[1-9]|([1-2][0-9])|30|31)$/g.test(
            valiObj[i],
          );
          !t && callback(new Error(`出厂日期格式错误!`));
        }
      }
    });
    callback();
  }

  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const form = this.formData.map(item => {
              delete item.id;
              return { ...item };
            });
            resolve({
              form,
              idxTenantId: this.form.idxTenantId,
            });
          } catch (error) {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
  async ExcelIn() {
    this.$store.commit('hat/setExcelState', 1);
    const modal = createModal(
      h =>
        h(Excel, {
          props: { record: { name: '张三' } },
          on: {
            handleClose: () => {
              modal.handleClose();
            },
          },
        }),
      {
        width: 450,
        title: '导入操作',
      },
    );
  }

  addTableList = _.debounce(() => {
    this.id++;
    this.formData.length < 10 &&
      this.formData.push({
        deviceName: '',
        factoryTime: this.$moment().format('YYYY/MM/DD'),
        color: '白',
        id: this.id,
      });
    let nowTime = new Date().getTime();
    if (nowTime - this.tipsTime > 2000) {
      !(this.formData.length < 10) &&
        this.$message.warn('建议用Excel模板导入!');
      this.tipsTime = new Date().getTime();
    }
  }, 100);
  deleteRow(id) {
    const index = this.formData.findIndex(item => item.id == id);
    this.formData.splice(index, 1);
  }
}
</script>
<style lang="less" module>
.box {
  table {
    border-color: var(--primary);
    width: 98%;
    th {
      width: 33%;
      height: 40px;
      text-align: center;
    }

    td {
      text-align: center;
      .textArea {
        width: 100%;
        resize: none;
        border: none;
        outline: none;
        text-align: center;
        padding-top: 6px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .textArea::-webkit-input-placeholder {
        /* WebKit browsers，webkit内核浏览器 */
        color: #bfbfbf;
      }
      .textArea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #bfbfbf;
      }
      .textArea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #bfbfbf;
      }
      .textArea:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #bfbfbf;
      }
      .select {
        width: 90%;
        text-align: center;
        border: none;
        outline: none;
      }
    }

    .delete {
      width: 30px;
      text-align: center;
      position: absolute;
      top: 6px;
      right: -26px;
    }

    .delete:hover {
      color: var(--primary);
      cursor: pointer;
    }
  }
}
</style>
