<template>
  <div :class="$style.recycleWrap">
    <div :class="$style.recycleHeader">
      <x-input
        :class="$style.searchKeyWord"
        @pressEnter="dataList"
        @blur="dataList"
        :placeholder="$t('common.action.search')"
        v-model="searchData.keyword"
      >
        <a-icon slot="prefix" type="search" />
      </x-input>
      <span>{{ $t('common.label.deleteDate') }}</span>
      <a-range-picker
        v-model="dateRange"
        :class="$style.rangePicker"
        format="yyyy-MM-DD"
        @change="handleRangePickerChange"
      />
      <div>
        {{
          $t('solution.solutionsTotal', {
            value: list.length,
          })
        }}
      </div>
    </div>
    <div :class="$style.recycleList">
      <card-item v-for="item in list" :data="item" :key="item.pkId">
        <template>
          <a-tooltip :title="$t('common.action.preview')">
            <x-icon type="eye" @click="goDetail(item)" />
          </a-tooltip>

          <a-tooltip :title="$t('iconLibrary.library.undo')">
            <x-icon
              type="tc-icon-recovery"
              @click="recycle(item)"
              v-if="$p.action('UPDATE')"
            />
          </a-tooltip>
          <a-tooltip
            :title="$t('common.action.delete')"
            v-if="$p.action('DELETE')"
          >
            <x-icon
              type="tc-icon-delete"
              @click="deleteItem(item)"
              v-if="$p.action('DELETE')"
            />
          </a-tooltip>
        </template>
      </card-item>
      <!-- <card-item>
        <x-icon type="edit" slot="action" />
        <x-icon type="tc-icon-push" slot="action" />
        <x-icon type="tc-icon-recovery" slot="action" />
        <x-icon type="delete" slot="action" />
      </card-item>
      <card-item>
        <x-icon type="edit" slot="action" />
        <x-icon type="tc-icon-push" slot="action" />
        <x-icon type="tc-icon-recovery" slot="action" />
        <x-icon type="delete" slot="action" />
      </card-item> -->
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import CardItem from './card-item.vue';
import { createModuleUrl } from '@/utils';

import {
  recycle,
  deleteSolution,
  recover,
} from '@/services/template-manager/solution';
import moment from 'moment';

@Component({
  components: {
    CardItem,
  },
})
export default class RecycleBin extends Vue {
  searchData = {
    keyword: '',
    endTime: '',
    beginTime: '',
  };
  list = [];
  created() {
    this.dataList();
  }

  goDetail(data) {
    window.open(createModuleUrl('www') + '/solution/' + data.pkId);
  }

  async dataList() {
    this.list = await recycle(this.searchData);
  }

  handleRangePickerChange(value, range) {
    this.searchData.beginTime = range[0]
      ? moment(range[0])
          .startOf('day')
          .valueOf()
      : '';
    this.searchData.endTime = range[1]
      ? moment(range[1])
          .endOf('day')
          .valueOf()
      : '';
    this.dataList();
  }
  dateRange = '';

  async deleteItem(data) {
    await this.$confirm(this.$t('solution.tip3'));
    await deleteSolution(data.pkId);
    this.dataList();
  }

  async recycle(data) {
    await this.$confirm(this.$t('solution.tip4'));
    await recover(data.pkId);
    this.dataList();
    this.$emit('refresh');
  }
}
</script>
<style lang="less" module>
.searchKeyWord {
  width: 110px;
  margin-right: 40px;
}

.rangePicker {
  margin-left: 10px;
  margin-right: 20px;
}

.recycleHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--border);
}

.recycleList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 244px);
  gap: 20px 32px;
  margin: 20px;
}
</style>
