<template>
  <div :class="$style.recordWrap">
    <div :class="$style.descItem">
      <div :class="$style.itemTlt">{{ $t('inviteCode.codes') }}</div>
      <div :class="$style.desc">{{ data.code }}</div>
    </div>
    <div :class="$style.descItem">
      <div :class="$style.itemTlt">{{ $t('inviteCode.clone') }}</div>
      <div :class="$style.desc">{{ data.copyMember }}</div>
    </div>
    <div :class="$style.descItem">
      <div :class="$style.itemTlt">{{ $t('inviteCode.copyTime') }}</div>
      <div :class="$style.desc">
        {{
          data.copyTime ? $moment(data.copyTime).format('Y-MM-DD HH:mm') : ''
        }}
      </div>
    </div>
    <div :class="$style.descItem">
      <div :class="$style.itemTlt">{{ $t('iot.device.creator') }}</div>
      <div :class="$style.desc">{{ data.createName }}</div>
    </div>
    <div :class="$style.descItem">
      <div :class="$style.itemTlt">{{ $t('common.label.createTime') }}</div>
      <div :class="$style.desc">
        {{
          data.createTime
            ? $moment(data.createTime).format('Y-MM-DD HH:mm')
            : ''
        }}
      </div>
    </div>
    <div :class="useTable">
      <div :class="$style.tableHeader">
        <div :class="$style.tltItem">{{ $t('inviteCode.user') }}</div>
        <div :class="$style.tltItem">{{ $t('inviteCode.enterprise') }}</div>
        <div :class="$style.tltItem">{{ $t('inviteCode.usageTime') }}</div>
      </div>
      <div :class="$style.tableItem">
        <div :class="$style.list">
          <div :class="$style.listItem">{{ data.useMember }}</div>
          <div :class="$style.listItem">{{ data.useTenant }}</div>
          <div :class="$style.listItem">
            {{
              data.useTime ? $moment(data.useTime).format('Y-MM-DD HH:mm') : ''
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';

function createUseRecordModal(props) {
  return createModal(h => h(UseRecord, { props }), {
    title: globalVueI18n.translate('inviteCode.usageRecord'),
    width: 500,
  });
}
@Component()
export default class UseRecord extends Vue {
  @Prop({ type: Object, default: {} }) data;
  static createModal = createUseRecordModal;
}
</script>
<style lang="less" module>
.recordWrap {
}

.itemTlt {
  color: var(--font-info);
}

.desc {
  color: var(--font);
  margin-top: 15px;
}
.descItem {
  margin-top: 25px;
  &:first-child {
    margin-top: 0;
  }
}

.tableHeader {
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: var(--primary-fade-10);
  padding: 0 20px;
  margin-top: 30px;
  color: var(--font);

  .tltItem {
    flex: 1;
    text-align: left;
    &:first-child {
      flex: 0.5;
    }
    &:last-child {
      flex: 0.8;
    }
  }
}
.list {
  display: flex;
  flex-direction: row;

  padding: 0 20px;
  background: var(--table-row-stripe);

  .listItem {
    flex: 1;
    text-align: left;
    height: 45px;
    display: flex;
    align-items: center;
    color: var(--font);
    &:first-child {
      flex: 0.5;
    }
    &:last-child {
      flex: 0.8;
    }
  }
}
</style>
