<template>
  <div ref="PluginRelationCharts" />
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { crossStorageModule } from '@/enum/store';
import { globalVueI18n } from '@triascloud/i18n';

import ResizeObserver from 'resize-observer-polyfill';

@Component({
  components: {},
})
export default class PluginRelationCharts extends Vue {
  @Prop({ type: Object }) data;
  /** @type { ResizeObserver } */
  _ob = null;
  myChart = null;
  @crossStorageModule.State skin;

  get option() {
    const res = this.data && this.data.data ? this.data.data : [];
    return {
      legend: {
        show: true,
        data: globalVueI18n.translate('plugin.overview.associated'),
        top: 25,
        left: 5,
        itemWidth: 8,
        itemHeight: 8,
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },

      yAxis: {
        max: 'dataMax',
        // data:['0%', 100, 200,]
      },
      xAxis: [
        {
          boundaryGap: false,
          type: 'category',
          data: res.length ? res.map(item => item.tenantName) : [],
          inside: true,
          axisTick: {
            alignWithLabel: true,
          },
          showMaxLabel: true,
          axisLabel: {
            width: 170,
            rotate: 45,
            formatter: function(value) {
              if (value.length >= 5) {
                let v = value.slice(0, 5) + '...';
                return v;
              } else {
                return value;
              }
            },
          },
        },
      ],
      series: [
        {
          name: globalVueI18n.translate('plugin.overview.associated'),
          type: 'line',
          showSymbol: false,
          data: res.map(item => item.associateCount),
          areaStyle: {
            color: 'rgba(255, 169, 40, 1)',
          },
          color: ['#FFA928'],
          stack: 'one',
          barWidth: 30,
        },
      ],
    };
  }
  mounted() {
    this._ob = new ResizeObserver(this.chartResize);
    this._ob.observe(this.$refs.PluginRelationCharts);
    this.initChart();
  }

  chartResize() {
    this.myChart.resize();
  }

  @Watch('option')
  @Watch('skin', { deep: true })
  initChart() {
    const chart = this.$refs.PluginRelationCharts;
    this.myChart = echarts.init(chart);
    this.myChart.setOption(this.option);
  }
}
</script>
<style lang="less" module></style>
