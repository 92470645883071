<template>
  <x-data-table
    ref="table"
    base-path="/template-manager/kit/list"
    :columns="columns"
    :service="service"
    :class="$style.table"
    row-key="pkId"
    :selected-row-keys.sync="selectedRowKeys"
  >
    <template slot="action">
      <a-button @click="addKitMethod" v-if="$p.action('CREATE')"
        >新建套件</a-button
      >
      <a-button
        type="primary"
        @click="changeInstallTimes"
        v-if="$p.action('UPDATE')"
        >批量修改安装次数</a-button
      >
    </template>
  </x-data-table>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { DataSourceService } from '@triascloud/services';
import {
  kitList,
  installCount,
  removeKit,
  kitChangeState,
  hotKitChangeState,
} from '@/services/template-manager/kit';
import { kitGroupList } from '@/services/template-manager/kitGroup';
import KitTemplate from './kit-template.vue';
import PushHotModal from './pushHotModal.vue';

import InstallTimesSetting from './install-times-setting.vue';
import { kitStateText, kitState, kitShelvesState } from '@/enum/kit';

@Component()
export default class KitTypeList extends Vue {
  selectedRowKeys = [];

  get columns() {
    return [
      {
        dataIndex: 'kitName',
        title: '套件名称',
      },
      {
        dataIndex: 'groupName',
        title: '分类',
      },
      {
        dataIndex: 'state',
        title: '状态',
        customRender: status => this.$t(kitStateText[status]),
      },
      {
        dataIndex: 'installCount',
        title: '安装次数',
      },
      {
        title: this.$t('common.action.operating'),
        key: 'actions',
        customRender: record => {
          return [
            <span
              class={this.$style.marginSpan}
              v-if={this.$p.action('CREATE')}
              onClick={e => {
                e.stopPropagation();
                this.openDetails(record);
              }}
            >
              编辑
            </span>,
            <span
              class={this.$style.marginSpan}
              v-if={this.$p.action('DELETE')}
              onClick={e => {
                e.stopPropagation();
                this.deleteKit(record);
              }}
            >
              删除
            </span>,
            <span
              class={this.$style.marginSpan}
              v-if={this.$p.action('UPDATE')}
              onClick={e => {
                e.stopPropagation();
                this.changeState(record);
              }}
            >
              {record.state === kitState.shelves ? '下架' : '上架'}
            </span>,
            <span
              class={this.$style.marginSpan}
              v-show={record.state === kitState.shelves}
              v-if={this.$p.action('UPDATE')}
              onClick={e => {
                e.stopPropagation();
                this.hotKitChangeState(record);
              }}
            >
              {record.popular ? '下架热门' : '上架热门'}
            </span>,
          ];
        },
      },
    ];
  }

  async kitGroupData() {
    await kitGroupList();
  }

  async deleteKit(res) {
    await this.$confirm(this.$t('feedback.deleteConfirm'));
    await removeKit(res.pkId);
    this.$message.success(this.$t('common.tips.operationSuccess'));
    this.$refs.table.loadData();
  }

  async hotKitChangeState(res) {
    if (res.popular) {
      await this.$confirm(
        this.$t('market.template.openConfirm', {
          value: '热门套件',
        }),
      );
      //下架热门
      await hotKitChangeState({
        pkId: res.pkId,
        state: kitShelvesState.SOLD_OUT,
        popular: false,
      });
    } else {
      await PushHotModal.create({ data: res });
    }
    res.popular = !res.popular;
    this.$message.success(this.$t('common.tips.operationSuccess'));
  }

  async changeState(res) {
    if (res.state === kitState.shelves) {
      await this.$confirm(
        this.$t('market.template.downKit', {
          value: '套件',
        }),
      );
      //下架
      await kitChangeState({ pkId: res.pkId, state: kitShelvesState.SOLD_OUT });
    } else {
      await this.$confirm(
        this.$t('market.template.openConfirm', {
          value: '套件',
        }),
      );
      await kitChangeState({ pkId: res.pkId, state: kitShelvesState.PUT_AWAY });
    }
    this.$message.success(this.$t('common.tips.operationSuccess'));
    this.$refs.table.loadData();
  }

  async changeInstallTimes() {
    if (this.selectedRowKeys.length) {
      const data = await InstallTimesSetting.create({
        total: this.selectedRowKeys.length,
      });
      await installCount([this.selectedRowKeys], data);
    } else {
      this.$message.warn('请先选择套件');
    }
  }

  async openDetails(res) {
    await KitTemplate.createModal({
      id: res.pkId,
    });
    this.$refs.table.loadData();
  }

  async addKitMethod() {
    await KitTemplate.createModal();
    this.$refs.table.loadData();
  }

  get service() {
    const service = new DataSourceService('/template-manager/kit/list');
    service.getList = report =>
      kitList({
        query: {
          size: report.size,
          current: report.skip / report.size + 1,
        },
        search: report.keyword,
      }).then(res => ({ data: res?.records, total: res?.records.length }));
    return service;
  }
}
</script>
<style lang="less" module>
.table {
  height: calc(100% - 60px);
  :global(.x-data-table--action .ant-btn-primary) {
    color: #fff;
    margin-left: 10px;
  }
}

.marginSpan {
  color: var(--primary);
  margin-left: 5px;
  cursor: pointer;
}
</style>
