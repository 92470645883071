<template>
  <x-oss-image v-if="isImage" class="x-file-icon" size="32" :oss-path="url" />
  <x-icon v-else class="x-file-icon" :type="icon" />
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class FileIcon extends Vue {
  @Prop({ type: String, default: '' }) url;
  @Prop({ type: Boolean, default: true }) image;
  get icon() {
    switch (this.ext) {
      case 'doc':
      case 'docx':
        return 'tc-icon-file-word';
      case 'xlsx':
      case 'xls':
        return 'tc-icon-file-excel';
      case 'ppt':
        return 'tc-icon-file-ppt';
      case 'txt':
        return 'tc-icon-file-txt';
      case 'rar':
        return 'tc-icon-file-rar';
      case 'zip':
        return 'tc-icon-file-rar';
      case 'jpg':
      case 'jpeg':
        return 'tc-icon-file-jpg';
      case 'png':
        return 'tc-icon-file-rar';
      case 'pdf':
        return 'tc-icon-file-pdf';
      default:
        return 'tc-icon-file-unknown';
    }
  }

  get isImage() {
    if (!this.image) return false;
    return ['png', 'jpg', 'jpeg', 'gif', 'svg', 'ico', 'bmp', 'webp'].includes(
      this.ext,
    );
  }

  get ext() {
    const result = /\.([^.]+)$/.exec(this.url);
    return result ? result[1].toLowerCase() : null;
  }
}
</script>
<style lang="less">
.x-file-icon {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  &.x-icon.anticon {
    font-size: 48px;
    overflow: hidden;
    > svg {
      margin: -8px -8px;
    }
  }
}
</style>
