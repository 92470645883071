<template>
  <div>
    <field-config-config-desc :desc="$t('controls.desc.currentDataSet')" />
    <field-config-title v-bind="fieldProps" />
    <field-config-description v-bind="fieldProps" />
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import FieldConfigTitle from '../components/field-config/title.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';

@Component({
  components: {
    FieldConfigTitle,
    FieldConfigDescription,
    FieldConfigConfigDesc,
  },
})
export default class CurrentDataSetConfig extends Vue {
  @Prop() field;
  @Prop() updateField;

  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
    };
  }
}
</script>
