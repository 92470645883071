<template>
  <div :class="$style.index" v-if="data">
    <div :class="$style.item">
      <div :class="$style.count">
        {{
          (data.amount / 100 >= 10000
            ? data.amount / 1000000
            : data.amount / 100
          ).toFixed(2)
        }}
        <span>{{
          data.amount / 100 >= 10000
            ? $t('inviteCode.unit')
            : $t('inviteCode.Yuan')
        }}</span>
      </div>
      <div :class="$style.tlt">{{ $t('solution.purchaseAmount') }}</div>
      <div :class="$style.bot">
        <span
          >{{ $t('solution.weekon') }}:
          <a-icon
            type="caret-up"
            :style="{ color: 'green' }"
            v-if="data.weekCompared > 0"
          />
          <a-icon type="caret-down" :style="{ color: '#ff5a20' }" v-else />
          {{ data.weekCompared }}%
        </span>
        <span>
          {{ $t('solution.dayTo') }}:
          <a-icon
            type="caret-up"
            :style="{ color: 'green' }"
            v-if="data.dayCompared > 0"
          />
          <a-icon type="caret-down" :style="{ color: '#ff5a20' }" v-else />
          {{ data.dayCompared }}%
        </span>
      </div>
    </div>
    <div :class="$style.item">
      <div :class="$style.count">{{ data.previewCount }}</div>
      <div :class="$style.tlt">{{ $t('solution.totalPreviews') }}</div>
      <img :class="$style.img" src="@/assets/img/totalPreviews.png" />
    </div>
    <div :class="$style.item">
      <div :class="$style.count">{{ data.buyCount }}</div>
      <div :class="$style.tlt">{{ $t('solution.Purchases') }}</div>
      <img :class="$style.img" src="@/assets/img/buyCount.png" />
    </div>
    <div :class="$style.item">
      <div :class="$style.count">
        {{ data.previewInstallationConversionRates }}%
      </div>
      <div :class="$style.tlt">{{ $t('solution.conversionRate') }}</div>
      <img :class="$style.item_right" src="@/assets/img/privewRank.png" />
    </div>
    <div :class="$style.item">
      <div :class="$style.count">{{ data.installEnterpriseCount }}</div>
      <div :class="$style.tlt">{{ $t('solution.solutionInstaller') }}</div>
      <a-button type="link" @click="installDetails">{{
        $t('common.label.detail')
      }}</a-button>
      <x-custom-icon
        :class="[$style.card__icon, $style.item_right]"
        type="1381795643223031810.28804060"
      />
    </div>
    <div :class="$style.item">
      <div :class="$style.count">{{ data.buildEnterpriseCount }}</div>
      <div :class="$style.tlt">{{ $t('solution.enterprise') }}</div>
      <a-button type="link" @click="companyCount">{{
        $t('common.label.detail')
      }}</a-button>
      <x-custom-icon
        :class="[$style.card__icon, $style.item_right]"
        type="1381795643223031810.22724474"
      />
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import ManageInstallDetails from './manage-install-details.vue';
import ManageCompanyCount from './manage-company-count.vue';
import { createModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';

@Component({
  components: {},
})
export default class ManageHeader extends Vue {
  @Prop({ type: Object, default: () => null }) data;
  async installDetails() {
    await createModal(
      () => <ManageInstallDetails data={this.data.installEnterpriseDetail} />,
      {
        title: globalVueI18n.translate('solution.installationTlt'),
        width: 600,
        bodyStyle: {
          padding: 0,
          overflow: 'hidden',
        },
      },
    );
  }
  async companyCount() {
    await createModal(
      () => <ManageCompanyCount data={this.data.buildEnterpriseDetailCO} />,
      {
        title: globalVueI18n.translate('solution.coConstruction'),
        width: 600,
        bodyStyle: {
          padding: 0,
          overflow: 'hidden',
        },
      },
    );
  }
}
</script>
<style lang="less" module>
.index {
  padding: 30px 20px;
  display: flex;
  border-radius: 8px;
  padding-bottom: 10px;
  flex-wrap: wrap;
}
.item {
  box-shadow: 0px 0px 5px 0px var(--shadow);
  min-height: 116px;
  padding: 0 20px;
  position: relative;
  //   margin: 0 10px;
  min-width: calc(16.66% - 20px);
  margin-right: 20px;
  margin-bottom: 20px;
  &:first-child {
    margin-left: 0px;
  }
  &:last-child {
    margin-right: 0;
  }

  .count {
    font-size: 32px;
    color: var(--danger);
    line-height: 32px;
    margin-top: 14px;
    span {
      font-size: 16px;
    }
  }
  &:nth-child(2) {
    .count {
      color: #4771ff;
    }
  }

  &:nth-child(3) {
    .count {
      color: #41c400;
    }
  }
  &:nth-child(4) {
    .count {
      color: #f99604;
    }
    button {
      color: #f99604;
    }
  }

  &:nth-child(5) {
    .count {
      color: #f99604;
    }
    button {
      color: #f99604;
    }
  }

  &:nth-child(6) {
    .count {
      color: #ff6231;
    }
    button {
      color: #ff6231;
    }
  }
  .tlt {
    font-size: 14px;
    line-height: 14px;
    margin-top: 10px;
  }
  .img {
    margin-top: 15px;
    width: 100%;
  }
  .bot {
    font-size: 14px;
    line-height: 14px;
    margin-top: 16px;
    color: var(--font-info);
    display: flex;
    justify-content: space-between;
  }
  button {
    padding: 0;
    text-align: left;
  }
  &:last-child {
    .card__icon {
      background-color: #ff6231;
    }
  }
}
.item_right {
  position: absolute;
  right: 20px;
  top: 25px;
}
.card__icon {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  color: #fff;
  background-color: #f99604;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}
.item_icon {
  background-color: var(--warning);
  i {
    font-size: 36px;
  }
}
@media screen and (max-width: 1920px) {
  .item {
    min-width: 256px;
  }
}
</style>
