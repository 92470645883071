// @ts-check
import { get, has, noop, set } from 'lodash';
import Vue from 'vue';
import { ensureArray, match } from '@triascloud/utils';
import { vuePathSet } from '../../utils';

/** @typedef { Record<string, any> } ReactiveStoreData */
/** @typedef { string | string[] } ReactiveStorePath */
/** @typedef { (data: ReactiveStoreData, path: string[]) => void } ReactiveStoreListener */
/** @typedef { { path: string[], listeners: Set<ReactiveStoreListener> }} ReactiveStorePathMatcher */

/**
 * @template { {} } T
 * @member { Record<string, T> } data
 */
export default class ReactiveStore {
  /** @param { Record<string, T> } initData */
  constructor(initData) {
    this.data = Vue.observable(initData || {});
  }

  /**
   * @param { ReactiveStorePath } path
   */
  get(path) {
    return get(this.data, path);
  }

  /**
   * @param { ReactiveStorePath } path
   */
  getArray(path) {
    return getArrayValue(this.data, path);
  }

  /**
   * @param { ReactiveStorePath } path
   * @param { any } value
   * @param { boolean } trigger
   */
  set(path, value, trigger = true) {
    const pathArr = ensurePath(path);
    if (!has(this.data, pathArr)) {
      vuePathSet(this.data, pathArr, value);
    } else {
      const oldValue = this.get(pathArr);
      // 避免不必要的计算
      if (oldValue === value) return;
      set(this.data, pathArr, value);
    }
    if (trigger) {
      this.trigger(pathArr);
    }
  }

  /**
   * @name 触发监听器
   * @param { ReactiveStorePath } path 触发路径
   */
  trigger(path) {
    const pathArr = ensurePath(path);
    if (!this.#checkTriggerCircularReference(pathArr)) return;
    pathArr.reduce((prevPath, part) => {
      const currentPath = prevPath + part;
      // 先触发静态监听
      execListeners(this.#staticListeners[currentPath], this.data, pathArr);
      // 然后触发动态监听
      const dynamicMatchers = this.#dynamicListeners[currentPath];
      if (dynamicMatchers) {
        dynamicMatchers.forEach(
          matcher =>
            matchDynamicPath(matcher.path, pathArr) &&
            execListeners(matcher.listeners, this.data, pathArr),
        );
      }
      return currentPath + '.';
    }, '');
    // 最后触发全局监听
    execListeners(this.#globalListeners, this.data, pathArr);
  }

  /** @type { Map<string, number> | null } */
  // @ts-ignore
  #changeCountMap = null;
  /**
   * @name 检查trigger是否存在循环调用
   * @param { string[] } pathArr
   */
  // @ts-ignore
  #checkTriggerCircularReference(pathArr) {
    if (!this.#changeCountMap) {
      this.#changeCountMap = new Map();
      requestAnimationFrame(() => (this.#changeCountMap = null));
    }
    const pathString = pathArr.join('.');
    const changeCount = this.#changeCountMap.get(pathString) || 0;
    if (changeCount >= 1000) {
      /** @todo 已知问题：在一帧内对同一个属性多次set会导致此处报错 */
      // eslint-disable-next-line no-console
      console.error(
        `ReactiveStore: [Trigger Circular Reference] ${pathString}`,
        this.#changeCountMap,
      );
      return false;
    }
    this.#changeCountMap.set(pathString, changeCount + 1);
    return true;
  }

  /**
   * @name 添加监听器
   * @param { string | string[] } listenerPath 监听路径，可以一次监听多个路径
   * *          监听data上所有属性的变化
   * foo        监听data上foo属性的变化
   * foo.bar    监听data上foo属性与foo.bar属性的变化
   * foo.1.bar  监听data上foo数组第一项bar属性的变化
   * foo.*.bar  监听data上foo数组中所有元素bar属性的变化
   * @param { ReactiveStoreListener } callback
   */
  watch(listenerPath, callback) {
    const unwatchList = ensureArray(listenerPath).map(path =>
      this.#watch(path, callback),
    );
    if (!unwatchList.length) return noop;
    return () => unwatchList.forEach(unwatch => unwatch());
  }

  /**
   * @name 全局监听器
   * @description 任何数据的变更都会触发
   */
  // @ts-ignore
  #globalListeners = new Set();
  /**
   * @name 静态路径监听器
   * @type { Record<string, Set<ReactiveStoreListener>> }
   * @description 监听静态路径的数据变化
   */
  // @ts-ignore
  #staticListeners = {};
  /**
   * @name 动态路径监听器
   * @type { Record<string, Map<string, ReactiveStorePathMatcher>> }
   * @description 第一层key是动态路径中的静态前缀部分，为了提高匹配效率
   * @example
   * {
   *   "dynamic": {
   *     "dynamic.*.title": {
   *       "path": ["static", "*", "title"],
   *       "listeners": [],
   *     },
   *   },
   * }
   */
  // @ts-ignore
  #dynamicListeners = {};

  /**
   *
   * @param { string | string } path
   * @param { ReactiveStoreListener } listener
   */
  // @ts-ignore
  #watch = (path, listener) => {
    if (!path) return noop;
    if (path === '*') {
      this.#globalListeners.add(listener);
      return () => this.#globalListeners.delete(listener);
    } else if (path.includes('*')) {
      return this.#addDynamicListener(path, listener);
    } else {
      if (!this.#staticListeners[path]) {
        this.#staticListeners[path] = new Set();
      }
      this.#staticListeners[path].add(listener);
      return () => this.#staticListeners[path].delete(listener);
    }
  };
  /**
   * @param { string } path
   * @param { ReactiveStoreListener } listener
   */
  // @ts-ignore
  #addDynamicListener = (path, listener) => {
    // 取出*号之前的部分
    const staticPath = path.split('*')[0].replace(/\.$/, '');
    if (!this.#dynamicListeners[staticPath]) {
      this.#dynamicListeners[staticPath] = new Map();
    }
    if (!this.#dynamicListeners[staticPath].has(path)) {
      this.#dynamicListeners[staticPath].set(path, {
        path: path.split('.').filter(part => part),
        listeners: new Set(),
      });
    }
    this.#dynamicListeners[staticPath].get(path)?.listeners.add(listener);
    return () =>
      this.#dynamicListeners[staticPath].get(path)?.listeners.delete(listener);
  };

  debug() {
    return [
      this.#globalListeners,
      this.#staticListeners,
      this.#dynamicListeners,
    ];
  }
}

/**
 *
 * @param { string[] } matcherPath ['foo', '*', 'bar', '*', 'baz']
 * @param { string[] } targetPath ['foo', 1, 'bar'] or ['foo', 1, 'bar', 2, 'baz'] or ['foo', 1, 'bar', 2, 'baz', 3, 'test']
 */
function matchDynamicPath(matcherPath, targetPath) {
  const [matcher, target] =
    matcherPath.length < targetPath.length
      ? [matcherPath, targetPath]
      : [targetPath, matcherPath];
  return matcher.every(
    (part, index) => matcherPath[index] === '*' || target[index] === part,
  );
}

/** @type { (path: string | string[]) => string[] } */
function ensurePath(path) {
  return Array.isArray(path) ? path : path.split('.');
}

/** @type { (listeners: Set<Function> | undefined, ...payload: any[]) => void } */
function execListeners(listeners, ...params) {
  if (!listeners) return;
  listeners.forEach(listener => listener.apply(null, params));
}

/**
 * @name 根据path获取对象中的值，遇到数组则遍历返回
 * @description 如果path中存在数字，则会作为数组的key处理
 * @param { any } data
 * @param { ReactiveStorePath } path
 * @example
 * getArrayValue({ test1: [{ test2: 'test3'}] }, 'test1.test2') => ['test3']
 * @return { any }
 */
export function getArrayValue(data, path) {
  if (!data || !path) return null;
  const pathArr = ensurePath(path);
  let result = data;
  let index = 0;
  for (const part of pathArr) {
    if (part === '*') {
      index++;
      continue;
    }
    if (Array.isArray(result) && !match.isPureNumber(part)) {
      return result.map(item => getArrayValue(item, pathArr.slice(index)));
    } else if (result) {
      result = result[part];
    }
    index++;
  }
  return result == null ? null : result;
}
