import request from '../request';

const serviceName = '/iot-smart-helmet/system/detection';

/** @name 获取记录列表 */
export function getRecordList(data) {
  return request(`${serviceName}/page`, {
    method: 'POST',
    body: data,
  });
}

/** @name 删除设备检测记录 */
export function deleteCheckRecord(id) {
  return request(`${serviceName}/delete/${id}`, {
    method: 'DELETE',
  });
}
