<template>
  <div :class="$style.productSelector">
    <a-select
      placeholder="请选择产品"
      :value="value"
      @change="selectChange"
      allowClear
      :filter-option="false"
      :getPopupContainer="triggerNode => triggerNode.parentNode"
    >
      <a-select-option
        :value="item.pkId"
        v-for="item in products"
        :key="item.pkId"
        >{{ item.name }}</a-select-option
      >
    </a-select>
    <a-button type="link" @click="manageProducts" size="small">
      管理
    </a-button>
    <a-modal
      v-model="showClassify"
      :width="500"
      title="管理分类"
      :footer="null"
    >
      <productForm v-if="showClassify" :platformId="id" @close="handleClose" />
    </a-modal>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { getProducts } from '@/services/iot-platform/dpManager';
import productForm from './product-manage.vue';
@Component({
  components: { productForm },
})
export default class productSelector extends Vue {
  @Prop({ type: String, default: '' }) id;
  @Prop({ type: String, default: undefined }) value;

  products = [];
  created() {
    this.getProducts();
  }
  async getProducts() {
    this.products = await getProducts(this.id);
  }
  selectChange(val) {
    this.$emit('input', val);
    this.$emit('change', val);
  }
  showClassify = false;
  manageProducts() {
    this.showClassify = true;
  }
  handleClose() {
    this.getProducts();
    this.showClassify = false;
  }
}
</script>
<style lang="less" module>
//@import url(); 引入公共css类
.productSelector {
  display: flex;
}
</style>
