<script>
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import {
  PLACEHOLDER_IN,
  PLACEHOLDER_OUT,
  variableReset,
} from './components/utils';
import VariableModel from './components/variable-model';
import { eachDelParent } from './utils';

@Component({
  components: {
    VariableModel,
  },
})
export default class ApiTableCopy extends Vue {
  @PropSync('tableData') syncTableData;
  @Prop({ type: String, default: 'req' }) tableType;
  @Prop({ type: String, default: '' }) platformId;
  @PropSync('store') syncStore;

  get isRes() {
    return this.tableType === 'res';
  }

  get RefName() {
    return this.tableType + 'Ref';
  }

  hideParam(item) {
    item.open = !item.open;
  }
  changeEdit(item, flag) {
    if (item.val === PLACEHOLDER_IN || item.val === PLACEHOLDER_OUT) {
      item.val = '';
    }
    if (flag === 1) {
      if (item.type !== 'dataPoint') {
        item.isEdit = true;
      }
    } else {
      item.isEdit = true;
    }
  }
  deleteParam(row, refType) {
    // 1、如果table组件是出参，则需要考虑store结构的val属性数据的清除，需要将所有子集都val都清除掉
    const eachStoreVal = (list, id, bool = false) => {
      if (!(Array.isArray(list) && list.length > 0)) {
        return false;
      }
      for (let child of list) {
        if (child.id === id) {
          this.syncStore.set(child.id, '');
          if (child.children && child.children.length > 0) {
            eachStoreVal(child.children, id, true);
          }
        }
        if (bool) {
          this.syncStore.set(child.id, '');
          if (child.children && child.children.length > 0) {
            eachStoreVal(child.children, id, true);
          }
        }
        eachStoreVal(child.children, id, false);
      }
    };
    eachStoreVal(this.syncTableData, row.id);
    // 1、删除resData中的数据
    // 2、删除该项后，需要考虑父级是否为全选状态，如果是，则改为包含状态
    const eachFind = (list, id, parent) => {
      if (!(Array.isArray(list) && list.length > 0)) {
        return false;
      }
      let resultId = '';
      for (let [index, child] of list.entries()) {
        // 记录子级的父级
        if (parent) {
          child.parent = parent;
        }
        if (child.id === id) {
          resultId = id;
          if (parent) {
            // 需要用链表往上遍历最顶层的parent
            let parentCp = parent;
            while (parentCp) {
              // 树形组件中，不是【选中】状态，是【含有】的状态
              if (parentCp.selectable && !parentCp.isContain) {
                Vue.set(parentCp, 'isContain', true);
              }
              parentCp = parentCp.parent;
            }
          }
          list.splice(index, 1);
          break;
        }
        if (child.children && child.children.length > 0) {
          eachFind(child.children, id, child);
        }
      }
      if (resultId) {
        return true;
      }
    };
    eachFind(this.syncTableData, row.id);
    eachDelParent(this.syncTableData);
    // 更新变量
    // TODO 变量的Ref是否是对的呢？
    if (!refType) return;
    setTimeout(() => {
      variableReset(this.$refs, this.RefName);
    }, 20);
  }
  valBlur(item) {
    if (!item.val && this.isRes) {
      item.val = PLACEHOLDER_OUT;
    } else {
      if (this.isRes) {
        this.syncStore.set(item.id, item.val);
      }
    }
    item.isEdit = false;
  }

  childDom(list, parent, layer) {
    return list.map((item, idx) => {
      if (!item.required) {
        let required = 'false';
        if (parent && parent.required) {
          required = parent.required;
        }
        Vue.set(item, 'required', required);
      }
      return (
        <div data-ctx="true">
          <div
            class={[this.$style.flex, this.$style.treeListItem]}
            key={item.id}
          >
            <div class={this.$style.width_250} style={{ display: 'flex' }}>
              <p style={{ width: `${layer * 14}px` }}></p>
              <div
                class={this.$style.treeIcon}
                style={{
                  opacity: item.children && item.children.length > 0 ? 1 : 0,
                }}
                onClick={() => this.hideParam(item)}
              >
                {item.open ? (
                  <a-icon type="caret-down" />
                ) : (
                  <a-icon type="caret-right" />
                )}
              </div>
              <span>{item.name}</span>
            </div>
            <div class={[this.$style.width_150, this.$style.identifier]}>
              {item.identifier}
            </div>
            <div class={this.$style.width_100}>{item.type}</div>
            {!this.isRes ? (
              <div class={this.$style.width_100}>{`${item.required}`}</div>
            ) : (
              ''
            )}
            {!this.isRes && item.type !== 'text' ? (
              <div class={this.$style.width_250}>{item.val}</div>
            ) : (
              ''
            )}
            {!this.isRes && item.type === 'text' ? (
              <div class={this.$style.width_250}>
                <VariableModel
                  ref={this.RefName + idx}
                  variableVal={item.val}
                  {...{
                    on: {
                      'update:variableVal': val => {
                        item.val = val;
                      },
                    },
                  }}
                  platformId={this.platformId}
                />
              </div>
            ) : (
              ''
            )}
            {this.isRes ? (
              <div
                class={[this.$style.width_300, this.$style.parentEdit]}
                onClick={() => this.changeEdit(item, 1)}
              >
                {!item.isEdit ? (
                  <span class={this.$style.editValue}>{item.val}</span>
                ) : (
                  <a-input
                    v-model={item.val}
                    auto-focus
                    onBlur={() => this.valBlur(item)}
                  />
                )}
              </div>
            ) : (
              ''
            )}
            <div
              style={{
                'color': 'var(--delete)',
                'cursor': 'pointer',
                'padding-left': '10px',
              }}
              onClick={() => this.deleteParam(item, this.RefName)}
            >
              <x-icon type="tc-icon-delete" />
            </div>
          </div>
          {item.open && item.children && item.children.length > 0
            ? this.renderChild(item.children, item, layer + 1)
            : ''}
        </div>
      );
    });
  }

  renderChild(list, parent, layer) {
    if (!(Array.isArray(list) && list.length > 0)) {
      return '';
    }
    return this.childDom(list, parent, layer);
  }

  render() {
    return (
      <div class={this.$style.urlParams} ref="table">
        <div class={[this.$style.flex, this.$style.treeListItem]}>
          <div class={this.$style.width_250}>
            <span style="width: 22px; display: inline-block"></span>
            <span>{this.$t('connector.api.parameterName')}</span>
          </div>
          <div class={this.$style.width_150}>
            {this.$t('connector.dataType.title')}
          </div>
          <div class={this.$style.width_100}>
            {this.$t('controls.currentDataSet.dataType')}
          </div>
          {!this.isRes ? (
            <div class={this.$style.width_100}>
              {this.$t('controls.config.required')}
            </div>
          ) : (
            ''
          )}
          {!this.isRes ? <div class={this.$style.width_250}>Value</div> : ''}
          {this.isRes ? (
            <div class={this.$style.width_300}>
              {this.$t('connector.api.parsingRules')}
            </div>
          ) : (
            ''
          )}
          <div>{this.$t('common.action.operating')}</div>
        </div>
        {this.renderChild(this.syncTableData, '', 0)}
      </div>
    );
  }
}
</script>

<style lang="less" module>
.urlParams {
  max-height: 300px;
  overflow-y: auto;
  position: relative;
}

.flex {
  display: flex;
  align-items: center;
}

.treeListItem {
  height: 35px;
  border-bottom: 1px solid var(--border-fade-20);
  border-left: 1px solid var(--border-fade-20);
  border-right: 1px solid var(--border-fade-20);

  &:first-child {
    border-top: 1px solid var(--border-fade-20);
  }

  &:not(:first-child):hover {
    background-color: var(--table-header-bg);
  }
}

.treeIcon {
  padding: 0 6px;
  cursor: pointer;
  color: var(--form-border);
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.paddingLeft {
  padding-left: 10px;
}

.width_100 {
  width: 100px;
}

.width_150 {
  width: 150px;
}

.width_250 {
  width: 250px;
}

.width_300 {
  width: 300px;
}

.width_500 {
  width: 500px;
}
.parentEdit {
  display: inline-flex;
}
.editValue {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.identifier {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
