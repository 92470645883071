import request from '../request';
const basePath = '/configuration/navigation';

export const getConfig = () => request(`${basePath}/management/get/config`);

export const saveConfig = config =>
  request(`${basePath}/management/add/config`, {
    method: 'POST',
    body: config,
  });
