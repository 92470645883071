<template>
  <div :class="$style.wrap">
    <div :class="$style.content">
      <dictionary-detail
        ref="form"
        :view="!$p.action('UPDATE')"
        :pkId="data.pkId"
      />
    </div>
    <div :class="$style.footer">
      <a-button
        v-if="$p.action('UPDATE')"
        type="primary"
        :class="$style.footer__confirm"
        @click="confirm"
        >{{ $t('common.action.confirm') }}</a-button
      >
      <a-button @click="cancel">{{ $t('common.action.cancel') }}</a-button>
    </div>
  </div>
</template>
<script>
import { createDrawer } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DictionaryDetail from './dictionary-detail';
import { updateDictionary } from '@/services/resource/cloud-dictionary';
import { globalVueI18n } from '@triascloud/i18n';

function createDictionaryDrawer(data) {
  return new Promise(resolve => {
    const drawer = createDrawer(
      h =>
        h(DictionaryDetailDrawer, {
          props: { data },
          on: {
            confirm: () => {
              drawer.visible = false;
              resolve();
            },
            cancel: () => (drawer.visible = false),
          },
        }),
      {
        title: globalVueI18n.translate('dictionary.details'),
        width: 536,
        bodyStyle: {
          padding: 0,
        },
      },
    );
  });
}

@Component({
  components: { DictionaryDetail },
})
export default class DictionaryDetailDrawer extends Vue {
  @Prop({ type: Object, required: true }) data;
  static createDrawer = createDictionaryDrawer;
  async confirm() {
    const {
      idxBusinessId,
      labels,
      removeChildrenIds,
    } = this.$refs.form.formData;
    await updateDictionary({
      pkId: this.data.pkId,
      idxBusinessId,
      labelIds: labels.map(item => item.pkId),
      removeChildrenIds,
    });
    this.$message.success(this.$t('common.tips.save'));
    this.$emit('confirm');
  }
  async cancel() {
    this.$emit('cancel');
  }
}
</script>
<style lang="less" module>
.wrap {
  height: 100%;
  .content {
    padding: 30px;
    height: calc(100% - 80px);
    overflow-y: auto;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header__left {
        font-size: 12px;
        display: flex;
        align-items: center;
        overflow: hidden;
        color: var(--font-info);
        .header__title {
          display: block;
          font-size: 16px;
          max-width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: var(--font);
        }
        .header__icon {
          margin-left: 10px;
          cursor: pointer;
          &.header__icon--link {
            color: var(--font-active);
            margin-right: 10px;
            cursor: default;
          }
        }
      }
      .header__right {
        font-size: 12px;
        color: var(--font-info);
        display: flex;
        align-items: center;
        .header__nickname {
          margin: 0 10px 0 8px;
          max-width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
        }
      }
    }
    .table {
      margin: 20px 0;
      .table__row {
        display: flex;
        position: relative;
        line-height: 40px;
        align-items: center;
        &:first-child {
          color: var(--font);
          .table__cell {
            border-top: 1px solid var(--table-border);
          }
        }
        .table__cell {
          border-color: var(--table-border);
          border-style: solid;
          border-width: 0 1px 1px 0;
          &:first-child {
            text-align: center;
            width: 66px;
            border-left: 1px solid var(--table-border);
          }
          &:nth-child(2) {
            flex: 1;
            padding: 0 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .table__icon {
          position: absolute;
          padding: 6px;
          display: none;
          color: var(--danger);
          cursor: pointer;
          right: -26px;
          top: 6px;
        }
        &:nth-child(2n) .table__cell {
          background-color: var(--list-hover);
        }
        &:hover {
          .table__cell {
            background-color: var(--list-active);
          }
          .table__icon {
            display: block;
          }
        }
      }
    }
    .summary {
      font-size: 12px;
      color: var(--font-info);
      line-height: 28px;
      .summary__value {
        color: var(--font-sub);
      }
      .summary__label {
        margin-left: 15px;
        &:first-child {
          margin-left: 0;
        }
      }
      .summary__time {
        margin-left: 15px;
      }
    }
    .form-item {
      margin-top: 20px;
      .form-item-label {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: var(--font-info);
        line-height: 28px;
      }
      .form-item-select {
        width: 100%;
      }
      .form-item-textarea {
        border: 1px solid var(--form-border);
        padding: 6px;
        border-radius: 4px;
        min-height: 80px;
        max-height: 160px;
        overflow-y: auto;
      }
    }
    .tag-add {
      height: 30px;
      margin-right: 10px;
    }
    .tag {
      height: 30px;
      border: 1px solid var(--font-active);
      color: var(--font-active);
      cursor: pointer;
      margin: 5px 10px 5px 0;
      padding: 6px 12px;
      line-height: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 30px;
    border-top: 1px solid var(--border);
    .footer__confirm {
      margin-right: 30px;
    }
  }
}
</style>
