<template>
  <a-tabs v-model="activeTab" :class="$style.tabs">
    <a-tab-pane v-for="tab in tabs" :tab="tab.name" :key="tab.key">
      <div :class="$style.header">
        <div>{{ $t('common.label.index') }}</div>
        <div>{{ $t('help.name') }}</div>
        <div>{{ $t('website.url') }}</div>
        <div>{{ $t('website.showOnMobile') }}</div>
        <div v-if="$p.action('UPDATE')">
          {{ $t('common.action.operating') }}
        </div>
      </div>
      <vue-draggable
        v-model="config[tab.key].navigation"
        :handle="`.${$style.drag}`"
        :animation="300"
        @update="handleInput"
      >
        <transition-group>
          <div
            v-for="(item, index) in config[tab.key].navigation"
            :key="item.id"
          >
            <div :class="$style.item">
              <div>{{ index + 1 }}</div>
              <div :title="item.name">{{ item.name }}</div>
              <div :title="item.path">{{ item.path }}</div>
              <div>
                {{
                  item.showOnMobile
                    ? $t('template.review.checked')
                    : $t('template.review.unChecked')
                }}
              </div>
              <div v-if="$p.action('UPDATE')">
                <a-tooltip :title="$t('website.addSub')">
                  <x-icon type="tc-icon-cascade" @click="addNavigation(item)" />
                </a-tooltip>
                <a-tooltip :title="$t('solution.edit')">
                  <x-icon type="edit" @click="editNavigation(item)" />
                </a-tooltip>
                <a-tooltip :title="$t('common.action.delete')">
                  <x-icon
                    type="tc-icon-delete"
                    @click="handleDelete(config[tab.key].navigation, index)"
                  />
                </a-tooltip>
                <x-icon type="fullscreen" :class="$style.drag" />
              </div>
            </div>
            <vue-draggable
              v-model="item.children"
              :key="item.id"
              :group="tab.key"
              :handle="`.${$style.drag2}`"
              :animation="300"
              :class="$style.dragItem"
              @end="handleInput"
            >
              <transition-group v-if="$p.action('UPDATE')">
                <div
                  v-for="(child, i) in item.children"
                  :key="child.id"
                  :class="[$style.item, $style['item--children']]"
                >
                  <div>{{ i + 1 }}</div>
                  <div :title="child.name">{{ child.name }}</div>
                  <div :title="child.path">{{ child.path }}</div>
                  <div>
                    {{
                      child.showOnMobile
                        ? $t('template.review.checked')
                        : $t('template.review.unChecked')
                    }}
                  </div>
                  <div :class="$style.childrenAction">
                    <x-icon
                      v-if="$p.action('UPDATE')"
                      type="edit"
                      @click="editNavigation(child, item.name)"
                    />
                    <x-icon
                      type="tc-icon-delete"
                      @click="handleDelete(item.children, i)"
                    />
                    <x-icon type="fullscreen" :class="$style.drag2" />
                  </div>
                </div>
              </transition-group>
            </vue-draggable>
          </div>
        </transition-group>
      </vue-draggable>
    </a-tab-pane>
    <a-button
      slot="tabBarExtraContent"
      icon="plus-circle"
      type="primary"
      v-if="$p.action('UPDATE')"
      @click="addNavigation()"
      >{{
        activeTab === 'header'
          ? $t('website.addHeaderNav')
          : $t('website.addFooterNav')
      }}</a-button
    >
  </a-tabs>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueDraggable from 'vuedraggable';
import { deepClone, uuid } from '@triascloud/utils';
import NavigationDrawer from './navigation-drawer.vue';
@Component({ components: { VueDraggable } })
export default class NavigationSetting extends Vue {
  @Prop({ type: Object, default: null }) value;
  @Prop({ type: Boolean, default: false }) showDesc;
  activeTab = 'header';
  config = {
    header: {
      navigation: [],
    },
    footer: {
      navigation: [],
    },
  };
  get tabs() {
    return [
      {
        name: this.$t('website.headerSetting'),
        key: 'header',
      },
      {
        name: this.$t('website.BottomNavigation'),
        key: 'footer',
      },
    ];
  }
  created() {
    this.getConfig();
  }
  getConfig() {
    this.config = deepClone(this.value);
  }
  async addNavigation(item) {
    const data = await NavigationDrawer.createDrawer({
      parent: item && item.name,
      showDesc: this.showDesc,
    });
    data.id = uuid();
    if (item) {
      item.children
        ? item.children.push(data)
        : this.$set(item, 'children', [data]);
    } else {
      this.config[this.activeTab].navigation.push({
        ...data,
        children: [],
      });
    }
    this.handleInput();
  }
  async editNavigation(item, parent) {
    const data = await NavigationDrawer.createDrawer({
      data: item,
      parent,
      showDesc: this.showDesc,
    });
    Object.keys(data).forEach(key => {
      item[key] = data[key];
    });
    this.handleInput();
  }
  handleDelete(item, i) {
    item.splice(i, 1);
    this.handleInput();
  }
  handleInput() {
    this.$emit('input', this.config);
  }
}
</script>
<style lang="less" module>
.tabs {
  height: 100%;
  :global {
    .ant-tabs-bottom-content,
    .ant-tabs-top-content {
      height: calc(100% - 64px);
      .ant-tabs-tabpane {
        height: 100%;
        overflow: auto;
      }
    }
  }
}
.header,
.item {
  display: flex;
  height: 47px;
  line-height: 47px;
  > div {
    padding: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    &:nth-child(1) {
      min-width: 120px;
      text-align: center;
      width: percentage(120 / 1440);
    }
    &:nth-child(2) {
      min-width: 240px;
      width: percentage(240 / 1440);
    }
    &:nth-child(3) {
      min-width: 600px;
      width: percentage(600 / 1440);
    }
    &:nth-child(4) {
      min-width: 240px;
      width: percentage(240 / 1440);
    }
    &:nth-child(5) {
      min-width: 240px;
      width: percentage(240 / 1440);
      font-size: 18px;
      i {
        margin: 0 12px;
      }
    }
  }
  .childrenAction {
    padding-left: 46px;
  }
  // &:nth-child(2n) > div {
  //   background-color: var(--table-row-stripe);
  // }
}
.row:nth-child(2n) .item > div {
  background-color: var(--table-row-stripe);
}
.item--children {
  > div {
    &:nth-child(1) {
      padding-left: 60px;
    }
  }
}
.header {
  > div {
    background-color: var(--table-header-bg);
    &:nth-child(5) {
      font-size: 14px;
    }
  }
}
.drag,
.drag2 {
  cursor: move;
}
</style>
