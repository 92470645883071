var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[
      _vm.$style.ImgList,
      _vm.type === 'customer' ? _vm.$style.customerBg : '',
      _vm.type === 'advantage' ? _vm.$style.customerBg : '',
      _vm.type === 'architecture' ? _vm.$style.architecture : '',
      'solutionBg' ]},_vm._l((_vm.value),function(item,index){return _c('ImgItem',{key:index,ref:_vm.type,refInFor:true,attrs:{"form-item-prop":(_vm.formItemProp + "." + index),"value":item,"status":true,"type":_vm.type},on:{"input":function($event){return _vm.changData($event, index)},"deleteItem":function () {
          _vm.$emit(
            'input',
            _vm.value.filter(function (_, i) { return index !== i; })
          );
        }}})}),1),(_vm.type !== 'architecture')?_c('a-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.value && _vm.value.length < _vm.countMap[_vm.type]),expression:"value && value.length < countMap[type]"}],class:_vm.$style.addApp,attrs:{"icon":"plus","type":"link"},on:{"click":_vm.addModal}},[_vm._v(_vm._s(_vm.addText))]):_c('x-upload-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.value && _vm.value.length < _vm.countMap[_vm.type]),expression:"value && value.length < countMap[type]"}],attrs:{"maxFileSize":5},on:{"success":function (res) {
        _vm.$emit('input', _vm.value.concat( [{
            description: '',
            pkId: '',
            title: '',
            image: res.ossData.ossPath,
          }] ));
      }}},[_c('a-button',{class:_vm.$style.addApp,attrs:{"icon":"plus","type":"link"}},[_vm._v(" "+_vm._s(_vm.addText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }