<template>
  <ul>
    <li
      v-for="(item, index) in followupList"
      :key="index"
      :class="$style.items"
    >
      <FollowupInfoItem :itemInfo="item" />
    </li>
  </ul>
</template>

<script>
/** 查看更多跟进信息 */

import { Component, Vue, Prop } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import FollowupInfoItem from './followup-info-item.vue';
import { fetchMoreFollowup } from '@/services/business-opportunity/index';

@Component({
  components: {
    FollowupInfoItem,
  },
})
export default class MoreFollowupInfo extends Vue {
  @Prop({ type: Object, default: () => null }) modalInfo;
  @Prop({ type: String, default: () => '' }) memberId;
  @Prop({ type: Number, default: () => 1 }) moreFollowupType;

  static createMoreFollowupInfoModal = createMoreFollowupInfoModal;
  followupList = [];
  created() {
    fetchMoreFollowup(this.memberId, this.moreFollowupType).then(res => {
      this.followupList = res;
    });
  }
}

function createMoreFollowupInfoModal(props) {
  return new Promise(resolve => {
    const modal = createModal(
      h =>
        h(MoreFollowupInfo, {
          props,
          on: {
            close: () => {
              modal.handleClose();
              resolve();
            },
            handleCreate: () => {
              modal.handleClose();
              resolve('success');
            },
          },
        }),
      {
        width: 540,
        title: props.modalInfo.title,
      },
    );
  });
}
</script>

<style lang="less" module>
.items {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
