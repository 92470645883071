<template>
  <FlexColContent>
    <div :class="$style.operation">
      <div :class="$style.searchBox">
        <a-input
          :class="$style.searchInput"
          v-model="searchParams.keyword"
          @input.stop="() => handleSearch()"
          placeholder="搜索"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-select
          allowClear
          v-model="searchParams.developmenterType"
          placeholder="请选择开发者类型"
          @change="handleSearch()"
          :class="$style.searchSelect"
        >
          <a-select-option
            :value="key"
            v-for="(value, key) in developerType"
            :key="key"
          >
            {{ value }}
          </a-select-option>
        </a-select>
      </div>

      <a-button
        type="primary"
        @click="handleAdd({}, 'add')"
        v-if="$p.action('CREATE')"
        >+添加开发者</a-button
      >
    </div>

    <template slot="footer">
      <PaginationTable
        ref="tableRef"
        :columns="columns"
        :api="getDeveloperList"
        row-key="deviceId"
        :scroll="{ x: '100%', y: 'max-content' }"
        :search-params="searchParams"
      >
      </PaginationTable>
    </template>
  </FlexColContent>
</template>
<script>
import { Component, Ref, Vue } from 'vue-property-decorator';
import TopData from '../components/top-data.vue';
import PaginationTable from '@/views/digital-site/components/pagination-table';
import FlexColContent from '@/views/digital-site/components/flex-col-content';
import {
  getDeveloperList,
  deleteDeveloper,
  resetAppSecret,
} from '@/services/digital-site/developer';
import { createFormModal } from '@triascloud/x-components';
import Tip from '@/components/tip';
import DeveloperEditForm from '../form-modal/developer-edit-form.vue';
import PermissionConfigForm from '../form-modal/permission-config-form.vue';
import ResetSecretForm from '../form-modal/reset-secret-form.vue';
import { AButton } from '@triascloud/x-blocks/dist/ant-design-vue';
import { clipboardWrite } from '@triascloud/utils';
import { developerType } from '../enum';

@Component({
  components: {
    TopData,
    PaginationTable,
    FlexColContent,
  },
})
export default class Dev extends Vue {
  developerType = developerType;
  get columns() {
    return [
      {
        title: '开发者名称',
        dataIndex: 'developmenterName',
        ellipsis: true,
        width: '180px',
      },
      {
        title: 'appKey',
        dataIndex: 'appKey',
        ellipsis: true,
        width: '350px',
      },
      {
        title: 'appsecret',
        dataIndex: 'appSecret',
        ellipsis: true,
        width: '350px',
      },
      {
        title: '开发者类型',
        dataIndex: 'developmenterType',
        ellipsis: true,
        width: '150px',
        customRender: text => developerType[text] ?? '',
      },
      {
        title: '备注',
        dataIndex: 'remark',
        ellipsis: true,
        width: '200px',
      },
      {
        title: '操作',
        key: 'action',
        ellipsis: true,
        width: 0,
        // fixed: 'right',
        customRender: (text, record) => {
          return (
            <div class={this.$style.buttonGroups}>
              {this.$p.action('UPDATE') ? (
                <span
                  class={this.$style.button}
                  onClick={() => this.handlePermissionConfig(record)}
                >
                  权限配置
                </span>
              ) : (
                ''
              )}
              {/* {this.$p.action('UPDATE') ? (
                <span
                  class={this.$style.button}
                  onClick={() => this.handleReset(record)}
                >
                  重置secret
                </span>
              ) : (
                ''
              )} */}
              {this.$p.action('UPDATE') ? (
                <span
                  class={this.$style.button}
                  onClick={() => this.handleEdit(record)}
                >
                  编辑
                </span>
              ) : (
                ''
              )}
              {this.$p.action('DELETE') ? (
                <span
                  class={this.$style.button}
                  onClick={() => this.handleDelete(record)}
                >
                  删除
                </span>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    ];
  }

  searchParams = {
    developmenterType: undefined,
    keyword: '',
  };

  getDeveloperList = getDeveloperList;

  @Ref() tableRef;

  handleSearch() {
    this.tableRef.search();
  }

  handleDelete({ pkId }) {
    createFormModal(
      () => (
        <Tip iconStyle={{ padding: '0 0 22px' }}>
          <template slot="txt">
            <span>确定删除开发者？</span>
          </template>
          <span slot="subTxt">
            删除后将清空开发者所有数据及权限，请谨慎删除
          </span>
        </Tip>
      ),
      {
        title: '提示',
        maskClosable: false,
        onOk: async () => {
          try {
            await deleteDeveloper(pkId);
            this.$message.success('删除成功！');
            this.tableRef.refresh();
          } catch (error) {
            return error;
          }
        },
      },
    );
  }

  async handleEdit(record) {
    const form = await createFormModal(
      () => <DeveloperEditForm type="edit" record={record} />,
      {
        title: '编辑开发者',
        width: '500px',
        closable: true,
        maskClosable: false,
      },
    );
    if (form) {
      this.tableRef.refresh();
    }
  }

  async handleAdd() {
    const form = await createFormModal(() => <DeveloperEditForm />, {
      title: '添加开发者',
      width: '500px',
      closable: true,
      maskClosable: false,
    });
    if (form) {
      this.tableRef.refresh();
    }
  }

  secretFlag = false;
  async handleReset(record) {
    this.secretFlag = false;
    const secretId = await createFormModal(
      () => (
        <ResetSecretForm
          record={record}
          onReset={async () => {
            const res = await this.handleResetConfirm(record.pkId);
            record.appSecret = res;
            this.secretFlag = true;
          }}
        />
      ),
      {
        title: '重置secret',
        width: '500px',
        closable: true,
        maskClosable: false,
        footer: (h, submit, cancel) => {
          return [
            <AButton onClick={cancel}>取消</AButton>,
            !this.secretFlag ? (
              <AButton
                type="primary"
                onClick={async () => {
                  const res = await this.handleResetConfirm(record.pkId);
                  record.appSecret = res;
                  this.secretFlag = true;
                }}
              >
                确认
              </AButton>
            ) : null,
            this.secretFlag ? (
              <AButton
                type="primary"
                onClick={async () => {
                  await clipboardWrite(record.appSecret);
                  this.$message.success('复制成功');
                }}
              >
                复制secret
              </AButton>
            ) : null,
          ];
        },
      },
    );
    if (secretId) {
      this.tableRef.refresh();
    }
  }

  async handleResetConfirm(pkId) {
    const res = await createFormModal(
      () => (
        <Tip iconStyle={{ padding: '0 0 22px' }}>
          <template slot="txt">
            <span>确定重置secret？</span>
          </template>
          <span slot="subTxt">重置后旧secret将失效，请妥善保管新的secret</span>
        </Tip>
      ),
      {
        title: '提示',
        maskClosable: false,
      },
    );
    if (res) {
      try {
        const res = await resetAppSecret(pkId);
        return res;
      } catch (error) {
        return false;
      }
    }
  }

  async handlePermissionConfig(record) {
    const form = await createFormModal(
      () => <PermissionConfigForm record={record} />,
      {
        title: '权限配置',
        width: '500px',
        closable: true,
        maskClosable: false,
      },
    );
    if (form) {
      this.tableRef.refresh();
    }
  }
}
</script>
<style lang="less" module>
.operation {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  .searchBox {
    display: flex;
    gap: 10px;
    .searchInput {
      margin-right: 10px;
      width: 200px;
    }
    .searchSelect {
      width: 180px;
    }
  }
}

.tableWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.buttonGroups {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  width: max-content;
  .button {
    cursor: pointer;
    color: var(--primary);
  }
}
</style>
