export const TemplateType = {
  /** @name 主动标识载体 */
  Initiative: 1,
  /** @name 其他 */
  Other: 2,
};

/**
 * @name 同步间隔类型
 */
export const SyncTimeType = {
  /** @name 手动 */
  Manual: -1,
  /** @name 每小时 */
  Hour: 1,
  /** @name 每六小时 */
  SixHour: 2,
  /** @name 半天 */
  HalfDay: 3,
  /** @name 一天 */
  Day: 4,
  /** @name 每周 */
  Week: 5,
  /** @name 每月 */
  Month: 6,
};

/** @name 同步状态成功为1，其它都是失败 */
export const SyncResult = {
  /** @name 成功 */
  Success: 1,
  Fail: 0,
};

/** @name 同步类型 */
export const SyncType = {
  Register: 1,
  Update: 2,
  Remove: 3,
};

/** @name 标识解析触发类型 */
export const ParseTriggerType = {
  All: 1,
  List: 2,
  Details: 3,
};
