<template>
  <div>
    <div v-if="selectCount > 0">
      <div :class="$style.tips">
        <span>提示：共选中</span>
        <span :class="$style.count">{{ selectCount }}</span>
        <span>台待分配设备，非待分配设备已被过滤</span>
      </div>
    </div>
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-form-model-item label="分配方式" prop="allocationType">
        <a-radio-group v-model="form.allocationType" default-value="long">
          <a-radio value="ASSIGN">
            指定企业
          </a-radio>
          <a-radio value="ALL">
            任意企业
          </a-radio>
        </a-radio-group>
        <p>
          说明：需要输入企业的Tcid（见<a target="_blank" :href="companySetting"
            >企业设置</a
          >）来绑定
        </p>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.allocationType === 'ASSIGN'"
        label="分配企业"
        prop="idxTenantTcid"
      >
        <a-input
          v-model="form.idxTenantTcid"
          placeholder="请输入被分配企业Tcid"
        />
      </a-form-model-item>
      <a-form-model-item label="校验方式" prop="verifyType">
        <a-select v-model="form.verifyType" placeholder="请输入随机密钥">
          <a-select-option value="RAW_KEY">设备码+原始密钥</a-select-option>
          <a-select-option value="RANDOM_KEY">设备码+随机密钥</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.verifyType === 'RANDOM_KEY' && selectCount === 0"
        label="随机密钥"
        prop="randomKey"
      >
        <a-row>
          <a-col span="22">
            <a-input v-model="form.randomKey"></a-input>
          </a-col>
          <a-col span="2" :class="$style.retweet" @click="handleRandom">
            <a-icon type="retweet" />
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="设备有效期" prop="expireType">
        <a-radio-group
          v-model="form.expireType"
          default-value="long"
          @change="changeExpireType"
        >
          <a-radio value="long">
            长期
          </a-radio>
          <a-radio value="custom">
            自定义
          </a-radio>
        </a-radio-group>
        <a-form-model-item
          v-if="form.expireType === 'custom'"
          prop="expireTime"
          style="display: inline-block;"
        >
          <a-date-picker
            v-model="form.expireTime"
            :disabled-date="disabledDate"
            placeholder="YYYY/MM/DD"
            format="YYYY/MM/DD"
          />
        </a-form-model-item>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'moment';
import { getTenantName } from '@/services/tenant-center/enterprise';
import { randomFn } from '../utils';
import { createModuleUrl } from '@/utils';
@Component()
export default class Allocation extends Vue {
  @Prop({ type: Object }) detail;
  @Prop({ type: Array, default: () => [] }) deviceSelect;

  disabledDate(current) {
    const cur = current && dayjs(current).add(1, 'day');
    return cur < dayjs().endOf('day');
  }

  changeExpireType(event) {
    if (event.target.value === 'long') {
      this.form.expireTime = '';
    }
  }

  form = {
    expireType: 'long',
    expireTime: '',
    randomKey: '',
    verifyType: undefined,
    idxTenantTcid: '',
    allocationType: '',
  };
  rules = {
    allocationType: [{ required: true, message: '不能为空！' }],
    verifyType: [{ required: true, message: '不能为空！' }],
    randomKey: [{ required: true, message: '不能为空！' }],
    idxTenantTcid: [
      { required: true, message: '不能为空！' },
      {
        validator: this.checkTenantValidator,
        trigger: 'blur',
      },
    ],
    expireType: [{ required: true, message: '不能为空！' }],
    expireTime: [{ required: true, message: '不能为空！' }],
  };

  get companySetting() {
    return `${createModuleUrl('app')}/company/setting`;
  }

  get selectCount() {
    return this.deviceSelect.filter(v => v.deviceStatus === 'NOT_ALLOCATED')
      .length;
  }

  async checkTenantValidator(rule, value, cb) {
    if (value !== '') {
      try {
        const name = await getTenantName(value);
        this.$message.success(`校验通过，当前分配企业为${name}`);
        cb();
      } catch (error) {
        cb(new Error('企业ID不正确！'));
      }
    } else {
      cb();
    }
  }

  handleRandom() {
    this.form.randomKey = randomFn(6);
  }

  async getValue() {
    await this.$refs.form.validate();
    // if (this.form.verifyType === 'RAW_KEY' && !this.detail.rawKey) {
    //   this.$message.warn('当前设备的原始密钥缺失，请在“编辑”中补全');
    //   return Promise.reject(false);
    // }
    return this.form;
  }
}
</script>
<style lang="less" module>
.retweet {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 18px;
  font-weight: bolder;
  cursor: pointer;
}

.txt {
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  color: var(--primary);
  margin-left: 10px;
}

.tips {
  margin-bottom: 10px;
  color: var(--warning);

  .count {
    color: var(--primary);
    font-size: 18px;
  }
}
</style>
