<template>
  <!-- <a-form-model ref="form" :model="{ data }" :rules="rules"> -->
  <div
    :class="[
      $style.solutionBg,
      type === 'customer' ? $style.customerBg : '',
      type === 'architecture' ? $style.architecture : '',
      'solutionBg',
    ]"
  >
    <div :class="[$style.solutionItem]">
      <a-button
        v-if="status"
        type="link"
        :class="[$style.delIconBox, 'delIconBox']"
        @click="deleteBgItem"
      >
        <x-icon type="tc-icon-delete" :class="$style.delIcon" />
      </a-button>
      <a-form-model-item
        :prop="`${formItemProp}.image`"
        ref="imageForm"
        :rules="[
          {
            message: $t('solution.selectPicture'),
            required: true,
          },
        ]"
      >
        <input v-model="value.image" :class="$style.solutionInput" />
        <x-upload-button
          :maxFileSize="5"
          @success="
            res => {
              $refs.imageForm.FormContext.clearValidate(
                `${formItemProp}.image`,
              );
              $emit('input', { ...value, image: res.ossData.ossPath });
            }
          "
        >
          <div
            :class="[
              $style.solutionBgDetail,
              type === 'customer' || type === 'advantage'
                ? $style.customerDetail
                : '',
              type === 'architecture' ? $style.upload : '',
              'solutionBgDetail',
            ]"
          >
            <div :class="$style.addIcon" v-if="!value.image">
              <x-icon type="tc-icon-image-download"></x-icon>
              {{ $t('solution.addPictures') }}
            </div>

            <x-oss-image
              :oss-path="value.image"
              :class="$style.image__img"
              v-else
            />
            <div :class="$style.changePic" v-if="value.image && !status">
              <div :class="$style.addIcon">
                <x-icon type="tc-icon-image-download"></x-icon>
                {{ $t('solution.addPictures') }}
              </div>
            </div>
          </div>
        </x-upload-button>

        <!-- <div v-if="type === 'advantage'" :class="$style.iconBg">
          <x-icon-library
            :value="value.image"
            @change="
              res => {
                $refs.imageForm.FormContext.clearValidate(
                  `${formItemProp}.image`,
                );
                $emit('input', { ...value, image: res });
              }
            "
          >
            <div slot="block">
              <x-custom-icon
                :class="$style.advantageIcon"
                v-if="value.image"
                :type="value.image"
              />

              <div v-else>
                <x-icon type="tc-icon-image-download"></x-icon>
                <div>{{ $t('solution.selectIcon') }}</div>
              </div>
            </div>
          </x-icon-library>
        </div> -->
      </a-form-model-item>

      <a-form-model-item
        v-if="type !== 'architecture'"
        :prop="`${formItemProp}.title`"
        :rules="[
          {
            trigger: 'blur',
            message: $t('solution.tip1'),
            required: true,
            whitespace: true,
          },
          {
            min: 2,
            max: 30,
            message: $t('solution.tip2'),
            trigger: 'blur',
          },
        ]"
      >
        <a-input
          :value="value.title"
          :maxLength="30"
          @input="res => changeData(res.target.value)"
          :class="[
            $style.solutionBgDetailTlt,
            type === 'customer' ? $style.customerDetailTlt : '',
          ]"
        ></a-input>
      </a-form-model-item>

      <a-form-model-item
        :prop="`${formItemProp}.description`"
        v-if="type !== 'architecture'"
        :rules="[
          {
            trigger: 'blur',
            message: $t('record.updateProfile'),
            required: true,
            whitespace: true,
          },
          type === 'customer'
            ? {
                min: 1,
                max: 500,
                message: $t('solution.tip2'),
                trigger: 'blur',
              }
            : {
                min: 1,
                max: 200,
                message: $t('solution.tip2'),
                trigger: 'blur',
              },
        ]"
      >
        <a-textarea
          :value="value.description"
          :class="$style.solutionDesc"
          @input="
            res =>
              $emit('input', { ...this.value, description: res.target.value })
          "
          :maxLength="type === 'customer' ? 500 : 200"
        ></a-textarea>
      </a-form-model-item>
    </div>
  </div>
  <!-- </a-form-model> -->
</template>
<script>
import { Component, Prop, Vue, Provide } from 'vue-property-decorator';
import { UploadPathSymbol } from '@triascloud/x-components';

@Component()
export default class ImgItem extends Vue {
  @Prop({ type: String, default: 'customer' }) type;
  @Prop({ type: Object }) value;
  @Prop({ type: Boolean }) status;
  @Prop() formItemProp;
  @Provide(UploadPathSymbol) uploadPath = 'common/screenshot/market/';
  changeData(res) {
    this.$emit('input', { ...this.value, title: res });
  }
  deleteBgItem() {
    this.$emit('deleteItem');
  }
}
</script>
<style lang="less" module>
.delIconBox {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  padding: 0;
  min-width: 60px;
  max-width: 60px;
  .delIcon {
    color: var(--danger);
  }
}

.solutionInput {
  opacity: 0;
  height: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.solutionItem {
  position: relative;
  width: 100%;
  :global(.ant-input) {
    border: 1px solid var(--border);
  }
}

.delItem {
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  align-items: center;
  justify-content: center;
  button i {
    font-size: 40px;
  }
  .delIcon:only-child {
    font-size: 40px;
  }
  button {
    color: var(--font-color-secondary);
  }
}

.changePic {
  background-color: #000;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  border-radius: 6px;
  button i {
    font-size: 40px;
    color: #ccc;
  }
  button {
    color: var(--block-bg);
  }
}

.solutionBgDetail {
  height: 117px;
  width: 415px;
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    .changePic {
      display: flex;
    }
  }
  .image {
    width: 415px;
    height: 100%;
  }
  .image__img {
    height: 100%;
    width: 100%;
  }
  .addBtn {
    color: var(--font-color-secondary);
    display: flex;
    flex-direction: column;
  }
}

.customerDetail {
  width: 330px;
  height: 181px;
}

.addIcon {
  display: flex;
  flex-direction: column;
  color: var(--font-color-secondary);
  cursor: pointer;
  i {
    font-size: 30px;
    margin-bottom: 5px;
  }
}

.upload {
  width: 1140px;
  height: 296px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  border-radius: 6px;
  &:hover {
    .changePic {
      display: flex;
    }
  }
  .image {
    width: 1140px;
    height: 296px;
  }
  .image__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.advantageItem {
  width: 330px;

  .iconBg {
    margin-bottom: 5px;
  }
}

.iconBg {
  display: flex;
  border: 1px solid var(--btn-border);
  color: var(--font-color-secondary);
  cursor: pointer;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  margin-left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  position: relative;
  .changePic {
    border-radius: 4px;
  }
  &:hover {
    .changePic {
      display: flex;
    }
  }
  .advantageIcon {
    font-size: 40px;
    cursor: pointer;
  }
  .i {
    text-align: center;
  }
  :global(.x-icon-library-slot-button) {
    text-align: center;
  }
}
</style>
