<template>
  <a-form-model ref="form" :model="formData" :rules="rules">
    <a-form-model-item prop="img">
      <x-upload-button @success="data => handleImageChange(data.ossData)">
        <div v-if="formData.img" :class="$style.image" @click="openImageSelect">
          <x-oss-image
            :oss-path="formData.img"
            :class="$style.image__img"
            size="330"
          />
          <div :class="$style.image__dimmer">
            {{ $t('market.template.banner.updateImage') }}
          </div>
        </div>
        <a-button v-else :class="$style.uploadButton" @click="openImageSelect">
          <x-icon type="upload" /><span>{{
            $t(
              'upload.maxSize',
              { type: $t('gallery.label.image'), value: 20 },
              'package',
            )
          }}</span>
        </a-button>
      </x-upload-button>
    </a-form-model-item>
    <a-form-model-item prop="idxTemplateId">
      <div :class="$style.setting">
        <div :class="$style.setting__item">
          <span>{{ $t('market.template.banner.bgColor') }}</span>
          <custom-color-picker v-model="formData.color" :class="$style.color" />
        </div>
        <div :class="$style.setting__item">
          <span>{{ $t('market.template.select') }}</span>
          <div
            type="link"
            :class="$style.setting__template"
            @click="selectTemplate"
          >
            {{
              selectedTemplate
                ? selectedTemplate.name
                : $t('common.label.click')
            }}
          </div>
        </div>
      </div>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue, Provide } from 'vue-property-decorator';
import {
  createFormModal,
  ImageSelect,
  UploadPathSymbol,
} from '@triascloud/x-components';
import { addBanner, editBanner } from '@/services/template-manager/banner';
import { CustomColorPicker } from '@/components/custom-color-picker';
import { TemplateType } from '@/enum/template';
import { createModuleUrl } from '@/utils';
import SelectTemplateModal from './select-template-modal.vue';
import { globalVueI18n } from '@triascloud/i18n';
function createBannerFormModal(props) {
  return createFormModal(h => h(BannerFormModal, { props }), {
    title:
      props && props.data
        ? globalVueI18n.translate('market.template.banner.edit')
        : globalVueI18n.translate('market.template.banner.add'),
    width: 500,
  });
}
@Component({ components: { CustomColorPicker } })
export default class BannerFormModal extends Vue {
  @Prop({ type: Object }) data;
  @Prop({ type: String, default: TemplateType.Application }) templateType;
  @Provide(UploadPathSymbol) uploadPath = 'common/screenshot/market/';
  static createModal = createBannerFormModal;
  formData = {
    color: '',
    idxTemplateId: '',
    img: '',
    pkId: '',
    sort: 0,
  };
  selectedTemplate = null;
  get rules() {
    return {
      img: [
        {
          required: true,
          message: this.$t('market.template.banner.upload'),
        },
      ],
      idxTemplateId: [
        {
          required: true,
          message: this.$t('market.template.selectedTip'),
        },
      ],
    };
  }
  created() {
    if (this.data) {
      this.formData.color = this.data.bgColor;
      this.formData.idxTemplateId = this.data.idxTemplateId;
      this.formData.img = this.data.img;
      this.formData.pkId = this.data.pkId;
      this.selectedTemplate = {
        name: this.data.name,
        pkId: this.data.idxTemplateId,
      };
    }
    this.formData.templateType = this.templateType;
  }
  openImageSelect(event) {
    const drawer = ImageSelect.createDrawer({
      props: {
        signalUse: false,
        uploadPath: this.uploadPath,
        qrCode: createModuleUrl('m'),
      },
      on: {
        change: data => {
          this.handleImageChange(data);
          drawer.visible = false;
        },
      },
    });
    event.preventDefault();
    event.stopPropagation();
  }
  handleImageChange(data) {
    this.formData.img = data.ossPath;
  }
  async getValue() {
    await this.$refs.form.validate();
    this.data
      ? await editBanner(this.formData)
      : await addBanner([this.formData]);
    this.$message.success(this.$t('common.tips.save'));
  }
  async selectTemplate() {
    this.selectedTemplate = await SelectTemplateModal.createModal(
      {},
      { title: this.$t('market.template.select') },
    );
    this.formData.idxTemplateId = this.selectedTemplate.pkId;
  }
}
</script>
<style lang="less" module>
.image {
  position: relative;
  width: 460px;
  height: 160px;
  cursor: pointer;
  .image__dimmer,
  .image__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .image__dimmer {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  &:hover {
    .image__dimmer {
      display: flex;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
.uploadButton {
  width: 460px;
}
.setting {
  display: flex;
  align-items: center;
  .setting__item {
    display: flex;
    align-items: center;
    &:last-child {
      flex: 1;
      overflow: hidden;
      .setting__template {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 10px;
        cursor: pointer;
        color: var(--primary);
      }
    }
  }
  .color {
    border: 1px solid var(--form-border);
    border-radius: 2px;
    cursor: pointer;
    margin: 0 30px 0 10px;
  }
}
</style>
