<template>
  <div :class="$style.hotRecommend">
    <a-button
      type="primary"
      icon="plus"
      :disabled="list.length >= 6"
      @click="addHot"
      >{{ $t('market.template.addRecommend') }}</a-button
    >
    <a-spin :spinning="loading">
      <draggable
        v-if="list && list.length"
        v-model="list"
        :animation="300"
        @end="sort"
        :handle="`.${$style.card__dragHandle}`"
      >
        <transition-group>
          <application-card
            v-for="(item, index) in list"
            :key="item.id"
            :data="item"
            :class="$style.card"
          >
            <a-tooltip
              v-if="$p.action('DELETE')"
              placement="bottom"
              :title="$t('common.action.delete')"
            >
              <x-icon
                type="tc-icon-delete"
                :class="[$style.card__icon, $style['card__icon--delete']]"
                @click="removeHot(item.id, index)"
              />
            </a-tooltip>
            <x-icon
              v-if="$p.action('UPDATE')"
              type="fullscreen"
              :class="[
                $style.card__icon,
                $style.card__dragHandle,
                $style['card__dragHandle--hot'],
              ]"
            />
          </application-card>
        </transition-group>
      </draggable>
    </a-spin>
    <empty-content v-if="!loading && !list.length" :class="$style.empty" />
  </div>
</template>
<script>
import { createDrawer } from '@triascloud/x-components';
import { Component, Vue } from 'vue-property-decorator';
import ApplicationCard from './card';
import {
  deleteHotTemp,
  getHotTemp,
  setHotTemp,
  sortRecommend,
} from '@/services/template-manager/hotRecommend';
import SelectTemplateModal from './select-template-modal.vue';
import { TemplateType } from '@/enum/template';
import EmptyContent from '@/components/empty-content';
function createHotRecommendDrawer(props, listener) {
  return createDrawer(h => h(HotRecommendDrawer, { props, on: listener }), {
    title: props.title,
    width: 900,
    bodyStyle: {
      padding: 0,
    },
    wrapClassName: 'hot-recommend-drawer--wrapper',
  });
}
@Component({ components: { ApplicationCard, EmptyContent } })
export default class HotRecommendDrawer extends Vue {
  static createModal = createHotRecommendDrawer;
  list = [];
  loading = true;

  async created() {
    this.getTemplateList();
  }
  async getTemplateList() {
    this.loading = true;
    this.list = this.sortTemplate((await getHotTemp()) || []);
    this.loading = false;
  }
  sortTemplate(list) {
    return list.sort((a, b) => a.sort - b.sort);
  }
  async removeHot(idxTemplateId, index) {
    await this.$confirm(this.$t('market.template.deleteRecommendConfirm'));
    await deleteHotTemp(idxTemplateId);
    this.list.splice(index, 1);
    this.$message.success(this.$t('common.tips.delete'));
  }
  async addHot() {
    const template = await SelectTemplateModal.createModal();
    await setHotTemp({
      idxTemplateId: template.pkId,
      templateType: TemplateType.Application,
    });
    this.getTemplateList();
    this.$message.success(this.$t('common.tips.setSuccessfully'));
  }
  /**
   * @name 拖动排序更新sort字段
   */
  async sort({ newIndex, oldIndex }) {
    if (!this.list.length || newIndex === oldIndex) return;
    this.loading = true;
    const sortItems = [];
    this.list.forEach((item, index) => {
      const sort = index;
      item.sort = sort;

      sortItems.push({
        sort,
        id: item.hotRecommendId,
      });
    });
    if (sortItems.length) {
      await sortRecommend(sortItems);
    }
    this.loading = false;
  }
}
</script>
<style lang="less" module>
.hotRecommend {
  padding: 20px;
}
.card {
  margin: 30px 35px 0 0;
  .card__icon {
    cursor: pointer;
    &:hover {
      color: var(--font-active);
    }
  }
  .card__dragHandle {
    cursor: move;
    &.card__dragHandle--hot {
      margin-left: 25px;
    }
    &.card__dragHandle--disabled {
      cursor: not-allowed;
    }
  }
  .card__icon--delete {
    &:hover {
      color: var(--delete);
    }
  }
  .empty {
    flex: 1;
    display: flex;
  }
}
</style>
