<template>
  <a-form-model
    ref="form"
    :model="formData"
    :rules="readonly ? null : rules"
    :class="$style.form"
  >
    <div :class="$style.title">{{ $t('market.template.base') }}</div>
    <a-form-model-item prop="appName" :label="$t('market.template.appName')">
      <a-input v-model="formData.appName" :max-length="30">
        <template v-slot:suffix
          >{{ formData.appName ? formData.appName.length : 0 }}/30</template
        >
      </a-input>
    </a-form-model-item>
    <a-form-model-item
      prop="recommendStart"
      :label="$t('market.template.editStar')"
      :labelCol="{ sm: { span: 6 } }"
      label-align="left"
    >
      <a-rate
        @change="value => (formData.recommendStart = value * 10)"
        :value="formData.recommendStart / 10"
        allow-half
        :class="$style.rate"
      />
    </a-form-model-item>
    <a-form-model-item
      prop="instruction"
      :label="name + $t('market.template.instruction')"
    >
      <a-input v-model="formData.instruction" :max-length="30">
        <template v-slot:suffix
          >{{
            formData.instruction ? formData.instruction.length : 0
          }}/30</template
        >
      </a-input>
    </a-form-model-item>
    <a-form-model-item
      prop="price"
      :label="$t('market.template.priceSetting')"
      :class="$style.price"
    >
      <a-radio-group v-model="isFree">
        <a-radio :value="true">{{ $t('market.template.free') }}</a-radio>
        <a-radio :value="false">
          {{ $t('market.template.price') }}
        </a-radio>
      </a-radio-group>
      <a-input-number
        v-show="!isFree"
        v-model="price"
        :min="0"
        :precision="2"
      />
    </a-form-model-item>
    <a-form-model-item :label="$t('market.template.virtualInstalls')">
      <a-input-number v-model="formData.virtualInstallCount" />
    </a-form-model-item>
    <a-form-model-item
      prop="installScore"
      :label="$t('market.template.getPoints')"
    >
      <a-input v-model="formData.installScore" />
    </a-form-model-item>
    <div :class="$style.title">{{ $t('market.template.description') }}</div>
    <a-form-model-item
      prop="description"
      :label="$t('market.template.introduction')"
    >
      <div :class="$style.textarea">
        <a-textarea
          v-model="formData.description"
          :max-length="500"
          :rows="4"
        ></a-textarea>
        <span :class="$style.textarea__count"
          >{{
            formData.description ? formData.description.length : 0
          }}/500</span
        >
      </div>
    </a-form-model-item>
    <a-form-model-item prop="cover">
      <span slot="label">
        {{ $t('market.template.cover') }}
        <span>{{
          $t('market.template.recommendedSize', { value: '1920*1080' })
        }}</span>
      </span>
      <x-upload v-model="formData.cover" :max="1" :max-file-size="5"></x-upload>
    </a-form-model-item>
    <a-form-model-item prop="pcScreenshotList">
      <div slot="label">
        {{ $t('market.template.pcScreenshot')
        }}<span :class="$style.recommendedSize">
          {{
            $t('market.template.recommendedSize', { value: '1920*1080' })
          }}</span
        >
      </div>
      <x-upload
        v-model="formData.pcScreenshotList"
        :max="6"
        :max-file-size="5"
      ></x-upload>
    </a-form-model-item>
    <a-form-model-item prop="mobileScreenshotList">
      <div slot="label">
        {{ $t('market.template.mobileScreenshot')
        }}<span :class="$style.recommendedSize">
          {{
            $t('market.template.recommendedSize', { value: '375*812' })
          }}</span
        >
      </div>
      <x-upload
        v-model="formData.mobileScreenshotList"
        :max="6"
        :max-file-size="5"
      ></x-upload>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop, Provide } from 'vue-property-decorator';
import { createFormModal, UploadPathSymbol } from '@triascloud/x-components';
import {
  editTemplate,
  getTemplateDetail,
} from '@/services/template-manager/template';
import { globalVueI18n } from '@triascloud/i18n';
function createApplicationSettingModal(props) {
  return createFormModal(h => h(ApplicationSettingModal, { props }), {
    title:
      globalVueI18n.translate('market.template.application') +
      globalVueI18n.translate('market.template.setting'),
    width: 440,
  });
}
@Component
export default class ApplicationSettingModal extends Vue {
  static createModal = createApplicationSettingModal;
  @Prop({ type: String }) templateId;
  @Prop({ type: Function }) service;
  @Prop({ type: Boolean, default: false }) readonly;
  @Provide(UploadPathSymbol) uploadPath = 'common/screenshot/market/';
  formData = {
    id: '',
    appName: '',
    recommendStart: 0,
    instruction: '',
    price: '',
    cover: [],
    installScore: '',
    description: '',
    pcScreenshotList: [],
    mobileScreenshotList: [],
    virtualInstallCount: 0,
  };
  isFree = true;
  price = '';
  get rules() {
    return {
      appName: [
        {
          required: true,
          message:
            this.$t('common.placeholder.input') +
            this.$t('market.template.appName'),
        },
        {
          min: 2,
          max: 30,
          message: this.$t('common.validate.length', { value: '2~30' }),
        },
      ],
      cover: [
        {
          required: true,
          message: this.$t('market.template.coverImageTip'),
        },
      ],
      instruction: {
        max: 30,
        message: this.$t('market.template.introductionValidateTip'),
      },
      price: {
        pattern: /(^[0-9]\d*$)/,
        message: this.$t('market.template.priceValidateTip'),
      },
      description: [
        {
          required: true,
          message:
            this.$t('common.placeholder.input') +
            this.$t('market.template.introduction'),
        },
        {
          min: 2,
          max: 500,
          message: this.$t('common.validate.length', { value: '2~500' }),
        },
      ],
      installScore: {
        pattern: /(^[0-9]\d*$)/,
        message: this.$t('market.template.integralValidateTip'),
      },
    };
  }
  get name() {
    return this.$t('market.template.application');
  }
  get computedService() {
    return this.service || getTemplateDetail;
  }
  get formatFormData() {
    return {
      ...this.formData,
      price: !this.isFree && this.price ? this.price * 100 : 0,
      pcScreenshotList: this.formData.pcScreenshotList.map(
        item => item.ossPath,
      ),
      cover:
        this.formData.cover && this.formData.cover.length
          ? this.formData.cover[0].ossPath
          : this.formData.cover,
      mobileScreenshotList: this.formData.mobileScreenshotList.map(
        item => item.ossPath,
      ),
    };
  }
  async created() {
    const data = await this.computedService(this.templateId);
    this.formData = {
      ...data,
      installScore: data.installScore >= 0 ? data.installScore : '',
      virtualInstallCount:
        data.virtualInstallCount === -1 ? 0 : data.virtualInstallCount,
      recommendStart: data.recommendStart >= 0 ? data.recommendStart : 0,
      pcScreenshotList: data.pcScreenshotList
        ? data.pcScreenshotList.map(item => ({
            ossPath: item,
            name: '',
          }))
        : [],
      mobileScreenshotList: data.pcScreenshotList
        ? data.mobileScreenshotList.map(item => ({
            ossPath: item,
            name: '',
          }))
        : [],
      cover: data.cover ? [{ name: '', ossPath: data.cover }] : [],
      price: data.price === -1 ? 0 : data.price,
    };
    this.price =
      this.formData.price && this.formData.price !== -1
        ? this.formData.price / 100
        : '';
    this.isFree = !this.price;
  }
  async getValue() {
    await this.$refs.form.validate();
    await editTemplate(this.formatFormData);
    this.$message.success(this.$t('common.tips.save'));
  }
}
</script>
<style lang="less" module>
.form :global .ant-form-item {
  margin-bottom: 10px;
  .x-upload--fileList-oneFile-name,
  .x-upload--fileList-oneFile-icon-edit {
    display: none;
  }
}
.title {
  font-size: 16px;
  color: var(--font-sub);
  margin-bottom: 10px;
}
.price {
  :global(.ant-form-item-children) {
    display: flex;
    align-items: center;
    height: 32px;
  }
}
.textarea {
  position: relative;
  .textarea__count {
    position: absolute;
    bottom: 4px;
    right: 10px;
    color: var(--font-info);
  }
  :global .ant-input {
    height: 86px;
  }
}
</style>
