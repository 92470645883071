import request from '../request';
const basePath = '/smart-site/system/device';

export const getDeviceList = params =>
  request(`${basePath}/page`, {
    method: 'GET',
    body: params,
  });

export const getDeviceGroup = () => request(`${basePath}/deviceGroup`);

export const getDeviceStatistics = () => request(`${basePath}/statistics`);

export const addDevice = params =>
  request(`${basePath}`, {
    method: 'POST',
    body: params,
  });

export const editDevice = params =>
  request(`${basePath}`, {
    method: 'PUT',
    body: params,
  });

export const deleteDevice = deviceId =>
  request(`${basePath}/${deviceId}`, {
    method: 'DELETE',
  });
