<template>
  <div :class="$style.banner">
    <div :class="$style.imgBox">
      <x-oss-image :ossPath="data.logoUrl" :class="$style.image" />
    </div>
    <div :class="$style.imgMsg">
      <div :class="$style.title">
        <span :class="$style.label">{{ data.platformName }}</span>
        <span :class="$style.tip">{{
          this.$t('connector.accessPlatform.development')
        }}</span>
      </div>
      <div :class="$style.msg" v-html="html"></div>
    </div>
    <div :class="$style.imgBtn">
      <a-popover v-model="reviewVisible">
        <section style="width: 100px;" slot="content">
          <a-button
            v-if="data.productPending === false"
            style="display: block; width: 100px;"
            type="primary"
            @click="handleProduct"
            >{{ $t('connector.accessPlatform.product') }}</a-button
          >
          <a-button
            v-if="data.productPending === true"
            style="display: block; width: 100px;"
            type="primary"
            @click="handleProductReview"
            >{{ $t('connector.accessPlatform.productReview') }}</a-button
          >
          <a-button
            style="margin-top: 10px; display: block; width: 100px;"
            type="primary"
            >{{ $t('connector.accessPlatform.officialWebsite') }}</a-button
          >
        </section>
        <a-button type="primary">{{ this.$t('connector.release') }}</a-button>
      </a-popover>
    </div>
  </div>
</template>
<script>
import { Component, Vue, InjectReactive, Watch } from 'vue-property-decorator';
import { getPlatformData } from '@/services/iot-platform/platform';
import { markedHref } from '@/views/connector/platform-manage/components/platform-form.vue';
import { createFormModal, createModal } from '@triascloud/x-components';
import ReleaseProduct from './release-product.vue';
import { deleteRelease } from '@/services/iot-platform/releaseRecord';

@Component()
export default class banner extends Vue {
  @InjectReactive() id;

  data = {};
  @Watch('id', { immediate: true })
  async onIdChange(val) {
    if (val) {
      this.getPlatformData(val);
    }
  }

  async getPlatformData(id) {
    this.data = await getPlatformData(id);
  }

  get html() {
    return this.data.introduction ? markedHref(this.data.introduction) : '';
  }

  reviewVisible = false;
  handleProduct() {
    this.reviewVisible = false;
    const modal = createModal(
      () => (
        <ReleaseProduct
          id={this.id}
          close={() => modal.handleClose()}
          success={() => {
            modal.handleClose();
            this.getPlatformData(this.id);
          }}
        />
      ),
      {
        width: 550,
        title: this.$t('connector.accessPlatform.publishProduct'),
      },
    );
  }
  handleProductReview() {
    this.reviewVisible = false;
    createFormModal(
      () => <div>{this.$t('connector.accessPlatform.publisTips')}</div>,
      {
        title: this.$t('connector.accessPlatform.productReview'),
        width: 500,
        okText: this.$t('connector.accessPlatform.unpublish'),
        onOk: async () => {
          const res = await deleteRelease(this.id, 'PRODUCT');
          if (res) {
            this.$message.success(
              this.$t('common.tips.operationSuccess') + '！',
            );
            this.getPlatformData(this.id);
          }
        },
      },
    );
  }
}
</script>
<style lang="less" module>
.banner {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid var(--border);
  box-shadow: 0 0 6px 0 var(--shadow);
  border-radius: 4px;
  display: flex;
  .imgBox {
    .image {
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }
    margin-right: 20px;
  }
  .imgMsg {
    flex: 1;
    .title {
      .label {
        font-size: 16px;
        font-weight: 600;
        vertical-align: middle;
      }
      .tip {
        margin-left: 10px;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        border-radius: 4px;
        padding: 2px 16px;
        background-color: var(--font-active);
      }
    }
    .msg {
      font-size: 12px;
      color: var(--font-info);
      max-height: 100px;
      overflow: auto;
    }
  }
  .imgBtn {
    margin-left: 20px;
    .btnGroup {
      width: 100px;
      .btn {
        display: flex;
      }
    }
  }
}
</style>
