var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-upload',{class:_vm.$style.wrap,attrs:{"list-type":"picture-card","file-list":_vm.images,"beforeUpload":_vm.beforeUpload,"customRequest":_vm.customUpload,"accept":"image/*","disabled":_vm.isDisable},on:{"preview":_vm.handlePreview,"change":_vm.handleImgsChange}},[(_vm.images.length < _vm.maxCount && !_vm.isDisable)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}})],1):_vm._e()]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"title":"预览图片","wrapClassName":"viewModel"},on:{"cancel":_vm.handlePreviewCancel,"ok":_vm.handlePreviewOk}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})]),_c('a-modal',{attrs:{"visible":_vm.imageEditVisible,"maskClosable":false,"title":"裁剪图片","wrapClassName":"viewModel"},on:{"ok":_vm.handleImgResize,"cancel":function () { return _vm.handleCancel('cancel'); }}},[_c('div',{staticStyle:{"min-height":"300px"}},[(_vm.showCutImg)?_c('div',{staticStyle:{"margin-bottom":"5px","color":"var(--primary)"}},[_vm._v(" 裁剪尺寸："+_vm._s(_vm.cutImgSize)+" * "+_vm._s(_vm.cutImgSize)+" ")]):_vm._e(),_c('cropper',{ref:"cropper",attrs:{"src":_vm.previewImage,"stencil-size":{
          width: _vm.cutImgSize,
          height: _vm.cutImgSize,
        },"stencil-props":{
          handlers: {},
          movable: true,
          resizable: false,
          aspectRatio: 1,
        },"image-restriction":"stencil"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }