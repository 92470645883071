<template>
  <div :class="$style.manageBox">
    <div :class="$style.manageContent" slot="content">
      <div :class="$style.searchBox">
        <a-range-picker
          :show-time="{ format: 'YYYY-MM-DD' }"
          :ranges="presetTime"
          format="YYYY-MM-DD"
          v-model="dateRange"
          :allowClear="true"
          :placeholder="[
            $t('hat.clockRecord.startDate'),
            $t('hat.clockRecord.endDate'),
          ]"
          style="margin-right: 15px;"
          :disabled-date="disabledDate"
          @ok="handleSearchTime"
          @calendarChange="calendarPriceRangeChange"
        />
        <compony-select
          v-model="searchParams.tenantId"
          style="margin-right: 15px; width: 220px;"
          :allowClear="false"
          @change="v => handleCompanySelect(v)"
          :initialValue="{
            value: '',
            label: '全部企业',
          }"
        />
        <a-select
          v-model="searchParams.deviceId"
          placeholder="请选择"
          show-search
          option-filter-prop="children"
          :filter-option="true"
          style="width:  220px;"
          :disabled="!searchParams.tenantId"
          @change="handleDeviceSelect"
        >
          <a-select-option
            :value="item.deviceId"
            v-for="item in deviceList"
            :key="item.deviceId"
            >{{
              item.userName
                ? `${item.deviceName}(${item.userName})`
                : item.deviceName
            }}</a-select-option
          >
        </a-select>
      </div>
      <div :class="$style.chartBox">
        <div :class="$style.chartItem">
          <div :class="$style.chartTitle">
            <div :class="$style.name">
              <span @click="backLevelOne('IOT')">物联网卡流量</span>
              <span
                v-if="statisticsData['IOT'].tenantName"
                @click="backLevelTwo('IOT')"
                >{{ '>' + statisticsData['IOT'].tenantName }}</span
              >
              <span v-if="statisticsData['IOT'].deviceName">{{
                '>' + statisticsData['IOT'].deviceName
              }}</span>
            </div>
            <div :class="$style.des">
              累计使用：{{ statisticsData['IOT'].chartData.total }}Mb
            </div>
          </div>
          <bar-chart
            :chartData="statisticsData['IOT'].chartData"
            :isVertical="statisticsData['IOT'].chartData.isVertical"
            unit="Mb"
            @click-chart="handleClickChart($event, 'IOT')"
          />
        </div>
        <div :class="$style.chartItem">
          <div :class="$style.chartTitle">
            <div :class="$style.name">
              <span @click="backLevelOne('OSS')">设备文件云存储</span>
              <span
                v-if="statisticsData['OSS'].tenantName"
                @click="backLevelTwo('OSS')"
                >{{ '>' + statisticsData['OSS'].tenantName }}</span
              >
              <span v-if="statisticsData['OSS'].deviceName">{{
                '>' + statisticsData['OSS'].deviceName
              }}</span>
            </div>
            <div :class="$style.des">
              累计使用：{{ statisticsData['OSS'].chartData.total }}Mb(图片：{{
                statisticsData['OSS'].chartData.tagOneTotal
              }}Mb, 视频：{{ statisticsData['OSS'].chartData.tagTwoTotal }}Mb)
            </div>
          </div>
          <bar-chart
            :chartData="statisticsData['OSS'].chartData"
            :isVertical="statisticsData['OSS'].chartData.isVertical"
            unit="Mb"
            @click-chart="handleClickChart($event, 'OSS')"
          />
        </div>
        <div :class="$style.chartItem">
          <div :class="$style.chartTitle">
            <div :class="$style.name">
              <span @click="backLevelOne('RTC')">通话时长</span>
              <span
                v-if="statisticsData['RTC'].tenantName"
                @click="backLevelTwo('RTC')"
                >{{ '>' + statisticsData['RTC'].tenantName }}</span
              >
              <span v-if="statisticsData['RTC'].deviceName">{{
                '>' + statisticsData['RTC'].deviceName
              }}</span>
            </div>
            <div :class="$style.des">
              累计使用：{{ statisticsData['RTC'].chartData.total }}分钟(语音：{{
                statisticsData['RTC'].chartData.tagOneTotal
              }}分钟 视频：{{
                statisticsData['RTC'].chartData.tagTwoTotal
              }}分钟)
              <a-tooltip>
                <template slot="title">
                  1、基于设备通话明细中发布的语音、视频统计；<br />
                  2、由于音频和视频是独立发布/订阅，故单一设备的音频+视频时长可能大于等于实际在线时长；<br />
                  3、平台端用户发布的语音、视频不在统计范围内；<br />
                  4、当日的通话明细查询有一天延迟；
                </template>
                <a-icon type="question-circle" style="margin-left: 4px;" />
              </a-tooltip>
            </div>
          </div>
          <bar-chart
            :chartData="statisticsData['RTC'].chartData"
            :isVertical="statisticsData['RTC'].chartData.isVertical"
            unit="分钟"
            @click-chart="handleClickChart($event, 'RTC')"
          />
        </div>
        <div :class="$style.chartItem">
          <div :class="$style.chartTitle">
            <div :class="$style.name">
              <span @click="backLevelOne('MESSAGE')">短信用量</span>
              <span
                v-if="statisticsData['MESSAGE'].tenantName"
                @click="backLevelTwo('MESSAGE')"
                >{{ '>' + statisticsData['MESSAGE'].tenantName }}</span
              >
              <span v-if="statisticsData['MESSAGE'].deviceName">{{
                '>' + statisticsData['MESSAGE'].deviceName
              }}</span>
            </div>
            <div :class="$style.des">
              累计使用：{{ statisticsData['MESSAGE'].chartData.total }}条
            </div>
          </div>
          <bar-chart
            :chartData="statisticsData['MESSAGE'].chartData"
            :isVertical="statisticsData['MESSAGE'].chartData.isVertical"
            unit="条"
            @click-chart="handleClickChart($event, 'MESSAGE')"
          />
        </div>
      </div>
      <a-spin :spinning="loading" size="large" />
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import BarChart from './bar-chart.vue';
import moment from 'moment';
import { getUsageStatistics } from '@/services/hat/dosage-statistics';
import { getSystemDeviceList } from '@/services/hat/index';
import ComponySelect from '@/views/digital-site/components/compony-select.vue';

const DAY = 24 * 60 * 60 * 1000;
@Component({
  components: {
    LayoutContent,
    BarChart,
    ComponySelect,
  },
})
export default class HatStatistics extends Vue {
  searchParams = {
    startTime: '',
    endTime: '',
    deviceId: '',
    tenantId: '',
    levelEnum: 'TENANT',
    type: 'IOT',
  };
  dateRange = [
    // 默认显示一周   今天是最后一天
    this.$moment(new Date().getTime() - 30 * DAY).format('YYYY-MM-DD'),
    this.$moment(new Date().getTime()).format('YYYY-MM-DD'),
  ];
  mounted() {
    // this.getDeviceList();
    this.searchParams.startTime = this.$moment(this.dateRange[0]).valueOf();
    this.searchParams.endTime =
      this.$moment(this.dateRange[1]).valueOf() + DAY - 1000;
    this.getAllStatics();
  }
  loading = false;
  async getAllStatics(deviceId = '', levelEnum = 'TENANT', tenantId = '') {
    this.searchParams.levelEnum = levelEnum;
    try {
      this.loading = true;
      await Promise.all([
        this.getIotStatistics(deviceId, levelEnum, tenantId),
        this.getOssStatistics(deviceId, levelEnum, tenantId),
        this.getRtcStatistics(deviceId, levelEnum, tenantId),
        this.getMessageStatistics(deviceId, levelEnum, tenantId),
      ]);
    } finally {
      this.loading = false;
    }
  }
  deviceList = [
    {
      deviceId: '',
      deviceName: '全部设备',
    },
  ];
  async getDeviceList(tenantId = '') {
    this.deviceList = [
      {
        deviceId: '',
        deviceName: '全部设备',
      },
    ];
    const data = {
      current: 1,
      size: 500,
      tenantId,
      //   content: '',
    };
    const { records } = await getSystemDeviceList(data);
    this.deviceList = this.deviceList.concat(records);
  }
  async handleDeviceSelect(v) {
    const idx = this.deviceList.findIndex(item => v === item.deviceId);
    if (v) {
      Object.keys(this.statisticsData).forEach(item => {
        this.statisticsData[item].deviceName = this.deviceList[idx].deviceName;
      });
      this.getAllStatics(v, 'TIME', this.searchParams.tenantId);
    } else {
      Object.keys(this.statisticsData).forEach(item => {
        this.statisticsData[item].deviceName = '';
      });
      this.getAllStatics('', 'DEVICE', this.searchParams.tenantId);
    }
  }
  async handleCompanySelect(v) {
    await this.getDeviceList(v.id);
    if (v.id) {
      Object.keys(this.statisticsData).forEach(item => {
        this.statisticsData[item].tenantName = v.value;
        this.statisticsData[item].tenantId = v.id;
        this.searchParams.deviceId = '';
        this.statisticsData[item].deviceName = '';
      });
      this.getAllStatics('', 'DEVICE', v.id);
    } else {
      Object.keys(this.statisticsData).forEach(item => {
        this.statisticsData[item].tenantName = '';
        this.statisticsData[item].deviceName = '';
        this.searchParams.deviceId = '';
      });
      this.getAllStatics('', 'TENANT', '');
    }
  }
  statisticsData = {
    IOT: {
      chartData: {},
      tenantId: '',
      tenantName: '',
      deviceId: '',
      deviceName: '',
    },
    OSS: {
      chartData: {},
      tenantId: '',
      tenantName: '',
      deviceId: '',
      deviceName: '',
    },
    MESSAGE: {
      chartData: {},
      tenantId: '',
      tenantName: '',
      deviceId: '',
      deviceName: '',
    },
    RTC: {
      chartData: {},
      tenantId: '',
      tenantName: '',
      deviceId: '',
      deviceName: '',
    },
  };
  /** 物联网卡流量统计数据 */
  async getIotStatistics(deviceId, levelEnum, tenantId) {
    await this.getStatistics({
      deviceId,
      type: 'IOT',
      tagList: [''],
      levelEnum,
      tenantId,
    });
  }
  /** 设备文件云存储统计数据 */
  async getOssStatistics(deviceId, levelEnum, tenantId) {
    await this.getStatistics({
      deviceId,
      type: 'OSS',
      tagList: ['图片', '视频'],
      levelEnum,
      tenantId,
    });
  }
  /** 通话时长统计数据 */
  async getRtcStatistics(deviceId, levelEnum, tenantId) {
    await this.getStatistics({
      deviceId,
      type: 'RTC',
      tagList: ['语音', '视频'],
      levelEnum,
      tenantId,
    });
  }
  /** 短信用量统计数据 */
  async getMessageStatistics(deviceId, levelEnum, tenantId) {
    await this.getStatistics({
      deviceId,
      type: 'MESSAGE',
      tagList: [''],
      levelEnum,
      tenantId,
    });
  }
  /** 获取统计数据 */
  async getStatistics(obj) {
    const {
      deviceId,
      type,
      tagList = [''],
      levelEnum = 'TENANT',
      tenantId = '',
    } = obj;
    const postData = {
      startTime: this.searchParams.startTime,
      endTime: this.searchParams.endTime,
      levelEnum,
      deviceId,
      type,
      tenantId,
    };
    try {
      const { list, total } = await getUsageStatistics(postData);
      this.statisticsData[type].chartData = {
        levelEnum,
        isVertical: levelEnum === 'TIME' ? false : true,
        deviceId,
        deviceName: '',
        tenantName: '',
        nameList: [],
        dataList: tagList.map(item => ({
          tag: item,
          values: [],
        })),
        total,
        tagOneTotal: 0,
        tagTwoTotal: 0,
      };
      list.forEach(item => {
        this.statisticsData[type].chartData.nameList.unshift(item.titleName);
        if (item.detail.length) {
          this.statisticsData[type].chartData.tagOneTotal +=
            item.detail[0].value;
          this.statisticsData[type].chartData.tagTwoTotal +=
            item.detail[1].value;
          this.statisticsData[type].chartData.dataList[0].values.unshift({
            deviceId: item?.deviceId,
            tenantId: item?.tenantId,
            value: item?.detail[0].value,
            deviceName: item?.titleName,
            tenantName: item?.titleName,
          });
          this.statisticsData[type].chartData.dataList[1].values.unshift({
            deviceId: item?.deviceId,
            tenantId: item?.tenantId,
            value: item?.detail[1].value,
            deviceName: item?.titleName,
            tenantName: item?.titleName,
          });
        } else {
          this.statisticsData[type].chartData.dataList[0].values.unshift({
            deviceId: item?.deviceId,
            tenantId: item?.tenantId,
            value: item?.total,
            deviceName: item?.titleName,
            tenantName: item?.titleName,
          });
        }
      });
      if (levelEnum === 'TIME') {
        this.statisticsData[type].chartData.nameList.reverse();
        this.statisticsData[type].chartData.dataList[0]?.values.reverse();
        this.statisticsData[type].chartData.dataList[1]?.values.reverse();
      }
      this.statisticsData[type].chartData.tagOneTotal = this.statisticsData[
        type
      ].chartData.tagOneTotal.toFixed(2);
      this.statisticsData[type].chartData.tagTwoTotal = this.statisticsData[
        type
      ].chartData.tagTwoTotal.toFixed(2);
    } catch {
      this.statisticsData[type].chartData = {
        levelEnum: 'TENANT',
        isVertical: true,
        deviceId: '',
        deviceName: '',
        nameList: [],
        dataList: [],
        total: 0,
        tagOneTotal: 0,
        tagTwoTotal: 0,
      };
    }
  }
  apiEnum = {
    IOT: this.getIotStatistics,
    OSS: this.getOssStatistics,
    RTC: this.getRtcStatistics,
    MESSAGE: this.getMessageStatistics,
  };
  async handleClickChart(params, type) {
    const { tenantId, deviceId, deviceName, tenantName } = params.data;
    if (this.statisticsData[type].chartData.levelEnum === 'TENANT') {
      await this.apiEnum[type]('', 'DEVICE', tenantId);
      this.statisticsData[type].chartData.levelEnum = 'DEVICE';
      this.statisticsData[type].tenantName = tenantName;
      this.statisticsData[type].tenantId = tenantId;
    } else if (this.statisticsData[type].chartData.levelEnum === 'DEVICE') {
      await this.apiEnum[type](
        deviceId,
        'TIME',
        this.statisticsData[type].tenantId,
      );
      this.statisticsData[type].deviceId = deviceId;
      this.statisticsData[type].deviceName = deviceName;
      this.statisticsData[type].chartData.levelEnum = 'TIME';
    }
  }
  backLevelTwo(type) {
    this.statisticsData[type].deviceName = '';
    this.apiEnum[type]('', 'DEVICE', this.statisticsData[type].tenantId);
  }
  backLevelOne(type) {
    this.statisticsData[type].tenantName = '';
    this.statisticsData[type].deviceName = '';
    this.apiEnum[type]('', 'TENANT', '');
  }
  // 预设时间
  get presetTime() {
    return {
      [this.$t('generalView.filter.day')]: [
        moment().startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('screen.last3')]: [
        moment()
          .add(-2, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last7')]: [
        moment()
          .add(-6, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
    };
  }
  selectPriceDate = '';
  calendarPriceRangeChange(date) {
    this.selectPriceDate = date[0];
  }
  disabledDate(current) {
    const selectDate = this.$moment(this.selectPriceDate).valueOf();
    const offsetDays = 360 * DAY;
    // 禁用选中时间前后的{{offsetDays}}天
    return (
      this.$moment(current).valueOf() > selectDate + offsetDays ||
      this.$moment(current).valueOf() < selectDate - offsetDays ||
      current > this.$moment().endOf('day')
    );
  }
  handleSearchTime(time) {
    this.searchParams.startTime = this.$moment(time[0]).valueOf();
    this.searchParams.endTime = this.$moment(time[1]).valueOf() + DAY - 1000;
    this.getAllStatics(
      this.searchParams.deviceId,
      this.searchParams.levelEnum,
      this.searchParams.tenantId,
    );
  }
}
</script>
<style lang="less" module>
.manageBox {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  .manageContent {
    height: 100%;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    .searchBox {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .chartBox {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 20px;
      .chartItem {
        position: relative;
        .chartTitle {
          position: absolute;
          top: 6px;
          left: 5px;
          z-index: 99;
          .name {
            cursor: pointer;
            color: var(--font);
          }
          .des {
            font-size: 14px;
            color: var(--font-info);
          }
        }
      }
    }
  }
}
</style>
