<template>
  <a-form-model
    :class="$style.subscribeWrap"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-row
      :gutter="10"
      v-if="editFlag !== 'add'"
      style="margin: 0;width: 100%;display: flex;justify-content: end"
    >
      <a-select
        placeholder="请选择"
        v-model="form.version"
        style="width: 140px;"
        :getPopupContainer="triggerNode => triggerNode.parentNode"
        @change="chanegVersion"
      >
        <a-select-option
          v-for="item in historyData"
          :value="item.versionId"
          :key="item.version"
        >
          {{ getVersion(item) }}
        </a-select-option>
      </a-select>
    </a-row>
    <a-row :gutter="10" style="margin-bottom:15px;">
      <a-col :span="12">
        <a-form-model-item label="计划名称" prop="planName" :required="true">
          <a-input
            placeholder="请输入计划名称"
            :max-length="32"
            v-model="form.planName"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="所属产品" prop="product" :required="true">
          <selectProduct
            v-if="editFlag === 'add'"
            :platformId="platformId"
            v-model="form.productId"
            @change="changeProduct"
          />
          <a-input v-else v-model="form.product" :disabled="true" />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="10" v-if="form.product" style="margin-bottom:15px;">
      <a-col :span="18">
        <a-form-model-item prop="dataSources">
          <span slot="label">
            <span>数据来源</span>
            <span v-if="dataSourceType && dataSourceType === 'PASSIVE'">
              （被动触发：创建前请确认订阅计划和API配置能正常运行！）
            </span>
            <span v-if="dataSourceType && dataSourceType === 'TIMING'">
              （定时触发：需创建定时任务主动请求获取，请提前确认API配置并合理设置定时任务！）
            </span>
          </span>
          <a-select
            v-if="editFlag === 'add'"
            placeholder="请选择"
            v-model="form.dataSources"
            :filter-option="false"
            allowClear
            style="width: 455px;"
            @change="getConnectorDetail"
            :getPopupContainer="triggerNode => triggerNode.parentNode"
          >
            <a-select-option
              :value="`${item.pkId}-${item.triggerMethod}`"
              v-for="item in connectorList"
              :key="item.pkId"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-input v-else v-model="form.dataSourceName" :disabled="true" />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="10" style="margin-bottom:15px;">
      <a-col :span="12">
        <a-form-model-item label="在线判断" prop="statusTime">
          <a-select
            v-model="form.statusTime"
            style="width: 30%;margin-right: 10px"
          >
            <a-select-option
              v-for="item in [1, 2, 3, 4, 5, 10, 20, 30, 60]"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
          <span>分钟内有新数据即判定为在线</span>
        </a-form-model-item>
      </a-col>
    </a-row>
    <div :class="$style.subTitle" v-if="dataSourceType !== 'TIMING'">
      订阅主题
    </div>
    <div
      :class="[
        $style.topicsList,
        dataSourceType === 'TIMING' ? $style.hideTab : '',
      ]"
    >
      <a-tabs :activeKey="activeKey" @change="handleTabChange">
        <a-tab-pane
          v-for="(item, idx) of tabList"
          :key="item.id"
          :tab="item.name"
        >
          <Subscribe
            @change-name="name => changeThemeName(name, idx)"
            :ref="'subscribe-' + item.id"
            :topic="item"
            :editFlag="editFlag"
            :paramsIn="paramsIn"
            :paramsOut="paramsOut"
            :paramsBody="paramsBody"
            :paramsHeader="paramsHeader"
            :connectorType="dataSourceType"
            :productId="form.productId"
            :platformId="platformId"
          />
        </a-tab-pane>
        <div :class="$style.extraBtns" slot="tabBarExtraContent">
          <a-button
            v-if="tabList.length < 50"
            icon="plus"
            type="link"
            @click="addTopic"
            >新增
          </a-button>
          <a-button
            v-if="tabList.length > 1"
            style="color:red"
            icon="delete"
            type="link"
            @click="delTopic"
          ></a-button>
        </div>
      </a-tabs>
    </div>
    <div :class="$style.btn">
      <a-form-model-item>
        <a-button @click="close" :class="$style.bt">取消</a-button>
        <a-button @click="handleDebug" type="primary" :class="$style.bt"
          >调试</a-button
        >
        <a-button
          v-if="editFlag === 'edit'"
          type="primary"
          :class="$style.bt"
          @click="publishRun('save')"
        >
          保存
        </a-button>
        <a-button type="primary" @click="publishRun('run')">
          发布并运行
        </a-button>
      </a-form-model-item>
    </div>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import Subscribe from './subscribe-topic';
import ProductSelector from '../api-config/components/product-select.vue';
import { getTemplateById } from '@/services/iot-platform/dataTemplate';
import { ControlTypeEnum } from '../data-template/template-design/form/controls/enum.js';
import {
  getApiBasicSetting,
  getTriggerSettings,
} from '@/services/iot-platform/platformInterface';
import {
  PLACEHOLDER_IN,
  PLACEHOLDER_OUT,
  // variableReset,
} from '../api-config/components/utils';
import {
  addSubscribe,
  editChangeVersion,
  getApiList,
  getEditData,
  getHistoryData,
  saveChangeVersion,
} from '@/services/iot-platform/dataManage';
import {
  GetContentType,
  ReactiveStore,
  checkDefaultValue,
  fetchReleaseOption,
  flatFields,
  formatBody,
  requestURL,
  checkArrayType,
} from '../api-config/debug-api/utils';
import { deepClone, generateMap, uuid } from '@triascloud/utils';
import { createFormModal, createModal } from '@triascloud/x-components';
import Debug from './debug.vue';
import { getProductsNoPage } from '@/services/iot-platform/dpManager';
import ProductList from '@/views/connector/platform-manage/develop/device-manage/device-form/product-list.vue';
import SelectProduct from '@/views/connector/platform-manage/develop/device-manage/device-form/select-product.vue';
import { getVariables } from '@/services/iot-platform/variable';
import { eachJsonPath } from '../api-config/utils';

@Component({
  components: { SelectProduct, Subscribe, ProductSelector, ProductList },
})
export default class DataForm extends Vue {
  @Prop({ type: String, default: 'add' }) editFlag;
  @Prop({ type: String, default: '' }) subscribeId;
  @Prop({ type: String, default: '' }) editFlags;
  @Prop({ type: String, default: '' }) platformId;

  mounted() {
    if (this.editFlag === 'edit') {
      this.getHistory();
      this.getEditList();
    } else {
      this.activeKey = this.tabList[0].id;
      this.getProductData();
    }
  }
  close() {
    this.$emit('close');
  }
  refreshLists() {
    this.$emit('refreshList');
  }
  historyData = [];
  async getHistory() {
    try {
      this.historyData = await getHistoryData(this.subscribeId);
    } catch {
      return false;
    }
  }

  changeThemeName(name, index) {
    this.tabList[index].name = name;
  }

  async getEditList() {
    try {
      const res = await getEditData(this.subscribeId);
      if (res) {
        const form = this.form;
        form.planName = res.name;
        form.product = res.productName;
        form.productId = res.productId;
        // 设备在线判断
        if (res.customJson.length > 0) {
          const idx = res.customJson.findIndex(
            v => v.subType === 'ON_LINE_TIME',
          );
          form.statusTime = res.customJson[idx].intervalTime;
        } else {
          form.statusTime = 5;
        }
        form.dataSourceName = res.apiName;
        form.dataSources = `${res.idxPlatformApi}-${res.dataSources}`;
        form.currentVersion = res.currentVersionId;
        form.version = res.currentVersionId;
        this.tabList = res.dataAnalysisJson;
        this.activeKey = this.tabList[0].id;
        await this.getConnectorDetail(form.dataSources);
      }
    } catch {
      return false;
    }
  }

  chanegVersion(val) {
    const idx = this.historyData.findIndex(v => v.versionId === val);
    this.tabList = this.historyData[idx].dataAnalysisJson;
    this.activeKey = this.tabList[0].id;
  }

  getVersion(row) {
    if (row.versionId === this.form.currentVersion) {
      return `V${row.version}（当前）`;
    } else {
      return `V${row.version}`;
    }
  }

  async handleTabChange(tabKey) {
    const flag = await this.checkForm();
    const id = this.activeKey;
    const key = 'subscribe-' + id;
    const obj = this.$refs[key][0].themeData();
    const idx = this.tabList.findIndex(v => v.id === id);
    this.tabList.splice(idx, 1, obj);
    if (flag) {
      this.activeKey = tabKey;
    }
  }

  changeProduct(val) {
    this.form.product = val;
    this.form.productId = val;
    if (val) {
      this.getConnectors();
    } else {
      this.form.dataSources = undefined;
    }
  }

  productData = [];

  async getProductData() {
    try {
      this.productData = await getProductsNoPage(this.platformId);
    } catch {
      return false;
    }
  }

  async manageProduct() {
    const res = await createFormModal(
      () => <ProductList platformId={this.platformId} />,
      {
        title: '产品分类列表',
        width: 750,
        onCancel: async () => {
          await this.getProductData();
        },
      },
    );
    if (res.length > 0) {
      this.form.product = res[0].pkId;
      await this.getConnectors();
    } else {
      this.form.product = undefined;
    }
    await this.getProductData();
  }

  get dataSourceType() {
    return this.form.dataSources?.split('-')[1] || '';
  }

  activeKey = 0;
  form = {
    productType: 'service',
    statusTime: 5,
    planName: undefined,
    product: undefined,
    productId: '',
    dataSources: undefined,
    dataSourceName: '',
    currentVersion: 0,
    version: 0,
    dataTemplateId: '',
  };
  connectorList = [];
  hardwareList = [];
  loading = false;
  rules = {
    planName: [
      {
        required: true,
        message: '计划名称不能为空',
      },
    ],
    product: [
      {
        required: true,
        message: '所属产品不能为空',
      },
    ],
    dataSources: [
      {
        required: true,
        message: '数据来源不能为空',
      },
    ],
  };
  resData = [];
  templateList = [];

  tabList = [
    {
      id: uuid(),
      name: '新主题1',
    },
  ];

  async checkForm() {
    const key = 'subscribe-' + this.activeKey;
    await this.$refs[key][0].$refs.topicForm.validate();
    const obj = this.$refs[key][0].themeData();
    if (obj.customFields.length === 0) {
      this.$message.error('请至少添加一条自定义字段');
      return false;
    } else if (obj.storageRules !== 'EVERY_TIME') {
      if (obj.expressions.length === 0) {
        this.$message.error('请至少添加一条存储规则');
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  async addTopic() {
    const a = await this.checkForm();
    if (!a) return;
    const len = this.tabList.length;
    const o = {
      id: uuid(),
      name: '新主题' + (len + 1),
    };
    this.tabList.push(o);
    this.activeKey = this.tabList[len].id;
  }

  delTopic() {
    const idx = this.tabList.findIndex(v => v.id === this.activeKey);
    this.tabList.splice(idx, 1);
    const len = this.tabList.length;
    if (len > 0) {
      if (idx === len) {
        this.activeKey = this.tabList[idx - 1].id;
      } else {
        this.activeKey = this.tabList[idx].id;
      }
    }
  }

  /** @description 获取入参【key-value】结构 */
  getBobyParams(res) {
    try {
      const { requestUrlParam, requestBodyParam } = res;
      const bodyParams = requestBodyParam
        ? JSON.parse(requestBodyParam)
          ? JSON.parse(requestBodyParam).data
          : []
        : [];
      const r = {
        urlParams: requestUrlParam ? JSON.parse(requestUrlParam) : [],
        bodyParams,
      };
      const Method = res.requestMethod;
      const list = [].concat(r.urlParams).concat(r.bodyParams);
      checkArrayType(list, Method === 'POST' ? 'query' : '');
      const array = list.filter(v => v.pkId === v.id);

      const fieldKey = 'id';
      const data = generateMap(flatFields(array), fieldKey, item => {
        checkDefaultValue(item);
        return item.val;
      });
      const store = new ReactiveStore(data);
      return formatBody({
        list: array,
        key: fieldKey,
        store,
      });
    } catch (error) {
      this.$message.error(error);
      return {};
    }
  }

  async getHttpRquestParams(res, trigger, apiId) {
    try {
      const Method = res.requestMethod;
      const { requestUrlParam, requestBodyParam } = res;
      const bodyParams = requestBodyParam
        ? JSON.parse(requestBodyParam)
          ? JSON.parse(requestBodyParam).data
          : []
        : [];
      const r = {
        urlParams: requestUrlParam ? JSON.parse(requestUrlParam) : [],
        bodyParams,
      };
      const list = [].concat(r.urlParams).concat(r.bodyParams);
      checkArrayType(list, Method === 'POST' ? 'query' : '');
      const array = list.filter(v => v.pkId === v.id);

      const fieldKey = 'id';
      const data = generateMap(flatFields(array), fieldKey, item => {
        checkDefaultValue(item);
        return item.val;
      });
      const store = new ReactiveStore(data);

      let apiUrl = requestURL(res, array, store);
      const { records: variables } = await getVariables({
        platformId: this.platformId,
      });
      const { optBody, optHead, optUrl, optFnList } = await fetchReleaseOption({
        detail: res,
        list: array,
        store,
        apiId,
        trigger,
        variables,
        apiUrl,
      });
      const formatType = res.requestBodyParam
        ? JSON.parse(res.requestBodyParam).postFormat
        : '';
      return {
        apiBody: optBody,
        apiHead: optHead,
        apiRequestMethod: Method,
        apiUrl: optUrl,
        contentType: GetContentType(Method, formatType),
        functionList: optFnList,
      };
    } catch {
      return false;
    }
  }

  mqtt = {};
  httpRequest = {};
  paramsIn = [];
  paramsBody = [];
  paramsHeader = [];
  requestMethod = '';

  async getConnectorDetail(val) {
    this.mqtt = {};
    this.httpRequest = {};
    this.paramsIn = [];
    this.paramsBody = [];
    if (val) {
      try {
        const v = val?.split('-')[0];
        const res = await getApiBasicSetting(v);
        const trigger = await getTriggerSettings(v);
        this.requestMethod = res.requestMethod;
        if (res.subscriptionType === 'AMQP') {
          const { mqtt } = this.getBobyParams(res);
          this.mqtt = mqtt;
        } else {
          this.httpRequest = await this.getHttpRquestParams(res, trigger, v);
        }
        this.paramsIn =
          res.requestUrlParam === '' ? [] : JSON.parse(res.requestUrlParam);
        this.paramsHeader =
          res.requestHeaderParam === ''
            ? []
            : JSON.parse(res.requestHeaderParam);
        this.paramsBody =
          res.requestBodyParam === '' ? [] : JSON.parse(res.requestBodyParam);
        const form = this.form;
        form.templateRelay = res.dataTemplateId
          ? res.dataTemplateId
          : undefined;
        // form.templateRelay = '1630837021118021634';
        if (res.dataTemplateId) {
          await this.getTemplate();
        }
      } catch {
        return false;
      }
    }
  }
  paramsOut = [];
  async getTemplate(id = '') {
    this.paramsOut = [];
    const tId = id ? id : this.form.templateRelay;
    const res = await getTemplateById(tId);
    // const res = await getTemplateById('1630837021118021634');
    const result = res.map(v => {
      return {
        ...v,
        name: v.fieldName,
        type: v.fieldType,
        widget: JSON.parse(v.widget),
      };
    });
    const showArr = result.filter(v => v.widget?.isTabs !== true);
    this.paramsOut = this.changeParamArr(
      showArr[0].widget.tabs[1].fields,
      'res',
      'out',
    );
  }

  changeParamArr(arr, flag, dpFlag = 'in') {
    if (arr.length === 0) return;
    const str =
      flag !== 'res'
        ? this.$t('connector.api.debuggerInput')
        : this.$t('connector.api.supportFixedValue');
    return arr.map(v => {
      const o = this.filedsMap(v, false, str);
      if (dpFlag !== 'in' && o.type !== ControlTypeEnum.DataPoint) {
        o.val = `$.${o.identifier}`;
      }
      if (v.type !== ControlTypeEnum.DataPoint) {
        if (v.widget?.fields?.length > 0) {
          o['children'] = v.widget.fields.map(x => {
            return this.filedsMap(x, true, str, v.pkId);
          });
          if (dpFlag !== 'in') {
            eachJsonPath(o.children, o.identifier);
          }
        }
      } else {
        if (v.widget.selectedDp.length > 0) {
          o['children'] = v.widget.selectedDp.map(x => {
            return this.filedsMapDp(x, true, '', v.pkId, dpFlag);
          });
          if (dpFlag !== 'in') {
            eachJsonPath(o.children, '');
          }
        }
      }
      return o;
    });
  }

  filedsMapDp(v, flag, str, id = '', dpflag) {
    let children = [];
    if (v.children && v.children.length > 0) {
      children = v.children[0].children && v.children[0].children;
      // DP的类型为【当前数据组】，只有一个child，需要设置child的【identifier】为父的【identifier】
      if (
        v.children[0].type.toLocaleLowerCase() ===
        ControlTypeEnum.CurrentDataSet.toLocaleLowerCase()
      ) {
        children = children[0].children;
      }
    }
    const o = {
      pkId: flag !== true ? v.pkId : id,
      name: v.functionName,
      type: v.type,
      open: true,
      isChildren: flag,
      isEdit: false,
      required: v.isRequired,
      identifier: v.functionIdentifier,
      dpType: true,
      val: dpflag === 'in' ? PLACEHOLDER_IN : PLACEHOLDER_OUT,
      children: children,
    };
    // 【DP数组组件】
    if (v.type === ControlTypeEnum.Array) {
      const item = v.children[0].children && v.children[0].children[0];
      if (item && item.details && item.details.subType) {
        if (Object.keys(item.details).length > 0) {
          o['details'] = item.details;
        }
      }
    }
    // 【DP的事件、服务】type为空，设置为结构体struct
    if (o.type === '') {
      o.type = 'struct';
    }
    // 【DP】的文本组件children的属性name为空
    o.children.forEach(child => {
      if (!child.name) {
        child.name = o.name;
      }
    });
    o['id'] = v.dataPointId;
    return o;
  }
  filedsMap(v, flag, str, id = '') {
    const o = {
      pkId: flag !== true ? v.pkId : id,
      name: v.name,
      type: v.type,
      open: true,
      isChildren: flag,
      isEdit: false,
      dpType: false,
      required: v.widget.required,
      identifier: v.widget.identifier,
      val: '',
    };
    o['id'] = v.pkId;
    if (v.type === ControlTypeEnum.Array) {
      o['subType'] = v.widget.subType;
      o['subCount'] = v.widget.subCount;
      o.val = str;
    } else if (v.type === ControlTypeEnum.Date) {
      o.val = v.widget.defaultValue.custom
        ? this.$moment(v.widget.defaultValue.custom).format(v.widget.format)
        : str;
      o.defaultType = v.widget.defaultType;
      o.format = v.widget.format;
      if (v.widget.paramFormat) {
        o.format = v.widget.paramFormat;
      }
      o.outFormat = v.widget.outFormat;
    } else if (v.type === ControlTypeEnum.DataPoint) {
      o.val = '按DP点结构解析';
      o.paramInFormat = v.widget.paramInFormat;
      o.paramInTag = v.widget.paramInTag;
    } else if (v.type === ControlTypeEnum.Switch) {
      o.val = v.widget.defaultValue.custom;
    } else {
      o.val =
        v.widget.defaultValue.custom && v.widget.defaultValue.custom.length > 0
          ? v.widget.defaultValue.custom
          : str;
    }
    o['inParams'] = v.widget.inParams;
    o['outParams'] = v.widget.outParams;
    o['description'] = v.widget.description;
    if (v.type === ControlTypeEnum.Enum) {
      o['isSingle'] = v.widget.isSingle;
      // 枚举的（value）数值类型
      o['enumValueType'] = v.widget.enumValueType;
      o['optionList'] = v.widget.optionList;
      if (v.widget.defaultValue.custom.length > 0) {
        if (v.widget.isSingle) {
          const idx = v.widget.optionList.findIndex(
            s => s.id === v.widget.defaultValue.custom,
          );
          o.val = v.widget.optionList[idx].value;
        } else {
          let str = '';
          for (let i = 0; i < v.widget.optionList.length; i += 1) {
            for (let j = 0; j < v.widget.defaultValue.custom.length; j++) {
              if (
                v.widget.optionList[i].id === v.widget.defaultValue.custom[j]
              ) {
                str += `${v.widget.optionList[i].value}，`;
              }
            }
          }
          o.val = str.substring(0, str.length - 1);
        }
      } else {
        o.val = str;
      }
    }
    if (v.type === ControlTypeEnum.Switch) {
      o['is'] = v.widget.is;
      o['not'] = v.widget.not;
      o.val = v.widget.defaultValue.custom;
    }
    if (v.type === ControlTypeEnum.Image || v.type === ControlTypeEnum.File) {
      o['maxCount'] = v.widget.maxCount;
      o['maxSize'] = v.widget.maxSize;
      o['isStore'] = v.widget.isStore;
      o['storeSize'] = v.widget.storeSize;
      o['source'] = v.widget.source || '';
    }
    if (v.type === ControlTypeEnum.Input) {
      o.defaultType = v.widget.defaultType;
      o.ruleType = v.widget.ruleType;
      o.ruleLength = v.widget.ruleLength;
      o.variable = v.widget.variable;
    }
    return o;
  }

  apiTypeText = {
    PASSIVE: '被动',
    TIMING: '定时',
  };
  apiTypeMethod = {};

  async getConnectors() {
    this.apiTypeMethod = {};
    const params = {
      productId: this.form.product,
      platformId: this.platformId,
    };
    const res = await getApiList(params);
    this.connectorList = res.map(v => {
      this.apiTypeMethod[v.pkId] = v.triggerMethod;
      return {
        pkId: v.pkId,
        triggerMethod: v.triggerMethod,
        name: `${v.name}(${this.apiTypeText[v.triggerMethod]})`,
      };
    });
  }

  /** @description 获取主题的字段列表 */
  getSubscribeFields(activeKey) {
    const row = this.tabList.find(v => v.id === activeKey);
    const data = deepClone(row);
    let result = [];
    if (data && data.customFields.length > 0) {
      result = data.customFields[0].echoData.data;
    }
    return result;
  }

  handleDebug() {
    const themes = this.tabList.map(tab => {
      return {
        id: tab.id,
        name: tab.name,
      };
    });
    createModal(
      () => (
        <Debug themes={themes} fnFields={key => this.getSubscribeFields(key)} />
      ),
      {
        title: '调试订阅',
        width: 960,
      },
    );
  }

  async handlePublishDialog(flag) {
    let res = false;
    const cvId = this.form.currentVersion;
    const vId = this.form.version;
    const version = (flag, id) => {
      let row = this.historyData.find(i => i.versionId === id);
      if (!row) {
        row = { version: '1' };
      } else {
        let v;
        if (flag === 'run') {
          v = this.historyData[0].version + 1;
        } else {
          v = row.version;
        }
        row = { version: v };
      }
      return `V${row.version}`;
    };
    const str1 = (
      <div>
        <p class={this.$style.decTitle}>
          将发布并运行新版本{version(flag, vId)}，确认发布？
        </p>
        <p class={this.$style.decDec}>
          提示：最多保存5个版本！发布新版本后，请重新检查设备关联的订阅数据是否存在异常！
        </p>
      </div>
    );
    const str2 = (
      <div>
        <p class={this.$style.decTitle}>
          当前运行版本为{version(flag, cvId)}，确认保存并运行版本
          {version(flag, vId)}？
        </p>
        <p class={this.$style.decDec}>
          提示：切换版本后，请检查设备关联的订阅数据是否存在异常！
        </p>
      </div>
    );
    let str = str1;
    if (flag === 'save') {
      if (cvId !== vId) {
        str = str2;
      } else {
        return true;
      }
    }
    await createFormModal(() => str, {
      width: 430,
      title: flag === 'run' ? '发布订阅' : '保存并运行',
      onOk: () => {
        res = true;
      },
    });

    return res;
  }
  async publishRun(flag) {
    await this.$refs.form.validate();
    const id = this.activeKey;
    const key = 'subscribe-' + id;
    const obj = this.$refs[key][0].themeData();
    if (this.dataSourceType === 'TIMING') {
      if (obj.pageParams && obj.pageParams.pageType !== 'FIXED') {
        await this.$refs[key][0].$refs.topicForm.validateField(
          'topicForm.pageTotalPath',
        );
      }
      await this.$refs[key][0].$refs.topicForm.validate();
    }
    if (this.dataSourceType === 'TIMING') {
      if (obj.polling === 'CUSTOM' && !obj.deviceIds.length) {
        this.$message.error('请至少添加一个自定义设备');
        return;
      }
      if (obj.polling !== 'CLOSE') {
        if (!obj.deviceParams.length) {
          this.$message.error('请至少添加一条关联设备参数');
          return;
        }
      }
    }
    if (obj.customFields.length === 0) {
      this.$message.error('请至少添加一条自定义字段');
      return;
    }
    if (obj.storageRules !== 'EVERY_TIME') {
      if (obj.expressions.length === 0) {
        this.$message.error('请至少添加一条存储规则');
        return;
      }
    }
    const ff = await this.handlePublishDialog(flag);
    if (!ff) return;
    let subscribe = [];
    const form = this.form;
    const params = {
      name: form.planName,
      subCustomConfigs: [
        {
          subType: 'ON_LINE_TIME', // 枚举值写死
          intervalTime: this.form.statusTime, // 几分钟一次
        },
      ],
    };
    if (this.dataSourceType !== 'TIMING') {
      if (this.editFlag === 'add') {
        for (let i = 0; i < this.tabList.length; i++) {
          const key = 'subscribe-' + this.tabList[i].id;
          const o = this.$refs[key][0].themeData();
          subscribe.push(o);
        }
      } else {
        const idx = this.tabList.findIndex(v => v.id === id);
        this.tabList.splice(idx, 1, obj);
        subscribe = this.tabList;
      }
      params['themes'] = subscribe;
    } else {
      params['timing'] = obj;
    }
    if (this.editFlag === 'add') {
      params['platformApiId'] = form.dataSources.split('-')[0];
      if (this.dataSourceType !== 'TIMING') {
        params['mqtt'] = this.mqtt;
      } else {
        params['httpRequest'] = this.httpRequest;
      }
      await addSubscribe(params);
      this.$message.success('保存成功');
    } else {
      if (flag === 'run') {
        params['pkId'] = this.subscribeId;
        await editChangeVersion(params);
        this.$message.success('发布成功');
      } else {
        params['subscribeId'] = this.subscribeId;
        params['versionId'] = form.version;
        await saveChangeVersion(params);
        this.$message.success('保存并运行成功');
      }
    }
    this.refreshLists();
    this.close();
  }
}
</script>

<style lang="less" module>
.subscribeWrap {
  .pr10 {
    padding-right: 10px;
  }

  .pl10 {
    padding-left: 10px;
  }

  .hideTab {
    :global(.ant-tabs-top-bar) {
      display: none !important;
    }
  }

  .btn {
    margin: 10px;
    text-align: end;

    .bt {
      margin-right: 10px;
    }
  }

  .topicsList {
    :global(.ant-tabs-top-bar) {
      display: flex;
      flex-direction: row-reverse;
    }

    :global(.ant-tabs-extra-content) {
      flex: 1;
    }

    .extraBtns {
      margin-top: 4px;
      margin-left: 15px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .operationTips {
      color: #999;
      font-size: 12px;
    }
  }

  :global(.ant-form-vertical .ant-form-extra) {
    color: var(--font-info) !important;
  }

  .subTitle {
    color: var(--font);
    font-size: 16px;
    line-height: 16px;
    margin: 10px 0 5px 0;

    &::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}
.decTitle {
  margin-bottom: 6px;
  font-size: 14px;
}
.decDec {
  font-size: 12px;
  color: var(--warning);
}
</style>
