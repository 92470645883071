var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.index},[_c('x-data-table',{ref:"table",class:_vm.$style.table,attrs:{"base-path":"/template-manager/solution/admin/management/loadApprovalRequestForm","columns":_vm.columns,"service":_vm.service,"custom-row":function (record) { return ({
        style: {
          cursor: 'pointer',
        },
      }); },"scroll":{
      x: '100%',
      y: '100%',
    }}},[_c('template',{slot:"headerLeft"},[_c('a-input',{class:_vm.$style.toolbar__input,on:{"pressEnter":function($event){return _vm.handleTableKeywordChange($event, _vm.$table)},"blur":function($event){return _vm.handleTableKeywordChange($event, _vm.$table)}},model:{value:(_vm.searchData.keyword),callback:function ($$v) {_vm.$set(_vm.searchData, "keyword", $$v)},expression:"searchData.keyword"}},[_c('x-icon',{attrs:{"slot":"prefix","type":"tc-icon-search"},slot:"prefix"})],1),_c('a-select',{class:_vm.$style.toolbar__select,attrs:{"placeholder":_vm.$t('common.label.all')},on:{"change":_vm.typeSelectChange},model:{value:(_vm.searchData.solutionClassify),callback:function ($$v) {_vm.$set(_vm.searchData, "solutionClassify", $$v)},expression:"searchData.solutionClassify"}},_vm._l((_vm.templateTypes),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.text))])}),1),_c('a-range-picker',{class:_vm.$style.toolbar__picker,attrs:{"placeholder":[
          ((_vm.$t('common.label.year')) + "-" + (_vm.$t('common.label.month')) + "-" + (_vm.$t(
            'common.label.day'
          ))),
          ((_vm.$t('common.label.year')) + "-" + (_vm.$t('common.label.month')) + "-" + (_vm.$t(
            'common.label.day'
          ))) ],"separator":_vm.$t('common.label.to')},on:{"change":_vm.handlerDatePickerChange}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }