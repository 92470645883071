<template>
  <div :class="$style.label">
    <div :class="$style['form-item-label']">
      {{ $t('dictionary.tag') }}
    </div>
    <div :class="[$style['form-item-tag'], $style['form-item-tag--selected']]">
      <a-tag
        v-for="item in value"
        :key="item.pkId"
        :class="$style.tag"
        :style="{
          backgroundColor: item.color || '#ffe9d1',
        }"
        closable
        @close="handleRemoveLabel(item)"
        >{{ item.name || $t('common.tips.deleted') }}</a-tag
      >
    </div>
    <div :class="[$style['form-item-label'], $style.search]">
      <div>{{ $t('dictionary.selectTag') }}</div>
      <a-input
        allow-clear
        v-model="keyword"
        :class="$style.searchInput"
        :placeholder="$t('common.action.search') + $t('common.label.name')"
      >
        <x-icon slot="prefix" type="tc-icon-search" />
      </a-input>
    </div>
    <a-spin :spinning="loading" :class="$style['form-item-tag']">
      <a-popover v-model="addVisible" placement="bottom" trigger="click">
        <div slot="content" :class="$style['form-popoverInput']">
          <a-input
            v-model="labelName"
            :max-length="10"
            :class="$style['form-popoverInput__input']"
            auto-focus
            @pressEnter="addLabel"
          >
            <span slot="suffix">{{ labelName.length }}/10</span>
          </a-input>
          <a-button
            type="primary"
            :class="$style['form-popoverInput__button']"
            @click="addLabel"
            >{{ $t('common.action.confirm') }}</a-button
          >
        </div>
        <a-button type="primary" icon="plus" :class="$style['tag-add']">{{
          $t('dictionary.addTag')
        }}</a-button>
      </a-popover>
      <a-tag
        v-for="item in filterLabels"
        :key="item.pkId"
        :class="[$style.tag, { [$style['tag--active']]: valueMap[item.pkId] }]"
        :style="{ backgroundColor: item.color }"
        @click="handelCheckLabel(item)"
        >{{ item.name }}</a-tag
      >
    </a-spin>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { addLabel, getLabelList } from '@/services/template-manager/label';
import { generateMap } from '@triascloud/utils';
import { LabelColors } from '@/enum/label';
@Component()
export default class LabelSelect extends Vue {
  @Prop({ type: Array }) value;
  labelName = '';
  keyword = '';
  get valueMap() {
    return generateMap(this.value, 'pkId', _ => _);
  }
  get labelMap() {
    return generateMap(this.labels, 'pkId', _ => _);
  }
  get filterLabels() {
    return this.keyword
      ? this.labels.filter(item => item.name.includes(this.keyword))
      : this.labels;
  }
  created() {
    this.getLabel();
  }
  loading = false;
  labels = [];
  async getLabel() {
    this.loading = true;
    this.labels = await getLabelList();
    this.loading = false;
  }
  addVisible = false;
  async addLabel() {
    if (!this.labelName.trim()) return;
    await addLabel({
      name: this.labelName,
      color: LabelColors[Math.floor(Math.random() * 5)],
    });
    this.addVisible = false;
    this.labelName = '';
    this.getLabel();
  }
  handelCheckLabel(label) {
    if (this.value.length >= 5) {
      return this.$message.warning(this.$t('dictionary.tagMax'));
    }
    if (!this.valueMap[label.pkId]) {
      this.$emit('input', [...this.value, { ...label }]);
    }
  }
  handleRemoveLabel(id) {
    this.$emit(
      'input',
      this.value.filter(item => item !== id),
    );
  }
}
</script>
<style lang="less" module>
.form-item-label {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 28px;
  color: var(--font-info);
}
.form-item-tag {
  border: 1px solid var(--form-border);
  padding: 6px;
  border-radius: 4px;
  height: 160px;
  overflow-y: auto;
  &.form-item-tag--selected {
    height: 80px;
    margin-bottom: 10px;
  }
}
.tag-add {
  height: 30px;
  margin-right: 10px;
}
.label .tag {
  height: 30px;
  color: #fff;
  cursor: pointer;
  margin: 5px 10px 5px 0;
  padding: 6px 12px;
  line-height: 16px;
  min-width: 70px;
  text-align: center;
  &:last-child {
    margin-right: 0;
  }
  :global(.anticon-close) {
    color: #fff;
  }
}
.form-popoverInput {
  display: flex;
  .form-popoverInput__input {
    flex: 1;
  }
  .form-popoverInput__button {
    height: 30px;
    margin-left: 10px;
  }
}
.search {
  display: flex;
  margin-bottom: 10px;
  .searchInput {
    width: 180px;
    margin-left: 10px;
  }
}
</style>
