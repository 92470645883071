<script>
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { uuid } from '@triascloud/utils';
import {
  DATATYPE,
  types,
  unitArray,
  identifier,
  limitTxtLength,
} from '../../utils';

const offsetCol = { span: 20, offset: 4 };

@Component()
export default class AddForm extends Vue {
  /** @name 幂等id */
  idempotent = '';
  mounted() {
    this.idempotent = uuid();
    if (!this.isEdit && !this.isArray) {
      this.form.type = DATATYPE.int;
      this.defaultValue();
      this.addNumberFormScheme();
    }
  }
  beforeDestroy() {
    this.idempotent = '';
  }

  form = {
    name: '',
    identifier: '',
    type: '',
  };
  get zTypes() {
    return types(this).map(item => {
      return {
        desc: item.desc,
        key: item.key,
        value: item.value,
      };
    });
  }
  get Types() {
    if (this.isArray) {
      return this.list.map(v => {
        const item = this.zTypes.find(i => i.key === v.dataType);
        if (item) {
          return item;
        } else {
          return {};
        }
      });
    } else {
      return this.zTypes;
    }
  }

  oldTypeValue = '';
  oldForm = {};
  @Watch('form', { deep: true })
  onTypeWatch(value, oldValue) {
    this.oldTypeValue = oldValue.type;
    this.oldForm = oldValue;
  }

  isArray = false;
  @PropSync('list', { type: Array }) dataList;
  @Watch('dataList', { immediate: true })
  onListChange(val) {
    if (val) {
      this.isArray = true;
      this.form.type = val[0].dataType;
      this.onChangeType(this.form.type);
    }
  }
  changeFormData(type) {
    const item = this.dataList.find(i => i.dataType === type);
    if (!item) return;
    item.customizeName = this.oldForm.name;
    item.thingIdentifier = this.oldForm.identifier;
    switch (type) {
      case DATATYPE.int:
      case DATATYPE.float:
      case DATATYPE.double:
        item.dataSpecs.minIdentifier = this.oldForm.min;
        item.dataSpecs.maxIdentifier = this.oldForm.max;
        item.dataSpecs.stepFlag = this.oldForm.isStepOpen;
        item.dataSpecs.step.customizeName = this.oldForm.customStepName;
        item.dataSpecs.step.identifier = this.oldForm.customStepMark;
        item.dataSpecs.unitFlag = this.oldForm.isUnitOpen;
        item.dataSpecs.unit.customizeName = this.oldForm.customUnitName;
        item.dataSpecs.unit.identifier = this.oldForm.customUnitMark;
        item.dataSpecs.unit.source = this.oldForm.unitSource;
        item.dataSpecs.unit.unitName =
          this.oldForm.unitSource === 'SYSTEM'
            ? this.oldForm.unit
            : this.oldForm.customUnit;
        break;
      case DATATYPE.text:
        item.dataSpecs.max = this.oldForm.max;
        break;
      case DATATYPE.boolean:
        item.dataSpecs.maxLength = this.oldForm.max;
        break;
      case DATATYPE.enum:
        item.dataSpecs.amount = this.oldForm.enumCount;
        item.dataSpecs.enumValueType = this.oldForm.enumValueType;
        break;
      case DATATYPE.date:
        item.dataSpecs.storage = 'UTC';
        item.dataSpecs.precision = 'THIRTEEN';
        break;
      case DATATYPE.struct:
      case DATATYPE.array:
        item.dataSpecs.sonParamsName = this.oldForm.paramName;
        item.dataSpecs.sonParamsIdentifier = this.oldForm.paramIdentifier;
        item.dataSpecs.sonParamsSpecs = this.oldForm.paramGroup;
        break;
      default:
        break;
    }
  }
  updateFormData(type) {
    const item = this.dataList.find(i => i.dataType === type);
    this.form.name = item.customizeName;
    this.form.identifier = item.thingIdentifier;
    switch (type) {
      case DATATYPE.int:
      case DATATYPE.float:
      case DATATYPE.double:
        this.form.min = item.dataSpecs.minIdentifier;
        this.form.max = item.dataSpecs.maxIdentifier;
        this.form.isStepOpen = item.dataSpecs.stepFlag;
        this.form.customStepName = item.dataSpecs.step.customizeName;
        this.form.customStepMark = item.dataSpecs.step.identifier;
        this.form.isUnitOpen = item.dataSpecs.unitFlag;
        this.form.customUnitName = item.dataSpecs.unit.customizeName;
        this.form.customUnitMark = item.dataSpecs.unit.identifier;
        this.form.unitSource = item.dataSpecs.unit.source;
        this.form.unit =
          item.dataSpecs.unit.source === 'SYSTEM'
            ? item.dataSpecs.unit.unitName
            : '';
        this.form.customUnit =
          item.dataSpecs.unit.source !== 'SYSTEM'
            ? item.dataSpecs.unit.unitName
            : '';
        break;
      case DATATYPE.text:
        this.form.max = item.dataSpecs.max;
        break;
      case DATATYPE.boolean:
        this.form.max = item.dataSpecs.maxLength;
        break;
      case DATATYPE.enum:
        this.form.enumCount = item.dataSpecs.amount;
        // TODO: 需要后端同步
        this.form.enumValueType = item.dataSpecs.enumValueType;
        break;
      case DATATYPE.date:
        this.form.storeType = item.dataSpecs.storage ? true : false;
        this.form.timeUnit = item.dataSpecs.precision ? true : false;
        break;
      case DATATYPE.struct:
      case DATATYPE.array:
        this.form.paramName = item.dataSpecs.sonParamsName;
        this.form.paramIdentifier = item.dataSpecs.sonParamsIdentifier;
        this.form.paramGroup = item.dataSpecs.sonParamsSpecs;
        break;
      default:
        break;
    }
  }

  isEdit = false;
  @Prop({ type: Object, default: () => {} }) detail;
  @Watch('detail', { immediate: true })
  onDetailChange(val) {
    if (val) {
      this.isEdit = true;

      this.form.type = val.dataType;
      this.formatData(val);
    }
  }

  formatData(val) {
    this.updateFormScheme(val.dataType);
    this.form.identifier = val.thingIdentifier;
    this.form.name = val.customizeName;
    const ds = JSON.parse(val.dataSpecs);
    switch (this.form.type) {
      case DATATYPE.int:
      case DATATYPE.float:
      case DATATYPE.double:
        {
          this.form.min = ds.minIdentifier;
          this.form.max = ds.maxIdentifier;
          const systemFlag = ds.unit.source === 'SYSTEM' ? true : false;
          this.form.aide = 'stepSize';
          this.form.isStepOpen = ds.stepFlag;
          this.form.isUnitOpen = ds.unitFlag;
          this.form.customStepName = ds.step.customizeName;
          this.form.customUnitName = ds.unit.customizeName;
          this.form.customStepMark = ds.step.identifier;
          this.form.customUnitMark = ds.unit.identifier;
          this.form.unitSource = ds.unit.source;
          this.form.unit = systemFlag ? ds.unit.unitName : '';
          this.form.customUnit = !systemFlag ? ds.unit.unitName : '';
        }
        break;
      case DATATYPE.text:
        this.form.max = ds.max;
        break;
      case DATATYPE.date:
        this.form.storeType = true;
        this.form.timeUnit = true;
        break;
      case DATATYPE.boolean:
        this.form.max = ds.maxLength;
        break;
      case DATATYPE.enum:
        this.form.enumCount = ds.amount;
        // TODO: 需要后端同步
        this.form.enumValueType = ds.enumValueType;
        break;
      case DATATYPE.struct:
      case DATATYPE.array:
        this.form.paramName = ds.sonParamsName;
        this.form.paramIdentifier = ds.sonParamsIdentifier;
        this.form.paramGroup = ds.sonParamsSpecs;
        break;
      default:
        break;
    }
  }
  onChangeType(value) {
    if (this.isArray) {
      this.changeFormData(this.oldTypeValue);
    }
    this.updateFormScheme(value);
    if (this.isArray) {
      this.updateFormData(value);
    }
  }
  defaultValue() {
    const item = this.zTypes.find(v => v.key === this.form.type);
    this.form.name = identifier(item.value);
    this.form.identifier = this.form.type === DATATYPE.int ? 'INT' : item.key;
  }
  updateFormScheme(type) {
    this.defaultValue();
    this.deleteFormScheme();
    switch (type) {
      case DATATYPE.int:
      case DATATYPE.float:
      case DATATYPE.double:
        this.addNumberFormScheme();
        break;
      case DATATYPE.text:
        this.addTextFormScheme();
        break;
      case DATATYPE.boolean:
        this.addBooleanFormScheme();
        break;
      case DATATYPE.enum:
        this.addEnumFormScheme();
        break;
      case DATATYPE.date:
        this.addDateFormScheme();
        break;
      case DATATYPE.struct:
        this.addStructFormScheme();
        break;
      case DATATYPE.array:
        this.addArrayFormScheme();
        break;
      default:
        this.deleteFormScheme();
        break;
    }
  }
  addNumberFormScheme() {
    this.$set(this.form, 'min', 'min');
    this.$set(this.form, 'max', 'max');
    this.$set(this.form, 'aide', 'stepSize');
    this.$set(this.form, 'isStepOpen', true);
    this.$set(this.form, 'customStepName', '步长');
    this.$set(this.form, 'customStepMark', 'length');
    this.$set(this.form, 'isUnitOpen', true);
    this.$set(this.form, 'customUnitName', '单位');
    this.$set(this.form, 'customUnitMark', 'unit');
    this.$set(this.form, 'unitSource', 'SYSTEM');
    this.$set(this.form, 'unit', '无');
    this.$set(this.form, 'customUnit', '');
  }
  addTextFormScheme() {
    this.$set(this.form, 'max', 10240);
  }
  addDateFormScheme() {
    this.$set(this.form, 'storeType', true);
    this.$set(this.form, 'timeUnit', true);
  }
  addBooleanFormScheme() {
    this.$set(this.form, 'max', 10);
  }
  addEnumFormScheme() {
    this.$set(this.form, 'enumCount', 10);
    this.$set(this.form, 'enumValueType', 'TEXT');
  }
  addStructFormScheme() {
    this.$set(this.form, 'canUseType', this.canUseStructTypeArray);
    this.$set(this.form, 'paramName', 'name');
    this.$set(this.form, 'paramIdentifier', 'identifier');
    this.$set(this.form, 'paramGroup', 'specs');
  }
  addArrayFormScheme() {
    this.$set(this.form, 'canUseType', this.canUseTypeArray);
    this.$set(this.form, 'typeLimit', 'single');
    this.$set(this.form, 'paramName', 'name');
    this.$set(this.form, 'paramIdentifier', 'identifier');
    this.$set(this.form, 'paramGroup', 'specs');
  }
  deleteFormScheme() {
    this.$delete(this.form, 'min');
    this.$delete(this.form, 'max');
    this.$delete(this.form, 'aide');
    this.$delete(this.form, 'isStepOpen');
    this.$delete(this.form, 'customStepName');
    this.$delete(this.form, 'customStepMark');
    this.$delete(this.form, 'isUnitOpen');
    this.$delete(this.form, 'customUnitName');
    this.$delete(this.form, 'customUnitMark');
    this.$delete(this.form, 'unitSource');
    this.$delete(this.form, 'unit');
    this.$delete(this.form, 'customUnit');
    this.$delete(this.form, 'storeType');
    this.$delete(this.form, 'timeUnit');
    this.$delete(this.form, 'enumCount');
    this.$delete(this.form, 'enumValueType');
    this.$delete(this.form, 'canUseType');
    this.$delete(this.form, 'typeLimit');
    this.$delete(this.form, 'paramName');
    this.$delete(this.form, 'paramIdentifier');
    this.$delete(this.form, 'paramGroup');
  }

  get rules() {
    let result = {};
    const base = {
      type: [
        {
          required: true,
          message: `${this.$t('connector.dataType.rule.type')}${this.$t(
            'connector.dataType.rule.notempty',
          )}`,
        },
      ],
      name: [
        {
          required: true,
          validator: (rule, value, callback) =>
            this.checkLength(
              rule,
              value,
              callback,
              `${this.$t('connector.dataType.rule.customName')}${this.$t(
                'connector.dataType.rule.notempty',
              )}`,
              this.$t('connector.dataType.rule.limit16'),
              16,
            ),
          trigger: 'change',
        },
      ],
      identifier: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (value) {
              const reg = /^[a-zA-Z]+$/g;
              if (reg.test(value)) {
                if (value.length < 50) {
                  callback();
                } else {
                  callback(this.$t('connector.dataType.rule.limit50'));
                }
              } else {
                callback('请输入大小写字母！');
              }
            } else {
              callback(
                `${this.$t('connector.dataType.rule.identifier')}${this.$t(
                  'connector.dataType.rule.notempty',
                )}`,
              );
            }
          },
          trigger: 'change',
        },
      ],
    };
    switch (this.form.type) {
      case DATATYPE.int:
      case DATATYPE.float:
      case DATATYPE.double:
        result = {
          ...base,
          min: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.minIdentifier',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          max: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.maxIdentifier',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          customName: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.customName',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          customMark: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.identifier',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          unitSource: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.unitSource',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
        };
        break;
      case DATATYPE.text:
        result = {
          ...base,
          max: [
            {
              required: true,
              message: `${this.$t('connector.dataType.rule.maxByte')}${this.$t(
                'connector.dataType.rule.notempty',
              )}`,
            },
          ],
        };
        break;
      case DATATYPE.date:
        result = {
          ...base,
          storeType: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.unitSource',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          timeUnit: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.timePrecision',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
        };
        break;
      case DATATYPE.boolean:
        result = {
          ...base,
          max: [
            {
              required: true,
              message: `${this.$t('connector.dataType.rule.maximum')}${this.$t(
                'connector.dataType.rule.notempty',
              )}`,
            },
          ],
        };
        break;
      case DATATYPE.enum:
        result = {
          ...base,
          enumValueType: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.enumValueType',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          enumCount: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.numberOfEnum',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
        };
        break;
      case DATATYPE.struct:
        result = {
          ...base,
          canUseType: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.typeOfSupport',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          paramName: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.subIdentifier',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          paramIdentifier: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.dataIdentifier',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          paramGroup: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.groupDataIdentifier',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
        };
        break;
      case DATATYPE.array:
        result = {
          ...base,
          canUseType: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.typeOfSupport',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          typeLimit: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.typeLimit',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          max: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.maximumNumber',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          paramName: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.subIdentifier',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          paramIdentifier: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.dataIdentifier',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
          paramGroup: [
            {
              required: true,
              message: `${this.$t(
                'connector.dataType.rule.groupDataIdentifier',
              )}${this.$t('connector.dataType.rule.notempty')}`,
            },
          ],
        };
        break;
      default:
        result = {
          ...base,
        };
        break;
    }
    return result;
  }
  /**
   * @name 校验长度和空值
   * @param rule {object} 校验规则
   * @param value {string} 值
   * @param callback {function} 函数
   * @param nullTxt {string} 空值提示文本
   * @param lengthTxt {string} 长度值限制提示文本
   * @param len {number} 限制长度
   */
  checkLength(rule, value, callback, nullTxt, lengthTxt, len) {
    if (value) {
      if (!limitTxtLength(value, len)) {
        callback(lengthTxt);
      } else {
        callback();
      }
    } else {
      if (nullTxt) {
        callback(nullTxt);
      } else {
        callback();
      }
    }
  }

  getData() {
    let result = {};
    const base = {
      customizeName: this.form.name,
      dataType: this.form.type,
      thingIdentifier: this.form.identifier,
      idempotent: this.idempotent,
    };
    switch (this.form.type) {
      case DATATYPE.int:
      case DATATYPE.float:
      case DATATYPE.double:
        {
          const systemFlag = this.form.unitSource === 'SYSTEM' ? true : false;
          result = {
            ...base,
            dataSpecs: {
              maxIdentifier: this.form.max,
              minIdentifier: this.form.min,
              step: {
                customizeName: this.form.customStepName,
                identifier: this.form.customStepMark,
              },
              stepFlag: this.form.isStepOpen,
              unit: {
                customizeName: this.form.customUnitName,
                identifier: this.form.customUnitMark,
                source: this.form.unitSource,
                unitName: systemFlag ? this.form.unit : this.form.customUnit,
              },
              unitFlag: this.form.isUnitOpen,
            },
          };
        }
        break;
      case DATATYPE.text:
        result = {
          ...base,
          dataSpecs: {
            max: this.form.max,
          },
        };
        break;
      case DATATYPE.date:
        result = {
          ...base,
          dataSpecs: {
            precision: 'THIRTEEN',
            storage: 'UTC',
          },
        };
        break;
      case DATATYPE.boolean:
        result = {
          ...base,
          dataSpecs: {
            maxLength: this.form.max,
          },
        };
        break;
      case DATATYPE.enum:
        result = {
          ...base,
          dataSpecs: {
            amount: this.form.enumCount,
            enumValueType: this.form.enumValueType,
          },
        };
        break;
      case DATATYPE.struct:
        result = {
          ...base,
          dataSpecs: {
            sonParamsIdentifier: this.form.paramIdentifier,
            sonParamsName: this.form.paramName,
            sonParamsSpecs: this.form.paramGroup,
          },
        };
        break;
      case DATATYPE.array:
        result = {
          ...base,
          dataSpecs: {
            sonParamsIdentifier: this.form.paramIdentifier,
            sonParamsName: this.form.paramName,
            sonParamsSpecs: this.form.paramGroup,
          },
        };
        break;
      default:
        result = {
          ...base,
        };
        break;
    }

    return result;
  }

  async getValue() {
    const flag = await this.$refs.form.validate();
    if (flag) {
      let array = [];
      if (this.isArray) {
        this.changeFormData(this.form.type);
        array = this.dataList.map(v => {
          return {
            ...v,
            idempotent: uuid(),
          };
        });
      }
      return this.isArray ? array : this.getData();
    } else {
      return false;
    }
  }

  labelSlotTooltip(name, desc) {
    return (
      <span slot="label">
        {name}
        <a-tooltip placement={'top'}>
          <span slot={'title'}>{desc}</span>
          <a-icon
            class={[this.$style.ml5, this.$style.mr5]}
            type={'info-circle'}
          />
        </a-tooltip>
      </span>
    );
  }
  renderTooltip(name, desc) {
    return (
      <span>
        {name}
        <a-tooltip placement={'top'}>
          <span slot={'title'}>{desc}</span>
          <a-icon
            class={[this.$style.ml5, this.$style.mr5]}
            type={'info-circle'}
          />
        </a-tooltip>
      </span>
    );
  }

  renderStepSize() {
    return (
      <div>
        <a-form-model-item
          class={this.$style.textAlignLeft}
          labelCol={offsetCol}
          wrapperCol={offsetCol}
        >
          <a-switch
            checked-children={this.$t('connector.dataType.open')}
            un-checked-children={this.$t('connector.dataType.close')}
            v-model={this.form.isStepOpen}
          />
        </a-form-model-item>
        <a-form-model-item
          prop={'customStepName'}
          label={this.$t('connector.dataType.rule.customName')}
          class={this.$style.textAlignLeft}
          labelCol={offsetCol}
          wrapperCol={offsetCol}
        >
          <a-input
            disabled={this.isEdit}
            placeholder={
              this.$t('common.placeholder.input') +
              this.$t('connector.dataType.rule.customName')
            }
            v-model={this.form.customStepName}
          />
        </a-form-model-item>
        <a-form-model-item
          prop={'customStepMark'}
          label={this.$t('connector.dataType.rule.identifier')}
          class={this.$style.textAlignLeft}
          labelCol={offsetCol}
          wrapperCol={offsetCol}
        >
          <a-input
            disabled={this.isEdit}
            placeholder={
              this.$t('common.placeholder.input') +
              this.$t('connector.dataType.rule.identifier')
            }
            v-model={this.form.customStepMark}
          />
        </a-form-model-item>
      </div>
    );
  }
  renderUnit() {
    return (
      <div>
        <a-form-model-item
          class={this.$style.textAlignLeft}
          labelCol={offsetCol}
          wrapperCol={offsetCol}
        >
          <a-switch
            checked-children={this.$t('connector.dataType.open')}
            un-checked-children={this.$t('connector.dataType.close')}
            v-model={this.form.isUnitOpen}
          />
        </a-form-model-item>
        <a-form-model-item
          prop={'customUnitName'}
          label={this.$t('connector.dataType.rule.customName')}
          class={this.$style.textAlignLeft}
          labelCol={offsetCol}
          wrapperCol={offsetCol}
        >
          <a-input
            disabled={this.isEdit}
            placeholder={
              this.$t('common.placeholder.input') +
              this.$t('connector.dataType.rule.customName')
            }
            v-model={this.form.customUnitName}
          />
        </a-form-model-item>
        <a-form-model-item
          prop={'customUnitMark'}
          label={this.$t('connector.dataType.rule.identifier')}
          class={this.$style.textAlignLeft}
          labelCol={offsetCol}
          wrapperCol={offsetCol}
        >
          <a-input
            disabled={this.isEdit}
            placeholder={
              this.$t('common.placeholder.input') +
              this.$t('connector.dataType.rule.identifier')
            }
            v-model={this.form.customUnitMark}
          />
        </a-form-model-item>
        <a-form-model-item
          prop={'unitSource'}
          label={this.$t('connector.dataType.rule.unitSource')}
          class={this.$style.textAlignLeft}
          labelCol={offsetCol}
          wrapperCol={offsetCol}
        >
          <a-radio-group disabled={this.isEdit} v-model={this.form.unitSource}>
            <a-radio value="SYSTEM">
              {this.$t('connector.dataType.radio.system')}
            </a-radio>
            <a-radio value="CUSTOMIZE">
              {this.$t('connector.dataType.radio.custom')}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        {this.form.unitSource === 'SYSTEM' ? (
          <a-form-model-item
            prop={'unit'}
            class={this.$style.textAlignLeft}
            labelCol={offsetCol}
            wrapperCol={offsetCol}
          >
            <a-select v-model={this.form.unit} disabled={this.isEdit}>
              {unitArray.map(item => (
                <a-select-option value={item.key} key={item.key}>
                  {item.value}
                </a-select-option>
              ))}
            </a-select>
          </a-form-model-item>
        ) : (
          <a-form-model-item
            prop={'customUnit'}
            class={this.$style.textAlignLeft}
            labelCol={offsetCol}
            wrapperCol={offsetCol}
          >
            <a-input
              disabled={this.isEdit}
              placeholder={this.$t('common.placeholder.input')}
              v-model={this.form.customUnit}
            />
          </a-form-model-item>
        )}
      </div>
    );
  }
  get renderAideScheme() {
    switch (this.form.aide) {
      case 'stepSize':
        return this.renderStepSize();
      case 'unit':
        return this.renderUnit();
      default:
        return '';
    }
  }
  onChangeAide() {
    this.form.isOpen = true;
  }
  renderNumber() {
    return (
      <div>
        <a-form-model-item prop={'min'}>
          {this.labelSlotTooltip(
            this.$t('connector.dataType.rule.minIdentifier'),
            this.$t('connector.dataType.desc.minIdentifier'),
          )}
          <a-input
            disabled={this.isEdit}
            placeholder={'min'}
            v-model={this.form.min}
          />
        </a-form-model-item>
        <a-form-model-item prop={'max'}>
          {this.labelSlotTooltip(
            this.$t('connector.dataType.rule.maxIdentifier'),
            this.$t('connector.dataType.desc.maxIdentifier'),
          )}
          <a-input
            disabled={this.isEdit}
            placeholder={'max'}
            v-model={this.form.max}
          />
        </a-form-model-item>
        <a-form-model-item>
          {this.labelSlotTooltip(
            this.$t('connector.dataType.rule.aide'),
            this.$t('connector.dataType.desc.aide'),
          )}
          <a-radio-group
            v-model={this.form.aide}
            onChange={type => this.onChangeAide(type)}
          >
            <a-radio-button value="stepSize">
              {this.renderTooltip(
                this.$t('connector.dataType.rule.stepSize'),
                this.$t('connector.dataType.desc.stepSize'),
              )}
            </a-radio-button>
            <a-radio-button value="unit">
              {this.renderTooltip(
                this.$t('connector.dataType.rule.unit'),
                this.$t('connector.dataType.desc.unit'),
              )}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        {this.renderAideScheme}
      </div>
    );
  }

  renderText() {
    return (
      <a-form-model-item prop={'max'}>
        {this.labelSlotTooltip(
          this.$t('connector.dataType.rule.maxByte'),
          this.$t('connector.dataType.desc.maxByte'),
        )}
        <a-input-number
          disabled
          v-model={this.form.max}
          class={this.$style.w100}
        />
      </a-form-model-item>
    );
  }

  renderDate() {
    return (
      <div>
        <a-form-model-item
          prop={'storeType'}
          label={this.$t('connector.dataType.rule.storageType')}
        >
          <a-radio v-model={this.form.storeType}>string</a-radio>
        </a-form-model-item>
        <a-form-model-item
          prop={'timeUnit'}
          label={this.$t('connector.dataType.rule.timePrecision')}
        >
          <a-radio v-model={this.form.timeUnit}>
            {this.$t('connector.dataType.radio.timeUnit')}
          </a-radio>
        </a-form-model-item>
      </div>
    );
  }

  renderBoolean() {
    return (
      <a-form-model-item
        prop={'max'}
        label={this.$t('connector.dataType.rule.maxByte')}
      >
        <section class={this.$style.section}>
          <a-input-number
            class={this.$style.item}
            disabled
            v-model={this.form.max}
          />
          <span class={[this.$style.ml10, this.$style.label2]}>
            {this.$t('connector.dataType.byte')}
          </span>
        </section>
      </a-form-model-item>
    );
  }

  enumValueList = [
    { text: 'int', value: 'INT32' },
    { text: 'string', value: 'TEXT' },
  ];
  renderEnum() {
    return (
      <div>
        <a-form-model-item
          prop={'enumValueType'}
          label={this.$t('connector.dataType.rule.enumValueType')}
        >
          <a-select v-model={this.form.enumValueType}>
            {this.enumValueList.map(item => (
              <a-select-option value={item.value} key={item.value}>
                {item.text}
              </a-select-option>
            ))}
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          prop={'enumCount'}
          label={this.$t('connector.dataType.rule.numberOfEnum')}
        >
          <section class={this.$style.section}>
            <a-input-number
              class={this.$style.item}
              min={1}
              max={512}
              step={1}
              placeholder={this.$t('connector.dataType.rule.limit512')}
              v-model={this.form.enumCount}
            />
            <span class={[this.$style.ml10, this.$style.label1]}>
              {this.$t('common.label.piece')}
            </span>
          </section>
        </a-form-model-item>
      </div>
    );
  }

  canUseStructTypeArray = [
    DATATYPE.int,
    DATATYPE.float,
    DATATYPE.double,
    DATATYPE.enum,
    DATATYPE.boolean,
    DATATYPE.date,
    DATATYPE.text,
  ];
  renderStruct() {
    return (
      <div>
        <a-form-model-item
          prop={'canUseType'}
          label={this.$t('connector.dataType.rule.typeOfSupport')}
        >
          <a-checkbox-group
            disabled
            v-model={this.form.canUseType}
            options={this.canUseStructTypeArray}
          />
        </a-form-model-item>
        {this.renderChild()}
      </div>
    );
  }

  renderChild() {
    return (
      <div>
        <a-form-model-item class={this.$style.modelItem}>
          {this.labelSlotTooltip(
            this.$t('connector.dataType.subItems'),
            this.$t('connector.dataType.desc.subItems'),
          )}
        </a-form-model-item>
        <a-form-model-item
          prop={'paramName'}
          label={this.$t('connector.dataType.rule.subIdentifier')}
          class={this.$style.textAlignLeft}
          labelCol={offsetCol}
          wrapperCol={offsetCol}
        >
          <a-input
            disabled={this.isEdit}
            placeholder={
              this.$t('common.placeholder.input') +
              this.$t('connector.dataType.rule.subIdentifier')
            }
            v-model={this.form.paramName}
          />
        </a-form-model-item>
        <a-form-model-item
          prop={'paramIdentifier'}
          label={this.$t('connector.dataType.rule.dataIdentifier')}
          class={this.$style.textAlignLeft}
          labelCol={offsetCol}
          wrapperCol={offsetCol}
        >
          <a-input
            disabled={this.isEdit}
            placeholder={
              this.$t('common.placeholder.input') +
              this.$t('connector.dataType.rule.dataIdentifier')
            }
            v-model={this.form.paramIdentifier}
          />
        </a-form-model-item>
        <a-form-model-item
          prop={'paramGroup'}
          label={this.$t('connector.dataType.rule.groupDataIdentifier')}
          class={this.$style.textAlignLeft}
          labelCol={offsetCol}
          wrapperCol={offsetCol}
        >
          <a-input
            disabled={this.isEdit}
            placeholder={
              this.$t('common.placeholder.input') +
              this.$t('connector.dataType.rule.groupDataIdentifier')
            }
            v-model={this.form.paramGroup}
          />
        </a-form-model-item>
      </div>
    );
  }

  canUseTypeArray = [
    DATATYPE.int,
    DATATYPE.float,
    DATATYPE.double,
    DATATYPE.text,
    DATATYPE.struct,
  ];
  renderArray() {
    return (
      <div>
        <a-form-model-item
          prop={'canUseType'}
          label={this.$t('connector.dataType.rule.typeOfSupport')}
        >
          <a-checkbox-group
            disabled
            v-model={this.form.canUseType}
            options={this.canUseTypeArray}
          />
        </a-form-model-item>
        <a-form-model-item
          prop={'typeLimit'}
          label={this.$t('connector.dataType.rule.typeLimit')}
        >
          <a-radio-group disabled={this.isEdit} v-model={this.form.typeLimit}>
            <a-radio value="single">
              {this.$t('connector.dataType.radio.single')}
            </a-radio>
            <a-radio disabled value="can">
              {this.$t('connector.dataType.radio.can')}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        {this.renderChild()}
      </div>
    );
  }

  get renderScheme() {
    switch (this.form.type) {
      case DATATYPE.int:
      case DATATYPE.float:
      case DATATYPE.double:
        return this.renderNumber();
      case DATATYPE.date:
        return this.renderDate();
      case DATATYPE.enum:
        return this.renderEnum();
      case DATATYPE.text:
        return this.renderText();
      case DATATYPE.boolean:
        return this.renderBoolean();
      case DATATYPE.struct:
        return this.renderStruct();
      case DATATYPE.array:
        return this.renderArray();
      default:
        return '';
    }
  }

  renderTypeDesc(type) {
    const item = this.zTypes.find(v => v.key === type);
    return item ? <div class={this.$style.typeDesc}>{item.desc}</div> : '';
  }
  render() {
    return (
      <a-form-model
        rules={this.rules}
        props={{ model: this.form }}
        ref={'form'}
      >
        <a-form-model-item prop={'type'}>
          {this.labelSlotTooltip(
            this.$t('connector.dataType.rule.type'),
            this.$t('connector.dataType.desc.type'),
          )}
          <a-select
            disabled={this.isEdit}
            onChange={type => this.onChangeType(type)}
            v-model={this.form.type}
          >
            {this.Types.map(item => (
              <a-select-option value={item.key} key={item.key}>
                {item.value}
              </a-select-option>
            ))}
          </a-select>
        </a-form-model-item>
        {this.renderTypeDesc(this.form.type)}
        <a-form-model-item prop={'name'}>
          {this.labelSlotTooltip(
            this.$t('connector.dataType.rule.customName'),
            this.$t('connector.dataType.desc.customName'),
          )}
          <a-input
            placeholder={
              this.$t('common.placeholder.input') +
              this.$t('connector.dataType.rule.customName')
            }
            v-model={this.form.name}
          />
        </a-form-model-item>
        <a-form-model-item prop={'identifier'}>
          {this.labelSlotTooltip(
            this.$t('connector.dataType.rule.identifier'),
            this.$t('connector.dataType.desc.identifier'),
          )}
          <a-input
            disabled={this.isEdit}
            placeholder={
              this.$t('common.placeholder.input') +
              this.$t('connector.dataType.rule.identifier')
            }
            v-model={this.form.identifier}
          />
        </a-form-model-item>
        {this.renderScheme}
      </a-form-model>
    );
  }
}
</script>
<style lang="less" module>
.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.mr5 {
  margin-right: 5px;
}
.typeDesc {
  margin-top: -15px;
  font-size: 12px;
  padding-bottom: 15px;
}
.section {
  display: flex;
  .item {
    flex: 1;
  }
  .label2 {
    width: 32px;
  }
  .label1 {
    width: 15px;
  }
}
.w100 {
  display: flex;
  width: 100%;
}
.modelItem {
  :global {
    .ant-form-item-control-wrapper {
      display: none;
    }
  }
}
.textAlignLeft {
  :global {
    .ant-form-item-label {
      text-align: left;
    }
  }
}
</style>
