<template>
  <div>
    <div :class="$style.actionSort">{{ $t('common.displaySetting') }}</div>

    <draggable tag="ul" handle=".drag-item" v-model="formData" :animation="300">
      <li
        :class="$style.flexItem"
        v-for="tab in formData"
        :key="tab.actionName"
        v-show="featureMap[tab.moduleType]"
      >
        <div :class="$style.moduleName">
          <x-icon :type="moduleObject(tab.moduleType, 'icon')" />{{
            $t(moduleObject(tab.moduleType, 'actionName'))
          }}
        </div>
        <div :class="$style.action">
          <x-icon
            :class="$style.delete"
            :type="tab.show ? 'eye' : 'eye-invisible'"
            @click="handleDeleteTab(tab)"
          />
          <x-icon class="drag-item" type="tc-icon-drag-handler" />
        </div>
      </li>
    </draggable>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';

function createActionSort(props, listener, modalProps = {}) {
  const modal = createFormModal(
    h =>
      h(ActionSort, {
        props,
      }),
    {
      title: globalVueI18n.translate('common.topSettings'),
      width: 440,
      bodyStyle: { padding: '20px 20px 15px 20px' },
      ...modalProps,
      onCancel: () => {
        listener.closed();
      },
    },
  );
  return modal;
}
@Component({
  components: {
    Draggable,
  },
})
export default class ActionSort extends Vue {
  @Prop({ type: Array }) actionData;
  @Prop({ type: Object }) featureMap;

  formData = [];

  static createModal = createActionSort;

  handleDeleteTab(item) {
    item.show = !item.show;
  }
  moduleObject(type, attr) {
    return this.actionData.filter(item => item.moduleType === type)[0][attr];
    // return this.$t(str);
  }

  getValue() {
    return this.formData.map((item, index) => ({ ...item, sort: index }));
  }

  created() {
    this.formData = this.actionData.sort((a, b) => a.sort - b.sort);
  }
}
</script>
<style lang="less" module>
.actionSort {
  color: var(--font-info);
  margin-bottom: 5px;
}
.flexItem {
  display: flex;
  justify-content: space-between;
  color: var(--font);
  line-height: 32px;
}
.moduleName {
  i {
    font-size: 15px;
    margin-right: 10px;
  }
}
.action {
  i:nth-child(1) {
    margin-right: 10px;
  }
}
</style>
