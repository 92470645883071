<template>
  <div :class="$style.empty">
    <div :class="$style.emptyBg" />
    <span>{{ $t('controls.fieldConfig.empty') }}</span>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';

@Component()
export default class CustomParamConfig extends Vue {}
</script>
<style lang="less" module>
.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.emptyBg {
  margin-top: 60%;
  width: 250px;
  height: 160px;
  margin-bottom: 20px;
  background-image: var(--empty-drag-image);
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
