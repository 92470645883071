<template>
  <a-form-model layout="vertical" :model="form" ref="form">
    <!-- <a-form-model-item :label="$t('connector.dataType.addType')" prop="type">
      <a-radio-group v-model="form.type" @change="onTypeChange">
        <a-radio :value="1" v-if="$p.action('CREATE')">
          {{ $t('connector.dataType.customAdd') }}
        </a-radio>
        <a-radio :value="2" v-if="$p.action('UPDATE')">
          {{ $t('connector.dataType.importJson') }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item> -->
    <a-form-model-item>
      <a-upload
        name="file"
        :file-list="files"
        :customRequest="handleUploadChange"
        :remove="handleRemove"
      >
        <a-button>
          <a-icon type="upload" /> {{ $t('connector.dataType.uploadFile') }}
        </a-button>
      </a-upload>
    </a-form-model-item>
    <a-form-model-item :class="$style.centerRight">
      <a-button type="primary" :disabled="disabled" @click="handleSure">{{
        $t('common.action.confirm')
      }}</a-button>
      <a-button :class="$style.mr10" @click="handleCancel">{{
        $t('common.action.cancel')
      }}</a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import Ajv from 'ajv';
import { types } from '../../utils';

const ajv = new Ajv();

@Component()
export default class AddFormType extends Vue {
  form = {};
  disabled = false;
  onTypeChange(val) {
    if (val.target.value === 1) {
      this.disabled = false;
      this.files = [];
    } else {
      this.disabled = true;
    }
  }
  files = [];
  async handleUploadChange(obj) {
    try {
      let size = obj.file.size;
      obj.file.status = 'done';
      this.files.push(obj.file);
      let text = await obj.file.slice(0, size).text();
      const result = JSON.parse(text, (k, v) => {
        if (k === 'dataSpecs') {
          return JSON.parse(v);
        } else {
          return v;
        }
      });
      const data = this.formatJSON(result);
      this.disabled = false;

      const valid = this.validate(data);
      if (valid.array && valid.array.length) {
        this.$emit('upload', valid);
      }
    } catch (error) {
      this.$message.error(this.$t('connector.dataType.jsonError'));
    }
  }
  handleRemove() {
    this.disabled = true;
    this.files = [];
  }
  formatJSON(list = []) {
    let errors = [];
    let data = [];
    list.forEach(item => {
      if (item.dataType) {
        const type = types(this).find(v => v.key === item.dataType);
        if (type) {
          const validate = ajv.compile(type.schema);
          if (!validate(item)) {
            errors.push({
              type: item.dataType,
              errors: validate.errors,
            });
          } else {
            data.push(item);
          }
        } else {
          errors.push({
            type: item.dataType,
            errors: [],
          });
        }
      }
    });
    return {
      errors,
      data,
      list,
    };
  }
  @Prop({ type: Array }) tableData;
  validate(result) {
    const { data } = result;
    let errMsg = ``;
    const array = [];
    data.forEach(item => {
      const index = this.tableData.findIndex(v => v.datatype === item.dataType);
      if (index === -1) {
        array.push(item);
      } else {
        errMsg += `${item.dataType}/`;
      }
    });
    errMsg = errMsg.length > 1 ? errMsg.slice(0, -1) : '';
    /** @name 本次导入的数据，都有了 */
    if (array.length === 0) {
      this.disabled = true;
      this.$message.warn(errMsg + '已存在');
    }
    return {
      errMsg,
      array,
      data,
    };
  }

  @Emit('close')
  @Emit('message')
  handleSure() {
    return this.form.type;
  }

  @Emit('close')
  handleCancel() {}
}
</script>
<style lang="less" module>
.mr10 {
  margin-left: 10px;
}
.centerRight {
  text-align: right;
}
</style>
