// @ts-check
import {
  DataSourceCompareOperator,
  DataSourceLogicOperator,
} from '@triascloud/services';
import { deepClone, ensureArray, match } from '@triascloud/utils';
import { times } from 'lodash';
import moment from 'moment';
import { unwrapValue } from '../controls';
import { getArrayValue } from '../data-form/reactive-store';

/** @name 未填写 */
export const UN_FILLED = 'notFilled';

/**
 * @name 特殊对比条件转换为基本对比条件
 * @type { Record<string, (config: FormCompareConfig) => (DataSourceFilter | DataSourceFilterGroup | null)> }
 */
export const Operator = {
  /**
   * @name 等于任意一个
   * @description 数据源为非数组，对比数据为数组，要求数据源等于对比数据中的任意一个
   */
  eqOne: ({ field, value }) => ({
    group: ensureArray(value).map(item => ({
      compare:
        item === UN_FILLED
          ? DataSourceCompareOperator.NULL
          : DataSourceCompareOperator.EQ,
      value: item === UN_FILLED ? null : item,
      field,
    })),
    logic: DataSourceLogicOperator.OR,
  }),
  /**
   * @name 不等于任意一个
   * @description 数据源为非数组，对比数据为数组，要求数据源不等于对比数据中的任意一个
   */
  uneqOne: ({ field, value }) => ({
    group: ensureArray(value).map(item => ({
      compare: DataSourceCompareOperator.NOTEQ,
      value: item,
      field,
    })),
    logic: DataSourceLogicOperator.AND,
  }),
  /**
   * @name 包含任意一个
   * @description 数据源为数组，对比数据为数组，要求数据源包含对比数据中的任意一个元素
   */
  some: ({ field, value }) => ({
    group: ensureArray(value).map(item => ({
      field,
      compare: DataSourceCompareOperator.CONTAINS,
      value: item,
    })),
    logic: DataSourceLogicOperator.OR,
  }),
  /**
   * @name 同时包含
   * @description 数据源为数组，对比数据为数组，要求数据源包含对比数据中的所有元素
   */
  alsoInclude: ({ field, value }) => ({
    group: ensureArray(value).map(item => ({
      field,
      compare: DataSourceCompareOperator.CONTAINS,
      value: item,
    })),
    logic: DataSourceLogicOperator.AND,
  }),
  /**
   * @name 不包含
   * @description 数据源为数组，对比数据为数组，要求数据源包含对比数据中的所有元素
   */
  notContains: config => Operator.uneqOne(config),
  /**
   * @name 选择范围
   * @description 要求数据源在一段区间内
   */
  range: ({ field, value }) => ({
    group: [
      { compare: DataSourceCompareOperator.GE, value: value[0], field },
      { compare: DataSourceCompareOperator.LE, value: value[1], field },
    ],
    logic: DataSourceLogicOperator.AND,
  }),
  /** @name 日期动态筛选 */
  filter: ({ field, option }) => {
    // 自定义动态筛选
    const range = generateDateFilter(option);
    if (!range) return null;
    return Operator.range({ field, value: range, compare: '' });
  },
  /** @name 手机号已验证 */
  valid: ({ field }) => ({
    compare: DataSourceCompareOperator.NOTNULL,
    field,
    value: null,
  }),
  /** @name 手机号未验证 */
  notValid: ({ field }) => ({
    compare: DataSourceCompareOperator.NULL,
    field,
    value: null,
  }),
  /** @name 属于 */
  belong: ({ field, value }) => ({
    group: Array.isArray(value)
      ? times(value.length).map(key => ({
          field: `${field}.0.${key}`,
          compare: DataSourceCompareOperator.EQ,
          value: value[key],
        }))
      : [
          value.country && 'country',
          value.province && 'province',
          value.city && 'city',
          value.area && 'area',
        ]
          .filter(key => key)
          .map(key => ({
            field: `${field}.${key}`,
            compare: DataSourceCompareOperator.EQ,
            value: value[key],
          })),
    logic: DataSourceLogicOperator.AND,
  }),
  /** @name 不属于 */
  notBelong: ({ field, value }) => ({
    group: Array.isArray(value)
      ? times(value.length).map(key => ({
          field: `${field}.0.${key}`,
          compare: DataSourceCompareOperator.NOTEQ,
          value: value[key],
        }))
      : [
          value.country && 'country',
          value.province && 'province',
          value.city && 'city',
          value.area && 'area',
        ]
          .filter(key => key)
          .map(key => ({
            field: `${field}.${key}`,
            compare: DataSourceCompareOperator.NOTEQ,
            value: value[key],
          })),
    logic: DataSourceLogicOperator.OR,
  }),
};

/** @param { unknown } value */
const hasValue = value => typeof value === 'number' || !!value;

/** @param { string } num */
const normalizeNumber = num =>
  match.isPureNumber(num) && Number.MAX_SAFE_INTEGER > Number(num)
    ? Number(num)
    : num;

/**
 * @name 基本对比条件判断
 * @type { Record<DataSourceCompareOperator, (value: any, condition?: any) => boolean> }
 */
const OperatorCompare = {
  EQ: (value, condition) => {
    condition = normalizeNumber(condition);
    if (Array.isArray(condition)) {
      return condition.some(item => normalizeNumber(item) === value);
    } else {
      return normalizeNumber(value) === condition;
    }
  },
  NOTEQ: (value, condition) => !OperatorCompare.EQ(value, condition),
  NULL: value =>
    Array.isArray(value) ? !value.length : value == null || value === '',
  NOTNULL: value => !OperatorCompare.NULL(value),
  LT: (value, condition) =>
    hasValue(value) && Number(value) < Number(condition),
  LE: (value, condition) =>
    hasValue(value) && Number(value) <= Number(condition),
  GT: (value, condition) =>
    hasValue(value) && Number(value) > Number(condition),
  GE: (value, condition) =>
    hasValue(value) && Number(value) >= Number(condition),
  CONTAINS: (value, condition) =>
    (typeof value === 'string' || Array.isArray(value)) &&
    value.includes(condition),
  NOTCONTAINS: (value, condition) =>
    !OperatorCompare.CONTAINS(value, condition),
};

/**
 * @name 对比组件数据结构生成对比条件
 * @param { FormCompareConfig[] | FormCompareFilter | undefined } input 对比条件
 * @param { GenerateFilterGroupOptions } options 生成选项
 * @param { DataSourceLogicOperator } logic 对比条件逻辑（并、或）
 * @returns { DataSourceFilterGroup }
 */
export function generateFilterGroup(
  input,
  options,
  logic = DataSourceLogicOperator.AND,
) {
  /** @type { FormCompareConfig[] } */
  let configs;
  if (input && !Array.isArray(input)) {
    // @ts-ignore
    configs = ensureArray(input.configs || input.group);
    logic = input.logic;
  } else {
    configs = ensureArray(input);
  }
  const { getKey, getValue } = options;
  return {
    // @ts-ignore
    group: configs
      .map(item => {
        const { field, compare, value } = item;
        const fieldKey = typeof getKey === 'function' ? getKey(item) : field;
        // 找不到字段的处理
        if (!fieldKey) {
          // 如果对比逻辑是任一成立，则跳过找不到字段的条件
          if (logic === DataSourceLogicOperator.OR) return null;
          // 如果对比逻辑是同时成立，则生成一个永远不成立的条件
          return {
            logic: DataSourceLogicOperator.AND,
            group: [
              {
                compare: DataSourceCompareOperator.NULL,
                field,
                value: null,
              },
              {
                compare: DataSourceCompareOperator.NOTNULL,
                field,
                value: null,
              },
            ],
          };
        }
        const realValue =
          typeof getValue === 'function' ? getValue(item) : value;
        // 原子条件无需转换
        // @ts-ignore
        if (DataSourceCompareOperator[compare.toUpperCase()]) {
          return {
            compare: compare.toUpperCase(),
            field: fieldKey,
            value: realValue,
          };
        } else {
          // 复合条件转基础条件
          return (
            Operator[compare] &&
            Operator[compare]({ ...item, value: realValue, field: fieldKey })
          );
        }
      })
      // 过滤无效条件
      .filter(_ => _),
    logic,
  };
}

/**
 * @typedef { import('@triascloud/services').DataSourceFilterGroup<any> }  DataSourceFilterGroup
 */

/**
 * @typedef { import('@triascloud/services').DataSourceFilter<any> }  DataSourceFilter
 */

/**
 *
 * @param {  DataSourceFilterGroup | DataSourceFilter } input
 * @returns { input is DataSourceFilterGroup }
 */
function isFilterGroup(input) {
  return 'group' in input && Array.isArray(input.group);
}

/**
 * @name 对比取值缓存
 * @type { Record<string, any> }
 */
let compareValueCache = {};

/**
 * @name 对比方法
 * @param { DataSourceFilterGroup } filterGroup
 * @param { Record<string, any> | ((field: string | number) => any) } data
 * @returns { boolean }
 */
export function compare(filterGroup, data) {
  compareValueCache = {};
  return _compare(filterGroup, data);
}

/**
 * @param { Record<string, any> | ((field: string | number) => any) } fnOrMap
 * @param { string | number } path
 */
function _getValueWithCache(fnOrMap, path) {
  if (compareValueCache[path]) return compareValueCache[path];
  if (typeof fnOrMap === 'function') {
    return unwrapValue(fnOrMap(path), false);
  } else {
    return unwrapValue(getArrayValue(fnOrMap, path.toString()), false);
  }
}

/**
 * @name 对比方法内部实现
 * @param { DataSourceFilterGroup } filterGroup
 * @param { Record<string, any> | ((field: string | number) => any) } getValue
 * @returns { boolean }
 */
function _compare(filterGroup, getValue) {
  return filterGroup.group[
    filterGroup.logic === DataSourceLogicOperator.AND ? 'every' : 'some'
  ](item => {
    if (isFilterGroup(item)) {
      return compare(item, getValue);
    } else {
      // @ts-ignore
      const value = _getValueWithCache(getValue, item.field);
      const fn = OperatorCompare[item.compare];
      // 如果没有匹配到对比方法直接跳过
      if (!fn) return true;
      if (
        Array.isArray(value) &&
        item.compare !== DataSourceCompareOperator.NULL &&
        item.compare !== DataSourceCompareOperator.NOTNULL
      ) {
        // 数组类型取任一成立
        return value.some(data => fn(data, item.value));
      } else {
        return fn(value, item.value);
      }
    }
  });
}

/**
 * @name 新配置转旧结构
 * @param { FormCompareConfig[] } configs
 * @param { function } getType
 * @returns { any[] }
 */
export function generateOldConfiguration(configs, getType) {
  return configs.map(field => {
    if (field.compare === 'range') {
      field.option = {
        startTimestamp: (field.value && field.value[0]) || '',
        rangeStart: (field.value && field.value[0]) || '',
        endTimestamp: (field.value && field.value[1]) || '',
        rangeEnd: (field.value && field.value[1]) || '',
      };
    }
    return {
      operation: field.compare,
      pkId: field.field,
      type: getType && getType(field),
      option: {
        ...field.option,
        value: field.value,
      },
    };
  });
}
/**
 * @name 旧配置转新配置
 * @param { FormCompareConfigOld[] } configs
 * @returns { FormCompareConfig[] }
 */
export function generateNewConfiguration(configs) {
  return configs.map(field => {
    let value = field.option.value;
    let option = null;
    if (field.operation === 'range') {
      value = [
        field.option.rangeStart || field.option.startTimestamp,
        field.option.rangeEnd || field.option.endTimestamp,
      ];
    } else if (field.operation === 'filter') {
      const { starTime, ..._option } = field.option;
      option = {
        ..._option,
        startTime: (starTime && starTime) || field.option.startTime,
      };
    }
    return {
      compare: field.operation,
      field: field.pkId,
      option,
      value,
    };
  });
}

/**
 * @name 从新filter转旧filter
 * @param { Partial<FormCompareFilter>? } newFilter
 * @param { () => string } getType
 * @returns { FormCompareFilterOld }
 */
export function generateOldFilter(newFilter, getType) {
  newFilter = newFilter || {
    logic: DataSourceLogicOperator.OR,
    configs: [],
  };
  return {
    ifAll: newFilter.logic === DataSourceLogicOperator.AND ? 'ALL' : 'ANY',
    fields: generateOldConfiguration(ensureArray(newFilter.configs), getType),
  };
}

/**
 * @name 从旧filter转新filter
 * @param { Partial<FormCompareFilterOld>?= } oldFilter
 * @returns { FormCompareFilter }
 */
export function generateNewFilter(oldFilter = {}) {
  oldFilter = oldFilter || {
    ifAll: 'ANY',
    fields: [],
  };
  if (!Object.prototype.hasOwnProperty.call(oldFilter, 'ifAll')) {
    // @ts-ignore
    return deepClone(oldFilter);
  }
  return {
    logic:
      oldFilter.ifAll === 'ALL'
        ? DataSourceLogicOperator.AND
        : DataSourceLogicOperator.OR,
    configs: generateNewConfiguration(ensureArray(oldFilter.fields)),
  };
}

/**
 * @typedef { Exclude<keyof typeof Operator, 'prototype'> } FormCompareOperation
 */

/**
 * @typedef FormCompareFilter
 * @property { FormCompareConfig[] } configs
 * @property { DataSourceLogicOperator } logic
 */

/**
 * @typedef FormCompareFilterOld
 * @property { 'ALL' | 'ANY' } ifAll
 * @property { FormCompareConfigOld[] } fields;
 */

/**
 * @typedef FormCompareConfigOld
 * @property { FormCompareOperation } operation
 * @property { string } pkId
 * @property { string } type
 * @property { FormCompareConfigOptionOld } option
 */

/**
 * @typedef { FormCompareConfigOption & { value?: any; starTime?: FormCompareConfigOption['startTime'] } } FormCompareConfigOptionOld
 */

/**
 * @typedef FormCompareConfig
 * @property { FormCompareOperation } compare
 * @property { string } field
 * @property { any } value
 * @property { FormCompareConfigOption?= } option
 */

/**
 * @typedef FormCompareTimePoint
 * @property { 'CURRENT'|'PAST'|'FUTURE' } moment
 * @property { number } number
 * @property { 'DAY'|'WEEK'|'MONTH' } timeUnit
 */

/**
 * @typedef FormCompareConfigOption
 * @property { string= } field
 * @property { FormCompareTimePoint= } startTime
 * @property { FormCompareTimePoint= } endTime
 * @property { string= } dynamicScreening
 * @property { string= } rangeStart
 * @property { string= } rangeEnd
 * @property { string= } startTimestamp
 * @property { string= } endTimestamp
 */

/**
 * @typedef GenerateFilterGroupOptions
 * @property { (config: FormCompareConfig) => string= } getKey 自定义获取对比字段名方法，如data.子表单id.字段id.value
 * @property { (config: FormCompareConfig) => any= } getValue 自定义获取对比值方法
 */

/** @param { "DAY" | "WEEK" | "MONTH" } unit */
function parseDateUnit(unit) {
  switch (unit) {
    case 'MONTH':
      return 'months';
    case 'DAY':
      return 'day';
    case 'WEEK':
      return 'week';
    default:
      return 'day';
  }
}

/**
 * @param { FormCompareTimePoint } timePoint
 * @param { 'startOf' | 'endOf' } type
 */
function generateTimePoint(timePoint, type) {
  const unit = parseDateUnit(timePoint.timeUnit);
  const m = moment()[type](unit);
  switch (timePoint.moment) {
    case 'PAST':
      return m.subtract(timePoint.number, unit).valueOf();
    case 'CURRENT':
      return m.valueOf();
    case 'FUTURE':
      return m.add(timePoint.number, unit).valueOf();
  }
}

/**
 * @param { FormCompareConfigOption?= } option
 */
function generateDateFilter(option) {
  let start = null;
  let end = null;
  if (!option) return null;
  // 动态筛选类型
  const { dynamicScreening, startTime, endTime } = option;
  switch (dynamicScreening) {
    // 自定义
    case 'CUSTOMIZE': {
      if (!startTime) return null;
      start = generateTimePoint(startTime, 'startOf');
      if (startTime.moment === 'CURRENT') {
        end = generateTimePoint(startTime, 'endOf');
      } else if (endTime) {
        end = generateTimePoint(endTime, 'endOf');
      }
      break;
    }
    // 当天
    case 'TODAY':
      start = moment()
        .startOf('day')
        .valueOf();
      end = moment()
        .endOf('day')
        .valueOf();
      break;
    case 'YESTERDAY':
      start = moment()
        .subtract(1, 'days')
        .startOf('day')
        .valueOf();
      end = moment()
        .subtract(1, 'days')
        .endOf('day')
        .valueOf();
      break;
    case 'TOMORROW':
      start = moment()
        .add(1, 'days')
        .startOf('day')
        .valueOf();
      end = moment()
        .add(1, 'days')
        .endOf('day')
        .valueOf();
      break;
    case 'THIS_WEEK':
      start = moment()
        .startOf('weeks')
        .valueOf();
      end = moment()
        .endOf('weeks')
        .valueOf();
      break;
    case 'LAST_WEEK':
      start = moment()
        .subtract(1, 'weeks')
        .startOf('weeks')
        .valueOf();
      end = moment()
        .subtract(1, 'weeks')
        .endOf('weeks')
        .valueOf();
      break;
    case 'THIS_MONTH':
      start = moment()
        .startOf('months')
        .valueOf();
      end = moment()
        .endOf('months')
        .valueOf();
      break;
    case 'FIRST_MONTH':
      start = moment()
        .subtract(1, 'months')
        .startOf('months')
        .valueOf();
      end = moment()
        .subtract(1, 'months')
        .endOf('months')
        .valueOf();
      break;
    case 'NEXT_MONTH':
      start = moment()
        .add(1, 'months')
        .startOf('months')
        .valueOf();
      end = moment()
        .add(1, 'months')
        .endOf('months')
        .valueOf();
      break;
  }
  return [start, end].sort();
}
