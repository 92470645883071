<template>
  <div :class="$style.imageLogInfoModalWrapper">
    <div :class="$style.header">
      <ul :class="$style.infoList">
        <li
          v-for="(item, index) in headerList"
          :key="index"
          :class="$style.infoItem"
        >
          <span :class="$style.label">{{ item.label }}：</span>
          <x-ellipsis :class="$style.content">
            {{ item.value }}
          </x-ellipsis>
        </li>
      </ul>
    </div>

    <div :class="$style.dataTable">
      <x-data-table
        ref="table"
        row-key="pkId"
        scroll-mode
        :index-column="false"
        :columns="formattedColumns"
        :service="service"
      >
        <div slot="headerLeft"></div>
        <div slot="headerRight"></div>
      </x-data-table>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';

@Component()
export default class ImageLogInfoModal extends Vue {
  @Prop({ type: Object, default: () => {} }) imgInfo;
  @Prop({ type: Array, default: () => [] }) columns;
  @Prop({ type: Object, default: () => {} }) service;

  static createModal = createModal;

  get headerList() {
    return [
      {
        label: this.$t('common.label.imageName'),
        value: this.imgInfo.imgName,
        mark: '图片名称',
      },
      {
        label: this.$t('common.label.belongGroupName'),
        value: this.imgInfo.imgGroupName,
        mark: '所属分类',
      },
      {
        label: this.$t('gallery.label.sharer'),
        value: `${this.imgInfo.createBy} ${this.$moment(
          this.imgInfo.createDate,
        ).format('yyyy/MM/DD HH:mm')}`,
        mark: '共享者',
      },
    ];
  }

  get formattedColumns() {
    return this.columns.map(item => {
      item.filter = false;
      item.sorter = false;
      item.align = 'center';
      if (item.dataIndex === 'operationTime') {
        item.customRender = text => {
          return [<span>{this.$moment(text).format('yyyy/MM/DD HH:mm')}</span>];
        };
      }
      return item;
    });
  }
}

function createModal({ titlePath, imgInfo, columns, service }) {
  return createFormModal(
    render =>
      render(ImageLogInfoModal, {
        props: {
          imgInfo,
          columns,
          service,
        },
      }),
    {
      title: globalVueI18n.translate(titlePath),
      width: 580,
    },
  );
}
</script>

<style lang="less" module>
.imageLogInfoModalWrapper {
  .infoList {
    display: flex;
    //border-bottom: 1px solid var(--border);

    .infoItem {
      flex: 1;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      overflow: hidden;

      .label {
        color: var(--font-info);
        flex-shrink: 0;
      }
      .content {
        font-size: var(--font-sm);
        line-height: 1;
        color: var(--font);
        padding-right: 10px;
      }
    }
  }
}
</style>
