<template>
  <div ref="Box" style="perspective:0;">
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-row :gutter="10" :class="$style.rowClass">
        <a-col :span="12">
          <a-form-model-item prop="identifier">
            <span slot="label">
              {{ $t('connector.api.apiIdentification') }}
              <a-tooltip
                :title="$t('connector.api.apiIdentifyTips')"
                placement="right"
                :overlayStyle="{ maxWidth: '100%' }"
              >
                <a-icon type="info-circle" />
              </a-tooltip>
            </span>
            <a-input
              :placeholder="$t('connector.api.apiIdentificationInput')"
              :maxLength="50"
              :disabled="operationFlag"
              v-model="form.identifier"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item
            :label="$t('connector.api.associatedDataTemplate')"
            prop="templateRelay"
          >
            <a-select
              v-model="form.templateRelay"
              :placeholder="$t('connector.api.associatedDataTemplateSelect')"
              @change="handleTempChange"
            >
              <a-select-option
                v-for="item in templateList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.templateName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="10" :class="$style.rowClass">
        <a-col :span="24">
          <a-form-model-item prop="req" ref="req">
            <span slot="label">
              {{ $t('connector.api.reqSrtting') }}
              <a-tooltip
                title="AMQP请求地址可为空，若是“三方推送”需要将当前连接器授权给指定开发者方可使用"
                placement="right"
                :overlayStyle="{ maxWidth: '100%' }"
              >
                <a-icon type="info-circle" />
              </a-tooltip>
            </span>
            <div style="display: flex">
              <a-select
                v-model="form.reqWay"
                style="width: 120px; padding-right: 10px"
                @change="methodsChange"
              >
                <a-select-option value="GET">GET</a-select-option>
                <a-select-option value="POST">POST</a-select-option>
                <a-select-option value="THIRD_PARTY">三方推送</a-select-option>
              </a-select>
              <a-input
                v-model="form.req"
                :disabled="disabledUrl"
                :placeholder="$t('connector.api.reqSettingInput')"
                :maxLength="65536"
                @blur="
                  () => {
                    $refs.req.onFieldBlur();
                  }
                "
                @change="
                  () => {
                    $refs.req.onFieldChange();
                  }
                "
              />
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="10" :class="$style.rowClass">
        <a-col :span="24">
          <a-form-model-item>
            <span
              slot="label"
              style="display: flex; width: 100%; justify-content: space-between"
            >
              {{ $t('connector.api.requestParameter') }}
              <span
                v-if="form.templateRelay && activeKey !== 'header'"
                :class="$style.link"
                @click="chooseParameter()"
              >
                <a-icon type="plus" style="font-size: 12px" />
                添加入参
              </span>
              <span
                v-if="activeKey === 'header'"
                :class="$style.link"
                @click="addCustomerParam"
              >
                <a-icon type="plus" style="font-size: 12px" />
                {{ $t('connector.api.addCustomParameters') }}
              </span>
            </span>
            <a-tabs
              v-model="activeKey"
              type="card"
              @change="changeTab"
              :default-active-key="activeKey"
            >
              <a-tab-pane key="url" tab="query">
                <ApiTable
                  ref="queryRef"
                  :table-data="reqData"
                  table-type="req"
                  :platformId="platformId"
                />
              </a-tab-pane>
              <a-tab-pane key="header" tab="header">
                <div :class="$style.urlParams">
                  <div :class="[$style.flex, $style.treeListItem]">
                    <div :class="$style.width_300">
                      {{ $t('connector.dataType.title') }}
                    </div>
                    <div :class="$style.width_150">
                      {{ $t('controls.config.required') }}
                    </div>
                    <div :class="$style.width_300">Value</div>
                    <div>{{ $t('common.action.operating') }}</div>
                  </div>
                  <div
                    v-for="(item, idx) in headerData"
                    :class="[$style.flex, $style.treeListItem]"
                    :key="idx"
                  >
                    <div :class="$style.width_300">
                      <a-input
                        :placeholder="$t('connector.api.keyInput')"
                        :class="$style.width_250"
                        v-model="item.identifier"
                      />
                    </div>
                    <div :class="$style.width_150">
                      <a-switch
                        v-model="item.required"
                        :checked-children="$t('template.review.checked')"
                        :un-checked-children="$t('template.review.unChecked')"
                      />
                    </div>
                    <div :class="$style.width_300">
                      <VariableModel
                        :ref="'headerRef' + idx"
                        :variableVal.sync="item.val"
                        :platformId="platformId"
                      />
                    </div>
                    <div
                      style="color: var(--delete); cursor: pointer"
                      @click.stop="deleteHeader('headerRef', idx)"
                    >
                      <x-icon type="tc-icon-delete" />
                    </div>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane v-if="form.reqWay === 'POST'" key="body" tab="body">
                <a-radio-group
                  v-model="form.bodyRadio"
                  :options="bodyOptions"
                  @change="bodyRadioChange"
                />
                <ApiTable
                  ref="bodyRef"
                  :table-data="bodyData"
                  table-type="body"
                  :platformId="platformId"
                />
              </a-tab-pane>
            </a-tabs>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="10" :class="$style.rowClass">
        <a-col :span="12">
          <a-form-model-item
            :label="$t('connector.api.returnFormat')"
            prop="resFormat"
          >
            <a-radio-group v-model="form.resFormat">
              <a-radio value="JSON"> JSON </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item :wrapper-col="{ span: 24 }">
        <span slot="label">
          {{ $t('connector.api.returnsResultSetting') }}
          <span
            v-if="form.templateRelay"
            :class="$style.link"
            @click="chooseParameter('res')"
          >
            <a-icon type="plus" style="font-size: 12px" />
            添加出参
          </span>
        </span>
        <ApiTable
          :table-data.sync="resData"
          table-type="res"
          :platformId="platformId"
          :store="outStore"
        />
      </a-form-model-item>

      <a-form-model-item :label="$t('connector.api.descr')" prop="remark">
        <a-textarea
          v-model="form.remark"
          :placeholder="$t('connector.api.limit256Characters')"
          :max-length="256"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-model-item>
      <a-form-model-item prop="helpDoc">
        <span slot="label">
          {{ $t('connector.api.helpDoc') }}
          <a-tooltip
            :title="$t('connector.api.helpDescTips')"
            placement="right"
            :overlayStyle="{ maxWidth: '100%' }"
          >
            <a-icon type="info-circle" />
          </a-tooltip>
        </span>
        <a-input
          v-model="form.helpDoc"
          :placeholder="$t('connector.api.helpDescLinkInput')"
          :max-length="256"
        />
      </a-form-model-item>
      <a-form-model-item prop="colorful">
        <span slot="label">
          {{ $t('connector.api.themeStyle') }}
          <a-tooltip
            :title="$t('connector.api.themeStyleTips')"
            placement="right"
            :overlayStyle="{ maxWidth: '100%' }"
          >
            <a-icon type="info-circle" />
          </a-tooltip>
        </span>
        <div :class="$style.flex">
          <a-switch
            v-model="form.colorful"
            :checked-children="$t('connector.dataType.open')"
            :un-checked-children="$t('connector.dataType.close')"
          />
          <custom-color-picker
            :class="$style['color-picker']"
            v-if="form.colorful"
            :value="form.color"
            @change="value => handleColorChange(value)"
          />
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <div :class="$style.btns">
          <a-button @click="close">{{ $t('common.action.cancel') }}</a-button>
          <a-button
            type="primary"
            style="margin-left: 10px"
            @click="saveBasicForm()"
          >
            {{ $t('common.action.save') }}
          </a-button>
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import {
  getTemplateById,
  getTemplateList,
} from '@/services/iot-platform/dataTemplate';
import ApiParams from './api-params';
import ApiTable from './api-table';
import CustomColorPicker from './components/custom-color-picker';
import {
  checkApiIdentifier,
  getApiBasicSetting,
  updateApiBasicSetting,
} from '@/services/iot-platform/platformInterface';
import { uuid } from '@triascloud/utils';
import { ControlTypeEnum } from '../data-template/template-design/form/controls';
import { createFormModal } from '@triascloud/x-components';
import VariableModel from './components/variable-model';
import {
  PLACEHOLDER_IN,
  PLACEHOLDER_OUT,
  variableReset,
} from './components/utils';
import {
  eachJsonPath,
  eachSetJsonPath,
  eachSetJsonPathForStore,
  flatFieldsId,
} from './utils';
import { ReactiveStore } from './debug-api/utils';

@Component({
  components: {
    ApiParams,
    ApiTable,
    CustomColorPicker,
    VariableModel,
  },
})
export default class BaseConfig extends Vue {
  @Prop({ type: String, default: '' }) apiId;
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: String, default: '' }) platformIdentifier;

  operationFlag = false;
  form = {
    identifier: '',
    templateRelay: undefined,
    req: '',
    qps: 0,
    remark: '',
    helpDoc: '',
    reqWay: 'GET',
    bodyRadio: 'JSON',
    resFormat: 'JSON',
    colorful: false,
    color: '#9743fe',
  };
  rules = {
    templateRelay: [
      {
        type: 'string',
        required: true,
        message: this.$t('connector.api.associatedDataTemplateSelect'),
      },
    ],
    identifier: [
      {
        type: 'string',
        required: true,
        validator: this.checkIdentifier,
        trigger: 'blur',
      },
    ],
    req: [
      {
        type: 'string',
        required: true,
        validator: this.checkUrl,
        trigger: 'blur',
      },
    ],
    helpDoc: [
      {
        type: 'string',
        pattern: /^(?:(http|https|ftp):\/\/)((?:[\w-]+\.)+[a-z\d]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i,
        message: this.$t('connector.accessPlatform.jumpLinkRight'),
        trigger: 'blur',
      },
    ],
  };
  async checkIdentifier(rule, value, callback) {
    const reg = /^\w+$/;
    if (this.operationFlag) {
      callback();
    } else if (value === '') {
      const str = this.$t('connector.api.apiIdentificationInput');
      callback(new Error(str));
    } else if (!reg.test(value)) {
      const str = this.$t('connector.api.numberCharactersUnderline');
      callback(new Error(str));
    } else {
      const params = {
        identifier: value,
        platformId: this.platformId,
      };
      try {
        const res = await checkApiIdentifier(params);
        if (res) {
          callback();
        } else {
          const str = this.$t('connector.api.apiIdentificationExist');
          callback(new Error(str));
        }
      } catch {
        return false;
      }
    }
  }
  checkUrl(rule, value, callback) {
    const reg = /^(?:(http|https|ftp):\/\/)((?:[\w-]+\.)+[a-z\d]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i;
    if (!value) {
      callback(new Error('请输入请求地址'));
    } else {
      if (!reg.test(value)) {
        const str = this.$t('connector.accessPlatform.jumpLinkRight');
        callback(new Error(str));
      } else {
        callback();
      }
    }
  }
  activeKey = 'url';
  changeTab(key) {
    this.activeKey = key;
  }
  handleTempChange(v) {
    this.form.templateRelay = v;
    this.reqData = [];
    this.resData = [];
    this.headerData = [];
    this.form.bodyRadio = 'JSON';
    this.bodyData = [];
    this.getTemplate();
  }
  /** @name 修改选项颜色 */
  handleColorChange(value) {
    this.form.color = value;
    this.$emit('changeColor', value);
  }

  disabledUrl = false;
  methodsChange(val) {
    this.form.reqWay = val;
    this.disabledUrl = val === 'THIRD_PARTY';
    if (val === 'POST') {
      this.$nextTick(() => {
        setTimeout(() => {
          this.activeKey = 'body';
        }, 300);
      });
    } else {
      if (val === 'THIRD_PARTY') {
        this.form.req = `https://oapi.sandieyun.com/v1/iot/${this.platformIdentifier}/${this.form.identifier}/`;
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.activeKey = 'url';
        }, 300);
      });
    }
  }
  bodyOptions = [
    { label: 'JSON', value: 'JSON' },
    { label: 'form-data', value: 'form-data' },
    { label: 'x-www-form-urlencoded', value: 'x-www-form-urlencoded' },
  ];
  bodyRadioChange(e) {
    this.form.bodyRadio = e.target.value;
  }
  get requestType() {
    const type = {
      url: 'req',
      body: 'body',
    };
    return type[this.activeKey];
  }
  templateList = [];
  async mounted() {
    await this.getTempList();
    await this.getBaseSettings();
  }
  async getTempList() {
    const params = {
      current: 1,
      size: 30,
      platformId: this.platformId,
    };
    try {
      const { records } = await getTemplateList(params);
      this.templateList = records
        .filter(f => f.templateStatus === 'NOT_ASSOCIATED')
        .map(v => {
          return {
            id: v.pkId,
            templateName: v.templateName,
          };
        });
    } catch {
      return false;
    }
  }
  async getBaseSettings() {
    try {
      const res = await getApiBasicSetting(this.apiId);
      const form = this.form;
      this.operationFlag = res.identifier !== '';
      form.identifier = res.identifier;
      form.templateRelay = res.dataTemplateId ? res.dataTemplateId : undefined;
      if (res.dataTemplateId) {
        const obj = {
          id: res.dataTemplateId,
          templateName: res.dataTemplateName,
        };
        this.templateList.push(obj);
        await this.getTemplate();
      }
      form.reqWay = res.requestMethod;
      form.req = res.requestUrl;
      this.disabledUrl = res.requestMethod === 'THIRD_PARTY';
      form.remark = res.remark;
      form.helpDoc = res.helpDocumentationUrl;
      form.colorful = res.themeColor !== '';
      form.color = res.themeColor !== '' ? res.themeColor : '#9743fe';
      if (res.themeColor) {
        this.$emit('changeColor', res.themeColor);
      }
      form.resFormat = res.returnType;
      this.reqData =
        res.requestUrlParam === '' ? [] : JSON.parse(res.requestUrlParam);
      const resDataList =
        res.returnParam === '' ? [] : JSON.parse(res.returnParam);
      if (Object.keys(this.outStore).length > 0) {
        eachSetJsonPathForStore(resDataList, this.outStore);
      }
      this.resData = resDataList;
      this.headerData =
        res.requestHeaderParam === '' ? [] : JSON.parse(res.requestHeaderParam);
      if (form.reqWay === 'POST') {
        const obj = JSON.parse(res.requestBodyParam);
        form.bodyRadio = obj.postFormat;
        this.bodyData = obj.data;
      }
    } catch {
      return false;
    }
  }

  @Debounce(200)
  async saveBasicForm() {
    try {
      const isValidate = await this.$refs.form.validate();
      if (isValidate) {
        const form = this.form;
        const params = {
          dataTemplateId: form.templateRelay,
          helpDocumentationUrl: form.helpDoc,
          identifier: form.identifier,
          interfaceId: this.apiId,
          remark: form.remark,
          requestMethod: form.reqWay,
          requestUrl: form.req,
          requestUrlParam:
            this.reqData.length > 0 ? JSON.stringify(this.reqData) : '',
          returnParam:
            this.resData.length > 0 ? JSON.stringify(this.resData) : '',
          requestHeaderParam:
            this.headerData.length > 0 ? JSON.stringify(this.headerData) : '',
          returnType: form.resFormat,
          themeColor: form.colorful ? form.color : '',
        };
        // requestBodyParam
        if (form.reqWay === 'POST') {
          const obj = {
            postFormat: form.bodyRadio,
            data: this.bodyData,
          };
          params['requestBodyParam'] = JSON.stringify(obj);
        }
        await updateApiBasicSetting(params);
        const str = this.$t('common.tips.save');
        this.$message.success(str);
        await this.getTempList();
        await this.getBaseSettings();
      }
    } catch {
      return false;
    }
  }
  close() {
    this.$emit('close');
  }

  paramsIn = [];
  paramsOut = [];
  outStore = {};
  async getTemplate() {
    this.paramsIn = [];
    this.paramsOut = [];
    const res = await getTemplateById(this.form.templateRelay);
    const result = res.map(v => {
      return {
        ...v,
        name: v.fieldName,
        type: v.fieldType,
        widget: JSON.parse(v.widget),
      };
    });
    const showArr = result.filter(v => v.widget?.isTabs !== true);
    this.paramsIn = this.changeParamArr(
      showArr[0].widget.tabs[0].fields,
      'req',
    );
    this.paramsOut = this.changeParamArr(
      showArr[0].widget.tabs[1].fields,
      'res',
      'out',
    );

    let data = flatFieldsId(this.paramsOut, {});
    this.outStore = new ReactiveStore(data);
  }
  changeParamArr(arr, flag, dpFlag = 'in') {
    if (arr.length === 0) return;
    const str =
      flag !== 'res'
        ? this.$t('connector.api.debuggerInput')
        : this.$t('connector.api.supportFixedValue');
    return arr.map(v => {
      const o = this.filedsMap(v, false, str);
      if (dpFlag !== 'in' && o.type !== ControlTypeEnum.DataPoint) {
        o.val = `$.${o.identifier}`;
      }
      if (v.type !== ControlTypeEnum.DataPoint) {
        if (v.widget?.fields?.length > 0) {
          o['children'] = v.widget.fields.map(x => {
            return this.filedsMap(x, true, str, v.pkId);
          });
          if (dpFlag !== 'in') {
            eachJsonPath(o.children, o.identifier);
          }
        }
      } else {
        if (v.widget.selectedDp.length > 0) {
          o['children'] = v.widget.selectedDp.map(x => {
            return this.filedsMapDp(x, true, '', v.pkId, dpFlag);
          });
          if (dpFlag !== 'in') {
            eachJsonPath(o.children, '');
          }
        }
      }
      return o;
    });
  }
  filedsMapDp(v, flag, str, id = '', dpflag) {
    let children = [];
    if (v.children && v.children.length > 0) {
      children = v.children[0].children && v.children[0].children;
      // DP的类型为【当前数据组】，只有一个child，需要设置child的【identifier】为父的【identifier】
      if (
        v.children[0].type.toLocaleLowerCase() ===
        ControlTypeEnum.CurrentDataSet.toLocaleLowerCase()
      ) {
        children = children[0].children;
      }
    }
    const o = {
      pkId: flag !== true ? v.pkId : id,
      name: v.functionName,
      type: v.type,
      open: true,
      isChildren: flag,
      isEdit: false,
      required: v.isRequired,
      identifier: v.functionIdentifier,
      dpType: true,
      val: dpflag === 'in' ? PLACEHOLDER_IN : PLACEHOLDER_OUT,
      children: children,
    };
    // 【DP数组组件】
    if (v.type === ControlTypeEnum.Array) {
      const item = v.children[0].children && v.children[0].children[0];
      if (item && item.details && item.details.subType) {
        if (Object.keys(item.details).length > 0) {
          o['details'] = item.details;
        }
      }
    }
    // 【DP的事件、服务】type为空，设置为结构体struct
    if (o.type === '') {
      o.type = 'struct';
    }
    // 【DP】的文本组件children的属性name为空
    o.children.forEach(child => {
      if (!child.name) {
        child.name = o.name;
      }
    });
    o['id'] = v.dataPointId;
    return o;
  }
  filedsMap(v, flag, str, id = '') {
    const o = {
      pkId: flag !== true ? v.pkId : id,
      name: v.name,
      type: v.type,
      open: true,
      isChildren: flag,
      isEdit: false,
      dpType: false,
      max: v.widget.max,
      min: v.widget.min,
      precision: v.widget.precision,
      maxLength: v.widget.maxLength,
      required: v.widget.required,
      identifier: v.widget.identifier,
      val: '',
    };
    o['id'] = v.pkId;
    if (v.type === ControlTypeEnum.Array) {
      o['subType'] = v.widget.subType;
      o['subCount'] = v.widget.subCount;
      o.val = str;
    } else if (v.type === ControlTypeEnum.Date) {
      o.val = v.widget.defaultValue.custom
        ? this.$moment(v.widget.defaultValue.custom).format(v.widget.format)
        : str;
      o.defaultType = v.widget.defaultType;
      o.format = v.widget.format;
      // 【日期】的【显示格式】
      o.displayFormat = v.widget.format;
      if (v.widget.paramFormat) {
        o.format = v.widget.paramFormat;
      }
      o.outFormat = v.widget.outFormat;
    } else if (v.type === ControlTypeEnum.DataPoint) {
      o.val = '按DP点结构解析';
      o.paramInFormat = v.widget.paramInFormat;
      o.paramInTag = v.widget.paramInTag;
    } else if (v.type === ControlTypeEnum.Switch) {
      o['is'] = v.widget.is;
      o['not'] = v.widget.not;
      o.val = v.widget.defaultValue.custom;
    } else {
      o.val =
        v.widget.defaultValue.custom && v.widget.defaultValue.custom.length > 0
          ? v.widget.defaultValue.custom
          : str;
    }
    o['inParams'] = v.widget.inParams;
    o['outParams'] = v.widget.outParams;
    o['description'] = v.widget.description;
    if (v.type === ControlTypeEnum.Enum) {
      o['isSingle'] = v.widget.isSingle;
      o['enumValueType'] = v.widget.enumValueType;
      o['optionList'] = v.widget.optionList;
      if (v.widget.defaultValue.custom.length > 0) {
        if (v.widget.isSingle) {
          const idx = v.widget.optionList.findIndex(
            s => s.id === v.widget.defaultValue.custom,
          );
          o.val = v.widget.optionList[idx].value;
        } else {
          let str = '';
          for (let i = 0; i < v.widget.optionList.length; i += 1) {
            for (let j = 0; j < v.widget.defaultValue.custom.length; j++) {
              if (
                v.widget.optionList[i].id === v.widget.defaultValue.custom[j]
              ) {
                str += `${v.widget.optionList[i].value}，`;
              }
            }
          }
          o.val = str.substring(0, str.length - 1);
        }
      } else {
        o.val = str;
      }
    }
    if (v.type === ControlTypeEnum.Switch) {
      o['is'] = v.widget.is;
      o['not'] = v.widget.not;
      o.val = v.widget.defaultValue.custom;
    }
    if (v.type === ControlTypeEnum.Image || v.type === ControlTypeEnum.File) {
      o['maxCount'] = v.widget.maxCount;
      o['maxSize'] = v.widget.maxSize;
      o['isStore'] = v.widget.isStore;
      o['storeSize'] = v.widget.storeSize;
    }
    if (v.type === ControlTypeEnum.Input) {
      o.defaultType = v.widget.defaultType;
      o.ruleType = v.widget.ruleType;
      o.ruleLength = v.widget.ruleLength;
      o.variable = v.widget.variable;
    }
    return o;
  }

  reqData = [];
  resData = [];
  bodyData = [];
  paramsClick(obj) {
    const { type, list } = obj;
    const arrTypeObj = {
      req: 'reqData',
      res: 'resData',
      body: 'bodyData',
    };
    if (!(Array.isArray(list) && list.length > 0)) return;
    if (type === 'res') {
      eachSetJsonPath(list, this.outStore);
    }
    this[arrTypeObj[type]] = list;

    let reflect = undefined;
    if (type === 'body') {
      reflect = this.$refs.bodyRef.$refs;
    } else if (type === 'req') {
      reflect = this.$refs.queryRef.$refs;
    }
    if (!reflect) return;
    const RefName = type + 'Ref';
    this.$nextTick(() => {
      setTimeout(() => {
        variableReset(reflect, RefName);
      }, 20);
    });
  }

  headerData = [];
  addCustomerParam() {
    const o = {
      id: uuid(),
      identifier: '',
      required: false,
      val: '',
    };
    this.headerData.push(o);
  }
  deleteHeader(refType, idx) {
    this.headerData.splice(idx, 1);
    setTimeout(() => {
      variableReset(this.$refs, refType);
    }, 20);
  }
  // type = '' 代表req和body 入参
  async chooseParameter(type = '') {
    const t = type !== '' ? type : this.requestType;
    let data = type !== '' ? this.paramsOut : this.paramsIn;
    const arrTypeObj = {
      req: 'reqData',
      res: 'resData',
      body: 'bodyData',
    };
    const addParams = await createFormModal(
      () => (
        <ApiParams
          dataType={t}
          paramsData={data}
          selectList={this[arrTypeObj[t]]}
          {...{
            on: {
              'update:paramsData': val => {
                data = val;
              },
            },
          }}
        />
      ),
      {
        title: '选择参数',
        width: 500,
      },
    );
    if (addParams) {
      this.paramsClick(addParams);
    }
  }
}
</script>
<style lang="less" module>
.buttonGroups {
  display: flex;
  justify-content: flex-start;
  .red {
    color: red;
  }
  .button {
    width: 50px;
    position: relative;
  }
}
:global {
  .ant-tag {
    border-radius: 12px;
    line-height: 12px;
  }
  .ant-form-item .ant-switch {
    margin: 2px 0 2px;
  }
}
.urlParams {
  max-height: 300px;
  overflow-y: auto;
  position: relative;
}
.flex {
  display: flex;
  align-items: center;
}
.treeListItem {
  height: 35px;
  padding-left: 30px;
  border-bottom: 1px solid var(--border-fade-20);
  border-left: 1px solid var(--border-fade-20);
  border-right: 1px solid var(--border-fade-20);
  &:first-child {
    border-top: 1px solid var(--border-fade-20);
  }
  &:not(:first-child):hover {
    background-color: var(--table-header-bg);
  }
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.width_150 {
  width: 150px;
}
.width_300 {
  width: 300px;
}
.width_250 {
  width: 250px;
}
.link {
  color: var(--primary);
  cursor: pointer;
  float: right;
  margin-right: 5px;
}
.rowClass {
  margin-bottom: 20px;
}
.color-picker {
  margin-left: 20px;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
</style>
