<template>
  <div :class="$style.dragItem">
    <Icon :icon="data" :class="$style.icon" />
    <span :class="$style.name" :title="data.iconName">
      {{ data.iconName }}
    </span>
    <span :class="$style.action">
      <x-icon
        v-if="$p.action('UPDATE')"
        type="edit"
        @click="handleEditIcon"
        :title="$t('iconLibrary.icon.edit')"
      />
      <x-icon
        v-if="$p.action('DELETE')"
        type="tc-icon-delete"
        style="color: var(--delete)"
        @click="handleDeleteIcon"
        :title="$t('common.action.delete')"
      />
      <x-icon
        v-if="$p.action('UPDATE')"
        type="tc-icon-change"
        @click="handleMove"
        :title="$t('iconLibrary.icon.copy')"
      />
      <x-icon
        type="link"
        @click="handleQuoteList"
        :title="$t('iconLibrary.icon.reference.details')"
      />
    </span>
  </div>
</template>
<script>
import { updateIcon } from '@/services/resource/icon';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Icon from '../icon';
import EditForm from './edit-form';
import QuoteList from './quote-list';
import MoveIcon from './move-icon';
import { addIcon, removeIcon } from '@/services/resource/icon-library';
import { createFormModal } from '@triascloud/x-components';

@Component({
  components: { Icon },
})
export default class IconItem extends Vue {
  @Prop() data;
  @Prop() library;

  /** @name 编辑名称 */
  async handleEditIcon() {
    const { iconName, pkId } = await EditForm.createModal(
      { data: this.data },
      { title: this.$t('iconLibrary.icon.edit') },
    );
    await updateIcon(iconName, pkId);
    this.$emit('change');
  }

  /** @name 引用详情 */
  async handleQuoteList() {
    await QuoteList.createQuoteListModal(
      { data: this.data, library: this.library },
      { title: this.$t('iconLibrary.icon.reference.details') },
    );
  }

  /** @name 复制图标至图标库  */
  async handleMove() {
    const { iconIds, iconLibraryId } = await MoveIcon.createModal(
      { data: this.data, library: this.library },
      { title: this.$t('iconLibrary.icon.copy') },
    );
    await addIcon(iconIds, iconLibraryId);
    await this.$message.success(this.$t('common.tips.copy'));
    this.$emit('refreshAll');
  }

  async handleDeleteIcon() {
    await createFormModal(
      () => (
        <div>
          <XIcon type="tc-icon-warning" class={this.$style.icon} />
          <p>{this.$t('iconLibrary.library.tips.delete')}</p>
        </div>
      ),
      {
        title: this.$t('common.tips.confirmDelete'),
        width: 420,
        wrapClassName: this.$style.delete,
      },
    );
    await removeIcon([this.data.pkId], this.library.pkId);
    await this.$message.success(this.$t('common.tips.delete'));
    this.$emit('refreshAll');
  }
}
</script>
<style lang="less" module>
:global {
  .tint {
    --block-hover-bg: #e9e9e9;
  }
  .dark {
    --block-hover-bg: #343434;
  }
}
.dragItem {
  width: 100px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;

  .icon {
    font-size: 40px;
    line-height: 1em;
    margin-bottom: 10px;
  }
  .name {
    font-size: 12px;
    color: var(--font);
    margin-bottom: 10px;
    padding: 0 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .action {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    display: none;

    i {
      font-size: 14px;
      &:hover {
        color: var(--primary);
        cursor: pointer;
      }
    }
  }

  &:hover {
    background: var(--block-hover-bg);
    border-radius: 4px;

    .action {
      display: flex;
    }
  }
}
.delete {
  text-align: center;
  .icon {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 30px;
    color: #fe5959;
  }
  p {
    font-size: 16px;
    color: var(--font);
  }
}
</style>
