<template>
  <a-dropdown
    :trigger="['click']"
    :overlayClassName="$style['custom-color-picker']"
    :key="value"
  >
    <slot
      ><span
        :class="$style['custom-color-picker--link']"
        :style="`background: ${value}`"
      ></span>
    </slot>
    <div slot="overlay">
      <custom-color-panel
        :value="value"
        @change="handleColorChange"
        :identifier="identifier"
      />
    </div>
  </a-dropdown>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomColorPanel from './color-panel.vue';

@Component({
  components: { CustomColorPanel },
})
export default class CustomColorPicker extends Vue {
  @Prop({ type: String, default: 'market.banner.color.picker' }) identifier;
  @Prop({ type: String, default: '' }) value;

  handleColorChange(value) {
    this.$emit('input', value);
  }
}
</script>
<style lang="less" module>
.custom-color-picker {
  width: 285px;
  padding: 10px;
  background: var(--dropdown-bg);
  border-radius: 4px;

  &--link {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
  }
}
</style>
