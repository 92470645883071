<template>
  <div>
    <Banner />
    <a-tabs @change="changeTab" :default-active-key="activeKey">
      <a-tab-pane
        key="data-template"
        :tab="$t('connector.dataTemplate.dataTemplate')"
      >
        <DataTemplate v-if="activeKey === 'data-template'" />
      </a-tab-pane>
      <a-tab-pane key="dp-manage" :tab="$t('connector.dpManage.tabName')">
        <DpManage v-if="activeKey === 'dp-manage'" />
      </a-tab-pane>
      <a-tab-pane key="api-config" :tab="$t('connector.connectors.name')">
        <ApiConfig
          v-if="activeKey === 'api-config'"
          :products="products"
          :platformIdentifier="platformIdentifier"
        />
      </a-tab-pane>
      <a-tab-pane key="data-manage" tab="订阅计划">
        <DataManage v-if="activeKey === 'data-manage'" />
      </a-tab-pane>
      <a-tab-pane key="device-manage" tab="设备管理">
        <DeviceManage v-if="activeKey === 'device-manage'" />
      </a-tab-pane>
      <div slot="tabBarExtraContent">
        <FormExtra :activeKey="activeKey" :products="products" />
      </div>
    </a-tabs>
  </div>
</template>
<script>
import { Component, Vue, Prop, ProvideReactive } from 'vue-property-decorator';
import Banner from './banner.vue';
import FormExtra from './form-extra.vue';
import DpManage from './dp-manage/index.vue';
import DeviceManage from './device-manage/index.vue';
import DataTemplate from './data-template/index';
import ApiConfig from './api-config/index';
import DataManage from './data-manage/index';
import { getProductsNoPage } from '@/services/iot-platform/dpManager';
import { Emitter, EventType } from './utils';

@Component({
  components: {
    Banner,
    FormExtra,
    DpManage,
    DataTemplate,
    ApiConfig,
    DeviceManage,
    DataManage,
  },
})
export default class Develop extends Vue {
  @ProvideReactive()
  @Prop({ type: String })
  id;
  @Prop({ type: String, default: '' }) platformIdentifier;

  mounted() {
    Emitter.$on(EventType.DEVICE_MANAGE_PRODUCT_UPDATE, this.getProductList);
  }

  activeKey = 'data-template';
  changeTab(key) {
    this.activeKey = key;
    if (key === 'device-manage' || key === 'api-config') {
      this.getProductList();
    }
  }

  products = [];
  async getProductList() {
    try {
      this.products = await getProductsNoPage(this.id);
    } catch {
      return false;
    }
  }
}
</script>
