<template>
  <div>
    <field-config-title v-bind="fieldProps" />
    <field-config-description v-bind="fieldProps" />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import FieldConfigTitle from '../components/field-config/title.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
@Component({
  components: {
    FieldConfigTitle,
    FieldConfigDescription,
  },
})
export default class FnNameConfig extends Vue {
  @Prop() field;
  @Prop() updateField;

  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
      name: false,
    };
  }
}
</script>
