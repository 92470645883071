<template>
  <div :class="$style.body">
    <a-form-model
      ref="form"
      :model="formData"
      :rules="rules"
      :class="$style.form"
    >
      <a-form-model-item v-if="parent" :label="$t('website.parentNav')">
        <a-input :value="parent" disabled />
      </a-form-model-item>
      <a-form-model-item :label="$t('help.name')" prop="name">
        <a-input
          v-model="formData.name"
          :placeholder="$t('common.tips.enterName')"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('website.url')" prop="path">
        <a-input
          v-model="formData.path"
          :placeholder="$t('connector.api.helpDescLinkInput')"
          @blur="formData.path = formData.path.trim()"
        />
      </a-form-model-item>
      <a-form-model-item
        v-if="showDesc"
        :label="$t('market.template.description')"
        prop="desc"
      >
        <a-input v-model="formData.desc" />
      </a-form-model-item>
      <a-form-model-item :label="$t('website.openNewWindow')" prop="target">
        <a-radio-group v-model="formData.target">
          <a-radio value="_blank">{{ $t('template.review.checked') }}</a-radio>
          <a-radio value="_self">{{ $t('template.review.unChecked') }}</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('website.showOnMobile')"
        prop="showOnMobile"
      >
        <a-radio-group v-model="formData.showOnMobile">
          <a-radio :value="1">{{ $t('template.review.checked') }}</a-radio>
          <a-radio :value="0">{{ $t('template.review.unChecked') }}</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('website.showOnPc')" prop="showOnMobile">
        <a-radio-group v-model="formData.showOnPc">
          <a-radio :value="1">{{ $t('template.review.checked') }}</a-radio>
          <a-radio :value="0">{{ $t('template.review.unChecked') }}</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
    <div :class="$style.footer">
      <a-button type="primary" @click="submit">{{
        $t('common.action.confirm')
      }}</a-button>
    </div>
  </div>
</template>
<script>
import { globalVueI18n } from '@triascloud/i18n';
import { createDrawer } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';

function createNavigationDrawer(props, drawerOption = {}) {
  return new Promise((resolve, reject) => {
    const drawer = createDrawer(
      h =>
        h(NavigationDrawer, {
          props,
          on: {
            confirm: data => {
              drawer.visible = false;
              resolve(data);
            },
            close: () => {
              drawer.visible = false;
              reject(new Error('close'));
            },
          },
        }),
      {
        title: props.data
          ? globalVueI18n.translate('website.editNav')
          : globalVueI18n.translate('website.addNav'),
        width: 560,
        bodyStyle: { padding: 0 },
        ...drawerOption,
      },
    );
  });
}
@Component
export default class NavigationDrawer extends Vue {
  @Prop({ type: Object }) data;
  @Prop({ type: String }) parent;
  @Prop({ type: Boolean, default: false }) showDesc;
  static createDrawer = createNavigationDrawer;
  formData = {
    id: '',
    name: '',
    path: '',
    showOnMobile: 1,
    showOnPc: 1,
    target: '_self',
    desc: '',
  };
  get rules() {
    return {
      name: [
        {
          required: true,
          message: this.$t('common.placeholder.input') + this.$t('help.name'),
        },
      ],
      // path: [
      //   {
      //     required: true,
      //     message: this.$t('common.placeholder.input') + this.$t('website.url'),
      //   },
      // ],
    };
  }
  created() {
    if (this.data) {
      Object.keys(this.data).forEach(key => {
        if (this.formData[key] !== undefined) {
          this.formData[key] = this.data[key];
        }
      });
    }
  }

  async submit() {
    await this.$refs.form.validate();
    this.$emit('confirm', this.formData);
  }
}
</script>
<style lang="less" module>
.body {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.form {
  padding: 20px;
  flex: 1;
  padding-right: 140px;
}
.footer {
  border-top: 1px solid var(--border);
  padding: 10px 20px;
}
</style>
