<template>
  <div :class="$style.main">
    <div :class="$style.content">
      <div :class="$style.detail">
        <!-- <div :class="$style.head">开票申请详情</div> -->
        <div :class="$style['bill-info']">
          <a-descriptions :title="$t('finance.order.BillingInfo')" :column="1">
            <a-descriptions-item
              v-for="(item, key) in labelObj"
              :key="key"
              :label="item"
            >
              <div v-if="key === 'invoiceType'">
                {{ InvoiceTypeText[info[key]] }}
              </div>
              <div v-else>
                {{ info[key]
                }}{{
                  key === 'invoiceContent' && !info[key] ? '技术服务费' : ''
                }}
              </div>
            </a-descriptions-item>
          </a-descriptions>
          <div :class="$style['table-info']">
            <div :class="$style.title">
              {{ $t('finance.order.OrderInformation') }}
            </div>
            <a-table
              rowKey="idxTradeId"
              :columns="orderColumns"
              :data-source="info.invoiceTradeVO.tradeBaseVOList"
              :scroll="{ x: 600 }"
              :pagination="false"
            />
          </div>
          <div :class="$style['table-info']">
            <a-descriptions
              :title="$t('finance.order.ExpressInfo')"
              :column="1"
              v-if="info.invoiceProcessing"
            >
              <a-descriptions-item
                v-for="(item, key) in expressData"
                :key="key"
                :label="item"
              >
                <div>{{ info.invoiceProcessing[key] }}</div>
              </a-descriptions-item>
            </a-descriptions>
            <!-- <div :class="$style.title">快递信息</div> 
            <a-table
              :columns="expressColumns"
              :data-source="expressData"
              :rowKey="(row, index) => index"
              :scroll="{ x: 565 }"
              :pagination="false"
            /> -->
          </div>
        </div>
      </div>
      <div :class="$style.dynamic">
        <div :class="$style.head">开票动态</div>
        <div :class="$style.list" v-if="invoiceData.length">
          <div
            :class="$style.item"
            v-for="item in invoiceData"
            :key="item.createDate"
          >
            <div :class="$style.date">
              {{ $moment(item.createDate).format('YYYY-MM-DD HH:mm') }}
            </div>
            <div :class="$style.action">
              {{
                item.operationType === 1
                  ? '开票申请'
                  : $t('finance.order.InvoiceApproval')
              }}
            </div>
            <div :class="$style.operator">操作人：{{ item.createName }}</div>
            <div :class="$style['company-name']">备注:{{ item.content }}</div>
          </div>
        </div>
        <EmptyContent v-else type="list" />
      </div>
    </div>
    <footer :class="$style.foot">
      <a-button :class="$style.close" @click="$emit('close')">
        {{ $t('finance.order.close') }}
      </a-button>
      <a-button
        type="primary"
        v-if="info.invoiceStatus === InvoiceStatus['TO_BE_INVOICED']"
        @click="handleBill"
      >
        {{ $t('finance.order.InvoiceApproval') }}
      </a-button>
    </footer>
  </div>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { createFormModal, createModal } from '@triascloud/x-components';
import {
  getDetail,
  getInvoiceDynamics,
} from '@/services/transaction/invoice.js';
import {
  BillStatusText,
  InvoiceTypeText,
  OrderStatusText,
  InvoiceStatus,
} from '@/enum/order-bill.js';
import EmptyContent from '@/components/empty-content';
import CompanyOrder from '../components/company-order.vue';
import ApplyInvoice from './apply-invoice';

@Component({
  components: { EmptyContent },
})
export default class dtail extends Vue {
  @Prop({ type: String, default: '' }) pkId;
  @Prop({ type: String, default: '' }) companyId;
  InvoiceTypeText = InvoiceTypeText;
  invoiceData = [];
  labelBase = {
    invoiceId: this.$t('finance.order.BillingOrderNo'),
    invoice: this.$t('finance.order.InvoiceTitle'),
    taxNumber: this.$t('finance.order.UnitTaxNo'),
    invoiceType: this.$t('finance.order.InvoiceType'),
    invoiceContent: this.$t('finance.order.InvoiceContent'),
    taxPoint: this.$t('finance.order.TaxRate'),
    invoiceAmount: this.$t('finance.order.InvoiceAmount'),
    invoiceStatusText: this.$t('finance.order.BillingStatus'),
    applicant: this.$t('solution.applicant'),
  };

  expressData = {
    addresseeName: this.$t('finance.order.addressee'),
    receivingPhone: this.$t('finance.order.TelephoneNumber'),
    shippingAddress: this.$t('finance.order.ReceivingAddress'),
    sendCourier: this.$t('finance.order.express'),
    trackingNumber: this.$t('finance.order.ExpressNo'),
  };

  // expressData = [
  //   {
  //     express: '顺丰快递',
  //     num: '6604289000323890',
  //     time: '2020.09.04 15:12:20',
  //     addressee: '小黑豆',
  //     mobile: '13729823610',
  //     address: '广东省黄埔区',
  //   },
  // ];

  get labelObj() {
    return this.info.invoiceType === 'GENERAL'
      ? this.labelBase
      : {
          ...this.labelBase,
          registeredAddress: this.$t('finance.order.RegisteredAddress'),
          registeredPhone: this.$t('finance.order.RegistrationTelephone:'),
          bankName: this.$t('finance.order.BankOfDeposit'),
          bankAccount: this.$t('activity.account'),
        };
  }

  InvoiceStatus = InvoiceStatus;
  info = {
    invoiceTradeVO: {
      tradeBaseVOList: [],
    },
  };
  get orderColumns() {
    return [
      {
        title: this.$t('finance.order.OrderNo'),
        dataIndex: 'tradeCode',
        width: 180,
      },
      {
        title: this.$t('finance.order.CommodityName'),
        dataIndex: 'productName',
        width: 120,
      },
      {
        title: this.$t('finance.order.SubmissionTime'),
        key: 'createDate',
        width: 200,
        customRender: record =>
          record.createDate &&
          this.$moment(record.createDate).format('YYYY.MM.DD HH:mm:ss'),
      },
      {
        title: this.$t('solution.paymentStatus'),
        key: 'tradeStatus',
        width: 100,
        customRender: record => (
          <span>{OrderStatusText[record.tradeStatus]}</span>
        ),
      },
      {
        title: '优惠券',
        key: 'reduceAmount',
        width: 100,
        customRender: record => (
          <span>
            {record.reduceAmount
              ? '￥' + (record.reduceAmount / 100).toFixed(2)
              : '-'}
          </span>
        ),
      },
      {
        title: this.$t('common.action.operating'),
        key: 'action',
        fixed: 'right',
        width: 100,
        customRender: record =>
          record.tradeCode && (
            <a onClick={() => this.viewDetail(record.tradeId)}>
              {this.$t('finance.order.ViewDetails')}
            </a>
          ),
      },
    ];
  }
  expressColumns = [
    {
      title: '寄送快递',
      dataIndex: 'express',
      key: 'express',
      width: 100,
    },
    {
      title: '快递单号',
      dataIndex: 'num',
      key: 'num',
      width: 150,
      ellipsis: true,
    },
    {
      title: '寄送时间',
      dataIndex: 'time',
      key: 'time',
      width: 150,
    },
    {
      title: '收件人',
      dataIndex: 'addressee',
      key: 'addressee',
      width: 100,
      ellipsis: true,
    },
    {
      title: '收件人电话',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 120,
      ellipsis: true,
    },
    {
      title: '收件人地址',
      key: 'address',
      dataIndex: 'address',
      width: 150,
      ellipsis: true,
    },
    {
      title: '附件',
      key: 'annex',
      fixed: 'right',
      width: 100,
      scopedSlots: { customRender: 'annex' },
    },
  ];

  mounted() {
    this.getInvoiceDetail();
  }

  async getInvoiceDetail() {
    const data = await getDetail(this.pkId);
    this.invoiceData = await getInvoiceDynamics(this.pkId);
    data.taxPoint = `${data.taxPoint}%`;
    // data.invoiceType = InvoiceTypeText[data.invoiceType];
    data.invoiceStatusText = BillStatusText[data.invoiceStatus];
    data.invoiceAmount = `￥${(data.invoiceTradeVO.invoiceAmount / 100).toFixed(
      2,
    )}`;
    data.invoiceTradeVO.tradeBaseVOList.map(item => {
      this.$set(
        item,
        'productName',
        item.productList.map(prod => prod.productName).join(),
      );
      this.$set(item, 'idxTradeId', item.tradeCode);
    });
    this.info = data;
  }

  async handleBill() {
    await createFormModal(
      () => (
        <ApplyInvoice
          pkId={this.pkId}
          data={this.info}
          companyId={this.companyId}
        />
      ),
      {
        title: this.$t('solution.orderBilling'),
        width: 700,
      },
    );
    this.$emit('close', true);
  }

  viewDetail(id) {
    createModal(() => <CompanyOrder pkId={id} />, {
      title: '企业订单',
      width: 700,
      wrapClassName: 'company-order-drawer',
    });
  }
}
</script>
<style lang="less" module>
.main {
  .content {
    display: flex;
    margin-bottom: 70px;
    .head {
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid var(--tag-border);
      padding-left: 20px;
    }
    .detail {
      display: flex;
      flex-direction: column;
      flex: 1;
      border-right: 1px solid var(--tag-border);
    }
  }
  .bill-info {
    padding: 20px;
    height: calc(100vh - 174px);
    overflow-y: scroll;
  }
  .table-info {
    display: flex;
    flex-direction: column;
    width: 565px;
    margin-bottom: 15px;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.85);
  }
  .dynamic {
    width: 312px;
    .list {
      height: calc(100vh - 174px);
      overflow-y: scroll;
      padding: 20px 10px;
    }
    .item {
      border-radius: 4px;
      margin-bottom: 10px;
      padding: 10px;
      background: #e5e5e5;
    }
    .action {
      color: var(--active-switch);
    }
  }
  .foot {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    line-height: 70px;
    border-top: 1px solid var(--tag-border);
    padding: 0 24px;
    background: var(--block-bg);
    z-index: 99;
    .close {
      margin-right: 15px;
    }
  }
  :global {
    .ant-descriptions-item-label {
      width: 100px;
    }
  }
}
:global .bill-drawer {
  .ant-drawer-body {
    padding: 0;
  }
}
// :global .ant-modal-root .company-order-drawer .ant-modal .ant-modal-content {
//   box-shadow: none;
// }
</style>
