<template>
  <div>
    <span v-if="!field.widget.fields.length">
      从左侧“通用数据”中拖拽添加字段，不支持数组和结构体类型
    </span>
    <draggable
      :value="field.widget.fields"
      @input="handleDragInput"
      :group="{ name: 'tab', put: ['control', 'tab'] }"
      ghost-class="x-tab-design--ghost"
      :animation="300"
      class="x-tab-design--drag"
    >
      <a-form-model-item
        v-for="field in field.widget.fields"
        @click.native.stop="selectField(field)"
        :key="field.pkId"
        :data-type="field.type"
        :class="[`x-tab-design--type-${field.type}`, getFieldClass(field)]"
        :prop="field.pkId"
        :rules="generateRules(field)"
      >
        <template slot="label">
          <x-icon v-if="field.widget.param" type="tc-icon-param" />
          <label
            v-if="!field.widget.hideTitle"
            :class="field.widget.required && 'ant-form-item-required'"
            >{{ field.name }}</label
          >
          <a-tooltip
            :overlayClassName="
              field.widget.description.length > 100 ? $style.tooltip : ''
            "
            v-if="field.widget.description"
            :arrowPointAtCenter="true"
            placement="right"
          >
            <div
              v-html="field.widget.description"
              :class="$style.boxHtml"
              slot="title"
            ></div>
            <a-icon type="info-circle" :class="$style.tooltipIcon" />
          </a-tooltip>
        </template>
        <field-render
          type="design"
          :field="field"
          :value="defaultData[field.pkId]"
          :fields="field.widget.fields"
          :updateField="updateSelectedField"
        />
        <drag-action
          v-if="
            selectedField.pkId === field.pkId && !selectedField.widget.alone
          "
          :clone="CustomTemplate"
          @clone="handleCloneField(field)"
          @delete="handleDelete(field)"
        />
      </a-form-model-item>
    </draggable>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { generateMap } from '@triascloud/utils';
import { set, difference, cloneDeep } from 'lodash';
// import { set, difference } from 'lodash';
import Draggable from 'vuedraggable';
import { templateDesignModule } from '@/enum/store';
import { FieldRender } from '../../render';
import DragAction from '../components/drag-action.vue';
import { ControlTypeEnum } from '../enum';
import {
  getControlMeta,
  getFieldTagClass,
  TemplateType,
  cloneField,
  flatFields,
  getFieldDefaultValue,
} from '../index';
import { generateWidgetRulesFromField } from '../validateWidget';

@Component({
  components: {
    Draggable,
    FieldRender,
    DragAction,
  },
})
export default class StructDesign extends Vue {
  @Prop({ required: true }) field;
  @templateDesignModule.State selectedField;
  @templateDesignModule.State fieldTagMap;
  @templateDesignModule.State templateType;
  @templateDesignModule.Getter fields;
  @templateDesignModule.Mutation selectField;
  @templateDesignModule.Mutation updateSelectedField;
  @templateDesignModule.Mutation tagField;
  @templateDesignModule.Action updateFields;

  get defaultData() {
    return generateMap(
      flatFields(this.field.widget.fields, { param: true }),
      'pkId',
      getFieldDefaultValue,
    );
  }

  get CustomTemplate() {
    return this.templateType === TemplateType.CUSTOM_TEMPLATE;
  }

  getFieldClass(field) {
    const { selectedField, fieldTagMap } = this;
    return [
      ['x-tab-design--item-column-1'],
      {
        selected: selectedField.pkId === field.pkId,
        [`x-tab-design--action-open`]: getControlMeta(field.type, 'actionOpen'),
        ...getFieldTagClass(field.pkId, fieldTagMap),
      },
    ];
  }

  updateField(fnOrPath) {
    return this.updateFields(fields => {
      if (typeof fnOrPath === 'function') {
        fnOrPath(this.field);
      } else {
        set(this.field, fnOrPath.key, fnOrPath.value);
      }
      return fields;
    });
  }

  async handleDragInput(fields) {
    if (
      fields.some(field =>
        [
          ControlTypeEnum.Struct,
          ControlTypeEnum.Array,
          ControlTypeEnum.DataPoint,
        ].includes(field.type),
      )
    ) {
      this.$message.error(this.$t('controls.label.unsupported'));
      return;
    }
    let newField = undefined;
    if (fields.length > this.field.widget.fields.length) {
      newField = difference(fields, this.field.widget.fields)[0];
    }

    const cloneFields = cloneDeep(fields);
    cloneFields.forEach(field => {
      field.widget.parentId = this.field.pkId;
      field.widget.inParams = this.field.widget.inParams;
    });

    setTimeout(() => {
      this.updateField({
        key: `widget.fields`,
        value: cloneFields,
      });
    }, 0);

    if (newField) {
      const field = cloneFields.find(field => field.pkId === newField.pkId);
      this.selectField(field);
    }
  }

  handleDelete(currentField) {
    const fields = this.field.widget.fields;
    this.updateField({
      key: 'widget.fields',
      value: fields.filter(field => field.pkId !== currentField.pkId),
    });
    this.selectField(this.field);
  }

  handleCloneField(currentField) {
    const newFields = [...this.field.widget.fields];
    const fieldIndex = newFields.indexOf(currentField);
    const newField = cloneField(currentField);
    newFields.splice(fieldIndex + 1, 0, newField);
    this.updateField({
      key: 'widget.fields',
      value: newFields,
    });
    this.tagField({
      field: newField,
      tag: 'clone',
    });
    this.selectField(newField);
  }

  generateRules(field) {
    return generateWidgetRulesFromField(field, this.getField);
  }

  getField(fieldId) {
    return this.fieldMap[fieldId] || null;
  }

  get fieldMap() {
    return generateMap(
      flatFields([...this.field.widget.fields], {
        subform: true,
        parent: true,
        layout: true,
        param: true,
      }),
      'pkId',
      field => field,
    );
  }
}
</script>
<style lang="less">
@prefix: x-tab-design;
.@{prefix} {
  box-sizing: border-box;
  background: var(--dropdown-bg);
  margin: 0 -10px;
  .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
    height: 28px;
    line-height: 28px;
    margin-right: 40px;
  }
  .ant-tabs-bar.ant-tabs-top-bar {
    margin-bottom: 5px;
    padding: 0 25px;
  }
  .x-grid-design {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.@{prefix}--drag {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  min-height: 100px;
  padding-bottom: 20px;
  width: 100%;
  overflow: hidden;
  > .ant-form-item {
    padding: 5px 10px 15px;
    position: relative;
    margin-bottom: 0;
    &.selected {
      background: var(--primary-fade-10);
    }
    &.@{prefix}--type-text,
    // &.@{prefix}--type-textarea, // 多列增补，多行文本整行时宽度100%
    &.@{prefix}--type-date,
    &.@{prefix}--type-int,
    &.@{prefix}--type-float,
    &.@{prefix}--type-double,
    &.@{prefix}--type-fnName,
    &.@{prefix}--type-fnIdentifier {
      > .ant-form-item-control-wrapper {
        max-width: 400px;
      }
    }
    &.@{prefix}--type-groupTitle {
      .x-grouping-line-ninth {
        margin: 3px -10px 0;
      }
    }
  }
  > .ant-form-item::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
  }
  > .@{prefix}--action-open.ant-form-item::after {
    content: none;
  }
  .@{prefix}--ghost {
    background-color: var(--primary-fade-10) !important;
    width: 100% !important;
    height: 0 !important;
    font-size: 0 !important;
    padding: 30px 0 !important;
    margin: 0;
    border: none;
    overflow: hidden;
    border-radius: 0;
    box-shadow: none !important;
    &::before {
      content: none !important;
    }
    .ant-col,
    [class^='x-subform-design'] {
      display: none;
    }
  }
}
</style>

<style lang="less" module>
.tooltipIcon {
  margin: 0 5px;
  z-index: 101;
  position: relative;
}
.tooltip {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  max-width: 250px;
}
.boxHtml {
  img {
    display: none;
  }
}
</style>
