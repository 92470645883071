<template>
  <div :class="$style.imageWrap">
    <img v-if="this.isImage" :src="src" alt="" />
    <img v-else src="@/assets/img/default-avatar.png" alt="" />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class image extends Vue {
  @Prop({ type: String, default: '' }) src;
  isImage = true;
  async created() {
    this.checkImgExists(this.src);
  }
  checkImgExists(imgurl) {
    var ImgObj = new Image();
    ImgObj.src = imgurl;
    ImgObj.onload = () => {
      this.isImage = true;
    };
    ImgObj.onerror = () => {
      this.isImage = false;
    };
  }
}
</script>
<style lang="less" module>
.imageWrap {
  display: inline-block;
  margin-right: 5px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}
</style>
