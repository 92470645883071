<template>
  <div :class="$style.index">
    <div :class="$style.typeList">
      <div
        v-for="item in typeList"
        :key="item"
        :class="[$style.typeItem, current === item ? $style.active : '']"
        @click="current = item"
      >
        {{ $t(`common.action.${item}`) }}
      </div>
    </div>
    <SolutionApprovalListTable v-if="current === 'review'" />
    <SolutionListTable v-if="current === 'manage'" />
    <Statistics v-show="current === 'statistics'" />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import SolutionListTable from './components/solution-list-table.vue';
import SolutionApprovalListTable from './components/solution-approval-list-table.vue';
import Statistics from './statistics.vue';

@Component({
  components: {
    SolutionListTable,
    SolutionApprovalListTable,
    Statistics,
  },
})
export default class Management extends Vue {
  current = 'review';
  get typeList() {
    return ['review', 'manage', 'statistics'];
  }
}
</script>
<style lang="less" module>
.index {
  .typeList {
    display: flex;
    margin: 10px 0 10px 20px;
    .typeItem {
      margin: 0 10px;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }
    }
    .active {
      color: var(--primary);
    }
  }
}
</style>
