var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{class:[_vm.$style.card, ( _obj = {}, _obj[_vm.$style['card--bg']] = _vm.data.bgColor, _obj )],style:({ color: _vm.data.bgColor }),attrs:{"href":_vm.helpUrl,"target":"_blank"}},[_c('div',{class:_vm.$style.card__header},[_c('x-custom-icon',{class:_vm.$style.card__icon,attrs:{"type":_vm.data.icon}}),_c('span',{class:_vm.$style.card__header__name,attrs:{"title":_vm.data.centerName}},[_vm._v(_vm._s(_vm.data.centerName))])],1),_c('div',{class:_vm.$style.card__title},[_vm._v(_vm._s(_vm.data.repositoryName))]),_c('div',{class:_vm.$style.card__footer},[_c('span',{class:_vm.$style.card__label},[_vm._v(_vm._s(_vm.data.promulgator && _vm.data.promulgator.memberName))]),_c('span',[_vm._v(_vm._s(_vm.data.createDate && _vm.$moment(_vm.data.createDate).format('yyyy.MM.DD hh:mm')))])]),_c('div',{class:_vm.$style.card__footer},[_c('span',{class:_vm.$style.card__label,attrs:{"title":_vm.$t('help.validPeriod')}},[_vm._v(_vm._s(_vm.$t('help.validPeriod')))]),_c('span',[_vm._v(_vm._s(_vm.data.forever ? _vm.$t('help.forever') : _vm.$moment(_vm.data.expireDate).format('yyyy.MM.DD hh:mm')))])]),_c('div',{class:_vm.$style.controllers,on:{"click":function($event){$event.preventDefault();}}},[_c('x-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.$p.action('SORT')),expression:"$p.action('SORT')"}],class:[
        _vm.$style.controllers__icon,
        _vm.$style['controllers__icon--drag'],
        'draggle-handle' ],attrs:{"type":"drag"}}),_c('div',[(_vm.$p.action('DELETE'))?_c('a-tooltip',{attrs:{"title":_vm.$t('common.action.delete')}},[_c('x-icon',{class:[
            _vm.$style.controllers__icon,
            _vm.$style['controllers__icon--delete'] ],attrs:{"type":"tc-icon-delete"},on:{"click":_vm.remove}})],1):_vm._e(),(_vm.$p.action('CREATE'))?_c('a-tooltip',{attrs:{"title":_vm.$t('solution.edit')}},[_c('x-icon',{class:_vm.$style.controllers__icon,attrs:{"type":"setting"},on:{"click":_vm.edit}})],1):_vm._e()],1)],1),_c('div',{class:[
      _vm.$style.card__status,
      ( _obj$1 = {}, _obj$1[_vm.$style['card__status--open']] = _vm.data.open, _obj$1 ) ]},[_vm._v(" "+_vm._s(_vm.data.open ? _vm.$t('help.open') : _vm.$t('help.closed'))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }