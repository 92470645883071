<template>
  <a
    :class="[$style.card, { [$style['card--bg']]: data.bgColor }]"
    :style="{ color: data.bgColor }"
    :href="helpUrl"
    target="_blank"
  >
    <div :class="$style.card__header">
      <x-custom-icon :class="$style.card__icon" :type="data.icon" />
      <span :class="$style.card__header__name" :title="data.centerName">{{
        data.centerName
      }}</span>
    </div>
    <div :class="$style.card__title">{{ data.repositoryName }}</div>
    <div :class="$style.card__footer">
      <span :class="$style.card__label">{{
        data.promulgator && data.promulgator.memberName
      }}</span>
      <span>{{
        data.createDate && $moment(data.createDate).format('yyyy.MM.DD hh:mm')
      }}</span>
    </div>
    <div :class="$style.card__footer">
      <span :class="$style.card__label" :title="$t('help.validPeriod')">{{
        $t('help.validPeriod')
      }}</span>
      <span>{{
        data.forever
          ? $t('help.forever')
          : $moment(data.expireDate).format('yyyy.MM.DD hh:mm')
      }}</span>
    </div>
    <div :class="$style.controllers" @click.prevent>
      <x-icon
        v-show="$p.action('SORT')"
        type="drag"
        :class="[
          $style.controllers__icon,
          $style['controllers__icon--drag'],
          'draggle-handle',
        ]"
      />
      <div>
        <a-tooltip
          v-if="$p.action('DELETE')"
          :title="$t('common.action.delete')"
        >
          <x-icon
            type="tc-icon-delete"
            :class="[
              $style.controllers__icon,
              $style['controllers__icon--delete'],
            ]"
            @click="remove"
          />
        </a-tooltip>
        <a-tooltip v-if="$p.action('CREATE')" :title="$t('solution.edit')">
          <x-icon
            type="setting"
            :class="$style.controllers__icon"
            @click="edit"
          />
        </a-tooltip>
      </div>
    </div>
    <div
      :class="[
        $style.card__status,
        { [$style['card__status--open']]: data.open },
      ]"
    >
      {{ data.open ? $t('help.open') : $t('help.closed') }}
    </div>
  </a>
</template>
<script>
import { remove } from '@/services/resource/helpCenter';
import { createModuleUrl } from '@/utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import HelpSettingModal from './help-setting-modal.vue';

@Component()
export default class Card extends Vue {
  @Prop({ type: Object, required: true }) data;
  get helpUrl() {
    return `${createModuleUrl('help')}/doc/${this.data.idxRepositoryId}`;
  }
  async edit() {
    await HelpSettingModal.createModal({ data: this.data });
    this.$emit('update');
  }
  async remove() {
    await this.$confirm(this.$t('help.delete'));
    await remove(this.data.pkCenterId);
    this.$message.success(this.$t('common.tips.delete'));
    this.$emit('remove');
  }
}
</script>
<style lang="less" module>
.card {
  position: relative;
  display: inline-block;
  height: 264px;
  width: 214px;
  color: var(--main-bg);
  box-shadow: 13px 9px 22px 0px var(--shadow);
  border-radius: 0px 15px 15px 0px;
  background: currentColor;
  cursor: pointer;
  padding: 22px;
  overflow: hidden;
  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(51, 51, 51, 0.08);
    width: 10px;
    height: 100%;
  }
  .card__header,
  .card__title,
  .card__footer,
  .controllers {
    color: var(--font-sub);
  }
  .card__header {
    display: flex;
    align-items: center;
    .card__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--primary-fade-10);
      border-radius: 50%;
      font-size: 18px;
      height: 36px;
      margin-right: 10px;
      width: 36px;
    }
    .card__header__name {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .card__title {
    margin: 50px 0 40px;
    text-align: center;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .card__footer {
    line-height: 1.8;
    .card__label {
      display: inline-block;
      margin-right: 10px;
      max-width: 44px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: middle;
    }
  }
  .controllers {
    height: 42px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    display: none;
    width: 215px;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 20px;
    display: none;
    align-items: center;
    border-radius: 0 0 5px 5px;
    background: var(--primary-fade-10);
    font-size: 20px;
    .controllers__icon {
      cursor: pointer;
      &:hover {
        color: var(--font-active);
      }
    }
    .controllers__icon--drag {
      cursor: move;
    }
    .controllers__icon--delete {
      margin-right: 10px;
      &:hover {
        color: var(--delete);
      }
    }
  }
  .card__status {
    position: absolute;
    width: 100px;
    color: #fff;
    right: -20px;
    top: 15px;
    background: #c7c7c7;
    text-align: center;
    transform: rotate(40deg);
    &.card__status--open {
      background: var(--success);
    }
  }
  &.card--bg {
    &:hover {
      box-shadow: 0px 0px 14px 0px currentColor;
    }
    .card__header,
    .card__title,
    .card__footer,
    .controllers {
      color: #fff;
    }
    .card__header {
      .card__icon {
        background: rgba(255, 255, 255, 0.2);
      }
    }
    .controllers {
      background: rgba(255, 255, 255, 0.3);
    }
  }
  &:hover {
    box-shadow: 0px 0px 14px 0px var(--primary);
    color: var(--main-bg);
    .controllers {
      display: flex;
    }
  }
}
</style>
