<template>
  <div :class="$style.qualityCheck">
    <div :class="$style.tableWrap">
      <FlexColContent>
        <div :class="$style.operation">
          <div :class="$style.searchBox">
            <a-select
              show-search
              v-model="type"
              placeholder="全部"
              @change="handleSelect"
              :class="$style.searchSelect"
            >
              <a-select-option value="ALL"> 全部 </a-select-option>
              <a-select-option value="MINE"> 我的 </a-select-option>
            </a-select>
            <a-select
              show-search
              v-model="searchParams.status"
              @change="() => $refs.tableRef.search()"
              :class="$style.searchSelect"
            >
              <a-select-option
                :value="item.value"
                v-for="item in typeList"
                :key="item.text"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
            <a-input
              :class="$style.searchInput"
              v-model="searchParams.input"
              @input.stop="() => $refs.tableRef.search()"
              placeholder="设备号/姓名"
            >
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
          <a-button
            type="primary"
            @click="showFormModel({}, 'add')"
            v-if="$p.action('CREATE')"
            >+新增</a-button
          >
        </div>
        <template slot="footer">
          <PaginationTable
            ref="tableRef"
            :columns="columns"
            :api="getDeviceList"
            :extraKeys="{ showKey: false }"
            row-key="pkId"
            :scroll="{ x: 1600, y: 'max-content' }"
            :slotKeys="['action', 'deviceName', 'progress', 'detail']"
            :search-params="searchParams"
          >
            <template slot="deviceName" slot-scope="{ record }">
              <div :class="$style.deviceCell">
                <span>{{ record.deviceName }}</span>
                <span
                  :style="{
                    backgroundColor: hatColors[record.color],
                    color: '#ffffff',
                    fontSize: '16px',
                  }"
                >
                  <span :class="$style.plusTxt" v-if="record.type === 'PLUS'"
                    >plus</span
                  >
                  <x-icon v-else type="tc-icon-hat-smile" />
                </span>
              </div>
            </template>
            <template slot="progress" slot-scope="{ record }">
              <div>
                {{ getTestProgress(record) }}
              </div>
            </template>
            <template slot="detail" slot-scope="{ record }">
              <div
                :class="$style.testDetail"
                @click="showTestDetailModal(record)"
              >
                {{ getTestDetail(record.detailResult) }}
              </div>
            </template>
            <template slot="action" slot-scope="{ record }">
              <div :class="$style.buttonGroups">
                <span
                  :class="$style.button"
                  @click="showFormModel(record, 'detail')"
                  v-if="record.status === 'FINISH'"
                  >详情</span
                >
                <template v-else>
                  <span
                    :class="$style.button"
                    @click="showFormModel(record, 'edit')"
                    v-if="$p.action('UPDATE')"
                    >{{ $t('common.action.edit2') }}</span
                  >
                  <span
                    :class="$style.button"
                    @click="handleDelete(record)"
                    v-if="$p.action('DELETE')"
                    >{{ $t('common.action.delete') }}</span
                  >
                </template>
              </div>
            </template>
          </PaginationTable>
        </template>
      </FlexColContent>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { getRecordList, deleteCheckRecord } from '@/services/hat/quality-check';
import { createFormModal, createModal } from '@triascloud/x-components';
import PaginationTable from '@/views/digital-site/components/pagination-table';
import FlexColContent from '@/views/digital-site/components/flex-col-content';
import Tip from '@/components/tip';
import moment from 'moment';
import XQrcode from '@/components/common/x-qrcode/index.vue';
import { clipboardWrite } from '@triascloud/utils';

const URL_ENUM = {
  dev: `http://dev.m.${process.env.VUE_APP_HOST}/#/`,
  production: `https://m.${process.env.VUE_APP_HOST}/#/`,
  sit: `https://sit.m.${process.env.VUE_APP_HOST}/#/`,
  uat: `https://uat.m.${process.env.VUE_APP_HOST}/#/`,
};
const hatColors = {
  WHITE: '#ededed',
  RED: '#F60000',
  ORANGE: '#FF8707',
  YELLOW: '#FFBF07',
  BLUE: '#4771FF',
};
const typeEnum = {
  ALL: '全部',
  UNINITIALIZED: '待测试',
  IN_TESTING: '测试中',
  NOT_FILED: '待提交',
  FINISH: '已完成',
};
const testItemEnum = {
  ACCELEROMETER: ['加速计', 'tc-icon-dashboard'],
  BATTERY: ['电池', 'tc-icon-battery'],
  BLOOD_OXY: ['血氧检测', 'tc-icon-bloodOxygen'],
  BLOOD_PRESSURE: ['血压检测', 'tc-icon-bloodPressure'],
  BODY_TEMP: ['体温', 'tc-icon-hat-thermometer'],
  CAP_LINING: ['帽衬', 'tc-icon-hat-liner'],
  COLOR: ['帽壳', 'tc-icon-hat-shell'],
  ELECTROINDUCTION: ['近电检测', 'tc-icon-warn-electricity'],
  GAS: ['有害气体', 'tc-icon-mask'],
  HEART_RATE: ['心率监测', 'tc-icon-heartRate'],
  INFRARED_LAMP: ['红外灯', 'tc-icon-hat-infrared'],
  LED: ['LED照明', 'tc-icon-hat-bulb'],
  LOCATION: ['定位', 'tc-icon-location'],
  NETWORK_SIGNAL: ['联网', 'tc-icon-columnar'],
  PHOTO_BUTTON: ['拍照按钮', 'tc-icon-hat-camera'],
  PHOTO_FILE: ['照片文件', 'tc-icon-picture'],
  SOS: ['SOS警报', 'tc-icon-caution'],
  STORAGE: ['存储卡', 'tc-icon-terabyte'],
  UNCAP: ['脱戴帽', 'tc-icon-unbonnet'],
  VIDEO_BUTTON: ['录像按钮', 'tc-icon-hat-video'],
  VIDEO_FILE: ['录像文件', 'tc-icon-hat-movie'],
};
@Component({
  components: {
    PaginationTable,
    FlexColContent,
  },
})
export default class HatQualityCheck extends Vue {
  typeList = [
    { text: '全部', value: '' },
    { text: '测试中', value: 'IN_TESTING' },
    { text: '待提交', value: 'NOT_FILED' },
    { text: '已完成', value: 'FINISH' },
  ];
  hatColors = hatColors;
  get columns() {
    return [
      {
        align: 'left',
        title: '设备',
        width: 230,
        ellipsis: true,
        scopedSlots: { customRender: 'deviceName' },
      },
      {
        align: 'left',
        title: '产品分类',
        dataIndex: 'type',
        ellipsis: true,
        customRender: text => (text === 'PLUS' ? 'Plus版' : '基础版'),
      },
      {
        align: 'left',
        title: '状态',
        dataIndex: 'status',
        ellipsis: true,
        customRender: text => typeEnum[text],
      },
      {
        align: 'left',
        title: '测试进度',
        ellipsis: true,
        scopedSlots: { customRender: 'progress' },
      },
      {
        align: 'left',
        title: '测试明细',
        ellipsis: true,
        width: 220,
        scopedSlots: { customRender: 'detail' },
      },
      {
        align: 'left',
        title: '测试时长',
        dataIndex: 'durationOfTest',
        ellipsis: true,
        customRender: text => (text ? text.toFixed(2) + '分钟' : ''),
      },
      {
        align: 'left',
        title: '创建人',
        dataIndex: 'createName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '创建时间',
        dataIndex: 'createTime',
        ellipsis: true,
        width: 180,
        customRender: text =>
          text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: '修改人',
        dataIndex: 'updateName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '修改时间',
        dataIndex: 'updateTime',
        ellipsis: true,
        width: 180,
        customRender: text =>
          text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        title: this.$t('common.action.operating'),
        key: 'operation',
        width: 160,
        fixed: 'right',
        ellipsis: true,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  mounted() {}
  searchParams = {
    status: '',
    input: '',
    isAll: true,
  };
  getDeviceList = getRecordList;
  type = 'ALL';
  handleSelect(v) {
    this.searchParams.isAll = v === 'ALL';
    this.$refs.tableRef.search();
  }
  async showFormModel(row, operationType) {
    const qrcodeUrls = {
      add:
        URL_ENUM[process.env.VUE_APP_ENV] +
        'pages/aIot/hat/quality-check/index',
      edit:
        URL_ENUM[process.env.VUE_APP_ENV] +
        `pages/aIot/hat/quality-check/device-edit?id=${row.pkId}&color=${row.color}`,
      detail:
        URL_ENUM[process.env.VUE_APP_ENV] +
        `pages/aIot/hat/quality-check/check?id=${row.pkId}&color=${row.color}&readOnly=true`,
    };
    try {
      await createModal(
        () => (
          <div class={this.$style.qrcodeModal}>
            <XQrcode
              class={this.$style.qrcode}
              src={qrcodeUrls[operationType]}
            />
            <div style="margin: 8px 0;">
              {operationType === 'edit'
                ? '请用手机钉钉或微信扫码编辑或查看详情'
                : '请用手机钉钉或微信扫码登录后创建质检记录'}
            </div>
            <div
              class={operationType === 'add' ? this.$style.hidden : ''}
              style="color: red;"
            >
              注意：已完成提交的记录不支持修改和删除操作！
            </div>
          </div>
        ),
        {
          width: 500,
          maskClosable: false,
          title:
            operationType === 'add'
              ? '操作提示'
              : `${row.deviceName}的质检记录`,
          footer: () => (
            <AButton
              type="primary"
              onClick={() => this.handleCopy(qrcodeUrls[operationType])}
            >
              复制链接
            </AButton>
          ),
        },
      );
    } catch {
      return false;
    }
  }
  async handleCopy(data) {
    await clipboardWrite(data);
    this.$message.success('复制成功！');
  }
  async handleDelete(record) {
    const text = `确定删除${record.deviceName}的检测记录？`;
    const tips = '注意：除设备码以外，本次测试的过程数据均不会保留';
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: '操作确认',
          onOk: async () => {
            await deleteCheckRecord(record.pkId);
            this.$message.success('删除成功!');
            this.$refs.tableRef.getTableList();
          },
        },
      );
    } catch {
      return false;
    }
  }
  getTestProgress(record) {
    let count = 0;
    record.detailResult.forEach(item => {
      if (item.status !== 'UNDETECTED') {
        count += item.count;
      }
    });
    return `${count}/${record.type === 'PLUS' ? 21 : 15}`;
  }
  getTestDetail(resultList) {
    const { fault, normal, other } = this.getTestDetailResult(resultList);
    return `正常-${normal.count}，异常-${fault.count}，其他-${other.count}`;
  }
  getTestDetailResult(resultList) {
    let fault = { count: 0, detailList: [] };
    let normal = { count: 0, detailList: [] };
    let other = { count: 0, detailList: [] };
    resultList.forEach(item => {
      if (item.status === 'FAULT') {
        fault = item;
      } else if (item.status === 'NORMAL') {
        normal = item;
      } else if (item.status === 'OTHER') {
        other = item;
      }
    });
    return {
      fault,
      normal,
      other,
    };
  }
  async showTestDetailModal(row) {
    const { fault, normal, other } = this.getTestDetailResult(row.detailResult);
    try {
      const modal = await createModal(
        () => (
          <div class={this.$style.testDetailModal}>
            <div class={this.$style.typeTitle}>正常</div>
            <div class={this.$style.iconList}>
              {normal.detailList.map(item => (
                <div class={this.$style.iconWrap}>
                  <XIcon
                    type={testItemEnum[item.type][1]}
                    class={this.$style.icon}
                  />
                  <div class={this.$style.name}>
                    {testItemEnum[item.type][0]}
                  </div>
                </div>
              ))}
            </div>
            <div class={this.$style.typeTitle}>异常</div>
            <div class={this.$style.iconList}>
              {fault.detailList.map(item => (
                <div class={this.$style.iconWrap}>
                  <XIcon
                    type={testItemEnum[item.type][1]}
                    class={this.$style.icon}
                  />
                  <div class={this.$style.name}>
                    {testItemEnum[item.type][0]}
                  </div>
                </div>
              ))}
            </div>
            <div class={this.$style.typeTitle}>其他</div>
            <div class={this.$style.iconList}>
              {other.detailList.map(item => (
                <div class={this.$style.iconWrap}>
                  <XIcon
                    type={testItemEnum[item.type][1]}
                    class={this.$style.icon}
                  />
                  <div class={this.$style.name}>
                    {testItemEnum[item.type][0]}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ),
        {
          width: 500,
          maskClosable: false,
          title: `${row.deviceName}测试结果`,
          footer: () => (
            <div>
              <AButton onClick={() => (modal.visible = false)}>关闭</AButton>
              <AButton
                type="primary"
                onClick={() => this.showFormModel(row, 'detail')}
              >
                详情
              </AButton>
            </div>
          ),
        },
      );
    } catch {
      return false;
    }
  }
}
</script>
<style lang="less" module>
.qualityCheck {
  height: 100%;
  padding: 20px;
  .tableWrap {
    height: 100%;
    .operation {
      display: flex;
      justify-content: space-between;
      margin: 0 0 12px;
      .searchBox {
        .searchInput {
          margin-right: 10px;
          width: 200px;
        }
        .searchSelect {
          margin-right: 10px;
          width: 180px;
        }
      }
    }
    .buttonGroups {
      .button {
        cursor: pointer;
        color: var(--primary);
        margin-right: 20px;
      }
      .button:last-of-type {
        margin-right: 0;
      }
    }
    .deviceCell {
      display: flex;
      align-items: center;

      span:last-of-type {
        width: 32px;
        height: 16px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
      }
      .plusTxt {
        font-size: 20px;
        font-weight: bolder;
        transform: scale(0.5);
        margin: 0 !important;
        color: #ffffff;
      }
    }
    .testDetail {
      cursor: pointer;
      color: var(--primary);
    }
  }
}
.qrcodeModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .qrcode {
    height: 200px;
    width: 200px;
  }
  .hidden {
    display: none;
  }
}
.testDetailModal {
  .typeTitle {
    font-size: 16px;
  }
  .iconList {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    margin: 10px 0 20px;
    .iconWrap {
      width: 50px;
      text-align: center;
      .icon {
        font-size: 24px;
      }
      .name {
        font-size: 12px;
      }
    }
  }
}
</style>
