<template>
  <div :class="$style.wrap">
    <field-config-config-desc :desc="$t('controls.desc.input')" />
    <field-config-title v-bind="fieldProps" />
    <field-config-required v-bind="fieldProps" />
    <parameter-object v-if="!isRes" v-bind="fieldProps" isInOrOut="in" />
    <parameter-object v-if="isRes" v-bind="fieldProps" isInOrOut="out" />
    <a-form-model-item
      v-if="!isRes"
      prop="widget.defaultValue.custom"
      label="默认值"
    >
      <a-radio-group
        v-if="isReq"
        :value="field.widget.defaultType"
        @change="onTypeChange"
      >
        <a-radio :value="TextDefault.MANUAL"> 手动输入 </a-radio>
        <a-radio :value="TextDefault.RULE"> 规则生成 </a-radio>
        <a-radio :value="TextDefault.VARIABLE"> 指定变量 </a-radio>
      </a-radio-group>
      <a-input
        v-if="field.widget.defaultType === TextDefault.MANUAL"
        :value="field.widget.defaultValue.custom"
        :class="$style.item"
        placeholder="请输入默认值"
        @change="v => handleInput('widget.defaultValue.custom', v.target.value)"
      />
      <a-input-group
        compact
        v-if="field.widget.defaultType === TextDefault.RULE"
      >
        <a-select
          style="width: 35%"
          :value="field.widget.ruleType"
          placeholder="请选择"
          @change="
            v => {
              handleInput('widget.ruleType', v);
              if (v === 'uuid') {
                handleInput('widget.ruleLength', '32');
              } else {
                handleInput('widget.ruleLength', '');
              }
            }
          "
        >
          <a-select-option :value="RuleType.INT"> 整数 </a-select-option>
          <a-select-option :value="RuleType.FLOAT"> 浮点 </a-select-option>
          <a-select-option :value="RuleType.UUID"> UUID(V4) </a-select-option>
        </a-select>
        <a-input
          style="width: 65%"
          :value="field.widget.ruleLength"
          placeholder="长度"
          type="number"
          :max="100"
          :min="1"
          :disabled="field.widget.ruleType === RuleType.UUID"
          @change="v => handleInput('widget.ruleLength', v.target.value)"
        >
          <span slot="addonAfter">位</span>
        </a-input>
      </a-input-group>
      <a-select
        v-if="field.widget.defaultType === TextDefault.VARIABLE"
        :value="field.widget.variableId"
        placeholder="请选择"
        @change="handleVarChange"
      >
        <a-select-option
          v-for="item of variables"
          :key="item.apiVariableId"
          :value="item.apiVariableId"
        >
          {{ item.remarkName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      prop="widget.maxLength"
      :label="$t('controls.input.maxLength')"
    >
      <section :class="this.$style.section">
        <a-input
          :value="field.widget.maxLength"
          type="number"
          :max="10240"
          :min="1"
          :class="$style.item"
          @change="v => handleInput('widget.maxLength', +v)"
        >
          <span slot="addonAfter">{{ $t('connector.dataType.byte') }}</span>
        </a-input>
        <!-- <span :class="[this.$style.ml10, this.$style.label2]">
          {{ $t('connector.dataType.byte') }}
        </span> -->
      </section>
    </a-form-model-item>
    <field-config-description v-bind="fieldProps" />
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import FieldConfigTitle from '../components/field-config/title.vue';
import FieldConfigRequired from '../components/field-config/required.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';
import ParameterObject from '../components/field-config/parameter-object.vue';
import { ParamType, TextDefault, RuleType } from '../enum';
import { templateDesignModule } from '@/enum/store';
import { getVariables } from '@/services/iot-platform/variable.js';

@Component({
  components: {
    FieldConfigTitle,
    FieldConfigRequired,
    FieldConfigDescription,
    FieldConfigConfigDesc,
    ParameterObject,
  },
})
export default class InputConfig extends Vue {
  @Prop() field;
  @Prop() updateField;
  @templateDesignModule.Getter paramType;
  @templateDesignModule.State platformId;

  TextDefault = TextDefault;
  RuleType = RuleType;

  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
    };
  }
  /** @name 出参 */
  get isRes() {
    return this.paramType === ParamType.RES_PARAM;
  }
  /** @name 入参 */
  get isReq() {
    return this.paramType === ParamType.REQ_PARAM;
  }
  created() {
    this.getList();
  }
  variables = [];
  async getList() {
    const params = {
      current: 1,
      size: 1000,
      platformId: this.platformId,
    };
    const { records } = await getVariables(params);
    this.variables = records;
  }
  handleInput(key, value) {
    this.updateField({ key: key, value: value });
  }
  handleVarChange(v) {
    this.handleInput('widget.variableId', v);
    const item = this.variables.find(item => item.apiVariableId === v);
    this.handleInput('widget.defaultValue.custom', `{{${item.identifier}}}`);
  }
  onTypeChange(e) {
    this.handleInput('widget.ruleLength', '');
    this.handleInput('widget.defaultValue.custom', '');
    this.handleInput('widget.ruleType', undefined);
    this.handleInput('widget.variableId', undefined);
    this.handleInput('widget.defaultType', e.target.value);
  }
}
</script>

<style lang="less" module>
.wrap {
  :global(.ant-radio-wrapper span.ant-radio + *) {
    padding-right: 3px;
    padding-left: 3px;
  }
}

.ml10 {
  margin-left: 10px;
}
.section {
  display: flex;
  width: 100%;
  .item {
    flex: 1;
  }
  .label2 {
    width: 32px;
  }
  .label1 {
    width: 15px;
  }
}
</style>
