// @ts-check
import DesignComponent from './design.vue';
import ConfigComponent from './config.vue';
import CurrentDataSet from './currentDataSet.vue';

import { BaseControl } from '../BaseControl';
/** @name 当前数据组 */
class CurrentDataSetControl extends BaseControl {
  /** @readonly */
  type = 'currentDataSet';

  label = 'controls.currentDataSet.label';

  icon = 'tc-icon-data';

  actionOpen = true;

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    defaultValue: {
      type: 'custom',
      custom: '',
    },
    datatype: '',
    identifier: 'datatype',
    // hideTitle: true,
  });

  DesignComponent = DesignComponent;
  FormComponent = CurrentDataSet;
  ConfigComponent = ConfigComponent;
}

export default new CurrentDataSetControl();
