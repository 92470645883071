import { ensureArray } from '@triascloud/utils';
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { generateDataTitle } from '../controls';
import { FormatContextSymbol } from '../controls/symbol';
import { DataFormContext } from './context';

@Component()
export default class DataFormTitleWatcher extends Vue {
  /** @type { string } */
  @Prop() dataTitle;

  /** @type { import('../controls').Field } */
  @Prop() defaultField;

  /** @name 当标题计算为空时的占位信息 */
  @Prop() placeholder;

  /** @type { import('./data-form-model-context').default } */
  @Inject(DataFormContext.Model) model;

  title = '';

  created() {
    this.addTitleWatcher();
  }

  get computedDataTitle() {
    return (
      this.dataTitle ||
      (this.defaultField && `##${this.defaultField.pkId}$$`) ||
      ''
    );
  }

  /** @name 数据标题依赖的字段列表 */
  get relationFieldIds() {
    return ensureArray(this.computedDataTitle.match(/##(.*?)\$\$/g)).map(
      field => /##(.*?)\$\$/.exec(field)[1],
    );
  }

  /** @name 添加标题变化监视器 */
  addTitleWatcher() {
    this.updateTitle();
    this.model.watch(this.relationFieldIds, this.updateTitle);
  }

  @Watch('model.path')
  /** @name 更新标题 */
  async updateTitle() {
    this.title = await generateDataTitle(
      this.computedDataTitle,
      this.model.getField,
      this.model.getValue,
      this.formatContext,
    );
    if (!this.title && this.placeholder) {
      this.title = this.placeholder;
    }
    this.$emit('change', this.title);
  }

  /** @type { import('@/form/controls').FormatContext */
  @Inject(FormatContextSymbol)
  formatContext;

  render() {
    return this.$scopedSlots.default
      ? this.$scopedSlots.default(this.title)
      : null;
  }
}
