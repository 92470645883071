export const IconLibraryTypeEnum = {
  /** @name 官方图标 */
  OFFICIAL: 'OFFICIAL',
  /** @name 单色图标 */
  MONOCHROMATIC: 'MONOCHROMATIC',
  /** @name 多色图标 */
  MULTICOLOR: 'MULTICOLOR',
};

export const DefaultIconTypeEnum = {
  /** @name 应用管理 */
  APPLICATION: 'APPLICATION',
  /** @name 表单分组 */
  GROUP: 'GROUP',
  /** @name 表单 */
  FORM: 'FORM',
  /** @name 知识库 */
  KNOWLEDGE: 'KNOWLEDGE',
  /** @name 默认分组图标 */
  APPLICATION_DEFAULT_GROUP: 'APPLICATION_DEFAULT_GROUP',
};
