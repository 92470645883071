<template>
  <div>
    <div :class="$style.header">
      <a-input-search
        placeholder=""
        v-model="searchWord"
        style="width: 200px;margin-right: 10px;"
        @search="handleSearch"
      />
      <!-- <a-select
        placeholder="请选择"
        @change="handleSearch"
        style="width: 200px;margin-right: 10px;"
        v-model="type"
      >
        <a-select-option
          v-for="(item, idx) in statusTypes"
          :key="idx"
          :value="item.value"
        >
          {{ item.text }}
        </a-select-option>
      </a-select> -->
      <a-button type="primary" @click="modify" :class="$style.actionBtn">{{
        $t('market.template.batchEdit')
      }}</a-button>
    </div>
    <x-data-table
      ref="table"
      base-path="/template-manager/template/applicationTemplateList"
      :columns="columns"
      :service="service"
      row-key="id"
      :class="$style.table"
      :selected-row-keys.sync="selectedRowKeys"
    >
      <div slot="headerLeft"></div>
      <template v-slot:footer />
    </x-data-table>
    <!-- <a-table
      :data-source="list"
      :columns="columns"
      :class="$style.table"
      :pagination="false"
      :selected-row-keys.sync="selectedRowKeys"
      :scroll="{ y: 600 }"
    >


</a-table> -->
  </div>
</template>
<script>
import { globalVueI18n } from '@triascloud/i18n';
import { getTemplateList } from '@/services/template-manager/template';
import { createDrawer } from '@triascloud/x-components';
import { Component, Vue, Prop } from 'vue-property-decorator';
import InstallSettingModal from './install-setting-modal.vue';
import { TemplateState, TemplateType } from '@/enum/template';
import { DataSourceService } from '@triascloud/services';

function createInstallManageModal(props) {
  return createDrawer(h => h(InstallManageModal, { props }), {
    title: globalVueI18n.translate('market.template.virtualManagement'),
    width: 900,
  });
}
@Component({})
export default class InstallManageModal extends Vue {
  @Prop({ type: String }) classifyId;

  static createModal = createInstallManageModal;
  list = [];
  loading = true;
  selectedRowKeys = [];
  created() {
    // await this.getList();
    this.loading = false;
  }

  get service() {
    const service = new DataSourceService(
      '/template-manager/template/applicationTemplateList',
    );
    service.getList = () =>
      getTemplateList({
        classifyId: this.classifyId,
        keyword: this.searchWord || null,
      }).then(res => ({ data: res }));
    return service;
  }
  type = '';
  searchWord = '';

  async modify() {
    if (this.selectedRowKeys.length <= 0) {
      this.$message.warning(this.$t('market.template.selectedTip'));
      return;
    }
    await InstallSettingModal.create({
      total: this.selectedRowKeys.length,
      id: this.selectedRowKeys,
      templateType: TemplateType.Application,
    });
    this.$message.success(this.$t('common.tips.operationSuccess'));
    this.$refs.table.loadData();
  }

  handleSearch() {
    this.$refs.table.loadData();
  }

  statusTypes = [
    {
      text: this.$t('common.action.all'),
      value: '',
    },

    {
      text: this.$t('solution.addedTo'),
      value: '1',
    },
    {
      text: this.$t('template.review.state.offShelf'),
      value: '2',
    },
  ];

  get columns() {
    return [
      {
        title: () => this.$t('market.statistics.templateName'),
        dataIndex: 'name',
        width: 300,
        ellipsis: true,
      },
      {
        title: () => this.$t('help.status'),
        key: 'state',
        dataIndex: 'state',
        filter: false,
        sorter: false,
        customRender: state => (
          <span
            class={[
              {
                [this.$style['card__status--open']]:
                  state === TemplateState.Open,
              },
              {
                [this.$style['card__status--init']]:
                  state === TemplateState.Init,
              },
            ]}
          >
            {state === TemplateState.Open
              ? this.$t('template.review.state.open')
              : state === TemplateState.Close
              ? this.$t('template.review.state.offShelf')
              : state === TemplateState.Init
              ? this.$t('template.review.state.notListed')
              : '-'}
          </span>
        ),
      },
      {
        title: () => this.$t('market.template.Installs'),
        dataIndex: 'installCount',
        key: 'installCount',
      },
      {
        title: () => this.$t('market.template.virtualInstalls'),
        dataIndex: 'virtualInstallCount',
        key: 'virtualInstallCount',
      },
    ];
  }
}
</script>
<style lang="less" module>
.table {
  margin-top: 20px;
  height: 830px;
}
.header {
  display: flex;
  justify-content: space-between;
}

.card__status--open {
  color: var(--success);
}
.card__status--init {
  color: #18c4ec;
}
</style>
