<template>
  <div :class="$style.recyclingWrapper">
    <div :class="$style.actionTab">
      <a-input
        :class="[$style.input, focus ? $style.focus : '']"
        v-model="keyword"
        @focus="focus = true"
        @blur="focus = false"
        @pressEnter="refresh"
      >
        <x-icon type="search" slot="prefix" />
      </a-input>
      <div :class="$style.timeFilter">
        <span>{{ $t('dictionary.shareTime') }}：</span>
        <a-range-picker
          v-model="dateRange"
          @change="handleRangePickerChange"
          format="yyyy-MM-DD"
          :separator="$t('common.label.to')"
          style="width: 260px"
        />
      </div>
      <div :class="$style.total">
        <span>{{ $t('dictionary.total') }}：</span>
        <span :class="$style.nums">{{ total }}</span>
        <span>{{ $t('gallery.label.Apicture') }}</span>
      </div>
    </div>
    <x-scroll-view
      :class="$style.imgList"
      :disabled="!hasMore"
      ref="imgList"
      @scrolltolower="handleLoadMore"
    >
      <template v-if="imgList.length > 0">
        <image-box
          :class="$style.imageBox"
          v-for="item in imgList"
          :key="item.pkId"
          :image-data="item"
          is-deleted
          @refresh="getData(true)"
        />
      </template>
      <empty-content v-else type="list" />
    </x-scroll-view>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createDrawer } from '@triascloud/x-components';
import ImageBox from './ImageBox';
import { getRecycleList } from '@/services/resource/cloud-img-group';
import EmptyContent from '@/components/empty-content/empty-content.vue';

@Component({
  components: {
    ImageBox,
    EmptyContent,
  },
})
export default class RecyclingDrawer extends Vue {
  @Prop({ tpye: String, default: 'All' }) imageType;

  static createdDrawer = createRecyclingDrawer;

  keyword = '';
  focus = false;
  dateRange = [];
  startTimestamp = '';
  endTimestamp = '';
  imgList = [];
  loading = false;
  scrollLoading = false;
  current = 1;
  hasMore = true;
  total = 0; // 图片总数

  handleLoadMore() {
    this.getData(false);
  }

  /**
   * 处理日期范围数据变动
   * @param value
   */
  handleRangePickerChange(value) {
    this.startTimestamp = value[0] ? value[0].valueOf() : '';
    this.endTimestamp = value[1] ? value[1].valueOf() : '';
    this.refresh();
  }

  /**
   * 加载数据
   * @param refresh 是否来自刷新
   * @returns {Promise<void>}
   */
  async getData(refresh = false) {
    if (this.scrollLoading) return;
    this.scrollLoading = true;
    if (refresh) {
      this.loading = true;
      this.current = 1;
    }
    let data = {
      size: 25,
      keyword: this.keyword,
      skip: 25 * (this.current - 1),
      filterGroup: {
        logic: 'AND',
        group:
          this.startTimestamp && this.endTimestamp
            ? [
                {
                  compare: 'GE',
                  field: 'createDate',
                  value: this.startTimestamp,
                },
                {
                  compare: 'LE',
                  field: 'createDate',
                  value: this.endTimestamp,
                },
              ]
            : [],
      },
      sorters: [
        {
          field: 'deleteDate',
          order: 'DESC',
        },
      ],
    };

    data.filterGroup.group.push(
      ...[
        {
          compare: 'EQ',
          field: 'deleted',
          value: 1,
        },
        {
          compare: 'EQ',
          field: 'checkStatus',
          value: 'PASSED',
        },
      ],
    );
    switch (this.imageType) {
      case 'All':
        break;
      case 'Elite':
        data.filterGroup.group.push({
          compare: 'EQ',
          field: 'elite',
          value: 1,
        });
        break;
      default:
        data.filterGroup.group.push({
          compare: 'EQ',
          field: 'idxImgGroup',
          value: this.imageType,
        });
        break;
    }
    const res = await getRecycleList(data);
    this.imgList = refresh ? res.data : [...this.imgList, ...res.data];
    this.total = res.total;
    this.scrollLoading = false;
    this.loading = false;
    if (res.data.length) {
      this.current += 1;
    } else {
      this.hasMore = false;
    }
  }

  refresh() {
    this.current = 1;
    this.hasMore = true;
    this.getData(true);
  }
}

function createRecyclingDrawer(imageType = 'All') {
  return createDrawer(
    render =>
      render(RecyclingDrawer, {
        props: {
          imageType,
        },
      }),
    {
      width: 900,
      title: '回收站',
    },
  );
}
</script>

<style lang="less" module>
.recyclingWrapper {
  .actionTab {
    display: flex;
    align-items: center;
    padding: 0 15px 20px;

    .input {
      width: 110px;
      transition: all 0.5s linear;
      &.focus {
        width: 200px;
      }
    }

    .timeFilter {
      padding: 0 20px;
    }

    .total {
      .nums {
        color: var(--primary);
        padding-right: 0.5em;
      }
    }
  }

  .imgList {
    overflow-y: auto;
    height: calc(100vh - 160px);

    .imageBox {
      float: left;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
    }
  }
}
</style>
