// @ts-check
import { BaseControl } from '../BaseControl';
// @ts-ignore
import DesignComponent from './design.vue';
// @ts-ignore
import ConfigComponent from './config.vue';

/** @name 结构体 */
class StructControl extends BaseControl {
  /** @readonly */
  type = 'struct';

  label = 'controls.struct.label';

  icon = 'tc-icon-batch';

  actionOpen = true;

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    defaultValue: {
      type: 'custom',
      // 默认值
      custom: '',
    },
    fields: [],
    identifier: 'STRUCT',
    inParams: ['DEVELOPMENT'],
    outParams: [],
    subName: 'name',
    subIdentifier: 'identifier',
    subGroup: 'specs',
  });

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;
}

export default new StructControl();
