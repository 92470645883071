<template>
  <div :class="$style.tabConfig">
    <field-config-config-desc :desc="$t('controls.desc.struct')" />
    <field-config-title v-bind="fieldProps" />
    <parameter-object v-if="!isRes" v-bind="fieldProps" isInOrOut="in" />
    <parameter-object v-if="isRes" v-bind="fieldProps" isInOrOut="out" />
    <a-form-model-item label="子参数名称_标识符">
      <x-input
        :value="field.widget.subName"
        @input="
          updateField({
            key: `widget.subName`,
            value: $event,
          })
        "
      />
    </a-form-model-item>
    <a-form-model-item label="子参数标识_数据标识符">
      <x-input
        :value="field.widget.subIdentifier"
        @input="
          updateField({
            key: `widget.subIdentifier`,
            value: $event,
          })
        "
      />
    </a-form-model-item>
    <a-form-model-item label="子参数数据组_数据标识符">
      <x-input
        :value="field.widget.subGroup"
        @input="
          updateField({
            key: `widget.subGroup`,
            value: $event,
          })
        "
      />
    </a-form-model-item>
    <field-config-description v-bind="fieldProps" />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import FieldConfigTitle from '../components/field-config/title.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';
import ParameterObject from '../components/field-config/parameter-object.vue';
import { templateDesignModule } from '@/enum/store';
import { ParamType } from '../index';

@Component({
  components: {
    Draggable,
    FieldConfigConfigDesc,
    FieldConfigTitle,
    FieldConfigDescription,
    ParameterObject,
  },
})
export default class StructConfig extends Vue {
  @Prop() field;
  @Prop() updateField;
  @templateDesignModule.Getter paramType;

  /** @name 出参 */
  get isRes() {
    return this.paramType === ParamType.RES_PARAM;
  }

  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
    };
  }
}
</script>
<style lang="less" module>
li.flexItem {
  margin-bottom: 8px;
  .label {
    flex: 1;
    :global {
      .x-input + .x-input {
        margin-top: 4px;
      }
    }
  }

  .labelAction {
    margin-left: 10px;
  }
}
li.flexItem,
.flexItem :global(.ant-form-item-children) {
  display: flex;
  align-items: center;
  > :global(.ant-input),
  > :global(.ant-select),
  > :global(.x-tree-select) {
    flex: 1;
    margin-right: 4px;
  }
  > :global(.anticon) {
    font-size: 16px;
    width: 24px;
    text-align: center;
  }
}
.dragHandler {
  cursor: grab;
  font-size: 16px;
  width: 24px;
}
.delete {
  font-size: 16px;
  width: 24px;
}
.delete:hover {
  color: var(--danger);
}
.tab-style {
  height: 60px;
  border-radius: 4px;
  border: 1px solid var(--border);
  padding: 0 10px;
  display: flex;
  align-items: center;
  :global {
    .x-tab {
      width: 100%;
      margin: 0;
    }

    .x-tab::after {
      border: none;
    }

    .ant-tabs-nav-scroll {
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }

    .x-tab .ant-tabs-bar.ant-tabs-top-bar {
      margin: 0;
      padding: 0;
    }
  }
}
.descriptionEditor:global(.x-editor) :global {
  padding: 0;
  .ql-editor {
    min-height: 150px;
  }
  .x-editor .ql-snow.ql-toolbar .ql-picker-options,
  .x-editor-toolbar.ql-snow.ql-toolbar .ql-picker-options {
    max-height: 150px;
  }
  .ql-snow.ql-toolbar {
    padding: 4px 0;
  }
}
</style>
