var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style.solutionBg,
    _vm.type === 'customer' ? _vm.$style.customerBg : '',
    _vm.type === 'architecture' ? _vm.$style.architecture : '',
    'solutionBg' ]},[_c('div',{class:[_vm.$style.solutionItem]},[(_vm.status)?_c('a-button',{class:[_vm.$style.delIconBox, 'delIconBox'],attrs:{"type":"link"},on:{"click":_vm.deleteBgItem}},[_c('x-icon',{class:_vm.$style.delIcon,attrs:{"type":"tc-icon-delete"}})],1):_vm._e(),_c('a-form-model-item',{ref:"imageForm",attrs:{"prop":(_vm.formItemProp + ".image"),"rules":[
        {
          message: _vm.$t('solution.selectPicture'),
          required: true,
        } ]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.image),expression:"value.image"}],class:_vm.$style.solutionInput,domProps:{"value":(_vm.value.image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "image", $event.target.value)}}}),_c('x-upload-button',{attrs:{"maxFileSize":5},on:{"success":function (res) {
            _vm.$refs.imageForm.FormContext.clearValidate(
              (_vm.formItemProp + ".image")
            );
            _vm.$emit('input', Object.assign({}, _vm.value, {image: res.ossData.ossPath}));
          }}},[_c('div',{class:[
            _vm.$style.solutionBgDetail,
            _vm.type === 'customer' || _vm.type === 'advantage'
              ? _vm.$style.customerDetail
              : '',
            _vm.type === 'architecture' ? _vm.$style.upload : '',
            'solutionBgDetail' ]},[(!_vm.value.image)?_c('div',{class:_vm.$style.addIcon},[_c('x-icon',{attrs:{"type":"tc-icon-image-download"}}),_vm._v(" "+_vm._s(_vm.$t('solution.addPictures'))+" ")],1):_c('x-oss-image',{class:_vm.$style.image__img,attrs:{"oss-path":_vm.value.image}}),(_vm.value.image && !_vm.status)?_c('div',{class:_vm.$style.changePic},[_c('div',{class:_vm.$style.addIcon},[_c('x-icon',{attrs:{"type":"tc-icon-image-download"}}),_vm._v(" "+_vm._s(_vm.$t('solution.addPictures'))+" ")],1)]):_vm._e()],1)])],1),(_vm.type !== 'architecture')?_c('a-form-model-item',{attrs:{"prop":(_vm.formItemProp + ".title"),"rules":[
        {
          trigger: 'blur',
          message: _vm.$t('solution.tip1'),
          required: true,
          whitespace: true,
        },
        {
          min: 2,
          max: 30,
          message: _vm.$t('solution.tip2'),
          trigger: 'blur',
        } ]}},[_c('a-input',{class:[
          _vm.$style.solutionBgDetailTlt,
          _vm.type === 'customer' ? _vm.$style.customerDetailTlt : '' ],attrs:{"value":_vm.value.title,"maxLength":30},on:{"input":function (res) { return _vm.changeData(res.target.value); }}})],1):_vm._e(),(_vm.type !== 'architecture')?_c('a-form-model-item',{attrs:{"prop":(_vm.formItemProp + ".description"),"rules":[
        {
          trigger: 'blur',
          message: _vm.$t('record.updateProfile'),
          required: true,
          whitespace: true,
        },
        _vm.type === 'customer'
          ? {
              min: 1,
              max: 500,
              message: _vm.$t('solution.tip2'),
              trigger: 'blur',
            }
          : {
              min: 1,
              max: 200,
              message: _vm.$t('solution.tip2'),
              trigger: 'blur',
            } ]}},[_c('a-textarea',{class:_vm.$style.solutionDesc,attrs:{"value":_vm.value.description,"maxLength":_vm.type === 'customer' ? 500 : 200},on:{"input":function (res) { return _vm.$emit('input', Object.assign({}, this$1.value, {description: res.target.value})); }}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }