<template>
  <div :class="$style.deviceM">
    <a-table
      :columns="columns"
      :data-source="list"
      :pagination="pagination"
      :row-key="record => record.id"
      :loading="tableLoading"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      @change="handlePagination"
    >
      <template slot="deviceCode" slot-scope="text, record">
        <div :class="$style.tagColumn">
          <div>
            <p>{{ text }}</p>
            <p>({{ record.deviceId }})</p>
          </div>
          <div>
            <a-icon
              type="copy"
              :class="$style.copyBtn"
              @click="copyCode(record)"
            />
          </div>
        </div>
      </template>
      <template slot="deviceKey" slot-scope="text, record">
        <div :class="$style.tagColumn">
          <div>
            <p>{{ text }}</p>
            <p>({{ record.randomKey || '-' }})</p>
          </div>
        </div>
      </template>
      <template slot="deviceStatus" slot-scope="text, record">
        <div style="display: flex;flex-direction: column;line-height: 35px">
          <span>
            {{ deivceStatusEnum[text] }}
            <x-icon
              :class="$style.iconQrcode"
              @click.stop="handleQrcode(record)"
              type="tc-icon-qrcode"
            />
          </span>
          <div
            v-if="record.idxBindTenantId"
            :class="$style.statusName"
            :title="record.idxBindTenantId"
          >
            {{ record.idxBindTenantId }}
          </div>
        </div>
      </template>
      <template slot="operate" slot-scope="record">
        <span :class="$style.buttonGroups">
          <a :class="[$style.button]" @click.stop="handleUpdate(record)"
            >编辑</a
          >
          <a :class="[$style.button]" @click.stop="handleProview(record)"
            >预览</a
          >
          <a :class="[$style.button]" @click.stop="handleFn(record)">{{
            fnTxt(record)
          }}</a>
          <a
            :class="[$style.button, $style.red]"
            @click.stop="handleDelete(record)"
            >删除</a
          >
        </span>
      </template>
    </a-table>
    <a-button
      v-if="list.length > 0 && this.selectedRows.length > 0"
      :class="$style.btn"
      type="primary"
      @click="handleAllocation({}, '2')"
    >
      分配
    </a-button>
  </div>
</template>
<script>
import { Component, Vue, InjectReactive, Prop } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import { clipboardWrite } from '@triascloud/utils';
import dayjs from 'moment';
import { message } from 'ant-design-vue';
import {
  Emitter,
  EventType,
} from '@/views/connector/platform-manage/develop/utils';
import DeviceForm from './device-form';
import SecondForm from './device-form/second-form.vue';
import { createFormModal, createModal } from '@triascloud/x-components';
import ProductSetting from './product-setting.vue';
import Introduce from './introduce/index.vue';
import Panel from './panel/index.vue';
import {
  add,
  getList,
  updateDevice,
  getDevice,
  deleteDevice,
  allocation,
  unbind,
  importDevice,
} from '@/services/iot-platform/platformDevice';
import Allocation from './allocation.vue';
import DeleteDevice from './delete-device.vue';
import { addOrEditDevicePanel } from '@/services/iot-platform/deviceManage';
import ShowQrcode from '@/views/connector/platform-manage/develop/device-manage/show-qrcode.vue';

@Component({
  components: { LayoutContent, DeviceForm, ShowQrcode },
})
export default class ServiceHardware extends Vue {
  @InjectReactive() id;
  @Prop({ type: Array, default: () => [] }) products;
  mounted() {
    Emitter.$on(EventType.DEVICE_MANAGE_SEARCH, this.handleSearch);
    Emitter.$on(EventType.DEVICE_MANAGE_CHNAGE, this.handleSelect);
    Emitter.$on(EventType.DEVICE_STATUS_CHNAGE, this.handleStatus);
    Emitter.$on(EventType.DEVICE_MANAGE_SETTING, this.handleSetting);
    Emitter.$on(EventType.DEVICE_MANAGE_ADD, this.handleAdd);
    this.getList();
  }

  beforeDestroy() {
    Emitter.$off(EventType.DEVICE_MANAGE_SEARCH, this.handleSearch);
    Emitter.$off(EventType.DEVICE_MANAGE_CHNAGE, this.handleSelect);
    Emitter.$off(EventType.DEVICE_STATUS_CHNAGE, this.handleStatus);
    Emitter.$off(EventType.DEVICE_MANAGE_SETTING, this.handleSetting);
    Emitter.$off(EventType.DEVICE_MANAGE_ADD, this.handleAdd);
  }

  keyword = '';
  async handleSearch(val) {
    this.keyword = val;
    this.pagination.current = 1;
    await this.getList();
  }

  async handleUpdate(val) {
    try {
      let detail = await getDevice(val.pkId);
      detail.deviceStatus = val.deviceStatus;
      const result = await createFormModal(
        () => <SecondForm detail={detail} productId={val.idxProductId} />,
        {
          width: '450px',
          title: '编辑设备',
        },
      );
      const updateParams = {
        deviceId: result.deviceID,
        deviceType: result.deviceType,
        expireTime: result.expireTime
          ? dayjs(result.expireTime)
              .endOf('date')
              .format('x')
          : '',
        idxDeviceCode: result.gateway,
        pkId: val.pkId,
        rawKey: result.deviceKey,
        remark: result.remark,
      };
      if (result.image && result.image.length > 0) {
        updateParams['headFigure'] = result.image[0].ossPath;
      }
      const res = await updateDevice(updateParams);
      if (res) {
        this.$message.success('更新成功！');
        this.getList();
      } else {
        this.$message.error('更新失败！');
      }
    } catch (error) {
      if (error.message !== 'user cancel') this.$message.error(error.message);
    }
  }

  handleProview(record) {
    if (record.confDone) {
      this.panelModal(record.idxProductId, record);
    } else {
      const str = `所属产品${record.productName}还未配置设备面板，请先配置`;
      this.$message.warning(str);
    }
  }

  async handleSetting() {
    const form = await createFormModal(
      () => <ProductSetting platformId={this.id} />,
      {
        title: '产品设置',
        width: 450,
        okText: '去配置',
      },
    );
    if (form.content === 'introduce') {
      // 产品介绍
      this.introduceModal(form.production);
    } else {
      // 设备面板
      await this.panelModal(form.production);
    }
  }

  fnTxt(item) {
    if (item.deviceStatus === 'NOT_ALLOCATED') {
      return '分配';
    } else {
      return '解绑';
    }
  }

  selectedRowKeys = [];
  selectedRows = [];

  onSelectChange(selectedRowKeys, selectedRows) {
    this.selectedRowKeys = selectedRowKeys;
    this.selectedRows = selectedRows;
  }

  handleFn(item) {
    if (item.deviceStatus === 'NOT_ALLOCATED') {
      this.handleAllocation(item, '1');
    } else {
      this.handleUnbind(item);
    }
  }

  handleQrcode(row) {
    const deviceItem = {
      idxProductId: row.idxProductId,
      deviceCode: row.deviceCode,
    };
    createModal(() => <ShowQrcode deviceItem={deviceItem} />, {
      width: 300,
      title: '二维码',
    });
  }

  async handleAllocation(val, flag) {
    const arr = [];
    if (flag === '2') {
      if (this.selectedRows.length > 0) {
        this.selectedRows.forEach(v => {
          if (v.deviceStatus === 'NOT_ALLOCATED') {
            arr.push(v.pkId);
          }
        });
        if (arr.length === 0) {
          this.$message.warning('请至少选择一条待分配设备');
          return;
        }
      }
    }
    try {
      const data = await createFormModal(
        () => <Allocation detail={val} deviceSelect={this.selectedRows} />,
        {
          title: '分配设备',
          width: 450,
        },
      );
      const params = {
        allocationType: data.allocationType,
        randomKey: data.randomKey,
        verifyType: data.verifyType,
        expireTime: data.expireTime
          ? dayjs(data.expireTime)
              .endOf('date')
              .format('x')
          : '',
        idxTenantTcid: data.idxTenantTcid,
      };
      if (flag === '1') {
        params['pkIds'] = [val.pkId];
        params['isBatch'] = false;
      } else {
        params['pkIds'] = arr;
        params['isBatch'] = true;
      }
      const res = await allocation(params);
      if (res) {
        this.$message.success('分配成功！');
        await this.getList();
      } else {
        this.$message.error('分配失败！');
      }
      if (flag === '2') {
        this.selectedRows = [];
        this.selectedRowKeys = [];
      }
    } catch {
      this.selectedRows = [];
      this.selectedRowKeys = [];
      return false;
    }
  }
  handleUnbind(result) {
    createFormModal(
      () => (
        <div>
          <p class={this.$style.UnbindMain}>
            确认解绑此设备？解绑前请和绑定企业确认！
          </p>
          <p class={this.$style.UnbindDesc}>
            说明：解绑后将变为“待分配”状态，重新分配设备后方可供企业添加使用！
          </p>
        </div>
      ),
      {
        title: '解绑设备',
        width: 450,
        onOk: async () => {
          const data = await unbind(result.pkId);
          if (data) {
            this.$message.success('解绑成功！');
            this.getList();
          } else {
            this.$message.error('解绑失败！');
          }
        },
      },
    );
  }

  async handleDelete(val) {
    try {
      const data = await createFormModal(
        () => <DeleteDevice code={val.deviceCode} />,
        {
          title: '删除设备',
          width: 460,
        },
      );
      if (data) {
        const res = await deleteDevice(val.pkId);
        if (res) {
          this.$message.success('删除成功！');
          this.getList();
        } else {
          this.$message.error('删除失败！');
        }
      }
    } catch (error) {
      if (error.message !== 'user cancel') this.$message.error(error.message);
    }
  }

  introduceModal(id) {
    const model = createModal(
      () => (
        <Introduce
          platformId={this.id}
          productionId={id}
          onClose={() => model.handleClose()}
        />
      ),
      {
        title: '产品介绍',
        width: 1250,
      },
    );
  }

  async panelModal(id, deviceData = {}) {
    try {
      const preview = Object.keys(deviceData).length > 0 ? true : false;
      const options = {
        title: '设备面板',
        width: 800,
        okText: '保存',
      };
      if (preview) {
        options['footer'] = () => null;
      }
      const form = await createFormModal(
        () => (
          <Panel
            platformId={this.id}
            productionId={id}
            deviceData={deviceData}
            preview={preview}
          />
        ),
        options,
      );
      if (form) {
        await addOrEditDevicePanel(form);
        this.$message.success('保存成功');
      }
    } catch {
      return false;
    }
  }

  async copyCode(record) {
    await clipboardWrite(record.deviceCode + '/' + record.deviceId);
    this.$message.success(this.$t('common.tips.copy'));
  }
  handleConfig() {}
  async copyTxt(msg) {
    await clipboardWrite(msg);
    this.$message.success(this.$t('common.tips.copy'));
  }
  /** @description Message弹窗Error VNode */
  errorMsg(msg, duration = 3) {
    const h = this.$createElement;
    const container = h(
      'span',
      {
        on: {
          click: () => {
            this.copyTxt(msg);
          },
        },
      },
      [
        h(
          'a',
          {
            style: {
              textDecoration: 'underline',
            },
          },
          '复制错误信息!',
        ),
      ],
    );
    message.warn({
      content: container,
      duration,
    });
  }
  async handleAdd(obj) {
    try {
      const title = '添加设备';
      let res = await createFormModal(
        () => (
          <DeviceForm editData={obj} editFlags={title} platformId={this.id} />
        ),
        {
          width: '450px',
          title: title,
        },
      );
      if (res && res.addType === 'manual') {
        const result = await createFormModal(
          () => <SecondForm productId={res.production} />,
          {
            width: '450px',
            title: title,
          },
        );
        const addParams = {
          platformId: this.id,
          productId: res.production,
          deviceCode: result.deviceCode,
          deviceId: result.deviceID,
          deviceType: result.deviceType,
          idxDeviceCode: result.gateway,
          rawKey: result.deviceKey,
          remark: result.remark,
        };
        if (result.image && result.image.length > 0) {
          addParams['headFigure'] = result.image[0].ossPath;
        }
        const data = await add(addParams);
        if (data) {
          this.$message.success('添加成功！');
          await this.getList();
        } else {
          this.$message.success('添加失败！');
        }
      } else if (res && res.addType === 'excel') {
        const form = new FormData();
        form.append('file', res.file);
        const {
          deviceCodeErrorList,
          deviceIdErrorList,
          deviceTypeErrorList,
          idxDeviceCodeErrorList,
          rawKeyErrorList,
          remarkErrorList,
        } = await importDevice(res.production, form);
        if (
          deviceCodeErrorList.length ||
          deviceIdErrorList.length ||
          deviceTypeErrorList.length ||
          idxDeviceCodeErrorList.length ||
          rawKeyErrorList.length ||
          remarkErrorList.length
        ) {
          let msg = '';
          if (deviceCodeErrorList.length) {
            msg += `设备码异常：第${deviceCodeErrorList.join('、')}行；`;
          }
          if (deviceIdErrorList.length) {
            msg += `设备ID超长：第${deviceIdErrorList.join('、')}行；`;
          }
          if (deviceTypeErrorList.length) {
            msg += `设备类型异常：第${deviceTypeErrorList.join('、')}行；`;
          }
          if (idxDeviceCodeErrorList.length) {
            msg += `所属网关超长：第${idxDeviceCodeErrorList.join('、')}行；`;
          }
          if (rawKeyErrorList.length) {
            msg += `原始密钥超长：第${rawKeyErrorList.join('、')}行；`;
          }
          if (remarkErrorList.length) {
            msg += `备注说明超长：第${remarkErrorList.join('、')}行；`;
          }
          // const h = this.$createElement;
          // h('div', { on: { click: this.copyTxt(msg) } }, ['复制错误信息！']),
          // this.$message.error(msg);
          this.errorMsg(msg);
          await this.getList();
        } else {
          this.$message.success('导入成功！');
          await this.getList();
        }
      }
      Emitter.$emit(EventType.DEVICE_MANAGE_PRODUCT_UPDATE);
    } catch (error) {
      if (error.message === 'user cancel') return;
    }
  }
  columns = [
    {
      dataIndex: 'index',
      customRender: (text, row, index) => {
        return index + 1;
      },
      title: '序号',
      align: 'center',
      width: 80,
    },
    {
      title: '设备码/ID',
      dataIndex: 'deviceCode',
      width: 300,
      scopedSlots: { customRender: 'deviceCode' },
    },
    {
      dataIndex: 'rawKey',
      title: '原始密钥/自定义',
      width: 300,
      scopedSlots: { customRender: 'deviceKey' },
    },
    {
      dataIndex: 'deviceStatus',
      title: '分配状态',
      width: 200,
      scopedSlots: { customRender: 'deviceStatus' },
    },
    {
      dataIndex: 'deviceType',
      title: '设备类型',
      customRender: type => {
        const key = {
          DIRECT_CONNECTION: '直连设备',
          GATEWAY: '网关设备',
          SUB_GATEWAY: '网关子设备',
        };
        return key[type];
      },
    },
    {
      dataIndex: 'idxDeviceCode',
      title: '所属网关',
      customRender: val => {
        return val || '-';
      },
    },
    {
      dataIndex: 'productName',
      title: '产品分类',
    },
    {
      dataIndex: 'remark',
      title: '备注',
    },
    {
      width: 200,
      dataIndex: '',
      title: '操作',
      scopedSlots: { customRender: 'operate' },
    },
  ];
  deivceStatusEnum = {
    NOT_ALLOCATED: '待分配',
    ALLOCATED: '已分配',
    BIND: '已绑定',
    EXPIRED: '已过期',
  };

  list = [];
  statusMap = { open: '启用', stoped: '停用', expired: '过期' };
  selectItem = {};
  tableLoading = false;
  confirmVisible = false;
  confirmLoading = false;
  detailsVisible = false;
  pagination = {
    showQuickJumper: true,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };

  productId = '';

  async handleSelect(productId) {
    this.productId = productId;
    this.pagination.current = 1;
    await this.getList();
  }

  deviceStatus = 'ALL';

  handleStatus(value) {
    this.deviceStatus = value ? value : 'ALL';
    this.pagination.current = 1;
    this.getList();
  }

  async getList() {
    const params = {
      platformId: this.id,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.deviceStatus !== 'ALL') {
      params['deviceStatusEnum'] = this.deviceStatus;
    }
    if (this.keyword) {
      params['keyword'] = this.keyword;
    }
    if (this.productId) {
      params['productId'] = this.productId;
    }
    try {
      this.tableLoading = true;
      const { records, total } = await getList(params);
      this.tableLoading = false;
      this.list = records;
      this.pagination.total = total;
    } catch {
      this.tableLoading = false;
      return false;
    }
  }

  switchStatus(record) {
    this.selectItem = record;
    if (record.status === 'open') {
      this.confirmVisible = true;
    } else {
      this.selectItem.status = 'open';
      this.$message.success('启用成功！');
    }
  }

  handlePagination(data) {
    this.pagination = data;
    this.selectedRows = [];
    this.selectedRowKeys = [];
    this.getList();
  }
}
</script>
<style lang="less" module>
.deviceM {
  position: relative;

  .btn {
    position: absolute;
    left: 10px;
    bottom: 20px;
  }
}

.primaryColor {
  color: var(--primary);
}
.btns {
  color: var(--primary);
  cursor: pointer;
}
.tagColumn {
  display: flex;
  text-align: center;
  p {
    line-height: 25px;
    margin-right: 10px;
  }
  .copyBtn {
    cursor: pointer;
  }
}
.logodiv {
  display: flex;
  align-items: center;
  .logo {
    width: 50px;
    height: 50px;
  }

  .logotext {
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.iconQrcode {
  margin-left: 10px;
  cursor: pointer;
  color: var(--primary);
}

.statusName {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.buttonGroups {
  display: flex;
  justify-content: flex-start;

  .red {
    color: red;
  }

  .button {
    width: 50px;
    position: relative;

    .messageCount {
      position: absolute;
      font-size: 12px;
      top: 1px;
      right: 5px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      border-radius: 50%;
      background: var(--font-active);
      color: var(--table-row);
      text-align: center;
    }
  }
}
.UnbindMain {
  font-size: 16px;
}
.UnbindDesc {
  padding-top: 10px;
  font-size: 12px;
  color: var(--font-active);
}

:global {
  .ant-table-wrapper .ant-table-pagination {
    display: flex;
    justify-content: flex-end;
    text-align: left;
    padding: 16px 0;
  }
}
</style>
