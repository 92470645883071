<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :form="form"
    layout="vertical"
  >
    <a-form-model-item
      :label="$t('iconLibrary.icon.name')"
      ref="iconName"
      prop="iconName"
    >
      <a-input v-model="form.iconName" :maxLength="30"></a-input>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';

function createModal({ ...props }, { title, width = '440px' }) {
  return createFormModal(h => h(IconForm, { props }), {
    title,
    width,
  });
}

@Component()
export default class IconForm extends Vue {
  @Prop({ type: Object, default: () => null }) data;
  form = {
    iconName: '',
    pkId: '',
  };

  get rules() {
    return {
      iconName: [
        {
          required: true,
          trigger: 'blur',
          message: this.$t('common.tips.enterName'),
        },
      ],
    };
  }

  async getValue() {
    await this.$refs.ruleForm.validate();
    return {
      ...this.form,
    };
  }

  created() {
    this.initData();
  }
  initData() {
    if (this.data) {
      Object.keys(this.form).forEach(key => {
        if (this.data[key] !== undefined && this.data[key] !== null) {
          this.form[key] = this.data[key];
        }
      });
    }
  }

  static createModal = createModal;
}
</script>
<style lang="less" module></style>
