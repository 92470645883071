import request from '../request';
const serviceName = '/iot-platform/releaseRecord';

/** @name 产品发布 */
export function releaseRecord(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}

/** @name 产品审批分页列表 */
export function getReleaseList(data) {
  return request(`${serviceName}/page`, {
    method: 'POST',
    body: data,
  });
}

/** @name 产品历史记录 */
export function getReleasehistory(data) {
  return request(`${serviceName}/history`, {
    method: 'POST',
    body: data,
  });
}

/** @name 审批 */
export function approval(data) {
  return request(`${serviceName}/approval`, {
    method: 'PUT',
    body: data,
  });
}

/** @name 移除审批中的发布记录 */
export function deleteRelease(id, type) {
  return request(`${serviceName}?platformId=${id}&releaseTypeEnum=${type}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
  });
}

/** @name 发布记录企业列表 */
export function getTenant(data) {
  return request(`${serviceName}/tenant`, {
    method: 'GET',
    body: data,
  });
}
