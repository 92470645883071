<template>
  <div :class="$style.box">
    <div :class="$style.fileName">
      <x-icon type="tc-icon-file-unknown" style="font-size: 30px" />
      <span>{{ $store.state.hat.fileName }}</span>
    </div>
    <div :class="$style.loading">
      <a-icon type="sync" spin />
      <div :class="$style.tips">导入中</div>
    </div>
    <div :class="$style.footer">
      <a-button type="primary" @click="cancle">取消</a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class ExcelLoading extends Vue {
  @Prop({ type: Function, default: () => {} }) handleClose;
  cancle() {
    this.$store.state.hat.cor.abort();
    this.$store.commit('hat/setExcelState', 1);
    this.$message.success('取消上传文件!');
  }
}
</script>
<style lang="less" module>
.box {
  .fileName {
    width: 100%;
    height: 40px;
    background-color: #f5f7fa;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    padding-left: 10px;
    span {
      margin-left: 15px;
    }
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .tips {
      margin-left: 10px;
    }
  }
  .footer {
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>
