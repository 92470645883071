<template>
  <a-form-model
    :class="$style.form"
    ref="form"
    :model="form"
    :rules="rules"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 9 }"
  >
    <a-form-model-item
      :label="$t('finance.order.InvoiceAttachment')"
      prop="attachment"
    >
      <x-upload-button
        basePath="/oss/backend"
        :uploadPath="uploadPath"
        @success="handleUpload"
      >
        <a-button slot="default">{{ $t('finance.order.Upload20M') }}</a-button>
      </x-upload-button>
      <div
        :class="$style.file"
        v-for="(file, index) in form.attachment"
        :key="file.ossPath"
        @click="preview(index)"
      >
        <file-icon :url="file.ossPath" />
        <x-ellipsis :class="$style.name">{{ file.name }}</x-ellipsis>
        <a-icon
          :class="$style.delete"
          type="delete"
          @click="
            () =>
              (form.attachment = form.attachment.filter(
                item => item.ossPath !== file.ossPath,
              ))
          "
        />
      </div>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('finance.order.ExpressDelivery')"
      prop="sendCourier"
    >
      <a-input
        v-model="form.sendCourier"
        :placeholder="
          $t('common.placeholder.input') + $t('finance.order.ExpressDelivery')
        "
      />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('finance.order.CourierNumber')"
      prop="trackingNumber"
    >
      <a-input
        v-model="form.trackingNumber"
        :placeholder="
          $t('common.placeholder.input') + $t('finance.order.CourierNumber')
        "
      />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('finance.order.DeliveryTime')"
      required
      prop="deliveryTime"
    >
      <a-date-picker
        v-model="form.deliveryTime"
        type="date"
        :placeholder="$t('finance.order.SelectDate')"
        value-format="YYYY-MM-DD"
      />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('finance.order.RecipientName')"
      prop="addresseeName"
    >
      <a-input
        v-model="form.addresseeName"
        :placeholder="
          $t('common.placeholder.input') + $t('finance.order.RecipientName')
        "
      />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('finance.order.RecipientPhone')"
      prop="receivingPhone"
    >
      <x-input
        v-model="form.receivingPhone"
        :placeholder="
          $t('common.placeholder.input') + $t('finance.order.RecipientPhone')
        "
        :maxLength="11"
        autocomplete="tel"
        transform="number"
      />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('finance.order.RecipientAddress')"
      prop="shippingAddress"
    >
      <a-textarea
        v-model="form.shippingAddress"
        :placeholder="
          $t('common.placeholder.input') + $t('finance.order.RecipientAddress')
        "
      />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { uuid } from '@triascloud/utils';
import { UploadService } from '@triascloud/services';
import { createProcessing } from '@/services/transaction/invoice.js';
import FileIcon from '../components/file-icon';

@Component({ components: { FileIcon } })
export default class ApplyInvoice extends Vue {
  @Prop({ type: String, default: '' }) companyId;
  @Prop({ type: String, default: '' }) pkId;
  @Prop({ type: Object, default: {} }) data;

  fileList = [];
  form = {
    attachment: [],
    sendCourier: '',
    trackingNumber: '',
    deliveryTime: '',
    addresseeName: '',
    receivingPhone: '',
    shippingAddress: '',
  };

  mounted() {
    this.form.addresseeName = this.data.expressDelivery.addresseeName;
    this.form.receivingPhone = this.data.expressDelivery.receivingPhone;
    this.form.shippingAddress = this.data.expressDelivery.shippingAddress;
  }

  rules = {
    attachment: [
      {
        required: true,
        message: this.$t('finance.order.RulesInvoice'),
        trigger: 'change',
      },
    ],
    sendCourier: [
      {
        required: true,
        message:
          this.$t('common.placeholder.input') +
          this.$t('finance.order.ExpressDelivery'),
      },
    ],
    trackingNumber: [
      {
        required: true,
        message:
          this.$t('common.placeholder.input') +
          this.$t('finance.order.CourierNumber'),
      },
    ],
    deliveryTime: [
      {
        required: true,
        message: this.$t('finance.order.SelectDate'),
        trigger: 'change',
      },
    ],
    addresseeName: [
      {
        required: true,
        message:
          this.$t('common.placeholder.input') +
          this.$t('finance.order.RecipientName'),
      },
    ],
    receivingPhone: [
      { required: true, message: this.$t('finance.order.PhoneCannotBeEmpty') },
    ],
    shippingAddress: [
      {
        required: true,
        message:
          this.$t('common.placeholder.input') +
          this.$t('finance.order.RecipientAddress'),
      },
    ],
  };

  get service() {
    return new UploadService('/oss/backend');
  }

  get uploadPath() {
    return `${this.companyId}/order/${this.pkId}/${uuid()}`;
  }

  handleUpload({ ossData }) {
    this.form.attachment.push(ossData);
    this.$refs.form.validate('form.attachment');
  }

  preview(index) {
    this.$preview({
      index,
      urls: this.form.attachment.map(item => item.ossPath),
      wpsBasePath: '/oss/backend/wps',
      ossBasePath: '/oss/backend',
    });
  }

  async getValue() {
    await this.$refs.form.validate();
    await createProcessing({
      ...this.form,
      invoiceId: this.pkId,
      attachment: JSON.stringify(this.form.attachment),
      deliveryTime: this.$moment(this.form.deliveryTime).valueOf(),
    });
    this.$message.success(this.$t('finance.order.BillingSucceeded'));
  }
}
</script>
<style lang="less" module>
.form {
  :global {
    .ant-form-item {
      margin-bottom: 20px;
      .ant-form-item-label {
        text-align: center !important;
      }
    }
    .ant-form-explain {
      position: absolute;
    }
  }
}
.file {
  width: 500px;
  display: flex;
  align-items: center;
  border: 1px solid var(--tag-border);
  border-radius: 4px;
  padding: 8px;
  margin-top: 10px;
  .name {
    width: 100%;
    margin: 0 10px 0 5px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-all;
  }
  .delete {
    float: right;
    color: var(--font-btn);
    margin-top: 3px;
  }
}
</style>
