<template>
  <div :id="id" :style="{ height: height, width: width }"></div>
</template>

<script>
import * as echarts from 'echarts';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class TopPieChart extends Vue {
  @Prop({ type: String, default: '200px' }) height;
  @Prop({ type: String, default: '200px' }) width;
  @Prop({ type: Array, default: () => [] }) chartData;
  @Prop({ type: String, default: 'id' }) id;
  @Prop({ type: Function, default: () => {} }) updateTable;
  @Prop({
    type: Array,
    default: () => [],
  })
  colorArray;
  time = '';
  mounted() {}
  @Watch('chartData', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal && newVal.length) {
      this.$nextTick(() => {
        this.initChart();
      });
    } else {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
    }
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get option() {
    const colors = [
      '#4FA2FF',
      '#00C56E',
      '#FFCC37',
      '#EF6666',
      '#0065C4',
      '#07B9B9',
      '#3BA272',
      '#EA7CCC',
      '#91CC75',
      '#FAC858',
    ];

    return {
      color: [...this.colorArray, ...colors],
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '5%',
        left: 'center',
        show: false,
      },
      series: [
        {
          type: 'pie',
          radius: ['42%', '65%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 14,
              fontWeight: 'normal',
            },
          },
          labelLine: {
            show: false,
          },
          data: this.chartData,
        },
      ],
    };
  }
  chart = null;
  initChart() {
    if (this.chartData.length) {
      this.chart = echarts.init(document.getElementById(this.id));
      const options = this.option;
      this.chart.setOption(options);
      const that = this;
      this.chart.on('click', function(params) {
        params.event.event.stopPropagation();
        that.getTablistbyDevice(params.name);
      });
    }
  }
  getTablistbyDevice(name) {
    const update = () => {
      let obj = {
        未激活: 'UNACTIVE',
        在线: 'ONLINE',
        离线: 'OFFLINE',
        待机: 'STANDBY',
        禁用: 'DISABLE',
        过期: 'EXPIRED',
      };
      const en = obj[name];
      if (this.$store.state.hat.status == en) {
        this.$store.commit('hat/setStatus', null);
        this.updateTable();
      } else {
        this.$store.commit('hat/setStatus', en);
        this.updateTable();
      }
    };
    var date = new Date().getTime();
    if (date - 800 > this.time) {
      update();
      this.time = new Date().getTime();
    }
  }
}
</script>

<style scoped></style>
