<template>
  <component
    :is="computedComponent"
    :value="value"
    :view="view"
    :field="field"
    v-on="$listeners"
    v-bind="$attrs"
    :class="$style.box"
    v-if="field.widget.subType !== ControlTypeEnum.struct"
  />
  <Design v-else :field="field" />
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ControlTypeEnum } from '../enum';
import Design from '../Struct/design.vue';

@Component({
  components: {
    Design,
  },
})
export default class ArrayDesign extends Vue {
  /** @description 数据 */
  @Prop({
    type: Object,
    required: true,
  })
  field;
  @Prop() value;
  @Prop() view;

  ControlTypeEnum = ControlTypeEnum;

  get computedComponent() {
    if (this.field.widget.subType === ControlTypeEnum.Struct) {
      return 'Design';
    } else {
      return 'x-input';
    }
  }
}
</script>

<style lang="less" module>
.box {
  max-width: 400px;
}
</style>
