import request from '../request';
const serviceName = '/iot-platform/platform';

// 接入平台
// list 查询
export function getPlatform(data) {
  return request(`${serviceName}/list`, {
    method: 'GET',
    body: data,
  });
}

/** @name 获取单个平台 */
export function getPlatformData(id) {
  return request(`${serviceName}/get/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

// 新增平台
export function addOrEditPlatform(data) {
  return request(`${serviceName}/createCommand`, {
    method: 'POST',
    body: data,
  });
}

// 新增平台
export function addPlatform(data) {
  return request(`${serviceName}/createCommand`, {
    method: 'POST',
    body: data,
  });
}

// 修改平台
export function editPlatform(data) {
  return request(`${serviceName}/updateCommand`, {
    method: 'PUT',
    body: data,
  });
}

// 校验平台标识
export function checkIdentifier(data) {
  return request(`${serviceName}/checkIdentifier`, {
    method: 'POST',
    body: data,
  });
}
// 删除平台
export function deletePlatform(platFormId) {
  return request(`${serviceName}/deleteCommand/${platFormId}`, {
    method: 'DELETE',
  });
}

// 改变平台状态
export function changePlatformStatus(data) {
  return request(`${serviceName}/updateStatusCommand`, {
    method: 'PUT',
    body: data,
  });
}

// * 连接器日志
export function apiLog(data) {
  return request('/iot-platform/apiLog/runLog', {
    method: 'POST',
    body: data,
  });
}

// * 连接器 模糊搜索
export function apiLikeSearch(data) {
  return request('/iot-platform/platformInterface/like', {
    method: 'POST',
    body: data,
  });
}
