<template>
  <div>
    <x-data-table
      ref="table"
      base-path="/template-manager/secondary/node/statistics/identifyQuantity/detail"
      :columns="columns"
      :service="service"
      :class="$style.table"
      :custom-row="
        record => ({
          on: {
            click: () => details(record),
          },
          style: {
            cursor: 'pointer',
          },
        })
      "
    >
      <template v-slot:header></template>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { DataSourceService } from '@triascloud/services';
import { createModal } from '@triascloud/x-components';
import { quantityDetail } from '@/services/template-manager/secondary';
import NodeDetails from './node-details.vue';
import { SyncType, SyncResult } from '@/enum/snms';
import { globalVueI18n } from '@triascloud/i18n';

function createLogoDetailsModal(props) {
  createModal(h => h(LogoDetailsModal, { ...props }), {
    title: globalVueI18n.translate('plugin.snms.idList'),
    width: 1000,
  });
}
@Component()
export default class LogoDetailsModal extends Vue {
  static create = createLogoDetailsModal;
  async details({ identification }) {
    await createModal(
      h => h(NodeDetails, { props: { handle: identification } }),
      {
        title: globalVueI18n.translate('plugin.snms.identification'),
        width: 1000,
        bodyStyle: { padding: 0 },
      },
    );
  }
  get service() {
    const service = new DataSourceService(
      '/template-manager/secondary/node/statistics/identifyQuantity/detail',
    );
    service.getList = report =>
      quantityDetail({
        current: report.skip / report.size + 1,
        size: report.size,
      }).then(res => {
        return {
          data: res ? res.records : [],
          total: res ? res.total : 0,
        };
      });
    return service;
  }
  get columns() {
    return [
      {
        title: this.$t('plugin.snms.prefix'),
        key: 'prefix',
        customRender: record => record.identification.split('/')[0],
      },
      {
        key: 'identification',
        title: this.$t('plugin.snms.logo'),
        dataIndex: 'identification',
        width: 150,
      },
      {
        dataIndex: 'type',
        title: this.$t('common.type'),
        customRender: type => this.getSyncTypeLabel(type),
        width: 50,
      },
      {
        dataIndex: 'parseStatus',
        title: this.$t('plugin.snms.parsingStatus'),
        customRender: () => this.$t('plugin.snms.synced'),
        width: 70,
      },
      {
        dataIndex: 'synchronizationInformation',
        title: this.$t('plugin.snms.synchronization'),
        customRender: status =>
          status === SyncResult.Success
            ? this.$t('plugin.snms.SynchronizationSucceeded')
            : this.$t('plugin.snms.syncFailed'),
        width: 80,
      },
      {
        title: this.$t('common.label.createTime'),
        dataIndex: 'createTime',
        width: 120,
        customRender: time => this.$moment(time).format('Y-MM-DD HH:mm:ss'),
      },
      // {
      //   title: '更新时间',
      //   key: 'updateTime',
      //   dataIndex: 'updateTime',
      //   width: 120,
      // },
      {
        title: this.$t('common.action.operating'),
        key: 'action',
        width: 80,
        customRender: () => <a>{this.$t('common.label.detail')}</a>,
      },
    ].map(item => ({
      ...item,
      width: item.width || 100,
      sorter: false,
      filter: false,
    }));
  }
  getSyncTypeLabel(syncType) {
    switch (syncType) {
      case SyncType.Register:
        return this.$t('plugin.snms.register');
      case SyncType.Update:
        return this.$t('common.action.renew');
      case SyncType.Remove:
        return this.$t('common.action.delete');
      default:
        return '-';
    }
  }
}
</script>
<style lang="less" module>
.primaryColor {
  color: var(--primary);
}
</style>
