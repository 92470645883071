<template>
  <div :class="$style.business">
    <div :class="$style.search">
      <div :class="$style.left">
        <a-input
          :placeholder="`${$t('common.placeholder.input')}`"
          v-model="search.keyword"
          :class="$style.input"
        ></a-input>

        <div :class="$style.item">
          <span :class="$style.label">
            {{ $t('inviteCode.registrationTime') }}
          </span>
          <x-range-picker
            v-model="registerTime"
            :format="'YYYY-MM-DD'"
            :class="$style.rangepicker"
          ></x-range-picker>
        </div>

        <div :class="$style.item">
          <span :class="$style.label">
            {{ $t('business.isCertifiedEnterprise') }}
          </span>
          <a-select default-value="1" v-model="search.isCertifiedEnterprise">
            <a-select-option :value="''">
              {{ $t('common.label.all') }}
            </a-select-option>
            <a-select-option :value="1">
              {{ $t('template.review.checked') }}
            </a-select-option>
            <a-select-option :value="0">
              {{ $t('template.review.unChecked') }}
            </a-select-option>
          </a-select>
        </div>

        <div :class="$style.item">
          <span :class="$style.labelTime">
            {{ $t('business.enterpriseCertificationTime') }}
          </span>
          <x-range-picker
            v-model="certifiedTime"
            :format="'YYYY-MM-DD'"
            :class="$style.rangepicker"
          ></x-range-picker>
        </div>
      </div>
      <div :class="$style.right">
        <a-button :class="$style.btn" @click="handleReset">
          {{ $t('activity.reset') }}
        </a-button>
        <a-button
          :class="$style.btn"
          @click="handleConfirm"
          @keyup.enter.native="handleConfirm"
        >
          {{ $t('common.action.confirm') }}
        </a-button>
      </div>
    </div>

    <x-data-table
      base-path="/tenant-center/business/opportunities/list"
      :columns="columns"
      :service="service"
      ref="table"
      :class="$style.table"
    >
      <div slot="headerLeft"></div>
      <div slot="headerRight"></div>
    </x-data-table>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import InputFollowupInfo from './components/input-followup-info.vue';
import ReadDetail from './components/read-detail.vue';
import { DataSourceService } from '@triascloud/services';
import { fetchList } from '@/services/business-opportunity/index';

@Component({})
export default class BusinessOpportunity extends Vue {
  search = {
    keyword: '',
    registerTimeStart: '',
    registerTimeEnd: '',
    isCertifiedEnterprise: '',
    certifiedTimeStart: '',
    certifiedTimeEnd: '',
  };
  registerTime = [];
  certifiedTime = [];

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  }
  handleKeyDown(e) {
    var key = window.event ? e.keyCode : e.which;
    if (key === 13) {
      this.handleConfirm();
    }
  }

  @Watch('registerTime')
  onWatchRegisterTime() {
    this.search.registerTimeStart = this.registerTime[0] || '';
    this.search.registerTimeEnd = this.registerTime[1] || '';
  }
  @Watch('certifiedTime')
  onWatchCertifiedTime() {
    this.search.certifiedTimeStart = this.certifiedTime[0] || '';
    this.search.certifiedTimeEnd = this.certifiedTime[1] || '';
  }
  handleReset() {
    Object.keys(this.search).map(key => (this.search[key] = ''));
    this.registerTime = [];
    this.certifiedTime = [];

    this.resetList();
  }
  handleConfirm() {
    this.resetList();
  }
  size = 10;
  resetList() {
    this.$refs.table?.loadData({
      ...this.search,
      size: this.size,
      current: 1,
    });
  }
  get service() {
    const service = new DataSourceService(
      '/tenant-center/business/opportunities/list',
    );
    service.getList = ({ skip, size }) => {
      return fetchList({
        ...this.search,
        size,
        current: skip / size + 1,
      }).then(res => {
        this.size = res.size;
        return res
          ? {
              total: res.total,
              data: res?.records,
            }
          : { total: 0, data: [] };
      });
    };
    return service;
  }

  certifiedEnterpriseText(status) {
    switch (status) {
      case 1:
        return this.$t('template.review.checked');
      case 0:
        return this.$t('template.review.unChecked');
      default:
        return this.$t('common.label.all');
    }
  }

  async followupInfo(record) {
    await InputFollowupInfo.createInputFollowupInfoModal({
      modalInfo: { title: this.$t('business.fillInFollowUpInformation') },
      memberId: record.memberId,
    });
  }

  async readDetail(record) {
    await ReadDetail.createReadDetailModal({
      modalInfo: { title: this.$t('finance.order.ViewDetails') },
      detail: record,
    });
  }

  get columns() {
    return [
      {
        dataIndex: 'memberName',
        title: this.$t('website.homePage.name'),
        key: 'memberName',
        filter: false,
        sorter: false,
        customRender: record => (
          <span slot="memberName">
            <x-ellipsis>{record}</x-ellipsis>
          </span>
        ),
      },
      {
        dataIndex: 'phone',
        title: this.$t('activity.phoneNumber'),
        key: 'phone',
        filter: false,
        sorter: false,
        customRender: record => (
          <span slot="phone">
            <x-ellipsis>{record}</x-ellipsis>
          </span>
        ),
      },
      {
        dataIndex: 'registerTime',
        title: this.$t('inviteCode.registrationTime'),
        key: 'registerTime',
        filter: false,
        sorter: false,
        customRender: record => (
          <span slot="registerTime">
            <x-ellipsis>
              {record ? this.$moment(record).format('YYYY-MM-DD') : ''}
            </x-ellipsis>
          </span>
        ),
      },
      {
        dataIndex: 'tenantName',
        title: this.$t('enterprise.enterpriseText'),
        key: 'tenantName',
        filter: false,
        sorter: false,
        customRender: record => (
          <span slot="tenantName">
            <x-ellipsis>{record}</x-ellipsis>
          </span>
        ),
      },
      {
        dataIndex: 'isCertifiedEnterprise',
        title: this.$t('business.isCertifiedEnterprise'),
        key: 'isCertifiedEnterprise',
        filter: false,
        sorter: false,
        width: 150,
        customRender: record => (
          <span slot="isCertifiedEnterprise">{`
          ${this.certifiedEnterpriseText(record)}
          `}</span>
        ),
      },
      {
        dataIndex: 'certifiedTime',
        title: this.$t('business.enterpriseCertificationTime'),
        key: 'certifiedTime',
        filter: false,
        sorter: false,
        width: 150,
        customRender: record => (
          <span slot="certifiedTime">
            <x-ellipsis>
              {record ? this.$moment(record).format('YYYY-MM-DD') : ''}
            </x-ellipsis>
          </span>
        ),
      },
      {
        dataIndex: 'memberCount',
        title: this.$t('business.enterpriseMembers'),
        key: 'memberCount',
        filter: false,
        sorter: false,
        customRender: record => (
          <span slot="memberCount">
            <x-ellipsis>{record}</x-ellipsis>
          </span>
        ),
      },
      {
        dataIndex: 'position',
        title: this.$t('business.enterprisePosition'),
        key: 'position',
        filter: false,
        sorter: false,
        customRender: record => (
          <span slot="position">
            <x-ellipsis>{record}</x-ellipsis>
          </span>
        ),
      },
      {
        dataIndex: 'lastFlowUpPersonName',
        title: this.$t('business.LastFollower'),
        key: 'lastFlowUpPersonName',
        filter: false,
        sorter: false,
        customRender: record => (
          <span slot="lastFlowUpPersonName">
            <x-ellipsis>{record}</x-ellipsis>
          </span>
        ),
      },
      {
        dataIndex: 'lastFlowUpPersonTime',
        title: this.$t('business.LastFollowUpTime'),
        key: 'lastFlowUpPersonTime',
        filter: false,
        sorter: false,
        width: 150,
        customRender: record => (
          <span slot="lastFlowUpPersonTime">
            <x-ellipsis>
              {record ? this.$moment(record).format('YYYY-MM-DD') : ''}
            </x-ellipsis>
          </span>
        ),
      },
      {
        dataIndex: 'lastFlowUpPersonRecord',
        title: this.$t('business.LastFollowUpRecord'),
        key: 'lastFlowUpPersonRecord',
        filter: false,
        sorter: false,
        width: 150,
        customRender: record => (
          <span slot="lastFlowUpPersonRecord">
            <x-ellipsis>{record}</x-ellipsis>
          </span>
        ),
      },
      {
        key: 'action',
        title: this.$t('common.action.operating'),
        filter: false,
        sorter: false,
        width: 230,
        customRender: record => (
          <span>
            <span onClick={ev => ev.stopPropagation()}>
              <a
                type="link"
                onClick={() => {
                  this.followupInfo(record);
                }}
                class={this.$style.action}
              >
                {this.$t('business.FillInTheFollowUpRecord')}
              </a>
            </span>
            <span onClick={ev => ev.stopPropagation()}>
              <a
                type="link"
                onClick={() => {
                  this.readDetail(record);
                }}
                class={this.$style.action}
              >
                {this.$t('finance.order.ViewDetails')}
              </a>
            </span>
          </span>
        ),
      },
    ];
  }
}
</script>

<style lang="less" module>
.business {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  .left {
    .input {
      width: 120px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 10px;
      .label {
        width: 90px;
        text-align: center;
        margin-right: 5px;
        color: var(--font);
      }
      .labelTime {
        width: 110px;
        text-align: center;
        margin-right: 5px;
        color: var(--font);
      }
      .rangepicker {
        width: 120px;
      }
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn {
      background-color: var(--primary);
      color: var(--block-bg);
      border: none;
      margin-left: 10px;
    }
  }
}
.table {
  flex: 1;
  overflow: hidden;
}
.action {
  margin-right: 10px;
}
</style>
