/** @type { import('vuex').Module } */
const variable = {
  namespaced: true,
  state: {
    variableAllList: null,
  },
  mutations: {
    updateVariableAllList(state, payload) {
      state.variableAllList = payload;
    },
  },
};

export default variable;
