<template>
  <div :class="$style.index">
    <x-data-table
      ref="table"
      base-path="/template-manager/template/statistics/templateUseDetail"
      :columns="columns"
      :service="service"
      :class="$style.table"
      :custom-row="
        record => ({
          on: {
            click: () => openDetail(record),
          },
          style: {
            cursor: 'pointer',
          },
        })
      "
      :scroll="{
        x: '100%',
        y: '100%',
      }"
    >
      <template slot="action">
        <a-input
          :class="$style.toolbar__input"
          v-model="searchData.keyword"
          @pressEnter="handleSearch($event, $table)"
          @blur="handleSearch($event, $table)"
        >
          <x-icon type="tc-icon-search" slot="prefix" />
        </a-input>
        <a-range-picker
          :class="$style.toolbar__picker"
          :placeholder="[
            `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
              'common.label.day',
            )}`,
            `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
              'common.label.day',
            )}`,
          ]"
          :separator="$t('common.label.to')"
          @change="handlerDatePickerChange"
        />
        <a-select
          :placeholder="$t('common.label.all')"
          :class="$style.toolbar__select"
          v-model="searchData.templateClassify"
          @change="handleSearch"
        >
          <a-select-option
            v-for="item in templateTypes"
            :key="item.value"
            :value="item.value"
            >{{ item.text }}</a-select-option
          >
        </a-select>
        <a-select
          :placeholder="$t('common.label.all')"
          :class="$style.toolbar__select"
          v-model="searchData.paidSolution"
          @change="handleSearch"
        >
          <a-select-option
            v-for="item in templateClassList"
            :key="item.value"
            :value="item.value"
            >{{ item.text }}</a-select-option
          >
        </a-select>
      </template>
      <div slot="headerLeft" :class="$style.toolbar">
        <b>{{ $t('market.statistics.usageDetails') }}</b>
      </div>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { templateUseDetail } from '@/services/template-manager/template';
import { DataSourceService } from '@triascloud/services';
import { clipboardWrite } from '@triascloud/utils';
import { OrderStatusText } from '@/enum/order';

@Component
export default class UseDetailTable extends Vue {
  @Prop({ type: String, default: '' }) classId;
  searchData = {
    keyword: '',
    timeStart: '',
    timeEnd: '',
    paidSolution: '',
    templateClassify: '',
  };
  templateTypes = [
    {
      text: this.$t('common.label.all'),
      value: '',
    },
    {
      text: this.$t('path.market.form'),
      value: 2,
    },
    {
      text: this.$t('path.market./'),
      value: 1,
    },
    {
      text: '打印模板',
      value: 3,
    },
  ];
  templateClassList = [
    {
      text: this.$t('common.label.all'),
      value: '',
    },
    {
      text: this.$t('market.statistics.paidTemplate'),
      value: 1,
    },
    {
      text: this.$t('market.statistics.freeTemplate'),
      value: 0,
    },
  ];
  datePicker = [];
  handleMenuClick() {}
  handlerDatePickerChange(range) {
    if (range.length) {
      this.searchData.timeStart = range[0].startOf('day').valueOf();
      this.searchData.timeEnd = range[1].endOf('day').valueOf();
    } else {
      this.searchData.timeStart = null;
      this.searchData.timeEnd = null;
    }
    this.$refs.table.loadData();
  }
  get columns() {
    return [
      {
        title: this.$t('market.statistics.templateName'),
        dataIndex: 'templateName',
        key: 'templateName',
      },
      {
        key: 'templateType',
        title: this.$t('market.statistics.templateType'),
        dataIndex: 'templateType',
        customRender: status =>
          status === 1 ? '应用模板' : status === 3 ? '打印模板' : '表单模板',
      },
      {
        title: this.$t('market.statistics.payTemplate'),
        dataIndex: 'paid',
        key: 'paid',
        customRender: status => (status === 1 ? '是' : '否'),
      },
      {
        title: this.$t('market.statistics.orderNumber'),
        dataIndex: 'orderNo',
        key: 'orderNo',
        width: 140,
      },
      {
        title: this.$t('solution.paymentStatus'),
        key: 'orderStatus',
        dataIndex: 'orderStatus',
        customRender: status => (
          <span
            class={
              status === '1'
                ? this.$style.primarySpan
                : status === '0'
                ? this.$style.warningSpan
                : status === '-2' || status === '-1'
                ? this.$style.dangerSpan
                : ''
            }
          >
            {status === '' ? '-' : OrderStatusText[status]}
          </span>
        ),

        width: 130,
      },
      {
        title: this.$t('solution.AmountTransaction'),
        dataIndex: 'amount',
        key: 'amount',
        // customRender: record => `¥ ${(record / 100).toFixed(2)}`,
        customRender: (_, res) => {
          return (
            <span>
              {res.paid !== 1 ? '-' : '¥ ' + (res.amount / 100).toFixed(2)}
            </span>
          );
        },
      },
      {
        title: this.$t('market.template.installTenant'),
        dataIndex: 'installTenantName',
      },
      {
        title: this.$t('market.statistics.Installer'),
        dataIndex: 'installMemberName',
      },
      {
        title: this.$t('solution.purchaseTime'),
        dataIndex: 'installTime',
        customRender: checkDate =>
          checkDate ? moment(checkDate).format('Y-MM-DD HH:mm') : '-',
      },
      {
        title: this.$t('market.statistics.contributionEnterprise'),
        dataIndex: 'pushTenantName',
      },
    ].map(item => ({
      ...item,
      width: item.width || 130,
      sorter: false,
      filter: false,
      align: 'left',
      ellipsis: false,
    }));
  }

  handleSearch() {
    this.$refs.table.loadData();
  }

  async handleCopy(data) {
    await clipboardWrite(data.inviteCode);
    this.$message.success(this.$t('common.tips.copy'));
  }

  total = 0;
  get service() {
    const service = new DataSourceService(
      '/template-manager/template/statistics/templateUseDetail',
    );
    service.getList = report =>
      templateUseDetail({
        current: report.skip / report.size + 1,
        endTime: this.searchData.timeEnd,
        keyword: this.searchData.keyword,
        paidSolution: this.searchData.paidSolution,
        startTime: this.searchData.timeStart,
        templateClassify: this.searchData.templateClassify,
        size: report.size,
      }).then(res => {
        return {
          ...res,
          current: 1,
          hitCount: false,
          pages: 1,
          searchCount: true,
          size: 10,
          data: res.records,
          total: res ? res.total : 0,
        };
      });
    return service;
  }
}
</script>
<style lang="less" module>
.table {
  padding: 20px;
  overflow: auto;
  :global(.x-data-table--action .ant-btn-primary) {
    color: #fff;
    margin-left: 10px;
  }
}

.index {
  height: 640px;
  box-shadow: 0px 0px 8px 0px var(--shadow);
}
.action {
  cursor: pointer;
  color: var(--font-active);
  &.action--delete {
    color: var(--delete);
    margin-left: 20px;
  }
}
.toolbar__input {
  width: 120px;
  margin-right: 10px;
}
.toolbar__picker {
  width: 220px;
  margin-right: 10px;

  :global {
    .ant-calendar-picker-icon {
      display: none;
    }
    .ant-calendar-picker-input.ant-input {
      padding-left: 10px;
      text-align: center;
    }
  }
}
.toolbar__select {
  min-width: 124px;
  margin-right: 10px;
}

.primarySpan {
  color: var(--success);
}
.warningSpan {
  color: var(--warning);
}
.dangerSpan {
  color: var(--danger);
}
</style>
