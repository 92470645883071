<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="tableList"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="tableLoading"
      @change="handlePagination"
    >
      <template slot="state" slot-scope="text, record">
        <span>{{ textObj[text] }}</span>
        <a-tooltip>
          <template slot="title">
            请检查订阅计划，建议重新发布运行！
          </template>
          <a-icon
            v-if="text === 'ERROR'"
            type="exclamation-circle"
            :class="$style.errorIcon"
          />
        </a-tooltip>
        <span>（V{{ record.currentVersion }}）</span>
      </template>
      <template slot="operate" slot-scope="record">
        <span :class="$style.buttonGroups">
          <a
            :class="[$style.button]"
            @click.stop="handleAddData(record, 'edit')"
            >编辑</a
          >
          <a
            v-if="record.state === 'STOP' || record.state === 'ERROR'"
            :class="[$style.button]"
            @click.stop="deleteList(record, 1)"
            >运行</a
          >
          <a v-else :class="[$style.button]" @click.stop="deleteList(record, 2)"
            >暂停</a
          >
          <a :class="[$style.button]" @click.stop="overView(record)">预览</a>
          <a
            :class="[$style.button]"
            style="color: var(--delete)"
            @click.stop="deleteList(record, 3)"
            >删除</a
          >
        </span>
      </template>
    </a-table>
  </div>
</template>
<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import {
  Emitter,
  EventType,
} from '@/views/connector/platform-manage/develop/utils';
import ServiceForm from './form';
import { createModal, createFormModal } from '@triascloud/x-components';
import { getSubscribeList } from '@/services/iot-platform/dataManage';
import TipsModel from '@/views/connector/platform-manage/develop/data-manage/tips-model.vue';
import OverView from '@/views/connector/platform-manage/develop/data-manage/overView.vue';

@Component({
  components: {
    LayoutContent,
    ServiceForm,
    OverView,
  },
})
export default class DataManage extends Vue {
  @InjectReactive() id;

  mounted() {
    this.getList();
    Emitter.$on(EventType.DATA_MANAGE_ADD, this.handleAddData);
    Emitter.$on(EventType.DATA_MANAGE_CHANGE, this.handleSearchStatus);
    Emitter.$on(EventType.DATA_MANAGE_SEARCH, this.handleSearch);
  }

  beforeDestroy() {
    Emitter.$off(EventType.DATA_MANAGE_ADD, this.handleAddData);
    Emitter.$off(EventType.DATA_MANAGE_CHANGE, this.handleSearchStatus);
    Emitter.$off(EventType.DATA_MANAGE_SEARCH, this.handleSearch);
  }

  searchParams = {
    status: '',
    input: '',
  };

  handleSearchStatus(val) {
    this.searchParams.status = val ? val : '';
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.getList();
  }

  handleSearch(val) {
    this.searchParams.input = val ? val : '';
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.getList();
  }

  handleLink() {}

  textObj = {
    RUN: '运行中',
    STOP: '已暂停',
    ERROR: '运行异常',
  };

  async deleteList(row, flag) {
    const tipsType = {
      1: 'STOP',
      2: 'RUN',
      3: 'delete',
    };
    await createFormModal(
      () => (
        <TipsModel
          pkId={row.pkId}
          editName={row.name}
          tipsType={tipsType[flag]}
        />
      ),
      {
        width: '460px',
        title: flag === 3 ? '操作确认' : '管理订阅',
      },
    );
    await this.getList();
  }

  overView(record) {
    const id = record.pkId;
    const modal = createModal(
      () => (
        <OverView
          subscribeId={id}
          platformId={this.id}
          planType={record.dataSources}
          onClose={() => modal.handleClose()}
        />
      ),
      {
        width: '1250px',
        title: '预览订阅',
      },
    );
  }

  handleAddData(obj, flag = 'add') {
    try {
      const title = obj ? '编辑订阅计划' : '新增订阅计划';
      const id = obj?.pkId;
      const modal = createModal(
        () => (
          <ServiceForm
            editFlag={flag}
            editFlags={title}
            subscribeId={id}
            platformId={this.id}
            onClose={() => modal.handleClose()}
            onRefreshList={() => this.refreshList()}
          />
        ),
        {
          width: '960px',
          title: title,
        },
      );
    } catch (error) {
      if (error.message !== 'user cancel') this.$message.error(error.message);
    }
  }

  refreshList() {
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.searchParams = {
      status: '',
      input: '',
    };
    this.getList();
  }

  columns = [
    {
      dataIndex: 'index',
      customRender: (text, row, index) => {
        return index + 1;
      },
      title: '序号',
      align: 'center',
      width: 80,
    },
    {
      title: '订阅计划',
      dataIndex: 'name',
      ellipsis: true,
      width: 400,
    },
    {
      dataIndex: 'state',
      title: '状态/版本',
      scopedSlots: { customRender: 'state' },
    },
    {
      dataIndex: 'dataSources',
      title: '订阅类型',
      customRender: val => {
        return val === 'PASSIVE' ? '被动触发' : '主动触发';
      },
    },
    {
      width: 200,
      dataIndex: 'createTime',
      title: '创建时间',
      customRender: val => {
        return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
      },
    },
    {
      width: 200,
      dataIndex: 'updateTime',
      title: '最后更新时间',
      customRender: val => {
        return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
      },
    },
    {
      width: 200,
      dataIndex: '',
      title: '操作',
      scopedSlots: { customRender: 'operate' },
    },
  ];
  tableLoading = false;
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };
  tableList = [];

  async getList() {
    const params = {
      platformId: this.id,
      size: this.pagination.pageSize,
      current: this.pagination.current,
    };
    if (this.searchParams.status) {
      params['state'] = this.searchParams.status;
    }
    if (this.searchParams.input) {
      params['input'] = this.searchParams.input;
    }
    this.tableLoading = true;
    try {
      const { records, size, current, total } = await getSubscribeList(params);
      this.tableList = records;
      this.pagination.current = current;
      this.pagination.total = total;
      this.pagination.pageSize = size;
      this.tableLoading = false;
    } catch {
      this.tableLoading = false;
      return false;
    }
  }

  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getList();
  }
}
</script>
<style lang="less" module>
.primaryColor {
  color: var(--primary);
}
.btns {
  color: var(--primary);
  cursor: pointer;
}
.logodiv {
  display: flex;
  align-items: center;
  .logo {
    width: 50px;
    height: 50px;
  }

  .logotext {
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.errorIcon {
  margin-left: 5px;
  color: var(--delete);
}

.buttonGroups {
  display: flex;
  justify-content: flex-start;

  .red {
    color: red;
  }

  .button {
    width: 50px;
    position: relative;

    .messageCount {
      position: absolute;
      font-size: 12px;
      top: 1px;
      right: 5px;
      height: 18px;
      width: 18px;
      line-height: 18px;
      border-radius: 50%;
      background: var(--font-active);
      color: var(--table-row);
      text-align: center;
    }
  }
}
</style>
