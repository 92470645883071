// @ts-check
import { BaseControl } from '../BaseControl';
import ConfigComponent from '../FnName/config.vue';
/** @name 功能标识 */
class FnIdentifierControl extends BaseControl {
  /** @readonly */
  type = 'fnIdentifier';

  label = 'controls.fnIdentifier.label';

  icon = 'tc-icon-font-line';

  trigger = 'blur';

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    identifier: 'identifier',
    required: true,
    alone: true, // 独立字段，不允许操作
  });

  FormComponent = 'x-input';

  DesignComponent = 'x-input';

  ConfigComponent = ConfigComponent;
}

export default new FnIdentifierControl();
