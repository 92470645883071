<template>
  <section>
    <section>
      <a-select
        :class="$style.queryWrap"
        v-model="query"
        style="width:130px"
        @change="handleChange"
      >
        <a-select-option value="">全部审批状态</a-select-option>
        <a-select-option value="PENDING_APPROVAL">待审批</a-select-option>
        <a-select-option value="PASSED">已通过</a-select-option>
        <a-select-option value="REJECTED">已驳回</a-select-option>
      </a-select>
    </section>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
    >
      <div :class="$style.timeWrap" slot="status" slot-scope="txt">
        <a-tag :color="statusColor[txt]">
          {{ STATUS_KEY[txt] }}
        </a-tag>
      </div>
      <div :class="$style.timeWrap" slot="createTime" slot-scope="txt, record">
        <span :class="$style.txt">{{ formatDate(txt) }}</span>
        <span :class="$style.txt">{{
          record.appraiseTime ? `(${formatDate(record.appraiseTime)})` : '(-)'
        }}</span>
      </div>
    </a-table>
  </section>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'moment';
import { getReleasehistory } from '@/services/iot-platform/releaseRecord';

@Component()
export default class Detail extends Vue {
  @Prop({ type: String }) id;

  query = '';
  handleChange(val) {
    this.query = val;
    this.pagination.current = 1;
    this.getReleasehistory();
  }

  STATUS_KEY = {
    UNPUBLISHED: '未发布',
    REJECTED: '已驳回',
    PENDING_APPROVAL: '待审核',
    PASSED: '已通过',
  };
  statusColor = {
    UNPUBLISHED: '#ccc',
    REJECTED: 'var(--delete)',
    PENDING_APPROVAL: 'var(--warning)',
    PASSED: 'var(--success)',
  };
  mounted() {
    this.getReleasehistory();
  }

  async getReleasehistory() {
    let option = {
      idxReleaseId: this.id,
      current: this.pagination.current,
    };
    if (this.query) {
      option.releaseStatus = this.query;
    }
    const { records, total } = await getReleasehistory(option);
    this.pagination.total = total;
    this.tableData = records;
  }

  formatDate(val, format = 'YYYY-MM-DD HH:mm:ss') {
    if (val) {
      return dayjs(val).format(format);
    }
    return val;
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => index + 1,
        title: this.$t('common.label.index'),
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        title: '发布内容',
        dataIndex: 'content',
      },
      {
        title: '审批状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' },
      },
      {
        title: '发布（审核）时间',
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' },
      },
      {
        title: '发布说明',
        dataIndex: 'directions',
      },
      {
        title: '审批意见',
        dataIndex: 'appraiseOpinion',
      },
    ];
  }

  tableData = [];

  loading = false;
  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => `${total} ` + this.$t('connector.dataTemplate.itmes'),
  };
  handlePagination() {}
}
</script>
<style lang="less" module>
.queryWrap {
  margin-bottom: 20px;
}
.timeWrap {
  .txt {
    display: block;
    height: 22px;
    line-height: 22px;
  }
}
</style>
