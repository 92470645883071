<template>
  <div :class="$style.addCode">
    <div :class="$style.title">
      {{ $t('inviteCode.unusedCodes') }}：{{ remainingAvailable }}
    </div>
    <a-form-model>
      <a-form-model-item :label="$t('inviteCode.addtions') + '：'">
        <a-radio-group v-model="count">
          <a-radio value="1">1</a-radio>
          <a-radio value="10">10</a-radio>
          <a-radio value="100">100</a-radio>
          <a-radio value="500">500</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
    <!-- <div :class="$style.footer">
      <a-button @click="$emit('handleClosed')">取消</a-button>
      <a-button type="primary" @click="addCode">确定</a-button>
    </div> -->
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { addInviteCode } from '@/services/tenant-center/invite-code';
import { tenantAddInviteCode } from '@/services/tenant-center/tenant-invite-code';

import { globalVueI18n } from '@triascloud/i18n';

function createModalAddCode(props, listener) {
  const addCodeModal = createFormModal(
    h =>
      h(AddCode, {
        props,
        on: {
          ...listener,
          handleClosed: () => {
            addCodeModal.handleClose();
          },
        },
      }),
    {
      title: globalVueI18n.translate('inviteCode.addCode'),
      width: 420,
      bodyStyle: {
        padding: 0,
      },
      wrapClassName: 'form-preview-drawer--wrapper',
    },
  );
  return addCodeModal;
}
@Component()
export default class AddCode extends Vue {
  @Prop({ type: Number, default: 0 }) remainingAvailable;
  static createModal = createModalAddCode;
  count = '1';
  async getValue() {
    this.$route.name === 'companyCode'
      ? await tenantAddInviteCode(this.count)
      : await addInviteCode(this.count);
    return this.count;
  }
}
</script>
<style lang="less" module>
.addCode {
  padding-left: 20px;
  padding-top: 20px;
  :global(.ant-radio-wrapper) {
    margin: 0 32px 0 0;
  }
}
.title {
  color: var(--font-info);
  margin-bottom: 10px;
}
</style>
