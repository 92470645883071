import { match } from '@triascloud/utils';
import Vue from 'vue';

/**
 * @name 将元素滚动到视图内
 * @param { Element } dom
 */
export function scrollIntoView(dom) {
  if (!dom) return;
  if (dom.scrollIntoViewIfNeed) {
    dom.scrollIntoViewIfNeed(true);
  } else if (dom.scrollIntoView) {
    dom.scrollIntoView({
      smooth: true,
      block: 'center',
      inline: 'center',
    });
  }
}

/**
 *
 * @param { any } obj
 * @param { string | string[] } path
 * @param { any } value
 */
export function vuePathSet(obj, path, value) {
  if (!isStructuralType(obj)) return obj;
  const pathArr = (Array.isArray(path) ? path : path.split('.')).filter(
    part => part || typeof part === 'number',
  );
  pathArr.reduce((current, part, index) => {
    if (isStructuralType(current[part])) return current[part];
    const nextPart = pathArr[index + 1];
    if (nextPart === undefined) {
      Vue.set(current, part, value);
    } else {
      Vue.set(current, part, match.isPureNumber(nextPart) ? [] : {});
    }
    return current[part];
  }, obj);
  return obj;
}

/** @type { (obj: unknown) => boolean } */
function isStructuralType(obj) {
  if (!obj) return false;
  const type = typeof obj;
  return type === 'object' || type === 'function';
}

/** @typedef { string | WrappedValue } WrappedValueOrOldValue  */

/** @typedef { { text: string, value: string, oldValue?: string } } WrappedValue  */

/**
 * @name 解包表单的值，用于从复合类型数据（下拉，单选，多选等）中取到value
 * @param { WrappedValueOrOldValue | WrappedValueOrOldValue[] } value
 * @param { boolean } useText
 */
export function unwrapValue(value, useText) {
  return Array.isArray(value)
    ? value.map(item => getInnerValue(item, useText)).filter(value => value)
    : getInnerValue(value, useText);
}

/**
 * @param { WrappedValueOrOldValue } value
 * @param { boolean } useText
 */
export function getInnerValue(value, useText) {
  return value && typeof value === 'object' && 'value' in value
    ? useText
      ? value.text
      : value.value
    : value;
}
