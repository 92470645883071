import request from '../request';
const serviceName = '/iot-platform/dataIdentifier';

/**@name 新增数据标识 */
export function insertData(data) {
  return request(`${serviceName}/insert`, {
    method: 'POST',
    body: data,
  });
}

/**@name 获取所有数据标识 */
export function getList(id) {
  return request(`${serviceName}/list/${id}`, {
    method: 'GET',
  });
}
/**@name 获取所有数据标识（部分字段） */
export function getDetailList(id) {
  return request(`${serviceName}/detailList/${id}`, {
    method: 'GET',
  });
}

/**@name 导出数据 */
export function exportData(id) {
  return request(`${serviceName}/export/${id}`, {
    method: 'GET',
  });
}

/**@name 导入数据 */
export function importData(id, data) {
  return request(`${serviceName}/import/${id}`, {
    method: 'POST',
    body: data,
  });
}

/**@name 获取数据标识 */
export function getData(id) {
  return request(`${serviceName}/${id}`, {
    method: 'GET',
  });
}

/**@name 修改数据标识 */
export function updateData(id, data) {
  return request(`${serviceName}/${id}`, {
    method: 'PUT',
    body: data,
  });
}

/**@name 删除数据标识 */
export function deleteData(id) {
  return request(`${serviceName}/${id}`, {
    method: 'DELETE',
  });
}
