<template>
  <div ref="footer" :class="$style.footer">
    <a-button @click="$emit('close')" ghost>{{
      $t('common.action.cancel')
    }}</a-button>
    <AsyncButton v-if="mode === 'add'" type="primary" :click="stashRes" ghost>{{
      $t('common.action.draft')
    }}</AsyncButton>
    <AsyncButton type="primary" :click="submit">{{
      $t('common.action.save')
    }}</AsyncButton>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import AsyncButton from '@/components/async-button';
import { crossStorageModule } from '@/enum/store';
import { deepClone } from '@triascloud/utils';

@Component({
  components: {
    AsyncButton,
  },
})
export default class EditCase extends Vue {
  @Prop({ type: Object }) habitService;
  @Prop({ type: Object }) formData;
  @Prop({ type: Object }) initParams;
  @Prop({ type: String }) mode;
  @Prop({ type: Object }) BasicFormModal;
  @Prop({ type: Array }) footerRequests;
  @Prop({ type: String }) type;
  @crossStorageModule.State tenant;

  // 提交
  submit() {
    this.$parent.$refs.basicFormModal.$refs.form.validate(async valid => {
      if (valid) {
        const params = deepClone(this.formData);
        if (this.type === 'news') {
          params.classifyId = [params.classifyId];
          if (params.type === 0) {
            delete params.otherUrl;
          }
        } else if (this.type === 'download') {
          params.classifyId = [params.classifyId];
          if (params.fileUrl && params.fileUrl.length) {
            params.fileUrl = JSON.stringify(params.fileUrl);
          }
        }
        params.label = params.label.map(item => item.pkId);
        params.pushTime = this.$moment(params.pushTime).valueOf();
        let res;
        if (this.initParams) {
          params.pkId = this.initParams.pkId;
          res = await this.footerRequests[1](params);
        } else {
          res = await this.footerRequests[0](params);
          this.habitService.setItem(
            `co.website.${this.type}.add.${this.tenant.pkId}`,
            '{}',
          );
        }
        res && this.$emit('refresh');
      } else return false;
    });
  }

  // 暂存
  async stashRes() {
    const res = this.habitService.setItem(
      `co.website.${this.type}.add.${this.tenant.pkId}`,
      JSON.stringify(this.formData),
    );
    res && this.$message.success(this.$t('common.tips.temporarySuccess'));
  }
}
</script>

<style lang="less" module>
// 底部按钮
.footer {
  border-top: 1px solid var(--border);
  display: flex;
  justify-content: right;
  align-items: center;
  height: 60px;
  padding: 20px 20px 15px;
  width: 100%;
  background-color: var(--modal-bg);
  z-index: 10;
  :global .ant-btn {
    margin-left: 20px;
  }
}
</style>
