<template>
  <div :class="$style.log">
    <a-table
      :columns="columns"
      :data-source="list"
      :pagination="pagination"
      :row-key="record => record.id"
      :loading="loading"
      @change="handlePagination"
    >
      <template slot="connectorName" slot-scope="text, record">
        <div :class="$style.tagColumn">
          <span>{{ text }}</span>
          <span>{{ record.connectorIdentifier }}</span>
        </div>
      </template>
      <template slot="inputParams" slot-scope="text">
        <div :class="$style.params">
          <div :class="$style.popText" :title="text">
            {{ text || '-' }}
          </div>
          <div v-if="text" :class="$style.copyBtn" @click="copyText(text)">
            {{ $t('inviteCode.copy') }}
          </div>
        </div>
      </template>
      <template slot="outputParams" slot-scope="text">
        <div :class="$style.params">
          <div :class="$style.popText" :title="text">
            {{ text }}
          </div>
          <div :class="$style.copyBtn" @click="copyText(text)">
            {{ $t('inviteCode.copy') }}
          </div>
        </div>
      </template>
    </a-table>
    <div :class="$style.tips">
      <span>{{ $t('iot.systemLog.pageTips', { val: realTotal }) }}</span>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import { clipboardWrite } from '@triascloud/utils';
import { apiLog } from '@/services/iot-platform/platform';
import { debounce } from 'lodash-decorators';
// import moment from 'moment';
// import InlineList from '@/components/inline-list';
@Component({
  components: { LayoutContent },
})
export default class ApiLog extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  apiParams;

  @Watch('apiParams', { deep: true, immediate: true })
  handleParamsChange() {
    this.pagination.current = 1;
    this.getList();
  }

  loading = false;

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => index + 1,
        title: this.$t('common.label.index'),
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        width: 200,
        dataIndex: 'callTime',
        title: this.$t('iot.systemLog.time'),
        customRender: val => {
          return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
        },
      },
      {
        dataIndex: 'connectorName',
        title: this.$t('iot.systemLog.connectors'),
        scopedSlots: { customRender: 'connectorName' },
      },
      {
        dataIndex: 'callingEnterpriseName',
        title: this.$t('iot.systemLog.companies'),
      },
      {
        width: 450,
        dataIndex: 'inputParams',
        title: this.$t('iot.systemLog.inputs'),
        scopedSlots: { customRender: 'inputParams' },
      },
      {
        width: 450,
        dataIndex: 'outputParams',
        title: this.$t('iot.systemLog.outputs'),
        scopedSlots: { customRender: 'outputParams' },
      },
    ];
  }

  list = [];
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => ` ${total + this.$t('connector.dataTemplate.itmes')}`,
  };
  realTotal = 0;
  @debounce(300)
  async getList() {
    const params = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    const { timeArr, keyword, apiName } = this.apiParams;
    if (timeArr.length) {
      params['startTime'] = this.$moment(timeArr[0]).valueOf();
      params['endTime'] = this.$moment(timeArr[1]).valueOf();
    }
    if (keyword) {
      params['keyword'] = keyword;
    }
    if (apiName) {
      params['apiId'] = apiName;
    }
    try {
      const { records, current, total, realTotal } = await apiLog(params);
      this.list = records.map((v, idx) => {
        return {
          ...v,
          id: idx,
        };
      });
      this.pagination.current = current;
      this.pagination.total = total;
      this.realTotal = realTotal || 0;
    } catch {
      return false;
    }
  }

  handlePagination(pagination) {
    this.pagination = pagination;
    this.getList();
  }

  async copyText(text) {
    await clipboardWrite(text);
    this.$message.success(this.$t('common.tips.copy'));
  }
}
</script>
<style lang="less" module>
.log {
  position: relative;

  .params {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .popText {
      max-width: 380px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }

    .copyBtn {
      color: var(--primary);
      cursor: pointer;
    }
  }

  .tagColumn {
    display: flex;
    flex-direction: column;

    span {
      line-height: 18px;
    }

    span:last-child {
      color: var(--font-info);
    }
  }

  .tips {
    position: absolute;
    bottom: 10px;
    color: var(--primary);
  }
}
</style>
