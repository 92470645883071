<template>
  <a-form-model ref="form" :model="formData" :rules="rules">
    <a-form-model-item label="分类名称" prop="groupName">
      <a-input v-model="formData.groupName"></a-input>
    </a-form-model-item>

    <a-form-model-item
      label="分类封面图"
      ref="imageForm"
      prop="cover"
      :rules="[
        {
          message: $t('solution.selectPicture'),
          required: true,
        },
      ]"
    >
      <input v-model="formData.cover" :class="$style.solutionInput" />
      <x-upload-button
        :maxFileSize="5"
        uploadPath="common/temp/"
        @success="
          res => {
            $refs.imageForm.FormContext.clearValidate('cover');
            this.formData.cover = res.ossData.ossPath;
          }
        "
      >
        <div :class="[$style.solutionBgDetail]">
          <div :class="$style.addIcon" v-if="!formData.cover">
            <x-icon type="plus"></x-icon>
            <div>点击上传图片，不超过5m</div>
          </div>

          <x-oss-image
            :oss-path="formData.cover"
            :class="$style.image__img"
            v-else
          />
          <div :class="$style.changePic" v-if="formData.cover">
            <div :class="$style.coverAction">
              <div :class="$style.delIcon" @click.stop="formData.cover = null">
                <x-icon type="tc-icon-delete"></x-icon>
                删除
              </div>
              <div :class="$style.changeIcon">
                <x-icon type="edit"></x-icon>
                替换
              </div>
            </div>
          </div>
        </div>
      </x-upload-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';
import { clone } from 'lodash';

function createAddType(props) {
  return createFormModal(h => h(AddType, { props }), {
    title: globalVueI18n.translate('kit.addType'),
    width: 440,
  });
}
@Component()
export default class AddType extends Vue {
  @Prop({ type: Object, default: null }) data;

  static create = createAddType;
  formData = {
    cover: '',
    groupName: '',
  };

  value = {
    image: '',
  };

  created() {
    this.formData = this.data ? clone(this.data) : this.formData;
  }

  get rules() {
    return {
      groupName: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入分类名称',
        },
      ],
    };
  }

  async getValue() {
    await this.$refs.form.validate();
    return this.formData;
  }
}
</script>
<style lang="less" module>
.solutionInput {
  opacity: 0;
  height: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.solutionBgDetail {
  height: 225px;
  width: 400px;
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  text-align: center;
  &:hover {
    .changePic {
      display: flex;
    }
  }
  .image {
    width: 415px;
    height: 100%;
  }
  .image__img {
    height: 100%;
    width: 100%;
  }
  .addBtn {
    color: var(--font-color-secondary);
    display: flex;
    flex-direction: column;
  }
}

.delIcon {
  cursor: pointer;
}

.changeIcon {
  cursor: pointer;
}

.changePic {
  background-color: #000;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  border-radius: 6px;
  button i {
    font-size: 40px;
    color: #ccc;
  }
  button {
    color: var(--block-bg);
  }
}

.addIcon {
  color: var(--font-info);
  i {
    font-size: 40px;
  }
}

.coverAction {
  display: flex;
  justify-content: space-between;
  color: #fff;
  width: 100%;
  margin: 0 135px;
}
</style>
