<template>
  <div :class="$style.wrap">
    <a-form-model :class="$style.model" layout="inline">
      <a-form-model-item>
        <a-input
          v-model="searchData.destVersion"
          placeholder="升级包版本号"
          @input.stop="() => search()"
          style="width: 220px;"
          allow-clear
        >
          <a-icon @click="() => search()" slot="suffix" type="search" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item style="margin-right: 0;">
        <a-button
          type="primary"
          size="small"
          @click="addGrade"
          :class="$style.button"
        >
          添加升级包
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div :class="$style.tableWrap">
      <a-table
        :columns="columns"
        :data-source="data"
        :row-selection="rowSelection()"
        :scroll="{ x: 1000, y: 300 }"
        :pagination="pagination"
        :loading="loading"
        @change="showSizeChange"
        ref="table"
      >
        <template slot="type" slot-scope="type">
          <div>{{ type_package[type] }}</div>
        </template>
        <template slot="destVersion" slot-scope="text, record">
          {{ text }}
          <a-tooltip placement="right">
            <template slot="title">
              <div>差分待升级版本号：</div>
              <div>
                {{ record.srcVersion }}
              </div>
            </template>
            <a-icon
              type="question-circle"
              v-if="record.type === 'PART_PACKAGE'"
            />
          </a-tooltip>
        </template>
        <template slot="operation" slot-scope="record">
          <a
            v-if="$p.action('UPDATE')"
            style="margin-right: 10px;"
            @click="deleteVersion(record)"
            >升级记录</a
          >
          <a v-if="$p.action('UPDATE')" @click="deleteVersion(record)">删除</a>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { Vue, Prop, Component } from 'vue-property-decorator';
import { reqOtaList } from '@/services/hat/ota.js';
import { Throttle } from 'lodash-decorators';
const type_package = {
  FULL_PACKAGE: '整包',
  PART_PACKAGE: '差分包',
};
const status_package = {
  '-1': '不需要验证',
  0: '未验证',
  1: '已验证',
  2: '验证中',
  3: '验证失败',
};
@Component({
  components: {},
})
export default class setFirmware extends Vue {
  @Prop({ type: Object, default: () => {} }) record;
  type_package = type_package;
  data = [];
  searchData = {
    destVersion: undefined,
  };
  loading = false;
  selectedRow = {};
  pagination = {
    pageSize: 10,
    current: 1,
    total: 1,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `总数 ${total} `,
  };
  created() {
    this.addGrade = this.addGrade.bind(this);
    this.deleteVersion = this.deleteVersion.bind(this);
  }
  search() {
    this.pagination.current = 1;
    this.getOTAlist();
  }
  mounted() {
    this.getOTAlist();
  }
  async getOTAlist() {
    try {
      this.loading = true;
      const { current, pageSize: size } = this.pagination;
      const { destVersion } = this.searchData;
      const result = await reqOtaList({ current, size, destVersion });
      this.pagination.total = result.total;
      this.data = result.records.map(item => {
        let { status, createTime } = item;
        status = status_package[status];
        createTime = this.$moment(createTime).format('YYYY-MM-DD HH:mm:ss');
        return { ...item, status, createTime };
      });
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }
  get columns() {
    return [
      {
        title: '升级包名称',
        width: '150px',
        dataIndex: 'firmwareName',
        ellipsis: true,
      },
      {
        title: '升级包类型',
        width: '120px',
        dataIndex: 'type',
        scopedSlots: { customRender: 'type' },
      },
      {
        title: '升级包版本号',
        dataIndex: 'destVersion',
        width: '320px',
        scopedSlots: { customRender: 'destVersion' },
      },
      {
        title: '状态',
        dataIndex: 'status',
        width: '120px',
        ellipsis: true,
      },
      {
        title: '添加时间',
        dataIndex: 'createTime',
        width: '180px',
        ellipsis: true,
      },
      {
        title: '操作',
        width: '150px',
        scopedSlots: { customRender: 'operation' },
      },
    ];
  }
  @Throttle(1500)
  addGrade() {
    this.$message.warn('规划功能! 请到对应环境的阿里云IOT平台上传固件!');
  }
  @Throttle(1500)
  deleteVersion() {
    this.$message.warn('功能开发中,即将上线!');
  }
  showSizeChange(page) {
    this.pagination.current = page.current;
    this.pagination.pageSize = page.pageSize;
    this.getOTAlist();
  }
  rowSelection() {
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        this.selectedRow = selectedRows[0];
      },
      hideDefaultSelections: true,
      type: 'radio',
    };
  }
}
</script>
<style lang="less" module>
.wrap {
  .model {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    .button {
      height: 34px;
    }
  }
  .tableWrap {
    height: 360px;
  }
}
</style>
