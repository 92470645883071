<template>
  <div :class="$style.wrap">
    <ElectronFence ref="topData" :updateTable="updateTable" />
    <HatTable ref="hatTable" :update="update" />
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import ElectronFence from './components/electron-fence/index.vue';
import HatTable from './components/hat-table/index.vue';
@Component({
  components: {
    ElectronFence,
    HatTable,
  },
})
export default class HatDevice extends Vue {
  update() {
    this.$refs.topData.getList();
  }
  updateTable() {
    this.$refs.hatTable.getList();
  }
}
</script>
<style lang="less" module>
.wrap {
  padding: 20px;
}
</style>
