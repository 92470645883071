<template>
  <div :class="$style.TemplateStatistics">
    <switch-tab></switch-tab>
    <header-list :data="data"></header-list>
    <div :class="$style.chart">
      <div :class="$style.chart_row">
        <ManageChartsWrap
          v-for="item in dataList"
          :key="item.key"
          :title="item.title"
          :isEmpty="item.data.length"
          :loading="loading"
          :class="$style.chart_wrap"
          :timeOptionsProp="timeOptionsProp"
        >
          <template v-slot="{ selectTime }">
            <Component
              :class="$style.chart_item"
              :is="item.component"
              :selectTime="selectTime"
              :data="item.data[selectTime]"
              :key="selectTime"
              v-if="item.hasData(item.data[selectTime])"
            />
            <EmptyContent
              type="list"
              :class="$style.chart_item"
              v-if="!item.hasData(item.data[selectTime]) && !loading"
            />
          </template>
        </ManageChartsWrap>
        <NodeDetailsTable />
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import HeaderList from './components/header-list.vue';
import ManageChartsWrap from '@/views/solution/components/manage-charts-wrap.vue';
import EmptyContent from '@/components/empty-content';
import NodeDetailsTable from './components/node-details-table.vue';
import PluginRelationCharts from '@/views/plugin/data/components/plugin-relation-charts.vue';
import PluginCountCharts from '@/views/plugin/data/components/plugin-count-charts.vue';
import PluginCharts from '@/views/plugin/data/components/plugin-charts.vue';
import { getDbServiceByType } from '@/services/template-manager/plugin';
import SwitchTab from '@/views/plugin/components/switch-tab.vue';
import { generateMap } from '@triascloud/utils';

@Component({
  components: {
    SwitchTab,
    HeaderList,
    ManageChartsWrap,
    EmptyContent,
    NodeDetailsTable,
    PluginCountCharts,
    PluginCharts,
    PluginRelationCharts,
  },
})
export default class Data extends Vue {
  data = {};

  get timeOptionsProp() {
    return [
      {
        text: this.$t('common.label.all'),
        value: 'ALL',
      },
      {
        text: this.$t('common.week'),
        value: 'WEEK',
      },
      {
        text: this.$t('common.month'),
        value: 'MONTH',
      },
      {
        text: this.$t('common.year'),
        value: 'YEAR',
      },
    ];
  }

  initData() {
    return this.allData && this.allData.length ? this.allData : [];
  }
  loading = false;
  created() {
    this.getData();
  }
  allData = [];
  async getData() {
    this.loading = true;
    const res = await getDbServiceByType(this.dbType);
    this.data = res.baseStatistics;
    this.allData = res.rankVOS.map(item => {
      const { tenantConfigVOS, timeType } = item;
      return {
        ...item,
        data: tenantConfigVOS,
        timeType,
      };
    });

    this.loading = false;
  }

  get dbType() {
    return this.$route.path === '/plugin/api' ? '3' : '2' || '3';
  }
  get dataList() {
    return [
      {
        key: 'identityLeaderboard',
        component: PluginCharts,
        title: this.$t('plugin.overview.list'),
        hasData: data => data && data.data && data.data.length,
        data: generateMap(this.allData, 'timeType', item => item),
      },
      {
        key: 'identifyRegistration',
        component: PluginCountCharts,
        title: this.$t('plugin.overview.config'),
        hasData: data => data && data.data && data.data.length,
        data: generateMap(this.allData, 'timeType', item => item),
      },
      {
        key: 'identifyAnalysis',
        component: PluginRelationCharts,
        title: this.$t('plugin.overview.associated'),
        hasData: data => data && data.data && data.data.length,
        data: generateMap(this.allData, 'timeType', item => item),
      },
    ];
  }

  @Watch('$route')
  async handleRouteChange() {
    await this.getData();
  }
}
</script>
<style lang="less" module>
.TemplateStatistics {
  padding-bottom: 20px;
  width: 100%;
  min-width: 1500px;
}
.chart_row {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}
.chart {
  width: 100%;
}

.chart_item {
  height: 390px;
  padding-top: 10px;
}
.chart_wrap {
  margin-bottom: 20px;
  border-radius: 1px;
  width: calc(33.33% - 13.3px);

  &:nth-child(3n-1) {
    margin: 0 20px;
  }
}
</style>
