import request from '../request';
const basePath = '/resource/cloud-dictionary';

export const getDictionaryList = report =>
  request(`${basePath}/list`, {
    method: 'POST',
    body: report,
  });

export const getDictionaryRecycle = report =>
  request(`${basePath}/listRecycleBin`, {
    method: 'POST',
    body: report,
  });

export const getDictionaryDetail = pkId =>
  request(`${basePath}/detail/${pkId}`, {
    body: { pkId },
  });

export const getCheckDictionaryDetail = pkId =>
  request(`${basePath}/check/detail/${pkId}`, {
    body: { pkId },
  });

export const updateDictionary = data =>
  request(`${basePath}/update`, {
    method: 'PUT',
    body: data,
  });

export const checkDictionary = data =>
  request(`${basePath}/check`, {
    method: 'PUT',
    body: data,
  });

export const batchCheckDictionary = data =>
  request(`${basePath}/checkBatch`, {
    method: 'PUT',
    body: data,
  });

export const getDictionaryCheckList = report =>
  request(`${basePath}/checkList`, {
    method: 'POST',
    body: report,
  });

export const deleteDictionary = data =>
  request(`${basePath}/deleted`, {
    method: 'PUT',
    body: data,
  });

export const regainDictionary = data =>
  request(`${basePath}/regain`, {
    method: 'PUT',
    body: data,
  });

export const removeDictionary = dictIds =>
  request(`${basePath}/remove`, {
    method: 'POST',
    body: dictIds,
  });

export const removeRecycleBinDictionary = dictIds =>
  request(`${basePath}/removeByRecycleBin`, {
    method: 'POST',
    body: dictIds,
  });

export const batchRemoveDictionary = dictIds =>
  request(`${basePath}/removeBatch`, {
    method: 'POST',
    body: dictIds,
  });

export const dictionaryCount = () => request(`${basePath}/count`);
