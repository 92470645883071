// @ts-check
import { BaseControl } from '../BaseControl';
// @ts-ignore
import ConfigComponent from './config.vue';
// @ts-ignore
import DesignComponent from './design.vue';
// @ts-ignore
import { textFormat } from '../utils';
import { TextDefault } from '../enum';
// import { generateRangeRule, generateUniqueRule } from '../validate';

/** @name 单行文本 */
class InputControl extends BaseControl {
  /** @readonly */
  type = 'text';

  label = 'controls.input.label';

  icon = 'tc-icon-font-line';

  trigger = 'blur';

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    defaultValue: {
      // 单行文本默认值类型
      type: 'custom',
      // 默认值
      custom: '',
    },
    defaultVal: '',
    identifier: 'TEXT',
    inParams: ['DEVELOPMENT'],
    outParams: [],
    // 最大长度
    maxLength: 10240,
    description: '当前文本最大为10240字节',
    defaultType: TextDefault.MANUAL,
    ruleType: undefined,
    ruleLength: '',
    variableId: undefined,
  });

  // @ts-ignore
  props = field => ({
    maxLength: field.widget.max || undefined,
  });

  // @ts-ignore
  // rules = (field, root) => [
  //   generateRangeRule(field),
  //   generateUniqueRule(field, root),
  // ];

  /**
   * @name 字段特征定义
   * @type { object | ((field: import('../BaseControl').Field) => object) }
   * @returns  { object }
   */
  feature = field => ({
    WRITABLE: !field?.widget?.view,
    REQUIRED: !field?.widget?.view,
    PRINTABLE: true,
    BRIEFING: true,
    sorter: 'order',
  });

  FormComponent = DesignComponent;

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;

  format = textFormat;
}

export default new InputControl();
