<template>
  <div class="x-inline-list">
    <slot v-if="!headerList || !headerList.length" name="empty" />
    <a-tooltip
      v-for="(item, index) in headerList"
      :title="item[name]"
      :key="index"
    >
      <slot :row="item" :index="index">
        <file-icon
          :url="item[path]"
          class="x-inline-list-icon"
          @click.native.stop="preview(index)"
        />
      </slot>
    </a-tooltip>
    <a-dropdown
      :overlay-class-name="`x-inline-list-dropdown ${overlayClassName}`"
      v-if="list && list.length > limit"
      :trigger="['hover']"
      placement="bottomLeft"
      v-model="visible"
      :disabled="disabled"
    >
      <div class="x-inline-list-dropdown-badge" @click="handleBadgeClick">
        {{ list.length }}
      </div>
      <a-menu slot="overlay" class="x-inline-list-dropdown-menu">
        <a-menu-item v-for="(item, index) in list" :key="index">
          <slot :row="item" :index="index">
            <div class="x-inline-list-dropdown-item" @click="preview(index)">
              <file-icon
                :url="item[path]"
                @click.native.stop="preview(index)"
              />
              <x-ellipsis
                @click="preview(index)"
                class="x-inline-list-dropdown-item__name"
                placement="right"
              >
                {{ item[name] }}
              </x-ellipsis>
            </div>
          </slot>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import FileIcon from '@/components/file-icon';

/** @todo 需要重新封装，允许容器内使用自定义组件渲染 */
@Component({ components: { FileIcon } })
export default class InlineList extends Vue {
  @Prop({ type: Array, default: () => [] }) list;
  @Prop({ type: Number, default: 3 }) limit;
  @Prop({ type: String, default: '' }) overlayClassName;
  @Prop({ type: Boolean, default: false }) disabled;
  @Prop({ type: Object, default: () => ({ path: 'ossPath', name: 'name' }) })
  props;

  visible = false;

  get headerList() {
    return this.list && Array.isArray(this.list)
      ? this.list.filter((_item, index) => index < this.limit)
      : [];
  }
  get path() {
    return this.props.path || 'ossPath';
  }
  get name() {
    return this.props.name || 'name';
  }

  handleBadgeClick(ev) {
    if (!this.disabled) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }

  preview(index) {
    this.visible = false;
    this.$preview({
      urls: this.list.map(item => ({
        name: item[this.name],
        url: item[this.path],
      })),
      index,
    });
  }
}
</script>
<style lang="less">
.x-inline-list {
  display: flex;
  align-items: center;
}
.x-inline-list-dropdown-badge {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border: 1px solid;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  color: var(--font-sub);
  &:hover {
    color: var(--primary);
  }
}

.x-inline-list-icon {
  margin-left: 8px;
  &:first-child {
    margin-left: 0;
  }
}
.x-inline-list-dropdown-overlay {
  background-color: var(--dropdown-bg);
  box-shadow: var(--dropdown-shadow);
}
.x-inline-list-dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}
.x-inline-list-dropdown-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.x-inline-list-dropdown-item__name {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  margin-left: 10px;
}
</style>
