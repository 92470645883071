import request from '../request';
const serviceName = '/iot-platform/category';

/**@name 新增品类 */
export function insertCategory(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}

/**@name 编辑品类 */
export function updateCategory(data) {
  return request(`${serviceName}`, {
    method: 'PUT',
    body: data,
  });
}

/**@name 删除品类 */
export function deleteCategory(id) {
  return request(`${serviceName}/${id}`, {
    method: 'DELETE',
  });
}

/**@name 获取品类列表 */
export function getCategoryList(data) {
  return request(`${serviceName}/page`, {
    method: 'GET',
    body: data,
  });
}

/**@name 获取品类子集列表 */
export function getCategorySubList(data) {
  return request(`${serviceName}/list`, {
    method: 'GET',
    body: data,
  });
}
