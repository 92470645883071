<template>
  <a-form-model layout="vertical" :model="form" ref="form">
    <a-form-model-item prop="production">
      <span slot="label">
        <span>{{ $t('connector.dpManage.cProductCategory') }}</span>
        <span style="color: var(--delete)">
          （{{ $t('connector.dpManage.DPTips1') }}！）
        </span>
      </span>
      <selectProduct
        :platformId="platformId"
        style="width:300px"
        v-model="form.production"
        :showManage="false"
      />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import SelectProduct from '@/views/connector/platform-manage/develop/device-manage/device-form/select-product.vue';

@Component({
  components: { SelectProduct },
})
export default class DownloadDpform extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  form = {
    production: undefined,
  };

  getValue() {
    return this.form.production;
  }
}
</script>

<style scoped lang="less"></style>
