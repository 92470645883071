import request from '../request';
export const basePath = '/template-manager/templateChecker';

/**
 * @name 获取审核列表
 * @param {{ current: number; size: number; checkState: number; templateType: number; }} report 筛选分页
 * @returns Promise<{ records: any[]; total: number; }>
 */
export function getList(report) {
  return request(`${basePath}/loadApprovalRequestForm`, {
    method: 'POST',
    body: report,
  });
}

/**
 * @name 审核
 * @param {{advice: string;checkResult: 0|1;classify: string[];requestId: string; }} report
 * @returns Promise<any>
 */
export function review(report) {
  return request(`${basePath}/processApprovalRequestForm`, {
    method: 'POST',
    body: report,
  });
}

/**
 * @name 获取审核结果
 * @param {string} id
 * @returns Promise<{ result: 0|1; advice: string }>
 */
export const getResult = id => request(`${basePath}/checkResult/${id}`);

/** @name 获取用户提交的模板信息 */
export const getReviewDetail = id =>
  request(`${basePath}/templateInfoWithUserSetting/${id}`);
