<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item prop="deviceCode">
      <a-input v-model="form.deviceCode" placeholder="请输入设备码"></a-input>
    </a-form-model-item>
    <p :class="$style.red">
      提醒：将会从已绑定企业和当前列表彻底删除此设备，设备的订阅数据不受影响
    </p>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component()
export default class DeleteDevice extends Vue {
  @Prop({ type: String }) code;
  form = {
    deviceCode: '',
  };
  validatorCode(rule, value, cb) {
    if (value === '') {
      cb('不能为空！');
    } else if (value !== this.code) {
      cb('设备码不一致！');
    } else {
      cb();
    }
  }
  rules = {
    deviceCode: [{ validator: this.validatorCode, trigger: 'blur' }],
  };

  async getValue() {
    await this.$refs.form.validate();
    return true;
  }
}
</script>
<style lang="less" module>
.red {
  font-size: 12px;
  color: #f45454;
  padding-top: 10px;
}
</style>
