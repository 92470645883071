<template>
  <div class="appList">
    <x-input v-model="search" class="appList_search" v-if="result">
      <x-icon type="tc-icon-search" slot="prefix" />
    </x-input>
    <div class="appBox">
      <label
        v-for="item in result"
        :key="item.value"
        class="appList__item appList__item--checkbox"
        ><span class="appList__label">{{ item.text }}</span
        ><a-checkbox
          @change="select($event.target.checked, item)"
          :checked="computedChecked(item)"
          :disabled="!value.includes(item) && value.length >= 20"
        ></a-checkbox>
      </label>
    </div>
    <empty-content type="list" v-if="!result" />
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content';

@Component({
  components: {
    EmptyContent,
  },
})
export default class AppList extends Vue {
  @Prop({ type: Array }) data;
  @Prop({ type: Array, default: () => [] }) value;

  /**
   * @name 选中、取消选中
   */
  search = null;
  select(checked, row) {
    if (checked) {
      this.$emit('input', [...this.value, row]);
    } else {
      this.$emit(
        'input',
        this.value.filter(item => row.value !== item.value),
      );
    }
  }
  get result() {
    if (!this.search) {
      return this.data;
    } else {
      return this.data.filter(item => item.text.indexOf(this.search) !== -1);
    }
  }

  computedChecked(row) {
    return this.value.some(item => row.value === item.value);
  }
}
</script>
<style lang="less">
.appList {
  height: 100%;
}
.appList_search {
  position: absolute;
  top: 40px;
  left: 0;
  padding: 10px;
  z-index: 2;
  background: var(--modal-bg);
  height: 42px;
  .ant-input-prefix {
    top: 25px;
    left: 20px;
  }

  input {
    border-radius: 0px;
  }
}
.appBox {
  padding-top: 45px;
}
.appList__item {
  height: 40px;
  line-height: 40px;
  color: var(--font);
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
}
.appList--checkgroup {
  flex: 1;
}
.appList__item--active,
.appList__item:hover {
  background: var(--list-hover);
  color: var(--font-active);
}
.appList__item--empty {
  margin: 40px 0 0;
  svg {
    max-width: 100%;
  }
}
.appList__item--checkbox {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}
.appList__label {
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
