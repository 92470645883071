// @ts-check
import { globalVueI18n } from '@triascloud/i18n';
import { uuid, ensureArray, deepClone, generateMap } from '@triascloud/utils';
import { ControlMap, ControlTypeEnum, WrappedValueControls } from './enum.js';
import moment from 'moment';
import { ControlValueTypeEnum } from './BaseControl';
import { generateFilterGroup } from '../form-compare/compare';
import { cloneDeep } from 'lodash';

/** @typedef { keyof ControlMap } ControlType */
/** @typedef { import('./BaseControl').Field } Field */

/**
 * @name 根据type获取对应的Control
 * @type { (type: ControlType) => ControlMap[ControlType] }
 */
export function getControl(type) {
  if (!ControlMap[type]) {
    throw new Error(`Control [${type}] not found`);
  }
  return ControlMap[type];
}

/**
 * @name 根据type和metaName获取对应的控件元信息
 * @param { ControlType } type
 * @param { keyof ControlMap[ControlType] } metaName
 */
export function getControlMeta(type, metaName) {
  return getControl(type)[metaName];
}

/**
 * @name 根据type创建对应的Field
 * @param { keyof ControlMap } type
 * @returns { Field }
 */
export function createField(type) {
  const Control = getControl(type);
  return {
    // @ts-ignore
    name: globalVueI18n.translate(Control.label),
    pkId: Control.static ? type : uuid(),
    type: Control.type,
    icon: Control.icon,
    widget: Control.widget(),
  };
}

/**
 * @name 克隆Field
 * @type { (field: Field ) => Field }
 */
export function cloneField(field) {
  const newField = deepClone(field);
  newField.pkId = uuid();
  return newField;
}

/**
 * @param { Field } field
 * @returns { Field[] }
 */
export function getChildrenFields(field) {
  if (!field) return [];
  switch (field.type) {
    case ControlTypeEnum.Tabs:
    case ControlTypeEnum.CustomParam:
      // @ts-ignore
      return ensureArray(field.widget.tabs)
        .flatMap(tab => tab.fields)
        .filter(field => field);
    default:
      return [];
  }
}

/**
 * @name 根据字段id在字段中查找父级子表单字段
 * @param { string } currentFieldId
 * @param { Field[] } fields
 * @param { { layout?: boolean, subform?: boolean }= } options 是否显示布局父级
 * @returns { Field[] }
 */
export function findFieldParents(
  currentFieldId,
  fields,
  options = { layout: false, subform: true },
) {
  /** @type { Field[] } */
  let parents = [];
  for (const field of fields) {
    if (currentFieldId === field.pkId) break;
    const children = getChildrenFields(field);
    if (!children.length) continue;
    if (children.find(f => f.pkId === currentFieldId)) {
      switch (field.type) {
        case ControlTypeEnum.Tabs:
        case ControlTypeEnum.CustomParam:
          options.layout && parents.push(field);
          break;
        default:
          parents.push(field);
      }
    } else {
      parents = parents.concat(
        findFieldParents(currentFieldId, children, options),
      );
    }
  }
  return parents;
}

/**
 * @param { string } fieldId
 * @param { Record<string, string> } map
 */
export function getFieldTagClass(fieldId, map) {
  if (!map) return {};
  return {
    'field-tag--clone': map[fieldId] === 'clone',
    'field-tag--new': map[fieldId] === undefined,
    'field-tag--old': map[fieldId] === 'old',
  };
}

/**
 * @name 扁平化字段列表
 * @param { Field[] } fields 字段列表
 * @param { { parent?: boolean; subform?: boolean; layout?: boolean; param?: boolean | 'only' } } options 选项
 * @description options.parent 是否包含父级字段（一行两列，分页卡，子表单），默认不包含
 * @description options.layout 是否包含布局字段（分组标题，描述信息，表单基础资料），默认不包含
 * @description options.subform 是否展开子表单字段（展开子表单时会默认过滤掉子表单字段本身），默认不展开
 * @description options.param 是否包含参数字段，默认包含，设置为字符串'only'可以只包含参数字段
 * @returns { Field[] }
 */
export function flatFields(
  fields,
  options = { subform: false, layout: false, parent: false, param: true },
) {
  if (typeof options === 'boolean') {
    options = { subform: options, layout: false, parent: false, param: true };
  }
  return ensureArray(fields).reduce((
    /** @type { Field[] } */ result,
    field,
  ) => {
    const param = field.widget.param || false;
    if (options.param === false) {
      if (param) return result;
    } else if (options.param === 'only') {
      if (!param && !getChildrenFields(field).length) return result;
    }
    switch (field.type) {
      // case ControlTypeEnum.GroupTitle:
      // case ControlTypeEnum.Tips:
      // case ControlTypeEnum.BaseInfo:
      //   return options.layout ? result.concat(field) : result;
      case ControlTypeEnum.Tabs:
        if (options.parent) result.push(field);
        return result.concat(flatFields(getChildrenFields(field), options));
      default:
        return result.concat(field);
    }
  }, []);
}

/**
 * @template T
 * @typedef { Record<string, T> | ((key: string) => T) } MapOrGetFn
 */

/** @typedef { MapOrGetFn<Field | null> } FieldMapOrGetFieldFn */

/**
 * @name 根据字段配置获取默认值
 * @param { Field } field
 */
export function getFieldDefaultValue(field) {
  if (!field?.widget?.defaultValue) return null;
  switch (field.widget.defaultValue.type) {
    case 'custom':
      return deepClone(field.widget.defaultValue.custom);
    case 'current':
      return moment().valueOf();
    default:
      return null;
  }
}

/**
 * @param { string[] | null | string } value
 * @returns { string }
 */
export function textFormat(value) {
  if (value === 'notFilled')
    return globalVueI18n.translate('controls.radio.unfilled').toString();
  if (value == null) return '';
  if (Array.isArray(value)) {
    return value
      .map(item => textFormat(item))
      .filter(item => item)
      .join(',');
  }
  return value;
}
/**
 * @param { string | number | Array<string | number>} value
 * @param { Field } field
 * @return { string }
 */
export function dateFormat(value, field) {
  if (!value) return '';
  if (Array.isArray(value))
    return value.map(item => dateFormat(item, field)).join('~');
  const date = moment(value);
  return date.isValid()
    ? // @ts-ignore
      date.format(normalizeFormat(field.widget.format))
    : '';
}
/**
 * @param { string } format
 */
function normalizeFormat(format) {
  return (format || 'YYYY-MM-DD')
    .replace(/YYYY-MM-DD/gi, $0 => $0.toUpperCase())
    .replace(/mm:ss/gi, $0 => $0.toLowerCase());
}

/** @typedef { string | WrappedValue } WrappedValueOrOldValue  */

/** @typedef { { text: string, value: string, oldValue?: string } } WrappedValue  */

/**
 * @name 解包表单的值，用于从复合类型数据（下拉，单选，多选等）中取到value
 * @param { WrappedValueOrOldValue | WrappedValueOrOldValue[] } value
 * @param { boolean } useText
 */
export function unwrapValue(value, useText) {
  return Array.isArray(value)
    ? value.map(item => getInnerValue(item, useText)).filter(value => value)
    : getInnerValue(value, useText);
}

/**
 * @param { WrappedValueOrOldValue } value
 * @param { boolean } useText
 */
export function getInnerValue(value, useText) {
  return value && typeof value === 'object' && 'value' in value
    ? useText
      ? value.text
      : value.value
    : value;
}

/**
 * @param { string | string[] | WrappedValue | WrappedValue[] } value
 * @param { WrappedValue[]= } list 如果传入list，则会根据list查找value最新的text，如果不传入则直接将value作为text返回
 * @returns { WrappedValue | WrappedValue[] | null }
 */
export function wrapValue(value, list) {
  if (Array.isArray(value)) {
    // @ts-ignore
    return value.map(item => wrapValue(item, list)).filter(result => result);
  }
  const item =
    typeof value === 'string'
      ? {
          text: value,
          value,
        }
      : value;
  if (!list || !item) return item;
  const currentValue = item.value;
  const result = list.find(
    // 新值优先
    item => item.value === currentValue || item.oldValue === currentValue,
  );
  if (!result) return item;
  return {
    text: result.text,
    value: result.value,
  };
}

/**
 * @param { any } value
 * @param { Field } fromField
 * @param { Field } toField
 * @param { import('./FormatContext').FormatContext } formatContext
 * @returns { Promise<any> }
 */
export async function transformValue(value, fromField, toField, formatContext) {
  if (value == null || value.length === 0) return null;
  const toFieldType = getFieldValueType(toField);
  if (toFieldType === ControlValueTypeEnum.Text) {
    const text = await formatContext.format(value, fromField);
    if (WrappedValueControls.includes(toField.type)) {
      if (toFieldType === getFieldValueType(fromField)) {
        return deepClone(value);
      } else {
        const { multiple } = toField.widget;
        return wrapValue(multiple ? text.split(',').filter(e => e) : text);
      }
    } else {
      return text;
    }
  } else if (toFieldType === getFieldValueType(fromField)) {
    return deepClone(value);
  } else {
    return null;
  }
}

/**
 * @name 根据field和metaName获取对应的控件元信息
 * @param { Field } field
 * @param { keyof ControlMap[ControlType] } metaName
 */
export function getFieldMeta(field, metaName) {
  if (!field) return null;
  const meta = getControlMeta(field.type, metaName);
  if (!meta) return null;
  return typeof meta === 'function'
    ? // @ts-ignore
      meta.call(getControl(field.type), field)
    : meta;
}
/**
 * @param { Field } field
 */
export function getFieldValueType(field) {
  return getFieldMeta(field, 'valueType');
}

/**
 * @template T
 * @param { MapOrGetFn<T> } fn
 * @param { string } key
 * @returns { T }
 */
const fnGet = (fn, key) => (typeof fn === 'function' ? fn(key) : fn[key]);

/**
 *
 * @param { import('./BaseControl').WidgetDataFill[] | undefined } dataFill
 * @param { FieldMapOrGetFieldFn } getFromField
 * @param { MapOrGetFn<any> } getFromValue
 * @param { FieldMapOrGetFieldFn } getToField
 * @param { import('.').FormatContext } formatContext
 * @param { (rule: import('./BaseControl').WidgetDataFill, toValue: any) => void } callback
 */
export function execDataFill(
  dataFill,
  getFromField,
  getFromValue,
  getToField,
  formatContext,
  callback,
) {
  if (!dataFill) return Promise.resolve();
  return Promise.all(
    dataFill.map(async rule => {
      const fromField = fnGet(getFromField, rule.from);
      const toField = fnGet(getToField, rule.to);
      if (!fromField || !toField) return;
      const fromValue = fnGet(getFromValue, rule.from);
      const childrenDataFill = rule.children;
      let toValue;
      if (childrenDataFill && Array.isArray(fromValue)) {
        const fromFieldMap = generateMap(
          getChildrenFields(fromField),
          'pkId',
          field => field,
        );
        const toFieldMap = generateMap(
          getChildrenFields(toField),
          'pkId',
          field => field,
        );
        toValue = await Promise.all(
          fromValue.map(async fromRow => {
            /** @type { Record<string, any> } */
            const toRow = generateMap(
              getChildrenFields(toField),
              'pkId',
              getFieldDefaultValue,
            );
            await execDataFill(
              childrenDataFill,
              fromFieldMap,
              fromRow,
              toFieldMap,
              formatContext,
              (rule, toValue) => (toRow[rule.to] = toValue),
            );
            return toRow;
          }),
        );
      } else {
        toValue = await transformValue(
          fromValue,
          fromField,
          toField,
          formatContext,
        );
      }
      if (toValue != null) {
        callback(rule, toValue);
      }
    }),
  ).then(() => {});
}

/**
 * @name 获取字段值全路径
 * @param { string } fieldId 字段id
 * @param { FieldMapOrGetFieldFn } fieldMap
 * @returns { string }
 */
export function getCompareFieldKey(fieldId, fieldMap) {
  /** @type { string[] } */
  const fieldKeys = [];
  // const isBaseControl = FormInfoControls.includes(fieldId);
  // if (!isBaseControl) {
  //   // 非基础信息字段添加data前缀
  //   fieldKeys.push('data');
  // }
  const currentField = fnGet(fieldMap, fieldId);
  if (!currentField) return '';
  const { pkId } = currentField;
  // 子表单增加父级id
  // if (parentId) {
  //   const parentField = fnGet(fieldMap, parentId);
  //   if (parentField && parentField.type === ControlTypeEnum.Subform) {
  //     fieldKeys.push(parentId);
  //   }
  // }
  fieldKeys.push(pkId);
  // switch (type) {
  //   case ControlTypeEnum.Department:
  //   case ControlTypeEnum.Member:
  //   case ControlTypeEnum.CurrentNode:
  //   case ControlTypeEnum.CurrentAssignee:
  //     fieldKeys.push('value');
  //     break;
  // }
  return fieldKeys.join('.');
}

/**
 * @name 表单生成筛选结构
 * @param { import('../form-compare/compare').FormCompareFilter } filter
 * @param { FieldMapOrGetFieldFn } getTargetField
 * @param { import('../data-form/data-form-model-context').default= } model
 * @description 目前存在三种使用场景
 * 1. 列表页高级筛选这类不需要结合当前表单数据进行查询的情况，只需要传getTargetField
 * 2. 数据联动、关联其他表单、关联查询、表单关联等需要结合当前表单数据进行查询的情况，需要同时传getTargetField和model
 * 3. 关联组合、字段关联显示等在当前表单内进行对比的情况，getTargetField传入model.getField，同时传入model
 */
export function generateFormFilterGroup(filter, getTargetField, model) {
  return generateFilterGroup(filter, {
    getKey: ({ field }) => getCompareFieldKey(field, getTargetField),
    getValue: ({ value, option }) => {
      let currentValue;
      if (model && option && option.field) {
        currentValue = model.getValue(option.field);
      } else {
        currentValue = value;
      }
      return unwrapValue(currentValue, false);
    },
  });
}

/**
 * @name 通过表单过滤条件获取监听字段
 * @param { import('../form-compare').FormCompareFilter= } filter
 */
export function getWatcherFieldsByFilter(filter) {
  return ensureArray(filter?.configs)
    .map(config => config.option?.field)
    .filter(field => field);
}

/**
 * @name 正则匹配全部
 * @param { RegExp } reg
 * @param { string } str
 */
function matchAll(reg, str) {
  if (reg.global) {
    reg.lastIndex = 0;
  }
  const result = [];
  let current = null;
  while ((current = reg.exec(str))) {
    result.push(current);
  }
  return result;
}

/**
 * @name 通过公式编辑获取监听字段
 * @param { string } formula
 * @param { import('../data-form/data-form-model-context').default } model
 */
export function getWatcherFieldsByFormula(formula, model) {
  if (!formula) return [];
  return matchAll(/##current\|(.*?)\$\$/g, formula).map(result =>
    model.generateWatcherPath(result[1]),
  );
}

/**
 * @param { string } str
 * @param { RegExp } reg
 * @param { (...args: string[]) => string | PromiseLike<string> } replacer
 */
function asyncReplace(str, reg, replacer) {
  /** @type { PromiseLike<string>[] } */
  const promises = [];
  // @ts-ignore
  str.replace(reg, (...args) => promises.push(replacer(...args)));
  return Promise.all(promises).then(values =>
    str.replace(reg, () => values.shift() || ''),
  );
}

/**
 * @name 生成数据标题
 * @param { string } dataTitle
 * @param { FieldMapOrGetFieldFn } getField
 * @param { MapOrGetFn<any> } getValue
 * @param { import('./FormatContext').FormatContext } formatContext
 */
export function generateDataTitle(
  dataTitle,
  getField,
  getValue,
  formatContext,
) {
  if (!dataTitle) return Promise.resolve('');
  if (!/##(.*?)\$\$/.test(dataTitle)) return Promise.resolve(dataTitle);
  return asyncReplace(dataTitle, /##(.*?)\$\$/g, (_, fieldId) => {
    const field = fnGet(getField, fieldId);
    if (!field) return '';
    return formatContext.format(fnGet(getValue, fieldId), field);
  });
}

/**
 * @name 【新增数据组】入参和出参类型，用于限制【组件】的入参和出参只能有唯一的各一个
 * @param { Field[] } array
 * @returns { { fields: Field[], index: number  } }
 */
export function addDataSetFormat(array) {
  const fields = cloneDeep(array);
  const addDataSetArray = fields.filter(
    v => v.type === ControlTypeEnum.AddDataSet,
  );
  let fIndex = -1;
  let sIndex = -1;
  if (addDataSetArray.length > 1) {
    const [first, second] = addDataSetArray;
    fIndex = fields.findIndex(v => v.pkId === first.pkId);
    sIndex = fields.findIndex(v => v.pkId === second.pkId);
    if (!first.widget.paramType) {
      if (second.widget.paramType === 1) {
        first.widget.paramType = 2;
        first.widget.identifier = 'outputData';
      } else {
        first.widget.paramType = 1;
        first.widget.identifier = 'inputData';
      }
    }
    if (!second.widget.paramType) {
      if (first.widget.paramType === 1) {
        second.widget.paramType = 2;
        second.widget.identifier = 'outputData';
      } else {
        second.widget.paramType = 1;
        second.widget.identifier = 'inputData';
      }
    }
    first.widget.disabled = true;
    second.widget.disabled = true;
  } else {
    if (addDataSetArray.length) {
      addDataSetArray[0].widget.paramType = 1;
      addDataSetArray[0].widget.identifier = 'inputData';
      addDataSetArray[0].widget.disabled = false;
      fIndex = fields.findIndex(v => v.pkId === addDataSetArray[0].pkId);
    }
  }

  if (fIndex !== -1) {
    fields[fIndex] = addDataSetArray[0];
  }
  if (sIndex !== -1) {
    fields[sIndex] = addDataSetArray[1];
  }

  return { fields, index: sIndex !== -1 ? sIndex : fIndex };
}
