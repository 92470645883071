<template>
  <div :class="$style.invitationCode">
    <div :class="$style.classList">
      <div v-if="activityData.length">
        <div
          v-for="item in activityData"
          :key="item.id"
          :class="[
            $style.item,
            item.id === currentData.id && item.type === 'activity'
              ? $style.active
              : '',
          ]"
          @click="changeType(item)"
        >
          <x-ellipsis>
            {{ item.title }}
          </x-ellipsis>
        </div>
      </div>
      <div v-if="invitationData.length">
        <div
          v-for="item in invitationData"
          :key="item.id"
          :class="[
            $style.item,
            item.id === currentData.id && item.type === 'invitation'
              ? $style.active
              : '',
          ]"
          @click="changeType(item)"
        >
          <x-ellipsis>
            {{ item.name }}
          </x-ellipsis>
        </div>
      </div>

      <!-- <div :class="$style.item" v-if="activityData.length">
        <span type="link" @click="invitationCount">
          邀请函统计
        </span>
      </div> -->

      <empty-content
        v-if="!invitationData.length && !activityData.length"
        type="list"
      />
    </div>
    <a-spin :spinning="loading" />

    <div :class="$style.rightContent" v-if="!statistics">
      <div :class="$style.rightContent_top">
        <div :class="$style.rightContent_top_left">
          <a-form-model
            ref="form"
            :model="currentData"
            :rules="rules"
            :class="[$style.form, $style.left_item]"
            layout="inline"
          >
            <a-form-model-item :label="$t('activity.name') + '：'" prop="title">
              <a-input
                :class="$style.left_box"
                v-model.trim="currentData.title"
                :maxLength="30"
                :disabled="!$p.action('UPDATE')"
              />
            </a-form-model-item>
          </a-form-model>
          <div :class="$style.left_item">
            <div>{{ $t('activity.activityTime') }}:</div>
            <a-range-picker
              format="YYYY-MM-DD HH:mm:ss"
              v-if="currentData.beginTime"
              :disabled="!$p.action('UPDATE')"
              :default-value="[
                $moment(
                  $moment(this.currentData.beginTime).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
                  'YYYY/MM/DD HH:mm:ss',
                ),
                $moment(
                  $moment(this.currentData.endTime).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
                  'YYYY/MM/DD HH:mm:ss',
                ),
              ]"
              :show-time="{
                hideDisabledOptions: true,
              }"
              :class="[$style.left_box, $style.invitationCode_picker]"
              :placeholder="[
                `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
                  'common.label.day',
                )}`,
                `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
                  'common.label.day',
                )}`,
              ]"
              :separator="$t('common.label.to')"
              @change="handlerRangPickerChange"
            />
          </div>
          <div :class="$style.left_item">
            <div :class="$style.left_box">开奖时间：</div>
            <a-date-picker
              format="YYYY-MM-DD HH:mm:ss"
              v-if="currentData.awardTime"
              :default-value="
                $moment(
                  $moment(this.currentData.awardTime).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
                  'YYYY/MM/DD HH:mm:ss',
                )
              "
              :placeholder="
                `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
                  'common.label.day',
                )}`
              "
              :show-time="{
                hideDisabledOptions: true,
              }"
              @change="handlerDatePickerChange"
            />
            <!-- <a-range-picker
              format="YYYY-MM-DD HH:mm:ss"
              v-if="currentData.beginTime"
              :disabled="!$p.action('UPDATE')"
              :default-value="[
                $moment(
                  $moment(this.currentData.beginTime).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
                  'YYYY/MM/DD HH:mm:ss',
                ),
                $moment(
                  $moment(this.currentData.endTime).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ),
                  'YYYY/MM/DD HH:mm:ss',
                ),
              ]"
              :show-time="{
                hideDisabledOptions: true,
              }"
              :class="[$style.left_box, $style.invitationCode_picker]"
              :placeholder="[
                `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
                  'common.label.day',
                )}`,
                `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
                  'common.label.day',
                )}`,
              ]"
              :separator="$t('common.label.to')"
              @change="handlerDatePickerChange"
            /> -->
          </div>
        </div>
        <div :class="$style.actionList" v-if="currentData.status !== -1">
          <a-button type="primary" @click="edit" v-if="$p.action('UPDATE')">
            {{ $t('activity.modify') }}
          </a-button>
          <a-button
            type="primary"
            @click="closeActivity"
            v-if="$p.action('UPDATE')"
          >
            {{ $t('activity.endEvent') }}
          </a-button>
        </div>
        <div :class="$style.activityStatus" v-else>
          <span type="primary"> {{ $t('activity.eventOver') }} </span>
          <span type="primary">
            {{ $t('activity.endTime') }}：{{
              currentData.interruptionTime
                ? $moment(currentData.interruptionTime).format('Y-MM-DD HH:mm')
                : '-'
            }}
          </span>
        </div>
      </div>
      <managementList
        :activityId="currentData.pkId"
        v-if="currentData.pkId && activityData.length"
        ref="managementList"
      />
    </div>

    <div :class="$style.rightContent" v-else>
      <div :class="$style.rightContent_top">
        <div :class="$style.rightContent_top_left">
          <a-form-model
            ref="form"
            :model="currentData"
            :rules="rules"
            :class="[$style.form, $style.left_item]"
            layout="inline"
          >
            <a-form-model-item :label="$t('activity.name') + '：'" prop="title">
              <a-input
                :class="$style.left_box"
                v-model.trim="currentData.title"
                :disabled="!$p.action('UPDATE')"
                :maxLength="30"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
        <div :class="$style.actionList" v-if="!currentData.finish">
          <a-button @click="copyInvitationUrl">
            {{ $t('activity.copyLink') }}
          </a-button>
          <a-button
            type="primary"
            @click="statisticsEdit"
            v-if="$p.action('UPDATE')"
          >
            {{ $t('activity.modify') }}
          </a-button>
          <a-button
            type="primary"
            @click="statisticsCloseActivity"
            v-if="$p.action('UPDATE')"
          >
            {{ $t('activity.endEvent') }}
          </a-button>
        </div>
        <div :class="$style.activityStatus" v-else>
          <span type="primary"> {{ $t('activity.eventOver') }} </span>
          <span type="primary">
            {{ $t('activity.endTime') }}：{{
              currentData.invalidateTime
                ? $moment(currentData.invalidateTime).format('Y-MM-DD HH:mm')
                : '-'
            }}
          </span>
        </div>
      </div>
      <InvitationStatistics
        :activityId="currentData.id"
        v-if="currentData.id"
        ref="InvitationStatistics"
      />
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import managementList from './components/management-list.vue';
import InvitationStatistics from './components/invitation-statistics.vue';

import {
  getActivityList,
  closeActivity,
  editActivity,
  invitationClsList,
  editStatisticsName,
} from '@/services/marketing/invite-code';
import EmptyContent from '@/components/empty-content';
import { deepClone } from '@triascloud/utils';
import moment from 'moment';
import { createModuleUrl } from '@/utils';
import { clipboardWrite } from '@triascloud/utils';

@Component({
  components: {
    getActivityList,
    managementList,
    EmptyContent,
    InvitationStatistics,
  },
})
export default class EventManagement extends Vue {
  activityData = [];
  loading = false;
  statistics = false;
  currentData = {
    pkId: '',
    beginTime: null,
    endTime: null,
    awardTime: null,
  };

  invitationData = {};

  rules = {
    title: [
      {
        min: 2,
        max: 30,
        message: this.$t('record.groupNameTip'),
        trigger: 'blur',
      },
      {
        required: true,
        trigger: 'blur',
        message: '请输入活动名称',
      },
    ],
  };
  async closeActivity() {
    await this.$confirm(this.$t('activity.tip'));
    this.loading = true;
    await closeActivity({ pkId: this.currentData.pkId });
    this.loading = false;
    this.currentData.status = -1;
    this.$message.success(this.$t('common.tips.operationSuccess'));
  }

  async copyInvitationUrl() {
    await clipboardWrite(`${createModuleUrl('www')}/letter/invite`);
    this.$message.success(this.$t('common.tips.copy'));
  }

  async changeType(data) {
    this.$refs.form.resetFields();
    this.statistics = data.type === 'invitation' ? true : false;
    this.currentData = deepClone({
      ...data,
      name: data.name ? data.name : data.title,
      title: data.title ? data.title : data.name,
      type: data.type,
    });
    await this.$nextTick();
    this.statistics
      ? this.$refs.InvitationStatistics.refreshData()
      : this.$refs.managementList.refreshData();
  }
  async edit() {
    await this.$refs.form.validate();
    this.loading = true;
    await editActivity({
      pkId: this.currentData.pkId,
      activityName: this.currentData.title,
      beginTime: this.currentData.beginTime,
      endTime: this.currentData.endTime,
      awardTime: this.currentData.awardTime,
    });
    this.loading = false;
    this.$message.success(this.$t('common.tips.edit'));
    this.activityData = await getActivityList();
    this.currentData.name = this.currentData.title;
  }

  async invitationClsTypeList() {
    this.invitationData = await invitationClsList();
  }

  async statisticsEdit() {
    await this.$refs.form.validate();
    await editStatisticsName({
      finish: false,
      id: this.currentData.id,
      name: this.currentData.title,
    });
    await this.invitationClsTypeList();
    this.currentData.name = this.currentData.title;
  }
  async statisticsCloseActivity() {
    await this.$confirm(this.$t('activity.tip'));
    this.loading = true;
    await editStatisticsName({
      finish: true,
      id: this.currentData.id,
      name: this.currentData.name,
    });
    this.currentData.status = -1;
    this.loading = false;
    this.$message.success(this.$t('common.tips.operationSuccess'));
    await this.invitationClsTypeList();
    this.currentData.name = this.currentData.title;
  }

  handlerRangPickerChange(range) {
    this.currentData.beginTime = moment(range[0]).format('x');
    this.currentData.endTime = moment(range[1]).format('x');
  }
  handlerDatePickerChange(range) {
    this.currentData.awardTime = moment(range).format('x');
  }

  async created() {
    this.activityData = await getActivityList();
    this.invitationData = await invitationClsList();
    if (this.activityData.length) {
      this.currentData = deepClone({
        ...this.activityData[0],
        name: this.activityData[0].title,
      });
    } else {
      this.statistics = true;
      this.currentData = deepClone({
        ...this.invitationData[0],
        title: this.invitationData[0].name,
      });
      await this.$nextTick();
      this.$refs.InvitationStatistics.refreshData();
    }
  }
}
</script>
<style lang="less" module>
.invitationCode {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 100%;
}
.rightContent {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.rightContent_top {
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid var(--border);
  flex-wrap: wrap;
}
.rightContent_top_left {
  display: flex;
  flex-shrink: 0;
}
.left_item {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.left_box {
  margin-left: 10px;
}
.invitationCode_picker {
  width: 297px;
}
.actionList {
  button {
    margin-left: 20px;
  }
  :global(.ant-btn) {
    height: 32px;
  }
}

.classList {
  width: 300px;
  border-right: 1px solid #eee;
  height: 100%;
  flex-shrink: 0;
  overflow: auto;
}
.item {
  height: 40px;
  padding: 0px 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.active {
  background-color: var(--inline-menu-active);
  color: var(--font-active);
}
.left_name {
  width: 45px;
  margin-right: 8px;
}
.activityStatus {
  span {
    margin-right: 10px;
  }
}

.addButton {
  position: fixed;
  margin: 10px;
  width: 280px;
  button {
    width: 100%;
  }
}
.form {
  height: 39px;
  :global {
    .ant-form-item.ant-form-item-with-help {
      margin-bottom: 0px;
    }
  }
}
</style>
