<template>
  <a-row>
    <a-row :gutter="[20, 10]">
      <a-col :span="8">
        <a-form-model-item label="联系电话" prop="supportForm.contact">
          <a-input
            :maxLength="11"
            v-model="infoForm.contact"
            :disabled="disabledFlag"
            placeholder="请输入联系电话"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="售后时间" prop="supportForm.afterSalesTime">
          <a-input
            :maxLength="32"
            v-model="infoForm.afterSalesTime"
            :disabled="disabledFlag"
            placeholder="周一~周五9:00-18:00"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="联系邮箱" prop="supportForm.email">
          <a-input
            v-model="infoForm.email"
            :disabled="disabledFlag"
            placeholder="请输入联系邮箱"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="[20, 10]">
      <a-col :span="16">
        <a-form-model-item label="联系地址" prop="supportForm.address">
          <a-input
            v-model="infoForm.address"
            :maxLength="128"
            :disabled="disabledFlag"
            placeholder="请输入联系地址"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="[20, 10]">
      <a-col :span="24">
        <a-form-model-item prop="supportForm.serviceIntroduction">
          <span slot="label">
            <span>售后服务介绍</span>
            <span>（推荐宽1200px的图片，支持jpg、bmp、png等主流图片格式）</span>
          </span>
          <image-upload
            v-model="infoForm.serviceIntroduction"
            :maxCount="10"
            :is-disable="disabledFlag"
            ossFilePath="productInfo"
            :showCutImg="false"
            style="height: 120px"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-row>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ImageUpload from '@/views/connector/platform-manage/components/images-upload.vue';

@Component({
  components: {
    ImageUpload,
  },
})
export default class SalesSupport extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  infoForm;
  @Prop({ type: Boolean, default: false }) disabledFlag;
}
</script>
<style lang="less" module>
.btnGroup {
  text-align: right;
  padding-top: 24px;
}
.ml10 {
  margin-left: 10px;
}
</style>
