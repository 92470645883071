<template>
  <div :class="[$style.index, 'wrapBox']">
    <theme-select
      auto-set-theme
      :value="skin"
      @input="handleSkinChange"
      ref="themeSelect"
    />

    <span class="text" slot="icon">{{ $t('common.skin') }}</span>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { crossStorageModule } from '@/enum/store';
import { ThemeSelect } from '@triascloud/x-blocks';

@Component({
  components: {
    ThemeSelect,
  },
})
export default class ThemeSelectWrap extends Vue {
  @crossStorageModule.Mutation setItem;
  @crossStorageModule.State skin;

  handleSkinChange(value) {
    this.setItem({
      key: 'skin',
      value: JSON.stringify(value),
    });
  }
}
</script>
<style lang="less" module>
.index {
  position: relative;
  margin-right: 30px;

  :global {
    .ant-dropdown-link {
      display: flex;
      align-items: center;
      height: 32px;
      color: var(--font);
      font-size: 20px;
      margin: 0;
      padding: 0 10px;
      z-index: 1;
      position: relative;
      .action {
        margin-left: 8px;
        font-size: 20px;
      }
      .x-icon-hover {
        font-size: 20px;
      }
    }
    .text {
      position: absolute;
      top: -2px;
      left: 38px;
    }
  }

  &:hover {
    :global {
      .ant-dropdown-link .x-icon-hover,
      .text {
        color: var(--primary);
      }
    }
  }
}
</style>
