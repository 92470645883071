import request from '../request';
export const basePath = '/template-manager/hotRecommend';

/**
 * @name 热门模板
 */
export function getHotTemp() {
  return request(`${basePath}/applicationTemplateList`);
}

/**
 * @name 添加热门模板
 */
export function setHotTemp({ idxTemplateId, templateType }) {
  return request(`${basePath}/setHotTemp`, {
    method: 'POST',
    body: { idxTemplateId, templateType },
  });
}

/**
 * @name 取消热门模板
 */
export function deleteHotTemp(tempId) {
  return request(`${basePath}/deleteHotTemp?tempId=${tempId}`, {
    method: 'DELETE',
  });
}

/**
 * @name 热门模板排序
 */
export function sortRecommend(arr) {
  return request(`${basePath}/setSort`, {
    method: 'POST',
    body: arr,
  });
}
