<template>
  <a-select
    :mode="getMode"
    :value="getVal"
    style="width: 100%"
    placeholder="请选择"
  >
    <a-select-option
      v-for="item of field.widget.optionList"
      :key="item.id"
      :value="item.id"
    >
      {{ item.text }}
    </a-select-option>
  </a-select>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component()
export default class EnumDesign extends Vue {
  /** @description 数据 */
  @Prop({
    type: Object,
    required: true,
  })
  field;
  @Prop() value;
  @Prop() view;
  get getMode() {
    return this.field.widget.isSingle ? 'default' : 'multiple';
  }
  get getVal() {
    return this.field.widget.defaultValue.custom || undefined;
  }
}
</script>
