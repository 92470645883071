<template>
  <a-popover
    :trigger="['hover']"
    placement="left"
    :overlayClassName="$style.index"
  >
    <div>{{ $t('common.switchLanguage') }}</div>
    <template slot="content">
      <a-menu slot="overlay">
        <a-menu-item
          v-for="item in LanguageData"
          :key="item.value"
          :class="item.value === value ? 'active' : ''"
          @click="handleClickChange(item.value)"
        >
          <x-icon type="check" :class="$style.check" />
          {{ item.text }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-popover>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component()
export default class LanguageSelect extends Vue {
  @Prop({ type: String, default: 'zh_CN' }) value;

  LanguageData = [
    {
      text: '简体中文',
      value: 'zh_CN',
    },
    {
      text: '繁體中文',
      value: 'zh_TW',
    },
    {
      text: 'ENGLISH',
      value: 'en_US',
    },
  ];
  visible = false;
  handleClickChange(value) {
    this.$emit('input', value);
  }
}
</script>
<style lang="less" module>
.index {
  :global(.ant-popover-inner-content) {
    padding: 0;
    padding: 4px 0;
  }
  :global(.ant-menu-item) {
    padding: 5px 12px;
    margin: 0 !important;
    display: flex;
    align-items: center;
  }

  .check {
    margin-right: 8px;
    color: transparent;
  }
  :global(.active) {
    color: var(--primary);
    i {
      color: var(--primary);
    }
  }
}
</style>
