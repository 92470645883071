<template>
  <div :class="$style.excel">
    <a-upload
      name="file"
      :headers="headers"
      class="upload"
      :customRequest="handleChange"
    >
      <a-button style="color:black;width: 100%;">
        <a-icon type="upload" /> 点击或拖拽上传附件，不超过20M
      </a-button>
    </a-upload>
    <ul style="font-size:12px;color:#999999;margin-top: 15px;">
      <li>
        1、单次最多支持添加200行数据
      </li>
      <li>
        <span>2、点击下载:</span>
        <a
          href="https://trias-aiot.oss-cn-shenzhen.aliyuncs.com/common/samrt_helmet_import.xlsx"
          target="tempiframe"
        >
          Excel导入模板</a
        >
        <iframe name="tempiframe" style="display: none"></iframe>
      </li>
    </ul>
    <div style="display: flex;flex-direction: row-reverse;">
      <a-button type="primary" style="margin-left: 10px;" @click="handleClose"
        >确定</a-button
      >
      <a-button @click="handleClose">取消</a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { addDevice } from '@/services/hat/index';
import ExcelError from './excel-error.vue';
import _ from 'lodash';
@Component({})
export default class ExcelIn extends Vue {
  @Prop({ type: Function, default: () => {} }) handleClose;
  d = _;
  headers = {
    authorization: 'authorization-text',
  };
  mounted() {}
  handleChange(info) {
    const { file } = info;
    var formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'BASE');
    this.$store.commit('hat/setExcelState', 2);
    this.$store.commit('hat/setFilName', file.name);
    let cor = new AbortController();
    const signal = cor.signal;
    this.$store.commit('hat/setCor', cor);
    addDevice({ file: formData, signal })
      .then(res => {
        this.$store.commit('hat/setDevicesInfo', res);
        this.$store.commit('hat/setExcelState', 3);
        const { type, createNumber, devices } = res;
        var tips = {
          EXIST() {
            this.$message.warn(<ExcelError record={{ devices }} />, 3);
          },
          FORMAT() {
            this.$message.warn('导入文件与导入模板格式不一致!');
          },
        };
        if (type) {
          tips[type].call(this);
        } else {
          this.$message.success(`导入${createNumber}条设备成功`);
        }
      })
      .catch(() => {
        this.$store.commit('hat/setExcelState', 1);
      });
  }
}
</script>
<style lang="less" module>
.excel {
  :global .ant-upload-list-item {
    display: none;
  }
}
</style>
