import Vue from 'vue';
import Vuex from 'vuex';
import { createCrossStorageVuexPlugin } from '@triascloud/cross-storage';
import { ensureLocale } from '@triascloud/i18n';
import { ThemeSelect } from '@triascloud/x-blocks';
import { safeParseJSON } from '@/utils';
import TenantChange from '@/components/tenant-change';
import global from './modules/global';
import home from './modules/home';
import variable from './modules/variable';
import templateDesign from './modules/template-design';
import hat from './modules/hatManage';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  strict: true,
  modules: {
    global,
    home,
    templateDesign,
    variable,
    hat,
  },
  plugins: [
    createCrossStorageVuexPlugin({
      keys: {
        user: json => safeParseJSON(json, () => ({})),
        skin: json => ThemeSelect.ensureTheme(json),
        locale: locale => ensureLocale(locale),
        tenant: json => safeParseJSON(json, () => null),
      },
      filter: ({ key, value }, initiative) => {
        // 如果是在当前窗口主动触发更新，则永远成功
        if (initiative || key !== 'tenant') return true;
        const oldTenant = store.state.crossStorage.tenant;
        const newTenant = safeParseJSON(value, () => null);
        if (!oldTenant || !newTenant || oldTenant.pkId === newTenant.pkId)
          return true;
        TenantChange.create(oldTenant);
        return false;
      },
    }),
  ],
});

export default store;
