<template>
  <div :class="$style.classifyDiv">
    <a-spin :spinning="spinning">
      <a-form-model-item>
        <div :class="$style.listWarp" v-if="products.length">
          <div
            v-for="item in products"
            :key="item.pkId"
            :class="[
              $style.listItem,
              productPicked === item.pkId && !editMode ? $style.activeItem : '',
            ]"
          >
            <span
              :class="[$style.productText]"
              @click="productPicked = editMode ? '' : item.pkId"
              >{{ item.name }}</span
            >
            <span
              v-if="editMode"
              @click="handleRemove(item)"
              :class="$style.delBtn"
            >
              <a-icon type="close" />
            </span>
          </div>
        </div>
        <div v-else>
          <empty-content></empty-content>
        </div>
      </a-form-model-item>
      <a-form-model layout="vertical" :model="form" ref="form" :rules="rules">
        <a-form-model-item label="" prop="name" v-if="editMode">
          <a-input ref="productName" v-model="form.name" placeholder="分类名称">
            <span
              slot="suffix"
              @click="addClassify"
              style="cursor:pointer;color:var(--warning)"
            >
              添加
            </span>
          </a-input>
        </a-form-model-item>
      </a-form-model>
      <a-form-model-item :class="$style.centerRight">
        <a-button :class="$style.mr10" @click="handleCancel">{{
          $t('common.action.cancel')
        }}</a-button>
        <a-button
          :class="$style.mr10"
          type="primary"
          v-if="!editMode"
          @click="editMode = true"
        >
          管理分类
        </a-button>
        <a-button type="primary" @click="handleSure" :class="$style.mr10">{{
          editMode ? $t('common.action.confirm') : '下一步'
        }}</a-button>
      </a-form-model-item>
    </a-spin>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content';
import {
  getProducts,
  insertProduct,
  delProducts,
} from '@/services/iot-platform/dpManager';
import { uuid } from '@triascloud/utils';
@Component({
  components: {
    EmptyContent,
  },
})
export default class ClassifyForm extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  form = {
    idempotent: '',
    idxPlatformId: '',
    name: '',
  };
  productPicked = '';
  showAddInput = false;
  spinning = false;
  products = [];
  editMode = true;
  rules = {
    name: [
      {
        required: true,
        message: '请输入产品分类名称',
      },
      {
        min: 1,
        max: 14,
        message: '产品分类名称不能超过14个字符',
        trigger: 'change',
      },
    ],
  };
  mounted() {
    this.form.idxPlatformId = this.platformId;
    this.getList();
  }
  async getList() {
    this.products = await getProducts(this.platformId);
    if (this.products.length === 0) {
      this.editMode = true;
    }
  }
  async handleRemove(record) {
    this.spinning = true;
    try {
      await delProducts(record.pkId);
      this.getList();
    } catch (error) {
      this.spinning = false;
    }
    this.spinning = false;
  }
  async addClassify() {
    this.$refs.form.validate(async valid => {
      if (valid) {
        if (!this.form.name || this.form.name.length > 14) return;
        this.spinning = true;
        this.form.idempotent = uuid();
        await insertProduct(this.form);
        this.form.name = '';
        this.showAddInput = false;
        this.spinning = false;
        this.getList();
      }
    });
  }

  handleCancel() {
    this.$emit('close');
  }
  // @Emit('close')
  // @Emit('message')
  handleSure() {
    this.$emit('close');
  }
}
</script>

<style module lang="less">
.classifyDiv {
  .mr10 {
    margin-right: 10px;
  }

  .centerRight {
    margin: 20px 0 0 0;
    text-align: right;
  }

  .listWarp {
    display: flex;
    flex-direction: row;
    margin: 8px 0;
    flex-wrap: wrap;

    .listItem {
      position: relative;
      margin: 10px;
      cursor: pointer;
      border-radius: 5px;
      color: var(--font-sub);
      background-color: var(--body-bg);
    }

    // .listItem:last-child {
    //   border: none;
    // }
    .productText {
      padding: 5px;
      line-height: 32px;
    }

    .activeItem {
      color: var(--block-bg);
      background-color: var(--primary);

      .delBtn {
        color: var(--block-bg);
      }
    }

    .delBtn {
      padding: 5px;
      color: var(--font-info);
      // position: absolute;
      // top: -16px;
      // right: -13px;
    }

    .configBtn {
      color: var(--primary);
    }
  }

  :global {
    .x-empty-content--image {
      height: 100px;
    }
  }
}
</style>
