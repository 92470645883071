<template>
  <a-form-model :model="form" ref="form" :rules="rules">
    <a-form-model-item label="开发者类型" prop="developmenterType">
      <a-radio-group v-model="form.developmenterType" @change="onChange">
        <a-radio v-for="(value, key) in developerType" :key="key" :value="+key">
          {{ value }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item
      v-if="form.developmenterType == 1"
      label="所属厂商"
      prop="manufacturer"
    >
      <a-select
        show-search
        allowClear
        placeholder="请选择所属厂商"
        v-model="form.manufacturer"
        @search="handleManufacturerSearch"
        :options="manufacturerList"
        :filterOption="false"
      >
      </a-select>
    </a-form-model-item>

    <a-form-model-item
      v-if="form.developmenterType == 2"
      label="对接企业范围"
      prop="enterprise"
    >
      <a-select
        show-search
        allowClear
        placeholder="请选择企业"
        v-model="form.enterprise"
        :options="enterpriseList"
        :filterOption="false"
        @search="handleEnterpriseSearch"
      >
      </a-select>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { updatePermissionForDeveloper } from '@/services/digital-site/developer';
import { reqGetCompany } from '@/services/hat/index';
import { developerType } from '../enum';
import { getManufacturerList } from '@/services/digital-site/manufacturer';
import { Debounce } from 'lodash-decorators';

@Component
export default class PermissionConfigForm extends Vue {
  @Prop({ type: Object, default: () => {} }) record;

  developerType = developerType;
  form = {
    developmenterType: null,
    enterprise: undefined,
    manufacturer: undefined,
  };

  rules = {
    developmenterType: [{ required: true, message: '请选择开发者类型' }],
    manufacturer: [
      { required: true, message: '请选择所属厂商', trigger: 'change' },
    ],
    enterprise: [
      { required: true, message: '请选择对接企业范围', trigger: 'change' },
    ],
  };

  projectList = [];

  async getValue() {
    await this.$refs.form.validate();
    try {
      await updatePermissionForDeveloper({
        developmenterType: this.form.developmenterType,
        permissionId: this.form[
          { 1: 'manufacturer', 2: 'enterprise' }[this.form.developmenterType]
        ],
        pkId: this.record.pkId,
      });
      this.$message.success('保存成功!');

      return true;
    } catch (error) {
      return false;
    }
  }

  async onChange() {
    this.$refs.form.clearValidate();
  }

  initForm() {
    this.form.developmenterType = Object.keys(this.developerType).includes(
      this.record.developmenterType + '',
    )
      ? this.record.developmenterType
      : null;

    this.form[
      { 1: 'manufacturer', 2: 'enterprise' }[this.record.developmenterType]
    ] = this.record.permissionId || undefined;
  }

  // 厂商options
  manufacturerList = [];
  @Debounce(300)
  async initManufacturer(keyword = undefined) {
    const { records } = await getManufacturerList({
      current: 1,
      size: 20,
      keyword,
    });
    this.manufacturerList = records.map(
      ({ supplierName, pkId, tenantStatus }) => ({
        label:
          supplierName + `${tenantStatus == 'DISABLE' ? '（已停用）' : ''}`,
        value: pkId,
        disabled: tenantStatus == 'DISABLE',
      }),
    );

    // 给初始option添加默认选中的值
    if (
      this.record.developmenterType == 1 &&
      this.manufacturerList.findIndex(
        ({ value }) => value == this.record.permissionId,
      ) == -1 &&
      this.record.permissionName
    ) {
      this.manufacturerList.unshift({
        label: this.record.permissionName,
        value: this.record.permissionId,
      });
    }
    return records;
  }

  async handleManufacturerSearch(val) {
    this.initManufacturer(val);
  }

  // 政府options
  enterpriseList = [];
  @Debounce(300)
  async initEnterprise(keyword = undefined) {
    const { data } = await reqGetCompany({
      current: 1,
      size: 20,
      keyword,
    });
    this.enterpriseList = data.map(
      ({ enterpriseName, pkId, tenantStatus }) => ({
        label:
          enterpriseName + `${tenantStatus == 'DISABLE' ? '（已停用）' : ''}`,
        value: pkId,
        disabled: tenantStatus == 'DISABLE',
      }),
    );

    // 给初始option添加默认选中的值
    if (
      this.record.developmenterType == 2 &&
      this.enterpriseList.findIndex(
        ({ value }) => value == this.record.permissionId,
      ) == -1 &&
      this.record.permissionName
    ) {
      this.enterpriseList.unshift({
        label: this.record.permissionName,
        value: this.record.permissionId,
      });
    }

    return data;
  }

  async handleEnterpriseSearch(val) {
    this.initEnterprise(val);
  }

  async initOptions() {
    this.initEnterprise();
    this.initManufacturer();
  }
  async mounted() {
    this.initOptions();
    this.initForm();
  }
}
</script>

<style lang="less"></style>
