<template>
  <div :class="$style.pform">
    <a-row :gutter="[10, 10]" v-for="(item, idx) in value" :key="item.id">
      <a-form-model
        layout="vertical"
        :model="item.form"
        :rules="rules"
        ref="filedForm"
      >
        <a-col :span="6">
          <a-form-model-item label="订阅主题" prop="theme">
            <a-select
              v-model="item.form.theme"
              placeholder="请选择"
              :dropdownMenuStyle="{ maxHeight: '130px' }"
              allowClear
              @change="val => changeTheme(val, item)"
              :getPopupContainer="triggerNode => triggerNode.parentNode"
            >
              <a-select-option
                v-for="t in planObj[planId]"
                :key="t.id"
                :value="t.id"
              >
                {{ t.name || '默认定时主题' }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="一级字段" prop="pfiled">
            <a-select
              v-model="item.form.pfiled"
              placeholder="请选择"
              :dropdownMenuStyle="{ maxHeight: '130px' }"
              :getPopupContainer="triggerNode => triggerNode.parentNode"
              allowClear
              @change="val => changeOne(val, item)"
            >
              <a-select-option
                v-for="p in filedsObj[item.form.theme]"
                :key="p.identifier"
                :value="`${item.form.theme}.${p.identifier}`"
              >
                {{ p.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6" v-if="item.showTwo">
          <a-form-model-item label="二级字段" prop="cfiled">
            <a-select
              v-model="item.form.cfiled"
              placeholder="请选择"
              :dropdownMenuStyle="{ maxHeight: '130px' }"
              :getPopupContainer="triggerNode => triggerNode.parentNode"
              allowClear
            >
              <a-select-option
                v-for="c in sonObj[item.form.pfiled]"
                :key="c.identifier"
                :value="`${item.form.pfiled}.${c.identifier}`"
              >
                {{ c.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item>
            <div slot="label">&nbsp;</div>
            <x-icon
              type="tc-icon-delete"
              style="color: var(--delete);margin-left: 5px"
              @click="deleteItem(idx)"
            />
          </a-form-model-item>
        </a-col>
      </a-form-model>
    </a-row>
    <a-row :gutter="[10, 10]" style="margin-top: 10px;">
      <a-button type="link" icon="plus" style="padding: 0" @click="addFileds">
        添加属性字段
      </a-button>
    </a-row>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { uuid } from '@triascloud/utils';

@Component
export default class PropertyForm extends Vue {
  @Prop({ type: Array, default: () => [] }) value;
  @Prop({ type: String, default: '' }) planId;
  @Prop({
    type: Object,
    default: () => {
      return {
        theme: undefined,
        pfiled: undefined,
        cfiled: undefined,
      };
    },
  })
  form;
  @Prop({
    type: Object,
    default: () => {},
  })
  selectData;

  @Watch('selectData', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal && newVal.planData?.length) {
      this.initData();
    }
  }

  planData = [];
  planObj = {};
  filedsObj = {};
  sonObj = {};

  initData() {
    const row = this.selectData;
    this.planData = row.planData;
    this.planObj = row.planObj;
    this.filedsObj = row.filedsObj;
    this.sonObj = row.sonObj;
  }

  arrToList() {
    this.value.push({
      id: uuid(),
      form: {
        theme: undefined,
        pfiled: undefined,
        cfiled: undefined,
      },
      showTwo: true,
    });
    this.changeProperty();
  }
  changeProperty() {
    const arr = this.value;
    this.$emit('input', arr);
    this.$emit('change', arr);
  }
  checkForm() {
    return new Promise(resolve => {
      this.$refs.filedForm?.forEach(async v => {
        try {
          await v.validate();
          resolve(true);
        } catch {
          resolve(false);
          return false;
        }
      });
    });
  }

  async addFileds() {
    if (this.value.length === 0) {
      this.arrToList();
    }
    try {
      const flag = await this.checkForm();
      if (flag) {
        this.arrToList();
      }
    } catch {
      return false;
    }
  }

  deleteItem(idx) {
    this.value.splice(idx, 1);
    this.changeProperty();
  }

  rules = {
    theme: [
      {
        required: true,
        message: '请选择订阅主题',
      },
    ],
    pfiled: [
      {
        required: true,
        validator: this.checkOne,
      },
    ],
    cfiled: [
      {
        required: true,
        validator: this.checkTwo,
      },
    ],
  };

  changeOne(val, item) {
    if (val) {
      const arr = this.sonObj[val].length;
      item.showTwo = !!arr;
      if (item.showTwo && item.form.cfiled) {
        const c = item.form.cfiled.split('.');
        const str = val.split('.')[1];
        if (c[1] !== str) {
          item.form.cfiled = undefined;
        }
      }
    } else {
      item.form.cfiled = undefined;
    }
  }

  changeTheme(val, item) {
    if (!val) {
      item.form = {
        theme: undefined,
        pfiled: undefined,
        cfiled: undefined,
      };
    } else {
      item.form = {
        theme: val,
        pfiled: undefined,
        cfiled: undefined,
      };
      item.showTwo = true;
    }
  }

  hasFiled(value, filed) {
    const str = value.split('.');
    if (str.length === 2) {
      const arr = [];
      this.value.forEach(v => {
        if (v.form.theme === str[0] && v.form[filed] === value) {
          arr.push(1);
        }
      });
      return arr.length > 1;
    } else {
      const arr = [];
      this.value.forEach(v => {
        if (
          v.form.pfiled === `${str[0]}.${str[1]}` &&
          v.form[filed] === value
        ) {
          arr.push(1);
        }
      });
      return arr.length > 1;
    }
  }

  checkOne(rule, value, callback) {
    if (!value) {
      callback(new Error('请选择一级字段'));
    } else {
      const arr = this.sonObj[value];
      if (!arr.length) {
        const flag = this.hasFiled(value, 'pfiled');
        if (flag) {
          callback(new Error('存在相同一级字段'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
  }

  checkTwo(rule, value, callback) {
    if (!value) {
      callback(new Error('请选择二级字段'));
    } else {
      const flag = this.hasFiled(value, 'cfiled');
      if (flag) {
        callback(new Error('存在相同二级字段'));
      } else {
        callback();
      }
    }
  }
}
</script>

<style lang="less" module>
.pform {
  :global {
    .ant-select-dropdown {
      top: 27px !important;
    }
  }
}
</style>
