var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('field-config-title',_vm._b({},'field-config-title',_vm.fieldProps,false)),_c('a-form-model-item',{attrs:{"prop":"widget.productId","label":"来源产品","required":""}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"value":_vm.field.widget.productId,"placeholder":"请选择"},on:{"change":function (val) {
          _vm.handleInput('widget.productId', val);
          _vm.handleInput('widget.defaultValue.custom', []);
          _vm.handleInput('widget.selectedDp', []);
          _vm.getDpList();
        }}},_vm._l((_vm.products),function(item){return _c('a-select-option',{key:item.pkId,attrs:{"value":item.pkId}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"prop":"widget.classificationId","label":"物模型分类","required":""}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"value":_vm.field.widget.classificationId,"placeholder":"请选择"},on:{"change":function (val) {
          _vm.handleInput('widget.classificationId', val);
          _vm.handleInput('widget.defaultValue.custom', []);
          _vm.handleInput('widget.selectedDp', []);
          _vm.getDpList();
        }}},_vm._l((_vm.dpTemps),function(item){return _c('a-select-option',{key:item.identifier,attrs:{"value":item.identifier}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"prop":"widget.defaultValue.custom","rules":_vm.dpRule}},[_c('label',{staticClass:"ant-form-item-required",attrs:{"slot":"label"},slot:"label"},[_vm._v(" DP点 ")]),_c('a-select',{staticStyle:{"width":"100%"},attrs:{"mode":"multiple","value":_vm.field.widget.defaultValue.custom,"placeholder":"请选择"},on:{"change":_vm.dpChanged}},_vm._l((_vm.dpList),function(item){return _c('a-select-option',{key:item.dataPointId,attrs:{"value":item.dataPointId}},[_vm._v(" "+_vm._s(item.functionName)+" ")])}),1)],1),_c('field-config-required',_vm._b({},'field-config-required',_vm.fieldProps,false)),(!_vm.isRes)?_c('parameter-object',_vm._b({attrs:{"isInOrOut":"in"}},'parameter-object',_vm.fieldProps,false)):_vm._e(),(_vm.isRes)?_c('parameter-object',_vm._b({attrs:{"isInOrOut":"out"}},'parameter-object',_vm.fieldProps,false)):_vm._e(),(_vm.isReq)?_c('a-form-model-item',{attrs:{"prop":"widget.paramInFormat","label":"入参格式"}},[_c('a-radio-group',{attrs:{"value":_vm.field.widget.paramInFormat},on:{"change":function (e) {
          this$1.handleInput('widget.paramInFormat', e.target.value);
        }}},[_c('a-radio',{attrs:{"value":_vm.InputFormat.NEVER}},[_vm._v(" 不参与入参 ")]),_c('a-radio',{attrs:{"value":_vm.InputFormat.OBJECT}},[_vm._v(" 对象 ")]),_c('a-radio',{attrs:{"value":_vm.InputFormat.KEYVALUE}},[_vm._v(" 键值 ")])],1)],1):_vm._e(),(_vm.isReq)?_c('a-form-model-item',{attrs:{"prop":"widget.paramInTag"}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" DP点入参标识 "),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('a-icon',{attrs:{"type":"info-circle"}}),_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" 根据所选DP点动态添加query参数（若为空则不添加），例如选择DP点为开关时（标识符为switch)，则自动在实际query种添加Identifier=switch ")])],1)],1),_c('a-input',{attrs:{"value":_vm.field.widget.paramInTag},on:{"change":function (e) {
          this$1.handleInput('widget.paramInTag', e.target.value);
        }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }