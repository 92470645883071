<template>
  <a-form-model :model="form" :rules="rules">
    <a-form-model-item label="模拟" prop="query">
      <a-textarea
        v-model="form.query"
        :auto-size="{ minRows: 4, maxRows: 8 }"
        placeholder="请输入调试数据"
      />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
@Component()
export default class MockForm extends Vue {
  form = {
    query: '',
  };

  rules = {
    query: [{ required: true, message: '请输入调试数据', trigger: 'blur' }],
  };

  async getValue() {
    if (!this.form.query) {
      return Promise.reject('请输入调试数据');
    } else {
      return this.form.query;
    }
  }
}
</script>
<style lang="less" module></style>
