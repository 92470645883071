<template>
  <a-form-model :model="form" :rules="rules" ref="form">
    <div :class="$style.apiHeader" :style="boxShadowColor">
      <a-form-model-item prop="logoUrl">
        <images-upload
          v-model="form.logoUrl"
          style="width: 115px;height: 120px"
        />
      </a-form-model-item>
      <div :class="$style.headerRight">
        <a-form-model-item prop="apiName">
          <a-input
            v-if="isEdit"
            auto-focus
            placeholder="API名称"
            :maxLength="50"
            v-model="form.apiName"
          />
          <p v-else :style="{ color: themeColor }">{{ form.apiName }}</p>
        </a-form-model-item>
        <a-form-model-item prop="apiDesc">
          <a-textarea
            v-if="isEdit"
            placeholder="API的介绍，用于订阅客户快速了解当前API的情况"
            :maxLength="100"
            v-model="form.apiDesc"
          />
          <p v-else>{{ form.apiDesc }}</p>
        </a-form-model-item>
      </div>
      <div :class="$style.editBtn">
        <a-icon
          :class="[$style.iconStyle, $style.delete]"
          v-if="isEdit"
          type="close-circle"
          @click="cancel"
        />
        <a-icon
          v-if="!isEdit"
          type="edit"
          style="color: var(--primary)"
          :class="$style.iconStyle"
          @click="isEdit = true"
        />
        <a-icon
          v-if="isEdit"
          type="check-circle"
          :class="[$style.iconStyle, $style.success]"
          @click="saveHeader"
        />
      </div>
    </div>
  </a-form-model>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ImagesUpload from '@/views/connector/platform-manage/components/images-upload.vue';
import {
  getApiBaseInfo,
  getOssHost,
  updateApiBaseInfo,
} from '@/services/iot-platform/platformInterface';
import { changeOssToUrl } from './components/utils';
@Component({
  components: {
    ImagesUpload,
  },
})
export default class ApiHeader extends Vue {
  @Prop({ type: String, default: '' }) apiId;
  @Prop({ type: String, default: '' }) themeColor;

  @Watch('themeColor')
  changeColor(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.themeColor = newVal;
      this.boxShadowColor = {
        'box-shadow': `0 0 5px 0 ${newVal}`,
      };
    }
  }
  boxShadowColor = '';
  form = {
    logoUrl: [],
    apiName: '',
    apiDesc: '',
  };
  rules = {
    apiName: [
      {
        type: 'string',
        required: true,
        message: '请输入API名称',
      },
    ],
    // apiDesc: [
    //   {
    //     type: 'string',
    //     required: true,
    //     message: '请输入API描述',
    //   },
    // ],
  };
  staticOssHead = '';
  async mounted() {
    this.staticOssHead = await getOssHost();
    await this.init();
  }
  async init() {
    try {
      const res = await getApiBaseInfo(this.apiId);
      this.form.apiName = res.apiName;
      this.form.apiDesc = res.introduction;
      const staticOssHead = this.staticOssHead;
      const defaultUrl = await changeOssToUrl(staticOssHead, res.logoUrl);
      this.form.logoUrl = [
        {
          uid: -1,
          name: 'picture',
          status: 'done',
          url: defaultUrl[0],
          ossPath: res.logoUrl,
        },
      ];
    } catch {
      return false;
    }
  }
  isEdit = false;
  async saveHeader() {
    await this.$refs.form.validate();
    const params = {
      apiName: this.form.apiName,
      introduction: this.form.apiDesc,
      logoUrl: this.form.logoUrl[0].ossPath,
      pkId: this.apiId,
    };
    try {
      await updateApiBaseInfo(params);
      this.$message.success('保存成功');
      this.$emit('refresh');
      await this.init();
    } catch {
      return false;
    }
    this.isEdit = false;
  }
  cancel() {
    this.isEdit = false;
    this.init();
  }
}
</script>

<style lang="less" module>
.apiHeader {
  display: flex;
  border: 1px solid var(--border);
  padding: 20px 20px 0;
  position: relative;
}
.headerRight {
  flex: 0.75;
}
.editBtn {
  position: absolute;
  right: 30px;
}
.iconStyle {
  font-size: 20px;
  &:not(:first-child) {
    margin-left: 10px;
  }
}
.delete {
  color: var(--delete);
}
.success {
  color: var(--success);
}
</style>
