import request from '../request';
import { promiseLock } from '@triascloud/utils';
const serviceName = '/iot-platform/platform/datapoint';
const serviceNameTemp = '/iot-platform/dataTemplate';
const serviceNamePro = '/iot-platform/platform/product';

/**@name 获取dataPoint列表 */
export function getDataPointList(data) {
  return request(`${serviceName}/list`, {
    method: 'GET',
    body: data,
  });
}

/**@name 新增dataPoint */
export function insertDataPoint(data) {
  return request(`${serviceName}/insert`, {
    method: 'POST',
    body: data,
  });
}
/**@name 修改dataPoint */
export function updateDataPoint(data) {
  return request(`${serviceName}/update`, {
    method: 'PUT',
    body: data,
  });
}

/**@name 删除dataPoint列表 */
export function deleteDataPoint(dataPointId) {
  return request(`${serviceName}/delete/${dataPointId}`, {
    method: 'DELETE',
  });
}
/**@name 导入dataPoint列表 */
export function importDataPoint(platformId, fileData) {
  return request(`${serviceName}/import/${platformId}`, {
    method: 'POST',
    body: fileData,
  });
}

/**@name 导出dataPoint列表 */
export function exportDataPoint(platformId, data) {
  return request(`${serviceName}/export/${platformId}`, {
    method: 'GET',
    body: data,
  });
}

/**@name 获取dataPoint详情 */
export function getDataPointDetail(dataPointId) {
  return request(`${serviceName}/get/${dataPointId}`, {
    method: 'GET',
  });
}

/**@name 新增产品分类 */
export function insertProduct(data) {
  return request(`${serviceNamePro}/insert`, {
    method: 'POST',
    body: data,
  });
}

// 编辑产品分类
export function editProduct(data) {
  return request(`${serviceNamePro}`, {
    method: 'PUT',
    body: data,
  });
}

/**@name 删除产品分类 */
export function delProducts(id) {
  return request(`${serviceNamePro}/${id}`, {
    method: 'DELETE',
  });
}

/**@name 获取平台下所有产品分类分页 */
export function getProducts(platformId, data) {
  return request(`${serviceNamePro}/page/${platformId}`, {
    method: 'GET',
    body: data,
  });
}

/**@name 获取平台下所有产品分类 */
export function getProductsNoPage(platformId) {
  return request(`${serviceNamePro}/list/${platformId}`, {
    method: 'GET',
  });
}

/**@name 获取dataPoint详情 */
export function getClassification(platformId) {
  return request(`${serviceNameTemp}/classification/${platformId}`, {
    method: 'GET',
  });
}

/**
 * @name 获取dataPoint详情
 * @param platformId
 * @return {Promise<T|*>}
 */

export const getClassificationCache = promiseLock(
  platformId => {
    return request(`${serviceNameTemp}/classification/${platformId}`, {
      method: 'GET',
    });
  },
  { keyGenerator: platformId => platformId, forever: true },
);

/**
 * @name 获取平台下所有分类
 * @param platformId
 * @return {Promise<T|*>}
 */
export const getProductsCache = promiseLock(
  platformId => {
    return request(`${serviceNamePro}/list/${platformId}`, {
      method: 'GET',
    });
  },
  { keyGenerator: platformId => platformId, forever: true },
);

/**
 * @name 查询dataPoint列表
 * @param data
 * @return {Promise<T|*>}
 */
export const dataPointSearch = promiseLock(
  data => {
    return request(`${serviceName}/listByProduct`, {
      method: 'GET',
      body: data,
    });
  },
  {
    keyGenerator: data =>
      data.classificationId + data.productId + data.platformId,
    // forever: true,
  },
);
