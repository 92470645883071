<template>
  <x-scroll-view
    :disabled="loading || finished"
    :class="[$style.wrap, { [$style['wrap--enough']]: list.length >= 10 }]"
    @scrolltolower="loadMore"
  >
    <div
      v-if="$p.action('CREATE') && list.length < 10 && list.length"
      :class="$style['card--add']"
      @click="add"
    >
      <x-icon type="plus-circle" />
    </div>
    <draggable
      v-model="list"
      :animation="300"
      :class="{ [$style.noDrag]: !$p.action('SORT') }"
      :disabled="!$p.action('SORT')"
      handle=".draggle-handle"
      @end="sort"
    >
      <transition-group>
        <help-card
          v-for="item in list"
          :key="item.pkCenterId"
          :data="item"
          :class="$style.card"
          @update="refresh"
          @remove="refresh"
        />
      </transition-group>
    </draggable>
    <div v-if="!loading && !list.length" :class="$style.empty">
      <empty-content type="list" :label="$t('help.empty')" />
      <a-button
        v-if="$p.action('CREATE')"
        type="primary"
        :class="$style.empty__button"
        @click="add"
        >{{ $t('help.create') }}</a-button
      >
    </div>
    <a-spin :spinning="loading" />
  </x-scroll-view>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content';
import { getList, sort } from '@/services/resource/helpCenter';
import HelpSettingModal from './components/help-setting-modal.vue';
import HelpCard from './components/card';
import Draggable from 'vuedraggable';

@Component({
  components: { EmptyContent, HelpCard, Draggable },
})
export default class Index extends Vue {
  list = [];
  current = 1;
  finished = false;
  loading = false;
  total = 0;
  refresh() {
    this.list = [];
    this.current = 1;
    this.finished = false;
    this.loadMore();
  }
  async loadMore() {
    this.loading = true;
    const data = await getList({ current: this.current, size: 20 });
    this.total = data.total;
    this.list = this.list.concat(data.records);
    if (data.total === this.list.length) {
      this.finished = true;
    } else {
      this.current += 1;
    }
    this.loading = false;
  }
  async add() {
    await HelpSettingModal.createModal();
    this.refresh();
  }
  /**
   * @name 拖动排序更新sort字段
   */
  sort({ newIndex, oldIndex }) {
    if (!this.list.length || newIndex === oldIndex) return;
    const startIndex = Math.max(newIndex, oldIndex);
    const startSort =
      startIndex === this.total - 1 ? 1 : this.list[startIndex + 1].sort + 1;
    const sortItems = [];
    this.list.forEach((item, index) => {
      if (index > startIndex) return;
      const newSort = startSort + (startIndex - index);
      if (newSort !== item.sort) {
        item.sort = newSort;
        sortItems.push({
          sort: startSort + (startIndex - index),
          id: item.pkCenterId,
        });
      }
    });
    sortItems.length && sort(sortItems);
  }
}
</script>
<style lang="less" module>
.wrap {
  position: relative;
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  :global(.x-empty-content) {
    height: auto;
  }
  .empty__button {
    width: 240px;
    height: 44px;
    margin-top: 74px;
  }
}
.card {
  margin: 30px 35px;
  &:first-child {
    margin-left: 318px;
  }
}
.noDrag .card {
  cursor: default;
}
.wrap--enough .card:first-child {
  margin-left: 35px;
}
.card--add {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-active);
  font-size: 78px;
  height: 264px;
  width: 214px;
  box-shadow: 0px 0px 15px 0px var(--shadow);
  top: 30px;
  left: 30px;
  border-radius: 6px;
  background: var(--main-bg);
}
</style>
