var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrap},[_c('a-tabs',{class:_vm.$style.side,attrs:{"activeKey":_vm.activeTab,"animated":false,"tab-bar-gutter":40},on:{"change":_vm.handleTabChange}},_vm._l((_vm.classify),function(item){
var _obj;
return _c('a-tab-pane',{key:item.pkId,attrs:{"tab":item.name}},[_c('div',{class:_vm.$style.classify},[_c('div',{class:[
            _vm.$style['classify-item'],
            ( _obj = {}, _obj[_vm.$style.active] = _vm.activeClassify === item.pkId, _obj ) ],on:{"click":function($event){_vm.activeClassify = item.pkId}}},[_vm._v(" "+_vm._s(_vm.$t('common.label.all'))+" ")]),_vm._l((item.childList),function(child){
          var _obj;
return _c('x-ellipsis',{key:child.pkId,class:[
            _vm.$style['classify-item'],
            ( _obj = {}, _obj[_vm.$style.active] = _vm.activeClassify === child.pkId, _obj ) ],nativeOn:{"click":function($event){_vm.activeClassify = child.pkId}}},[_vm._v(" "+_vm._s(child.name)+" ")])})],2)])}),1),_c('div',{class:_vm.$style.content},[(_vm.activeClassify)?_c('x-data-table',{ref:"table",class:_vm.$style.table,attrs:{"base-path":"/template-manager/template/formTemplateList","columns":_vm.columns,"service":_vm.service,"row-key":"id","selected-row-keys":_vm.selectedRowKeys},on:{"update:selectedRowKeys":function($event){_vm.selectedRowKeys=$event},"update:selected-row-keys":function($event){_vm.selectedRowKeys=$event}},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.$p.action('UPDATE', '/market'))?_c('a-button',{class:_vm.$style.action,attrs:{"type":"primary"},on:{"click":_vm.modify}},[_c('x-icon',{attrs:{"type":"tc-icon-setting"}}),_vm._v(" "+_vm._s(_vm.$t('market.template.virtualManagement'))+" ")],1):_vm._e(),(_vm.$p.action('RECYCLE-BIN', '/market'))?_c('a-button',{class:_vm.$style.action,on:{"click":_vm.recycle}},[_c('x-icon',{attrs:{"type":"tc-icon-delete"}}),_vm._v(" "+_vm._s(_vm.$t('gallery.actions.recycling'))+" ")],1):_vm._e(),_c('a-button',{class:_vm.$style.action,attrs:{"type":"primary"},on:{"click":_vm.labelManage}},[_vm._v(_vm._s(_vm.$t('market.label.manage')))]),(_vm.$p.action('CLASSIFY', '/market'))?[_c('a-tooltip',{attrs:{"title":_vm.$t('market.template.classify.manage')}},[_c('x-icon',{class:_vm.$style.action,attrs:{"type":"setting","hover":""},on:{"click":_vm.manageClassify}})],1),_c('a-tooltip',{attrs:{"title":_vm.$t('market.template.classify.add')}},[_c('x-icon',{class:_vm.$style.action,attrs:{"type":"plus-circle","hover":""},on:{"click":function($event){return _vm.addClassify(false)}}})],1)]:_vm._e()]},proxy:true}],null,false,2259917154)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }