<template>
  <section :class="$style.content">
    <a-tabs @change="changeTab" :default-active-key="activeKey">
      <a-tab-pane key="review" tab="审批">
        <Review v-if="activeKey === 'review'" />
      </a-tab-pane>
      <a-tab-pane key="category" tab="品类管理">
        <Category v-if="activeKey === 'category'" />
      </a-tab-pane>
      <div slot="tabBarExtraContent"></div>
    </a-tabs>
  </section>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import Review from './review/index.vue';
import Category from './category/index.vue';

@Component({
  components: {
    Review,
    Category,
  },
})
export default class PlatformReview extends Vue {
  activeKey = 'review';

  changeTab(key) {
    this.activeKey = key;
  }
}
</script>
<style lang="less" module>
.content {
  position: relative;
  padding: 0 20px;
}
</style>
