<template>
  <div ref="ManageInstallEchart" />
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { globalVueI18n } from '@triascloud/i18n';
import ResizeObserver from 'resize-observer-polyfill';
import { crossStorageModule } from '@/enum/store';

@Component({
  components: {},
})
export default class ManageInstallEchart extends Vue {
  @Prop({ type: Object }) data;
  /** @type { ResizeObserver } */
  _ob = null;
  myChart = null;
  @crossStorageModule.State skin;

  mounted() {
    this._ob = new ResizeObserver(this.chartResize);
    this._ob.observe(this.$refs.ManageInstallEchart);
    this.initChart();
  }
  chartResize() {
    this.myChart.resize();
  }
  get option() {
    const res =
      this.data && this.data.solutionInstallRankDetailList
        ? this.data.solutionInstallRankDetailList
        : [];
    return {
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          //   console.log(params);
          return [
            params[0].name + '<br/>',
            globalVueI18n.translate('solution.numberOfInstalls') +
              ': ' +
              params[1].value +
              '&nbsp' +
              params[1].data.purchaseOf +
              '%' +
              '<br/>',
            globalVueI18n.translate('inviteCode.purchases') +
              ': ' +
              params[0].value +
              '&nbsp' +
              params[0].data.purchaseOf +
              '%' +
              '<br/>',
          ].join('');
        },
      },
      grid: {
        left: '5%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      legend: {
        data: [
          this.$t('inviteCode.purchases'),
          this.$t('solution.numberOfInstalls'),
        ],
        top: 25,
        right: 10,
        itemWidth: 8,
        itemHeight: 8,
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },
      yAxis: [
        {
          type: 'category',
          data: res.length ? res.map(item => item.tenantName) : [],
          inside: true,
          showMaxLabel: true,
          axisLabel: {
            width: 70,
            formatter: function(value) {
              if (value.length >= 5) {
                let v = value.slice(0, 5) + '...';
                return v;
              } else {
                return value;
              }
            },
          },
        },
      ],
      xAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: globalVueI18n.translate('inviteCode.purchases'),
          type: 'bar',
          tooltip: {
            // formatter: '{b0}: {c0}<br />{b1}: {c1}',
          },
          color: ['#5BCC75'],
          data: res.length
            ? res.map(item => ({
                value: item.buyCount,
                purchaseOf: item.purchaseOf,
              }))
            : [],
        },
        {
          name: globalVueI18n.translate('solution.numberOfInstalls'),
          type: 'bar',
          color: ['#4FA2FF'],
          tooltip: {
            valueFormatter: function(value) {
              return value;
            },
          },
          data: res.length
            ? res.map(item => ({
                value: item.installCount,
                purchaseOf: item.theInstallationOf,
              }))
            : [],
        },
      ],
    };
  }
  @Watch('option')
  @Watch('skin', { deep: true })
  initChart() {
    const chart = this.$refs.ManageInstallEchart;
    this.myChart = echarts.init(chart);
    this.myChart.setOption(this.option);
  }
}
</script>
<style lang="less" module></style>
