import request from '../request';
export const basePath = '/template-manager/kit';

/**
 * @name 新增套件
 * @returns Promise<{ any[] }>
 */
export function addKit(params) {
  return request(`${basePath}/save`, {
    method: 'POST',
    body: params,
  });
}

/**
 * @name 套件列表
 * @returns Promise<{ any[] }>
 */
export function kitList(params) {
  return request(`${basePath}/list`, {
    method: 'POST',
    body: params,
  });
}

/**
 * @name 套件详情
 */
export function kitDetails(pkId) {
  return request(`${basePath}/${pkId}`, {
    method: 'GET',
  });
}

/**
 * @name 套件编辑
 */
export function kitEdit(data) {
  return request(`${basePath}/${data.pkId}`, {
    method: 'PUT',
    body: data,
  });
}

/**
 * @name 套件上下架
 */
export function kitChangeState(data) {
  return request(`${basePath}/upAndDownState`, {
    method: 'PUT',
    body: data,
  });
}

/**
 * @name 热门套件上下架
 */
export function hotKitChangeState(data) {
  return request(`${basePath}/upAndDownPopular`, {
    method: 'PUT',
    body: data,
  });
}

/**
 * @name 删除套件
 */
export function removeKit(pkId) {
  return request(`${basePath}/${pkId}`, {
    method: 'DELETE',
  });
}

/**
 * @name 套件安装次数
 */
export function installCount(pkId, installCount) {
  return request(
    `${basePath}/updateInstallCount?pkId=${pkId}&installCount=${installCount}`,
    {
      method: 'GET',
    },
  );
}
