<template>
  <a-form-model ref="form" :model="form" :rules="rules">
    <a-form-model-item prop="type" label="抽取类型">
      <a-radio-group v-model="form.type">
        <a-radio value="1">双色球</a-radio>
        <a-radio value="2">幸运奖493个</a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item
      v-if="form.type === '1'"
      prop="code"
      label="输入双色球号码"
    >
      <a-input v-model="form.code" placeholder="红球+蓝球共14位数字"></a-input>
    </a-form-model-item>
    <div>
      <a-button type="primary" @click="getCode">抽取</a-button>
      <async-button
        v-if="form.type === '2' && results.length"
        :class="$style.button"
        type="link"
        :click="batchSet"
        >一键设置幸运奖</async-button
      >
      <!-- <async-button
        v-if="form.type === '2' && results.length && alreadySet"
        :class="$style.button"
        type="link"
        :click="batchSet"
        >一键发送通知</async-button
      > -->
    </div>
    <a-form-model-item label="抽取结果">
      <a-table
        :data-source="results"
        :columns="columns"
        :class="$style.table"
      ></a-table>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  getCodeByDualColoredBall,
  getLuckyCode,
  batchSendAwardMessage,
  batchSetLucky,
} from '@/services/marketing/invite-code';
import { createModal } from '@triascloud/x-components';
import WinningCodeDetails from './winning-code-details.vue';
import { AwardType, AwardTypeText } from '@/enum/activity';
import { AButton } from '@triascloud/x-blocks/dist/ant-design-vue';
import AsyncButton from '@/components/async-button';

function createExtractAwardCodeModal() {
  return createModal(h => h(ExtractAwardCodeModal), {
    title: '抽取中奖码',
    width: 1000,
  });
}
@Component({
  components: { AsyncButton },
})
export default class ExtractAwardCodeModal extends Vue {
  static createModal = createExtractAwardCodeModal;
  alreadySet = false;
  results = [];
  form = {
    type: '1',
    code: '',
  };
  rules = {
    code: [
      {
        required: true,
        message: '请输入双色球号码',
      },
      {
        validator: (_, value, callback) => {
          if (/^[0-9]{14,14}$/.test(value)) {
            callback();
          } else {
            callback('双色球号码格式有误');
          }
        },
      },
    ],
  };
  get columns() {
    return [
      {
        title: this.$t('activity.winningCode'),
        dataIndex: 'award',
      },
      {
        title: this.$t('activity.linkedAccount'),
        key: 'account',
        dataIndex: 'account',
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('activity.associatedWeChat'),
        dataIndex: 'weChat',
        key: 'weChat',
      },
      {
        title: this.$t('activity.associatedName'),
        dataIndex: 'userName',
        key: 'userName',
      },
      {
        title: this.$t('activity.phoneAssociate'),
        dataIndex: 'userPhone',
        key: 'userPhone',
      },
      {
        title: '获取时间',
        dataIndex: 'createTime',
        key: 'createTime',
        customRender: checkDate =>
          checkDate ? this.$moment(checkDate).format('Y-MM-DD HH:mm') : '-',
      },
      {
        title: this.$t('activity.whetherWin'),
        dataIndex: 'isWin',
        key: 'isWin',
        customRender: win =>
          AwardTypeText[win] ? this.$t(AwardTypeText[win]) : '-',
      },
      {
        title: '操作',
        key: 'action',
        customRender: record => (
          <AButton
            type="link"
            onClick={e => {
              e.stopPropagation();
              this.openDetail(record);
            }}
          >
            调整
          </AButton>
        ),
      },
    ];
  }
  async getCode() {
    await this.$refs.form.validate();
    if (this.form.type === '1') {
      this.results = await getCodeByDualColoredBall(this.form.code);
    } else {
      this.results = await getLuckyCode();
    }
  }
  async openDetail(data) {
    await WinningCodeDetails.createDrawer(
      { data: data },
      {
        refresh: isWin => {
          data.isWin = isWin;
        },
      },
    );
  }
  @Watch('form.type')
  reset() {
    this.results = [];
  }
  async batchSet() {
    if (!this.results.length) return this.$message.warning('请先抽取幸运码');
    await this.$confirm('确定设置当前幸运中奖码吗?');
    await batchSetLucky(this.results.map(item => item.award));
    this.$message.success('设置成功');
    this.results.forEach(item => {
      item.isWin = AwardType.Mystery_Award;
    });
    this.alreadySet = true;
  }
  async batchSend() {
    await this.$confirm('确认批量发送消息吗，发送后不可撤回');
    await batchSendAwardMessage(
      this.results
        .filter(item => item.isWin === AwardType.Mystery_Award)
        .map(item => item.award),
    );
  }
}
</script>
<style lang="less" module>
.table {
  min-height: 500px;
}
.button {
  margin-right: 20px;
}
</style>
