/** @name 订单状态 */
export const OrderStatus = {
  /** @name 未支付 */
  UNPAID: 0,
  /** @name 已完成 */
  COMPLETED: 1,
  /** @name 已失效 */
  EXPIRED: -1,
  /** @name 已关闭 */
  CLOSED: -2,
  /** @name 申请退款 */
  REFUNDING: -3,
  /** @name 已退款 */
  REFUNDED: -4,
};

/** @name 客户申请开票状态 */
export const InvoiceStatus = {
  /** @name 未开票 */
  NOT_INVOICED: 0,
  /** @name 已申请 */
  ALREADY_APPLIED: 1,
  /** @name 已开票 */
  INVOICED: 2,
  /** @name 待开票 */
  TO_BE_INVOICED: 3,
};

/** @name 支付方式 */
export const PayWay = {
  /** @name 微信 */
  WECHAT: 1,
  /** @name 支付宝 */
  ALIPAY: 2,
  /** @name 钱包 */
  WALLET: 3,
  /** @name 线下汇款 */
  REMITTANCE: 4,
};

/** @name 发票类型 */
export const InvoiceType = {
  /** @name 普通发票 */
  GENERAL: 'GENERAL',
  /** @name 专用发票 */
  SPECIAL: 'SPECIAL',
};

export const OrderStatusText = {
  [OrderStatus.UNPAID]: '未支付',
  [OrderStatus.COMPLETED]: '已完成',
  [OrderStatus.EXPIRED]: '已失效',
  [OrderStatus.CLOSED]: '已关闭',
  [OrderStatus.REFUNDING]: '申请退款',
  [OrderStatus.REFUNDED]: '已退款',
};

export const InvoiceStatusText = {
  [InvoiceStatus.NOT_INVOICED]: '未开票',
  [InvoiceStatus.ALREADY_APPLIED]: '已申请',
  [InvoiceStatus.INVOICED]: '已开票',
};

export const BillStatusText = {
  [InvoiceStatus.TO_BE_INVOICED]: '待开票',
  [InvoiceStatus.INVOICED]: '已开票',
};

export const PayWayText = {
  [PayWay.WECHAT]: '微信',
  [PayWay.ALIPAY]: '支付宝',
  [PayWay.WALLET]: '余额',
  [PayWay.REMITTANCE]: '线下汇款',
};

export const InvoiceTypeText = {
  [InvoiceType.GENERAL]: '普通发票（电子发票）',
  [InvoiceType.SPECIAL]: '专用发票',
};
/** @name 订单商品类型 */
export const OrderProductType = {
  /** @name 套餐 */
  Combo: 1,
  /** @name 拓展包 || 增量包 */
  Expand: 2,
  /** @name 应用模板 */
  TemplateApplication: 3,
  /** @name 表单模板 */
  TemplateForm: 4,
  /** @name 普通解决方案 */
  SolutionNormal: 5,
  /** @name 远程解决方案 */
  SolutionRemote: 6,
  /** @name 充值 */
  Recharge: 7,
};

export const OrderTypeText = {
  /** @name 企业套餐 */
  [OrderProductType.Combo]: '企业套餐',
  /** @name 增量包 */
  [OrderProductType.Expand]: '拓展包',
  /** @name 应用模板 */
  [OrderProductType.TemplateApplication]: '应用模板',
  /** @name 表单模板 */
  [OrderProductType.TemplateForm]: '表单模板',
  /** @name 普通解决方案 */
  [OrderProductType.SolutionNormal]: '解决方案',
  /** @name 远程升级解决方案 */
  [OrderProductType.SolutionRemote]: '远程解决方案',
  /** @name 钱包充值 */
  [OrderProductType.Recharge]: '钱包充值',
};

/** @name 套餐功能键 */
export const FunctionKey = {
  /** name 表单 */
  FORM: '001',
  /** name 表单套件 */
  FORM_SUITE: '002',
  /** @name 表单图表 */
  FORM_CHART: '003',
  /** @name 模型 */
  MODEL: '004',
  /** @name 表单外链 */
  FORM_EXTERNAL_LINK: '005',
  /** @name 数据处理 */
  DATA_PROCESSING: '006',
  /** @name 数据锁 */
  DATA_LOCK: '007',
  /** @name 扫码录入 */
  FORM_QR_CODE: '008',
  /** @name 公式编辑 */
  FORM_FORMULA: '009',
  /** @name 跨表单数据关联 */
  FORM_RELATION: '010',
  /** @name 表单视图 */
  FORM_VIEW: '011',
  /** @name 手写签名 */
  FORM_SIGNATURE: '012',
  /** @name 自定义主页 */
  CUSTOM_HOMEPAGE: '013',
  /** @name 知识库 */
  KNOWLEDGE: '014',
  /** @name 模板中心 */
  TEMPLATE: '015',
  /** @name 数据字典 */
  DICTIONARY: '016',
  /** @name 云图标库 */
  CLOUD_ICON_LIBRARY: '017',
  /** @name 云图库 */
  CLOUD_GALLERY: '018',
};

/** @name 套餐容量 */
export const CapacityMap = {
  '001': {
    code: '001',
    name: '使用人数',
    unit: '人',
  },
  '002': {
    code: '002',
    name: '管理员人数',
    unit: '人',
  },
  '003': {
    code: '003',
    name: '系统管理员人数',
    unit: '人',
  },
  '004': {
    code: '004',
    name: '应用总数',
    unit: '个',
  },
  '005': {
    code: '005',
    name: '表单数量',
    unit: '个',
  },
  '006': {
    code: '006',
    name: '单表数据上限',
    unit: '条',
  },
  '007': {
    code: '007',
    name: '数据总量',
    unit: '条',
  },
  '008': {
    code: '008',
    name: '每年可上传附件总量',
    unit: 'GB',
  },
  '009': {
    code: '009',
    name: '短信条数',
    unit: '条',
  },
  '010': {
    code: '010',
    name: '附件总容量',
    unit: 'cGB',
  },
  '011': {
    code: '011',
    name: '文字识别总数',
    unit: '次',
  },
  '012': {
    code: '012',
    name: '人脸识别总数',
    unit: '次',
  },
  '013': {
    code: '013',
    name: '数据回收/恢复',
    unit: '天',
  },
  '014': {
    code: '014',
    name: '流程分析',
    unit: '张',
  },
  '015': {
    code: '015',
    name: '自定义打印模板',
    unit: '次',
  },
  '016': {
    code: '016',
    name: '知识库篇数',
    unit: '篇',
  },
};

/** @name 功能名称 */
export const FunctionText = {
  '001': '表单字段',
  '002': '表单套件',
  '003': '表单图表',
  '004': '模型',
  '005': '表单外链',
  '006': '数据流',
  '007': '数据锁',
  '008': '扫码录入',
  '009': '公式编辑',
  '010': '跨表单数据关联',
  '011': '表单视图',
  '012': '手写签名',
  '013': '自定义主页',
  '014': '知识库',
  '015': '模板中心',
  '016': '数据字典',
  '017': '云图标库',
  '018': '云图库',
};

export const CapacityUnitText = Object.keys(CapacityMap).reduce((obj, key) => {
  obj[key] = CapacityMap[key].unit;
  return obj;
}, {});

export const CapacityText = Object.keys(CapacityMap).reduce((obj, key) => {
  obj[key] = CapacityMap[key].name;
  return obj;
}, {});

/** @name 套餐容量键 */
export const CapacityKey = {
  /** @name 使用人数 */
  USER: '001',
  /** @name 管理员人数 */
  MANAGER: '002',
  /** @name 系统管理员人数 */
  SYSTEM_MANAGER: '003',
  /** @name 应用总数 */
  APPLICATION: '004',
  /** @name 表单数量 */
  FORM: '005',
  /** @name 单表数据上限 */
  SINGLE_FORM_DATA: '006',
  /** @name 数据总量 */
  FORM_DATA: '007',
  /** @name 每年可上传附件总量 */
  FILE_PER_YEAR: '008',
  /** @name 短信条数 */
  MESSAGE: '009',
  /** @name 附件总容量 */
  FILE: '010',
  /** @name 文字识别总数 */
  TEXT_RECOGNITION: '011',
  /** @name 人脸识别总数 */
  FACE_RECOGNITION: '012',
  /** @name 数据回收/恢复 */
  RECYCLE_BIN: '013',
  /** @name 流程分析 */
  PROCESS_ANALYSIS: '014',
  /** @name 自定义打印模板 */
  PRINT_TEMPLATE: '015',
  /** @name 知识库篇数 */
  KNOWLEDGE: '016',
};
