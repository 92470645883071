<template>
  <a-spin :spinning="loading" :class="$style.wrap">
    <transition-group>
      <a-popover
        v-model="addVisible"
        placement="bottom"
        trigger="click"
        key="add"
      >
        <div slot="content" :class="$style['form-popoverInput']">
          <a-input
            v-model="labelName"
            :max-length="10"
            :class="$style['form-popoverInput__input']"
            auto-focus
            @pressEnter="addLabel"
          >
            <span slot="suffix">{{ labelName.length }}/10</span>
          </a-input>
          <a-button
            type="primary"
            :class="$style['form-popoverInput__button']"
            @click="addLabel"
            >{{ $t('common.action.confirm') }}</a-button
          >
        </div>
        <a-button type="primary" icon="plus" :class="$style['tag-add']">{{
          $t('dictionary.addTag')
        }}</a-button>
      </a-popover>
      <a-tag
        v-for="(item, index) in labels"
        :key="item.pkId"
        :class="$style.tag"
        :style="{ backgroundColor: item.color }"
      >
        <span :class="$style.tag__name">{{ item.name }}</span>
        <span :class="$style.tag__controllers">
          <custom-color-picker
            @input="handleEditLabelColor($event, item)"
            :class="$style.colorPicker"
          >
            <x-icon type="bg-colors" />
          </custom-color-picker>
          <x-icon
            type="arrow-up"
            :class="$style.tag__controllers__up"
            @click="handOverheadLabel(item.pkId, index)"
          />
          <x-icon
            type="close-circle"
            @click="handleRemoveLabel(item.pkId, index)"
          />
        </span>
      </a-tag>
    </transition-group>
  </a-spin>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import {
  addLabel,
  getLabelList,
  removeLabel,
  editLabelColor,
  overheadLabel,
} from '@/services/template-manager/label';
import { createFormModal } from '@triascloud/x-components';
import { LabelColors } from '@/enum/label';
import { CustomColorPicker } from '@/components/custom-color-picker';
import { globalVueI18n } from '@triascloud/i18n';

function createLabelManageModal() {
  return createFormModal(h => h(LabelManageModal), {
    title: globalVueI18n.translate('market.label.manage'),
    width: 550,
  });
}
@Component({ components: { CustomColorPicker } })
export default class LabelManageModal extends Vue {
  static createModal = createLabelManageModal;
  labelName = '';
  created() {
    this.getLabel();
  }
  loading = false;
  labels = [];
  async getLabel() {
    this.loading = true;
    this.labels = await getLabelList();
    this.loading = false;
  }
  addVisible = false;
  async addLabel() {
    if (!this.labelName.trim()) return;
    await addLabel({
      name: this.labelName,
      color: LabelColors[Math.floor(Math.random() * 5)],
    });
    this.addVisible = false;
    this.labelName = '';
    this.getLabel();
  }
  async handleRemoveLabel(pkId, index) {
    await this.$confirm(this.$t('market.label.deleteConfirm'));
    this.loading = true;
    try {
      await removeLabel(pkId);
      this.labels.splice(index, 1);
      this.$message.success(this.$t('common.tips.delete'));
    } catch (_) {
      // 忽略问题
    } finally {
      this.loading = false;
    }
  }
  async handleEditLabelColor(color, item) {
    item.color = color;
    await editLabelColor({ pkId: item.pkId, color: item.color });
  }
  async handOverheadLabel(pkId, index) {
    if (!index) return;
    this.labels.unshift(this.labels.splice(index, 1)[0]);
    await overheadLabel(pkId);
  }
}
</script>
<style lang="less" module>
.wrap {
  min-height: 400px;
}
.tag-add {
  height: 36px;
  margin-right: 10px;
}
.wrap .tag {
  height: 36px;
  color: #fff;
  cursor: pointer;
  margin: 5px 10px 5px 0;
  padding: 6px 12px;
  line-height: 22px;
  min-width: 70px;
  text-align: center;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    .tag__name {
      opacity: 0;
    }
    .tag__controllers {
      opacity: 1;
    }
  }
  .tag__controllers {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    .tag__controllers__up {
      border: 1px solid #fff;
      border-radius: 50%;
      font-size: 13px;
    }
    i:hover {
      transform: scale(1.1);
    }
  }
}
.form-popoverInput {
  display: flex;
  .form-popoverInput__input {
    flex: 1;
  }
  .form-popoverInput__button {
    height: 30px;
    margin-left: 10px;
  }
}
</style>
