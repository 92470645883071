<template>
  <div :class="$style.banner">
    <application-banner />
  </div>
</template>
<script>
import { createDrawer } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ApplicationBanner from './banner';
function createTemplateBannerDrawer(props, listener) {
  return createDrawer(h => h(TemplateBannerDrawer, { props, on: listener }), {
    title: props.title,
    width: 900,
    bodyStyle: {
      padding: 0,
    },
    wrapClassName: 'template-banner-drawer--wrapper',
  });
}
@Component({ components: { ApplicationBanner } })
export default class TemplateBannerDrawer extends Vue {
  @Prop() data;
  static createModal = createTemplateBannerDrawer;

  created() {}
}
</script>
<style lang="less" module>
.banner {
  padding: 20px;
}
</style>
