<template>
  <div :class="$style.imageBoxWrapper">
    <div :class="$style.imgWrapper">
      <x-oss-image
        :class="$style.imageBox__img"
        :title="imageData.imgName"
        :oss-path="imageData.imgUrl"
        @click.native="
          $preview([{ url: imageData.imgUrl, name: imageData.imgName }])
        "
        size="180"
      />
      <div :class="[$style.imgNumsInfoWrapper, $style.showHover]">
        <!--图片数据-->
        <div :class="$style.numsWrapper">
          <span :class="$style.numsItem">
            <x-icon type="like" />
            {{ imageData.likeNum }}
          </span>
          <span :class="$style.numsItem">
            <x-icon
              type="star"
              :theme="imageData.collectThis ? 'filled' : 'outlined'"
            />
            {{ imageData.collectNum }}
          </span>
          <span :class="$style.numsItem">
            <x-icon type="link" />
            {{ imageData.quoteNum }}
          </span>
        </div>
        <!--图片数据-->

        <!--删除人信息-->
        <div v-if="isDeleted" :class="$style.deleteInfoWrapper">
          <div class="ellipsis">
            {{ $t('dictionary.deletePerson') + '：' + imageData.deleteBy }}
          </div>
          <div class="ellipsis">
            {{
              $t('common.label.deleteDate') +
                '：' +
                $moment(imageData.deleteDate).format('yyyy/MM/DD HH:mm')
            }}
          </div>
        </div>
        <!--删除人信息-->
      </div>
      <div v-if="imageData.elite" :class="$style.eliteTips">
        {{ $t('gallery.label.fine') }}
      </div>
    </div>

    <div :class="$style.infoWrapper">
      <div :class="$style.infoRow">
        <div class="ellipsis" :class="$style.name">
          {{ imageData.imgName }}
        </div>
        <a-tooltip
          v-if="$p.action('UPDATE') && !isDeleted"
          :title="$t('iconLibrary.icon.edit')"
        >
          <x-icon
            :class="[$style.primaryAction, $style.showHover]"
            type="edit"
            @click="handleEditImgName"
          />
        </a-tooltip>
      </div>
      <div :class="$style.infoRow">
        <div class="ellipsis" :class="$style.mid">
          {{
            $t('common.label.belongGroupName') + '：' + imageData.imgGroupName
          }}
        </div>
        <a-tooltip
          v-if="$p.action('UPDATE') && !isDeleted"
          :title="$t('market.template.classify.move')"
        >
          <x-icon
            :class="[$style.primaryAction, $style.showHover]"
            type="tc-icon-change"
            @click="handleRegroup"
          />
        </a-tooltip>
      </div>
      <div :class="[$style.bottom, $style.infoRow]">
        <div class="ellipsis" :class="$style.createName">
          {{ $t('gallery.label.sharer') }}：{{ imageData.createBy }}
        </div>
        <div v-if="!isDeleted" :class="[$style.showHover, $style.moreActions]">
          <a-tooltip
            v-if="$p.action('SET_PREFER_IMAGE')"
            :title="
              imageData.elite
                ? `${$t('common.action.cancel')}${$t('gallery.label.fine')}`
                : $t('gallery.actions.setFine')
            "
          >
            <x-icon
              style="color: #FF9E40"
              :type="imageData.elite ? 'tc-icon-crown-cancel' : 'tc-icon-crown'"
              @click="handleSetElite"
            />
          </a-tooltip>
          <a-tooltip
            v-if="$p.action('DELETE')"
            :title="$t('common.action.delete')"
          >
            <x-icon
              @click="handleDeleteStatusChange(true)"
              type="delete"
              :class="$style.delete"
            />
          </a-tooltip>
          <a-tooltip :title="$t('gallery.label.imgInfoTitle')">
            <x-icon @click="handleShowInfo" type="dash" />
          </a-tooltip>
        </div>
        <a-tooltip
          v-else-if="$p.action('DELETE')"
          :title="$t('iconLibrary.library.undo')"
        >
          <x-icon
            @click="handleDeleteStatusChange(false)"
            :class="$style.recycling"
            type="tc-icon-recovery"
          />
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import RenameImageModal from './RenameImageModal';
import DeleteImageModal from './DeleteImageModal';
import {
  updateImg,
  setElite,
  removeCloudImg,
} from '@/services/resource/cloud-img';
import RegroupImageModal from './RegroupImageModal';
import ImageInfoModal from './ImageInfoModal';
import RecyclingImageModal from './RecyclingImageModal';

@Component()
export default class ImageBox extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return {
        checkStatus: 'PASSED', // 审核状态
        checkBy: '', // 审核人名称
        checkDate: '', // 审核时间
        collectNum: 0, // 收藏数量
        collectThis: true, // 是否收藏
        createBy: '', // 创建人名称
        createDate: '', // 创建时间
        deleteBy: '', // 删除人名称
        deleteDate: '', // 删除时间
        elite: true, // 是否精品
        idxImgGroup: '', // 图片所属分组
        imgGroupName: '', // 图片分组名
        imgName: '', // 图片名称
        imgUrl: '', // 图片路径
        labels: [
          {
            labelColor: '',
            labelName: '',
            pkId: '',
          },
        ], // 标签
        likeNum: 0, // 点赞数
        likeThis: true, // 是否点赞
        pkId: '', //
        quoteNum: 0, // 引用数量
        regainBy: '', // 恢复人名称
        regainDate: '', // 恢复时间
        updateBy: '', // 更新人名称
        updateDate: '', // 更新时间
      };
    },
  })
  imageData;
  @Prop({ type: Boolean, default: false }) isDeleted;
  @Prop({ type: Array, default: () => [] }) imgGroupOptions; // 候选图片分组选项

  /**
   * 编辑图片名称
   * @returns {Promise<void>}
   */
  async handleEditImgName() {
    const { imgName } = await RenameImageModal.createModal({
      imgName: this.imageData.imgName,
    });
    const { pkId } = this.imageData;
    await updateImg({ pkId, imgName });
    this.$message.success(this.$t('common.tips.save'));
    this.handleRefresh();
  }

  /**
   * 处理标记为精品图片
   * @returns {Promise<void>}
   */
  async handleSetElite() {
    const { pkId, elite } = this.imageData;
    const msg = !elite
      ? this.$t('common.tips.addElite')
      : this.$t('common.tips.removeElite');
    await setElite({ pkId, elite: !elite });
    this.$message.success(msg);
    this.handleRefresh();
  }

  /**
   * 处理移动分组
   * @returns {Promise<void>}
   */
  async handleRegroup() {
    const { idxImgGroup, imgGroupName, pkId } = this.imageData;
    const formData = await RegroupImageModal.createModal({
      idxImgGroup,
      imgGroupName,
      imgGroupOptions: this.imgGroupOptions.filter(
        item => item.pkId !== 'All' && item.pkId !== 'Elite',
      ),
    });
    await updateImg({ pkId, idxImgGroup: formData.idxImgGroup });
    this.$message.success(this.$t('common.tips.save'));
    this.handleRefresh();
  }

  async handleShowInfo() {
    await ImageInfoModal.createModal({ imgInfo: this.imageData });
  }

  /**
   * 处理修改删除/恢复状态
   * @param status
   * @returns {Promise<void>}
   */
  async handleDeleteStatusChange(status) {
    const { pkId } = this.imageData;
    status
      ? await DeleteImageModal.createModal(pkId)
      : await RecyclingImageModal.createModal(pkId);
    let msg = status
      ? this.$t('common.tips.delete')
      : this.$t('common.tips.recoverySuccess');
    await removeCloudImg({ pkId, deleted: +status });
    this.$message.success(msg);
    this.handleRefresh();
  }

  @Emit('refresh')
  handleRefresh() {}
}
</script>

<style lang="less" module>
.imageBoxWrapper {
  position: relative;

  &:hover {
    .showHover {
      opacity: 1 !important;
      transition: all 0.3s;
    }
  }
}

.imgWrapper {
  position: relative;
  width: 180px;
  height: 180px;
  overflow: hidden;
  box-shadow: 0 0 14px 0 var(--shadow);
  border-radius: 4px;

  .eliteTips {
    background-color: var(--primary);
    position: absolute;
    top: 0;
    left: 120px;
    color: #fff;
    font-size: var(--font-xs);
    padding: 5px 10px;
    transform: rotate(45deg) translateY(50%);
    text-align: center;
    width: 100px;
    z-index: 99;
    letter-spacing: 1px;
  }

  .imageBox__img {
    width: 180px;
    height: 180px;
    display: block;
    object-fit: cover;
    cursor: pointer;
  }
  .imgNumsInfoWrapper {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    color: var(--font-color-secondary);
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
  }
  .numsWrapper {
    display: flex;
    align-items: center;
    .numsItem {
      padding: 8px 0;
      flex: 1;
      text-align: center;
    }
  }

  .deleteInfoWrapper {
    font-size: var(--font-xs);
    padding: 0 5px;
  }
}

.infoWrapper {
  color: var(--font);
  line-height: 1.5;
  font-size: var(--font-sm);
  padding: 5px 0;
  width: 180px;
  overflow: hidden;
  .infoRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .name {
    color: var(--font-sub);
    width: 90%;
  }
  .mid {
    width: 90%;
  }
  .bottom {
    .createName {
      width: 65%;
      color: var(--font-info);
    }
  }
  .primaryAction {
    color: var(--primary);
    cursor: pointer;
    opacity: 0;
  }
  .moreActions {
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: space-around;
    opacity: 0;
    .delete {
      color: var(--danger);
    }
  }
  .recycling {
    color: var(--primary);
    cursor: pointer;
  }
}
</style>
