// @ts-check
import { BaseControl } from '../BaseControl';
import { ControlTypeEnum } from '../enum';
// @ts-ignore
import ConfigComponent from './config.vue';
// @ts-ignore
import DesignComponent from './design.vue';
// import { generateRangeRule, generateUniqueRule } from '../validate';

/** @name 数组 */
class ArrayControl extends BaseControl {
  /** @readonly */
  type = 'array';

  label = 'controls.array.label';

  icon = 'tc-icon-batch';

  trigger = 'blur';

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    defaultValue: {
      type: 'custom',
      // 默认值
      custom: '',
    },
    identifier: 'ARRAY',
    inParams: ['DEVELOPMENT'],
    outParams: [],
    subType: ControlTypeEnum.Int,
    subCount: 10,
    subName: 'name',
    subIdentifier: 'identifier',
    subGroup: 'specs',
    fields: [],
  });

  actionOpen = true;

  // FormComponent = DesignComponent;

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;
}

export default new ArrayControl();
