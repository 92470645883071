import request from '../request';
const basePath = '/smart-site/ssc/supplierData';

export const getManufacturerList = params =>
  request(`${basePath}/pageData`, {
    method: 'POST',
    body: params,
  });

export const getManufacturerStatistics = () =>
  request(`${basePath}/statistics`);

export const addManufacturer = params =>
  request(`${basePath}/add`, {
    method: 'POST',
    body: params,
  });

export const editManufacturer = params =>
  request(`${basePath}/update`, {
    method: 'POST',
    body: params,
  });

export const deleteManufacturer = id =>
  request(`${basePath}/delete/${id}`, {
    method: 'DELETE',
  });
