<template>
  <div>
    <div v-if="operating === 'DISABLE'">
      <div>
        {{ $t('connector.stopPlatform') }}
      </div>
      <div class="star">
        <p>1、{{ $t('connector.accessPlatform.disableTips1') }}；</p>
        <p>2、{{ $t('connector.accessPlatform.disableTips2') }}；</p>
        <p>3、{{ $t('connector.accessPlatform.disableTips3') }}；</p>
        <p>4、{{ $t('connector.accessPlatform.disableTips4') }}；</p>
      </div>
    </div>
    <div v-else-if="operating === 'RUN'">
      <div>
        {{ $t('connector.enablePlatform') }}
      </div>
      <div class="star">
        <p>1、{{ $t('connector.accessPlatform.enableTips1') }}；</p>
        <p>2、{{ $t('connector.accessPlatform.enableTips2') }}；</p>
        <p>3、{{ $t('connector.accessPlatform.enableTips3') }}；</p>
        <p>4、{{ $t('connector.accessPlatform.enableTips4') }}；</p>
      </div>
    </div>
    <div v-else>
      <div>{{ $t('connector.accessPlatform.delTips1') }}？</div>
      <div class="star">*{{ $t('connector.accessPlatform.delTips2') }}</div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class StopRun extends Vue {
  @Prop({ type: String, default: '' }) operating;
  getValue() {
    return true;
  }
}
</script>

<style scoped>
.star {
  color: red;
}
</style>
