<template>
  <div :class="$style.template">
    <div :class="$style.headerRight">
      <template v-if="review">
        <template v-if="isChecked">
          <a-button @click="checkResult(1)">{{
            $t('common.action.pass')
          }}</a-button>
          <a-button @click="checkResult(0)">{{
            $t('common.action.fail')
          }}</a-button></template
        >
      </template>
      <template v-else>
        <a-button @click="$developing">{{ $t('template.edit') }}</a-button>
        <a-button @click="$developing">{{ $t('template.update') }}</a-button>
      </template>
    </div>
    <preview :data="data" :type="'modal'" :file="fileFunction" />

    <!-- <div :class="$style.templateItem">
      <div :class="$style.templateItem_icon">
        <x-icon
          :class="$style.card__icon"
          type="printer"
          :style="{ backgroundColor: data.style }"
        />
        <x-ellipsis :rows="1" :class="$style.text">
          {{ data.pushName }}
        </x-ellipsis>
      </div>
      <a-button @click="$emit('preview', data)" type="link">模板预览</a-button>
    </div> -->
  </div>
</template>
<script>
import { createModuleUrl } from '@/utils';
import { createDrawer } from '@triascloud/x-components';
import EmptyContent from '@/components/empty-content';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getPrintDetails } from '@/services/template-manager/printTemplate';
import Print from '../pdf';
import { previewPdf } from '@/services/oss/pdf';
import { globalSocket } from '@triascloud/message-hub';
import { OssSocket } from '@/enum/socket';
import Preview from './preview.vue';

function createApplicationPreviewDrawer(props, listener) {
  return createDrawer(h => h(PrintPreviewDrawer, { props, on: listener }), {
    title: props.data.name || props.data.pushName,
    width: 700,
    bodyStyle: {
      padding: 0,
    },
    wrapClassName: 'application-preview-drawer--wrapper',
  });
}
@Component({
  components: {
    EmptyContent,
    Preview,
  },
})
export default class PrintPreviewDrawer extends Vue {
  @Prop() data;
  @Prop({ type: Boolean, default: false }) review;

  static createModal = createApplicationPreviewDrawer;

  get applicationUrl() {
    return (
      createModuleUrl('app') + `/application/preview/${this.data.templateId}`
    );
  }

  async fileFunction() {
    const data = await getPrintDetails(this.data.templateId);
    const pages =
      data.template && data.template.default
        ? await new Print([data.template]).export()
        : [];
    return new Promise((resolve, reject) => {
      previewPdf({ fileName: data.name, pages });
      globalSocket.once(OssSocket.PdfPreview, ({ data }) => {
        if (!data.abort) {
          resolve(data.previewUrl);
        } else {
          reject(new Error());
        }
      });
    });
  }

  momentFormat(time) {
    return moment(time).format('Y-MM-DD H:mm');
  }
  checkResult(type) {
    this.$emit('check', this.data, type);
  }
  isChecked = false;
  created() {
    this.isChecked = this.review && this.data.isChecked === 0;
  }
}
</script>
<style lang="less" module>
.template {
  width: 100%;
  height: 100%;
}

.headerLeft {
  position: absolute;
  top: 0;
  left: 250px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  margin-right: 10px;

  span + span {
    margin-left: 10px;
  }
}

.templateItem {
  width: 300px;
  height: 80px;
  border: 1px solid var(--border);
  margin: 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  align-items: center;
  justify-content: space-between;
}
.templateItem_icon {
  display: flex;
  align-items: center;
  width: 200px;
  i {
    margin-right: 10px;
    font-size: 32px;
  }
}

.headerRight {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 80px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #ffffff;
    margin-right: 10px;
    padding: 0;
    text-align: center;

    &:hover {
      background: #ffffff;
      color: var(--primary);
    }
  }
}

.image__img {
  width: 78px;
  height: 98px;
  object-fit: contain !important;
}
</style>
