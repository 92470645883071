<template>
  <a-form-model :model="form" ref="form" :rules="rules">
    <a-form-model-item label="开发者名称" prop="developmenterName">
      <a-input
        @keyup.enter="nextFocus('remarkRef')"
        autoFocus
        :maxLength="56"
        v-model="form.developmenterName"
        placeholder="请输入开发者名称"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item label="备注" prop="remark">
      <a-input
        ref="remarkRef"
        :maxLength="104"
        type="textarea"
        v-model="form.remark"
        placeholder="请输入备注"
      ></a-input>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Vue, Prop, Component } from 'vue-property-decorator';
import {
  addDeveloper,
  updateDeveloper,
} from '@/services/digital-site/developer';

@Component
export default class DeveloperEditForm extends Vue {
  @Prop({ type: String, default: 'add' }) type;
  @Prop({ type: Object, default: () => {} }) record;

  form = {
    developmenterName: '',
    remark: '',
  };
  rules = {
    developmenterName: [{ required: true, message: '请输入开发者名称' }],
  };
  async getValue() {
    const message = {
      add: '添加成功！',
      edit: '编辑成功',
    };

    await this.$refs.form.validate();
    try {
      await (this.type === 'edit'
        ? updateDeveloper({ ...this.form, pkId: this.record.pkId })
        : addDeveloper(this.form));

      this.$message.success(message[this.type]);
      return true;
    } catch (error) {
      return false;
    }
  }

  nextFocus(name) {
    this.$refs[name].focus();
  }

  initEdit() {
    for (const key in this.form) {
      if (Object.hasOwnProperty.call(this.form, key)) {
        this.form[key] = this.record[key];
      }
    }
  }

  mounted() {
    this.type === 'edit' && this.initEdit();
  }
}
</script>

<style lang="less"></style>
