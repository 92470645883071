<template>
  <div :class="$style.wrapper">
    <!-- 多行文本 -->
    <x-textarea
      v-if="type === 'textarea'"
      :value="value"
      :style="{ height: contentHeight }"
      @input="value => $emit('changeValue', value)"
    />

    <!-- 标签 -->
    <common-label
      v-if="type === 'label'"
      :type="labelType"
      :label-list="value"
      @add-label="label => $emit('changeValue', [label, 'add'])"
      @remove-label="pkId => $emit('changeValue', [pkId, 'remove'])"
    />

    <!-- 添加图片 -->
    <x-upload-button
      v-if="type === 'image'"
      :maxFileSize="5"
      @success="data => $emit('changeValue', data.ossData.ossPath)"
    >
      <div ref="uploadButton" :class="$style.upload">
        <div v-if="!value" :class="$style['init-status']">
          <x-icon type="tc-icon-image-download"></x-icon>
          <p>{{ $t('website.addPicture') }}</p>
        </div>
        <div
          v-else
          :class="$style['active-status']"
          @click="clickImg"
          @mouseover="showHoverStatus = true"
          @mouseout="showHoverStatus = false"
        >
          <x-oss-image :oss-path="value" :class="$style['view-img']" />
          <div v-show="showHoverStatus" :class="$style['active-content']">
            <div :class="$style.delete" @click="deleteImg">
              <x-icon type="tc-icon-image-download"></x-icon>
              <p>{{ $t('common.action.delete') }}</p>
            </div>
            <div :class="$style.replace" @click="$refs.uploadButton.click()">
              <x-icon type="tc-icon-image-download"></x-icon>
              <p>{{ $t('common.action.replace') }}</p>
            </div>
          </div>
        </div>
      </div>
    </x-upload-button>

    <!-- 选择时间 -->
    <a-date-picker
      v-if="type === 'time'"
      :value="value"
      :allowClear="false"
      inputReadOnly
      show-time
      @change="date => (changedDate = date)"
      @ok="$emit('changeValue', changedDate)"
    />

    <!-- 富文本 -->
    <x-editor
      v-if="type === 'editor'"
      :value="value"
      upload-path="/website/setting"
      @input="value => $emit('changeValue', value)"
    />

    <!-- 单选框 -->
    <a-radio-group
      v-if="type === 'radio'"
      :value="value"
      @change="e => $emit('changeValue', e.target.value)"
    >
      <a-radio
        v-for="item in radioList"
        :key="item.pkId"
        :disabled="item.disabled"
        :value="item.pkId"
        >{{ item.classifyName }}</a-radio
      >
    </a-radio-group>

    <!-- 输入框 -->
    <a-input
      v-if="type === 'input'"
      :value="value"
      @change="e => $emit('changeValue', e.target.value)"
    />

    <!-- 上传文件 -->
    <x-upload
      v-if="type === 'upload'"
      type="file"
      mode="summary"
      :max="1"
      :value="value"
      @input="data => $emit('changeValue', [data, 'change'])"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop, Provide } from 'vue-property-decorator';
import { UploadPathSymbol } from '@triascloud/x-components';
import CommonLabel from './common-label.vue';

@Component({
  components: {
    CommonLabel,
  },
})
export default class EditCase extends Vue {
  @Prop({ type: String }) type;
  @Prop({ type: String }) contentHeight;
  @Prop({ type: String }) labelType;
  @Prop({ type: [String, Array, Object, Number] }) value;
  @Prop({ type: Array }) radioList;
  @Provide(UploadPathSymbol) uploadPath = 'website/setting';

  showHoverStatus = false;
  changedDate = '';

  fakeUrl = null;

  // 图片相关
  clickImg(event) {
    event.stopPropagation();
  }
  deleteImg(event) {
    event.stopPropagation();
    this.$emit('changeValue', '');
  }
}
</script>

<style lang="less" module>
.wrapper {
  width: inherit;
}
:global .ant-upload-select-text {
  width: 100%;
}
// 上传图片
.upload {
  border: 1px solid var(--border);
  width: inherit;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  .init-status {
    text-align: center;
    color: var(--font-color-secondary);
  }
  .active-status {
    position: relative;
    width: 100%;
    height: 100%;
    .active-content {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #000;
      opacity: 0.5;
      font-size: 14px;
      div {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:first-of-type {
          margin-right: 21px;
        }
        p:last-of-type {
          margin-left: 4px;
        }
      }
    }
    .view-img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
