<template>
  <div :class="$style.card">
    <x-oss-image
      v-if="data.banner"
      :oss-path="data.banner"
      :class="$style.banner"
    />
    <img v-else :src="defaultBanner" />

    <div :class="$style.cardBot">
      <div :class="$style.cardTlt">
        <x-ellipsis :rows="1">{{ data.name }}</x-ellipsis>
      </div>
      <div :class="$style.cardDesc">
        <x-ellipsis :rows="2">
          <span v-if="data.removeUpgrade === 1">专业方案</span
          >{{ data.description }}
        </x-ellipsis>
      </div>
      <div :class="$style.price">
        {{ $t('market.template.sellingPrice') }}：{{
          data.price === 0
            ? $t('market.template.free')
            : `${data.price / 100}${$t('inviteCode.Yuan')}`
        }}
      </div>
      <!-- <div :class="$style.num">
        {{ $t('market.template.Installs') }}：{{ data.installCount }}
        <x-icon type="eye" v-if="data.installCount" @click="showInstallDesc" />
      </div> -->
      <div :class="$style.create">
        {{ $t('iot.device.creator') }}：{{ data.createUserName }}
      </div>
      <div :class="$style.createTime">
        {{ $t('common.label.createTime') }}：{{
          data.createTime
            ? $moment(data.createTime).format('YYYY.MM.DD HH:mm')
            : ''
        }}
      </div>
    </div>
    <div :class="$style.action">
      <slot></slot>
    </div>
    <div
      :class="[
        $style.status,
        data.state === 0
          ? $style.starStatus
          : data.state === 1
          ? ''
          : $style.downStatus,
      ]"
    >
      <!-- @click="$emit('statusRecord', data.pkId)" -->
      {{
        data.state === 0
          ? $t('template.review.state.notListed')
          : data.state === 1
          ? $t('template.review.state.open')
          : data.state === 2
          ? '被下架'
          : '审核中'
      }}
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component()
export default class CardItem extends Vue {
  @Prop({ type: Object, default: {} }) data;
  //   showInstallDesc() {
  //     this.$emit('showInstallDesc', this.data);
  //   }
  get defaultBanner() {
    return require('@/assets/img/default-banner.png');
  }
}
</script>
<style lang="less" module>
.card {
  border: 1px solid var(--border);
  width: 266px;
  height: 370px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  .banner {
    border-radius: 4px 4px 0 0;
  }
  img {
    width: 100%;
    height: 184px;
  }
  .action {
    display: flex;
    height: 42px;
    opacity: 0.9;
    box-shadow: 0px 0px 5px 0px var(--block-bg);
    position: absolute;
    bottom: 0;
    width: 100%;
    background: var(--block-bg);
    opacity: 0.9;
    align-items: center;
    display: none;
    border-radius: 0px 0px 10px 10px;
    i {
      flex: 1;
      font-size: 18px;
      cursor: pointer;
    }
  }
  &:hover {
    box-shadow: 0px 0px 12px 0px var(--primary-50);
    .action {
      display: flex;
    }
  }
}

.cardBot {
  margin: 0 20px;
  .cardTlt {
    margin-top: 14px;
    font-size: 14px;
    color: var(--font);
    margin-bottom: 10px;
  }
  .cardDesc {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 8px;
    height: 36px;
    overflow: hidden;
    span {
      background: var(--primary-fade-10);
      color: var(--primary-fade-80);
      padding: 2px;
      font-size: 12px;
      margin-right: 5px;
    }
  }
  .price {
    color: #666;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .num {
    color: #666;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .create {
    color: #666;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .createTime {
    color: #666;
    font-size: 14px;
  }
}

.status {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0 0 13px;
  background: var(--success);
  color: #fff;
  padding: 2px 13px;
}

.downStatus {
  background: var(--font-info);
}

.starStatus {
  background: var(--warning);
}

.downEdStatus {
  background: var(--danger);
}
</style>
