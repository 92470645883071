import { ensureArray } from '@triascloud/utils';
import Vue from 'vue';
import { difference, set, has } from 'lodash';
import {
  ControlTypeEnum,
  findFieldParents,
  TemplateType,
} from '@/views/connector/platform-manage/develop/data-template/template-design/form/controls';
import { vuePathSet } from '@/views/connector/platform-manage/develop/data-template/template-design/utils';
import { getList } from '@/services/iot-platform/dataIdentifier';
// import { getDetailList } from '@/services/iot-platform/dataIdentifier';

/** @typedef { import('@/views/connector/platform-manage/develop/data-template/template-design/form/controls/BaseControl').Field } Field */
/** @typedef { import('@/views/connector/platform-manage/develop/data-template/template-design/form/controls/BaseControl').Widget } Widget */

/**
 * @name 表单设计详情
 * @typedef FormFieldData
 * @property { string } appId
 * @property { string } formId
 * @property { boolean } collected
 * @property { number } formType
 * @property { string } name
 * @property { Field[] } items
 */

function getValue(input, param) {
  return typeof input === 'function' ? input(param) : input;
}

/** @type { import('vuex').Module } */
const templateDesign = {
  namespaced: true,
  state: {
    loading: false,
    changed: false,
    selectedField: null,
    fieldTagMap: {},
    /** @type { FormFieldData } */
    form: {
      appId: '',
      formId: '',
      items: [],
      name: '',
      formType: 1,
    },
    types: [], // 数据标识列表
    templateType: '', // 自定义模板 or 物模型模板
    platformId: '',
  },
  mutations: {
    selectField(state, field) {
      state.selectedField = field;
    },
    tagField(state, { field, tag }) {
      Vue.set(state.fieldTagMap, field.pkId, tag);
    },
    privateUpdateFields(state, fields) {
      state.form.items = ensureArray(
        getValue(fields, (state.form && state.form.items) || []),
      );
      state.changed = true;
    },
    updateSelectedField(state, fnOrPath) {
      const obj = state.selectedField;
      if (typeof fnOrPath === 'function') {
        fnOrPath(obj);
      } else if (has(obj, fnOrPath.key)) {
        set(obj, fnOrPath.key, fnOrPath.value);
      } else {
        // eslint-disable-next-line no-console
        console.warn(
          `正在向字段上设置不存在的属性[${fnOrPath.key}]`,
          fnOrPath.value,
        );
        vuePathSet(obj, fnOrPath.key, fnOrPath.value);
      }
      state.changed = true;
    },
    updateTypes(state, types) {
      state.types = types;
    },
    updatePlatformId(state, id) {
      state.platformId = id;
    },
    updateTemplateType(state, type) {
      state.templateType = type;
    },
    reset(state) {
      Object.assign(state, {
        loading: false,
        changed: false,
        selectedField: null,
        fieldTagMap: {},
        /** @type { FormFieldData } */
        form: {
          appId: '',
          formId: '',
          items: [],
          name: '',
          formType: 1,
        },
        types: [], // 数据标识列表
        templateType: '', // 自定义模板 or 物模型模板
      });
    },
  },
  actions: {
    addField({ dispatch, getters }, currentField) {
      if (!currentField) return;
      const { fields, selectedIndex } = getters;
      const index = selectedIndex === -1 ? fields.length : selectedIndex + 1;
      const newFields = [...fields];
      newFields.splice(index, 0, currentField);
      return dispatch('updateFields', newFields);
    },
    deleteField({ dispatch, commit, state, getters }, currentField) {
      const {
        fields,
        selectedIndex,
        selectedFieldParent,
        selectedFieldParentChildren,
      } = getters;
      const newFields = selectedFieldParentChildren.filter(
        field => field.pkId !== currentField.pkId,
      );
      if (
        state.selectedField &&
        selectedIndex !== -1 &&
        currentField.pkId === state.selectedField.pkId
      ) {
        const newSelectedField =
          newFields[selectedIndex] ||
          newFields[newFields.length - 1] ||
          selectedFieldParent;
        commit('selectField', newSelectedField);
      }
      if (selectedFieldParentChildren === fields) {
        dispatch('updateFields', newFields);
        /** @description 异步调用加入回收站接口，不关心是否返回 */
        // recoveryAdd(currentField);
      } else {
        dispatch('updateFields', fields => {
          selectedFieldParent.widget.fields = newFields;
          return fields;
        });
      }
    },
    async updateFields({ commit, getters }, fields) {
      if (Array.isArray(fields) && fields.length > getters.fields.length) {
        const newField = difference(fields, getters.fields)[0];
        await Vue.nextTick();
        // if (!addFieldValidate(newField, getters.fields)) return;
        // if (newField.type === ControlTypeEnum.SerialNumber) {
        //   // 新增流水号字段时，重置自增数
        //   commit('updateSetting', { key: 'serialNumberInitValue', value: 0 });
        // }
        commit('selectField', newField);
      }
      commit('privateUpdateFields', fields);
    },
    async getTypes({ commit }, id) {
      try {
        const types = await getList(id);
        commit('updateTypes', types);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  },
  getters: {
    selectedFieldParents(state, getters) {
      if (!state.selectedField) return [];
      return findFieldParents(state.selectedField.pkId, getters.fields);
    },
    selectedFieldParent(_, { selectedFieldParents }) {
      if (!selectedFieldParents.length) return null;
      return selectedFieldParents[selectedFieldParents.length - 1] || null;
    },
    selectedFieldParentChildren(_, getters) {
      return getters.selectedFieldParent
        ? getters.selectedFieldParent.widget.fields
        : getters.fields;
    },
    selectedIndex(state, getters) {
      if (!state.selectedField) return -1;
      return getters.selectedFieldParentChildren.findIndex(
        field => field.pkId === state.selectedField.pkId,
      );
    },
    fields(state) {
      return ensureArray(state.form && state.form.items);
    },
    /**
     * @name 自定义模板的tab类型（入参or出参）
     */
    paramType(state) {
      if (state.templateType !== TemplateType.CUSTOM_TEMPLATE) return;
      const array = ensureArray(state.form && state.form.items);
      const customParam = array.find(
        v => v.type === ControlTypeEnum.CustomParam,
      );
      if (!customParam) return;
      const currentTab = customParam.widget.tabs.find(
        v => v.id === customParam.widget.current,
      );
      return currentTab && currentTab.type;
    },
  },
};

export default templateDesign;
