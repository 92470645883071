<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="list"
      :pagination="pagination"
      :row-key="record => record.id"
      :loading="tableLoading"
      @change="handlePagination"
    >
      <span slot="customTitle3">
        <span>{{ $t('iot.companyManage.deviceSales') }}</span>
        <a-popover
          ><template #content>
            <p>{{ $t('iot.companyManage.tips1') }}</p> </template
          ><a-icon type="info-circle" />
        </a-popover>
      </span>
      <span slot="customTitle2">
        <span>{{ $t('iot.companyManage.serviceSales') }}</span>
        <a-popover
          ><template #content
            ><p>{{ $t('iot.companyManage.tips2') }}</p> </template
          ><a-icon type="info-circle" />
        </a-popover>
      </span>
      <template slot="serviceDeal" slot-scope="text, record">
        <div :class="$style.btns" @click="showDetails(record, 'serviceDeal')">
          <span>{{ record.serviceDeal }}</span>
        </div>
      </template>
      <template slot="hardwareDeal" slot-scope="text, record">
        <div :class="$style.btns" @click="showDetails(record, 'hardwareDeal')">
          <span>{{ record.hardwareDeal }}</span>
        </div>
      </template>
    </a-table>
    <a-modal
      :title="$t('iot.companyManage.dealDetails')"
      :width="900"
      :visible="detailsVisible"
      :footer="null"
      @cancel="detailsVisible = false"
    >
      <a-table
        bordered
        :closable="true"
        :maskClosable="true"
        :columns="detailsColumns"
        :data-source="detailsList"
        :pagination="detailsPagination"
        :row-key="record => record.id"
        @change="handleDetailsPagination"
      >
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
@Component({
  components: { LayoutContent },
})
export default class SubscribeManage extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  platStatus;
  @Watch('platStatus', { immediate: true })
  handleParamsChange() {
    this.getList();
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => {
          return index + 1;
        },
        title: this.$t('common.label.index'),
        align: 'center',
        width: 80,
      },
      {
        dataIndex: 'tenant',
        title: this.$t('iot.companyManage.useCompanies'),
      },
      {
        dataIndex: 'deviceCount',
        title: this.$t('iot.companyManage.DeviceCounts'),
      },
      {
        dataIndex: 'hardwareDeal',
        slots: { title: 'customTitle3' },
        scopedSlots: { customRender: 'hardwareDeal' },
      },
      {
        dataIndex: 'serviceDeal',
        slots: { title: 'customTitle2' },
        scopedSlots: { customRender: 'serviceDeal' },
      },
      {
        width: 200,
        dataIndex: 'callTime',
        title: this.$t('iot.companyManage.activation'),
        customRender: val => {
          return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
        },
      },
      {
        width: 200,
        dataIndex: 'tenantOwner',
        title: this.$t('iot.companyManage.owner'),
      },
    ];
  }
  list = [
    {
      callTime: 1652776394732,
      apiName: '公共接口',
      apiTag: 'public',
      deviceCount: '10',
      tenant: '广州达蒙',
      serviceDeal: '10次/7000元',
      hardwareDeal: '10台/2000元',
      tenantOwner: '陈佳',
    },
  ];
  statusMap = { open: '启用', stoped: '停用', expired: '过期' };
  selectItem = {};
  tableLoading = false;
  confirmVisible = false;
  confirmLoading = false;
  detailsVisible = false;

  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => ` ${total + this.$t('connector.dataTemplate.itmes')}`,
  };
  getList() {}

  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    // this.getTableList();
  }
  showDetails() {
    this.detailsVisible = true;
  }
  stopService() {
    this.confirmVisible = false;
    this.selectItem.status = 'stoped';
  }
  detailsColumns = [
    {
      width: 200,
      dataIndex: 'dealTime',
      title: this.$t('iot.companyManage.time'),
      customRender: val => {
        return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
      },
    },
    {
      dataIndex: 'name',
      title: this.$t('iot.companyManage.deveiceName'),
    },
    {
      dataIndex: 'price',
      title: this.$t('market.template.price'),
    },
    {
      dataIndex: 'number',
      title: this.$t('iot.companyManage.volume'),
    },
    {
      dataIndex: 'totalPrice',
      title: this.$t('iot.companyManage.turnover'),
    },
  ];
  detailsList = [
    {
      dealTime: 1652776394732,
      name: '阿里云物模型同步接口',
      price: '1000次调用/¥1.00',
      number: 10,
      totalPrice: '¥10.00',
    },
  ];
  detailsPagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };
  handleDetailsPagination() {}
}
</script>
<style lang="less" module>
.primaryColor {
  color: var(--primary);
}
.btns {
  color: var(--primary);
  cursor: pointer;
}
</style>
