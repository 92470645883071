<template>
  <div>
    <field-config-config-desc :desc="$t('controls.desc.input')" />
    <field-config-title v-bind="fieldProps" />
    <field-config-required v-bind="fieldProps" />
    <parameter-object v-if="!isRes" v-bind="fieldProps" isInOrOut="in" />
    <parameter-object v-if="isRes" v-bind="fieldProps" isInOrOut="out" />
    <a-form-model-item v-if="isRes" prop="widget.source" label="图片来源">
      <a-select
        :value="field.widget.source"
        @change="val => handleInput('widget.source', val)"
      >
        <a-select-option v-for="i in sources" :key="i" :value="i">
          {{ i }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item v-if="isRes" prop="widget.isStore">
      <template slot="label">
        <label>订阅计划转存文件</label>
        <a-tooltip :arrowPointAtCenter="true" placement="right">
          <span slot="title"
            >仅用于订阅计划转存文件，若订阅计划的规则存储判定文件地址为空会转存失败</span
          >
          <a-icon type="info-circle" :class="$style.tooltipIcon" />
        </a-tooltip>
      </template>
      <a-radio-group
        :value="field.widget.isStore"
        :options="inStoreOptions"
        @change="e => handleInput('widget.isStore', e.target.value)"
      />
    </a-form-model-item>
    <a-form-model-item
      v-if="isRes && field.widget.isStore"
      prop="widget.storeSize"
    >
      <template slot="label">
        <label>文件转存大小限制</label>
        <a-tooltip :arrowPointAtCenter="true" placement="right">
          <span slot="title"
            >在订阅计划中，若超过限制则直接存储文件原始URL</span
          >
          <a-icon type="info-circle" :class="$style.tooltipIcon" />
        </a-tooltip>
      </template>
      <a-select
        :value="field.widget.storeSize"
        @change="val => handleInput('widget.storeSize', val)"
      >
        <a-select-option
          v-for="i in countArray"
          :key="(i + 9).toString(36) + i"
          :value="i"
        >
          {{ i }}M
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item v-if="!isRes" prop="widget.maxSize" label="文件大小限制">
      <a-select
        :value="field.widget.maxSize"
        @change="val => handleInput('widget.maxSize', val)"
      >
        <a-select-option
          v-for="i in sizeArray"
          :key="(i + 9).toString(36) + i"
          :value="i"
        >
          {{ i }}M
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item v-if="!isRes" prop="widget.maxCount" label="文件个数">
      <a-select
        :value="field.widget.maxCount"
        @change="val => handleInput('widget.maxCount', val)"
      >
        <a-select-option
          v-for="i in countArray"
          :key="i.toString(36) + i"
          :value="i"
        >
          {{ i }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <field-config-description v-bind="fieldProps" />
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import FieldConfigTitle from '../components/field-config/title.vue';
import FieldConfigRequired from '../components/field-config/required.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';
import ParameterObject from '../components/field-config/parameter-object.vue';
import { ParamType } from '@/views/connector/platform-manage/develop/data-template/template-design/form/controls';
import { templateDesignModule } from '@/enum/store';

@Component({
  components: {
    FieldConfigTitle,
    FieldConfigRequired,
    FieldConfigDescription,
    FieldConfigConfigDesc,
    ParameterObject,
  },
})
export default class ImageConfig extends Vue {
  @Prop() field;
  @Prop() updateField;
  @templateDesignModule.Getter paramType;
  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
    };
  }
  /** @name 出参 */
  get isRes() {
    return this.paramType === ParamType.RES_PARAM;
  }
  handleInput(key, value) {
    this.updateField({ key: key, value: value });
  }
  sizeArray = [1, 5, 10, 20, 50];
  countArray = [1, 5, 10, 20, 50];
  sources = ['URL', 'Base64'];
  inStoreOptions = [
    { label: '关闭', value: false },
    { label: '开启', value: true },
  ];
}
</script>

<style lang="less" module>
.ml10 {
  margin-left: 10px;
}
.section {
  display: flex;
  width: 100%;
  .item {
    flex: 1;
  }
  .label2 {
    width: 32px;
  }
  .label1 {
    width: 15px;
  }
}
.tooltipIcon {
  vertical-align: middle !important;
  margin-left: 5px;
}
</style>
