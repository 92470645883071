<template>
  <a-form-model
    ref="form"
    :model="formData"
    :rules="readonly ? null : rules"
    :class="$style.form"
  >
    <a-form-model-item prop="appName" :label="$t('market.template.formName')">
      <a-input v-model="formData.appName" :max-length="30">
        <template v-slot:suffix>{{ formData.appName.length }}/30</template>
      </a-input>
    </a-form-model-item>
    <a-form-model-item
      prop="instruction"
      :label="name + $t('market.template.instruction')"
    >
      <a-input v-model="formData.instruction" :max-length="30">
        <template v-slot:suffix>{{ formData.instruction.length }}/30</template>
      </a-input>
    </a-form-model-item>
    <a-form-model-item :label="$t('market.template.virtualInstalls')">
      <a-input-number v-model="formData.virtualInstallCount" />
    </a-form-model-item>
    <a-form-model-item
      prop="price"
      :label="$t('market.template.priceSetting')"
      :class="$style.price"
    >
      <a-radio-group v-model="isFree">
        <a-radio :value="true">{{ $t('market.template.free') }}</a-radio>
        <a-radio :value="false">
          {{ $t('market.template.price') }}
          <a-input-number
            v-show="!isFree"
            v-model="price"
            :min="0"
            :precision="2"
            type="number"
          />
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item prop="cover">
      <span slot="label">
        {{ $t('market.template.cover') }}
        <span>{{
          $t('market.template.recommendedSize', { value: '1920*1080' })
        }}</span>
      </span>
      <x-upload v-model="formData.cover" :max="1" :max-file-size="5"></x-upload>
    </a-form-model-item>
    <a-form-model-item
      prop="installScore"
      :label="$t('market.template.getPoints')"
    >
      <a-input v-model="formData.installScore" />
    </a-form-model-item>
    <label-select v-model="formData.labelIds" />
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop, Provide } from 'vue-property-decorator';
import { globalVueI18n } from '@triascloud/i18n';
import { createFormModal, UploadPathSymbol } from '@triascloud/x-components';
import {
  editFormTemplate,
  getFormTemplateDetail,
} from '@/services/template-manager/template';
import LabelSelect from './label-select';
function createFormSettingModal(props) {
  return createFormModal(h => h(FormSettingModal, { props }), {
    title:
      globalVueI18n.translate('market.template.form') +
      globalVueI18n.translate('market.template.setting'),
    width: 500,
  });
}
@Component({
  components: { LabelSelect },
})
export default class FormSettingModal extends Vue {
  static createModal = createFormSettingModal;
  @Prop({ type: String }) templateId;
  @Prop({ type: Function }) service;
  @Prop({ type: Boolean, default: false }) readonly;
  @Provide(UploadPathSymbol) uploadPath = 'common/screenshot/market/';

  formData = {
    id: '',
    appName: '',
    instruction: '',
    installScore: '',
    labelIds: [],
    cover: [],
    virtualInstallCount: 0,
  };
  isFree = true;
  price = '';
  get name() {
    return this.$t('market.template.form');
  }
  get rules() {
    return {
      appName: [
        {
          required: true,
          message:
            this.$t('common.placeholder.input') +
            this.$t('market.template.formName'),
        },
        {
          min: 2,
          max: 30,
          message: this.$t('common.validate.length', { value: '2~30' }),
        },
      ],
      cover: [
        {
          required: true,
          message: this.$t('market.template.coverImageTip'),
        },
      ],
      instruction: {
        max: 30,
        message: this.$t('market.template.introductionValidateTip'),
      },
      installScore: {
        pattern: /(^[0-9]\d*$)/,
        message: this.$t('market.template.integralValidateTip'),
      },
    };
  }
  get computedService() {
    return this.service || getFormTemplateDetail;
  }
  get formatFormData() {
    return {
      ...this.formData,
      labelIds: this.formData.labelIds.map(item => item.pkId),
      name: this.formData.appName,
      price: !this.isFree && this.price ? this.price * 100 : 0,
      cover:
        this.formData.cover && this.formData.cover.length
          ? this.formData.cover[0].ossPath
          : this.formData.cover,
    };
  }
  async created() {
    const data = await this.computedService(this.templateId);
    this.formData = {
      ...data,
      installScore: data.installScore >= 0 ? data.installScore : '',
      price: data.price === -1 ? 0 : data.price,
      virtualInstallCount:
        data.virtualInstallCount === -1 ? 0 : data.virtualInstallCount,
      cover: data.cover ? [{ name: '', ossPath: data.cover }] : [],
    };
    this.price = this.formData.price ? this.formData.price / 100 : '';
    this.isFree = !this.price;
  }
  async getValue() {
    await this.$refs.form.validate();
    await editFormTemplate(this.formatFormData);
    this.$message.success(this.$t('common.tips.save'));
  }
}
</script>
<style lang="less" module>
.form :global .ant-form-item {
  margin-bottom: 10px;
  .x-upload--fileList-oneFile-name,
  .x-upload--fileList-oneFile-icon-edit {
    display: none;
  }
}
.price {
  :global(.ant-form-item-children) {
    display: flex;
    align-items: center;
    height: 32px;
  }
}
.title {
  font-size: 16px;
  color: var(--font-sub);
  margin-bottom: 10px;
}
.textarea {
  position: relative;
  .textarea__count {
    position: absolute;
    bottom: 4px;
    right: 10px;
    color: var(--font-info);
  }
  :global .ant-input {
    height: 86px;
  }
}
</style>
