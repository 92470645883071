import request from '../request';
export const basePath = '/template-manager/plugin';

/**
 * @name 获取插件概览
 * @returns Promise<{ any[] }>
 */
export function getDbServiceByType(type) {
  return request(`${basePath}/statistics/dbServiceBase/${type}`);
}

/**
 * @name 获取数据库连接数据
 * @returns Promise<{ any[] }>
 */
export function getDbServiceList(params) {
  return request(`${basePath}/statistics/dbServiceTenantDetail`, {
    method: 'POST',
    body: params,
  });
}
/**
 * @name 插件概览
 */
export function getOverViewList() {
  return request(`${basePath}/statistics/overView`, {
    method: 'GET',
  });
}
