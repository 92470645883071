<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-row :gutter="15" :class="$style.rowClass">
      <a-col>
        <a-form-model-item prop="apiType">
          <span slot="label">
            API类型
            <span>（说明：{{ apiTypeText[form.apiType] }}）</span>
          </span>
          <a-radio-group
            v-model="form.apiType"
            :options="apiTypeList"
            @change="apiTypeChange"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="15" :class="$style.rowClass">
      <a-col :span="12">
        <a-form-model-item ref="callApi" label="前置调用" prop="idxPreCallApi">
          <a-select
            v-model="form.commonParam"
            :disabled="commonParamDisabled"
            style="width: 120px"
            @change="changeCommonParam"
          >
            <a-select-option value="NONE">不关联</a-select-option>
            <a-select-option value="PUBLIC_AUTHENTICATION">
              公共/鉴权
            </a-select-option>
          </a-select>
          <a-select
            v-if="form.commonParam === 'PUBLIC_AUTHENTICATION'"
            v-model="form.idxPreCallApi"
            placeholder="请选择"
            style="width: 120px;margin-left: 10px"
            @blur="
              () => {
                $refs.callApi.onFieldBlur();
              }
            "
            @change="
              () => {
                $refs.callApi.onFieldChange();
              }
            "
          >
            <a-select-option
              v-for="item in authList"
              :key="item.pkId"
              :value="item.pkId"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col v-if="showCallFrequency" :span="12">
        <a-form-model-item prop="preCallFrequency">
          <span slot="label">
            前置调用频率
            <a-tooltip
              title="限0.00-720.00小时，当为0时表示每次都前置调用关联的接口；一般而言公共参数接口需要实时调用，鉴权接口有效期从数分钟到数天不等，具体视介入平台而定"
              placement="right"
            >
              <a-icon type="info-circle" />
            </a-tooltip>
          </span>
          <a-input-number
            v-model="form.preCallFrequency"
            :min="0"
            :max="720"
            :step="0.01"
            :precision="2"
          />
          <span style="margin-left:10px;color:var(--font-info)">小时</span>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="15" :class="$style.rowClass">
      <a-col>
        <a-form-model-item>
          <span slot="label">
            触发方式
            <span>（说明：{{ triggerWayText[form.triggerWay] }}）</span>
          </span>
          <a-radio-group
            v-model="form.triggerWay"
            :options="triggerWays"
            @change="triggerWayChange"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="15" :class="$style.rowClass">
      <a-col :span="12">
        <a-form-model-item prop="qps">
          <span slot="label">
            QPS限制
            <a-tooltip
              title="请根据对接API的实际情况配置，超出配置后将暂缓用户请求，若无限制要求请填写0"
              placement="right"
            >
              <a-icon type="info-circle" />
            </a-tooltip>
          </span>
          <a-input-number
            v-model="form.qps"
            :min="0"
            :max="9999"
            @change="onQpsChange"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item ref="date" prop="dateQps">
          <span slot="label">
            订阅用户调用频率
            <a-tooltip
              :arrowPointAtCenter="true"
              :overlayClassName="$style.w400"
              placement="right"
            >
              <div slot="title" :class="$style.title">
                控制单个订阅用户调用接口的频率，当调用超过此设置时会提示“请求过于频繁！”，请根据当前API的QPS以及订阅用户数合理设置！
              </div>
              <a-icon type="info-circle" />
            </a-tooltip>
          </span>
          <div style="display: flex">
            <a-select v-model="form.date" style="width: 90px">
              <a-select-option value="MINUTE">每分钟</a-select-option>
              <a-select-option value="HOUR">每小时</a-select-option>
              <a-select-option value="DAY">每天</a-select-option>
              <a-select-option value="MONTH">每月</a-select-option>
            </a-select>
            <span style="padding: 0 10px">不超过</span>
            <a-input-number
              v-model="form.dateQps"
              :min="0"
              @blur="
                () => {
                  $refs.date.onFieldBlur();
                }
              "
              @change="
                () => {
                  $refs.date.onFieldChange();
                }
              "
            />
            <span style="margin-left: 10px">次</span>
          </div>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="15" :class="$style.rowClass">
      <a-col :span="12">
        <a-form-model-item prop="btnTxt">
          <span slot="label">
            触发按钮文案
            <a-tooltip :arrowPointAtCenter="true" placement="right">
              <div slot="title">
                用户点击使用此接口的按钮文案，限8字符长度！
              </div>
              <a-icon type="info-circle" />
            </a-tooltip>
          </span>
          <a-input v-model="form.btnTxt" :maxLength="8" />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-form-model-item>
      <div :class="$style.btns">
        <a-button @click="$emit('close')">取消</a-button>
        <a-button type="primary" style="margin-left: 10px" @click="save()"
          >保存</a-button
        >
      </div>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import {
  getAuthList,
  getTriggerSettings,
  updateTriggerSettings,
} from '@/services/iot-platform/platformInterface';

/** @names API类型 */
const API_TYPE = {
  /** @names 业务调用 */
  BUSINESS: 'BUSINESS',
  /** @names 数据订阅 */
  SUBSCRIPTION: 'SUBSCRIPTION',
  /** @names 公共-鉴权 */
  PUBLIC_AUTHENTICATION: 'PUBLIC_AUTHENTICATION',
};

/** @name 触发方式 */
const TRIGGER_WAY = {
  /** @name 手动 */
  MANUAL: 'MANUAL',
  /** @name 定时 */
  TIMING: 'TIMING',
  /** @name 被动 */
  PASSIVE: 'PASSIVE',
};

@Component()
export default class TriggerConfig extends Vue {
  @Prop({ type: String, default: '' }) apiId;
  @Prop({ type: String, default: '' }) platformId;

  mounted() {
    this.getTriggerSetting();
    this.getAuthLists();
  }

  form = {
    apiType: 'BUSINESS',
    triggerWay: '',
    idxPreCallApi: undefined,
    commonParam: 'NONE',
    date: 'MINUTE',
    dateQps: 0,
    qps: 5,
    btnTxt: '调用',
    preCallFrequency: 0,
  };
  rules = {
    idxPreCallApi: [
      {
        type: 'string',
        required: true,
        validator: this.checkPreCallApi,
      },
    ],
    dateQps: [
      {
        type: 'string',
        required: true,
        validator: this.checkQps,
      },
    ],
    btnTxt: [
      {
        type: 'string',
        required: true,
        message: '请输入触发按钮文案',
      },
    ],
  };
  checkQps(rule, value, callback) {
    const type = this.form.date;
    const qps = this.form.qps;
    const qpsCallback = (value, diff, type, callback) => {
      const obj = {
        MINUTE: 60,
        HOUR: 3600,
        DAY: 86400,
        MONTH: 2592000,
      };
      if (value > qps * obj[type]) {
        callback(new Error('当前调用频率超过QPS设置值'));
      } else {
        callback();
      }
    };
    if (qps !== 0) {
      qpsCallback(value, qps, type, callback);
    } else {
      callback();
    }
  }
  checkPreCallApi(rule, value, callback) {
    const type = this.form.apiType;
    const choose = this.form.commonParam;
    if (type !== API_TYPE.PUBLIC_AUTHENTICATION) {
      if (choose === API_TYPE.PUBLIC_AUTHENTICATION) {
        if (!value) {
          callback(new Error('请选择公共/鉴权连接器'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    } else {
      callback();
    }
  }
  changeCommonParam(val) {
    if (val === API_TYPE.PUBLIC_AUTHENTICATION) {
      this.form.idxPreCallApi = undefined;
    }
  }
  authList = [];
  async getAuthLists() {
    try {
      const res = await getAuthList(this.platformId);
      this.authList = res.map(v => {
        return {
          pkId: v.pkId,
          label: v.apiName,
        };
      });
    } catch {
      return false;
    }
  }
  isSame(val) {
    return this.form.apiType === val;
  }
  get showCallFrequency() {
    return this.form.apiType === API_TYPE.PUBLIC_AUTHENTICATION;
  }
  triggerWayText = {
    [TRIGGER_WAY.MANUAL]: '需手动点击方可触发当前API的调用',
    [TRIGGER_WAY.TIMING]: '支持创建定时任务调用API，请根据QPS情况合理设置',
    [TRIGGER_WAY.PASSIVE]: '过其它接口（例如业务接口关联）或者订阅数据推送触发',
  };
  get triggerWays() {
    return [
      {
        label: '手动触发',
        value: TRIGGER_WAY.MANUAL,
        disabled: !this.isSame(API_TYPE.BUSINESS),
      },
      {
        label: '定时触发',
        value: TRIGGER_WAY.TIMING,
        disabled: this.isSame(API_TYPE.PUBLIC_AUTHENTICATION),
      },
      {
        label: '被动触发',
        value: TRIGGER_WAY.PASSIVE,
        disabled: this.isSame(API_TYPE.BUSINESS),
      },
    ];
  }
  commonParamDisabled = false;
  apiTypeText = {
    BUSINESS: '支持实时/定时调用完成API请求',
    SUBSCRIPTION: '数据订阅的连接管道，具体的存储内容需在“数据管理”中配置任务',
    PUBLIC_AUTHENTICATION:
      '为业务调用、数据订阅接口提供前置鉴权，自身需要在“绑定接口”处关联平台接口来完成完整操作',
  };
  apiTypeList = [
    {
      label: '业务调用',
      value: 'BUSINESS',
    },
    {
      label: '数据订阅',
      value: 'SUBSCRIPTION',
    },
    {
      label: '公共/鉴权',
      value: 'PUBLIC_AUTHENTICATION',
    },
  ];
  apiTypeChange(v) {
    const val = v.target.value;
    this.form.apiType = val;
    this.$nextTick(() => {
      this.$refs.form.clearValidate(['idxPreCallApi']);
      this.form.commonParam = 'NONE';
      this.form.idxPreCallApi = undefined;
    });
    this.commonParamDisabled = val === 'PUBLIC_AUTHENTICATION';
    if (val === API_TYPE.BUSINESS) {
      this.form.triggerWay = TRIGGER_WAY.MANUAL;
    }
    if (val === API_TYPE.SUBSCRIPTION) {
      this.form.triggerWay = TRIGGER_WAY.TIMING;
    }
    if (val === API_TYPE.PUBLIC_AUTHENTICATION) {
      this.form.triggerWay = TRIGGER_WAY.PASSIVE;
    }
  }
  triggerWayChange(v) {
    this.form.triggerWay = v.target.value;
  }
  onQpsChange(v) {
    this.form.qps = v;
    this.$refs.form.validateField('dateQps', this.checkQps);
  }
  @Debounce(200)
  async save() {
    try {
      const isValidate = await this.$refs.form.validate();
      if (isValidate) {
        const params = {
          apiType: this.form.apiType,
          citingPublicParameters: this.form.commonParam,
          copyWriting: this.form.btnTxt,
          frequency: this.form.date,
          frequencyCount: this.form.dateQps,
          pkId: this.apiId,
          queriesPerSecond: this.form.qps,
          triggerMethod: this.form.triggerWay,
        };
        if (this.form.commonParam === API_TYPE.PUBLIC_AUTHENTICATION) {
          params['idxPreCallApi'] = this.form.idxPreCallApi;
        }
        if (this.isSame(API_TYPE.PUBLIC_AUTHENTICATION)) {
          params['preCallFrequency'] = this.form.preCallFrequency * 3600;
        }
        await updateTriggerSettings(params);
        this.$message.success('保存成功');
        this.$emit('refresh');
        await this.getTriggerSetting();
      }
    } catch {
      return false;
    }
  }
  async getTriggerSetting() {
    try {
      const res = await getTriggerSettings(this.apiId);
      this.form.apiType = res.apiType;
      this.form.triggerWay = res.triggerMethod;
      this.form.btnTxt = res.copyWriting;
      this.form.date = res.frequency;
      this.form.dateQps = res.frequencyCount;
      this.form.qps = res.queriesPerSecond;
      this.form.commonParam = res.citingPublicParameters;
      this.commonParamDisabled = res.apiType === 'PUBLIC_AUTHENTICATION';
      if (this.form.commonParam === API_TYPE.PUBLIC_AUTHENTICATION) {
        this.form.idxPreCallApi = res.idxPreCallApi;
      }
      if (this.isSame(API_TYPE.PUBLIC_AUTHENTICATION)) {
        this.form.preCallFrequency = res.preCallFrequency / 3600;
      }
    } catch {
      return false;
    }
  }
}
</script>
<style lang="less" module>
.tooltipIcon {
  margin: 0 5px;
  z-index: 101;
  position: relative;
  vertical-align: middle !important;
}
.w800 {
  :global {
    .ant-tooltip-content {
      width: 800px;
    }
  }
  .title {
    width: 800px;
  }
  .lh4 {
    line-height: 1.6;
  }
  .margin1 {
    margin-left: 40px;
  }
}
.w400 {
  :global {
    .ant-tooltip-content {
      width: 400px;
    }
  }
  .title {
    width: 400px;
  }
  .lh4 {
    line-height: 1.6;
  }
  .margin1 {
    margin-left: 40px;
  }
}
.btns {
  display: flex;
  justify-content: flex-end;
}
.rowClass {
  margin-bottom: 20px;
}
</style>
