import request from '../request';
const serviceNameTemplate = '/iot-platform/dataTemplate';
const serviceNameFileds = '/iot-platform/templateField';

/**@name 获取数据模板列表 */
export function getTemplateList(data) {
  return request(`${serviceNameTemplate}`, {
    method: 'GET',
    body: data,
  });
}

/**@name 创建数据模板 */
export function createTemplate(data) {
  return request(`${serviceNameTemplate}`, {
    method: 'POST',
    body: data,
  });
}
/**@name 是否有物模型模板 */
export function hasThingTemplate(platformId) {
  return request(`${serviceNameTemplate}/hasModel/${platformId}`, {
    method: 'GET',
  });
}

/**@name 删除物模型模板 */
export function deleteTemplateById(dataTemplateId) {
  return request(`${serviceNameTemplate}/${dataTemplateId}`, {
    method: 'DELETE',
  });
}

/**@name 获取物模型模板 */
// export function getTemplateById(templateId) {
//   return request(`${serviceNameFileds}/${templateId}`, {
//     method: 'GET',
//   });
// }

export function getTemplateById(templateId) {
  return request(`${serviceNameFileds}/${templateId}`, {
    method: 'GET',
  });
}

/**@name 修改物模型模板名称 */
export function editTemplateName(data) {
  return request(`${serviceNameTemplate}`, {
    method: 'PUT',
    body: data,
  });
}

/**@name 修改物模型模板 */
export function editTemplate(data) {
  return request(`${serviceNameFileds}`, {
    method: 'PUT',
    body: data,
  });
}

/**@name 导出物模型模板 */
export function exportTemplate(dataTemplateId) {
  return request(`${serviceNameTemplate}/export/${dataTemplateId}`, {
    method: 'GET',
  });
}

/**@name 导入物模型模板 */
export function importTemplate(data) {
  return request(`${serviceNameTemplate}/import`, {
    method: 'POST',
    body: data,
  });
}
