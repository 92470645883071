export const conditionMap = {
  int: ['eq', 'notEq', 'lt', 'le', 'gt', 'ge', 'null', 'notNull'],
  int32: ['eq', 'notEq', 'lt', 'le', 'gt', 'ge', 'null', 'notNull'],
  float: ['eq', 'notEq', 'lt', 'le', 'gt', 'ge', 'null', 'notNull'],
  double: ['eq', 'notEq', 'lt', 'le', 'gt', 'ge', 'null', 'notNull'],
  date: ['eq', 'notEq', 'lt', 'le', 'gt', 'ge', 'null', 'notNull'],
  text: ['eq', 'notEq', 'contains', 'notContains', 'null', 'notNull'],
  enum: ['eq', 'notEq', 'contains', 'notContains', 'null', 'notNull'],
  bool: ['eq', 'notEq'], // TODO boolean 还是 bool
  array: ['eq', 'notEq', 'contains', 'notContains', 'null', 'notNull'],
  struct: ['eq', 'notEq', 'contains', 'notContains', 'null', 'notNull'],
  image: ['null', 'notNull'],
  file: ['null', 'notNull'],
};
export const OperationEnum = {
  eq: '等于',
  notEq: '不等于',
  lt: '小于',
  le: '小于等于',
  gt: '大于',
  ge: '大于等于',
  null: '为空',
  notNull: '不为空',
  contains: '包含',
  notContains: '不包含',
};
export const CompareMap = {
  /** @name 等于 */
  eq: 'EQ',
  /** @name 不等于 */
  notEq: 'NOTEQ',
  /** @name 包含 */
  contains: 'CONTAINS',
  /** @name 不包含 */
  notContains: 'NOTCONTAINS',
  /** @name 为空 */
  null: 'NULL',
  /** @name 不为空 */
  notNull: 'NOTNULL',
  /** @name 小于 */
  lt: 'LT',
  /** @name 小于等于 */
  le: 'LE',
  /** @name 大于 */
  gt: 'GT',
  /** @name 大于等于 */
  ge: 'GE',
};
export const CompareEnum = {
  /** @name 等于 */
  EQ: 'eq',
  /** @name 不等于 */
  NOTEQ: 'notEq',
  /** @name 包含 */
  CONTAINS: 'contains',
  /** @name 不包含 */
  NOTCONTAINS: 'notContains',
  /** @name 为空 */
  NULL: 'null',
  /** @name 不为空 */
  NOTNULL: 'notNull',
  /** @name 小于 */
  LT: 'lt',
  /** @name 小于等于 */
  LE: 'le',
  /** @name 大于 */
  GT: 'gt',
  /** @name 大于等于 */
  GE: 'ge',
};
