<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <!-- <p>当前平台设备容量10/500</p> -->
    <a-form-model-item label="选择产品" prop="production">
      <SelectProduct :platformId="platformId" v-model="form.production" />
    </a-form-model-item>
    <a-form-model-item label="添加方式" prop="addType">
      <a-radio-group v-model="form.addType">
        <a-radio value="manual">手动添加</a-radio>
        <a-radio value="excel">Excel导入</a-radio>
        <a-radio value="api">接口导入</a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item v-if="form.addType === 'excel'" label="" prop="fileName">
      <div :class="$style.formWrap">
        <a-upload
          name="fileName"
          :multiple="false"
          :before-upload="beforeUpload"
        >
          <a-button>
            <a-icon type="upload" />选择按模板填写的Excel导入
          </a-button>
        </a-upload>
        <a
          :class="$style.download"
          href="https://trias-aiot.oss-cn-shenzhen.aliyuncs.com/common/iot_platform_device.xlsx"
          download="设备导入模板.xlsx"
          >下载模板</a
        >
      </div>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import SelectProduct from '@/views/connector/platform-manage/develop/device-manage/device-form/select-product.vue';

@Component({
  components: { SelectProduct },
})
export default class DeviceForm extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  form = {
    production: undefined,
    addType: 'manual',
    fileName: '',
    file: '',
  };

  rules = {
    production: [
      {
        required: true,
        message: '请选择产品',
      },
    ],
    fileName: [
      {
        required: true,
        message: '请选择Excel',
      },
    ],
    addType: [
      {
        required: true,
      },
    ],
  };
  beforeUpload(file) {
    this.form.fileName = file.name;
    this.form.file = file;
    return false;
  }

  async getValue() {
    if (this.form.addType === 'api') {
      this.$message.warn('即将上线，敬请期待！');
      return Promise.reject(false);
    }
    await this.$refs.form.validate();
    return this.form;
  }
}
</script>
<style lang="less" module>
.formWrap {
  display: flex;
  align-items: center;
  .download {
    margin-left: 20px;
  }
}
</style>
