export const DataFormContext = {
  /** @name 表单根节点 */
  Root: Symbol('DataFormContext.Root'),
  /** @name 用于表单递归渲染 */
  Item: Symbol('DataFormContext.Item'),
  /** @name 当前字段路径 */
  Path: Symbol('DataFormContext.Path'),
  /** @name 当前数据模型层 */
  Model: Symbol('DataFormContext.Model'),
  /** @name 外部注入的表单额外元信息 */
  Meta: Symbol('DataFormContext.Meta'),
};

/** @constant */
export const DataFormFilterType = {
  All: 'All',
  Contains: 'Contains',
  Writable: 'Writable',
  Required: 'Required',
};
