<template>
  <inline-list :list="data" :limit="1">
    <span slot-scope="{ row }" :class="$style.tag">{{ row.labelName }}</span>
  </inline-list>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import InlineList from '@/components/inline-list';

@Component({
  components: { InlineList },
})
export default class DictionaryTableTag extends Vue {
  @Prop({ default: () => [], type: Array }) data;
}
</script>
<style lang="less" module>
.tag {
  vertical-align: middle;
  display: inline-block;
  border: 1px solid var(--font-active);
  color: var(--font-active);
  min-width: 80px;
  text-align: center;
  padding: 0 10px;
  border-radius: 10px;
  height: 20px;
  line-height: 20px;
}
</style>
