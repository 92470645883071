<template>
  <a-form-model :model="form" ref="form" :rules="rules">
    <a-form-model-item label="企业" prop="tenantId">
      <a-select
        show-search
        allowClear
        v-model="form.tenantId"
        :placeholder="$t('ds.device.selectEnterprise')"
        option-filter-prop="children"
        :filter-option="false"
        @search="v => selectSearch(v)"
        :disabled="operationType === 'edit'"
        style="width: 400px"
      >
        <a-select-option
          :value="item.id"
          v-for="item in companyList"
          :key="item.id"
          :disabled="item.tenantStatus === 'DISABLE'"
        >
          {{
            item.tenantStatus === 'DISABLE'
              ? `${item.value}(已停用)`
              : item.value
          }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="权限配置">
      <div :class="$style.config">
        <div :class="$style.configItem">
          <div :class="$style.name">项目BI</div>
          <div :class="$style.radioSelect">
            <div
              :class="$style.selectItem"
              v-for="item in projectConfig"
              :key="item.key"
            >
              <div :class="$style.title">
                {{ item.title }}
              </div>
              <a-radio-group v-model="projectValues[item.key]">
                <a-radio
                  v-for="itemChild in item.children"
                  :key="itemChild.pkId"
                  :value="itemChild.pkId"
                  @click="projectValues[item.key] = ''"
                  >{{ itemChild.templateName }}</a-radio
                >
              </a-radio-group>
            </div>
          </div>
        </div>
        <div :class="$style.configItem">
          <div :class="$style.name">公司BI</div>
          <div :class="$style.radioSelect">
            <div
              :class="$style.selectItem"
              v-for="item in componyConfig"
              :key="item.key"
            >
              <div :class="$style.title">{{ item.title }}</div>
              <a-radio-group v-model="componyValues[item.key]">
                <a-radio
                  v-for="itemChild in item.children"
                  :key="itemChild.pkId"
                  :value="itemChild.pkId"
                  @click="componyValues[item.key] = ''"
                  >{{ itemChild.templateName }}</a-radio
                >
              </a-radio-group>
            </div>
          </div>
        </div>
      </div>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Vue, Prop, Component } from 'vue-property-decorator';
import { getEnterpriseList } from '@/services/digital-site/template';
import { Debounce } from 'lodash-decorators';
import {
  editTenantConfig,
  addTenantConfig,
  getEnterpriseConfigDetail,
} from '@/services/digital-site/template';
import { projectNavMenu, componyNavMenu } from '../enum';

@Component
export default class DeveloperEditForm extends Vue {
  @Prop({ type: String, default: 'add' }) operationType;
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: Array, default: () => [] }) projectConfig;
  @Prop({ type: Array, default: () => [] }) componyConfig;

  // 使用浅拷贝防止枚举值被修改
  projectValues = { ...projectNavMenu };
  componyValues = { ...componyNavMenu };
  get projectTemplates() {
    const obj = {};
    this.projectConfig.forEach(element => {
      obj[element.key] = element.children.map(item => item.pkId);
    });
    return obj;
  }
  get componyTemplates() {
    const obj = {};
    this.componyConfig.forEach(element => {
      obj[element.key] = element.children.map(item => item.pkId);
    });
    return obj;
  }
  form = {
    tenantId: undefined,
  };
  rules = {
    tenantId: [{ required: true, message: '请选择企业！' }],
  };
  async mounted() {
    this.initFormData();
    this.getCompanyList();
  }
  async initFormData() {
    if (this.operationType === 'edit') {
      if (!this.companyList.find(item => item.id === this.editData.tenantId)) {
        this.companyList.unshift({
          id: this.editData.tenantId,
          value: this.editData.tenantName,
        });
        this.form.tenantId = this.editData.tenantId;
      }
      const {
        projectTemplateId,
        companyTemplateId,
      } = await getEnterpriseConfigDetail(this.editData.pkId);
      Object.keys(this.projectTemplates).forEach(key => {
        this.projectTemplates[key].forEach(item => {
          if (projectTemplateId.indexOf(item) > -1) {
            this.projectValues[key] = item;
          } else {
            this.projectValues[key] = '';
          }
        });
      });
      Object.keys(this.componyTemplates).forEach(key => {
        this.componyTemplates[key].forEach(item => {
          if (companyTemplateId.indexOf(item) > -1) {
            this.componyValues[key] = item;
          } else {
            this.componyValues[key] = '';
          }
        });
      });
    } else {
      Object.keys(this.projectValues).forEach(key => {
        this.projectValues[key] = '';
      });
      Object.keys(this.componyValues).forEach(key => {
        this.componyValues[key] = '';
      });
    }
  }
  // 企业筛选支持远程查询
  companyList = [];
  async getCompanyList(keyword = '') {
    const params = {
      skip: 0,
      size: 100,
      keyword,
    };
    const { data } = await getEnterpriseList(params);
    this.companyList = data.map(item => ({
      id: item.pkId,
      value: item.enterpriseName,
      tenantStatus: item.tenantStatus,
    }));
  }
  @Debounce(300)
  selectSearch(keyword) {
    this.getCompanyList(keyword);
  }
  getObjValueArray(obj) {
    return Object.keys(obj)
      .map(key => obj[key])
      .filter(item => item);
  }
  getValue() {
    return new Promise((resolve, reject) => {
      const message = {
        add: '添加成功！',
        edit: '编辑成功',
      };
      this.$refs.form.validate(async valid => {
        const companyTemplateIds = this.getObjValueArray(this.componyValues);
        const projectTemplateId = this.getObjValueArray(this.projectValues);
        if (!companyTemplateIds.length && !projectTemplateId.length) {
          this.$message.error('请至少配置一个权限');
          valid = false;
        }
        if (valid) {
          try {
            this.operationType === 'edit'
              ? await editTenantConfig({
                  companyTemplateId: companyTemplateIds,
                  pkId: this.editData.pkId,
                  projectTemplateId: projectTemplateId,
                })
              : await addTenantConfig({
                  companyTemplateId: companyTemplateIds,
                  tenantId: this.form.tenantId,
                  tenantName: this.companyList.find(
                    item => item.id === this.form.tenantId,
                  ).value,
                  projectTemplateId: projectTemplateId,
                });
            this.$message.success(message[this.operationType]);
            resolve(true);
          } catch (error) {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" module>
.config {
  width: 100%;
  max-height: 450px;
  display: flex;
  justify-content: space-between;
  .configItem {
    width: 49%;
    height: 400px;
    display: flex;
    border: 1px solid var(--border);
    border-radius: 2px;
    :global {
      .ant-descriptions-item-label {
        width: 102px;
        background-color: var(--main-bg);
      }
      .ant-descriptions-item-content {
        padding: 10px;
      }
    }
    .name {
      width: 82px;
      text-align: center;
      line-height: 400px;
      border-right: 1px solid var(--border);
    }
    .radioSelect {
      flex: 1;
      height: 100%;
      overflow-y: auto;
      padding-left: 9px;
      .selectItem {
        margin-bottom: 10px;
        .title {
          color: var(--font-info);
          margin-bottom: 8px;
        }
        :global {
          .ant-radio-group {
            display: flex;
            // flex-direction: column;
            flex-wrap: wrap;
          }
          .ant-radio-wrapper {
            display: inline-block;
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>
