<template>
  <div class="x-operation-select">
    <div class="x-operation-select--tip">
      您还未加入任何企业或团队，请选择操作：
    </div>
    <div class="x-operation-select--type">
      <div
        class="x-operation-select--type-oneBlock"
        :class="{ 'x-operation-select--checked': operationType === 'add' }"
        @click="handleChooseOperation('add')"
      >
        <x-icon
          type="tc-icon-company"
          class="x-operation-select--type-oneBlock-icon"
        />
        <div class="x-operation-select--type-oneBlock-tip">创建企业或团队</div>
        <!-- <a-icon type="check" class="x-operation-select--type-oneBlock-check" /> -->
        <x-icon
          type="tc-icon-check-bold"
          class="x-operation-select--type-oneBlock-check"
        />
      </div>
      <div
        class="x-operation-select--type-oneBlock"
        :class="{ 'x-operation-select--checked': operationType === 'join' }"
        @click="handleChooseOperation('join')"
      >
        <x-icon
          type="tc-icon-usergroup-add"
          class="x-operation-select--type-oneBlock-icon"
        />
        <div class="x-operation-select--type-oneBlock-tip">加入企业或团队</div>
        <!-- <a-icon type="check" class="x-operation-select--type-oneBlock-check" /> -->
        <x-icon
          type="tc-icon-check-bold"
          class="x-operation-select--type-oneBlock-check"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Vue, Component, Emit } from 'vue-property-decorator';
import { CreateCompany, JoinCompany } from '@triascloud/x-blocks';
import { createModal } from '@triascloud/x-components';

function createOperationSelectModal() {
  return new Promise((resolve, reject) => {
    const modal = createModal(
      h =>
        h(XOperationSelect, {
          on: {
            change: () => {
              modal.handleClosed();
              resolve();
            },
          },
        }),
      {
        title: '选择操作',
        width: 730,
        onClose: () => reject(new Error('user cancel')),
      },
    );
  });
}

@Component()
export default class XOperationSelect extends Vue {
  static create = createOperationSelectModal;
  operationType = '';
  // 选择操作
  @Emit('change')
  async handleChooseOperation(operation) {
    this.operationType = operation;
    if (operation === 'join') {
      await JoinCompany.createModal();
    } else {
      await CreateCompany.createModal();
    }
  }
}
</script>
<style lang="less">
@prefix: x-operation-select;
.@{prefix}--tip {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: var(--font);
  // color: #5b5b5b;
  margin-left: 61px;
  margin-top: 62px;
}
.@{prefix}--type {
  // margin-top: 63px;
  display: flex;
  justify-content: space-between;
  width: 395px;
  margin: 63px auto;
}
.@{prefix}--type-oneBlock {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}
.@{prefix}--type-oneBlock-icon {
  font-size: 95px;
  border: 2px solid var(--border);
  // border: 2px solid #dddddd;
  padding: 32px;
  // color: #999999;
  color: var(--font-info);
  border-radius: 6px;
}
.@{prefix}--type-oneBlock-tip {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  // color: #5b5b5b;
  color: var(--font);
  margin-top: 11px;
  text-align: center;
  // margin-bottom: 66px;
}
.@{prefix}--type-oneBlock-check {
  font-size: 36px;
  position: absolute;
  right: 10px;
  bottom: 42px;
  color: transparent;
}
.@{prefix}--checked {
  .@{prefix}--type-oneBlock-icon {
    border: 2px solid var(--primary);
    color: var(--primary);
  }
  .@{prefix}--type-oneBlock-check {
    color: var(--primary);
  }
}
</style>
