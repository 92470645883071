<template>
  <div :class="$style.wrap">
    <x-qrcode
      @click.native="$preview($refs.qrcode.imageSrc, 'image')"
      :class="$style.code1"
      :src="qrInfo()"
      ref="qrcode"
    ></x-qrcode>
    <span :class="$style.code">设备码:{{ record.deviceName }}</span>
    <span :class="$style.info">设备信息</span>
    <a href="#">{{ record.detailUrl }}</a>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import XQrcode from '@/components/common/x-qrcode/index.vue';

const hatColors = {
  YELLOW: '黄',
  BLUE: '蓝',
  RED: '红',
  WHITE: '白',
  ORANGE: '橙',
};
@Component({
  components: {
    XQrcode,
  },
})
export default class DeviceInfo extends Vue {
  @Prop({ type: Object, default: () => {} }) record;

  qrInfo() {
    const { deviceName, factoryTime, color } = this.record;
    return `AISCAP,${deviceName},${this.$moment(factoryTime).format(
      'YYYY-MM-DD',
    )},${hatColors[color]}`;
  }
  down() {
    const qrcodePic = this.$refs.qrcode.$el;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 500;
    canvas.height = 500;
    ctx.drawImage(qrcodePic, 0, 0, canvas.width, canvas.height);
    const dom = document.createElement('a');
    dom.href = canvas.toDataURL('image/png');
    dom.download = this.record.deviceName;
    document.body.appendChild(dom);
    dom.click();
  }
}
</script>
<style lang="less" module>
.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 200px;
    height: 200px;
    border-radius: 10px;
  }
  .code {
    font-size: 18px;
  }
  .info {
    font-size: 14px;
    margin-top: 10px;
    color: var(--font-info);
  }
  .code1 {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
  }
}
</style>
