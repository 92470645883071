<template>
  <div>
    <field-config-title v-bind="fieldProps" />
    <a-form-model-item prop="widget.productId" label="来源产品" required>
      <a-select
        :value="field.widget.productId"
        style="width: 100%"
        placeholder="请选择"
        @change="
          val => {
            handleInput('widget.productId', val);
            handleInput('widget.defaultValue.custom', []);
            handleInput('widget.selectedDp', []);
            getDpList();
          }
        "
      >
        <a-select-option
          v-for="item of products"
          :key="item.pkId"
          :value="item.pkId"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      prop="widget.classificationId"
      label="物模型分类"
      required
    >
      <a-select
        :value="field.widget.classificationId"
        style="width: 100%"
        placeholder="请选择"
        @change="
          val => {
            handleInput('widget.classificationId', val);
            handleInput('widget.defaultValue.custom', []);
            handleInput('widget.selectedDp', []);
            getDpList();
          }
        "
      >
        <a-select-option
          v-for="item of dpTemps"
          :key="item.identifier"
          :value="item.identifier"
        >
          {{ item.title }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item prop="widget.defaultValue.custom" :rules="dpRule">
      <label slot="label" class="ant-form-item-required"> DP点 </label>
      <a-select
        mode="multiple"
        :value="field.widget.defaultValue.custom"
        @change="dpChanged"
        style="width: 100%"
        placeholder="请选择"
      >
        <a-select-option
          v-for="item of dpList"
          :key="item.dataPointId"
          :value="item.dataPointId"
        >
          {{ item.functionName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <field-config-required v-bind="fieldProps" />
    <parameter-object v-if="!isRes" v-bind="fieldProps" isInOrOut="in" />
    <parameter-object v-if="isRes" v-bind="fieldProps" isInOrOut="out" />

    <a-form-model-item
      prop="widget.paramInFormat"
      label="入参格式"
      v-if="isReq"
    >
      <a-radio-group
        :value="field.widget.paramInFormat"
        @change="
          e => {
            this.handleInput('widget.paramInFormat', e.target.value);
          }
        "
      >
        <a-radio :value="InputFormat.NEVER">
          不参与入参
        </a-radio>
        <a-radio :value="InputFormat.OBJECT">
          对象
        </a-radio>
        <a-radio :value="InputFormat.KEYVALUE">
          键值
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item prop="widget.paramInTag" v-if="isReq">
      <div slot="label">
        DP点入参标识
        <a-tooltip placement="top">
          <a-icon type="info-circle" />
          <div slot="title">
            根据所选DP点动态添加query参数（若为空则不添加），例如选择DP点为开关时（标识符为switch)，则自动在实际query种添加Identifier=switch
          </div>
        </a-tooltip>
      </div>
      <a-input
        :value="field.widget.paramInTag"
        @change="
          e => {
            this.handleInput('widget.paramInTag', e.target.value);
          }
        "
      ></a-input>
    </a-form-model-item>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import FieldConfigTitle from '../components/field-config/title.vue';
import FieldConfigRequired from '../components/field-config/required.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';
import ParameterObject from '../components/field-config/parameter-object.vue';
import { ParamType, InputFormat } from '../enum';
import { templateDesignModule } from '@/enum/store';
import {
  getClassificationCache,
  getProductsCache,
  dataPointSearch,
} from '@/services/iot-platform/dpManager';
import { DATATYPE } from '@/views/connector/platform-manage/develop/utils';
@Component({
  components: {
    FieldConfigTitle,
    FieldConfigRequired,
    FieldConfigDescription,
    FieldConfigConfigDesc,
    ParameterObject,
  },
})
export default class DPSConfig extends Vue {
  @templateDesignModule.State platformId;
  @templateDesignModule.Getter paramType;
  @Prop() field;
  @Prop() updateField;
  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
    };
  }

  InputFormat = InputFormat;

  /** @name 入参 */
  get isReq() {
    return this.paramType === ParamType.REQ_PARAM;
  }
  /** @name 出参 */
  get isRes() {
    return this.paramType === ParamType.RES_PARAM;
  }
  created() {
    this.getList();
    if (this.field.widget.classificationId && this.field.widget.productId) {
      this.getDpList();
    }
  }
  noTemplateTips = false;
  products = [];
  dpTemps = [];
  dpList = [];
  dpRule = {
    validator: this.validator,
    trigger: 'change',
  };
  validator(rule, value, callback) {
    if (this.field.widget.defaultValue.custom.length) {
      callback();
    } else {
      callback(new Error('请选择DP点'));
    }
  }
  async getList() {
    this.products = await getProductsCache(this.platformId);
    this.dpTemps = await getClassificationCache(this.platformId);
    if (!this.dpTemps.length) this.noTemplateTips = true;
  }
  async getDpList() {
    if (!this.field.widget.classificationId || !this.field.widget.productId) {
      return;
    }
    let params = {
      classificationId: this.field.widget.classificationId,
      productId: this.field.widget.productId,
      platformId: this.platformId,
    };
    const dpList = await dataPointSearch(params);
    this.dpList = this.dataParse(dpList);
  }
  dataParse(dpList) {
    for (let i = 0; i < dpList.length; i++) {
      const record = dpList[i];
      let jsonData = JSON.parse(record.dataSpecs);
      record.children = [];
      record.type = '';
      let notMatch = true;
      for (let item of jsonData.fields) {
        let node = this.getNodeItem(item);
        if (item.type === 'currentDataSet' || item.type === 'addDataSet') {
          node.name = jsonData.fields.find(f => {
            return f.type === 'fnName';
          })?.value;
          if (item.type === 'addDataSet') {
            if (
              (this.isRes && item.widget.paramType === 2) ||
              (item.widget.paramType === 1 && !this.isRes)
            ) {
              const childs = this.getChilds(item.value, node);
              childs && record.children.push(childs);
              notMatch = false;
            }
          } else {
            notMatch = false;
            record.type = item.value.dataTypeText?.toLocaleLowerCase() || '';
            record.children.push(this.getChilds([item.value], node));
          }
        }
      }
      if (notMatch) {
        dpList.splice(i, 1);
        i--;
      }
    }
    return dpList;
  }

  getNodeItem(item) {
    delete item.widgetStr;
    const o = {
      id: item.id || item.pkId,
      pkId: '',
      name: item.name || item.paramName || item.functionName,
      type: (item.type || item.dataTypeText)?.toLocaleLowerCase(),
      open: true,
      isEdit: false,
      isDp: true,
      required: item.widget?.required,
      identifier:
        item.functionIdentifier || item.paramTag || item.thingIdentifier,
      val: '',
      children: [],
      isChildren: true,
      selectable: false,
    };
    this.parseItemDetail(o, item);
    // if (!o.name) {
    //   o.name = this.formateType(o.type);
    // }
    return o;
  }

  parseItemDetail(obj, item) {
    let type = item.dataTypeText?.toLocaleUpperCase();
    if (
      type === DATATYPE.int ||
      type === DATATYPE.float ||
      type === DATATYPE.double
    ) {
      obj.details = item.dataSpecs.number;
    } else if (type === DATATYPE.boolean) {
      obj.details = item.dataSpecs.boolean;
    } else if (type === DATATYPE.text) {
      obj.details = item.dataSpecs.text;
    } else if (type === DATATYPE.enum) {
      obj.details = item.dataSpecs.enum;
    } else if (type === DATATYPE.array) {
      obj.details = {
        count: item.dataSpecs.array.count,
        subType: item.dataSpecs.array.type,
      };
    }
  }

  getChilds(dataList, parent) {
    if (!dataList || !dataList.length) {
      return null;
    }
    for (const dataItem of dataList) {
      const node = this.getNodeItem(dataItem);
      node.pkId = parent.id;
      let childs = null;
      if (node.type === 'array' && dataItem.dataSpecs.array.type === 'struct') {
        childs = this.getChilds(dataItem.dataSpecs.array.list, node);
        childs && parent.children.push(childs);
      } else if (node.type === 'struct') {
        childs = this.getChilds(dataItem.dataSpecs.struct, node);
        childs && parent.children.push(childs);
      } else {
        node.selectable = true;
        parent.children.push(node);
      }
    }
    return parent;
  }
  formateType(t) {
    const obj = {
      int32: '整数',
      float: '浮点',
      double: '双精度',
      input: '文本',
      date: '日期',
      switch: '布尔',
      struct: '结构体',
      array: '数组',
      enum: '枚举',
      image: '图片',
      file: '文件',
    };
    return obj[t];
  }
  dpChanged(val) {
    const arr = [];
    for (let record of this.dpList) {
      if (val.indexOf(record.dataPointId) >= 0) {
        arr.push(record);
      }
    }
    this.handleInput('widget.defaultValue.custom', val);
    this.handleInput('widget.selectedDp', arr);
  }
  handleInput(key, value) {
    this.updateField({ key: key, value: value });
  }
}
</script>

<style lang="less" module>
.ml10 {
  margin-left: 10px;
}
.section {
  display: flex;
  width: 100%;
  .item {
    flex: 1;
  }
  .label2 {
    width: 32px;
  }
  .label1 {
    width: 15px;
  }
}
</style>
