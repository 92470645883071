<template>
  <a-config-provider :getPopupContainer="getPopupContainer">
    <a-form-model
      v-if="selectedField"
      :class="$style.fieldConfig"
      :model="selectedField"
      ref="form"
      layout="vertical"
    >
      <div :class="$style.configTitle">{{ $t('controls.config.title') }}</div>
      <component
        :is="configComponent"
        :field="selectedField"
        :updateField="updateSelectedField"
        :fields="fields"
        :contextFields="contextFields"
        @validate="handleValidate"
      />
    </a-form-model>
  </a-config-provider>
</template>
<script>
import {
  Component,
  Provide,
  ProvideReactive,
  Vue,
} from 'vue-property-decorator';
import { UploadPathSymbol } from '@triascloud/x-components';
import { getControl, ControlDesignContext, flatFields } from './form/controls';
import { crossStorageModule, templateDesignModule } from '@/enum/store';

@Component()
export default class FormDesignFieldConfig extends Vue {
  /** @type { import('./form/controls').Field[] } */
  @templateDesignModule.Getter fields;
  @templateDesignModule.Getter formId;
  @templateDesignModule.Getter column;
  /** @type { import('./form/controls').Field | null } */
  @templateDesignModule.State
  selectedField;
  /** @type { import('./form/controls').Field | null } */
  @templateDesignModule.Getter selectedFieldParent;
  @templateDesignModule.Mutation updateSelectedField;
  @crossStorageModule.State tenant;

  get control() {
    return getControl(this.selectedField.type);
  }
  get configComponent() {
    if (!this.selectedField) return null;
    return this.control.ConfigComponent || 'div';
  }

  @ProvideReactive(ControlDesignContext.ParentField)
  get parentField() {
    return this.selectedFieldParent;
  }

  @ProvideReactive(ControlDesignContext.Fields)
  get provideFields() {
    return this.fields;
  }

  @ProvideReactive(ControlDesignContext.ContextFields)
  get contextFields() {
    const subFields = this.parentField ? this.parentField.widget.fields : [];
    return flatFields(subFields, { param: true })
      .map(subField => ({
        ...subField,
        name: `${this.parentField.name}.${subField.name}`,
      }))
      .concat(flatFields(this.fields, { param: true }))
      .filter(
        field =>
          field.pkId !== this.parentField?.pkId &&
          field.pkId !== this.selectedField.pkId,
      );
  }

  /**
   * @name 设计器中的附件上传地址，仅为了使富文本组件正常运行
   */
  @Provide(UploadPathSymbol)
  get uploadPath() {
    return 'common/temp/';
  }

  handleValidate() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(valid => {
        if (valid) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  }

  getPopupContainer() {
    return this.$el;
  }
}
</script>
<style lang="less" module>
.configTitle {
  color: var(--font);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
}
.fieldConfig {
  padding: 25px 20px;
  position: relative;
  :global {
    .ant-input,
    .ant-select {
      color: var(--font);
      font-size: 14px;
    }
    .anticon-tc-icon-warning {
      margin-left: 8px;
      font-size: 16px;
      color: var(--warning);
    }
    .ant-btn-link {
      height: auto;
      padding: 0;
      min-width: 0;
      border: none;
      > .x-icon {
        margin-left: 2px;
      }
    }
  }
}
.radioButtonGroup {
  display: flex;
  :global(.ant-radio-button-wrapper) {
    flex: 1;
    text-align: center;
    padding: 0 8px;
  }
}
</style>
