// @ts-check
import { BaseControl, ControlValueTypeEnum } from '../BaseControl';
// @ts-ignore
import ConfigComponent from './config.vue';
import FormComponent from './date.vue';
// @ts-ignore
import DesignComponent from '../Input/design.vue';
import { dateFormat } from '../utils';
import { DateDefault } from '../enum';
/** @name 日期 */
class DateControl extends BaseControl {
  /** @readonly */
  type = 'date';

  label = 'controls.date.label';

  icon = 'tc-icon-calendar';

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    defaultValue: {
      // 单行文本默认值类型
      type: 'custom',
      // 默认值
      custom: '',
    },
    identifier: 'DATE',
    inParams: ['DEVELOPMENT'],
    outParams: [],
    timePrecision: '13',
    format: 'YYYY-MM-DD',
    paramFormat: '',
    outFormat: '', // 出参格式，给后端存储使用的
    defaultType: DateDefault.MANUAL,
  });

  // @ts-ignore
  props = ({ widget: { format } }) => ({ format });

  /**
   * @name 字段特征定义
   * @type { object | ((field: import('../BaseControl').Field) => object) }
   * @returns  { object }
   */
  feature = field => ({
    WRITABLE: !field?.widget?.view,
    REQUIRED: !field?.widget?.view,
    PRINTABLE: true,
    BRIEFING: true,
    sorter: 'order',
  });

  FormComponent = FormComponent;

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;

  // @ts-ignore
  format = dateFormat;

  printable = true;

  valueType = ControlValueTypeEnum.Date;
}

export default new DateControl();
