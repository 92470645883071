import request from '../../request';
export const basePath = '/configuration/fire/news/management';
/** @name 获取消防新闻资讯管理列表 */
export const getMediaList = config =>
  request(`${basePath}/get/config`, { method: 'POST', body: config });

/** @name 获取消防新闻资讯管理详情 */
export const getMediaDetail = pkId =>
  request(`${basePath}/get/config/detail?pkId=${pkId}`, { method: 'GET' });

/** @name 删除消防新闻资讯管理 */
export const deleteMedia = pkId =>
  request(`${basePath}/remove?pkId=${pkId}`, {
    method: 'DELETE',
  });

/** @name 保存消防官网新闻资讯管理 */
export const addMedia = config =>
  request(`${basePath}/add/config`, { method: 'POST', body: config });

/** @name 编辑消防新闻资讯管理 */
export const editMedia = config =>
  request(`${basePath}/edit/config`, { method: 'POST', body: config });
