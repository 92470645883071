<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item label="连接器头图" prop="logoUrl">
      <images-upload v-model="form.logoUrl" style="height: 120px" />
    </a-form-model-item>
    <a-form-model-item label="连接器名称" prop="apiName">
      <a-input
        placeholder="请输入连接器名称"
        :maxLength="50"
        v-model="form.apiName"
      />
    </a-form-model-item>
    <a-form-model-item prop="subscriptionType">
      <span slot="label">
        连接器类型
        <a-tooltip placement="right" :overlayStyle="{ maxWidth: '100%' }">
          <a-icon type="info-circle" />
          <span slot="title">
            <p>
              创建后不允许更改，目前仅支持HTTP、HTTPS、阿里云AMQP，更多类型正在规划中！
            </p>
          </span>
        </a-tooltip>
      </span>
      <a-select placeholder="请选择连接器类型" v-model="form.subscriptionType">
        <a-select-option value="HTTP">HTTP</a-select-option>
        <a-select-option value="HTTPS">HTTPS</a-select-option>
        <a-select-option value="AMQP">AMQP</a-select-option>
        <a-select-option value="SOCKET" disabled>SOCKET</a-select-option>
        <a-select-option value="KAFKA" disabled>KAFKA</a-select-option>
        <a-select-option value="CKAFKA" disabled>CKAFKA</a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="所属产品" prop="productId">
      <selectProduct :platformId="id" v-model="form.productId" />
    </a-form-model-item>

    <a-form-model-item label="连接器介绍" prop="apiDesc">
      <a-textarea
        placeholder="请输入连接器介绍"
        :maxLength="100"
        v-model="form.apiDesc"
      />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ImagesUpload from '@/views/connector/platform-manage/components/images-upload.vue';
import { getOssHost, addApi } from '@/services/iot-platform/platformInterface';
import { changeOssToUrl } from './components/utils';
import SelectProduct from '@/views/connector/platform-manage/develop/device-manage/device-form/select-product.vue';
@Component({
  components: {
    SelectProduct,
    ImagesUpload,
  },
})
export default class AddApi extends Vue {
  @Prop({ type: String, default: '' }) id;

  form = {
    logoUrl: [],
    apiName: '',
    apiDesc: '',
    apiType: null,
    productId: undefined,
    subscriptionType: undefined,
  };
  rules = {
    apiName: [
      {
        type: 'string',
        required: true,
        message: '请输入API名称',
      },
    ],
    productId: [
      {
        type: 'string',
        required: true,
        message: '请选择所属产品',
      },
    ],
    subscriptionType: [
      {
        type: 'string',
        required: true,
        message: '请选择连接器类型',
      },
    ],
  };
  mounted() {
    this.init();
  }
  async init() {
    try {
      const staticOssHead = await getOssHost();
      const defaultUrl = await changeOssToUrl(
        staticOssHead,
        'common/public/images/SystemCoConstruction/apiDefault.png',
      );
      this.form.logoUrl = [
        {
          uid: -1,
          name: 'picture',
          status: 'done',
          url: defaultUrl[0],
          ossPath: 'common/public/images/SystemCoConstruction/apiDefault.png',
        },
      ];
    } catch {
      return false;
    }
  }
  async getValue() {
    await this.$refs.form.validate();
    const params = {
      platformId: this.id,
      apiName: this.form.apiName,
      introduction: this.form.apiDesc,
      logoUrl: this.form.logoUrl[0].ossPath,
      productId: this.form.productId,
      subscriptionType: this.form.subscriptionType,
    };
    try {
      const res = await addApi(params);
      this.$message.success('添加成功');
      return res;
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module></style>
