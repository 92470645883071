// @ts-check
import Vue from 'vue';
import { get, has } from 'lodash';
import { ensureArray } from '@triascloud/utils';
import { getControl } from './utils';
import { FormatContextSymbol } from './symbol';

/** @typedef { <T>(key: string, asyncFn: (() => Promise<T>), processResult: ((result: T) => string) ) => (string | Promise<string>) } CacheRequest */

export class FormatContext {
  static Symbol = FormatContextSymbol;

  cache = Vue.observable({});

  /** @type { CacheRequest } */
  cacheRequest = async (key, asyncFn, processResult) => {
    const result = await (has(this.cache, key)
      ? get(this.cache, key)
      : this.execRequest(key, asyncFn));
    return processResult(result);
  };

  /** @type { CacheRequest } */
  cacheRequestSync = (key, asyncFn, processResult) => {
    if (has(this.cache, key)) {
      const result = get(this.cache, key);
      if (result instanceof Promise) return '';
      return processResult(result);
    } else {
      this.execRequest(key, asyncFn);
      return '';
    }
  };

  /**
   * @template T
   * @param { string } key
   * @param { () => Promise<T> } asyncFn
   * @returns { Promise<T> }
   */
  execRequest = (key, asyncFn) => {
    const promise = asyncFn().then(result => {
      Vue.set(this.cache, key, result);
      return result;
    });
    Vue.set(this.cache, key, promise);
    // 这里通过访问一次来让vue收集依赖
    return get(this.cache, key);
  };

  /**
   * @param { any } value
   * @param { import("./BaseControl").Field } field
   * @return { Promise<string> }
   */
  format = (value, field) => {
    if (!field) return Promise.resolve('');
    const Control = getControl(field.type);
    if (!Control.format) return Promise.resolve('');
    return Promise.resolve(
      Control.format(value, field, this.cacheRequest, this.format),
    );
  };

  /**
   * @param { any[] } list
   * @param { import("./BaseControl").Field } field
   * @return { Promise<string[]> }
   */
  formatList = (list, field) =>
    Promise.all(ensureArray(list).map(value => this.format(value, field)));

  /**
   * @name 同步渲染值对应文本，切记只能在computed中使用！！！
   * @param { any } value
   * @param { import("./BaseControl").Field } field
   * @return { string }
   */
  formatSync = (value, field) => {
    if (!field) return '';
    const Control = getControl(field.type);
    if (!Control.format) return '';
    // @ts-ignore
    return Control.format(value, field, this.cacheRequestSync, this.formatSync);
  };
}
