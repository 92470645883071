<template>
  <div>
    <a-table
      :columns="columns"
      :pagination="false"
      :data-source="list.list"
      :class="$style.table"
      :scroll="{
        x: '100%',
        y: '100%',
      }"
    >
    </a-table>
    <div slot="footer" :class="$style.action">
      <div :class="$style.desc">
        <div :class="$style.descItem">
          {{ $t('inviteCode.payTlt') }}:
          <span>{{ list.tenantCount }}{{ $t('common.label.piece') }}</span>
        </div>
        <div :class="$style.descItem">
          {{ $t('inviteCode.amountTlt') }}:
          <span>{{ list.amount / 100 }}{{ $t('inviteCode.Yuan') }}</span>
        </div>
      </div>
      <div :class="$style.closeBtn">
        <a-button type="primary" @click="$emit('handleClosed')">
          {{ $t('common.action.confirm') }}
        </a-button>
        <a-button @click="$emit('handleClosed')">
          {{ $t('common.action.cancel') }}
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';
import { payCountDetail } from '@/services/tenant-center/tenant-invite-code';
function createPayDetailModal(props) {
  const detail = createModal(
    h =>
      h(PayDetail, {
        props,
        on: {
          handleClosed: () => {
            detail.handleClose();
          },
        },
      }),
    {
      title: globalVueI18n.translate('inviteCode.companiesList'),
      width: 700,
    },
  );
  return detail;
}
@Component()
export default class PayDetail extends Vue {
  @Prop({ type: String, default: '' }) tenantId;
  static create = createPayDetailModal;
  list = [];
  get columns() {
    return [
      {
        dataIndex: 'tenantName',
        title: this.$t('inviteCode.companyName'),
        ellipsis: true,
        width: 165,
      },
      {
        dataIndex: 'comboName',
        title: this.$t('inviteCode.version'),
      },
      {
        dataIndex: 'buyYear',
        title: this.$t('inviteCode.period'),
      },
      {
        dataIndex: 'amount',
        title: this.$t('inviteCode.Payment'),
        customRender: price => {
          return <span>{price / 100}</span>;
        },
      },
    ].map(item => ({
      ellipsis: false,
      ...item,
      filter: false,
      sorter: false,
      align: 'center',
    }));
  }
  async count() {
    this.list = await payCountDetail({ tenantId: this.tenantId });
  }

  created() {
    this.count();
  }
}
</script>
<style lang="less" module>
.table {
}
.action {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
}
.desc {
  display: flex;
  flex-direction: row;
  color: var(--font-info);
  .descItem {
    &:last-child {
      margin-left: 20px;
    }
    span {
      color: var(--font);
    }
  }
}

.closeBtn {
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
}
</style>
