<template>
  <div :class="$style.index" v-if="data">
    <div :class="$style.item" v-for="item in dataKey" :key="item.key">
      <div :class="$style.count" v-html="item.data"></div>
      <div :class="$style.tlt">{{ item.tlt }}</div>
      <img
        :class="[item.ImgPosition ? $style.item_right : $style.img]"
        :src="item.img"
        v-if="item.img"
      />
      <x-custom-icon
        :class="[$style.card__icon, $style.item_right]"
        :type="item.icon"
        v-if="item.icon"
      />
      <a-button
        type="link"
        @click="openDetails"
        :class="$style.details"
        v-if="item.details"
        >{{ $t('common.label.detail') }}</a-button
      >

      <div :class="$style.bot" v-if="item.dayOrWeek.length">
        <span
          >{{ $t('solution.weekon') }}:
          <a-icon
            type="caret-up"
            :style="{ color: 'green' }"
            v-if="data[item.key] && data[item.key][item.dayOrWeek[0]] > 0"
          />
          <a-icon type="caret-down" :style="{ color: '#ff5a20' }" v-else />
          {{ data[item.key] && data[item.key][item.dayOrWeek[0]] }}%
        </span>
        <span>
          {{ $t('solution.dayTo') }}:
          <a-icon
            type="caret-up"
            :style="{ color: 'green' }"
            v-if="data[item.key] && data[item.key][item.dayOrWeek[1]] > 0"
          />
          <a-icon type="caret-down" :style="{ color: '#ff5a20' }" v-else />
          {{ data[item.key] && data[item.key][item.dayOrWeek[1]] }}%
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
// import { globalVueI18n } from '@triascloud/i18n';
import LogoDetailsModal from './logo-details-modal.vue';
@Component({
  components: {
    LogoDetailsModal,
  },
})
export default class HeaderList extends Vue {
  @Prop({ type: Object, default: () => null }) data;
  async openDetails() {
    await LogoDetailsModal.create();
  }
  get dataKey() {
    return [
      {
        key: 'enterpriseCount',
        icon: '1381795643223031810.28804060',
        tlt: this.$t('plugin.snms.numberEnterprises'),
        img: null,
        dayOrWeek: [],
        data: this.data && this.data.enterpriseCount,
        ImgPosition: true,
      },
      {
        key: 'synchronizedConfigurationsCount',
        icon: null,
        tlt: this.$t('plugin.snms.secondaryQuantity'),
        img: null,
        dayOrWeek: ['dayCompared', 'weekCompared'],
        data:
          this.data &&
          this.data.synchronizedConfigurationsCount &&
          this.data.synchronizedConfigurationsCount
            .synchronizedConfigurationsCount,
      },
      {
        key: 'identifyQuantityCount',
        icon: null,
        tlt: this.$t('plugin.snms.identificationAmount'),
        img: require('@/assets/img/trade.png'),
        dayOrWeek: [],
        data: this.data && this.data.identifyQuantityCount,
        ImgPosition: false,
        details: true,
      },
      {
        key: 'identificationResolutionCount',
        icon: null,
        tlt: this.$t('plugin.snms.logoResolution'),
        img: require('@/assets/img/application.png'),
        dayOrWeek: [],
        data: this.data && this.data.identificationResolutionCount,
        ImgPosition: false,
      },
      {
        key: 'identifyTheStockCount',
        icon: null,
        tlt: this.$t('plugin.snms.markStock'),
        img: require('@/assets/img/install.png'),
        dayOrWeek: [],
        data: this.data && this.data.identifyTheStockCount,
        ImgPosition: false,
      },
    ];
  }
}
</script>
<style lang="less" module>
.index {
  padding: 10px 20px;
  padding-right: 0;
  display: flex;
  border-radius: 8px;
  flex-wrap: wrap;
}

.details {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  justify-content: flex-end;
}
.item {
  box-shadow: 0px 0px 5px 0px var(--shadow);
  min-width: 300px;
  min-height: 116px;
  padding: 0 20px;
  position: relative;
  width: calc(20% - 20px);
  //   margin: 0 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  &:first-child {
    margin-left: 0px;
    .card__icon {
      background: #4771ff;
    }
  }
  &:last-child {
    margin-right: 0;
  }

  .count {
    font-size: 32px;
    color: #4771ff;
    line-height: 32px;
    margin-top: 14px;
    span {
      font-size: 18px;
    }
  }
  &:nth-child(2) {
    .count {
      color: #b73eff;
    }
  }

  &:nth-child(3) {
    .count {
      color: #4f7af2;
    }
    .card__icon {
      background: #4f7af2;
    }
  }
  &:nth-child(4) {
    .count {
      color: #f99604;
    }
    .card__icon {
      background: #f99604;
    }
    button {
      color: #f99604;
    }
  }

  &:nth-child(5) {
    .count {
      color: var(--danger);
    }
    button {
      color: var(--danger);
    }
  }

  .tlt {
    font-size: 14px;
    line-height: 14px;
    margin-top: 10px;
  }
  .img {
    margin-top: 15px;
    width: 100%;
  }
  .bot {
    font-size: 14px;
    line-height: 14px;
    margin-top: 16px;
    color: var(--font-info);
    display: flex;
    justify-content: space-between;
  }
  button {
    padding: 0;
    text-align: left;
  }
  &:last-child {
    .card__icon {
      background-color: #ff6231;
    }
  }
}
.item_right {
  position: absolute;
  right: 20px;
  top: 25px;
}
.card__icon {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  color: #fff;
  background-color: #f99604;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}
.item_icon {
  background-color: var(--warning);
  i {
    font-size: 36px;
  }
}
</style>
