import request from '../request';

export function fetchPermission(module) {
  return request(`/production/module-auth?module=${module}`, {
    method: 'GET',
  });
}

// export function fetchPermission() {
//   return Promise.resolve({
//     '/': [],
//     '/market': [
//       'READ',
//       'SETUP_DOWN',
//       'DELETE',
//       'MOVE',
//       'UPDATE',
//       'RECYCLE-BIN',
//       'CLASSIFY',
//     ],

//     '/dictionary': ['READ', 'UPDATE', 'DELETE', 'RECYCLE-BIN'],
//     '/dictionary/review': ['READ', 'VERIFY', 'DELETE', 'DELETE_BATCH'],
//     '/kitMarket': ['READ', 'VERIFY', 'DELETE', 'DELETE_BATCH'],
//     '/gallery': [
//       'READ',
//       'UPDATE',
//       'DELETE',
//       'SET_PREFER_IMAGE',
//       'ADD_IMG_GROUP',
//       'UPDATE_IMG_GROUP',
//       'RECYCLE-BIN',
//     ],
//     '/gallery/review': [
//       'READ',
//       'VERIFY',
//       'DELETE',
//       'BATCH_VERIFY',
//       'DELETE_BATCH',
//     ],
//     '/icon': [
//       'READ',
//       'UPDATE',
//       'DELETE',
//       'ADD_ICON',
//       'CREATE_ICON_BIN',
//       'SETTING_DEFAULT_ICON',
//       'RECYCLE-BIN',
//     ],
//     '/feedback': ['READ', 'VERIFY', 'REPLY', 'DELETE'],
//     '/help': ['READ', 'UPDATE', 'SORT', 'DELETE', 'CREATE'],
//   });
// }
