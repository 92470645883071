<template>
  <a-form-model
    :class="[$style.container, $style['column-1']]"
    :model="defaultData"
    layout="vertical"
    ref="form"
  >
    <draggable
      :class="$style.list"
      :value="filteredFields"
      :group="{ name: 'form', put: ['control'] }"
      :ghostClass="$style.ghost"
      :animation="300"
      :filter="`.${$style.filtered}`"
      @input="handleSort"
    >
      <drag-item
        v-for="field in filteredFields"
        :key="field.pkId"
        :field="field"
        :value="defaultData[field.pkId]"
        v-bind="dragItemProps"
        ref="dragItems"
      />
      <drag-item
        v-if="baseInfoField"
        slot="footer"
        :key="baseInfoField.pkId"
        :class="$style.filtered"
        :field="baseInfoField"
        :value="defaultData[baseInfoField.pkId]"
        v-bind="dragItemProps"
        ref="baseInfo"
      />
    </draggable>
  </a-form-model>
</template>
<script>
import {
  Component,
  Prop,
  Provide,
  ProvideReactive,
  Vue,
} from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { UploadPathSymbol, Icon } from '@triascloud/x-components';
import { generateMap } from '@triascloud/utils';
import {
  ControlDesignContext,
  ControlTypeEnum,
  flatFields,
  FormatContext,
  getFieldDefaultValue,
} from './form/controls';
import { FormatContextSymbol, TemplateType } from './form/controls';
import { DataFormContext } from './form/data-form';
import DragItem from './drag-item';

@Component({
  components: { Draggable, DragItem },
})
export default class FormDesignDragContainer extends Vue {
  @Prop() updateFields;
  @Prop() selectedField;
  @Prop() selectField;
  @Prop() deleteField;
  @Prop() fields;
  @Prop() fieldTagMap;
  @Prop() templateType;

  get dragItemProps() {
    return {
      selectedField: this.selectedField,
      fieldTagMap: this.fieldTagMap,
      selectField: this.selectField,
      deleteField: this.deleteField,
      updateFields: this.updateFields,
    };
  }

  @ProvideReactive(ControlDesignContext.Root)
  get root() {
    return this;
  }

  @ProvideReactive(ControlDesignContext.DefaultData)
  get defaultData() {
    return generateMap(
      flatFields(this.fields, { param: true }),
      'pkId',
      getFieldDefaultValue,
    );
  }

  @ProvideReactive(DataFormContext.Meta)
  meta = { preview: true };

  /**
   * @name 设计器中的附件上传地址，仅为了使富文本组件正常运行
   */
  @Provide(UploadPathSymbol)
  uploadPath = 'common/temp/';

  @Provide(FormatContextSymbol)
  formatContext = new FormatContext();

  handleSort(value) {
    if (
      this.templateType === TemplateType.MODEL_TEMPLATE &&
      value.some(field =>
        [
          ControlTypeEnum.CurrentDataSet,
          ControlTypeEnum.AddDataSet,
          ControlTypeEnum.SyncInterface,
          ControlTypeEnum.Int,
          ControlTypeEnum.Float,
          ControlTypeEnum.Double,
          ControlTypeEnum.Input,
          ControlTypeEnum.Date,
          ControlTypeEnum.Switch,
          ControlTypeEnum.Enum,
          ControlTypeEnum.File,
          ControlTypeEnum.Image,
          ControlTypeEnum.DataPoint,
          ControlTypeEnum.Struct,
          ControlTypeEnum.Array,
        ].includes(field.type),
      )
    ) {
      this.$message.error(this.$t('controls.label.unsupported'));
      return;
    }
    if (
      this.templateType === TemplateType.CUSTOM_TEMPLATE &&
      value.some(field =>
        [
          ControlTypeEnum.Int,
          ControlTypeEnum.Float,
          ControlTypeEnum.Double,
          ControlTypeEnum.Input,
          ControlTypeEnum.Date,
          ControlTypeEnum.Switch,
          ControlTypeEnum.Enum,
          ControlTypeEnum.File,
          ControlTypeEnum.Image,
          ControlTypeEnum.DataPoint,
          ControlTypeEnum.Struct,
          ControlTypeEnum.Array,
        ].includes(field.type),
      )
    ) {
      this.$message.error(this.$t('controls.label.unsupported'));
      return;
    }
    const fields = this.baseInfoField ? [...value, this.baseInfoField] : value;
    this.updateFields(fields);
  }

  get filteredFields() {
    return this.fields.filter(
      field =>
        field.type !== ControlTypeEnum.AddDataSet ||
        field.type !== ControlTypeEnum.CurrentDataSet ||
        field.type !== ControlTypeEnum.SyncInterface,
    );
  }

  get baseInfoField() {
    return this.fields.find(field => field.type === ControlTypeEnum.BaseInfo);
  }

  findItemRef(pkId) {
    if (this.baseInfoField?.pkId === pkId) return this.$refs.baseInfo || null;
    return this.$refs.dragItems.find(item => item.field.pkId === pkId) || null;
  }

  get fieldMap() {
    return generateMap(
      flatFields([...this.fields], {
        subform: true,
        parent: true,
        layout: true,
        param: true,
      }),
      'pkId',
      field => field,
    );
  }

  getField(fieldId) {
    return this.fieldMap[fieldId] || null;
  }

  /** @name 此校验方法需要判断返回的errors */
  async validate(needConfirm = true) {
    const form = this.$refs.form;
    if (!form || !form.fields.length) return;
    // clearValidateCache();
    const errors = (
      await Promise.all(
        form.fields.map(
          field =>
            new Promise(resolve =>
              field.validate('', (_, errorMap) =>
                resolve(errorMap ? Object.values(errorMap)[0]?.[0] : null),
              ),
            ),
        ),
      )
    ).filter(error => error);
    const needConfirmErrors = errors.filter(error => error.confirmProp);
    if (needConfirm && needConfirmErrors.length) {
      await this.$confirm({
        width: 500,
        icon: () => <Icon type="tc-icon-warning" />,
        content: (
          <div>
            <h4>以下组件配置错误，保存后将无法正常使用，是否继续？</h4>
            <ul class={this.$style.errorList}>
              {needConfirmErrors.map(error => (
                <li key={error.field}>{`${
                  this.getField(error.field)?.name
                }组件中没有配置“${error.confirmProp}”`}</li>
              ))}
            </ul>
          </div>
        ),
      });
    }
    return errors;
  }

  mounted() {
    this.validate(false);
  }
}
</script>
<style lang="less" module>
.container,
.list {
  height: 100%;
}
.list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 1px;
  padding-bottom: 20px;
  :global {
    .ant-form-item.field-tag--new,
    .ant-form-item.field-tag--clone {
      > .ant-form-item-control-wrapper::after {
        content: '';
        position: absolute;
        right: 0;
        top: 5px;
        bottom: 5px;
      }
    }
    .ant-form-item.field-tag--new > .ant-form-item-control-wrapper::after {
      border-right: 1px solid var(--primary);
    }
    .ant-form-item.field-tag--clone > .ant-form-item-control-wrapper::after {
      border-right: 1px solid #ff9900;
    }
    .ant-form-item[data-type='groupTitle'] .ant-form-item-label {
      display: none;
    }
    .ant-form-item[data-type='groupTitle'],
    .ant-form-item[data-type='tips'] {
      :global(.ant-form-item-label) {
        margin-bottom: 0;
      }
      :global(.ant-form-item-children) {
        min-height: 0;
      }
    }
    .ant-form-item label > .anticon {
      color: var(--font);
      margin-right: 10px;
      vertical-align: -2px;
    }
  }
}
.list:empty {
  height: 100%;
  background-image: url('../../../../../../assets/img/form-design-empty.png');
  background-size: 50% auto;
  background-position: center 30%;
  background-repeat: no-repeat;
}
:global(.dark) .list:empty {
  background-image: url('../../../../../../assets/img/form-design-empty-dark.png');
}
.filtered {
  cursor: pointer !important;
}
.ghost {
  background-color: var(--primary-fade-10);
  &:not(:global(.drag-item)) {
    height: 0;
    font-size: 0;
    padding: 45px 0;
    margin: 0;
    border: none;
    overflow: hidden;
    border-radius: 0;
    box-shadow: none;
    :global(.ant-col),
    [class^='x-subform-design'] {
      display: none;
    }
  }
}
.column-1 .ghost {
  width: 100%;
}
.column-2 .ghost {
  width: 50%;
}
.column-3 .ghost {
  width: 33.3333%;
}
.column-4 .ghost {
  width: 25%;
}
.column-1 .list > :global(.ant-form-item) {
  padding: 10px 30px 15px;
}
.errorList {
  padding-left: 40px;
  color: var(--danger);
}
</style>
