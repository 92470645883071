/** @name 全局权限 */
export const TenantPermission = {
  /** @name 游客 */
  VISITOR: 'VISITOR',
  /** @name 企业普通成员 */
  NORMAL: 'TENANT_NORMAL',
  /** @name 企业管理员 */
  ADMIN: 'TENANT_ADMIN',
  /** @name 企业系统管理员 */
  SYSTEM_ADMIN: 'TENANT_SYSTEM_ADMIN',
  /** @name 企业主 */
  BOSS: 'TENANT_BOSS',
  // 以下两个权限暂时在前端不开放
  // /** @name 三叠云人员 */
  // TRIASCLOUD: 'TRIASCLOUD',
  // /** @name 鉴权服务 */
  // OAUTH: 'OAUTH',
};

const TenantPermissionLevel = [
  TenantPermission.VISITOR,
  TenantPermission.NORMAL,
  TenantPermission.ADMIN,
  TenantPermission.SYSTEM_ADMIN,
  TenantPermission.BOSS,
];

export function level(permission) {
  return TenantPermissionLevel.indexOf(permission);
}
