<template>
  <layout :class="$style.index">
    <layout-menu
      :menus="menus"
      :class="$style.menus"
      :value="menu ? menu.path : $route.path"
    >
      <template slot="menu" slot-scope="menuItem">
        <div :title="menuItem.title" :class="$style.menuTlt">
          {{ menuItem.title }}
        </div>
      </template>
      <template v-slot:header-left>
        <x-icon type="tc-icon-company" :class="$style.icon" />
        <span :title="$t('enterprise.buildManage')">{{
          $t('enterprise.buildManage')
        }}</span>
      </template>
    </layout-menu>
    <layout-content :class="$style.content">
      <template v-slot:header-left>{{ title }}</template>
      <router-view slot="content" />
    </layout-content>
  </layout>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Layout, LayoutContent, LayoutMenu } from '@triascloud/x-blocks';
import { findTree } from '@triascloud/utils';

@Component({
  components: {
    Layout,
    LayoutContent,
    LayoutMenu,
  },
})
export default class IndexPage extends Vue {
  get menus() {
    return (
      [
        {
          path: 'dictionary',
          title: this.$t('dictionary.title'),
          children: [
            {
              path: '/dictionary',
              title: this.$t('path.dictionary./'),
            },
            {
              path: '/dictionary/review',
              title: this.$t('path.dictionary.review'),
            },
          ],
        },
        {
          path: 'gallery',
          title: this.$t('gallery.title'),
          children: [
            {
              path: '/gallery',
              title: this.$t('path.gallery./'),
            },
            {
              path: '/gallery/review',
              title: this.$t('path.gallery.review'),
            },
            {
              path: '/icon',
              title: this.$t('path.icon./'),
            },
          ],
        },
        {
          path: '/feedback',
          title: this.$t('path.feedback./'),
        },
        {
          path: '/kitMarket',
          title: '套件市场',
          auth: true,
        },
        {
          path: 'connector',
          title: this.$t('connector.title'),
          auth: true,
          children: [
            {
              path: '/connector/management',
              title: this.$t('path.connector.management'),
              auth: true,
            },
            {
              path: '/connector/review',
              title: this.$t('path.connector.review'),
              auth: true,
            },
            {
              path: '/connector/digitalTwins',
              title: this.$t('path.connector.digitalTwins'),
              target: '_blank',
              auth: true,
            },
          ],
        },
        {
          path: 'market',
          title: this.$t('market.title'),
          children: [
            {
              path: '/market/application',
              title: this.$t('path.market./'),
              alias: '/market',
            },
            {
              path: '/market/form',
              title: this.$t('path.market.form'),
              alias: '/market',
            },
            {
              path: '/market/print',
              title: this.$t('market.template.printTemplate'),
              alias: '/market',
            },
            {
              path: '/market/statistics',
              title: this.$t('market.statistics.templateStatistics'),
              alias: '/market',
            },
          ],
        },
        {
          path: '/help',
          title: this.$t('path.help./'),
        },
        {
          path: '/solution',
          title: this.$t('solution.solutionManagement'),
          children: [
            {
              path: '/solution',
              title: this.$t('solution.solutionManagement'),
            },
            {
              path: '/solution/management',
              title: this.$t('solution.solutionManagements'),
            },
          ],
        },
        {
          path: '/template/review',
          title: this.$t('path.template.review'),
        },
        {
          path: 'website',
          title: this.$t('path.website./'),
          children: [
            {
              path: '/website/official',
              title: this.$t('market.triassicWebsite'),
              alias: '/website',
            },
            {
              path: '/website/fire',
              title: this.$t('path.co.website.fire', 'package'),
              alias: '/website',
            },
            {
              path: '/website',
              title: this.$t('market.cloudWebsite'),
            },
            {
              path: '/website/guide',
              title: this.$t('path.website.guide'),
            },
          ],
        },
        {
          path: '/record',
          title: this.$t('inviteCode.updateSettings'),
        },
        {
          path: '/invitation',
          title: this.$t('inviteCode.userCodeSet'),
        },
        {
          path: '/companyCode',
          title: this.$t('inviteCode.enterpriseCode'),
        },
        {
          path: '/statistics',
          title: this.$t('inviteCode.statisticsTlt'),
        },
        {
          path: '/activity',
          title: this.$t('activity.Activity'),
          children: [
            {
              path: '/activity/management',
              title: this.$t('activity.managementActivity'),
            },
            {
              path: '/activity/invitation',
              title: this.$t('activity.invitationTlt'),
            },
          ],
        },
        {
          path: '/plugin',
          title: this.$t('plugin.title'),
          validate: () =>
            this.$p('/plugin/snms') ||
            this.$p('/plugin/api') ||
            this.$p('/plugin/data'),
          children: [
            {
              path: '/plugin/overview',
              title: this.$t('plugin.overview.plugins'),
              validate: () =>
                this.$p('/plugin/snms') ||
                this.$p('/plugin/api') ||
                this.$p('/plugin/data'),
            },
            {
              path: '/plugin/snms',
              title: this.$t('plugin.snms.name'),
              auth: true,
            },
            {
              path: '/plugin/api',
              title: this.$t('plugin.overview.apicpt'),
              auth: true,
            },
            {
              path: '/plugin/data',
              title: this.$t('plugin.overview.Database'),
              auth: true,
            },
          ],
        },
        {
          path: '/attest',
          title: this.$t('enterprise.attest'),
        },
        {
          path: '/order-bill',
          title: this.$t('solution.orderBilling'),
        },
        {
          path: '/registeredUserFollowup',
          title: this.$t('business.registeredUserFollowup'),
        },
        {
          path: '/hat',
          title: this.$t('hat.title'),
          children: [
            {
              path: '/hat/device',
              title: '设备管理',
            },
            {
              path: '/hat/qualityCheck',
              title: '生产质检',
            },
            {
              path: '/hat/statistics',
              title: '用量统计',
            },
          ],
        },
        {
          path: '/ds',
          title: this.$t('ds.title'),
          children: [
            {
              path: '/ds/manufacturer',
              title: this.$path('/ds/manufacturer'),
            },
            {
              path: '/ds/device',
              title: this.$path('/ds/device'),
            },
            {
              path: '/ds/developer',
              title: this.$path('/ds/developer'),
            },
            {
              path: '/ds/template',
              title: this.$path('/ds/template'),
            },
          ],
        },
      ]
        // 等待权限设置
        .filter(item => {
          // 过滤权限菜单
          if (item.children) {
            item.children = item.children.filter(
              child =>
                this.$p(child.alias || child.path) || child.auth === false,
            );
            return item.children.length;
          } else {
            return this.$p(item.alias || item.path) || item.auth === false;
          }
        })
        .filter(item => {
          if (item.children) {
            item.children = item.children.filter(child =>
              child.validate ? child.validate() : true,
            );
          }
          return item.validate ? item.validate() : true;
        })
    );
  }

  get menu() {
    let menu = findTree(this.menus, item => item.path === this.$route.path);
    if (!menu) {
      menu = findTree(
        this.menus,
        item =>
          item.path ===
          this.$route.matched[this.$route.matched.length - 2]?.path,
      );
    }
    return menu;
  }
  get title() {
    return this.menu && this.menu.title
      ? this.menu.title
      : this.$path(this.$route.path);
  }
  @Watch('menus')
  redirect() {
    if (this.$route.path === '/') {
      const path =
        this.menus[0] &&
        this.menus[0].children &&
        this.menus[0].children[0] &&
        this.menus[0].children[0].path;
      path && this.$router.replace(path);
    }
  }
}
</script>
<style lang="less" module>
.menus {
  width: 200px;
  .icon {
    font-size: 18px;
    margin-right: 10px;
  }
}
.content {
  flex: 1;
}
.menuTlt {
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
  position: relative;
}
</style>
