<template>
  <div :class="$style.wrap" ref="wrap"></div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'moment';
import { previewPosition } from '@/services/iot-platform/platformDevice';
import { funAMap, mouseEvent } from './util';

@Component()
export default class DeviceLocation extends Vue {
  @Prop() detail;
  async mounted() {
    await this.previewPosition();
    await this.init();
  }

  center = [];
  time = undefined;
  async previewPosition() {
    if (Object.keys(this.detail).length === 0) return false;
    const res = await previewPosition(this.detail);
    if (res.point2D) {
      this.center = [res.point2D.x, res.point2D.y];
      this.time = dayjs(res.time).format('YYYY/MM/DD HH:mm:ss');
    } else {
      this.$message.warn('暂无定位数据！');
    }
  }

  createMarker(position) {
    return new this.AMap.Marker({
      position,
      draggable: true,
      zIndex: 8,
    });
  }

  AMap = undefined;
  map = undefined;
  async init() {
    const AMap = await funAMap();
    this.AMap = AMap;
    const opt = {
      zoom: 16,
    };
    if (this.center.length) {
      opt['center'] = this.center;
    }
    this.map = new AMap.Map(this.$refs.wrap, opt);

    if (this.center.length) {
      const centerMarker = this.createMarker(this.center);
      centerMarker.setExtData({
        lng: this.center[0],
        lat: this.center[1],
        time: this.time,
      });
      this.map.add(centerMarker);
      mouseEvent(AMap, centerMarker, this.map);
    }
  }
}
</script>
<style lang="less" module>
.wrap {
  height: 300px;
}
</style>
