export const FeedbackState = {
  Waiting: 1,
  Adopted: 2,
  Processed: 3,
  Rejected: 4,
};

export const FeedbackType = {
  Form: 0,
  Enterprise: 1,
  Documentation: 2,
  Features: 3,
  Suggest: 4,
  Other: 5,
};

export const FeedbackReplyType = {
  Processed: 1,
  Reply: 2,
  Adopt: 3,
  Reject: 4,
};

export const FeedbackStateText = {
  [FeedbackState.Waiting]: 'feedback.state.pending',
  [FeedbackState.Adopted]: 'feedback.state.adopted',
  [FeedbackState.Processed]: 'feedback.state.processed',
  [FeedbackState.Rejected]: 'feedback.state.rejected',
};
export const FeedBackTypeText = {
  [FeedbackType.Form]: 'feedback.types.form',
  [FeedbackType.Enterprise]: 'feedback.types.enterprise',
  [FeedbackType.Documentation]: 'feedback.types.document',
  [FeedbackType.Features]: 'feedback.types.features',
  [FeedbackType.Suggest]: 'feedback.types.suggest',
  [FeedbackType.Other]: 'feedback.types.other',
};
