<template>
  <div :class="$style.TemplateStatistics">
    <switch-tab></switch-tab>
    <header-list :data="data"></header-list>
    <div :class="$style.chart">
      <div :class="$style.chart_row">
        <ManageChartsWrap
          v-for="item in dataList"
          :key="item.key"
          :title="item.title"
          :isEmpty="item.data.length"
          :loading="loading"
          :class="$style.chart_wrap"
          :timeOptionsProp="timeOptionsProp"
        >
          <template v-slot="{ selectTime }">
            <Component
              :class="$style.chart_item"
              :is="item.component"
              :selectTime="selectTime"
              :data="item.data[selectTime]"
              :key="selectTime"
              v-if="item.hasData(item.data[selectTime])"
            />
            <EmptyContent
              type="list"
              :class="$style.chart_item"
              v-if="!item.hasData(item.data[selectTime]) && !loading"
            />
          </template>
        </ManageChartsWrap>
        <NodeDetailsTable />
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import HeaderList from './components/header-list.vue';
import ManageChartsWrap from '@/views/solution/components/manage-charts-wrap.vue';
import EmptyContent from '@/components/empty-content';
import { generateMap } from '@triascloud/utils';
import LogoRankingCharts from './components/logo-ranking-charts.vue';
import LogoRegistrationCharts from './components/logo-registration-charts.vue';
import LogoResolutionCharts from './components/logo-resolution-charts.vue';
import NodeDetailsTable from './components/node-details-table.vue';
import { getNodeData } from '@/services/template-manager/secondary';
import SwitchTab from '@/views/plugin/components/switch-tab.vue';

@Component({
  components: {
    SwitchTab,
    HeaderList,
    ManageChartsWrap,
    EmptyContent,
    LogoRankingCharts,
    LogoRegistrationCharts,
    LogoResolutionCharts,
    NodeDetailsTable,
  },
})
export default class nodeManage extends Vue {
  data = {
    identifyAnalysis: [],
    identifyRegistration: [],
    identityLeaderboard: [],
    templateTotalAmountVO: {
      amount: 99999909,
      appInstallCount: 447,
      appPreviewInstallationConversionRates: 37.31218697829716,
      appTemplateCount: 68,
      buyCount: 10,
      dayCompared: 0,
      formInstallCount: 143,
      formPreviewInstallationConversionRates: 6.918238993710692,
      formTemplateCount: 45,
      templateAccess: 3265,
      templateAccessDayCompared: 0,
      templateAccessWeekCompared: 13,
      templateContributionEnterprise: 16,
      weekCompared: -100,
    },
  };

  get timeOptionsProp() {
    return [
      {
        text: this.$t('common.label.all'),
        value: 'ALL',
      },
      {
        text: this.$t('plugin.snms.lastDays'),
        value: 'WEEK',
      },
      {
        text: this.$t('plugin.snms.lastMonth'),
        value: 'MONTH',
      },
      {
        text: this.$t('plugin.snms.lastYear'),
        value: 'YEAR',
      },
    ];
  }

  initData(arr) {
    return arr && arr.length ? generateMap(arr, 'timeType', item => item) : [];
  }
  loading = false;
  created() {
    this.getData();
  }
  async getData() {
    this.loading = true;
    this.data = await getNodeData();
    this.loading = false;
  }
  get dataList() {
    return [
      {
        key: 'identityLeaderboard',
        component: LogoRankingCharts,
        title: this.$t('plugin.snms.logoLeaderboard'),
        hasData: data => data && data.data && data.data.length,
        data: this.initData(this.data.identityLeaderboard),
      },
      {
        key: 'identifyRegistration',
        component: LogoRegistrationCharts,
        title: this.$t('plugin.snms.LogoRegistration'),
        hasData: data => data && data.data && data.data.length,
        data: this.initData(this.data.identifyRegistration),
      },
      {
        key: 'identifyAnalysis',
        component: LogoResolutionCharts,
        title: this.$t('plugin.snms.statisticsLogo'),
        hasData: data => data && data.data && data.data.length,
        data: this.initData(this.data.identifyAnalysis),
      },
    ];
  }
}
</script>
<style lang="less" module>
.TemplateStatistics {
  padding-bottom: 20px;
  width: 100%;
  min-width: 1500px;
}
.chart_row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}
.chart {
  width: 100%;
}

.chart_item {
  height: 390px;
}
.chart_wrap {
  margin-bottom: 20px;
  border-radius: 1px;
  width: calc(33.33% - 13.3px);

  &:nth-child(3n-1) {
    margin: 0 20px;
  }
}
</style>
