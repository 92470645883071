<template>
  <div :class="$style.index">
    <div :class="$style.indexTop">
      <div :class="$style.topItem">
        {{ $t('solution.ventures') }}：<span
          >{{ data.buildEnterpriseCount }}{{ $t('common.label.piece') }}</span
        >
      </div>
      <div :class="$style.topItem">
        {{ $t('solution.solutionsTotalTlt') }}：<span
          >{{ data.solutionCount }}{{ $t('common.label.piece') }}</span
        >
      </div>
      <div :class="$style.topItem">
        {{ $t('solution.paidToal') }}：<span
          >{{ data.paidSolution }}{{ $t('common.label.piece') }}</span
        >
      </div>
    </div>
    <a-table
      :columns="columns"
      :pagination="false"
      :data-source="data.buildEnterpriseList"
      :class="$style.table"
      :scroll="{
        x: '100%',
        y: '100%',
      }"
    >
    </a-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class ManageCompanyCount extends Vue {
  @Prop({ type: Object, default: () => null }) data;

  get columns() {
    return [
      {
        dataIndex: 'enterpriseName',
        title: this.$t('inviteCode.enterpriseName'),
      },
      {
        dataIndex: 'solutionCount',
        title: this.$t('solution.solutionsNumber'),
      },
      {
        dataIndex: 'paidSolutionCount',
        title: this.$t('solution.paidToal'),
      },
    ].map(item => ({
      ...item,
      sorter: false,
      filter: false,
      width: item.width || 100,
      align: 'center',
      ellipsis: true,
    }));
  }
}
</script>
<style lang="less" module>
.index {
}
.table {
  padding: 20px;
  height: calc(100vh - 200px);
}
.indexTop {
  border-bottom: 1px solid var(--border);
  display: flex;
  flex-wrap: wrap;
  padding: 0 14px;
  justify-content: space-between;
  padding-bottom: 10px;
}
.topItem {
  width: 50%;
  color: var(--font-info);
  font-size: 14px;
  line-height: 14px;
  margin: 10px 0px;
  span {
    color: var(--font);
  }
}
</style>
