<template>
  <ul :class="$style.infoItem">
    <li>
      <!-- 跟进时间 -->
      <span :class="$style.label">{{ $t('business.followupTime') }}: </span>
      <span :class="$style.items">
        {{ $moment(itemInfo.createTime).format('YYYY-MM-DD') || '-' }}
      </span>
    </li>
    <li>
      <!-- 跟进人 -->
      <span :class="$style.label">{{ $t('business.followupPerson') }}: </span>
      <span :class="$style.items">
        {{ itemInfo.nameOfFollowUpPerson || '-' }}
      </span>
    </li>
    <li>
      <!-- 是否续费 -->
      <span :class="$style.label">{{ $t('business.renewalOrNot') }}: </span>
      <span :class="$style.items">
        {{ getText(itemInfo.renewalOrNot) }}
      </span>
    </li>
    <li>
      <!-- 是否有定制需求 -->
      <span :class="$style.label">
        {{ $t('business.whetherThereIsCustomizationRequirement') }}:
      </span>
      <span :class="$style.items">
        {{ getText(itemInfo.customizedDemand) }}
      </span>
    </li>
    <li>
      <!-- 跟进记录 -->
      <span :class="$style.label"> {{ $t('business.followupRecord') }}: </span>
      <span :class="$style.items">
        {{ itemInfo.otherRecord || '-' }}
      </span>
    </li>
  </ul>
</template>

<script>
/** 跟进记录列表元素组件 */

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class FollowupInfoItem extends Vue {
  /** 每条记录的信息 */
  @Prop({ type: Object, default: () => null }) itemInfo;

  getText(type) {
    switch (type) {
      case 1:
        return this.$t('template.review.checked');
      case 0:
        return this.$t('template.review.unChecked');
      default:
        return '-';
    }
  }
}
</script>

<style lang="less" module>
.infoItem {
  width: 100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid var(--border);
  padding: 20px;
  li {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    .label {
      color: var(--font-info);
      min-width: 112px;
      text-align: right;
    }
    .items {
      color: var(--font);
      margin-left: 10px;
    }
  }
}
</style>
