import request from '../request';
import { stringify } from '@triascloud/utils';

const basePath = '/tenant-center/tenant-invite-code';

/** @name 用户邀请码范围 */
export const tenantInviteList = ({
  current,
  endTime,
  ifUsed,
  keyword,
  startTime,
  size,
}) =>
  request(`${basePath}/list`, {
    method: 'POST',
    body: {
      current,
      endTime,
      ifUsed,
      keyword,
      startTime,
      size,
    },
  });

/** @name 添加邀请码 */
export const tenantAddInviteCode = count =>
  request(`${basePath}/generate?count=${count}`, {
    method: 'GET',
  });

/** @name 邀请码统计 */
export const tenantInviteCodeCount = () =>
  request(`${basePath}/count`, {
    method: 'GET',
  });

/** @name 邀请码复制 */
export const tenantCopyCode = pkId =>
  request(`${basePath}/copyCode?pkId=${pkId}`, {
    method: 'GET',
  });

/** @name 使用记录 */
export const useHistory = pkId => {
  return request(`${basePath}/useHistory?pkId=${pkId}`, {
    method: 'GET',
  });
};

/** @name 邀请码排行 */
export const rankData = () => {
  return request(`${basePath}/ranking`, {
    method: 'GET',
  });
};

/** @name 邀请码排行 */
export const statisticsData = () => {
  return request(`${basePath}/tenant/statistics`, {
    method: 'GET',
  });
};

/** @name 邀请详情 */
export const detail = ({ orderBy, size, skip }) => {
  return request(`${basePath}/invite/detail`, {
    method: 'POST',
    body: {
      orderBy,
      size,
      skip,
    },
  });
};

/** @name 付费占比 */
export const amount = () => {
  return request(`${basePath}/ratio/of/paid/amount`, {
    method: 'GET',
  });
};

/** @name 邀请企业数量详情 */
export const count = data => {
  return request(`${basePath}/invite/tenant/count`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: stringify(data),
  });
};

/** @name 邀请企业付费详情 */
export const payCountDetail = data => {
  return request(`${basePath}/invite/tenant/pay`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: stringify(data),
  });
};

/** @name 导出邀请企业付费详情 */
export const exportDetail = () => {
  return request(`${basePath}/invite/detail/export`, {
    method: 'GET',
  });
};

/** @name 企业邀请码明细 */
export const tenantCodeList = ({
  current,
  endTime,
  ifUsed,
  keyword,
  startTime,
  size,
}) =>
  request(`${basePath}/all/tenant/code/list`, {
    method: 'POST',
    body: {
      current,
      endTime,
      ifUsed,
      keyword,
      startTime,
      size,
    },
  });

/** @name 企业邀请码数量明细 */
export const tenantCodeCount = () =>
  request(`${basePath}/all/tenant/code/count `, {
    method: 'GET',
  });

/** @name 用户邀请码明细 */
export const inviteList = ({
  current,
  endTime,
  ifUsed,
  keyword,
  startTime,
  size,
}) => {
  return request(`${basePath}/all/member/code/list`, {
    method: 'POST',
    body: {
      current,
      endTime,
      ifUsed,
      keyword,
      startTime,
      size,
    },
  });
};

/** @name 用户邀请码数量明细 */
export const inviteCodeCount = () =>
  request(`${basePath}/all/member/code/count`, {
    method: 'GET',
  });
