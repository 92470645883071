<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item
      :label="$t('connector.accessPlatform.showName')"
      prop="showText"
    >
      <a-input
        :placeholder="$t('connector.accessPlatform.inputShowName')"
        :maxLength="12"
        v-model="form.showText"
      />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('connector.accessPlatform.jumpLink')"
      prop="url"
    >
      <a-input
        :placeholder="$t('connector.accessPlatform.inputJumpName')"
        v-model="form.url"
      />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class LinkForm extends Vue {
  form = {
    showText: '',
    url: '',
  };
  rules = {
    showText: [
      {
        required: true,
        message: this.$t('connector.accessPlatform.showNameEmpty'),
      },
    ],
    url: [
      {
        type: 'string',
        required: true,
        validator: this.checkUrl,
        trigger: 'blur',
      },
    ],
  };
  async getValue() {
    await this.$refs.form.validate();
    return this.form;
  }
  checkUrl(rule, value, callback) {
    const reg = /^(?:(http|https|ftp):\/\/)((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i;
    if (value === '') {
      const str = this.$t('connector.accessPlatform.jumpLinkEmpty');
      callback(new Error(str));
    } else if (!reg.test(value)) {
      const str = this.$t('connector.accessPlatform.jumpLinkRight');
      callback(new Error(str));
    } else {
      callback();
    }
  }
}
</script>

<style scoped></style>
