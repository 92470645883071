import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { ensureArray, generateMap } from '@triascloud/utils';
import { FieldRender } from '../render';
import {
  ControlTypeEnum,
  execDataFill,
  flatFields,
  getControl,
  getFieldValueType,
  getWatcherFieldsByFilter,
  getWatcherFieldsByFormula,
  transformValue,
  FormatContextSymbol,
} from '../controls';
import { DataFormContext } from './context';
@Component({ components: { FieldRender } })
export default class DataFormFieldContext extends Vue {
  /** @name 渲染类型 */
  @Prop({ type: [String, Function], default: 'form' }) type;
  /**
   * @name 字段配置
   * @type { import('../controls/BaseControl').Field }
   */
  @Prop({ type: Object, required: true }) field;
  /**
   * @name 表单根节点
   * @type { import('./data-form.vue').default } */
  @Inject(DataFormContext.Root) root;
  /**
   * @name 上下文模型
   * @type { import('./data-form-model-context').default['model'] }
   */
  @Inject(DataFormContext.Model) model;
  /**
   * @name 字段是否查看状态
   * @type { boolean }
   */
  /** @name 外部传入的view */
  @Prop({ type: Boolean }) view;
  get computedView() {
    return this.view || this.root.view || this.field.widget.view;
  }

  /** @name 字段控件 */
  get Control() {
    return getControl(this.field.type);
  }

  get value() {
    return this.model.getValue(this.field.pkId);
  }

  handleInput(value) {
    this.model.setValue(
      this.field.pkId,
      value,
      this.Control.trigger === 'change',
    );
    this.$emit('change');
  }

  _cachedValue = null;
  handleFocus() {
    // 缓存focus时的值，来避免blur时不必要的更新
    this._cachedValue = this.value;
  }
  handleBlur() {
    if (this.Control.trigger === 'blur' && this._cachedValue !== this.value) {
      this.model.trigger(this.field.pkId);
    }
    this._cachedValue = null;
    this.$emit('blur');
  }

  /** @type { Partial<import('../controls').WidgetDefaultValue> } */
  get defaultValue() {
    return this.field.widget.defaultValue || {};
  }

  get formulaFields() {
    return getWatcherFieldsByFormula(this.defaultValue.formula, this.model);
  }

  get fieldValueType() {
    return getFieldValueType(this.field);
  }

  /** @name 数据联动配置 */
  get linkage() {
    return (
      this.defaultValue.linkage || {
        targetForm: '',
        targetField: '',
        filter: null,
      }
    );
  }

  /** @name 数据联动字段 */
  get linkageFields() {
    return getWatcherFieldsByFilter(this.linkage.filter);
  }

  /** @type { import('../controls/FormatContext').FormatContext } */
  @Inject(FormatContextSymbol) formatContext;

  loading = false;
  disableChange = false;
  updateLinkageValue(disableChange) {
    this.disableChange = disableChange === true;
    this.loading = true;
  }
  @Watch('loading')
  async handleLoadingChange(loading) {
    if (loading !== true) return;
    await this.$nextTick();
    this._updateLinkageValue()
      .then(() =>
        this.disableChange === true
          ? (this.disableChange = false)
          : this.$emit('change'),
      )
      .finally(() => (this.loading = false));
  }

  async _updateLinkageValue() {
    const { targetField, targetForm, subformDataFill } = this.linkage;
    if (!targetForm) return;
    // const filterGroup = generateFormFilterGroup(
    //   filter,
    //   this.linkageTargetFieldMap,
    //   this.model,
    // );

    let linkageValue;
    // 子表单数据联动父表单
    if (targetForm === targetField) {
      // linkageValue = (
      //   await searchReferenceListByFilter(targetForm, filterGroup)
      // ).map(row => row.data);
    } else {
      // const linkageData = await searchReferenceDataByFilter(
      //   targetForm,
      //   filterGroup,
      // );
      // linkageValue = linkageData && get(linkageData, `data.${targetField}`);
    }

    let value = null;
    // 子表单数据联动
    if (this.field.type === ControlTypeEnum.Subform) {
      const childFieldMap = generateMap(
        this.field.widget.fields,
        'pkId',
        field => field,
      );
      value = await Promise.all(
        ensureArray(linkageValue).map(async row => {
          const result = {};
          await execDataFill(
            subformDataFill,
            this.linkageTargetFieldMap,
            row,
            fieldId => childFieldMap[fieldId] || this.model.getField(fieldId),
            this.formatContext,
            (rule, toValue) => (result[rule.to] = toValue),
          );
          return result;
        }),
      );
    } else {
      value = await transformValue(
        linkageValue,
        this.linkageTargetFieldMap[targetField],
        this.field,
        this.formatContext,
      );
    }
    this.model.setValue(this.field.pkId, value);
  }

  linkageTargetFieldList = [];

  async addLinkageWatcher() {
    if (!this.linkage?.targetForm) return;
    // this.linkageTargetFieldList = (
    //   await getFormData(this.linkage.targetForm)
    // ).items;
    if (this.linkageFields.length) {
      this.model.watch(this.linkageFields, this.updateLinkageValue);
    }
    // 当字段已经存在值时，不自动重新计算
    if (this.value != null) return;
    this.updateLinkageValue(true);
  }

  get linkageTargetFieldMap() {
    return generateMap(
      flatFields(this.linkageTargetFieldList, { subform: true }),
      'pkId',
      field => field,
    );
  }

  created() {
    // 当表单整体只读时，不添加监听
    if (!this.root || this.root.view) return;
    if (this.root.tools) {
      this.root.tools.forEach(
        tool => tool.initField && tool.initField(this.field, this.model),
      );
    }
    switch (this.defaultValue.type) {
      // case 'formula':
      //   return this.addFormulaWatcher();
      case 'linkage':
        return this.addLinkageWatcher();
      default:
        return;
    }
  }

  render() {
    const listeners = {
      'update:field': val => {
        this.field = val;
      },
    };
    return (
      <FieldRender
        type={this.type}
        field={this.field}
        {...{ on: listeners }}
        value={this.value}
        view={this.computedView}
        loading={this.loading}
        onInput={this.handleInput}
        onFocus={this.handleFocus}
        onblur={this.handleBlur}
      />
    );
  }
}
