// @ts-check
import { BaseControl } from '../BaseControl';
import { uuid } from '@triascloud/utils';
// import { globalVueI18n } from '@triascloud/i18n';
import DesignComponent from './design.vue';
import ConfigComponent from './config.vue';
import { ParamType } from '../enum';
/** @name 入参和出参 */
class CustomParamControl extends BaseControl {
  /** @readonly */
  type = 'customParam';

  label = 'controls.customParam.label';

  icon = 'file';

  actionOpen = true;

  /** @type { import('../BaseControl').WidgetGenerator } */
  widget = () => ({
    ...super.widget(),
    defaultValue: {
      type: 'custom',
      custom: '',
    },
    tabs: [
      {
        id: uuid(),
        type: ParamType.REQ_PARAM,
        title: '入参',
        fields: [],
      },
      {
        id: uuid(),
        type: ParamType.RES_PARAM,
        title: '出参',
        fields: [],
      },
    ],
    hideTitle: true,
    current: '',
    alone: true, // 独立字段，不允许操作
  });

  DesignComponent = DesignComponent;

  ConfigComponent = ConfigComponent;
}

export default new CustomParamControl();
