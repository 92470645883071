/**
 * @name 字段设计与配置组件需要用到的上下文
 * @description 必须使用InjectReactive
 */
export const ControlDesignContext = {
  Root: Symbol('ControlDesignContext.Root'),
  /** @name 当前表单全部字段（未平铺） */
  Fields: Symbol('ControlDesignContext.Fields'),
  /** @name 当前字段上下文字段列表（同一层级的字段+平铺父级字段列表-当前选中字段） */
  ContextFields: Symbol('ControlDesignContext.ContextFields'),
  /** @name 当前配置字段的父字段 */
  ParentField: Symbol('ControlDesignContext.ParentField'),
  /** @name 设计器中的表单默认值  */
  DefaultData: Symbol('ControlDesignContext.DefaultData'),
  /** @name 检查字段是否可见 */
  CheckVisible: Symbol('ControlDesignContext.CheckVisible'),
};
