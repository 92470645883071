import AMapLoader from '@amap/amap-jsapi-loader';
/**
 * @description 高德AMap对象
 * @param { Object } option 高德'@amap/amap-jsapi-loader'的option
 */
export async function funAMap(option) {
  const opt = {
    key: process.env.VUE_APP_AMAP_API_KEY,
    version: '2.0',
    plugins: ['AMap.PolygonEditor', 'AMap.CircleEditor', 'AMap.PlaceSearch'],
  };
  let options = Object.assign(opt, option);
  return await AMapLoader.load(options);
}

/** @name hover弹窗 */
const hoverDialog = msg => {
  return `
    <style>
    .worldWrap {
      background-color: rgba(0, 0, 0, 0.6);
      padding: 6px 10px;
      border-radius: 4px;
      position: relative
    }
    .worldWrap::before {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-top: 10px solid rgba(0, 0, 0, 0.6);
    }
    .worldWrap .txt {
      color: #fff;
      font-size: 14px;
      display: block;
      min-width: 220px;
    }
    .worldWrap .txt + .txt {
      margin-top: 5px;
    }
    </style>
    <section class="worldWrap">
      <span class="txt"
        >经度：${msg.lng}</span
      >
      <span class="txt"
        >维度：${msg.lat}</span
      >
      <span class="txt"
        >最近更新：${msg.time || '-'}</span
      >
    </section>
  `;
};
/** @name AMap地图mouse事件  */
export const mouseEvent = (AMap, chart, mapObject) => {
  chart.on('mouseover', event => {
    const extData = event.target.getExtData();

    const textField = new AMap.Marker({
      content: hoverDialog(extData),
      position: [event.lnglat.lng, event.lnglat.lat],
      offset: new AMap.Pixel(-120, -100),
      zIndex: 101,
    });

    event.target.textField = textField;
    mapObject.add(event.target.textField);
  });
  chart.on('mouseout', event => {
    event.target.textField &&
      mapObject.remove(event.target.textField) &&
      (event.target.textField = null);
  });
};
