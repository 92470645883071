<template>
  <a-form-model
    :class="$style.wrap"
    layout="horizontal"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-form-model-item label="发布内容">
      <span
        >{{ record.content }}&nbsp;
        <a-tooltip>
          <template slot="title">
            查看当前发布内容的历史版本数据
          </template>
          <a-icon
            type="container"
            :class="$style.copyBtn"
            @click="handleDetail(record)"
          /> </a-tooltip
      ></span>
    </a-form-model-item>
    <a-form-model-item label="发布人">
      <span
        >{{ record.createBy }}&nbsp;<a-icon
          type="copy"
          :class="$style.copyBtn"
          @click="copyCode(record)"
      /></span>
    </a-form-model-item>
    <a-form-model-item label="产品分类" ref="firstType" prop="firstType">
      <a-row>
        <a-col :span="11">
          <a-select
            v-model="form.firstType"
            :class="$style.selectWrap"
            @change="handleChange"
            @blur="
              () => {
                $refs.firstType.onFieldBlur();
              }
            "
          >
            <a-select-option
              v-for="item in list"
              :key="item.pkId"
              :value="item.pkId"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col :span="11">
          <a-select v-model="form.secondType" :class="$style.selectWrap">
            <a-select-option
              v-for="item in subList"
              :key="item.pkId"
              :value="item.pkId"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col
          v-if="currentCategory.pkId"
          :class="$style.btnWrap"
          :span="2"
          @click="handleAddType"
        >
          管理
        </a-col>
      </a-row>
    </a-form-model-item>
    <a-form-model-item label="产品介绍">
      <span :class="$style.btnWrap" @click="introduceModal">预览</span>
    </a-form-model-item>
    <a-form-model-item label="设备面板">
      <span :class="$style.btnWrap" @click="panelModal">预览</span>
    </a-form-model-item>
    <a-form-model-item label="审批意见" prop="desc">
      <a-textarea v-model="form.desc" :auto-size="{ minRows: 6 }"></a-textarea>
    </a-form-model-item>
    <a-form-model-item :class="$style.btnBottom">
      <a-button @click="handleApproval(false)">驳回</a-button>
      <a-button
        :class="$style.margin"
        type="primary"
        @click="handleApproval(true)"
        >审批通过</a-button
      >
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  getCategoryList,
  getCategorySubList,
  updateCategory,
} from '@/services/iot-platform/category';
import { approval } from '@/services/iot-platform/releaseRecord';
import { clipboardWrite } from '@triascloud/utils';
import { createFormModal, createModal } from '@triascloud/x-components';
import Add from '../category/add.vue';
import Detail from './detail.vue';
import Panel from '../../platform-manage/develop/device-manage/panel/index.vue';
import Introduce from '../../platform-manage/develop/device-manage/introduce/index.vue';

@Component()
export default class ReviewOption extends Vue {
  @Prop({ type: Function }) close;
  @Prop({ type: Object }) record;
  form = {
    firstType: undefined,
    secondType: undefined,
    desc: '',
  };
  rules = {
    desc: [{ required: true, message: '审批意见不能为空！' }],
    firstType: [{ required: true, message: '产品分类不能为空！' }],
  };

  mounted() {
    if (this.record.category) {
      this.form.firstType = this.record.category.pkId;
      this.handleChange(this.form.firstType);
      this.form.secondType = this.record.category.childrenList[0].pkId;
    }
    this.getCategoryList();
  }

  async handleApproval(passed = false) {
    const valid = await this.$refs.form.validate();
    if (valid) {
      const res = await approval({
        pkId: this.record.pkId,
        appraiseOpinion: this.form.desc,
        isPassed: passed,
        idxCategoryId: this.form.secondType,
      });
      if (res) {
        this.close();
        this.$message.success('操作成功！');
      }
    }
  }

  list = [];
  async getCategoryList() {
    const { records } = await getCategoryList({
      current: 1,
      size: 100,
    });
    records.forEach(v => {
      v.firstName = v.name;
      v.secondName = '';
      v.children.forEach(i => {
        i.firstName = '';
        i.secondName = i.name;
        if (i['children']) {
          delete i.children;
        }
      });
    });
    this.list = records;
  }

  get currentCategory() {
    if (this.form.firstType) {
      const item = this.list.find(v => v.pkId === this.form.firstType);
      if (item) {
        return item;
      } else {
        return {};
      }
    } else {
      return {};
    }
  }

  parentId = '';
  handleChange(val) {
    this.parentId = val;
    this.form.secondType = '';
    this.getCategorySubList();
  }

  subList = [];
  subKeyword = '';
  async getCategorySubList() {
    const records = await getCategorySubList({
      parentId: this.parentId,
      keyword: this.subKeyword,
    });
    this.subList = records;
  }

  async handleAddType() {
    if (!this.currentCategory.pkId) {
      return;
    }
    const form = await createFormModal(
      () => <Add detail={this.currentCategory} />,
      {
        title: '编辑分类',
        width: 500,
      },
    );
    const option = {
      name: form.firstName,
      headImgUrl: form.firstPic[0].ossPath,
      sort: 0,
      children: [],
      pkId: this.currentCategory.pkId,
    };

    let list = [];
    form.secondSort.forEach((v, idx) => {
      const option = {
        name: v.name,
        headImgUrl: v.images[0].ossPath,
        sort: idx,
      };
      if (v.pkId) {
        option.pkId = v.pkId;
      }
      list.push(option);
    });
    option.children = list;
    const bol = await updateCategory(option);
    if (bol) {
      this.$message.success('更新成功！');
      this.handleChange(this.currentCategory.pkId);
      this.getCategoryList();
    }
  }

  handleDetail(record) {
    createModal(() => <Detail id={record.idxReleaseId} />, {
      width: 900,
      title: '产品发布记录',
    });
  }

  async copyCode(record) {
    await clipboardWrite(record.createMobile);
    this.$message.success('号码已复制');
  }

  introduceModal() {
    createModal(
      () => (
        <Introduce
          productionId={this.record.idxReleaseId}
          reviewId={this.record.pkId}
          disabledFlag={true}
        />
      ),
      {
        title: '产品介绍',
        width: 1250,
      },
    );
  }

  panelModal() {
    createModal(
      () => (
        <Panel
          productionId={this.record.idxReleaseId}
          platformId={this.record.platformId}
          reviewId={this.record.pkId}
          disabledFlag={true}
        />
      ),
      {
        title: '设备面板',
        width: 800,
        okText: '保存',
      },
    );
  }
}
</script>
<style lang="less" module>
.wrap {
  .copyBtn {
    cursor: pointer;
  }
  .selectWrap {
    width: 180px;
  }

  .btnBottom {
    text-align: right;
    .margin {
      margin-left: 20px;
    }
  }
  .btnWrap {
    cursor: pointer;
    color: var(--font-active);
  }
}
</style>
