/** @description 这里放socket事件类型 */

/** @name 租户中心 */
const tenantCenterTopic = 'triascloud-tenant-center';
/** @name 模板 */

const templateManageTopic = 'triascloud-template-manager';

export const TenantCenterSocket = {
  /** @name 成员信息更新 */
  MemberUpdate: `${tenantCenterTopic}:member_update`,
  /** @name 账号停用 */
  MemberDisable: `${tenantCenterTopic}:member_disable`,
  /** @name 离职 */
  MemberResign: `${tenantCenterTopic}:member_resign`,
  /** @name 租户解散 */
  TenantDisband: `${tenantCenterTopic}:tenant_disband`,
  /** @name 租户信息更新 */
  TenantUpdate: `${tenantCenterTopic}:tenant_update`,
  /** @name 密码变更 */
  PasswordUpdate: `${tenantCenterTopic}:password_update`,
};

export const TemplateManageSocket = {
  /** @name 升级通知 */
  SolutionUpdate: `${templateManageTopic}:sl_data_generate_result`,
};
/** @name 应用消息条数 */
const flowMessageTopic = 'triascloud-workflow';
export const FlowSocket = {
  /** @name 应用消息条数 */
  TodoTotal: `${flowMessageTopic}:HOME_TODO_COUNT`,
  CompleteTotal: `${flowMessageTopic}:HOME_COMPLETE_COUNT`,
  CcTotal: `${flowMessageTopic}:HOME_CC_COUNT`,
};

/** @name 文件服务 */
const ossTopic = 'triascloud-oss';
export const OssSocket = {
  LoadingExcel: `${ossTopic}:loading-excel`,
  Signature: `${ossTopic}:signature-notify`,
  PdfPreview: `${ossTopic}:pdf-preview`,
};

/** @name 物联平台 */
const iotTopic = 'triascloud-iot-platform';
export const IotSocket = {
  /** @name 产品自检 */
  ProductInspection: `${iotTopic}:PRODUCT_SELF_INSPECTION`,
  /** @name WEBSOKCT调试 */
  AmqpDebug: `${iotTopic}:AMQP_DEBUG`,
  /** @name WEBSOKCT调试异常 */
  AmqpEventError: `${iotTopic}:AMQP_EVENT_ERROR`,
  /** @name WEBSOKCT事件回调 */
  AmqpEvent: `${iotTopic}:AMQP_EVENT`,
  /** @name WEBSOKCT事件关闭 */
  AmqpDisconnect: `${iotTopic}:AMQP_DISCONNECT`,
  /** @name 连接器配置 */
  connectorConfiguration: `${iotTopic}:CONNECTOR_CONFIGURATION`,
  /** @name 订阅计划 */
  subscriptionPlan: `${iotTopic}:SUBSCRIPTION_PLAN`,
  /** @name 设备添加 */
  deviceAddition: `${iotTopic}:DEVICE_ADDITION`,
  /** @name 设备面板 */
  devicePanel: `${iotTopic}:DEVICE_PANEL`,
  /** @name 产品介绍 */
  productDescription: `${iotTopic}:PRODUCT_DESCRIPTION`,
  /** @name 三方推送调试 */
  thirdParty: `${iotTopic}:THIRD_PARTY`,
  /** @name 三方推送调试关闭 */
  thirdPartyClose: `${iotTopic}:THIRD_PARTY_CLOSE`,
  /** @name 三方推送推送事件 */
  thirdPartyEvent: `${iotTopic}:THIRD_PARTY_EVENT`,
  /** @name 三方推送error事件 */
  thirdPartyEventError: `${iotTopic}:THIRD_PARTY_EVENT_ERROR`,
};
