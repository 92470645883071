var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form-model',{ref:"form",class:_vm.$style['form-wrapper'],attrs:{"rules":_vm.computedRules,"model":_vm.formData}},_vm._l((_vm.formModelData),function(item){return _c('a-form-model-item',{key:item.id,class:_vm.$style['small-item'],style:({
      marginBottom: item.marginBottom,
      flex: item.layout === 'singleLine' && '100%',
      marginLeft: item.layout === 'singleLine' && 0,
    }),attrs:{"label":_vm.$t(item.label),"prop":item.name}},[_c('multi-field',{attrs:{"type":item.type,"labelType":item.labelType,"content-height":item.contentHeight,"value":_vm.formData[item.name],"radioList":item.radioList},on:{"changeValue":function (value) { return Array.isArray(value)
            ? _vm.changeValue([item.name, value[0], value[1]])
            : _vm.changeValue([item.name, value]); }}}),(
        item.child &&
          _vm.formData[item.child.condition.name] === item.child.condition.value
      )?_c('a-form-model-item',{class:[_vm.$style['small-item'], _vm.$style['extra-item']],style:({
        marginLeft: item.child.layout === 'singleLine' && 0,
      }),attrs:{"prop":item.child.name}},[_c('multi-field',{attrs:{"type":item.child.type,"content-height":item.child.contentHeight,"value":_vm.formData[item.child.name],"radioList":item.child.radioList},on:{"changeValue":function (value) { return Array.isArray(value)
              ? _vm.changeValue([item.child.name, value[0], value[1]])
              : _vm.changeValue([item.child.name, value]); }}})],1):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }