import request from '../request';
export const basePath = '/template-manager/banner';

/**
 * @name 获取banner
 * @returns Promise<{ any[] }>
 */
export function getBanner() {
  return request(`${basePath}/config`);
}

/**
 * @name 新增banner
 * @returns Promise<true>
 */
export function addBanner(dataList) {
  return request(`${basePath}/config`, {
    method: 'POST',
    body: dataList,
  });
}

/**
 * @name 新增banner
 * @returns Promise<true>
 */
export function editBanner(data) {
  return request(`${basePath}/edit`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @name banner排序
 * @returns Promise<true>
 */
export function sortBanner(data) {
  return request(`${basePath}/sort`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @name 删除banner
 * @returns Promise<true>
 */
export function deleteBanner(templateId) {
  return request(`${basePath}/remove?templateId=${templateId}`, {
    method: 'DELETE',
  });
}
