<template>
  <div>
    <div :class="$style.headerWrap">
      <div :class="$style.activeItem">
        {{ $t('enterprise.enterpriseText') }}
      </div>

      <div :class="$style.searchWrap" v-if="!isSearchModal">
        <x-icon
          :class="$style.searchIcon"
          slot="prefix"
          type="tc-icon-search"
          @click="showSearch"
        />
      </div>
      <div :class="[$style.searchWrap, $style.searchWrap__fullScreen]" v-else>
        <a-input
          ref="searchInput"
          :value="searchKey"
          @change="event => (this.searchKey = event.target.value)"
          :class="$style.searchInput"
        >
          <XIcon
            slot="suffix"
            type="close-circle"
            @click="
              () => {
                isSearchModal = false;
              }
            "
          />
        </a-input>
        <div :class="$style.searchResultWrap">
          <div
            :class="$style.companyItem"
            v-for="(item, idx) in searchResult"
            :key="idx"
            @click="handleCheckChange(item)"
          >
            <div :class="$style.companyTit">{{ item.text }}</div>

            <a-checkbox :checked="ids.includes(item.value)" />
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.companyListWrap">
      <div
        :class="$style.companyItem"
        v-for="(item, idx) in companyList"
        :key="idx"
        @click="handleCheckChange(item)"
      >
        <div :class="$style.companyTit">{{ item.text }}</div>

        <a-checkbox :checked="ids.includes(item.value)" />
      </div>
    </div>
    <div :class="$style.selectCompanyListWrap">
      <x-member-select-view :value="result" @input="handleSelectChange" />
    </div>
    <div :class="$style.selectCompanyListNum">
      {{ $t('solution.selected') }}：{{ result.length }}
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { createFormModal, MemberSelect } from '@triascloud/x-components';
import { getCompanyList } from '@/services/tenant-center/tenant';
import { globalVueI18n } from '@triascloud/i18n';

function createCompanySelect(data = [], idMode = false) {
  return createFormModal(
    h => h(CompanySelects, { props: { list: data, idMode } }),
    {
      title: globalVueI18n.translate('hat.table.selectCompany'),
      width: 580,
      wrapClassName: 'x-company-select-wrap',
    },
  );
}

@Component({
  components: { XMemberSelectView: MemberSelect.View },
})
export default class CompanySelects extends Vue {
  @Prop({ type: Array, default: () => [] }) list;
  @Prop({ type: Boolean, default: false }) idMode; // 仅传进来id
  static create = createCompanySelect;
  companyList = [];
  searchKey = '';
  searchResult = [];
  isSearchModal = false;
  result = [];

  get ids() {
    return this.result.map(item => item.value) || [];
  }

  handleSelectChange(value) {
    this.result = value;
  }
  showSearch() {
    this.isSearchModal = true;
    this.$nextTick(() => {
      this.$refs.searchInput.focus();
    });
  }

  handleCheckChange(data) {
    this.ids.includes(data.value)
      ? (this.result = this.result.filter(item => item.value !== data.value))
      : this.result.push(data);
  }

  @Watch('searchKey')
  async searching() {
    if (!this.searchKey) {
      this.searchResult = [];
      return;
    }
    this.searchResult = this.companyList.filter(item =>
      item.text.includes(this.searchKey),
    );
  }

  async created() {
    this.companyList = (await getCompanyList(1, 99999)).data.map(item => {
      return {
        value: item.pkId,
        text: item.enterpriseName,
        avatar: item.logo,
        type: 'company',
      };
    });
    if (this.idMode && this.list?.length) {
      this.result = this.companyList.filter(item =>
        this.list.includes(item.value),
      );
    } else if (this.list?.length) {
      this.result = this.list;
    }
  }

  getValue() {
    return this.idMode ? this.result.map(item => item.value) : this.result;
  }
}
</script>
<style lang="less" module>
:global {
  .x-company-select-wrap {
    .ant-modal-body {
      padding: 0;
    }
    .ant-tag {
      border-radius: 4px;
    }
  }
}

.headerWrap {
  position: relative;
  height: 40px;
  border-bottom: 1px solid #eeeeee;
  .activeItem {
    margin: 0 20px;
    height: 40px;
    width: max-content;
    font-size: 14px;
    color: var(--primary);
    line-height: 40px;
    border-bottom: 1px solid var(--primary);
  }
  .searchWrap__fullScreen {
    width: 100%;
  }
  .searchWrap {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    line-height: 40px;
    z-index: 2;
    .searchIcon {
      font-size: 16px;
      padding: 12px 20px;
      cursor: pointer;
      color: var(--font-sub);
    }
  }
  .searchInput {
    background: #fff;
    width: 100%;
    height: 40px;
    input {
      height: 40px;
      border-radius: 0;
    }
  }
  .searchResultWrap {
    background: #fff;
    height: 391px;
    overflow-y: auto;
    border-bottom: 1px solid var(--form-border);
  }
}

.companyListWrap {
  height: 390px;
  overflow-y: auto;
}
.companyItem {
  padding: 0 20px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  line-height: 36px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background: var(--primary-10);
  }
}

.companyTit {
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.selectCompanyListWrap {
  height: 100px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-radius: 4px;
  overflow-y: auto;
}
.selectCompanyListNum {
  position: absolute;
  bottom: 28px;
  left: 20px;
}
</style>
