<template>
  <div v-if="data" :class="$style.wrap">
    <div :class="$style.header">
      <div :class="$style.header__left">
        <x-icon type="check-circle" :class="$style.header__icon" />
        <span :class="$style.header__title" :title="data.dictionaryName">{{
          data.dictionaryName
        }}</span>
      </div>
      <div :class="$style.header__right">
        <x-oss-image
          :oss-path="data.createAvatar"
          :class="$style.header__avatar"
        />
        <span :class="$style.header__nickname">{{ data.createNickName }}</span>
        <span :class="$style.header__time">{{
          $moment(data.createDate).format('Y-MM-DD')
        }}</span>
      </div>
    </div>
    <div :class="$style.table">
      <div :class="$style.table__row">
        <div :class="$style.table__cell">{{ $t('common.label.index') }}</div>
        <div :class="$style.table__cell">{{ $t('dictionary.subsetName') }}</div>
      </div>
      <div
        v-for="(item, index) in data.children"
        :key="item.pkId"
        :class="$style.table__row"
      >
        <div :class="$style.table__cell">{{ index + 1 }}</div>
        <div :class="$style.table__cell" :title="item.dictionaryName">
          {{ item.dictionaryName }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createFormModal } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  getDictionaryDetail,
  getCheckDictionaryDetail,
} from '@/services/resource/cloud-dictionary';
import { globalVueI18n } from '@triascloud/i18n';
function createDictionaryModal(pkId, review = false) {
  return createFormModal(
    h =>
      h(DictionaryRemove, {
        props: { pkId, review },
      }),
    {
      actionTips: globalVueI18n.translate('dictionary.deleteConfirm'),
      title: globalVueI18n.translate('common.tips.confirmDelete'),
      width: 480,
    },
  );
}

@Component
export default class DictionaryRemove extends Vue {
  @Prop({ type: String, required: true }) pkId;
  @Prop({ type: Boolean, required: false }) review;
  static createModal = createDictionaryModal;
  created() {
    this.getData();
  }
  data = null;
  async getData() {
    this.data = this.review
      ? await getCheckDictionaryDetail(this.pkId)
      : await getDictionaryDetail(this.pkId);
  }
}
</script>
<style lang="less" module>
.wrap {
  min-height: 350px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header__left {
    display: flex;
    align-items: center;
    overflow: hidden;
    color: var(--font-info);
    .header__title {
      display: block;
      font-size: 16px;
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--font);
    }
    .header__icon {
      margin-right: 10px;
      color: var(--font-active);
    }
  }
  .header__right {
    font-size: 12px;
    color: var(--font-info);
    display: flex;
    align-items: center;
    .header__nickname {
      margin: 0 10px 0 8px;
      max-width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
    .header__avatar {
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
}
.table {
  margin: 20px 0;
  .table__row {
    display: flex;
    position: relative;
    line-height: 40px;
    align-items: center;
    &:first-child {
      color: var(--font);
      .table__cell {
        border-top: 1px solid var(--table-border);
      }
    }
    .table__cell {
      border-color: var(--table-border);
      border-style: solid;
      border-width: 0 1px 1px 0;
      &:first-child {
        text-align: center;
        width: 66px;
        border-left: 1px solid var(--table-border);
      }
      &:nth-child(2) {
        flex: 1;
        padding: 0 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &:nth-child(2n) .table__cell {
      background-color: var(--list-hover);
    }
    &:hover {
      .table__cell {
        background-color: var(--list-active);
      }
    }
  }
}
</style>
