<template>
  <div :class="$style.wrap">
    <!-- <a-tabs
      v-model="activeKey"
      :class="$style.tabs"
      type="card"
      @change="handleTabChange"
    >
      <a-tab-pane
        v-for="item in routes"
        :key="item.path"
        :to="item.path"
        :tab="item.title"
      />
    </a-tabs> -->
    <keep-alive>
      <router-view :class="$style.content" />
    </keep-alive>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';

@Component()
export default class Index extends Vue {
  // activeKey = '/website/fire/news';
  // get routes() {
  //   return [
  //     {
  //       path: '/website/fire/news',
  //       title: this.$t('website.topBar.news'),
  //     },
  //   ];
  // }
  // handleTabChange(path) {
  //   this.$router.replace(path);
  // }
  // created() {
  //   this.activeKey = this.$route.path;
  // }
}
</script>
<style lang="less" module>
.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  .content {
    flex: 1;
    overflow: hidden;
  }
}
.tabs {
  margin-bottom: 10px;
  :global(.ant-tabs-bar) {
    margin: 0 0 2px;
    :global(.ant-tabs-tab) {
      background: none !important;
    }
  }
}
</style>
