import request from '../request';
import { stringify } from '@triascloud/utils';
const serviceName = '/tenant-center'; // 租户中心
const moduleName = '/enterprise'; // 企业对象管理

const publicPath = serviceName + moduleName;

/**
 * 获取当前用户的组织详细信息集合
 * @returns {*}
 */
export function switchEnterprise(tenantId) {
  return request(`${publicPath}/switchEnterprise?${stringify({ tenantId })}`, {
    method: 'PUT',
  });
}

/**
 * @name 获取当前用户公司简要信息列表
 * @description 用于头部公司列表渲染
 * @returns { Promise<Enterprise[]> }
 */
export function getCurrentTenantInfoList() {
  return request(`${publicPath}/memberEnterprise`, {
    method: 'GET',
  });
}

/**
 * @name 企业ID获取企业名称
 */
export function getTenantName(id) {
  return request(`${publicPath}/getName/${id}`, {
    method: 'GET',
  });
}
