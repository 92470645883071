import request from '../request';
export const basePath = '/template-manager/kitGroup';

/**
 * @name 套件分类列表
 * @returns Promise<{ any[] }>
 */
export function kitGroupList(params) {
  return request(`${basePath}/list`, {
    method: 'POST',
    body: params,
  });
}

/**
 * @name 套件分类新增
 * @returns Promise<{ any[] }>
 */
export function addKitGroup(params) {
  return request(`${basePath}/save`, {
    method: 'POST',
    body: params,
  });
}

/**
 * @name 套件分类升降序
 */
export function kitChangeSort(pkId, asc) {
  return request(`${basePath}/sort/${pkId}`, {
    method: 'GET',
    body: asc,
  });
}

/**
 * @name 套件分类列表
 */
export function kitGroupAll() {
  return request(`${basePath}/all`, {
    method: 'GET',
  });
}

/**
 * @name 套件分类详情
 */
export function kitDetails(pkId) {
  return request(`${basePath}/${pkId}`, {
    method: 'GET',
  });
}

/**
 * @name 套件分类编辑
 */
export function kitGroupEdit(pkId, data) {
  return request(`${basePath}/${pkId}`, {
    method: 'PUT',
    body: data,
  });
}

/**
 * @name 套件分类删除
 */
export function removeKitGroup(pkId) {
  return request(`${basePath}/${pkId}`, {
    method: 'DELETE',
  });
}
