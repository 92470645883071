<template>
  <div>
    <field-config-config-desc
      desc="日期：最高支持13位精度（毫秒级）的时间戳传值，并且可以切换时间戳输入格式。"
    />
    <field-config-title v-bind="fieldProps" />
    <field-config-required v-if="!isRes" v-bind="fieldProps" />
    <parameter-object v-if="!isRes" v-bind="fieldProps" isInOrOut="in" />
    <parameter-object v-if="isRes" v-bind="fieldProps" isInOrOut="out" />
    <a-form-model-item v-if="isRes" prop="widget.outFormat">
      <span slot="label"> 出参格式</span>
      <x-select
        :value="field.widget.outFormat"
        :data="outFormatList"
        @input="updateField({ key: 'widget.outFormat', value: $event })"
        :showSearch="false"
      />
    </a-form-model-item>
    <a-form-model-item prop="widget.format">
      <span slot="label" v-if="isRes"> 展示格式</span>
      <span slot="label" v-else>输入格式 </span>
      <x-select
        :value="field.widget.format"
        :data="formatList"
        @input="updateField({ key: 'widget.format', value: $event })"
        :showSearch="false"
      />
    </a-form-model-item>
    <a-form-model-item label="入参格式" v-if="!isRes">
      <x-select
        :value="field.widget.paramFormat"
        :data="paramFormats"
        @input="updateField({ key: 'widget.paramFormat', value: $event })"
        :showSearch="false"
      />
    </a-form-model-item>
    <a-form-model-item label="默认值">
      <a-radio-group
        v-if="isReq"
        :value="field.widget.defaultType"
        @change="onTypeChange"
      >
        <a-radio :value="DateDefault.MANUAL"> 手动输入 </a-radio>
        <a-radio :value="DateDefault.CURRENT"> 此刻 </a-radio>
      </a-radio-group>
      <div v-if="field.widget.defaultType === DateDefault.MANUAL">
        <x-date-picker
          :value="field.widget.defaultValue.custom"
          @input="
            updateField({ key: 'widget.defaultValue.custom', value: $event })
          "
          :format="field.widget.format"
          style="width: 100%"
        />
      </div>
    </a-form-model-item>
    <field-config-description v-bind="fieldProps" />
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { trim } from 'lodash';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';
import FieldConfigTitle from '../components/field-config/title.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
import FieldConfigRequired from '../components/field-config/required.vue';
import { templateDesignModule } from '@/enum/store';
import { ParamType, DateDefault } from '../enum';
import ParameterObject from '../components/field-config/parameter-object.vue';
@Component({
  components: {
    FieldConfigConfigDesc,
    FieldConfigTitle,
    FieldConfigRequired,
    FieldConfigDescription,
    ParameterObject,
  },
})
export default class DateConfig extends Vue {
  @Prop() field;
  @Prop() updateField;
  @templateDesignModule.Getter paramType;

  DateDefault = DateDefault;

  /** @name 入参 */
  get isReq() {
    return this.paramType === ParamType.REQ_PARAM;
  }
  /** @name 出参 */
  get isRes() {
    return this.paramType === ParamType.RES_PARAM;
  }
  onTypeChange(e) {
    this.updateField({
      key: 'widget.defaultType',
      value: e.target.value,
    });
    this.updateField({
      key: 'widget.defaultValue.custom',
      value: '',
    });
  }
  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
    };
  }
  /** @name 遵循moment的输出格式 */
  paramFormats = [
    { text: '时间戳（秒）', value: 'X' },
    { text: '时间戳（毫秒）', value: 'x' },
    { text: '同输入格式', value: '' },
    { text: 'UTC(YYYY-MM-ddTHH:mm:ssZ)', value: 'YYYY-MM-DDTHH:mm:ssZ' },
  ];
  get formatList() {
    return [
      'YYYY-MM-DD',
      'YYYY-MM',
      'YYYY',
      'YYYY-MM-DD HH:mm',
      'YYYY-MM-DD HH:mm:ss',
      'HH:mm',
      'YYYY-Wo',
    ].map(value => ({
      text: this.formatDateOption(value),
      value,
    }));
  }

  formatDateOption(value) {
    return this.$t(`controls.date.${value}`);
    /* return value.replace(/(YYYY|MM|DD|HH|mm|ss|W)/g, ($0, $1) =>
      this.$t(`controls.date.${$1}`),
    ); */
  }

  get outFormatList() {
    return [
      'x',
      'X',
      'YYYY-MM-DD',
      'YYYY-MM',
      'YYYY',
      'YYYY-MM-DD HH:mm',
      'YYYY-MM-DD HH:mm:ss',
      'HH:mm',
      'YYYY-MM-DDTHH:mm:ssZ',
    ].map(value => ({
      text: this.formatDateOption(value),
      value,
    }));
  }

  handleInput(key, value) {
    this.updateField({ key, value });
  }
  validateName() {
    if (!trim(this.field.name)) {
      this.handleNameInput(this.controlLabel);
    }
  }
  parameterOptions = [
    { label: '开发', value: 'dev' },
    { label: '平台方', value: 'Pear' },
    { label: '订阅方', value: 'Orange' },
  ];
  changeInParams(v) {
    this.handleInput('widget.inParams', v);
  }
  timeOptions = [
    { label: '13位', value: '13' },
    { label: '10位', value: '10' },
  ];
  changeTime(e) {
    this.handleInput('widget.timePrecision', e.target.value);
  }
  updateRequired(required) {
    this.updateField(field => {
      field.widget.view = false;
      field.widget.required = required;
      return field;
    });
  }
  get editorOption() {
    return {
      modules: {
        toolbar: {
          container: [['link']],
        },
      },
    };
  }
}
</script>

<style lang="less" module>
.desc {
  color: var(--font-info);
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 16px;
}
.descriptionEditor:global(.x-editor) :global {
  padding: 0;
  .ql-editor {
    min-height: 150px;
  }
  .x-editor .ql-snow.ql-toolbar .ql-picker-options,
  .x-editor-toolbar.ql-snow.ql-toolbar .ql-picker-options {
    max-height: 150px;
  }
  .ql-snow.ql-toolbar {
    padding: 4px 0;
  }
}
</style>
