<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :form="form"
    layout="vertical"
    :class="$style.form"
  >
    <a-form-model-item
      :label="$t('iconLibrary.library.name')"
      ref="libraryName"
      prop="libraryName"
    >
      <a-input v-model="form.libraryName" :maxLength="30"></a-input>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('iconLibrary.icon.belong')"
      ref="libraryType"
      prop="libraryType"
      v-if="!data"
    >
      <a-select v-model="form.libraryType">
        <a-select-option
          v-for="item in libraryTypeList"
          :value="item.value"
          :key="item.name"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { IconLibraryTypeEnum } from '@/enum/icon';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';

function createModal({ ...props }, { title, width = '440px' }) {
  return createFormModal(h => h(IconForm, { props }), {
    title,
    width,
  });
}

@Component()
export default class IconForm extends Vue {
  @Prop({ type: Object, default: () => null }) data;
  form = {
    deleted: 0,
    libraryName: '',
    libraryType: '',
    pkId: '',
  };

  get libraryTypeList() {
    return [
      {
        name: this.$t('iconLibrary.library.type.official'),
        value: IconLibraryTypeEnum.OFFICIAL,
      },
      {
        name: this.$t('iconLibrary.library.type.monochrome'),
        value: IconLibraryTypeEnum.MONOCHROMATIC,
      },
      {
        name: this.$t('iconLibrary.library.type.multiColor'),
        value: IconLibraryTypeEnum.MULTICOLOR,
      },
    ];
  }

  get rules() {
    return {
      libraryName: [
        {
          required: true,
          trigger: 'blur',
          message: this.$t('common.tips.enterName'),
        },
      ],
      libraryType: [
        {
          required: true,
          trigger: 'change',
          message: this.$t('iconLibrary.library.tips.belong'),
        },
      ],
    };
  }

  async getValue() {
    await this.$refs.ruleForm.validate();
    return {
      ...this.form,
    };
  }

  created() {
    this.initData();
  }
  initData() {
    if (this.data) {
      Object.keys(this.form).forEach(key => {
        let keyValue =
          key === 'libraryName'
            ? 'iconLibraryName'
            : key === 'libraryType'
            ? 'iconLibraryType'
            : key;
        if (this.data[keyValue] !== undefined && this.data[keyValue] !== null) {
          this.form[key] = this.data[keyValue];
        }
      });
    }
  }

  static createModal = createModal;
}
</script>
<style lang="less" module>
.form {
}
</style>
