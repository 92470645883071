<script>
import moment from 'moment';
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { DATATYPE } from '../../utils';
import { PLACEHOLDER_INPUT } from '../components/utils';
import RenderArray from './render-array.vue';
import { PARAM_STATUS, PARAM_TYPE } from './utils';
import { isNumber } from 'lodash';
import { eachDelParent } from '../utils';

@Component()
export default class RenderDataPoint extends Vue {
  @PropSync('item') syncItem;
  @PropSync('store') syncStore;
  @Prop() tooltip;
  @Prop() param;
  @Prop() checkDefaultValue;
  @Prop() schemeByType;
  @Prop({ type: String, default: 'id' }) useKey;

  get selectList() {
    let array = [];
    if (this.syncItem.val !== PLACEHOLDER_INPUT) {
      const result =
        this.syncItem.children &&
        this.syncItem.children.find(v => v.id === this.syncItem.val);
      if (result) {
        array = [result];
      }
    }
    return array;
  }

  renderNumber(item) {
    return (
      <a-form-model-item
        label={item.name}
        rules={[
          {
            required: this.syncItem.required,
            message: '不能为空',
            trigger: 'blur',
          },
        ]}
        prop={item[this.useKey]}
      >
        <a-row>
          <a-col span={item.details.unit && item.details.unit ? 20 : 24}>
            <a-input-number
              max={+item.details.max === 0 ? Infinity : +item.details.max}
              min={+item.details.min}
              step={+item.details.step === 0 ? 1 : +item.details.step}
              v-model={this.syncStore.data[item[this.useKey]]}
              style={{ width: '100%' }}
            />
          </a-col>
          {item.details.unit && item.details.unit ? (
            <a-col span={4} class={this.$style.unitOverflow}>
              {item.details.unit && item.details.unit ? item.details.unit : ''}
            </a-col>
          ) : (
            ''
          )}
        </a-row>
      </a-form-model-item>
    );
  }

  renderText(item) {
    return (
      <a-form-model-item
        label={item.name}
        rules={[
          {
            required: this.syncItem.required,
            message: '不能为空',
            trigger: 'blur',
          },
        ]}
        prop={item[this.useKey]}
      >
        <a-input
          maxLength={
            +item.details.maxLength === 0 ? Infinity : +item.details.maxLength
          }
          v-model={this.syncStore.data[item[this.useKey]]}
        />
      </a-form-model-item>
    );
  }

  renderBoolean(item) {
    return (
      <a-form-model-item label={item.name}>
        <a-switch
          checked={this.syncStore.get(item[this.useKey]) ? true : false}
          onChange={target => {
            this.syncStore.set(item[this.useKey], target ? 1 : 0);
          }}
          checked-children={item.details['1']}
          un-checked-children={item.details['0']}
        />
      </a-form-model-item>
    );
  }

  renderEnum(item) {
    if (isNumber(this.syncStore.data[item[this.useKey]])) {
      this.syncStore.set(
        item[this.useKey],
        `${this.syncStore.data[item[this.useKey]]}`,
      );
    }
    return (
      <a-form-model-item
        label={item.name}
        rules={[
          {
            required: this.syncItem.required,
            message: '不能为空',
            trigger: 'blur',
          },
        ]}
        prop={item[this.useKey]}
      >
        <a-select
          v-model={this.syncStore.data[item[this.useKey]]}
          placeholder={'请选择'}
          style={{ width: '100%' }}
        >
          {item.details &&
            item.details.map(v => {
              return (
                <a-select-option key={v.value} value={`${v.value}`}>
                  {v.key}
                </a-select-option>
              );
            })}
        </a-select>
      </a-form-model-item>
    );
  }

  renderDate(item) {
    let value = moment(+this.syncStore.data[item[this.useKey]]);
    if (value.format('x') === '0') {
      value = '';
    }
    return (
      <a-form-model-item
        label={item.name}
        rules={[
          {
            required: this.syncItem.required,
            message: '不能为空',
            trigger: 'blur',
          },
        ]}
        prop={item[this.useKey]}
      >
        <a-date-picker
          format={'YYYY-MM-DD HH:mm:ss'}
          value={value}
          onChange={(_, d) => {
            this.syncStore.set(item[this.useKey], +d);
          }}
        />
      </a-form-model-item>
    );
  }

  renderStruct(item) {
    if (!item.children) return '';
    return (
      <a-form-model-item>
        {/* 出参显示 */}
        {this.syncItem[PARAM_TYPE] !== PARAM_STATUS.OUT ? (
          <div>
            <span slot={'label'}>{item.name}</span>
            <a-input
              disabled
              value={item.identifier ? item.identifier : item.name}
              style={{ marginBottom: '15px' }}
            />
          </div>
        ) : (
          ''
        )}
        <div class={this.$style.ChildrenStructBox}>
          {item.children.map((v, idx) => {
            if (this.syncItem[PARAM_TYPE]) {
              Vue.set(v, PARAM_TYPE, this.syncItem[PARAM_TYPE]);
            }
            return (
              <div key={'struct' + idx + v.identifier}>
                {this.schemeFormat(v)}
              </div>
            );
          })}
        </div>
      </a-form-model-item>
    );
  }

  renderArray(item) {
    if (this.syncItem.required) {
      Vue.set(item, 'required', true);
    }
    const subType = item.details.subType.toLocaleLowerCase();
    let domJsx;
    if (subType !== DATATYPE.struct.toLocaleLowerCase()) {
      let value;
      let disabled = false;
      if (this.syncItem[PARAM_TYPE] === PARAM_STATUS.OUT) {
        value = this.syncStore.get(item[this.useKey]) || [];
        disabled = true;
      } else {
        value = this.syncStore.get(item[this.useKey]).toString();
      }
      domJsx = (
        <a-form-model-item
          rules={[
            {
              required: item.required,
              message: '不能为空',
              trigger: 'blur',
            },
          ]}
          prop={item[this.useKey]}
        >
          {this.syncItem[PARAM_TYPE] !== PARAM_STATUS.OUT ? (
            <span slot={'label'}>{item.name}</span>
          ) : (
            ''
          )}

          {disabled ? (
            <a-select
              disabled={disabled}
              mode="tags"
              style="width: 100%"
              token-separators={[',']}
              value={this.syncStore.get(item[this.useKey]) || []}
              onChange={val => {
                this.syncStore.set(item[this.useKey], val);
              }}
            >
              {Array.isArray(value) &&
                value.map((v, idx) => {
                  <a-select-option key={'sep' + idx}>{v}</a-select-option>;
                })}
            </a-select>
          ) : (
            <a-input
              value={value}
              onChange={val => {
                this.syncStore.set(
                  item[this.useKey],
                  val.target.value.split(','),
                );
              }}
            />
          )}
        </a-form-model-item>
      );
    } else {
      Vue.set(item, PARAM_TYPE, this.syncItem[PARAM_TYPE]);
      domJsx = (
        <RenderArray
          item={item}
          store={this.syncStore}
          tooltip={() => {}}
          schemeByType={this.schemeFormat}
          param={() => {}}
        />
      );
    }
    return domJsx;
  }

  schemeFormat(v) {
    const type = v.type.toLocaleUpperCase();
    switch (type) {
      case DATATYPE.int:
      case DATATYPE.float:
      case DATATYPE.double:
        return this.renderNumber(v);
      case DATATYPE.text:
        return this.renderText(v);
      case DATATYPE.boolean:
        return this.renderBoolean(v);
      case DATATYPE.enum:
        return this.renderEnum(v);
      case DATATYPE.date:
        return this.renderDate(v);
      case DATATYPE.array:
        return this.renderArray(v);
      case DATATYPE.struct:
        return this.renderStruct(v);
      default:
        return '';
    }
  }

  renderScheme(list = []) {
    return (
      <div class={this.$style.ChildrenStructBox}>
        {list.map(v => {
          return this.schemeFormat(v);
        })}
      </div>
    );
  }

  findChildren() {
    /** @description 检查是否为JSONPath公式 */
    const checkJsonPath = str => {
      if (typeof str === 'string' && str.indexOf('$.') > -1) {
        return false;
      }
      return true;
    };
    /**
     * 寻找 store 和 children 里面的关系
     * 只用在出参
     */
    let itemId = null;
    const eachFind = (list, parent) => {
      if (!(Array.isArray(list) && list.length > 0)) {
        return '';
      }
      for (let item of list) {
        if (parent) {
          item.parent = parent;
        }
        if (
          this.syncStore.data[item[this.useKey]] &&
          checkJsonPath(this.syncStore.data[item[this.useKey]])
        ) {
          if (
            (Array.isArray(this.syncStore.data[item[this.useKey]]) &&
              this.syncStore.data[item[this.useKey]].length > 0) ||
            !Array.isArray(this.syncStore.data[item[this.useKey]])
          ) {
            let parentCp = parent;
            if (parent) {
              while (parentCp.parent) {
                parentCp = parentCp.parent;
              }
            } else {
              // 数组普通类型【int,float,double,string】组件，就是自己
              parentCp = item;
            }
            itemId = parentCp && parentCp[this.useKey];
            break;
          }
        }
        if (item.children && item.children.length > 0) {
          eachFind(item.children, item);
        }
      }
      if (itemId) {
        return itemId;
      }
    };
    eachFind(this.syncItem.children, '');
    eachDelParent(this.syncItem.children);
    if (itemId) {
      Vue.set(this.syncItem, 'val', itemId);
    }
  }

  firstLoading = true;
  render() {
    const item = this.syncItem;
    if (this.syncItem[PARAM_TYPE] === PARAM_STATUS.OUT && this.firstLoading) {
      this.findChildren();
      this.firstLoading = false;
    }
    return (
      <div class={this.$style.mb20}>
        <a-form-model-item
          class={this.$style.formItem}
          prop={item[this.useKey]}
        >
          <label
            slot="label"
            class={[
              this.$style.label,
              item.required ? 'ant-form-item-required' : '',
            ]}
          >
            <span>{item.name}</span>
            {this.tooltip(item)}
            {this.param(item)}
          </label>
          {/* 选中DP项，用于获取formatBody */}
          <a-select v-model={item.val}>
            {item.children &&
              item.children.map(v => {
                return (
                  <a-select-option key={v.id} value={v.id}>
                    {v.name}
                  </a-select-option>
                );
              })}
          </a-select>
        </a-form-model-item>
        {this.renderScheme(this.selectList)}
      </div>
    );
  }
}
</script>
<style lang="less" module>
.formItem {
  :global {
    .ant-form-item-required {
      display: flex;
      align-items: center;
    }
    .ant-select {
      width: 100%;
    }
  }
}
.label {
  display: flex;
  align-items: center;
}
.mb20 {
  margin-bottom: 20px;
}
.ChildrenBox {
  border-radius: 4px;
  box-shadow: 0 0 1px 3px var(--shadow);
  padding: 4px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.ChildrenStructBox {
  margin-left: 10px;
}

.unitOverflow {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
