<template>
  <div :class="$style.modalFooter">
    <p :class="$style.leftTips" v-if="type === 'delete'">
      <x-icon type="tc-icon-warning" :class="$style.icon" />
      {{ $t('common.tips.confirmDeleteImageSubTips') }}？
    </p>
    <div :class="$style.btnGroup">
      <a-button @click="handleCancel">{{
        $t('common.action.cancel')
      }}</a-button>
      <a-button
        type="danger"
        v-if="type !== 'delete'"
        @click="handleAudit('FAIL')"
        >{{ $t('common.action.fail') }}</a-button
      >
      <a-button
        type="primary"
        v-if="type !== 'delete'"
        @click="handleAudit('PASSED')"
        >{{ $t('common.action.pass') }}</a-button
      >
      <a-button type="primary" v-else @click="handleDelete">{{
        $t('common.action.confirm')
      }}</a-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  checkBatch,
  checkBatchOne,
  checkDeleteBatch,
  checkDeleteOne,
} from '@/services/resource/cloud-img';
@Component({
  components: {},
})
export default class DeleteModalFooter extends Vue {
  @Prop({ type: Object, default: () => null }) modal;
  @Prop({ type: Function }) resolve;
  type = 'audit';

  mounted() {
    this.type = this.modal.ref.getInstance().type;
  }

  handleClose() {
    this.modal.ref.handleClose();
  }
  handleCancel() {
    this.handleClose();
  }
  async handleDelete() {
    const bodyInstance = this.modal.ref.getInstance();
    const checkImgIds = [];
    let checkType = true;
    bodyInstance.cloneRecord.some(item => {
      if (item.checkStatus === 'PASSED') {
        this.$message.warning(this.$t('gallery.tips.approvedPictureTips'));
        checkType = false;
        return true;
      } else {
        checkType = true;
        checkImgIds.push(item.pkId);
      }
    });
    if (checkType) {
      checkImgIds.length === 1
        ? await checkDeleteOne(checkImgIds)
        : await checkDeleteBatch(checkImgIds);
      this.handleClose();
      this.resolve(this.type);
    }
  }
  async handleAudit(checkStatus) {
    const bodyInstance = this.modal.ref.getInstance();
    const checkImgIds = [];
    let checkType = true;
    bodyInstance.cloneRecord.some(item => {
      if (item.checkStatus === checkStatus) {
        this.$message.warning(this.$t('gallery.tips.approvedPictureTips'));
        checkType = false;
        return true;
      } else {
        checkType = true;
        checkImgIds.push(item.pkId);
      }
    });
    if (checkType) {
      let data = { ...bodyInstance.audit, checkStatus, checkImgIds };
      checkImgIds.length === 1
        ? await checkBatchOne(data)
        : await checkBatch(data);
      this.handleClose();
      this.resolve(this.type);
    }
  }
}
</script>

<style lang="less" module>
.modalFooter {
  display: flex;
  justify-content: flex-end;
  text-align: left;
}
.leftTips {
  color: #fe5959;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: var(--font-md);
  box-sizing: border-box;
  flex: 1;
  .icon {
    font-size: 31px;
    margin-right: 14px;
  }
}
</style>
