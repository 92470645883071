<template>
  <!-- <data-form-field-context
    v-if="field.widget.param || !field.widget.visible"
    v-show="false"
    type="form"
    :field="field"
  /> -->
  <a-form-model-item
    class="x-data-form-item"
    ref="field"
    :data-type="field.type"
    :auto-link="false"
    :class="computedClass"
    :label-position="field.widget.labelPosition"
    :prop="path"
    :rules="rules"
  >
    <template slot="label">
      <label v-if="!field.widget.hideTitle">{{ label }}</label>
      <a-tooltip
        v-if="field.widget.description"
        :arrowPointAtCenter="true"
        placement="right"
      >
        <div v-html="field.widget.description" slot="title"></div>
        <a-icon type="info-circle" :class="$style.tooltipIcon" />
      </a-tooltip>
    </template>
    <data-form-field-context
      type="form"
      :field="field"
      @change="handleFieldChange"
      @blur="handleFieldBlur"
    />
  </a-form-model-item>
</template>
<script>
import { trim } from 'lodash';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { ensureArray, requiredRule } from '@triascloud/utils';
import { getControl } from '../controls';
import { DataFormContext } from './context';
import DataFormFieldContext from './data-form-field-context';

@Component({
  components: {
    DataFormFieldContext,
  },
})
export default class DataFormItem extends Vue {
  @Prop({ required: true }) field;

  /** @type { import('./data-form.vue').default } */
  @Inject(DataFormContext.Root) root;

  @Inject({ from: DataFormContext.Path, default: null }) injectPath;

  get computedView() {
    return this.root.view || this.field.widget.view;
  }

  get path() {
    return ensureArray(this.injectPath)
      .concat(this.field.pkId)
      .join('.');
  }

  /** @name 字段是否可见 */
  get visible() {
    return true;
  }

  get computedClass() {
    return [
      `x-data-form-item--column-1`,
      `x-data-form-item--type-${this.field.type}`,
      {
        ['x-data-form-item--view']: this.computedView,
        ['x-data-form-item--hide-required']: this.hideRequired,
      },
    ];
  }

  get hideRequired() {
    const { min, required } = this.field.widget;
    // 如果没有rules或者显式设置了必填，则正常处理
    if (!this.rules || required) return false;
    // 在设置了最小值但没有设置必填的情况下，需要隐藏必填*号
    return typeof min === 'number';
  }

  /** @name 字段控件 */
  get Control() {
    return getControl(this.field.type);
  }

  /** @name 字段标题 */
  get label() {
    return trim(this.field.name) || this.$t(this.Control.label);
  }

  /** @name 字段验证规则 */
  get rules() {
    if (this.root.view || this.field.widget.visible === false) return undefined;
    let result = [];
    const { validateContext } = this.root;
    const { required, invalidMessage } = this.field.widget;
    if (validateContext.required && required) {
      result.push(
        requiredRule(
          invalidMessage ||
            this.$t('controls.validateError.required', {
              name: this.field.name,
            }),
        ),
      );
    }
    if (this.Control.rules) {
      result = result.concat(this.Control.rules(this.field, this.root));
    }
    return result.filter(rule => rule);
  }

  handleFieldChange() {
    this.$refs.field && this.$refs.field.onFieldChange();
  }

  handleFieldBlur() {
    this.$refs.field && this.$refs.field.onFieldBlur();
  }
}
</script>
<style lang="less">
@prefix: x-data-form-item;
.@{prefix} {
  &:not(.@{prefix}--view) {
    &.@{prefix}--type-text,
    // &.@{prefix}--type-textarea, // 多列增补，多行文本整行时宽度100%
    &.@{prefix}--type-date,
    &.@{prefix}--type-fnName,
    &.@{prefix}--type-fnIdentifier,
    &.@{prefix}--type-addDataSet,
    &.@{prefix}--type-currentDataSet,
    &.@{prefix}--type-switch,
    &.@{prefix}--type-double,
    &.@{prefix}--type-float,
    &.@{prefix}--type-int {
      > .ant-form-item-control-wrapper {
        max-width: 400px;
      }
    }
  }
  &.@{prefix}--type-textarea .ant-form-item-children {
    white-space: pre-wrap;
  }
  &.@{prefix}--type-groupTitle::before,
  &.@{prefix}--type-tips::before {
    content: none;
  }
  &.@{prefix}--type-slider {
    > .ant-form-item-control-wrapper {
      max-width: 400px;
    }
  }
  &.@{prefix}--type-tips,
  &.@{prefix}--type-groupTitle {
    margin-bottom: 0;
  }
  &.@{prefix}--type-groupTitle {
    .ant-form-item-label {
      display: none;
    }
  }
  // 让基础资料字段固定在最后
  &.@{prefix}--type-baseInfo {
    order: 1;
  }
  // 图表 需要初识宽度
  &.@{prefix}--type-area,
  &.@{prefix}--type-radar,
  &.@{prefix}--type-pie,
  &.@{prefix}--type-line,
  &.@{prefix}--type-scatter,
  &.@{prefix}--type-bubble,
  &.@{prefix}--type-funnel,
  &.@{prefix}--type-contrast,
  &.@{prefix}--type-strip,
  &.@{prefix}--type-ring,
  &.@{prefix}--type-areaMap,
  &.@{prefix}--type-heatMap,
  &.@{prefix}--type-rose,
  &.@{prefix}--type-jadeBlock,
  &.@{prefix}--type-sunrise,
  &.@{prefix}--type-histogram,
  &.@{prefix}--type-crossTimeline,
  &.@{prefix}--type-timeline,
  &.@{prefix}--type-scatterMap {
    div[placeholder] {
      height: 344px;
    }
  }
  // 隐藏不需要的必填*号
  &.@{prefix}--hide-required .ant-form-item-required::before {
    content: none;
  }
  &[label-position='center'] .ant-form-item-label {
    width: 100%;
    text-align: center;
  }
  &[label-position='right'] .ant-form-item-label {
    width: 100%;
    text-align: right;
  }
  .ant-form-item-label .x-editor--view:not(:only-child) {
    color: var(--font-color-secondary);
  }
  &--column-3-4 {
    width: 75%;
  }
  &--column-2-3 {
    width: 66.6667%;
  }
  &--column-4 {
    width: 25%;
  }
  &--column-3 {
    width: 33.3333%;
  }
  &--column-2 {
    width: 50%;
  }
  &--column-1 {
    width: 100%;
  }
}
</style>

<style lang="less" module>
.tooltipIcon {
  margin: 0 5px;
  z-index: 101;
  position: relative;
  vertical-align: middle !important;
}
</style>
