var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.tabConfig},[_c('field-config-config-desc',{attrs:{"desc":_vm.$t('controls.desc.struct')}}),_c('field-config-title',_vm._b({},'field-config-title',_vm.fieldProps,false)),(!_vm.isRes)?_c('parameter-object',_vm._b({attrs:{"isInOrOut":"in"}},'parameter-object',_vm.fieldProps,false)):_vm._e(),(_vm.isRes)?_c('parameter-object',_vm._b({attrs:{"isInOrOut":"out"}},'parameter-object',_vm.fieldProps,false)):_vm._e(),_c('a-form-model-item',{attrs:{"label":"子参数名称_标识符"}},[_c('x-input',{attrs:{"value":_vm.field.widget.subName},on:{"input":function($event){return _vm.updateField({
          key: "widget.subName",
          value: $event,
        })}}})],1),_c('a-form-model-item',{attrs:{"label":"子参数标识_数据标识符"}},[_c('x-input',{attrs:{"value":_vm.field.widget.subIdentifier},on:{"input":function($event){return _vm.updateField({
          key: "widget.subIdentifier",
          value: $event,
        })}}})],1),_c('a-form-model-item',{attrs:{"label":"子参数数据组_数据标识符"}},[_c('x-input',{attrs:{"value":_vm.field.widget.subGroup},on:{"input":function($event){return _vm.updateField({
          key: "widget.subGroup",
          value: $event,
        })}}})],1),_c('field-config-description',_vm._b({},'field-config-description',_vm.fieldProps,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }