<template>
  <draggable
    tag="ul"
    :value="data"
    @input="handleInput"
    handle=".drag-item"
    :animation="300"
    :group="{ name: `tab-${configsKey}` }"
    :move="onMove"
    :class="$style.collapse"
  >
    <a-collapse
      :bordered="bordered"
      v-for="(item, index) in data"
      :key="index"
      accordion
    >
      <a-collapse-panel
        :header="item.title"
        :class="$style.collapse"
        :style="customStyle"
      >
        <template slot="extra" v-if="!view">
          <x-icon
            type="tc-icon-delete"
            :tooltip="$t('website.setting.removeNavigation')"
            @click.stop="removeGuide(item)"
            v-if="data.length > 1"
          />
          <x-icon
            class="drag-item"
            style="margin-left: 10px;cursor: move;"
            type="tc-icon-drag-handler"
          />
        </template>

        <a-form-model-item
          :class="$style.itemCol"
          :label="$t('common.title')"
          :prop="
            !bordered
              ? `config.data.${index}.title`
              : `config.data.${i}.children.${index}.title`
          "
          :rules="[
            {
              min: 2,
              max: 10,
              message: $t('website.setting.wordTip'),
              trigger: 'blur',
            },
            {
              required: true,
              trigger: 'blur',
              message: $t('solution.tip1'),
            },
          ]"
        >
          <a-input
            style="width: 380px;"
            v-model="item.title"
            :disabled="view"
          />
        </a-form-model-item>
        <a-form-model-item
          :class="$style.itemCol"
          :label="$t('website.setting.navigationIcon')"
          v-if="!bordered"
          :prop="`config.data.${index}.title`"
          :rules="[
            {
              required: true,
              trigger: 'blur',
              message: $t('iconLibrary.icon.tips.selectIcon'),
            },
          ]"
        >
          <x-icon-library
            v-model="item.icon"
            :class="$style.libraryIcon"
            :disabled="view"
          >
            <div slot="block">
              <x-custom-icon
                :class="$style.guideIcon"
                v-if="item.icon"
                :type="item.icon"
              />

              <div v-else :class="$style.chooseIcon">
                <x-icon type="plus-circle"></x-icon>
              </div>
            </div>
          </x-icon-library>
        </a-form-model-item>
        <slot name="content" :data="{ ...item, i: index }"></slot>
        <a-form-model-item
          :class="{ [$style.itemCol]: true, [$style.itemColMg]: !bordered }"
          :label="$t('website.setting.navigationMenu')"
          ref="imageForm"
          :prop="bordered ? undefined : `config.data.${index}.bg`"
          :rules="
            bordered
              ? undefined
              : [
                  {
                    message: $t('solution.selectPicture'),
                    required: true,
                  },
                ]
          "
        >
          <x-upload-button
            uploadPath="/oss/backend"
            :maxFileSize="5"
            :disabled="view"
            :class="$style.uploadBox"
            @success="
              res => {
                //   $refs.imageForm.FormContext.clearValidate(
                //     `config.data.${scope.data.i}.children.${scope.data.i}.bg`,
                //   );
                item.bg = res.ossData.ossPath;
                $emit('input', {
                  ...item,
                  bg: res.ossData.ossPath,
                });
              }
            "
          >
            <div :class="$style.guideBg">
              <div :class="$style.addIcon" v-if="!item.bg">
                <x-icon type="tc-icon-image-download"></x-icon>
                {{ $t('solution.addPictures') }}
              </div>

              <x-oss-image
                :oss-path="item.bg"
                :class="$style.image__img"
                :size="100"
                v-else
              />
            </div>
          </x-upload-button>
        </a-form-model-item>
        <a-form-model-item
          :class="{ [$style.itemCol]: true, [$style.itemColMg]: !bordered }"
          :label="$t('website.setting.navigationMenuDark')"
          ref="imageForm"
          :prop="bordered ? undefined : `config.data.${index}.bgDark`"
          :rules="
            bordered
              ? undefined
              : [
                  {
                    message: $t('solution.selectPicture'),
                    required: true,
                  },
                ]
          "
        >
          <x-upload-button
            uploadPath="/oss/backend"
            :maxFileSize="5"
            :disabled="view"
            :class="$style.uploadBox"
            @success="
              res => {
                //   $refs.imageForm.FormContext.clearValidate(
                //     `config.data.${scope.data.i}.children.${scope.data.i}.bg`,
                //   );
                item.bgDark = res.ossData.ossPath;
                $emit('input', {
                  ...item,
                  bgDark: res.ossData.ossPath,
                });
              }
            "
          >
            <div :class="$style.guideBg">
              <div :class="$style.addIcon" v-if="!item.bgDark">
                <x-icon type="tc-icon-image-download"></x-icon>
                {{ $t('solution.addPictures') }}
              </div>

              <x-oss-image
                :oss-path="item.bgDark"
                :class="$style.image__img"
                :size="100"
                v-else
              />
            </div>
          </x-upload-button>
        </a-form-model-item>

        <slot :data="{ ...item, i: index }"></slot>
        <navigate-item-children
          :data="item.children"
          :bordered="true"
          :view="view"
          :i="index"
          @remove="removeGuide"
          @dragGuide="data => (item.children = data)"
        >
          <template v-slot:content="scope">
            <a-form-model-item
              :class="$style.itemCol"
              :label="$t('website.setting.content')"
              :prop="`config.data.${index}.children.${scope.data.i}.desc`"
              :rules="[
                {
                  min: 2,
                  max: 150,
                  message: $t('record.wordLimit', { value: 150 }),
                  trigger: 'blur',
                },
                {
                  required: true,
                  trigger: 'blur',
                  message: $t('website.setting.enterContent'),
                },
              ]"
            >
              <a-input
                style="width: 380px;"
                v-model="item.children[scope.data.i].desc"
                :disabled="view"
              />
            </a-form-model-item>
          </template>
          <template v-slot:default="scope">
            <a-form-model-item
              :class="$style.itemCol"
              :label="$t('website.setting.submenuVideos')"
              ref="imageForm"
              :prop="`config.data.${index}.children.${scope.data.i}.media`"
              :rules="[
                {
                  required: true,
                  trigger: 'blur',
                  message: $t('website.setting.uploadVideo'),
                },
              ]"
            >
              <x-upload
                :max="1"
                :uploadPath="UploadPath"
                mode="summary"
                type="file"
                accept="image/*,video/*"
                :key="'media'"
                :maxFileSize="200"
                v-model="item.children[scope.data.i].media"
              />
            </a-form-model-item>
            <a-form-model-item
              :class="$style.itemCol"
              :label="$t('website.setting.submenu')"
            >
              <a-radio-group
                v-model="item.children[scope.data.i].showButton"
                :disabled="view"
              >
                <a-radio value="NO">
                  {{ $t('help.nothing') }}
                </a-radio>
                <a-radio value="YES">
                  {{ $t('help.have') }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <div
              :class="$style.itemRow"
              v-if="item.children[scope.data.i].showButton === 'YES'"
            >
              <a-form-model-item
                :class="$style.itemCol"
                :label="$t('website.setting.buttonName')"
                :prop="
                  `config.data.${index}.children.${scope.data.i}.buttonText`
                "
                :rules="[
                  {
                    min: 2,
                    max: 30,
                    message: $t('common.tips.wordTip'),
                    trigger: 'blur',
                  },
                  {
                    required: true,
                    trigger: 'blur',
                    message: $t('website.setting.enterName'),
                  },
                ]"
              >
                <a-input
                  v-model="item.children[scope.data.i].buttonText"
                  :disabled="view"
                />
              </a-form-model-item>
              <a-form-model-item
                :class="$style.itemCol"
                label="URL"
                :prop="`config.data.${index}.children.${scope.data.i}.route`"
                :rules="[
                  {
                    required: true,
                    trigger: 'blur',
                    message: $t('website.setting.enterUrl'),
                  },
                ]"
              >
                <a-input
                  v-model="item.children[scope.data.i].route"
                  :disabled="view"
                />
              </a-form-model-item>
            </div>
          </template>
        </navigate-item-children>
        <a-button
          icon="plus-circle"
          @click="addSubGuide(item)"
          v-if="!bordered && item.children.length <= 8 && !view"
          >{{ $t('website.setting.addSubnavigation') }}</a-button
        >
      </a-collapse-panel>
    </a-collapse>
  </draggable>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { uuid } from '@triascloud/utils';
import { crossStorageModule } from '@/enum/store';
import NavigateItemChildren from './navigate-item.vue';
@Component({
  components: {
    NavigateItemChildren,
  },
  name: 'NavigateItemChildren',
})
export default class NavigateItem extends Vue {
  @Prop({ type: Array, default: null }) data;
  @Prop({ type: Boolean, default: false }) bordered;
  @Prop({ type: Boolean, default: false }) view;
  @Prop({ type: Number, default: 0 }) i;

  @crossStorageModule.State('tenant') tenant;
  get customStyle() {
    return {
      borderRadius: '4px',
      marginBottom: '24px',
      overflow: 'hidden',
    };
  }

  get UploadPath() {
    return `/${this.tenant.pkId}/video/`;
  }

  get configsKey() {
    return `open${Date.now()}`;
  }

  handleInput(data) {
    this.$emit('dragGuide', data);
  }
  onMove() {}
  addSubGuide(item) {
    item.children.push({
      bg: '',
      bgDark: '',
      buttonText: '',
      desc: '',
      icon: '',
      id: uuid(),
      media: [],
      route: '',
      showButton: 'YES',
      title: '',
    });
  }
  removeGuide(data) {
    this.$emit('remove', data);
  }
  get collapseKey() {
    return Date.now();
  }
}
</script>
<style lang="less" module>
.collapse {
  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
    padding: 6px 16px 6px 40px;
    min-height: 34px;
  }
  :global(.ant-collapse-borderless > .ant-collapse-item) {
    border: none;
    margin-bottom: 30px !important;
  }
  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
    background-color: var(--modal-header-bg);
  }
  :global(.ant-collapse-content > .ant-collapse-content-box) {
    background-color: var(--block-bg);
  }
  :global(.ant-collapse-borderless
      > .ant-collapse-item
      > .ant-collapse-content) {
    background-color: var(--block-bg);
  }
  :global(.ant-collapse) {
    margin-top: 10px;
  }
}

.guideIcon {
  font-size: 64px;
  margin-bottom: 20px;
}

.libraryIcon {
  width: 64px;
  cursor: pointer;
}
.uploadBox {
  width: 105px;
  cursor: pointer;
}

.chooseIcon {
  width: 64px;
  height: 64px;
  border: 1px solid var(--border);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  i {
    font-size: 28px;
    color: var(--font-info);
  }
}
.itemRow {
  display: flex;
  justify-content: space-between;
  .itemCol {
    width: 360px;
  }
}
.itemColMg {
  margin-bottom: 24px !important;
}

.guideBg {
  height: 85px;
  width: 105px;
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    .changePic {
      display: flex;
    }
  }
  .image {
    width: 415px;
    height: 100%;
  }
  .image__img {
    height: 100%;
    width: 100%;
  }
  .addBtn {
    color: var(--font-color-secondary);
    display: flex;
    flex-direction: column;
  }
}
</style>
