<template>
  <div :class="$style.wrap">
    <div :class="$style.toolbar">
      <a-input
        v-model="keyword"
        @pressEnter="handleKeywordChange"
        :class="$style.toolbar__input"
      >
        <x-icon type="tc-icon-search" slot="prefix" />
      </a-input>
      <span>{{ $t('dictionary.shareTime') }}</span>
      <a-range-picker
        :class="$style.toolbar__picker"
        :separator="$t('common.label.to')"
        :placeholder="[
          `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
            'common.label.day',
          )}`,
          `${$t('common.label.year')}-${$t('common.label.month')}-${$t(
            'common.label.day',
          )}`,
        ]"
        @change="handlerDatePickerChange"
      />
      <span
        >{{ $t('dictionary.total') }}：<a> {{ total }}</a
        >{{ $t('dictionary.aggregation') }}：<a>{{ childrenTotal }}</a
        >{{ $t('dictionary.subset') }}</span
      >
    </div>
    <x-data-table
      ref="table"
      :columns="columns"
      :service="service"
      :class="$style.table"
      habit-suffix="deleted"
      base-path="/resource/cloud-dictionary/list"
    >
      <div slot="header"></div>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import {
  getDictionaryRecycle,
  removeRecycleBinDictionary,
  regainDictionary,
} from '@/services/resource/cloud-dictionary';
import { createDrawer, createModal } from '@triascloud/x-components';
import DictionaryDetail from './dictionary-detail.vue';
import { AButton } from '@triascloud/x-blocks/dist/ant-design-vue';
import DictionaryRemove from './dictionary-remove-modal.vue';
import {
  DataSourceCompareOperator,
  DataSourceLogicOperator,
  DataSourceService,
} from '@triascloud/services';
import { globalVueI18n } from '@triascloud/i18n';
function createDictionaryRecycleDrawer(listeners = {}) {
  return createDrawer(h => h(DictionaryRecycle, { on: listeners }), {
    title: globalVueI18n.translate('dictionary.recovery'),
    width: 942,
    bodyStyle: {
      padding: 0,
    },
  });
}
@Component
export default class DictionaryRecycle extends Vue {
  static createDrawer = createDictionaryRecycleDrawer;
  get columns() {
    return [
      {
        title: this.$t('dictionary.name'),
        dataIndex: 'dictionaryName',
        width: 130,
      },
      {
        title: this.$t('dictionary.sharerName'),
        dataIndex: 'createNickName',
        width: 130,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.sharerInstitution'),
        dataIndex: 'shareEnterpriseName',
        width: 130,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.quoteCount'),
        dataIndex: 'quoteCount',
        width: 130,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('common.action.operating'),
        key: 'actions',
        width: 130,
        filter: false,
        sorter: false,
        customRender: record => {
          return [
            <ATooltip title={this.$t('common.label.detail')}>
              <XIcon
                type="eye"
                class={this.$style.action}
                onClick={() => {
                  const modal = createModal(
                    () => <DictionaryDetail view pkId={record.pkId} />,
                    {
                      width: 490,
                      title: this.$t('dictionary.details'),
                      bodyStyle: {
                        minHeight: '400px',
                      },
                      footer: () => (
                        <AButton
                          type="primary"
                          onClick={() => (modal.visible = false)}
                        >
                          {this.$t('common.action.confirm')}
                        </AButton>
                      ),
                    },
                  );
                }}
              />
            </ATooltip>,
            <ATooltip title={this.$t('common.action.removeCompletely')}>
              <XIcon
                type="tc-icon-delete"
                class={[this.$style.action, this.$style['action--delete']]}
                onClick={() => this.remove(record.pkId)}
              />
            </ATooltip>,
            <ATooltip title={this.$t('iconLibrary.library.undo')}>
              <XIcon
                type="tc-icon-recovery"
                class={[this.$style.action, this.$style['action--recovery']]}
                onClick={() => this.recovery(record.pkId)}
              />
            </ATooltip>,
          ];
        },
      },
    ];
  }
  datePicker = [];
  handlerDatePickerChange(_, dateString) {
    dateString = dateString.filter(item => item);
    if (dateString.length) {
      this.datePicker = [
        moment(dateString[0]).valueOf(),
        moment(dateString[1]).valueOf() + 86400000,
      ];
    } else {
      this.datePicker = [];
    }
    this.$refs.table.loadData();
  }
  keyword = '';
  handleKeywordChange() {
    this.$refs.table.loadData();
  }
  async remove(pkId) {
    await DictionaryRemove.createModal(pkId);
    await removeRecycleBinDictionary([pkId]);
    this.$refs.table.loadData();
    this.$message.success(this.$t('common.action.operating'));
  }
  total = 0;
  childrenTotal = 0;
  get service() {
    const service = new DataSourceService('/resource/cloud-dictionary/list');
    service.getList = report =>
      getDictionaryRecycle({
        ...report,
        filterGroup: {
          group: [
            ...report.filterGroup.group,
            {
              logic: DataSourceLogicOperator.OR,
              group: [
                {
                  compare: DataSourceCompareOperator.EQ,
                  value: 1,
                  field: 'deleted',
                },
              ],
            },
            ...[
              this.datePicker.length
                ? {
                    logic: DataSourceLogicOperator.AND,
                    group: [
                      {
                        compare: DataSourceCompareOperator.GE,
                        value: this.datePicker[0],
                        field: 'deleteDate',
                      },
                      {
                        compare: DataSourceCompareOperator.LE,
                        value: this.datePicker[1],
                        field: 'deleteDate',
                      },
                    ],
                  }
                : null,
            ].filter(item => item),
          ],
          logic: DataSourceLogicOperator.AND,
        },
        keyword: this.keyword,
      }).then(res => {
        this.total = res.total;
        this.childrenTotal = res.childrenTotal;
        return res;
      });
    return service;
  }
  async recovery(pkId) {
    await regainDictionary({
      deleted: false,
      pkId,
    });
    this.$message.success(this.$t('common.tips.recoverySuccess'));
    this.$refs.table.loadData();
    this.$emit('recovery');
  }
}
</script>
<style lang="less" module>
.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.table {
  flex: 1;
  padding: 20px 30px;
}
.action {
  cursor: pointer;
  color: var(--font-active);
  &.action--delete {
    color: var(--danger);
    margin: 0 20px;
  }
  &.action--recovery {
    color: var(--warning);
  }
}
.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid var(--border);
  padding: 10px 30px;
  .toolbar__input {
    width: auto;
    margin-right: 30px;
    :global .ant-input {
      width: 110px;
      transition: width 0.5s;
      &:focus {
        width: 200px;
      }
    }
  }
  .toolbar__picker {
    width: 220px;
    margin: 0 5px;
    :global {
      .ant-calendar-picker-icon {
        display: none;
      }
      .ant-calendar-picker-input.ant-input {
        padding-left: 10px;
        text-align: center;
      }
    }
  }
}
</style>
