<template>
  <div>
    <a-form-model ref="form" :model="formData">
      <a-form-model-item
        :label="$t('market.template.virtualInstalls')"
        prop="times"
      >
        <a-input v-model="formData.times" type="number"> </a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { globalVueI18n } from '@triascloud/i18n';
function createInstallTimeSetting(props) {
  return createFormModal(h => h(InstallTimesSetting, { props }), {
    title: globalVueI18n.translate('market.template.batchEdit'),
    width: 500,
    footerLeft: () =>
      globalVueI18n.translate('market.template.totalData', {
        value: props.total,
      }),
  });
}
@Component()
export default class InstallTimesSetting extends Vue {
  @Prop({ type: Number, default: 0 }) total;

  static create = createInstallTimeSetting;
  formData = {
    times: 0,
  };

  async getValue() {
    return this.formData.times;
  }
}
</script>
<style lang="less" module></style>
