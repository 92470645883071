<template>
  <div :class="$style.item" @click.stop="$emit('openDetail', data)">
    <div :class="$style.title">
      {{ data.versionName }}
      <x-icon type="check-circle" v-if="data.issue === 3" />
    </div>
    <!-- 1 未发布 ，2 发布中 ，3 已发 -->
    <div
      :class="$style.itemText"
      v-for="(item, index) in synopsis"
      :key="item + index"
    >
      <x-icon type="tc-icon-circle" /><x-ellipsis :row="1">{{
        item
      }}</x-ellipsis>
    </div>
    <div :class="$style.itemText" v-if="synopsisData.length > 3">
      <x-icon type="tc-icon-circle" /><span>......</span>
    </div>
    <div :class="[$style.itemText, $style.itemLast]" v-if="data.issue === 2">
      <!-- 版本于{{ pushTime }}分钟后发布 -->
      {{ $t('record.pushTime', { value: pushTime }) }}
    </div>
    <div :class="$style.itemHover">
      <div :class="$style.itemTime">
        {{ $moment(data.createTime).format('Y-MM-DD HH:mm') }}
      </div>
      <div :class="$style.action">
        <a-tooltip v-if="data.issue === 1" :title="$t('record.confirmRelease')">
          <x-icon type="tc-icon-push" @click.stop="$emit('pushRecord', data)" />
        </a-tooltip>
        <a-tooltip v-if="data.issue === 2" :title="$t('record.editPushTime')">
          <x-icon
            type="tc-icon-push-refresh"
            :class="$style.pushing"
            @click.stop="$emit('pushRecord', data)"
          />
        </a-tooltip>
        <a-tooltip
          v-if="data.issue !== 3 && $p.action('DELETE')"
          :title="$t('common.action.delete')"
        >
          <x-icon
            type="tc-icon-delete"
            @click.stop="$emit('delete', data.pkId)"
          />
        </a-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ensureArray } from '@triascloud/utils';
import moment from 'moment';

@Component()
export default class RecordItem extends Vue {
  @Prop({ type: Object, default: {} }) data;

  pushTime = 0;
  largeTimer = '';
  get synopsis() {
    const data = ensureArray(JSON.parse(this.data.synopsis));
    if (data.length > 3) {
      return data.slice(0, 3);
    } else {
      return data;
    }
  }

  created() {
    this.pushTimeFunData();
  }

  @Watch('data')
  pushTimeFunData() {
    if (this.data.issue !== 2) {
      return;
    }
    const nowTime = moment(Date.parse(new Date()));
    const pushEnd = moment(this.data.startPushTime);
    this.pushTime = parseInt(pushEnd.diff(nowTime) / 60000);
    this.largeTimer = setInterval(() => {
      this.pushTime -= 1;
      if (this.pushTime === 0) {
        clearInterval(this.largeTimer);
        this.emit('refreshState', this.data);
      }
    }, 60000);
  }

  get synopsisData() {
    return ensureArray(JSON.parse(this.data.synopsis));
  }
}
</script>
<style lang="less" module>
.item {
  padding-top: 20px;
  border-radius: 10px;
  width: 350px;
  position: relative;
  transition: all 0.3s;
  height: 170px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 12px 0px rgba(71, 113, 255, 0.5);
  }
  box-shadow: 0px 0px 12px 0px var(--shadow);
  &:hover {
    .itemHover {
      display: flex;
    }
  }
}

.itemLast {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 42px;
  justify-content: flex-end;
}

.title {
  padding: 0 17px;
  font-size: 14px;
  font-weight: bold;
  color: var(--font);
  line-height: 15px;
  padding-bottom: 10px;
  i {
    color: var(--success);
    margin-left: 8px;
  }
}
.itemText {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 14px;
  line-height: 28px;
  .itemStatus {
    margin-left: auto;
    color: var(--danger);
  }
  span {
    margin-bottom: 5px;
  }
}
.itemHover {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--border);
  padding: 0 20px;
  height: 42px;
  align-items: center;
  width: 100%;
  display: none;
  position: absolute;
  bottom: 0;
  z-index: 10;
  background: var(--main-bg);
  border-radius: 0 0 10px 10px;
  .itemTime {
    color: var(--font-info);
  }
  .action {
    color: var(--font);
    i {
      margin-left: 20px;
    }
    .pushing {
      color: var(--primary);
    }
  }
}
</style>
