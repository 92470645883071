<template>
  <div :class="$style.index">
    <div :class="$style.indexTop">
      <div :class="$style.topItem">
        {{ $t('solution.solutionName') }}：<span>{{ listData.name }}</span>
      </div>
      <div :class="$style.topItem">
        {{ $t('market.template.sellingPrice') }}：<span
          >¥{{ (listData.price / 100).toFixed(2) }}</span
        >
      </div>
      <div :class="$style.topItem">
        {{ $t('iot.device.creator') }}：<span>{{
          listData.createUserName
        }}</span>
      </div>
      <div :class="$style.topItem">
        {{ $t('common.label.createTime') }}：<span>{{
          $moment(listData.createTime).format('Y-MM-DD HH:mm')
        }}</span>
      </div>
    </div>
    <a-table
      :columns="columns"
      :pagination="false"
      :data-source="listData.installList"
      :class="$style.table"
      :scroll="{
        x: '100%',
        y: '100%',
      }"
    >
    </a-table>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { solutionPurchaseTimesDetails } from '@/services/template-manager/solution';
import moment from 'moment';

@Component()
export default class ManageBuysCount extends Vue {
  @Prop({ type: String }) data;

  async getdata() {
    this.listData = await solutionPurchaseTimesDetails({
      solutionId: this.data,
    });
  }
  created() {
    this.getdata();
  }

  listData = {
    solutionName: '',
  };

  get columns() {
    return [
      {
        dataIndex: 'installTenantName',
        title: this.$t('market.template.installTenant'),
        width: 140,
      },
      {
        dataIndex: 'installTime',
        title: this.$t('market.template.installTime'),
        width: 130,
        customRender: checkDate =>
          checkDate ? moment(checkDate).format('Y-MM-DD HH:mm') : '-',
      },
      {
        dataIndex: 'buyTime',
        title: this.$t('solution.purchaseTime'),
        customRender: checkDate =>
          checkDate ? moment(checkDate).format('Y-MM-DD HH:mm') : '-',
      },
      {
        dataIndex: 'yieldProfit',
        title: this.$t('market.template.awardScore'),
        customRender: income => `¥${(income / 100).toFixed(2)}`,
      },
    ].map(item => ({
      ...item,
      width: item.width || 110,
      sorter: false,
      filter: false,
      align: 'center',
      ellipsis: true,
    }));
  }
}
</script>
<style lang="less" module>
.index {
}
.table {
  padding: 20px;
  height: calc(100vh - 200px);
}
.indexTop {
  border-bottom: 1px solid var(--border);
  display: flex;
  flex-wrap: wrap;
  padding: 0 14px;
  justify-content: space-between;
  padding-bottom: 10px;
}
.topItem {
  width: 50%;
  color: var(--font-info);
  font-size: 14px;
  line-height: 14px;
  margin: 10px 0px;
  span {
    color: var(--font);
  }
}
</style>
