<template>
  <a-input
    v-if="type === FieldType.Text"
    :value="value"
    @input="event => emit(event.target.value)"
  />
  <x-upload
    v-else-if="type === FieldType.Image"
    :value="value"
    @input="emit"
    type="image"
    :upload-path="uploadPath"
  ></x-upload>
  <x-upload
    v-else-if="type === FieldType.File"
    :value="value"
    @input="emit"
    :upload-path="uploadPath"
  ></x-upload>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { WebsiteSettingUploadPath } from '@/enum/uploadPath';
export const FieldType = {
  Text: 'text',
  Image: 'image',
  File: 'file',
};
@Component()
export default class FieldRender extends Vue {
  @Prop({ default: FieldType.Text }) type;
  @Prop() value;
  emit(value) {
    this.$emit('input', value);
  }
  FieldType = FieldType;
  uploadPath = WebsiteSettingUploadPath;
}
</script>
<style lang="less" module></style>
