<template>
  <a-row :class="$style.valueAdd">
    <a-row :gutter="10">
      <a-col :span="8">
        <a-form-model-item ref="free" prop="valueAddForm.freeCallCount">
          <span slot="label">
            免费调用
            <a-tooltip
              placement="right"
              :overlayStyle="{ maxWidth: '100%', whiteSpace: 'nowrap' }"
              :getPopupContainer="() => getParentEl('free')"
            >
              <a-icon :class="$style.ml10" type="info-circle" />
              <span slot="title">
                产品下所有设备免费调用连接器次数
              </span>
            </a-tooltip>
          </span>
          <a-input-number
            v-model="infoForm.freeCallCount"
            :min="0"
            :max="9999"
            :default-value="1000"
            :disabled="disabledFlag"
            style="width: 40%"
            placeholder="请输入调用次数"
          />
          <span :class="$style.ml10">次</span>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="[0, 16]">
      <a-col>
        <a-form-model-item ref="extend" prop="callCountExpands">
          <span slot="label">
            调用扩展包
            <a-tooltip
              placement="right"
              :overlayStyle="{ maxWidth: '100%', whiteSpace: 'nowrap' }"
              :getPopupContainer="() => getParentEl('extend')"
            >
              <a-icon :class="$style.ml10" type="info-circle" />
              <span slot="title">
                免费调用额度使用完后可通过购买扩展包增加调用次数！
              </span>
            </a-tooltip>
          </span>
          <ExtendList
            v-model="infoForm.callCountExpands"
            :disabledFlag="disabledFlag"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="[0, 10]">
      <a-col :span="8">
        <a-form-model-item prop="valueAddForm.freeStorageTime">
          <span slot="label">
            免费数据存储
            <a-tooltip
              placement="right"
              :overlayStyle="{ maxWidth: '100%', whiteSpace: 'nowrap' }"
              :getPopupContainer="() => getParentEl('extend')"
            >
              <span slot="title">允许用户查询的历史数据时长</span>
              <a-icon :class="$style.mlr5" type="info-circle" />
            </a-tooltip>
          </span>
          <div :class="$style.sectionFlex">
            <a-input-number
              :min="1"
              :max="100"
              :default-value="1"
              :disabled="disabledFlag"
              style="width: 40%"
              v-model="infoForm.freeStorageTime"
              placeholder="请输入免费数据存储"
            />
            <span :class="$style.ml10">月</span>
          </div>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="[0, 16]">
      <a-col>
        <a-form-model-item ref="store" prop="storageTimeExpands">
          <span slot="label">
            存储扩展包
            <a-tooltip
              placement="right"
              :overlayStyle="{ maxWidth: '100%', whiteSpace: 'nowrap' }"
              :getPopupContainer="() => getParentEl('store')"
            >
              <a-icon :class="$style.ml10" type="info-circle" />
              <span slot="title">
                用户可查阅过去最长时间段内的历史数据
              </span>
            </a-tooltip>
          </span>
          <ExtendList
            v-model="infoForm.storageTimeExpands"
            flag="store"
            :disabledFlag="disabledFlag"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-row>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ExtendList from '@/views/connector/platform-manage/develop/device-manage/introduce/extend-list.vue';

@Component({
  components: {
    ExtendList,
  },
})
export default class ValueAdded extends Vue {
  @Prop({
    type: Object,
    default: () => {},
  })
  infoForm;
  @Prop({ type: Boolean, default: false }) disabledFlag;

  getParentEl(el) {
    return this.$refs[el].$el;
  }
}
</script>
<style lang="less" module>
.valueAdd {
  .mlr5 {
    margin: 0 5px;
  }

  .btnGroup {
    text-align: right;
    padding-top: 24px;
  }

  .ml10 {
    margin-left: 10px;
  }

  .sectionFlex {
    display: flex;

    .sectionFlexExtra {
      flex: 0 0 30px;
      text-align: right;
    }
  }

  .add {
    padding: 0;
  }

  .paddingRow {
    margin: 16px 0;
  }

  .tooltip {
    display: block;
    color: var(--font-info);
    padding-bottom: 8px;
  }
}

.toolTip {
  max-width: max-content;
  white-space: nowrap;
}
</style>
