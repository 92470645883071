<template>
  <div :class="$style.quoteList">
    <div :class="$style.header">
      <span
        >{{ $t('iconLibrary.icon.name') }}：<i>{{ data.iconName }}</i></span
      >
      <span
        >{{ $t('iconLibrary.icon.belong') }}：<i>{{
          library.iconLibraryName
        }}</i></span
      >
      <span
        >{{ $t('iconLibrary.icon.group.belong') }}：<i>{{
          iconLibraryType[library.iconLibraryType]
        }}</i></span
      >
    </div>
    <div :class="$style.body">
      <p :class="$style.title">
        <span>{{ $t('gallery.label.ReferencePerson') }}</span
        ><span>{{ $t('gallery.label.referenceTime') }}</span>
      </p>
      <EmptyContent type="list" v-if="!quoteList.length" />
      <x-scroll-list
        v-show="quoteList.length > 0"
        v-model="quoteList"
        :service="initData"
        ref="scrollList"
        :class="$style.list"
      >
        <p slot-scope="{ data }">
          <span>{{ data.operator }}</span
          ><span>{{ getTime(data.operationTime) }}</span>
        </p>
      </x-scroll-list>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import EmptyContent from '@/components/empty-content';
import { getQuoteDetail } from '@/services/resource/icon';

function createQuoteListModal({ ...props }, { title, width = '580px' }) {
  return createModal(h => h(QuoteList, { props }), {
    title,
    width,
    wrapClassName: 'x-icon-quote-list-wrap',
  });
}

@Component({
  components: { EmptyContent },
})
export default class QuoteList extends Vue {
  @Prop() data;
  @Prop() library;
  static createQuoteListModal = createQuoteListModal;

  get iconLibraryType() {
    return {
      OFFICIAL: this.$t('iconLibrary.library.type.official'),
      MONOCHROMATIC: this.$t('iconLibrary.library.type.monochrome'),
      MULTICOLOR: this.$t('iconLibrary.library.type.multiColor'),
    };
  }

  quoteList = [];

  async initData(page) {
    const data = (await getQuoteDetail(this.data.pkId, (page - 1) * 20)).data;
    return data;
  }

  getTime(time) {
    return this.$moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
}
</script>
<style lang="less" module>
:global {
  .x-icon-quote-list-wrap {
    .ant-modal-body {
      padding: 0;
    }
  }
}
.quoteList {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    border-bottom: 1px solid var(--border);
    padding: 0 35px;
    span {
      font-size: 14px;
      color: var(--font-sub);

      i {
        font-style: normal;
        color: var(--font);
      }
    }
  }
  .body {
    padding: 20px 30px;
    height: 350px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%;
      height: 45px;
      border-bottom: 1px solid var(--border);

      span {
        flex: 1;
        text-align: center;
        font-size: 14px;
      }
    }

    .title {
      background: var(--table-header-bg);
      color: var(--font);
    }

    .list {
      width: 100%;
      color: var(--font-sub);

      p {
        background: var(--table-row);

        &:nth-of-type(2n) {
          background: var(--table-row-stripe);
        }
      }
    }

    .empty {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 90px;
        color: var(--font-info);
      }
    }
  }
}
</style>
