import request from '../request';
const basePath = '/resource/cloud-img';

export const updateLike = cloudImgId =>
  request(`${basePath}/updateLike?cloudImgId=${cloudImgId}`, {
    method: 'PUT',
    body: { cloudImgId },
  });

export const updateCollect = cloudImgId =>
  request(`${basePath}/updateCollect?cloudImgId=${cloudImgId}`, {
    method: 'PUT',
    body: { cloudImgId },
  });

/**
 * @name 推送到云图库
 * @param files 图片数组 [{ auditImgGroup, baseImg, imgName, imgUrl }]
 */
export const pushImg = files =>
  request(`${basePath}/importImg`, {
    method: 'POST',
    body: files,
  });

/**
 * 编辑云图库
 * @param data
 * @returns {*}
 */
export const updateImg = data => {
  return request(`${basePath}/update`, {
    method: 'PUT',
    body: data,
  });
};

/**
 * 设置精品图库
 * @param {*} data
 */
export const setElite = data => {
  return request(`${basePath}/setElite`, {
    method: 'PUT',
    body: data,
  });
};

/** *
 * @name 云图库审核列表
 */
export const getCloudCheckList = report =>
  request(`${basePath}/checkList`, {
    method: 'POST',
    body: report,
  });

/**
 * @name 云图库审核或者云图库批量审核
 * */
export const checkBatch = data =>
  request(`${basePath}/checkBatch`, {
    method: 'PUT',
    body: data,
  });

/** 删除云图库审核*/
export const checkDeleteBatch = data =>
  request(`${basePath}/checkDeleteBatch`, {
    method: 'PUT',
    body: data,
  });

/**
 * 删除云图库审核-单个
 * @param {*} data
 */
export const checkDeleteOne = data => {
  return request(`${basePath}/checkDeleteOne`, {
    method: 'PUT',
    body: data,
  });
};

/**
 * 云图库审核-单个
 * @param {*} data
 */
export const checkBatchOne = data => {
  return request(`${basePath}/checkOne`, {
    method: 'PUT',
    body: data,
  });
};

/**
 * 获取图片热数据统计列表
 * @param data
 * @returns {*}
 */
export const getHotDataDetail = data => {
  return request(`${basePath}/hotDataDetail`, {
    method: 'POST',
    body: data,
  });
};

/**
 * 获取图片修改数据的统计列表
 * @param data
 * @returns {*}
 */
export const getChangeDataDetail = data => {
  return request(`${basePath}/changeDataDetail`, {
    method: 'POST',
    body: data,
  });
};

/**
 * 云图库管理-删除
 * @param {*} data
 */
export const removeCloudImg = data => {
  return request(`${basePath}/removeCloudImg`, {
    method: 'PUT',
    body: data,
  });
};
