<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :form="form"
    layout="vertical"
  >
    <a-form-model-item
      label="原图标库"
      ref="iconLibraryName"
      prop="iconLibraryName"
    >
      <a-input
        disabled
        :value="library.iconLibraryName"
        :maxLength="30"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item
      label="复制至新图标库"
      ref="iconLibraryId"
      prop="iconLibraryId"
    >
      <x-tree-select v-model="form.iconLibraryId" :data="treeData" />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import { treeByType } from '@/services/resource/icon-library';

function createModal({ ...props }, { title, width = '440px' }) {
  return createFormModal(h => h(MoveIcon, { props }), {
    title,
    width,
  });
}

@Component()
export default class MoveIcon extends Vue {
  @Prop({ type: Object, default: () => null }) data;
  @Prop({ type: Object, default: () => null }) library;

  form = {
    iconIds: [],
    iconLibraryId: '',
  };

  get rules() {
    return {
      iconLibraryId: [
        {
          required: true,
          trigger: 'change',
          message: this.$t('iconLibrary.library.tips.belong'),
        },
      ],
    };
  }

  async getValue() {
    await this.$refs.ruleForm.validate();
    return {
      ...this.form,
    };
  }

  created() {
    this.initData();
  }

  get iconLibraryType() {
    return {
      OFFICIAL: this.$t('iconLibrary.library.type.official'),
      MONOCHROMATIC: this.$t('iconLibrary.library.type.monochrome'),
      MULTICOLOR: this.$t('iconLibrary.library.type.multiColor'),
    };
  }

  treeData = [];

  async initData() {
    if (this.data) {
      this.form.iconIds = [this.data.pkId];
    }
    const treeData = await treeByType();
    this.treeData = treeData.map(item => ({
      title: this.iconLibraryType[item.iconLibraryType],
      type: item.iconLibraryType,
      value: item.iconLibraryType,
      key: item.iconLibraryType,
      selectable: false,
      children: item.libraryVOList
        .map(it => ({
          title: it.iconLibraryName,
          type: it.iconLibraryType,
          value: it.pkId,
          key: it.pkId,
        }))
        .filter(it => it.value !== this.form.iconLibraryId),
    }));
  }

  static createModal = createModal;
}
</script>
<style lang="less" module></style>
