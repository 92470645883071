<template>
  <FlexColContent>
    <div :class="$style.operation">
      <div :class="$style.searchBox">
        <a-input
          :class="$style.searchInput"
          v-model="searchParams.developmenter"
          @input.stop="handleSearch"
          placeholder="搜索开发者"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-select
          show-search
          allowClear
          v-model="searchParams.deviceGroup"
          :placeholder="$t('ds.device.deviceType')"
          option-filter-prop="children"
          :filter-option="true"
          @change="handleSearch"
          :options="deviceTypeList"
          style="width: 200px;"
        >
        </a-select>
        <a-range-picker
          @change="rangeChange"
          v-model="dateRange"
          style="width: 380px;"
          :allowClear="false"
          :disabledDate="disabledDate"
          @calendarChange="calendarPriceRangeChange"
          :show-time="{ format: 'HH:mm:ss' }"
          format="YYYY-MM-DD HH:mm:ss"
        ></a-range-picker>
      </div>
    </div>

    <template slot="footer">
      <PaginationTable
        ref="tableRef"
        :columns="columns"
        :api="logList"
        row-key="pkId"
        :scroll="{ x: false, y: 'max-content' }"
        :searchParams="searchParams"
      >
      </PaginationTable>
    </template>
  </FlexColContent>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import TopData from '../components/top-data.vue';
import PaginationTable from '@/views/digital-site/components/pagination-table';
import FlexColContent from '@/views/digital-site/components/flex-col-content';
import { logList } from '@/services/digital-site/developer';
import moment from 'moment';
import { getDeviceGroup } from '@/services/digital-site/device';
import { clipboardWrite } from '@triascloud/utils';

const DAY = 24 * 60 * 60 * 1000;
@Component({
  components: {
    TopData,
    PaginationTable,
    FlexColContent,
  },
})
export default class Log extends Vue {
  get columns() {
    return [
      {
        title: '开发者',
        dataIndex: 'developmenter',
        width: 120,
        ellipsis: true,
      },
      {
        title: '日志时间',
        dataIndex: 'createTime',
        ellipsis: true,
        width: 180,
        customRender: val =>
          val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : '-',
      },
      {
        title: '设备类型',
        dataIndex: 'deviceGroup',
        ellipsis: true,
        width: 120,
        customRender: val => this.deviceTypeEnum[val],
      },
      {
        title: '推送报文',
        dataIndex: 'content',
        // ellipsis: true,
        customRender: val => (
          <div>
            {val}
            <a style="margin-left: 15px" onClick={() => this.copy(val)}>
              复制
            </a>
          </div>
        ),
      },
    ];
  }
  searchParams = {
    developmenter: '',
    startTime: this.$moment(new Date().getTime() - 30 * 86400000).valueOf(),
    endTime: this.$moment(new Date().getTime()).valueOf(),
    deviceGroup: undefined,
  };

  logList = logList;

  deviceTypeList = [];
  deviceTypeEnum = {};
  async getDeviceType() {
    const data = await getDeviceGroup();
    this.deviceTypeList = data
      .map(item => {
        this.deviceTypeEnum[item.groupId] = item.groupShortName;
        return {
          label: item.groupShortName,
          value: item.groupId,
        };
      })
      .filter(item => !item.value.includes('VS.'));
  }

  dateRange = [
    //默认显示一周   今天是最后一天
    this.$moment(new Date().getTime() - 30 * 86400000).format('YYYY-MM-DD'),
    this.$moment(new Date().getTime()).format('YYYY-MM-DD'),
  ];
  rangeChange([value1, value2]) {
    this.searchParams.startTime = value1
      ? moment(value1)
          .startOf('day')
          .valueOf()
      : value1;
    this.searchParams.endTime = value2
      ? moment(value2)
          .endOf('day')
          .valueOf()
      : value2;

    this.handleSearch();
  }

  selectPriceDate = '';
  calendarPriceRangeChange(date) {
    this.selectPriceDate = date[0];
  }
  disabledDate(current) {
    const selectDate = this.$moment(this.selectPriceDate).valueOf();
    const offsetDays = 30 * DAY;
    // 禁用选中时间前后的{{offsetDays}}天
    return (
      this.$moment(current).valueOf() > selectDate + offsetDays ||
      this.$moment(current).valueOf() < selectDate - offsetDays ||
      current > this.$moment().endOf('day')
    );
  }

  handleSearch() {
    this.$refs.tableRef.search();
  }

  created() {
    this.getDeviceType();
  }

  copy(text) {
    clipboardWrite(text).then(() => {
      this.$message.success('复制成功');
    });
  }
}
</script>
<style lang="less" module>
.operation {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  .searchBox {
    display: flex;
    gap: 10px;
    .searchInput {
      width: 200px;
    }
    .searchSelect {
      width: 180px;
    }
  }
}

.tableWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.buttonGroups {
  display: flex;
  gap: 20px;
  .button {
    cursor: pointer;
    color: var(--primary);
  }
}
</style>
