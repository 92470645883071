<template>
  <div v-if="!develop" :class="$style.content">
    <div :class="$style.topData">
      <div
        :class="$style.item"
        v-for="(item, idx) in topDataOptions"
        :key="idx"
        :style="{ backgroundColor: item.backgroundColor }"
      >
        <div
          :class="$style.iconDiv"
          :style="{
            backgroundColor: item.iconBackgroundColor,
          }"
        >
          <x-custom-icon :class="$style.iconStyle" :type="item.icon" />
        </div>
        <div :class="$style.textDiv">
          <div :class="$style.numberTextDiv">
            {{ item.text }}
            <a-tooltip :arrowPointAtCenter="true" placement="top">
              <template slot="title">
                {{ item.iconText }}
              </template>
              <div :class="$style.topTips">
                <a-icon type="info-circle" :class="$style.iconStyle" />
              </div>
            </a-tooltip>
          </div>
          <div
            :class="$style.numberDiv"
            :style="{ color: item.iconBackgroundColor }"
          >
            {{ countObj[item.number] }}
          </div>
        </div>
      </div>
    </div>
    <a-tabs
      :default-active-key="activeKey"
      :tabBarStyle="{ marginLeft: '8px' }"
      @change="changeTab"
    >
      <a-tab-pane
        key="in-platform"
        :tab="$t('connector.accessPlatform.accessPlatform')"
      >
        <InPlatform ref="InPlatform" :platrormStatus.sync="platrormStatus" />
      </a-tab-pane>
      <a-tab-pane key="API-Log" :tab="$t('connector.accessPlatform.apiLog')">
        <ApiLog :apiParams="apiParams" />
      </a-tab-pane>
      <a-tab-pane
        key="subscribe"
        :tab="$t('connector.accessPlatform.subscriptionManagement')"
      >
        <SubscribeManage :platStatus="platrormStatus" />
      </a-tab-pane>
      <div slot="tabBarExtraContent">
        <div v-if="activeKey === 'in-platform'">
          <a-select
            v-model="platrormStatus"
            style="width: 120px"
            @change="handlePlatformChange"
          >
            <a-select-option
              v-for="item in platformArr"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-button
            v-if="$p.action('CREATE')"
            :class="$style.marginLeft_10"
            icon="plus"
            type="primary"
            @click="addPlatform('add')"
          >
            {{ $t('connector.accessPlatform.addPlatform') }}
          </a-button>
        </div>

        <div v-if="activeKey === 'API-Log'" :class="$style.apiConditions">
          <a-range-picker
            :class="[$style.apiTimePicker, $style.apiCondition]"
            v-model="apiParams.timeArr"
            show-time
            :ranges="dateRange"
            :disabled-date="disabledDate"
            :disabled-time="disabledRangeTime"
            format="YYYY-MM-DD HH:mm:ss"
          />
          <!--          <a-select-->
          <!--            v-model="apiParams.tenant"-->
          <!--            style="width: 120px"-->
          <!--            :class="$style.apiCondition"-->
          <!--          >-->
          <!--            <a-select-option-->
          <!--              v-for="item in tenantOptions"-->
          <!--              :key="item.value"-->
          <!--              :value="item.value"-->
          <!--            >-->
          <!--              {{ item.label }}-->
          <!--            </a-select-option>-->
          <!--          </a-select>-->
          <a-select
            show-search
            v-model="apiParams.apiName"
            :placeholder="$t('iot.systemLog.connectorsPlaceholder')"
            style="width: 200px"
            :default-active-first-option="false"
            :show-arrow="false"
            allowClear
            :filter-option="false"
            :not-found-content="null"
            @search="val => logSearchByName(val)"
          >
            <a-select-option v-for="d in apiSearchData" :key="d.pkId">
              {{ d.apiName }}
            </a-select-option>
          </a-select>
          <a-input
            :class="$style.apiCondition"
            v-model="apiParams.keyword"
            style="width: 200px"
            allowClear
            :placeholder="$t('iot.systemLog.inOutKeys')"
          ></a-input>
          <!-- <ApiLog /> -->
        </div>
        <div v-if="activeKey === 'subscribe'" :class="$style.apiCondition">
          <a-input-search
            :placeholder="$t('iot.companyManage.companyOwner')"
            :class="[$style.w200, $style.mr10]"
            @change="
              val => {
                deviceSearch(val.target.value);
              }
            "
          />
        </div>
      </div>
    </a-tabs>
  </div>
  <div v-else :class="$style.content">
    <div :class="$style.header">
      <a-button @click.stop="setDevelop(false)">
        {{ $t('connector.accessPlatform.back') }}
      </a-button>
    </div>
    <Develop :id="developId" :platformIdentifier="platformIdentifier" />
  </div>
</template>
<script>
import { Component, Vue, Provide } from 'vue-property-decorator';
import InPlatform from './in-platform';
import ApiLog from './api-log';
import Develop from './develop';
import SubscribeManage from './subscribe-manage';
import { Emitter } from '@/views/connector/platform-manage/develop/utils';
import { debounce } from 'lodash-decorators';
import { apiLikeSearch } from '@/services/iot-platform/platform';

@Component({
  components: {
    InPlatform,
    ApiLog,
    Develop,
    SubscribeManage,
  },
})
export default class PlatformManage extends Vue {
  develop = false;
  developId = '';
  platformIdentifier = '';

  @Provide()
  setDevelop(flag, id = '', identifier) {
    this.develop = flag;
    this.developId = id;
    this.platformIdentifier = identifier;
  }
  get topDataOptions() {
    return [
      {
        number: 'riseBuildingNumber',
        text: this.$t('connector.accessPlatform.totalPlatformNumber'),
        icon: '1381795643223031810.21733934',
        iconText: this.$t('connector.accessPlatform.totalPlatformTips'),
        backgroundColor: 'rgba(249, 150, 4, 0.1)',
        iconBackgroundColor: 'rgba(249, 150, 4, 1)',
      },
      {
        number: 'riseProjectNumber',
        text: this.$t('connector.accessPlatform.payingEnterprises'),
        icon: '1381795643223031810.28804060',
        iconText: this.$t('connector.accessPlatform.payingEnterprisesTips'),
        backgroundColor: 'rgba(71, 113, 255, 0.1)',
        iconBackgroundColor: 'rgba(71, 113, 255, 1)',
      },
      {
        number: 'riseLayerNumber',
        text: this.$t('connector.accessPlatform.totalDeal'),
        icon: '1381795643223031810.30096335',
        iconText: this.$t('connector.accessPlatform.totalDealTips'),
        backgroundColor: 'rgba(183, 62, 255, 0.1)',
        iconBackgroundColor: 'rgba(183, 62, 255, 1)',
      },
      {
        number: 'errorNumber',
        text: this.$t('connector.accessPlatform.logTotalNumber'),
        icon: '1381795643223031810.21742567',
        iconText: this.$t('connector.accessPlatform.logTotalTips'),
        backgroundColor: 'rgba(255, 98, 49, 0.1)',
        iconBackgroundColor: 'rgba(255, 98, 49, 1)',
      },
      {
        number: 'warnNumber',
        text: this.$t('connector.accessPlatform.messageTPS'),
        icon: '1381795643223031810.20655676',
        iconText: this.$t('connector.accessPlatform.tpsTips'),
        backgroundColor: 'rgba(65, 196, 0, 0.1)',
        iconBackgroundColor: 'rgba(65, 196, 0, 1)',
      },
    ];
  }
  countObj = {
    riseBuildingNumber: '6/10',
    riseProjectNumber: 45,
    riseLayerNumber: 268,
    errorNumber: 1445,
    warnNumber: 4.54,
  };

  get dateRange() {
    const moment = this.$moment;
    return {
      '1小时': [moment().subtract(1, 'h'), moment()],
      '24小时': [moment().subtract(24, 'h'), moment()],
      '7天': [moment().subtract(7, 'd'), moment()],
    };
  }

  range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledDate(current) {
    return (
      current < this.$moment().subtract(7, 'days') || current > this.$moment()
    );
  }

  disabledRangeTime() {
    const time = this.$moment()
      .format('HH:mm')
      .split(':');
    return {
      disabledHours: () => this.range(time[0], 23),
      disabledMinutes: () => this.range(time[1], 59),
    };
  }

  activeKey = this.$route.query.active || 'in-platform';

  changeTab(activeKey) {
    this.activeKey = activeKey;
    this.platrormStatus = 'all';
    this.apiParams.timeArr = [];
    this.apiParams.keyword = '';
    this.apiParams.apiName = undefined;
    this.apiSearchData = [];
    this.$router.push({ query: { active: activeKey } });
  }

  platrormStatus = 'all';
  platformArr = [
    {
      label: this.$t('connector.accessPlatform.allState'),
      value: 'all',
    },
    {
      label: this.$t('connector.accessPlatform.inService'),
      value: 'RUN',
    },
    {
      label: this.$t('connector.accessPlatform.outOfService'),
      value: 'DISABLE',
    },
  ];
  apiParams = {
    timeScope: 1,
    startTime: '',
    endTime: '',
    apiName: undefined,
    tenant: '',
    apiTag: '',
    keyword: '',
    timeArr: [],
  };
  timeOptions = [
    {
      label: this.$t('connector.accessPlatform.oneHour'),
      value: 1,
    },
    {
      label: this.$t('connector.accessPlatform.24hours'),
      value: 2,
    },
    {
      label: this.$t('connector.accessPlatform.sevenDays'),
      value: 3,
    },
    {
      label: this.$t('connector.accessPlatform.customTime'),
      value: 4,
    },
  ];
  tenantOptions = [
    {
      label: '全部企业',
      value: '',
    },
    {
      label: '广州达蒙',
      value: '1',
    },
    {
      label: '三叠纪元',
      value: '2',
    },
  ];
  apiSearchData = [];

  @debounce(300)
  async logSearchByName(val) {
    if (val) {
      try {
        const params = {
          keyword: val,
        };
        const res = await apiLikeSearch(params);
        this.apiSearchData = res.map(v => {
          return {
            pkId: v.pkId,
            apiName: `${v.apiName}（${v.identifier}）`,
          };
        });
      } catch {
        return false;
      }
    } else {
      this.apiSearchData = [];
    }
  }

  deviceSearch(val) {
    Emitter.$emit('searchSubscribe', val);
  }

  handlePlatformChange(val) {
    this.platrormStatus = val;
  }

  flag = 11;

  addPlatform(flag) {
    const a = this.flag;
    if (a === 1) {
      this.$message.warning(this.$t('connector.accessPlatform.buySetMeal'));
    } else if (a === 2) {
      this.$message.warning(this.$t('connector.accessPlatform.buySetMealMore'));
    } else {
      Emitter.$emit('addPlatform', flag);
    }
  }
}
</script>
<style lang="less" module>
.content {
  position: relative;
  padding: 0 20px;
  :global {
    .ant-table-thead > tr > th .ant-table-header-column {
      padding: 0;
    }
  }
  .header {
    position: fixed;
    top: 75px;
    right: 34px;
    z-index: 101;
  }
  .topData {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    .item {
      width: 18%;
      height: 100px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: default;
      margin-top: 30px;
      .textDiv {
        margin-left: 105px;
        width: 190px;
        .numberDiv {
          font-weight: 500;
          font-size: 32px;
        }
        .numberTextDiv {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
        }
      }
      .topTips {
        margin-left: 4px;
        .iconStyle {
          color: var(--font-info);
          cursor: pointer;
        }
      }
      .iconDiv {
        position: absolute;
        left: 15px;
        height: 66px;
        width: 66px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconStyle {
          color: white;
          font-size: 40px;
        }
      }
    }
  }
  .marginLeft_10 {
    margin-left: 10px;
  }
  .apiConditions {
    display: flex;
    align-items: center;

    .apiTimePicker {
      width: 370px !important;
    }

    .apiCondition {
      margin: 5px;
    }

    input:last-child {
      width: 180px;
    }
  }
}
</style>
