<template>
  <div>
    <div style="color:var(--warning)">
      此处不支持预览，如需查看详情请到DP管理！
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="handlePagination"
    />
    <a-form-model-item prop="powerForm.productDocumentation">
      <span slot="label" :class="$style.world">
        产品文档（推荐格式：pdf、ppt、pptx、xls、xlsx、doc、docx，单个文件限200M）
      </span>
      <x-upload
        type="file"
        mode="summary"
        :uploadPath="getUploadPath"
        :max="10"
        :view="disabledFlag"
        :maxFileSize="200"
        v-model="infoForm.productDocumentation"
      />
    </a-form-model-item>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getProductDP } from '@/services/iot-platform/deviceManage';
import { crossStorageModule } from '@/enum/store';

@Component()
export default class DevicePower extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: String, default: '' }) productionId;
  @Prop({
    type: Object,
    default: () => {},
  })
  infoForm;
  @Prop({ type: Boolean, default: false }) disabledFlag;

  @crossStorageModule.State('tenant') tenant;

  get getUploadPath() {
    return `/${this.tenant.pkId}/connector/files`;
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => {
          return index + 1;
        },
        title: '序号',
        align: 'center',
        width: 80,
      },
      {
        dataIndex: 'classificationName',
        title: '功能分类',
      },
      {
        dataIndex: 'functionName',
        title: '功能名称',
      },
      {
        dataIndex: 'functionIdentifier',
        title: '功能标识符',
      },
      {
        dataIndex: 'dataType',
        title: '数据类型',
      },
      // {
      //   title: '操作',
      //   customRender: () => {
      //     return <a-button type="link">查看详情</a-button>;
      //   },
      // },
    ];
  }

  mounted() {
    this.getList();
  }

  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => `${total} ` + this.$t('connector.dataTemplate.itmes'),
  };
  tableLoading = false;
  tableData = [];

  async getList() {
    const params = {
      productId: this.productionId,
      current: this.pagination.current,
      size: 10,
    };
    try {
      this.tableLoading = true;
      const { records, current, total } = await getProductDP(params);
      for (let record of records) {
        record.dataType = '-';
        let jsonData = JSON.parse(record.dataSpecs);
        for (let item of jsonData.fields) {
          if (item.type === 'currentDataSet' && item.value) {
            record.dataType = item.value.thingIdentifier || '-';
          } else if (item.type === 'addDataSet' && item.value?.length === 1) {
            record.dataType = item.value[0]?.thingIdentifier || '-';
          }
        }
      }
      this.tableLoading = false;
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.total = total;
    } catch {
      this.tableLoading = false;
      return false;
    }
  }

  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getList();
  }
}
</script>
<style lang="less" module>
.world {
  padding: 16px 0;
  color: var(--font-info);
}
</style>
