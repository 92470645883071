<template>
  <div>
    <field-config-config-desc desc="布尔：采用false或者true来定义布尔值" />
    <field-config-title v-bind="fieldProps" />
    <parameter-object v-if="!isRes" v-bind="fieldProps" isInOrOut="in" />
    <parameter-object v-if="isRes" v-bind="fieldProps" isInOrOut="out" />
    <a-form-model-item label="true-显示文本" prop="widget.is">
      <a-input
        :value="field.widget.is"
        :max-length="10"
        @input="updateField({ key: 'widget.is', value: $event.target.value })"
      />
    </a-form-model-item>
    <a-form-model-item label="false-显示文本" prop="widget.not">
      <a-input
        :value="field.widget.not"
        :max-length="10"
        @input="updateField({ key: 'widget.not', value: $event.target.value })"
      />
    </a-form-model-item>
    <a-form-model-item label="默认值" prop="widget.defaultValue.custom">
      <a-switch
        :checked="field.widget.defaultValue.custom"
        @change="
          updateField({ key: 'widget.defaultValue.custom', value: $event })
        "
      />
    </a-form-model-item>
    <field-config-description v-bind="fieldProps" />
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';
import FieldConfigDescription from '../components/field-config/description.vue';
import FieldConfigTitle from '../components/field-config/title.vue';
import ParameterObject from '../components/field-config/parameter-object.vue';
import { templateDesignModule } from '@/enum/store';
import { ParamType } from '@/views/connector/platform-manage/develop/data-template/template-design/form/controls';
@Component({
  components: {
    FieldConfigTitle,
    FieldConfigConfigDesc,
    FieldConfigDescription,
    ParameterObject,
  },
})
export default class SwitchConfig extends Vue {
  @Prop() field;
  @Prop() updateField;
  @templateDesignModule.Getter paramType;

  /** @name 出参 */
  get isRes() {
    return this.paramType === ParamType.RES_PARAM;
  }
  get fieldProps() {
    return {
      field: this.field,
      updateField: this.updateField,
    };
  }
}
</script>
<style lang="less" module></style>
