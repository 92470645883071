<template>
  <div>
    <div :class="$style.title">{{ name }}</div>
    <draggable
      v-model="list"
      :animation="300"
      :handle="`.${$style.dragHandle}`"
      :scroll="true"
      @end="sort"
    >
      <transition-group>
        <div
          v-for="(item, index) in list"
          :key="item.pkId"
          :class="$style.cell"
        >
          <div :class="$style.cell__left">
            <x-input
              auto-focus
              :class="$style.cell__input"
              :view="!item.edit"
              :max-length="20"
              v-model="item.name"
            />
            <span v-show="item.edit">
              <x-icon
                type="check"
                @click="save(item)"
                :class="[$style.cell__icon, $style['cell__icon--checked']]"
              />
              <x-icon
                type="close"
                @click="close(item)"
                :class="$style.cell__icon"
              />
            </span>
          </div>
          <div>
            <x-icon
              :class="$style.cell__icon"
              type="edit"
              @click="item.edit = true"
            />
            <x-icon
              :class="$style.cell__icon"
              type="tc-icon-delete"
              @click="remove(item.pkId, index)"
            />
            <span :class="[$style.dragHandle, $style.cell__icon]"
              ><x-icon type="tc-icon-drag-handler"
            /></span>
          </div>
        </div>
      </transition-group>
    </draggable>
    <empty-content v-if="!loading && !list.length" />
  </div>
</template>
<script>
import {
  editClassify,
  getClassifyChildren,
  removeClassify,
  sortClassify,
} from '@/services/template-manager/tpClassify';
import { createFormModal } from '@triascloud/x-components';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import AsyncButton from '@/components/async-button';
import { globalVueI18n } from '@triascloud/i18n';
import EmptyContent from '@/components/empty-content';

function createClassifyManageModal(props) {
  return createFormModal(h => h(ClassifyManageModal, { props }), {
    title: globalVueI18n.translate('market.template.classify.manage'),
    width: 440,
  });
}
@Component({
  components: { Draggable, AsyncButton, EmptyContent },
})
export default class ClassifyManageModal extends Vue {
  @Prop({ type: String, required: true }) pkId;
  @Prop({ type: String, required: true }) name;
  static createModal = createClassifyManageModal;
  list = [];
  loading = true;
  async created() {
    await this.getClassify();
    this.loading = false;
  }
  async getClassify() {
    this.list = ((await getClassifyChildren(this.pkId)) || []).map(item => ({
      ...item,
      edit: false,
      _originName: item.name,
    }));
  }
  /**
   * @name 拖动排序更新sort字段
   */
  sort({ newIndex, oldIndex }) {
    if (!this.list.length || newIndex === oldIndex) return;
    // const sortItems = [];
    // this.list.forEach((item, index) => {
    //   const sort = index;
    //   if (item.sort === sort) return;
    //   item.sort = sort;
    //   sortItems.push({
    //     sort,
    //     pkId: item.pkId,
    //   });
    // });
    sortClassify(
      this.list.map((item, index) => ({ sort: index, pkId: item.pkId })),
    );
    this.$message.success(this.$t('common.tips.sortSuccessful'));
  }

  async save(item) {
    if (item.name.length < 2 || item.name.length > 20) {
      return this.$message.error(
        this.$t('common.validate.length', { value: '2~20' }),
      );
    }
    item.edit = false;
    if (item.name !== item._originName) {
      await editClassify({
        name: item.name,
        pkId: item.pkId,
        parentId: this.pkId,
      });
    }
    item._originName = item.name;
    this.$message.success(this.$t('common.tips.save'));
  }
  close(item) {
    item.edit = false;
    item.name = item._originName;
  }
  async remove(pkId, index) {
    await this.$confirm(this.$t('market.template.classify.removeConfirm'));
    this.list.splice(index, 1);
    try {
      await removeClassify(pkId);
      this.$message.success(this.$t('common.tips.delete'));
    } catch (error) {
      this.getClassify();
    }
  }
}
</script>
<style lang="less" module>
.title {
  margin-bottom: 10px;
  color: var(--font);
  font-weight: bold;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  line-height: 36px;
  .cell__left {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .cell__input {
      width: 160px;
    }
  }
  .cell__icon {
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
    &.cell__icon--checked {
      color: var(--success);
    }
    &.dragHandle {
      cursor: move;
    }
  }
}
</style>
