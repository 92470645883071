<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-row :gutter="[16, 16]">
      <a-col
        :span="12"
        v-for="(item, idx) in form.list"
        :key="'hardware-spec' + idx"
      >
        <a-form-model-item :label="item.name" :prop="item.value">
          <div :class="$style.sectionFlex">
            <a-input :maxLength="50" v-model="item.value" />
            <span :class="$style.sectionFlexExtra">
              <x-icon
                :class="$style.delete"
                type="tc-icon-delete"
                @click="deleteList(idx)"
              />
            </span>
          </div>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item>
          <span slot="label">&nbsp;</span>
          <div :class="$style.sectionFlex">
            <a-input
              v-model="newName"
              :maxLength="16"
              placeholder="添加新字段，名称限制16字符"
            />
            <span :class="$style.sectionFlexExtra">
              <a-icon
                :class="[$style.delete, $style.font]"
                type="plus"
                @click="addList"
              />
            </span>
          </div>
        </a-form-model-item>
      </a-col>
    </a-row>
    <div :class="$style.btnGroup">
      <a-button>取消</a-button>
      <a-button :class="$style.ml10" type="primary">保存</a-button>
    </div>
  </a-form-model>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
@Component()
export default class HardwareSpec extends Vue {
  form = {
    list: [],
  };
  rules = {};

  newName = '';
  addList() {
    if (!this.newName) {
      this.$message.warn('名称不能为空');
      return false;
    }
    this.form.list.push({
      name: this.newName,
      value: '',
    });
    this.newName = '';
  }
  deleteList(index) {
    this.form.list.splice(index, 1);
  }
}
</script>
<style lang="less" module>
.sectionFlex {
  display: flex;
  .sectionFlexExtra {
    flex: 0 0 30px;
    text-align: right;
    .delete {
      font-size: 16px;
      width: 24px;
    }
    .font {
      color: var(--font-active);
    }
  }
}
.btnGroup {
  text-align: right;
  padding-top: 24px;
}
.ml10 {
  margin-left: 10px;
}
</style>
