<template>
  <div ref="ManageRankEchart" />
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { globalVueI18n } from '@triascloud/i18n';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  components: {},
})
export default class ManageRankEchart extends Vue {
  @Prop({ type: Object }) data;
  /** @type { ResizeObserver } */
  _ob = null;
  myChart = null;

  mounted() {
    this._ob = new ResizeObserver(this.chartResize);
    this._ob.observe(this.$refs.ManageRankEchart);
    this.initChart();
  }
  chartResize() {
    this.myChart.resize();
  }
  get option() {
    const res =
      this.data && this.data.solutionConversionRateStatisticsDetailVOS
        ? this.data.solutionConversionRateStatisticsDetailVOS
        : [];
    return {
      tooltip: {
        trigger: 'item',
        formatter: function(params) {
          return [
            params.data.name + '<br/>',
            globalVueI18n.translate('solution.quantity') +
              '：' +
              params.value +
              '<br/>',
            params.data.rate
              ? globalVueI18n.translate('solution.proportion') +
                '：' +
                params.data.rate +
                '%<br/>'
              : '',
          ].join('');
        },
      },
      series: [
        {
          name: this.$t('solution.paymentRate'),
          type: 'funnel',
          left: 40,
          top: 60,
          bottom: 60,
          width: '70%',
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '60%',
          sort: 'descending',
          gap: 2,
          label: {
            // formatter: '{name|{b}}转化率：{@rate}%  数量:{c}',
            formatter: params => {
              return `${params.name}  ${
                params.data.rate || params.data.rate === 0
                  ? globalVueI18n.translate('solution.rates') +
                    '：' +
                    params.data.rate +
                    '%'
                  : ''
              } ${globalVueI18n.translate('solution.quantity')}：${
                params.value
              }`;
            },
            rich: {
              time: {
                fontSize: 10,
                color: '#999',
              },
            },
            overflow: 'break',
          },
          labelLine: {
            length: 15,
            length2: 0,
            maxSurfaceAngle: 80,
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1,
          },
          emphasis: {
            label: {
              fontSize: 20,
            },
          },
          data: [
            {
              value: res.previewCount,
              name: this.$t('solution.previewAmount'),
              rate: null,
            },
            {
              value: res.installCount,
              name: this.$t('solution.Installs'),
              rate: res.installCountPercentConversion,
            },
            {
              value: res.payCount,
              name: this.$t('solution.paidAmount'),
              rate: res.payCountPercentConversion,
            },
            {
              value: res.invoiceCount,
              name: this.$t('solution.orderBilling'),
              rate: res.invoiceCountPercentConversion,
            },
          ],
        },
      ],
    };
  }
  @Watch('option')
  initChart() {
    const chart = this.$refs.ManageRankEchart;
    this.myChart = echarts.init(chart);
    this.myChart.setOption(this.option);
  }
}
</script>
<style lang="less" module></style>
