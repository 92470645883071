<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { defaultColors } from './utils';

@Component({})
export default class ApiDropdown extends Vue {
  @Prop({ type: Boolean, default: false }) treeDefaultExpandAll;
  @Prop({ type: Array, default: () => [] }) treeData;
  @Prop({ type: String, default: undefined }) value;
  @Prop({ type: String, default: '' }) width;
  get tagColor() {
    return [...defaultColors];
  }
  selectNode = undefined;
  @Watch('value', { immediate: true })
  async changeValue(newVal) {
    this.selectNode = newVal;
    this.refreshTag = false;
    await this.$nextTick();
    this.refreshTag = true;
  }
  get dataTree() {
    return this.treeData;
  }
  getTagColor(t) {
    const obj = {
      int: 0,
      int32: 0,
      float: 1,
      double: 2,
      text: 3,
      date: 4,
      switch: 5,
      struct: 6,
      array: 7,
      enum: 8,
      image: 9,
      file: 10,
      dp: 10,
    };
    return this.tagColor[obj[t]];
  }
  formateType(t) {
    const obj = {
      int: '整数',
      int32: '整数',
      float: '浮点',
      double: '双精度',
      text: '文本',
      date: '日期',
      switch: '布尔',
      struct: '结构体',
      array: '数组',
      enum: '枚举',
      image: '图片',
      file: '文件',
      dp: 'dp点',
    };
    return obj[t];
  }
  paramsReqClick({ key }) {
    const obj = {
      key,
      type: this.dropdownType,
    };
    this.$emit('paramsReqClick', obj);
  }
  refreshTag = true;
  render() {
    return (
      <div>
        {this.refreshTag && (
          <a-tree-select
            v-model={this.selectNode}
            style={`width:${this.width || 250}px`}
            placeholder="请选择"
            treeDefaultExpandAll={this.treeDefaultExpandAll}
            onChange={(value, label, extra) => {
              this.$emit('input', value);
              this.$emit('change', value, label, extra);
            }}
          >
            {this.renderMenue(this.dataTree)}
          </a-tree-select>
        )}
      </div>
    );
  }

  renderMenue(list) {
    return list.map(item => {
      return item ? (
        item.children?.length ? (
          <a-tree-select-node
            key={item.key}
            value={item.id}
            selectable={false}
            isLeaf={false}
          >
            <span slot="title">
              {item.type ? `${item.name}（${item.type}）` : `${item.name}`}
            </span>
            {item.children?.length && this.renderMenue(item.children)}
          </a-tree-select-node>
        ) : (
          <a-tree-select-node
            key={item.key}
            value={item.id}
            isLeaf={true}
            title={`${item.name}（${item.type}）`}
          >
            <div class={this.$style.params} slot="title">
              <div class={[this.$style.text, this.$style.truncate]}>
                {`${item.name}（${item.type}）`}
              </div>
              <a-tag color={this.getTagColor(item.type)}>
                {this.formateType(item.type)}
              </a-tag>
            </div>
          </a-tree-select-node>
        )
      ) : (
        ''
      );
    });
  }
}
</script>

<style lang="less" module>
.link {
  color: var(--primary);
  cursor: pointer;
  float: right;
  margin-right: 5px;
}
.params {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
}
.dropClass {
  left: 530px !important;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
