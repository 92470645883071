<template>
  <div>
    <div :class="$style.subTab">
      <div :class="$style.left">
        <x-select
          :class="$style.searchType"
          v-model="data.ifUsed"
          :data="options"
          @change="$refs.table.loadData()"
        />
        <div :class="$style.timeFilter">
          <a-range-picker @change="onChange" format="yyyy-MM-DD" />
        </div>
        <div :class="$style.total">
          <span :class="$style.numText">{{ $t('dictionary.total') }}：</span>
          <span :class="$style.nums"
            >{{ countData.allCount }}
            {{ $t('inviteCode.InvitationCodes') }}</span
          >
          <span :class="$style.numText"
            >{{ $t('inviteCode.remaining') }}：</span
          >
          <span :class="$style.nums"
            >{{ countData.remainingAvailable }}
            {{ $t('inviteCode.InvitationCodes') }}</span
          >
        </div>
      </div>
      <div :class="$style.right">
        <a-button
          icon="plus"
          type="primary"
          @click="addCodeModal"
          v-if="$p.action('CREATE')"
          >{{ $t('inviteCode.add') }}</a-button
        >
      </div>
    </div>
    <x-data-table
      ref="table"
      :class="$style.table"
      :columns="columns"
      base-path="/tenant-center/invite-code/list"
      :service="service"
      :scroll="{
        x: '100%',
        y: '100%',
      }"
    >
      <span slot="action"></span>
    </x-data-table>
  </div>
</template>
<script>
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import AddCode from './components/add-code.vue';
import { clipboardWrite } from '@triascloud/utils';
import UseRecord from './components/use-record.vue';
import {
  tenantInviteList,
  tenantInviteCodeCount,
  tenantCopyCode,
  useHistory,
} from '@/services/tenant-center/tenant-invite-code';
import { DataSourceService } from '@triascloud/services';

@Component()
export default class Index extends Vue {
  focus = null;
  countData = {
    allCount: 0,
    remainingAvailable: 0,
  };
  data = {
    current: 1,
    endTime: null,
    ifUsed: 'ALL',
    keyword: null,
    startTime: null,
    size: 10,
  };

  get options() {
    return [
      { text: this.$t('common.label.all'), value: 'ALL' },
      { text: this.$t('inviteCode.Used'), value: 'YES' },
      { text: this.$t('inviteCode.Unused'), value: 'NO' },
    ];
  }

  get service() {
    const service = new DataSourceService('/tenant-center/invite-code/list');
    service.getList = ({ skip, size, keyword }) =>
      tenantInviteList({
        ...this.data,
        current: Math.ceil(skip / size),
        keyword,
        size,
      }).then(res => {
        return {
          ...res,
          data: res.records,
        };
      });
    return service;
  }

  created() {
    this.codeCount();
  }

  get columns() {
    return [
      {
        dataIndex: 'code',
        title: this.$t('inviteCode.enterpriseCode'),
        customRender: code =>
          code
            ? code.replace(code.charAt(2), '*').replace(code.charAt(3), '*')
            : '',
      },
      {
        dataIndex: 'ifUsed',
        title: this.$t('inviteCode.useNot'),
        customRender: status => {
          return status === 'YES' ? (
            <span style="color:var(--success)">
              {this.$t('template.review.checked')}
            </span>
          ) : (
            <span style="color:var(--danger)">
              {this.$t('template.review.unChecked')}
            </span>
          );
        },
      },
      {
        dataIndex: 'useMember',
        title: this.$t('inviteCode.user'),
      },
      {
        dataIndex: 'useTime',
        title: this.$t('inviteCode.usageTime'),
        customRender: useTime =>
          useTime ? moment(useTime).format('Y-MM-DD HH:mm') : '',
      },
      {
        dataIndex: 'useTenant',
        title: this.$t('inviteCode.enterprise'),
      },
      {
        dataIndex: 'createName',
        title: this.$t('iot.device.creator'),
      },
      {
        dataIndex: 'createTime',
        title: this.$t('common.label.createTime'),
        customRender: createDate => moment(createDate).format('Y-MM-DD HH:mm'),
      },
      {
        dataIndex: 'copyMember',
        title: this.$t('inviteCode.clone'),
      },
      {
        dataIndex: 'copyTime',
        title: this.$t('inviteCode.copyTime'),
        customRender: copyTime =>
          copyTime ? moment(copyTime).format('Y-MM-DD HH:mm') : '',
      },
      {
        title: this.$t('common.action.operating'),
        key: 'action',
        width: 100,
        customRender: (_, res) => {
          return res.ifUsed === 'YES' ? (
            <span
              class={this.$style.actionAtn}
              onClick={() => this.showUseRecord(res)}
            >
              {this.$t('inviteCode.usageRecord')}
            </span>
          ) : (
            <span
              class={this.$style.actionAtn}
              onClick={() => this.handleCopy(res)}
            >
              {this.$t('inviteCode.copy')}
            </span>
          );
        },
      },
    ].map(item => ({
      ...item,
      width: item.width || 150,
      sorter: false,
      filter: false,
      align: 'center',
      ellipsis: false,
    }));
  }

  onChange(range) {
    this.data.startTime = range[0] ? range[0].startOf('day').valueOf() : '';
    this.data.endTime = range[1] ? range[1].endOf('day').valueOf() : '';
    this.$refs.table.loadData();
  }

  async handleCopy(data) {
    await tenantCopyCode(data.pkId);
    await clipboardWrite(data.code);

    this.$refs.table.loadData({
      skip: this.$refs.table.params.skip,
      size: this.$refs.table.params.size,
    });
    this.$message.success(this.$t('common.tips.copy'));
  }

  async codeCount() {
    this.countData = await tenantInviteCodeCount();
  }

  async addCodeModal() {
    await AddCode.createModal({
      remainingAvailable: this.countData.remainingAvailable,
    });
    this.$refs.table.loadData();
    this.codeCount();
  }
  async showUseRecord(data) {
    const record = await useHistory(data.pkId);
    await UseRecord.createModal({ data: record });
  }
}
</script>
<style lang="less" module>
.subTab {
  padding: 20px 20px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left,
  .right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }
  .right {
    button {
      margin-left: 15px;
    }
  }
  .input {
    width: 110px;
    transition: width 0.5s linear;
    &.focus {
      width: 200px;
    }
  }

  .searchType {
    width: 110px;
  }

  .timeFilter {
    padding: 0 20px;
  }

  .total {
    margin-left: 20px;
    .nums {
      color: var(--font-info);
      padding-right: 0.5em;
    }
    .numText {
      color: var(--font-info);
      margin-left: 40px;
    }
  }
}

.table {
  margin: 10px 20px 20px 20px;
  :global(.ant-table-thead th) {
    position: relative;
    &::before {
      content: '';
      height: 26px;
      width: 1px;
      position: absolute;
      right: 0;
      top: 15px;
      display: block;
      background: var(--table-border);
    }
    &:last-child::before {
      display: none;
    }
  }
  .actionAtn {
    color: var(--primary);
    cursor: pointer;
  }
}
</style>
