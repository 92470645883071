<template>
  <div>
    <a-button type="primary" icon="plus" @click="add">{{
      $t('market.template.banner.add')
    }}</a-button>
    <a-spin :spinning="loading" :class="$style.wrap">
      <draggable
        v-model="list"
        :animation="300"
        @end="sort"
        :class="$style.list"
      >
        <transition-group>
          <div v-for="item in list" :key="item.pkId" :class="$style.card">
            <x-oss-image
              :oss-path="item.img"
              size="330"
              :class="$style.card__cover"
            />
            <div :class="$style.card__bottom">
              <div>
                <span>{{ $t('market.template.banner.bgColor') }}：</span>
                <span
                  :class="$style.card__color"
                  :style="{ background: item.bgColor || 'transparent' }"
                ></span>
              </div>
              <div>
                <span>{{ $t('path.market./') }}</span>
                <span :class="$style.card__name">{{ item.name }}</span>
              </div>
            </div>
            <div :class="$style.card_control">
              <x-icon
                :class="$style.card__icon"
                type="edit"
                @click="edit(item)"
              />
              <x-icon
                v-if="list.length > 1"
                :class="$style.card__icon"
                type="tc-icon-delete"
                @click="remove(item.pkId)"
              />
            </div>
          </div>
        </transition-group>
      </draggable>
    </a-spin>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  getBanner,
  deleteBanner,
  sortBanner,
} from '@/services/template-manager/banner';
import BannerFormModal from './banner-form-modal.vue';
import { TemplateType } from '@/enum/template';

@Component()
export default class Banner extends Vue {
  @Prop({ default: TemplateType.Application, type: String }) templateType;
  loading = false;
  list = [];
  mounted() {
    this.getBanner();
  }
  async getBanner() {
    this.loading = true;
    this.list = await getBanner();
    this.loading = false;
  }
  async sort({ newIndex, oldIndex }) {
    if (newIndex === oldIndex) return;
    this.loading = true;
    await sortBanner(
      this.list.map((item, index) => ({
        sort: index,
        idxTemplateId: item.pkId,
        templateType: this.templateType,
      })),
    );
    this.loading = false;
  }
  async add() {
    if (this.list.length >= 6) {
      return this.$message.warning(
        this.$t('market.template.banner.max', { value: 6 }),
      );
    }
    await BannerFormModal.createModal();
    this.getBanner();
  }
  async edit(item) {
    await BannerFormModal.createModal({ data: item });
    this.getBanner();
  }
  async remove(pkId) {
    await this.$confirm(this.$t('market.template.banner.deleteConfirm'));
    await deleteBanner(pkId);
    this.getBanner();
    this.$message.success(this.$t('common.tips.delete'));
  }
}
</script>
<style lang="less" module>
.wrap {
  min-height: 600px;
  margin-top: 30px;
}
.card {
  position: relative;
  display: inline-block;
  padding: 14px;
  margin-right: 70px;
  margin-bottom: 70px;
  width: 360px;
  height: 184px;
  background-color: var(--block-bg);
  box-shadow: 0px 0px 12px 0px var(--shadow);
  border-radius: 6px;
  cursor: move;
  .card__cover {
    height: 116px;
    width: 100%;
    object-fit: cover;
    cursor: move;
  }
  .card__bottom {
    display: flex;
    margin-top: 12px;
    height: 42px;
    span {
      display: inline-block;
      vertical-align: middle;
    }
    .card__color {
      width: 24px;
      height: 24px;
      border-radius: 2px;
      border: 1px solid var(--form-border);
      margin: 0 20px 0 10px;
    }
    .card__name {
      color: var(--primary);
      width: 140px;
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .card_control {
    display: none;
    justify-content: space-around;
    position: absolute;
    background-color: var(--block-bg);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 42px;
    align-items: center;
    font-size: 18px;
    .card__icon {
      cursor: pointer;
      &:hover {
        color: var(--primary);
      }
      &:last-child:hover {
        color: var(--delete);
      }
    }
  }
  &:hover {
    box-shadow: 0px 0px 12px 0px var(--primary-fade-50);
    .card_control {
      display: flex;
    }
  }
}
</style>
