<template>
  <div :class="$style.review">
    <x-data-table
      ref="table"
      base-path="/resource/cloud-img/checkList"
      row-key="pkId"
      :columns="columns"
      :service="service"
      :selected-row-keys.sync="selectedRowKeys"
      @data-change="tableChange"
      :class="$style.reviewTable"
    >
      <div slot="headerLeft" slot-scope="{ $table }" :class="$style.headerBar">
        <ul>
          <li :class="$style.headerSelect">
            <x-select
              v-model="checkStatus"
              :data="menuTitle"
              @change="handleChange"
            />
          </li>
          <li :class="$style.search">
            <a-input
              :placeholder="$t('common.action.search')"
              @pressEnter="handleTableKeywordChange($event, $table)"
            >
              <x-icon slot="prefix" type="search" />
            </a-input>
          </li>
          <li :class="$style.datePicker">
            <span :class="$style.shareTimes">{{
              $t('dictionary.shareTime')
            }}</span>
            <a-range-picker
              v-model="datePicker"
              :class="$style.toolbar__picker"
              :separator="$t('common.label.to')"
              @change="handleChangeDate"
            />
          </li>
          <li :class="$style.searchTips">
            <p>
              {{ $t('dictionary.total') }}：<span :class="$style.total">
                {{ count }} </span
              >{{ $t('gallery.label.Apicture') }}
            </p>
          </li>
        </ul>
      </div>
      <template v-slot:action>
        <div :class="$style.btnGroup">
          <a-button
            v-if="$p.action('BATCH_VERIFY')"
            :class="$style.btn"
            type="primary"
            @click="filterDataSelect(selectedRowKeys, 'audit')"
            ><x-icon type="tc-icon-feedback" />{{
              $t('gallery.actions.bulkAuditing')
            }}</a-button
          >
          <a-button
            v-if="$p.action('DELETE_BATCH')"
            :class="$style.btn"
            @click="filterDataSelect(selectedRowKeys, 'delete')"
            ><x-icon type="tc-icon-delete" />{{
              $t('common.action.batchDelete')
            }}</a-button
          >
        </div>
      </template>
    </x-data-table>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import ImageAudit from './components/ImageAudit.vue';
import { getCloudCheckList } from '@/services/resource/cloud-img';
import {
  DataSourceCompareOperator,
  DataSourceService,
  DataSourceLogicOperator,
} from '@triascloud/services';

@Component({
  components: { ImageAudit },
})
export default class Review extends Vue {
  checkStatus = 'all';
  datePicker = [];
  startTime = '';
  endTime = '';
  count = 0;
  selectedRowKeys = []; // 表格选中的字段id
  get menuTitle() {
    return [
      { text: this.$t('common.label.all'), value: 'all' },
      { text: this.$t('common.label.pendingReview'), value: 'UNCHECKED' },
      { text: this.$t('dictionary.checkStatus.passed'), value: 'TODO' },
      { text: this.$t('common.label.arrovalFailed'), value: 'FAIL' },
    ];
  }

  // 取出表格选中的数据集合
  filterDataSelect(record, type) {
    const tableData = this.$refs.table.data;
    const value = tableData.filter(item => record.includes(item.pkId));
    if (value.length > 12) {
      this.$message.warning(this.$t('common.tips.selectMaximun'));
      return;
    } else if (!value || value.length < 1) {
      const msg =
        type === 'audit'
          ? this.$t('common.tips.selectApprovedImage')
          : this.$t('common.tips.selectDeleteImage');
      this.$message.warning(msg);
      return;
    }
    this.showAuditModal(value, type);
  }
  async showAuditModal(record, type) {
    const res = await ImageAudit.creadAuditModal(record, type);
    let msg = this.$t('common.tips.auditSuccessful');
    res === 'delete'
      ? (msg = this.$t('common.tips.delete'))
      : (msg = this.$t('common.tips.auditSuccessful'));
    this.$message.success(msg);
    this.$refs.table.loadData();
    this.selectedRowKeys = [];
  }
  handleChangeDate(range) {
    if (range.length) {
      this.startTime = range[0].startOf('day').valueOf();
      this.endTime = range[1].endOf('day').valueOf();
    } else {
      this.startTime = '';
      this.endTime = '';
    }
    this.$refs.table.loadData();
  }

  handleTableKeywordChange(event, table) {
    table.loadData({ keyword: event.target.value });
  }
  handleChange() {
    this.$refs.table.loadData();
  }
  // 获取标准表格总数
  tableChange(e) {
    this.count = e.total > -1 ? e.total : 0;
  }
  getFliterParams(report) {
    let group;
    switch (this.checkStatus) {
      case 'all':
        group = [...report.filterGroup.group];
        break;
      case 'TODO':
        group = [
          ...report.filterGroup.group,
          {
            logic: DataSourceLogicOperator.OR,
            group: [
              {
                compare: DataSourceCompareOperator.EQ,
                value: 'PASSED',
                field: 'checkStatus',
              },
            ],
          },
        ];
        break;
      default:
        group = [
          ...report.filterGroup.group,
          {
            compare: DataSourceCompareOperator.EQ,
            value: this.checkStatus,
            field: 'checkStatus',
          },
        ];
        break;
    }
    if (this.startTime !== '' && this.endTime !== '') {
      group = [
        ...group,
        {
          compare: DataSourceCompareOperator.GE,
          value: this.startTime,
          field: 'createDate',
        },
        {
          compare: DataSourceCompareOperator.LE,
          value: this.endTime,
          field: 'createDate',
        },
      ];
    }
    const data = { ...report, filterGroup: { group: group } };
    return data;
  }
  get columns() {
    return [
      {
        title: this.$t('gallery.label.image'),
        key: 'imgUrl',
        ellipsis: false,
        filter: false,
        sorter: false,
        width: 130,
        customRender: ({ imgUrl, imgName }) => (
          <x-oss-image
            class={this.$style.tableImg}
            size="40"
            ossPath={imgUrl}
            onClick={() => this.$preview([{ url: imgUrl, name: imgName }])}
          />
        ),
      },
      {
        title: this.$t('common.label.imageName'),
        dataIndex: 'imgName',
        ellipsis: true,
        filter: false,
        width: 200,
      },
      {
        title: this.$t('common.label.approvalStatus'),
        dataIndex: 'checkStatus',
        ellipsis: false,
        width: 130,
        customRender: checkStatus => [
          <p>
            {checkStatus === 'PASSED' ? (
              <span class={this.$style.primary}>
                {this.$t('common.action.pass')}
              </span>
            ) : checkStatus === 'FAIL' ? (
              <span class={this.$style.danger}>
                {this.$t('common.action.Failed')}
              </span>
            ) : (
              <span>{this.$t('common.label.pendingReview')}</span>
            )}
          </p>,
        ],
        generateFilterOptions: list =>
          [...new Set(list.map(item => item.checkStatus))].map(checkStatus => ({
            text:
              checkStatus === 'PASSED'
                ? this.$t('common.action.pass')
                : checkStatus === 'FAIL'
                ? this.$t('common.action.Failed')
                : this.$t('common.label.pendingReview'),
            value: checkStatus,
          })),
      },
      {
        title: this.$t('gallery.label.sharedGroup'),
        key: 'auditImgGroup',
        ellipsis: false,
        width: 200,
        customRender: record => {
          return <span>{record.auditImgGroupName}</span>;
        },
        generateFilterOptions: list =>
          [
            ...new Set(
              list.map(item => {
                return item.auditImgGroupName + ':' + item.auditImgGroup;
              }),
            ),
          ].map(str => ({
            text: str.split(':')[0],
            value: str.split(':')[1],
          })),
      },
      {
        title: this.$t('gallery.label.sharerNickname'),
        dataIndex: 'createNickName',
        ellipsis: false,
        width: 200,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.sharerName'),
        dataIndex: 'createBy',
        ellipsis: false,
        width: 200,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.sharerPhone'),
        dataIndex: 'createPhone',
        ellipsis: false,
        width: 200,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.pushTime'),
        dataIndex: 'createDate',
        ellipsis: false,
        width: 200,
        customRender: createDate => [
          <span>
            {createDate
              ? this.$moment(createDate).format('YYYY-MM-DD HH:mm')
              : '--'}
          </span>,
        ],
        generateFilterOptions: list =>
          [...new Set(list.map(item => item.createDate))].map(createDate => ({
            text: createDate
              ? this.$moment(createDate).format('YYYY-MM-DD HH:mm')
              : '--',
            value: createDate,
          })),
      },
      {
        title: this.$t('dictionary.reviewer'),
        dataIndex: 'checkBy',
        ellipsis: false,
        width: 130,
        filter: false,
        sorter: false,
      },
      {
        title: this.$t('dictionary.reviewTime'),
        dataIndex: 'checkDate',
        ellipsis: false,
        width: 200,
        customRender: checkDate => [
          <span>
            {checkDate
              ? this.$moment(checkDate).format('YYYY-MM-DD HH:mm')
              : '--'}
          </span>,
        ],
        generateFilterOptions: list =>
          [...new Set(list.map(item => item.checkDate))].map(checkDate => ({
            text: checkDate
              ? this.$moment(checkDate).format('YYYY-MM-DD HH:mm')
              : '--',
            value: checkDate,
          })),
      },
      {
        title: this.$t('dictionary.auditOpinion'),
        dataIndex: 'checkOpinion',
        ellipsis: false,
        width: 200,
        customRender: checkOpinion => {
          return <span title={checkOpinion}>{checkOpinion}</span>;
        },
      },
      {
        title: this.$t('gallery.label.approvalGroup'),
        // dataIndex: 'idxImgGroup',
        key: 'idxImgGroup',
        ellipsis: false,
        width: 200,
        customRender: record => {
          return <span>{record.imgGroupName}</span>;
        },
        generateFilterOptions: list =>
          [
            ...new Set(
              list.map(item => {
                return item.imgGroupName + ':' + item.idxImgGroup;
              }),
            ),
          ].map(str => ({
            text: str.split(':')[0],
            value: str.split(':')[1],
          })),
      },
      {
        title: this.$t('common.action.operating'),
        key: 'action',
        ellipsis: false,
        filter: false,
        sorter: false,
        width: 130,
        customRender: record => {
          return (
            <span>
              {this.$p.action('VERIFY') ? (
                <a onClick={() => this.showAuditModal([record], 'audit')}>
                  {record.checkStatus !== 'UNCHECKED'
                    ? this.$t('dictionary.editReview')
                    : this.$t('common.action.review')}
                </a>
              ) : null}
              {record.checkStatus !== 'PASSED' && this.$p.action('DELETE') ? (
                <a
                  class={this.$style.action}
                  onClick={() => this.showAuditModal([record], 'delete')}
                >
                  {this.$t('common.action.delete')}
                </a>
              ) : null}
            </span>
          );
        },
      },
    ];
  }
  // 一级参数必须得自定义参数
  get service() {
    const service = new DataSourceService('/resource/cloud-img/checkList');
    service.getList = report => {
      const data = this.getFliterParams(report);
      return getCloudCheckList(data);
    };
    return service;
  }
}
</script>
<style lang="less" module>
.review {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 20px;
  box-sizing: border-box;
}
.action {
  color: #fe5959;
  margin-left: 20px;
  &:hover {
    color: #fe5959;
  }
}
.reviewTable {
  height: calc(100% - 20px);
}
.checkStatus {
  color: var(--primary);
}
.headerBar {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  padding-right: 10px;
  margin-bottom: 13px;
  ul {
    /*display: flex;*/
    /*align-items: center;*/
    color: var(--font-sub);
    line-height: 2.5;
  }
  li {
    float: left;
    font-size: var(--font-sm);
    border: none;
    margin-right: 40px;
    min-width: 0;
    &:last-child {
      margin-right: 0px;
    }
  }
}
.headerSelect {
  width: 106px;
}
.search {
  :global(.ant-input) {
    width: 110px;
    &:focus {
      width: 160px;
      transition: 1s all;
    }
  }
}
.datePicker {
  display: flex;
  align-items: center;
  .shareTimes {
    color: var(--font-info);
    margin-right: 5px;
  }
  .toolbar__picker {
    width: 240px;
    margin: 0 5px;
    font-size: var(--font-xs);
    :global {
      .ant-calendar-picker-icon {
        display: none;
      }
      .ant-calendar-picker-input.ant-input {
        padding-left: 10px;
        text-align: center;
      }
    }
  }
}
.searchTips {
  font-size: var(--font-xs) !important;
  color: var(--font-info);
  .total {
    color: var(--primary);
    font-size: var(--font-sm);
  }
}
.btnGroup {
  float: left;
  color: var(--font-sub);
  .btn {
    margin-right: 20px;
  }
  .btn:first-child {
    color: #fff;
  }
}
.tableImg {
  width: 60px;
  height: 60px;
  margin: 5px 0;
  cursor: pointer;
}
.primary {
  color: var(--primary);
}
.danger {
  color: #fe5959;
}
</style>
