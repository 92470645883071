<template>
  <div>
    <div :class="$style.subTab">
      <div :class="$style.left">
        <a-input
          :class="[$style.input, focus ? $style.focus : '']"
          v-model="keyword"
          @focus="focus = true"
          @blur="focus = false"
          @pressEnter="getRecords"
        >
          <x-icon type="search" slot="prefix" />
        </a-input>
        <div :class="$style.timeFilter">
          <a-range-picker
            v-model="dateRange"
            @change="handleRangePickerChange"
            format="yyyy-MM-DD"
            :separator="$t('common.label.to')"
            style="width: 260px"
          />
        </div>
        <div :class="$style.total">
          <span :class="$style.numText">{{ $t('record.UpdateNum') }}：</span>
          <span :class="$style.nums">{{ countData.editCount }}</span>
          <span :class="$style.numText"
            >{{ $t('record.optimizationNum') }}：</span
          >
          <span :class="$style.nums">{{ countData.optimizeCount }}</span>
          <a-dropdown :trigger="['click']" v-model="visible">
            <x-icon
              type="plus-circle"
              :class="$style.addNum"
              v-if="$p.action('CREATE')"
              hover
            />
            <div slot="overlay" :class="$style.overlay">
              <div :class="$style.showNum">
                {{ $t('record.optimizationsTime') }}
                <span
                  >{{ $t('record.currentTimes') }}：{{
                    countData.optimizeCount
                  }}</span
                >
              </div>
              <x-select v-model="optimizeCount" :options="optimizeOptions" />
              <div :class="$style.overlayFooter">
                <a-button type="info" @click="visible = false">{{
                  $t('common.action.cancel')
                }}</a-button>
                <a-button type="primary" @click="handleMenuClick">{{
                  $t('common.action.confirm')
                }}</a-button>
              </div>
            </div>
          </a-dropdown>
          <span :class="$style.numText"
            >{{ $t('record.functionTimes') }}：</span
          >
          <span :class="$style.nums">{{ countData.functionCount }}</span>

          <a-dropdown :trigger="['click']" v-model="functionVisible">
            <x-icon
              type="plus-circle"
              :class="$style.addNum"
              v-if="$p.action('CREATE')"
              hover
            />

            <div slot="overlay" :class="$style.overlay">
              <div :class="$style.showNum">
                {{ $t('record.addTime') }}
                <span
                  >{{ $t('record.currentTimes') }}：{{
                    countData.functionCount
                  }}</span
                >
              </div>
              <x-select v-model="updateNum" :options="options" />
              <div :class="$style.overlayFooter">
                <a-button type="info" @click="functionVisible = false">{{
                  $t('common.action.cancel')
                }}</a-button>
                <a-button
                  type="primary"
                  @click="addFunction"
                  v-if="$p.action('CREATE')"
                  >{{ $t('common.action.confirm') }}</a-button
                >
              </div>
            </div>
          </a-dropdown>
        </div>
      </div>
      <div :class="$style.right">
        <a-button type="primary" ghost @click="toView">{{
          $t('common.action.preview')
        }}</a-button>
        <a-button
          icon="plus"
          type="primary"
          @click="openDetail()"
          v-if="$p.action('CREATE')"
          >{{ $t('inviteCode.add') }}</a-button
        >
      </div>
    </div>
    <div :class="$style.emptyWrap" v-if="!recordsData.length">
      <div :class="$style.emptyBox"></div>
      <p>{{ $t('record.noRecord') }}</p>
    </div>
    <div v-for="item in recordsData" :key="item.group">
      <div :class="$style.period">
        <span v-html="timeReplace(item.group)"></span>
      </div>
      <div :class="$style.recordList">
        <RecordItem
          v-for="record in item.list"
          :key="record.pkId"
          :data="record"
          @openDetail="openDetail(record)"
          @delete="deleteItem"
          @pushRecord="pushRecord"
          @refreshState="refreshState"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import RecordItem from './components/record-item.vue';
import RecordDetail from './components/record-detail.vue';
import PushTime from './components/push-time.vue';

import {
  getRecordsList,
  delRecordItem,
} from '@/services/configuration/recordConfig';
import {
  setOptimizeCount,
  getOptimizeCount,
} from '@/services/configuration/recordConfig';
import moment from 'moment';
import { createModuleUrl } from '@/utils';

@Component({ components: { RecordItem } })
export default class Index extends Vue {
  visible = false;
  dateRange = null;
  focus = null;
  updateNum = 1;
  optimizeCount = 1;
  recordsData = [];
  functionVisible = false;
  countData = {
    functionCount: 0,
    optimizeCount: 0,
  };

  keyword = '';
  beginTime = null;
  endingTime = null;
  get options() {
    return [
      {
        value: -1,
        label: '-1',
      },
      {
        value: -2,
        label: '-2',
      },
      {
        value: -3,
        label: '-3',
      },
      {
        value: -4,
        label: '-4',
      },
      {
        value: -5,
        label: '-5',
      },
      {
        value: -6,
        label: '-6',
      },
      {
        value: -7,
        label: '-7',
      },
      {
        value: -8,
        label: '-8',
      },
      {
        value: -9,
        label: '-9',
      },
      {
        value: -10,
        label: '-10',
      },
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 6,
        label: '6',
      },
      {
        value: 7,
        label: '7',
      },
      {
        value: 8,
        label: '8',
      },
      {
        value: 9,
        label: '9',
      },
      {
        value: 10,
        label: '10',
      },
      {
        value: 11,
        label: '11',
      },
      {
        value: 12,
        label: '12',
      },
      {
        value: 13,
        label: '13',
      },
      {
        value: 14,
        label: '14',
      },
      {
        value: 15,
        label: '15',
      },
      {
        value: 16,
        label: '16',
      },
      {
        value: 17,
        label: '17',
      },
      {
        value: 18,
        label: '18',
      },
      {
        value: 19,
        label: '19',
      },
      {
        value: 20,
        label: '20',
      },
    ];
  }

  toView() {
    window.open(createModuleUrl('www') + '/help/updateRecord');
  }

  get optimizeOptions() {
    return [
      ...this.options,
      {
        value: 21,
        label: '21',
      },
      {
        value: 22,
        label: '22',
      },
      {
        value: 23,
        label: '23',
      },
      {
        value: 24,
        label: '24',
      },
      {
        value: 25,
        label: '25',
      },
      {
        value: 26,
        label: '26',
      },
      {
        value: 27,
        label: '27',
      },
      {
        value: 28,
        label: '28',
      },
      {
        value: 29,
        label: '29',
      },
      {
        value: 30,
        label: '30',
      },
    ];
  }

  timeReplace(data) {
    let timeReplace = moment(data).format('Y-MM');
    return timeReplace;
  }
  created() {
    this.getRecords();
    this.getCount();
  }

  async pushRecord(data) {
    const value = await PushTime.createFormModal({ data: data });
    this.getRecords();
    data.pushTime = value;
    if (value === 0) {
      data.issue = 3;
    }
  }
  refreshState() {
    this.getRecords();
  }

  async getCount() {
    const value = await getOptimizeCount();
    this.countData = value;
  }

  async deleteItem(data) {
    await this.$confirm(this.$t('record.delTip'));
    await delRecordItem(data);
    this.getRecords();
    this.getCount();
  }

  async addFunction() {
    this.countData.functionCount =
      this.countData.functionCount + this.updateNum < 0
        ? 0
        : this.countData.functionCount + this.updateNum;
    await setOptimizeCount({
      functionCount:
        this.countData.functionCount + this.updateNum < 0
          ? 0
          : this.countData.functionCount + this.updateNum,
      optimizeCount: this.countData.optimizeCount,
    });
    this.functionVisible = false;
  }

  async handleMenuClick() {
    this.countData.optimizeCount =
      this.countData.optimizeCount + this.optimizeCount < 0
        ? 0
        : this.countData.optimizeCount + this.optimizeCount;
    await setOptimizeCount({
      functionCount: this.countData.functionCount,
      optimizeCount:
        this.countData.optimizeCount + this.optimizeCount < 0
          ? 0
          : this.countData.optimizeCount + this.optimizeCount,
    });
    this.visible = false;
  }

  handleRangePickerChange(range) {
    this.beginTime = range[0] ? range[0].startOf('day').valueOf() : '';
    this.endingTime = range[1] ? range[1].endOf('day').valueOf() : '';
    this.getRecords();
  }

  async getRecords() {
    this.recordsData = await getRecordsList({
      beginTime: this.beginTime,
      endingTime: this.endingTime,
      keyword: this.keyword,
    });
  }

  openDetail(data) {
    const value = RecordDetail.createDrawer(
      {
        data: data,
      },
      {
        confirm: () => {
          value.visible = false;
          this.getRecords();
        },
        close: () => {
          value.visible = false;
        },
      },
    );
  }
}
</script>
<style lang="less" module>
.subTab {
  padding: 20px 20px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left,
  .right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }
  .right {
    button {
      margin-left: 15px;
    }
  }
  .input {
    width: 110px;
    transition: width 0.5s linear;
    &.focus {
      width: 200px;
    }
  }

  .timeFilter {
    padding: 0 20px;
  }

  .total {
    margin-left: 20px;
    .addNum {
      color: var(--primary);
      cursor: pointer;
      margin-top: 4px;
      margin-left: 10px;
    }
    .nums {
      color: var(--primary);
      padding-right: 0.5em;
    }
    .numText {
      color: var(--font-info);
      margin-left: 40px;
      cursor: pointer;
      &:nth-child(1) {
        cursor: auto;
      }
    }
  }
}

.recordList {
  display: flex;
  padding: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  display: grid;
  gap: 40px 80px;
  grid-template-columns: repeat(auto-fill, 350px);
}

.recordMonths {
  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.period {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0px;
  padding-left: 20px;
  font-size: 18px;
  height: 50px;
  background-color: var(--main-bg);
  line-height: 50px;
  z-index: 100;
  color: var(--font-info);
  b {
    margin-left: 10px;
    color: var(--font);
  }
}

.overlay {
  width: 280px;
  border-radius: 6px;
  padding: 20px;
  background-color: var(--dropdown-bg);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  .showNum {
    margin-bottom: 9px;
    color: var(--font-info);

    span {
      margin-left: 20px;
      color: --font-color-secondary;
    }
  }
}

.overlayFooter {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  margin-top: 20px;
  button {
    margin-left: 20px;
    min-width: 60px;
    height: 24px;
    padding: 0 10px;
  }
}

.emptyWrap {
  width: 100%;
  height: calc(100% - 268px);
  text-align: center;
  margin-top: 268px;
  justify-content: center;
  p {
    line-height: 30px;
  }
}
.emptyBox {
  height: 200px;
  width: 415px;
  background-image: var(--empty-home-image);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: auto;
}
</style>
