<template>
  <div :class="$style.recycle">
    <div :class="$style.header">
      <a-input-search v-model="keyword" @search="refreshList"></a-input-search>
      <span>
        {{ $t('common.label.deleteDate') }}
        <a-range-picker
          v-model="shareDate"
          valueFormat="x"
          format="YYYY-MM-DD"
          @change="refreshList"
        >
        </a-range-picker>
      </span>
      <span
        >{{ $t('dictionary.total') }}<i :class="$style.primary">{{ total }}</i>
        {{ $t('iconLibrary.icon.size') }}</span
      >
    </div>
    <div v-if="!libraryList.length" :class="$style.empty">
      <EmptyContent />
    </div>
    <x-scroll-list
      v-show="libraryList.length > 0"
      v-model="libraryList"
      :service="initData"
      ref="scrollList"
      :class="$style.list"
    >
      <icon-library
        slot-scope="{ data }"
        :data="data"
        :recycle="true"
        @change="refreshAll"
      />
    </x-scroll-list>
  </div>
</template>
<script>
import { getRecycleBinList } from '@/services/resource/icon-library';
import { createDrawer } from '@triascloud/x-components';
import { Component, Vue } from 'vue-property-decorator';
import IconLibrary from '../icon-library';
import EmptyContent from '@/components/empty-content';
import { globalVueI18n } from '@triascloud/i18n';

function createRecycleDrawer(props, listener) {
  return createDrawer(
    h =>
      h(Recycle, {
        props,
        on: {
          refreshLibraryList:
            listener && listener.refreshLibraryList
              ? listener.refreshLibraryList
              : () => null,
        },
      }),
    {
      title: globalVueI18n.translate('iconLibrary.library.recycle'),
      width: 854,
      className: 'x-icon-manage-recycle-drawer',
    },
  );
}
@Component({
  components: { IconLibrary, EmptyContent },
})
export default class Recycle extends Vue {
  static createRecycleDrawer = createRecycleDrawer;

  total = 0;
  keyword = '';
  shareDate = [];

  refreshList() {
    this.$refs.scrollList.refresh();
  }

  refreshAll() {
    this.refreshList();
    this.$emit('refreshLibraryList');
  }

  libraryList = [];

  get starTime() {
    return !this.shareDate.length ? '' : this.shareDate[0];
  }

  get endTime() {
    return !this.shareDate.length ? '' : this.shareDate[1];
  }

  async initData(page) {
    const params = {
      deleted: 1,
      endTime: this.endTime,
      iconLibraryType: null,
      iconLibraryQuery: {
        current: page,
        size: 20,
      },
      iconQuery: {
        current: 1,
        size: 12,
      },
      keyword: this.keyword,
      starTime: this.starTime,
    };
    const list = await getRecycleBinList(params);
    this.total = list.totalLibraryNum;

    return list.libraries.records;
  }
}
</script>
<style lang="less" module>
:global {
  .x-icon-manage-recycle-drawer {
    .ant-drawer-body {
      padding: 0;
    }
  }
}
.recycle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    height: 50px;
    border-bottom: 1px solid var(--border);
    width: 100%;
    padding: 0 30px;

    .primary {
      color: var(--primary);
      font-style: normal;
    }

    :global {
      .ant-input-search {
        width: 110px;
        margin-right: 20px;
      }

      .ant-calendar-picker {
        width: 230px;
        margin-right: 12px;
      }
    }
  }
  .empty {
    padding: 30px;
    flex: 1;
    width: 100%;
  }
  .list {
    flex: 1;
    padding: 20px 20px;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, 257px);
    gap: 20px 15px;
    justify-content: space-between;
    align-content: flex-start;

    > div {
      height: 325px;
      width: 257px;
    }

    :global {
      .x-scroll-list-footer {
        display: none;
      }
    }
  }
}
</style>
