import request from '../request';
const basePath = '/resource/screening';

/**
 * @name 综合筛选
 * @param data  [{ current, keyword, size }]
 */
export function getSearchRes(keyword, current, size) {
  return request(`${basePath}/list`, {
    method: 'GET',
    body: {
      keyword,
      current,
      size,
    },
  }).then(res => {
    if (res.repositoryDetails && res.repositoryDetails.length) {
      res.repositoryDetails.forEach(item => {
        item.name = item.title;
      });
    }
    if (res.repositories && res.repositories.length) {
      res.repositories.forEach(item => {
        item.name = item.repositoryName;
      });
    }

    if (res.taskList && res.taskList.length) {
      res.taskList.forEach(item => {
        item.name = item.title;
      });
    }
    return res;
  });
}
