<template>
  <a-upload
    :file-list="fileList"
    :remove="handleRemove"
    :multiple="false"
    :customRequest="beforeUpload"
  >
    <div>
      <a-button> <a-icon type="upload" /> 选择文件 </a-button>
    </div>
  </a-upload>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component()
export default class ImageDesign extends Vue {
  /** @description 数据 */
  @Prop({
    type: Object,
    required: true,
  })
  field;
  @Prop() value;
  @Prop() view;
  fileList = [];
  handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  }
  beforeUpload(fileObj) {
    fileObj.file;
  }
}
</script>
