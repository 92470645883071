/** @type { import('vuex').Module } */
import { reqGetCompany } from '@/services/hat';
const hat = {
  namespaced: true,
  state: {
    //1展示导入，2展示导入中，3展示导入完成
    excelState: 1,
    fileName: '',
    devicesInfo: {},
    cor: {},
    status: '',
    companyList: [],
    //选择的OTA升级版本
    OTAversion: {},
    deviceIds: [],
  },
  mutations: {
    setExcelState(state, number) {
      state.excelState = number;
    },
    setFilName(state, name) {
      state.fileName = name.split('.')[0];
    },
    setDevicesInfo(state, obj) {
      state.devicesInfo = obj;
    },
    setCor(state, cor) {
      state.cor = cor;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setCompanyList(state, list) {
      state.companyList = list;
    },
    setOTAversion(state, obj) {
      state.OTAversion = obj;
    },
    setDeviceIds(state, ids) {
      state.deviceIds = ids;
    },
  },
  actions: {
    async getCompanyList({ commit, state }) {
      return new Promise(resolve => {
        if (state.companyList.length) {
          resolve();
        } else {
          reqGetCompany({ current: 1, size: 1000 }).then(result => {
            commit('setCompanyList', result.records);
            resolve();
          });
        }
      });
    },
  },
};

export default hat;
