<template>
  <a-form-model
    ref="form"
    :rules="computedRules"
    :model="formData"
    :class="$style['form-wrapper']"
  >
    <a-form-model-item
      v-for="item in formModelData"
      :key="item.id"
      :label="$t(item.label)"
      :prop="item.name"
      :class="$style['small-item']"
      :style="{
        marginBottom: item.marginBottom,
        flex: item.layout === 'singleLine' && '100%',
        marginLeft: item.layout === 'singleLine' && 0,
      }"
    >
      <multi-field
        :type="item.type"
        :labelType="item.labelType"
        :content-height="item.contentHeight"
        :value="formData[item.name]"
        :radioList="item.radioList"
        @changeValue="
          value =>
            Array.isArray(value)
              ? changeValue([item.name, value[0], value[1]])
              : changeValue([item.name, value])
        "
      />

      <!-- 处理子项 -->
      <a-form-model-item
        v-if="
          item.child &&
            formData[item.child.condition.name] === item.child.condition.value
        "
        :prop="item.child.name"
        :class="[$style['small-item'], $style['extra-item']]"
        :style="{
          marginLeft: item.child.layout === 'singleLine' && 0,
        }"
      >
        <multi-field
          :type="item.child.type"
          :content-height="item.child.contentHeight"
          :value="formData[item.child.name]"
          :radioList="item.child.radioList"
          @changeValue="
            value =>
              Array.isArray(value)
                ? changeValue([item.child.name, value[0], value[1]])
                : changeValue([item.child.name, value])
          "
        />
      </a-form-model-item>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import MultiField from './multi-field.vue';

@Component({
  components: {
    MultiField,
  },
})
export default class EditCase extends Vue {
  @Prop({ type: Array }) formModelData;
  @Prop({ type: Object }) formData;

  // 拼接每项的规则
  get computedRules() {
    let obj = {};
    const handleItem = item => {
      const val = item.rules.map(rule => {
        const res = { ...rule };
        if (res.preMsg) {
          res.message = this.$t(res.preMsg) + this.$t(item.label);
          delete res.preMsg;
        }
        return res;
      });
      obj[item.name] = val;
    };
    this.formModelData.forEach(item => {
      if (item.rules && item.rules.length) {
        handleItem(item);
      }
      if (item.child && item.child.rules && item.child.rules.length) {
        handleItem(item.child);
      }
    });
    return obj;
  }

  async changeValue([name, ...args]) {
    this.$emit('changeValue', [name, ...args]);
    await this.$nextTick();
    this.$refs.form.validateField(name);
  }
}
</script>

<style lang="less" module>
.form-wrapper {
  flex-shrink: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  .small-item {
    flex: calc(50% - 10px);
    flex-grow: 0;
    &:nth-of-type(2n) {
      margin-left: 20px;
    }
    :global .ant-form-item-children {
      // 旧版本的谷歌浏览器 span 没有撑起宽度
      display: block;
    }
  }
}

.extra-item {
  margin-top: 20px;
}
</style>
