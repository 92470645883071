<template>
  <div :class="$style.index">
    <div :class="$style.title">{{ $t('activity.informationCode') }}</div>
    <div :class="$style.item">
      <div :class="$style.label">{{ $t('activity.winningCode') }}</div>
      <div>{{ detailsData.award }}</div>
    </div>
    <div :class="$style.item" v-for="item in columns" :key="item.dataIndex">
      <div :class="$style.label">{{ item.title }}</div>
      <div v-if="item.dataIndex === 'createTime'">
        {{
          detailsData[item.dataIndex]
            ? $moment(detailsData[item.dataIndex]).format('Y-MM-DD HH:mm')
            : '-'
        }}
      </div>

      <div v-else>{{ detailsData[item.dataIndex] }}</div>
    </div>
    <div :class="$style.item">
      <div :class="$style.label" v-if="$p.action('UPDATE')">
        {{ $t('activity.whetherWin') }}
      </div>
      <div v-if="$p.action('UPDATE')">
        <a-select
          :placeholder="$t('common.label.all')"
          v-model="detailsData.isWin"
          :class="$style.toolbar__select"
        >
          <a-select-option
            v-for="item in templateTypes"
            :key="item.value"
            :value="item.value"
            >{{ item.text }}</a-select-option
          >
        </a-select>
        <a-button type="link" @click="setAwardStatus">{{
          $t('activity.adjustment')
        }}</a-button>

        <a-button type="link" @click="sendToUser"
          >发送中奖信息至微信消息/短信</a-button
        >
      </div>
    </div>
    <div :class="$style.item">
      <div :class="$style.label">{{ $t('activity.winningCodes') }}</div>
      <div
        :class="$style.award_value"
        v-for="item in detailsData.otherWinCode"
        :key="item.winCode"
      >
        {{ item.winCode
        }}<a-button type="link" @click="switchData(item.winCode)">{{
          $t('activity.Check')
        }}</a-button>
      </div>
    </div>

    <!-- <div :class="$style.item">
      <div :class="$style.label">{{ $t('activity.invitationCode') }}</div>
      <div
        :class="$style.value"
        v-for="item in detailsData.myInviteCode"
        :key="item.inviteCode"
      >
        <span :class="$style.inviteCode">{{ item.inviteCode }}</span>
        <span v-if="item.ifUse">{{ $t('activity.InvitedSuccessfully') }}</span>
        <span v-if="item.useName"
          >{{ $t('activity.invitees') }}：{{ item.useName }}
          {{ item.usePhone }}</span
        >
        <span v-if="item.useTime">{{
          $moment(item.useTime).format('Y-MM-DD HH:mm')
        }}</span>
      </div>
    </div> -->
    <div :class="$style.item">
      <div :class="$style.label">发送消息记录</div>
      <a-table :data-source="sendRecord" :columns="sendRecordColumn" />
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { createDrawer } from '@triascloud/x-components';
import {
  winningDetail,
  setAward,
  batchSendAwardMessage,
  getAwardSendRecord,
} from '@/services/marketing/invite-code';

import { globalVueI18n } from '@triascloud/i18n';
import { AwardTypeText, AwardType } from '@/enum/activity';

function createWinningCodeDetailsDrawer(props, listener) {
  return createDrawer(h => h(WinningCodeDetails, { props, on: listener }), {
    title: `${globalVueI18n.translate('activity.winningCode')}：${
      props.data.award
    }`,
    width: 518,
    className: 'x-default-icon-drawer',
  });
}
const sendType = {
  0: '微信',
  1: '短信',
};
@Component()
export default class WinningCodeDetails extends Vue {
  @Prop({ type: Object }) data;
  static createDrawer = createWinningCodeDetailsDrawer;
  sendRecord = [];
  get sendRecordColumn() {
    return [
      {
        title: '发送时间',
        dataIndex: 'createDate',
        customRender: date => this.$moment(date).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '发送渠道',
        dataIndex: 'msgType',
        customRender: type => sendType[type],
      },
      {
        title: '奖项',
        dataIndex: 'isWin',
        customRender: isWin => this.$t(AwardTypeText[isWin]),
      },
    ];
  }
  detailsData = {};
  get templateTypes() {
    return [
      {
        text: this.$t(AwardTypeText[AwardType.Null_Award]),
        value: AwardType.Null_Award,
      },
      {
        text: this.$t(AwardTypeText[AwardType.Special_Award]),
        value: AwardType.Special_Award,
      },
      {
        text: this.$t(AwardTypeText[AwardType.First_Award]),
        value: AwardType.First_Award,
      },
      {
        text: this.$t(AwardTypeText[AwardType.Second_Award]),
        value: AwardType.Second_Award,
      },
      {
        text: this.$t(AwardTypeText[AwardType.Third_Award]),
        value: AwardType.Third_Award,
      },
      {
        text: this.$t(AwardTypeText[AwardType.Mystery_Award]),
        value: AwardType.Mystery_Award,
      },
    ];
  }
  // templateTypes = [
  //   {
  //     text: this.$t('activity.didNotwin'),
  //     value: 0,
  //   },
  //   {
  //     text: this.$t('activity.grand'),
  //     value: 1,
  //   },
  //   {
  //     text: this.$t('activity.firstPrize'),
  //     value: 2,
  //   },
  //   {
  //     text: this.$t('activity.secondPrize'),
  //     value: 3,
  //   },
  //   {
  //     text: this.$t('activity.thirdPrize'),
  //     value: 4,
  //   },
  //   {
  //     text: this.$t('activity.mysteryAward'),
  //     value: 5,
  //   },
  // ];

  created() {
    this.getData();
    this.getSendRecord();
  }

  async getSendRecord() {
    this.sendRecord = await getAwardSendRecord(this.data.award);
  }

  async switchData(award) {
    this.detailsData = await winningDetail({ award: award });
  }

  async getData() {
    this.detailsData = await winningDetail({ award: this.data.award });
  }

  async setAwardStatus() {
    await setAward({ award: this.data.award, isWin: this.detailsData.isWin });
    this.$message.success(this.$t('common.tips.operationSuccess'));
    this.$emit('refresh', this.detailsData.isWin);
    this.getSendRecord();
  }

  async sendToUser() {
    if (!this.detailsData.isWin)
      return this.$message.warning('未中奖不允许发送消息');
    await batchSendAwardMessage([this.data.award]);
    this.$message.success(this.$t('common.tips.operationSuccess'));
  }

  get columns() {
    return [
      {
        title: this.$t('activity.RelatedCodes'),
        dataIndex: 'inviteCode',
      },
      {
        title: this.$t('activity.account'),
        dataIndex: 'account',
      },
      {
        dataIndex: 'userName',
        title: this.$t('activity.names'),
      },
      {
        title: this.$t('activity.phoneNumber'),
        dataIndex: 'userPhone',
      },
      {
        title: this.$t('activity.InvitePeople'),
        dataIndex: 'inviteUser',
      },
      {
        title: this.$t('activity.InviterPhone'),
        dataIndex: 'inviteUserPhone',
      },
      {
        title: '获取时间',
        dataIndex: 'createTime',
      },
    ];
  }
}
</script>
<style lang="less" module>
.index {
  padding: 0 20px;
}
.title {
  font-size: 14px;
  padding: 20px 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
}
.item {
  margin-bottom: 20px;
}
.label {
  margin-bottom: 10px;
}
.value {
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  span {
    margin-right: 8px;
  }
}
.toolbar__select {
  width: 95px;
}

.award_value {
  cursor: pointer;
  span {
    margin-left: 40px;
  }
}
.inviteCode {
  width: 63px;
  display: flex;
}
</style>
