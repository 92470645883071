var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form-model',{ref:"form",attrs:{"model":_vm.formData,"rules":{
    name: [
      {
        required: true,
        message:
          _vm.$t('common.placeholder.input') +
          _vm.$t('market.template.classify.name'),
      },
      {
        message: _vm.$t('common.validate.length', { value: '2~20' }),
        min: 2,
        max: 20,
      } ],
  }}},[_c('a-form-model-item',{attrs:{"label":_vm.$t('market.template.classify.name'),"prop":"name"}},[_c('a-input',{attrs:{"max-length":20},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(_vm._s(_vm.formData.name.length)+"/20")]},proxy:true}]),model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }