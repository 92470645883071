<template>
  <div :class="$style.overView">
    <div :class="[$style.top, planType === 'PASSIVE' ? $style.bt : $style.end]">
      <div v-if="planType === 'PASSIVE'">
        <span style="color:var(--font-info)">订阅主题：</span>
        <a-select
          placeholder="请选择主题"
          v-model="form.selectTheme"
          style="width: 140px;"
          :class="$style.marR"
          :getPopupContainer="triggerNode => triggerNode.parentNode"
          @change="chanegSelect"
        >
          <a-select-option
            v-for="item in tehemeData"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <a-dropdown
          v-model="dropDownVisible"
          placement="bottomRight"
          :trigger="['click']"
          :overlayClassName="$style.dropdown"
          :getPopupContainer="triggerNode => triggerNode.parentNode"
        >
          <a-button
            type="link"
            icon="filter"
            style="padding: 0"
            @click="e => e.preventDefault()"
          >
            筛选数据{{ getLength }}
          </a-button>
          <div slot="overlay" @click="handleMenuClick">
            <div :class="$style.header">
              <span style="font-size: 14px">筛选</span>
              <a-button
                type="link"
                style="padding: 0"
                @click.stop="cleanScreen"
              >
                <x-icon type="tc-icon-brush" />
                清空
              </a-button>
            </div>
            <div :class="$style.content">
              <div :class="$style.scroll">
                <div :class="$style.logic" v-if="ruleList.length">
                  <x-select
                    :value="form.logic"
                    :data="logicData"
                    @input="handleLogicChange"
                    size="small"
                    style="width: 100%;"
                    :dropdownClassName="$style.dropdownLogic"
                  />
                </div>
                <div style="flex: 1;overflow: hidden;">
                  <a-row :gutter="10">
                    <a-col
                      v-for="(item, idx) in ruleList"
                      :key="item.id"
                      :class="$style.select"
                    >
                      <a-col
                        :span="8"
                        style="display: flex;align-items: center"
                      >
                        <a-select
                          v-model="item.typeId"
                          style="width: 100%;"
                          placeholder="请选择"
                          @change="val => changeCondition(val, idx)"
                        >
                          <a-select-option
                            v-for="t in ruleSelectList"
                            :key="t.id"
                            :value="t.id"
                          >
                            <div
                              style="display: flex;justify-content: space-between;align-items: center;"
                            >
                              <div
                                :title="t.name"
                                style="width: 230px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                              >
                                {{ t.name }}
                              </div>
                              <a-tag
                                v-if="formateType(t.type, 'color')"
                                :color="formateType(t.type, 'color')"
                                style="height: 24px"
                              >
                                {{ formateType(t.type, 'type') }}
                              </a-tag>
                            </div>
                          </a-select-option>
                        </a-select>
                      </a-col>
                      <a-col :span="3">
                        <a-select
                          v-model="item.rule"
                          style="width: 100%;"
                          placeholder="请选择"
                          @change="
                            val => {
                              changeRule(val, item);
                            }
                          "
                        >
                          <a-select-option
                            v-for="t in item.ruleSelectList"
                            :key="t.value"
                            :value="t.value"
                          >
                            {{ t.label }}
                          </a-select-option>
                        </a-select>
                      </a-col>
                      <a-col :span="11">
                        <a-date-picker
                          v-if="item.type === 'date' && item.rule !== 'RANGE'"
                          v-model="item.value"
                          show-time
                          format="YYYY-MM-DD HH:mm:ss"
                          style="width: 100%;"
                          placeholder="选择时间"
                          @ok="
                            v => {
                              handleTimeChange(v, item, 'ok');
                            }
                          "
                          @change="
                            v => {
                              handleTimeChange(v, item, 'change');
                            }
                          "
                        />
                        <a-range-picker
                          v-else-if="
                            item.type === 'date' && item.rule === 'RANGE'
                          "
                          v-model="item.value"
                          show-time
                          format="YYYY-MM-DD HH:mm:ss"
                          style="width: 100%;"
                          :placeholder="['开始时间', '结束时间']"
                          @ok="
                            v => {
                              handleTimeChange(v, item, 'ok');
                            }
                          "
                          @change="
                            v => {
                              handleTimeChange(v, item, 'change');
                            }
                          "
                        />
                        <a-switch
                          v-else-if="item.type === 'bool'"
                          v-model="item.value"
                          checked-children="开"
                          un-checked-children="关"
                        />
                        <a-select
                          v-else-if="item.type === 'enum'"
                          placeholder="请选择设备"
                          v-model="item.value"
                          style="width: 100%;"
                        >
                          <a-select-option
                            v-for="e in item.selectList"
                            :value="e.value"
                            :key="e.value"
                          >
                            {{ e.key }}
                          </a-select-option>
                        </a-select>
                        <a-input
                          v-else-if="showInput(item)"
                          v-model="item.value"
                          allowClear
                          placeholder="请输入条件值"
                        />
                        <a-input
                          v-else-if="
                            getIntType(item) &&
                              ['EMPTY', 'NOT_EMPTY'].indexOf(item.rule) ===
                                -1 &&
                              item.rule !== 'RANGE'
                          "
                          type="number"
                          v-model="item.value"
                          @blur="validateMin(item)"
                          allowClear
                        />
                        <div
                          :class="$style.rangeValue"
                          v-else-if="
                            getIntType(item) &&
                              ['EMPTY', 'NOT_EMPTY'].indexOf(item.rule) ===
                                -1 &&
                              item.rule === 'RANGE'
                          "
                        >
                          <a-input
                            type="number"
                            v-model="item.minValue"
                            @blur="validateMin1(item)"
                            allowClear
                          />
                          <span style="margin: 0 10px">-</span>
                          <a-input
                            type="number"
                            v-model="item.maxValue"
                            @blur="validateMax(item)"
                            allowClear
                          />
                        </div>
                      </a-col>
                      <a-col :span="2">
                        <x-icon
                          type="tc-icon-delete"
                          :class="$style.deleteIcon"
                          @click="deleteRule(idx)"
                        />
                      </a-col>
                    </a-col>
                  </a-row>
                </div>
              </div>
              <div>
                <a-button
                  type="link"
                  icon="plus"
                  style="padding: 0"
                  @click.stop="addRule"
                >
                  添加规则
                </a-button>
              </div>
              <div style="text-align: end;margin-top: -20px">
                <a-button type="primary" @click.stop="sureScreen">
                  确认筛选
                </a-button>
              </div>
            </div>
          </div>
        </a-dropdown>
      </div>
    </div>
    <a-table
      :columns="clumns"
      :data-source="tableData"
      :pagination="pagination"
      :scroll="scrollObj"
      :row-key="record => record.pkId"
      :loading="tableLoading"
      @change="handlePagination"
    >
      <template slot="action" slot-scope="record">
        <span :class="$style.warningSpan" @click="copySource(record)">
          复制
        </span>
      </template>
    </a-table>
    <div :class="$style.tips">
      <span>共{{ realTotal }}条记录，预览最多展示前1000条</span>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  getPreviewList,
  getPreviewStruct,
  getThemeList,
} from '@/services/iot-platform/dataManage';
import { clipboardWrite, uuid, download } from '@triascloud/utils';
import { createModal } from '@triascloud/x-components';
import InnerModel from '@/views/connector/platform-manage/develop/data-manage/inner-model.vue';
import {
  formatData,
  formateType,
} from '@/views/connector/platform-manage/develop/data-manage/common';
import { ControlTypeEnum } from '@/views/connector/platform-manage/develop/data-template/template-design/form/controls/enum.js';

/** @name 筛选公式 */
const KEY_FORMULA = {
  EQUAL: {
    label: '等于',
    value: 'EQUAL',
  },
  NOT_EQUAL: {
    label: '不等于',
    value: 'NOT_EQUAL',
  },
  LESS: {
    label: '小于',
    value: 'LESS',
  },
  LESS_EQUAL: {
    label: '小于等于',
    value: 'LESS_EQUAL',
  },
  GRATER: {
    label: '大于',
    value: 'GRATER',
  },
  GRATER_EQUAL: {
    label: '大于等于',
    value: 'GRATER_EQUAL',
  },
  EMPTY: {
    label: '为空',
    value: 'EMPTY',
  },
  NOT_EMPTY: {
    label: '不为空',
    value: 'NOT_EMPTY',
  },
  RANGE: {
    label: '区间',
    value: 'RANGE',
  },
  CONTAIN: {
    label: '包含',
    value: 'CONTAIN',
  },
  NOT_CONTAIN: {
    label: '不包含',
    value: 'NOT_CONTAIN',
  },
};

@Component
export default class OverView extends Vue {
  @Prop({ type: String, default: '' }) subscribeId;
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: String, default: '' }) planType;

  @Watch('planType')
  changeType(newVal) {
    this.planType = newVal;
  }

  form = {
    selectDevice: undefined,
    selectTheme: undefined,
    timeRange: [],
    logic: 'AND',
  };

  formateType;

  beforeCreate() {
    this.formateType = formateType;
  }

  mounted() {
    this.getThemeData();
  }

  chanegSelect(val) {
    this.ruleList = [];
    this.dropDownVisible = false;
    this.form.selectTheme = val;
    this.firstFlag = true;
    this.queryCondition = [];
    this.pagination.current = 1;
    this.getList();
  }

  tehemeData = [];
  deviceList = [];
  deviceStatus = {
    NOT_ALLOCATED: '未分配',
    ALLOCATED: '已分配',
  };

  textDate() {
    return {
      text: [
        KEY_FORMULA.EQUAL,
        KEY_FORMULA.NOT_EQUAL,
        KEY_FORMULA.EMPTY,
        KEY_FORMULA.NOT_EMPTY,
        KEY_FORMULA.CONTAIN,
        KEY_FORMULA.NOT_CONTAIN,
      ],
      date: [
        KEY_FORMULA.EQUAL,
        KEY_FORMULA.NOT_EQUAL,
        KEY_FORMULA.LESS,
        KEY_FORMULA.LESS_EQUAL,
        KEY_FORMULA.GRATER,
        KEY_FORMULA.GRATER_EQUAL,
        KEY_FORMULA.EMPTY,
        KEY_FORMULA.NOT_EMPTY,
        KEY_FORMULA.RANGE,
      ],
    };
  }

  get defaultRuleList() {
    return [
      {
        id: uuid(),
        typeId: 'deviceCode',
        type: 'text',
        name: '设备号',
        rule: 'EQUAL',
        fi: 'triascloud_device_code',
        si: undefined,
        path: 'triascloud_device_code',
        ruleSelectList: this.textDate().text,
        selectList: [],
        value: '',
        other: '',
      },
      {
        id: uuid(),
        typeId: 'createTime',
        type: 'date',
        name: '创建时间',
        rule: 'EQUAL',
        fi: 'triascloud_create_time',
        si: undefined,
        path: 'triascloud_create_time',
        ruleSelectList: this.textDate().date,
        selectList: [],
        value: '',
        other: '',
      },
    ];
  }

  ruleList = [];
  ruleSelectList = [];

  addRule() {
    if (this.ruleList.length > 0) {
      const row = this.ruleList.at(-1);
      if (!row.typeId) {
        this.$message.warning('请完善添加的筛选规则');
        return;
      }
    }
    this.ruleList.push({
      id: uuid(),
      typeId: undefined,
      type: '',
      rule: undefined,
      ruleSelectList: [],
      selectList: [],
      value: '',
      other: '',
      minValue: undefined,
      maxValue: undefined,
    });
  }
  changeCondition(valId, index, flag = 'add') {
    const idx = this.ruleSelectList.findIndex(v => v.id === valId);
    const row = this.ruleSelectList[idx];
    const type = row.type;
    const sameFormula = [
      KEY_FORMULA.EQUAL,
      KEY_FORMULA.NOT_EQUAL,
      KEY_FORMULA.LESS,
      KEY_FORMULA.LESS_EQUAL,
      KEY_FORMULA.GRATER,
      KEY_FORMULA.GRATER_EQUAL,
      KEY_FORMULA.EMPTY,
      KEY_FORMULA.NOT_EMPTY,
      KEY_FORMULA.RANGE,
    ];
    const o = {
      int: sameFormula,
      int32: sameFormula,
      float: sameFormula,
      date: sameFormula,
      double: sameFormula,
      text: [
        KEY_FORMULA.EQUAL,
        KEY_FORMULA.NOT_EQUAL,
        KEY_FORMULA.EMPTY,
        KEY_FORMULA.NOT_EMPTY,
        KEY_FORMULA.CONTAIN,
        KEY_FORMULA.NOT_CONTAIN,
      ],
      bool: [KEY_FORMULA.EQUAL, KEY_FORMULA.NOT_EQUAL],
      enum: [
        KEY_FORMULA.EQUAL,
        KEY_FORMULA.NOT_EQUAL,
        KEY_FORMULA.EMPTY,
        KEY_FORMULA.NOT_EMPTY,
        KEY_FORMULA.CONTAIN,
        KEY_FORMULA.NOT_CONTAIN,
      ],
      file: [KEY_FORMULA.EMPTY, KEY_FORMULA.NOT_EMPTY],
      image: [KEY_FORMULA.EMPTY, KEY_FORMULA.NOT_EMPTY],
      array: [
        KEY_FORMULA.EMPTY,
        KEY_FORMULA.NOT_EMPTY,
        KEY_FORMULA.CONTAIN,
        KEY_FORMULA.NOT_CONTAIN,
      ],
    };
    this.ruleList[index].ruleSelectList = o[type];
    this.$nextTick(() => {
      const obj = this.ruleList[index];
      obj.typeId = row.id;
      obj.other = row.other;
      obj.type = type;
      obj.path = row.si ? `${row.fi}.${row.si}` : row.fi;
      if (flag === 'add') {
        obj.rule = obj.ruleSelectList[0].value;
        obj.value = undefined;
      }
      if (type === ControlTypeEnum.Enum) {
        if (row.other?.length) {
          const other = JSON.parse(row.other);
          obj.selectList = other;
          obj.value = other[0].value;
        }
      }
    });
  }

  get getLength() {
    const len = this.ruleList.length;
    return `（${len}）`;
  }

  getIntType(item) {
    return (
      item.type === ControlTypeEnum.Int ||
      item.type === 'int32' ||
      item.type === ControlTypeEnum.Float ||
      item.type === ControlTypeEnum.Double
    );
  }

  showInput(item) {
    const a = ['EMPTY', 'NOT_EMPTY'].indexOf(item.rule) === -1;
    const b = this.getIntType(item);
    return a && !b;
  }

  validateMin(item) {
    let value = Number(item.value);
    if (item.type === ControlTypeEnum.Int || item.type === 'int32') {
      const v = value.toString().split('.').length > 1;
      if (v) {
        item.value = +value.toString().split('.')[0];
      }
      if (value > 2147483648) {
        item.value = 2147483648;
      } else if (value < -2147483648) {
        item.value = -2147483648;
      }
    } else if (item.type === ControlTypeEnum.Float) {
      if (value > 3.4e38) {
        item.value = 3.4e38;
      } else if (value < -3.4e38) {
        item.value = -3.4e38;
      }
    } else {
      if (value > 1.79e308) {
        item.value = 1.79e308;
      } else if (value < -1.79e308) {
        item.value = -1.79e308;
      }
    }
  }

  validateMin1(item) {
    let min = Number(item.minValue);
    const max = Number(item.maxValue);
    if (max !== null && min > max) {
      min = max;
    }
    if (item.type === ControlTypeEnum.Int || item.type === 'int32') {
      if (min < -2147483648) {
        min = -2147483648;
      }
    } else if (item.type === ControlTypeEnum.Float) {
      if (min < Number(-3.4e38)) {
        min = Number(-3.4e38);
      }
    } else {
      if (min < Number(-1.79e308)) {
        min = Number(-1.79e308);
      }
    }
    item.minValue = min;
  }

  validateMax(item) {
    let min = Number(item.minValue);
    let max = Number(item.maxValue);
    if (min !== null && min > max) {
      max = min;
    }
    if (item.type === ControlTypeEnum.Int || item.type === 'int32') {
      if (max > 2147483648) {
        max = 2147483648;
      }
    } else if (item.type === ControlTypeEnum.Float) {
      if (max > Number(3.4e38)) {
        max = Number(3.4e38);
      }
    } else {
      if (max > Number(1.79e308)) {
        max = Number(1.79e308);
      }
    }
    item.maxValue = max;
  }

  changeRule(val, row) {
    row.value = undefined;
    row.rule = val;
  }

  handleTimeChange(v, row, flag) {
    if (flag === 'ok') {
      row.value = v;
    }
  }

  deleteRule(idx) {
    this.ruleList.splice(idx, 1);
  }

  queryCondition = [];

  sureScreen() {
    this.queryCondition = this.ruleList.map(v => {
      const o = {
        operator: v.rule,
        parameterPath: v.path,
        type: v.type.toUpperCase(),
        value: '',
      };
      if (this.getIntType(v)) {
        if (v.rule === 'RANGE') {
          o['minValue'] = v.minValue;
          o['maxValue'] = v.maxValue;
        } else {
          o.value = Number(v.value);
        }
      } else if (v.type === ControlTypeEnum.Date) {
        if (v.rule === 'RANGE') {
          if (v.value) {
            o['minValue'] = this.$moment(v.value[0]).valueOf();
            o['maxValue'] = this.$moment(v.value[1]).valueOf();
          } else {
            o['minValue'] = undefined;
            o['maxValue'] = undefined;
          }
        } else {
          o.value = this.$moment(v.value).valueOf();
        }
      } else {
        o.value = v.value;
      }
      return o;
    });
    this.pagination.current = 1;
    this.getList();
  }

  cleanScreen() {
    this.ruleList = [];
  }

  dropDownVisible = false;

  handleMenuClick(e) {
    e.preventDefault();
  }

  logicData = [
    {
      value: 'AND',
      text: '且',
    },
    {
      value: 'OR',
      text: '或',
    },
  ];

  handleLogicChange(val) {
    this.form.logic = val;
  }

  async getThemeData() {
    try {
      const res = await getThemeList(this.subscribeId);
      if (res) {
        this.tehemeData = res;
        this.form.selectTheme = res[0].id;
      }
      this.$nextTick(() => {
        this.getList();
      });
    } catch {
      return false;
    }
  }

  tableData = [];
  structArr = {};
  realTotal = 0;
  async getList() {
    try {
      if (this.firstFlag) {
        this.firstFlag = false;
        this.structArr = {};
        const structArr = this.structArr;
        const s = {
          subscribeId: this.subscribeId,
          themeId: this.form.selectTheme,
        };
        this.tableLoading = true;
        const struct = await getPreviewStruct(s);
        const arr1 = this.defaultRuleList;
        const arr2 = this.changeSelectList(struct);
        this.ruleSelectList = arr1.concat(arr2);
        struct.forEach(v => {
          structArr[v.identifier] = {
            name: v.name,
            type: v.dataType.toLowerCase(),
            other: v.other,
            unit: v.unit,
          };
          if (v.sonVO.length) {
            structArr[v.identifier]['sonVO'] = v.sonVO;
            structArr[v.identifier]['hasVO'] = true;
          } else {
            structArr[v.identifier]['hasVO'] = false;
          }
        });
      }
      const params = {
        pkId: this.subscribeId,
        current: this.pagination.current,
        size: this.pagination.pageSize,
        hasCreateTime: false,
        themeId: this.form.selectTheme,
      };
      if (this.queryCondition.length > 0) {
        const len = this.queryCondition.filter(
          v => v.parameterPath === 'triascloud_create_time',
        ).length;
        params.hasCreateTime = !!len;
      }
      if (this.queryCondition.length) {
        params.queryCondition = this.queryCondition;
        params.symbol = this.form.logic;
      }
      const { records, current, total, realTotal } = await getPreviewList(
        params,
      );
      const res = records;
      this.pagination.current = current;
      this.pagination.total = total;
      this.realTotal = realTotal || 0;
      this.formatTableData(this.structArr, res);
    } catch {
      this.tableLoading = false;
      return false;
    }
  }

  changeSelectList(arr) {
    if (!arr.length) return [];
    const res = [];
    arr.forEach(v => {
      if (v.sonVO.length) {
        v.sonVO.forEach(s => {
          const o = {
            id: uuid(),
            name: `${v.name}.${s.name}`,
            type: s.dataType.toLowerCase(),
            other: s.other,
            fi: v.identifier,
            si: s.identifier,
          };
          res.push(o);
        });
      } else {
        const o = {
          id: uuid(),
          name: `${v.name}`,
          type: v.dataType.toLowerCase(),
          other: v.other,
          fi: v.identifier,
          si: '',
        };
        res.push(o);
      }
    });
    return res;
  }

  clumns = [];

  get defaultColumns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => {
          return index + 1;
        },
        title: '序号',
        align: 'center',
        width: 80,
      },
      {
        title: '设备号',
        dataIndex: 'triascloud_device_code',
        width: 160,
      },
      {
        title: '创建时间',
        dataIndex: 'triascloud_create_time',
        width: 180,
      },
    ];
  }

  scrollObj = {};
  firstFlag = true;

  changeColumn(item) {
    const columnArr = [];
    this.clumns = this.defaultColumns;
    for (let key in item) {
      if (
        key !== 'triascloud_source_data' &&
        key !== 'triascloud_device_code' &&
        key !== 'triascloud_create_time'
      ) {
        if (typeof item[key] === 'object') {
          if (item[key].hasVO) {
            const o = {
              title: item[key].name,
              dataIndex: key,
            };
            if (o.title.length > 4) {
              o.width = o.title.length * 20;
            } else {
              o.width = o.title.length * 30;
            }
            o['customRender'] = text => (
              <span
                class={this.$style.primarySpan}
                onClick={e => {
                  e.stopPropagation();
                  this.openInnerModel(text, o.title);
                }}
              >
                详情
              </span>
            );
            columnArr.push(o);
          } else {
            if (item[key].sonVO) {
              item[key].sonVO.forEach(son => {
                if (son.isArray) {
                  const o = {
                    title: son.name,
                    dataIndex: son.key,
                  };
                  if (o.title.length > 4) {
                    o.width = o.title.length * 20;
                  } else {
                    o.width = o.title.length * 30;
                  }
                  o['customRender'] = text => (
                    <span
                      class={this.$style.primarySpan}
                      onClick={e => {
                        e.stopPropagation();
                        this.openInnerModel(text, o.title);
                      }}
                    >
                      详情
                    </span>
                  );
                  columnArr.push(o);
                } else {
                  const o = {
                    title: son.name,
                    dataIndex: son.key,
                    ellipsis: true,
                  };
                  if (o.title.length > 4) {
                    o.width = o.title.length * 20;
                  } else {
                    o.width = o.title.length * 30;
                  }
                  o['customRender'] = text => <span title={text}>{text}</span>;
                  columnArr.push(o);
                }
              });
            } else {
              const o = {
                title: item[key].name,
                dataIndex: key,
                ellipsis: true,
              };
              if (o.title.length > 4) {
                o.width = o.title.length * 20;
              } else {
                o.width = o.title.length * 30;
              }
              if (item[key].type.toLowerCase() === ControlTypeEnum.Array) {
                o['customRender'] = text => (
                  <span title={text ? text.toString() : '-'}>
                    {text ? text.toString() : ''}
                  </span>
                );
              } else {
                if (item[key].type.toLowerCase() === ControlTypeEnum.File) {
                  o['customRender'] = text => (
                    <span title={text} class={this.$style.fileWrap}>
                      <x-icon
                        class={this.$style.icon}
                        onClick={() => {
                          download(text, item[key].name);
                        }}
                        type="tc-icon-file-unknown"
                      />
                    </span>
                  );
                } else if (
                  item[key].type.toLowerCase() === ControlTypeEnum.Image
                ) {
                  o['customRender'] = text => (
                    <span>
                      <x-oss-image
                        class="x-file-icon"
                        size="32"
                        oss-path={text}
                        onClick={() => {
                          this.$preview(text, 'image');
                        }}
                      />
                    </span>
                  );
                } else {
                  o['customRender'] = text => <span title={text}>{text}</span>;
                }
              }
              columnArr.push(o);
            }
          }
        }
      }
    }
    this.clumns = this.clumns.concat(columnArr);
    const o = {
      title: '原始数据',
      // dataIndex: 'triascloud_source_data',
      key: 'operation',
      fixed: 'right',
      width: 100,
      scopedSlots: { customRender: 'action' },
    };
    const len = columnArr.length;
    this.scrollObj['x'] = 1000 + 1000 * Math.floor(len / 7);
    this.clumns.push(o);
    this.tableLoading = false;
  }

  async copySource(record) {
    await clipboardWrite(record.triascloud_source_data);
    this.$message.success(this.$t('common.tips.copy'));
  }

  openInnerModel(key, name) {
    const val = key === '[]' ? [] : key;
    const modal = createModal(
      () => <InnerModel modelData={val} onClose={() => modal.handleClose()} />,
      {
        width: '1000px',
        title: name,
      },
    );
  }

  formatTableData(obj, arr) {
    const row = formatData(
      obj,
      arr,
      [
        'triascloud_device_code',
        'triascloud_create_time',
        'triascloud_source_data',
      ],
      this.$moment,
    );
    const res = row.res;
    this.changeColumn(res[0]);
    this.tableData = Object.freeze(row.data);
  }

  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };
  tableLoading = false;

  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getList();
  }

  columns = [];
}
</script>

<style lang="less" module>
.overView {
  .top {
    display: flex;
    margin-bottom: 10px;

    .marR {
      margin-right: 10px;
    }

    .text {
      display: flex;
      align-items: center;
      color: var(--warning);
    }
  }

  .bt {
    justify-content: space-between;
  }

  .end {
    justify-content: flex-end;
  }

  .primarySpan {
    color: var(--primary);
    cursor: pointer;
  }

  .warningSpan {
    color: var(--warning);
    cursor: pointer;
  }

  .tips {
    margin-top: -26px;
    color: var(--primary);
  }
  .rangeValue {
    display: flex;
    align-items: center;
  }

  :global {
    .ant-table-thead > tr > th .ant-table-header-column {
      padding: 0;
    }
  }
}

.dropdown {
  background: var(--dropdown-bg);
  width: 900px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  box-shadow: 0 0 14px 0 var(--dropdown-shadow);

  .content {
    padding: 15px;
    max-height: 350px;
    overflow-y: auto;
    position: relative;

    .scroll {
      display: flex;

      .logic {
        width: 36px;
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 10px;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 12px;
          border: 1px solid var(--btn-border);
          border-right: none;
          pointer-events: none;
        }

        :global {
          .ant-select-sm .ant-select-arrow {
            right: 2px;
          }

          .ant-select-selection--single .ant-select-selection__rendered {
            margin: 0 4px;
          }

          .ant-select-selection {
            background-color: var(--block-bg);
          }
        }
      }

      .select {
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }

        .deleteIcon {
          font-size: 20px;
          margin-left: 10px;
          cursor: pointer;
          color: var(--delete);
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px 5px 10px;
    border-bottom: 1px solid var(--form-border);

    .action {
      font-size: 14px;
      cursor: pointer;

      &:hover {
        color: var(--primary);
      }
    }
  }

  :global {
    .ant-input-affix-wrapper {
      &:last-child {
        margin-right: 0;
      }

      .ant-input {
        padding-right: 11px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }

      .anticon-close-circle {
        display: none;
      }

      &:hover .ant-input {
        padding-right: 30px;
      }

      &:hover .anticon-close-circle {
        display: inline-block;
      }
    }

    .ant-select-selection--multiple .ant-select-selection__choice.zoom-leave {
      display: none;
    }
  }
}

.dropdownLogic {
  :global {
    .ant-select-dropdown-menu-item {
      padding: 4px 8px;
      line-height: 18px;
    }
  }
}
.fileWrap {
  display: flex;
  .icon {
    font-size: 32px;
  }
}
</style>
