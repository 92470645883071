<template>
  <div :class="$style.authModel">
    <a-table
      :columns="columns"
      :data-source="dataList"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="tableLoading"
      @change="handlePagination"
    >
      <template slot="appName" slot-scope="text, record">
        <div :class="$style.tagColumn">
          <span> {{ text }}</span>
          <span>{{ record.identifier }}</span>
        </div>
      </template>
      <template slot="operate" slot-scope="record">
        <span :class="$style.buttonGroups">
          <a :class="[$style.button]" @click.stop="copy(record)">
            复制对接示例
          </a>
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getApiBasicSetting } from '@/services/iot-platform/platformInterface';
import { clipboardWrite } from '@triascloud/utils';

@Component
export default class AuthModal extends Vue {
  @Prop({ type: Array, default: () => [] }) dataList;
  @Prop({ type: String, default: '' }) appKey;
  @Prop({ type: String, default: '' }) secret;

  @Watch('dataList', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal && newVal.length) {
      this.dataList = newVal;
    } else {
      this.dataList = [];
    }
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => {
          return index + 1;
        },
        title: '序号',
        align: 'center',
        width: 80,
      },
      {
        dataIndex: 'name',
        title: '连接器名称/标识符',
        scopedSlots: { customRender: 'appName' },
      },
      {
        dataIndex: 'status',
        title: '连接器状态',
        customRender: text => {
          const typeMap = {
            UNPUBLISHED: '待发布',
            PUBLISHED: '已发布',
          };
          return typeMap[text];
        },
      },
      {
        width: 150,
        dataIndex: '',
        title: '操作',
        scopedSlots: { customRender: 'operate' },
      },
    ];
  }

  tableList = [];
  tableLoading = false;
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => ` ${total} 条`,
  };

  handlePagination(data) {
    this.pagination = data;
    this.getList();
  }

  mounted() {
    // this.getList();
  }

  async getList() {}

  async copy(row) {
    try {
      const { requestUrl, returnParam } = await getApiBasicSetting(row.pkId);
      let str = `
          #重要提示
          请妥善保管key和secret，对接时请根据三叠开放平台的要求获取应用凭证并完成签名操作\n
          #对接账号信息
          key：${this.appKey}
          secret：${this.secret}\n
          #推送地址
          ${requestUrl}
        `;
      str = str.trim().replace(/(?!\n)\s/g, '');
      str += '\n';
      if (returnParam) {
        const s = JSON.parse(returnParam).filter(v => !v.isChildren);
        str += `\n#推送data示例\n`;
        const obj = this.changeObj(s);
        str += obj;
        str += `
          \n#data参数说明
        `;
        const dataStr = this.changeStr(s);
        str += dataStr.replace(/(?!\n)\s/g, '');
      } else {
        str += `
          #推送data示例
          暂无出参示例
        `;
      }
      await clipboardWrite(str);
      this.$message.success('复制成功');
    } catch {
      return false;
    }
  }

  changeObj(s) {
    let str = {
      data: {},
    };
    s.forEach(v => {
      if (['int', 'float', 'double', 'bool', 'enum'].indexOf(v.type) > -1) {
        str.data[v.identifier] = 1;
      } else if (v.type === 'text') {
        str.data[v.identifier] = '文本';
      } else if (v.type === 'date') {
        str.data[v.identifier] = 1234567989456;
      } else if (v.type === 'image' || v.type === 'file') {
        str.data[v.identifier] = 'https://sandieyun.com';
      } else if (v.type === 'dataPoint') {
        if (v.children.length > 0) {
          str.data[v.identifier] = this.changeDP(v.children);
        } else {
          str.data[v.identifier] = {};
        }
      } else if (v.type === 'struct') {
        str.data[v.identifier] = {};
      } else if (v.type === 'array') {
        str.data[v.identifier] = [];
      } else {
        str.data[v.identifier] = '';
      }
    });
    return JSON.stringify(str, null, 2);
  }

  changeDP(arr) {
    const o = {};
    arr.forEach(v => {
      if (['int', 'float', 'double', 'bool', 'enum'].indexOf(v.type) > -1) {
        o[v.identifier] = 0;
      } else if (v.type === 'date') {
        o[v.identifier] = 1234567989456;
      } else if (v.type === 'array') {
        o[v.identifier] = [];
      } else if (v.type === 'image' || v.type === 'file') {
        o[v.identifier] = 'https://sandieyun.com';
      } else if (v.type === 'struct') {
        o[v.identifier] = {};
      } else {
        o[v.identifier] = '';
      }
    });
    return o;
  }

  changeStr(s) {
    let res;
    s.forEach(v => {
      if (v.type === 'int') {
        res += this.changeInt(v);
      } else if (v.type === 'float' || v.type === 'double') {
        res += this.changeFloat(v);
      } else if (v.type === 'text') {
        res += this.changeText(v);
      } else if (v.type === 'date') {
        res += this.changeDate(v);
      } else if (v.type === 'bool') {
        res += this.changeBool(v);
      } else if (v.type === 'enum') {
        res += this.changeEnum(v);
      } else if (v.type === 'image' || v.type === 'file') {
        res += this.changeIF(v);
      } else if (v.type === 'dataPoint') {
        if (v.children.length > 0) {
          res += `${v.identifier}：${v.name}\n`;
          v.children.forEach(c => {
            res += `|--${c.identifier}：${c.name}\n`;
          });
          res += '\n';
        } else {
          res += `${v.identifier}：${v.name}\n`;
        }
      } else {
        res += `${v.identifier}：${v.name}`;
      }
    });
    return res;
  }

  changeInt(row) {
    let res = '';
    if (row.max !== null && row.min !== null) {
      res += `
        ${row.identifier}：${row.name}，数值范围为${row.min}-${row.max}
      `;
    } else {
      res += `
        ${row.identifier}：${row.name}，数值范围为-2147483648-2147483648
      `;
    }
    return res;
  }

  changeFloat(row) {
    return `${row.identifier}：${row.name}，参数精度：小数点后${row.precision}位\n`;
  }

  changeText(row) {
    return `${row.identifier}：${row.name}，最大长度：${row.maxLength}字节\n`;
  }

  changeDate(row) {
    return `${row.identifier}：${row.name}，13位时间戳\n`;
  }

  changeBool(row) {
    return `${row.identifier}：${row.name}，true-${row.is},false-${row.not}\n`;
  }

  changeEnum(row) {
    const list = row.optionList;
    let res = `${row.identifier}：${row.name}，\n`;
    list.forEach(e => {
      res += `${e.value}-${e.text}\n`;
    });
    return res;
  }

  changeIF(row) {
    let res = '';
    if (row.isStore) {
      res += `${row.identifier}：${row.name}，文件限制大小：限${row.storeSize}M\n`;
    } else {
      res += `${row.identifier}：${row.name}，网址url\n`;
    }
    return res;
  }
}
</script>

<style lang="less" module>
.authModel {
  .buttonGroups {
    display: flex;
    justify-content: flex-start;

    .button {
      width: 100px;
      position: relative;
    }
  }

  .tagColumn {
    display: flex;
    flex-direction: column;

    span {
      line-height: 18px;
    }

    span:last-child {
      color: var(--font-info);
    }
  }

  :global {
    .ant-table-thead > tr > th .ant-table-header-column {
      padding: 0;
    }
  }
}
</style>
