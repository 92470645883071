<template>
  <div :class="$style.tabConfig">
    <field-config-config-desc :desc="$t('controls.desc.tabs')" />
    <!-- 选项卡 -->
    <a-form-model-item
      :label="$t('controls.tabs.tabsLabel')"
      prop="widget.tabs"
      ref="tabs"
      :rules="rules"
    >
      <draggable
        tag="ul"
        :value="tabs"
        @input="handleInput"
        :group="{ name: 'tab-config' }"
        :handle="`.${$style.dragHandler}`"
        :animation="300"
      >
        <li
          :class="$style.flexItem"
          v-for="(tab, tabIndex) in tabs"
          :key="tab.id"
        >
          <span :class="$style.label">
            <x-input
              :value="tab.title"
              @input="
                updateField({
                  key: `widget.tabs.${tabIndex}.title`,
                  value: $event,
                })
              "
              @blur="
                () => {
                  $refs.tabs.onFieldBlur();
                }
              "
              @change="
                () => {
                  $refs.tabs.onFieldChange();
                }
              "
            />
            <x-input
              :value="tab.identifier"
              :disabled="operationFlag === 'edit' ? true : false"
              @input="
                updateField({
                  key: `widget.tabs.${tabIndex}.identifier`,
                  value: $event,
                })
              "
              @blur="
                () => {
                  $refs.tabs.onFieldBlur();
                }
              "
              @change="
                () => {
                  $refs.tabs.onFieldChange();
                }
              "
            />
          </span>
          <span :class="$style.labelAction">
            <x-icon :class="$style.dragHandler" type="tc-icon-drag-handler" />
            <x-icon
              v-if="operationFlag === 'add'"
              :class="$style.delete"
              type="tc-icon-delete"
              @click="handleDeleteTab(tab)"
            />
          </span>
        </li>
      </draggable>
      <a @click="handleAddTab" v-if="operationFlag === 'add'">
        {{ $t('controls.label.addOption') }}
      </a>
    </a-form-model-item>
  </div>
</template>
<script>
import { Component, Vue, Prop, InjectReactive } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { ensureArray, uuid } from '@triascloud/utils';
import { createField } from '../utils';
import FieldConfigConfigDesc from '../components/field-config/config-desc.vue';
import { ControlTypeEnum } from '../enum';

@Component({
  components: { Draggable, FieldConfigConfigDesc },
})
export default class TabConfig extends Vue {
  @Prop() field;
  @Prop() updateField;
  @InjectReactive() operationFlag;

  get tabs() {
    return ensureArray(this.field.widget.tabs);
  }

  validate(rule, value, cb) {
    const tabs = this.field.widget.tabs;
    let countTitle = 0;
    let countIdentifier = 0;
    tabs.forEach(v => {
      if (v.title) {
        countTitle++;
      }
      if (v.identifier) {
        countIdentifier++;
      }
    });

    /** @name 标识是否重复呢 */
    const identifiers = tabs.map(v => v.identifier);
    const set = new Set();
    identifiers.forEach(v => {
      set.add(v);
    });

    if (countTitle !== tabs.length) {
      cb('标题不能为空！');
    } else if (countIdentifier !== tabs.length) {
      cb('标识不能为空！');
    } else if (identifiers.length !== set.size) {
      cb('标识重复了！');
    } else {
      cb();
    }
  }

  rules = [
    {
      required: true,
      validator: this.validate,
      trigger: 'change',
    },
  ];

  handleInput(value) {
    this.updateField({
      key: 'widget.current',
      value: value.length ? value[0].id : '',
    });
    this.updateField({ key: 'widget.tabs', value: value });
  }

  handleAddTab() {
    if (this.tabs.length === 10) {
      this.$message.warn('物模型模板最多能添加10个分类！');
      return;
    }
    const count = this.field.widget.count + 1;
    const title = `${this.$t('controls.label.sort')}${count}`;
    const newTab = {
      id: uuid(),
      title,
      identifier: `type${count}`,
      fields: [
        createField(ControlTypeEnum.FnName),
        createField(ControlTypeEnum.FnIdentifier),
      ],
    };
    this.updateField({
      key: 'widget.count',
      value: count,
    });
    this.updateField({
      key: 'widget.tabs',
      value: [...this.tabs, newTab],
    });
  }

  handleDeleteTab(deleteTab) {
    if (this.tabs.length === 1) {
      this.$message.error('分页卡最少存在一个标题');
      return;
    }
    const tabs = this.tabs.filter(tab => tab.id !== deleteTab.id);
    this.updateField({
      key: 'widget.current',
      value: tabs.length ? tabs[0].id : '',
    });
    this.updateField({
      key: 'widget.tabs',
      value: tabs,
    });
  }
}
</script>
<style lang="less" module>
li.flexItem {
  margin-bottom: 8px;
  .label {
    flex: 1;
    :global {
      .x-input + .x-input {
        margin-top: 4px;
      }
    }
  }

  .labelAction {
    margin-left: 10px;
  }
}
li.flexItem,
.flexItem :global(.ant-form-item-children) {
  display: flex;
  align-items: center;
  > :global(.ant-input),
  > :global(.ant-select),
  > :global(.x-tree-select) {
    flex: 1;
    margin-right: 4px;
  }
  > :global(.anticon) {
    font-size: 16px;
    width: 24px;
    text-align: center;
  }
}
.dragHandler {
  cursor: grab;
  font-size: 16px;
  width: 24px;
}
.delete {
  font-size: 16px;
  width: 24px;
}
.delete:hover {
  color: var(--danger);
}
.tab-style {
  height: 60px;
  border-radius: 4px;
  border: 1px solid var(--border);
  padding: 0 10px;
  display: flex;
  align-items: center;
  :global {
    .x-tab {
      width: 100%;
      margin: 0;
    }

    .x-tab::after {
      border: none;
    }

    .ant-tabs-nav-scroll {
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }

    .x-tab .ant-tabs-bar.ant-tabs-top-bar {
      margin: 0;
      padding: 0;
    }
  }
}
.descriptionEditor:global(.x-editor) :global {
  padding: 0;
  .ql-editor {
    min-height: 150px;
  }
  .x-editor .ql-snow.ql-toolbar .ql-picker-options,
  .x-editor-toolbar.ql-snow.ql-toolbar .ql-picker-options {
    max-height: 150px;
  }
  .ql-snow.ql-toolbar {
    padding: 4px 0;
  }
}
</style>
