<template>
  <a-tabs v-model="activeKey" hideAdd type="editable-card" @edit="onEdit">
    <a-tab-pane
      v-for="(pane, idx) in panes"
      :key="pane.key"
      :tab="pane.title"
      :closable="closable"
      :forceRender="true"
    >
      <component
        @change-name="name => handleChangeEventName(name, idx)"
        :is="pane.content"
        :platformId="platformId"
        :defaultEvent="eventList && eventList.length > 0 && eventList[idx]"
        ref="forms"
      />
    </a-tab-pane>
    <a-button
      v-if="panes.length < 5"
      icon="plus"
      slot="tabBarExtraContent"
      @click="handleAdd"
    >
      新增
    </a-button>
  </a-tabs>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import EventSetting from './event-setting.vue';
import { clone } from 'lodash';
@Component({
  components: { EventSetting },
})
export default class EventSettingList extends Vue {
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: Array, default: () => [] }) defaultEvents;
  // defaultEvents的副本
  eventList = [];
  activeKey = '1';

  @Watch('defaultEvents', { immediate: true })
  onEvents(list) {
    if (list && list.length > 0) {
      this.eventList = clone(this.defaultEvents) || [];
      this.panes = list.map((v, i) => {
        return {
          title: v.eventName,
          content: 'EventSetting',
          key: `${i + 1}`,
        };
      });
      this.len = this.panes.length;
    }
  }

  handleChangeEventName(name, idx) {
    this.panes[idx].title = name;
  }

  panes = [
    {
      title: '新主题1',
      content: 'EventSetting',
      key: '1',
    },
  ];

  get closable() {
    if (this.panes.length === 1) {
      return false;
    } else {
      return true;
    }
  }

  len = 1;
  handleAdd() {
    if (this.panes.length === 5) {
      return;
    }
    this.len++;
    const len = this.len;
    this.panes.push({
      title: `新主题${len}`,
      content: 'EventSetting',
      key: `${len}`,
    });
    this.activeKey = `${len}`;
  }

  onEdit(targetKey, action) {
    this[action](targetKey);
  }
  remove(targetKey) {
    let activeKey = this.activeKey;
    let lastIndex;
    this.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    // 删除tab的数据
    this.eventList.splice(lastIndex, 1);
    const panes = this.panes.filter(pane => pane.key !== targetKey);
    if (panes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        activeKey = panes[lastIndex].key;
      } else {
        activeKey = panes[0].key;
      }
    }
    this.panes = panes;
    this.activeKey = activeKey;
  }

  async getValue() {
    try {
      let count = 0;
      let index = -1;
      const len = this.$refs.forms.length;
      for (let [idx, item] of this.$refs.forms.entries()) {
        item.$refs.form.validate(valid => {
          if (valid) {
            count++;
          } else {
            index = idx;
          }
        });
        if (index !== -1) {
          break;
        }
      }
      if (count !== len) {
        const item = this.panes.find((_, i) => i === index);
        this.activeKey = item.key;
        return Promise.reject(false);
      } else {
        const configList = [];
        for (let item of this.$refs.forms) {
          item.form.dataSources = item.activeFlag;
          configList.push(item.form);
        }
        return configList;
      }
    } catch (error) {
      return;
    }
  }
}
</script>
