<template>
  <div :class="$style.box">
    <a-form-model-item>
      <a-upload
        accept="image/*"
        list-type="picture-card"
        :remove="handleRemove"
        :customRequest="customUpload"
        :file-list="fileList"
        :multiple="isMultiple"
        @preview="handlePreview"
      >
        <div v-if="fileList.length < field.widget.maxCount">
          <a-icon type="plus" />
        </div>
      </a-upload>
    </a-form-model-item>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import { templateDesignModule } from '@/enum/store';
import { DataFormContext } from '../../data-form/context';
import { UploadService } from '@triascloud/services';
import { crossStorageModule } from '@/enum/store';

@Component({ components: {} })
export default class Dataset extends Vue {
  @Prop({ required: true }) field;
  @Inject({ from: DataFormContext.Root, default: null }) root;
  @Inject({ from: DataFormContext.Model, default: null }) model;
  @templateDesignModule.State types;
  @crossStorageModule.State('tenant') tenant;
  paramFormVisible = false;
  paramDetail = {};
  paramArrRule = {
    validator: this.validator,
    trigger: 'change',
  };
  previewVisible = false;
  handleCancel() {
    this.previewVisible = false;
  }
  previewImage = '';
  async handlePreview(file) {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  get isDisabled() {
    return this.fileList.length >= this.field.widget.maxCount;
  }
  get isMultiple() {
    return this.field.widget.maxCount > 1;
  }
  customService = null;
  async created() {
    this.customService = new UploadService('/oss/oss');
    const data = this.model.getValue(this.field.pkId);
    if (data) {
      this.fileList = data;
      this.fileList.map(async item => {
        const abPath = await this.customService.getAuth(item.ossPath);
        item.url = abPath;
      });
    }
  }
  validator(rule, value, callback) {
    if (this.paramArr.length) {
      callback();
    } else {
      callback(new Error('请添加参数'));
    }
  }
  fileList = [];
  handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
    this.$emit('input', (this.fileList.length && this.fileList) || null);
  }
  uploadOptions = null;
  async customUpload(options) {
    if (this.isDisabled) {
      this.$message.warn(`最多上传${this.field.widget.maxCount}张图片！`);
      return;
    }
    if (options.file.size > this.field.widget.maxSize * 1024 * 1024) {
      this.$message.warn(
        `文件${options.file.name}大小超过${this.field.widget.maxSize}M！`,
      );
      return;
    }
    this.uploadOptions = options;
    let ossPath = await this.uploadFile(options);
    const abPath = await this.customService.getAuth(ossPath);
    this.fileList.push({
      uid: options.file.uid,
      name: options.file.name,
      status: 'done',
      url: abPath,
      ossPath,
    });
    this.$emit('input', this.fileList);
  }
  async uploadFile(file) {
    const uploadOptions = this.uploadOptions;
    const files = file.file;
    const pkId = this.tenant.pkId;
    const uploadPath = `${pkId}/connector/dp-manage/file`;
    const res = await this.customService.upload(
      files,
      uploadPath,
      e => {
        e.addEventListener('responseprogress', p => {
          uploadOptions.onProgress(
            { percent: +Math.round((p.loaded / p.total) * 100).toFixed(2) },
            files,
          );
        });
      },
      false,
    );
    uploadOptions.onSuccess(res, files);
    return res;
  }
}
</script>
<style lang="less" module>
.box {
  :global {
    .ant-upload-list-item {
      max-width: 400px;
    }
    .ant-upload-list-item-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 350px;
    }
  }
}

.mt10 {
  margin-top: 10px;
  font-size: 12px;
}
</style>
