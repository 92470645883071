<template>
  <div :class="$style.index">
    <a-spin :spinning="loading">
      <a-form v-if="data" :class="$style.form">
        <a-form-item :label="$t('plugin.snms.prefix')">
          <a-input disabled :value="data.prefix" />
        </a-form-item>
        <a-form-item :label="$t('plugin.snms.logo')">
          <a-input disabled :value="data.handle" />
        </a-form-item>
        <a-form-model-item
          v-for="(item, index) in data.values"
          :key="index"
          :label="item.key"
        >
          <a-input :value="item.value" disabled />
        </a-form-model-item>
      </a-form>
      <empty-content
        v-else-if="!loading"
        :label="$t('plugin.snms.IdRemoved')"
      />
    </a-spin>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { identifyQuantityDetail } from '@/services/template-manager/secondary';
import EmptyContent from '@/components/empty-content';
@Component({
  components: { EmptyContent },
})
export default class NodeDetails extends Vue {
  @Prop({ type: String }) handle;
  data = [];
  loading = true;
  mounted() {
    this.getData();
  }
  async getData() {
    this.data = await identifyQuantityDetail(this.handle);
    this.loading = false;
  }
}
</script>

<style lang="less" module>
.index {
  height: 500px;
  overflow-y: auto;
  padding: 20px 20px 0;
}
.form {
  display: flex;
  flex-wrap: wrap;
  :global {
    .ant-form-item {
      width: calc(50% - 10px);
      &:nth-child(even) {
        margin-left: 20px;
      }
    }
  }
}
</style>
