<template>
  <a-spin :spinning="loading" :class="$style.wrap">
    <iframe
      ref="iframe"
      :src="previewUrl"
      frameborder="0"
      width="100%"
      height="100%"
      @load="loading = false"
    ></iframe>
  </a-spin>
</template>
<script>
import { createDrawer } from '@triascloud/x-components';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createModuleUrl } from '@/utils';

function createKitDesignModal(props) {
  return new Promise(resolve => {
    const drawer = createDrawer(
      h =>
        h(KitDesignModal, {
          props,
          on: {
            save: fields => {
              drawer.visible = false;
              resolve(fields);
            },
          },
        }),
      {
        title: '套件设计',
        width: '100vw',
        height: '100vh',
        placement: 'bottom',
        bodyStyle: { padding: 0 },
      },
    );
  });
}
const EventEnum = {
  /** @name 设计器初始化完成 */
  Init: 'DESIGN_INIT',
  /** @name 保存操作 */
  Save: 'DESIGN_SAVE',
  /** @name 加载字段 */
  Load: 'DESIGN_LOAD',
};
@Component
export default class KitDesignModal extends Vue {
  @Prop({ type: Array }) fields;
  static create = createKitDesignModal;
  loading = true;
  get previewUrl() {
    return `${createModuleUrl('app')}/field-group-design`;
  }
  created() {
    window.addEventListener('message', this.handleMessage);
  }
  handleMessage(ev = {}) {
    const { event, data } = ev.data;
    switch (event) {
      case EventEnum.Init:
        this.fields?.length &&
          this.$refs.iframe?.contentWindow.postMessage(
            {
              event: EventEnum.Load,
              data: this.fields,
            },
            '*',
          );
        break;
      case EventEnum.Save:
        this.$emit('save', data);
        break;
    }
  }
  beforeDestroyed() {
    window.removeEventListener('message', this.handleMessage);
  }
}
</script>
<style lang="less" module>
.wrap {
  height: 100%;
  :global .ant-spin-container {
    height: 100%;
  }
}
</style>
