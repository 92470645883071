<template>
  <x-data-table
    ref="table"
    :columns="columns"
    :service="service"
    :class="$style.table"
    :custom-row="
      record => ({
        on: {
          click: () => openDetail(record),
        },
        style: {
          cursor: 'pointer',
        },
      })
    "
  >
    <div slot="headerLeft" :class="$style.toolbar">
      <a-select
        :class="$style.toolbar__select"
        v-model="sendData.templateType"
        :placeholder="$t('common.label.all')"
        allow-clear
      >
        <a-select-option
          v-for="item in templateTypes"
          :key="item.value"
          :value="item.value"
          >{{ item.text }}</a-select-option
        >
      </a-select>
      <a-input
        :class="$style.toolbar__input"
        @pressEnter="handleFilterChange"
        @blur="handleFilterChange"
        v-model="keyword"
      >
        <x-icon type="tc-icon-search" slot="prefix" />
      </a-input>
      <span
        @click="filterState(undefined)"
        :class="[
          $style.toolbar__button,
          { [$style.active]: sendData.checkState === undefined },
        ]"
        >{{ $t('common.label.all') }}</span
      >
      <span
        :class="[
          $style.toolbar__button,
          { [$style.active]: sendData.checkState === CheckResult.Pass },
        ]"
        @click="filterState(CheckResult.Pass)"
        >{{ $t('template.review.checkState.pass') }}</span
      >
      <span
        :class="[
          $style.toolbar__button,
          { [$style.active]: sendData.checkState === CheckResult.Fail },
        ]"
        @click="filterState(CheckResult.Fail)"
        >{{ $t('dictionary.checkStatus.fail') }}</span
      >
    </div>
  </x-data-table>
</template>
<script>
import { DataSourceService } from '@triascloud/services';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  basePath,
  getList,
  getResult,
} from '@/services/template-manager/templateChecker';
import { TemplateType, CheckResult } from '@/enum/template';
import { globalVueI18n } from '@triascloud/i18n';
import moment from 'moment';
import { createFormModal } from '@triascloud/x-components';
import ReviewFormModal from './components/review-form-modal.vue';
import AsyncButton from '@/components/async-button';
import ApplicationPreviewDrawer from '@/views/market/components/application-preview-drawer';
import PrintPreviewDrawer from '../market/components/print-preview-drawer.vue';
import FormPreviewDrawer from '../market/components/form-preview-drawer.vue';
@Component
export default class Review extends Vue {
  basePath = basePath;
  keyword = '';
  CheckResult = CheckResult;
  sendData = {
    checkState: undefined,
    templateType: undefined,
  };
  templateTypeText = {
    [TemplateType.Application]: globalVueI18n.translate(
      'market.template.application',
    ),
    [TemplateType.Form]: globalVueI18n.translate('market.template.form'),
    [TemplateType.Print]: globalVueI18n.translate('market.template.print'),
  };
  templateTypes = [
    {
      text: this.templateTypeText[TemplateType.Application],
      value: TemplateType.Application,
    },
    {
      text: this.templateTypeText[TemplateType.Form],
      value: TemplateType.Form,
    },
    {
      text: this.templateTypeText[TemplateType.Print],
      value: TemplateType.Print,
    },
  ];
  CheckResultText = {
    [CheckResult.Fail]: globalVueI18n.translate('common.action.Failed'),
    [CheckResult.Pass]: globalVueI18n.translate('common.action.pass'),
  };
  async openDetail(record) {
    let openMethod = null;
    if (record.pushType === TemplateType.Application) {
      openMethod = ApplicationPreviewDrawer.createModal;
    } else if (record.pushType === TemplateType.Print) {
      openMethod = PrintPreviewDrawer.createModal;
    } else {
      openMethod = FormPreviewDrawer.createModal;
    }
    const drawer = openMethod(
      {
        data: record,
        review: true,
      },
      {
        check: async (record, type) => {
          await this.check(record, type);
          drawer.visible = false;
        },
        preview: data => this.openDetail(data),
      },
    );
  }
  filterState(checkState) {
    this.sendData.checkState = checkState;
  }
  @Watch('sendData', { deep: true })
  handleFilterChange() {
    this.$refs.table.loadData();
  }
  get columns() {
    return [
      {
        title: this.$t('template.review.pushType'),
        dataIndex: 'pushType',
        customRender: pushType => this.templateTypeText[pushType],
      },
      {
        title: this.$t('template.review.pushName'),
        dataIndex: 'pushName',
      },
      {
        title: this.$t('template.review.pushTenantName'),
        dataIndex: 'pushTenantName',
        width: 180,
      },

      {
        title: this.$t('dictionary.pushTime'),
        dataIndex: 'pushTime',
        customRender: pushTime =>
          pushTime ? moment(pushTime).format('YYYY-MM-DD HH:mm') : '',
      },
      {
        title: this.$t('template.review.pushUserName'),
        dataIndex: 'pushUserName',
      },
      {
        title: this.$t('template.review.isChecked'),
        dataIndex: 'isChecked',
        customRender: isChecked =>
          isChecked
            ? this.$t('template.review.checked')
            : this.$t('template.review.unChecked'),
      },
      {
        title: this.$t('feedback.processingTime'),
        dataIndex: 'checkedTime',
        customRender: checkedTime =>
          checkedTime ? moment(checkedTime).format('YYYY-MM-DD HH:mm') : '',
      },
      {
        title: this.$t('template.review.checkResult'),
        dataIndex: 'checkedResult',
        customRender: checkedResult =>
          this.CheckResultText[checkedResult] || '',
      },
      {
        title: this.$t('feedback.processor'),
        dataIndex: 'checker',
      },
      {
        title: this.$t('template.review.earnPoints'),
        dataIndex: 'integrate',
        customRender: integrate => (integrate >= 0 ? integrate : '--'),
      },
      {
        title: this.$t('common.action.operating'),
        key: 'actions',
        width: 180,
        customRender: record =>
          record.isChecked ? (
            <span
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <AsyncButton type="link" click={() => this.viewAdvice(record)}>
                {this.$t('template.review.viewComments')}
              </AsyncButton>
            </span>
          ) : this.$p.action('VERIFY') ? (
            [
              <AButton type="link" click={() => this.openDetail(record)}>
                {this.$t('common.action.review')}
              </AButton>,
            ]
          ) : null,
      },
    ].map(item => ({
      ...item,
      filter: false,
      sorter: false,
      width: item.width || 130,
    }));
  }
  get service() {
    const service = new DataSourceService(basePath);
    service.getList = ({ size, skip }) =>
      getList({
        current: skip / size + 1,
        size,
        keyword: this.keyword,
        ...this.sendData,
      })
        .then(res => {
          return {
            ...res,
            data: res ? res.records : [],
            total: res ? res.total : 0,
          };
        })
        .catch(() => ({
          data: [],
          total: 0,
        }));
    return service;
  }
  async check(data, checkResult) {
    await ReviewFormModal.createModal(
      {
        data: { checkResult, requestId: data.id },
        templateType: data.pushType,
      },
      {
        title: checkResult
          ? this.$t('template.review.checkState.pass')
          : this.$t('dictionary.checkStatus.fail'),
      },
    );
    this.$refs.table.loadData();
  }

  async viewAdvice({ id }) {
    const data = await getResult(id);
    createFormModal(
      () => [
        <AFormModelItem label={this.$t('template.review.checkResult')}>
          {this.CheckResultText[data.result]}
        </AFormModelItem>,
        <AFormModelItem label={this.$t('template.review.comments')}>
          {data.advice}
        </AFormModelItem>,
      ],
      {
        title: this.$t('template.review.viewComments'),
        width: 442,
      },
    );
  }
}
</script>
<style lang="less" module>
.table {
  padding: 20px;
  .toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .toolbar__input {
      width: auto;
      margin: 0 30px 0 10px;
      :global .ant-input {
        width: 110px;
        transition: width 0.5s;
        &:focus {
          width: 200px;
        }
      }
    }
    .toolbar__select {
      width: 140px;
    }
    .toolbar__button {
      margin-right: 20px;
      cursor: pointer;
      &.active {
        color: var(--primary);
      }
    }
  }
}
</style>
