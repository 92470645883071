<template>
  <div ref="IncomeSortCharts" />
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { globalVueI18n } from '@triascloud/i18n';
import ResizeObserver from 'resize-observer-polyfill';
import { crossStorageModule } from '@/enum/store';

@Component({
  components: {},
})
export default class IncomeSortCharts extends Vue {
  @Prop({ type: Object }) data;
  /** @type { ResizeObserver } */
  _ob = null;
  myChart = null;
  @crossStorageModule.State skin;
  mounted() {
    this._ob = new ResizeObserver(this.chartResize);
    this._ob.observe(this.$refs.IncomeSortCharts);
    this.initChart();
  }
  chartResize() {
    this.myChart.resize();
  }
  get option() {
    const res = this.data && this.data.data ? this.data.data : [];

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: function(params) {
          return [
            params[0].name + '<br/>',
            globalVueI18n.translate('solution.income') +
              '：¥' +
              params[0].value.toFixed(2) +
              '<br/>',
            globalVueI18n.translate('solution.proportion') +
              '：' +
              params[0].data.proportion +
              '%' +
              '<br/>',
          ].join('');
        },
      },
      legend: {
        show: true,
        data: [
          {
            name: globalVueI18n.translate('solution.income'),
            // 强制设置图形为圆。
            icon: 'roundRect',
          },
        ],
        top: 25,
        right: 10,
        itemWidth: 8,
        itemHeight: 8,
        textStyle: {
          color: getComputedStyle(document.documentElement).getPropertyValue(
            '--font-info',
          ),
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      yAxis: [
        {
          type: 'category',
          data: res.map(item => item.tenantName),
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      xAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: globalVueI18n.translate('solution.income'),
          type: 'bar',
          barWidth: 10,
          color: ['#4FA2FF'],
          data: res.map(item => ({
            value: item.income / 100,
            proportion: item.proportion,
          })),
        },
      ],
    };
  }
  @Watch('option')
  @Watch('skin', { deep: true })
  initChart() {
    const chart = this.$refs.IncomeSortCharts;
    this.myChart = echarts.init(chart);
    this.myChart.setOption(this.option);
  }
}
</script>
<style lang="less" module></style>
