<template>
  <div>
    <div :class="$style.list">
      <div v-for="item in list" :key="item" :class="$style.item">
        <div :class="$style.item__title" v-if="item.msgType !== 3">
          {{ item.appName }} > {{ item.formName
          }}<span v-if="item.fieldName"> > {{ item.fieldName }}</span>
        </div>
        <div :class="$style.item__title" v-if="item.msgType !== 3">
          {{ $t('solution.solutionError') }}
        </div>
        <div :class="$style.item__desc">
          {{ item.msg }}
        </div>
        <div
          :class="$style.item__desc"
          v-if="item.msgType === 2 && item.msgType !== 3"
          v-show="item.configName"
        >
          {{ item.configName }} > {{ item.nodeName }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createFormModal } from '@triascloud/x-components';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { globalVueI18n } from '@triascloud/i18n';

function createSolutionAbnormalNotifyModal(props) {
  const modal = createFormModal(
    h =>
      h(SolutionAbnormalNotifyModal, {
        props,
      }),
    {
      title: globalVueI18n.translate('solution.reminder'),
      width: 470,
    },
  );
  return modal;
}
@Component()
export default class SolutionAbnormalNotifyModal extends Vue {
  static createModal = createSolutionAbnormalNotifyModal;
  @Prop({ type: Array, required: true }) list;
}
</script>
<style lang="less" module>
.list {
  min-height: 400px;
}
.item {
  font-size: 16px;
  margin-bottom: 20px;
  .item__title {
    color: var(--font);
    margin-bottom: 4px;
  }
  .item__desc {
    color: var(--font-info);
    line-height: 1.8;
  }
}
</style>
