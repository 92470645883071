// @ts-check
// import { getLastData } from '@/services/custom-application/table';
import { ensureArray } from '@triascloud/utils';
import { DataFormTool } from './BaseTool';

/**
 * @typedef LoadLastDataToolConfig
 * @property { 'LOAD_LAST_DATA' } type
 * @property { string } formId
 * @property { LoadLastDataToolField[] } loadFields
 */

/**
 * @typedef LoadLastDataToolField
 * @property { string } pkId
 * @property { LoadLastDataToolField[]? } children
 */

/**
 * @extends { DataFormTool<LoadLastDataToolConfig> }
 */
export default class LoadLastDataTool extends DataFormTool {
  /** @readonly */
  static type = 'LOAD_LAST_DATA';

  /** @type { any } */
  // @ts-ignore
  #lastData = null;

  initTool = async () => {
    // this.#lastData = await getLastData(this.config.formId);
  };

  /**
   * @param { Record<string, any>} currentData
   * @param { Record<string, any>} lastData
   * @param { LoadLastDataToolField[] } fields
   */
  // @ts-ignore
  #setLastData(currentData, lastData, fields) {
    if (!lastData || !ensureArray(fields).length) return currentData;
    fields.forEach(field => {
      if (!field.pkId || !(field.pkId in lastData)) return;
      if (field.children) {
        const subFields = field.children;
        if (!subFields.length) return;
        /** @type { any[] } */
        const currentList = ensureArray(currentData[field.pkId]);
        const lastList = ensureArray(lastData[field.pkId]);
        if (lastList.length > currentList.length) {
          lastList
            .slice(currentList.length)
            .forEach(() => currentList.push({}));
        }
        currentList.forEach(
          (row, index) =>
            lastList[index] &&
            this.#setLastData(row, lastList[index], subFields),
        );
      } else {
        currentData[field.pkId] = lastData[field.pkId];
      }
    });
    return currentData;
  }

  /** @type { import('./BaseTool').InitForm } */
  initForm = currentData => {
    if (!this.#lastData) return currentData;
    return this.#setLastData(
      currentData,
      this.#lastData?.data,
      this.config.loadFields,
    );
  };
}
