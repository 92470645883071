<template>
  <div :class="$style.content">
    <a-card :class="$style.controls">
      <field-list :list="fieldList" @add="handleAddField" />
    </a-card>
    <a-card :class="$style.main">
      <a-spin v-if="loading" />
      <drag-container v-else v-bind="dragContainerProps" ref="container" />
    </a-card>
    <a-card :class="$style.setting">
      <field-config
        :key="selectedField.pkId"
        v-if="selectedField"
        ref="config"
      />
      <div v-else :class="$style.empty">
        <div :class="$style.emptyBg" />
        <span>{{ $t('controls.fieldConfig.empty') }}</span>
      </div>
    </a-card>
  </div>
</template>
<script>
import { Component, Vue, ProvideReactive, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import FieldList from './field-list.vue';
import DragContainer from './drag-container.vue';
import FieldConfig from './field-config.vue';
import componentData from './component-data';
import { templateDesignModule } from '@/enum/store';
import { scrollIntoView } from './utils';
import {
  ControlDesignContext,
  getChildrenFields,
  ControlTypeEnum,
  createField,
  TemplateType,
  addDataSetFormat,
} from './form/controls';
import { delay } from '@triascloud/utils';

@Component({
  components: {
    FieldList,
    DragContainer,
    FieldConfig,
  },
})
export default class TemplateDesign extends Vue {
  @templateDesignModule.State loading;
  @templateDesignModule.State selectedField;
  @templateDesignModule.State form;
  @templateDesignModule.State fieldTagMap;
  @templateDesignModule.State templateType;
  @templateDesignModule.Getter fields;
  @templateDesignModule.Mutation selectField;
  @templateDesignModule.Mutation updatePlatformId;
  @templateDesignModule.Mutation updateSelectedField;
  @templateDesignModule.Action updateFields;
  @templateDesignModule.Action addField;
  @templateDesignModule.Action deleteField;
  @templateDesignModule.Action getTypes;

  @Prop() id;
  @ProvideReactive()
  @Prop()
  operationFlag;

  async created() {
    await this.init();
  }

  get fieldList() {
    return componentData.filter(group => group.list && group.list.length);
  }

  get dragContainerProps() {
    return {
      selectedField: this.selectedField,
      fieldTagMap: this.fieldTagMap,
      selectField: this.selectField,
      deleteField: this.deleteField,
      // addField: this.addField,
      updateFields: this.updateFields,
      fields: this.fields,
      templateType: this.templateType,
      // paramsVisible: this.paramsVisible,
    };
  }

  async init() {
    this.updatePlatformId(this.id);
    if (
      this.templateType === TemplateType.CUSTOM_TEMPLATE &&
      this.operationFlag !== 'edit'
    ) {
      await this.addField(createField(ControlTypeEnum.CustomParam));
    } else {
      await this.getTypes(this.id);
    }
  }

  // TODO 添加到tabs组件滚动到中间scrollIntoView
  async handleAddField(field) {
    if (!field) return;
    if (this.hasNotAdd(field)) {
      this.handleUpdateFieldTab(field);
    } else {
      await this.addField(field);
      if (!this.$refs.container) return;
      await this.$nextTick();
      const dragItem = this.$refs.container.findItemRef(field.pkId);
      if (dragItem) scrollIntoView(dragItem.$el);
    }
  }

  /**
   * @name 不能添加的组件，只能添加到Tab组件中
   * @description
   *  1、【物模型模板】模式，所有组件都不能添加到【drag-container】；只能添加到【功能分页卡】组件
   *  2、【自定义模板】模式，不能直接添加到【drag-container】的组件为【通用数据】组件；只能添加到【入参和出参的分页卡】组件
   */
  hasNotAdd(control) {
    return this.hasModelTemplate(control) || this.hasCustomTemplate(control);
  }

  hasModelTemplate(control) {
    return (
      this.templateType === TemplateType.MODEL_TEMPLATE &&
      (control.type === ControlTypeEnum.CurrentDataSet ||
        control.type === ControlTypeEnum.AddDataSet ||
        control.type === ControlTypeEnum.Int ||
        control.type === ControlTypeEnum.Float ||
        control.type === ControlTypeEnum.Double ||
        control.type === ControlTypeEnum.Input ||
        control.type === ControlTypeEnum.Date ||
        control.type === ControlTypeEnum.Switch ||
        control.type === ControlTypeEnum.Struct ||
        control.type === ControlTypeEnum.Array ||
        control.type === ControlTypeEnum.Enum ||
        control.type === ControlTypeEnum.File ||
        control.type === ControlTypeEnum.Image ||
        control.type === ControlTypeEnum.SyncInterface)
    );
  }

  hasCustomTemplate(control) {
    return (
      this.templateType === TemplateType.CUSTOM_TEMPLATE &&
      (control.type !== ControlTypeEnum.CurrentDataSet ||
        control.type !== ControlTypeEnum.Tabs ||
        control.type !== ControlTypeEnum.AddDataSet ||
        control.type !== ControlTypeEnum.SyncInterface)
    );
  }

  async handleUpdateFieldTab(field) {
    await this.updateFieldTab(field);
    await this.updateFieldCustomParmaTab(field);
  }

  async updateFieldTab(field) {
    if (this.templateType !== TemplateType.MODEL_TEMPLATE) return;
    await this.updateCommonFieldTab(field, ControlTypeEnum.Tabs);
  }

  async updateFieldCustomParmaTab(field) {
    if (this.templateType !== TemplateType.CUSTOM_TEMPLATE) return;
    await this.updateCommonFieldTab(field, ControlTypeEnum.CustomParam);
  }

  async updateCommonFieldTab(field, type) {
    const tabsField = cloneDeep(this.fields.find(v => v.type === type));
    const tab = tabsField.widget.tabs[this.currentTabIndex];
    const index = this.currentIndex(tab);
    tab.fields.splice(index, 0, field);
    let targetField = undefined;
    if (this.templateType === TemplateType.MODEL_TEMPLATE) {
      const { fields, index } = addDataSetFormat(tab.fields);
      tab.fields = fields;
      targetField = fields[index];
    }
    tabsField.widget.tabs[this.currentTabIndex] = tab;
    const fields = cloneDeep(this.fields);
    const array = fields.filter(v => v.type !== type);
    await this.updateFields([tabsField, ...array]);
    await this.$nextTick();
    this.selectField(targetField ? targetField : field);
  }

  /**
   * @name 【功能分页卡】（默认field有2个）或者【入参出参分页卡】（默认field有0个），下一项的位置
   */
  currentIndex(tab) {
    const index =
      (tab.fields.length === 2 &&
        this.templateType === TemplateType.MODEL_TEMPLATE) ||
      (tab.fields.length === 0 &&
        this.templateType === TemplateType.CUSTOM_TEMPLATE)
        ? tab.fields.length
        : this.selectedField.type === ControlTypeEnum.Tabs ||
          this.selectedField.type === ControlTypeEnum.CustomParam
        ? tab.fields.length
        : tab.fields.findIndex(v => v.pkId === this.selectedField.pkId) + 1;
    return index;
  }

  get TabType() {
    return this.templateType === TemplateType.MODEL_TEMPLATE
      ? ControlTypeEnum.Tabs
      : ControlTypeEnum.CustomParam;
  }

  get currentTabIndex() {
    const tabField = this.fields.find(v => v.type === this.TabType);
    return (
      tabField &&
      tabField.widget.tabs.findIndex(v => v.id === tabField.widget.current)
    );
  }

  @ProvideReactive(ControlDesignContext.CheckVisible)
  checkVisible(field) {
    if (!field) return true;
    switch (this.paramsVisible) {
      case 'only':
        return (
          getChildrenFields(field).length > 0 || field.widget.param === true
        );
      case 'none':
        return !field.widget.param;
      default:
        return true;
    }
  }

  async getValue() {
    if (this.form.items.length === 0) {
      this.$message.error('请选择组件！');
      return Promise.reject(false);
    }
    const status = await this.formatData();
    if (status) {
      return this.form;
    } else {
      return Promise.reject(false);
    }
  }

  /** @name 当前没有【数据组】的tab */
  errorTabs = [];
  async formatData() {
    if (this.templateType === TemplateType.CUSTOM_TEMPLATE) return true;
    // const designStatus = this.designValidate();
    const configStatus = await this.configValidate();
    if (!configStatus) {
      return false;
    } else {
      return true;
    }
  }

  /** @name design校验 */
  designValidate() {
    let status = true;
    const tab = this.form.items.find(v => v.type === ControlTypeEnum.Tabs);
    const set = new Set();
    const limit = tab.widget.tabs.length;
    if (tab) {
      tab.widget.tabs.forEach((v, i) => {
        v.fields.forEach(res => {
          if (
            res.type === ControlTypeEnum.AddDataSet ||
            res.type === ControlTypeEnum.CurrentDataSet
          ) {
            if (!set.has(i)) {
              set.add(i);
            }
          }
        });
      });
    }
    this.errorTabs = [];
    tab.widget.tabs.forEach((_v, i) => {
      if (!set.has(i)) {
        this.errorTabs.push(_v);
      }
    });
    if (set.size !== limit) {
      status = false;
    } else {
      status = true;
    }

    if (this.errorTabs.length) {
      const item = this.errorTabs[0];
      let title = item.title;
      if (tab.widget.current !== item.id) {
        this.selectField(tab);
        this.updateSelectedField({
          key: 'widget.current',
          value: item.id,
        });
      }
      this.$message.error(
        `【${title}】至少需要一个“当前数据组”或“新增数据组”！`,
      );
    }
    return status;
  }

  /** @name config校验【tab】 */
  async configValidate() {
    const tab = this.form.items.find(v => v.type === ControlTypeEnum.Tabs);
    this.selectField(tab);
    await this.$nextTick();
    await delay(50);
    return await this.$refs.config.handleValidate();
  }
}
</script>
<style lang="less" module>
.content {
  display: flex;
  padding: 10px;
  height: 100%;
}
.main {
  flex: 1;
  margin: 0 10px;
  overflow: hidden;
  :global(.ant-card-body .ant-spin) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
.controls,
.main,
.setting {
  border: 1px solid var(--border);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  :global {
    .ant-card-head,
    .ant-card-head-title,
    .ant-tabs-large-bar .ant-tabs-tab {
      padding: 0;
    }
    .ant-card-head {
      position: relative;
      z-index: 1;
    }
  }
  > :global(.ant-card-body) {
    flex: 1;
    padding: 0;
    overflow-y: auto;
    position: relative;
  }
  :global(.anticon[data-type='tc-icon-warning']) {
    font-size: 16px;
    vertical-align: middle;
    margin-left: 8px;
    color: var(--font-sub);
    cursor: pointer;
    &:hover {
      color: var(--primary);
    }
  }
}
.controls,
.setting {
  width: 340px;
  border: 1px solid var(--border);
  :global {
    .ant-card-head-tabs {
      .ant-tabs-nav {
        display: block;
      }
      .ant-tabs-nav .ant-tabs-tab {
        flex: 1;
        text-align: center;
        line-height: 50px;
        height: 51px;
        font-size: 14px;
        font-weight: normal;
      }
      .ant-tabs-nav > div {
        display: flex;
      }
    }
  }
}
.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.emptyBg {
  margin-top: 60%;
  width: 250px;
  height: 160px;
  margin-bottom: 20px;
  background-image: var(--empty-drag-image);
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
