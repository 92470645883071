import request from '../request';

const service = '/iot-platform/platformDevice';

/**@name 添加设备 */
export function add(data) {
  return request(`${service}`, {
    method: 'POST',
    body: data,
  });
}

/**@name 编辑设备 */
export function updateDevice(data) {
  return request(`${service}`, {
    method: 'PUT',
    body: data,
  });
}

/**@name 删除设备 */
export function deleteDevice(id) {
  return request(`${service}/${id}`, {
    method: 'PUT',
  });
}

/**@name 获取设备详情 */
export function getDevice(id) {
  return request(`${service}/${id}`, {
    method: 'GET',
  });
}

/**@name 分配设备 */
export function allocation(data) {
  return request(`${service}/allocation`, {
    method: 'PUT',
    body: data,
  });
}

/**@name 绑定设备 */
export function bind(data) {
  return request(`${service}/bind`, {
    method: 'PUT',
    body: data,
  });
}

/**@name 解绑设备 */
export function unbind(id) {
  return request(`${service}/unbind/${id}`, {
    method: 'PUT',
  });
}

/**@name 导入设备 */
export function importDevice(id, data) {
  return request(`${service}/import/${id}`, {
    method: 'POST',
    body: data,
  });
}

/**@name 获取设备列表 */
export function getList(data) {
  return request(`${service}/devicePage`, {
    method: 'POST',
    body: data,
  });
}

/**@name 校验删除设备 */
export function getDeleteDeviceList(data) {
  return request('/iot-platform/platformDevice/check', {
    method: 'POST',
    body: data,
  });
}

/**@name 获取产品下网关设备列表 */
export function gatewayByProduct(id) {
  return request(`${service}/gatewayByProduct/${id}`, {
    method: 'GET',
  });
}

/**@name 获取产品 fenlei 列表  */
export function gatCategoryList(data) {
  return request('/iot-platform/category/list', {
    method: 'GET',
    body: data,
  });
}

/**@name 预览数据-设备地图轨迹 */
export function previewPosition(data) {
  return request(`${service}/previewPosition`, {
    method: 'POST',
    body: data,
  });
}
