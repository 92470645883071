import request from '../request';
export const basePath = '/template-manager/solution/management';
export const servicePath = '/template-manager';

/**
 * @name 解决方案分类列表
 */
export function getSolutionType() {
  return request(`${servicePath}/slClassify/list`, {
    method: 'POST',
  });
}

/**
 * @name 管理解决方案列表-管理
 */
export function getSolution(report) {
  return request(`${servicePath}/solution/admin/management/list`, {
    method: 'POST',
    body: report,
  });
}

/**
 * @name 解决方案详情管理者-管理
 */
export function solutionDetailManage(pkId) {
  return request(
    `${servicePath}/solution/admin/management/detail?pkId=${pkId}`,
    {
      method: 'GET',
    },
  );
}
/**
 * @name 解决方案安装详情-管理
 */
export function solutionInstallDetail(pkId) {
  return request(
    `${servicePath}/solution/amin/management/install/detail?pkId=${pkId}`,
    {
      method: 'GET',
    },
  );
}

/**
 * @name 解决方案操作记录-管理
 */
export function optRecord(pkId) {
  return request(`${servicePath}/solution/admin/management/optRecord/${pkId}`, {
    method: 'GET',
  });
}

/**
 * @name 解决方案管理详情列表-管理
 */
export const solutionSetDetails = report =>
  request(`${servicePath}/solution/admin/management/list`, {
    method: 'POST',
    body: report,
  });

/**
 * @name 解决方案管理详情列表-管理
 */
export const solutionApprovalDetails = report =>
  request(`${servicePath}/solution/admin/management/loadApprovalRequestForm`, {
    method: 'POST',
    body: report,
  });

/**
 * @name 解决方案审核-管理
 */
export const processApprovalRequestForm = report =>
  request(
    `${servicePath}/solution/admin/management/processApprovalRequestForm`,
    {
      method: 'POST',
      body: report,
    },
  );

/**
 * @name 解决方案排序-管理
 */
export function sortManage(report) {
  return request(`${servicePath}/solution/admin/management/sort`, {
    method: 'POST',
    body: report,
  });
}

/**
 * @name 解决方案上下架-管理
 */
export function standUpAndDownMange(report) {
  return request(
    `${servicePath}/solution/admin/management/standUpAndDown/${report.pkId}/${report.state}`,
    {
      method: 'POST',
    },
  );
}

/**
 * @name 解决方案删除-管理
 */
export function removeCase(report) {
  return request(
    `${servicePath}/solution/admin/management/remove/${report.pkId}
      `,
    {
      method: 'POST',
    },
  );
}

/**
 * @name 作者解决方案列表-作者
 */
export function getAuthorSolution(report) {
  return request(`${servicePath}/solution/author/management/list`, {
    method: 'POST',
    body: report,
  });
}

/**
 * @name 添加解决方案-作者
 */
export function addSolution(report) {
  return request(`${servicePath}/solution/author/management/add`, {
    method: 'POST',
    body: report,
  });
}

/**
 * @name 删除解决方案-作者
 */
export function removeSolution(pkId) {
  return request(`${servicePath}/solution/author/management/remove/${pkId}`, {
    method: 'DELETE',
  });
}

/**
 * @name 解决方案详情-作者
 */
export function solutionDetail(pkId) {
  return request(
    `${servicePath}/solution/author/management/detail?pkId=${pkId}`,
    {
      method: 'GET',
    },
  );
}

/**
 * @name 解决方案上下架-作者
 */
export function standUpAndDown(report) {
  return request(
    `${servicePath}/solution/author/management/applyUpOrDown/${report.pkId}/${report.state}`,
    {
      method: 'POST',
    },
  );
}

/**
 * @name 解决方案回收站
 */
export function recycle(report) {
  return request(`${servicePath}/solution/author/management/recycle`, {
    method: 'POST',
    body: report,
  });
}

/**
 * @name 解决方案恢复
 */
export function recover(pkId) {
  return request(`${servicePath}/solution/author/management/recover/${pkId}`, {
    method: 'GET',
  });
}

/**
 * @name 编辑解决方案
 */
export function editSolution(report) {
  return request(`${servicePath}/solution/author/management/edit`, {
    method: 'POST',
    body: report,
  });
}

/**
 * @name 解决方案永久删除
 */
export function deleteSolution(pkId) {
  return request(`${servicePath}/solution/author/management/delete/${pkId}`, {
    method: 'DELETE',
  });
}
/**
 * @name 解决方案排序
 */
export function sort(report) {
  return request(`${servicePath}/solution/author/management/sort`, {
    method: 'POST',
    body: report,
  });
}

/**
 * @name 添加升级方案记录
 */
export function addSolutionRecord(report) {
  return request(
    `${servicePath}/solution/author/management/add/upgrade/record`,
    {
      method: 'POST',
      body: report,
    },
  );
}

/**
 * @name 历史更新记录
 */
export function historyRecord(id) {
  return request(
    `${servicePath}/solution/author/management/update/detail/list?solutionId=${id}`,
    {
      method: 'GET',
    },
  );
}

/**
 * @name 本次更新记录
 */
export function currentRecord(id) {
  return request(
    `${servicePath}/solution/author/management/update/detail?solutionId=${id}`,
    {
      method: 'GET',
    },
  );
}

/**
 * @name 编辑更新记录
 */
export function editCurrentRecord(data) {
  return request(
    `${servicePath}/solution/author/management/edit/upgrade/record`,
    {
      method: 'POST',
      body: data,
    },
  );
}

/**
 * @name 更新记录失败校验信息
 */
export function checkResult(id) {
  return request(
    `${servicePath}/solution/author/management/check/result?solutionId=${id}`,
    {
      method: 'GET',
    },
  );
}

/**
 * @name 解决方案管理
 */
export function solutionStatistics() {
  return request(
    `${servicePath}/solution/statistics/integratedStatisticalInterface`,
    {
      method: 'GET',
    },
  );
}

/**
 * @name 企业解决方案交易明细
 */
export function solutionTrade(report) {
  return request(
    `${servicePath}/solution/statistics/enterpriseSolutionsTransactionDetails`,
    {
      method: 'GET',
      body: report,
    },
  );
}

/**
 * @name 解决方案购买次数详情
 */
export function solutionPurchaseTimesDetails(report) {
  return request(
    `${servicePath}/solution/admin/management/install/detail/${report.solutionId}`,
  );
}

/**
 * @name 解决方案生成或升级结果
 */
export function createdResult(report) {
  return request(
    `${servicePath}/solution/author/management/solutionGenerateOrUpgradeResult/${report}`,
    {
      method: 'GET',
    },
  );
}

/**
 * @name 解决方案审批
 */
export function solutionApproval(data) {
  return request(
    `${servicePath}/solution/admin/management/processApprovalRequestForm`,
    {
      method: 'POST',
      body: data,
    },
  );
}

/**
 * @name 解决方案可见设置
 */
export function solutionVisibleSetting(data) {
  return request(`${servicePath}/solution/admin/management/update`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @name 解决方案可见设置
 */
export function getSolutionVisible(id) {
  return request(
    `${servicePath}/solution/admin/management/getVisibleConfig/${id}`,
    {
      method: 'GET',
    },
  );
}
