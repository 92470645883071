<template>
  <a-form-model layout="vertical" :model="form" ref="form">
    <a-form-model-item
      :label="$t('connector.dpManage.chooseJsonFile')"
      prop="deviceSerial"
    >
      <a-upload
        :file-list="fileList"
        :remove="handleRemove"
        :multiple="false"
        :customRequest="beforeUpload"
        accept=".json"
      >
        <a-button>
          <a-icon type="upload" />
          {{ $t('connector.dpManage.chooseFile') }}</a-button
        >
      </a-upload>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import Ajv from 'ajv';
import { dpJsonSchema } from '../utils';

const ajv = new Ajv();
@Component()
export default class UploadForm extends Vue {
  @Prop({ type: Object }) record;
  form = {
    type: 1,
  };
  fileList = [];
  handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  }
  async beforeUpload(fileObj) {
    const file = fileObj.file;
    this.fileList = [file];
    try {
      let size = file.size;
      file.status = 'done';
      let text = await file.slice(0, size).text();
      JSON.parse(text);
      // this.jsonData = this.formatJSON(result);
    } catch (error) {
      this.$message.error(this.$t('connector.dpManage.DPTips2'));
    }
    return false;
  }

  formatJSON(list = []) {
    let errors = [];
    let data = [];
    list.forEach(item => {
      const validate = ajv.compile(dpJsonSchema);
      if (!validate(item)) {
        errors.push({
          errors: validate.errors,
        });
      } else {
        data.push(item);
      }
    });
    return {
      errors,
      data,
      list,
    };
  }
  getValue() {
    return new Promise((resolve, reject) => {
      // this.jsonData.errors.length === 0 &&
      if (this.fileList.length) {
        try {
          const { fileList } = this;
          const formData = new FormData();
          formData.append('file', fileList[0]);
          resolve(formData);
        } catch (error) {
          reject(false);
        }
      } else {
        reject(false);
      }
    });
  }
}
</script>
<style lang="less" module>
.mr10 {
  margin-left: 10px;
}
.centerRight {
  text-align: right;
}
</style>
