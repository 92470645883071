<template>
  <div>
    <ApiHeader
      :apiId="apiId"
      :themeColor="themeColor"
      @refresh="refreshLists"
    />
    <a-tabs @change="changeTab" :default-active-key="activeKey">
      <a-tab-pane key="bace-api" tab="基础设置">
        <BaseConfig
          v-if="activeKey === 'bace-api'"
          :apiId="apiId"
          :platformId="platformId"
          :platformIdentifier="platformIdentifier"
          @refresh="refreshLists"
          @close="close"
          @changeColor="changeColor"
        />
      </a-tab-pane>
      <a-tab-pane key="trigger-config" tab="触发设置">
        <TriggerConfig
          v-if="activeKey === 'trigger-config'"
          :apiId="apiId"
          :platformId="platformId"
          @close="close"
          @refresh="refreshLists"
        />
      </a-tab-pane>
      <a-tab-pane key="api-config" tab="API设置">
        <ApiSettings
          v-if="activeKey === 'api-config'"
          :apiId="apiId"
          :platformId="platformId"
          @refresh="refreshLists"
          @close="close"
          @changeColor="changeColor"
        ></ApiSettings>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseConfig from './base-config';
import ApiHeader from './api-header';
import TriggerConfig from './trigger-config.vue';
import ApiSettings from './api-settings.vue';
@Component({
  components: {
    BaseConfig,
    ApiHeader,
    TriggerConfig,
    ApiSettings,
  },
})
export default class SettingApiModel extends Vue {
  @Prop({ type: String, default: '' }) apiId;
  @Prop({ type: String, default: '' }) platformId;
  @Prop({ type: String, default: '' }) platformIdentifier;

  activeKey = 'bace-api';
  changeTab(key) {
    this.activeKey = key;
  }
  close() {
    this.$emit('close');
  }
  refreshLists() {
    this.$emit('refreshList');
  }
  themeColor = '';
  changeColor(color) {
    this.themeColor = color;
  }
}
</script>

<style scoped>
.apiHeader {
  display: flex;
  align-items: center;
  border: 1px solid var(--border);
  padding: 20px;
}
.iconHeader {
  font-size: 40px;
  margin-right: 10px;
}
</style>
