import Vue from 'vue';
import { VuePermissionRouter } from '@triascloud/permission';
import { fetchPermission } from '@/services/production/module-auth';
import MainLayout from '@/layout/main.vue';
import IndexPage from '@/views/index.vue';
import NotFoundPage from '@/views/404.vue';
import NotVerifyPage from '@/views/403.vue';

import DictionaryIndex from '@/views/dictionary';
import DictionaryReview from '@/views/dictionary/review';

import GalleryIndex from '@/views/gallery';
import GalleryReview from '@/views/gallery/review';
import IconIndex from '@/views/icon';

import Feedback from '@/views/feedback';
import KitMarket from '@/views/kit-market';

import Help from '@/views/help';

import TemplateReview from '@/views/template/review';
import Market from '@/views/market';
import FormTemplate from '@/views/market/form';
import ApplicationTemplate from '@/views/market/application';
import TemplateStatistics from '@/views/market/template-statistics';

import Print from '@/views/market/print';

import Record from '@/views/record';
import Invitation from '@/views/invitation';
import Solution from '@/views/solution/solution';
import CompanyCode from '@/views/invitation/company-code';
import InvitationView from '@/views/invitation/invitation-view';
import Activity from '@/views/activity';
import EventManagement from '@/views/activity/event-management.vue';
import EventInvitationCode from '@/views/activity/event-invitation-code';
// import SolutionStatistic from '@/views/solution/statistics';
import SolutionManagement from '@/views/solution/management';
import Plugin from '@/views/plugin';
import SnmsPlugin from '@/views/plugin/snms';
import dataLinkPlugin from '@/views/plugin/data';
import pluginOverView from '@/views/plugin/overview';
import Connector from '@/views/connector';
import ConnectorManagement from '@/views/connector/platform-manage/index';
import ConnectorReview from '@/views/connector/platform-review/index';

import WebSite from '@/views/website';
import WebSiteCloud from '@/views/website/cloud';
import WebSiteOfficial from '@/views/website/official/index';
import OfficialNavigator from '@/views/website/official/navigator';
import OfficialHome from '@/views/website/official/home';
import OfficialCase from '@/views/website/official/case';
import OfficialNews from '@/views/website/official/news';
import OfficialDownload from '@/views/website/official/download';

import AttestView from '@/views/attest/attest-view';
import OrderBill from '@/views/financial/order-bill/index.vue';
import { twinsExternal } from '@/utils/externalHref';

import BusinessOpportunity from '@/views/business-opportunity/index.vue';
import Hat from '@/views/hat/index.vue';
import HatDevice from '@/views/hat/device.vue';
import QualityCheck from '@/views/hat/quality-check.vue';
import Statistics from '@/views/hat/statistics/index.vue';

import DigitalSite from '@/views/digital-site/index.vue';
import Device from '@/views/digital-site/device.vue';
import Manufacturer from '@/views/digital-site/manufacturer.vue';
import Developer from '@/views/digital-site/developer';
import NavTemplate from '@/views/digital-site/template.vue';

import Guide from '@/views/website/guide/index.vue';

import WebSiteFire from '@/views/website/fire-safety/index';
import FireNews from '@/views/website/fire-safety/news';

Vue.use(VuePermissionRouter);
/** @type {import('vue-router').RouteConfig[]} } */
const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '/',
        component: IndexPage,
        children: [
          {
            path: '/dictionary',
            component: DictionaryIndex,
            name: 'dictionary',
            meta: { auth: true },
          },
          {
            path: '/dictionary/review',
            component: DictionaryReview,
            name: 'dictionary-review',
            meta: { auth: true },
          },
          {
            path: '/gallery',
            component: GalleryIndex,
            name: 'gallery',
            meta: { auth: true },
          },
          {
            path: '/gallery/review',
            component: GalleryReview,
            name: 'gallery-review',
            meta: { auth: true },
          },
          {
            path: '/icon',
            component: IconIndex,
            name: 'icon',
            meta: { auth: true },
          },
          {
            path: '/feedback',
            name: 'feedback',
            component: Feedback,
            meta: { auth: true },
          },
          {
            path: '/kitMarket',
            name: 'kitMarket',
            component: KitMarket,
            meta: { auth: true },
          },
          {
            path: '/help',
            name: 'help',
            component: Help,
            meta: { auth: true },
          },
          {
            path: '/solution',
            name: 'solution',
            component: Solution,
            meta: { auth: true },
          },
          //   {
          //     path: '/solution/management',
          //     name: 'management',
          //     components: SolutionStatistic,
          //     meta: { auth: false },
          //   },
          {
            path: '/solution/management',
            name: 'management',
            component: SolutionManagement,
            meta: { auth: true },
          },

          {
            path: '/template/review',
            name: 'template-review',
            component: TemplateReview,
            meta: { auth: true },
          },
          {
            name: 'market',
            path: '/market',
            component: Market,
            redirect: '/market/application',
            meta: { auth: true },
            children: [
              {
                path: '/market/application',
                name: 'market-application',
                component: ApplicationTemplate,
              },
              {
                path: '/market/form',
                name: 'market-form',
                component: FormTemplate,
              },
              {
                path: '/market/statistics',
                name: 'market-statistics',
                component: TemplateStatistics,
              },
              {
                path: '/market/print',
                name: 'market-print',
                component: Print,
              },
            ],
          },

          {
            name: 'plugin',
            path: '/plugin',
            component: Plugin,
            redirect: '/plugin/snms',
            meta: { auth: true },
            children: [
              {
                name: 'snms',
                path: '/plugin/snms',
                component: SnmsPlugin,
                meta: { auth: true },
              },
              {
                name: 'data',
                path: '/plugin/data',
                component: dataLinkPlugin,
                meta: { auth: true },
              },
              {
                name: 'api',
                path: '/plugin/api',
                component: dataLinkPlugin,
                meta: { auth: true },
              },

              {
                name: 'overview',
                path: '/plugin/overview',
                component: pluginOverView,
                meta: { auth: true },
              },
            ],
          },

          {
            path: '/website',
            name: 'website',
            component: WebSite,
            meta: { auth: true },
            children: [
              {
                path: '/website',
                name: 'website-cloud',
                component: WebSiteCloud,
              },
              {
                path: '/website/official',
                name: 'website-official',
                component: WebSiteOfficial,
                redirect: '/website/official/navigator',
                children: [
                  {
                    path: '/website/official/navigator',
                    name: 'website-official-navigator',
                    component: OfficialNavigator,
                  },
                  {
                    path: '/website/official/home',
                    name: 'website-official-home',
                    component: OfficialHome,
                  },
                  {
                    path: '/website/official/case',
                    name: 'website-official-case',
                    component: OfficialCase,
                  },
                  {
                    path: '/website/official/news',
                    name: 'website-official-news',
                    component: OfficialNews,
                  },
                  {
                    path: '/website/official/download',
                    name: 'website-official-download',
                    component: OfficialDownload,
                  },
                ],
              },
              {
                path: '/website/fire',
                name: 'website-fire',
                component: WebSiteFire,
                redirect: '/website/fire/news',
                children: [
                  {
                    path: '/website/fire/news',
                    name: 'website-fire-news',
                    component: FireNews,
                  },
                ],
              },
              {
                path: '/website/guide',
                name: 'website-guide',
                component: Guide,
                meta: { auth: true },
              },
            ],
          },
          {
            path: '/record',
            name: 'record',
            component: Record,
            meta: { auth: true },
          },
          {
            path: '/invitation',
            name: 'invitation',
            component: Invitation,
            meta: { auth: true },
          },
          {
            path: '/companyCode',
            name: 'companyCode',
            component: CompanyCode,
            meta: { auth: true },
          },
          {
            path: '/statistics',
            name: 'statistics',
            component: InvitationView,
            meta: { auth: true },
          },
          {
            path: '/activity',
            name: 'activity',
            component: Activity,
            children: [
              {
                path: '/activity/management',
                name: 'event-management',
                component: EventManagement,
                meta: { auth: true },
              },
              {
                path: '/activity/invitation',
                name: 'event-invitation-code',
                component: EventInvitationCode,
                meta: { auth: true },
              },
            ],
          },
          {
            path: '/connector',
            name: 'connector',
            component: Connector,
            children: [
              {
                path: '/connector/management',
                name: 'connector-management',
                component: ConnectorManagement,
                meta: { auth: true },
              },
              {
                path: '/connector/review',
                name: 'connector-review',
                component: ConnectorReview,
                meta: { auth: true },
              },
              {
                path: '/connector/digitalTwins',
                name: 'connector-twins',
                component: () => window.open(twinsExternal(), '_self'),
                meta: { auth: true },
              },
            ],
          },
          {
            path: '/attest',
            name: 'attest',
            component: AttestView,
            meta: { auth: true },
          },
          {
            path: '/order-bill',
            name: 'order-bill',
            component: OrderBill,
            meta: { auth: true },
          },
          {
            name: 'registeredUserFollowup',
            path: '/registeredUserFollowup',
            component: BusinessOpportunity,
            meta: { auth: true },
          },
          {
            path: '/hat',
            name: 'hat',
            redirect: '/hat/device',
            component: Hat,
            children: [
              {
                path: 'device',
                name: 'device',
                component: HatDevice,
                meta: { auth: true },
              },
              {
                path: 'qualityCheck',
                name: 'qualityCheck',
                component: QualityCheck,
                meta: { auth: true },
              },
              {
                path: 'statistics',
                name: 'Statistics',
                component: Statistics,
                meta: { auth: true },
              },
            ],
          },
          {
            path: '/ds',
            name: 'digital',
            redirect: '/ds/manufacturer',
            component: DigitalSite,
            children: [
              {
                path: 'manufacturer',
                name: 'manufacturer',
                component: Manufacturer,
                meta: { auth: true },
              },
              {
                path: 'device',
                name: 'device',
                component: Device,
                meta: { auth: true },
              },
              {
                path: 'developer',
                name: 'developer',
                component: Developer,
                meta: { auth: true },
              },
              {
                path: 'template',
                name: 'template',
                component: NavTemplate,
                meta: { auth: true },
              },
            ],
          },
        ],
      },
      /** @name 403页面 */
      {
        path: '/403',
        name: '403',
        component: NotVerifyPage,
      },
      /** @name 404页面 */
      {
        path: '*',
        name: '404',
        component: NotFoundPage,
      },
    ],
  },
];
const router = new VuePermissionRouter({
  fetch: () => fetchPermission('co'),
  forbiddenRoute: '/403',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
export default router;
