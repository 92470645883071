<template>
  <div :class="$style.iconLibrary">
    <ul :class="$style.tab">
      <li
        v-for="library in libraryTypeList"
        @click="handleChangeType(library.value)"
        :class="libraryType === library.value ? $style.active : ''"
        :key="library.value"
      >
        {{ library.name }}
      </li>
    </ul>
    <div :class="$style.action">
      <div :class="$style.search">
        <a-input-search
          v-model="keyword"
          @search="refreshList"
        ></a-input-search>
        <span>
          {{ $t('common.label.createTime') }}
          <a-range-picker
            v-model="shareDate"
            format="YYYY-MM-DD"
            @change="refreshList"
          >
          </a-range-picker>
        </span>
        <span
          >{{ $t('dictionary.total') }}
          <i :class="$style.primary">{{ total }}</i>
          {{ $t('iconLibrary.library.size') }}</span
        >
        <span
          ><i :class="$style.primary">{{ iconTotal }}</i>
          {{ $t('iconLibrary.icon.size') }}</span
        >
      </div>
      <div :class="$style.button">
        <a-button
          v-if="$p.action('CREATE_ICON_BIN')"
          type="primary"
          @click="handleAddLibrary"
          ><x-icon type="tc-icon-feedback" />{{
            $t('iconLibrary.library.add')
          }}</a-button
        >
        <a-button
          v-if="$p.action('SETTING_DEFAULT_ICON')"
          type="primary"
          ghost
          @click="handleSettingDefault"
          ><x-icon type="check-circle" />{{
            $t('iconLibrary.library.default.name')
          }}</a-button
        >
        <a-button v-if="$p.action('RECYCLE-BIN')" @click="handleOpenIconRecycle"
          ><x-icon type="tc-icon-delete" />{{
            $t('gallery.actions.recycling')
          }}</a-button
        >
      </div>
    </div>
    <a-spin v-if="spinning" />
    <EmptyContent :class="$style.empty" v-if="emptyContent" />
    <x-scroll-list
      v-show="!emptyContent"
      v-model="libraryList"
      :service="initData"
      ref="scrollList"
      :class="$style.list"
    >
      <icon-library slot-scope="{ data }" :data="data" @change="refreshList" />
    </x-scroll-list>
  </div>
</template>
<script>
import { Component, Provide, Vue } from 'vue-property-decorator';
import { IconSymbol } from './context';
import { IconLibraryTypeEnum } from '@/enum/icon';
import {
  getIconLibraryList,
  saveLibrary,
} from '@/services/resource/icon-library';
import IconForm from './component/icon-form';
import DefaultIcon from './component/default-icon/index';
import IconLibrary from './component/icon-library';
import IconRecycle from './component/recycle-list/index';
import EmptyContent from '@/components/empty-content';

@Component({
  components: { IconLibrary, EmptyContent },
})
export default class Index extends Vue {
  @Provide(IconSymbol)
  get Icon() {
    return this;
  }

  libraryType = '';
  total = 0;
  iconTotal = 0;
  keyword = '';
  shareDate = [];
  get libraryTypeList() {
    return [
      {
        name: this.$t('common.label.all'),
        value: '',
      },
      {
        name: this.$t('iconLibrary.library.type.official'),
        value: IconLibraryTypeEnum.OFFICIAL,
      },
      {
        name: this.$t('iconLibrary.library.type.monochrome'),
        value: IconLibraryTypeEnum.MONOCHROMATIC,
      },
      {
        name: this.$t('iconLibrary.library.type.multiColor'),
        value: IconLibraryTypeEnum.MULTICOLOR,
      },
    ];
  }

  handleChangeType(type) {
    this.libraryType = type;
    this.refreshList();
  }

  refreshList() {
    this.$refs.scrollList.refresh();
  }

  libraryList = [];
  emptyContent = false;

  get starTime() {
    return !this.shareDate.length
      ? ''
      : this.shareDate[0].startOf('day').valueOf();
  }

  get endTime() {
    return !this.shareDate.length
      ? ''
      : this.shareDate[1].endOf('day').valueOf();
  }

  spinning = false;

  async initData(page) {
    this.spinning = true;
    const params = {
      deleted: 0,
      endTime: this.endTime,
      iconLibraryType: !this.libraryType ? null : this.libraryType,
      iconLibraryQuery: {
        current: page,
        size: 18,
      },
      iconQuery: {
        current: 1,
        size: 12,
      },
      keyword: this.keyword,
      starTime: this.starTime,
    };
    const list = await getIconLibraryList(params);
    if (page === 1) {
      this.total = list.totalLibraryNum;
      this.iconTotal = list.totalIconNum;
    }

    this.emptyContent =
      !list.libraries.records.length && !this.libraryList.length;

    this.spinning = false;
    return list.libraries.records;
  }

  async handleAddLibrary() {
    const data = await IconForm.createModal(
      {},
      {
        title: this.$t('iconLibrary.library.add'),
      },
    );
    await saveLibrary(data);

    this.refreshList();
  }

  async handleSettingDefault() {
    await DefaultIcon.createDefaultIconDrawer();
  }

  async handleOpenIconRecycle() {
    await IconRecycle.createRecycleDrawer(
      {},
      {
        refreshLibraryList: () => this.refreshList(),
      },
    );
  }
}
</script>
<style lang="less" module>
.iconLibrary {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  overflow: hidden;
}
.tab {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  height: 80px;
  flex-shrink: 0;

  li {
    margin-right: 40px;
    color: var(--font);
    cursor: pointer;

    &.active {
      color: var(--primary);
    }
  }
}
.action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 20px;
  width: 100%;
  height: 32px;
  flex-shrink: 0;
  margin-bottom: 10px;
}
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;

  .primary {
    color: var(--primary);
    font-style: normal;
  }

  > span + span {
    margin-left: 10px;
  }

  :global {
    .ant-input-search {
      width: 110px;
      margin-right: 10px;
    }

    .ant-calendar-picker {
      width: 230px;
    }
  }
}
.button {
  flex: 1;
  text-align: right;
  button {
    height: 32px;
  }
  button + button {
    margin-left: 30px;
  }
}
.empty {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 90px;
    color: var(--font-info);
  }
}
.list {
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 255px);
  gap: 20px 20px;
  justify-content: space-between;
  padding: 15px 20px;
  align-content: flex-start;

  :global {
    .x-scroll-list-footer {
      display: none;
    }
  }
}
</style>
