<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item label="选择产品" prop="production">
      <selectProduct :platformId="platformId" v-model="form.production" />
    </a-form-model-item>
    <a-form-model-item label="设备内容" prop="content">
      <a-radio-group v-model="form.content">
        <a-radio value="panel">设备面板</a-radio>
        <a-radio value="introduce">产品介绍</a-radio>
      </a-radio-group>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import SelectProduct from '@/views/connector/platform-manage/develop/device-manage/device-form/select-product.vue';

@Component({
  components: {
    SelectProduct,
  },
})
export default class ProductSetting extends Vue {
  @Prop({ type: String, default: '' }) platformId;

  form = {
    production: undefined,
    content: 'panel',
  };

  rules = {
    production: [
      {
        required: true,
        message: '产品分类不能为空',
      },
    ],
  };

  async getValue() {
    await this.$refs.form.validate();
    return this.form;
  }
}
</script>
<style lang="less" module></style>
